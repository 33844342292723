import { Fragment } from 'react'
import { Grid, Divider } from 'semantic-ui-react'
import { IconButton, DatePicker, GridRowColumn, Text } from '../BaseComponents'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { GroupedDropdown } from '../shared/GroupedDropdown'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { User } from '../../reducers/auth/userReducer'
import { useReselector } from '../../utils/sharedHooks'
import { selectAdminOrUserGroupedFinancialAccountOptions } from '../../selectors/financeSelectors'
import { TaxFilingUser } from '../../features/Taxes/AnnualTaxes/annualTaxFilings.slice'
import {
  useIsDeviceWidth,
  DeviceWidthCombo,
} from '../../utils/deviceWidthHelpers'
import { isNil } from 'lodash'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../features/OpenFeature'

export interface StatementData {
  statementMonth: string
  financialAccountIds: number[]
}

export const StatementUploadSection = ({
  filesToUpload,
  setFilesToUpload,
  statementData,
  setStatementData,
  user,
  useFilteredAccountOptions,
}: {
  filesToUpload: File[]
  setFilesToUpload: (files: File[]) => void
  statementData: StatementData[]
  setStatementData: (statementData: StatementData[]) => void
  user: User | TaxFilingUser | null
  useFilteredAccountOptions?: boolean
}) => {
  const isMobileOrTablet = useIsDeviceWidth(DeviceWidthCombo.mobileOrTablet)

  const oneStatementRequired = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.oneStatementRequired,
    false
  )

  const financialAccountOptions = useReselector(
    selectAdminOrUserGroupedFinancialAccountOptions,
    user?.id
  )

  const filteredFinancialAccountOptions = financialAccountOptions.filter(
    (option) => isNil(option.statementPermissions)
  )

  const handleRemove = (index: number) => {
    setFilesToUpload([
      ...filesToUpload.slice(0, index),
      ...filesToUpload.slice(index + 1),
    ])
    setStatementData([
      ...statementData.slice(0, index),
      ...statementData.slice(index + 1),
    ])
  }

  const handleUpdate = <T,>(index: number, type: string, value: T) => {
    const updatedStatementData = [...statementData]
    updatedStatementData[index] = {
      ...updatedStatementData[index],
      [type]: value,
    }
    setStatementData(updatedStatementData)
  }

  return (
    <>
      <Divider />
      <GridRowColumn>
        <Text as="h3"> Uploaded </Text>
        {isMobileOrTablet && (
          <Grid padded>
            {filesToUpload.map((file, index) => (
              <Fragment key={file.name}>
                <Grid.Row>
                  <Grid.Column width={14}>
                    <a href={URL.createObjectURL(file)} download={file.name}>
                      {file.name}
                    </a>
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <IconButton
                      onClick={() => handleRemove(index)}
                      icon={regular('trash-can')}
                      style={{ marginLeft: 6 }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <GridRowColumn short>
                  <div className="monthPicker">
                    <DatePicker
                      placeholder="Select month"
                      value={statementData[index]?.statementMonth}
                      onChange={(date) =>
                        handleUpdate(index, 'statementMonth', date)
                      }
                      showMonthYearPicker
                      dateFormat={DATE_FORMATS.YEAR_MONTH}
                      placeholderText="MM/YYYY"
                      fullWidth
                    />
                  </div>
                </GridRowColumn>
                <GridRowColumn short>
                  <GroupedDropdown
                    placeholder="Select account"
                    value={statementData[index]?.financialAccountIds || []}
                    options={
                      oneStatementRequired && useFilteredAccountOptions
                        ? filteredFinancialAccountOptions
                        : financialAccountOptions
                    }
                    onChange={(value) =>
                      handleUpdate(index, 'financialAccountIds', value)
                    }
                    fullWidth
                    allowOneParent
                    height={50}
                  />
                </GridRowColumn>
              </Fragment>
            ))}
          </Grid>
        )}

        {!isMobileOrTablet &&
          filesToUpload.map((file, index) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
                marginBottom: 16,
              }}
              key={file.name}
            >
              <a
                style={{
                  width: 120,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                href={URL.createObjectURL(file)}
                download={file.name}
              >
                {file.name}
              </a>
              <div className="monthPicker">
                <DatePicker
                  value={statementData[index]?.statementMonth}
                  onChange={(date) =>
                    handleUpdate(index, 'statementMonth', date)
                  }
                  showMonthYearPicker
                  dateFormat={DATE_FORMATS.YEAR_MONTH}
                  placeholderText="MM/YYYY"
                  placeholder="Select month"
                  maxDate={new Date()}
                  style={{ width: 160 }}
                />
              </div>
              <GroupedDropdown
                placeholder="Select account"
                value={statementData[index]?.financialAccountIds || []}
                options={
                  oneStatementRequired && useFilteredAccountOptions
                    ? filteredFinancialAccountOptions
                    : financialAccountOptions
                }
                onChange={(value) =>
                  handleUpdate(index, 'financialAccountIds', value)
                }
                allowOneParent
                height={50}
                width={260}
              />
              <IconButton
                onClick={() => handleRemove(index)}
                icon={regular('trash-can')}
                style={{ marginLeft: 6 }}
              />
            </div>
          ))}
      </GridRowColumn>
    </>
  )
}
