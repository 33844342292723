import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Divider } from 'semantic-ui-react'
import { useFormik, FormikProvider } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  POST_CREATE_CONTRACTOR_KEY,
  postCreateContractor,
} from '../payrollActions'
import { GEP_PER_PERSON_PRICE, getContractorName } from '../helpers'
import PayrollError from '../PayrollError'
import {
  makeReqStringSchema,
  makeNumberSchema,
  Text,
  makeDateSchema,
  Button,
  FormikInput,
  FormikRadioButton,
  FormikDateInput,
  Modal,
  Card,
  Alert,
  GridRowColumn,
  getFieldName,
  FormikLabelError,
  Icon,
  makeReqEmailSchema,
} from '../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useAppDispatch } from '../../../utils/typeHelpers'

const END_VAL = 'for this contractor'
const validationSchema = yup.object({
  first_name: yup
    .string()
    .when('type', ([type], schema) =>
      type === 'Individual'
        ? makeReqStringSchema({ field: 'first name', end: END_VAL })
        : schema
    ),
  last_name: yup
    .string()
    .when('type', ([type], schema) =>
      type === 'Individual'
        ? makeReqStringSchema({ field: 'last name', end: END_VAL })
        : schema
    ),
  email: makeReqEmailSchema({ field: 'email', end: END_VAL }),
  business_name: yup
    .string()
    .when('type', ([type], schema) =>
      type === 'Business'
        ? makeReqStringSchema({ field: 'business name', end: END_VAL })
        : schema
    ),
  ein: yup.string().when('type', ([type], schema) =>
    type === 'Business'
      ? makeNumberSchema({
          field: 'EIN',
          numDigits: 9,
          end: END_VAL,
          allowLeadingZero: true,
        })
      : schema
  ),
  hourly_rate: yup.string().when('wage_type', ([wage_type], schema) =>
    wage_type === 'Hourly'
      ? makeNumberSchema({
          field: 'hourly rate',
          end: END_VAL,
          allowedDecimals: 2,
        })
      : schema
  ),
  start_date: makeDateSchema({ field: 'start date', end: END_VAL }),
  type: makeReqStringSchema({ field: 'type', end: END_VAL }),
  wage_type: makeReqStringSchema({ field: 'wage type', end: END_VAL }),
})

const CreateContractor = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [modalOpen, setModalOpen] = useState(false)

  const formik = useFormik({
    initialValues: {
      type: undefined as 'Individual' | 'Business' | undefined,
      first_name: '',
      last_name: '',
      business_name: '',
      email: '',
      ein: '',
      hourly_rate: '',
      wage_type: undefined as 'Hourly' | 'Fixed' | undefined,
      self_onboarding: true,
      start_date: '',
    },
    validationSchema,
    onSubmit: () => setModalOpen(true),
  })

  const { values, submitForm } = formik

  const onSave = useCallback(async () => {
    if (!values.type || !values.wage_type) {
      return
    }

    const res = await postCreateContractor({
      ...values,
      type: values.type,
      wage_type: values.wage_type,
      start_date: moment(values.start_date).format(DATE_FORMATS.GUSTO_SUBMIT),
    })(dispatch)

    if (res) {
      navigate(`/payroll/contractor/${res.uuid}`)
    }
  }, [dispatch, values, navigate])

  const contractorName = getContractorName({
    first_name: values.first_name,
    last_name: values.last_name,
    type: values.type,
    business_name: values.business_name,
  })

  return (
    <Card>
      <FormikProvider value={formik}>
        <Grid>
          <GridRowColumn>
            <Text as="h1">Add a new contractor</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Alert>
              You can invite contractors to enter their information online, or
              you can do it yourself. Self-onboarding saves time and lets
              contractors access their payments and yearly tax forms online.
            </Alert>
          </GridRowColumn>
          <GridRowColumn short>
            <Text as="h2">Let&apos;s onboard your contractor!</Text>
          </GridRowColumn>
          <Divider />
          <GridRowColumn>
            <FormikLabelError
              name={getFieldName<typeof values>('type')}
              label="Contractor Type"
              required
            />
          </GridRowColumn>
          <Grid.Row className="short">
            <Grid.Column width={2}>
              <FormikRadioButton
                name={getFieldName<typeof values>('type')}
                value="Individual"
                label="Individual"
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <FormikRadioButton
                name={getFieldName<typeof values>('type')}
                value="Business"
                label="Business"
              />
            </Grid.Column>
          </Grid.Row>
          {values.type && (
            <>
              {values.type === 'Individual' && (
                <Grid.Row>
                  <Grid.Column computer={4} tablet={6} mobile={7}>
                    <FormikInput
                      name={getFieldName<typeof values>('first_name')}
                      label="First name"
                      description="Their official, legal name."
                      required
                    />
                  </Grid.Column>
                  <Grid.Column computer={4} tablet={6} mobile={7}>
                    <FormikInput
                      name={getFieldName<typeof values>('last_name')}
                      required
                      label="Last name"
                      description="Their official, legal name."
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
              {values.type === 'Business' && (
                <>
                  <GridRowColumn>
                    <FormikInput
                      name={getFieldName<typeof values>('business_name')}
                      label="Business Name"
                      required
                      description="The contractor's business name is needed to file their annual 1099 tax form."
                    />
                  </GridRowColumn>
                  <GridRowColumn>
                    <FormikInput
                      name={getFieldName<typeof values>('ein')}
                      label="EIN (Employer Identification Number)"
                      required
                    />
                  </GridRowColumn>
                </>
              )}
              <GridRowColumn width={6}>
                <FormikDateInput
                  name={getFieldName<typeof values>('start_date')}
                  label="Start Date"
                  description="Their first day of work"
                  required
                />
              </GridRowColumn>
            </>
          )}
          <Grid.Row />
          <GridRowColumn short>
            <Text as="h2">Compensation</Text>
          </GridRowColumn>
          <Divider />
          <GridRowColumn>
            <FormikLabelError
              name={getFieldName<typeof values>('wage_type')}
              required
              label="Wage Type"
              description="We can calculate the total pay when you enter this contractor's hours, or you can enter an exact dollar amount each time you pay them."
            />
          </GridRowColumn>
          <Grid.Row className="short">
            <Grid.Column width={3}>
              <FormikRadioButton
                name={getFieldName<typeof values>('wage_type')}
                value="Hourly"
                label="Hourly Rate"
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <FormikRadioButton
                name={getFieldName<typeof values>('wage_type')}
                value="Fixed"
                label="Fixed Dollar Amount"
              />
            </Grid.Column>
          </Grid.Row>
          {values.wage_type === 'Hourly' && (
            <GridRowColumn>
              <FormikInput
                name={getFieldName<typeof values>('hourly_rate')}
                label="Hourly Rate"
                description="You can change this any time"
                componentType="currency"
              />
            </GridRowColumn>
          )}
          <Grid.Row />
          <GridRowColumn short>
            <Text as="h2">Contractor Access</Text>
          </GridRowColumn>
          <Divider />
          <GridRowColumn>
            <Text>
              We recommend inviting your contractor to Gusto. This allows them
              to view their payments and download their annual 1099 tax form.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <FormikInput
              name={getFieldName<typeof values>('email')}
              type="email"
              label="Email"
              required
              fullWidth
            />
          </GridRowColumn>
          <Grid.Row />
          <Grid.Row>
            <Grid.Column width={3}>
              <Button
                variant="secondary"
                onClick={() => navigate('/payroll/employees')}
                fullWidth
              >
                Cancel
              </Button>
            </Grid.Column>
            <Grid.Column width={9} />
            <Grid.Column width={4}>
              <Button onClick={submitForm} fullWidth>
                Submit
                <Icon icon={regular('arrow-right')} style={{ marginLeft: 8 }} />
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </FormikProvider>

      <Modal
        open={modalOpen}
        size="tiny"
        closeIcon
        onClose={() => setModalOpen(false)}
      >
        <Modal.Header>Confirm New Contractor: {contractorName}</Modal.Header>
        <Modal.Content>
          <Grid>
            <PayrollError fetchKey={POST_CREATE_CONTRACTOR_KEY} />
            <GridRowColumn width={15}>
              <Text>
                You are about to add a new member to your team! Just confirming
                a couple of details.
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text style={{ marginBottom: 3 }}>Name</Text>
              <Text>{contractorName}</Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text style={{ marginBottom: 3 }}>Compensation</Text>
              <Text>
                {values.wage_type === 'Hourly'
                  ? `$${values.hourly_rate} per hour`
                  : 'Fixed'}
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <div className="price-increase-wrapper">
                <Text color="green" style={{ marginBottom: 3 }}>
                  <b>Price increase</b>
                </Text>
                <Text color="green">
                  Your Heard subscription will increase by{' '}
                  <b>${GEP_PER_PERSON_PRICE}/month.</b>
                </Text>
              </div>
            </GridRowColumn>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setModalOpen(false)} variant="secondary">
            Cancel
          </Button>
          <Button onClick={onSave}>Save</Button>
        </Modal.Actions>
      </Modal>
    </Card>
  )
}

export default CreateContractor
