import { Container, Image } from 'semantic-ui-react'

import { Text } from '../BaseComponents'

const NotFound = () => (
  <Container style={{ marginTop: '3em' }} textAlign="center">
    <Image
      src="https://heard-images.s3-us-west-1.amazonaws.com/MessyDoodle.png"
      size="large"
      centered
    />
    <Text as="h1">
      Oops! We seem to have lost this page{' '}
      <span role="img" aria-label="sad face">
        😢
      </span>
    </Text>
  </Container>
)

export default NotFound
