import axios from 'axios'
import { fetchIfNeededWrapper } from '../../../reducers/fetch'
import { FinancialProfile } from '../../../reducers/auth/userReducer'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Dictionary, keyBy, mapKeys } from 'lodash'

/* 
  When adding a new entity set RecordSnapshotEntityName 
  and RecordSnapshotEntity accordingly
*/
export enum RecordSnapshotEntityName {
  financialProfile = 'financialProfile',
}
export type RecordSnapshotEntity = FinancialProfile

export interface RecordSnapshot {
  id: number
  userId: number
  year: number
  entity: keyof typeof RecordSnapshotEntityName
  data: RecordSnapshotEntity
  createdAt: string
  updatedAt: string
}

export interface RecordSnapshotsState {
  [key: string]: RecordSnapshot
}

const initialState: RecordSnapshotsState = {}

const snapshotSlice = createSlice({
  name: 'recordSnapshots',
  initialState,
  reducers: {
    receiveRecordSnapshots: (
      state,
      action: PayloadAction<Dictionary<RecordSnapshot>>
    ) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export default snapshotSlice.reducer

export const { receiveRecordSnapshots } = snapshotSlice.actions

export const fetchUserSnapshot = (
  userId: number,
  entity: keyof typeof RecordSnapshotEntityName
) => `fetchUserSnapshot_${userId}_${entity}`

const fetchSnapshotForUser = (
  userId: number,
  entity: keyof typeof RecordSnapshotEntityName,
  year?: string
) =>
  fetchIfNeededWrapper({
    fetchKey: fetchUserSnapshot(userId, entity),
    defaultErrorMessage: 'Error fetching record snapshots',
    fetchFunction: async (dispatch) => {
      const fetchBase = `/finances/api/v1/admin/snapshots/${userId}/${entity}`
      const fetchURL = year ? `${fetchBase}/${year}` : fetchBase
      const json = await axios.get<RecordSnapshot[]>(fetchURL)
      const data = mapKeys(
        keyBy(json.data, 'id'),
        (value, _) => `${value.entity}-${value.userId}-${value.year}`
      )
      dispatch(receiveRecordSnapshots(data))
      return data
    },
  })

export const fetchFinancialProfileSnapshotForUser = ({
  userId,
  year,
}: {
  userId: number
  year?: string
}) =>
  fetchSnapshotForUser(userId, RecordSnapshotEntityName.financialProfile, year)
