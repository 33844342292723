import { useCallback, useEffect, useState } from 'react'

// BL
import { DocumentTabPane } from '.'
import { other as copy } from '../copyConstants'
import {
  closeUploadModal,
  openUploadModal,
  useFeatureDispatch,
  useFeatureState,
} from '../state'
import { TabCategoryKeys as ModalTypes } from '../constants'

// UI
import { DocumentSearchBarOnChangeData } from '../sharedComponents/DocumentsSearchBar'
import TabPaneToolbar from '../sharedComponents/TabPaneToolbar'
import UserDocumentsTable from '../sharedComponents/Table/UserDocumentsTable'
import { OtherRow } from '../sharedComponents/Table/UserDocumentsTableRow'
import UploadDocumentButton from '../sharedComponents/Buttons/UploadDocumentButton'
import FileUploadModal from '../../../components/FileUpload/FileUploadModal'
import { UploadDocumentType } from '../../../constants/businessConstants'
import { useAnalyticsTrack } from '../../Amplitude'

const Other: DocumentTabPane = ({ documents, tableOptions }) => {
  const [filteredDocuments, setFilteredDocuments] = useState(documents)
  const { activeUploadModal } = useFeatureState()
  const dispatch = useFeatureDispatch()
  const track = useAnalyticsTrack()

  useEffect(() => {
    setFilteredDocuments(documents)
  }, [documents])

  const onSearch = useCallback((data: DocumentSearchBarOnChangeData) => {
    setFilteredDocuments(data.filtered)
  }, [])

  const openModal = () => {
    track('clicked document upload', {
      document_category: ModalTypes.OTHER,
      document_subcategory: '',
    })
    dispatch?.(openUploadModal(ModalTypes.OTHER))
  }

  return (
    <>
      <TabPaneToolbar
        preamble="Upload any other documents you'd like to share with our team."
        documents={documents}
        onSearch={onSearch}
        rightElement={<UploadDocumentButton onClick={openModal} />}
      />
      <UserDocumentsTable
        columns={copy.static.tableColumns}
        documents={filteredDocuments}
        RowComponent={OtherRow}
        tableOptions={tableOptions}
      />
      <FileUploadModal
        open={activeUploadModal === ModalTypes.OTHER}
        close={() => dispatch?.(closeUploadModal())}
        documentType={UploadDocumentType.OTHER}
        userFacing
      />
    </>
  )
}

Other.paneDescription = copy.static.paneDescription
Other.paneTitle = copy.static.paneTitle

export default Other
