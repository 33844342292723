import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Icon, Link, Text } from '../../../components/BaseComponents'
import { Colors, FontWeight } from '../../../styles/theme'
import { useAnalyticsTrack } from '../../Amplitude'
import DetailsTooltip from './DetailsTooltip'

const CategorizationTooltip = ({ openOnHover }: { openOnHover?: boolean }) => {
  const track = useAnalyticsTrack()

  return (
    <DetailsTooltip
      label="Category"
      content={
        <div
          onClick={(event) => {
            // The event bubbles up to the transaction table which would sort the table.  stop it here
            event.stopPropagation()
          }}
          role="presentation"
        >
          <Text>
            Heard&apos;s bookkeeping team categorizes transactions for you at a
            regular frequency.
          </Text>
          <br />
          <Text style={{ marginBottom: 8 }}>
            If a transaction is in{' '}
            <span
              style={{
                color: Colors.orange,
                fontWeight: FontWeight.SEMIBOLD,
              }}
            >
              orange
            </span>
            , this means Heard has categorized this transaction a certain way to
            ensure tax-compliance.
          </Text>
          <br />
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/10547782596759-How-do-I-know-which-category-to-use-"
            newPage
          >
            View category glossary
            <Icon
              icon={regular('arrow-right-long')}
              style={{ marginLeft: 6 }}
              size="sm"
            />
          </Link>
        </div>
      }
      track={() => track('clicked category tooltip')}
      openOnHover={openOnHover}
    />
  )
}

export default CategorizationTooltip
