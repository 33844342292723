import { useEffect } from 'react'
import PageHeader from '../../../components/shared/PageHeader'
import { UpriseIframe } from '../UpriseIframe'
import { useAnalyticsView } from '../../Amplitude'

const InboxPanel = () => {
  const pageView = useAnalyticsView()
  useEffect(() => {
    pageView('advisory inbox panel')
  }, [pageView])

  return (
    <>
      <PageHeader header="Financial Advisory" />
      <UpriseIframe page="inbox" />
    </>
  )
}

export default InboxPanel
