import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TaxListOptionId, TaxListQuestionId } from './service'
import { omitBy } from 'lodash'

export interface TaxChecklistQuestionOption {
  id: TaxListOptionId
  text: string
  taxfyleOptionId: string
  createdAt: string
  updatedAt: string
}

export interface TaxChecklistResponse {
  id: number
  userId: number
  annualTaxFilingFormId: number
  questionId: TaxListQuestionId
  value: boolean | number | string | string[]
  childcareProviderId: number | null
  userDependentId: number | null
  groupId: string | null
  createdAt: string
  updatedAt: string
}

export enum TaxChecklistQuestionType {
  BOOL = 'BOOL',
  NUMERIC = 'NUMERIC',
  TEXT = 'TEXT',
  DATE_TIME = 'DATE_TIME',
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
}

export interface TaxChecklistQuestion {
  id: TaxListQuestionId
  text: string
  taxfyleQuestionId: string | null
  questionType: TaxChecklistQuestionType
  options: string[]
  responses: number[]
  createdAt: string
  updatedAt: string
}

const initialState = {
  questions: {} as { [key: string]: TaxChecklistQuestion },
  options: {} as { [key: string]: TaxChecklistQuestionOption },
  responses: {} as { [key: number]: TaxChecklistResponse },
}

export interface TaxChecklistQuestionsState {
  questions: { [key: string]: TaxChecklistQuestion }
  options: { [key: string]: TaxChecklistQuestionOption }
  responses: { [key: number]: TaxChecklistResponse }
}

const annualTaxQuestionnaireQuestionSlice = createSlice({
  name: 'annualTaxQuestionnaireQuestions',
  initialState,
  reducers: {
    setTaxChecklistQuestionsAfterDeletion: (
      state,
      action: PayloadAction<Partial<TaxChecklistQuestionsState>>
    ) => {
      state.questions = { ...state.questions, ...action.payload.questions }
      state.options = { ...state.options, ...action.payload.options }
      //remove responses for any questions that have had their responses deleted
      state.responses = omitBy(
        state.responses,
        (response) =>
          action.payload.questions?.[response.questionId] &&
          !action.payload.responses?.[response.id]
      )
    },
    setTaxChecklistQuestions: (
      state,
      action: PayloadAction<Partial<TaxChecklistQuestionsState>>
    ) => {
      state.questions = { ...state.questions, ...action.payload.questions }
      state.responses = { ...state.responses, ...action.payload.responses }
      state.options = { ...state.options, ...action.payload.options }
    },
    resetTaxChecklistQuestions: () => initialState,
  },
})

export default annualTaxQuestionnaireQuestionSlice.reducer
export const {
  setTaxChecklistQuestions,
  resetTaxChecklistQuestions,
  setTaxChecklistQuestionsAfterDeletion,
} = annualTaxQuestionnaireQuestionSlice.actions
