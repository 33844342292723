import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { getUserByFinancialProfileId } from '../../../selectors/user.selectors'
import {
  adminUpdateStatement,
  adminUpdateUserDocument,
} from '../../../actions/admin/userDocumentsActions'
import { UserDocument } from '../../UserDocuments/userDocuments.slice'
import { Button, Input, Modal, Text } from '../../../components/BaseComponents'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../OpenFeature'
import { UploadDocumentType } from '../../../constants/businessConstants'
import { useReselector } from '../../../utils/sharedHooks'

interface Props {
  modalOpen: boolean
  setModalOpen: (open: boolean) => void
  document: UserDocument
}

const RenameDocumentModal = ({ modalOpen, setModalOpen, document }: Props) => {
  const dispatch = useAppDispatch()
  const user = useReselector(
    getUserByFinancialProfileId,
    document.financialProfileId
  )

  const [newFileName, setNewFileName] = useState(document.fileDescription)

  const releaseStatementUpload = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.multiStatementUpload,
    false
  )

  useEffect(() => {
    setNewFileName(document.fileDescription)
  }, [document.fileDescription])

  const handleRename = useCallback(async () => {
    if (
      releaseStatementUpload &&
      document.documentType === UploadDocumentType.STATEMENT
    ) {
      await dispatch(
        adminUpdateStatement(document.id, {
          fileDescription: newFileName,
        })
      )
    } else if (user?.id) {
      await dispatch(
        adminUpdateUserDocument(document.id, {
          fileDescription: newFileName,
        })
      )
    }
    setModalOpen(false)
  }, [
    dispatch,
    document.id,
    newFileName,
    setModalOpen,
    user?.id,
    releaseStatementUpload,
    document.documentType,
  ])

  return (
    <Modal
      size="small"
      dimmer="inverted"
      open={modalOpen}
      onClose={() => setModalOpen(!modalOpen)}
      closeIcon
    >
      <Modal.Header>Rename File</Modal.Header>

      <Modal.Content>
        <Text as="bodyLg">Existing File Name: {document.fileDescription}</Text>
        <Input
          placeholder="Type new file name"
          label="New File Name"
          value={newFileName || ''}
          onChange={(value) => setNewFileName(value)}
          required
        />
      </Modal.Content>
      <Modal.Actions
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button
          variant="secondary"
          onClick={() => {
            setModalOpen(!modalOpen)
          }}
        >
          Cancel
        </Button>

        <Button color="red" onClick={handleRename}>
          Rename
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default RenameDocumentModal
