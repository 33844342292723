import { UPDATE_USER_KEY, updateUser } from '../../actions/userActions'
import {
  Button,
  Checkbox,
  GridRowColumn,
  Link,
  Modal,
  Text,
} from '../BaseComponents'
import { useEffect, useState } from 'react'
import { useAnalyticsTrack } from '../../features/Amplitude'
import { TosVersion } from '../../constants/businessConstants'
import { Colors } from '../../styles/theme'
import { Grid, Message } from 'semantic-ui-react'
import { getFetchError, getIsFetching } from '../../reducers/fetch'
import { useReselector } from '../../utils/sharedHooks'
import { useAppDispatch } from '../../utils/typeHelpers'

interface Props {
  currentUserId: number
}

const TermsOfServiceModal = ({ currentUserId }: Props) => {
  const dispatch = useAppDispatch()
  const [isAccepted, setIsAccepted] = useState(false)
  const track = useAnalyticsTrack()

  useEffect(() => {
    track('viewed terms of service update modal')
  }, [track])

  const isUpdating = useReselector(getIsFetching, UPDATE_USER_KEY)
  const error = useReselector(getFetchError, UPDATE_USER_KEY)

  const onClick = async () => {
    track('clicked terms of service update modal agree button')
    const data = {
      tosVersion: TosVersion.tos_01_2024,
      tosAcceptedOn: Date.now(),
    }
    /* Update user — don't need to do anything after because the returned user will close the modal */
    await updateUser(currentUserId, data)(dispatch)
  }

  return (
    <Modal open size="small">
      <Modal.Header>
        Updates to Terms of Service and Privacy Policy
      </Modal.Header>
      <Modal.Content>
        <Grid
          style={{
            backgroundColor: Colors.stone,
            borderRadius: 4,
            margin: 'auto',
            padding: 16,
          }}
        >
          <GridRowColumn>
            <Text>
              We updated our Terms of Service and Privacy Policy, effective
              January 1, 2024. Please review and accept them to continue using
              Heard.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>You can review them here:</Text>

            <ul style={{ color: Colors.green, paddingTop: 10, lineHeight: 2 }}>
              <li>
                <Link newPage href="https://www.joinheard.com/terms-of-service">
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link newPage href="https://www.joinheard.com/privacy">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </GridRowColumn>
        </Grid>
        <Grid>
          <GridRowColumn>
            <Checkbox
              variant={'default'}
              value={false}
              onChange={(value) => setIsAccepted(value)}
              label={
                <Text>
                  I accept the updated Terms of Service and Privacy Policy
                </Text>
              }
            />
          </GridRowColumn>
          {error && (
            <GridRowColumn>
              <Message error>
                <Text>There was an error saving. Please try again.</Text>
              </Message>
            </GridRowColumn>
          )}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={onClick}
          disabled={!isAccepted || isUpdating}
          loading={isUpdating}
          style={{ marginLeft: 0 }}
        >
          I Accept
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default TermsOfServiceModal
