import { useMemo } from 'react'
import { UserDocument } from '../../userDocuments.slice'
import { Table } from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectAdminOrUserGroupedFinancialAccountOptions } from '../../../../selectors/financeSelectors'
import { getCurrentUser } from '../../../../selectors/user.selectors'
import { useGroupedDropdownLabel } from '../../../../components/shared/groupedDropdownHelpers'

interface DocumentFinancialAccountCellProps {
  document: UserDocument
}

const DocumentMultipleFinancialAccountCell = ({
  document,
}: DocumentFinancialAccountCellProps) => {
  const user = useReselector(getCurrentUser)

  const financialAccountOptions = useReselector(
    selectAdminOrUserGroupedFinancialAccountOptions,
    user?.id
  )

  const statementAccountIds = useMemo(() => {
    const statementAccounts =
      document.statement?.statementFinancialAccounts || []
    if (!statementAccounts.length) return []
    return statementAccounts.map((statement) => statement.financialAccountId)
  }, [document.statement])

  const { text } = useGroupedDropdownLabel(
    statementAccountIds,
    financialAccountOptions
  )

  return (
    <Table.Cell>
      <b>{document.id !== -1 && text}</b>
    </Table.Cell>
  )
}

export default DocumentMultipleFinancialAccountCell
