import { Grid } from 'semantic-ui-react'

import {
  Button,
  Modal,
  GridRowColumn,
  Alert,
  FormikDropdown,
  getFieldName,
  FormikInput,
} from '../../../components/BaseComponents'
import { FETCH_PAYROLL_PROFILE_KEY, putPayrollProfile } from '../payrollActions'
import { FormikProvider, useFormik } from 'formik'
import { selectPayrollProfile } from '../payroll.selectors'
import { useReselector } from '../../../utils/sharedHooks'
import { getFetchError, getIsFetching } from '../../../reducers/fetch'
import {
  NEXT,
  OTHER,
  POLICY_OPTIONS,
} from '../payrollWcEnrollmentRequirement.slice'
import { YesNoOptions } from '../../Taxes/taxConstants'
import { useAppDispatch } from '../../../utils/typeHelpers'

const WorkersCompModal = ({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) => {
  const dispatch = useAppDispatch()
  const payrollProfile = useReselector(selectPayrollProfile)
  const error = useReselector(getFetchError, FETCH_PAYROLL_PROFILE_KEY)
  const isSubmitting = useReselector(getIsFetching, FETCH_PAYROLL_PROFILE_KEY)
  const formik = useFormik({
    initialValues: {
      wcEnrolled: payrollProfile?.wcEnrolled,
      wcEnrollmentStartedAt: payrollProfile?.wcEnrollmentStartedAt,
      wcEnrollmentCompletedAt: payrollProfile?.wcEnrollmentCompletedAt,
      wcEnrollmentPolicyDetails: payrollProfile?.wcEnrollmentPolicyDetails,
      wcProvider: payrollProfile?.wcProvider !== NEXT ? OTHER : NEXT,
      wcProviderInput:
        payrollProfile?.wcProvider !== NEXT ? payrollProfile?.wcProvider : '',
    },
    onSubmit: async ({ wcEnrolled, wcProvider, wcProviderInput }) => {
      if (payrollProfile) {
        const res = await putPayrollProfile({
          id: payrollProfile.id,
          wcEnrolled,
          // If the WC provider is not next, we ask for free input
          wcProvider: wcProvider === NEXT ? wcProvider : wcProviderInput,
          // If user indicates to us that they've enrolled in WC, we set started and completed date time
          wcEnrollmentStartedAt: wcEnrolled ? new Date().toISOString() : null,
          wcEnrollmentCompletedAt: wcEnrolled ? new Date().toISOString() : null,
        })(dispatch)
        if (res) {
          close()
        }
      }
    },
  })
  const { values, submitForm } = formik
  return (
    <FormikProvider value={formik}>
      <Modal open={open} size="small" closeIcon onClose={close}>
        <Modal.Header>Update Your Workers Compensation Status</Modal.Header>
        <Modal.Content>
          <Grid>
            {error && (
              <GridRowColumn>
                <Alert>{error.message}</Alert>
              </GridRowColumn>
            )}
            <GridRowColumn>
              <FormikDropdown
                label="Do you have an existing workers compensation policy?"
                placeholder="Select an option"
                name={getFieldName<typeof values>('wcEnrolled')}
                options={YesNoOptions}
                clearable
                fullWidth
                required
              />
            </GridRowColumn>
            {values.wcEnrolled && (
              <>
                <GridRowColumn>
                  <FormikDropdown
                    name={getFieldName<typeof formik.values>('wcProvider')}
                    label="Select your policy provider"
                    placeholder="Select an option"
                    options={POLICY_OPTIONS}
                    clearable
                    fullWidth
                    required
                  />
                </GridRowColumn>
                {values.wcProvider !== NEXT && (
                  <GridRowColumn>
                    <FormikInput
                      name="wcProviderInput"
                      label="Let us know your workers compensation policy provider"
                      placeholder="Input your policy provider"
                      fullWidth
                    />
                  </GridRowColumn>
                )}
              </>
            )}
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={close} variant="actionLink">
            Cancel
          </Button>
          <Button onClick={submitForm} variant="primary" loading={isSubmitting}>
            Update
          </Button>
        </Modal.Actions>
      </Modal>
    </FormikProvider>
  )
}

export default WorkersCompModal
