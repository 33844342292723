import ReactDatePicker from 'react-datepicker'
import { Grid } from 'semantic-ui-react'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'
import moment from 'moment'

import { GridRowColumn, Text } from '../../../components/BaseComponents'
import { Colors, Fonts } from '../../../styles/theme'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { Gusto_Payroll } from '../generated_gusto_types'

const PAY_PERIOD_COLOR = 'blush'
const DEADLINE_COLOR = 'orange'
const PAYDAY_COLOR = 'teal'

const Calendar = styled(
  ({
    payroll: {
      pay_period: { start_date, end_date },
    },
    className,
    monthsShown,
  }: {
    payroll: Gusto_Payroll
    className?: string
    monthsShown: number
  }) => (
    <ReactDatePicker
      calendarClassName={className}
      onChange={() => {
        // This function is required by date picker but we don't need to do selecting
      }}
      disabled
      inline
      // Set so the calendar knows which date start displaying at
      selected={moment(start_date, DATE_FORMATS.GUSTO_SUBMIT).toDate()}
      startDate={moment(start_date, DATE_FORMATS.GUSTO_SUBMIT).toDate()}
      endDate={moment(end_date, DATE_FORMATS.GUSTO_SUBMIT).toDate()}
      renderCustomHeader={({ monthDate }) => (
        <Text as="h3">{moment(monthDate).format('MMMM')}</Text>
      )}
      monthsShown={monthsShown}
      dayClassName={(date) => `day-${moment(date).format('MM-DD')}`}
    />
  )
)(({ payroll: { payroll_deadline, check_date } }) => {
  const deadlineDay = moment(
    payroll_deadline,
    DATE_FORMATS.GUSTO_SUBMIT
  ).format('MM-DD')
  const checkDay = moment(check_date, DATE_FORMATS.GUSTO_SUBMIT).format('MM-DD')

  return {
    // This calendar should not be selectable
    pointerEvents: 'none',
    border: 'none',
    borderRadius: 8,
    backgroundColor: Colors.transparent,
    ...Fonts.eyebrow,

    '.react-datepicker__header': {
      backgroundColor: Colors.transparent,
    },

    '.react-datepicker__day-name': {
      color: Colors.mediumGray,
      // Padding adjusted here to match dates
      width: 21,
      margin: '0 6px 2px 0',
    },

    '.react-datepicker__day': {
      color: Colors.black,
      // Padding adjusted so there aren't spaces in selections
      margin: '2px 0',
      width: 26,
      height: 26,
      paddingTop: 2,

      '&.react-datepicker__day--in-range': {
        backgroundColor: Colors[PAY_PERIOD_COLOR],
        borderRadius: 0,
      },

      '&.react-datepicker__day--outside-month': {
        color: Colors.mediumGray,
      },

      // Prevents marking days on the wrong month
      '&:not(.react-datepicker__day--outside-month)': {
        [`&.day-${deadlineDay}`]: {
          backgroundColor: Colors[DEADLINE_COLOR],
          color: Colors.white,
          borderRadius: 2,
        },

        [`&.day-${checkDay}`]: {
          backgroundColor: Colors[PAYDAY_COLOR],
          color: Colors.white,
          borderRadius: 2,
        },
      },

      // Spacing changes cause empty cells to mess wth padding
      '&:empty': {
        height: 0,
        padding: 0,
      },
    },
  }
})

const LegendMarker = styled(
  ({ className }: { className?: string; color: keyof typeof Colors }) => (
    <div className={className} />
  )
)(({ color }) => ({
  height: 10,
  width: 10,
  backgroundColor: Colors[color],
  borderRadius: 2,
  marginRight: 10,
}))

const LegendItem = ({
  text,
  color,
}: {
  text: string
  color: keyof typeof Colors
}) => (
  <GridRowColumn short columnStyle={{ display: 'flex', alignItems: 'center' }}>
    <LegendMarker color={color} />
    <Text as="eyebrow">{text}</Text>
  </GridRowColumn>
)

const PayrollCalendar = ({ payroll }: { payroll: Gusto_Payroll }) => {
  const {
    pay_period: { start_date, end_date },
    payroll_deadline,
    check_date,
  } = payroll

  const monthDiff = moment(check_date, DATE_FORMATS.GUSTO_SUBMIT)
    .endOf('months')
    .diff(
      moment(start_date, DATE_FORMATS.GUSTO_SUBMIT).startOf('months'),
      'months'
    )

  return (
    <Grid textAlign="left">
      <Grid.Row className="short" />
      <GridRowColumn>
        <Text>Pay period</Text>
      </GridRowColumn>
      <GridRowColumn short>
        <Text as="h3">
          {moment(start_date, DATE_FORMATS.GUSTO_SUBMIT).format(
            DATE_FORMATS.DISPLAY_SHORT
          )}{' '}
          -{' '}
          {moment(end_date, DATE_FORMATS.GUSTO_SUBMIT).format(
            DATE_FORMATS.DISPLAY_SHORT
          )}
        </Text>
      </GridRowColumn>
      <Grid.Row className="short">
        <Grid.Column width={monthDiff ? 11 : 7}>
          <Calendar payroll={payroll} monthsShown={monthDiff ? 2 : 1} />
        </Grid.Column>
        <Grid.Column width={5}>
          <Grid>
            <GridRowColumn>
              <Text>Dates of interest</Text>
            </GridRowColumn>
            <LegendItem text="Payroll period" color={PAY_PERIOD_COLOR} />
            <LegendItem
              text={`${moment(
                payroll_deadline,
                DATE_FORMATS.GUSTO_SUBMIT
              ).format(DATE_FORMATS.MONTH_DAY)}: Payroll Deadline`}
              color={DEADLINE_COLOR}
            />
            <LegendItem
              text={`${moment(check_date, DATE_FORMATS.GUSTO_SUBMIT).format(
                DATE_FORMATS.MONTH_DAY
              )}: Payday`}
              color={PAYDAY_COLOR}
            />
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default PayrollCalendar
