import moment from 'moment'

import { getStore } from '../../../store'
import { getUserActionItemByActionItemIdentifier } from './userActionItems.selectors'
import {
  fetchUserActionItemsIfNeeded,
  updateUserActionItem,
} from './userActionItems.slice'
import { Attributes } from '../../Amplitude'

// Intended as a convenience method to keep fetching, selecting, and updating centralized
// Called from areas of App (outside of the todo component) when marking a user_action_item as complete.
// With this, we avoid having to add fetchIfNeeded, a selector query, and update logic to each component
export const markUserActionItemCompleteIfExists = async (
  identifier: string,
  track?: (event: string, properties: Attributes) => void,
  status?: string
) => {
  const store = getStore()
  if (!store) {
    return
  }

  await fetchUserActionItemsIfNeeded()(store?.dispatch, store.getState)
  const state = store.getState()

  const actionItem = getUserActionItemByActionItemIdentifier(state, identifier)

  if (actionItem && (status || !actionItem.completedAt)) {
    await updateUserActionItem(actionItem.id, {
      id: actionItem.id,
      ...(!actionItem.completedAt && { completedAt: moment().toJSON() }),
      ...(status && { status }),
    })(store.dispatch)
    track?.('completed todo', {
      completed_source: 'auto-completed',
      todo_description: actionItem.actionItem.description,
    })
  }
}
