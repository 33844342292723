import { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'

import {
  Dropdown,
  Text,
  GridRowColumn,
  Input,
  valsToDropdownOptions,
} from '../../../../../components/BaseComponents'
import { TAX_ENTITY_TYPES } from '../../../taxConstants'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxDetails } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import AdminTaxChecklistTable from './table'
import { useAppDispatch } from '../../../../../utils/typeHelpers'

const AdminTaxChecklist = () => {
  const [formSelection, setFormSelection] = useState<TAX_ENTITY_TYPES>()
  const [searchValue, setSearchValue] = useState<string>()
  const taxYear = useReselector(selectCurrentAnnualTaxDetails)?.taxYear
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
  }, [dispatch])

  return (
    <>
      <Grid>
        <GridRowColumn>
          <Text as="h1">{`${taxYear} Tax Checklist`}</Text>
        </GridRowColumn>
        <GridRowColumn>
          <Dropdown
            clearable
            options={valsToDropdownOptions([
              TAX_ENTITY_TYPES.form_1040,
              TAX_ENTITY_TYPES.form_1120_s,
            ])}
            placeholder="Filter by form type"
            value={formSelection}
            onChange={setFormSelection}
          />
        </GridRowColumn>
        <GridRowColumn>
          <Input
            clearable
            leftIcon="search"
            placeholder="Search"
            value={searchValue}
            onChange={setSearchValue}
          />
        </GridRowColumn>
      </Grid>

      <AdminTaxChecklistTable
        formSelection={formSelection}
        searchValue={searchValue}
      />
    </>
  )
}

export default AdminTaxChecklist
