import axios from 'axios'

import { Dispatch, GetState, ReduxState } from '../utils/typeHelpers'
import {
  FinancialProfile,
  receiveFinancialProfile,
} from '../reducers/auth/userReducer'
import { fetchWrapper } from '../reducers/fetch'

export const shouldFetchFinancialProfile = (state: ReduxState) =>
  !state.auth.user.financialProfile

export const FETCH_FINANCIAL_PROFILE_KEY = 'FETCH_FINANCIAL_PROFILE_KEY'

export const fetchFinancialProfile = () =>
  fetchWrapper({
    fetchKey: FETCH_FINANCIAL_PROFILE_KEY,
    fetchFunction: (dispatch: Dispatch) =>
      axios
        .get<FinancialProfile>('/finances/api/v1/financial_profiles')
        .then((json) => {
          dispatch(receiveFinancialProfile(json.data))
        })
        .catch((err) => err),
  })

export const fetchFinancialProfileIfNeeded =
  () => (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchFinancialProfile(getState())) {
      return dispatch(fetchFinancialProfile())
    }
    return Promise.resolve()
  }

export const UPDATE_FINANCIAL_PROFILE_KEY = (id?: number | string) =>
  `UPDATE_FINANCIAL_PROFILE_KEY_${id ? id.toString() : ''}`
export const updateFinancialProfile = (
  id?: number | string,
  data?: Partial<FinancialProfile>
) =>
  fetchWrapper({
    fetchKey: UPDATE_FINANCIAL_PROFILE_KEY(id),
    fetchFunction: async (dispatch: Dispatch) => {
      if (!id) {
        return undefined
      }

      const json = await axios.post<FinancialProfile>(
        `/finances/api/v1/financial_profiles/${id.toString()}`,
        data
      )

      dispatch(receiveFinancialProfile(json.data))
      return json.data
    },
  })
