import { Grid, Image } from 'semantic-ui-react'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import {
  Accordion,
  GridRowColumn,
  Text,
} from '../../../components/BaseComponents'
import { useAnalyticsView } from '../../Amplitude'
import { useEffect } from 'react'
import { FEATURE_FLAG_KEYS } from '../../OpenFeature'

export const IntroPage = () => {
  const currentYear = new Date().getFullYear()
  const pageView = useAnalyticsView()
  const enableLowerFrictionStatements = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableLowerFrictionStatements,
    false
  )

  useEffect(() => {
    pageView('statements splash')
  }, [pageView])

  return (
    <Grid>
      <Grid.Row style={{ marginTop: 16 }}>
        <Grid.Column width={5}>
          <Image
            src={'https://heard-images.s3.amazonaws.com/assets/statement.svg'}
            style={{
              padding: 20,
              backgroundColor: '#E4F2E6',
            }}
          />
        </Grid.Column>
        <Grid.Column
          width={8}
          style={{
            marginLeft: 0,
          }}
        >
          <GridRowColumn
            style={{
              marginLeft: 0,
            }}
          >
            <Text as="h2">Share your monthly bank statements</Text>
            <br />
            <Text>
              Next, we&apos;ll ask you to share monthly bank statements for each
              connected bank account/credit card for all of {currentYear}. These
              are needed for essential accounting procedures.
            </Text>
          </GridRowColumn>
          <GridRowColumn
            style={{
              marginTop: 30,
            }}
          >
            <Accordion
              title="Why do I need to do this even though I connected my bank account?"
              content={
                <Text>
                  Connecting your account doesn&apos;t automatically grant Heard
                  access to your bank statements. Common reasons we may not have
                  access:
                  <ul>
                    <li>
                      Your bank doesn&apos;t allow Heard access to your
                      statements
                    </li>
                    <li>
                      You declined access to your statements when connecting
                      your account
                    </li>
                  </ul>
                </Text>
              }
              variant="text"
              style={{
                marginTop: 16,
                marginBottom: 16,
              }}
            />
            <Accordion
              title="Why does Heard need monthly statements?"
              content={
                enableLowerFrictionStatements ? (
                  <Text>
                    We need your bank statements to get your books set up and
                    reconciled. In the future, we&apos;ll reconcile
                    automatically when possible and will only need bank
                    statements if there&apos;s a Plaid connection error.
                  </Text>
                ) : (
                  <Text>
                    Each month, your bookkeeper compares your bank statements to
                    your bank transactions in Heard to ensure completeness and
                    accuracy.
                  </Text>
                )
              }
              variant="text"
            />
          </GridRowColumn>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
