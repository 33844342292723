import { useMemo, useState } from 'react'
import { Button, Confirm } from 'semantic-ui-react'
import styled from 'styled-components'

import {
  adminBulkDeleteTransaction,
  BulkUpdatePayload,
  bulkUpdateUserTransactions,
} from '../../../actions/adminActions'
import BulkUpdateTransactionModal from '../BulkUpdateTransactionModal'
import BulkConfirmTransactionModal from '../BulkConfirmTransactionModal'
import {
  clearSelectedTransactions,
  getSelectedTransactionIds,
} from '../../../reducers/admin/selectedTransactionsReducer'
import RuleCreationModal from '../../../features/Admin/TransactionRules'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { useReselector } from '../../../utils/sharedHooks'
import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { TransactionRuleCategoryType } from '../../../reducers/admin/transactionRulesReducer'

const Wrapper = styled.div`
  border-radius: 5px;
  display: flex;
  align-items: center;
  align-content: center;
  position: fixed;
  bottom: 60px;
  z-index: 10000;
  background-color: white;
  box-shadow:
    0 0 1px 0 rgba(2, 32, 71, 0.08),
    0 8px 10px 0 rgba(2, 32, 71, 0.1);
  right: 40px;
  padding: 20px 15px;
`

export type OnActionParams =
  | {
      type: 'delete'
      transactionIds: number[]
    }
  | {
      type:
        | 'unconfirm'
        | 'confirm'
        | 'exclude'
        | 'needsClarification'
        | 'update'
      transactions: Transaction[]
    }
const BulkTransactionActions = ({
  selectedTransactions,
  onAction = (_: OnActionParams) => undefined,
}: {
  selectedTransactions?: Transaction[]
  onAction?: (params: OnActionParams) => void
}) => {
  const dispatch = useAppDispatch()
  const [excludeOpen, setExcludeOpen] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [unconfirmOpen, setUnconfirmOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [ruleModalOpen, setRuleModalOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const user = useReselector(getCurrentUser)
  const selectedTransactionKeys = useReselector(getSelectedTransactionIds)
  const someUnconfirmed = useMemo(
    () => selectedTransactions?.some((tx) => !tx.confirmedAt) ?? true,
    [selectedTransactions]
  )
  const someConfirmed = useMemo(
    () => selectedTransactions?.some((tx) => tx.confirmedAt) ?? false,
    [selectedTransactions]
  )

  const updateTransactions = async (
    updateType:
      | 'unconfirm'
      | 'confirm'
      | 'exclude'
      | 'needsClarification'
      | 'delete'
      | 'update'
  ) => {
    if (updateType === 'delete') {
      await adminBulkDeleteTransaction(selectedTransactionKeys)(dispatch)
      onAction({ type: 'delete', transactionIds: selectedTransactionKeys })
    } else {
      const data: BulkUpdatePayload = {
        transactionIds: selectedTransactionKeys,
      }

      if (updateType === 'unconfirm') {
        data.confirmedAt = null
      } else if (updateType === 'confirm') {
        data.confirmedAt = Date.now()
        data.requestedClarificationAt = null
        data.notifiedRequestedClarificationAt = null
      } else if (updateType === 'exclude') {
        data.excludedAt = Date.now()
        data.type = TransactionRuleCategoryType.personal
      } else if (updateType === 'needsClarification') {
        data.requestedClarificationAt = Date.now()
      }
      const updatedTransactions =
        await bulkUpdateUserTransactions(data)(dispatch)
      if (updatedTransactions) {
        onAction({ type: updateType, transactions: updatedTransactions })
      }
    }

    setExcludeOpen(false)
    setConfirmOpen(false)
    setDeleteOpen(false)
    setUnconfirmOpen(false)
    dispatch(clearSelectedTransactions())
  }

  const count = selectedTransactionKeys.length

  if (count > 0) {
    return (
      <Wrapper>
        <p style={{ marginBottom: 0, fontWeight: 700, paddingRight: '10px' }}>
          {count} items selected
        </p>
        {Boolean(selectedTransactions) && (
          <>
            <Button
              onClick={() => setUnconfirmOpen(true)}
              size="small"
              disabled={someUnconfirmed}
            >
              Bulk Unconfirm
            </Button>
            <Confirm
              size="mini"
              cancelButton="Cancel"
              confirmButton="Unconfirm"
              content="Are you sure you want to unconfirm these transactions?"
              open={unconfirmOpen}
              onCancel={() => setUnconfirmOpen(false)}
              onConfirm={() => updateTransactions('unconfirm')}
            />
          </>
        )}
        <Button
          onClick={() => setConfirmOpen(true)}
          size="small"
          disabled={someConfirmed}
        >
          Bulk Confirm
        </Button>
        {confirmOpen && (
          <BulkConfirmTransactionModal
            selectedTransactions={
              selectedTransactions ?? selectedTransactionKeys
            }
            open={confirmOpen}
            onConfirm={() => updateTransactions('confirm')}
            onClose={() => setConfirmOpen(false)}
          />
        )}

        <Button
          onClick={() => setModalOpen(true)}
          size="small"
          disabled={someConfirmed}
        >
          Bulk Update
        </Button>

        <BulkUpdateTransactionModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onApply={(updatedTransactions) => {
            if (updatedTransactions) {
              onAction({ type: 'update', transactions: updatedTransactions })
            }
            setRuleModalOpen(true)
          }}
          selectedTransactionKeys={selectedTransactionKeys}
        />

        <Button
          onClick={() => setExcludeOpen(true)}
          size="small"
          disabled={someConfirmed}
        >
          Bulk Exclude
        </Button>
        <Confirm
          size="mini"
          cancelButton="Cancel"
          confirmButton="Exclude Transactions"
          content="Are you sure you want to exclude these transactions? This will set the expense type to personal and remove it from reports."
          open={excludeOpen}
          onCancel={() => setExcludeOpen(false)}
          onConfirm={() => updateTransactions('exclude')}
        />

        <Button
          onClick={() => updateTransactions('needsClarification')}
          size="small"
          disabled={someConfirmed}
        >
          Mark Needs Clarification
        </Button>

        <Button onClick={() => setDeleteOpen(true)} size="small">
          Bulk Delete
        </Button>

        <Confirm
          size="mini"
          cancelButton="Cancel"
          confirmButton="Delete Transactions"
          content="Are you sure you want to delete these transactions?"
          open={deleteOpen}
          onCancel={() => setDeleteOpen(false)}
          onConfirm={() => updateTransactions('delete')}
        />
      </Wrapper>
    )
  }
  return (
    <>
      <span />
      {user?.id && (
        <RuleCreationModal
          modalOpen={ruleModalOpen}
          setModalOpen={setRuleModalOpen}
          userId={user.id}
        />
      )}
    </>
  )
}

export default BulkTransactionActions
