import { Button, Modal, Header } from 'semantic-ui-react'

import { getTransactionsByIdSelector } from '../../selectors/financeSelectors'
import { Transaction } from '../../reducers/admin/allTransactions.slice'
import { useReselector } from '../../utils/sharedHooks'

const isTransactionArray = (
  transactions: number[] | Transaction[]
): transactions is Transaction[] => {
  return transactions.length > 0 && typeof transactions[0] !== 'number'
}

const BulkConfirmTransactionModal = ({
  open,
  onClose,
  selectedTransactions,
  onConfirm,
}: {
  open: boolean
  onClose: () => void
  selectedTransactions: number[] | Transaction[]
  onConfirm: () => Promise<void>
}) => {
  const selectedTransactionKeys = isTransactionArray(selectedTransactions)
    ? []
    : selectedTransactions
  const transactions = useReselector(
    getTransactionsByIdSelector,
    selectedTransactionKeys
  )

  const invalidTransactions = (
    isTransactionArray(selectedTransactions)
      ? selectedTransactions
      : transactions
  ).filter(
    (transaction) =>
      (!transaction.type || !transaction.transactionCategoryId) &&
      transaction.type !== 'personal'
  )

  return (
    <Modal
      size={'small'}
      dimmer={'inverted'}
      className="bulkUpdateTransactionModal"
      open={open}
      onClose={onClose}
    >
      <Modal.Content>
        <Header as="h3">
          Confirm Transactions: {selectedTransactions.length} transactions
          selected
        </Header>
        <div>
          <Header as="h4">
            <span role="img" aria-label="magnifying-glass">
              🔎
            </span>{' '}
            Transactions Check:{' '}
          </Header>
          {invalidTransactions.length === 0 && (
            <p>Good job! All transactions are valid.</p>
          )}
          {invalidTransactions.map(({ id }) => (
            <li key={id}>Transaction {id} is invalid</li>
          ))}
          <br />
          <p>
            Please categorize the above categorizations before confirming the
            transactions.
          </p>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          positive
          onClick={onConfirm}
          disabled={invalidTransactions.length > 0}
        >
          Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default BulkConfirmTransactionModal
