import { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, Header, Form } from 'semantic-ui-react'

import {
  CREATE_ADMIN_USER_FINANCIAL_INSIGHTS_KEY,
  createAdminUserFinancialInsights,
} from '../../../actions/adminFinancialInsightActions'
import { User } from '../../../reducers/auth/userReducer'
import { Dispatch, ReduxState, wrapDispatch } from '../../../utils/typeHelpers'
import { Alert, DatePicker } from '../../BaseComponents'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { getFetchError } from '../../../reducers/fetch'

interface ParentProps {
  user: User
  open: boolean
  close: () => void
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = ParentProps & StateProps & DispatchProps

interface State {
  title: string
  description: string
  active: boolean
  month: string
  validated: boolean
}

class FinancialInsightsForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      title: '',
      description: '',
      month: '',
      active: false,
      validated: false,
    }
  }

  submit = async () => {
    const validated = this.validateForm()
    if (validated) {
      const res = await this.props.createAdminUserFinancialInsights(
        this.props.user.id,
        {
          userId: this.props.user.id,
          title: this.state.title,
          description: this.state.description,
          month: this.state.month,
          active: this.state.active,
        }
      )
      if (res) {
        this.setState({ validated: false })
        this.props.close()
      }
    }
  }

  validateForm = () => {
    this.setState({ validated: true })
    return !(!this.state.title || !this.state.description || !this.state.active)
  }

  renderErrors() {
    if (this.props.error) {
      return <Alert type="error">{this.props.error.message}</Alert>
    }
    return null
  }

  render() {
    const disabled =
      !this.state.title || !this.state.description || !this.state.active
    return (
      <Modal
        size={'small'}
        dimmer={'inverted'}
        className="financialInsightsForm"
        open={this.props.open}
        onClose={this.props.close}
      >
        <Modal.Content>
          <Header as="h4">
            Create a New Financial Insight for {this.props.user?.firstName}{' '}
            {this.props.user?.lastName}
          </Header>
          <p>
            As a reminder, this insight will show up AS IS on the user&#39;s
            Dashboard. Double check for accuracy and typos!
          </p>
          {this.renderErrors()}

          <Form>
            <Form.Field required>
              <Form.Input
                required
                placeholder="Title"
                name="title"
                label="Title"
                value={this.state.title}
                error={this.state.validated && !this.state.title}
                onChange={(_, { value }) => this.setState({ title: value })}
              />
            </Form.Field>
            <Form.Field required>
              <Form.TextArea
                required
                label="Description"
                placeholder="Description"
                name="description"
                error={this.state.validated && !this.state.description}
                value={this.state.description}
                onChange={(_, { value }) =>
                  typeof value === 'string' &&
                  this.setState({ description: value })
                }
              />
            </Form.Field>
            <Form.Field required>
              <Form.Group inline>
                <Form.Field required error={this.state.active === null}>
                  <label>Status</label>
                </Form.Field>
                <Form.Radio
                  label="Active"
                  name="active"
                  error={this.state.validated && this.state.active === null}
                  checked={this.state.active}
                  onClick={() => this.setState({ active: true })}
                />
                <Form.Radio
                  label="Inactive"
                  name="active"
                  error={this.state.validated && this.state.active === null}
                  checked={!this.state.active}
                  onClick={() => this.setState({ active: false })}
                />
              </Form.Group>
            </Form.Field>
            <Form.Field>
              <DatePicker
                placeholder="Month for Insight (if relevant)"
                value={this.state.month}
                dateFormat={DATE_FORMATS.MONTH_YEAR}
                onChange={(value) => this.setState({ month: value })}
                fullWidth
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.close}>Cancel</Button>
          <Button primary onClick={this.submit} disabled={disabled}>
            Create Financial Insight
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

const mapStateToProps = (state: ReduxState) => ({
  error: getFetchError(state, CREATE_ADMIN_USER_FINANCIAL_INSIGHTS_KEY),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createAdminUserFinancialInsights: wrapDispatch(
    dispatch,
    createAdminUserFinancialInsights
  ),
})

export default connect<StateProps, DispatchProps, ParentProps, ReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(FinancialInsightsForm)
