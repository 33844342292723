import { useEffect, useState } from 'react'
import { List } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { TransactionOrSplit } from '../../../reducers/admin/allTransactions.slice'
import { useReselector } from '../../../utils/sharedHooks'
import { selectDocuments } from '../../UserDocuments/userDocuments.selector'
import { Icon, Link, Popup } from '../../../components/BaseComponents'
import { UploadedFile } from '../../../components/FileUpload/FileUploadModal'
import {
  destroySingleUserDocument,
  UserDocument,
} from '../../UserDocuments/userDocuments.slice'
import FileUploadButton from '../../../components/FileUpload/FileUploadButton'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { UploadDocumentType } from '../../../constants/businessConstants'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface TransactionReceiptsProps {
  transaction: TransactionOrSplit
  buttonText?: string
  setUploadedFileIds?: (fileId: number) => void
  setDeletedFileIds?: (fileId: number) => void
}

export const DisplayFile = ({
  receipt,
  deleteFile,
}: {
  receipt: UserDocument | UploadedFile
  deleteFile: (id: number) => void
}) => {
  const documents = useReselector(selectDocuments)
  const doc = documents[receipt.id]
  if (!doc) {
    return null
  }

  return (
    <List.Item key={receipt.id}>
      <Link
        href={doc?.signedUrl || ''}
        color="black"
        newPage
        size="small"
        style={{ marginRight: 6 }}
      >
        <Popup
          position="top center"
          content={`File Name: ${doc?.id} ${doc?.fileDescription}`}
          trigger={doc?.fileDescription}
        />
      </Link>
      <Icon
        icon={regular('trash-can')}
        onClick={() => deleteFile(receipt.id)}
      />
    </List.Item>
  )
}

export const TransactionReceiptsPanel = ({
  transaction,
  setUploadedFileIds,
  setDeletedFileIds,
}: TransactionReceiptsProps) => {
  const currentUser = useReselector(getCurrentUser) || undefined
  const [filesToDisplay, setFilesToDisplay] = useState<
    (UserDocument | UploadedFile)[] | undefined
  >(transaction?.receipts)
  const [uploadedFile, setUploadedFile] = useState<UploadedFile>()
  const dispatch = useAppDispatch()
  const deleteFile = async (fileId: number) => {
    await destroySingleUserDocument(fileId)(dispatch)
    setDeletedFileIds?.(fileId)
  }

  useEffect(() => {
    if (!transaction?.id) {
      if (uploadedFile) {
        setFilesToDisplay((currentFiles) =>
          currentFiles ? [...currentFiles, uploadedFile] : [uploadedFile]
        )
        setUploadedFileIds?.(uploadedFile.id)
      }
    } else {
      setFilesToDisplay(transaction?.receipts)
      if (uploadedFile) {
        setUploadedFileIds?.(uploadedFile.id)
      }
    }
  }, [transaction?.receipts, transaction?.id, uploadedFile, setUploadedFileIds])

  return (
    <>
      <FileUploadButton
        buttonText="Upload Receipt"
        userFacing
        user={currentUser}
        documentType={UploadDocumentType.RECEIPT}
        setUploadedFile={setUploadedFile}
        extraPayload={{ transactionId: Number(transaction?.id) }}
      />
      <List>
        {filesToDisplay?.map((receipt) => (
          <DisplayFile
            key={receipt.id}
            receipt={receipt}
            deleteFile={deleteFile}
          />
        ))}
      </List>
    </>
  )
}

export default TransactionReceiptsPanel
