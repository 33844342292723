import axios from 'axios'
import {
  PrismaOffsetPagination,
  OffsetPaginatedResponse,
  buildUrlWithOffsetPagination,
} from '../../../utils/fetchHelpers'
import { UserWithAdminInfo } from '../../../reducers/admin/v2/allUsersReducerV2'

const V2_USER_API_BASE = '/finances/api/v2/admin/users'
export enum SearchType {
  ById = 'byId',
  BySearchString = 'bySearchString',
}

export const fetchPaginatedUsers = async (
  pagination: PrismaOffsetPagination,
  additionalQueryParams?: string
) => {
  try {
    const url = buildUrlWithOffsetPagination({
      fetchUrlBase: V2_USER_API_BASE,
      additionalQueryParams,
      pagination,
    })
    const res = await axios.get<OffsetPaginatedResponse<UserWithAdminInfo>>(url)
    return res.data
  } catch (_) {
    return {
      data: [],
      pagination: {
        totalItems: 0,
      },
    }
  }
}

export const fetchUser = async ({
  id,
  select,
}: {
  id: number
  select?: string[]
}) => {
  const res = await axios.get<UserWithAdminInfo>(`${V2_USER_API_BASE}/${id}`, {
    params: {
      selectRelation: select,
    },
  })
  return res.data
}

/**
 * Query the user search endpoint
 * @param pagination - pagination object
 * @param searchType - search type (byId or bySearchString)
 * @param search - search query (id or string)
 *  - If searchType is byId, search must be a number (used to fetch user by id)
 *  - If searchType is bySearchString, search must be a string (used to search by name or email)
 * @param bookkeeperId
 * @param additionalQueryParams
 * @returns
 */
export const searchUsers = async (
  pagination: PrismaOffsetPagination,
  searchType: SearchType,
  search: string | number,
  bookkeeperId?: number,
  additionalQueryParams?: string
) => {
  try {
    const fetchString = buildUrlWithOffsetPagination({
      fetchUrlBase: `${V2_USER_API_BASE}/search`,
      additionalQueryParams,
      pagination,
    })
    // Build search body
    const body: {
      filterBy?: { id?: number; bookkeeperId?: number }
      search?: string | number
    } = {}
    if (searchType === SearchType.ById && Number(search) > 0) {
      body.filterBy = { id: Number(search) }
    } else if (String(search).length > 0) {
      body.search = String(search)
    }
    if (bookkeeperId) {
      body.filterBy = { ...body.filterBy, bookkeeperId }
    }

    const res = await axios.post<OffsetPaginatedResponse<UserWithAdminInfo>>(
      fetchString,
      body
    )
    return res.data
  } catch (_) {
    return {
      data: [],
      pagination: {
        totalItems: 0,
      },
    }
  }
}
