import { Button, GridRowColumn, Link, Modal, Text } from '../../BaseComponents'
import { Grid, List } from 'semantic-ui-react'
import {
  FinancialAccount,
  TransactionSyncState,
} from '../../../reducers/finances/financialAccountsReducer'
import { FontWeight } from '../../../styles/theme'
import { INSTITUTIONS_MAP } from '../../../constants/businessConstants'
import { AccountBanner } from './InstitutionList'
import { updateFinancialAccount } from '../../../actions/financialAccountActions'
import { useAnalyticsTrack } from '../../../features/Amplitude'
import { useEffect } from 'react'
import { useAppDispatch } from '../../../utils/typeHelpers'

const getModalContent = (account: FinancialAccount) => {
  switch (account.plaidInstitutionId) {
    case INSTITUTIONS_MAP.CHASE:
      return (
        <>
          <span>
            The connection will still appear in Plaid and on chase.com.
          </span>
          <br />
          <br />
          <Link
            newPage
            href={
              'https://support.joinheard.com/hc/en-us/articles/19671412059799#unlink_chase'
            }
          >
            How do I unlink a Chase account?
          </Link>
        </>
      )
    case INSTITUTIONS_MAP.WELLS_FARGO:
      return (
        <>
          <span>
            The connection will still appear in Plaid and on wellsfargo.com
          </span>
          <br />
          <br />
          <Link
            newPage
            href={
              'https://support.joinheard.com/hc/en-us/articles/19671412059799#unlink_wf'
            }
          >
            How do I unlink a Wells Fargo account?
          </Link>
        </>
      )
    default:
      return (
        <span>
          The connection will still appear in Plaid and on Financial
          Institution&apos;s website.
        </span>
      )
  }
}

const HideAccountModal = ({
  open,
  account,
  onClose,
  setAccountBanner,
}: {
  open: boolean
  account: FinancialAccount
  onClose: () => void
  setAccountBanner: (accountBanner: AccountBanner | null) => void
}) => {
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()

  useEffect(() => {
    if (open) {
      track('viewed review bank account modal')
    }
  }, [open, track])

  return (
    <Modal size="small" open={open} closeIcon onClose={onClose}>
      <Modal.Header>Do you want to hide this account?</Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text style={{ display: 'inline' }}>
              Hiding{' '}
              <span style={{ fontWeight: FontWeight.SEMIBOLD }}>
                {account.name} - {account.mask}
              </span>{' '}
              will:
            </Text>
            <List bulleted>
              <List.Item>
                Hide all past transactions associated with the account
              </List.Item>
              <List.Item>
                Prevent future transactions from showing up in Heard
              </List.Item>
            </List>
            <Text>
              This does not unlink the connection to{' '}
              <span style={{ fontWeight: FontWeight.SEMIBOLD }}>
                {account.name} - {account.mask}
              </span>
              . {getModalContent(account)}
            </Text>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="actionLink"
          style={{ width: '121px' }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          onClick={async () => {
            onClose()
            await updateFinancialAccount(account.id, {
              isHidden: true,
              transactionSyncState: TransactionSyncState.DISABLED,
            })(dispatch)
            setAccountBanner({ account, type: 'hide' })
            track('clicked review bank account modal button', {
              button: 'hide account',
            })
          }}
        >
          Hide account
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default HideAccountModal
