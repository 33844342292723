import React, { CSSProperties, ReactNode, useEffect } from 'react'
import moment from 'moment'
import { Feed, Header } from 'semantic-ui-react'
import { History } from '../../../features/Admin/Histories/histories.slice'

import { getHistoryDiffs } from '../../../services/historyService'
import { useAppDispatch } from '../../../utils/typeHelpers'
import './TaxProfileHistoryPanel.scss'
import { getAllUsersById } from '../../../selectors/user.selectors'
import { adminFetchSingleUserIfNeeded } from '../../../actions/admin/adminAllUsersActions'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useReselector } from '../../../utils/sharedHooks'

interface Props {
  style?: CSSProperties
  financialProfileHistories: History[]
}

interface Diff {
  key: string
  currentValue: string | number | object
  previousValue: string | number | object
}

interface ViewModel {
  id: number
  date: string
  diffs: Diff[]
  title: string
  subtitle: ReactNode
}

export const subtitleForHistory = (diffs: Diff[], author: string) => {
  const header = `${author} made ${diffs.length} ${
    diffs.length === 1 ? 'change' : 'changes'
  }`

  const details = diffs.map((d, i) => (
    <div key={`key-${i}`} style={{ fontStyle: 'italic' }}>
      <div>
        {d.key}:
        <span style={{ padding: '0 5px 0 5px' }}>
          {d.currentValue === null
            ? 'null'
            : typeof d.currentValue === 'object' ||
                typeof d.currentValue === 'boolean'
              ? JSON.stringify(d.currentValue)
              : d.currentValue}
        </span>
        <span style={{ textDecoration: 'line-through', opacity: '0.5' }}>
          {d.previousValue === null
            ? 'null'
            : typeof d.previousValue === 'object' ||
                typeof d.previousValue === 'boolean'
              ? JSON.stringify(d.previousValue)
              : d.previousValue}
        </span>
      </div>
    </div>
  ))
  return (
    <>
      <div style={{ marginBottom: '10px' }}>{header}</div>
      {details}
    </>
  )
}

const titleForHistory = (index: number, total: number) =>
  index === 0 ? 'Current Version' : `Version ${total - index}`

const TaxProfileHistoryPanel: React.FC<Props> = ({
  financialProfileHistories,
  style,
}) => {
  const dispatch = useAppDispatch()

  const profileString = JSON.stringify(financialProfileHistories)

  // Sequentially load user profile(s) for author(s) who aren't in our redux state yet
  useEffect(() => {
    // Used to prevent unnecessary requests from firing
    const requestedUserIds: { [key: number]: number } = {}
    const histories = JSON.parse(profileString)

    for (const history of histories) {
      const userId = history.changedBy

      if (userId && !requestedUserIds[userId]) {
        requestedUserIds[userId] = userId
        dispatch(adminFetchSingleUserIfNeeded(userId))
      }
    }
  }, [dispatch, profileString])

  const allUsers = useReselector(getAllUsersById)

  const viewModels: ViewModel[] = React.useMemo(
    () =>
      financialProfileHistories.map((history, index) => {
        const diffs = getHistoryDiffs({
          record: history,
          excludedProps: ['updatedAt', 'taxProfileLockedAt', 'filingStates'],
        })

        const author = allUsers[history.changedBy]
        const authorName =
          author?.firstName && author?.lastName
            ? `${author.firstName} ${author.lastName.charAt(0)}.`
            : 'n/a'

        return {
          id: history.id,
          date: moment.utc(history.createdAt).format(DATE_FORMATS.DISPLAY_LONG),
          diffs,
          title: titleForHistory(index, financialProfileHistories.length),
          subtitle: subtitleForHistory(diffs, authorName),
        }
      }),
    [financialProfileHistories, allUsers]
  )

  return (
    <div id="tax-profile-history-panel">
      <Header style={style} as="h4">
        Version History ({financialProfileHistories.length})
      </Header>

      <Feed>
        {viewModels &&
          viewModels.length > 0 &&
          viewModels.map((model, i) => {
            let isSelected = false

            if (i === 0) {
              isSelected = true
            }

            return (
              <Feed.Event
                className={isSelected ? 'selected' : ''}
                key={`feed-event-${model.id}`}
                icon={`circle ${!isSelected ? 'outline' : ''}`}
                date={model.date}
                extraText={model.subtitle}
                style={{ position: 'relative' }}
                summary={model.title}
              >
                <span className="event-line" />
              </Feed.Event>
            )
          })}
      </Feed>
    </div>
  )
}

export default TaxProfileHistoryPanel
