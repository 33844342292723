import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Header, Grid, Message, Button } from 'semantic-ui-react'

import FinancialInsightsTable from './FinancialInsightsTable'
import FinancialInsightsForm from './FinancialInsightsForm'
import { fetchAllUsersIfNeeded } from '../../../actions/admin/adminAllUsersActions'
import { fetchAdminUserFinancialInsights } from '../../../actions/adminFinancialInsightActions'
import { getUserById } from '../../../selectors/user.selectors'
import { useReselector } from '../../../utils/sharedHooks'
import { selectFinancialInsightsByUserId } from '../../../selectors/financeSelectors'
import { useAppDispatch } from '../../../utils/typeHelpers'

const UserInsightsPanel = () => {
  const dispatch = useAppDispatch()
  const [modalOpen, setModalOpen] = useState(false)

  const { userId } = useParams<{ userId: string }>()

  const user = useReselector(getUserById, userId)
  const insights = useReselector(selectFinancialInsightsByUserId, userId)

  useEffect(() => {
    dispatch(fetchAllUsersIfNeeded())
    if (userId) {
      dispatch(fetchAdminUserFinancialInsights(userId))
    }
  }, [dispatch, userId])

  const inactiveInsights = insights?.filter(({ active }) => !active)
  const activeInsights = insights?.filter(({ active }) => active)

  return (
    <Container id="adminPanel">
      <Header as="h3">
        Profile for: {user?.firstName} {user?.lastName}{' '}
      </Header>
      <Header as="h6">
        This page shows additional user information and allows you to manage
        their financial insights.
      </Header>
      <Grid doubling stackable>
        <Grid.Row>
          <Grid.Column width={16}>
            <Message color="yellow">
              <Message.Header>To Update a User’s Insights:</Message.Header>
              <p>1. Set all current insights as “Inactive”</p>
              <p>2. Create the new insights</p>
              <p>3. Double check that the correct insights are set as active</p>
            </Message>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={4}>
            <Button primary onClick={() => setModalOpen(true)}>
              Create New Financial Insight
            </Button>
            {user && (
              <FinancialInsightsForm
                user={user}
                close={() => setModalOpen(false)}
                open={modalOpen}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header as="h4">Active Insights ({activeInsights?.length}) </Header>
      <FinancialInsightsTable insights={activeInsights} />
      <Header as="h4">Inactive Insights ({inactiveInsights?.length}) </Header>
      <FinancialInsightsTable insights={inactiveInsights} />
    </Container>
  )
}

export default UserInsightsPanel
