import { Grid } from 'semantic-ui-react'
import { Text, Button } from '../../BaseComponents'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { useToggle } from '../../../utils/sharedHooks'
import ChangeEmailModal from './ChangeEmailModal'

export const ChangeEmail = ({
  verifiedAt,
  sendVerificationEmail,
}: {
  verifiedAt: string | null | undefined
  sendVerificationEmail: () => void
}) => {
  const [open, toggleOpen] = useToggle()
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const renderEmailColumn = () => (
    <Grid.Column
      computer={4}
      tablet={6}
      mobile={16}
      verticalAlign="middle"
      style={{ margin: 4 }}
    >
      <Button variant="link" onClick={toggleOpen}>
        Change email address
      </Button>
      <ChangeEmailModal open={open} close={toggleOpen} />
    </Grid.Column>
  )

  return (
    <>
      {!isMobile && renderEmailColumn()}
      {!verifiedAt && (
        <Grid.Column
          width={16}
          style={!isMobile ? { marginTop: 8 } : undefined}
        >
          <div style={!isMobile ? { display: 'flex' } : undefined}>
            <Text color="red">This email has not been verified</Text>
            <Button
              variant="secondaryLink"
              onClick={sendVerificationEmail}
              style={!isMobile ? { marginLeft: 10 } : undefined}
            >
              Resend email
            </Button>
          </div>
        </Grid.Column>
      )}
      {isMobile && renderEmailColumn()}
    </>
  )
}
