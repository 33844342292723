import { Image } from 'semantic-ui-react'
import { Text } from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { Colors } from '../../../styles/theme'
import {
  useIsDeviceWidth,
  DeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import styled from 'styled-components'
import CatchUpBookkeepingStatus from './CatchUpBookkeeping/CatchUpBookkeepingStatus'
import OnboardingItemList from './GettingStartedItems/OnboardingItemList'
import { selectCatchUpBookkeepingIsCompleteForUser } from '../../CatchUpBookkeepingStatus/catchUpBookkeepingStatus.selector'
import { selectHasUserCompletedOnboardingPhase } from '../../Onboarding/UserOnboardingSteps/onboarding.selectors'
import { OnboardingStepPhase } from '../../Onboarding/UserOnboardingSteps/onboarding.reducer'

const StyledHeader = styled.div`
  display: flex;
  gap: 64px;
  padding-right: 64px;
  justify-content: space-between;
  &.mobile {
    flex-direction: column;
    gap: 32px;
    padding-right: 0px;
  }
  padding-bottom: 40px;
  width: 100%;
`

const GettingStarted = () => {
  const currentUser = useReselector(getCurrentUser)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const catchUpBKComplete = useReselector(
    selectCatchUpBookkeepingIsCompleteForUser,
    currentUser?.id
  )
  const gettingStartedPhaseComplete = useReselector(
    selectHasUserCompletedOnboardingPhase,
    OnboardingStepPhase.gettingStarted
  )
  const headerText = gettingStartedPhaseComplete
    ? 'Almost there'
    : 'Get Started with Heard'

  return (
    <>
      <StyledHeader className={`${isMobile && 'mobile'}`}>
        <div>
          <Text as="eyebrow" style={{ color: Colors.forest, paddingBottom: 8 }}>
            Welcome {currentUser?.firstName}!
          </Text>
          <Text as="display2">{headerText}</Text>
          <Text as="bodyMd">
            {!gettingStartedPhaseComplete ? (
              <>
                Let&apos;s get your account set up so we can help you grow and
                manage your therapy practice.
              </>
            ) : (
              gettingStartedPhaseComplete &&
              !catchUpBKComplete && (
                <>
                  Thanks for reviewing all of your getting started items. Our
                  bookkeeping team now has what they need to finish your
                  Catch-up Bookkeeping. We&apos;ll be working on this as fast as
                  possible and will reach out if we have any questions.
                </>
              )
            )}
          </Text>
          <CatchUpBookkeepingStatus />
        </div>
        {!isMobile && (
          <Image src="https://heard-images.s3.amazonaws.com/assets/ledger-and-coffee.svg" />
        )}
      </StyledHeader>
      <OnboardingItemList />
    </>
  )
}
export default GettingStarted
