import { useState } from 'react'
import { Container, Grid, Image, Divider } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'

import './enroll.scss'
import '../payroll.scss'

import PayrollSetupList from './PayrollSetupList'
import { GEP_ENROLL_PATHS } from '../helpers'
import {
  Button,
  Card,
  Checkbox,
  Link,
  Text,
  GridRowColumn,
} from '../../../components/BaseComponents'

const PayrollOverview = () => {
  const navigate = useNavigate()
  const [isChecked, setIsChecked] = useState(false)

  return (
    <Container>
      <Card>
        <Grid>
          <Grid.Row />
          <GridRowColumn>
            <Text as="h1">Welcome to Heard Payroll</Text>
          </GridRowColumn>
          <Divider />
          <Grid.Row />
          <Grid.Row>
            <Grid.Column width={8}>
              <Text as="h2">Let’s set up your business</Text>
              <PayrollSetupList isIntro />
            </Grid.Column>
            <Grid.Column width={7}>
              <Image src="https://heard-images.s3.us-west-1.amazonaws.com/assets/payroll_homepage.png" />
            </Grid.Column>
          </Grid.Row>
          <GridRowColumn spacer={2}>
            <Text color="darkGray">
              <i>
                <b>Please note:</b> we are required to register your business
                with the state, meaning this process may take up to 1-2 business
                days to wait for approval. You will not be able to run payroll
                until you have received approval from the state.
              </i>
            </Text>
          </GridRowColumn>
          <GridRowColumn spacer={6} width={4}>
            <Button
              onClick={() => navigate(GEP_ENROLL_PATHS.company)}
              disabled={!isChecked}
              fullWidth
            >
              Get Started
            </Button>
          </GridRowColumn>
          <GridRowColumn spacer={1}>
            <Checkbox
              checked={isChecked}
              onChange={setIsChecked}
              variant="default"
              label={
                <Text>
                  <b>
                    By clicking the above button, I agree to all terms as
                    provided in Gusto&apos;s{' '}
                    <Link href="https://gusto.com/about/privacy" newPage>
                      Privacy Policy
                    </Link>{' '}
                    and{' '}
                    <Link href="https://flows.gusto.com/terms" newPage>
                      Terms of Service.
                    </Link>
                  </b>
                </Text>
              }
            />
          </GridRowColumn>
          <Grid.Row />
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollOverview
