import { useEffect, useMemo } from 'react'
import Moment from 'react-moment'
import { Grid, Divider } from 'semantic-ui-react'
import { startCase } from 'lodash'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Text,
  Button,
  Icon,
  Card,
  GridRowColumn,
  Popup,
  Link,
  DatePicker,
  Alert,
} from '../../../components/BaseComponents'
import { TransactionOrSplit } from '../../../reducers/admin/allTransactions.slice'
import { DATE_FORMATS, DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import CurrencyFormatLabel from '../../../components/shared/CurrencyFormatLabel'
import { centsToDollars } from '../../../utils/currencyHelpers'
import {
  formatAccountNameWithInstitution,
  getTaxCompliantExplanation,
} from '../helpers'
import { useReselector } from '../../../utils/sharedHooks'
import { selectFinancialAccountById } from '../../../selectors/financeSelectors'
import EditableTransactionTypeLabel from '../../../components/shared/EditableTransactionTypeLabel'
import TransactionCategoryLabel from '../../../components/shared/TransactionCategoryLabel'
import { getTransactionCategoryById } from '../../Reports/reports.selectors'
import { TransactionInput } from './NicknameInput'
import { selectTransactionRuleById } from '../transactionRules.selectors'
import { fetchSingleTransactionRule } from '../../../actions/admin/transactionRuleActions'
import { FontWeight, Fonts } from '../../../styles/theme'
import {
  getCurrentUser,
  getFinancialProfile,
} from '../../../selectors/user.selectors'
import { useAnalyticsTrack } from '../../Amplitude'
import CategorizationTooltip from './CategorizationTooltip'
import DetailsTooltip from './DetailsTooltip'
import DetailsAlert from './DetailsAlert'
import { updateUserTransaction } from '../transactions.slice'
import { DateTime } from 'luxon'
import { TransactionAmountInput } from './AmountInput'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface DetailsPanelProps {
  transaction: TransactionOrSplit
  review?: boolean
  splitIndex?: number
  totalSplits?: number
  isManualTransaction?: boolean
}

export const DetailsPanel = ({
  transaction,
  review,
  splitIndex,
  totalSplits,
  isManualTransaction,
}: DetailsPanelProps) => {
  const dispatch = useAppDispatch()

  const {
    id,
    description,
    date,
    amountInCents,
    financialAccountId,
    autocategorizedTransactionRuleId,
    manuallyCreated,
    reconciliationId,
  } = transaction
  const financialProfile = useReselector(getFinancialProfile)
  const currentUser = useReselector(getCurrentUser)
  const taxCompliantExplanation = useMemo(() => {
    return getTaxCompliantExplanation(
      transaction.transactionCategory?.identifier,
      financialProfile?.taxEntityType
    )
  }, [
    transaction.transactionCategory?.identifier,
    financialProfile?.taxEntityType,
  ])

  useEffect(() => {
    if (autocategorizedTransactionRuleId) {
      dispatch(fetchSingleTransactionRule(autocategorizedTransactionRuleId))
    }
  }, [autocategorizedTransactionRuleId, dispatch])

  const autocategorizationRule = useReselector(
    selectTransactionRuleById,
    Number(autocategorizedTransactionRuleId)
  )

  const account = useReselector(
    selectFinancialAccountById,
    Number(financialAccountId)
  )

  const autoCategory = useReselector(
    getTransactionCategoryById,
    autocategorizationRule?.transactionCategoryId
  )

  const transactionCategory = useReselector(
    getTransactionCategoryById,
    transaction.transactionCategoryId
  )

  const track = useAnalyticsTrack()

  return (
    <Grid>
      {Boolean(reconciliationId) && (
        <GridRowColumn>
          <Alert
            customIcon={<Icon icon={regular('lock')} size="sm" />}
            type="warn"
          >
            <Text as="bodySm">
              This transaction has been confirmed by the bookkeeping team. It
              can no longer be modified or removed.
            </Text>
          </Alert>
        </GridRowColumn>
      )}
      <GridRowColumn>
        <DetailsAlert
          isManualTransaction={Boolean(manuallyCreated)}
          isUserManualTransaction={Boolean(isManualTransaction)}
          transactionId={id}
          isReconciled={Boolean(reconciliationId)}
        />
      </GridRowColumn>
      {isManualTransaction && (
        <GridRowColumn>
          <Text as="h3"> Description </Text>
          <TransactionInput transaction={transaction} nickname={false} />
        </GridRowColumn>
      )}
      {!isManualTransaction && (
        <GridRowColumn>
          <Text as="h3"> Description </Text>
          <Text> {description} </Text>
        </GridRowColumn>
      )}
      {typeof splitIndex !== 'number' && (
        <GridRowColumn>
          <Text as="h3" style={{ marginBottom: 6 }}>
            Nickname
            <DetailsTooltip
              label="Nickname"
              content={
                <Text>
                  You can give this transaction a nickname to easily identify
                  it. This is for your reference and has no impact on your
                  bookkeeping.
                </Text>
              }
            />
          </Text>
          <TransactionInput transaction={transaction} />
        </GridRowColumn>
      )}
      <Divider section />
      <Grid.Row>
        <Grid.Column width={5}>
          <Text as="h3" style={{ marginTop: 3 }}>
            Date
          </Text>
        </Grid.Column>
        <Grid.Column width={11}>
          {isManualTransaction && (
            <DatePicker
              style={{ width: '100%' }}
              value={DateTime.fromISO(transaction.date, {
                zone: 'utc',
              }).toFormat(DATE_FORMATS_LUXON.INPUT)}
              maxDate={new Date()}
              onChange={(value) => {
                updateUserTransaction(transaction.id, { date: value })(dispatch)
              }}
            />
          )}
          {!isManualTransaction && (
            <Text>
              <Moment format={DATE_FORMATS.DISPLAY_SHORT} utc>
                {date}
              </Moment>
            </Text>
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={5}>
          <Text as="h3" style={{ marginTop: 3 }}>
            Amount
          </Text>
        </Grid.Column>
        {isManualTransaction && (
          <Grid.Column width={11}>
            <TransactionAmountInput transaction={transaction} />
          </Grid.Column>
        )}
        {!isManualTransaction && (
          <Grid.Column width={11}>
            <Text>
              <CurrencyFormatLabel value={centsToDollars(amountInCents)} />
            </Text>
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={5}>
          <Text as="h3" style={{ marginTop: 3 }}>
            Account
          </Text>
        </Grid.Column>
        <Grid.Column width={11}>
          <Text> {formatAccountNameWithInstitution(account)} </Text>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={5}>
          <Text as="h3" style={{ marginTop: 3 }}>
            Type
          </Text>
        </Grid.Column>
        <Grid.Column width={11}>
          <EditableTransactionTypeLabel
            transaction={transaction}
            review={review}
          />
          {typeof splitIndex === 'number' && (
            <Text color="darkGray">
              Split Transaction ({splitIndex + 1}/{totalSplits})
            </Text>
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={5}>
          <Text as="h3" style={{ marginTop: 12 }}>
            Category <CategorizationTooltip />
          </Text>
        </Grid.Column>
        <Grid.Column width={11}>
          <TransactionCategoryLabel
            variant="text"
            useRecategorizationModal
            filtered
            transaction={transaction}
            editable
            review={review}
          />
          {transaction.type !== 'personal' && (
            <>
              {taxCompliantExplanation}
              {taxCompliantExplanation &&
                transactionCategory?.isTaxCompliant && (
                  <Link
                    as="secondaryLink"
                    color="black"
                    style={{ ...Fonts.bodySm, marginTop: 8, display: 'block' }}
                    href="https://support.joinheard.com/hc/en-us/articles/13615864394263"
                    newPage
                  >
                    Common mix-ups with tax-compliant categories
                  </Link>
                )}
            </>
          )}
        </Grid.Column>
      </Grid.Row>

      <Divider section />

      <GridRowColumn>
        <Text as="h3" style={{ marginBottom: 6 }}>
          Auto-Categorization Rules
          <DetailsTooltip
            label="Auto-categorization rules"
            content={
              <Text>
                Instead of manually categorizing transactions that are always
                categorized the same way, the Heard app does it automatically.
                We call this process &quot;auto-categorization&quot;.
              </Text>
            }
            track={() => track('clicked autocat rule tooltip')}
          />
        </Text>
        {autocategorizationRule ? (
          <Card type="subsection">
            <Card.Content>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={2}>
                    <Icon icon={regular('bolt-auto')} color="oak" />
                  </Grid.Column>
                  <Grid.Column width={14}>
                    <Text as="h3">
                      &quot;{autocategorizationRule.ruleText}&quot;
                    </Text>
                    <Text>
                      Type:{' '}
                      {startCase(
                        autocategorizationRule.transactionCategoryType ||
                          undefined
                      )}
                    </Text>
                    <Text>Category: {autoCategory?.name}</Text>
                    <Text color="green" as="h2">
                      H
                      <Popup
                        hoverable
                        content={
                          <Grid>
                            <GridRowColumn>
                              <Text as="h3" style={{ marginBottom: 8 }}>
                                Heard-created rules
                              </Text>
                              <Text>
                                We apply certain auto-categorization rules to
                                all customers to help categorize recurring
                                transactions consistently.
                              </Text>
                            </GridRowColumn>
                          </Grid>
                        }
                        position="top center"
                        trigger={
                          <Button
                            variant="tooltipLink"
                            style={{
                              display: 'inline-block',
                              marginLeft: 5,
                              fontWeight: FontWeight.REGULAR,
                            }}
                          >
                            Created By Heard
                          </Button>
                        }
                      />
                    </Text>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Content>
          </Card>
        ) : (
          <>
            <Text color="darkGray">None applied.</Text>
            <Card backgroundColor="stone40">
              <Text>
                If this is a recurring transaction, we can categorize it
                automatically for you.{' '}
                <Link
                  href={`https://${process.env.VITE_ZENDESK_SUBDOMAIN}.zendesk.com/hc/en-us/requests/new?tf_subject=Auto-categorization%20Rule%20Request%20-%20${encodeURIComponent(
                    description
                  )}%20APP&tf_description=${encodeURIComponent(
                    `
                        <ul>
                          <li><strong>Automatically categorize recurring transactions with this description:</strong> ${description} APP</li>
                          <li><strong>Categorize as:</strong> Requested category</li>
                          <li><strong>Additional comments:</strong> Please share any explanation, context, or comments here</li>
                        </ul>
                      `
                  )}&${process.env.VITE_ZENDESK_FIELD_ID}=topic_area__bookkeeping&tf_anonymous_requester_email=${encodeURIComponent(
                    currentUser?.email || ''
                  )}`}
                  trackMessage="clicked submit autocat request"
                  newPage
                >
                  Submit a request
                </Link>
              </Text>
            </Card>
          </>
        )}
      </GridRowColumn>
    </Grid>
  )
}
