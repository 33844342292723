import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AllocationAmount {
  allocationGuideId: number
  allocationRuleId: number
  allocationRuleName: string
  createdAt: string
  date: string
  id: number
  updatedAt: string
  amountInCents: number
  userId: number
  amount: null
}

export interface AllocationGuide {
  amounts: AllocationAmount[]
  bookkeepingReportStatus: string
  compiledOn: string
  date: string
  id: number
  totalAmount: string | null
  userId: number
  userNotifiedAt: number | null
  incomeAtCompilationInCents: number
  expensesAtCompilationInCents: number
}

export interface AllocationGuideSummary {
  allocationRuleName: string
  totalAmount: string
}

export interface UserAllocationGuideState {
  byId: { [key: number]: AllocationGuide }
  summaries: AllocationGuideSummary[]
}

const initialState: UserAllocationGuideState = {
  byId: {},
  summaries: [],
}

const allocationGuideReducer = createSlice({
  name: 'allocationGuide',
  initialState,
  reducers: {
    receiveAllAllocationGuides: (
      state,
      action: PayloadAction<{ [key: number]: AllocationGuide }>
    ) => {
      state.byId = { ...state.byId, ...action.payload }
    },
    receiveSingleAllocationGuide: (
      state,
      action: PayloadAction<AllocationGuide>
    ) => {
      state.byId[action.payload.id] = action.payload
    },
    receiveAllocationGuidesSummaries: (
      state,
      action: PayloadAction<AllocationGuideSummary[]>
    ) => {
      state.summaries = action.payload
    },
  },
})

export const {
  receiveAllAllocationGuides,
  receiveSingleAllocationGuide,
  receiveAllocationGuidesSummaries,
} = allocationGuideReducer.actions

export default allocationGuideReducer.reducer
