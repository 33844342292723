import { useEffect, useMemo, useState } from 'react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Button, Card, GridRowColumn, Icon, Text } from '../../BaseComponents'
import { InstitutionCard } from './InstitutionCard'
import {
  selectFinancialAccounts,
  selectPlaidItems,
} from '../../../selectors/financeSelectors'

import { fetchFinancialAccountsIfNeeded } from '../../../actions/financialAccountActions'
import { fetchPlaidItemsIfNeeded } from '../../../actions/plaidItemActions'

import { orderBy, values } from 'lodash'
import { Colors } from '../../../styles/theme'
import { FinancialAccount } from '../../../reducers/finances/financialAccountsReducer'
import { List, Loader } from 'semantic-ui-react'
import { getAccountsAwaitingConfirmation } from './helpers'
import { useAnalyticsTrack } from '../../../features/Amplitude'
import InitialStateModal from './InitialStateModal'
import {
  getCurrentUser,
  selectRolledOutInstitutionIds,
} from '../../../selectors/user.selectors'
import { useReselector } from '../../../utils/sharedHooks'
import { fetchRolledOutInstitutionIds } from '../../../actions/userActions'
import { useAppDispatch } from '../../../utils/typeHelpers'

const AccountBannerCard = ({ data }: { data: AccountBanner }) => {
  return (
    <Card
      style={{
        backgroundColor:
          data.type === 'hide' ? Colors.natural : Colors.lightGreen,
      }}
    >
      <Card.Content>
        <Text as={'h2'}>
          Account {data.type === 'hide' ? 'Hidden' : 'Visible'}
        </Text>
        <br />
        <Text>
          {data.account.name} - {data.account.mask}
          {data.type === 'hide'
            ? ' has been hidden. Transactions will be hidden in Heard.'
            : ' is now visible. Transactions will now be visible in Heard.'}
        </Text>
      </Card.Content>
    </Card>
  )
}

export interface AccountBanner {
  account: FinancialAccount
  type: 'hide' | 'show'
}

export const AwaitingConfirmationButton = ({
  account,
  disconnectedOn,
  setAccountToEdit,
  setInitialStateModalOpen,
}: {
  account: FinancialAccount
  disconnectedOn?: string
  setAccountToEdit: (value: FinancialAccount | null) => void
  setInitialStateModalOpen: (value: boolean) => void
}) => {
  const track = useAnalyticsTrack()

  return (
    <>
      <Icon
        color={'orange'}
        icon={solid('triangle-exclamation')}
        style={{ marginLeft: 8 }}
      />
      <Button
        variant={'secondaryLink'}
        disabled={Boolean(disconnectedOn)}
        inline
        style={{ marginLeft: 6 }}
        onClick={() => {
          setAccountToEdit(account)
          setInitialStateModalOpen(true)
          track('clicked review bank account button')
        }}
        size="small"
      >
        Awaiting your confirmation
      </Button>
    </>
  )
}

export const InstitutionList = ({ isOnboarding = false }) => {
  const dispatch = useAppDispatch()
  const user = useReselector(getCurrentUser)
  const plaidInstitutions = useReselector(selectPlaidItems)
  const financialAccounts = useReselector(selectFinancialAccounts)
  const rolledOutInstitutionIds = useReselector(selectRolledOutInstitutionIds)

  const [fetching, setFetching] = useState(true)
  const [accountBanner, setAccountBanner] = useState<AccountBanner | null>(null)
  const [accountToEdit, setAccountToEdit] = useState<FinancialAccount | null>(
    null
  )
  const [initialStateModalOpen, setInitialStateModalOpen] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchFinancialAccountsIfNeeded())
      await dispatch(fetchPlaidItemsIfNeeded())
      setFetching(false)
    }

    fetch()
  }, [dispatch])

  useEffect(() => {
    const fetch = async () => {
      if (user) await dispatch(fetchRolledOutInstitutionIds(user.id))
    }
    fetch()
  }, [dispatch, user, plaidInstitutions])

  const sortedPlaidInstitutions = useMemo(() => {
    if (!plaidInstitutions) return null
    return orderBy(Object.values(plaidInstitutions), 'disconnectedOn')
  }, [plaidInstitutions])

  const accountsAwaitingConfirmation = getAccountsAwaitingConfirmation(
    values(financialAccounts),
    rolledOutInstitutionIds ?? []
  )

  const hasAccountsAwaitingConfirmation = Boolean(
    rolledOutInstitutionIds?.length && accountsAwaitingConfirmation?.length
  )

  if (fetching || !user) {
    return <Loader inverted>Loading</Loader>
  }

  if (sortedPlaidInstitutions && sortedPlaidInstitutions.length > 0) {
    return (
      <div data-testid="institution-list">
        {accountBanner && <AccountBannerCard data={accountBanner} />}

        {hasAccountsAwaitingConfirmation && (
          <Card backgroundColor={'natural'}>
            <Text as={'h3'}>Review bank account</Text>
            <br />
            <Text color={'darkGray'} as={'eyebrow'}>
              {accountsAwaitingConfirmation.length} account
              {accountsAwaitingConfirmation.length > 1 && 's'}
            </Text>
            <List as="ul" style={{ color: Colors.mediumGray }}>
              {accountsAwaitingConfirmation.map((account) => (
                <List.Item as="li" key={account.id}>
                  {account.name} {account.mask}
                  <AwaitingConfirmationButton
                    account={account}
                    setAccountToEdit={setAccountToEdit}
                    setInitialStateModalOpen={setInitialStateModalOpen}
                  />
                </List.Item>
              ))}
            </List>
          </Card>
        )}

        {sortedPlaidInstitutions.map((institution) => (
          <GridRowColumn
            key={institution.institutionName}
            style={{ marginBottom: 14 }}
          >
            <InstitutionCard
              institution={institution}
              setAccountBanner={setAccountBanner}
              accountToEdit={accountToEdit}
              setAccountToEdit={setAccountToEdit}
              setInitialStateModalOpen={setInitialStateModalOpen}
              isOnboarding={isOnboarding}
            />
          </GridRowColumn>
        ))}

        {accountToEdit && (
          <InitialStateModal
            open={initialStateModalOpen}
            account={accountToEdit}
            onClose={() => {
              setInitialStateModalOpen(false)
              setAccountToEdit(null)
            }}
            setAccountBanner={setAccountBanner}
          />
        )}
      </div>
    )
  }

  if (isOnboarding) {
    return null
  }

  return (
    <GridRowColumn
      centerContent
      columnStyle={{ flexDirection: 'column', alignItems: 'center', gap: 20 }}
      data-testid="institution-list-empty"
    >
      <Icon icon={regular('bank')} size="5x" color="gray" />
      <Text textAlign="center">
        You don&apos;t have any financial institution connections yet.
      </Text>
    </GridRowColumn>
  )
}
