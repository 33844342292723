import { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import Truncate from 'react-truncate'
import { sortBy } from 'lodash'
import moment from 'moment'
import { saveAs } from 'file-saver'

import { selectEmployeeByUuid } from '../payroll.selectors'
import {
  fetchEmployeeFederalTaxes,
  fetchEmployeeStateTaxes,
  fetchEmployeePaymentMethod,
  fetchEmployeePaystub,
  FETCH_EMPLOYEE_PAYSTUB_KEY,
  fetchEmployeePaystubs,
} from '../payrollActions'
import EmployeeJobCompPanel from './EmployeeJobCompPanel'
import EmployeeDetailsPanel from './EmployeeDetailsPanel'
import { formatCurrency } from '../../../utils/currencyHelpers'
import { getIsFetching } from '../../../reducers/fetch'
import {
  Dropdown,
  Link,
  Text,
  Button,
  Alert,
  GridRowColumn,
} from '../../../components/BaseComponents'
import { useFetchResponse, useReselector } from '../../../utils/sharedHooks'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { LabelDescription } from '../../../components/BaseComponents/Input'
import { useAppDispatch } from '../../../utils/typeHelpers'
import {
  Gusto_EmployeeFederalTaxes,
  Gusto_EmployeePaymentMethod,
  Gusto_EmployeeStateTaxes,
} from '../generated_gusto_types'

export const DetailsRow = ({
  label,
  value,
}: {
  label: string | null
  value: string | undefined | null
}) => (
  <Grid.Row className="short">
    <Grid.Column width={5}>
      <Text>
        <b>{label || ''}</b>
      </Text>
    </Grid.Column>
    <Grid.Column width={11} textAlign="right">
      <Text>
        <Truncate>{value || ''}</Truncate>
      </Text>
    </Grid.Column>
  </Grid.Row>
)

const PaymentMethodPanel = ({ uuid }: { uuid: string }) => {
  const dispatch = useAppDispatch()
  const [paymentMethod, setPaymentMethod] =
    useState<Gusto_EmployeePaymentMethod>()

  useEffect(() => {
    const fetch = async () => {
      const res = await fetchEmployeePaymentMethod(uuid)(dispatch)

      if (res) {
        setPaymentMethod(res)
      }
    }
    fetch()
  }, [dispatch, uuid])

  return (
    <div className="gep-details-container">
      <Grid>
        <GridRowColumn>
          <Text as="h2">Payment Details</Text>
        </GridRowColumn>
        {paymentMethod && (
          <>
            <DetailsRow label="Payment Method" value={paymentMethod.type} />
            {paymentMethod.splits?.[0] && (
              <DetailsRow
                label="Bank Account"
                value={paymentMethod.splits[0].name}
              />
            )}
          </>
        )}
      </Grid>
    </div>
  )
}

const FederalTaxesPanel = ({ uuid }: { uuid: string }) => {
  const dispatch = useAppDispatch()
  const [federalTaxes, setFederalTaxes] = useState<Gusto_EmployeeFederalTaxes>()

  useEffect(() => {
    const fetch = async () => {
      const res = await fetchEmployeeFederalTaxes(uuid)(dispatch)
      if (res) {
        setFederalTaxes(res)
      }
    }

    fetch()
  }, [dispatch, uuid])

  return (
    <div className="gep-details-container">
      <Grid>
        <GridRowColumn>
          <Text as="h2">Federal Taxes</Text>
        </GridRowColumn>
        {federalTaxes && (
          <>
            <DetailsRow
              label="Filing Status"
              value={federalTaxes.filing_status}
            />
            <DetailsRow
              label="Multiple Jobs"
              value={federalTaxes.two_jobs ? 'Yes' : 'No'}
            />
            {/*This comes back with decimals so convert to number then string*/}
            <DetailsRow
              label="Dependents"
              value={Number(federalTaxes.dependents_amount).toString()}
            />
            <DetailsRow
              label="Other Income"
              value={formatCurrency(federalTaxes.other_income)}
            />
            <DetailsRow
              label="Deductions"
              value={formatCurrency(federalTaxes.deductions)}
            />
            <DetailsRow
              label="Extra Withholding"
              value={formatCurrency(federalTaxes.extra_withholding)}
            />
          </>
        )}
      </Grid>
    </div>
  )
}

const StateTaxesPanel = ({ uuid }: { uuid: string }) => {
  const dispatch = useAppDispatch()
  const [stateTaxes, setStateTaxes] = useState<Gusto_EmployeeStateTaxes>()

  useEffect(() => {
    const fetch = async () => {
      const res = await fetchEmployeeStateTaxes(uuid)(dispatch)
      if (res?.length) {
        setStateTaxes(res[0])
      }
    }

    fetch()
  }, [dispatch, uuid])

  return (
    <div className="gep-details-container">
      <Grid>
        <GridRowColumn>
          <Text as="h2">State Taxes</Text>
        </GridRowColumn>
        {stateTaxes?.questions.map((question) => {
          const answer = question.answers[0]?.value

          let answerText = ''
          if (typeof answer === 'boolean') {
            answerText = answer ? 'Yes' : 'No'
          } else if (answer !== undefined) {
            answerText = answer.toString()
          }
          return (
            <DetailsRow
              key={question.key}
              label={question.label}
              value={answerText}
            />
          )
        })}
      </Grid>
    </div>
  )
}

const PayStubs = ({ uuid }: { uuid: string }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const employee = useReselector(selectEmployeeByUuid, uuid)
  const [selectedPayrollUuid, setSelectedPayrollUuid] = useState<string>()

  const paystubs = useFetchResponse(fetchEmployeePaystubs, uuid)

  const isDownloadingPdf = useReselector(
    getIsFetching,
    FETCH_EMPLOYEE_PAYSTUB_KEY
  )

  const sortedPaystubs = useMemo(
    () => sortBy(paystubs, 'check_date'),
    [paystubs]
  )

  const mostRecent = sortedPaystubs[0] ? sortedPaystubs[0] : null

  // Once paystubs are fetched set the selected dropdown to latest
  useEffect(() => {
    setSelectedPayrollUuid(mostRecent?.payroll_uuid)
  }, [mostRecent?.payroll_uuid])

  const downloadPaystub = useCallback(async () => {
    if (!selectedPayrollUuid || !employee?.uuid) {
      return
    }

    const paystub = paystubs?.find(
      (paystub) => paystub.payroll_uuid === selectedPayrollUuid
    )

    if (!paystub) {
      return
    }

    const res = await fetchEmployeePaystub(
      selectedPayrollUuid,
      employee.uuid
    )(dispatch)

    if (!res) {
      return
    }

    saveAs(
      res,
      `paystub_${paystub.check_date}_${employee?.first_name}_${employee?.last_name}.pdf`
    )
  }, [
    selectedPayrollUuid,
    employee?.uuid,
    employee?.first_name,
    employee?.last_name,
    paystubs,
    dispatch,
  ])

  return (
    <div className="gep-details-container">
      <Grid>
        <GridRowColumn>
          <Text as="h2">Pay Stubs</Text>
        </GridRowColumn>
        {paystubs?.length ? (
          <>
            <GridRowColumn>
              <Link to={`/payroll/history/${selectedPayrollUuid}`}>
                Most recent {mostRecent?.check_date}
              </Link>
            </GridRowColumn>
            <GridRowColumn short>
              <LabelDescription label="Previous Pay Stubs" />
            </GridRowColumn>
            <GridRowColumn short>
              <Dropdown
                options={sortedPaystubs.map((paystub) => ({
                  value: paystub.payroll_uuid,
                  text: moment(paystub.check_date).format(
                    DATE_FORMATS.DISPLAY_SHORT
                  ),
                }))}
                value={selectedPayrollUuid}
                onChange={setSelectedPayrollUuid}
              />
            </GridRowColumn>
            <Grid.Row>
              <Grid.Column width={7}>
                <Button
                  disabled={!selectedPayrollUuid}
                  onClick={() =>
                    navigate(`/payroll/history/${selectedPayrollUuid}`)
                  }
                  fullWidth
                  variant="secondary"
                >
                  View Details
                </Button>
              </Grid.Column>
              <Grid.Column width={7}>
                <Button
                  disabled={!selectedPayrollUuid}
                  onClick={downloadPaystub}
                  fullWidth
                  variant="secondary"
                  loading={isDownloadingPdf}
                >
                  Download
                </Button>
              </Grid.Column>
            </Grid.Row>
          </>
        ) : (
          <GridRowColumn>
            <Alert title="Pay Stubs">
              All future employee pay stubs will display here.
            </Alert>
          </GridRowColumn>
        )}
      </Grid>
    </div>
  )
}

const JobPayTab = ({ uuid }: { uuid: string }) => {
  const employee = useReselector(selectEmployeeByUuid, uuid)

  if (!employee) {
    return null
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <Grid>
            <GridRowColumn>
              <EmployeeDetailsPanel employeeUuid={employee.uuid} />
            </GridRowColumn>
            <GridRowColumn>
              <EmployeeJobCompPanel employeeUuid={employee.uuid} />
            </GridRowColumn>
            <GridRowColumn>
              <PaymentMethodPanel uuid={employee.uuid} />
            </GridRowColumn>
            <GridRowColumn>
              <FederalTaxesPanel uuid={employee.uuid} />
            </GridRowColumn>
            <GridRowColumn>
              <StateTaxesPanel uuid={employee.uuid} />
            </GridRowColumn>
          </Grid>
        </Grid.Column>
        <Grid.Column width={8}>
          <PayStubs uuid={uuid} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default JobPayTab
