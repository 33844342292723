import { useFormikContext } from 'formik'
import {
  FormikDropdown,
  FormikLabelError,
  FormikRadioToggleButton,
  GridRowColumn,
  getFieldName,
  makeReqBoolSchema,
  makeReqStringSchema,
} from '../../../../components/BaseComponents'
import { FinancialProfile } from '../../../../reducers/auth/userReducer'
import { Grid } from 'semantic-ui-react'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import {
  FILING_STATUS_COPY,
  FILING_STATUSES,
  FilingStatusOptions,
} from '../../taxConstants'

interface FilingStatusValues {
  filingStatusSame: boolean | undefined
  filingStatus: FILING_STATUSES | undefined
}

const FilingStatusSubForm = ({ fp }: { fp?: FinancialProfile }) => {
  const { values } = useFormikContext<FilingStatusValues>()

  if (!fp) {
    return null
  }

  return (
    <>
      {/*if filing status is missing ask for it, otherwise check if it has changed*/}
      {fp?.filingStatus ? (
        <>
          <GridRowColumn>
            <FormikLabelError
              name={getFieldName<typeof values>('filingStatusSame')}
              required
              label={
                <>
                  Are you still filing as{' '}
                  <b>
                    {fp?.filingStatus &&
                      FILING_STATUS_COPY[fp?.filingStatus]?.title}
                  </b>
                  ?
                </>
              }
              description="If this has changed, select No."
              schema={makeReqBoolSchema()}
            />
          </GridRowColumn>
          <Grid.Row className="short">
            <Grid.Column computer={2} tablet={3} mobile={5}>
              <FormikRadioToggleButton
                name={getFieldName<typeof values>('filingStatusSame')}
                value
                fullWidth
              >
                Yes
              </FormikRadioToggleButton>
            </Grid.Column>
            <Grid.Column computer={2} tablet={3} mobile={5}>
              <FormikRadioToggleButton
                name={getFieldName<typeof values>('filingStatusSame')}
                value={false}
                fullWidth
              >
                No
              </FormikRadioToggleButton>
            </Grid.Column>
          </Grid.Row>

          {values.filingStatusSame === false && (
            <GridRowColumn {...makeGridConfig([10, 16])}>
              <FormikDropdown
                name={getFieldName<typeof values>('filingStatus')}
                options={FilingStatusOptions}
                fullWidth
                label="What is your new filing status?"
                required
                schema={makeReqStringSchema()}
              />
            </GridRowColumn>
          )}
        </>
      ) : (
        <GridRowColumn {...makeGridConfig([10, 16])}>
          <FormikDropdown
            name={getFieldName<typeof values>('filingStatus')}
            options={FilingStatusOptions}
            fullWidth
            label="What is your filing status?"
            required
            schema={makeReqStringSchema()}
          />
        </GridRowColumn>
      )}
    </>
  )
}

export default FilingStatusSubForm
