import { useParams } from 'react-router-dom'
import { DeductionsAndCreditsStepProps } from '.'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { TaxListOptionId, TaxListQuestionId } from '../../service'
import { selectTaxListQuestionResponsesByFormId } from '../../taxChecklist.selectors'
import { Grid } from 'semantic-ui-react'
import {
  Card,
  GridRowColumn,
  Icon,
  Link,
  Text,
  FormikRadioToggleButton,
  getFieldName,
} from '../../../../../../components/BaseComponents'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { FormikProvider, useFormik } from 'formik'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { UPSERT_USER_TAX_QUESTIONNAIRE_KEY } from '../../taxChecklistQuestion.actions'
import { UPDATE_USER_EOY_REVIEW_PROGRESS_KEY } from '../../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { formatCurrencyFromCents } from '../../../../../../utils/currencyHelpers'
import DocumentAddedAlert from '../../Shared/SpecialtyAlerts'

export const educationQuestionIds = [TaxListQuestionId.education_credit_type]

const EducationPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: DeductionsAndCreditsStepProps) => {
  const { formId } = useParams()

  const ATOCMaxCredit = formatCurrencyFromCents(250000, {
    hideDecimalsIfZero: true,
  })
  const ATOCRemainingCredit = formatCurrencyFromCents(100000, {
    hideDecimalsIfZero: true,
  })

  const LLCMaxCredit = formatCurrencyFromCents(200000, {
    hideDecimalsIfZero: true,
  })

  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    UPDATE_USER_EOY_REVIEW_PROGRESS_KEY,
  ])

  const responseEducationCreditType = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.education_credit_type,
    Number(formId)
  )

  const formik = useFormik({
    initialValues: {
      americanOpportunityTaxCredit: Array.isArray(
        responseEducationCreditType?.[0]?.value
      )
        ? responseEducationCreditType?.[0]?.value.includes(
            TaxListOptionId.american_opportunity_credit
          )
        : false,
      lifetimeLearningCredit: Array.isArray(
        responseEducationCreditType?.[0]?.value
      )
        ? responseEducationCreditType?.[0]?.value.includes(
            TaxListOptionId.lifetime_learning_credit
          )
        : false,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      const responseValue: string[] = []
      if (values.americanOpportunityTaxCredit) {
        responseValue.push(TaxListOptionId.american_opportunity_credit)
      }
      if (values.lifetimeLearningCredit) {
        responseValue.push(TaxListOptionId.lifetime_learning_credit)
      }
      if (
        JSON.stringify(responseEducationCreditType?.[0]?.value) !==
        JSON.stringify(responseValue)
      ) {
        responseData.push({
          id: responseEducationCreditType?.[0]?.id,
          value: responseValue,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.education_credit_type,
        })
      }
      await goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.education],
      })
    },
  })

  const { values, isValid, submitForm, isSubmitting } = formik

  return (
    <FormikProvider value={formik}>
      <Grid>
        <Grid.Row />
        <Grid.Row />
        <GridRowColumn>
          <Text as="display2" textAlign="center">
            Education Credits
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([7, 14], true)}>
          <Text as="bodyLg">
            If you, your spouse or your dependent are attending a qualifying
            institution, you may be eligible for one or both of these credits. A{' '}
            <b>1098-T</b> will help us determine how much credit you can claim.
          </Text>
        </GridRowColumn>
        <Grid.Row>
          <Grid.Column width={3} only="computer" />
          <Grid.Column computer={5} mobile={16} tablet={16} stretched>
            <Card backgroundColor="stone40" style={{ display: 'flex' }}>
              <Text as="h1" textAlign="center">
                American Opportunity Tax Credit
              </Text>
              <br />
              <Text as="bodyLg">
                The <b>American Opportunity Tax Credit (AOTC)</b> is a credit
                for qualified education expenses paid for an{' '}
                <b>
                  eligible student for the first four years of higher education.
                </b>
              </Text>
              <Text>
                <ul>
                  <li>
                    You can get a maximum annual credit of {ATOCMaxCredit} per
                    eligible student.
                  </li>
                  <li>
                    If the credit brings the amount of tax you owe to zero, you
                    can have 40 percent of any remaining amount of the credit
                    (up to {ATOCRemainingCredit}) refunded to you.
                  </li>
                </ul>
              </Text>
              <Link
                href="https://www.irs.gov/credits-deductions/individuals/aotc"
                newPage
              >
                Am I eligible for this credit?
                <Icon
                  icon={light('square-arrow-up-right')}
                  style={{ marginLeft: 6 }}
                />
              </Link>
              <div style={{ flex: 1, minHeight: 20 }} />
              <FormikRadioToggleButton
                fullWidth
                name={getFieldName<typeof values>(
                  'americanOpportunityTaxCredit'
                )}
                value
                deselectValue={false}
              >
                I may qualify
              </FormikRadioToggleButton>
            </Card>
          </Grid.Column>
          <Grid.Column computer={5} mobile={16} tablet={16} stretched>
            <Card backgroundColor="stone40" style={{ display: 'flex' }}>
              <Text as="h1" textAlign="center">
                Lifetime Learning Credit
              </Text>
              <br />
              <Text as="bodyLg">
                The <b>Lifetime Learning Credit (LLC)</b> is for qualified
                tuition and related expenses paid for{' '}
                <b>
                  eligible students enrolled in an eligible educational
                  institution.
                </b>
              </Text>
              <Text>
                <ul>
                  <li>
                    There&apos;s no limit on the number of years you can claim
                    the credit.
                  </li>
                  <li>
                    The credit is worth up to {LLCMaxCredit} per tax return.
                  </li>
                </ul>
              </Text>
              <Link
                href="https://www.irs.gov/credits-deductions/individuals/llc"
                newPage
              >
                Am I eligible for this credit?
                <Icon
                  icon={light('square-arrow-up-right')}
                  style={{ marginLeft: 6 }}
                />
              </Link>
              <div style={{ flex: 1, minHeight: 20 }} />
              <FormikRadioToggleButton
                fullWidth
                name={getFieldName<typeof values>('lifetimeLearningCredit')}
                value
                deselectValue={false}
              >
                I may qualify
              </FormikRadioToggleButton>
            </Card>
          </Grid.Column>
        </Grid.Row>
        {(values.americanOpportunityTaxCredit ||
          values.lifetimeLearningCredit) && (
          <GridRowColumn {...makeGridConfig([7, 14], true)}>
            <DocumentAddedAlert>
              Form 1098-T added to checklist
            </DocumentAddedAlert>
          </GridRowColumn>
        )}
        <Grid.Row />
        <FormFlowFooter
          continueDisabled={!isValid || isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isUpdating || isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default EducationPanel
