import { DateTime } from 'luxon'
import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import { Text } from '../../BaseComponents'
import { DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'

const RowNotes = ({ transaction }: { transaction: Transaction }) => (
  <>
    <p style={{ marginBottom: '0' }}>{transaction.notes}</p>
    {transaction.notesLastUpdatedAt && (
      <Text as="bodyXs" color="mediumGray">
        <em>
          {DateTime.fromISO(transaction.notesLastUpdatedAt).toFormat(
            DATE_FORMATS_LUXON.DISPLAY_SHORT
          )}
        </em>
      </Text>
    )}
    {transaction.adminNotes && (
      <p style={{ marginTop: '10px' }}>
        <strong>Admin: </strong>
        {transaction.adminNotes.length > 20
          ? `${transaction.adminNotes.substring(0, 20)}...`
          : transaction.adminNotes}
      </p>
    )}
  </>
)

export default RowNotes
