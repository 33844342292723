import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Gusto_CompanyAddress } from './generated_gusto_types'

const initialState: { [key: string]: Gusto_CompanyAddress } = {}

const PayrollCompanyLocationSlice = createSlice({
  name: 'payrollCompanyLocation',
  initialState,
  reducers: {
    setAllLocations: (
      state,
      action: PayloadAction<{ [key: string]: Gusto_CompanyAddress }>
    ) => ({ ...state, ...action.payload }),
    setSingleLocation: (state, action: PayloadAction<Gusto_CompanyAddress>) => {
      state[action.payload.uuid] = action.payload
    },
  },
})

export const { setAllLocations, setSingleLocation } =
  PayrollCompanyLocationSlice.actions

export default PayrollCompanyLocationSlice.reducer
