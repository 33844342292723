import React, { useState, Dispatch, SetStateAction, useEffect } from 'react'
import GridRowWrapper from './shared/GridRowWrapper'
import {
  Form,
  Grid,
  Button,
  TextArea,
  TextAreaProps,
  Label,
} from 'semantic-ui-react'

interface MessageRowProps {
  editingIndex: number | null
  messageBody: string
  setEditingIndex: Dispatch<SetStateAction<number | null>>
  setMessageBody: Dispatch<SetStateAction<string>>
  disableEditing: boolean
}

const COMPONENT_INDEX = 3

const MessageRow: React.FC<MessageRowProps> = ({
  messageBody,
  editingIndex,
  setEditingIndex,
  setMessageBody,
  disableEditing,
}) => {
  messageBody = messageBody.trim()

  useEffect(() => {
    setInputValue(messageBody)
  }, [messageBody])

  const [inputValue, setInputValue] = useState(messageBody)
  const buttonText = messageBody ? 'Edit Message' : 'Add Message'
  const subtitle = messageBody || "What's the body for this message?"

  const resetForm = () => {
    setEditingIndex(null)
    setInputValue(messageBody)
  }

  const onInputChange = (_e: unknown, data: TextAreaProps) =>
    setInputValue(data?.value ? data.value.toString() : '')

  const onSaveClicked = () => {
    if (inputValue) {
      setMessageBody(inputValue)
      setEditingIndex(null)
    }
  }

  const placeholder =
    'Hi {customer.firstName}, \n\n' +
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, ' +
    'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\n\n' +
    'All the best,\n\n' +
    '{sender.firstName} {sender.lastName}'

  return (
    <GridRowWrapper
      buttonHidden={editingIndex === COMPONENT_INDEX}
      buttonDisabled={editingIndex !== null || disableEditing}
      buttonText={buttonText}
      subtitle={subtitle}
      title="Body"
      checked={messageBody.trim().length > 0}
      buttonOnClick={() => setEditingIndex(COMPONENT_INDEX)}
    >
      {editingIndex === COMPONENT_INDEX && (
        <Grid>
          <Grid.Row stretched>
            <Grid.Column width={13}>
              <Form style={{ width: '100%' }}>
                <Form.Field>
                  <label>Body</label>
                  <p>
                    Note: If you are sending to a list of User Ids — Placeholder
                    variables will not work. You cannot use the user name or the
                    AM name. Please ask Eng if confused.
                  </p>
                  <TextArea
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={onInputChange}
                  />
                </Form.Field>
                <Button
                  className="form-button"
                  disabled={!inputValue || inputValue.trim() === ''}
                  onClick={onSaveClicked}
                  primary
                >
                  Save
                </Button>
                <Button onClick={resetForm} basic>
                  Cancel
                </Button>
              </Form>
            </Grid.Column>
            <Grid.Column width={3}>
              <Form>
                <Form.Field>
                  <label>Placeholders</label>
                  <Label>{'{customer.firstName}'}</Label>
                  <Label>{'{customer.lastName}'}</Label>
                  <Label>{'{sender.firstName}'}</Label>
                  <Label>{'{sender.lastName}'}</Label>
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </GridRowWrapper>
  )
}

export default MessageRow
