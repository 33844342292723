import { SyntheticEvent, useEffect, useState } from 'react'
import { sortBy } from 'lodash'

import {
  Container,
  Header,
  Loader,
  Dimmer,
  Table,
  DropdownItemProps,
  DropdownProps,
  Dropdown,
} from 'semantic-ui-react'
import {
  createUserRoleWithId,
  fetchAllUsersIfNeeded,
  removeUserRoleWithId,
} from '../../actions/admin/adminAllUsersActions'
import { fetchAllRolesIfNeeded } from '../../actions/admin/roleActions'
import { HEARD_PROFILE_IMAGE_URL } from '../../constants/businessConstants'
import {
  Role,
  RoleName,
  selectAllRoles,
  selectRoleByName,
} from '../../reducers/admin/allRolesReducer'
import ProfileCell from './shared/ProfileCell'
import { useReselector } from '../../utils/sharedHooks'
import {
  getAllAdminUsersById,
  getAllUsersById,
  getIsAdministrator,
} from '../../selectors/user.selectors'
import { ConfirmUserDeletion } from './PaginatedUsersTable/controls'
import { useAppDispatch } from '../../utils/typeHelpers'

const SIDE_PADDING = '1rem'

const AllInternalUsersPanel = () => {
  const [fetching, setFetching] = useState(true)
  const [options, setOptions] = useState<DropdownItemProps[]>([])
  const allRoles = useReselector(selectAllRoles)
  const allUsers = useReselector(getAllUsersById)
  const allAdmins = useReselector(getAllAdminUsersById)
  const administratorRole = useReselector(
    selectRoleByName,
    RoleName.Administrator
  )
  const bookkeeperRole = useReselector(selectRoleByName, RoleName.Bookkeeper)
  const accountManagerRole = useReselector(
    selectRoleByName,
    RoleName.AccountManager
  )

  const isAdministrator = useReselector(getIsAdministrator)

  const dispatch = useAppDispatch()

  useEffect(() => {
    async function batchFetch() {
      await Promise.all([
        dispatch(fetchAllRolesIfNeeded()),
        dispatch(fetchAllUsersIfNeeded()),
      ])
      setFetching(false)
    }

    batchFetch()
  }, [dispatch])

  useEffect(() => {
    if (!fetching) {
      let roles = Object.values(allRoles)

      roles = sortBy(roles, (a: Role) => a.sortingIndex)

      setOptions(
        roles.map((r) => ({
          key: `dropdown-role-${r.id}`,
          text: r.name,
          value: r.id,
        }))
      )
    }
  }, [fetching, allRoles])

  const handleRoleUpdate = async (
    e: SyntheticEvent,
    data: DropdownProps,
    userId: number,
    prevRoleIds: number[]
  ) => {
    const idsSelected = data.value as number[]
    if (prevRoleIds.length === idsSelected.length) return

    const user = allAdmins[userId]

    if (prevRoleIds.length > idsSelected.length) {
      const removedId = prevRoleIds.find((role) => !idsSelected.includes(role))
      const numOfUsersAMFor = Object.values(allUsers).filter(
        (u) => u?.ownerId === userId
      )?.length

      const numOfUsersBKFoor = Object.values(allUsers).filter(
        (u) => u?.bookkeeperId === userId
      )?.length

      const numOfAdminsRoles = Object.values(allUsers).filter((u) =>
        u.roles?.find((role) => role.name === RoleName.Administrator)
      ).length

      if (
        administratorRole?.id &&
        removedId === administratorRole.id &&
        numOfAdminsRoles === 1
      ) {
        alert('Cannot remove admin role unless there is another admin')
        idsSelected.push(removedId)
        return
      }

      if (
        bookkeeperRole?.id &&
        removedId === bookkeeperRole.id &&
        numOfUsersBKFoor !== 0
      ) {
        alert(
          'Cannot remove bookkeeper role from a user with customers assigned'
        )
        idsSelected.push(removedId)
        return
      }
      if (
        accountManagerRole?.id &&
        removedId === accountManagerRole.id &&
        numOfUsersAMFor !== 0
      ) {
        alert(
          'Cannot remove account manager role from a user with customers assigned'
        )
        idsSelected.push(removedId)
        return
      }

      if (!removedId) {
        alert('Could not find role')
        return
      }

      await removeUserRoleWithId(userId, removedId)(dispatch)
      return
    }

    if (idsSelected.length > prevRoleIds.length) {
      /**
       * Need to get that last inserted value of the array here without
       * using pop(). When using pop(), it messes with the dropdown selection
       */
      const newRoleId = idsSelected[idsSelected.length - 1]
      await createUserRoleWithId(userId, {
        userId,
        isDefaultBookkeeper: false,
        isDefaultManager: false,
        avatarUrl: user?.roles?.[0]?.UserRole?.avatarUrl || null,
        checkinUrl: user?.roles?.[0]?.UserRole?.checkinUrl || null,
        roleId: newRoleId,
      })(dispatch)
    }
  }

  const rowData = Object.values(allAdmins)

  return (
    <Container id="allInternalUsersPanel">
      <Header as="h3">All Internal Users ({rowData.length})</Header>
      <p>Note: Only Administrators are allowed to change internal roles</p>
      <br />
      {fetching && (
        <Dimmer active inverted>
          <Loader inverted>Fetching all internal users</Loader>
        </Dimmer>
      )}
      {!fetching && (
        <Table striped padded="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell width={4}>Role</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {rowData.map((user) => {
              const fullName = `${user.firstName} ${user.lastName}`
              const numUsersOwned = Object.values(allUsers).filter(
                (u) => u.ownerId === user.id || u.bookkeeperId === user.id
              ).length

              const subtitle = `${numUsersOwned} ${
                numUsersOwned === 1 ? 'user' : 'users'
              }, id: ${user.id}`

              const roleIds = user?.roles?.map((role) => role?.id) || []

              return (
                <Table.Row key={`internal-user-row-${user.id}`}>
                  <ProfileCell
                    imageUrl={
                      user?.roles?.[0]?.UserRole?.avatarUrl ||
                      HEARD_PROFILE_IMAGE_URL
                    }
                    subtitle={subtitle}
                    style={{ paddingLeft: SIDE_PADDING }}
                    title={fullName}
                  />
                  <Table.Cell>{user.email}</Table.Cell>
                  <Table.Cell>
                    <Dropdown
                      fluid
                      multiple
                      selection
                      placeholder="Choose Role"
                      options={options}
                      defaultValue={roleIds}
                      disabled={!isAdministrator}
                      onChange={(e: SyntheticEvent, data: DropdownProps) =>
                        handleRoleUpdate(e, data, user.id, roleIds)
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <ConfirmUserDeletion user={user} />
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      )}
    </Container>
  )
}

export default AllInternalUsersPanel
