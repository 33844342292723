import { Grid, Image } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  selectPayrollCompany,
  selectPayrollProfile,
} from '../payroll.selectors'
import {
  Text,
  Card,
  Button,
  Icon,
  GridRowColumn,
  Link,
} from '../../../components/BaseComponents'
import { useState, useEffect } from 'react'
import {
  fetchPayrollProfileIfNeeded,
  fetchPayrollWcEnrollmentRequirements,
} from '../payrollActions'
import {
  GOVERNMENT,
  NEXT,
  TEXAS,
  WcEnrollmentRequirement,
} from '../payrollWcEnrollmentRequirement.slice'
import WorkersCompPanel from './WorkersCompPanel'
import WorkersCompModal from './WorkersCompModal'
import { useReselector } from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'

const stateToCopyMapping: { [key: string]: string } = {
  ND: "This state's program in managed by the government and not a private carrier and therefore is not supported/paid by Heard or our workers' comp partner. Please call the state directly at (800) 777-5033.",
  OH: "This state's program in managed by the government and not a private carrier and therefore is not supported/paid by Heard or our workers' comp partner. Please call the state directly at (800) 644-6292.",
}
const GUSTO_WC_ARTICLE =
  'https://support.gusto.com/article/106950575100000/Workers-Compensation-requirements-by-state'

const LogoRow = () => (
  <GridRowColumn style={{ display: 'inline' }}>
    <Image
      src="https://heard-images.s3.us-west-1.amazonaws.com/heard_logo_green_2x.png"
      alt="heard logo"
      style={{ height: 18, display: 'inline' }}
    />
    <Icon
      size="xs"
      color="green"
      style={{ marginLeft: 8, display: 'inline' }}
      icon={regular('plus')}
    />
    <Image
      src="https://heard-images.s3.us-west-1.amazonaws.com/assets/next_logo.png"
      style={{ height: 18, display: 'inline', paddingLeft: '8px' }}
    />
  </GridRowColumn>
)

const EnrollmentButtons = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Grid.Column width={5} verticalAlign="middle">
      <WorkersCompPanel buttonText="Get Started" />
      <br />
      <Button variant="link" onClick={() => setIsOpen(true)}>
        I already have this
      </Button>
      <WorkersCompModal open={isOpen} close={() => setIsOpen(false)} />
    </Grid.Column>
  )
}

export const WcManagementPanel = () => {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const payrollProfile = useReselector(selectPayrollProfile)
  useEffect(() => {
    dispatch(fetchPayrollProfileIfNeeded())
  }, [dispatch])

  if (payrollProfile?.wcProvider === NEXT) {
    return (
      <Grid>
        <Grid.Row />
        <Grid.Row>
          <Grid.Column width={6}>
            <Card type="section">
              <LogoRow />

              <br />
              <Text as="h2">View your Workers Compensation Plan</Text>
              <br />
              <Text as="bodyMd">
                It looks like you are enrolled or have started enrollment in a
                Workers compensation plan with Next. Manage the status and view
                details of your Workers Compensation Plan.
              </Text>
              <br />
              <Grid.Row>
                <WorkersCompPanel buttonText="Manage your Plan" />
              </Grid.Row>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  } else {
    return (
      <Grid>
        <Grid.Row />
        <Grid.Row>
          <Grid.Column width={6}>
            <Card type="section">
              <Text as="h2">
                You’re already enrolled in a workers’ compensation plan
              </Text>
              <br />
              <Text as="bodyMd">
                If this isn’t correct, please let us know.
              </Text>
              <br />
              <Button variant="secondary" onClick={() => setIsOpen(true)}>
                Update
              </Button>
              <WorkersCompModal open={isOpen} close={() => setIsOpen(false)} />
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}
/**
 *
 * This Panel is used only for WC Enrollment. This is also show on the Payroll Details tab _if_ a user has not enrolled AND they live in a state that allows Next WC
 *
 * @returns Enrollment Panel
 */
export const NextWcEnrollmentPanel = ({
  enrollmentRequirements,
  suppressIfEnrolled,
}: {
  enrollmentRequirements?: WcEnrollmentRequirement
  suppressIfEnrolled?: boolean
}) => {
  if (enrollmentRequirements?.show_enrollment_type !== 'default') return null
  if (!enrollmentRequirements?.show_enrollment && suppressIfEnrolled)
    return null
  if (!enrollmentRequirements?.show_enrollment) return <WcManagementPanel />
  return (
    <Grid>
      <Grid.Row />
      <Grid.Row>
        <Grid.Column width={9}>
          <Card type="section" variant="emphasis">
            <Grid>
              <Grid.Row verticalAlign="middle">
                <Grid.Column width={10}>
                  <LogoRow />
                  <br />
                  <Text as="h2">Get Covered by Workers’ Compensation</Text>
                  <br />
                  <Text as="bodyMd">
                    Workers&#39; compensation insurance protects and covers your
                    business against the unexpected while your employees are on
                    the job. Depending on your state, you may be required to
                    enroll in workers compensation.
                  </Text>
                </Grid.Column>
                <EnrollmentButtons />
              </Grid.Row>
            </Grid>
          </Card>
        </Grid.Column>
        <Grid.Column width={7}>
          <Card type="section">
            <Grid>
              <Grid.Row className="short">
                <Grid.Column width={16}>
                  <Text as="h2">
                    Do I need workers&#39; compensation insurance?
                  </Text>
                  <br />
                  <Text>
                    If you are the owner of your business and only employ
                    yourself, you generally are not required to purchase workers
                    compensation. However, if you have additional employees,
                    workers compensation may be required.
                  </Text>
                  <br />
                  <Text>
                    We encourage you to review your{' '}
                    <Link newPage href={GUSTO_WC_ARTICLE}>
                      state requirements here
                    </Link>
                    .
                  </Text>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
const WorkersCompensationTab = () => {
  const [enrollmentRequirements, setEnrollmentRequirements] =
    useState<WcEnrollmentRequirement>()
  const company = useReselector(selectPayrollCompany)

  const dispatch = useAppDispatch()
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchPayrollWcEnrollmentRequirements()(dispatch)
      if (res) {
        setEnrollmentRequirements(res)
      }
      await Promise.all([dispatch(fetchPayrollProfileIfNeeded(false))])
    }

    fetchData()
  }, [dispatch])

  if (!company) {
    return null
  }

  if (enrollmentRequirements?.show_enrollment_type === GOVERNMENT) {
    return (
      <Grid>
        <Grid.Row />
        <Grid.Row>
          <Grid.Column width={10}>
            <Card type="section" variant="emphasis">
              <Grid>
                <GridRowColumn width={16}>
                  <Text as="h2">
                    Workers&#39; compensation in {enrollmentRequirements?.state}
                  </Text>
                  <br />
                  <Text as="bodyMd">
                    Workers&#39; compensation insurance protects and covers your
                    business against the unexpected while your employees are on
                    the job.{' '}
                  </Text>
                  <br />
                  {enrollmentRequirements.state && (
                    <Text as="bodyMd">
                      {stateToCopyMapping[enrollmentRequirements.state]
                        ? stateToCopyMapping[`${enrollmentRequirements?.state}`]
                        : "This state's program in managed by the government and not a private carrier and therefore is not supported/paid by Heard or our workers' comp partner."}
                    </Text>
                  )}
                </GridRowColumn>
              </Grid>
            </Card>
          </Grid.Column>
          <Grid.Column width={6}>
            <Card type="section">
              <Grid>
                <Grid.Row className="short">
                  <Grid.Column width={16}>
                    <Text as="h2">
                      Do I need workers&#39; compensation insurance?
                    </Text>
                    <br />
                    <Text>
                      If you are the owner of your business and only employ
                      yourself, you generally are not required to purchase
                      workers compensation. However, if you have additional
                      employees, workers compensation may be required.
                    </Text>
                    <br />
                    <Text>
                      We encourage you to review your{' '}
                      <Link newPage href={GUSTO_WC_ARTICLE}>
                        state requirements here
                      </Link>
                      .
                    </Text>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  } else if (enrollmentRequirements?.show_enrollment_type === TEXAS) {
    return (
      <Card type="section">
        <Grid>
          <Grid.Row>
            <Grid.Column width={10}>
              <Text as="h2">
                Workers&#39; Compensation may not be necessary
              </Text>
              <br />
              <Text as="bodyMd">
                Workers&#39; compensation insurance protects and covers your
                business against the unexpected while your employees are on the
                job. Typically, workers compensation is not required in Texas
                unless you work for the government or in construction, but we
                encourage you to review your{' '}
                <Link newPage href={GUSTO_WC_ARTICLE}>
                  state requirements here
                </Link>
                <br />
                <br />
                You may still enroll in workers compensation if you would like.
              </Text>
            </Grid.Column>
            <EnrollmentButtons />
          </Grid.Row>
        </Grid>
      </Card>
    )
  } else {
    return (
      <NextWcEnrollmentPanel enrollmentRequirements={enrollmentRequirements} />
    )
  }
}

export default WorkersCompensationTab
