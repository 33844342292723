import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Lottie from 'lottie-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Button,
  Card,
  GridRowColumn,
  Icon,
  IconButton,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectFederalEstimateByTaxQuarter,
  selectStatusForTaxEstimate,
} from '../userTaxEstimates.selector'
import { selectActiveQTEDetailsAsDates } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../../utils/deviceWidthHelpers'
import taxBreakdown from '../../../../assets/lottie/taxBreakdown.json'
import { createLinkToTaxBreakdown } from './row-components/TaxEstimateCta'
import {
  selectIsSingleStateCalculation,
  selectUserTaxCalculationWithinActiveQuarter,
} from '../../../../selectors/userTaxCalculation.selectors'
import { EstimateStatus } from '../service'

const BANNER_CLOSED_KEY = 'breakdown_banner_closed'
const BreakdownBanner = () => {
  const navigate = useNavigate()
  const [bannerDismissed, setBannerDismissed] = useState(
    localStorage.getItem(BANNER_CLOSED_KEY) === 'true'
  )
  const activeQuarterDetails = useReselector(selectActiveQTEDetailsAsDates)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const federalTaxEstimate = useReselector(
    selectFederalEstimateByTaxQuarter,
    `${activeQuarterDetails?.taxYear}-${activeQuarterDetails?.taxQuarter}`
  )
  const calculation = useReselector(selectUserTaxCalculationWithinActiveQuarter)

  const isSingleStateCalc = useReselector(
    selectIsSingleStateCalculation,
    calculation?.id
  )

  const status = useReselector(
    selectStatusForTaxEstimate,
    federalTaxEstimate?.id
  )

  if (
    !bannerDismissed &&
    federalTaxEstimate &&
    isSingleStateCalc &&
    status &&
    ![
      EstimateStatus.awaitingEstimate,
      EstimateStatus.upcoming,
      EstimateStatus.joinedTooLate,
    ].includes(status)
  ) {
    return (
      <GridRowColumn>
        <Card type="section" backgroundColor="stone40">
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <IconButton
              icon={regular('close')}
              style={{ position: 'absolute', top: 10, right: 10 }}
              color="darkGray"
              size="lg"
              onClick={() => {
                localStorage.setItem(BANNER_CLOSED_KEY, 'true')
                setBannerDismissed(true)
              }}
            />
            <Lottie
              animationData={taxBreakdown}
              loop
              style={{
                borderRadius: 12,
                marginBottom: 20,
                marginRight: 25,
                height: 288,
                width: 450,
                boxShadow:
                  '0px 5.999999523162842px 17.99999237060547px 0px #00000026',
              }}
            />
            <div style={{ maxWidth: 550 }}>
              <Text as="h1">
                See how Heard calculated your federal tax estimate
              </Text>
              <br />
              <br />
              <br />
              {!isMobile && (
                <>
                  <Text color="darkGray">
                    Visit your new breakdown page to view the numbers that went
                    into your federal tax estimate calculation.
                  </Text>
                  <br />
                  <br />
                  <br />
                </>
              )}
              <Button
                onClick={() =>
                  navigate(createLinkToTaxBreakdown(federalTaxEstimate))
                }
                variant="secondary"
              >
                Let&apos;s See{' '}
                <Icon
                  icon={regular('arrow-right')}
                  style={{ marginLeft: 10 }}
                />
              </Button>
            </div>
          </div>
        </Card>
      </GridRowColumn>
    )
  }

  return null
}

export default BreakdownBanner
