import { useEffect, useMemo, useState } from 'react'
import { Container, Grid, Icon } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import { sortBy } from 'lodash'

import {
  fetchPayrollProfileIfNeeded,
  fetchEmployees,
  fetchContractors,
} from '../payrollActions'
import {
  selectActiveContractors,
  selectActiveEmployees,
  selectContractorByUuid,
  selectEmployeeByUuid,
  selectInactiveContractors,
  selectInActiveEmployees,
  selectJobByEmployeeUuid,
} from '../payroll.selectors'
import { getContractorName, isEmployee } from '../helpers'
import {
  Button,
  Tab,
  Table,
  Text,
  Label,
  Link,
  Card,
  GridRowColumn,
} from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'

const getTitleText = (activeTab: number) => {
  switch (activeTab) {
    case 0:
      return 'Below is a list of all active team members. Active team members are those that you pay and can include both employees and contractors.'
    case 1:
      return 'Below is a list of your Employees. Generally, employees of your business are full-time team members who receive a set salary and benefits.'
    case 2:
      return 'Below is a list of your Contractors. Generally, contractors of your business are contracted for pay to complete certain jobs or tasks.'
    case 3:
      return 'Inactive team members are employees or contractors that have been dismissed or marked as inactive.'
    default:
      return ''
  }
}

const PayrollEmployee = ({ uuid }: { uuid: string }) => {
  const employee = useReselector(selectEmployeeByUuid, uuid)
  const job = useReselector(selectJobByEmployeeUuid, uuid)

  if (!employee) {
    return null
  }

  const { first_name, last_name, onboarded, terminated, email } = employee

  return (
    <Table.Row>
      <Table.Cell>
        <Link to={`/payroll/employee/${uuid}`}>
          {last_name}, {first_name}
        </Link>
        {!onboarded && <Label color="yellow">Pending</Label>}
      </Table.Cell>
      <Table.Cell>{terminated ? 'Inactive' : 'Employee'}</Table.Cell>
      <Table.Cell>{job?.title}</Table.Cell>
      <Table.Cell>{email}</Table.Cell>
    </Table.Row>
  )
}

const PayrollContractor = ({ uuid }: { uuid: string }) => {
  const contractor = useReselector(selectContractorByUuid, uuid)

  if (!contractor) {
    return null
  }

  return (
    <Table.Row>
      <Table.Cell>
        <Link to={`/payroll/contractor/${uuid}`}>
          {getContractorName(contractor)}
        </Link>
      </Table.Cell>
      <Table.Cell>Contractor</Table.Cell>
      <Table.Cell />
      <Table.Cell>{contractor.email}</Table.Cell>
    </Table.Row>
  )
}

const PayrollEmployees = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const activeEmployees = useReselector(selectActiveEmployees)
  const inactiveEmployees = useReselector(selectInActiveEmployees)
  const activeContractors = useReselector(selectActiveContractors)
  const inactiveContractors = useReselector(selectInactiveContractors)
  const [activeTab, setActiveTab] = useState(0)
  const [loading, setLoading] = useState(false)

  const contractorsToShow = useMemo(() => {
    if ([0, 2].includes(activeTab)) {
      return activeContractors
    } else if (activeTab === 3) {
      return inactiveContractors
    }
    return []
  }, [activeContractors, activeTab, inactiveContractors])

  const employeesToShow = useMemo(() => {
    if ([0, 1].includes(activeTab)) {
      return activeEmployees
    } else if (activeTab === 3) {
      return inactiveEmployees
    }
    return []
  }, [activeEmployees, activeTab, inactiveEmployees])

  const panes = useMemo(
    () => [
      {
        menuItem: `All Active (${
          activeEmployees.length + activeContractors.length
        })`,
      },
      { menuItem: `Employees (${activeEmployees.length})` },
      { menuItem: `Contractors (${activeContractors.length})` },
      {
        menuItem: `Inactive (${
          inactiveEmployees.length + inactiveContractors.length
        })`,
      },
    ],
    [
      activeContractors.length,
      activeEmployees.length,
      inactiveContractors.length,
      inactiveEmployees.length,
    ]
  )

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(fetchPayrollProfileIfNeeded(false)),
        dispatch(fetchEmployees()),
        dispatch(fetchContractors()),
      ])

      setLoading(false)
    }

    fetchData()
  }, [dispatch])

  const teamMemberTable = useMemo(
    () => (
      <Table.Body>
        {sortBy([...employeesToShow, ...contractorsToShow], (mem) =>
          isEmployee(mem)
            ? `${mem.last_name} ${mem.first_name}`.toLowerCase()
            : getContractorName(mem)?.toLowerCase()
        ).map((mem) =>
          isEmployee(mem) ? (
            <PayrollEmployee key={mem.uuid} uuid={mem.uuid} />
          ) : (
            <PayrollContractor key={mem.uuid} uuid={mem.uuid} />
          )
        )}
      </Table.Body>
    ),
    [contractorsToShow, employeesToShow]
  )

  return (
    <Container id="payroll-employees">
      <Card>
        <Grid>
          <Grid.Row />
          <Grid.Row>
            <Grid.Column width={12}>
              <Text as="h1">Employees and Contractors</Text>
            </Grid.Column>
            <Grid.Column width={4}>
              <Button
                onClick={() => navigate('/payroll/create_team_member')}
                fullWidth
              >
                <Icon name="plus" />
                Add New Team Member
              </Button>
            </Grid.Column>
          </Grid.Row>
          <GridRowColumn>
            <Tab
              panes={panes}
              activeIndex={activeTab}
              onTabClick={(index) => setActiveTab(index)}
            />
          </GridRowColumn>
          <GridRowColumn>
            <Text>{getTitleText(activeTab)}</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3}>Name</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Type</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Job Title</Table.HeaderCell>
                  <Table.HeaderCell width={7}>Email</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {!loading && teamMemberTable}
            </Table>
          </GridRowColumn>
          <Grid.Row />
          <Grid.Row />
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollEmployees
