import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import ImportantDates from './ImportantDates'
import PageHeader from '../../../../components/shared/PageHeader'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { fetchAnnualTaxFilingsIfNeeded } from '../annualTaxFilings.slice'
import { logSentryMessage } from '../../../../utils/sentryHelpers'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const TaxYearTimeline = () => {
  const dispatch = useAppDispatch()
  const { year } = useParams()

  useEffect(() => {
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    dispatch(fetchAnnualTaxFilingsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    if (!year) {
      logSentryMessage('tax year timeline viewed without year')
    }
  }, [year])

  // It shouldn't be possible to hit this
  if (!year) {
    return null
  }

  const yearNum = Number(year)

  return (
    <>
      <PageHeader
        header={`${yearNum}-${yearNum + 1} Timeline`}
        backControl={{
          text: `Back to ${year} Annual Tax Center`,
          link: '/taxes/annual',
        }}
      />
      <Grid>
        <ImportantDates year={year} />
      </Grid>
    </>
  )
}

export default TaxYearTimeline
