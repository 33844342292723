import { FC } from 'react'
import { Button, Header, Table } from 'semantic-ui-react'
import { deleteTransactionRule } from '../../../actions/admin/transactionRuleActions'
import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import { selectTransactionRulesById } from '../../Transactions/transactions.selectors'
import { getAllTransactionCategories } from '../../Reports/reports.selectors'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useReselector } from '../../../utils/sharedHooks'

interface props {
  user: UserWithAdminInfo
}
const UserTransactionRulesTable: FC<props> = ({ user }) => {
  const dispatch = useAppDispatch()
  const allTransactionRules = useReselector(selectTransactionRulesById)
  const categories = useReselector(getAllTransactionCategories)

  const userTransactionRules = Object.values(allTransactionRules).filter(
    (rule) => rule.userId === user.id
  )

  const deleteRule = async (ruleId: number) => {
    await deleteTransactionRule(ruleId)(dispatch)
  }
  return (
    <div>
      <Header as="h4">{userTransactionRules.length} transaction rules</Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Rule Use</Table.HeaderCell>
            <Table.HeaderCell>Rule Text</Table.HeaderCell>
            <Table.HeaderCell>Rule Type</Table.HeaderCell>
            <Table.HeaderCell>Category Type</Table.HeaderCell>
            <Table.HeaderCell>Category Name</Table.HeaderCell>
            <Table.HeaderCell>Category Id</Table.HeaderCell>
            <Table.HeaderCell>For Amount</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {userTransactionRules.map((rule, i) => {
            const categoryName =
              rule.transactionCategoryId &&
              categories?.[rule.transactionCategoryId]?.name

            return (
              <Table.Row key={i}>
                <Table.Cell>{rule.id}</Table.Cell>
                <Table.Cell>{rule.ruleUse}</Table.Cell>
                <Table.Cell>{rule.ruleText}</Table.Cell>
                <Table.Cell>{rule.ruleType}</Table.Cell>
                <Table.Cell>{rule.transactionCategoryType}</Table.Cell>
                <Table.Cell>{categoryName}</Table.Cell>
                <Table.Cell>{rule.transactionCategoryId}</Table.Cell>
                <Table.Cell>{rule.amountInCents}</Table.Cell>
                <Table.Cell>
                  <Button basic primary onClick={() => deleteRule(rule.id)}>
                    Delete Rule
                  </Button>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </div>
  )
}

export default UserTransactionRulesTable
