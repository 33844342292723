import { Container, Dimmer, Loader as SemanticLoader } from 'semantic-ui-react'
import { useReselector } from '../../utils/sharedHooks'
import { getIsFetching } from '../../reducers/fetch'

interface Props {
  // If neither `loading` nor `fetchKey` is sent in the loading wheel will display
  // Determines if loader should display or not.  Serves as override
  loading?: boolean
  // Fetch key.  If sent will look in fetch state
  fetchKey?: string
}

const Loader = ({ loading, fetchKey }: Props) => {
  const isStateLoading = useReselector(getIsFetching, fetchKey)

  if (!loading || (fetchKey && !isStateLoading)) {
    return null
  }

  return (
    <Container>
      <Dimmer active inverted>
        <SemanticLoader inline />
      </Dimmer>
    </Container>
  )
}

export default Loader
