import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import {
  Alert,
  FormikInput,
  FormikLabelError,
  FormikRadioToggleButton,
  getFieldName,
  GridRowColumn,
  makeNumberSchema,
  makeReqBoolSchema,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { getFinancialProfile } from '../../../../selectors/user.selectors'
import {
  UPDATE_FINANCIAL_PROFILE_KEY,
  updateFinancialProfile,
} from '../../../../actions/financialProfileActions'
import {
  TAX_PROFILE_STEP,
  TaxesProfileFlowAnswerProps,
  useTaxProfileSteps,
} from './helpers'
import { dollarsToCents } from '../../../../utils/currencyHelpers'
import TaxProfileFormFooter from './TaxProfileFormFooter'
import { getFetchError } from '../../../../reducers/fetch'
import TaxProfileAccordions, {
  useTaxProfileAccordionCopy,
} from './TaxProfileAccordions'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useAnalyticsTrack } from '../../../Amplitude'

const Spouse1099Income = ({
  formFlowAnswers,
  setFormFlowAnswers,
}: TaxesProfileFlowAnswerProps) => {
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()
  const fp = useReselector(getFinancialProfile)
  const error = useReselector(
    getFetchError,
    UPDATE_FINANCIAL_PROFILE_KEY(fp?.id)
  )
  const { goToPreviousStep, goToNextStep } = useTaxProfileSteps(
    TAX_PROFILE_STEP.spouse1099Income
  )

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      spouseHasOtherIncome: formFlowAnswers.spouseIndividualIncome
        ? true
        : undefined,
      spouseOtherIncome: formFlowAnswers.spouseIndividualIncome,
    },
    onSubmit: async ({ spouseHasOtherIncome, spouseOtherIncome }) => {
      const res = await dispatch(
        updateFinancialProfile(fp?.id, {
          spouseIndividualIncomeInCents: spouseHasOtherIncome
            ? dollarsToCents(spouseOtherIncome)
            : null,
        })
      )

      if (res) {
        setFormFlowAnswers({
          ...formFlowAnswers,
          spouseIndividualIncome: spouseOtherIncome,
        })
        track('spouse 1099 income submitted', {
          spouse_has_1099_income: spouseHasOtherIncome ?? false,
          spouse_1099_income_in_cents: spouseHasOtherIncome
            ? dollarsToCents(spouseOtherIncome)
            : null,
          user_id: fp?.userId ?? null,
        })
        goToNextStep(res)
      }
    },
  })

  const { values, isSubmitting, isValid, submitForm, validateForm } = formik

  const faqCopy = useTaxProfileAccordionCopy()

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn {...makeGridConfig([12, 16, 16], true)}>
          <Text as="display2" textAlign="center">
            Does your spouse earn 1099 income?
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([12, 16, 16], true)}>
          <Text as="bodyLg" textAlign="center">
            This includes work from freelancing, contractor positions and
            short-term gigs.
          </Text>
        </GridRowColumn>

        {error && (
          <GridRowColumn {...makeGridConfig([12, 16, 16], true)}>
            <Alert type="error">{error.message}</Alert>
          </GridRowColumn>
        )}
        <GridRowColumn short {...makeGridConfig([12, 16, 16], true)}>
          <FormikLabelError
            name={getFieldName<typeof values>('spouseHasOtherIncome')}
            schema={makeReqBoolSchema()}
          />
        </GridRowColumn>
        <Grid.Row>
          <Grid.Column width={3} only="tablet computer" />
          <Grid.Column
            computer={5}
            tablet={5}
            mobile={16}
            style={{ marginBottom: 10 }}
          >
            <FormikRadioToggleButton
              name={getFieldName<typeof values>('spouseHasOtherIncome')}
              value
              fullWidth
              onClick={validateForm}
            >
              Yes
            </FormikRadioToggleButton>
          </Grid.Column>
          <Grid.Column computer={5} tablet={5} mobile={16}>
            <FormikRadioToggleButton
              name={getFieldName<typeof values>('spouseHasOtherIncome')}
              value={false}
              onClick={validateForm}
              fullWidth
            >
              No
            </FormikRadioToggleButton>
          </Grid.Column>
        </Grid.Row>

        {values.spouseHasOtherIncome && (
          <GridRowColumn {...makeGridConfig([8, 12, 16], true)}>
            <FormikInput
              fullWidth
              required
              name={getFieldName<typeof values>('spouseOtherIncome')}
              label="Year-to-date 1099 income from other jobs"
              description="This is money earned this year so far through contractor positions, short-term gigs, or other services."
              componentType="currency"
              schema={makeNumberSchema({
                allowedDecimals: 2,
                greaterThanZero: true,
              })}
            />
          </GridRowColumn>
        )}

        <Grid.Row />
        <TaxProfileAccordions
          faqs={[faqCopy.whySpouseIncomeOtherJobs, faqCopy.howFindSpouse1099]}
        />
        <TaxProfileFormFooter
          submitOrContinue={submitForm}
          goToPreviousStep={goToPreviousStep}
          continueDisabled={!isValid || isSubmitting}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default Spouse1099Income
