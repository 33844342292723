import { useCallback, useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { times } from 'lodash'
import moment from 'moment'

// BL
import { UPLOAD_COPY } from '../../copyConstants'
import { UploadScreenProps } from './index'
import {
  ManualTransactionModel,
  Transaction,
} from '../../../../reducers/admin/allTransactions.slice'
import { createMultipleManualTransactions } from '../../transactions.slice'
import { userUploadDocument } from '../../../../actions/awsActions'

// UI
import UploadBackButton from '../UploadBackButton'
import { parseErrorFromCatch } from '../../../../utils/errorHelpers'
import { centsToDollars } from '../../../../utils/currencyHelpers'
import { getCurrentUser } from '../../../../selectors/user.selectors'
import { Button, Text, Modal } from '../../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useReselector } from '../../../../utils/sharedHooks'

const UploadDuplicateRow = ({ page }: { page: UploadDuplicatePageModel }) => {
  const dateFormatted = page.transaction
    ? moment.utc(page.transaction.date).format(DATE_FORMATS.DISPLAY_SHORT)
    : ''

  return (
    <Grid style={{ minHeight: '85px', margin: '1em 0 0 0' }} columns={3}>
      <Grid.Row
        style={{ padding: '1em', borderRadius: '4px' }}
        className="add-transactions-success-banner"
      >
        <Grid.Column width={4} verticalAlign="middle" textAlign="left">
          <Text>{dateFormatted}</Text>
        </Grid.Column>
        <Grid.Column width={8} verticalAlign="middle" textAlign="left">
          <Text>{page.transaction?.description}</Text>
        </Grid.Column>
        <Grid.Column
          width={4}
          verticalAlign="middle"
          style={{ fontWeight: 'bold' }}
          textAlign="right"
        >
          <Text>$ {page.transaction?.amountInDollars ?? ''}</Text>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

/**
 * This screen receives a "data" object containing the following attributes:
 * - validTransactions - Array of unique, valid ManualTransactionModel(s) which are ready to be saved to Heard
 *
 * - databaseDuplicates - **Unique** array of stringified ManualTransactionModels
 *    This set of duplicates is processed first b/c removing duplicates here may remove # of pages. E.g. If a transaction
 *    exists in BOTH databaseDuplicates and spreadsheetDuplicates, and the user decides to remove the database duplicate,
 *    we should NOT show the spreadsheet duplicate page.
 *    onKeepDuplicate() -
 *      - Does it already exist in spreadsheet duplicates?
 *         Yes - do NOT add to validTransactions, wait for user to work through within spreadsheet dups
 *         No  - add it to validTransactions
 *      - Increment page index
 *    onRemoveDuplicate()
 *      - Do not add it to validTransactions
 *      - Does it already exist in spreadsheet duplicates?
 *         Yes - remove it from spreadsheet duplicates
 *         No  - do nothing
 *      - Increment page index
 *
 * - spreadsheetDuplicates - An object whose key is a stringified ManualTransactionModel, and value is
 *                           equal to the # of times this transaction appears within the spreadsheet. For example,
 *    {
 *      '{"amountInCents":123,"date":"2021-11-17","description":"abc"}' : 3
 *    }
 *    onKeepDuplicate() -
 *      - Add n # of ManualTransactionModel to validTransactions
 *      - n will be the # of occurrences
 *      - Increment page index
 *    onRemoveDuplicate() -
 *      - Only add 1 ManualTransactionModel to validTransactions
 *      - Increment page index
 *
 * - spreadsheetFile (nullable)
 *
 * Upon receiving data, we:
 * 1. Populate our "pages" variable with UploadDuplicatePageModel(s)
 * 2. Set the current page index
 * 3. Render the page on screen
 *    - This method is called upon navigating to the next page. As such, we check to ensure the next page exists
 *    - If the next page does not exist, we are finished reviewing, and move to step #4
 * 4. Save valid transactions. On save success, we upload the spreadsheetFile (if not null) to Heard
 *
 */

interface UploadDuplicatePageModel {
  id: string
  type: 'database-dup' | 'spreadsheet-dup'
  transaction: ManualTransactionModel
  numOccurrencesToShow: number
}

const UploadDuplicateScreen = ({
  goToScreenWithData,
  data,
  finishFlow,
  onUploadComplete,
}: UploadScreenProps) => {
  const dispatch = useAppDispatch()
  const [init, setInit] = useState(false)
  const [currentPage, setCurrentPage] = useState<UploadDuplicatePageModel>()
  const [currentPageIndex, setCurrentPageIndex] = useState<number>()
  const [pages, setPages] = useState<Array<UploadDuplicatePageModel>>([])
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState<null | string>()
  const currentUser = useReselector(getCurrentUser)
  const {
    validTransactions,
    spreadsheetDuplicates,
    databaseDuplicates,
    spreadsheetFile,
  } = data || {}

  const performSave = useCallback(async () => {
    setSaving(true)

    try {
      if (validTransactions && validTransactions.length > 0) {
        const result =
          await createMultipleManualTransactions(validTransactions)(dispatch)
        const payload = result.map((t: Transaction) => ({
          id: Number(t.id),
          date: t.date,
          description: t.description,
          amountInDollars: centsToDollars(t.amountInCents).toString(),
        }))
        if (result) {
          if (spreadsheetFile && currentUser) {
            const docRes = await dispatch(
              userUploadDocument({
                file: spreadsheetFile,
                user: currentUser,
                documentType: 'other',
              })
            )
            if (docRes && 'error' in docRes) {
              throw new Error(docRes.error)
            }
          }
          onUploadComplete?.({ success: true, data: payload })
        } else {
          onUploadComplete?.({ success: false })
        }
      }

      finishFlow()
    } catch (err) {
      setError(parseErrorFromCatch(err))
    }

    setSaving(false)
  }, [
    validTransactions,
    finishFlow,
    dispatch,
    spreadsheetFile,
    currentUser,
    onUploadComplete,
  ])

  // 1
  useEffect(() => {
    // Data has been set, populate the pages
    if (data && Object.keys(data).length > 0) {
      const pagesArray: Array<UploadDuplicatePageModel> = []

      // Database duplicates first, then spreadsheet duplicates
      databaseDuplicates?.forEach((dupStringified: string) => {
        const dup: ManualTransactionModel = JSON.parse(dupStringified)
        pagesArray.push({
          id: dupStringified,
          type: 'database-dup',
          transaction: dup,
          numOccurrencesToShow: 1, // database duplicates always show 1 green row
        })
      })

      if (spreadsheetDuplicates) {
        Object.keys(spreadsheetDuplicates).forEach((key: string) => {
          pagesArray.push({
            id: key,
            type: 'spreadsheet-dup',
            transaction: JSON.parse(key),
            numOccurrencesToShow: spreadsheetDuplicates[key],
          })
        })
      }

      setPages(pagesArray)
    }
  }, [data, databaseDuplicates, spreadsheetDuplicates, setPages])

  // 2
  useEffect(() => {
    // Pages has been set, set the current index (which renders it)
    if (pages.length > 0 && !init) {
      setInit(true)
      setCurrentPageIndex(0)
    }
  }, [pages, init, setCurrentPageIndex, setInit])

  // 3
  useEffect(() => {
    if (typeof currentPageIndex === 'undefined') {
      return
    }

    // Render the current page

    if (currentPageIndex < pages.length) {
      // The page index has changed, navigate to next page
      setCurrentPage(pages[currentPageIndex])
    } else {
      // No more pages! time to save validTransactions
      performSave()
    }
  }, [currentPageIndex, pages, performSave, setCurrentPage])

  const goToLandingScreen = () => goToScreenWithData({ index: 0 })
  const goToNextPage = () =>
    setCurrentPageIndex((prev) => (typeof prev !== 'undefined' ? prev + 1 : 0))

  const onRemoveDuplicate = () => {
    if (currentPage?.type === 'database-dup' && spreadsheetDuplicates) {
      // Does dup already exist in spreadsheet duplicates?
      const spreadsheetDuplicate = spreadsheetDuplicates[currentPage.id]

      if (spreadsheetDuplicate) {
        // Remove it from pages
        const index = pages.findIndex(
          (p) =>
            p.type === 'spreadsheet-dup' &&
            p.id === spreadsheetDuplicate.toString()
        )
        const pagesClone = pages.map((p) => {
          return { ...p }
        })
        pagesClone.splice(index, 1)
        setPages(pagesClone)
      }
    } else if (currentPage?.type === 'spreadsheet-dup') {
      // Only add 1 ManualTransactionModel to validTransactions
      currentPage.transaction.description =
        currentPage.transaction.description.trim()
      validTransactions?.push(currentPage.transaction)
    }

    goToNextPage()
  }

  const onKeepDuplicate = () => {
    if (currentPage?.type === 'database-dup') {
      // Does dup already exist in spreadsheet duplicates?
      const key = JSON.stringify(currentPage.transaction)

      if (
        spreadsheetDuplicates &&
        !spreadsheetDuplicates[key] &&
        validTransactions
      ) {
        // No  - add it to validTransactions
        validTransactions.push(currentPage.transaction)
      }
    } else if (currentPage?.type === 'spreadsheet-dup' && validTransactions) {
      // Add n # of ManualTransactionModel to validTransactions

      for (let x = 0; x < currentPage.numOccurrencesToShow; x++) {
        validTransactions.push(currentPage.transaction)
      }
    }

    goToNextPage()
  }

  const titleText =
    currentPageIndex && currentPageIndex >= pages.length
      ? 'Saving transactions ...'
      : `Review potential duplicates (${
          typeof currentPageIndex !== 'undefined' ? currentPageIndex + 1 : 0
        } of ${pages.length})`

  const subtitle =
    currentPage?.type === 'database-dup'
      ? UPLOAD_COPY.DUPLICATE_DATABASE_SUBTITLE
      : UPLOAD_COPY.DUPLICATE_SPREADSHEET_SUBTITLE

  const removeButtonText =
    currentPage?.type === 'database-dup'
      ? 'Yes, remove duplicate'
      : 'Yes, remove duplicates'

  const keepButtonText =
    currentPage?.type === 'database-dup' ? 'No, keep it' : 'No, keep them'

  return (
    <>
      <Modal.Header>
        <UploadBackButton disabled={saving} onClick={goToLandingScreen} />
        <div
          style={{ marginTop: '1.25em', fontWeight: 'bold' }}
          className="padding-with-border"
        >
          <Text>{titleText}</Text>
          <Text as="bodySm">{subtitle}</Text>
        </div>
      </Modal.Header>
      <Modal.Content>
        {currentPage &&
          times(currentPage.numOccurrencesToShow).map((num) => (
            <UploadDuplicateRow key={`dup-row-${num}`} page={currentPage} />
          ))}
        <Grid style={{ margin: '2rem 0' }} columns={2}>
          {error && <Text color="red">{error}</Text>}
          <Grid.Row>
            <Grid.Column style={{ paddingLeft: '0px' }} width="8">
              <Button
                disabled={saving}
                onClick={onKeepDuplicate}
                size="large"
                fullWidth
              >
                {keepButtonText}
              </Button>
            </Grid.Column>
            <Grid.Column style={{ paddingRight: '0px' }} width="8">
              <Button
                disabled={saving}
                onClick={onRemoveDuplicate}
                size="large"
                fullWidth
              >
                {removeButtonText}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </>
  )
}

export default UploadDuplicateScreen
