import { useFormikContext } from 'formik'
import { Grid } from 'semantic-ui-react'

import {
  Alert,
  FormikInput,
  FormikLabelError,
  FormikRadioToggleButton,
  GridRowColumn,
  getFieldName,
  makeNumberSchema,
  makeReqBoolSchema,
  GridColumnSpacer,
} from '../../../../components/BaseComponents'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import { getProjectedW2Income } from './helpers'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectActiveQuarterlyTaxEstimateDetails } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { LabelDescription } from '../../../../components/BaseComponents/Input'
import { formatCurrency } from '../../../../utils/currencyHelpers'
import { W2IncomeWarning } from './W2IncomeWarning'
import { QuarterlyTaxEstimateDetail } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import PaystubVsEstimateAccordion, {
  PaystubAccordionContent,
} from './PaystubVsEstimateAccordion'

interface W2Values {
  moneyOutsideOfPractice: boolean | undefined
  wIncome: number | null | undefined
  enterPaystubW2: boolean | null
  estimatedAnnualW2Income?: number | null
}

const w2IncomeCopy = (activeQuarterDetails?: QuarterlyTaxEstimateDetail) => {
  switch (activeQuarterDetails?.taxQuarter) {
    case '1':
      return "Enter income earned from your additional W-2 job(s) for pay periods starting January 1st through February 28th. You'll find this on the first paycheck you received in March."
    case '2':
      return "Enter income earned from your additional W-2 job(s) for pay periods starting January 1st through April 30th. You'll find this on the first paycheck you received in May."
    case '3':
      return "Enter income earned from your additional W-2 job(s) for pay periods starting January 1st through July 31st. You'll find this on the first paycheck you received in August."
    case '4':
      return "Enter income earned from your additional W-2 job(s) for pay periods starting January 1st through November 30st. You'll find this on the first paycheck you received in December."
    // should never happen, but fall back to generic copy
    default:
      return 'Please include income only from your additional W-2 job(s). You can find this on your most recent paystub(s).'
  }
}

const W2Job = ({ isScorp }: { isScorp: boolean }) => {
  const activeQuarterDetails = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )

  const { values, touched } = useFormikContext<W2Values>()

  const projectedOtherW2Income = getProjectedW2Income({
    ytdIncomeInDollars: values.wIncome,
    quarterDetails: activeQuarterDetails,
  })

  const w2IncomeSubcopy = w2IncomeCopy(activeQuarterDetails)

  return (
    <>
      <GridRowColumn {...makeGridConfig([8, 12, 16], true)}>
        <FormikLabelError
          name={getFieldName<W2Values>('moneyOutsideOfPractice')}
          label={`Do you have ${isScorp ? 'additional' : 'a'} W-2 job${isScorp ? 's' : ''}?`}
          description={
            <>
              We’re referring to W-2 jobs <b>outside</b> of your private
              practice, e.g. working for a group practice, hospital, government
              agency, etc.
            </>
          }
          schema={makeReqBoolSchema()}
        />
      </GridRowColumn>
      <Grid.Row>
        <Grid.Column computer={5} tablet={4} only="tablet computer" />
        <Grid.Column computer={3} tablet={4} mobile={16}>
          <FormikRadioToggleButton
            name={getFieldName<W2Values>('moneyOutsideOfPractice')}
            value
            fullWidth
          >
            Yes
          </FormikRadioToggleButton>
        </Grid.Column>
        <GridColumnSpacer mobile />
        <Grid.Column computer={3} tablet={4} mobile={16}>
          <FormikRadioToggleButton
            name={getFieldName<W2Values>('moneyOutsideOfPractice')}
            value={false}
            fullWidth
          >
            No
          </FormikRadioToggleButton>
        </Grid.Column>
      </Grid.Row>
      {values.moneyOutsideOfPractice && (
        <>
          <GridRowColumn {...makeGridConfig([8, 12, 16], true)}>
            <FormikLabelError
              name={getFieldName<typeof values>('enterPaystubW2')}
              label="How would you like to enter income?"
              description="Click on “How should I choose?” below if you need help deciding how to enter income."
              schema={makeReqBoolSchema()}
            />
          </GridRowColumn>
          <GridRowColumn {...makeGridConfig([8, 12, 16], true)} short>
            <FormikRadioToggleButton
              name={getFieldName<typeof values>('enterPaystubW2')}
              value
              fullWidth
            >
              Year-to-date from paystub
            </FormikRadioToggleButton>
          </GridRowColumn>
          <GridRowColumn {...makeGridConfig([8, 12, 16], true)} short>
            <FormikRadioToggleButton
              name={getFieldName<typeof values>('enterPaystubW2')}
              value={false}
              fullWidth
            >
              Estimated income
            </FormikRadioToggleButton>
          </GridRowColumn>

          <Grid.Row />

          {values.enterPaystubW2 && (
            <GridRowColumn {...makeGridConfig([8, 12, 16], true)}>
              <Grid>
                <GridRowColumn>
                  <LabelDescription
                    required
                    label={`Year-to-Date gross income from${isScorp ? ' additional ' : ' '}W-2 job(s)`}
                    description={w2IncomeSubcopy}
                  />
                </GridRowColumn>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <FormikInput
                      name={getFieldName<typeof values>('wIncome')}
                      required
                      fullWidth
                      componentType="currency"
                      schema={makeNumberSchema({
                        allowedDecimals: 2,
                        greaterThanZero: true,
                      })}
                      placeholder="$"
                    />
                  </Grid.Column>
                  {projectedOtherW2Income && (
                    <Grid.Column width={8}>
                      <Alert
                        style={{ padding: 15 }}
                        customIcon={' '} //no icon
                        title={`${formatCurrency(projectedOtherW2Income, { hideDecimalsIfZero: true })} projected annually`}
                      />
                    </Grid.Column>
                  )}
                </Grid.Row>
                <W2IncomeWarning
                  inputTouched={touched.wIncome}
                  w2Income={values.wIncome}
                />
              </Grid>
            </GridRowColumn>
          )}

          {values.enterPaystubW2 === false && (
            <GridRowColumn {...makeGridConfig([8, 12, 16], true)}>
              <FormikInput
                name={getFieldName<typeof values>('estimatedAnnualW2Income')}
                required
                fullWidth
                componentType="currency"
                schema={makeNumberSchema({
                  allowedDecimals: 2,
                  greaterThanZero: true,
                })}
                placeholder="$"
                label={
                  isScorp
                    ? `${activeQuarterDetails?.taxYear} Annual gross income for all other W-2 job(s)`
                    : `${activeQuarterDetails?.taxYear} Annual gross income for your W-2 job`
                }
                description={
                  isScorp
                    ? 'Please include income only from your additional W-2 job(s).'
                    : 'Please include income only from your W-2 job'
                }
              />
            </GridRowColumn>
          )}

          <GridRowColumn {...makeGridConfig([12, 16, 16], true)}>
            <PaystubVsEstimateAccordion
              content={PaystubAccordionContent.sprop}
            />
          </GridRowColumn>
        </>
      )}
    </>
  )
}

export default W2Job
