import Alert from '../components/BaseComponents/Alert'

const message =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non et amet ornare et. Eget sit maecenas eu integer. Nibh ultrices scelerisque aliquet tincidunt quis sit. Donec cursus felis lectus integer mi feugiat.'

export const SampleAnnouncementBanner = () => (
  <Alert style={{ marginBottom: 8 }} type="announcement">
    With tax season in full swing, please note that our team will be slower to
    respond to messages. We appreciate your patience during this time!
  </Alert>
)

export const SampleErrorAlert = () => (
  <Alert
    style={{ marginBottom: 8, marginTop: 78 }}
    title="Error message alert"
    type="error"
  >
    {message}
  </Alert>
)

export const SampleInfoAlert = () => (
  <Alert style={{ marginBottom: 8 }} title="Informational alert" type="info">
    {message}
  </Alert>
)

export const SampleWarnAlert = () => (
  <Alert style={{ marginBottom: 8 }} title="Warning alert" type="warn">
    {message}
  </Alert>
)

export const CustomIconAlert = () => (
  <Alert
    style={{ marginBottom: 8 }}
    title="Custom Icon alert"
    type="warn"
    customIcon="👉"
  >
    {message}
  </Alert>
)

export const CloseableAlert = () => (
  <Alert closeable title="Closable alert" type="warn">
    {message}
  </Alert>
)
