import { flatten } from 'lodash'
import { createSelector } from 'reselect'
import { ReduxState } from '../../../utils/typeHelpers'

export const selectQAFeatures = (state: ReduxState) =>
  state.admin.allQAFeatures?.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  )

export const selectPathwayByIdentifier = createSelector(
  [
    selectQAFeatures,
    (_: unknown, userPathwayIdentifier: string) => userPathwayIdentifier,
  ],
  (features, pathwayIdentifier) =>
    flatten(features?.map((feature) => feature.pathways)).find(
      (pathway) => pathway.identifier === pathwayIdentifier
    )
)
