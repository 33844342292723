import { ReactNode } from 'react'
import { Icon } from 'semantic-ui-react'

import { Button, Popup, Table } from '../../../../components/BaseComponents'
import {
  AnnualTaxDetail,
  AnnualTaxDetailStatuses,
} from '../annualTaxDetails.slice'
import {
  DATE_FORMATS_LUXON,
  formatISOFromUTC,
} from '../../../../utils/dateHelpers'
import { useToggle } from '../../../../utils/sharedHooks'

interface Props {
  details: AnnualTaxDetail
  onEditClick: () => void
}

const StyledCell = ({
  children,
  status,
}: {
  children: ReactNode
  status: AnnualTaxDetailStatuses
}) => (
  <Table.Cell positive={status === AnnualTaxDetailStatuses.current}>
    {children}
  </Table.Cell>
)

const TimeCell = ({
  status,
  date,
}: {
  status: AnnualTaxDetailStatuses
  date?: string
}) => (
  <StyledCell status={status}>
    {formatISOFromUTC(date, DATE_FORMATS_LUXON.DISPLAY_ABBR)}
  </StyledCell>
)

const SubTableRow = ({
  title,
  status,
  popupText,
  date,
}: {
  title: string
  status: AnnualTaxDetailStatuses
  popupText: string
  date?: string
}) => (
  <Table.Row>
    <StyledCell status={status}>
      <Popup
        trigger={<Button variant="tooltipLink">{title}</Button>}
        content={popupText}
      />
    </StyledCell>
    <TimeCell status={status} date={date} />
  </Table.Row>
)

const SubTable = ({
  details,
  form,
}: {
  details: AnnualTaxDetail
  form: 'form_1040' | 'form_1120_s'
}) => {
  const { status } = details

  const [drawerOpen, toggleDrawer] = useToggle(false)
  const tqDueDates = details.taxQuestionnaireDueDates?.[form]
  const irsFormDueDates = details.irsFormDueDates[form]

  return (
    <Table.Cell onClick={toggleDrawer}>
      <Icon name={drawerOpen ? 'caret down' : 'caret right'} size="big" />
      {drawerOpen && (
        <Table>
          <SubTableRow
            title="IRS Due"
            popupText="The IRS regular filing due date for this form."
            status={status}
            date={irsFormDueDates.irs.dueDate}
          />
          <SubTableRow
            title="IRS extended due date"
            popupText="The IRS extension filing due date for this form."
            status={status}
            date={irsFormDueDates.irs.extendedDueDate}
          />
          <SubTableRow
            title="INTERNAL Due"
            popupText="The Heard deadline for users to complete their tax checklist in order to file this form for the current tax year."
            status={status}
            date={irsFormDueDates.internal.dueDate}
          />
          <SubTableRow
            title="INTERNAL extended due date"
            popupText="The Heard deadline that we tell users they need to complete the extension survey by."
            status={status}
            date={irsFormDueDates.internal.dueDate}
          />
          <SubTableRow
            title="IRS external file due date"
            popupText="The IRS deadline to file an extension for this form."
            status={status}
            date={irsFormDueDates.irs.extensionFileDueDate}
          />
          <SubTableRow
            title="INTERNAL extended request cutoff date"
            popupText="The Heard deadline that we tell users they need to complete the extension survey by."
            status={status}
            date={irsFormDueDates.irs.extensionFileDueDate}
          />
          <SubTableRow
            title="TQ start date"
            popupText="The date that users can begin the tax checklist for this form"
            status={status}
            date={tqDueDates?.startAt}
          />
          <SubTableRow
            title="TQ end date"
            popupText="The date that non-extended users need to complete their tax checklist"
            status={status}
            date={tqDueDates?.endAt}
          />
          <SubTableRow
            title="TQ cut off date"
            popupText="The date that users will no longer be able to access the tax checklist"
            status={status}
            date={tqDueDates?.cutoffDate}
          />
          <SubTableRow
            title="Late joiner on time cutoff date"
            popupText="The cutoff date for when late joiners can opt into an extension."
            status={status}
            date={tqDueDates?.lateJoinerOnTimeCutoffDate}
          />
          <SubTableRow
            title="Late joiner automatic extension cutoff date"
            popupText="The cutoff date for late joiners will be automatically extended"
            status={status}
            date={tqDueDates?.lateJoinerAutomaticExtensionCutoffDate}
          />
          <SubTableRow
            title="Late joiner final cutoff date"
            popupText="Late joiners who join after this date will not be able to file their annual taxes with Heard for this year."
            status={status}
            date={tqDueDates?.lateJoinerFinalCutoffDate}
          />
        </Table>
      )}
    </Table.Cell>
  )
}

const AnnualTaxDetailsRow = ({ details, onEditClick }: Props) => (
  <Table.Row>
    <StyledCell status={details.status}>
      {details.status !== AnnualTaxDetailStatuses.past && (
        <Button icon="pencil" onClick={onEditClick} />
      )}
    </StyledCell>
    <StyledCell status={details.status}>{details.taxYear}</StyledCell>
    <StyledCell status={details.status}>{details.status}</StyledCell>
    <TimeCell status={details.status} date={details.taxSeasonKickoffStartAt} />
    <TimeCell status={details.status} date={details.taxSeasonKickoffDueAt} />
    <TimeCell status={details.status} date={details.ten99NECFormsStartAt} />
    <TimeCell status={details.status} date={details.ten99NECFormsDueAt} />
    <TimeCell status={details.status} date={details.financialOverviewDueAt} />
    <TimeCell status={details.status} date={details.newUserCutOffAt} />

    {(['form_1040', 'form_1120_s'] as const).map((form) => (
      <SubTable key={form} form={form} details={details} />
    ))}
    <TimeCell status={details.status} date={details.updatedAt} />
  </Table.Row>
)

export default AnnualTaxDetailsRow
