import { useEffect, useState } from 'react'

import {
  fetchKeyForCode,
  fetchRecipientByCode,
  RecipientDetails,
  ReferralStatus,
  updateUserReferralStatusIfExists,
} from './referrals.slice'
import { useReselector } from '../../utils/sharedHooks'
import { getIsFetchingOrNotStarted } from '../../reducers/fetch'
import { useAppDispatch } from '../../utils/typeHelpers'

/**
 * A user has "accepted" their invitation if their status is >= account_created or greater
 */
export const hasAlreadyAcceptedInvitation = (status: ReferralStatus) =>
  status !== ReferralStatus.invited &&
  status !== ReferralStatus.clicked_invitation_link

export const useReferralFetch = (recipientCode: string | undefined) => {
  const dispatch = useAppDispatch()
  const [recipient, setRecipient] = useState<RecipientDetails>()

  const loading = useReselector(
    getIsFetchingOrNotStarted,
    fetchKeyForCode(recipientCode)
  )

  useEffect(() => {
    async function performAsync() {
      if (!recipientCode) {
        return
      }

      const recipientDetails =
        await fetchRecipientByCode(recipientCode)(dispatch)

      setRecipient(recipientDetails)

      if (recipientDetails?.email) {
        updateUserReferralStatusIfExists({
          status: ReferralStatus.clicked_invitation_link,
          notifyIfNonexistent: true,
          // bc this component is rendered exclusively for folks who have been referred, we would expect a
          // user_referral_status to exist and we want to be notified if it doesn't
          recipientEmail: recipientDetails.email,
        })(dispatch)
      }
    }
    performAsync()
  }, [dispatch, recipientCode])

  return {
    recipient,
    loading,
  }
}
