import { Divider, Grid, Image } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import {
  Accordion,
  Button,
  Card,
  GridRowColumn,
  Link,
  Text,
} from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import { ContainerStyle } from '../SetupLanding/SetupLandingCard'

export const IncompleteAccount = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/dashboard')
  }

  return (
    <ContainerStyle>
      <Text as="display2">Your Heard account is incomplete</Text>

      <Card
        style={{
          backgroundColor: Colors.lightOrange,
        }}
      >
        <Grid>
          <Grid.Row>
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/pavilion_exclamation_mark.png"
              style={{ width: 80, height: 80 }}
            />
          </Grid.Row>
          <Grid.Row>
            <Text style={{ marginBottom: '8px' }}>
              You’ve indicated that your account(s) could not be connected with
              Plaid. We’ll follow up with you via Conversations.
            </Text>
            <Text>
              In the meantime, we recommend replacing them with accounts at
              compatible banks. A compatible bank allows Heard to provide you
              the most consistent service.
            </Text>
          </Grid.Row>
          <Grid.Row>
            <Button variant="primary">Connect Account</Button>
          </Grid.Row>
          <Divider />
          <Grid.Row style={{ paddingTop: 0 }}>
            <Accordion
              title="Where can I open a business checking account?"
              style={{ marginBottom: 16 }}
              content={
                <Text>
                  You can open a business checking account with any bank or
                  provider, then come back when you’re ready. We recommend going
                  with a larger institution. They’re more compatible with
                  Heard’s features, including automatic monthly statement
                  uploads.
                  <br />
                  <br />
                  Some popular banks with Heard customers:
                  <br />
                  <br />
                  <ul style={{ paddingLeft: 10, marginTop: 0 }}>
                    <li>
                      <Link
                        as="secondaryLink"
                        href="https://www.bluevine.com/partner/heard-checking"
                        newPage
                      >
                        Bluevine
                      </Link>
                    </li>
                    <li>
                      <Link
                        as="secondaryLink"
                        href="https://www.bankofamerica.com/smallbusiness/deposits/business-advantage-banking/"
                        newPage
                      >
                        Bank of America
                      </Link>
                    </li>
                    <li>
                      <Link
                        as="secondaryLink"
                        href="https://www.becu.org/business-banking/checking"
                        newPage
                      >
                        BECU
                      </Link>
                    </li>
                    <li>
                      <Link
                        as="secondaryLink"
                        href="https://www.chase.com/business/banking/checking"
                        newPage
                      >
                        Chase
                      </Link>
                    </li>
                    <li>
                      <Link
                        as="secondaryLink"
                        href="https://www.td.com/us/en/small-business/checking-accounts"
                        newPage
                      >
                        TD Bank
                      </Link>
                    </li>
                    <li>
                      <Link
                        as="secondaryLink"
                        href="https://www.wellsfargo.com/biz/checking/"
                        newPage
                      >
                        Wells Fargo
                      </Link>
                    </li>
                  </ul>
                  <Link href="#" newPage>
                    Connecting your bank account with Plaid -{'>'}
                  </Link>
                </Text>
              }
              variant="text"
            />{' '}
            <Accordion
              title="What if I don’t want to replace my account(s)?"
              content={
                <>
                  <Text>
                    Our team will reach out to help you with your situation.
                    However, we strongly recommend replacing your accounts, as
                    Plaid-incompatible accounts significantly delay bookkeeping
                    and access to your reports.
                  </Text>
                  <Text>
                    We might not be able to support the account(s) if it
                    prevents us from providing consistent service.
                  </Text>
                </>
              }
              variant="text"
            />{' '}
          </Grid.Row>
        </Grid>
      </Card>

      <GridRowColumn
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button variant="secondary" size="medium" onClick={handleClick}>
          View Dashboard
        </Button>
      </GridRowColumn>
    </ContainerStyle>
  )
}

export default IncompleteAccount
