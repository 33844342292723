import { useState } from 'react'
import { Header, Table, Button, Modal, Form, Label } from 'semantic-ui-react'
import { orderBy } from 'lodash'
import moment from 'moment'

import {
  updateTransactionCategory,
  TransactionCategory,
  TransactionCategoryFinancialStatements,
  TransactionAccountTypes,
  BalanceSheetCategories,
} from '../../features/Reports/reports.slice'
import { ChartOfAccount } from '../../features/ChartOfAccounts/chartOfAccount.slice'

import { DATE_FORMATS } from '../../utils/dateHelpers'
import { valsToDropdownOptions, Dropdown } from '../BaseComponents'
import { useAppDispatch } from '../../utils/typeHelpers'

const TransactionCategoryEditModal = ({
  category,
  open,
  close,
}: {
  open: boolean
  close: () => void
  category: TransactionCategory
}) => {
  const dispatch = useAppDispatch()
  const [name, setName] = useState(category.name)
  const [accountType, setAccountType] = useState(category.accountType)

  const submit = async () => {
    const data = {
      name,
      accountType,
    }

    const result = await updateTransactionCategory(category.id, data)(dispatch)
    if (result) {
      close()
    }
  }

  return (
    <Modal
      size={'small'}
      dimmer={'inverted'}
      className="transactionCategoryCreationModal"
      open={open}
      onClose={close}
    >
      <Modal.Content>
        <Header as="h4">Edit Transaction Category</Header>

        <Form>
          <Form.Field>
            <label>Transaction Account Type</label>
            <Dropdown
              value={accountType}
              onChange={setAccountType}
              options={valsToDropdownOptions(
                Object.values(TransactionAccountTypes)
              )}
              required
              fluid
            />
          </Form.Field>

          <Form.Field>
            <Form.Input
              label="Transaction Category Name (enter with capitalization)"
              placeholder="Create Category Name"
              name="name"
              value={name}
              onChange={(name, target) => setName(target.value)}
              required
              fluid
            />
          </Form.Field>
        </Form>
        <br />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={close}>Cancel</Button>
        <Button primary onClick={submit}>
          Update
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export const TransactionCategoryRow = ({
  category,
  auxColumn,
  editable,
}: {
  category: TransactionCategory
  auxColumn: string
  editable?: boolean
}) => {
  const [editOpen, setEditOpen] = useState(false)

  return (
    <Table.Row style={category.archivedAt && { opacity: '0.3' }}>
      {editable && (
        <Table.Cell>
          {!category.archivedAt && (
            <Button
              className="link"
              onClick={() => setEditOpen(true)}
              icon="pencil"
            />
          )}
        </Table.Cell>
      )}
      <Table.Cell>{category.id}</Table.Cell>
      <Table.Cell>{category.name}</Table.Cell>
      <Table.Cell>
        {category.balanceSheetCategory === null
          ? category.accountType
          : BalanceSheetCategories[category.balanceSheetCategory]}
      </Table.Cell>
      <Table.Cell>
        {category.financialStatement === 'balance_sheet'
          ? TransactionCategoryFinancialStatements.balance_sheet
          : TransactionCategoryFinancialStatements.income_statement}
      </Table.Cell>
      <Table.Cell>{auxColumn}</Table.Cell>
      <Table.Cell />
      <Table.Cell>
        {category.archivedAt ? (
          <Label color="black">
            {moment(category.archivedAt).format(DATE_FORMATS.DISPLAY_SHORT)}
          </Label>
        ) : (
          ''
        )}
      </Table.Cell>
      <Table.Cell>
        <Button
          className="link"
          onClick={() => setEditOpen(true)}
          icon="pencil"
        />
      </Table.Cell>
      {editOpen && (
        <TransactionCategoryEditModal
          category={category}
          open={editOpen}
          close={() => setEditOpen(false)}
        />
      )}
    </Table.Row>
  )
}

interface Props {
  categories?: TransactionCategory[]
  coas?: ChartOfAccount[]
}
const TransactionCategoriesTable = ({ categories, coas }: Props) => {
  return (
    <Table compact basic>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>Category Name</Table.HeaderCell>
          <Table.HeaderCell>Within COAs</Table.HeaderCell>
          <Table.HeaderCell>Account Type</Table.HeaderCell>
          <Table.HeaderCell>Archived On</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {categories?.map((category, index) => {
          let parentCOAs: string[] = []

          category?.chartOfAccountCategories.forEach((coaCat) => {
            const parentCOA = coas?.find(
              (coa) => coa.id === coaCat.chartOfAccountId
            )
            if (parentCOA) {
              parentCOAs.push(parentCOA.calendarYear)
            }
          })

          parentCOAs = orderBy(parentCOAs, (el) => parseInt(el), ['desc'])

          const parentCOAsCSV = parentCOAs.join(', ')
          return (
            <TransactionCategoryRow
              key={index}
              editable
              auxColumn={parentCOAsCSV}
              category={category}
            />
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default TransactionCategoriesTable
