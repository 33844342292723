import { useMemo } from 'react'
import { Grid } from 'semantic-ui-react'

import { GridRowColumn, Text } from '../../../../../components/BaseComponents'
import DetailsAccordion from './DetailsAccordion'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../../../utils/deviceWidthHelpers'
import { getProductByInterval } from '../../helpers'
import { StripeInterval } from '../../../../../reducers/subscription.slice'
import {
  centsToCurrency,
  formatCurrency,
  formatCurrencyFromCents,
} from '../../../../../utils/currencyHelpers'
import { useReselector } from '../../../../../utils/sharedHooks'
import {
  selectIsCurrentUserScorp,
  selectIsGroupPractice,
  selectProductDescription,
} from '../../../../../selectors/user.selectors'
import { StripeProduct } from '../../../../../actions/settings/billingActions'

const PlanDetailsSection = ({
  stripeProducts,
  selectedProduct,
  freeTrialEnabled,
  basicPlanEnabled,
  isLateJoiner,
}: {
  stripeProducts: StripeProduct[]
  selectedProduct?: StripeProduct
  freeTrialEnabled: boolean
  basicPlanEnabled: boolean
  isLateJoiner: boolean | null | undefined
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const annualPlan = getProductByInterval(stripeProducts, StripeInterval.year)
  const isAnnual = selectedProduct === annualPlan
  const isGroupPractice = useReselector(selectIsGroupPractice)
  const isScorp = useReselector(selectIsCurrentUserScorp)

  const monthlyDollarsFormatted = useMemo(
    () =>
      selectedProduct?.default_price && isAnnual
        ? formatCurrency(
            centsToCurrency(selectedProduct.default_price.unit_amount).divide(
              12
            ),
            { hideDecimalsIfZero: true }
          )
        : formatCurrencyFromCents(selectedProduct?.default_price.unit_amount, {
            hideDecimalsIfZero: true,
          }),
    [isAnnual, selectedProduct?.default_price]
  )

  const sections = [
    'taxes' as const,
    'bookkeeping' as const,
    'budget' as const,
    'scorp' as const,
  ]

  const productDescription = useReselector(
    selectProductDescription,
    basicPlanEnabled,
    sections,
    isAnnual
  )

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <Text color={'darkGray'} as={'eyebrow'}>
            {isAnnual
              ? 'annual plan'
              : isLateJoiner
                ? 'annual plan, paid monthly'
                : 'monthly plan'}
          </Text>
        </Grid.Column>
        <Grid.Column width={8}>
          <Text color={'darkGray'} as={'eyebrow'} textAlign="right">
            {!isAnnual && !isLateJoiner && 'cancel anytime'}
          </Text>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={9}>
          <Text as={isMobile ? 'h2' : 'h1'}>{selectedProduct?.name}</Text>
        </Grid.Column>
        <Grid.Column textAlign="right" width={7}>
          <Text as={isMobile ? 'h2' : 'h1'} style={{ display: 'inline' }}>
            {monthlyDollarsFormatted}
          </Text>
          <Text as={isMobile ? 'h3' : 'h2'} style={{ display: 'inline' }}>
            /mo
          </Text>
          {!isGroupPractice && isScorp && (
            <Text style={{ textDecoration: 'line-through' }} color="darkGray">
              {formatCurrency(isAnnual ? 255 : 299, {
                hideDecimalsIfZero: true,
              })}
              /mo
            </Text>
          )}
          {isAnnual && (
            <div>
              <Text
                color="darkGray"
                style={{
                  position: 'absolute',
                  right: 15,
                }}
              >
                {freeTrialEnabled
                  ? 'Free Trial'
                  : `Billed ${formatCurrencyFromCents(annualPlan?.default_price.unit_amount_decimal, { hideDecimalsIfZero: true })} annually`}
              </Text>
            </div>
          )}
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn className="short">
        <DetailsAccordion productDescription={productDescription} />
      </GridRowColumn>
    </Grid>
  )
}

export default PlanDetailsSection
