import { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, Table, TableHeaderCell } from 'semantic-ui-react'
import { StateIncomeTaxDetailsModal } from './components/StateIncomeTaxDetailsModal'
import {
  Text,
  Button,
  Card,
  GridRowColumn,
  Checkbox,
  Dropdown,
  valsToDropdownOptions,
} from '../../../../../components/BaseComponents'
import {
  StateIncomeTaxDetailsStates,
  StateIncomeTaxEstimateDetails,
  TaxRateType,
  getStateDetails,
} from './stateIncomeTaxDetailsActions'
import {
  DATE_FORMATS_LUXON,
  formatISOFromUTC,
} from '../../../../../utils/dateHelpers'
import { formatCurrencyFromCents } from '../../../../../utils/currencyHelpers'
import {
  StandardDeduction,
  TaxRateBrackets,
  TaxRateBracketInner,
} from '../IncomeTaxDetailsHelpers'
import { useAppDispatch } from '../../../../../utils/typeHelpers'

const renderStandardDeduction = (
  standardDeduction?: StandardDeduction | null
) => {
  if (!standardDeduction) {
    return (
      <>
        <Table.Cell>---</Table.Cell>
        <Table.Cell>---</Table.Cell>
        <Table.Cell>---</Table.Cell>
        <Table.Cell>---</Table.Cell>
        <Table.Cell>---</Table.Cell>
      </>
    )
  }
  return (
    <>
      <Table.Cell>
        {formatCurrencyFromCents(Number(standardDeduction.single))}
      </Table.Cell>
      <Table.Cell>
        {formatCurrencyFromCents(Number(standardDeduction.headOfHousehold))}
      </Table.Cell>
      <Table.Cell>
        {formatCurrencyFromCents(
          Number(standardDeduction.marriedFilingSeparately)
        )}
      </Table.Cell>
      <Table.Cell>
        {formatCurrencyFromCents(Number(standardDeduction.qualifyingWidow))}
      </Table.Cell>
      <Table.Cell>
        {formatCurrencyFromCents(
          Number(standardDeduction.marriedFilingJointly)
        )}
      </Table.Cell>
    </>
  )
}

const renderInnerBrackets = (brackets: TaxRateBracketInner[]) => (
  <Table>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Min</Table.HeaderCell>
        <Table.HeaderCell>Max</Table.HeaderCell>
        <Table.HeaderCell>Rate</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    {brackets.map((bracket) => (
      <Table.Row key={bracket.minInCents}>
        <Table.Cell>{formatCurrencyFromCents(bracket.minInCents)}</Table.Cell>
        <Table.Cell>
          {!bracket.maxInCents
            ? '∞'
            : formatCurrencyFromCents(bracket.maxInCents)}
        </Table.Cell>
        <Table.Cell>{bracket.rate}</Table.Cell>
      </Table.Row>
    ))}
  </Table>
)

const renderTaxRateBrackets = (taxRateBrackets?: TaxRateBrackets | null) => {
  if (!taxRateBrackets) {
    return (
      <>
        <Table.Cell>---</Table.Cell>
        <Table.Cell>---</Table.Cell>
        <Table.Cell>---</Table.Cell>
        <Table.Cell>---</Table.Cell>
        <Table.Cell>---</Table.Cell>
      </>
    )
  }
  return (
    <>
      <Table.Cell>{renderInnerBrackets(taxRateBrackets.single)}</Table.Cell>
      <Table.Cell>
        {renderInnerBrackets(taxRateBrackets.headOfHousehold)}
      </Table.Cell>
      <Table.Cell>
        {renderInnerBrackets(taxRateBrackets.marriedFilingSeparately)}
      </Table.Cell>
      <Table.Cell>
        {renderInnerBrackets(taxRateBrackets.qualifyingWidow)}
      </Table.Cell>
      <Table.Cell>
        {renderInnerBrackets(taxRateBrackets.marriedFilingJointly)}
      </Table.Cell>
    </>
  )
}

const StateIncomeTaxDetailsPanel = () => {
  const dispatch = useAppDispatch()
  const [stateIncomeTaxDetailsModalOpen, setStateIncomeTaxDetailsModalOpen] =
    useState(false)

  const [taxDetails, setTaxDetails] = useState<StateIncomeTaxEstimateDetails[]>(
    []
  )
  const [currentOnly, setCurrentOnly] = useState(true)
  const [filterToState, setFilterToState] =
    useState<StateIncomeTaxDetailsStates>()
  const stateTaxDetails = useMemo(() => {
    return taxDetails
      .filter((detail) => {
        if (currentOnly) {
          return filterToState
            ? detail.state === filterToState && detail.current
            : detail.current
        } else if (filterToState) {
          return detail.state === filterToState
        }
        return true
      })
      .map((detail) => (
        <Table.Row key={detail.id}>
          <Table.Cell collapsing>{detail.id}</Table.Cell>
          <Table.Cell collapsing>
            {formatISOFromUTC(
              detail.effectiveFrom,
              DATE_FORMATS_LUXON.DISPLAY_SHORT
            )}
          </Table.Cell>
          <Table.Cell collapsing>{detail.state}</Table.Cell>
          <Table.Cell collapsing>
            {detail.current ? 'true' : 'false'}
          </Table.Cell>
          {renderStandardDeduction(detail.standardDeduction)}
          <Table.Cell>{detail.taxRateType}</Table.Cell>
          <Table.Cell>
            {detail.taxRateType === TaxRateType.flat
              ? detail.taxRateFlat
              : '--'}
          </Table.Cell>
          {renderTaxRateBrackets(detail.taxRateBrackets)}
        </Table.Row>
      ))
  }, [taxDetails, currentOnly, filterToState])

  useEffect(() => {
    const fetch = async () => {
      const res = await getStateDetails()(dispatch)
      if (res) {
        setTaxDetails(res)
      }
    }
    fetch()
  }, [dispatch])

  const onClose = useCallback((newDetails?: StateIncomeTaxEstimateDetails) => {
    setStateIncomeTaxDetailsModalOpen(false)
    if (newDetails) {
      setTaxDetails((prevDetails) => [...prevDetails, newDetails])
    }
  }, [])

  return (
    <Card>
      <Grid>
        <GridRowColumn>
          <Text as="h2">State Income Tax Details</Text>
        </GridRowColumn>
        <GridRowColumn>
          <Button onClick={() => setStateIncomeTaxDetailsModalOpen(true)}>
            Add New State Income Tax Details
          </Button>
        </GridRowColumn>
        <GridRowColumn>
          <Checkbox
            onClick={() => setCurrentOnly(!currentOnly)}
            checked={currentOnly}
            label="Show current only"
          />
          <Dropdown
            placeholder="Filter to state"
            search
            clearable
            options={valsToDropdownOptions([
              ...Object.values(StateIncomeTaxDetailsStates),
            ])}
            value={filterToState}
            onChange={setFilterToState}
          />
        </GridRowColumn>
        <GridRowColumn style={{ overflowX: 'scroll' }}>
          <Table celled structured>
            <Table.Header>
              <Table.Row>
                <TableHeaderCell rowSpan="2">id</TableHeaderCell>
                <TableHeaderCell rowSpan="2">Effective From</TableHeaderCell>
                <TableHeaderCell rowSpan="2">State</TableHeaderCell>
                <TableHeaderCell rowSpan="2">Current</TableHeaderCell>
                <TableHeaderCell colSpan="5">
                  Standard Deduction
                </TableHeaderCell>
                <TableHeaderCell rowSpan="2">Tax Rate Type</TableHeaderCell>
                <TableHeaderCell rowSpan="2">Tax Rate Flat</TableHeaderCell>
                <TableHeaderCell colSpan="5">Tax Rate Brackets</TableHeaderCell>
              </Table.Row>
              <Table.Row>
                {/* For Standard Deduction */}
                <TableHeaderCell>Single</TableHeaderCell>
                <TableHeaderCell>Head of Household</TableHeaderCell>
                <TableHeaderCell>Married Filing Separately</TableHeaderCell>
                <TableHeaderCell>Qualifying Widow(er)</TableHeaderCell>
                <TableHeaderCell>Married Filing Jointly</TableHeaderCell>
                {/* For Brackets */}
                <TableHeaderCell>Single</TableHeaderCell>
                <TableHeaderCell>Head of Household</TableHeaderCell>
                <TableHeaderCell>Married Filing Separately</TableHeaderCell>
                <TableHeaderCell>Qualifying Widow(er)</TableHeaderCell>
                <TableHeaderCell>Married Filing Jointly</TableHeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{stateTaxDetails}</Table.Body>
          </Table>
        </GridRowColumn>
        <StateIncomeTaxDetailsModal
          modalOpen={stateIncomeTaxDetailsModalOpen}
          onClose={onClose}
        />
      </Grid>
    </Card>
  )
}

export default StateIncomeTaxDetailsPanel
