import { SidebarSlice } from '../reducers/sidebar.slice'
import { ReduxState } from '../utils/typeHelpers'

// This selector is a generic in order to allow type inferences on the keys of the actual
// sidebarState, given that the sidebar can take on multiple configurations.
//
// Usage: const sidebarState = useSelector(getSidebarState<T>())
//
export const getSidebarState =
  <T extends string>() =>
  (state: ReduxState): SidebarSlice<T> =>
    state.sidebarState
