import { ReactNode, useEffect, useMemo } from 'react'
import { Divider, Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  TaxSeasonKickoffStepProps,
  TAX_SURVEY_CONTENT_SCREENS,
} from './AnnualTaxSurvey'
import {
  Button,
  GridRowColumn,
  Icon,
  Text,
} from '../../../../components/BaseComponents'
import FormFlowFooter from '../../../../components/FormFlow/FormFlowFooter'
import { useReselector } from '../../../../utils/sharedHooks'
import { getAnnualTaxFilingForYearSelector } from '../annualTaxFilings.selector'
import { selectTaxDetailsByYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../../utils/dateHelpers'
import { TaxFormType } from '../Questionnaires/constants'
import { needContractorFilingOption } from '../annualTaxFilings.slice'
import { useAnalyticsTrack, useAnalyticsView } from '../../../Amplitude'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import { useSetScreen } from '../../../../components/FormFlow/formFlow'

const EditSection = ({
  title,
  content,
  screen,
}: {
  title: string
  content: string | ReactNode
  screen: TAX_SURVEY_CONTENT_SCREENS
}) => {
  const { setReviewScreen } = useSetScreen()

  return (
    <>
      <Grid.Row>
        <Grid.Column computer={5} tablet={5} mobile={1} />
        <Grid.Column computer={5} tablet={5} mobile={11}>
          <Text as="eyebrow">{title}</Text>
          <br />
          {typeof content === 'string' ? <Text>{content}</Text> : content}
        </Grid.Column>
        <Grid.Column computer={1} tablet={1} mobile={4}>
          <Button variant="link" onClick={() => setReviewScreen(screen)}>
            <Icon icon={regular('pen-to-square')} style={{ marginRight: 5 }} />
            Edit
          </Button>
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn {...makeGridConfig([6, 14], true)} short>
        <Divider />
      </GridRowColumn>
    </>
  )
}

const SurveyReviewPanel = ({
  goBack,
  goToNextStep,
  year,
}: TaxSeasonKickoffStepProps) => {
  const currentAnnualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    year
  )
  const annualTaxDetails = useReselector(selectTaxDetailsByYear, year)

  const needs1120S = currentAnnualTaxFiling?.annualTaxFormNeeds?.includes(
    TaxFormType.form1120s
  )
  const lastSubmittedAt = currentAnnualTaxFiling?.pretaxSurveySubmittedAt

  const track = useAnalyticsTrack()
  const pageView = useAnalyticsView()
  useEffect(() => {
    pageView('TSK review')
  }, [pageView])

  const contractorContentStart = useMemo(() => {
    switch (currentAnnualTaxFiling?.needContractorFiling) {
      case needContractorFilingOption.yes:
        return 'Yes, I will'
      case needContractorFilingOption.no:
        return 'No, I won’t'
      case needContractorFilingOption.unsure:
      default:
        return 'I might'
    }
  }, [currentAnnualTaxFiling?.needContractorFiling])

  return (
    <Grid>
      <GridRowColumn>
        <Text as="display2" textAlign="center">
          Review
        </Text>
      </GridRowColumn>

      <GridRowColumn {...makeGridConfig([10, 12], true)}>
        <Text as="bodyLg" textAlign="center">
          You can update this information at any time until{' '}
          {annualTaxDetails?.taxSeasonKickoffDueAt &&
            isoToUTCDateTime(annualTaxDetails?.taxSeasonKickoffDueAt).toFormat(
              DATE_FORMATS_LUXON.DISPLAY_LONG
            )}
          .
        </Text>
      </GridRowColumn>

      <Grid.Row className="short" />

      <EditSection
        title="your tax form"
        content={
          needs1120S
            ? 'Business Income Tax Return (Form 1120-S)'
            : 'Personal Income Tax Return (Form 1040)'
        }
        screen={TAX_SURVEY_CONTENT_SCREENS.formSelection}
      />

      {needs1120S && (
        <EditSection
          title="additional tax filings"
          content={
            currentAnnualTaxFiling?.annualTaxFormNeeds?.includes(
              TaxFormType.form1040
            )
              ? 'Personal Income Tax Return (Form 1040)'
              : 'None'
          }
          screen={TAX_SURVEY_CONTENT_SCREENS.additionalForms}
        />
      )}

      <EditSection
        title="contractor payroll tax form"
        content={`${contractorContentStart} need to file a Contractor Payroll Tax Form (1099-NEC)`}
        screen={TAX_SURVEY_CONTENT_SCREENS.contractorNec}
      />

      <Grid.Row />

      <FormFlowFooter
        onBack={() => goBack(TAX_SURVEY_CONTENT_SCREENS.importantDates)}
        onForward={() => {
          track('clicked submit TSK review', {
            isResubmitting: Boolean(lastSubmittedAt),
            lastSubmittedAt: lastSubmittedAt ?? 'n/a',
          })
          goToNextStep({ pretaxSurveySubmittedAt: Date.now() }, null)
        }}
        isSubmit
      />
    </Grid>
  )
}

export default SurveyReviewPanel
