import { orderBy } from 'lodash'
import moment from 'moment'
import { QuarterlyTaxEstimateDetail } from '../QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import {
  TaxCalculationViewModel,
  UserTaxCalculation,
  UserTaxCalculationTabNames,
} from './userTaxCalculation.slice'
import { History, isVersionUser } from '../Histories/histories.slice'
import { TAX_ENTITY_TYPES } from '../../Taxes/taxConstants'
import { inOperator } from '../../../utils/typeHelpers'

/*
  Composes our payload into an object so we can easily access data by calendar year & quarter
    e.g. obj['2022']['Q1'][UserTaxCalculationTabName] = UserTaxCalculation[]
*/
export const createViewModel = (
  payload: UserTaxCalculation[],
  allQuarterlyTaxEstimateDetails: QuarterlyTaxEstimateDetail[],
  financialProfileHistoriesThisYear: History[]
) => {
  const viewModel: TaxCalculationViewModel = {}

  // Sort by federalQuarterlyEstimateInCents asc, within calendarQuarter asc, within calendarYear asc
  const sortedPayload = orderBy(
    payload,
    ['calendarYear', 'calendarQuarter', 'federalQuarterlyEstimateInCents'],
    ['asc', 'asc', 'asc']
  )

  sortedPayload.forEach((c) => {
    // Create year if it doesn't exist
    if (!viewModel[c.calendarYear]) {
      viewModel[c.calendarYear] = {}
    }

    // Create quarter if it doesn't exist
    if (!viewModel[c.calendarYear][c.calendarQuarter]) {
      viewModel[c.calendarYear][c.calendarQuarter] = {}
    }

    // Create "all" if doesn't exist
    if (
      !viewModel[c.calendarYear][c.calendarQuarter][
        UserTaxCalculationTabNames.all
      ]
    ) {
      viewModel[c.calendarYear][c.calendarQuarter][
        UserTaxCalculationTabNames.all
      ] = []
    }

    // Create "singleState" if doesn't exist
    if (
      !viewModel[c.calendarYear][c.calendarQuarter][
        UserTaxCalculationTabNames.singleState
      ]
    ) {
      viewModel[c.calendarYear][c.calendarQuarter][
        UserTaxCalculationTabNames.singleState
      ] = []
    }

    // Create "multiState" if doesn't exist
    if (
      !viewModel[c.calendarYear][c.calendarQuarter][
        UserTaxCalculationTabNames.multiState
      ]
    ) {
      viewModel[c.calendarYear][c.calendarQuarter][
        UserTaxCalculationTabNames.multiState
      ] = []
    }

    // Create "edgeCases" if doesn't exist
    if (
      !viewModel[c.calendarYear][c.calendarQuarter][
        UserTaxCalculationTabNames.edgeCases
      ]
    ) {
      viewModel[c.calendarYear][c.calendarQuarter][
        UserTaxCalculationTabNames.edgeCases
      ] = []
    }

    // Always add to "all"
    viewModel[c.calendarYear][c.calendarQuarter][
      UserTaxCalculationTabNames.all
    ].push(c)

    // Add to specific bucket
    const tabName = tabNameForCalculation(
      c,
      allQuarterlyTaxEstimateDetails,
      financialProfileHistoriesThisYear
    )
    if (tabName) {
      viewModel[c.calendarYear][c.calendarQuarter][tabName].push(c)
    }
  })

  return viewModel
}

const tabNameForCalculation = (
  calculation: UserTaxCalculation,
  allQuarterlyTaxEstimateDetails: QuarterlyTaxEstimateDetail[],
  financialProfileHistoriesThisYear: History[]
) => {
  const quarterDetails = allQuarterlyTaxEstimateDetails.find(
    (d) =>
      d.taxQuarter === calculation.calendarQuarter &&
      d.taxYear === calculation.calendarYear
  )

  if (quarterDetails?.edgeCaseUserIds?.includes(calculation.userId)) {
    return UserTaxCalculationTabNames.edgeCases
  }

  if (
    isSingleStateCalculation({
      allQuarterlyTaxEstimateDetails,
      financialProfileHistoriesThisYear,
      userTaxCalculation: calculation,
    })
  ) {
    return UserTaxCalculationTabNames.singleState
  }

  return UserTaxCalculationTabNames.multiState
}

export const datasourceForTab = ({
  calculations,
  tabName,
  allQuarterlyTaxEstimateDetails,
  financialProfileHistoriesThisYear,
}: {
  calculations: UserTaxCalculation[]
  tabName: UserTaxCalculationTabNames
  allQuarterlyTaxEstimateDetails: QuarterlyTaxEstimateDetail[]
  financialProfileHistoriesThisYear: History[]
}) => {
  const result: UserTaxCalculation[] = []

  calculations.forEach((c) => {
    if (
      tabNameForCalculation(
        c,
        allQuarterlyTaxEstimateDetails,
        financialProfileHistoriesThisYear
      ) === tabName
    ) {
      result.push(c)
    }
  })

  return result
}

const MIN_PERCENTAGE_INCOME_SINGLE_STATE_SCORP_INTEGER = 60

export const isSingleStateCalculation = ({
  allQuarterlyTaxEstimateDetails,
  financialProfileHistoriesThisYear,
  userTaxCalculation,
}: {
  allQuarterlyTaxEstimateDetails: QuarterlyTaxEstimateDetail[]
  financialProfileHistoriesThisYear: History[]
  userTaxCalculation: UserTaxCalculation | null | undefined
}) => {
  if (!userTaxCalculation) {
    return false
  }

  const qteDetails = allQuarterlyTaxEstimateDetails.find(
    (d) =>
      d.taxQuarter === userTaxCalculation.calendarQuarter &&
      d.taxYear === userTaxCalculation.calendarYear
  )

  // If the customer is an edge case, they are not single state
  if (qteDetails?.edgeCaseUserIds?.includes(userTaxCalculation.userId)) {
    return false
  }

  if (userTaxCalculation.isSingleState !== null) {
    return userTaxCalculation.isSingleState
  }

  // As of Q4 2023 isSingleState is only set once a user is ready to be calculated.
  // Before Q3 2023, isSingleState was not set at all. To handle these cases, we need
  // to determine if the user is a single state filer based on other criteria.

  // Starting Q4 2022, NY residents are considered single-state filers
  if (userTaxCalculation.homeState === 'NY') {
    return true
  }

  // If the customer is an 1120 or a 1065, treat them as single state
  if (
    userTaxCalculation.taxEntityType === TAX_ENTITY_TYPES.form_1120 ||
    userTaxCalculation.taxEntityType === TAX_ENTITY_TYPES.form_1065
  ) {
    return true
  }

  // If the customer is a 1040, scheduleC, or doesn't have a tax_entity_type
  if (
    userTaxCalculation.taxEntityType === TAX_ENTITY_TYPES.form_1040 ||
    userTaxCalculation.taxEntityType === TAX_ENTITY_TYPES.schedule_c ||
    !userTaxCalculation.taxEntityType
  ) {
    // Then they are a single state filer IF they did NOT move this current year
    // If they have histories, inspect them to determine if the user moved
    if (financialProfileHistoriesThisYear) {
      // If the user has at least 1 record with relocatedPreviousStateAt set, this
      // means they moved and are NOT a single state filer.
      // We currently inspect relocatedPreviousStateAt instead of relocatedThisQuarter b/c
      // the relocatedThisQuarter column didn't exist as of Q1 2022.
      const userFinancialProfileHistories =
        financialProfileHistoriesThisYear.filter(
          (h) =>
            h.newVersion &&
            !isVersionUser(h.newVersion) &&
            h.newVersion.userId === userTaxCalculation.userId
        )

      const relocatedHistories = userFinancialProfileHistories.filter((h) => {
        const relocated =
          inOperator('relocatedPreviousStateAt', h.newVersion) &&
          h.newVersion.relocatedPreviousStateAt
        return (
          relocated && moment(relocated).format('YYYY') === qteDetails?.taxYear
        )
      })
      return relocatedHistories.length === 0
    } else {
      // If they do not have histories, look at userTaxCalculation.relocatedPreviousStateAt
      // to determine if they moved

      // This prop has a value, they did move. Not a single state
      return !userTaxCalculation.relocatedPreviousStateAt
    }
  } else if (
    userTaxCalculation.taxEntityType === TAX_ENTITY_TYPES.form_1120_s
  ) {
    // If the customer is an scorp, then they are a single state filer if:
    // 60% or more of their income comes from their home state, see MIN_PERCENTAGE_INCOME_SINGLE_STATE_SCORP_INTEGER

    // If the user has NOT specified a % income per state
    if (
      !userTaxCalculation.percentageIncomePerState ||
      userTaxCalculation.percentageIncomePerState.length === 0
    ) {
      // We treat this as all income coming from their home state
      // So they will be treated as a single-state filer
      return true
    }

    // The user HAS specified % income per state

    // Determine which states they earned income
    const statesEarningIncome = userTaxCalculation.percentageIncomePerState.map(
      (p) => p.state
    )
    // If the states which earn income do not include the home state,
    // then all income is coming from outside of their home state aka they are a multi-state filer
    if (!statesEarningIncome.includes(userTaxCalculation.homeState)) {
      return false
    }

    // If the states which earn income do include the home state, see if home state's % is 60 or more
    if (statesEarningIncome.includes(userTaxCalculation.homeState)) {
      const percentageIncomeInHomeState =
        userTaxCalculation.percentageIncomePerState.find(
          (s) => s.state === userTaxCalculation.homeState
        )
      return Boolean(
        percentageIncomeInHomeState?.value &&
          Number(percentageIncomeInHomeState.value) >=
            MIN_PERCENTAGE_INCOME_SINGLE_STATE_SCORP_INTEGER
      )
    }
  }

  return false
}

//Tax quarters that are 2023-Q3 or above will use the new in-house calculator. Any quarter
//before that will use the abound API
export const quarterUsesAbound = ({
  calendarYear,
  calendarQuarter,
}: {
  calendarYear: string
  calendarQuarter: string
}) => {
  return (
    (Number(calendarYear) === 2023 && Number(calendarQuarter) <= 2) ||
    Number(calendarYear) < 2023
  )
}
