import { createSelector } from 'reselect'
import { ReduxState } from '../../utils/typeHelpers'
import { ChartOfAccount } from './chartOfAccount.slice'

export const chartOfAccounts = (state: ReduxState) => state.chartOfAccounts.byId

/*
  Get all unarchived chart of accounts
*/
export const getAllUnarchivedChartOfAccounts = createSelector(
  [chartOfAccounts],
  (chartOfAccounts) => {
    return Object.values(chartOfAccounts).filter((c) => !c.archivedAt)
  }
)

/*
  Get all archived chart of accounts
*/
export const getAllArchivedChartOfAccounts = createSelector(
  [chartOfAccounts],
  (chartOfAccounts) => {
    return Object.values(chartOfAccounts).filter((c) => c.archivedAt)
  }
)

/*
  Gets current coa by choosing the one w/ max calendarYear across all unarchived coas
*/
export const getCurrentChartOfAccount = createSelector(
  getAllUnarchivedChartOfAccounts,
  (unarchivedCOAs) => {
    let result: ChartOfAccount | undefined
    let maxIntYear = 0

    unarchivedCOAs.forEach((coa) => {
      const candidate = parseFloat(coa.calendarYear)
      if (!isNaN(candidate) && candidate > maxIntYear) {
        result = coa
        maxIntYear = candidate
      }
    })

    return result
  }
)
