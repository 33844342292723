import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { keyBy } from 'lodash'

export interface AllocationRule {
  createdAt: string
  id: number | null
  percentage?: string | null
  ruleName: string
  ruleType?: string | null
  updatedAt: string
  userId?: number | null
  amountInCents?: number | null
  amount?: number | null
}

export interface AllocationRuleState {
  [key: number]: AllocationRule
}

const allocationRuleSlice = createSlice({
  name: 'allocationRules',
  initialState: {} as AllocationRuleState,
  reducers: {
    receiveAllAllocationRules: (
      state,
      action: PayloadAction<{ [key: number]: AllocationRule }>
    ) => ({ ...state, ...action.payload }),
    receiveSingleAllocationRules: (
      state,
      action: PayloadAction<AllocationRule>
    ) => {
      if (action.payload.id) {
        state[action.payload.id] = action.payload
      }
    },
    updateAllAllocationRules: (
      state,
      action: PayloadAction<{ rules: AllocationRule[]; deletedIds: number[] }>
    ) => {
      const data = keyBy(action.payload.rules, 'id')
      if (action.payload.deletedIds.length === 0) return { ...state, ...data }
      for (const id of action.payload.deletedIds) {
        delete state[id]
      }
      return state
    },
  },
})

export const {
  receiveAllAllocationRules,
  receiveSingleAllocationRules,
  updateAllAllocationRules,
} = allocationRuleSlice.actions

export default allocationRuleSlice.reducer
