import { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'

import { selectContractorByUuid } from '../payroll.selectors'
import { fetchAllContractorForms } from '../payrollActions'
import GustoFormsTable from '../PayrollDocuments/GustoFormsTable'
import { GridRowColumn, Text } from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { getContractorName } from '../helpers'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { Gusto_GustoForm } from '../generated_gusto_types'

const ContractorDocumentsTab = ({ uuid }: { uuid: string }) => {
  const dispatch = useAppDispatch()
  const contractor = useReselector(selectContractorByUuid, uuid)

  const [forms, setForms] = useState<Gusto_GustoForm[]>([])

  useEffect(() => {
    const fetchForms = async () => {
      const res = await fetchAllContractorForms(uuid)(dispatch)
      if (res) {
        setForms(res)
      }
    }

    fetchForms()
  }, [dispatch, uuid])

  if (!contractor) {
    return null
  }

  return (
    <Grid>
      <GridRowColumn>
        <Text as="h2">Contractor Documents</Text>
      </GridRowColumn>
      <GridRowColumn short>
        <Text>
          These are documents shared between you and{' '}
          <b>{getContractorName(contractor)}</b>
        </Text>
      </GridRowColumn>
      <GridRowColumn>
        <GustoFormsTable
          forms={forms}
          contractorUuid={uuid}
          allowSign={false}
        />
      </GridRowColumn>
    </Grid>
  )
}

export default ContractorDocumentsTab
