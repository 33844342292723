import { useEffect, useState } from 'react'
import { Grid, Image } from 'semantic-ui-react'

import {
  Alert,
  Card,
  GridRowColumn,
  Text,
} from '../../../../../components/BaseComponents'
import { Colors, Fonts } from '../../../../../styles/theme'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { selectUserTransactionsByCategoryIdentifierForYear } from '../../../../Transactions/transactions.selectors'
import Tooltip from '../../../QuarterlyTaxEstimates/components/Tooltip'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import { UpdateYourBooksStepProps } from './UpdateYourBooks'
import { TransactionHeader } from '../../../../Transactions/Lists/transaction-header'
import { TransactionCard } from '../../../../Transactions/Lists/transaction-card'
import { TransactionCategoryIdentifier } from '../../../../../reducers/admin/allTransactions.slice'
import { SubStepIdentifiers } from '../Shared/ReviewStepsandProgresses/stepProgress.helpers'

const OwnersInvestmentStep = ({
  goForward,
  goBack,
}: UpdateYourBooksStepProps) => {
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const investmentTransactionsInState = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.owners_investments,
    currentTaxYear
  )
  const [investmentTransactionsIds, setInvestmentTransactionIds] = useState<
    number[]
  >([])

  useEffect(() => {
    // Still want to display accounts after category updates,
    // so don't update the distributionTransactionsIds list that was initially loaded
    if (
      investmentTransactionsInState.length &&
      !investmentTransactionsIds.length
    ) {
      setInvestmentTransactionIds(
        investmentTransactionsInState.map((t) => t.id)
      )
    }
  }, [investmentTransactionsIds.length, investmentTransactionsInState])

  const saveAndContinue = useAsyncCallback(() =>
    goForward({
      completedSteps: [SubStepIdentifiers.confirmOwnersInvestmentTransactions],
    })
  )

  return (
    <Grid>
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/payroll.svg"
          style={{ width: 120, height: 120 }}
        />
      </GridRowColumn>
      <GridRowColumn width={10} spacer={3}>
        <Text as="h1" textAlign="center">
          Do these Owner’s Investments look correct?
        </Text>
      </GridRowColumn>
      <GridRowColumn width={10} spacer={3}>
        <Text>
          Your bookkeepers categorized the following transactions as{' '}
          <b>Owner’s Investments</b>, or money you put into your business. If
          something doesn’t look right, please recategorize and add a note to
          explain why.
        </Text>
      </GridRowColumn>
      <GridRowColumn spacer={3} width={10}>
        <Tooltip
          popup={{
            title: "Owner's Investments",
            body: 'Money or assets that an owner contributes towards starting or running a business.<br/>An example of this is income that was originally paid into your personal account, but transferred back into your business account.',
          }}
          labelComponent="What is an Owner's Investment?"
          style={{
            display: 'inline-block',
            borderBottom: `2px dashed ${Colors.green}`,
            ...Fonts.bodySm,
          }}
        />
      </GridRowColumn>
      <Grid.Row />
      {investmentTransactionsIds?.length > 0 && <TransactionHeader />}
      <GridRowColumn short>
        {investmentTransactionsIds.map((tId) => (
          <TransactionCard key={tId} transactionId={tId} />
        ))}
      </GridRowColumn>
      {investmentTransactionsIds?.length === 0 && (
        <GridRowColumn width={10} spacer={3}>
          <Card backgroundColor="lightGreen">
            <Text>
              All transactions have been recategorized. You may continue to the
              next step.
            </Text>
          </Card>
        </GridRowColumn>
      )}
      <GridRowColumn width={10} spacer={3}>
        <Alert title="Why are we asking this?">
          Sometimes business income is miscategorized as Owner’s Investments. We
          need you to confirm if the above transactions are truly Owner’s
          Investments.
        </Alert>
      </GridRowColumn>
      <FormFlowFooter
        onBack={goBack}
        onForward={saveAndContinue.callback}
        loading={saveAndContinue.loading}
        continueDisabled={saveAndContinue.loading}
      />
    </Grid>
  )
}

export default OwnersInvestmentStep
