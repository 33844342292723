import { ReduxState } from '../../../utils/typeHelpers'
import { createSelector } from 'reselect'

export const getAllAnnualTaxFilings = (state: ReduxState) =>
  state.admin.allAnnualTaxFilings

export const selectAnnualTaxFilingsByYear = createSelector(
  getAllAnnualTaxFilings,
  (_: unknown, year: string) => year,
  (taxFilings, year) =>
    Object.values(taxFilings).filter((taxFiling) => taxFiling.year === year)
)

export const selectAnnualTaxFilingForId = createSelector(
  getAllAnnualTaxFilings,
  (_: unknown, id: number) => id,
  (filings, id) => (filings[id] ? filings[id] : null)
)

export const selectAnnualTaxFilingForIds = createSelector(
  getAllAnnualTaxFilings,
  (_: unknown, ids: number[]) => ids,
  (filings, ids) => ids.map((id) => filings[id])
)

export const selectAdminAnnualTaxFilingsByYearForUser = createSelector(
  selectAnnualTaxFilingsByYear,
  (_: unknown, __: unknown, userId: number) => userId,
  (taxFilings, userId) =>
    taxFilings.find(({ userId: filingUserId }) => filingUserId === userId)
)
