import { Grid, Image } from 'semantic-ui-react'
import { Alert, Button, Text } from '../../../components/BaseComponents'
import Card from '../../../components/BaseComponents/Card'
import {
  createUpriseAccount,
  FinancialAdvisoryProfile,
  FinancialAdvisoryProfilesStatus,
  putFinancialAdvisoryProfile,
} from '../financialAdvisory.slice'
import { useState } from 'react'
import { track } from '@amplitude/analytics-browser'
import { AdvisorHelpSummaryCard } from './PreIntroCallPanel'
import { useAppDispatch } from '../../../utils/typeHelpers'

const NotInterestedPanel = ({
  faProfile,
}: {
  faProfile: FinancialAdvisoryProfile
}) => {
  const dispatch = useAppDispatch()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  // This officially enables the user to start onboarding to Uprise
  const submitUpriseOnboarding = async () => {
    setLoading(true)
    setError('')
    try {
      track('started uprise onboarding — post cancellation or disinterest', {
        fa_profile_id: faProfile.id,
        user_id: faProfile.userId,
      })
      const response = await createUpriseAccount()(dispatch)
      await putFinancialAdvisoryProfile(faProfile.id, {
        status: FinancialAdvisoryProfilesStatus.onboarding,
      })(dispatch)
      if (!response) {
        setError('Something went wrong! Please refresh and try again.')
      }
    } catch {
      setError('An unexpected error occurred. Please try again later.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {error && (
        <Alert type="error">
          It looks like we encountered an error while trying to onboard you!
          Please try again.
        </Alert>
      )}
      <Card type="subsection" backgroundColor="stone">
        <Grid stackable doubling centered>
          <Grid.Row verticalAlign="middle">
            <Grid.Column
              width={5}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/two_chairs.svg"
                alt="heard financial advisory"
                width="300"
              />
            </Grid.Column>
            <Grid.Column width={11}>
              <Text as="h2">Thank you for trying Financial Advisory!</Text>
              <br />
              <Text as="bodyMd">
                As a reminder, Heard has partnered with Uprise, a premium
                financial advisory platform, to connect our therapists with
                financial experts geared towards mental health small business
                owners like you.
              </Text>

              <br />
              <Text as="bodyMd">
                You previously indicated that you were no longer interested the
                financial advisory experience.
              </Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4} />
            <Grid.Column width={5}>
              <Button
                variant="primary"
                fullWidth
                loading={loading}
                disabled={loading}
                onClick={submitUpriseOnboarding}
              >
                Start Onboarding
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
      <br />
      <AdvisorHelpSummaryCard />
    </>
  )
}

export default NotInterestedPanel
