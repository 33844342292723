import { Icon } from 'semantic-ui-react'
import { clamp } from 'lodash'
import styled from 'styled-components'
import PaginationButton from './PaginationButton'
import PaginationPages, { PaginationProps } from './PaginationPages'

const StyledPagination = (props: PaginationProps) => {
  const { className, currentPage, disabled = false, onPageClick, pages } = props

  return (
    <div className={className}>
      <PaginationButton disabled={disabled} onClick={() => onPageClick?.(1)}>
        <Icon name="angle double left" />
      </PaginationButton>
      <PaginationButton
        disabled={disabled}
        onClick={() => onPageClick?.(clamp(currentPage - 1, 1, currentPage))}
      >
        <Icon name="angle left" />
      </PaginationButton>
      <PaginationPages {...props} />
      <PaginationButton
        disabled={disabled}
        onClick={() =>
          onPageClick?.(clamp(currentPage + 1, currentPage, pages))
        }
      >
        <Icon name="angle right" />
      </PaginationButton>
      <PaginationButton
        disabled={disabled}
        onClick={() => onPageClick?.(pages)}
      >
        <Icon name="angle double right" />
      </PaginationButton>
    </div>
  )
}

const Pagination = styled((props: PaginationProps) => (
  <StyledPagination {...props} />
))(({ hoverColor }) => ({
  display: 'flex',

  ...(hoverColor && {
    'button:hover': {
      backgroundColor: hoverColor,
    },
  }),
}))

export default Pagination
