import { useCallback, useEffect } from 'react'
import {
  APPLY_CANCELLATION_DISCOUNT_KEY,
  applyCancellationDiscount,
  CancellationStepIdentifier,
  updateAutomaticCancellationAnalyticsRecord,
} from './userCancellation.slice'
import { useAppDispatch } from '../../utils/typeHelpers'
import {
  CANCELLATION_FLOW_STEP,
  getDefaultPrice,
  useSelfServiceCancellationSteps,
} from './helpers'
import { Grid, Image } from 'semantic-ui-react'
import {
  Alert,
  Button,
  Card,
  GridRowColumn,
  Pill,
  Text,
} from '../../components/BaseComponents'
import { makeGridConfig } from '../../components/BaseComponents/Grid'
import {
  fetchSubscriptions,
  selectHeardProductPlanInterval,
  selectHeardProductPlanPrice,
  StripeInterval,
} from '../../reducers/subscription.slice'
import { useReselector } from '../../utils/sharedHooks'
import {
  centsToCurrency,
  formatCurrencyFromCents,
} from '../../utils/currencyHelpers'
import {
  getCurrentUser,
  selectIsCurrentUserScorp,
} from '../../selectors/user.selectors'
import { useLocation, useSearchParams } from 'react-router-dom'
import {
  selectErrorsForKeys,
  selectIsFetchingForKeys,
} from '../../reducers/fetch'
import {
  submitCancelStripeSubscriptionAtPeriodEnd,
  SUMBIT_CANCEL_STRIPE_SUBSCRIPTION_AT_PERIOD_END_KEY,
} from '../../actions/settings/billingActions'
import { sendUserCancellationMessageZap } from '../../actions/zapierActions'

const DiscountOffer = ({ recordId }: { recordId?: number }) => {
  const dispatch = useAppDispatch()
  const [_, setSearchParams] = useSearchParams()
  const error = useReselector(selectErrorsForKeys, [
    APPLY_CANCELLATION_DISCOUNT_KEY,
    SUMBIT_CANCEL_STRIPE_SUBSCRIPTION_AT_PERIOD_END_KEY,
  ])

  const location = useLocation()
  const currentUser = useReselector(getCurrentUser)
  useEffect(() => {
    const updateRecord = async (recordId?: number) => {
      if (recordId) {
        await updateAutomaticCancellationAnalyticsRecord(recordId, {
          finalStepReached: CancellationStepIdentifier.DISCOUNT,
        })(dispatch)
      }
    }
    updateRecord(recordId)
  }, [dispatch, recordId])

  useEffect(() => {
    dispatch(fetchSubscriptions())
  }, [dispatch])

  const goToNextStep = useSelfServiceCancellationSteps(
    CANCELLATION_FLOW_STEP.discountOffer
  )

  const userPrice = useReselector(selectHeardProductPlanPrice)

  const getSavedAmount = (
    isMonthly: boolean,
    isScorp: boolean,
    userPrice: number | null
  ) => {
    const defaultPrice = getDefaultPrice(isMonthly, isScorp)
    const currentPrice = centsToCurrency(userPrice ?? defaultPrice)

    if (isMonthly) {
      // for monthly plans, we offer 50% off for 3 months
      return currentPrice.multiply(1.5)
    } else {
      // for annual plans, we offer 20% off their next plan
      return currentPrice.multiply(0.2)
    }
  }

  const currentSubscriptionInterval =
    useReselector(selectHeardProductPlanInterval) ?? StripeInterval.month

  const isMonthly = currentSubscriptionInterval === StripeInterval.month

  const isScorp = useReselector(selectIsCurrentUserScorp)

  const dollarsSavedText = formatCurrencyFromCents(
    getSavedAmount(isMonthly, isScorp, userPrice),
    { hideDecimalsIfZero: true }
  )

  const amountSavedText = isMonthly
    ? 'Get 50% off for the next 3 months'
    : 'Get 20% off your next renewal'
  const amountSavedSubtext = isMonthly
    ? 'As a one time offer, get 50% off for the next 3 months of the cost of your Heard membership on us.'
    : 'As a one time offer, get a 20% discount on your annual subscription*.'

  const isApplyingDiscountLoading = useReselector(selectIsFetchingForKeys, [
    APPLY_CANCELLATION_DISCOUNT_KEY,
  ])

  const acceptDiscount = useCallback(async () => {
    const res = await applyCancellationDiscount()(dispatch)
    if (res) {
      if (recordId) {
        await updateAutomaticCancellationAnalyticsRecord(recordId, {
          discountApplied: true,
        })(dispatch)
      }

      setSearchParams(
        { step: CANCELLATION_FLOW_STEP.discountComplete },
        { state: { recordId } }
      )
    }
  }, [dispatch, setSearchParams, recordId])

  const onContinue = useCallback(async () => {
    const res = await submitCancelStripeSubscriptionAtPeriodEnd(
      location?.state?.cancellationDetails ?? {}
    )(dispatch)
    if (res) {
      sendUserCancellationMessageZap(currentUser, 'submit_cancel')
      goToNextStep()
    }
  }, [location, currentUser, goToNextStep, dispatch])

  return (
    <Grid>
      {error.length > 0 && <Alert type="error">{error[0].message}</Alert>}
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/giftbox.svg"
          style={{ height: 180, width: 180 }}
        />
      </GridRowColumn>
      <GridRowColumn centerContent>
        <Text as="h1">Before you go...</Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([8, 16], true)}>
        <Text as="bodyLg">
          We&apos;re always working to improve Heard to address our therapist
          community&apos;s feedback. We would love for you to stay with us so
          you can enjoy even more benefits with your membership.
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([10, 16], true)}>
        <Card backgroundColor="natural">
          <Grid>
            <Grid.Column width={10}>
              <Pill color="neutral" variant="solid">
                Save {dollarsSavedText}
              </Pill>
              <br />
              <Text as="h2">{amountSavedText}</Text>
              <br />
              <Text as="bodyMd">{amountSavedSubtext}</Text>
              {!isMonthly && (
                <>
                  <br />
                  <Text as="bodyXs">
                    *Discount will apply when your subscription renews.
                  </Text>
                </>
              )}
            </Grid.Column>
            <Grid.Column width={6} verticalAlign="middle">
              <Button
                fullWidth
                loading={isApplyingDiscountLoading}
                onClick={() => acceptDiscount()}
              >
                Accept offer
              </Button>
            </Grid.Column>
          </Grid>
        </Card>
      </GridRowColumn>
      <GridRowColumn />
      <GridRowColumn centerContent>
        <Button variant="actionLink" onClick={() => onContinue()}>
          No thanks, Finalize Cancellation
        </Button>
      </GridRowColumn>
    </Grid>
  )
}

export default DiscountOffer
