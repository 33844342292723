import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import {
  Button,
  Checkbox,
  GridRowColumn,
  Modal,
  Text,
} from '../../../../components/BaseComponents'
import { useSaveAnnualTaxFilingFormData } from '../helpers'
import { useAnalyticsTrack } from '../../../Amplitude'

const TaxSurveyOptOutModal = ({
  open,
  close,
  year,
}: {
  open: boolean
  close: () => void
  year: string
}) => {
  const saveTaxFiling = useSaveAnnualTaxFilingFormData(year)
  const navigate = useNavigate()
  const [confirmChecked, setConfirmedCheck] = useState(false)
  const track = useAnalyticsTrack()

  const optOut = useCallback(async () => {
    await saveTaxFiling({
      optedOutAt: new Date().toISOString(),
      pretaxSurveySubmittedAt: new Date().toISOString(),
    })
    track('clicked confirm opt out of tax prep')
    close()
    navigate('/taxes/annual')
  }, [saveTaxFiling, track, close, navigate])

  return (
    <Modal open={open} onClose={close} closeIcon size="small">
      <Modal.Header>Opt out of taxes</Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text as="bodyLg">
              Please check the box below to opt out of Heard preparing your{' '}
              {year} taxes.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Checkbox
              checked={confirmChecked}
              onChange={setConfirmedCheck}
              label={`I understand that by opting out out, Heard will not do my personal or business taxes for ${year}. I also understand that I will not receive a refund for tax preparation services.`}
              variant="default"
            />
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          onClick={optOut}
          id="btn-annual-tax-review-opt-out-modal"
          disabled={!confirmChecked}
        >
          Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default TaxSurveyOptOutModal
