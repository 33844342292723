import { useParams } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import { DeductionsAndCreditsStepProps } from '.'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { TaxListQuestionId } from '../../service'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../../taxChecklist.selectors'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import {
  FormikInput,
  GridRowColumn,
  Popup,
  Text,
  makeNumberSchema,
  getFieldNames,
} from '../../../../../../components/BaseComponents'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import {
  centsToDollars,
  dollarsToCents,
} from '../../../../../../utils/currencyHelpers'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { UPSERT_USER_TAX_QUESTIONNAIRE_KEY } from '../../taxChecklistQuestion.actions'
import { UPDATE_USER_EOY_REVIEW_PROGRESS_KEY } from '../../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'

export const charitableContributionsQuestionIds = [
  TaxListQuestionId.charitable_cash_contribution_amount_in_cents,
  TaxListQuestionId.charitable_non_cash_contribution_amount_in_cents,
]

const CharitableContributionsPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: DeductionsAndCreditsStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()
  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    UPDATE_USER_EOY_REVIEW_PROGRESS_KEY,
  ])

  const questionCashContributions = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.charitable_cash_contribution_amount_in_cents,
    taxYear
  )
  const responseCashContributions = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.charitable_cash_contribution_amount_in_cents,
    Number(formId)
  )

  const questionNonCashContributions = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.charitable_non_cash_contribution_amount_in_cents,
    taxYear
  )
  const responseNonCashContributions = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.charitable_non_cash_contribution_amount_in_cents,
    Number(formId)
  )

  const formik = useFormik({
    initialValues: {
      cashContributionsInDollars:
        typeof responseCashContributions?.[0]?.value === 'number'
          ? centsToDollars(responseCashContributions[0].value)
          : undefined,
      nonCashContributionsInDollars:
        typeof responseNonCashContributions?.[0]?.value === 'number'
          ? centsToDollars(responseNonCashContributions[0].value)
          : undefined,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      if (
        values.cashContributionsInDollars &&
        dollarsToCents(values.cashContributionsInDollars) !==
          responseCashContributions?.[0]?.value
      ) {
        responseData.push({
          id: responseCashContributions?.[0]?.id,
          value: dollarsToCents(values.cashContributionsInDollars),
          annualTaxFilingFormId: Number(formId),
          questionId:
            TaxListQuestionId.charitable_cash_contribution_amount_in_cents,
        })
      }
      if (
        values.nonCashContributionsInDollars &&
        dollarsToCents(values.nonCashContributionsInDollars) !==
          responseCashContributions?.[0]?.value
      ) {
        responseData.push({
          id: responseNonCashContributions?.[0]?.id,
          value: dollarsToCents(values.nonCashContributionsInDollars),
          annualTaxFilingFormId: Number(formId),
          questionId:
            TaxListQuestionId.charitable_non_cash_contribution_amount_in_cents,
        })
      }
      await goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.charitableContributions],
      })
    },
  })

  const { submitForm, isSubmitting, isValid } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn>
          <Text as="display2" textAlign="center">
            Charitable Contributions
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <Text as="bodyLg" textAlign="center">
            Only enter amounts that have not already been recorded in Heard.
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikInput
            name={fieldNames.cashContributionsInDollars}
            label={questionCashContributions.question?.text}
            schema={makeNumberSchema({
              field: 'cash contributions',
              allowedDecimals: 2,
            })}
            fullWidth
            componentType="currency"
            placeholder="$0"
          />
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikInput
            name={fieldNames.nonCashContributionsInDollars}
            label={questionNonCashContributions.question?.text}
            schema={makeNumberSchema({
              field: 'non-cash contributions',
              allowedDecimals: 2,
            })}
            fullWidth
            componentType="currency"
            placeholder="$0"
            afterLabel={
              <Popup
                content={
                  <>
                    <Text as="h3">What is a non-cash contribution?</Text>
                    <br />
                    <Text as="bodySm">
                      Non-cash contributions can include physical goods such as
                      artwork, clothing, vehicles, etc, and also real estate
                      property. It doesn&apos;t include cash, check, or other
                      monetary gifts.
                    </Text>
                  </>
                }
              />
            }
          />
        </GridRowColumn>
        <FormFlowFooter
          continueDisabled={!isValid || isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isUpdating || isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default CharitableContributionsPanel
