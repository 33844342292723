import { Grid, Icon } from 'semantic-ui-react'

import { selectContractorByUuid } from '../../payroll.selectors'
import { getContractorWages } from '../../helpers'
import ContractorPayrollHeader from './ContractorPayrollHeader'
import ContractorPayrollOverview from './ContractorPayrollOverview'
import ContractorPaymentTable from './ContractorPaymentTable'
import PayrollError from '../../PayrollError'
import { POST_CONTACTOR_PAYMENT_KEY } from '../../payrollActions'
import { Button, Text } from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'

const ContPayrollReviewTab = ({
  contractorUuid,
  hours,
  bonus,
  reimbursement,
  wage,
  payDate,
  goForward,
  goBack,
}: {
  contractorUuid: string
  hours: string
  bonus: string
  wage: string
  reimbursement: string
  payDate: string
  goForward: () => void
  goBack: () => void
}) => {
  const contractor = useReselector(selectContractorByUuid, contractorUuid)

  if (!contractor) {
    return null
  }

  const { wages, total } = getContractorWages({
    contractor,
    bonus,
    hours,
    wage,
    reimbursement,
  })

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <ContractorPayrollHeader currentTab={2} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Text as="bodyLg">Review and submit</Text>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Text as="bodyMd">
            Please review the payments you’re submitting — we’ll debit funds on
            the date listed below.
          </Text>
        </Grid.Column>
      </Grid.Row>
      <PayrollError fetchKey={POST_CONTACTOR_PAYMENT_KEY} />
      <ContractorPayrollOverview payDate={payDate} total={total} />
      <Grid.Row>
        <Grid.Column>
          <Text as="bodyMd">
            <b>Note:</b> Due to banking regulations, we are unable to recover
            funds on your behalf once the money has reached your contractor’s
            account. If an error or overpayment was made, you will need to
            coordinate directly with your contractor.
          </Text>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Text as="h1">What your company pays</Text>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <ContractorPaymentTable
            contractor={contractor}
            hours={hours}
            bonus={bonus}
            wages={wages}
            reimbursement={reimbursement}
            total={total}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row />
      <Grid.Row />
      <Grid.Row>
        <Grid.Column width={3}>
          <Button variant="secondary" onClick={goBack} fullWidth>
            Go Back
          </Button>
        </Grid.Column>
        <Grid.Column width={8} />
        <Grid.Column width={4}>
          <Button onClick={goForward} fullWidth>
            Submit Payment <Icon name="arrow right" />
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row />
    </Grid>
  )
}

export default ContPayrollReviewTab
