export const TRANSACTION_CATEGORY_IDENTIFIERS = {
  computerHardware: 'computer_hardware',
  continuingEducation: 'continuing_education',
  creditCardPayments: 'credit_card_payments',
  equipment: 'equipment',
  equipmentFurniture: 'equipment_furniture',
  furniture: 'furniture',
  gifts: 'gifts',
  insuranceDental: 'insurance_dental',
  insuranceHealth: 'insurance_health',
  mealsAndEntertainment: 'meals_entertainment',
  moneyTransfer: 'money_transfer',
  officeExpenses: 'office_expenses',
  otherExpenses: 'other_expenses',
  ownersDistribution: 'owners_distribution',
  ownersInvestments: 'owners_investments',
  payrollContractors: 'payroll_contractors',
  payrollEmployeeBenefits: 'payroll_employee_benefits',
  payrollExpensesAndFees: 'payroll_expenses_fees',
  payrollSalariesAndWages: 'payroll_salaries_wages',
  payrollTaxes: 'payroll_payroll_taxes',
  professionalDevelopment: 'professional_development',
  rentAndLease: 'rent_and_lease',
  retirementContribution: 'retirement_contribution',
  taxesFederal: 'taxes_federal_taxes',
  taxesFranchise: 'taxes_franchise_taxes',
  taxesState: 'taxes_state_taxes',
  travelExpenses: 'travel_expenses',
  uniformAndLaundry: 'uniforms_laundry',
}
