export enum ConnectBusinessBankAccountStatus {
  connected = 'connected',
  notConnected = 'not_connected',
  inProgress = 'in_progress',
}

export enum UploadPrevMonthStatementStatus {
  noStatement = 'no_statement',
  skipped = 'skipped',
  uploaded = 'uploaded',
}

export enum ScheduleOnboardingCallStatus {
  scheduled = 'scheduled',
  skipped = 'skipped',
}

export enum SignBAAAgreementStatus {
  signed = 'signed',
}

export enum FinancialAccountsPrescreenStatus {
  hasBusinessCheckingAccount = 'has_business_checking_account',
  noBusinessCheckingAccount = 'no_business_checking_account',
}
