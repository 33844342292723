import { createSlice, PayloadAction } from '@reduxjs/toolkit'
export const NEXT = 'next'
export const OTHER = 'other'
export const POLICY_OPTIONS = [
  { value: NEXT, text: 'Next' },
  { value: OTHER, text: 'Other' },
]
export const GOVERNMENT = 'government_managed'
export const TEXAS = 'texas'
export const DEFAULT = 'default'
export interface PayrollWcEnrollmentRequirement {
  id: number
  state: string
  wc_employee_min: number
  government_managed: boolean
  force_show_wc_enrollment: boolean
  createdAt: string
  updatedAt: string
}

export interface WcEnrollmentRequirement {
  show_enrollment: boolean
  show_enrollment_type: 'government_managed' | 'texas' | 'default'
  state: string
}
const initialState: PayrollWcEnrollmentRequirement | null = null

const PayrollWcEnrollmentRequirementSlice = createSlice({
  name: 'payrollWcEnrollmentRequirement',
  initialState: initialState as PayrollWcEnrollmentRequirement | null,
  reducers: {
    // Fetches the enrollment calculation
    getPayrollWcEnrollmentRequirement: (
      state,
      action: PayloadAction<PayrollWcEnrollmentRequirement>
    ) => action.payload,
  },
})

export const { getPayrollWcEnrollmentRequirement } =
  PayrollWcEnrollmentRequirementSlice.actions

export default PayrollWcEnrollmentRequirementSlice.reducer
