import { useParams } from 'react-router-dom'
import { Container, Grid, Divider } from 'semantic-ui-react'
import moment from 'moment'

import { selectPayrollByUuid } from '../payroll.selectors'
import PayrollDetails from '../RunPayroll/EmployeePayroll/PayrollDetails'
import ContactSupport from './ContactSupport'
import { formatCurrency } from '../../../utils/currencyHelpers'
import { Link, Text, Card } from '../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useReselector } from '../../../utils/sharedHooks'
import { Gusto_Payroll } from '../generated_gusto_types'

const formatDate = (date: string) =>
  moment(date).format(DATE_FORMATS.DISPLAY_SHORT)

const PayrollOverview = ({ payroll }: { payroll: Gusto_Payroll | null }) => {
  if (!payroll) {
    return null
  }

  const {
    check_date,
    pay_period: { start_date, end_date },
    processed_date,
  } = payroll

  const companyDebit = payroll.totals?.company_debit || '0'

  return (
    <Grid className="bordered-grid green-top">
      <Grid.Row className="short">
        <Text as="h1">Payroll Details</Text>
      </Grid.Row>
      <Divider className="short" />
      <Grid.Row>
        <Grid.Column width={6}>
          <b>Funds Debited :</b>
        </Grid.Column>
        <Grid.Column width={10} textAlign="right">
          <Text>{formatCurrency(companyDebit)}</Text>
        </Grid.Column>
      </Grid.Row>
      <Divider className="short" />
      <Grid.Row>
        <Grid.Column width={6}>
          <b>Pay Date: </b>
        </Grid.Column>
        <Grid.Column width={10} textAlign="right">
          <Text>{formatDate(check_date)}</Text>
        </Grid.Column>
      </Grid.Row>
      <Divider className="short" />
      <Grid.Row>
        <Grid.Column width={6}>
          <Text>
            <b>Pay Period: </b>
          </Text>
        </Grid.Column>
        {start_date && end_date && (
          <Grid.Column width={10} textAlign="right">
            <Text>
              {formatDate(start_date)} - {formatDate(end_date)}
            </Text>
          </Grid.Column>
        )}
      </Grid.Row>
      <Divider className="short" />
      <Grid.Row>
        <Grid.Column width={6}>
          <Text>
            <b>Processed on: </b>
          </Text>
        </Grid.Column>
        <Grid.Column width={10} textAlign="right">
          <Text>{processed_date ? formatDate(processed_date) : ''}</Text>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const PayrollHistoryDetails = () => {
  const { uuid } = useParams()
  const payroll = useReselector(selectPayrollByUuid, uuid)

  return (
    <Container>
      <Card>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Text as="h1">Payroll History</Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="short">
            <Grid.Column>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Link to="/payroll/history">Payroll History</Link>
                {payroll?.pay_period.start_date &&
                  payroll?.pay_period.end_date && (
                    <Text style={{ paddingTop: 3 }}>
                      <b>
                        &nbsp;&rsaquo;&nbsp;Period:{' '}
                        {moment(payroll.pay_period.start_date).format(
                          DATE_FORMATS.MONTH_DAY
                        )}{' '}
                        - {formatDate(payroll.pay_period.end_date)}
                      </b>
                    </Text>
                  )}
              </div>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column width={7}>
              <PayrollOverview payroll={payroll} />
            </Grid.Column>
            <Grid.Column width={9}>
              <ContactSupport />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row />
          <Divider />
          {uuid && <PayrollDetails uuid={uuid} title="Additional Details" />}
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollHistoryDetails
