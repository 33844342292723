import { CSSProperties, ReactNode } from 'react'
import styled from 'styled-components'
import { Colors, Fonts } from '../../styles/theme'

interface Props {
  as?:
    | 'display'
    | 'display2'
    | 'display3'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'bodyLg'
    | 'bodyMd'
    | 'bodySm'
    | 'bodyXs'
    | 'eyebrow'
  children?: ReactNode
  className?: string
  color?: keyof typeof Colors
  style?: CSSProperties
  textAlign?: 'center' | 'left' | 'right' | 'inherit'
  testId?: string
}

const StyledText = styled(
  ({
    children,
    className,
    testId,
  }: Omit<Props, 'as'> & { textStyle?: Props['as'] }) => (
    <p className={className} data-testid={testId}>
      {children}
    </p>
  )
)(({ textStyle = 'bodyMd', color, textAlign = 'inherit', style }) => ({
  // in semantic.min.css, there's a selector that literally takes precedence over <5 classnames,
  // so unfortunately this is necessary to overwrite all those styles
  '&&&&&': {
    ...Fonts[textStyle],
    ...(color && {
      color: Colors[color],
    }),
    margin: 0,
    textAlign,
    ...style,
  },
}))

const Text = ({ as, ...rest }: Props) => <StyledText textStyle={as} {...rest} />

export default Text
