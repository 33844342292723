import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  NormalizedUserCallEvent,
  UserCallEvent,
} from '../finances/userCallEventsReducer'

export interface AllUserCallEventState {
  [userId: number]: { [callEventId: number]: UserCallEvent }
}

const initialState: AllUserCallEventState = {}

const allUserCallEventsSlice = createSlice({
  name: 'allUserCallEvents',
  initialState,
  reducers: {
    receiveAdminUserCallEvents: (
      state,
      action: PayloadAction<{ userId: number; data: NormalizedUserCallEvent }>
    ) => {
      if (!action.payload.data.entities.userCallEvents) {
        return
      }

      state[action.payload.userId] = {
        ...state[action.payload.userId],
        ...action.payload.data.entities.userCallEvents,
      }
    },
  },
})

export default allUserCallEventsSlice.reducer

export const { receiveAdminUserCallEvents } = allUserCallEventsSlice.actions
