import { useEffect, useMemo, useState } from 'react'
import { FormikProvider, useFormik, useFormikContext } from 'formik'
import { Divider, Grid } from 'semantic-ui-react'
import { filter, mapValues, sortBy } from 'lodash'
import moment from 'moment'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { selectOnboardingEstimates } from '../Taxes/QuarterlyTaxEstimates/userTaxEstimates.selector'
import { useReselector } from '../../utils/sharedHooks'
import {
  fetchUserTaxEstimates,
  updateUserTaxEstimates,
  UserTaxEstimate,
} from '../Taxes/QuarterlyTaxEstimates/userTaxEstimates.slice'
import { LabelDescription } from '../../components/BaseComponents/Input'
import FileUploadModal from '../../components/FileUpload/FileUploadModal'
import { getUserDocumentById } from '../UserDocuments/userDocuments.selector'
import {
  Button,
  Card,
  FormikDateInput,
  FormikHiddenInput,
  FormikInput,
  GridRowColumn,
  Icon,
  makeDateSchema,
  makeNumberSchema,
  Modal,
  Text,
} from '../../components/BaseComponents'
import { markUserActionItemCompleteIfExists } from '../Dashboard/UserActionItems/service'
import { PostOnboardingTaskIdents } from './config'
import { selectUserDocumentCategoryByInternalName } from '../Admin/UserDocumentCategories/userDocumentCategories.selectors'
import { selectCurrentAnnualTaxYear } from '../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { fetchUserDocuments } from '../UserDocuments/userDocuments.slice'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { UploadDocumentType } from '../../constants/businessConstants'
import { centsToDollars, dollarsToCents } from '../../utils/currencyHelpers'
import { useAnalyticsTrack } from '../Amplitude'
import { UserDocumentCategoryIdentifier } from '../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { useAppDispatch } from '../../utils/typeHelpers'

const QTEInput = ({
  estimate: { id, filingState, userDocumentId, taxQuarter },
}: {
  estimate: UserTaxEstimate
}) => {
  const [fileUploadOpen, setFileUploadOpen] = useState(false)
  const year = useReselector(selectCurrentAnnualTaxYear)

  // If the form is closed without saving most of it does not lose state but with the way the documents are
  // loaded/reloaded the past form state gets lost.  This fetches the document id value on load so it will restore
  // properly
  const { values } = useFormikContext<{ estimates: UserTaxEstimate[] }>()

  const document = useReselector(
    getUserDocumentById,
    values.estimates[id]?.userDocumentId || userDocumentId
  )
  const categoryIdentifier = useMemo(() => {
    const quarter = taxQuarter.split('-')[1]
    switch (quarter) {
      case '1':
        return UserDocumentCategoryIdentifier.q1Payment
      case '2':
        return UserDocumentCategoryIdentifier.q2Payment
      case '3':
        return UserDocumentCategoryIdentifier.q3Payment
      case '4':
        return UserDocumentCategoryIdentifier.q4Payment
      default:
        return UserDocumentCategoryIdentifier.qtePayment
    }
  }, [taxQuarter])
  const category = useReselector(
    selectUserDocumentCategoryByInternalName,
    categoryIdentifier
  )

  const [uploadedFile, setUploadedFile] = useState({
    title: document?.fileDescription || '',
    url: document?.signedUrl || '',
    id: document?.id,
  })

  useEffect(() => {
    if (document?.id) {
      setUploadedFile({
        title: document.fileDescription || '',
        url: document.signedUrl || '',
        id: document.id,
      })
    }
  }, [document?.fileDescription, document?.id, document?.signedUrl])

  return (
    <>
      <GridRowColumn>
        <Text as="eyebrow" color="darkGray">
          {filingState ? `State - ${filingState}` : 'Federal'}
        </Text>
      </GridRowColumn>
      <Grid.Row className="short">
        <Grid.Column width={10}>
          <FormikInput
            label="How much did you pay?"
            placeholder="Enter amount here"
            required
            fullWidth
            name={`estimates.${id}.amountPaid`}
            schema={makeNumberSchema({
              field: 'amount paid',
              allowedDecimals: 2,
            })}
            componentType="currency"
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <FormikDateInput
            label="When did you pay?"
            required
            fullWidth
            name={`estimates.${id}.paidAt`}
            schema={makeDateSchema({ field: 'amount paid' })}
            maxDate={new Date()}
          />
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn short>
        <LabelDescription label="Attach receipt" required />
        <Button
          onClick={() => setFileUploadOpen(true)}
          size="medium"
          variant="secondary"
        >
          <Icon icon={regular('upload')} style={{ marginRight: 6 }} />
          Upload Receipt
        </Button>
        {uploadedFile?.title && (
          <>
            <Text style={{ marginTop: 10, fontWeight: 'bold' }} as="bodySm">
              {uploadedFile.title}
            </Text>
            <Text style={{ fontStyle: 'italic' }} as="bodySm">
              Can be found in your &quot;Tax&quot; Documents
            </Text>
          </>
        )}
        <FormikHiddenInput
          name={`estimates.${id}.userDocumentId`}
          value={uploadedFile.id}
        />
      </GridRowColumn>
      <FileUploadModal
        open={fileUploadOpen}
        close={() => setFileUploadOpen(false)}
        documentType={UploadDocumentType.TAX}
        categoryId={category?.id}
        userFacing
        setUploadedFile={setUploadedFile}
        year={year}
      />
    </>
  )
}

const QuarterPanel = ({
  estimates,
  title,
}: {
  estimates: UserTaxEstimate[]
  title: string
}) => {
  if (!estimates.length) {
    return null
  }

  return (
    <Card backgroundColor="stone">
      <Grid>
        <GridRowColumn>
          <Text as="h2">{title}</Text>
        </GridRowColumn>

        {sortBy(estimates, 'type', 'filingState').map((estimate) => (
          <QTEInput estimate={estimate} key={`estimate-row-${estimate.id}`} />
        ))}
      </Grid>
    </Card>
  )
}

const OnboardingEstimatedTaxPaymentsModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()
  const estimates = useReselector(selectOnboardingEstimates)
  const year = useReselector(selectCurrentAnnualTaxYear)
  useEffect(() => {
    dispatch(fetchUserDocuments())
    dispatch(fetchUserTaxEstimates())
  }, [dispatch])

  // Transform the estimates into values digestible by the form
  const initialValues = useMemo(
    () => ({
      estimates: mapValues(
        estimates,
        ({ id, paidAt, amountPaidInCents, userDocumentId }) => ({
          id,
          paidAt: paidAt ? moment(paidAt).format(DATE_FORMATS.INPUT) : '',
          amountPaid: amountPaidInCents
            ? centsToDollars(amountPaidInCents)
            : '',
          userDocumentId,
        })
      ),
    }),
    [estimates]
  )

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async ({ estimates }) => {
      await Promise.all(
        Object.values(estimates).map(
          ({ id, paidAt, amountPaid, userDocumentId }) =>
            dispatch(
              updateUserTaxEstimates(id, {
                paidAt: moment(paidAt, DATE_FORMATS.INPUT).valueOf(),
                amountPaidInCents: amountPaid
                  ? dollarsToCents(amountPaid)
                  : null,
                userDocumentId,
              })
            )
        )
      )

      await markUserActionItemCompleteIfExists(
        PostOnboardingTaskIdents.ENTER_QTE_PAYMENTS,
        (event, properties) => track(event, properties)
      )
      onClose()
    },
  })

  return (
    <Modal open={open} closeIcon onClose={onClose} size="small">
      <FormikProvider value={formik}>
        <Modal.Header>Enter estimated tax payments for {year}</Modal.Header>
        <Modal.Content>
          <Grid>
            <GridRowColumn>
              <Text as="bodyLg">
                Enter your estimated tax payments for this year. Include any
                receipts if you have them. You can view estimated payments
                you’ve made on the federal or state tax portals.
              </Text>
            </GridRowColumn>
            <Divider />
            <QuarterPanel
              title="Q1 (January - March)"
              estimates={filter(
                estimates,
                (estimate) => estimate.taxQuarter === `${year}-1`
              )}
            />
            <QuarterPanel
              title="Q2 (April - July)"
              estimates={filter(
                estimates,
                (estimate) => estimate.taxQuarter === `${year}-2`
              )}
            />
            <QuarterPanel
              title="Q3 (August - September)"
              estimates={filter(
                estimates,
                (estimate) => estimate.taxQuarter === `${year}-3`
              )}
            />
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={formik.submitForm}>Submit</Button>
        </Modal.Actions>
      </FormikProvider>
    </Modal>
  )
}

export default OnboardingEstimatedTaxPaymentsModal
