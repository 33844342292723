import { useCallback, useState } from 'react'
import { Button } from 'semantic-ui-react'
import {
  CursorPaginatedResponse,
  PrismaCursorPagination,
} from '../../../utils/fetchHelpers'
import {
  TypeColumn,
  TableRowProps,
  AdminBaseTable,
  LoadingView,
  OrderBy,
} from './AdminBaseTable'
import { useAsync } from '../../../utils/sharedHooks'

export enum PaginationStyle {
  LoadMore = 'LoadMore',
  // Infinite = 'Infinite', // TODO: Implement infinite scrolling
}

export interface CursorPaginatedTableProps<T extends object> {
  columns: TypeColumn<T>[]
  paginationStyle?: PaginationStyle
  fetchData: (
    pagination: PrismaCursorPagination,
    orderBy?: OrderBy
  ) => Promise<CursorPaginatedResponse<T>>
  tableRowPropsConfig?: TableRowProps<T>
  paginationProps?: PrismaCursorPagination
  wrapperStyle?: React.CSSProperties
  initialOrderBy?: OrderBy
}

/**
 * Hook for fetching and rendering a paginated table with cursor-based pagination.
 *
 * Cursor-based pagination is useful for large datasets, as it allows for efficient
 * fetching using DB indexes and last fetched item's cursor. However, the sort order
 * is fixed and you can only fetch next or previous pages.
 */
export const useCursorPaginatedTable = <T extends { id: number }>({
  columns,
  paginationStyle = PaginationStyle.LoadMore,
  fetchData,
  tableRowPropsConfig,
  paginationProps = {
    cursor: undefined,
    take: 20,
  },
  wrapperStyle = {},
  initialOrderBy,
}: CursorPaginatedTableProps<T>) => {
  const [pagination, setPagination] =
    useState<PrismaCursorPagination>(paginationProps)
  const [orderBy, setOrderBy] = useState<OrderBy | undefined>(initialOrderBy)

  const [responseData, setResponseData] = useState<CursorPaginatedResponse<T>>({
    data: [],
    pagination: {},
  })

  const getItem = useCallback(
    (id: number) => {
      return responseData.data.find((dataItem) => dataItem.id === id)
    },
    [responseData]
  )

  const updateItems = (item: T[]) => {
    const updatedData = responseData.data.map((dataItem) => {
      const updatedItem = item.find((i) => i.id === dataItem.id)
      if (updatedItem) {
        return {
          ...dataItem,
          ...updatedItem,
        }
      }
      return dataItem
    })
    setResponseData({
      data: updatedData,
      pagination: responseData.pagination,
    })
  }

  const removeItems = (ids: number[]) => {
    const updatedData = responseData.data.filter(
      (dataItem) => !ids.includes(dataItem.id)
    )
    setResponseData({
      data: updatedData,
      pagination: responseData.pagination,
    })
  }

  const { loading, error } = useAsync(
    useCallback(async () => {
      const response = await fetchData(pagination, orderBy)
      if (response) {
        if (paginationStyle === PaginationStyle.LoadMore && pagination.cursor) {
          // Append new data to existing data
          setResponseData((prev) => ({
            data: [...prev.data, ...response.data],
            pagination: response.pagination,
          }))
        } else {
          setResponseData(response)
        }
      }

      return response
    }, [paginationStyle, pagination, orderBy, fetchData])
  )

  const resetPagination = () => {
    setPagination({
      ...pagination,
      cursor: undefined,
    })
  }

  const loadMore = (cursor: string | undefined) => {
    setPagination({ ...pagination, cursor })
  }

  const renderTable = () => {
    if (loading && !pagination.cursor) {
      return <LoadingView />
    }
    return (
      <>
        <div
          style={{
            ...wrapperStyle,
            margin: '10px 10px 10px 0px',
          }}
        >
          <AdminBaseTable
            columns={columns}
            data={responseData.data}
            tableRowPropsConfig={tableRowPropsConfig}
            orderBy={orderBy}
            onOrderByChange={(orderBy) => {
              resetPagination()
              setOrderBy(orderBy)
            }}
          />
        </div>
        {paginationStyle === PaginationStyle.LoadMore &&
          responseData.pagination.nextCursor && (
            <div>
              <Button
                loading={loading}
                primary
                onClick={() => loadMore(responseData.pagination.nextCursor)}
              >
                Load More
              </Button>
            </div>
          )}
      </>
    )
  }

  return {
    renderTable,
    items: responseData.data,
    getItem,
    updateItems,
    removeItems,
    fetchError: error,
    resetPagination,
  }
}
