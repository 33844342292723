import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FinancialAccountWithAdminInfo } from './financialAccountsReducer'
import { FinancialProfile, User } from '../auth/userReducer'

export interface FinancialAccountHealth extends FinancialAccountWithAdminInfo {
  accountTransactions: LatestTransaction[]
}

export interface LatestTransaction {
  date: string
}

export interface AccountsSummary {
  count: number
  plaid: {
    count: number
    percentage: number
  }
  manual: {
    count: number
    percentage: number
  }
  inactive: {
    count: number
    percentage: number
  }
}

export interface AccountsHealthSummary {
  healthy: {
    count: number
    percentage: number
  }
  disconnected: {
    count: number
    percentage: number
  }
  noTransactionsInLastSevenDays: {
    count: number
    percentage: number
  }
  usersWithNoAccounts: {
    count: number
  }
}

export interface FinancialAccounts {
  count: number
  financialAccounts: FinancialAccountHealth[]
}

export interface TransactionErrors {
  count: number
  accounts: FinancialAccountHealth[]
}

export interface ConnectionErrors {
  count: number
  accounts: FinancialAccountHealth[]
}

export interface PlaidErrors {
  count: number
  accounts: FinancialAccountHealth[]
}

export interface UserFinancialAccountsUser {
  id: number
  firstName: string
  lastName: string
  financialProfile: {
    id: number
  }
}

// There is a ton of duplicated data in here that should be spread to other reducers
export interface UserFinancialAccountsState {
  accountsSummary: AccountsSummary
  accountsHealthSummary: AccountsHealthSummary
  financialAccounts: FinancialAccounts
  transactionErrors: TransactionErrors
  connectionErrors: ConnectionErrors
  plaidErrors: PlaidErrors
  users: UserFinancialAccountsUser[]
}

export const userFinancialAccountsInitialState: UserFinancialAccountsState = {
  accountsSummary: {
    count: 0,
    plaid: {
      count: 0,
      percentage: 0,
    },
    manual: {
      count: 0,
      percentage: 0,
    },
    inactive: {
      count: 0,
      percentage: 0,
    },
  },
  accountsHealthSummary: {
    healthy: {
      count: 0,
      percentage: 0,
    },
    disconnected: {
      count: 0,
      percentage: 0,
    },
    noTransactionsInLastSevenDays: {
      count: 0,
      percentage: 0,
    },
    usersWithNoAccounts: {
      count: 0,
    },
  },
  financialAccounts: {
    count: 0,
    financialAccounts: [],
  },
  transactionErrors: {
    count: 0,
    accounts: [],
  },
  connectionErrors: {
    count: 0,
    accounts: [],
  },
  plaidErrors: {
    count: 0,
    accounts: [],
  },
  users: [],
}

const financialAccountsHealthSlice = createSlice({
  name: 'financialAccounts',
  initialState: userFinancialAccountsInitialState,
  reducers: {
    receiveFinancialAccountsSummary: (
      state,
      action: PayloadAction<AccountsSummary>
    ) => {
      state.accountsSummary = action.payload
    },
    receiveFinancialAccountHealthSummary: (
      state,
      action: PayloadAction<AccountsHealthSummary>
    ) => {
      state.accountsHealthSummary = action.payload
    },
    receiveAllUserFinAccountHealth: (
      state,
      action: PayloadAction<
        Array<User & { financialProfile: FinancialProfile }>
      >
    ) => {
      state.users = action.payload
    },
    receiveFinAccountHealth: (
      state,
      action: PayloadAction<FinancialAccounts>
    ) => {
      state.financialAccounts = action.payload
    },
    receiveFinAccountTransErrors: (
      state,
      action: PayloadAction<TransactionErrors>
    ) => {
      state.transactionErrors = action.payload
    },
    receiveFinAccountConnectionErrors: (
      state,
      action: PayloadAction<ConnectionErrors>
    ) => {
      state.connectionErrors = action.payload
    },
    receiveFinAccountPlaidErrors: (
      state,
      action: PayloadAction<PlaidErrors>
    ) => {
      state.plaidErrors = action.payload
    },
  },
})

export const {
  receiveFinancialAccountsSummary,
  receiveAllUserFinAccountHealth,
  receiveFinancialAccountHealthSummary,
  receiveFinAccountHealth,
  receiveFinAccountConnectionErrors,
  receiveFinAccountTransErrors,
  receiveFinAccountPlaidErrors,
} = financialAccountsHealthSlice.actions

export default financialAccountsHealthSlice.reducer
