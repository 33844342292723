import { FormikProvider, useFormik } from 'formik'
import { Grid } from 'semantic-ui-react'

import { useAppDispatch } from '../../utils/typeHelpers'
import { useReselector } from '../../utils/sharedHooks'
import {
  createSmsConsentStatus,
  getSmsConsentStatus,
  SmsConsentSource,
  SmsConsentStatusOption,
} from './smsConsentReducer'
import {
  FormikInput,
  getFieldName,
  Modal,
  Button,
  GridRowColumn,
  FormikLabelError,
  FormikRadioButton,
} from '../../components/BaseComponents'

const EditSmsConsentModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  const dispatch = useAppDispatch()
  const smsConsentStatus = useReselector(getSmsConsentStatus)

  const formik = useFormik({
    initialValues: {
      mobilePhoneNumber: smsConsentStatus?.mobilePhoneNumber || '',
      doesConsent: smsConsentStatus?.status === SmsConsentStatusOption.OPT_IN,
    },
    onSubmit: async (values) => {
      const res = await dispatch(
        createSmsConsentStatus({
          mobilePhoneNumber: values.doesConsent
            ? values.mobilePhoneNumber
            : null,
          source: SmsConsentSource.PRACTICE_PROFILE,
          status: values.doesConsent
            ? SmsConsentStatusOption.OPT_IN
            : SmsConsentStatusOption.OPT_OUT,
        })
      )

      if (res) {
        onClose()
      }
    },
  })

  const { values, submitForm, isSubmitting } = formik

  return (
    <Modal closeIcon onClose={onClose} open={open}>
      <FormikProvider value={formik}>
        <Modal.Header>Edit SMS Consent</Modal.Header>
        <Modal.Content>
          <Grid>
            <GridRowColumn>
              <FormikLabelError
                name={getFieldName<typeof values>('doesConsent')}
                label="Opt in/out of SMS notifications"
              />
              <FormikRadioButton
                name={getFieldName<typeof values>('doesConsent')}
                label="I would like to receive SMS notifications"
                value
              />
              <br />
              <FormikRadioButton
                name={getFieldName<typeof values>('doesConsent')}
                label="I do not want to receive SMS notifications"
                value={false}
              />
            </GridRowColumn>
            {values.doesConsent && (
              <GridRowColumn>
                <FormikInput
                  name={getFieldName<typeof values>('mobilePhoneNumber')}
                  label="Your mobile phone number"
                  componentType="phone"
                />
              </GridRowColumn>
            )}
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose} variant="secondary">
            Close
          </Button>
          <Button
            onClick={submitForm}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Save
          </Button>
        </Modal.Actions>
      </FormikProvider>
    </Modal>
  )
}

export default EditSmsConsentModal
