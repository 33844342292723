import moment from 'moment'
import { Label, Table } from 'semantic-ui-react'
import { Text } from '../../../BaseComponents'
import { FinancialAccountReconciliationWithTransactions } from '../../../../reducers/admin/allFinancialAccountReconciliationsReducer'
import { FinancialAccountWithAdminInfo } from '../../../../reducers/admin/financialAccountsReducer'
import { formatCurrencyFromCents } from '../../../../utils/currencyHelpers'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'

const FinancialAccountReconciliationsTableRow = ({
  financialAccount,
  financialAccountReconciliation,
}: {
  financialAccount?: FinancialAccountWithAdminInfo
  financialAccountReconciliation: FinancialAccountReconciliationWithTransactions
}) => {
  const accountDisplayName = `${financialAccount?.plaidInstitutionName} (${financialAccount?.mask})`
  const {
    id,
    startingBalanceDate,
    startingBalanceInCents,
    endingBalanceDate,
    endingBalanceInCents,
    status,
    type,
  } = financialAccountReconciliation

  return (
    <Table.Row key={id}>
      <Table.Cell>{id}</Table.Cell>
      <Table.Cell>
        {moment.utc(startingBalanceDate).format(DATE_FORMATS.DISPLAY_LONG)}
      </Table.Cell>
      <Table.Cell>{formatCurrencyFromCents(startingBalanceInCents)}</Table.Cell>
      <Table.Cell>
        {moment.utc(endingBalanceDate).format(DATE_FORMATS.DISPLAY_LONG)}
      </Table.Cell>
      <Table.Cell>{formatCurrencyFromCents(endingBalanceInCents)}</Table.Cell>
      <Table.Cell>{accountDisplayName}</Table.Cell>
      <Table.Cell>
        <Label color={status === 'complete' ? 'green' : 'red'}>{status}</Label>
        <Text as="bodyXs" color="darkGray" style={{ paddingTop: 4 }}>
          {type === 'auto' ? 'Auto-reconciled' : 'Manually reconciled'}{' '}
        </Text>
      </Table.Cell>
    </Table.Row>
  )
}

export default FinancialAccountReconciliationsTableRow
