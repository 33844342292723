import { Image } from 'semantic-ui-react'
import { Button, Modal, Text } from '../../components/BaseComponents'

export const OnboardingModal = ({
  showModal,
  setShowModal,
}: {
  showModal: boolean
  setShowModal: (value: boolean) => void
}) => {
  return (
    <Modal
      closeIcon
      onClose={() => setShowModal(false)}
      open={showModal}
      size="tiny"
    >
      <Modal.Header>Are you sure you want to skip?</Modal.Header>
      <Modal.Content>
        <div className="connect-business-accounts-skip__modal__body">
          <Image
            className="connect-business-accounts-skip__modal__image"
            src="https://heard-images.s3.amazonaws.com/assets/warning-logo.png"
          />

          <div>
            <Text style={{ marginBottom: '6px' }}>
              <b>
                You need to connect a business checking account to use Heard.
              </b>
            </Text>

            <Text style={{ marginBottom: '6px' }}>
              With a connected business bank account:
            </Text>

            <ul style={{ paddingLeft: '18px' }}>
              <li>Your bookkeeper can complete your catch-up bookkeeping</li>
              <li>
                You’ll receive a clear picture of your practice’s finances
              </li>
            </ul>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="actionLink"
          style={{ marginRight: 36 }}
          onClick={() => setShowModal(false)}
        >
          Cancel
        </Button>
        <Button variant="warning">Skip</Button>
      </Modal.Actions>
    </Modal>
  )
}
