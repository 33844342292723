import LearnHowBookkeepingWorks from './LearnHowBookkeepingWorks'
import BookkeepingAtHeard from './BookkeepingAtHeard'
import HowToClarifyTransactions from './HowToClarifyTransactions'
import { useBookkeepingSteps } from './CalculateBookkeepingFlow'
import TransactionsClarifiedSuccess from './TransactionsClarifiedSuccess'
import ClarifyTransactionsExample from './ClarifyTransactionsExample'
import { BOOKKEEPING_STEPS } from './utils'
import { useScrollRef } from '../../../../utils/sharedHooks'
import { useEffect } from 'react'

const BookkeepingFlow = () => {
  const { currentStep, goToNextStep, goToPreviousStep } = useBookkeepingSteps(
    BOOKKEEPING_STEPS.learnHowBookkeepingWorks
  )

  const { scrollRef, scrollToRef } = useScrollRef({ autoScroll: true })

  useEffect(() => {
    scrollToRef()
  }, [currentStep, scrollToRef])

  const renderStep = () => {
    switch (currentStep) {
      case BOOKKEEPING_STEPS.learnHowBookkeepingWorks:
        return (
          <LearnHowBookkeepingWorks
            handleContinue={goToNextStep}
            handleBack={goToPreviousStep}
          />
        )
      case BOOKKEEPING_STEPS.bookkeepingAtHeard:
        return (
          <BookkeepingAtHeard
            handleBack={goToPreviousStep}
            handleContinue={goToNextStep}
          />
        )
      case BOOKKEEPING_STEPS.howToClarifyTransactions:
        return (
          <HowToClarifyTransactions
            handleBack={goToPreviousStep}
            handleContinue={goToNextStep}
          />
        )
      case BOOKKEEPING_STEPS.clarifyTransactionsExample:
        return (
          <ClarifyTransactionsExample
            handleBack={goToPreviousStep}
            handleContinue={goToNextStep}
          />
        )
      case BOOKKEEPING_STEPS.transactionsClarifiedSuccess:
        return (
          <TransactionsClarifiedSuccess
            handleBack={goToPreviousStep}
            handleContinue={goToNextStep}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <span ref={scrollRef} />
      {renderStep()}
    </>
  )
}

export default BookkeepingFlow
