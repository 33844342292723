import { useState } from 'react'
import { Grid, List } from 'semantic-ui-react'
import {
  Modal,
  GridRowColumn,
  Text,
  Button,
  Table,
  Toggle,
} from '../../BaseComponents'
import {
  deletePlaidItem,
  markPlaidItemInactive,
  updateAdminUserFinancialAccounts,
} from '../../../actions/admin/adminFinancialAccountActions'
import { useAnalyticsTrack } from '../../../features/Amplitude'
import { PlaidItem } from '../../../reducers/finances/plaidItemReducer'
import { FinancialAccount } from '../../../reducers/finances/financialAccountsReducer'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface AdminConfirmModalProps {
  open: boolean
  toggleOpen: () => void
  plaidItem: PlaidItem
  userId: number
  setError: (errorType: string) => void
}

export const AdminDeleteModal = ({
  open,
  toggleOpen,
  plaidItem,
  userId,
  setError,
}: AdminConfirmModalProps) => {
  const [deleting, setDeleting] = useState(false)

  const dispatch = useAppDispatch()

  const confirmDelete = async () => {
    setDeleting(true)
    const res = await deletePlaidItem(plaidItem.id, userId)(dispatch)
    if (!res) {
      setError('delete')
    }
    setDeleting(false)
    toggleOpen()
  }

  return (
    <Modal open={open} closeIcon onClose={toggleOpen}>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text as="h2">
              Are you sure you want to permanently delete this connection?
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>Deleting this connection will:</Text>
            <List as="ul">
              <List.Item as="li">
                <Text>
                  <b>Permanently remove all transactions</b> from this
                  institution, both old and new.
                </Text>
              </List.Item>
              <List.Item as="li">
                <Text>
                  Remove this institution both from the user&apos;s page and
                  Admin.
                </Text>
              </List.Item>
            </List>
            <Text>
              This should only be used as a final option and is typically
              reserved for deleting financial institutions with all personal
              accounts.
            </Text>
            <br />
            <Text as="h3"> This cannot be undone.</Text>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="secondary"
          style={{ marginRight: 8 }}
          onClick={toggleOpen}
        >
          Cancel
        </Button>
        <Button variant="warning" loading={deleting} onClick={confirmDelete}>
          Delete Permanently
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export const AdminRemoveModal = ({
  open,
  toggleOpen,
  plaidItem,
  userId,
  setError,
}: AdminConfirmModalProps) => {
  const [removing, setRemoving] = useState(false)

  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack(true)

  const confirmRemove = async () => {
    setRemoving(true)
    const res = await markPlaidItemInactive(plaidItem.id, userId)(dispatch)
    if (!res) {
      setError('mark inactive')
    }
    track('submitted plaid remove connection', {
      fi_name: plaidItem.institutionName,
    })
    setRemoving(false)
    toggleOpen()
  }

  return (
    <Modal open={open} closeIcon onClose={toggleOpen}>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text as="h2">
              Are you sure you want to remove this connection?
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>Removing this connection will:</Text>
            <List as="ul">
              <List.Item as="li">
                <Text>
                  <b>Stop syncing new transactions from all accounts.</b> Past
                  transactions will remain.
                </Text>
              </List.Item>
              <List.Item as="li">
                <Text>
                  Hide this financial institution from the user&apos;s Settings
                  page. Admins can still view it but cannot perform any actions.
                </Text>
              </List.Item>
            </List>
            <Text>
              This is typically done when all reconnection attempts have been
              exhausted and the user needs to re-add the institution as a new
              connection.
            </Text>
            <br />
            <Text as="h3"> This cannot be undone.</Text>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="secondary"
          style={{ marginRight: 8 }}
          onClick={toggleOpen}
        >
          Cancel
        </Button>
        <Button variant="warning" loading={removing} onClick={confirmRemove}>
          Remove connection
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export const AdminFinancialErrorModal = ({
  open,
  toggleOpen,
}: {
  open: boolean
  toggleOpen: () => void
}) => {
  return (
    <Modal open={open} closeIcon onClose={toggleOpen}>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text as="h2">Error</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              There was an error performing this action. No changes were made to
              this connection. Please try again.
            </Text>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={toggleOpen}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export const AdminBankAccessStatusModal = ({
  open,
  toggleOpen,
  accounts,
  plaidItem,
}: {
  open: boolean
  toggleOpen: () => void
  accounts: FinancialAccount[]
  plaidItem: PlaidItem
}) => {
  const dispatch = useAppDispatch()

  const handleChangeToggle = async (checked: boolean, accountId: number) => {
    await dispatch(
      updateAdminUserFinancialAccounts(plaidItem.userId, accountId, {
        bankAccessEnabledAt: checked ? new Date().toISOString() : null,
      })
    )
  }

  return (
    <Modal open={open} closeIcon onClose={toggleOpen}>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text as="h2">
              Updating Bank Account Statuses for {plaidItem.institutionName}
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Table>
              <Table.Row>
                <Table.HeaderCell>Account Name</Table.HeaderCell>
                <Table.HeaderCell>Limited Bank Access Enabled</Table.HeaderCell>
              </Table.Row>
              {accounts.map((account) => (
                <Table.Row key={account.id}>
                  <Table.Cell>
                    {account.name}: {account.mask}
                  </Table.Cell>
                  <Table.Cell>
                    <Toggle
                      checked={Boolean(account.bankAccessEnabledAt)}
                      onChange={(checked) =>
                        handleChangeToggle(checked, account.id)
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="secondary"
          style={{ marginRight: 8 }}
          onClick={toggleOpen}
        >
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
