import { Grid } from 'semantic-ui-react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Button,
  Icon,
  Link,
  Modal,
  Text,
} from '../../../components/BaseComponents'
import { SIGNUP_PATHS } from '../helpers'
import { createUserActionItem } from '../../Dashboard/UserActionItems/userActionItems.slice'
import { OnboardingTaskIdents } from '../../Onboarding/config'
import { useNavigateWithPersistParams } from '../../../utils/sharedHooks'

export const SkipConnectBankModal = ({
  closeModal,
}: {
  closeModal: () => void
}) => {
  const navigate = useNavigateWithPersistParams()

  return (
    <Modal closeIcon onClose={closeModal} open size="small">
      <Modal.Content>
        <Grid>
          <Grid.Row />
          <Grid.Row />
          <Grid.Row>
            <Grid.Column style={{ display: 'flex', justifyContent: 'center' }}>
              <Icon
                icon={light('triangle-exclamation')}
                color="red"
                size="2x"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Text as="h1" textAlign="center">
                Are you sure you want to skip?
              </Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Text as="bodyLg" textAlign="center">
                Heard can&apos;t work on your books without a bank connection.
              </Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="short">
            <Grid.Column>
              <Text as="bodyLg" textAlign="center">
                If you skip this step now, you&apos;ll be asked to connect your
                bank after you&apos;ve finished signing up.
              </Text>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal} variant="actionLink">
          Return to Connect
        </Button>
        <Button
          onClick={() => {
            createUserActionItem(
              OnboardingTaskIdents.CONNECT_A_BUSINESS_BANK_ACCOUNT
            )
            navigate(SIGNUP_PATHS.choosePlan)
          }}
          variant="secondary"
          id="btn-signup-connect-accounts-skipped"
        >
          Skip for now
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export const SharingPermissionsModal = ({
  closeModal,
  hasAccount,
}: {
  closeModal: () => void
  hasAccount?: boolean
}) => {
  const navigate = useNavigateWithPersistParams()

  return (
    <Modal closeIcon onClose={closeModal} open size="tiny">
      <Modal.Header>Sharing permissions needed</Modal.Header>
      <Modal.Content>
        <Text as="bodyLg">
          Please login to your bank account and check if your online settings
          allow you to share your data. Then, retry connecting with Plaid.
        </Text>
        <br />
        <Link href="https://plaid.com/trouble-connecting/" newPage>
          Troubleshooting tips for connecting with Plaid
          <Icon
            icon={light('square-arrow-up-right')}
            style={{ marginLeft: 10 }}
          />
        </Link>
      </Modal.Content>
      {hasAccount ? (
        <Modal.Actions>
          <Button
            variant="secondary"
            onClick={() => navigate(SIGNUP_PATHS.choosePlan)}
          >
            Skip for Now
          </Button>
          <Button onClick={closeModal}>Try again</Button>
        </Modal.Actions>
      ) : (
        <Modal.Actions>
          <Button variant="secondary" onClick={closeModal}>
            Try Again
          </Button>
          <Button onClick={() => navigate(SIGNUP_PATHS.choosePlan)}>
            Continue
          </Button>
        </Modal.Actions>
      )}
    </Modal>
  )
}

export const ConnectionFailureModal = ({
  closeModal,
  hasAccount,
}: {
  closeModal: () => void
  hasAccount?: boolean
}) => {
  const navigate = useNavigateWithPersistParams()

  return (
    <Modal closeIcon onClose={closeModal} open size="tiny">
      <Modal.Header>Plaid is experiencing difficulties</Modal.Header>
      <Modal.Content>
        <Text as="bodyLg">
          Sorry, there&apos;s a technical issue with Plaid, our bank connection
          service. You can skip this step for now and connect your bank after
          you&apos;ve finished signing up.
        </Text>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => navigate(SIGNUP_PATHS.choosePlan)}>
          {hasAccount ? 'Skip for Now' : 'Continue'}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
