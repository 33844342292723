import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Container, Grid, Divider, Loader } from 'semantic-ui-react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  FETCH_PAY_SCHEDULE_KEY,
  FETCH_PAYROLL_KEY,
  fetchAllPayrolls,
  fetchAllPaySchedules,
} from '../payrollActions'
import {
  selectPaySchedule,
  selectPreviousUnprocessedPayrollOptions,
  selectUpcomingPayroll,
} from '../payroll.selectors'
import './runPayroll.scss'
import '../payroll.scss'
import AutoEnrollModal from './AutoEnrollModal'
import {
  Text,
  Button,
  Card,
  Link,
  Alert,
  GridRowColumn,
  Icon,
  Dropdown,
} from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import UpcomingPayroll from './UpcomingPayroll'
import { useReselector } from '../../../utils/sharedHooks'
import { getIsFetching, selectIsFetchingForKeys } from '../../../reducers/fetch'
import { DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import { DateTime } from 'luxon'
import { clearPayrolls } from '../payroll.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'

const PayrollHeader = () => {
  const dispatch = useAppDispatch()
  const paySchedule = useReselector(selectPaySchedule)
  const upcomingPayroll = useReselector(selectUpcomingPayroll)
  const isLoading = useReselector(selectIsFetchingForKeys, [
    FETCH_PAYROLL_KEY,
    FETCH_PAY_SCHEDULE_KEY,
  ])
  const [modalOpen, setModalOpen] = useState(false)

  const fetchPayrolls = useCallback(() => {
    dispatch(
      fetchAllPayrolls({
        end_date: DateTime.now()
          .plus({ months: 3 })
          .minus({ day: 1 })
          .toFormat(DATE_FORMATS_LUXON.GUSTO_SUBMIT),
        start_date: DateTime.now()
          .minus({ months: 3 })
          .toFormat(DATE_FORMATS_LUXON.GUSTO_SUBMIT),
      })
    )
  }, [dispatch])

  // Fetch pay schedules/payroll
  useEffect(() => {
    dispatch(fetchAllPaySchedules())
    fetchPayrolls()
  }, [dispatch, fetchPayrolls])

  const onClose = useCallback(() => {
    setModalOpen(false)
    // Changing the pay schedule will invalidate upcoming payrolls - the previous ones need to be wiped and refreshed
    dispatch(clearPayrolls())
    fetchPayrolls()
  }, [dispatch, fetchPayrolls])

  if (isLoading) {
    return (
      <Container className="loading-wrapper">
        <Loader active inline />
      </Container>
    )
  }

  if (!paySchedule || !upcomingPayroll) {
    return <Alert>Something went wrong</Alert>
  }

  const isAutoPilot = Boolean(paySchedule?.auto_pilot)
  return (
    <Grid>
      {!isAutoPilot && (
        <GridRowColumn>
          <Grid style={{ backgroundColor: Colors.stone }} textAlign="center">
            <GridRowColumn>
              <Text as="h2">Put your payroll on autopilot!</Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text>
                Tired of manually running payroll every time you need to pay
                someone? Set it to run on autopilot!
              </Text>
            </GridRowColumn>
            <GridRowColumn width={6}>
              <Button onClick={() => setModalOpen(true)} fullWidth>
                <Icon
                  icon={light('circle-arrow-right')}
                  style={{ marginRight: 10 }}
                />
                Set Up Automatic Payroll
              </Button>
            </GridRowColumn>
          </Grid>
        </GridRowColumn>
      )}
      <AutoEnrollModal
        modalOpen={modalOpen}
        onClose={onClose}
        paySchedule={paySchedule}
      />
      <UpcomingPayroll />
    </Grid>
  )
}

const RunPayroll = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const previousPayrollOptions = useReselector(
    selectPreviousUnprocessedPayrollOptions
  )
  const isFetching = useReselector(getIsFetching, FETCH_PAYROLL_KEY)

  const migrated = searchParams.get('migrated') === 'true'

  return (
    <Container>
      <Card>
        <Grid>
          <Grid.Row>
            <Grid.Column width={12}>
              <Text as="h2">Run Payroll</Text>
            </Grid.Column>
            <Grid.Column width={4}>
              <br />
              <Link
                newPage
                href="https://support.joinheard.com/hc/en-us/articles/7583457900567"
              >
                <Button variant="secondary" fullWidth>
                  <Icon icon={light('comment')} style={{ marginRight: 10 }} />
                  <b>I need help with this</b>
                </Button>
              </Link>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          {migrated && (
            <GridRowColumn textAlign="center">
              <Alert>
                Congrats! You’ve successfully migrated your Payroll to Heard.
                You can now run, view, and adjust payroll right on this page.
                <br />
                <Link
                  href="https://www.joinheard.com/articles/how-to-set-up-payroll-for-your-therapy-practice"
                  newPage
                >
                  Learn how Heard Payroll works.
                </Link>
              </Alert>
            </GridRowColumn>
          )}
          <GridRowColumn>
            <PayrollHeader />
          </GridRowColumn>
          <Divider />
          <Grid.Row>
            <Grid.Column width={8}>
              <Text as="h2">Other Payroll Options</Text>
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              <Text>
                Need to cancel an upcoming payroll?{' '}
                <Link to="/payroll/history">View Payroll History</Link>
              </Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column>
              <Card type="section">
                <Icon
                  icon={light('users')}
                  size="3x"
                  color="green"
                  style={{ alignSelf: 'start' }}
                />
                <br />
                <Text as="h2">Pay Contractors</Text>
                <br />
                <Text as="bodyLg">Run or schedule a contractor payment.</Text>
                <br />
                <Button
                  variant="secondary"
                  onClick={() => navigate('/payroll/run_contractor_payroll')}
                >
                  Pay Contractors
                </Button>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card type="section">
                <Icon
                  icon={light('sync')}
                  size="3x"
                  color="green"
                  style={{ alignSelf: 'start' }}
                />
                <br />
                <Text as="h2">Run Off-Cycle Payroll</Text>
                <br />
                <Text as="bodyLg">
                  Run a payroll outside your regular pay schedule.
                </Text>
                <br />
                <Button
                  variant="secondary"
                  onClick={() => navigate('/payroll/create_off_cycle_payroll')}
                >
                  Run Off-Cycle Payroll
                </Button>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card type="section">
                <Icon
                  icon={light('file-invoice-dollar')}
                  size="3x"
                  color="green"
                  style={{ alignSelf: 'start' }}
                />
                <br />
                <Text as="h2">Run Previous Payrolls</Text>
                <br />
                <Text as="bodyLg">
                  Run a previous payroll that hasn’t been processed yet.
                </Text>
                <br />
                {isFetching ? (
                  <Container className="loading-wrapper">
                    <Loader active inline />
                  </Container>
                ) : previousPayrollOptions.length ? (
                  <Dropdown
                    placeholder="Select a pay period"
                    options={previousPayrollOptions}
                    onChange={(payrollUuid) =>
                      navigate(`/payroll/run_payroll/process/${payrollUuid}`)
                    }
                    fullWidth
                  />
                ) : (
                  <Text>All payrolls Processed</Text>
                )}
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </Container>
  )
}

export default RunPayroll
