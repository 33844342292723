import { ReactNode, useMemo } from 'react'
import { Dimmer, List, Loader } from 'semantic-ui-react'
import moment from 'moment'

import {
  adminFetchSubscriptionsByUserId,
  ADMIN_REQUEST_SUBSCRIPTIONS_BY_USER_ID,
} from '../../../actions/adminActions'
import { Card, Link, Text } from '../../../components/BaseComponents'
import { getIsFetching } from '../../../reducers/fetch'
import { getUserById } from '../../../selectors/user.selectors'
import { Colors } from '../../../styles/theme'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useFetchResponse, useReselector } from '../../../utils/sharedHooks'

const isProd = process.env.VITE_IS_PROD === 'true'

const STRIPE_DASHBOARD_BASE_URL = 'https://dashboard.stripe.com'
const getStripeSubscriptionsUrl = (subscriptionId: string) =>
  `${STRIPE_DASHBOARD_BASE_URL}/${
    !isProd ? 'test/' : ''
  }subscriptions/${subscriptionId}`
export const getStripeCustomerURL = (customerId: string) =>
  `${STRIPE_DASHBOARD_BASE_URL}/${
    !isProd ? 'test/' : ''
  }customers/${customerId}`

interface Props {
  userId: number
}

const GreenText = ({ children }: { children?: ReactNode }) => (
  <span style={{ color: Colors.green }}>{children}</span>
)

const AccountDetailsTabPanel = ({ userId }: Props) => {
  const subscriptionsLoading = useReselector(
    getIsFetching,
    ADMIN_REQUEST_SUBSCRIPTIONS_BY_USER_ID
  )

  const { createdAt, stripeCustomer } = useReselector(getUserById, userId) ?? {}

  const subscriptions = useFetchResponse(
    adminFetchSubscriptionsByUserId,
    userId
  )

  const earliestSubscriptionStartDate = useMemo(() => {
    const startDate = (subscriptions || []).sort(
      (sub1, sub2) => sub1.start_date - sub2.start_date
    )[0]?.start_date
    if (startDate) {
      return moment.unix(startDate).format(DATE_FORMATS.DISPLAY_SHORT)
    }

    return ''
  }, [subscriptions])

  return (
    <Card fullWidth>
      <Text as="h1">Stripe Subscription Details</Text>
      {stripeCustomer && (
        <>
          <br />
          <Text as="h3">
            Stripe Customer:{' '}
            <Link href={getStripeCustomerURL(stripeCustomer)} newPage>
              {stripeCustomer}
            </Link>
          </Text>
        </>
      )}
      {subscriptions && (
        <>
          <br />
          <Text as="h3">Stripe Subscriptions:</Text>
          <Dimmer active={subscriptionsLoading} inverted>
            <Loader inverted>Loading subscription details</Loader>
          </Dimmer>
          <List bulleted>
            {subscriptions.map(({ id }) => (
              <List.Item
                key={`StripeSubscription__${id}`}
                // match bullet styling to Link styling to center with baseline
                style={{ fontSize: 16, lineHeight: '155%' }}
              >
                <Link href={getStripeSubscriptionsUrl(id)} newPage>
                  {id}
                </Link>
              </List.Item>
            ))}
          </List>
        </>
      )}
      <br />
      <Text as="h3">
        Subscription Start Date:{' '}
        <GreenText>{earliestSubscriptionStartDate}</GreenText>
      </Text>
      {createdAt && (
        <>
          <br />
          <Text as="h3">
            User Account Created On:
            <GreenText>{` ${
              moment(createdAt).format(DATE_FORMATS.DISPLAY_SHORT) ?? ''
            }`}</GreenText>
          </Text>
        </>
      )}
    </Card>
  )
}

export default AccountDetailsTabPanel
