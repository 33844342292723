import axios from 'axios'
import { normalize } from 'normalizr'

import { userCallEventListSchema } from '../schema'
import {
  receiveUserCallEvents,
  UserCallEvent,
  UserCallEventEntities,
} from '../reducers/finances/userCallEventsReducer'
import { fetchWrapper } from '../reducers/fetch'
import { receiveCalendlyLinks } from '../reducers/calendlyLink.slice'
import { Dispatch } from '../utils/typeHelpers'

const FETCH_USER_CALL_EVENTS_KEY = 'FETCH_USER_CALL_EVENTS_KEY'

export const fetchUserCallEvents = () =>
  fetchWrapper({
    fetchKey: FETCH_USER_CALL_EVENTS_KEY,
    defaultErrorMessage: 'There was an error fetching call events.',
    fetchFunction: (dispatch: Dispatch) =>
      axios
        .get<UserCallEvent[]>('/finances/api/v1/user_call_events')
        .then((json) => {
          const normalizedData = normalize<
            unknown,
            UserCallEventEntities,
            number[]
          >(json.data, userCallEventListSchema)
          dispatch(receiveCalendlyLinks(normalizedData))
          dispatch(receiveUserCallEvents(normalizedData))
          return json.data
        }),
  })
