import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Icon, Divider } from 'semantic-ui-react'

import { selectContractors } from '../../payroll.selectors'
import { getContractorName, getContractorWageText } from '../../helpers'
import ContractorPayrollHeader from './ContractorPayrollHeader'
import LastContractorPayments from './LastContractorPayments'
import {
  Button,
  GridRowColumn,
  Radio,
  Table,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'

const ContPayrollSelectContractorTab = ({
  selectedUuid: initSelectedUuid,
  goForward,
}: {
  selectedUuid: string | undefined
  goForward: (_: string) => void
}) => {
  const navigate = useNavigate()
  const contractors = useReselector(selectContractors)
  const [selectedUuid, setSelectedUuid] = useState(initSelectedUuid)

  return (
    <Grid>
      <GridRowColumn>
        <ContractorPayrollHeader currentTab={0} />
      </GridRowColumn>
      <GridRowColumn width={6} short>
        <LastContractorPayments />
      </GridRowColumn>
      <Divider />
      <GridRowColumn>
        <Text as="h3">Select a contractor to pay</Text>
      </GridRowColumn>
      <GridRowColumn short>
        <Text>
          When paying contractors, you will be asked to enter the number of
          hours worked.
        </Text>
      </GridRowColumn>
      <GridRowColumn>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1} />
              <Table.HeaderCell>
                <Text color="green">Contractor</Text>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.values(contractors).map((contractor) => (
              <Table.Row
                key={contractor.uuid}
                onClick={() =>
                  selectedUuid === contractor.uuid
                    ? setSelectedUuid(undefined)
                    : setSelectedUuid(contractor.uuid)
                }
              >
                <Table.Cell width={1}>
                  <Radio checked={selectedUuid === contractor.uuid} />
                </Table.Cell>
                <Table.Cell>
                  <Text style={{ marginBottom: 3 }}>
                    <b>{getContractorName(contractor)}</b>
                  </Text>
                  <Text>{getContractorWageText(contractor)}</Text>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </GridRowColumn>
      <Grid.Row>
        <Grid.Column width={4}>
          <Button
            variant="secondary"
            onClick={() => navigate('/payroll/run_payroll')}
            fullWidth
          >
            Cancel
          </Button>
        </Grid.Column>
        <Grid.Column width={8} />
        <Grid.Column width={4}>
          <Button
            onClick={() => selectedUuid && goForward(selectedUuid)}
            fullWidth
            disabled={!selectedUuid}
          >
            Continue <Icon name="arrow right" />
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default ContPayrollSelectContractorTab
