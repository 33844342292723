import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Container, Grid, Image } from 'semantic-ui-react'

import {
  Button,
  Card,
  GridRowColumn,
  Link,
  Text,
} from '../../../components/BaseComponents'
import { filterNulls, useAppDispatch } from '../../../utils/typeHelpers'
import { useSignupPageUpdate } from '../../../services/onboardingService'
import { SIGNUP_PATHS } from '../helpers'
import { useAnalyticsView } from '../../Amplitude'
import {
  ReferralStatus,
  updateUserReferralStatusIfExists,
} from '../../Referrals/referrals.slice'
import { useReselector } from '../../../utils/sharedHooks'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { updateUser } from '../../../actions/userActions'
import SignupHeader from '../SignupHeader'

const DisqualifiedSignup = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const currentUser = useReselector(getCurrentUser)
  const { email } = currentUser || {}
  useSignupPageUpdate(SIGNUP_PATHS.disqualified)
  const pageView = useAnalyticsView()

  useEffect(() => {
    pageView('sign up disqualifed')
  }, [pageView])

  useEffect(() => {
    updateUser(currentUser?.id, {
      disqualifiedAt: new Date().toISOString(),
    })(dispatch)
    if (email) {
      updateUserReferralStatusIfExists({
        recipientEmail: email,
        status: ReferralStatus.ineligible,
      })(dispatch)
    }
  }, [email, dispatch, currentUser?.id])

  const isScorpDisqualified = searchParams.get('ccorp') === 'true'

  const disqReasons = [
    searchParams.get('hasInternationalTaxes') === 'true'
      ? 'International taxes'
      : null,
    searchParams.get('multipleOwners') === 'true'
      ? 'Multiple practice owners'
      : null,
    isScorpDisqualified ? 'C Corporation taxes' : null,
    searchParams.get('sharedAccounts') === 'true'
      ? 'Combined personal and business banking'
      : null,
    searchParams.get('otherSelection') === 'true'
      ? 'We need to understand your business and tax entities before working with you'
      : null,
  ]

  return (
    <Container style={{ paddingTop: 64 }}>
      <Grid>
        <SignupHeader hideProgress />
        <GridRowColumn spacer={1} width={14}>
          <Card fullWidth backgroundColor="stone40">
            <Grid>
              <GridRowColumn centerContent>
                <Image
                  src="https://heard-images.s3.amazonaws.com/assets/seedling.svg"
                  style={{ height: 170, width: 120 }}
                />
              </GridRowColumn>
              <GridRowColumn>
                <Text as="display" textAlign="center">
                  We’re not ready for you yet...
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text as="h3" textAlign="center">
                  Based on your answers, we aren’t a good match for you at the
                  moment, and won’t be able to support what your practice needs
                  for:
                </Text>
              </GridRowColumn>
              <GridRowColumn spacer={4} width={8}>
                <ul>
                  {filterNulls(disqReasons).map((reason) => (
                    <li key={reason}>
                      <Text>{reason}</Text>
                    </li>
                  ))}
                </ul>
              </GridRowColumn>
              {isScorpDisqualified && (
                <GridRowColumn spacer={4} width={8}>
                  <Card type="subsection" backgroundColor="blush">
                    <Text as="bodySm">
                      We are currently only able to support C Corporation
                      (including Professional Corporation) business entities
                      that are taxed as S Corporations for the entire tax year.
                      We hope to support C Corporation tax entities
                      transitioning to S Corporation tax entities in the near
                      future. Please keep an eye out for future offerings from
                      Heard!
                    </Text>
                  </Card>
                </GridRowColumn>
              )}
              <GridRowColumn>
                <Text as="bodyLg" textAlign="center">
                  If you feel like you were incorrectly disqualified, you can
                  reach out to hello@joinheard.com
                </Text>
              </GridRowColumn>
            </Grid>
          </Card>
        </GridRowColumn>
        <GridRowColumn>
          <Text as="display2" textAlign="center">
            ...but, let’s keep in touch!
          </Text>
        </GridRowColumn>
        <GridRowColumn centerContent>
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/laptop-list.svg"
            style={{ width: 302, height: 185 }}
          />
        </GridRowColumn>
        <GridRowColumn spacer={2} width={12}>
          <Text textAlign="center">
            If your circumstances change in the future, please reach out again.
            In the meantime, we’d love you to subscribe to our newsletter to
            keep up to date on our offerings, and get valuable tips for how to
            run your practice.
          </Text>
        </GridRowColumn>
        <Grid.Row />
        <GridRowColumn spacer={6} width={4}>
          <Link href="https://www.joinheard.com/newsletter">
            <Button fullWidth>Subscribe</Button>
          </Link>
        </GridRowColumn>
        <GridRowColumn spacer={6} width={4}>
          <Link href="https://www.joinheard.com">
            <Button variant="secondary" fullWidth id="btn-signup-disqualified">
              Return to Site
            </Button>
          </Link>
        </GridRowColumn>
      </Grid>
    </Container>
  )
}

export default DisqualifiedSignup
