import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Gusto_PaySchedule } from './generated_gusto_types'

const initialState: Gusto_PaySchedule | null = null

const PayScheduleSlice = createSlice({
  name: 'payrollSchedule',
  initialState: initialState as Gusto_PaySchedule | null,
  reducers: {
    setPaySchedule: (state, action: PayloadAction<Gusto_PaySchedule>) =>
      action.payload,
  },
})

export const { setPaySchedule } = PayScheduleSlice.actions

export default PayScheduleSlice.reducer
