import { Grid } from 'semantic-ui-react'
import { DateTime } from 'luxon'

import { Button, Card, GridRowColumn, Modal, Text } from '../BaseComponents'
import CurrencyFormatLabel from '../shared/CurrencyFormatLabel'
import { Transaction } from '../../reducers/admin/allTransactions.slice'
import { centsToDollars } from '../../utils/currencyHelpers'
import { DATE_FORMATS_LUXON } from '../../utils/dateHelpers'

export const DuplicateTransactionsModal = ({
  open,
  close,
  saveAnyway,
  transactions,
}: {
  open: boolean
  saveAnyway: () => void
  close: () => void
  transactions?: Transaction[]
}) => {
  const duplicateTransaction =
    transactions && transactions?.length > 0 ? transactions[0] : null
  return (
    <Modal size="tiny" open={open} onClose={close}>
      <Modal.Header>Duplicate Transaction</Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text>
              You already have a transaction with the same category, date and
              amount in Heard. Are you sure you want to add this transaction?
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Card backgroundColor="stone">
              <Grid>
                <GridRowColumn>
                  <Text as="eyebrow">Existing Transaction</Text>
                </GridRowColumn>
                <GridRowColumn>
                  <Text>
                    <b>Date: </b>
                    {duplicateTransaction?.date &&
                      DateTime.fromISO(duplicateTransaction?.date).toFormat(
                        DATE_FORMATS_LUXON.DISPLAY_SHORT
                      )}
                  </Text>
                </GridRowColumn>
                <GridRowColumn>
                  <Text>
                    <b>Description: </b>
                    {duplicateTransaction?.description}
                  </Text>
                </GridRowColumn>
                <GridRowColumn>
                  <Text>
                    <b>Amount: </b>
                    <CurrencyFormatLabel
                      value={centsToDollars(
                        duplicateTransaction?.amountInCents
                      )}
                    />
                  </Text>
                </GridRowColumn>
              </Grid>
            </Card>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="actionLink" onClick={close}>
          Cancel
        </Button>
        <Button onClick={saveAnyway}>Add Anyway</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default DuplicateTransactionsModal
