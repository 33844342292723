import { useNavigate } from 'react-router-dom'
import { Grid, Icon } from 'semantic-ui-react'
import moment from 'moment'

import { selectPayrollByUuid } from '../../payroll.selectors'
import PaymentOverview from './PayrollOverview'
import PayrollDetails from './PayrollDetails'
import PayrollHeader from './PayrollHeader'
import { formatCurrency } from '../../../../utils/currencyHelpers'
import { Colors } from '../../../../styles/theme'
import { Button, Text } from '../../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import { Gusto_BankAccount } from '../../generated_gusto_types'

const PayrollConfirmationTab = ({
  uuid,
  bankAccount,
}: {
  uuid: string
  bankAccount?: Gusto_BankAccount
}) => {
  const navigate = useNavigate()
  const payroll = useReselector(selectPayrollByUuid, uuid)

  if (!payroll) {
    return null
  }

  const {
    pay_period: { start_date, end_date },
    totals,
    payroll_deadline,
  } = payroll

  const companyDebit = totals?.company_debit || '0.00'

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <PayrollHeader upcomingPayroll={payroll} currentTab={2} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} />
        <Grid.Column width={6}>
          <Text as="h1">
            <b>
              <Icon name="check circle" style={{ color: Colors.green }} />
              You&apos;re all set!
            </b>
          </Text>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Text as="bodyMd">
            Time to take a back seat. We will withdraw{' '}
            <span className="green">{formatCurrency(companyDebit)}</span> from
            your Bank Account (
            <span className="green">
              {bankAccount?.account_type}, {bankAccount?.hidden_account_number}
            </span>
            ) on{' '}
            <span className="green">
              {moment(payroll_deadline).format(
                DATE_FORMATS.DAY_OF_WEEK_DAY_MONTH
              )}
            </span>
            . Please ensure these funds are available in your attached business
            bank account.
          </Text>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Text as="bodyMd">
            Payroll Run for{' '}
            {moment(start_date).format(DATE_FORMATS.DISPLAY_SHORT)}-
            {moment(end_date).format(DATE_FORMATS.DISPLAY_SHORT)}
          </Text>
        </Grid.Column>
      </Grid.Row>
      <PaymentOverview payroll={payroll} />
      <Grid.Row />
      <PayrollDetails uuid={uuid} />
      <Grid.Row>
        <Grid.Column width={6} />
        <Grid.Column width={4}>
          <Button onClick={() => navigate('/payroll/run_payroll')} fullWidth>
            <Icon name="arrow left" />
            Back to dashboard
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default PayrollConfirmationTab
