import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useParams, useSearchParams } from 'react-router-dom'
import { Container, Divider, Grid, Dimmer, Loader } from 'semantic-ui-react'
import { adminFetchSingleUserIfNeeded } from '../../../actions/admin/adminAllUsersActions'
import { fetchUserFinancialAccounts } from '../../../actions/admin/adminFinancialAccountActions'
import {
  Button,
  Card,
  DatePicker,
  Text,
  GridRowColumn,
  Dropdown,
} from '../../../components/BaseComponents'
import Breadcrumb, {
  BreadcrumbSection,
} from '../../../components/BaseComponents/Breadcrumb'
import { getUserFinancialAccounts } from '../../../selectors/financeSelectors'
import { getUserById } from '../../../selectors/user.selectors'
import { useReselector, useToggle } from '../../../utils/sharedHooks'
import { filterObjNils, useAppDispatch } from '../../../utils/typeHelpers'
import GeneralLedger from '../../JournalEntries/components/GeneralLedger'
import {
  parseJournalEntryDataToCsv,
  getInitialDateFilters,
} from '../../JournalEntries/service'
import {
  fetchGeneralLedger,
  fetchJournalEntries,
  FETCH_GENERAL_LEDGER_KEY,
} from '../../../actions/admin/journalEntryActions'
import { LedgerAccount } from '../../JournalEntries/types'
import { getAllTransactionCategories } from '../reports.selectors'
import { fetchTransactionCategoriesIfNeeded } from '../reports.slice'
import {
  ACCOUNT_TYPE_OPTIONS,
  FINANCIAL_STATEMENT_OPTIONS,
  GL_SORT_OPTIONS,
} from '../reportConstants'
import { selectIsFetchingForKeys } from '../../../reducers/fetch'
import { EditJournalEntryModal } from '../../JournalEntries/components/EditJournalEntryModal'

const AdminGeneralLedgerPanel = () => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)
  const [isLoading, setIsLoading] = useState(false)
  const [generalLedgerItems, setGeneralLedgerItems] = useState<LedgerAccount[]>(
    []
  )
  const [dateRange, setDateRange] = useState<[string, string]>(
    getInitialDateFilters(searchParams?.get('endDate'))
  )
  const [accountFilter, setAccountFilter] = useState<string | undefined>()
  const [statementFilter, setStatementFilter] = useState<string | undefined>()
  const [sortBy, setSortBy] = useState('A-Z')

  const [csvData, setCsvData] = useState('')

  const [isCreating, toggleCreate] = useToggle()

  const { userId: userIdParam } = useParams<{ userId: string }>()
  const userId = Number(userIdParam)

  const user = useReselector(getUserById, userId)
  const transactionCategoriesById = useReselector(getAllTransactionCategories)
  const financialAccountsObj = useReselector(getUserFinancialAccounts, userId)

  const queryLoading = useReselector(selectIsFetchingForKeys, [
    FETCH_GENERAL_LEDGER_KEY,
  ])

  const financialAccountsById = useMemo(
    () => filterObjNils(financialAccountsObj),
    [financialAccountsObj]
  )
  const usersLink = '/admin/finances/list-users'

  const breadcrumbs = useMemo<BreadcrumbSection[]>(
    () => [
      { label: 'Dashboard', to: '/' },
      { label: 'Users', to: usersLink },
      {
        label: `${user?.firstName} ${user?.lastName}`,
        to: `/admin/finances/records/${userId}`,
      },
      { label: 'General Ledger' },
    ],
    [user?.firstName, user?.lastName, userId, usersLink]
  )

  useEffect(() => {
    const fetch = async () => {
      const res = await fetchGeneralLedger({
        userId,
        startDate: dateRange[0],
        endDate: dateRange[1],
      })(dispatch)
      setGeneralLedgerItems(res)
    }
    if (dateRange?.[0] && dateRange?.[1] && isCreating === false) {
      fetch()
    } else {
      setGeneralLedgerItems([])
    }
  }, [dispatch, dateRange, userId, isCreating])

  useEffect(() => {
    dispatch(adminFetchSingleUserIfNeeded(userId))
    dispatch(fetchUserFinancialAccounts(userId))
    dispatch(fetchTransactionCategoriesIfNeeded(true))
  }, [dispatch, userId])

  /**
   * This useEffect clears the csvData state variable in order to "unmount" the CSVDownload
   * component below. When the CSVDownload component is rendered in the DOM, it immediately
   * triggers a download of a CSV file with the given data. However, if the user wants to download
   * the CSV a second time, a download would not trigger if the CSVDownload component stayed
   * rendered, so setting the data to an empty string will unmount the CSVDownload component until
   * the next time the user presses "Download CSV", which will then populate the csvData state
   * variable, mount the CSVDownload component again, and  start the download of the data again.
   */
  useEffect(() => {
    if (csvData) {
      setCsvData('')
    }
  }, [csvData])
  const handleDateChange = (dateRange: [string, string]) => {
    setDateRange(dateRange)
  }
  const handleDownloadCsv = async () => {
    setIsLoading(true)

    const { transactions } =
      (await fetchJournalEntries({
        userId,
        startDate: dateRange[0],
        endDate: dateRange[1],
      })(dispatch)) ?? []

    if (transactions.length) {
      setCsvData(
        parseJournalEntryDataToCsv({
          journalEntryTransactions: transactions,
          financialAccounts: financialAccountsById,
          transactionCategories: transactionCategoriesById,
          sortFunction: (
            { accountName: accountName1, transaction: { date: date1 } },
            { accountName: accountName2, transaction: { date: date2 } }
          ) => {
            if (accountName1 !== accountName2) {
              return accountName1.localeCompare(accountName2)
            }
            return moment(date2).diff(date1)
          },
        })
      )
    }
    setIsLoading(false)
  }
  // Workaround for async https://github.com/react-csv/react-csv/issues/189
  useEffect(() => {
    if (csvData !== '' && csvLink?.current?.link && !isLoading) {
      csvLink?.current?.link.click()
    }
  }, [csvData, isLoading])

  const dateValue = useMemo(() => {
    if (!dateRange[0] && !dateRange[1]) return ''
    return `${dateRange[0]} - ${dateRange[1]}`
  }, [dateRange])

  return (
    <Container>
      <Breadcrumb crumbs={breadcrumbs} />
      <Divider />
      <Card>
        <Grid>
          <Grid.Row stretched>
            <Grid.Column width={8} floated="left">
              <Text as="h1">General Ledger</Text>
              <Text>
                {user?.financialProfile?.businessName ??
                  `${user?.firstName} ${user?.lastName}`}
              </Text>
            </Grid.Column>
            <Grid.Column width={4}>
              <Button variant="secondaryWhite" onClick={toggleCreate}>
                Create Journal Entry
              </Button>
              {isCreating && (
                <EditJournalEntryModal
                  open={isCreating}
                  onClose={toggleCreate}
                />
              )}
            </Grid.Column>
            <Grid.Column width={4} floated="right">
              <Button
                loading={isLoading}
                disabled={!generalLedgerItems.length}
                onClick={handleDownloadCsv}
              >
                Download CSV
              </Button>
              {csvData && (
                <CSVLink
                  data={csvData}
                  filename="transactions.csv"
                  className="hidden"
                  ref={csvLink}
                  target="_blank"
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <GridRowColumn>
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                flexWrap: 'wrap',
                paddingLeft: 10,
                gap: 8,
              }}
            >
              <DatePicker
                clearable
                onChange={handleDateChange}
                endDate={dateRange[1]}
                selectsRange
                startDate={dateRange[0]}
                value={dateValue}
                placeholder="Filter Date"
              />
              <Dropdown
                placeholder="Filter account type"
                value={accountFilter}
                options={ACCOUNT_TYPE_OPTIONS}
                onChange={(value) => setAccountFilter(value)}
                clearable
              />
              <Dropdown
                placeholder="Filter financial statement"
                value={statementFilter}
                options={FINANCIAL_STATEMENT_OPTIONS}
                onChange={(value) => setStatementFilter(value)}
                clearable
              />
              <Dropdown
                text={`Sort by: ${sortBy}`}
                value={sortBy}
                options={GL_SORT_OPTIONS}
                onChange={(value) => setSortBy(value)}
                variant="text"
                style={{ position: 'absolute', right: 50 }}
              />
            </div>
          </GridRowColumn>
          <GridRowColumn />
        </Grid>
        {queryLoading ? (
          <Dimmer active inverted>
            <Loader inverted>Loading general ledger data</Loader>
          </Dimmer>
        ) : (
          <GeneralLedger
            generalLedgerItems={generalLedgerItems}
            transactionCategoriesById={transactionCategoriesById ?? {}}
            financialAccountsById={financialAccountsById ?? {}}
            accountFilter={accountFilter}
            statementFilter={statementFilter}
            sortBy={sortBy}
          />
        )}
      </Card>
    </Container>
  )
}

export default AdminGeneralLedgerPanel
