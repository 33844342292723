import { useMemo } from 'react'
import { Grid, Image } from 'semantic-ui-react'

import { GridRowColumn, Text } from '../../../../../components/BaseComponents'
import { Form1040DetailsProps } from '../../TaxChecklist/Form1040/Details'
import { TAX_ENTITY_TYPES } from '../../../taxConstants'
import { ReviewSubSectionForFormIds } from '../../TaxChecklist/Shared/ReviewSubSection'
import { useReselector } from '../../../../../utils/sharedHooks'
import { getBusinessQuestionIds } from '../../TaxChecklist/Shared/PersonalDetails/CheckYourDetailsPanel'
import { select1120sFormForYear } from '../../annualTaxFilingForms.selector'
import { EXTENSION_REQUEST_SCREENS } from '../Combined'
import { getListOfDocumentQuestionsAndResponses } from '../helpers'
import {
  selectSortedTaxReturnDocumentsForYear,
  selectUserTaxDocumentsForCategory,
} from '../../../../UserDocuments/userDocuments.selector'
import { UserDocumentCategoryIdentifier } from '../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { TaxListQuestionId } from '../../TaxChecklist/service'
import { makeGridConfig } from '../../../../../components/BaseComponents/Grid'

const estimatedTaxQuestionIds = [
  TaxListQuestionId.household_income_in_cents,
  TaxListQuestionId.household_income_withholdings_in_cents,
  TaxListQuestionId.business_income_in_cents,
  TaxListQuestionId.business_calendar_type,
  TaxListQuestionId.previous_year_business_tax_return,
  TaxListQuestionId.multi_state_business_states,
]

const BusinessReviewReadOnly = ({
  taxYear,
  isReadOnly,
}: Form1040DetailsProps) => {
  const filingForm1120s = useReselector(select1120sFormForYear, taxYear)
  const formId1120s = String(filingForm1120s?.id)

  const govIds = useReselector(
    selectUserTaxDocumentsForCategory,
    UserDocumentCategoryIdentifier.officialGovernmentId,
    taxYear
  )

  const spouseGovIds = useReselector(
    selectUserTaxDocumentsForCategory,
    UserDocumentCategoryIdentifier.spouseOfficialGovernmentId,
    taxYear
  )

  const prevYearBizReturns = useReselector(
    selectSortedTaxReturnDocumentsForYear,
    (Number(taxYear) - 1).toString()
  ).business

  const documentQuestionsAndResponses = useMemo(
    () =>
      getListOfDocumentQuestionsAndResponses({
        taxYear,
        govIds,
        spouseGovIds,
        prevYearBizReturns,
      }),
    [govIds, prevYearBizReturns, spouseGovIds, taxYear]
  )

  return (
    <Grid>
      <GridRowColumn
        columnStyle={{ display: 'flex', justifyContent: 'center' }}
      >
        <Image src="https://heard-images.s3.amazonaws.com/assets/magnifying-glass-checkmark.svg" />
      </GridRowColumn>
      <GridRowColumn>
        <Text as="display2" textAlign="center">
          Review
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([8, 14], true)}>
        <Text as="bodyLg" textAlign="center">
          You can no longer edit your Extension Request responses, but you can
          view them anytime.
        </Text>
      </GridRowColumn>
      <Grid.Row />
      <ReviewSubSectionForFormIds
        title="business details"
        questionIds={getBusinessQuestionIds(TAX_ENTITY_TYPES.form_1120_s)}
        editScreen={EXTENSION_REQUEST_SCREENS.confirmDetails}
        formIds={formId1120s}
        readOnly={isReadOnly}
      />
      <ReviewSubSectionForFormIds
        title="Business Income"
        questionIds={estimatedTaxQuestionIds}
        additionalInfo={documentQuestionsAndResponses}
        editScreen={EXTENSION_REQUEST_SCREENS.estimateTax}
        formIds={formId1120s}
        readOnly={isReadOnly}
      />
    </Grid>
  )
}

export default BusinessReviewReadOnly
