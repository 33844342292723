import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  getContractorsSortedByCreatedAt,
  getAboundTaxDocsForPayer,
} from '../aboundAnnualTaxFilings.selector'
import {
  AboundContractor,
  AboundTaxDocument,
  fetchAboundContractors,
  fetchAboundPayer,
  fetchAboundTaxDocumentsForPayer,
} from '../aboundAnnualTaxFilings.slice'
import {
  ABOUND_ERROR_TYPES,
  ABOUND_STATUS,
  TIN_VERIFICATION_STATUS,
} from './constants'
import {
  Button,
  Card,
  GridRowColumn,
  Icon,
  Label,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { DateTime } from 'luxon'
import { NEC_CUTOFF } from '../annualTaxFilings.selector'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const Statuses = ({
  ten99,
  tinStatus,
}: {
  ten99?: AboundTaxDocument
  tinStatus: string
}) => {
  if (!ten99) {
    return <Label color="red">No 1099-NEC Found</Label>
  }
  if (tinStatus === TIN_VERIFICATION_STATUS.mismatch) {
    return (
      <Label color="red">
        <Icon icon={light('circle-exclamation')} style={{ marginRight: 8 }} />
        Name/SSN Mismatch
      </Label>
    )
  }
  switch (ten99.aboundStatus) {
    case ABOUND_STATUS.error:
      return (
        <Label color="red">
          <Icon icon={light('circle-exclamation')} style={{ marginRight: 8 }} />
          Error
        </Label>
      )
    case ABOUND_STATUS.done:
      return (
        <Label color="green">
          <Icon icon={light('check')} style={{ marginRight: 8 }} />
          Filed
        </Label>
      )
    default:
      return <Label color="dark">Processing</Label>
  }
}
const AdditionalInfo = ({
  ten99,
  tinStatus,
  uuid,
}: {
  ten99?: AboundTaxDocument
  tinStatus: string
  uuid: string
}) => {
  const navigate = useNavigate()

  // Abound is turning off their v3 API on 4/1, so we should re-eval if this date should change next tax year
  if (
    tinStatus === TIN_VERIFICATION_STATUS.mismatch &&
    DateTime.now() < NEC_CUTOFF
  ) {
    return (
      <Button
        onClick={() =>
          navigate(`/taxes/annual/file_1099_nec/contractors/${uuid}`)
        }
        variant="secondary"
        style={{ float: 'right' }}
      >
        Fix Contractor Info
      </Button>
    )
  }
  if (ten99?.aboundStatus === ABOUND_STATUS.done) {
    return (
      <Button
        variant="secondary"
        style={{ float: 'right' }}
        onClick={() => navigate('/practice/documents')}
      >
        Documents
      </Button>
    )
  } else if (ten99?.aboundStatus === ABOUND_STATUS.error) {
    switch (ten99?.aboundErrorType) {
      case ABOUND_ERROR_TYPES.ssnVerificationError:
      case ABOUND_ERROR_TYPES.userError:
        return (
          <Text as="bodySm" color="red">
            We couldn&apos;t validate this contractor&apos;s information.
          </Text>
        )
      case ABOUND_ERROR_TYPES.payerError:
        return (
          <Text as="bodySm" color="red">
            We couldn&apos;t validate your business&apos;s information.
          </Text>
        )
      default:
        return (
          <Text as="bodySm" color="red">
            Something went wrong. Reach out to our team on message center.
          </Text>
        )
    }
  }
  return null
}

const Contractor = ({
  contractor,
  ten99,
}: {
  contractor: AboundContractor
  ten99?: AboundTaxDocument
}) => (
  <Card type="subsection" backgroundColor="white">
    <Grid>
      <Grid.Row>
        <Grid.Column width={4} verticalAlign="middle">
          <Text as="h3">
            {contractor.firstName} {contractor.lastName}
          </Text>
        </Grid.Column>
        <Grid.Column width={4} verticalAlign="middle">
          <Statuses
            ten99={ten99}
            tinStatus={contractor.tinVerificationStatus}
          />
        </Grid.Column>
        <Grid.Column verticalAlign="middle" width={8}>
          <AdditionalInfo
            ten99={ten99}
            tinStatus={contractor.tinVerificationStatus}
            uuid={contractor.uuid}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Card>
)

export const ContractorList = () => {
  const dispatch = useAppDispatch()

  const contractors = useReselector(getContractorsSortedByCreatedAt)
  const ten99s = useReselector(getAboundTaxDocsForPayer)

  const listContractors = useMemo(
    () =>
      contractors.map((contractor) => (
        <GridRowColumn key={`${contractor.id}`}>
          <Contractor contractor={contractor} ten99={ten99s?.[contractor.id]} />
        </GridRowColumn>
      )),
    [contractors, ten99s]
  )

  useEffect(() => {
    dispatch(fetchAboundPayer())
    dispatch(fetchAboundContractors())
    dispatch(fetchAboundTaxDocumentsForPayer())
  }, [dispatch])

  return <Grid>{listContractors}</Grid>
}
