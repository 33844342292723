import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Image } from 'semantic-ui-react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import { TaxListQuestionId } from '../../service'
import {
  DEDUCTIONS_AND_CREDITS_SCREENS,
  DeductionsAndCreditsStepProps,
} from '.'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  selectTaxListQuestionResponsesByFormId,
  selectTaxListQuestionResponsesByQuestionIds,
} from '../../taxChecklist.selectors'
import { NoneApply, useTiles } from '../../Shared/UseTiles'
import { fetchAnnualTaxFilingFormsIfNeeded } from '../../../annualTaxFilingForms.slice'
import { fetchUserEoyReviewProgress } from '../../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { selectAnnualTaxFilingFormById } from '../../../annualTaxFilingForms.selector'
import {
  GridRowColumn,
  Text,
  TileSelector,
} from '../../../../../../components/BaseComponents'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import DocumentAddedAlert from '../../Shared/SpecialtyAlerts'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import {
  DEDUCTIONS_AND_CREDITS,
  SubStepIdentifiers,
} from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { fetchAllEoyReviewStepsIfNeeded } from '../../Shared/ReviewStepsandProgresses/allEoyReviewSteps.slice'
import { logSentryError } from '../../../../../../utils/sentryHelpers'
import { ownedHomeQuestionIds } from './OwnedHomePanel'
import {
  DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  deleteTaxQuestionnaireResponses,
} from '../../taxChecklistQuestion.actions'
import { businessMileageQuestionIds } from './HadBusinessMileagePanel'
import { charitableContributionsQuestionIds } from './CharitableContributionsPanel'
import { educationQuestionIds } from './EducationPanel'
import { dependentCareQuestionIds } from './DependentCareExpensesPanel'
import { homeOfficeQuestionIds } from './HomeOfficePanel'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import useProgressSteps from '../../Shared/ReviewStepsandProgresses/useProgressSteps'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'

export const deductionsAndCreditsQuestionIds = [
  TaxListQuestionId.owned_home,
  TaxListQuestionId.worked_from_home,
  TaxListQuestionId.had_business_mileage,
  TaxListQuestionId.owned_farm,
  TaxListQuestionId.had_college_students,
  TaxListQuestionId.paid_dependent_expenses,
  TaxListQuestionId.charitable_contribution,
]

const DeductionsAndCreditsPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
}: DeductionsAndCreditsStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()
  const form = useReselector(selectAnnualTaxFilingFormById, formId)
  const dispatch = useAppDispatch()
  const { updateProgressData, fetchKeys: progressFetchKeys } = useProgressSteps(
    { steps: DEDUCTIONS_AND_CREDITS }
  )

  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
    ...progressFetchKeys,
  ])

  const ownedHomeResponse = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.owned_home,
    Number(formId)
  )

  const ownedHomeFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    ownedHomeQuestionIds.filter(
      (qId) => qId !== TaxListQuestionId.paid_mortgage_interest
    ), // owned home is always required by TF so don't delete
    Number(formId)
  )

  const workedFromHomeResponse = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.worked_from_home,
    Number(formId)
  )

  const workedFromHomeFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    homeOfficeQuestionIds,
    Number(formId)
  )

  const hadBusinessMileageResponse = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.had_business_mileage,
    Number(formId)
  )

  const businessMileageFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    businessMileageQuestionIds,
    Number(formId)
  )

  const ownedFarmResponse = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.owned_farm,
    Number(formId)
  )

  const hadCollegeStudentsResponse = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.had_college_students,
    Number(formId)
  )

  const hadCollegeStudentsFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    educationQuestionIds,
    Number(formId)
  )

  const paidDependentExpensesResponse = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.paid_dependent_expenses,
    Number(formId)
  )

  const paidDependentExpensesFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    dependentCareQuestionIds,
    Number(formId)
  )

  const charitableContributionsResponse = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.charitable_contribution,
    Number(formId)
  )

  const charitableContributionsFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    charitableContributionsQuestionIds,
    Number(formId)
  )

  const responsePaidMortgageInterest = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.paid_mortgage_interest,
    Number(formId)
  )
  const responseNumberOfFarms = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_of_farms,
    Number(formId)
  )

  const {
    tiles: selections,
    setTiles,
    noneApply,
    tileColumnStyle,
    tileClicked,
    tileHeight,
    tileWidth,
    tilesToResponses,
    anyResponseSelected,
  } = useTiles()

  useEffect(() => {
    setTiles([
      {
        icon: light('house'),
        text: 'Owned a home',
        questionId: TaxListQuestionId.owned_home,
        response: ownedHomeResponse?.[0],
      },
      {
        icon: light('chair-office'),
        text: 'Worked from home',
        questionId: TaxListQuestionId.worked_from_home,
        response: workedFromHomeResponse?.[0],
      },
      {
        icon: light('car'),
        text: 'Used a car for business',
        questionId: TaxListQuestionId.had_business_mileage,
        response: hadBusinessMileageResponse?.[0],
      },
      {
        icon: light('tractor'),
        text: 'Owned a farm',
        questionId: TaxListQuestionId.owned_farm,
        response: ownedFarmResponse?.[0],
      },
      {
        icon: light('graduation-cap'),
        text: 'College student in family',
        questionId: TaxListQuestionId.had_college_students,
        response: hadCollegeStudentsResponse?.[0],
      },
      {
        icon: light('baby'),
        text: 'Paid dependent care expenses',
        questionId: TaxListQuestionId.paid_dependent_expenses,
        response: paidDependentExpensesResponse?.[0],
      },
      {
        icon: light('gift'),
        text: 'Contributed to charity',
        questionId: TaxListQuestionId.charitable_contribution,
        response: charitableContributionsResponse?.[0],
      },
    ])
  }, [
    ownedHomeResponse,
    workedFromHomeResponse,
    hadBusinessMileageResponse,
    ownedFarmResponse,
    hadCollegeStudentsResponse,
    paidDependentExpensesResponse,
    charitableContributionsResponse,
    setTiles,
  ])

  const saveAndContinue = useAsyncCallback(async () => {
    try {
      const responseData: Partial<TaxChecklistResponse>[] = []
      const didOwnHome =
        selections.find((s) => s.questionId === TaxListQuestionId.owned_home)
          ?.response?.value === true
      const ownedHomeFollowupIds = ownedHomeFollowUpQuestionResponses.map(
        (r) => r.id
      )

      const didWorkFromHome =
        selections.find(
          (s) => s.questionId === TaxListQuestionId.worked_from_home
        )?.response?.value === true
      const workedFromHomeFollowupIds =
        workedFromHomeFollowUpQuestionResponses.map((r) => r.id)

      const didHaveBusinessMileage =
        selections.find(
          (s) => s.questionId === TaxListQuestionId.had_business_mileage
        )?.response?.value === true
      const hadBusinessMileageFollowupIds =
        businessMileageFollowUpQuestionResponses.map((r) => r.id)

      const didOwnFarm =
        selections.find((s) => s.questionId === TaxListQuestionId.owned_farm)
          ?.response?.value === true

      const didHaveCollegeStudents =
        selections.find(
          (s) => s.questionId === TaxListQuestionId.had_college_students
        )?.response?.value === true
      const hadCollegeStudentsFollowupIds =
        hadCollegeStudentsFollowUpQuestionResponses.map((r) => r.id)

      const didPayDependentCareExpenses =
        selections.find(
          (s) => s.questionId === TaxListQuestionId.paid_dependent_expenses
        )?.response?.value === true
      const didPayDependentCareExpensesFollowupIds =
        paidDependentExpensesFollowUpQuestionResponses.map((r) => r.id)

      const didHaveCharitableContribution =
        selections.find(
          (s) => s.questionId === TaxListQuestionId.charitable_contribution
        )?.response?.value === true
      const charitableContributionsFollowupIds =
        charitableContributionsFollowUpQuestionResponses.map((r) => r.id)

      // if screen conditions change, make sure to also update in DeductionsAndCredits/index
      let nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.review

      if (didOwnHome) {
        nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.ownedHome
      } else if (didWorkFromHome) {
        nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.homeOffice
      } else if (didHaveBusinessMileage) {
        nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.businessMileage
      } else if (didOwnFarm) {
        nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.farm
      } else if (didHaveCollegeStudents) {
        nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.education
      } else if (didPayDependentCareExpenses) {
        nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.dependentCare
      } else if (didHaveCharitableContribution) {
        nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.charitableContributions
      }

      const idsToDelete: number[] = []
      if (!didOwnHome && responsePaidMortgageInterest?.[0]?.value !== false) {
        responseData.push({
          id: responsePaidMortgageInterest?.[0]?.id,
          value: false,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.paid_mortgage_interest,
        })
      }
      if (!didWorkFromHome && workedFromHomeFollowupIds.length) {
        idsToDelete.push(...workedFromHomeFollowupIds)
      }
      if (!didHaveBusinessMileage && hadBusinessMileageFollowupIds.length) {
        idsToDelete.push(...hadBusinessMileageFollowupIds)
      }
      if (!didOwnFarm && responseNumberOfFarms?.[0]?.value !== 0) {
        responseData.push({
          id: responseNumberOfFarms?.[0]?.id,
          value: 0,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.number_of_farms,
        })
      }
      if (!didHaveCollegeStudents && hadCollegeStudentsFollowupIds.length) {
        idsToDelete.push(...hadCollegeStudentsFollowupIds)
      }
      if (
        !didPayDependentCareExpenses &&
        didPayDependentCareExpensesFollowupIds.length
      ) {
        idsToDelete.push(...didPayDependentCareExpensesFollowupIds)
      }
      if (
        !didHaveCharitableContribution &&
        charitableContributionsFollowupIds.length
      ) {
        idsToDelete.push(...charitableContributionsFollowupIds)
      }

      let deletionSucceeded = true
      if (idsToDelete.length > 0) {
        deletionSucceeded = Boolean(
          await dispatch(deleteTaxQuestionnaireResponses(idsToDelete))
        )
      }

      //process progresses for the step and substeps
      let progressUpdateSucceeded = false
      if (deletionSucceeded) {
        //there should always be a completed progress for the first tile page upon submission
        const completedSteps = [SubStepIdentifiers.deductionsAndCredits]
        const incompleteSteps: SubStepIdentifiers[] = []

        if (didOwnHome) {
          if (!ownedHomeFollowupIds.length) {
            incompleteSteps.push(SubStepIdentifiers.ownedHome)
          }
        } else {
          completedSteps.push(SubStepIdentifiers.ownedHome)
        }

        if (didWorkFromHome) {
          if (!workedFromHomeFollowupIds.length) {
            incompleteSteps.push(SubStepIdentifiers.homeOffice)
          }
        } else {
          completedSteps.push(SubStepIdentifiers.homeOffice)
        }

        if (didHaveBusinessMileage) {
          if (!hadBusinessMileageFollowupIds.length) {
            incompleteSteps.push(SubStepIdentifiers.businessMileage)
          }
        } else {
          completedSteps.push(SubStepIdentifiers.businessMileage)
        }

        if (didOwnFarm) {
          if (
            ownedFarmResponse?.[0]?.value !== didOwnFarm ||
            !responseNumberOfFarms?.[0]?.value
          ) {
            incompleteSteps.push(SubStepIdentifiers.farm)
          }
        } else {
          completedSteps.push(SubStepIdentifiers.farm)
        }

        if (didHaveCollegeStudents) {
          if (!hadCollegeStudentsFollowupIds.length) {
            incompleteSteps.push(SubStepIdentifiers.education)
          }
        } else {
          completedSteps.push(SubStepIdentifiers.education)
        }

        if (didPayDependentCareExpenses) {
          if (!didPayDependentCareExpensesFollowupIds.length) {
            incompleteSteps.push(SubStepIdentifiers.dependentCare)
          }
        } else {
          completedSteps.push(SubStepIdentifiers.dependentCare)
        }

        if (didHaveCharitableContribution) {
          if (!charitableContributionsFollowupIds.length) {
            incompleteSteps.push(SubStepIdentifiers.charitableContributions)
          }
        } else {
          completedSteps.push(SubStepIdentifiers.charitableContributions)
        }

        progressUpdateSucceeded = await updateProgressData({
          completedSteps,
          incompleteSteps,
        })
      }

      if (deletionSucceeded && progressUpdateSucceeded) {
        await goToNextStep(
          [...tilesToResponses(), ...responseData],
          nextScreen ?? null
        )
      }
    } catch (err) {
      logSentryError(err)
    }
  })

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchAllEoyReviewStepsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    if (form?.year) {
      dispatch(fetchUserEoyReviewProgress(form?.year))
    }
  }, [dispatch, form?.year])

  const ownedHomeSelection = selections.find(
    (s) => s.questionId === TaxListQuestionId.owned_home
  )
  const workedFromHomeSelection = selections.find(
    (s) => s.questionId === TaxListQuestionId.worked_from_home
  )
  const hadBusinessMileageSelection = selections.find(
    (s) => s.questionId === TaxListQuestionId.had_business_mileage
  )
  const ownedFarmSelection = selections.find(
    (s) => s.questionId === TaxListQuestionId.owned_farm
  )
  const hadCollegeStudentsSelection = selections.find(
    (s) => s.questionId === TaxListQuestionId.had_college_students
  )
  const paidDependentExpensesSelection = selections.find(
    (s) => s.questionId === TaxListQuestionId.paid_dependent_expenses
  )
  const charitableContributionsSelection = selections.find(
    (s) => s.questionId === TaxListQuestionId.charitable_contribution
  )
  return (
    <Grid>
      <GridRowColumn
        columnStyle={{ display: 'flex', justifyContent: 'center' }}
      >
        <Image src="https://heard-images.s3.amazonaws.com/assets/magnifying-glass.svg" />
      </GridRowColumn>
      <GridRowColumn>
        <Text as="display2" textAlign="center">
          Deductions and Credits
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([8, 14], true)}>
        <Text as="bodyLg" textAlign="center">
          Let us know if any of these applied to you in {taxYear}, so we can
          personalize this questionnaire for you.
        </Text>
      </GridRowColumn>
      <GridRowColumn columnStyle={tileColumnStyle}>
        {[
          ownedHomeSelection && (
            <TileSelector
              key={ownedHomeSelection.questionId}
              icon={ownedHomeSelection.icon}
              active={Boolean(ownedHomeSelection.response?.value)}
              text={ownedHomeSelection.text}
              width={tileWidth}
              height={tileHeight}
              onClick={() => tileClicked(ownedHomeSelection.questionId)}
            />
          ),
        ]}
      </GridRowColumn>
      <GridRowColumn columnStyle={tileColumnStyle}>
        {[
          workedFromHomeSelection && (
            <TileSelector
              key={workedFromHomeSelection.questionId}
              icon={workedFromHomeSelection.icon}
              active={Boolean(workedFromHomeSelection.response?.value)}
              text={workedFromHomeSelection.text}
              popup={{
                title: 'Worked from home',
                body: (
                  <Text>
                    You can claim a home office deduction if you’re working from
                    either a rental, primary or secondary property. You cannot
                    claim a home office deduction if you have an actual office
                    for your practice.
                  </Text>
                ),
              }}
              width={tileWidth}
              height={tileHeight}
              onClick={() => tileClicked(workedFromHomeSelection.questionId)}
            />
          ),
          hadBusinessMileageSelection && (
            <TileSelector
              key={hadBusinessMileageSelection.questionId}
              icon={hadBusinessMileageSelection.icon}
              active={Boolean(hadBusinessMileageSelection.response?.value)}
              text={hadBusinessMileageSelection.text}
              width={tileWidth}
              height={tileHeight}
              onClick={() =>
                tileClicked(hadBusinessMileageSelection.questionId)
              }
            />
          ),
          ownedFarmSelection && (
            <TileSelector
              key={ownedFarmSelection.questionId}
              icon={ownedFarmSelection.icon}
              active={Boolean(ownedFarmSelection.response?.value)}
              text={ownedFarmSelection.text}
              width={tileWidth}
              height={tileHeight}
              onClick={() => tileClicked(ownedFarmSelection.questionId)}
            />
          ),
        ]}
      </GridRowColumn>
      <GridRowColumn columnStyle={tileColumnStyle}>
        {[
          hadCollegeStudentsSelection && (
            <TileSelector
              key={hadCollegeStudentsSelection.questionId}
              icon={hadCollegeStudentsSelection.icon}
              active={Boolean(hadCollegeStudentsSelection.response?.value)}
              text={hadCollegeStudentsSelection.text}
              width={tileWidth}
              height={tileHeight}
              onClick={() =>
                tileClicked(hadCollegeStudentsSelection.questionId)
              }
            />
          ),
          paidDependentExpensesSelection && (
            <TileSelector
              key={paidDependentExpensesSelection.questionId}
              icon={paidDependentExpensesSelection.icon}
              active={Boolean(paidDependentExpensesSelection.response?.value)}
              text={paidDependentExpensesSelection.text}
              width={tileWidth}
              height={tileHeight}
              onClick={() =>
                tileClicked(paidDependentExpensesSelection.questionId)
              }
            />
          ),
          charitableContributionsSelection && (
            <TileSelector
              key={charitableContributionsSelection.questionId}
              icon={charitableContributionsSelection.icon}
              active={Boolean(charitableContributionsSelection.response?.value)}
              text={charitableContributionsSelection.text}
              subtext="(Not logged in Heard)"
              width={tileWidth}
              height={tileHeight}
              onClick={() =>
                tileClicked(charitableContributionsSelection.questionId)
              }
            />
          ),
        ]}
      </GridRowColumn>
      <GridRowColumn short columnStyle={tileColumnStyle}>
        <NoneApply
          width={noneApply.width}
          selected={noneApply.selected}
          setSelection={noneApply.clearTiles}
        />
      </GridRowColumn>
      {anyResponseSelected && (
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <DocumentAddedAlert>
            When you see the “add file” icon, we&apos;ve added a form
            requirement to your Upload Documents checklist. This list is at the
            end of the questionnaire, which is when you&apos;ll upload your
            documents.
          </DocumentAddedAlert>
        </GridRowColumn>
      )}
      <Grid.Row />
      <FormFlowFooter
        isSubmit={noneApply.selected}
        continueDisabled={
          (!noneApply.selected && !anyResponseSelected) ||
          saveAndContinue.loading
        }
        onBack={() => goBack(previousScreen ?? null)}
        onForward={saveAndContinue.callback}
        loading={isUpdating || saveAndContinue.loading}
      />
    </Grid>
  )
}

export default DeductionsAndCreditsPanel
