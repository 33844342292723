import { Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Popup, Icon, GridRowColumn, Text } from '../BaseComponents'

const NoDocumentCheckboxLabel = () => {
  return (
    <>
      I don’t have this document
      <Popup
        content={
          <Grid>
            <GridRowColumn>
              <Text as="h3">What if I don’t have this document?</Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text as="bodyLg">
                If you don’t have an electronic copy, you can download one using
                the link above.
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text as="bodyLg">
                If you don’t have this document or aren’t sure what to do, check
                this box and the Heard Team will assist you.
              </Text>
            </GridRowColumn>
          </Grid>
        }
        trigger={
          <Icon icon={regular('question-circle')} style={{ marginLeft: 8 }} />
        }
      />
    </>
  )
}

export default NoDocumentCheckboxLabel
