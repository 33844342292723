// This file contains Table.Row's used within all TabPanes
import { useEffect } from 'react'

// BL
import { UserDocument } from '../../userDocuments.slice'

// UI
import { Table } from 'semantic-ui-react'
import UserDocumentTag from '../UserDocumentTag'
import DocumentDateUploadedCell from './DocumentDateUploadedCell'
import DocumentFileNameCell from './DocumentFileNameCell'
import DocumentActionCell from './DocumentActionCell'
import { getDocumentDescription } from '../../service'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import DocumentFinancialAccountCell from './DocumentFinancialAccountCell'
import DocumentMultipleFinancialAccountCell from './DocumentMultipleFinancialAccountCell'
import { useReselector } from '../../../../utils/sharedHooks'
import { fetchFinancialAccountsIfNeeded } from '../../../../actions/financialAccountActions'
import { selectUserDocumentCategoryByDocument } from '../../userDocuments.selector'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../../OpenFeature'
import { selectFinancialAccountById } from '../../../../selectors/financeSelectors'

export interface RowComponentProps {
  document: UserDocument
  onCheck?: (docId: number, checked: boolean) => void
  editable?: boolean
}

export const BankStatementRow = ({ document }: RowComponentProps) => {
  const dispatch = useAppDispatch()
  const category = useReselector(selectUserDocumentCategoryByDocument, document)
  const releaseStatementUpload = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.multiStatementUpload,
    false
  )

  useEffect(() => {
    dispatch(fetchFinancialAccountsIfNeeded())
  }, [dispatch])

  const financialAccounts = useReselector(
    selectFinancialAccountById,
    document.statementFinancialAccountId
  )

  return (
    <Table.Row className={`${document.id === -1 && 'required-user-document'}`}>
      <DocumentDateUploadedCell document={document} />
      <DocumentFileNameCell category={category} document={document} />
      <Table.Cell>
        <UserDocumentTag document={document} />
      </Table.Cell>
      {releaseStatementUpload ? (
        <DocumentMultipleFinancialAccountCell document={document} />
      ) : (
        <DocumentFinancialAccountCell
          document={document}
          account={financialAccounts}
        />
      )}
      <DocumentActionCell document={document} />
    </Table.Row>
  )
}

export const TaxOrIncorporationRow = ({ document }: RowComponentProps) => {
  const category = useReselector(selectUserDocumentCategoryByDocument, document)
  if (document.id === -1 && category?.archivedAt) {
    return null
  }
  return (
    <Table.Row className={`${document.id === -1 && 'required-user-document'}`}>
      <DocumentFileNameCell category={category} document={document} />
      <Table.Cell>{getDocumentDescription(document, category)}</Table.Cell>
      <Table.Cell>
        <UserDocumentTag category={category} document={document} />
      </Table.Cell>
      <DocumentActionCell document={document} />
    </Table.Row>
  )
}

export const OtherRow = ({ document }: RowComponentProps) => {
  const category = useReselector(selectUserDocumentCategoryByDocument, document)
  return (
    <Table.Row className={`${document.id === -1 && 'required-user-document'}`}>
      <DocumentDateUploadedCell document={document} />
      <DocumentFileNameCell category={category} document={document} />
      <Table.Cell>{document.customerDescription}</Table.Cell>
      <DocumentActionCell document={document} />
    </Table.Row>
  )
}

export const ReceiptRow = ({ document }: RowComponentProps) => {
  const category = useReselector(selectUserDocumentCategoryByDocument, document)

  return (
    <Table.Row className={`${document.id === -1 && 'required-user-document'}`}>
      <DocumentDateUploadedCell document={document} />
      <DocumentFileNameCell category={category} document={document} />
      <Table.Cell />
      <Table.Cell />
    </Table.Row>
  )
}
