import axios from 'axios'
import { keyBy } from 'lodash'

import {
  AllocationRule,
  receiveAllAllocationRules,
  updateAllAllocationRules,
} from '../reducers/finances/allocationRulesReducer'
import { fetchIfNeededWrapper, fetchWrapper } from '../reducers/fetch'

/*
  Fetch all Allocation Rules
*/
export const FETCH_ALLOCATION_RULES_KEY = 'FETCH_ALLOCATION_RULES_KEY'

export const fetchAllocationRulesIfNeeded = () =>
  fetchIfNeededWrapper({
    defaultErrorMessage: 'There was an error fetching all allocation rules.',
    fetchKey: FETCH_ALLOCATION_RULES_KEY,
    fetchFunction: (dispatch) =>
      axios
        .get<AllocationRule[]>('/finances/api/v1/allocation_rules')
        .then((json) => {
          dispatch(receiveAllAllocationRules(keyBy(json.data, 'id')))
          return json.data
        }),
  })

/*
  Edit Allocation rules

  This uses a custom endpoint that creates, updates or deletes rules based on existence and difference
*/

export const editAllocationRuleset = (allocationRulesArray: AllocationRule[]) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error update allocation ruleset.',
    defaultValue: false,
    fetchFunction: (dispatch) => {
      const ids = allocationRulesArray
        .map(({ id }) => id)
        .filter((id) => Boolean(id))

      const data = {
        rules: allocationRulesArray,
        ruleIds: ids,
      }

      return axios
        .post<{
          rules: AllocationRule[]
          deletedIds: number[]
        }>('/finances/api/v1/allocation_rules/edit', data)
        .then((json) => {
          dispatch(updateAllAllocationRules(json.data))
          return json.data
        })
    },
  })
