import { useState } from 'react'
import { Button, GridRowColumn } from '../components/BaseComponents'
import Modal from '../components/BaseComponents/Modal'
import { Grid } from 'semantic-ui-react'

const content = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis hac massa
convallis et ipsum volutpat. Tempor, vitae accumsan posuere quisque quis
in et et velit. Convallis fermentum eget tellus morbi vestibulum, nibh
quisque ultrices. Senectus varius a senectus in amet, tellus quam
feugiat. In in sed rhoncus eu pellentesque sed. Vestibulum ut consequat
fames pulvinar id. Maecenas turpis risus vulputate amet in neque,
ullamcorper non. Vulputate iaculis commodo viverra turpis. Metus
fringilla dapibus tortor dolor.`

export const DefaultModal = () => {
  const [open, setOpen] = useState(false)

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>Default Modal</Button>}
    >
      <Modal.Header>Default Modal</Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions>
        <Button variant="actionLink" style={{ marginRight: 36 }}>
          Cancel
        </Button>
        <Button>Submit</Button>
      </Modal.Actions>
    </Modal>
  )
}

export const SmallModal = () => {
  const [open, setOpen] = useState(false)

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={<Button>Small Modal</Button>}
    >
      <Modal.Header>Small Modal</Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions>
        <Button variant="actionLink" style={{ marginRight: 36 }}>
          Cancel
        </Button>
        <Button>Submit</Button>
      </Modal.Actions>
    </Modal>
  )
}

export const TinyModal = () => {
  const [open, setOpen] = useState(false)

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="tiny"
      trigger={<Button>Tiny Modal</Button>}
    >
      <Modal.Header>Tiny Modal</Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions>
        <Button variant="actionLink" style={{ marginRight: 36 }}>
          Cancel
        </Button>
        <Button>Submit</Button>
      </Modal.Actions>
    </Modal>
  )
}

export const MiniModal = () => {
  const [open, setOpen] = useState(false)

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="mini"
      trigger={<Button>Mini Modal</Button>}
    >
      <Modal.Header>Mini Modal</Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions>
        <Button variant="actionLink" style={{ marginRight: 36 }}>
          Cancel
        </Button>
        <Button>Submit</Button>
      </Modal.Actions>
    </Modal>
  )
}

export const FullscreenModal = () => {
  const [open, setOpen] = useState(false)

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="fullscreen"
      trigger={<Button>Fullscreen Modal</Button>}
    >
      <Modal.Header>Fullscreen Modal</Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions>
        <Button variant="actionLink" style={{ marginRight: 36 }}>
          Cancel
        </Button>
        <Button>Submit</Button>
      </Modal.Actions>
    </Modal>
  )
}

export const ScrollableModal = () => {
  const [open, setOpen] = useState(false)

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>Scrollable Modal</Button>}
    >
      <Modal.Header>Scrollable Modal</Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <div className={'scrollable'}>
              {content + content + content + content}
            </div>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="actionLink" style={{ marginRight: 36 }}>
          Cancel
        </Button>
        <Button>Submit</Button>
      </Modal.Actions>
    </Modal>
  )
}
