import Button from '../../../../components/BaseComponents/Button'
import './styles.scss'

interface INavButtonsProps {
  isStepComplete: boolean
  saveAndExit: () => void
  stepBack: () => void
  stepForward: () => void
}

export const NavButtons = ({
  isStepComplete,
  saveAndExit,
  stepBack,
  stepForward,
}: INavButtonsProps) => {
  return (
    <div className="nav-buttons_wrapper">
      <div>
        <Button variant="secondary" size="medium" onClick={stepBack}>
          Back
        </Button>
      </div>

      <div className="right-buttons">
        <Button
          className="save-and-exit"
          variant="secondary"
          size="medium"
          onClick={saveAndExit}
        >
          Save and Exit
        </Button>

        <Button
          disabled={!isStepComplete}
          variant="primary"
          size="medium"
          onClick={stepForward}
        >
          Continue
        </Button>
      </div>
    </div>
  )
}
