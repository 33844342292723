import { Container, Grid, Icon } from 'semantic-ui-react'
import moment from 'moment'

import { formatCurrency } from '../../../../utils/currencyHelpers'
import { useBankAccount } from '../../helpers'
import { Popup, Text } from '../../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'

const ContractorPayrollOverview = ({
  total,
  payDate,
}: {
  total: number
  payDate: string
}) => {
  const bankAccount = useBankAccount()

  return (
    <Grid.Row className="payment-overview-con">
      <Grid.Column width={6}>
        <Container className="payment-overview-section">
          <div className="title-wrapper">
            <Text as="bodyMd">Contractor Pay Date</Text>
            <Popup
              content="This is the date your contractor payment will be debited into their account."
              trigger={<Icon name="info circle" />}
            />
          </div>
          <Text as="h1" className="amount">
            {moment(payDate).format(DATE_FORMATS.DISPLAY_LONG)}
          </Text>
        </Container>
      </Grid.Column>
      <Grid.Column width={6}>
        <Container className="payment-overview-section">
          <div className="title-wrapper">
            <Text as="bodyMd">
              Withdrawn from Bank Account
              <br />
              {bankAccount?.account_type} {bankAccount?.hidden_account_number}
            </Text>
            <Popup
              content="This is the amount that will be taken from your attached bank account for this contractor payment."
              trigger={<Icon name="info circle" />}
            />
          </div>
          <Text as="h1" className="amount">
            {formatCurrency(total)}
          </Text>
        </Container>
      </Grid.Column>
    </Grid.Row>
  )
}

export default ContractorPayrollOverview
