import { UserDocument } from '../../userDocuments.slice'
import { Table } from '../../../../components/BaseComponents'
import { FinancialAccount } from '../../../../reducers/finances/financialAccountsReducer'

interface DocumentFinancialAccountCellProps {
  document: UserDocument
  account?: FinancialAccount
}

const DocumentFinancialAccountCell = ({
  document,
  account,
}: DocumentFinancialAccountCellProps) => (
  <Table.Cell>
    <b>
      {document.id !== -1 &&
        account &&
        `${account?.name || ''}: ${account?.mask || ''}`}
    </b>
  </Table.Cell>
)

export default DocumentFinancialAccountCell
