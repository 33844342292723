import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  Button,
  Card,
  Icon,
  IconButton,
  Text,
} from '../../../components/BaseComponents'

interface MessageBannerProps {
  close: () => void
  error: boolean
  message: string
  undo: () => void
}

export const MessageBanner = ({
  close,
  error,
  message,
  undo,
}: MessageBannerProps) => {
  return (
    <Card
      type="subsection"
      backgroundColor={error ? 'lightRed' : 'natural'}
      className="message-banner__card"
      style={{ border: error ? '1px solid #b95042' : 'none' }}
    >
      <div className="message-banner__copy">
        {error && (
          <Icon
            icon={solid('circle-exclamation')}
            color="red"
            className="message-banner__error-icon"
            aria-label="exclamation icon"
          />
        )}

        {message && (
          <>
            <Text className="message-banner__text">{message}</Text>

            <Button variant="secondaryLink" onClick={undo}>
              Undo
            </Button>
          </>
        )}
      </div>

      {!error && (
        <IconButton
          size="xl"
          icon={solid('close')}
          onClick={close}
          aria-label="close button"
        />
      )}
    </Card>
  )
}
