import { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Form, Grid } from 'semantic-ui-react'
import { Navigate } from 'react-router-dom'

import {
  validateResetPasswordToken,
  updateResetPassword,
  UPDATE_RESET_PASSWORD_KEY,
  VALIDATE_RESET_PASSWORD_TOKEN_ERROR_KEY,
} from '../../actions/authActions'
import { withRouter } from '../../utils/routeHelpers'
import { Dispatch, ReduxState } from '../../utils/typeHelpers'
import { Alert, Button, Input, Text, Link, Card } from '../BaseComponents'
import { selectErrorsForKeys } from '../../reducers/fetch'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
interface ParentProps {
  params: { token: string | undefined }
}
type Props = ParentProps & StateProps & DispatchProps

interface State {
  newPassword: string
  confirmPassword: string
  passwordMatch: boolean
}

class ResetPasswordForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      newPassword: '',
      confirmPassword: '',
      passwordMatch: true,
    }
  }

  componentDidMount() {
    const {
      params: { token },
      validateResetPasswordToken,
    } = this.props
    if (token) {
      validateResetPasswordToken(token)
    }
  }

  renderResetPasswordForm() {
    const {
      auth: {
        validResetPasswordToken,
        updatedPassword,
        validatingTokenLoading,
      },
      params: { token },
      updateResetPassword,
      errors,
    } = this.props

    // If we're fetching validation data, we're waiting
    if (validatingTokenLoading) {
      return (
        <Container>
          {' '}
          <Text as="bodyMd">Loading...</Text>
        </Container>
      )
    }

    // Show Redirection to login screen if successful password reset

    if (updatedPassword) {
      return (
        <div>
          <Navigate to="/login" />

          <Text as="bodyMd">Redirecting you to login...</Text>
        </div>
      )
    }

    // Allow reset if token is still valid
    if (validResetPasswordToken) {
      return (
        <Grid centered stackable doubling>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Text as="h2">Reset Your Password Form</Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={6} mobile={15} tablet={12}>
              <Card>
                <Form
                  onSubmit={(event) => {
                    event.preventDefault()
                    if (token) {
                      updateResetPassword(this.state.newPassword, token)
                    }
                  }}
                  error
                >
                  <Grid>
                    {Boolean(errors.length) && (
                      <Grid.Row>
                        <Grid.Column>
                          {errors.map((error) => (
                            <div key={error.message}>
                              <Alert type="error">{error.message}</Alert>
                              <br />
                            </div>
                          ))}
                        </Grid.Column>
                      </Grid.Row>
                    )}
                    <Grid.Row>
                      <Grid.Column>
                        <Input
                          placeholder="New Password"
                          label="Create a New Password"
                          type="password"
                          value={this.state.newPassword}
                          onChange={(newPassword) => {
                            this.setState({ newPassword })
                            const passwordMatches =
                              newPassword === this.state.confirmPassword &&
                              newPassword.length > 0
                            this.setState({ passwordMatch: passwordMatches })
                          }}
                          required
                          fullWidth
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column>
                        <Input
                          label="Confirm Your New Password"
                          placeholder="Confirm Password"
                          type="password"
                          value={this.state.confirmPassword}
                          onChange={(confirmPassword) => {
                            this.setState({ confirmPassword })
                            const passwordMatches =
                              confirmPassword === this.state.newPassword &&
                              confirmPassword.length > 0
                            this.setState({ passwordMatch: passwordMatches })
                          }}
                          error={!this.state.passwordMatch}
                          required
                          fullWidth
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <br />
                    <Grid.Row centered>
                      <Grid.Column>
                        <Button
                          id="btn-auth-reset-password"
                          type="submit"
                          disabled={!this.state.passwordMatch}
                          fullWidth
                        >
                          Update Password
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )
    }

    return (
      <div>
        <Text as="h1">
          Uh oh! It looks like your password reset link is no longer valid.
        </Text>
        <Text as="bodyMd">
          If you need to reset your password, please head to the{' '}
          <Link to="/login">login</Link> page to try again.
        </Text>
      </div>
    )
  }
  render() {
    if (this.props.auth.isAuthenticated) {
      return <Navigate to="/dashboard" />
    }

    return (
      <Container fluid className="finances reset">
        {this.renderResetPasswordForm()}
      </Container>
    )
  }
}

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth.security,
  errors: selectErrorsForKeys(state, [
    UPDATE_RESET_PASSWORD_KEY,
    VALIDATE_RESET_PASSWORD_TOKEN_ERROR_KEY,
  ]),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  validateResetPasswordToken: (token: string) =>
    dispatch(validateResetPasswordToken(token)),
  updateResetPassword: (password: string, token: string) =>
    dispatch(updateResetPassword({ password }, token)),
})

const ConnectedResetPasswordForm = connect<
  StateProps,
  DispatchProps,
  ParentProps,
  ReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordForm)

export default withRouter(ConnectedResetPasswordForm)
