import { useCallback, useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'

import {
  Accordion,
  Button,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import { TaxFormType } from '../Questionnaires/constants'
import {
  TaxSeasonKickoffStepProps,
  TAX_SURVEY_CONTENT_SCREENS,
} from './AnnualTaxSurvey'
import { useReselector } from '../../../../utils/sharedHooks'
import { getAnnualTaxFilingForYearSelector } from '../annualTaxFilings.selector'
import FormFlowFooter from '../../../../components/FormFlow/FormFlowFooter'
import { selectTaxDetailsByYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { useAnalyticsView } from '../../../Amplitude'
import { selectIsNewScorpSubscription2023 } from '../../../../reducers/subscription.slice'
import {
  DATE_FORMATS_LUXON,
  formatISOFromUTC,
} from '../../../../utils/dateHelpers'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import { ADDITIONAL_1040_COST } from '../constants'

const Additional1040Panel = ({
  goBack,
  goToNextStep,
  year,
}: TaxSeasonKickoffStepProps) => {
  const currentAnnualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    year
  )
  // Users on subscriptions will not be charged for the extra form
  const isNewSubscription = useReselector(selectIsNewScorpSubscription2023)
  const annualTaxDetails = useReselector(selectTaxDetailsByYear, year)

  const pageView = useAnalyticsView()
  const screenName = 'TSK additional 1040'
  useEffect(() => {
    pageView(screenName)
  }, [pageView])

  // There isn't a way to tell if a user has chosen *not* to file an additional tax filing.
  // We will just make them answer it again
  const [agreeToFile, setAgreeToFile] = useState<boolean | undefined>(
    currentAnnualTaxFiling?.annualTaxFormNeeds?.length === 2 || undefined
  )

  const whyFilePersonalTitle =
    'Why do I also need to file a personal income tax return?'
  const whyFilePersonalContent = (
    <Text>
      As an S corporation, all of the earned income from your practice is passed
      through to shareholders. This means that as an <b>employer</b>, you
      don&apos;t pay federal income taxes.
      <br />
      <br />
      You only pay taxes on the income you earned as <b>an employee</b>. You
      report this income on your personal tax return.
    </Text>
  )

  const saveAndContinue = useCallback(() => {
    goToNextStep(
      {
        annualTaxFormNeeds: agreeToFile
          ? [TaxFormType.form1120s, TaxFormType.form1040]
          : [TaxFormType.form1120s],
      },
      TAX_SURVEY_CONTENT_SCREENS.contractorNec
    )
  }, [agreeToFile, goToNextStep])

  const formAccordionTitle = 'What is Form 1040?'

  return (
    <Grid>
      <GridRowColumn>
        <Text as="display2" textAlign="center">
          Additional Tax Filings
        </Text>
      </GridRowColumn>

      <GridRowColumn {...makeGridConfig([10, 12], true)}>
        <Text as="bodyLg">
          As an S corporation, you must also file a personal income tax return
          (Form 1040).
        </Text>
      </GridRowColumn>

      <GridRowColumn {...makeGridConfig([10, 12], true)}>
        <Text as="bodyLg">
          {isNewSubscription
            ? 'Your Heard membership includes two tax filings. If you’d like Heard to prepare your personal income tax return, there’s no additional fee.'
            : `Your Heard membership includes one tax filing. If you’d like Heard to prepare your personal income tax return, there’s a $${ADDITIONAL_1040_COST} fee.`}
        </Text>
      </GridRowColumn>
      <Grid.Row />

      <GridRowColumn {...makeGridConfig([6, 14], true)}>
        <Text as="h3">
          Would you like Heard to also file a personal income tax return for
          you?
        </Text>
      </GridRowColumn>

      <Grid.Row>
        <Grid.Column computer={5} tablet={5} mobile={1} />
        <Grid.Column computer={3} tablet={3} mobile={7}>
          <Button
            variant={agreeToFile ? 'primary' : 'secondary'}
            onClick={() => setAgreeToFile(true)}
            fullWidth
          >
            Yes
          </Button>
        </Grid.Column>
        <Grid.Column computer={3} tablet={3} mobile={7}>
          <Button
            variant={agreeToFile === false ? 'primary' : 'secondary'}
            onClick={() => setAgreeToFile(false)}
            fullWidth
          >
            No
          </Button>
        </Grid.Column>
      </Grid.Row>
      {agreeToFile && !isNewSubscription && (
        <GridRowColumn {...makeGridConfig([10, 14], true)}>
          <Text>This will incur an additional ${ADDITIONAL_1040_COST} fee</Text>
        </GridRowColumn>
      )}
      {agreeToFile === false && (
        <GridRowColumn {...makeGridConfig([10, 14], true)}>
          <Text>
            No problem! Just remember that you must file Form 1040 outside of
            Heard before{' '}
            <b>
              {formatISOFromUTC(
                annualTaxDetails?.irsFormDueDates.form_1040.irs.dueDate,
                DATE_FORMATS_LUXON.DISPLAY_LONG
              )}
            </b>
            .
          </Text>
        </GridRowColumn>
      )}
      <Grid.Row className="short" />
      <GridRowColumn {...makeGridConfig([10, 14], true)}>
        <Accordion
          title={formAccordionTitle}
          content="This is the personal income tax return form that the majority of U.S. taxpayers use to report their annual income."
          trackOnOpenClick={{
            screen: screenName,
            title: formAccordionTitle,
          }}
          variant="default"
        />
        <Accordion
          title={whyFilePersonalTitle}
          content={whyFilePersonalContent}
          trackOnOpenClick={{
            screen: screenName,
            title: whyFilePersonalTitle,
          }}
          variant="default"
        />
      </GridRowColumn>
      <FormFlowFooter
        onBack={() => goBack(TAX_SURVEY_CONTENT_SCREENS.formSelection)}
        onForward={saveAndContinue}
        continueDisabled={agreeToFile === undefined}
      />
    </Grid>
  )
}

export default Additional1040Panel
