import { omit } from 'lodash'
import moment from 'moment'

import { TransactionFiltersState } from '../reducers/finances/transactionFiltersReducer'
import { FilteredTransactionsPayload } from '../actions/adminActions'
import { DATE_FORMATS } from '../utils/dateHelpers'

export const transactionFilterToQueryUrlService = (
  transactionFilters: Partial<TransactionFiltersState> & { userId: number }
) => {
  const { startDate, endDate, sortVal, transactionsType, sortAsc } =
    transactionFilters

  const filters: FilteredTransactionsPayload = omit(
    transactionFilters,
    'startDate',
    'endDate'
  )

  if (startDate) {
    filters.startDate = moment(startDate, DATE_FORMATS.INPUT)
  }

  if (endDate) {
    filters.endDate = moment(endDate, DATE_FORMATS.INPUT)
  }

  if (transactionsType === 'excluded') {
    filters.includeSplitChildren = true
  }

  filters.sortName = sortVal

  if (sortAsc !== undefined) {
    filters.sortDirection = sortAsc ? 'asc' : 'desc'
  }

  return filters
}
