import { FormikProvider, useFormik } from 'formik'
import { Form1120sProps } from '.'
import { useParams } from 'react-router-dom'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { TaxListQuestionId } from '../service'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../taxChecklist.selectors'
import { Container, Grid } from 'semantic-ui-react'
import {
  FormikDropdown,
  FormikLabelError,
  FormikRadioToggleButton,
  GridRowColumn,
  Icon,
  Popup,
  Text,
  getFieldName,
  makeReqBoolSchema,
  makeStringArraySchema,
} from '../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import DocumentAddedAlert from '../Shared/SpecialtyAlerts'
import { TaxChecklistResponse } from '../taxChecklistQuestion.slice'
import { SubStepIdentifiers } from '../Shared/ReviewStepsandProgresses/stepProgress.helpers'

export const updateDetailsQuestionsIds = [
  TaxListQuestionId.multi_state_business_states,
  TaxListQuestionId.shareholder_info_changed,
]

const UpdateDetailsPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: Form1120sProps) => {
  const { formId } = useParams()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const questionMultiStateBusinessStates = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.multi_state_business_states,
    taxYear
  )
  const responseMultiStateBusinessStates = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.multi_state_business_states,
    Number(formId)
  )

  const questionShareHolderInfoChanged = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.shareholder_info_changed,
    taxYear
  )
  const responseShareHolderInfoChanged = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.shareholder_info_changed,
    Number(formId)
  )

  // Derived TF responses
  const responseNumberStatesConductedBiz = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_states_conducted_business,
    Number(formId)
  )

  const dropdownOptions = questionMultiStateBusinessStates?.options
    ?.map((o) => ({
      text: o.text,
      value: o.id,
      key: o.id,
    }))
    .sort((a, b) => a.text.localeCompare(b.text))

  const formik = useFormik({
    initialValues: {
      multiStateBusinessStates: responseMultiStateBusinessStates?.[0]?.value,
      shareHolderInfoChanged: responseShareHolderInfoChanged?.[0]?.value,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseUpdates: Partial<TaxChecklistResponse>[] = []
      if (
        values.multiStateBusinessStates !==
        responseMultiStateBusinessStates?.[0]?.value
      ) {
        responseUpdates.push({
          id: responseMultiStateBusinessStates?.[0]?.id,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.multi_state_business_states,
          value: values.multiStateBusinessStates,
        })
      }
      if (
        Array.isArray(values.multiStateBusinessStates) &&
        values.multiStateBusinessStates.length !==
          responseNumberStatesConductedBiz?.[0]?.value
      ) {
        responseUpdates.push({
          id: responseNumberStatesConductedBiz?.[0]?.id,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.number_states_conducted_business,
          value: values.multiStateBusinessStates.length,
        })
      }
      if (
        values.shareHolderInfoChanged !==
        responseShareHolderInfoChanged?.[0]?.value
      ) {
        responseUpdates.push({
          id: responseShareHolderInfoChanged?.[0]?.id,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.shareholder_info_changed,
          value: values.shareHolderInfoChanged,
        })
      }
      await goToNextStep(responseUpdates, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.checkDetails1120s],
      })
    },
  })

  const { values, isSubmitting, isValid, submitForm } = formik
  return (
    <>
      <FormikProvider value={formik}>
        <Container style={{ marginTop: 56 }} text textAlign="left">
          <Grid>
            <GridRowColumn>
              <Text as="display2" textAlign="center">
                Update Details for {taxYear}
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text as="bodyLg">
                Please update the following information for {taxYear}. These
                answers will appear on your tax return.
              </Text>
            </GridRowColumn>
            <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
              <FormikDropdown
                search
                name={getFieldName<typeof values>('multiStateBusinessStates')}
                label={questionMultiStateBusinessStates?.question?.text}
                options={dropdownOptions}
                schema={makeStringArraySchema()}
                multiple
                clearable
                fullWidth
                afterLabel={
                  <Popup
                    content={
                      <Grid>
                        <GridRowColumn>
                          <Text as="h3">
                            Registering business in another state
                          </Text>
                        </GridRowColumn>
                        <GridRowColumn short>
                          <Text>
                            Select <b>Yes</b> if you see clients in more than
                            one state.
                          </Text>
                        </GridRowColumn>
                      </Grid>
                    }
                    trigger={
                      <Icon
                        size="1x"
                        style={{ marginLeft: 4 }}
                        icon={regular('question-circle')}
                        color="mediumGray"
                      />
                    }
                  />
                }
              />
            </GridRowColumn>
            <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
              <FormikLabelError
                name={getFieldName<typeof values>('shareHolderInfoChanged')}
                label={questionShareHolderInfoChanged.question?.text}
                schema={makeReqBoolSchema()}
              />
            </GridRowColumn>
            <Grid.Row className="short" style={{ justifyContent: 'center' }}>
              <Grid.Column width={6} style={{ paddingRight: 0 }}>
                <FormikRadioToggleButton
                  fullWidth
                  name={getFieldName<typeof values>('shareHolderInfoChanged')}
                  value
                >
                  Yes
                </FormikRadioToggleButton>
              </Grid.Column>
              <Grid.Column width={6}>
                <FormikRadioToggleButton
                  fullWidth
                  name={getFieldName<typeof values>('shareHolderInfoChanged')}
                  value={false}
                >
                  No
                </FormikRadioToggleButton>
              </Grid.Column>
            </Grid.Row>
            {values.shareHolderInfoChanged && (
              <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
                <DocumentAddedAlert>
                  You&apos;ll need to upload your most recent incorporation
                  documents at a later step.
                </DocumentAddedAlert>
              </GridRowColumn>
            )}
          </Grid>
        </Container>
      </FormikProvider>
      <Grid>
        <FormFlowFooter
          continueDisabled={!isValid || isSubmitting}
          loading={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
        />
      </Grid>
    </>
  )
}

export default UpdateDetailsPanel
