import { getFetchError } from '../../reducers/fetch'
import { Alert, GridRowColumn } from '../../components/BaseComponents'
import { useReselector } from '../../utils/sharedHooks'

const PayrollError = ({ fetchKey }: { fetchKey: string }) => {
  const error = useReselector(getFetchError, fetchKey)

  if (!error) {
    return null
  }

  return (
    <GridRowColumn>
      <Alert type="error">{error.message}</Alert>
    </GridRowColumn>
  )
}

export default PayrollError
