import { Grid } from 'semantic-ui-react'
import {
  Alert,
  GridRowColumn,
  Radio,
  Text,
} from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import { useEffect } from 'react'
import { useAnalyticsView } from '../../Amplitude'

interface ILBAIntroPageProps {
  isInterestedInLba: boolean | null | undefined
  setIsInterestedInLba: (isInterestedInLba: boolean) => void
}

export const LBAIntroPage = ({
  isInterestedInLba,
  setIsInterestedInLba,
}: ILBAIntroPageProps) => {
  const pageView = useAnalyticsView()

  useEffect(() => {
    pageView('lba interest')
  }, [pageView])
  return (
    <Grid>
      <GridRowColumn>
        <Text as="h2">
          Don&apos;t want to upload bank statements every month?
        </Text>
      </GridRowColumn>
      <Grid.Row>
        <Grid.Column width={12}>
          <Text>
            At least one of your banks allows <b>limited bank access</b>, which
            grants Heard a separate login to your accounts, allowing us to
            download your bank statements directly. Moving forward, you
            wouldn&apos;t have to upload them each month.
          </Text>
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn>
        <Text as="h3" style={{ marginBottom: 12 }}>
          Are you interested?
        </Text>
        <Radio
          label="Yes"
          checked={isInterestedInLba === true}
          onChange={() => setIsInterestedInLba(true)}
        />
      </GridRowColumn>
      <GridRowColumn
        style={{
          paddingTop: 0,
        }}
      >
        <Radio
          label="No"
          checked={isInterestedInLba === false}
          onChange={() => setIsInterestedInLba(false)}
        />
      </GridRowColumn>
      {isInterestedInLba === false && (
        <Grid.Row>
          <Grid.Column
            width={12}
            style={{
              backgroundColor: Colors.stone,
              paddingTop: 0,
            }}
          >
            <Alert type="info">
              No problem. You can always reach out to us if you would like to
              set up limited bank access in the future.
            </Alert>
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  )
}
