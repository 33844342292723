import { useState } from 'react'
import { Divider, Grid } from 'semantic-ui-react'
import {
  Text,
  Button,
  Icon,
  Link,
  IconButton,
  GridRowColumn,
  Popup,
  Alert,
} from '../../../components/BaseComponents'
import { TransactionOrSplit } from '../../../reducers/admin/allTransactions.slice'
import { NotesInput } from './NotesInput'
import { useReselector } from '../../../utils/sharedHooks'
import { selectReceiptsForTransaction } from '../../UserDocuments/userDocuments.selector'
import { destroySingleUserDocument } from '../../UserDocuments/userDocuments.slice'
import Truncate from 'react-truncate'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import DetailsTooltip from './DetailsTooltip'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface NotesPanelProps {
  transaction: TransactionOrSplit
  toggleFileModal: () => void
  splitIndex?: number
  parentId?: number
  setPreventClose: (value: boolean) => void
}

export const NotesPanel = ({
  transaction,
  toggleFileModal,
  splitIndex,
  parentId,
  setPreventClose,
}: NotesPanelProps) => {
  const [error, setError] = useState('')
  const transactionIdForDocs = parentId || transaction.id

  const documents = useReselector(
    selectReceiptsForTransaction,
    transactionIdForDocs,
    splitIndex
  )
  const dispatch = useAppDispatch()

  const deleteFile = async (fileId: number) => {
    setError('')
    const deleted = await destroySingleUserDocument(fileId)(dispatch)
    if (!deleted) setError('Error deleting your receipt.')
  }
  return (
    <Grid>
      <GridRowColumn>
        <NotesInput transaction={transaction} />
      </GridRowColumn>
      <Divider section />
      <GridRowColumn>
        <Text as="h3" style={{ marginBottom: 3 }}>
          Receipt
          <DetailsTooltip
            label="Receipt"
            content={
              <Text>Uploading a receipt is optional but recommended.</Text>
            }
          />
        </Text>
        {documents?.map((doc) => (
          <div style={{ marginBottom: 3 }} key={doc.id}>
            <Link href={doc?.signedUrl || ''} newPage key={doc.id}>
              <Popup
                position="top center"
                content={`File Name: ${doc?.fileDescription}`}
                trigger={<Truncate>{doc?.fileDescription}</Truncate>}
              />
            </Link>
            <IconButton
              onClick={() => deleteFile(doc.id)}
              icon={regular('trash-can')}
              style={{ marginLeft: 6 }}
            />
            <br />
          </div>
        ))}
        <Button
          variant="secondaryWhite"
          onClick={() => {
            toggleFileModal()
            setPreventClose(true)
          }}
        >
          <Icon icon={regular('upload')} style={{ marginRight: 6 }} />
          Upload
        </Button>
      </GridRowColumn>
      {error && (
        <GridRowColumn>
          <Alert type="error">{error}</Alert>
        </GridRowColumn>
      )}
    </Grid>
  )
}
