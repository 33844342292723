import { useSearchParams } from 'react-router-dom'
import moment from 'moment'

import { PAYROLL_STEPS } from '../../helpers'
import { ProgressBar, Text } from '../../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { Gusto_Payroll } from '../../generated_gusto_types'

const PayrollHeader = ({
  upcomingPayroll,
  currentTab,
}: {
  upcomingPayroll: Gusto_Payroll
  currentTab: number
}) => {
  const [searchParams] = useSearchParams()
  const isOffCycle = searchParams.get('isOffCycle')

  return (
    <>
      <Text as="h2">{isOffCycle ? 'Off-Cycle Payroll' : 'Run Payroll'}</Text>
      <Text className="date-range">
        <b>
          {upcomingPayroll &&
            moment(upcomingPayroll?.pay_period.start_date).format(
              DATE_FORMATS.MONTH_DAY
            )}{' '}
          -{' '}
          {moment(upcomingPayroll?.pay_period.end_date).format(
            DATE_FORMATS.DISPLAY_SHORT
          )}{' '}
        </b>
        Pay Period
      </Text>
      <ProgressBar currentStep={currentTab} steps={PAYROLL_STEPS} />
    </>
  )
}

export default PayrollHeader
