import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { keyBy } from 'lodash'

import { fetchIfNeededWrapper, fetchWrapper } from '../../../reducers/fetch'
import { ActionItem } from '../../Admin/ActionItems/allActionItems.slice'
import { User, updateCurrentUser } from '../../../reducers/auth/userReducer'

// Used to identify specific action_items across all envs

export const UserActionItemActionItemIdentifiers = {
  reconnectBankAccount: 'reconnect-bank-account',
  submitQTEPayment: ({
    taxQuarter,
    taxYear,
  }: {
    taxQuarter: string
    taxYear: string
  }) => `submit-q${taxQuarter}-${taxYear}-qte-payment`,
  completeQTEChecklist: ({
    taxQuarter,
    taxYear,
  }: {
    taxQuarter: string
    taxYear: string
  }) => `${taxYear}-q${taxQuarter}-complete-qte-checklist`,
  submitAnnualFederalTaxReturnPayment: ({
    taxYear = '',
  }: {
    taxYear?: string
  }) => `submit-${taxYear}-federal-tax-return-payment`,
  reviewAllocationGuide: ({ month, year }: { month: string; year: string }) =>
    `review-${month}-${year}-allocation-guide`,
  reviewBooks: ({ month, year }: { month: string; year: string }) =>
    `review-${month}-${year}-books`,
  reviewRecentTransactions: 'review-recent-transactions',
  uploadBankStatements: ({ month, year }: { month: string; year: string }) =>
    `upload-${month}-${year}-bank-statements`,
  completeTaxSeasonKickoff: ({ year }: { year: string }) =>
    `${year}-complete-tax-season-kickoff`,
  completeTaxSeasonKickoffLateSignup: ({ year }: { year: string }) =>
    `${year}-complete-pretax-survey-late-signup`,
  eoyReviewSurvey: ({ year }: { year: string }) =>
    `${year}-review-eoy-financial-review`,
  submit1099NEC: ({ year }: { year: string }) => `${year}-submit-1099-nec`,
  reviewDraft1040: ({ year }: { year: string }) =>
    `${year}-review-tax-return-draft-1040`,
  reviewDraft1120s: ({ year }: { year: string }) =>
    `${year}-review-tax-return-draft-1120s`,
  limitedBankAccess: 'limited-bank-access',
  transactionsNeedReview: 'transactions-need-review',
  submitTaxChecklist1040: ({ year }: { year: string }) =>
    `${year}-submit-tq-form-1040`,
  submitTaxChecklist1120s: ({ year }: { year: string }) =>
    `${year}-submit-tq-form-1120-s`,
  submitExtension1040: ({ year }: { year: string }) =>
    `${year}-submit-extension-form-1040`,
  submitExtension1120s: ({ year }: { year: string }) =>
    `${year}-submit-extension-form-1120-s`,
  smsOptIn: 'sms-opt-in',
}

export const getAnnualTaxActionItemIdentifiersForYear = (year: string) => {
  return [
    UserActionItemActionItemIdentifiers.completeTaxSeasonKickoff({ year }),
    UserActionItemActionItemIdentifiers.completeTaxSeasonKickoffLateSignup({
      year,
    }),
    UserActionItemActionItemIdentifiers.reviewDraft1040({ year }),
    UserActionItemActionItemIdentifiers.reviewDraft1120s({ year }),
    UserActionItemActionItemIdentifiers.submitTaxChecklist1040({ year }),
    UserActionItemActionItemIdentifiers.submitTaxChecklist1120s({ year }),
    UserActionItemActionItemIdentifiers.submitExtension1040({ year }),
    UserActionItemActionItemIdentifiers.submitExtension1120s({ year }),
  ]
}

/**
 * Function to get quarterly tax action item identifiers for a specific quarter
 * @param taxYear The tax year
 * @param taxQuarter The tax quarter
 */
export const getQuarterlyTaxActionItemIdentifiersForQuarter = ({
  taxYear,
  taxQuarter,
}: {
  taxYear?: string
  taxQuarter?: string
}) => {
  if (!taxYear || !taxQuarter) {
    return []
  }
  return [
    UserActionItemActionItemIdentifiers.submitQTEPayment({
      taxYear,
      taxQuarter,
    }),
    UserActionItemActionItemIdentifiers.completeQTEChecklist({
      taxYear,
      taxQuarter,
    }),
  ]
}

export type UserActionItemsGroupOption =
  // date will either be userActionItem.actionItem.endsAt or userActionItem.completedAt
  // depending upon the tab pane
  'date-asc-groupedby-categoryName' | 'date-asc-groupedby-month'

export const groupOptions: {
  text: string
  value: UserActionItemsGroupOption
}[] = [
  {
    text: 'Category',
    value: 'date-asc-groupedby-categoryName',
  },
  {
    text: 'Due Date',
    value: 'date-asc-groupedby-month',
  },
]

export interface UserActionItem {
  id: number
  readAt?: string
  completedAt?: string
  deletedAt?: string
  createdAt?: string
  actionItem: ActionItem
  startsAt?: string // format: YYYY-MM-DD
  endsAt?: string // format: YYYY-MM-DD
  templateValue?: string
  status?: string
  userId?: number
}

export interface UserActionItemState {
  [key: number]: UserActionItem
}

const initialState: UserActionItemState = {}

const userActionItemsSlice = createSlice({
  name: 'userActionItems',
  initialState,
  reducers: {
    receiveFetchUserActionItems: (
      state,
      action: PayloadAction<{
        [key: number]: UserActionItem
      }>
    ) => ({ ...state, ...action.payload }),
    receiveUpdateUserActionItem: (
      state,
      action: PayloadAction<UserActionItem>
    ) => {
      state[action.payload.id] = action.payload
    },
    removeUserActionItem: (state, action: PayloadAction<number>) => {
      delete state[action.payload]
    },
  },
})

export default userActionItemsSlice.reducer

const {
  receiveFetchUserActionItems,
  receiveUpdateUserActionItem,
  removeUserActionItem,
} = userActionItemsSlice.actions

export const FETCH_USER_ACTION_ITEMS_KEY = 'FETCH_USER_ACTION_ITEMS_KEY'

export const fetchUserActionItemsIfNeeded = (
  params: {
    forceFetch?: boolean
  } = { forceFetch: undefined }
) => {
  const { forceFetch } = params

  return fetchIfNeededWrapper({
    fetchKey: FETCH_USER_ACTION_ITEMS_KEY,
    alwaysFetch: Boolean(forceFetch),
    defaultErrorMessage: 'Error fetching user action items',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<UserActionItem[]>(
        '/finances/api/v1/user_action_items'
      )

      dispatch(receiveFetchUserActionItems(keyBy(json.data, 'id')))
      return json.data
    },
  })
}

export interface UpdateUserActionItemPayload
  extends Omit<
    UserActionItem,
    'actionItem' | 'readAt' | 'completedAt' | 'deletedAt' | 'status'
  > {
  readAt?: null | string
  completedAt?: null | string
  status?: null | string
  markedNotApplicableAt?: null | string
}

export const UPDATE_ACTION_ITEM_KEY = 'UPDATE_ACTION_ITEM_KEY'

export const updateUserActionItem = (
  id: number,
  data: UpdateUserActionItemPayload
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error updating the user action item.',
    fetchKey: UPDATE_ACTION_ITEM_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.put<UserActionItem>(
        `/finances/api/v1/user_action_items/${id}`,
        data
      )

      dispatch(receiveUpdateUserActionItem(json.data))
      return json.data
    },
  })

export const COMPLETE_ONBOARDING_KEY = 'COMPLETE_ONBOARDING_KEY'
export const postCompleteOnboarding = () =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error completing onboarding.',
    fetchKey: COMPLETE_ONBOARDING_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<User>(
        '/finances/api/v1/user_action_items/complete_onboarding'
      )
      dispatch(updateCurrentUser(json.data))

      return json.data
    },
  })

export const createUserActionItem = (identifier: string) =>
  fetchWrapper({
    fetchFunction: async () => {
      const json = await axios.post<{ message: string }>(
        '/finances/api/v1/user_action_items',
        { identifier }
      )

      return json.data
    },
  })

/*
  //////
  Actions below can be called by admins only 
  /////
*/

export const ADMIN_FETCH_USER_ACTION_ITEMS_KEY =
  'ADMIN_FETCH_USER_ACTION_ITEMS_KEY'
export const adminFetchUserActionItems = (userId: number) =>
  fetchWrapper({
    fetchKey: ADMIN_FETCH_USER_ACTION_ITEMS_KEY,
    defaultErrorMessage: 'Error fetching user action items',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<UserActionItem[]>(
        `/finances/api/v1/admin/${userId}/user_action_items`
      )

      dispatch(receiveFetchUserActionItems(keyBy(json.data, 'id')))
      return json.data
    },
  })

export const ADMIN_CREATE_USER_ACTION_ITEM_KEY =
  'ADMIN_CREATE_USER_ACTION_ITEM_KEY'
export const adminCreateUserActionItem = (
  userId: number,
  data: {
    actionItemId: number | undefined
  }
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error updating the user action item.',
    fetchKey: ADMIN_CREATE_USER_ACTION_ITEM_KEY,
    fetchFunction: async (dispatch) => {
      // Api won't always return an action item and doesn't error if one isn't returned
      const json = await axios.post<UserActionItem | null>(
        `/finances/api/v1/admin/${userId}/user_action_items`,
        data
      )

      if (json.data) {
        dispatch(receiveUpdateUserActionItem(json.data))
      }
      return json.data
    },
  })

export const adminDeleteUserActionItem = (id: number, userId: number) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error updating the user action item.',
    fetchFunction: async (dispatch) => {
      const json = await axios.delete<UserActionItem>(
        `/finances/api/v1/admin/${userId}/user_action_items/${id}`
      )

      dispatch(removeUserActionItem(id))
      return json.data
    },
  })
