import { forwardRef, KeyboardEventHandler, ReactNode } from 'react'
import {
  Icon,
  Input as SemanticInput,
  SemanticICONS,
  StrictInputProps,
  SemanticCOLORS,
} from 'semantic-ui-react'
import {
  NumericFormatProps,
  NumericFormat,
  PatternFormat,
  PatternFormatProps,
} from 'react-number-format'
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field'
import styled from 'styled-components'
import { isNil, omit } from 'lodash'
import PhoneInput from 'react-phone-number-input/input'

import { Colors, Fonts } from '../../styles/theme'
import Text from './Text'
import { NOT_DISPLAY_ERR } from './FormInputs'

interface Props
  extends Omit<
      StrictInputProps,
      | 'error'
      | 'fluid'
      | 'icon'
      | 'iconPosition'
      | 'onChange'
      | 'value'
      | 'label'
    >,
    Omit<
      CurrencyInputProps,
      'size' | 'tabIndex' | 'type' | 'onChange' | 'step' | 'ref'
    >,
    LabelDescriptionProps,
    Omit<
      NumericFormatProps,
      | 'size'
      | 'type'
      | 'tabIndex'
      | 'onValueChange'
      | 'customInput'
      | 'onChange'
      | 'defaultValue'
    >,
    Omit<
      PatternFormatProps,
      | 'customInput'
      | 'defaultValue'
      | 'onValueChange'
      | 'size'
      | 'tabIndex'
      | 'type'
      | 'onChange'
      | 'format'
    > {
  clearable?: boolean
  error?: boolean | string
  fullWidth?: boolean
  leftIcon?: SemanticICONS
  onChange?: (value: string) => void
  onClearClick?: () => void
  onClick?: () => void
  onFocus?: () => void
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
  onLeftIconClick?: () => void
  onRightIconClick?: () => void
  placeholder?: string // placeholder is a prop on the <input> vanilla HTML component
  rightIcon?: SemanticICONS
  value?: string
  name?: string
  readOnly?: boolean
  step?: string | number
  format?: string
  // Be careful when using the `phone` type.  It returns a formatted value of phone number.  Use with `isValidPhoneNumber` or `parsePhoneNumber`
  componentType?:
    | 'phone'
    | 'number'
    | 'currency'
    | 'location'
    | 'date'
    | 'pattern'
  iconColor?: SemanticCOLORS
}

export interface LabelDescriptionProps {
  afterLabel?: ReactNode
  description?: ReactNode
  label?: ReactNode
  required?: boolean
}

export const identificationNumberProps = {
  decimalScale: 0,
  allowNegative: false,
  prefix: '',
  thousandSeparator: false,
  fixedDecimalScale: false,
  allowLeadingZeros: true,
}

export const LabelDescription = styled(
  ({
    afterLabel,
    className,
    description,
    label,
    required,
  }: LabelDescriptionProps & { className?: string }) => (
    <div className={className}>
      {label && (
        <Text as="h3">
          {label}
          {required && <span className="requiredLabel"> *</span>}
          {afterLabel}
        </Text>
      )}
      {description && <Text as="bodySm">{description}</Text>}
    </div>
  )
)({
  '&&&&&': {
    '.requiredLabel': {
      color: Colors.red,
    },

    '> p': {
      marginBottom: 8,
    },
  },
})

export const InputError = styled(
  ({ error, className, name }: Pick<Props, 'error' | 'className' | 'name'>) =>
    (error && typeof error === 'string' && error !== NOT_DISPLAY_ERR && (
      <Text
        as="bodySm"
        style={{ color: Colors.red, marginTop: 8 }}
        className={className}
        testId={name ? `error-${name}` : undefined}
      >
        {error}
      </Text>
    )) ||
    null
)({
  '&&&&&': {
    color: Colors.red,
    marginTop: 8,
  },
})

const StyledInput = styled(
  ({
    className,
    clearable,
    description,
    error,
    fullWidth,
    label,
    leftIcon,
    onChange,
    onClearClick,
    onClick,
    onFocus,
    onLeftIconClick,
    onRightIconClick,
    readOnly,
    required,
    rightIcon,
    value,
    componentType,
    afterLabel,
    allowLeadingZeros,
    iconColor,
    ...rest
  }: Props) => (
    <div className={className}>
      <LabelDescription
        description={description}
        label={label}
        required={required}
        afterLabel={afterLabel}
      />
      {componentType === 'phone' && (
        <PhoneInput
          country="US"
          className="phoneInput"
          onChange={(newVal) => onChange?.(newVal || '')}
          value={value}
          {...rest}
        />
      )}
      {componentType === 'number' && (
        <NumericFormat
          className="numberFormat"
          onValueChange={({ value, floatValue }) =>
            allowLeadingZeros
              ? onChange?.(value?.toString() || '')
              : onChange?.(floatValue?.toString() || '')
          }
          allowLeadingZeros={allowLeadingZeros}
          decimalScale={2}
          fixedDecimalScale
          displayType="input"
          thousandSeparator
          prefix="$"
          type="text"
          value={value}
          {...omit(
            rest,
            'isDateInput',
            'type',
            'size',
            'tabIndex',
            'onValueChange',
            'customInput'
          )}
        />
      )}
      {componentType === 'pattern' && (
        <PatternFormat
          className="patternFormat"
          onValueChange={({ formattedValue }) => onChange?.(formattedValue)}
          displayType="input"
          type="text"
          value={value}
          {...omit(
            rest,
            'isDateInput',
            'type',
            'size',
            'tabIndex',
            'onValueChange',
            'customInput'
          )}
          format={rest.format || ''}
        />
      )}
      {componentType === 'currency' && (
        <CurrencyInput
          className="currencyInput"
          prefix="$"
          value={value}
          onValueChange={(_, __, values) => {
            // Currency input can return `09` as a value.  The 0 needs to be removed however for `0.` the 0 cannot be removed
            onChange?.(
              values?.value === '0.'
                ? values?.value
                : values?.value.replace(/^0+/, '') || '0'
            )
          }}
          allowNegativeValue={rest.allowNegative || rest.allowNegativeValue}
          {...omit(rest, 'isDateInput', 'type', 'size', 'tabIndex', 'step')}
          onBlur={(event) => {
            // On blur change the number to a formatted version
            onChange?.(Number(value).toFixed(2))
            rest.onBlur?.(event)
          }}
          onClick={onClick}
        />
      )}
      {(!componentType ||
        componentType === 'location' ||
        componentType === 'date') && (
        <SemanticInput
          className={value && 'filled'}
          error={Boolean(error)}
          fluid={fullWidth}
          icon
          onChange={(e) => {
            onChange?.(e.target.value)
          }}
          onClick={onClick}
          onFocus={onFocus}
          readOnly={readOnly}
          value={value}
          {...rest}
        >
          {leftIcon && (
            <Icon
              className="icon-left"
              name={leftIcon}
              link={Boolean(onLeftIconClick)}
              onClick={onLeftIconClick}
              color={iconColor || undefined}
            />
          )}
          <input
            {...(value && { className: 'filled' })}
            // autoComplete interferes with suggested location and date so disable
            {...((componentType === 'location' || componentType === 'date') && {
              autoComplete: 'off',
            })}
          />
          {(error || rightIcon) && (
            <Icon
              className="icon-right"
              name={error ? 'exclamation circle' : rightIcon}
              link={Boolean(onRightIconClick)}
              onClick={onRightIconClick}
              color={(!error && iconColor) || undefined}
            />
          )}
          {!isNil(value) && clearable && (
            <Icon
              className="icon-clear"
              link
              onClick={
                onClearClick ||
                (() => {
                  onChange?.('')
                })
              }
              name="close"
            />
          )}
        </SemanticInput>
      )}
      <InputError error={error} name={rest.name} />
    </div>
  )
)(({
  clearable,
  disabled,
  error,
  leftIcon,
  rightIcon,
  value,
  componentType,
  fullWidth,
}) => {
  const isClearableAndFilled = clearable && value
  const hasRightIcon = rightIcon || error

  return {
    '&&&': {
      borderRadius: 8,
      maxWidth: '100%',

      input: {
        backgroundColor: `${error ? Colors.lightRed : Colors.white} !important`,
        border: disabled
          ? 'none'
          : `1px solid ${error ? Colors.red : Colors.gray} !important`,
        opacity: disabled ? 0.45 : undefined,
        borderRadius: 8,
        color: error ? Colors.red : Colors.black,
        padding: '12px 16px !important',
        ...(leftIcon && { paddingLeft: '36px !important' }),
        ...((isClearableAndFilled || hasRightIcon) && {
          paddingRight: `${
            isClearableAndFilled && hasRightIcon ? 60 : 36
          }px !important`,
        }),

        ...Fonts.bodyMd,

        // Getting input and suggestions hover state synced doesn't work well so disable
        ...(componentType !== 'location' && {
          '&:focus, &:hover': {
            borderColor: `${error ? Colors.red : Colors.mediumGray} !important`,
          },
        }),
      },

      'i.icon': {
        color: error ? Colors.red : Colors.mediumGray,
        lineHeight: 1,
        margin: 0,
        position: 'absolute',
        textAlign: 'center',
        top: 0,
        width: 40,

        '&.icon-left': {
          left: 0,
        },

        '&.icon-right': {
          right: clearable && value ? 24 : 0,
        },

        '&.icon-clear': {
          right: 0,
        },
      },

      '.currencyInput, .numberFormat, .phoneInput, .patternFormat': {
        width: fullWidth ? '100%' : 'inherit',
      },
      'input::placeholder': {
        color: Colors.mediumGray,
      },
    },
  }
})

const Input = forwardRef((props: Props, ref) => (
  <StyledInput {...props} ref={ref} />
))

export default Input

export type { Props as InputProps }
