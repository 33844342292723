import {
  Transaction,
  TransactionCategoryIdentifier,
} from '../../../../reducers/admin/allTransactions.slice'

export const sampleTransactionData: Transaction[] = [
  {
    FinancialProfileId: 1,
    accountTransactions: {
      plaidInstitutionName: 'Chase',
      name: 'Plaid Checking',
      mask: '0000',
    },
    adminNotes: null,
    amountInCents: -10000, // -$100.00
    autocategorizedAt: null,
    autocategorizedTransactionRuleId: null,
    confirmedAt: null,
    createdAt: '2024-09-04T06:22:25.841Z',
    date: '2024-09-04',
    deletedAt: null,
    description: 'Gym membership',
    excludedAt: null,
    financialAccountId: 1,
    financialProfileId: 1,
    id: 5,
    importedOn: null,
    manuallyCreated: false,
    manuallyDedupedAt: null,
    notes: null,
    notesLastUpdatedAt: null,
    notifiedRequestedClarificationAt: null,
    plaidTransactionId: '',
    receipts: [],
    reconciliationId: null,
    requestedClarificationAt: null,
    transactionCategory: {
      accountType: '',
      name: '',
      identifier: TransactionCategoryIdentifier.uncategorized,
      isTaxCompliant: false,
    },
    transactionCategoryId: null,
    transactionEdits: [],
    type: null,
    updatedAt: '',
    userId: 3,
  },
  {
    FinancialProfileId: 1,
    accountTransactions: {
      plaidInstitutionName: 'Chase',
      name: 'Plaid Checking',
      mask: '0000',
    },
    adminNotes: null,
    amountInCents: -2995, // -$29.95
    autocategorizedAt: null,
    autocategorizedTransactionRuleId: null,
    confirmedAt: null,
    createdAt: '2024-09-04T06:22:25.841Z',
    date: '2024-09-04',
    deletedAt: null,
    description: 'Psychology Today membership',
    excludedAt: null,
    financialAccountId: 1,
    financialProfileId: 1,
    id: 6,
    importedOn: null,
    manuallyCreated: false,
    manuallyDedupedAt: null,
    notes: null,
    notesLastUpdatedAt: null,
    notifiedRequestedClarificationAt: null,
    plaidTransactionId: '',
    receipts: [],
    reconciliationId: null,
    requestedClarificationAt: null,
    transactionCategory: {
      accountType: '',
      name: '',
      identifier: TransactionCategoryIdentifier.uncategorized,
      isTaxCompliant: false,
    },
    transactionCategoryId: null,
    transactionEdits: [],
    type: null,
    updatedAt: '',
    userId: 3,
  },
  {
    FinancialProfileId: 1,
    accountTransactions: {
      plaidInstitutionName: 'Chase',
      name: 'Plaid Checking',
      mask: '0000',
    },
    adminNotes: null,
    amountInCents: 15000, // $150.00
    autocategorizedAt: null,
    autocategorizedTransactionRuleId: null,
    confirmedAt: null,
    createdAt: '2024-09-04T06:22:25.841Z',
    date: '2024-09-04',
    deletedAt: null,
    description: 'Therapy session payment',
    excludedAt: null,
    financialAccountId: 1,
    financialProfileId: 1,
    id: 7,
    importedOn: null,
    manuallyCreated: false,
    manuallyDedupedAt: null,
    notes: null,
    notesLastUpdatedAt: null,
    notifiedRequestedClarificationAt: null,
    plaidTransactionId: '',
    receipts: [],
    reconciliationId: null,
    requestedClarificationAt: null,
    transactionCategory: {
      accountType: '',
      name: '',
      identifier: TransactionCategoryIdentifier.uncategorized,
      isTaxCompliant: false,
    },
    transactionCategoryId: null,
    transactionEdits: [],
    type: null,
    updatedAt: '',
    userId: 3,
  },
]
