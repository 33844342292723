// UI
import { Dropdown } from 'semantic-ui-react'

// BL
import { UserDocument } from '../../userDocuments.slice'
import { actionButton as copy } from '../../copyConstants'
import { useFeatureDispatch, openDeleteConfirm } from '../../state'

interface UserDocumentActionButtonProps {
  document: UserDocument
}

const UserDocumentActionButton = ({
  document,
}: UserDocumentActionButtonProps) => {
  const dispatch = useFeatureDispatch()

  const openConfirm = () => {
    dispatch?.(openDeleteConfirm(document))
  }

  return (
    <Dropdown
      className="action-button"
      direction="left"
      icon="ellipsis vertical"
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={openConfirm}
          text={copy.static.deleteMenuItemText}
        />
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default UserDocumentActionButton
