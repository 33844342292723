import React from 'react'

// BL
import { TransactionRowModel } from '../shared'

// UI
import { Table } from 'semantic-ui-react'
import PanelTableRow from './PanelTableRow'

interface Props {
  datasource: TransactionRowModel[]
  onDeleteClicked: (t: TransactionRowModel) => Promise<void>
  onRowChecked: (t: TransactionRowModel) => Promise<void>
}

const PanelTable: React.FC<Props> = ({
  datasource,
  onDeleteClicked,
  onRowChecked,
}) => {
  return (
    <Table className="mute" singleLine padded="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Manual</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell>Debit</Table.HeaderCell>
          <Table.HeaderCell>Credit</Table.HeaderCell>
          <Table.HeaderCell>Matched</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {datasource.map((t) => (
          <PanelTableRow
            key={`reconciliation-row-${t.id}`}
            transaction={t}
            onDeleteClicked={onDeleteClicked}
            onCheckButtonClicked={onRowChecked}
          />
        ))}
      </Table.Body>
    </Table>
  )
}

export default PanelTable
