export const FORM_1040_TYPE = 'form_1040'
export const FORM_1120_S_TYPE = 'form_1120_s'

export enum TaxFormType {
  form1040 = 'form1040',
  form1120s = 'form1120s',
}

export const ANNUAL_TAX_FILING_FORM_TYPES = {
  form_1040: 'form_1040',
  form_1120: 'form_1120',
  form_1120_s: 'form_1120_s',
  form_1065: 'form_1065',
  schedule_c: 'schedule_c',
}

export const QUESTION_CATEGORY_TYPES = {
  changes: 'changes',
  income: 'income',
  retirement: 'retirement',
  misc: 'misc',
  about_your_scorp: 'about_your_scorp',
}

export const QUESTION_FIELD_TYPES = {
  option_select: 'option_select',
  date_input: 'date_input',
  text_input: 'text_input',
  file_upload: 'file_upload',
  radio_group: 'radio_group',
  checkbox_group: 'checkbox_group',
  custom: 'custom',
  binary_select: 'binary_select',
  currency_input: 'currency_input',
  address_input: 'address_input',
}
