import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Grid } from 'semantic-ui-react'
import { useFormik, FormikProvider } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import '../runPayroll.scss'

import {
  fetchEmployees,
  fetchPaymentConfigs,
  PUT_CREATE_OFF_CYCLE_PAYROLL_KEY,
  putCreateOffCyclePayroll,
} from '../../payrollActions'
import { getMinPayDateFromConfig, PAYROLL_STEPS } from '../../helpers'
import {
  Button,
  Card,
  FormikDateInput,
  FormikDropdown,
  getFieldName,
  GridRowColumn,
  Icon,
  Link,
  makeDateSchema,
  makeReqStringSchema,
  ProgressBar,
  Text,
} from '../../../../components/BaseComponents'
import PayrollError from '../../PayrollError'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useFetchResponse, useReselector } from '../../../../utils/sharedHooks'
import { selectActiveOnboardedEmployees } from '../../payroll.selectors'

const validationSchema = yup.object({
  start_date: makeDateSchema({ field: 'start date' }),
  end_date: makeDateSchema({ field: 'end date' }).test(
    'Date Test',
    'Please enter an end date that occurs after the start date',
    function (end_date) {
      const start_date = this.parent['start_date']
      return moment(start_date, DATE_FORMATS.INPUT).isSameOrBefore(
        moment(end_date, DATE_FORMATS.INPUT)
      )
    }
  ),
  check_date: makeDateSchema({ field: 'check date', end: 'for payment' }),
  reason: makeReqStringSchema({ field: 'reason' }),
})

const OffCyclePayroll = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)
  const employees = useReselector(selectActiveOnboardedEmployees)
  const paymentConfig = useFetchResponse(fetchPaymentConfigs)
  const minPayDate = getMinPayDateFromConfig(paymentConfig?.payment_speed)

  const formik = useFormik({
    initialValues: {
      start_date: '',
      end_date: '',
      check_date: '',
      reason: 'Correction' as 'Correction' | 'Bonus',
    },
    validationSchema,
    onSubmit: async (values) => {
      setSubmitting(true)
      const res = await putCreateOffCyclePayroll({
        start_date: moment(values.start_date, DATE_FORMATS.INPUT).format(
          DATE_FORMATS.GUSTO_SUBMIT
        ),
        end_date: moment(values.end_date, DATE_FORMATS.INPUT).format(
          DATE_FORMATS.GUSTO_SUBMIT
        ),
        check_date: moment(values.check_date, DATE_FORMATS.INPUT).format(
          DATE_FORMATS.GUSTO_SUBMIT
        ),
        off_cycle_reason: values.reason,
        employee_uuids: employees.map((emp) => emp.uuid),
        off_cycle: true,
      })(dispatch)
      setSubmitting(false)
      if (res) {
        navigate(
          `/payroll/run_payroll/process/${res.payroll_uuid}?isOffCycle=true`
        )
      }
    },
  })

  useEffect(() => {
    dispatch(fetchEmployees())
  }, [dispatch])

  return (
    <Container id="run-payroll">
      <Card>
        <Grid>
          <GridRowColumn>
            <Text as="h2">Run Off-cycle Payroll</Text>
          </GridRowColumn>
          <GridRowColumn>
            <ProgressBar currentStep={0} steps={PAYROLL_STEPS} />
          </GridRowColumn>
          <PayrollError fetchKey={PUT_CREATE_OFF_CYCLE_PAYROLL_KEY} />
          <GridRowColumn>
            <FormikProvider value={formik}>
              <Grid>
                <GridRowColumn>
                  <div className="payroll-info-wrapper">
                    <Text>
                      <Icon
                        icon={light('circle-plus')}
                        style={{ marginRight: 8 }}
                      />
                      Are you sure you need an off-cycle payroll? Here are some
                      use cases where you should use this.{' '}
                      <Link
                        href="https://support.joinheard.com/hc/en-us/articles/7256827466135"
                        newPage
                      >
                        Learn here
                      </Link>
                    </Text>
                  </div>
                </GridRowColumn>
                <GridRowColumn>
                  <Text as="h1">Basics</Text>
                </GridRowColumn>
                <GridRowColumn>
                  <Text as="h3">Work Period</Text>
                </GridRowColumn>
                <GridRowColumn short>
                  <Text>
                    Enter a work period to show on your employee&apos;s pay
                    stub. This is needed to accurately file your taxes.
                  </Text>
                </GridRowColumn>
                <Grid.Row className="short" verticalAlign="middle">
                  <Grid.Column width={4}>
                    <FormikDateInput
                      required
                      name={getFieldName<typeof formik.values>('start_date')}
                      style={{ marginBottom: 0 }}
                      fullWidth
                    />
                  </Grid.Column>
                  <Grid.Column width={1} textAlign="center">
                    <Text>—</Text>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <FormikDateInput
                      required
                      name={getFieldName<typeof formik.values>('end_date')}
                      fullWidth
                    />
                  </Grid.Column>
                </Grid.Row>

                <GridRowColumn>
                  <FormikDateInput
                    required
                    name={getFieldName<typeof formik.values>('check_date')}
                    label="Payment Date"
                    description="Enter the date you’d like your employees to receive payment."
                    minDate={minPayDate.toDate()}
                  />
                </GridRowColumn>
                <GridRowColumn>
                  <FormikDropdown
                    name={getFieldName<typeof formik.values>('reason')}
                    required
                    optionValues={['Bonus', 'Correction']}
                    label="Off-Cycle Payroll Reason"
                  />
                </GridRowColumn>
                <Grid.Row />
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Button
                      onClick={() => navigate('/payroll/run_payroll')}
                      variant="secondary"
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </Grid.Column>
                  <Grid.Column width={8} />
                  <Grid.Column width={4}>
                    <Button
                      onClick={formik.submitForm}
                      fullWidth
                      loading={submitting}
                    >
                      Continue{' '}
                      <Icon
                        icon={light('arrow-right')}
                        style={{ marginLeft: 8 }}
                      />
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </FormikProvider>
          </GridRowColumn>
        </Grid>
      </Card>
    </Container>
  )
}

export default OffCyclePayroll
