import { Grid, Image } from 'semantic-ui-react'
import { GridRowColumn, Text } from '../../../../../components/BaseComponents'
import { Form1040DetailsProps } from '../../TaxChecklist/Form1040/Details'
import { EXTENSION_REQUEST_SCREENS } from '.'
import { makeGridConfig } from '../../../../../components/BaseComponents/Grid'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import { useNavigateWithLocation } from '../../../../../utils/routeHelpers'
import {
  NO_STATE_QUARTERLY_TAX_ESTIMATES,
  TAX_ENTITY_TYPES,
} from '../../../taxConstants'
import { ReviewSubSectionForFormIds } from '../../TaxChecklist/Shared/ReviewSubSection'
import { estimatedTaxQuestionIds } from './EstimateTaxes'
import { useMemo } from 'react'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../utils/sharedHooks'
import { getEstimatesByYear } from '../../../QuarterlyTaxEstimates/userTaxEstimates.selector'
import { formatCurrencyFromCents } from '../../../../../utils/currencyHelpers'
import { filingStatusQuestionIds } from '../../TaxChecklist/Form1040/Details/FilingStatusPanel'
import { locationInfoQuestionIds } from '../../TaxChecklist/Form1040/Details/LocationInfo'
import { spouseQuestionIds } from '../../TaxChecklist/Form1040/Details/SpousePanel'
import {
  getPersonalQuestionIds,
  getBusinessQuestionIds,
} from '../../TaxChecklist/Shared/PersonalDetails/CheckYourDetailsPanel'
import {
  selectSortedTaxReturnDocumentsForYear,
  selectUserTaxDocumentsForCategory,
} from '../../../../UserDocuments/userDocuments.selector'
import { submitExtensionRequest } from '../../TaxChecklist/taxChecklistQuestion.actions'
import { getAnnualTaxFilingForYearSelector } from '../../annualTaxFilings.selector'
import { getListOfDocumentQuestionsAndResponses } from '../helpers'
import {
  select1120sFormForYear,
  select1040FormForYear,
} from '../../annualTaxFilingForms.selector'
import { markUserActionItemCompleteIfExists } from '../../../../Dashboard/UserActionItems/service'
import { UserActionItemActionItemIdentifiers } from '../../../../Dashboard/UserActionItems/userActionItems.slice'

import { splitTaxYearQuarter } from '../../../QuarterlyTaxEstimates/helpers'
import { UserDocumentCategoryIdentifier } from '../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { useAppDispatch } from '../../../../../utils/typeHelpers'

const ReviewPanel = ({
  goBack,
  previousScreen,
  taxYear,
  isReadOnly,
}: Form1040DetailsProps) => {
  const navigate = useNavigateWithLocation()
  const dispatch = useAppDispatch()
  const estimates = useReselector(getEstimatesByYear, taxYear)
  const annualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    taxYear
  )
  const filingForm1120s = useReselector(select1120sFormForYear, taxYear)
  const filingForm1040 = useReselector(select1040FormForYear, taxYear)
  const formId1040 = String(filingForm1040?.id)
  const formId1120s = String(filingForm1120s?.id)

  const estimatesQuestionsAndResponses = useMemo(() => {
    const quarterEstimatedTaxQuestionAndResponse = []
    for (const estimate of estimates) {
      if (
        estimate.filingState &&
        NO_STATE_QUARTERLY_TAX_ESTIMATES.includes(estimate.filingState)
      ) {
        continue
      }
      const typeText = estimate.filingState ?? 'Federal'
      const { quarter: quarterString } = splitTaxYearQuarter(
        estimate.taxQuarter
      )

      const quarter = `Q${quarterString}`
      quarterEstimatedTaxQuestionAndResponse.push({
        questionId: `quarter_${estimate.taxQuarter}_${typeText}_estimated_tax`,
        question: `${quarter} ${typeText} Tax Payment Amount `,
        response: estimate.quarterPaid
          ? formatCurrencyFromCents(estimate.amountPaidInCents)
          : 'I did not make a payment this quarter',
      })
    }
    return quarterEstimatedTaxQuestionAndResponse
  }, [estimates])

  const prevYearBizReturns = useReselector(
    selectSortedTaxReturnDocumentsForYear,
    (Number(taxYear) - 1).toString()
  ).business
  const prevYearPersonalReturns = useReselector(
    selectSortedTaxReturnDocumentsForYear,
    (Number(taxYear) - 1).toString()
  ).personal
  const govIds = useReselector(
    selectUserTaxDocumentsForCategory,
    UserDocumentCategoryIdentifier.officialGovernmentId,
    taxYear
  )
  const spouseGovIds = useReselector(
    selectUserTaxDocumentsForCategory,
    UserDocumentCategoryIdentifier.spouseOfficialGovernmentId,
    taxYear
  )

  const documentQuestionsAndResponses = useMemo(() => {
    return getListOfDocumentQuestionsAndResponses({
      taxYear,
      prevYearPersonalReturns,
      govIds,
      spouseGovIds,
      prevYearBizReturns,
    })
  }, [
    govIds,
    prevYearBizReturns,
    prevYearPersonalReturns,
    spouseGovIds,
    taxYear,
  ])

  const submitExtensionRequestAndNavigate = useAsyncCallback(async () => {
    const success = await submitExtensionRequest(annualTaxFiling?.id)(dispatch)
    if (success) {
      // there is no 1040 action item for combined extensions
      await markUserActionItemCompleteIfExists(
        UserActionItemActionItemIdentifiers.submitExtension1120s({
          year: taxYear,
        })
      )
      navigate('/taxes/annual')
    }
  })

  return (
    <Grid>
      <GridRowColumn
        columnStyle={{ display: 'flex', justifyContent: 'center' }}
      >
        <Image src="https://heard-images.s3.amazonaws.com/assets/magnifying-glass-checkmark.svg" />
      </GridRowColumn>
      <GridRowColumn>
        <Text as="display2" textAlign="center">
          Review
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([8, 14], true)}>
        <Text as="bodyLg" textAlign="center">
          {isReadOnly
            ? 'You can no longer edit your Extension Request responses, but you can view them anytime.'
            : 'Review your answers for accuracy. Click edit to go back to the previous section.'}
        </Text>
      </GridRowColumn>
      <Grid.Row />
      <ReviewSubSectionForFormIds
        title="personal details"
        questionIds={getPersonalQuestionIds(TAX_ENTITY_TYPES.form_1120_s)}
        editScreen={EXTENSION_REQUEST_SCREENS.confirmDetails}
        formIds={formId1120s}
        readOnly={isReadOnly}
      />
      <ReviewSubSectionForFormIds
        title="business details"
        questionIds={getBusinessQuestionIds(TAX_ENTITY_TYPES.form_1120_s)}
        editScreen={EXTENSION_REQUEST_SCREENS.confirmDetails}
        formIds={formId1120s}
        readOnly={isReadOnly}
      />
      <ReviewSubSectionForFormIds
        title="filing status"
        questionIds={filingStatusQuestionIds}
        editScreen={EXTENSION_REQUEST_SCREENS.filingStatus}
        formIds={formId1040}
        readOnly={isReadOnly}
      />
      <ReviewSubSectionForFormIds
        title="your spouse"
        questionIds={spouseQuestionIds}
        editScreen={EXTENSION_REQUEST_SCREENS.spouse}
        formIds={formId1040}
        readOnly={isReadOnly}
      />
      <ReviewSubSectionForFormIds
        title="Update location information"
        questionIds={locationInfoQuestionIds}
        editScreen={EXTENSION_REQUEST_SCREENS.locationInfo}
        formIds={formId1040}
        readOnly={isReadOnly}
      />
      <ReviewSubSectionForFormIds
        title="quarterly tax payments"
        questionIds={[]}
        additionalInfo={estimatesQuestionsAndResponses}
        editScreen={EXTENSION_REQUEST_SCREENS.estimateTaxQuarterPayments}
        formIds={formId1040}
        readOnly={isReadOnly}
      />
      <ReviewSubSectionForFormIds
        title="estimate taxes"
        questionIds={estimatedTaxQuestionIds}
        additionalInfo={documentQuestionsAndResponses}
        editScreen={EXTENSION_REQUEST_SCREENS.estimateTax}
        formIds={[formId1040, formId1120s]}
        readOnly={isReadOnly}
      />
      {!isReadOnly && (
        <FormFlowFooter
          isSubmit
          continueDisabled={submitExtensionRequestAndNavigate.loading}
          loading={submitExtensionRequestAndNavigate.loading}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitExtensionRequestAndNavigate.callback}
        />
      )}
    </Grid>
  )
}

export default ReviewPanel
