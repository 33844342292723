import axios from 'axios'
import { keyBy } from 'lodash'

import { fetchIfNeededWrapper } from '../../reducers/fetch'
import { receiveUserPlaidItems } from '../../reducers/admin/plaidItemReducer'
import { AdminPlaidItem } from '../../reducers/finances/plaidItemReducer'

export const REQUEST_USER_PLAID_ITEMS_KEY = (userId: number | string) =>
  `REQUEST_USER_PLAID_ITEMS_KEY_${userId}`

export const fetchUserPlaidItems = (
  userId: number | string,
  alwaysFetch = false
) =>
  fetchIfNeededWrapper({
    alwaysFetch,
    fetchKey: REQUEST_USER_PLAID_ITEMS_KEY(userId),
    defaultErrorMessage: 'There was an error fetching financial accounts',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<AdminPlaidItem[]>(
        `/finances/api/v1/admin/plaid/user_items/${userId}`
      )
      dispatch(
        receiveUserPlaidItems({
          plaidItems: keyBy(json.data, 'id'),
          userId: Number(userId),
        })
      )
      return true
    },
  })
