import { useState, useCallback } from 'react'
import { debounce, escapeRegExp, reduce } from 'lodash'
import { Search } from 'semantic-ui-react'

import './UserRecordSearch.scss'
import { logSentryMessage } from '../../../utils/sentryHelpers'
import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import { User } from '../../../reducers/auth/userReducer'
import { filterNulls } from '../../../utils/typeHelpers'
import { useReselector } from '../../../utils/sharedHooks'
import { getUserById } from '../../../selectors/user.selectors'
import { Text } from '../../BaseComponents'

export interface UserSearchResult {
  id: number
  description: string
  title: string
  type: 'user' | 'manager' | 'bookkeeper'
  data: string[]
}

export type UserSearchData = {
  [p: string]: { name: string; results: UserSearchResult[] }
}

const UserSearchResultView = ({ id }: { id?: string }) => {
  const user = useReselector(getUserById, id)

  if (!user) {
    return null
  }
  return (
    <>
      <Text as="bodySm">
        <b>{user.id}</b> - {user.firstName} {user.lastName}
      </Text>
      <Text as="bodySm" color="darkGray">
        <i>{user.email}</i>
      </Text>
      {user.financialProfile?.einNumber && (
        <Text as="bodySm" color="darkGray">
          Ein - {user.financialProfile.einNumber}
        </Text>
      )}
      {user.financialProfile?.businessName && (
        <Text as="bodySm" color="darkGray">
          Business Name - {user.financialProfile.businessName}
        </Text>
      )}
    </>
  )
}

const filterResults = (results: UserSearchResult[], searchVal: string) => {
  const reg = new RegExp(escapeRegExp(searchVal), 'i')

  return results.filter((result) =>
    result.data.some((stringVal) => reg.test(stringVal))
  )
}

export const UserRecordSearch = ({
  datasource,
  onResultSelectedCallback,
}: {
  datasource: UserSearchData
  onResultSelectedCallback: (_: UserSearchResult | null) => void
}) => {
  const [results, setResults] = useState<UserSearchData>({})
  const [value, setValue] = useState('')

  const MIN_CHARACTERS_REQUIRED = 3

  const onSearchChanged = useCallback(
    (_: unknown, { value: newValue }: { value?: string }) => {
      setValue(newValue || '')

      if (!newValue || newValue.length < MIN_CHARACTERS_REQUIRED) {
        return
      }

      // Returns an array of matching results
      setResults(
        reduce<UserSearchData, UserSearchData>(
          datasource,
          (memo, data, name) => {
            const results = filterResults(data.results, newValue)

            if (results.length) {
              memo[name] = { name, results }
            }

            return memo
          },
          {}
        )
      )
    },
    [datasource]
  )

  const onResultSelected = useCallback(
    (e: unknown, { result }: { result: UserSearchResult | null }) => {
      if (result) {
        setValue(`${result.title} (${result.type})`)
      }
      onResultSelectedCallback(result)
    },
    [onResultSelectedCallback]
  )

  return (
    <Search
      category
      onResultSelect={onResultSelected}
      onSearchChange={debounce(onSearchChanged, 500, {
        leading: true,
      })}
      results={results}
      value={value}
      aligned="right"
      placeholder="Filter by user, account owner, or bookkeeper"
      minCharacters={MIN_CHARACTERS_REQUIRED}
      resultRenderer={(val) => {
        // Semantic UI reindexes results but the description is also the user id so use that
        return <UserSearchResultView id={val.description} />
      }}
    />
  )
}

interface SearchHandlers {
  getResultTitle: (_: string, __: number) => string
  getUserIds: (_: UserSearchResult, __: UserWithAdminInfo[]) => number[]
}

// Separates a search item's content from its logic
// Addresses a previous React component property warning
export class UserRecordSearchManager {
  _datasource: UserSearchData
  _handlers: { [key: string]: SearchHandlers }

  constructor(
    data: Array<{
      name: string
      values: UserSearchResult[]
      fns: SearchHandlers
    }>
  ) {
    this._datasource = {}
    this._handlers = {}
    data.forEach((d) => {
      this._datasource[d.name] = {
        name: d.name,
        results: d.values,
      }

      if (d.values.length > 0) {
        this._handlers[d.values[0].type] = d.fns
      } else {
        logSentryMessage(`Datasource category "${d.name}" has no values`)
      }
    })
  }

  get handlers() {
    return this._handlers
  }

  get datasource() {
    return this._datasource
  }
}

// anything added here will be included in the search
const transformUserIntoSearchData = (user: User) =>
  filterNulls([
    user.id.toString(),
    user.email,
    `${user.firstName} ${user.lastName}`,
    user.financialProfile?.businessName,
    user.financialProfile?.einNumber,
  ])

export const createSearchManager = ({
  bookkeepersArray,
  allUsers,
}: {
  bookkeepersArray: UserWithAdminInfo[]
  allUsers: UserWithAdminInfo[]
}) =>
  new UserRecordSearchManager([
    {
      name: 'Users',
      values: allUsers.map((u) => ({
        id: u.id,
        description: u.id.toString() ?? '',
        title: `${u.firstName} ${u.lastName}`,
        type: 'user' as const,
        data: transformUserIntoSearchData(u),
      })),
      fns: {
        getResultTitle: () => '1 User',
        getUserIds: (item: UserSearchResult) => [item.id],
      },
    },
    {
      name: 'Bookkeepers',
      values: bookkeepersArray.map((bookkeeper) => ({
        id: bookkeeper.id,
        description: bookkeeper.id.toString(),
        title: `${bookkeeper.firstName} ${bookkeeper.lastName}`,
        type: 'bookkeeper' as const,
        data: transformUserIntoSearchData(bookkeeper),
      })),
      fns: {
        getResultTitle: (title: string, count: number) =>
          `${title}'s users (${count})`,
        getUserIds: (item: UserSearchResult, users: UserWithAdminInfo[]) =>
          Object.values(users)
            .filter((u) => u.bookkeeperId === item.id)
            .map((u) => u.id),
      },
    },
  ])
