import { Moment } from 'moment'
import { uploadFile } from '../utils/aws'
import { adminCreateUserDocument } from './admin/userDocumentsActions'
import { createSingleUserDocument } from '../features/UserDocuments/userDocuments.slice'
import { Dispatch } from '../utils/typeHelpers'

export interface UploadUserDocumentPayload {
  file: File
  user: { uuid: string; id: number }
  transactionId?: number
  documentType: string
  statementMonth?: Moment
  statementFinancialAccountId?: number
  userDocumentCategoryId?: number | null
  customerDescription?: string
  annualTaxFilingFormId?: number
  annualTaxFormTypeId?: number
  year?: string
  statement?: {
    statementMonth: string
    financialAccountIds: number[]
  }
}

export interface UploadError {
  error: string
}

const generateAwsFileName = (userUuid: string, fileName: string) =>
  `${userUuid}_${crypto.randomUUID()}_${fileName}`

// Admin Action
export const uploadDocument =
  ({
    file,
    user,
    transactionId,
    documentType,
    statementMonth,
    statementFinancialAccountId,
    userDocumentCategoryId,
    statement,
    year,
  }: UploadUserDocumentPayload) =>
  async (dispatch: Dispatch) => {
    try {
      const fileName = generateAwsFileName(user.uuid, file.name)
      const uploadError = await uploadFile(file, fileName)
      if (uploadError?.error) return uploadError
      return dispatch(
        adminCreateUserDocument({
          awsFileName: fileName,
          fileDescription: file.name,
          userId: user.id,
          transactionId,
          documentType,
          statementMonth: statementMonth || null,
          statementFinancialAccountId: statementFinancialAccountId || null,
          userDocumentCategoryId: userDocumentCategoryId || null,
          statement,
          year: year ? year.toString() : undefined,
        })
      )
    } catch (_) {
      return { error: 'Error uploading file.' }
    }
  }

// User action
export const userUploadDocument =
  ({
    file,
    user,
    transactionId,
    documentType,
    statementMonth,
    statementFinancialAccountId,
    userDocumentCategoryId,
    customerDescription,
    annualTaxFilingFormId,
    annualTaxFormTypeId,
    year,
    statement,
  }: UploadUserDocumentPayload) =>
  async (dispatch: Dispatch) => {
    try {
      const fileName = generateAwsFileName(user.uuid, file.name)
      const uploadError = await uploadFile(file, fileName)
      if (uploadError?.error) return uploadError
      return dispatch(
        createSingleUserDocument({
          awsFileName: fileName,
          fileDescription: file.name,
          transactionId: transactionId || null,
          documentType,
          statementMonth: statementMonth || null,
          statementFinancialAccountId: statementFinancialAccountId || null,
          userDocumentCategoryId: userDocumentCategoryId || null,
          customerDescription: customerDescription || null,
          annualTaxFilingFormId: annualTaxFilingFormId || null,
          annualTaxFormTypeId: annualTaxFormTypeId || null,
          bookkeepingReportId: null,
          year,
          statement,
        })
      )
    } catch (_) {
      return { error: 'Error uploading file.' }
    }
  }
