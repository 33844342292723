/**
 * Tax Terms Card: Used to house the logic and terminology for 2024 late joiners for the 2023 tax season.
 *
 * This has various logic branches, detailed in this PRD
 *
 *
 */

import { DateTime } from 'luxon'
import { Card, Link, Text } from '../../../components/BaseComponents'
import { selectTaxEntityPracticeTypeDescriptor } from '../../../selectors/user.selectors'
import { useReselector } from '../../../utils/sharedHooks'
import {
  TAX_ENTITY_TYPES,
  TAX_ENTITY_TYPES_TYPE,
} from '../../Taxes/taxConstants'
import currency from 'currency.js'
import { formatCurrency } from '../../../utils/currencyHelpers'
import { selectCurrentAnnualTaxDetails } from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { convertUtcToLocalDate } from '../../../utils/dateHelpers'
import { CATCHUP_BOOKKEEPING_PRICE_PER_MONTH } from '../../../constants/pricingConstants'

const TaxTerms = ({
  taxEntityType,
  currentTaxYear,
}: {
  taxEntityType?: TAX_ENTITY_TYPES_TYPE | null
  currentTaxYear: string
}) => {
  const today = DateTime.now()
  const groupName = useReselector(selectTaxEntityPracticeTypeDescriptor)
  const sharedCancellationPolicyText = `Heard's ${groupName} plan is offered on an annual subscription basis only. By selecting Heard's ${groupName} plan you acknowledge and agree that you are signing up for a full year’s subscription and will not be entitled to any refund or cancellation of fees owed to us should you choose to terminate your subscription early.`

  const catchupBookkeepingCostPerMonth = CATCHUP_BOOKKEEPING_PRICE_PER_MONTH

  const bookkeepingText = catchupBookkeepingCostPerMonth ? (
    <>
      Upload documents for 12 months of catchup bookkeeping, which will{' '}
      <b>
        incur a fee of{' '}
        {formatCurrency(currency(catchupBookkeepingCostPerMonth).multiply(12), {
          hideDecimalsIfZero: true,
        })}{' '}
      </b>
      (
      {formatCurrency(catchupBookkeepingCostPerMonth, {
        hideDecimalsIfZero: true,
      })}
      /mo)
    </>
  ) : (
    'Upload documents for catchup bookkeeping'
  )

  const activeDetails = useReselector(selectCurrentAnnualTaxDetails)

  if (!activeDetails?.taxQuestionnaireDueDates) {
    return null
  }

  const form1040LateJoinerOnTimeCutoffDate = convertUtcToLocalDate(
    activeDetails.taxQuestionnaireDueDates.form_1040.lateJoinerOnTimeCutoffDate
  ).endOf('day')

  const form1040LateJoinerAutomaticExtensionCutoffDate = convertUtcToLocalDate(
    activeDetails.taxQuestionnaireDueDates.form_1040
      .lateJoinerAutomaticExtensionCutoffDate
  ).endOf('day')

  const form1040LateJoinerFinalCutoffDate = convertUtcToLocalDate(
    activeDetails.taxQuestionnaireDueDates.form_1040.lateJoinerFinalCutoffDate
  ).endOf('day')

  const form1120sLateJoinerOnTimeCutoffDate = convertUtcToLocalDate(
    activeDetails.taxQuestionnaireDueDates.form_1120_s
      .lateJoinerOnTimeCutoffDate
  ).endOf('day')

  const form1120sLateJoinerFinalCutoffDate = convertUtcToLocalDate(
    activeDetails.taxQuestionnaireDueDates.form_1120_s.lateJoinerFinalCutoffDate
  ).endOf('day')

  switch (taxEntityType) {
    case TAX_ENTITY_TYPES.form_1040:
      if (today <= form1040LateJoinerOnTimeCutoffDate) {
        return (
          <Card type="subsection" backgroundColor="stone">
            <div className={'scrollable'}>
              <Text as="h2">{currentTaxYear} Tax Preparation Terms</Text>
              <Text>
                <ol>
                  <li>
                    You must upload the following documents by{' '}
                    <b>
                      February 15,
                      {Number(currentTaxYear) + 1}.
                    </b>
                  </li>
                  <ul>
                    <li>
                      Bank statements for all business accounts used in{' '}
                      {currentTaxYear}
                    </li>
                    <li>Income and merchant fees reports</li>
                    <li>Form W-3 (if applicable)</li>
                    <li>Contractor payments reports (if applicable)</li>
                  </ul>
                  <br />
                  <li>
                    You must provide information around your business
                    transactions, income, and expenses by{' '}
                    <b>March 15, {Number(currentTaxYear) + 1}.</b>
                  </li>
                  <br />
                  <li>
                    You must provide all required information or documents
                    needed to prepare your taxes by{' '}
                    <b>April 1, {Number(currentTaxYear) + 1}.</b>
                  </li>
                  <br />
                  <li>
                    Your completion of the above tasks determines whether your
                    taxes are filed on time.
                    <br />
                    If you don’t complete these tasks on time, an extension will
                    be filed for you.
                  </li>
                  <br />
                  <li>
                    You may still be responsible for the “failure to pay”
                    penalty and interest.
                    <br />
                    This penalty may still apply if you underpaid your{' '}
                    {Number(currentTaxYear)}
                    quarterly tax payments.{' '}
                    <Link
                      href="https://support.joinheard.com/hc/en-us/articles/4416196562967-What-if-I-didn-t-pay-my-quarterly-estimates-for-prior-quarters-this-year-"
                      newPage
                    >
                      Learn more.
                    </Link>
                  </li>
                  <br />
                  <li>
                    You agree to an annual term for your subscription.
                    <br />
                    {sharedCancellationPolicyText}
                  </li>
                </ol>
              </Text>
            </div>
          </Card>
        )
      } else if (today <= form1040LateJoinerAutomaticExtensionCutoffDate) {
        return (
          <Card type="subsection" backgroundColor="stone">
            <div className={'scrollable'}>
              <Text as="h2">{currentTaxYear} Tax Preparation Terms</Text>
              <Text>
                <ol>
                  <li>
                    You may still be responsible for some interest and
                    penalties.
                    <br />
                    We will automatically file an extension for you to avoid the
                    “failure to file” penalty, but a{' '}
                    <u>
                      “failure to pay” penalty may still apply if you underpaid
                      your {currentTaxYear} quarterly tax payments
                    </u>
                    .
                    <Link href={'www.google.com'} newPage>
                      Learn more.
                    </Link>
                  </li>
                  <br />
                  <li>
                    You must complete all of the below ASAP to avoid the
                    “failure to file” penalty and interest.
                  </li>

                  <ul>
                    <li>Fill out your extension request survey</li>
                    <li>{bookkeepingText}</li>
                    <li>Complete your Tax Checklist</li>
                  </ul>
                  <br />

                  <li>
                    You agree to an annual term for your subscription.
                    <br />
                    {sharedCancellationPolicyText}
                  </li>
                </ol>
              </Text>
            </div>
          </Card>
        )
      } else if (today <= form1040LateJoinerFinalCutoffDate) {
        return (
          <Card type="subsection" backgroundColor="stone">
            <div className={'scrollable'}>
              <Text as="h2">{currentTaxYear} Tax Preparation Terms</Text>
              <Text as="h3">
                <ol>
                  <li>
                    Heard can file your {currentTaxYear} annual taxes for you,
                    but if you haven’t already filed an extension for your
                    taxes, you may incur “failure to file” penalties and
                    interest.
                    <br />
                    <br />
                    You must complete all of the below ASAP to avoid additional
                    accruing interest.
                    <br />
                    <br />
                    <ul>
                      <li>{bookkeepingText}</li>
                      <li>Complete your Tax Checklist</li>
                    </ul>
                    <br />
                    The longer it takes to complete the required steps, the more
                    interest you’ll pay.
                  </li>
                  <br />
                  <br />
                  <li>
                    You agree to an annual term for your subscription.
                    <br />
                    {sharedCancellationPolicyText}
                  </li>
                </ol>
              </Text>
            </div>
          </Card>
        )
      } else {
        return null
      }

    case TAX_ENTITY_TYPES.form_1120_s:
      if (today <= form1120sLateJoinerOnTimeCutoffDate) {
        return (
          <Card type="subsection" backgroundColor="stone">
            <div className={'scrollable'}>
              <Text as="h2">{currentTaxYear} Tax Preparation Terms</Text>
              <Text>
                Your tax return will be automatically extended, to enable more
                time for preparation.
              </Text>
              <Text>
                <ol>
                  <li>
                    You must have a payroll system in place.
                    <br />
                    If you don’t have one already, you can enroll in Heard
                    Payroll.
                  </li>
                  <br />

                  <li>
                    You must complete the extension request survey by March 1,
                    {Number(currentTaxYear) + 1}.
                    <br />
                    This helps you to avoid a “failure to file” penalty.
                  </li>
                  <br />
                  <li>
                    You must complete all of the below ASAP to avoid additional
                    accruing interest.
                  </li>
                  <ul>
                    <li>Fill out your extension request survey</li>
                    <li>{bookkeepingText}</li>
                    <li>Complete your Tax Checklist</li>
                  </ul>
                  <br />
                  <li>
                    You agree to an annual term for your subscription.
                    <br />
                    {sharedCancellationPolicyText}
                  </li>
                </ol>
              </Text>
            </div>
          </Card>
        )
      } else if (today <= form1120sLateJoinerFinalCutoffDate) {
        return (
          <Card type="subsection" backgroundColor="stone">
            <div className={'scrollable'}>
              <Text as="h2">{currentTaxYear} Tax Preparation Terms</Text>
              <Text>
                <ol>
                  <li>
                    You must have a payroll system in place.
                    <br />
                    If you don’t have one already, you can enroll in Heard
                    Payroll.
                  </li>
                  <br />

                  <li>
                    Heard can file your {currentTaxYear} annual taxes for you,
                    but if you haven’t already filed an extension for your
                    taxes, you may incur “failure to file” penalties and
                    interest.
                    <br />
                    <br />
                    You must complete all of the below ASAP to avoid additional
                    accruing interest.
                    <ul>
                      <li>Enroll in Heard Payroll (if applicable)</li>
                      <li>{bookkeepingText}</li>
                      <li>Complete your Tax Checklist</li>
                    </ul>
                    <br />
                    The longer it takes to complete these steps, the more
                    interest you’ll pay.
                  </li>

                  <br />
                  <li>
                    You agree to an annual term for your subscription.
                    <br />
                    {sharedCancellationPolicyText}
                  </li>
                </ol>
              </Text>
            </div>
          </Card>
        )
      } else {
        return null
      }

    default:
      return null
  }
}
export default TaxTerms
