import moment from 'moment'
import { CalendlyLink } from '../../reducers/calendlyLink.slice'
import {
  UserCallEvent,
  UserCallStateOfEvent,
} from '../../reducers/finances/userCallEventsReducer'

// Do not manipulate these!
export const OFFSET_YEAR_MOMENT = moment().month(0).date(7)
const CURRENT_MOMENT = moment()

export const getOffsetYear = () => {
  // Current year is offset because of Quarter cutoff
  return CURRENT_MOMENT.isAfter(OFFSET_YEAR_MOMENT)
    ? CURRENT_MOMENT.year()
    : CURRENT_MOMENT.year() - 1
}

export const getUserCallLabelColorClass = (status: UserCallStateOfEvent) => {
  switch (status) {
    case 'missed':
      return 'red'
    case 'skipped':
      return 'orange'
    case 'upcoming':
    case 'unscheduled':
      return 'green'
    case 'not_required':
    case 'completed':
      return 'neutral'
    default:
      return 'dark'
  }
}

export const getCallEventName = (
  callEvent: UserCallEvent,
  calendlyLink: CalendlyLink | undefined
) => {
  if (calendlyLink?.userFacingEventName) {
    return calendlyLink?.userFacingEventName
  } else if (callEvent.callName) {
    return callEvent.callName
  } else if (callEvent.quarter) {
    return `Q${callEvent.quarter}`
  }

  return ''
}

export const QUARTER_DROPDOWN_OPTIONS = [
  {
    text: 'Q1',
    value: '1',
  },
  {
    text: 'Q2',
    value: '2',
  },
  {
    text: 'Q3',
    value: '3',
  },
  {
    text: 'Q4',
    value: '4',
  },
]

export const createYearDropdownValues = () => {
  const currentYear = CURRENT_MOMENT.year()

  const options = []

  for (let i = currentYear - 1; i < currentYear + 3; i++) {
    options.push({ text: i.toString(), value: i.toString() })
  }

  return options
}
