import { useOffsetPaginatedTable } from '../PaginatedTable/OffsetPaginatedTable'
import { TypeColumn } from '../PaginatedTable/AdminBaseTable'
import { useGodViewLogin } from '../../../utils/adminHelpers'
import { Button, Grid, Label, Loader } from 'semantic-ui-react'
import { UserWithAdminInfo } from '../../../reducers/admin/v2/allUsersReducerV2'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  getAllBookkeepersById,
  getIsAdministrator,
} from '../../../selectors/user.selectors'
import { useReselector } from '../../../utils/sharedHooks'
import {
  FETCH_ALL_ADMINS_KEY,
  fetchAllAdmins,
} from '../../../actions/admin/adminAllUsersActions'
import { Alert } from '../../BaseComponents'
import { PrismaOffsetPagination } from '../../../utils/fetchHelpers'
import {
  fetchPaginatedUsers,
  searchUsers,
  SearchType,
} from '../../../actions/admin/v2/adminUsersActions'
import {
  FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  fetchAllAnnualTaxDetailsIfNeeded,
} from '../../../features/Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { getIsFetchingOrNotStarted } from '../../../reducers/fetch'
import {
  ConfirmUserDeletion,
  FilterByBookkeeperIdDropdown,
  SearchInput,
} from './controls'
import RowDrawer from './RowDrawer'
import { useAppDispatch } from '../../../utils/typeHelpers'

const REQUIRED_SELECT_RELATIONS_FOR_TABLE = '&selectRelation=memberships'

const AdminListAllUsersTable = ({
  bookkeeperOptions,
}: {
  bookkeeperOptions: { key: number; text: string; value: number }[]
}) => {
  const hasAdministratorRole = useReselector(getIsAdministrator)

  const [searchType, setSearchType] = useState<SearchType>(
    SearchType.BySearchString
  )
  const [search, setSearch] = useState<string | number>('')
  const [bookkeeperId, setBookkeeperId] = useState<number>()

  const loginGodView = useGodViewLogin()

  const renderMembershipStatus = (user: UserWithAdminInfo) => {
    const primaryMembership = user.memberships?.find(
      (membership) => membership.isPrimary
    )
    const membershipStatus = primaryMembership?.status
    if (!membershipStatus) {
      return null
    }
    switch (membershipStatus) {
      case 'paid':
        return <Label color="green">Paid</Label>
      case 'cancelling':
        return <Label color="orange">Cancelling</Label>
      case 'canceled':
        return <Label color="red">Canceled</Label>
      case 'unpaid':
        return <Label color="red">Unpaid</Label>
      case 'trial':
        return <Label>Trial</Label>
      default:
        return <Label>{membershipStatus}</Label>
    }
  }

  const cols: TypeColumn<UserWithAdminInfo>[] = [
    {
      header: 'ID',
      accessor: 'id',
      width: '1',
      cellProps: {
        collapsing: true,
      },
    },
    {
      header: 'Membership',
      accessor: 'memberships',
      width: '1',
      cellProps: {
        textAlign: 'center',
      },
      render: (user) => renderMembershipStatus(user),
    },
    { header: 'First Name', accessor: 'firstName', width: '2' },
    { header: 'Last Name', accessor: 'lastName', width: '2' },
    {
      header: 'Email',
      accessor: null,
      width: '2',
      render: (user) => {
        // wordwrap for email column to prevent overflow
        return <div style={{ wordWrap: 'break-word' }}>{user.email}</div>
      },
    },
    {
      header: 'Transactions',
      width: '1',
      accessor: null,
      render: (user) => (
        <Link to={`/admin/finances/transactions/${user.id}`}>Transactions</Link>
      ),
    },
    {
      header: 'User Record',
      width: '1',
      accessor: null,
      render: (user) => (
        <Link to={`/admin/finances/records/${user.id}`}>Details</Link>
      ),
    },
    {
      header: 'God View',
      accessor: null,
      width: '1',
      cellProps: {
        collapsing: true,
      },
      render: (user) => (
        <Button negative onClick={() => loginGodView(user)}>
          God View
        </Button>
      ),
    },
  ]

  if (hasAdministratorRole) {
    cols.push({
      header: 'Delete User',
      accessor: null,
      width: '1',
      cellProps: {
        collapsing: true,
      },
      render: (user) => <ConfirmUserDeletion user={user} />,
    })
  }

  const fetchData = useCallback(
    (pagination: PrismaOffsetPagination) => {
      if (!search && !bookkeeperId) {
        return fetchPaginatedUsers(
          pagination,
          REQUIRED_SELECT_RELATIONS_FOR_TABLE
        )
      }
      return searchUsers(
        pagination,
        searchType,
        search,
        bookkeeperId,
        REQUIRED_SELECT_RELATIONS_FOR_TABLE
      )
    },
    // We don't want to trigger fetch when the searchType changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bookkeeperId, search]
  )

  const {
    renderTable,
    updateItem: updateUser,
    fetchError,
    resetPaginationAndActivePage,
  } = useOffsetPaginatedTable<UserWithAdminInfo>({
    columns: cols,
    fetchData,
    tableRowPropsConfig: {
      negative: (row) => row?.memberships?.[0]?.status === 'unpaid',
      renderDrawer: (row: UserWithAdminInfo) => (
        <RowDrawer
          row={row}
          colSpan={cols.length + 1}
          onUserUpdated={updateUser}
          bookkeeperOptions={bookkeeperOptions}
        />
      ),
    },
  })

  const onSearchSubmit = (searchString: string) => {
    if (searchString === '') {
      setSearch('')
      resetPaginationAndActivePage()
      return
    }
    if (searchType === 'bySearchString' && searchString.length < 2) {
      // skipcq: JS-0052
      alert('Search string must be at least 2 characters long')
      return
    } else if (searchType === SearchType.ById && isNaN(Number(searchString))) {
      // skipcq: JS-0052
      alert('User id must be a number')
      return
    }
    const searchToUse =
      searchType === SearchType.ById ? Number(searchString) : searchString
    if (searchToUse !== search) {
      setSearch(searchToUse)
      resetPaginationAndActivePage()
    }
  }

  return (
    <div>
      {fetchError && <Alert type="error" title={fetchError.message} />}
      <Grid>
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Grid.Column width={16} style={{ display: 'flex' }}>
            <div>
              <h2>All Users</h2>
              <i>Excluding internal users</i>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: 5,
                flexGrow: 1,
              }}
            >
              <SearchInput
                searchType={searchType}
                setSearchType={setSearchType}
                onSearchSubmit={onSearchSubmit}
              />
              <FilterByBookkeeperIdDropdown
                bookkeeperOptions={bookkeeperOptions}
                setBookkeeperId={setBookkeeperId}
                bookkeeperId={bookkeeperId}
                resetPaginationAndActivePage={resetPaginationAndActivePage}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0 }}>
          <Grid.Column width={16}>{renderTable()}</Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

const AdminListAllUsersPanel = () => {
  const dispatch = useAppDispatch()
  const isFetchingAdmins = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ALL_ADMINS_KEY
  )
  const isFetchingTaxDetails = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ALL_ANNUAL_TAX_DETAILS_KEY
  )
  const allBookkeepersById = useReselector(getAllBookkeepersById)
  const bookkeeperOptions = useMemo(
    () =>
      Object.values(allBookkeepersById).map((bookkeeper) => ({
        key: bookkeeper.id,
        text: `${bookkeeper.firstName} ${bookkeeper.lastName}`,
        value: bookkeeper.id,
      })),
    [allBookkeepersById]
  )

  useEffect(() => {
    dispatch(fetchAllAdmins())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
  }, [dispatch])

  return isFetchingAdmins || isFetchingTaxDetails ? (
    <Loader active inline="centered" />
  ) : (
    <AdminListAllUsersTable bookkeeperOptions={bookkeeperOptions} />
  )
}

export default AdminListAllUsersPanel
