import { combineReducers } from 'redux'
import allUsersReducer from './allUsersReducer'
import allTransactionsReducer from './allTransactions.slice'
import selectedTransactionsReducer from './selectedTransactionsReducer'
import financialAccountsReducer from './financialAccountsReducer'
import plaidItemReducer from './plaidItemReducer'
import transactionRulesReducer from './transactionRulesReducer'
import allUserCallEventsReducer from './allUserCallEventsReducer'
import allTransactionEditsReducer from './allTransactionEdits.slice'
import allAnnualTaxFilingsReducer from '../../features/Admin/Taxes/adminAnnualTaxFilings.slice'
import allRolesReducer from './allRolesReducer'
import allActionItemsReducer from '../../features/Admin/ActionItems/allActionItems.slice'
import allActionItemCategoriesReducer from '../../features/Admin/ActionItems/actionItemCategories.slice'
import allTooltipsReducer from '../../features/Admin/ActionItems/allTooltips.slice'
import allFinancialAccountReconciliationsReducer from './allFinancialAccountReconciliationsReducer'
import allFinancialAccountsHealthReducer from './allFinancialAccountsHealthReducer'
import allUserDocumentCategoriesReducer from '../../features/Admin/UserDocumentCategories/userDocumentCategories.slice'
import allZendeskBulkMessagesReducer from '../../features/Admin/ZendeskBulkMessages/zendeskBulkMessage.slice'
import allTransactionChangeLogsReducer from '../../features/Admin/TransactionChangeLogs/transactionChangeLog.slice'
import allHistoriesReducer from '../../features/Admin/Histories/histories.slice'
import allRecordSnapshotsReducer from '../../features/Admin/RecordSnapshots/recordSnapshot.slice'
import allQAFeaturesReducer from '../../features/Admin/QA/qaFeatures.slice'
import allQAUsersReducer from '../../features/Admin/QA/qaUsers.slice'

export default combineReducers({
  allUsers: allUsersReducer,
  allTransactions: allTransactionsReducer,
  allTransactionEdits: allTransactionEditsReducer,
  selectedTransactions: selectedTransactionsReducer,
  financialAccounts: financialAccountsReducer,
  plaidItems: plaidItemReducer,
  transactionRules: transactionRulesReducer,
  allUserCallEvents: allUserCallEventsReducer,
  allRoles: allRolesReducer,
  allActionItems: allActionItemsReducer,
  allActionItemCategories: allActionItemCategoriesReducer,
  allTooltips: allTooltipsReducer,
  allFinancialAccountReconciliations: allFinancialAccountReconciliationsReducer,
  allUserFinancialAccounts: allFinancialAccountsHealthReducer,
  allUserDocumentCategories: allUserDocumentCategoriesReducer,
  allZendeskBulkMessages: allZendeskBulkMessagesReducer,
  allTransactionChangeLogs: allTransactionChangeLogsReducer,
  allHistories: allHistoriesReducer,
  allRecordSnapshots: allRecordSnapshotsReducer,
  allAnnualTaxFilings: allAnnualTaxFilingsReducer,
  allQAFeatures: allQAFeaturesReducer,
  allQAUsers: allQAUsersReducer,
})
