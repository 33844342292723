import Table from '../components/BaseComponents/Table'
import { times } from 'lodash'
import { Label } from 'semantic-ui-react'

const CELL_COUNT = 5
const ROW_COUNT = 10

const SampleTable = () => (
  <Table celled>
    <Table.Header>
      <Table.Row>
        {times(CELL_COUNT, (value) => (
          <Table.HeaderCell key={`header-${value}`}>
            Header {value}
          </Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {times(ROW_COUNT, (rowValue) => (
        <Table.Row key={`row-${rowValue}`}>
          {times(CELL_COUNT, (cellValue) => (
            <Table.Cell key={`cell-${rowValue}-${cellValue}`}>
              {cellValue === 3 ? (
                <Label>
                  Cell {rowValue} {cellValue}
                </Label>
              ) : (
                `Cell ${rowValue} ${cellValue}`
              )}
            </Table.Cell>
          ))}
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)

export default SampleTable
