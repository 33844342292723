import { Container, Header, Loader } from 'semantic-ui-react'
import { useReselector } from '../../utils/sharedHooks'
import { getCurrentUser } from '../../selectors/user.selectors'

const AdminFinancesPanel = () => {
  const currentUser = useReselector(getCurrentUser)

  if (!currentUser) {
    return <Loader active />
  }
  return (
    <Container id="adminPanel">
      <Header as="h3">Welcome to Heard, {currentUser.firstName}! </Header>
      <br />
      <div>
        <p>
          We have removed this overview dashboard to streamline the admin
          tooling. Please use the sidebar to navigate to where you need to go!
        </p>

        <br />
        <b>Your Roles at Heard:</b>
        <ul>
          {currentUser.roles?.map((role) => (
            <li key={role.id}>
              <b>Role: </b>
              {role.name}
            </li>
          ))}
        </ul>
      </div>
    </Container>
  )
}
export default AdminFinancesPanel
