import { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { Grid } from 'semantic-ui-react'
import * as yup from 'yup'
import { difference, startCase, uniq } from 'lodash'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Alert,
  Button,
  FormikCheckbox,
  FormikInput,
  FormikTextArea,
  getFieldName,
  GridRowColumn,
  Icon,
  Link,
} from '../../../components/BaseComponents'
import PageHeader from '../../../components/shared/PageHeader'
import { LabelDescription } from '../../../components/BaseComponents/Input'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { POST_SEND_GRID_EMAIL_KEY, postSendGridEmail } from './actions'
import { useReselector } from '../../../utils/sharedHooks'
import { getFetchError, selectFetchSuccess } from '../../../reducers/fetch'

const parseUserInput = (val: string) =>
  uniq(
    val
      // Split on non digits
      .split(/\D/)
      // Map result strings into numbers
      .map((val) => Number(val))
      // Filter out NANs
      .filter((val) => val && !isNaN(val))
  )
const parseCategories = (val: string) => {
  const trimmedVal = val.trim()
  if (!trimmedVal) {
    return []
  }
  return trimmedVal.split(',').map((val) => startCase(val))
}

const EmailUsers = () => {
  const dispatch = useAppDispatch()
  const success = useReselector(selectFetchSuccess, POST_SEND_GRID_EMAIL_KEY)
  const error = useReselector(getFetchError, POST_SEND_GRID_EMAIL_KEY)
  const [skippedUserIds, setSkippedUserIds] = useState<number[]>([])

  const formik = useFormik({
    initialValues: {
      templateId: '',
      templateVersion: '',
      userInput: '',
      applyStrictUserCriteria: true,
      categories: '',
    },
    onSubmit: async ({
      templateId,
      templateVersion,
      userInput,
      applyStrictUserCriteria,
      categories,
    }) => {
      const userList = parseUserInput(userInput)

      const res = await dispatch(
        postSendGridEmail({
          templateId,
          templateVersion,
          userList,
          applyStrictUserCriteria,
          categories: parseCategories(categories),
        })
      )

      if (res) {
        // Compare the response with the sent user ids to display the skipped ids
        setSkippedUserIds(
          difference(
            userList,
            res.map(({ id }) => id)
          )
        )
      }
    },
  })

  const { values, submitForm, isSubmitting, isValid, setFieldValue } = formik

  const cleanInputOnClick = () => {
    const parsedArray = parseUserInput(values.userInput)

    setFieldValue(
      getFieldName<typeof values>('userInput'),
      parsedArray.join(', ')
    )
  }

  return (
    <FormikProvider value={formik}>
      <PageHeader header="Trigger SendGrid Emails" />
      <Grid>
        {success && (
          <GridRowColumn>
            <Alert>
              Emails successfully sent!
              <br />
              {Boolean(skippedUserIds.length) &&
                `These user ids were skipped: ${skippedUserIds.join(', ')}`}
            </Alert>
          </GridRowColumn>
        )}
        {error?.message && (
          <GridRowColumn>
            <Alert type="error">{error?.message}</Alert>
          </GridRowColumn>
        )}
        <GridRowColumn>
          <FormikInput
            label="SendGrid Template Id"
            description={
              <>
                Templates can be found{' '}
                <Link href="https://mc.sendgrid.com/dynamic-templates" newPage>
                  here
                </Link>
              </>
            }
            name={getFieldName<typeof values>('templateId')}
            placeholder="d-0bf34d9955fb49dca5efd8eacdc4d242"
            required
            fullWidth
            schema={yup
              .string()
              .required()
              // Checking for `d-` followed by 32 hex characters
              .matches(/^d-[\da-f]{32}$/)}
          />
        </GridRowColumn>

        <GridRowColumn>
          <FormikInput
            label="(Optional) SendGrid Template Version"
            description="This can be set to ensure you are sending the expected version of the email.  Can be found within the url while in the SendGrid editor"
            placeholder="ac1f80a2-6b55-4867-9516-7702a2295956"
            name={getFieldName<typeof values>('templateVersion')}
            fullWidth
            schema={yup.string().matches(
              // Checking for hex characters in groups of 8-4-4-4-12
              /^[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}$/,
              { excludeEmptyString: true }
            )}
          />
        </GridRowColumn>
        {values.templateId && values.templateVersion && (
          <GridRowColumn>
            <Link
              href={`https://mc.sendgrid.com/dynamic-templates/${values.templateId}/version/${values.templateVersion}/editor`}
              newPage
            >
              <Icon
                icon={regular('square-up-right')}
                style={{ marginRight: 8 }}
              />
              View template editor
            </Link>
          </GridRowColumn>
        )}

        <GridRowColumn>
          <FormikInput
            label="(Optional) SendGrid Category"
            description={
              <>
                List of categories to apply to the email. If multiple categories
                are needed, separate them with a comma. Existing categories can
                be seen{' '}
                <Link
                  href="https://app.sendgrid.com/statistics/category"
                  newPage
                >
                  here
                </Link>
              </>
            }
            name={getFieldName<typeof values>('categories')}
            placeholder="Category With Spaces, Category2"
            fullWidth
            schema={yup.string()}
          />
        </GridRowColumn>
        <GridRowColumn>
          <FormikCheckbox
            name={getFieldName<typeof values>('applyStrictUserCriteria')}
            label="Apply Strict User Criteria"
            variant="default"
          />
          <LabelDescription description="When checked emails will only be sent to active non admin users" />
        </GridRowColumn>

        <GridRowColumn>
          <FormikTextArea
            name={getFieldName<typeof values>('userInput')}
            label="List of User Ids"
            description="List of user ids to send the email to.  As long as there is SOMETHING between the numbers it can be parsed (i.e. comma, space, newline)"
            placeholder="i.e. `1, 2, 3, 4` or `1 2 3 4`"
            required
            schema={yup
              .string()
              .required()
              .test(
                'Length check',
                'must have at least one valid number',
                (val) => Boolean(parseUserInput(val).length)
              )}
          />
        </GridRowColumn>
        <GridRowColumn short>
          <LabelDescription
            label="(Optional) Format User Id Input"
            description="Parses, formats, and removes duplicates from the above user id input. This will be done automatically upon submission"
          />
          <Button onClick={cleanInputOnClick} variant="link">
            Clean Input
          </Button>
        </GridRowColumn>
        <GridRowColumn>
          <Button
            onClick={submitForm}
            disabled={!isValid || isSubmitting}
            loading={isSubmitting}
          >
            Send Emails
          </Button>
        </GridRowColumn>
      </Grid>
    </FormikProvider>
  )
}

export default EmailUsers
