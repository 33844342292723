import { useEffect, useState, useCallback } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  Button,
  Card,
  GridRowColumn,
  Text,
  Icon,
} from '../../../components/BaseComponents'
import { useReselector, useToggle } from '../../../utils/sharedHooks'
import {
  getFinancialProfile,
  selectSCorpRequiredIncome,
  getCurrentUser,
} from '../../../selectors/user.selectors'
import { Colors } from '../../../styles/theme'
import { SCorpInterestedModal } from './SCorpInterestedModal'
import {
  formatCurrencyFromCents,
  centsToDollars,
} from '../../../utils/currencyHelpers'
import { SCorpEligibility, fetchSCorpEligibility } from './sCorpActions'
import { TAX_ENTITY_TYPES } from '../../Taxes/taxConstants'
import { includes } from 'lodash'
import { useAnalyticsTrack } from '../../Amplitude'
import { selectSCorpSetUpStatusesForUser } from './sCorpActions.selector'
import { useAppDispatch } from '../../../utils/typeHelpers'

const ELIGIBLE_ENTITY_TYPES = [
  TAX_ENTITY_TYPES.form_1040,
  TAX_ENTITY_TYPES.form_1120,
  TAX_ENTITY_TYPES.schedule_c,
]

export enum ScorpCardAndModalButtonType {
  INTERESTED = 'interested',
  NOT_NOW = 'not-now',
  DONE = 'done',
  CLOSE = 'close',
  NEXT = 'next',
  CANCEL = 'cancel',
  LEARN_MORE = 'learn-more',
  GOT_IT = 'got-it',
}

export const SCorpUpsellCard = () => {
  const dispatch = useAppDispatch()

  const [modalOpen, toggleModal] = useToggle()
  const [eligibility, setEligibility] = useState<SCorpEligibility>()

  const financialProfile = useReselector(getFinancialProfile)
  const currentUser = useReselector(getCurrentUser)

  const sCorpRequestStatus = useReselector(
    selectSCorpSetUpStatusesForUser,
    currentUser?.id
  )
  const requiredIncome = useReselector(selectSCorpRequiredIncome)
  const track = useAnalyticsTrack()

  const trackUpsellCardButtonClick = (button: ScorpCardAndModalButtonType) =>
    track('clicked s corp upsell card button', { button })

  const handleLearnMoreButtonClick = () => {
    toggleModal()
    trackUpsellCardButtonClick(ScorpCardAndModalButtonType.LEARN_MORE)
  }

  const handleOnCloseModalClick = () => {
    toggleModal()
    track('clicked s corp upsell modal button', {
      button: ScorpCardAndModalButtonType.CLOSE,
    })
  }

  useEffect(() => {
    if (eligibility?.show_card_to_user) {
      track('viewed s corp upsell card')
    }
  }, [track, eligibility])

  useEffect(() => {
    const fetchEligibility = async () => {
      const eligibilityResponse = await fetchSCorpEligibility()(dispatch)
      if (eligibilityResponse) {
        setEligibility(eligibilityResponse)
      }
    }

    if (
      eligibility ||
      !financialProfile ||
      !includes(ELIGIBLE_ENTITY_TYPES, financialProfile.taxEntityType) ||
      sCorpRequestStatus?.initialRequestSubmittedAt
    )
      return

    fetchEligibility()
  }, [financialProfile, eligibility, dispatch, sCorpRequestStatus])

  const hideCard = useCallback(() => {
    setEligibility(undefined)
  }, [])

  const currentYear = DateTime.now().year

  if (!financialProfile || !eligibility?.show_card_to_user) return null

  const formattedSavingsHeader = Math.round(
    centsToDollars(eligibility?.tax_savings_in_cents)
  ).toLocaleString()

  const formattedTaxSavings = formatCurrencyFromCents(
    eligibility?.tax_savings_in_cents
  )

  const headerText = eligibility.user_joined_prior_to_current_year
    ? `Save $${formattedSavingsHeader} in taxes for the ${currentYear} tax year`
    : 'Consider Becoming an S Corporation'

  const bodyText = eligibility.user_joined_prior_to_current_year
    ? `File by March 15, ${currentYear} to ensure your election will be applied to the whole year of ${currentYear} and you maximize your savings for the ${currentYear} tax year.`
    : `If you earned more than $${requiredIncome}k in ${
        currentYear - 1
      }, you might be a good fit to become an S Corporation and save on taxes for the ${currentYear} tax year.`

  return (
    <Card
      style={{ marginBottom: 12, border: `1px solid ${Colors.lightGray}` }}
      type="subsection"
      backgroundColor="stone40"
    >
      <Grid>
        <GridRowColumn>
          <Text as="h2">{headerText}</Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text color="darkGray">{bodyText}</Text>
          <br />
          <Button variant="link" onClick={handleLearnMoreButtonClick}>
            Learn More
            <Icon icon={regular('arrow-right')} style={{ marginLeft: 8 }} />
          </Button>
        </GridRowColumn>
        <GridRowColumn
          columnStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image src="https://heard-images.s3.amazonaws.com/assets/coins-cloud.svg" />
        </GridRowColumn>
      </Grid>
      <SCorpInterestedModal
        open={modalOpen}
        onClose={handleOnCloseModalClick}
        isPreCurrentYear={eligibility.user_joined_prior_to_current_year}
        taxSavings={formattedTaxSavings}
        hideCard={hideCard}
      />
    </Card>
  )
}
