import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import {
  Alert,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { getFinancialProfile } from '../../../../selectors/user.selectors'
import {
  UPDATE_FINANCIAL_PROFILE_KEY,
  updateFinancialProfile,
} from '../../../../actions/financialProfileActions'
import {
  TAX_PROFILE_STEP,
  TaxesProfileFlowAnswerProps,
  useTaxProfileSteps,
} from './helpers'
import { dollarsToCents } from '../../../../utils/currencyHelpers'
import TaxProfileFormFooter from './TaxProfileFormFooter'
import { getFetchError } from '../../../../reducers/fetch'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import ExamplePaystub from './ExamplePaystub'
import W2Job from './W2Job'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useAnalyticsTrack } from '../../../Amplitude'
import TaxProfileAccordions, {
  useTaxProfileAccordionCopy,
} from './TaxProfileAccordions'

/*
 * This component is used for sole proprietors to enter their W-2 income outside of their practice
 * An scorp owner will see ScorpAllW2Income.tsx
 */
const W2IncomeOutsideOfPractice = ({
  formFlowAnswers,
  setFormFlowAnswers,
}: TaxesProfileFlowAnswerProps) => {
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()
  const fp = useReselector(getFinancialProfile)
  const error = useReselector(
    getFetchError,
    UPDATE_FINANCIAL_PROFILE_KEY(fp?.id)
  )
  const { goToPreviousStep, goToNextStep } = useTaxProfileSteps(
    TAX_PROFILE_STEP.incomeOutsideOfPractice
  )

  const someIncomeEntered =
    formFlowAnswers.wIncome !== null ||
    formFlowAnswers.estimatedAnnualW2Income !== null

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      moneyOutsideOfPractice: someIncomeEntered ? true : undefined,
      enterPaystubW2: someIncomeEntered
        ? formFlowAnswers.wIncome !== null
        : undefined,
      wIncome: formFlowAnswers.wIncome,
      estimatedAnnualW2Income: formFlowAnswers.estimatedAnnualW2Income,
    },
    onSubmit: async ({
      moneyOutsideOfPractice,
      wIncome,
      enterPaystubW2,
      estimatedAnnualW2Income,
    }) => {
      const res = await dispatch(
        updateFinancialProfile(fp?.id, {
          wIncomeInCents:
            moneyOutsideOfPractice && enterPaystubW2
              ? dollarsToCents(wIncome)
              : null,
          estimatedAnnualW2IncomeInCents:
            moneyOutsideOfPractice && !enterPaystubW2
              ? dollarsToCents(estimatedAnnualW2Income)
              : null,
          // User will skip the withholding page if answered no - clear these values in this case
          ...(!moneyOutsideOfPractice && {
            federalWithholdingInCents: null,
            stateWithholdingInCents: null,
          }),
        })
      )

      if (res) {
        const newAnswers = {
          ...formFlowAnswers,
          wIncome,
          estimatedAnnualW2Income,
        }

        if (moneyOutsideOfPractice) {
          if (enterPaystubW2) {
            newAnswers.estimatedAnnualW2Income = null
          } else {
            newAnswers.wIncome = null
          }
        } else {
          newAnswers.wIncome = null
          newAnswers.estimatedAnnualW2Income = null
          newAnswers.federalWithholding = null
          newAnswers.stateWithholding = null
        }

        setFormFlowAnswers(newAnswers)
        track('self w2 income submitted', {
          has_w2_income_outside_practice: moneyOutsideOfPractice ?? false,
          enter_w2_paystub: Boolean(enterPaystubW2),
          w2_income_in_cents:
            moneyOutsideOfPractice && enterPaystubW2
              ? dollarsToCents(wIncome)
              : null,
          estimated_annual_w2_income_in_cents:
            moneyOutsideOfPractice && !enterPaystubW2
              ? dollarsToCents(estimatedAnnualW2Income)
              : null,
          user_id: fp?.userId ?? null,
        })
        goToNextStep(res)
      }
    },
  })

  const { isValid, isSubmitting, submitForm } = formik

  const faqCopy = useTaxProfileAccordionCopy()

  return (
    <FormikProvider value={formik}>
      <Grid>
        <ExamplePaystub zoomTo="grossIncome" />
        <GridRowColumn {...makeGridConfig([10, 12, 16], true)}>
          <Text as="display2" textAlign="center">
            Do you have a W-2 job?
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([10, 12, 16], true)}>
          <Text as="bodyLg" textAlign="center">
            This is a form of employment where the therapist is considered an
            employee and receives a W-2 tax form every year to report their
            income and tax withholdings.
          </Text>
        </GridRowColumn>

        {error && (
          <GridRowColumn {...makeGridConfig([10, 12, 16], true)}>
            <Alert type="error">{error.message}</Alert>
          </GridRowColumn>
        )}
        <W2Job isScorp={false} />
        <Grid.Row />
        <TaxProfileAccordions
          faqs={[
            faqCopy.whyNeedToIncludeIncomeOtherJobs,
            faqCopy.whatIsW2Job,
            faqCopy.howDoIEstimate,
            faqCopy.whatIsYearToDate,
            faqCopy.whatIsGrossIncome,
          ]}
        />
        <TaxProfileFormFooter
          submitOrContinue={submitForm}
          goToPreviousStep={goToPreviousStep}
          continueDisabled={!isValid || isSubmitting}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default W2IncomeOutsideOfPractice
