import currency from 'currency.js'

type CurrencyInput = number | string | currency | undefined | null
export const dollarsToCents = (value: CurrencyInput) =>
  currency(value || 0).intValue
export const centsToDollars = (value: CurrencyInput) =>
  centsToCurrency(value).value
export const formatCurrency = (
  value: CurrencyInput,
  options?: currency.Options & { hideDecimalsIfZero?: boolean }
) => {
  const formatedCur = currency(value || 0, { separator: ',' }).format(options)
  return options?.hideDecimalsIfZero
    ? formatedCur.replace('.00', '')
    : formatedCur
}
export const formatCurrencyFromCents = (
  value: CurrencyInput,
  options?: currency.Options & { hideDecimalsIfZero?: boolean }
) => formatCurrency(centsToDollars(value), options)
export const centsToCurrency = (value: CurrencyInput) =>
  currency(value || 0, { fromCents: true })
export const dollarsToCurrency = (value: CurrencyInput) => currency(value || 0)
export const addCurrencyArray = (vals: CurrencyInput[]) =>
  vals.reduce<number>((prev, cur) => currency(prev || 0).add(cur || 0).value, 0)

export const addCents = (
  firstAmountInCents: number,
  secondAmountInCents: number
) => {
  const firstAmount = centsToCurrency(firstAmountInCents)
  const secondAmount = centsToCurrency(secondAmountInCents)
  return firstAmount.add(secondAmount).intValue
}
