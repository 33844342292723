import { useLocation, useSearchParams } from 'react-router-dom'
import { useScrollRef } from '../../utils/sharedHooks'
import { GridRowColumn } from '../../components/BaseComponents'
import { Container } from 'semantic-ui-react'
import { useEffect, useMemo } from 'react'
import { createIsSomeEnum, useAppDispatch } from '../../utils/typeHelpers'
import { CANCELLATION_FLOW_STEP } from './helpers'
import SavingMoneyAndTime from './SavingMoneyAndTime'
import CancellationPrevented from './CancellationPrevented'
import CostComparison from './CostComparison'
import DiscountOffer from './DiscountOffer'
import { fetchFinancialAccountsIfNeeded } from '../../actions/financialAccountActions'
import { fetchUserTaxEstimates } from '../Taxes/QuarterlyTaxEstimates/userTaxEstimates.slice'
import { fetchUserTransactions } from '../Transactions/transactions.slice'
import { fetchSubscriptions } from '../../reducers/subscription.slice'
import DiscountComplete from './DiscountComplete'
import CancellationComplete from './CancellationComplete'
import { fetchCurrentUserDetailsIfNeeded } from '../../actions/userActions'

const isStep = createIsSomeEnum(CANCELLATION_FLOW_STEP)
const SelfServeCancellationFlow = () => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const { scrollRef, scrollToRef } = useScrollRef({ autoScroll: true })
  const location = useLocation()

  const recordId = location?.state?.recordId

  const stepParam = searchParams.get('step')
  const step = isStep(stepParam)
    ? stepParam
    : CANCELLATION_FLOW_STEP.savingMoneyAndTime

  useEffect(() => {
    scrollToRef()
  }, [scrollToRef])

  useEffect(() => {
    dispatch(fetchUserTransactions())
    dispatch(fetchUserTaxEstimates())
    dispatch(fetchFinancialAccountsIfNeeded())
    dispatch(fetchSubscriptions())
    dispatch(fetchCurrentUserDetailsIfNeeded(true))
  }, [dispatch])

  const content = useMemo(() => {
    switch (step) {
      case CANCELLATION_FLOW_STEP.savingMoneyAndTime:
        return <SavingMoneyAndTime recordId={recordId} />
      case CANCELLATION_FLOW_STEP.costComparison:
        return <CostComparison recordId={recordId} />
      case CANCELLATION_FLOW_STEP.discountOffer:
        return <DiscountOffer recordId={recordId} />
      case CANCELLATION_FLOW_STEP.discountComplete:
        return <DiscountComplete />
      case CANCELLATION_FLOW_STEP.cancellationComplete:
        return <CancellationComplete recordId={recordId} />
      case CANCELLATION_FLOW_STEP.cancellationPrevented:
        return <CancellationPrevented />
      default:
        return step satisfies never
    }
  }, [step, recordId])

  return (
    <Container variant="noShadow">
      <span ref={scrollRef} />
      <GridRowColumn>{content}</GridRowColumn>
    </Container>
  )
}

export default SelfServeCancellationFlow
