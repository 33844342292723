import { CSSProperties, ReactNode } from 'react'
import styled from 'styled-components'
import { Colors } from '../../styles/theme'

export interface CircularWrapperProps {
  height: number
  wrapperColor?: keyof typeof Colors
  className?: string
  children?: ReactNode
  style?: CSSProperties
}

export const CircularWrapper = styled(
  ({ className, children }: CircularWrapperProps) => (
    <div className={className}>{children}</div>
  )
)(({ height, wrapperColor, style }) => ({
  backgroundColor: wrapperColor && Colors[wrapperColor],
  borderRadius: height / 2,
  height,
  width: height,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '5px',
  ...style,
}))

export default CircularWrapper
