import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  FormikInput,
  IconButton,
  Table,
  getFieldName,
  Text,
} from '../../../../../components/BaseComponents'
import { useState, useMemo, useEffect } from 'react'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxDetails } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { TAX_ENTITY_TYPES_TYPE } from '../../../taxConstants'
import { selectAdminTaxList } from '../taxChecklist.selectors'
import {
  adminFetchTaxQuestionnaire,
  adminUpdateTaxQuestionnaireQuestion,
} from '../taxChecklistQuestion.actions'
import { useFormik, FormikProvider } from 'formik'
import { DateTime } from 'luxon'
import { TaxChecklistQuestion } from '../taxChecklistQuestion.slice'
import * as yup from 'yup'
import { useTableSort } from '../../../../../utils/sortHelpers'
import { useAppDispatch } from '../../../../../utils/typeHelpers'

const sortQuestions = (
  questions: TaxChecklistQuestion[],
  sortBy: string | null
) => {
  switch (sortBy) {
    case 'id':
      return questions.sort((a, b) => b.id.localeCompare(a.id))
    case 'text':
      return questions.sort((a, b) => b.text.localeCompare(a.text))
    default:
      return questions
  }
}

/**
 * Download questions that have been updated since creation
 */
const downloadQuestions = (questions: TaxChecklistQuestion[]) => {
  const data = questions.flatMap((q) => {
    if (DateTime.fromISO(q.updatedAt).equals(DateTime.fromISO(q.createdAt))) {
      return []
    }
    return {
      id: q.id,
      text: q.text,
    }
  })

  const migrationContent = `
    const updates = [${data
      .map((d) => `{ id: '${d.id}', text: '${d.text}' }`)
      .join(', ')}]
    const updatedAt = new Date()

    for (const update of updates) {
        await queryInterface.bulkUpdate('tax_questionnaire_questions', {
            text: update.text,
            updatedAt
        }, {
            id: update.id,
            text: {
            [Sequelize.Op.not]: update.text
            }
        })
    }`

  const fileContents = [
    {
      type: 'application/json',
      content: JSON.stringify(data),
    },
    {
      type: 'text/plain',
      content: migrationContent,
    },
  ]

  for (const { content, type } of fileContents) {
    const element = document.createElement('a')
    const file = new Blob([content], { type })
    element.href = URL.createObjectURL(file)
    element.download =
      type === 'text/plain' ? 'migrationLogic.txt' : 'questions.json'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }
}

const QuestionRow = ({ question }: { question: TaxChecklistQuestion }) => {
  const [editing, setEditing] = useState(false)
  const dispatch = useAppDispatch()
  const formik = useFormik({
    initialValues: {
      text: question.text,
    },
    onSubmit: ({ text }) => {
      if (text !== question.text) {
        adminUpdateTaxQuestionnaireQuestion(question.id, text)(dispatch)
      }
      setEditing(false)
    },
  })

  return (
    <FormikProvider value={formik}>
      <Table.Row>
        <Table.Cell>{question.id}</Table.Cell>
        {editing && (
          <Table.Cell>
            <FormikInput
              fullWidth
              name={getFieldName<typeof formik.values>('text')}
              schema={yup.string().required('Text is required')}
            />
          </Table.Cell>
        )}
        {!editing && (
          <Table.Cell onClick={() => setEditing(true)}>
            <Text>{question.text}</Text>
          </Table.Cell>
        )}
        <Table.Cell textAlign="right">
          {editing && (
            <div style={{ display: 'flex', gap: 15, justifyContent: 'right' }}>
              <IconButton
                color="green"
                icon={regular('check')}
                onClick={formik.submitForm}
              />
              <IconButton
                color="red"
                icon={regular('times')}
                onClick={() => setEditing(false)}
              />
            </div>
          )}
          {!editing && (
            <IconButton
              icon={regular('edit')}
              onClick={() => setEditing(true)}
            />
          )}
        </Table.Cell>
      </Table.Row>
    </FormikProvider>
  )
}

const AdminTaxChecklistTable = ({
  formSelection,
  searchValue,
}: {
  formSelection?: TAX_ENTITY_TYPES_TYPE
  searchValue?: string
}) => {
  const dispatch = useAppDispatch()
  const taxYear = useReselector(selectCurrentAnnualTaxDetails)?.taxYear
  const tqQuestions = useReselector(selectAdminTaxList)

  const filteredQuestions = useMemo(() => {
    if (!searchValue) {
      return tqQuestions
    } else {
      return tqQuestions.filter(
        (question) =>
          question.text.toLowerCase().includes(searchValue.toLowerCase()) ||
          question.id.toLowerCase().includes(searchValue.toLowerCase())
      )
    }
  }, [searchValue, tqQuestions])

  const { sortedValues, getHeaderCellProps } = useTableSort({
    values: filteredQuestions,
    defaultSortBy: 'id',
    sortFunction: sortQuestions,
    pageSize: filteredQuestions.length,
  })

  useEffect(() => {
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    if (taxYear) {
      dispatch(adminFetchTaxQuestionnaire(taxYear, formSelection))
    }
  }, [dispatch, taxYear, formSelection])

  return (
    <Table celled striped sortable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell {...getHeaderCellProps('id')} width={4}>
            Id
          </Table.HeaderCell>
          <Table.HeaderCell {...getHeaderCellProps('text')} width={10}>
            Text
          </Table.HeaderCell>
          <Table.HeaderCell width={2} textAlign="right" key="action">
            <IconButton
              icon={regular('download')}
              onClick={() => downloadQuestions(sortedValues)}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {sortedValues.map((question) => (
          <QuestionRow key={`row-${question.id}`} question={question} />
        ))}
      </Table.Body>
    </Table>
  )
}

export default AdminTaxChecklistTable
