import { useCallback, useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import {
  FinancialAccountWithAdminInfo,
  Reconciliation,
} from '../../../../reducers/admin/financialAccountsReducer'
import { GridRowColumn, Modal, Text } from '../../../BaseComponents'
import { Colors } from '../../../../styles/theme'
import { parseErrorFromCatch } from '../../../../utils/errorHelpers'
import { performAutoReconciliation } from '../../../../actions/admin/accountReconciliationActions'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useTimeoutRef } from '../../../../utils/sharedHooks'
import { BookkeepingReport } from '../../../../reducers/finances/bookkeepingReportsReducer'
import { ENABLE_ADMIN, useAnalyticsTrack } from '../../../../features/Amplitude'

export interface AutoReconciliationLoadingIndicatorProps {
  open: boolean
  account: FinancialAccountWithAdminInfo
  report: BookkeepingReport | undefined
  // Must be memoized to prevent unnecessary re-renders
  onCompleted: (reconciliation: Reconciliation | null) => void
}

const AutoReconciliationLoadingIndicator = ({
  open,
  account,
  report,
  onCompleted,
}: AutoReconciliationLoadingIndicatorProps) => {
  const [error, setError] = useState<string | null>(null)
  const dispatch = useAppDispatch()
  const timeoutRef = useTimeoutRef()
  const track = useAnalyticsTrack(ENABLE_ADMIN)

  // Set a minimum wait time to prevent the modal from flashing too quickly
  // given that the auto-reconciliation process is usually very quick
  const minumumWaitTime = 2000
  const delayedAction = useCallback(
    (startTime: number, action: () => void) => {
      const waitTime = minumumWaitTime - (Date.now() - startTime)
      if (waitTime > 0) {
        timeoutRef.current = setTimeout(() => action(), waitTime)
      } else {
        action()
      }
    },
    [timeoutRef]
  )

  useEffect(() => {
    const runAutoReconciliation = async (bookkeepingReportId: number) => {
      const startTime = Date.now()
      try {
        const result = await performAutoReconciliation(
          account.id,
          bookkeepingReportId
        )(dispatch)
        if (result) {
          delayedAction(startTime, () => onCompleted(result.reconciliation))

          if (result.analyticsScenario) {
            track(result.analyticsScenario, {
              bookkeepingReportId,
              accountId: account.id,
              reconciliationId: result.reconciliation.id,
              reconType: result.reconciliation.type,
              reconStatus: result.reconciliation.status,
            })
          }
        }
      } catch (err) {
        delayedAction(startTime, () => setError(parseErrorFromCatch(err)))
      }
    }
    if (open && account.id && report?.id) {
      runAutoReconciliation(report.id)
    }
  }, [
    open,
    account.id,
    report?.id,
    dispatch,
    onCompleted,
    delayedAction,
    track,
  ])

  return (
    <Modal
      open={open}
      size="tiny"
      centered
      closeIcon={Boolean(error)}
      closeOnDimmerClick={Boolean(error)}
      closeOnDocumentClick={Boolean(error)}
      onClose={() => onCompleted(null)}
      style={{ padding: '60px' }}
    >
      <Modal.Content>
        <Grid textAlign="center">
          <GridRowColumn>
            <Text as="h2">
              {error
                ? 'Auto-reconciliation error'
                : 'Running auto-reconciliation...'}
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>{account.plaidInstitutionName}</Text>
            <Text>
              <strong>{account.name}: </strong>
              {account.mask}
            </Text>
            <Text>
              {account.type} -- {account.subtype}
            </Text>
          </GridRowColumn>
          {error && (
            <GridRowColumn columnStyle={{ color: Colors.red }}>
              {error}
            </GridRowColumn>
          )}
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

export default AutoReconciliationLoadingIndicator
