import axios from 'axios'

import { updateCurrentUser, User } from '../reducers/auth/userReducer'
import { fetchWrapper } from '../reducers/fetch'
import {
  setInvalidResetPasswordToken,
  setResetPasswordEmailSent,
  setUpdatedPassword,
  setValidResetPasswordToken,
  validatingTokenLoading,
} from '../reducers/auth/securityReducer'

import { userLogout } from '../reducers/rootReducer.actions'
import { TosVersion } from '../constants/businessConstants'

interface SignupPayload {
  firstName: string
  lastName: string
  email: string
  password: string
  phoneNumber: string
  state: string
  hadConsult: boolean
  tosVersion: TosVersion
  tosAcceptedOn: number
  smsContactPhoneNumber?: string
  freeTrialPromoCode?: string | null
}

export const REGISTER_FINANCES_USER_KEY = 'REGISTER_FINANCES_USER_KEY'
export const registerFinancesUser = (userData: SignupPayload) =>
  fetchWrapper({
    fetchKey: REGISTER_FINANCES_USER_KEY,
    fetchFunction: (dispatch) =>
      axios.post<{ user: User }>('/finances/signup', userData).then((res) => {
        if (res.status === 201) {
          dispatch(updateCurrentUser(res.data.user))
        }
        return res.data
      }),
  })

export interface LoginPayload {
  email: string
  password: string
  otp: string | undefined
}

// Login - get user token
export const POST_LOGIN_KEY = 'POST_LOGIN_KEY'
export const POST_LOGIN_SSO_KEY = 'POST_LOGIN_SSO_KEY'

export const loginUser = (userData: LoginPayload) =>
  fetchWrapper({
    fetchKey: POST_LOGIN_KEY,
    defaultValue: false as const,
    fetchFunction: (dispatch) =>
      axios
        .post<User | { lastDigits: string }>('/finances/login', userData)
        .then((res) => {
          if (res.status === 200 && !('lastDigits' in res.data)) {
            dispatch(updateCurrentUser(res.data))
          }
          return res
        }),
  })

export const loginUserSso = (userData: LoginPayload) =>
  fetchWrapper({
    fetchKey: POST_LOGIN_SSO_KEY,
    defaultValue: false as const,
    fetchFunction: () =>
      axios.post<
        | {
            jwt: string
            uuid: string
          }
        | { lastDigits: string }
      >('/finances/zendesk/sso', userData),
  })

export const GET_ZENDESK_SSO_KEY = 'GET_ZENDESK_SSO_KEY'

// For users already logged in
export const getZendeskSso = () =>
  fetchWrapper({
    fetchKey: GET_ZENDESK_SSO_KEY,
    fetchFunction: () => axios.get<{ jwt: string }>('/finances/jwt'),
  })

export const SEND_RESET_PASSWORD_EMAIL_KEY = 'SEND_RESET_PASSWORD_EMAIL_KEY'
export const sendResetPasswordEmail = (userData: { email: string }) =>
  fetchWrapper({
    fetchKey: SEND_RESET_PASSWORD_EMAIL_KEY,
    fetchFunction: (dispatch) =>
      axios
        .post<Record<string, unknown>>('/auth/recover', userData)
        .then(() => {
          dispatch(setResetPasswordEmailSent())
        }),
  })

export const VALIDATE_RESET_PASSWORD_TOKEN_ERROR_KEY =
  'VALIDATE_RESET_PASSWORD_TOKEN_ERROR_KEY'
export const validateResetPasswordToken = (token: string) =>
  fetchWrapper({
    fetchKey: VALIDATE_RESET_PASSWORD_TOKEN_ERROR_KEY,
    fetchFunction: (dispatch) => {
      dispatch(validatingTokenLoading())

      return axios
        .get<{ message: string }>(`/auth/reset/${token}`)
        .then(() => {
          dispatch(setValidResetPasswordToken())
        })
        .catch(() => {
          dispatch(setInvalidResetPasswordToken())
        })
    },
  })

export const UPDATE_RESET_PASSWORD_KEY = 'UPDATE_RESET_PASSWORD_KEY'
export const updateResetPassword = (
  data: { password: string },
  token: string
) =>
  fetchWrapper({
    fetchKey: UPDATE_RESET_PASSWORD_KEY,
    fetchFunction: (dispatch) =>
      axios
        .post<Record<string, unknown>>(`/auth/reset/${token}`, data)
        .then(() => {
          // Set current user
          dispatch(setUpdatedPassword())
        }),
  })

export const logoutUser = () =>
  fetchWrapper({
    fetchFunction: (dispatch) =>
      axios.delete<{ message: string }>('/finances/session').then(() => {
        dispatch(userLogout())
      }),
  })

// *******
// Verification Tokens
export const validateVerificationToken = (token: string) =>
  fetchWrapper({
    defaultValue: false,
    fetchFunction: (dispatch) => {
      dispatch(validatingTokenLoading())
      return axios
        .get<User>(`/finances/api/v1/users/verify-email/${token}`)
        .then((json) => {
          dispatch(updateCurrentUser(json.data))
          return true
        })
    },
  })

export const sendVerificationEmail = () =>
  fetchWrapper({
    defaultValue: false,
    fetchFunction: () =>
      axios
        .post<
          Record<string, unknown>
        >('/finances/api/v1/users/verify-email', {})
        .then(() => true),
  })

export const POST_ENABLE_2FA_KEY = 'POST_ENABLE_2FA_KEY'

// 2FA actions
export const enable2FA = (data: { passcode: string; phoneNumber: string }) =>
  fetchWrapper({
    defaultValue: false,
    fetchKey: POST_ENABLE_2FA_KEY,
    fetchFunction: (dispatch) =>
      axios
        .post<User>('/finances/api/v1/users/enable-2fa', data)
        .then((json) => {
          dispatch(updateCurrentUser(json.data))
          return true
        }),
  })

export const POST_GENERATE_OTP_KEY = 'POST_GENERATE_OTP_KEY'

// This endpoint is protected and used internally
export const generateOTP = (data: { phoneNumber: string }) =>
  fetchWrapper({
    defaultValue: false,
    fetchKey: POST_GENERATE_OTP_KEY,
    fetchFunction: () =>
      axios
        .post<
          Record<string, unknown>
        >('/finances/api/v1/users/generate-passcode', data)
        .then(() => true),
  })

export interface LoginRegenerateOtpPayload {
  email: string
  password: string
}

// This endpoint is for regeneration on logging in (we check user and pass as well)
export const loginRegenerateOTP = (data: LoginRegenerateOtpPayload) =>
  fetchWrapper({
    fetchKey: POST_LOGIN_KEY,
    defaultValue: false,
    fetchFunction: () =>
      axios
        .post<Record<string, unknown>>('/finances/generate-passcode', data)
        .then(() => true),
  })

// Admin
export const loginGodviewUser = (userId: number, userEmail: string) =>
  fetchWrapper({
    fetchFunction: (dispatch) =>
      axios
        .post<User>(`/finances/login/godview?userId=${userId}`, {
          email: userEmail,
          password: process.env.VITE_GOD_VIEW_PASSWORD,
        })
        .then((res) => {
          dispatch(updateCurrentUser(res.data))
          return res
        }),
  })

export const VERIFY_PASSWORD_KEY = 'VERIFY_PASSWORD_KEY'

export const verifyPassword = (payload: { password: string }) =>
  fetchWrapper({
    fetchKey: VERIFY_PASSWORD_KEY,
    fetchFunction: async () => {
      const res = await axios.post<{ message: string }>(
        '/finances/api/v1/users/verify-password',
        payload
      )

      return res.data
    },
  })

export const UPDATE_EMAIL_KEY = 'UPDATE_EMAIL_KEY'

export const updateUserEmail = (payload: { newEmail: string }) =>
  fetchWrapper({
    fetchKey: UPDATE_EMAIL_KEY,
    fetchFunction: async (dispatch) => {
      const res = await axios.post<{ message: string }>(
        '/finances/api/v1/users/change-email',
        payload
      )

      dispatch(updateCurrentUser({ email: payload.newEmail }))

      return res
    },
  })

export const UPDATE_USER_PASSWORD_KEY = 'UPDATE_USER_PASSWORD_KEY'
export const updateUserPassword = (payload: { newPassword: string }) =>
  fetchWrapper({
    fetchKey: UPDATE_USER_PASSWORD_KEY,
    fetchFunction: async () => {
      const res = await axios.post<{ message: string }>(
        '/finances/api/v1/users/change-password',
        payload
      )

      return res.data
    },
  })
