import { MessageProps } from 'semantic-ui-react'

import { getLockedTransactionDescription } from './copyConstants'
import { Alert } from '../../components/BaseComponents'

interface Props extends MessageProps {
  year: string
  admin?: boolean
  userOnlyLocked?: boolean
}

const LockedBooksMessage = ({
  year,
  admin,
  userOnlyLocked,
  ...rest
}: Props) => {
  const message = admin
    ? userOnlyLocked
      ? getLockedTransactionDescription(year).adminUserLockedBannerMessage
      : getLockedTransactionDescription(year).adminLockedBannerMessage
    : getLockedTransactionDescription().lockedBannerMessage
  return (
    <Alert type="warn" {...rest}>
      {message}
    </Alert>
  )
}

export default LockedBooksMessage
