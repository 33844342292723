import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { Accordion, Container, Grid } from 'semantic-ui-react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { times } from 'lodash'

import {
  Dropdown,
  GridRowColumn,
  Icon,
  Label,
  Text,
} from '../../../../components/BaseComponents'
import Tooltip from './Tooltip'
import { fetchAllQuarterlyTaxEstimateDetailsIfNeeded } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import { Colors } from '../../../../styles/theme'
import {
  selectEstimatesByQuarter,
  selectUserCreatedPastQteCutoff,
} from '../userTaxEstimates.selector'
import { EstimateStatus, statusForSingleEstimate } from '../service'
import UserTaxEstimateSummary from './UserTaxEstimateRow'
import { selectActiveQuarterlyTaxEstimateDetails } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { useReselector } from '../../../../utils/sharedHooks'
import { getUserCreatedAt } from '../../../../selectors/user.selectors'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const now = moment()
const currentYear = now.year()
const lastYear = now.subtract(1, 'year').year()

const yearOptions = [
  {
    text: currentYear,
    value: currentYear,
    key: currentYear,
  },
  {
    text: lastYear,
    value: lastYear,
    key: lastYear,
  },
]

const containerStyle = {
  padding: '24px',
  margin: '0 0 12px 0',
  backgroundColor: Colors.stone40,
}

const headerStyle = {
  display: 'inline-block',
}

const labelStyle = {
  float: 'right' as const,
  margin: '-3px 18px 0 0',
}

const contentStyle = {
  marginTop: '55px',
}

const QuarterLabel = ({
  taxQuarter,
  taxYear,
}: {
  taxQuarter: number
  taxYear: number
}) => {
  const activeQuarterDetails = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )
  const estimatesByQuarter = useReselector(selectEstimatesByQuarter, taxYear)
  const userJoinedTooLate = useReselector(selectUserCreatedPastQteCutoff)

  // Active Quarter label
  if (
    Number(activeQuarterDetails?.taxQuarter) === taxQuarter &&
    taxYear === Number(activeQuarterDetails?.taxYear)
  ) {
    return <Label style={labelStyle}>Active Quarter</Label>
  }

  // Update payment label – inspect estimates to determine if any have status = unpaid_without_response
  const key = `${taxYear}-${taxQuarter}`
  const estimates = estimatesByQuarter[key]
  return estimates?.some(
    (e) =>
      statusForSingleEstimate(e, userJoinedTooLate) ===
      EstimateStatus.unpaidWithoutResponse
  ) ? (
    <Tooltip
      style={{ display: 'inline-block', float: 'right' }}
      popup={{
        body: 'We need this to calculate accurate Q4 estimates.',
      }}
      labelComponent={
        <Label
          icon={regular('exclamation-circle')}
          color="red"
          style={labelStyle}
        >
          Please update your payment(s) for this quarter
        </Label>
      }
    />
  ) : null
}

const QuarterlyTaxPaymentHistory = () => {
  const dispatch = useAppDispatch()
  const { taxYear, taxQuarter } = useParams()
  const overrideTaxYear = !isNaN(Number(taxYear)) ? Number(taxYear) : null
  const overrideTaxQuarter = !isNaN(Number(taxQuarter))
    ? Number(taxQuarter)
    : null

  const activeQuarterDetails = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )

  const userCreatedAt = useReselector(getUserCreatedAt)
  const userJoinedTooLate = useReselector(selectUserCreatedPastQteCutoff)

  const [dropdownValue, setDropdownValue] = useState(
    overrideTaxYear || Number(activeQuarterDetails?.taxYear)
  )
  const [accordionValue, setAccordionValue] = useState(
    // if an overriding tax year is provided without an overriding quarter, all accordions should be collapsed
    overrideTaxQuarter && overrideTaxQuarter
      ? overrideTaxQuarter
      : overrideTaxYear && !overrideTaxQuarter
        ? null
        : Number(activeQuarterDetails?.taxQuarter)
  )
  const estimatesByQuarter = useReselector(
    selectEstimatesByQuarter,
    dropdownValue
  )

  useEffect(() => {
    dispatch(fetchAllQuarterlyTaxEstimateDetailsIfNeeded())
  }, [dispatch])

  if (!activeQuarterDetails) {
    return null
  }

  return (
    <Grid>
      <GridRowColumn
        columnStyle={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 10,
        }}
      >
        <Dropdown
          fontSize={20}
          fontWeight={500}
          value={dropdownValue}
          onChange={setDropdownValue}
          options={yearOptions}
          variant="text"
        />
        <Tooltip
          popup={{
            title: 'Quarterly Tax Payment History',
            body: 'Your personal record of past, present, and future quarterly tax payments. You can also update your payment status here.',
            link: 'https://support.joinheard.com/hc/en-us/articles/4416208037655-What-are-quarterly-tax-estimates-',
          }}
          labelComponent={
            <Icon icon={regular('question-circle')} color="mediumGray" />
          }
        />
      </GridRowColumn>
      <GridRowColumn>
        <Accordion>
          {times(4).map((i) => {
            const taxQuarter = i + 1
            return (
              <Container
                key={`accordion-quarter-${taxQuarter}`}
                style={containerStyle}
              >
                <Accordion.Title
                  active={accordionValue === taxQuarter}
                  onClick={() =>
                    setAccordionValue(
                      taxQuarter === accordionValue ? null : taxQuarter
                    )
                  }
                >
                  <Text style={headerStyle} as="h2">
                    Q{taxQuarter} {dropdownValue}
                  </Text>
                  <Icon
                    style={{ float: 'right' }}
                    icon={
                      accordionValue === taxQuarter
                        ? solid('minus')
                        : solid('plus')
                    }
                  />
                  <QuarterLabel
                    taxQuarter={taxQuarter}
                    taxYear={dropdownValue}
                  />
                </Accordion.Title>
                <Accordion.Content
                  style={contentStyle}
                  active={accordionValue === taxQuarter}
                >
                  {estimatesByQuarter[`${dropdownValue}-${taxQuarter}`]?.map(
                    (estimate) => (
                      <UserTaxEstimateSummary
                        style={{ marginBottom: '24px' }}
                        key={`${estimate.id}-accordion-content`}
                        overrideBgColor={
                          statusForSingleEstimate(
                            estimate,
                            userJoinedTooLate
                          ) === EstimateStatus.unpaidWithoutResponse
                            ? 'lightMagenta'
                            : 'stone'
                        }
                        estimate={estimate}
                      />
                    )
                  ) ||
                    `Since you signed up in ${moment
                      .utc(userCreatedAt)
                      .year()}, Heard doesn't need your tax payments for this year.`}
                </Accordion.Content>
              </Container>
            )
          })}
        </Accordion>
      </GridRowColumn>
    </Grid>
  )
}

export default QuarterlyTaxPaymentHistory
