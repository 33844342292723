import axios from 'axios'

import { fetchWrapper } from '../reducers/fetch'

// Admin actions below — Flagging

export const updateTransactionEdit = (
  id: number | string,
  data: {
    approvedOn: number | null
    transactionType: string | null
    transactionCategoryId: number | null
  }
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error flagging transaction by category.',
    fetchFunction: () =>
      // the api returns a boolean here which is a bit strange.  It prob should be the edit
      axios.post<boolean>(
        `/finances/api/v1/admin/transactions/edit/${id}`,
        data
      ),
  })
