import { GridRowColumn, Text } from '../../components/BaseComponents'
import { Grid } from 'semantic-ui-react'

export const Form2553Tooltip = () => (
  <Grid>
    <GridRowColumn>
      <Text as="h3">Form 2553</Text>
    </GridRowColumn>
    <GridRowColumn>
      <Text as="bodyLg">
        You filed this form to be elected as an S Corporation for federal tax
        purposes.
      </Text>
    </GridRowColumn>
  </Grid>
)

export const CP261Tooltip = () => (
  <Grid>
    <GridRowColumn>
      <Text as="h3">CP261 Letter</Text>
    </GridRowColumn>
    <GridRowColumn>
      <Text as="bodyLg">
        This indicates that the IRS has accepted your S Corporation election per
        Form 2553, Election by a Small Business Corporation.
      </Text>
    </GridRowColumn>
  </Grid>
)

export const IncorporationDocTooltip = () => (
  <Grid>
    <GridRowColumn>
      <Text as="h3">Incorporation Document</Text>
    </GridRowColumn>
    <GridRowColumn>
      <Text as="bodyLg">
        These are documents you’ve filed with the state for your corporation to
        exist.
      </Text>
    </GridRowColumn>
  </Grid>
)

export const EinLetterTooltip = () => (
  <Grid>
    <GridRowColumn>
      <Text as="h3">EIN Letter</Text>
    </GridRowColumn>
    <GridRowColumn>
      <Text as="bodyLg">
        In order to retrieve this, you can:
        <ul>
          <li>Request it by calling the IRS at 800-829-4933.</li>
          <li>
            Find it on your confirmation letter from the IRS, old tax returns,
            credit reports for your business or payroll documents
          </li>
        </ul>
      </Text>
    </GridRowColumn>
  </Grid>
)

export const StateRegistrationTooltip = () => (
  <Grid>
    <GridRowColumn>
      <Text as="h3">State Registration</Text>
    </GridRowColumn>
    <GridRowColumn>
      <Text as="bodyLg">
        This is the document that shows that you’ve registered your business in
        the state where it’s physically located.
      </Text>
    </GridRowColumn>
  </Grid>
)
