import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface RolledOutInstitutionsState {
  rolledOutInstitutionIds: string[]
}

const rolloutGroupSlice = createSlice({
  name: 'rolloutGroup',
  initialState: {} as RolledOutInstitutionsState,
  reducers: {
    receiveRolledOutInstitutionIds: (
      state,
      action: PayloadAction<string[]>
    ) => ({
      ...state,
      rolledOutInstitutionIds: action.payload,
    }),
  },
})

export default rolloutGroupSlice.reducer

export const { receiveRolledOutInstitutionIds } = rolloutGroupSlice.actions
