import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReduxState } from '../../utils/typeHelpers'
import { createSelector } from 'reselect'

export enum RoleName {
  Administrator = 'Administrator',
  Bookkeeper = 'Bookkeeper',
  AccountManager = 'Account Manager',
  TaxPreparer = 'Tax Preparer',
  TaxCoordinator = 'Tax Coordinator',
  Internal = 'Internal',
  AccountingOpsManager = 'Accounting Ops Manager',
}

export interface Role {
  id: number
  name: RoleName
  description: null | string
  sortingIndex: null | number
}

export interface RoleState {
  [key: string]: Role
}

const allRolesSlice = createSlice({
  name: 'allRoles',
  initialState: {} as RoleState,
  reducers: {
    receiveAllRoles: (state, action: PayloadAction<RoleState>) =>
      action.payload,
  },
})

export const selectAllRoles = (state: ReduxState) => state.admin.allRoles

export const selectRoleByName = createSelector(
  selectAllRoles,
  (_: unknown, roleName: RoleName) => roleName,
  (allRoles, roleName) =>
    Object.values(allRoles).find((role) => role.name === roleName)
)

export const { receiveAllRoles } = allRolesSlice.actions

export default allRolesSlice.reducer
