import { ReactNode } from 'react'
import { Container, Segment, Sidebar } from 'semantic-ui-react'
import styled from 'styled-components'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import Text from './Text'
import { Colors } from '../../styles/theme'
import { useFlyout } from '../../utils/sharedHooks'
import { IconButton } from '.'
import { StackedIcon } from './Icon'

interface FlyoutContentProps {
  className?: string
}

const Content = ({ className }: FlyoutContentProps) => {
  const {
    open,
    closeFlyout,
    flyoutContent,
    direction,
    title,
    titleIcon,
    footer,
  } = useFlyout()

  return (
    <Sidebar
      className={className}
      as={Container}
      animation="overlay"
      direction={direction}
      visible={open}
      onHide={closeFlyout}
      width="very wide"
    >
      <div className="flyout-header">
        <div className="flyout-title">
          {titleIcon && (
            <StackedIcon
              icon={titleIcon}
              color="white"
              backgroundIcon={solid('circle')}
              backgroundColor="black"
              marginRight={16}
            />
          )}
          <Text as="h2">{title}</Text>
        </div>
        <IconButton size="xl" icon={solid('close')} onClick={closeFlyout} />
      </div>
      <div className="flyout-content">
        <div className="flyout-padding">{flyoutContent}</div>
      </div>
      {footer && (
        <div className="flyout-footer">
          <div className="flyout-padding">{footer}</div>
        </div>
      )}
    </Sidebar>
  )
}

export const FlyoutContent = styled(Content)({
  '&&&&': {
    backgroundColor: Colors.white,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 0,

    '.flyout-header': {
      display: 'flex',
      padding: 32,
    },

    '.flyout-title': {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
    },

    '.flyout-content': {
      flex: 1,
      overflowY: 'scroll',
      overflowX: 'hidden',

      '.flyout-padding': {
        padding: '15px 32px',
      },
    },

    '.flyout-footer': {
      overflow: 'hidden',
      borderTop: `1px ${Colors.lightGray} solid`,

      '.flyout-padding': {
        padding: '15px 32px',
      },
    },
  },
})

export interface FlyoutOptions {
  children: ReactNode
}

const Flyout = ({ children }: FlyoutOptions) => {
  const { open } = useFlyout()
  return (
    <Sidebar.Pushable as={Segment}>
      <FlyoutContent />
      <Sidebar.Pusher dimmed={open}>
        <Segment>{children}</Segment>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  )
}

export default Flyout
