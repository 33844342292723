import { Link } from '../components/BaseComponents'

export const PrimaryLink = () => (
  <Link href="https://support.joinheard.com" newPage>
    Primary Link
  </Link>
)

export const SecondaryLink = () => (
  <Link href="https://support.joinheard.com" as="secondaryLink">
    Secondary Link
  </Link>
)

export const LargeLink = () => (
  <Link href="https://support.joinheard.com" newPage size="large">
    Large Link
  </Link>
)

export const MediumLink = () => (
  <Link href="https://support.joinheard.com" newPage size="medium">
    Medium Link
  </Link>
)

export const SmallLink = () => (
  <Link href="https://support.joinheard.com" newPage size="small">
    Small Link
  </Link>
)

export const XSmallLink = () => (
  <Link href="https://support.joinheard.com" newPage size="xSmall">
    X Small Link
  </Link>
)
