import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
// BL
import { DocumentTabPane } from '.'
import { bookkeepingPane as copy } from '../copyConstants'
import {
  closeUploadModal,
  openUploadModal,
  useFeatureDispatch,
  useFeatureState,
} from '../state'
import { DescriptionType, TabCategoryKeys as ModalTypes } from '../constants'

// UI
import { DocumentSearchBarOnChangeData } from '../sharedComponents/DocumentsSearchBar'
import TabPaneToolbar from '../sharedComponents/TabPaneToolbar'
import UploadDocumentButton from '../sharedComponents/Buttons/UploadDocumentButton'
import UserDocumentsTable from '../sharedComponents/Table/UserDocumentsTable'
import { TaxOrIncorporationRow } from '../sharedComponents/Table/UserDocumentsTableRow'
import FileUploadModal from '../../../components/FileUpload/FileUploadModal'
import { UploadDocumentType } from '../../../constants/businessConstants'
import { useAnalyticsTrack } from '../../Amplitude'

const Bookkeeping: DocumentTabPane = ({ tableOptions, documents }) => {
  const dispatch = useFeatureDispatch()
  const [filteredDocuments, setFilteredDocuments] = useState(documents)
  const { activeUploadModal, userDocumentCategoryId, descriptionType } =
    useFeatureState()
  const [searchParams] = useSearchParams()
  const track = useAnalyticsTrack()

  useEffect(() => {
    const documentCategoryId = searchParams.get('documentCategoryId')
    const descriptionType = searchParams.get('descriptionType')
    if (documentCategoryId)
      dispatch?.(
        openUploadModal(
          ModalTypes.BOOKKEEPING,
          parseInt(documentCategoryId),
          descriptionType as DescriptionType
        )
      )
  }, [searchParams, dispatch])

  useEffect(() => {
    setFilteredDocuments(documents)
  }, [documents])

  const onSearch = useCallback((data: DocumentSearchBarOnChangeData) => {
    setFilteredDocuments(data.filtered)
  }, [])

  const openModal = () => {
    track('clicked document upload', {
      document_category: ModalTypes.BOOKKEEPING,
      document_subcategory: '',
    })
    dispatch?.(openUploadModal(ModalTypes.BOOKKEEPING))
  }

  return (
    <>
      <TabPaneToolbar
        preamble="Share bookkeeping-related documents here."
        documents={documents}
        onSearch={onSearch}
        rightElement={<UploadDocumentButton onClick={openModal} />}
      />
      <UserDocumentsTable
        columns={copy.static.tableColumns}
        documents={filteredDocuments}
        RowComponent={TaxOrIncorporationRow}
        tableOptions={tableOptions}
      />
      <FileUploadModal
        open={activeUploadModal === ModalTypes.BOOKKEEPING}
        close={() => dispatch?.(closeUploadModal())}
        documentType={UploadDocumentType.BOOKKEEPING}
        descriptionType={descriptionType}
        userFacing
        categoryId={userDocumentCategoryId}
      />
    </>
  )
}

Bookkeeping.paneDescription = copy.static.paneDescription
Bookkeeping.paneTitle = copy.static.paneTitle

export default Bookkeeping
