import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button, GridRowColumn, Icon, Modal, Text } from '../../BaseComponents'
import { Colors } from '../../../styles/theme'
import { Container, Divider, Grid, Image, List } from 'semantic-ui-react'
import { ReactNode, useCallback } from 'react'
import { fetchBillingPortalSession } from '../../../actions/settings/billingActions'
import { useAppDispatch } from '../../../utils/typeHelpers'

const contentStyle = {
  background: Colors.natural,
  padding: 48,
}

const Perk = ({ children }: { children: ReactNode }) => (
  <List.Item style={{ padding: '8px 0' }}>
    <List.Icon style={{ verticalAlign: 'middle' }}>
      <Icon color="green" icon={regular('check')} />
    </List.Icon>
    <List.Content>{children}</List.Content>
  </List.Item>
)

const AnnualTaxesContent = () => {
  const dispatch = useAppDispatch()

  const redirectToStripePortal = useCallback(async () => {
    const res = await fetchBillingPortalSession()(dispatch)
    if (res) {
      window.location.href = res.url
    }
  }, [dispatch])

  return (
    <>
      <Modal.Content style={contentStyle}>
        <Grid>
          <GridRowColumn>
            <Text as="display">
              Annual Taxes
              <Icon
                size="xs"
                style={{ marginLeft: 16 }}
                icon={regular('lock')}
              />
            </Text>
          </GridRowColumn>
          <Grid.Row columns={2} style={{ paddingBottom: 80 }}>
            <Grid.Column>
              <Text>
                Heard&apos;s annual tax service is a premium feature that walks
                you through the tax filing process with the guidance of our
                in-house tax experts and dedicated support team.
              </Text>
              <br />
              <Text>The perks of Heard&apos;s annual tax service:</Text>
              <Divider
                style={{
                  borderTop: `1px solid ${Colors.green}`,
                  borderBottom: 'none',
                  marginTop: 5,
                }}
              />
              <List>
                <Perk>
                  Save thousands of dollars on your personal and business income
                  taxes
                </Perk>
                <Perk>Annual federal and state taxes filed for you</Perk>
                <Perk>Never miss a tax write-off</Perk>
                <Perk>Personal walkthroughs of your tax return</Perk>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Image src="https://heard-images.s3.amazonaws.com/assets/annual-tax-teaser-cards.png" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Container
        style={{
          backgroundColor: Colors.moss,
          margin: 0,
          padding: '28px 48px',
        }}
      >
        <Grid>
          <Grid.Row verticalAlign="middle" columns={2}>
            <Grid.Column width={11}>
              <Text>You&apos;re currently on a Basic Subscription Plan</Text>
            </Grid.Column>
            <Grid.Column width={5}>
              <Button onClick={redirectToStripePortal}>
                Upgrade to Pro Plan
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  )
}

export default AnnualTaxesContent
