import { useEffect, useMemo, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { sortBy } from 'lodash'
import moment from 'moment'

import {
  Card,
  Dropdown,
  GridRowColumn,
  Input,
  Label,
  Link,
  Pagination,
  Table,
  Text,
} from '../../../components/BaseComponents'
import { PayrollProfile } from '../payrollProfile.slice'
import { fetchAllUsersIfNeeded } from '../../../actions/admin/adminAllUsersActions'
import { fetchPayrollUsers } from '../payrollActions'
import { useReselector } from '../../../utils/sharedHooks'
import { getAllUsersById, getUserById } from '../../../selectors/user.selectors'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { getStripeCustomerURL } from '../../Admin/UserRecord/AccountDetailsTabPanel'
import { useTableSort } from '../../../utils/sortHelpers'
import PayrollForecast from './PayrollForecast'
import { useAppDispatch } from '../../../utils/typeHelpers'

const PayrollRow = ({ profile }: { profile: PayrollProfile }) => {
  const user = useReselector(getUserById, profile.userId)

  const statusLabel = useMemo(() => {
    if (profile.onboardingCompleted) {
      return <Label color="green">Completed</Label>
    } else if (profile.needsMigration) {
      return <Label color="red">Needs Migration</Label>
    } else {
      return <Label color="red">Onboarding</Label>
    }
  }, [profile.needsMigration, profile.onboardingCompleted])

  if (!user) {
    return null
  }

  const joinedText = profile.onboardingCompleted
    ? profile.onboardedAt
      ? moment.utc(profile.onboardedAt).format(DATE_FORMATS.DISPLAY_SHORT)
      : 'Unknown'
    : 'Not Onboarded'

  const payrollType =
    profile.migratedFromGusto === true
      ? 'Migration'
      : profile.migratedFromGusto === false
        ? 'Net new'
        : 'Unknown'

  return (
    <Table.Row>
      <Table.Cell>
        <Text>
          <b>ID:</b>
          {'  '}
          <Link to={`/admin/finances/records/${user.id}`}>
            {user.id} {user.firstName} {user.lastName}
          </Link>
        </Text>
        <Text>
          <b>Gusto UUID:</b>
          {'  '}
          {profile.gustoCompanyUuid}
        </Text>
        <Text>
          <b>Business Name:</b>
          {'  '}
          {user.financialProfile?.businessName}
        </Text>
        {user.stripeCustomer && (
          <Link href={getStripeCustomerURL(user.stripeCustomer)} newPage>
            Stripe Customer Link
          </Link>
        )}
      </Table.Cell>
      <Table.Cell>
        <Text>
          <b>Date joined Heard Payroll:</b>
          {'  '}
          {joinedText}
        </Text>
        <Text>
          <b>Heard Payroll Type:</b>
          {'  '}
          {payrollType}
        </Text>
        <Text>
          <b>Number of Employees + Contractors Paid in Last Cycle:</b>
          {'  '}
          {profile.numberOfTeamMembers}
        </Text>
      </Table.Cell>
      <Table.Cell>{statusLabel}</Table.Cell>
    </Table.Row>
  )
}

enum SORT_BY {
  userId = 'userId',
  dateJoined = 'dateJoined',
  onboardingStatus = 'onboardingStatus',
}

enum FILTER_STATUS {
  completed = 'completed',
  onboarding = 'onboarding',
  needs_migration = 'useMigration',
}

const PayrollUsers = () => {
  const dispatch = useAppDispatch()
  const usersById = useReselector(getAllUsersById)
  const [payrollProfiles, setPayrollProfiles] = useState<PayrollProfile[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [filteredStatus, setFilteredStatus] = useState<
    FILTER_STATUS | undefined
  >(undefined)

  const filteredProfiles = useMemo(() => {
    let newProfiles = [...payrollProfiles]
    if (filteredStatus === FILTER_STATUS.completed) {
      newProfiles = newProfiles.filter((profile) => profile.onboardingCompleted)
    } else if (filteredStatus === FILTER_STATUS.needs_migration) {
      newProfiles = newProfiles.filter((profile) => profile.needsMigration)
    } else if (filteredStatus === FILTER_STATUS.onboarding) {
      newProfiles = newProfiles.filter(
        (profile) => !profile.onboardingCompleted && !profile.needsMigration
      )
    }

    if (searchValue) {
      newProfiles = newProfiles.filter((profile) => {
        const user = usersById[profile.userId]
        return (
          user &&
          (`${user.firstName} ${user.lastName}`
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
            user.financialProfile?.businessName
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()))
        )
      })
    }

    return newProfiles
  }, [filteredStatus, payrollProfiles, searchValue, usersById])

  const { sortedValues, getHeaderCellProps, paginationProps } = useTableSort({
    values: filteredProfiles,
    defaultSortBy: SORT_BY.userId,
    sortFunction: (profiles, sortByValue) => {
      if (sortByValue === SORT_BY.userId) {
        return sortBy(profiles, 'userId')
      } else if (sortByValue === SORT_BY.onboardingStatus) {
        return sortBy(profiles, (profile) => {
          if (profile.onboardingCompleted) {
            return 'completed'
          } else if (profile.needsMigration) {
            return 'needs migration'
          } else {
            return 'onboarding'
          }
        })
      } else {
        return sortBy(profiles, 'onboardedAt')
      }
    },
  })

  const onboardedUsersCount = useMemo(
    () => payrollProfiles.filter((pp) => pp.onboardingCompleted).length,
    [payrollProfiles]
  )

  const onboardingUsersCount = useMemo(
    () =>
      payrollProfiles.filter(
        (pp) => !pp.onboardingCompleted && pp.isActive && !pp.needsMigration
      ).length,
    [payrollProfiles]
  )

  const needsMigrationCount = useMemo(
    () =>
      payrollProfiles.filter(
        (pp) => !pp.onboardingCompleted && pp.isActive && pp.needsMigration
      ).length,
    [payrollProfiles]
  )

  useEffect(() => {
    const fetch = async () => {
      dispatch(fetchAllUsersIfNeeded(true, { includeFinancialProfile: true }))
      const res = await fetchPayrollUsers()(dispatch)

      if (res) {
        setPayrollProfiles(res)
      }
    }

    fetch()
  }, [dispatch])

  return (
    <Card>
      <Grid>
        <GridRowColumn>
          <Text as="h2">Heard Payroll Users</Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text>
            <b>Total Payroll:</b>
            {'  '}
            {payrollProfiles.length} Users
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text>
            <b>Onboarded users:</b>
            {'  '}
            {onboardedUsersCount} Users
          </Text>
          <Text>
            <b>Onboarding users:</b>
            {'  '}
            {onboardingUsersCount} Users
          </Text>
          <Text>
            <b>Needs migration:</b>
            {'  '}
            {needsMigrationCount} Users
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text as="h3">
            {moment().year()} Heard Payroll Forecasting + Performance
          </Text>
        </GridRowColumn>
        <GridRowColumn short>
          <PayrollForecast payrollProfiles={payrollProfiles} />
        </GridRowColumn>
        <Grid.Row />
        <Grid.Row>
          <Grid.Column width={8}>
            <Input
              label="Find User or Business:"
              value={searchValue}
              onChange={setSearchValue}
              fullWidth
              clearable
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Dropdown
              label="Filter by Status:"
              fullWidth
              value={filteredStatus}
              onChange={setFilteredStatus}
              clearable
              options={[
                {
                  text: 'Completed',
                  value: FILTER_STATUS.completed,
                },
                {
                  text: 'Onboarding',
                  value: FILTER_STATUS.onboarding,
                },
                {
                  text: 'Needs Migration',
                  value: FILTER_STATUS.needs_migration,
                },
              ]}
            />
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn>
          <Table striped sortable compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell {...getHeaderCellProps(SORT_BY.userId)}>
                  User
                </Table.HeaderCell>
                <Table.HeaderCell {...getHeaderCellProps(SORT_BY.dateJoined)}>
                  Payroll Details
                </Table.HeaderCell>
                <Table.HeaderCell
                  {...getHeaderCellProps(SORT_BY.onboardingStatus)}
                >
                  Payroll Onboarding Status
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {sortedValues.map((profile) => (
              <PayrollRow key={profile.id} profile={profile} />
            ))}
          </Table>
        </GridRowColumn>
        <GridRowColumn centered>
          <Pagination {...paginationProps} />
        </GridRowColumn>
      </Grid>
    </Card>
  )
}

export default PayrollUsers
