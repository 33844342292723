import { createSelector } from 'reselect'
import { map, filter, pickBy, orderBy, values } from 'lodash'

import { filterNulls, ReduxState } from '../utils/typeHelpers'
import {
  FinancialAccount,
  FinancialType,
} from '../reducers/finances/financialAccountsReducer'
import { FinancialAccountWithAdminInfo } from '../reducers/admin/financialAccountsReducer'
import { getAllTransactions } from '../features/Transactions/transactions.selectors'
import { formatAccountName } from '../features/Transactions/helpers'
import { ParentDropdownOption } from '../components/shared/groupedDropdownHelpers'
import { selectRolledOutInstitutionIds } from './user.selectors'
import {
  checkIsInRolledOutInstitutions,
  isHealthyInstitution,
  isManualPlaidStatementUploadAccount,
} from '../components/Finances/Accounts/helpers'
import {
  StatementStatus,
  StatementStatusType,
} from '../actions/financialAccountActions'

export const selectFinancialAccounts = (state: ReduxState) =>
  state.financialAccounts

export const selectTransactionEdits = (state: ReduxState) =>
  state.admin.allTransactionEdits

export const selectPlaidItems = (state: ReduxState) => state.plaidItems

export const selectUsersPlaidItems = (state: ReduxState) =>
  state.admin.plaidItems

export const selectPlaidStatementInstitutions = (state: ReduxState) =>
  state.plaidStatementInstitutions

export const selectDisconnectedFinancialAccounts = createSelector(
  selectFinancialAccounts,
  (accounts) =>
    Object.values(accounts).filter((fa) => !fa.accounts?.needsReconnection)
)

/**
 * Returns true if there are any accounts that are disconnected and were not marked as "can't reconnect"
 */
export const selectDisconnectedFinancialAccountsNeedAttention = createSelector(
  selectFinancialAccounts,
  (accounts) => {
    const needsAttention = Object.values(accounts).filter(
      (fa) =>
        fa.accounts?.needsReconnection && fa.accounts?.markedCannotReconnectAt
    )
    return needsAttention.length > 0
  }
)

export const selectPlaidFinancialAccounts = createSelector(
  [selectFinancialAccounts],
  (accounts) => {
    return pickBy(accounts, (val) => val.accountType === FinancialType.PLAID)
  }
)

export const selectActivePlaidFinancialAccounts = createSelector(
  [selectPlaidFinancialAccounts],
  (accounts) => Object.values(accounts).filter((account) => !account.inactive)
)

export const getTransactionSelector = createSelector(
  [
    getAllTransactions,
    (_: unknown, transactionId: number | string) => transactionId,
  ],
  (allTransactions, transactionId) =>
    allTransactions[transactionId] ? allTransactions[transactionId] : undefined
)

const getUsersTransactions = (state: ReduxState) => state.transactions.byId

export const getUsersTransactionsSelector = createSelector(
  [getUsersTransactions, (_: unknown, transactionId: number) => transactionId],
  (userTransactions, transactionId) => {
    return userTransactions[transactionId]
  }
)

export const getTransactionsByIdSelector = createSelector(
  [
    getAllTransactions,
    (_: unknown, transactionIds: number[] | string[]) => transactionIds,
  ],
  (allTransactions, transactionIds) =>
    transactionIds.map((transactionId) => allTransactions[transactionId])
)

const selectFinancialInsights = (state: ReduxState) => state.financialInsights

export const selectFinancialInsightsByUserId = createSelector(
  selectFinancialInsights,
  (_: unknown, userId: number | string | undefined) => userId,
  (insights, userId) => filter(insights, (insight) => insight.userId === userId)
)

export const getAdminFinancialAccounts = (state: ReduxState) =>
  state.admin.financialAccounts.byUserId

export const getUserFinancialAccounts = createSelector(
  getAdminFinancialAccounts,
  (_: unknown, userId: number | null | undefined | string) => userId,
  (financialAccounts, userId) => {
    return userId && financialAccounts[Number(userId)]
      ? financialAccounts[Number(userId)]
      : undefined
  }
)

export const getManualFinancialAccountsByUserId = createSelector(
  [getAdminFinancialAccounts, (_: unknown, userId: number) => userId],
  (fas, userId) => {
    const userAccounts = fas[userId]
    const accounts = Object.values(userAccounts || {})
    if (!accounts.length) return []
    return filterNulls(
      accounts.filter((account) => {
        return (
          account?.accountType === FinancialType.MANUAL ||
          account?.accountType === FinancialType.USER_MANUAL_UPLOAD
        )
      })
    )
  }
)

export const getLinkedManualAccountsByUserId = createSelector(
  [getAdminFinancialAccounts, (_: unknown, userId: number) => userId],
  (fas, userId) => {
    const userAccounts = fas[userId]
    const accounts = Object.values(userAccounts || {})
    if (!accounts.length) return []
    return filterNulls(
      accounts.filter((account) => {
        return (
          account?.accountType === FinancialType.PLAID && account.manualAccount
        )
      })
    )
  }
)

export const getManualUploadAccountsByUserId = createSelector(
  [getAdminFinancialAccounts, (_: unknown, userId: number) => userId],
  (fas, userId) => {
    const userAccounts = fas[userId]
    const accounts = Object.values(userAccounts || {})
    if (!accounts.length) return []
    return filterNulls(
      accounts.filter((account) => {
        return (
          ((account?.accountType === FinancialType.PLAID &&
            account.manualAccount) ||
            account?.accountType === FinancialType.MANUAL ||
            account?.accountType === FinancialType.USER_MANUAL_UPLOAD) &&
          !account?.inactive
        )
      })
    )
  }
)

export const getPlaidOnlyFinancialAccountsByUserId = createSelector(
  [getAdminFinancialAccounts, (_: unknown, userId: number) => userId],
  (fas, userId) => {
    const userAccounts = fas[userId]
    const accounts = Object.values(userAccounts || {})
    if (!accounts.length) return []
    return filterNulls(
      accounts.filter(
        (account) =>
          account?.accountType === FinancialType.PLAID && !account.manualAccount
      )
    )
  }
)

export const selectSortedFinancialAccounts = createSelector(
  getUserFinancialAccounts,
  (accounts) => {
    const sortedAccounts = accounts ? filterNulls(Object.values(accounts)) : []

    // Sort by inactive, id DESC
    sortedAccounts.sort((a, b) => b.id - a.id)
    sortedAccounts.sort((a, b) => Number(a.inactive) - Number(b.inactive))
    return sortedAccounts
  }
)

export const getUserFinancialAccountById = createSelector(
  getUserFinancialAccounts,
  (_: unknown, __: unknown, accountId: number | null | undefined) => accountId,
  (accounts, id) => (accounts && id && accounts[id] ? accounts[id] : undefined)
)

export const selectFinancialAccountById = createSelector(
  selectFinancialAccounts,
  (_: unknown, id: number | null | undefined) => id,
  (accounts, id) => (accounts && id && accounts[id] ? accounts[id] : undefined)
)

const mapFinancialAccountsToOptions = (
  finAccounts:
    | { [p: number]: FinancialAccount }
    | { [p: number]: FinancialAccountWithAdminInfo | undefined }
) => {
  // turn object into array
  const mappedAccounts = filterNulls(
    map(finAccounts, (finAccount) => finAccount)
  )
  // put inactive accounts at the bottom of the array
  const sortedAccounts = orderBy(mappedAccounts, (a) => a.inactive, 'asc')
  /*
    if the account is inactive, don't add it to returned array if
    there is another active account with the same name and mask
  */
  const filteredAccounts = []
  for (const account of sortedAccounts) {
    const { id, name, mask, inactive } = account
    if (inactive) {
      const containsMatch = sortedAccounts.some(
        (a) => !a.inactive && a.name === name && a.mask === mask
      )
      if (containsMatch) continue
    }
    filteredAccounts.push({
      value: Number(id),
      text: formatAccountName(account),
    })
  }
  return filteredAccounts
}

const mapFinancialAccountsToGroupedOptions = (
  finAccounts:
    | { [p: number]: FinancialAccount }
    | { [p: number]: FinancialAccountWithAdminInfo | undefined }
) => {
  const result: {
    [id: number | string]: ParentDropdownOption<number | string>
  } = {}
  // turn object into array
  const mappedAccounts = filterNulls(
    map(finAccounts, (finAccount) => finAccount)
  )
  // put inactive accounts at the bottom of the array
  const sortedAccounts = orderBy(mappedAccounts, (a) => a.inactive, 'asc')
  // convert into grouped option format
  for (const account of mappedAccounts) {
    const {
      plaidInstitutionId,
      plaidInstitutionName,
      id,
      name,
      mask,
      inactive,
      statementPermissions,
    } = account
    if (inactive) {
      const containsMatch = sortedAccounts.some(
        (a) => !a.inactive && a.name === name && a.mask === mask
      )
      if (containsMatch) continue
    }
    const index = plaidInstitutionId || 'manual'
    if (!result[index]) {
      result[index] = {
        text: `${plaidInstitutionName}` || 'Manual Upload',
        value: index,
        options: [],
        statementPermissions,
      }
    }
    result[index].options.push({ text: formatAccountName(account), value: id })
  }
  return Object.values(result || {})
}

// Looks for financial accounts for a given user, first in admin table then own user.
// Users won't have data in the admin table, admins won't have own financial accounts data
// Usage `useReselector(selectAdminOrUserFinancialAccounts, USER_ID)`
const selectAdminOrUserFinancialAccounts = createSelector(
  selectFinancialAccounts,
  getUserFinancialAccounts,
  (ownFinAccounts, userFinAccounts) => userFinAccounts || ownFinAccounts
)

export const selectAdminOrUserFinancialAccountOptions = createSelector(
  selectAdminOrUserFinancialAccounts,
  mapFinancialAccountsToOptions
)

export const selectAdminOrUserGroupedFinancialAccountOptions = createSelector(
  selectAdminOrUserFinancialAccounts,
  mapFinancialAccountsToGroupedOptions
)

export const selectTransactionEditByTransactionId = createSelector(
  getTransactionSelector,
  selectTransactionEdits,
  (transaction, transactionEdits) =>
    transaction?.transactionEdits?.[0] &&
    typeof transaction?.transactionEdits[0] === 'number'
      ? transactionEdits[transaction.transactionEdits[0]]
      : null
)

export const selectAdminPlaidItemsByUserId = createSelector(
  selectUsersPlaidItems,
  (_: unknown, userId: number) => userId,
  (plaidItems, userId) => {
    const userPlaidItems = plaidItems?.[userId]
    return Object.values(userPlaidItems || {})
  }
)

export const hiddenAccountsFilter = (account: FinancialAccount) =>
  account.isHidden && !account.inactive

export const visibleAccountsFilter = (
  account: FinancialAccount,
  isInRolloutGroup: boolean
) => {
  if (isInRolloutGroup) return !account.isHidden && !account.inactive
  return !account.inactive
}

export const selectAdminVisibleAccounts = createSelector(
  selectUsersPlaidItems,
  (_: unknown, userId: number) => userId,
  (_: unknown, __: unknown, plaidItemId: number) => plaidItemId,
  (_: unknown, __: unknown, ___: unknown, isInRolloutGroup: boolean) =>
    isInRolloutGroup,
  (plaidItems, userId, plaidItemId, isInRolloutGroup) => {
    const userPlaidItem = plaidItems?.[userId]?.[plaidItemId]
    if (!userPlaidItem) return []
    const { accounts } = userPlaidItem
    return accounts.filter((account) => {
      return visibleAccountsFilter(account, isInRolloutGroup)
    })
  }
)

export const selectVisibleAccounts = createSelector(
  selectFinancialAccounts,
  (_: unknown, plaidItemId: number) => plaidItemId,
  (_: unknown, __: unknown, isInRolloutGroup: boolean) => isInRolloutGroup,

  (financialAccounts, plaidItemId, isInRolloutGroup) =>
    values(financialAccounts)
      .filter((account) => account.plaidItemId === plaidItemId)
      .filter((account) => visibleAccountsFilter(account, isInRolloutGroup))
)

export const selectAllVisibleAccounts = createSelector(
  selectFinancialAccounts,
  selectRolledOutInstitutionIds,
  (financialAccounts, rolledOutInstitutionIds) =>
    values(financialAccounts).filter((account) => {
      const isInRolloutGroup =
        account.plaidInstitutionId !== null &&
        checkIsInRolledOutInstitutions(
          values(rolledOutInstitutionIds),
          account.plaidInstitutionId
        )
      return visibleAccountsFilter(account, isInRolloutGroup)
    })
)

export const selectAdminHiddenAccounts = createSelector(
  selectUsersPlaidItems,
  (_: unknown, userId: number) => userId,
  (_: unknown, __: unknown, plaidItemId: number) => plaidItemId,
  (_: unknown, __: unknown, ___: unknown, isInRolloutGroup: boolean) =>
    isInRolloutGroup,
  (plaidItems, userId, plaidItemId, isInRolloutGroup) => {
    const userPlaidItem = plaidItems?.[userId]?.[plaidItemId]
    if (!userPlaidItem || !isInRolloutGroup) return []
    return userPlaidItem.accounts.filter((account) =>
      hiddenAccountsFilter(account)
    )
  }
)

export const selectHiddenAccounts = createSelector(
  selectFinancialAccounts,
  (_: unknown, plaidItemId: number) => plaidItemId,
  (_: unknown, __: unknown, isInRolloutGroup: boolean) => isInRolloutGroup,
  (financialAccounts, plaidItemId, isInRolloutGroup) => {
    if (!isInRolloutGroup) return []
    return values(financialAccounts)
      .filter((account) => account.plaidItemId === plaidItemId)
      .filter((account) => hiddenAccountsFilter(account))
  }
)

export const selectIsUserManualAccount = createSelector(
  selectFinancialAccountById,
  (account) => account?.accountId === FinancialType.USER_MANUAL_UPLOAD
)

export const selectActivePlaidStatementInstitutions = createSelector(
  selectPlaidStatementInstitutions,
  (plaidStatementInstitutions) =>
    filter(plaidStatementInstitutions, (psi) => !psi.supportEndedAt)
)

export const selectInstitutionSupportsPlaidStatements = createSelector(
  selectPlaidStatementInstitutions,
  (_: unknown, institutionId: string) => institutionId,
  (plaidStatementInstitutions, institutionId) => {
    return values(plaidStatementInstitutions).some(
      (psi) => psi.institutionId === institutionId && !psi.supportEndedAt
    )
  }
)

export const selectEligibleForPlaidStatements = createSelector(
  selectPlaidStatementInstitutions,
  selectPlaidItems,
  (_: unknown, enableLowerFrictionStatements: boolean) =>
    enableLowerFrictionStatements,
  (plaidStatementInstitutions, plaidItems, enableLowerFrictionStatements) => {
    const hasEligibleInstitution = values(plaidItems)
      .filter((plaidItem) =>
        plaidItem.accounts.some(
          (account) =>
            visibleAccountsFilter(account, true) &&
            isManualPlaidStatementUploadAccount(account)
        )
      )
      .some((plaidItem) =>
        values(plaidStatementInstitutions).some((psi) => {
          const isSupported =
            psi.institutionId === plaidItem.institutionId && !psi.supportEndedAt
          if (enableLowerFrictionStatements) {
            // Only unhealthy institutions are supported
            return isSupported && !isHealthyInstitution(psi.institutionId)
          }
          return isSupported
        })
      )
    return hasEligibleInstitution
  }
)

export const selectEligibleForLBA = createSelector(
  selectFinancialAccounts,
  (_: unknown, accountUploadStatuses?: StatementStatus[]) =>
    accountUploadStatuses,
  (financialAccounts, accountUploadStatuses) => {
    const lbaEligibleAccountIds = accountUploadStatuses
      ?.filter(
        (status) => status.status === StatementStatusType.LIMITED_BANK_ACCESS
      )
      .map((status) => status.id)

    const hasEligibleInstitution = values(financialAccounts).some(
      (account) =>
        lbaEligibleAccountIds?.includes(account.id) &&
        account.bankAccessEnabledAt === null
    )
    return hasEligibleInstitution
  }
)
