import React from 'react'
import { Label, Statistic } from 'semantic-ui-react'
import { Text } from '../../../../components/BaseComponents'
import {
  UserTaxCalculation,
  UserTaxCalculationStatuses,
} from '../userTaxCalculation.slice'
import { isoToUTCDateTime } from '../../../../utils/dateHelpers'

interface Props {
  calculations: UserTaxCalculation[]
  quarterNotificationDate?: string
  newUserCutOffAt?: string
}

const TaxCalculationStatistics: React.FC<Props> = ({
  calculations,
  newUserCutOffAt,
}) => {
  const releasedCount = React.useMemo(
    () =>
      calculations.filter(
        (c) =>
          c.status === UserTaxCalculationStatuses.savedAndNotified.toString()
      )?.length,
    [calculations]
  )

  const calculatedCount = React.useMemo(
    () =>
      calculations.filter(
        (c) => c.status === UserTaxCalculationStatuses.calculated.toString()
      )?.length,
    [calculations]
  )
  const notStartedCount = React.useMemo(
    () =>
      calculations.filter(
        (c) => c.status === UserTaxCalculationStatuses.notStarted.toString()
      )?.length,
    [calculations]
  )
  const readyToCalculateCount = React.useMemo(
    () =>
      calculations.filter((c) => {
        const reviewedSinceNewUserCutOff =
          c.taxProfileLastReviewedAt &&
          isoToUTCDateTime(c.taxProfileLastReviewedAt) >=
            isoToUTCDateTime(newUserCutOffAt || '').startOf('day')
        return (
          c.status === UserTaxCalculationStatuses.notStarted.toString() &&
          reviewedSinceNewUserCutOff &&
          c.booksClosedForPreviousMonths &&
          c.hasPaidAllTaxEstimates
        )
      })?.length,
    [calculations, newUserCutOffAt]
  )

  return (
    <Statistic.Group size="small" widths={3} style={{ margin: '1.5em 0' }}>
      <Statistic>
        <Statistic.Value>{releasedCount}</Statistic.Value>
        <Statistic.Label style={{ marginTop: '0.5em' }}>
          <Label
            size="small"
            color="green"
            content="complete"
            style={{ borderRadius: '13px', marginTop: '0.25em' }}
          />
        </Statistic.Label>
      </Statistic>
      <Statistic>
        <Statistic.Value>{calculatedCount}</Statistic.Value>
        <Statistic.Label style={{ marginTop: '0.5em' }}>
          <Label
            size="small"
            color="yellow"
            content="calculated"
            style={{ borderRadius: '13px', margin: '0.25em 0 0.5em' }}
          />
        </Statistic.Label>
      </Statistic>
      <Statistic>
        <Statistic.Value>{notStartedCount}</Statistic.Value>
        <Statistic.Label style={{ marginTop: '0.5em' }}>
          <Label
            size="small"
            color="black"
            content="not started"
            style={{ borderRadius: '13px', margin: '0.25em 0' }}
          />
          <Text as="eyebrow">{readyToCalculateCount} ready to calculate</Text>
        </Statistic.Label>
      </Statistic>
    </Statistic.Group>
  )
}

export default TaxCalculationStatistics
