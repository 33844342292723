import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Modal, Text } from '../../../../components/BaseComponents'
import { useSaveAnnualTaxFilingFormData } from '../helpers'
import { useAnalyticsTrack } from '../../../Amplitude'

const TaxSurveyOptInModal = ({
  open,
  close,
  year,
}: {
  open: boolean
  close: () => void
  year: string
}) => {
  const navigate = useNavigate()
  const saveTaxFiling = useSaveAnnualTaxFilingFormData(year)
  const track = useAnalyticsTrack()

  const onContinue = useCallback(async () => {
    track('clicked confirm opt back into tax prep')
    const res = await saveTaxFiling({
      optedOutAt: null,
      pretaxSurveySubmittedAt: null,
      surveyLastUpdatedAt: new Date().toISOString(),
    })

    if (res) {
      navigate(`/taxes/annual/annual_tax_needs/${year}`)
    }
  }, [navigate, saveTaxFiling, track, year])

  return (
    <Modal open={open} onClose={close} closeIcon size="tiny">
      <Modal.Header>Opt into taxes</Modal.Header>
      <Modal.Content>
        <Text>
          Great! We’d love to help. Let’s revisit the Tax Season Kickoff so we
          can better understand your tax needs for this season.
        </Text>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={close} variant="secondary">
          Cancel
        </Button>
        <Button onClick={onContinue}>Continue</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default TaxSurveyOptInModal
