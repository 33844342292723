import { List, Table } from 'semantic-ui-react'
import moment from 'moment'

import CurrencyFormatLabel from '../../../../components/shared/CurrencyFormatLabel'
import CalculationListItem from './CalculationListItem'
import CalculationTableLink from './CalculationTableLink'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectIsCalculatorDefaultVersion,
  selectIsSingleStateCalculation,
  selectUserTaxCalculationById,
} from '../../../../selectors/userTaxCalculation.selectors'
import { isAboundResponse } from '../userTaxCalculation.slice'
import { centsToDollars } from '../../../../utils/currencyHelpers'

const CalculationOutputsCell = ({ id }: { id: number }) => {
  const calc = useReselector(selectUserTaxCalculationById, id)
  const isSingleStateCalc = useReselector(selectIsSingleStateCalculation, id)
  const isDefaultBreakDown = useReselector(selectIsCalculatorDefaultVersion, id)

  if (!calc) {
    return null
  }

  const { calculatedAt, taxCalculationOutput, homeState } = calc

  //This cell should only show up for calculations created <= 2023-Q2. These calcs will be using the old
  //abound calculation, so if the taxCalculationOutput isn't of type taxCalculationOutput, just return nothing
  const federalTaxOutstanding = isAboundResponse(taxCalculationOutput)
    ? taxCalculationOutput?.data.federalTaxOutstanding
    : 0
  const stateIncomeTax = isAboundResponse(taxCalculationOutput)
    ? taxCalculationOutput?.data.stateIncomeTax
    : 0

  return (
    <Table.Cell verticalAlign="top">
      {isSingleStateCalc && (
        <List>
          <CalculationListItem
            header="Calculated At"
            value={
              calculatedAt &&
              moment(calculatedAt).format(DATE_FORMATS.DISPLAY_SHORT)
            }
          />
          <CalculationListItem
            header="Federal Annual"
            value={
              taxCalculationOutput?.data && (
                <CurrencyFormatLabel value={federalTaxOutstanding} />
              )
            }
          />
          <CalculationListItem
            header="Federal Quarterly"
            value={
              taxCalculationOutput?.data && (
                <CurrencyFormatLabel value={federalTaxOutstanding / 4} />
              )
            }
          />
          <CalculationListItem
            header={`${homeState} Annual`}
            value={
              taxCalculationOutput?.data && (
                <CurrencyFormatLabel value={stateIncomeTax} />
              )
            }
          />
          <CalculationListItem
            header={`${homeState} Quarterly`}
            value={
              taxCalculationOutput?.data && (
                <CurrencyFormatLabel value={stateIncomeTax / 4} />
              )
            }
          />

          {!isDefaultBreakDown && (
            <>
              <CalculationListItem
                header="Federal Tax Liability"
                value={
                  taxCalculationOutput?.data && (
                    <CurrencyFormatLabel
                      value={centsToDollars(
                        taxCalculationOutput?.data
                          .annualFederalIncomeTaxLiabilityInCents
                      )}
                    />
                  )
                }
              />
              <CalculationListItem
                header="State Tax Liability"
                value={
                  taxCalculationOutput?.data && (
                    <CurrencyFormatLabel
                      value={centsToDollars(
                        taxCalculationOutput?.data
                          .annualStateTaxLiabilityInCents
                      )}
                    />
                  )
                }
              />
            </>
          )}
        </List>
      )}
      {!isSingleStateCalc && <i>N/A (Multi-state filer)</i>}

      {isSingleStateCalc && calculatedAt && (
        <CalculationTableLink
          url={URL.createObjectURL(
            new Blob([JSON.stringify(taxCalculationOutput)], {
              type: 'text/json',
            })
          )}
          text="Calculation output"
        />
      )}
    </Table.Cell>
  )
}

export default CalculationOutputsCell
