import { useMemo, useEffect, useState } from 'react'
import { Divider, Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { debounce } from 'lodash'
import {
  FormikDateInput,
  FormikDropdown,
  FormikInput,
  FormikTextArea,
  Modal,
  Text,
  Button,
  GridRowColumn,
  getFieldName,
} from '../../../components/BaseComponents'
import {
  JournalEntry,
  JournalEntryLineItem,
  JournalEntryType,
  SubmitJournalEntryBody,
} from '../types'
import {
  formatCurrency,
  addCurrencyArray,
  dollarsToCents,
} from '../../../utils/currencyHelpers'
import {
  getJournalEntryInitialValues,
  COMMON_JOURNAL_ENTRY_TYPES,
} from '../service'
import {
  fetchSingleTransaction,
  FETCH_SINGLE_TRANSACTION_KEY,
} from '../../../actions/admin/transactionActions'
import { useReselector } from '../../../utils/sharedHooks'
import { selectIsFetchingForKeys } from '../../../reducers/fetch'
import Tooltip from '../../Taxes/QuarterlyTaxEstimates/components/Tooltip'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { valsToDropdownOptions } from '../../../components/BaseComponents/Dropdown'
import {
  updateJournalEntry,
  UPDATE_JOURNAL_ENTRY_KEY,
  createJournalEntry,
} from '../../../actions/admin/journalEntryActions'
import { JournalEntryLineInputs } from './JournalEntryLineInputs'
import {
  JournalEntryUpdateType,
  MaybeJournalEntry,
} from '../../../components/Admin/PaginatedTransactionTable/RowDrawer'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface EditJournalEntryModalProps {
  open: boolean
  onClose: () => void
  journalEntry?: JournalEntry
  transactionId?: string
  onUpdate?: (
    journalEntry: MaybeJournalEntry,
    type: JournalEntryUpdateType
  ) => void
}

export const EditJournalEntryModal = ({
  open,
  onClose,
  journalEntry,
  transactionId,
  onUpdate = (_: MaybeJournalEntry, _2?: JournalEntryUpdateType) => undefined,
}: EditJournalEntryModalProps) => {
  const loading = useReselector(selectIsFetchingForKeys, [
    UPDATE_JOURNAL_ENTRY_KEY,
    FETCH_SINGLE_TRANSACTION_KEY,
  ])
  const dispatch = useAppDispatch()
  const { userId } = useParams<{ userId: string }>()
  const currentUser = useReselector(getCurrentUser)
  const [transactionObj, setTransactionObj] = useState<{
    error?: string
    description: string
  }>({
    error: undefined,
    description: '',
  })
  const [journalEntryLinesToDelete, setJournalEntryLinesToDelete] = useState<
    number[]
  >([])

  const formik = useFormik({
    initialValues: getJournalEntryInitialValues(journalEntry, transactionId),
    onSubmit: async (values) => {
      if (!userId) {
        return
      }
      const body: SubmitJournalEntryBody = {
        details: values.details,
        journalEntryDate: new Date(values.journalEntryDate).toISOString(),
        lastUpdatedBy: currentUser?.id,
        userId: parseInt(userId),
      }
      if (!journalEntry?.automated) {
        if (values.type) {
          body.details = `${values.type} ${values.details}`
        }
        body.journalEntryLines = values.journalEntryLines.map((line) => {
          const formattedLine: Partial<JournalEntryLineItem> = {
            financialAccountId: null,
            transactionCategoryId: null,
          }
          const debitAmount = parseFloat(line.debitAmount)
          const creditAmount = parseFloat(line.creditAmount)
          if (debitAmount) {
            formattedLine.amountInCents = dollarsToCents(debitAmount)
            formattedLine.entryType = JournalEntryType.DEBIT
          } else {
            formattedLine.amountInCents = dollarsToCents(creditAmount)
            formattedLine.entryType = JournalEntryType.CREDIT
          }
          if (line.accountOrCategoryId?.includes('fin')) {
            formattedLine.financialAccountId = parseInt(
              line.accountOrCategoryId
            )
          } else if (line.accountOrCategoryId?.includes('cat')) {
            formattedLine.transactionCategoryId = parseInt(
              line.accountOrCategoryId
            )
          }
          if (line.id) {
            formattedLine.id = line.id
          }
          if (journalEntry?.id) {
            formattedLine.journalEntryId = journalEntry?.id
          }
          return formattedLine
        })
        body.transactionId = values.transactionId
          ? parseInt(values.transactionId)
          : undefined
        if (journalEntryLinesToDelete.length > 0) {
          body.journalEntryLinesToDelete = journalEntryLinesToDelete
        } else if (body.journalEntryLinesToDelete) {
          delete body.journalEntryLinesToDelete
        }
      }
      let res
      if (journalEntry) {
        res = await updateJournalEntry(journalEntry.id, body)(dispatch)
      } else {
        res = await createJournalEntry(body)(dispatch)
      }
      if (res) {
        onUpdate(
          res,
          journalEntry
            ? JournalEntryUpdateType.UPDATE
            : JournalEntryUpdateType.CREATE
        )
        onClose()
      }
    },
  })

  const debouncedGetTransaction = useMemo(
    () =>
      debounce(async (transId: string | undefined | null) => {
        if (journalEntry?.automated) return // dont call API if journal entry is automated (no transaction field shown)

        if (!transId) {
          return setTransactionObj({
            error: undefined,
            description: '',
          })
        }

        // if trans id has decimals, don't call API
        if (parseFloat(transId) % 1 !== 0) return

        const retrievedTransaction =
          await fetchSingleTransaction(transId)(dispatch)

        if (!retrievedTransaction || !retrievedTransaction.data) {
          return setTransactionObj({
            error: 'Transaction not found.',
            description: '',
          })
        }

        if (userId && retrievedTransaction.data.userId !== parseInt(userId)) {
          return setTransactionObj({
            error: 'Transaction is connected to a different user.',
            description: '',
          })
        }
        return setTransactionObj({
          error: undefined,
          description: retrievedTransaction.data.description.substring(0, 160), // only display up to first 160 chars so it fits on screen
        })
      }, 500),
    [dispatch, userId, journalEntry?.automated]
  )

  useEffect(() => {
    debouncedGetTransaction(formik.values.transactionId)
  }, [formik.values.transactionId, debouncedGetTransaction])

  useEffect(() => {
    if (formik.errors.transactionId && transactionObj.error) return
    if (formik.errors.transactionId !== transactionObj.error) {
      formik.setFieldError('transactionId', transactionObj.error)
      // if there is an error but field hasn't been touched yet, still show error state
      if (!formik.touched.transactionId) {
        formik.setFieldTouched('transactionId')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    transactionObj.error,
    formik.errors.transactionId,
    formik.touched.transactionId,
  ]) // requires formik but causes too many triggers

  const totals = useMemo(() => {
    const debitTotal = addCurrencyArray(
      formik.values.journalEntryLines.map((line) => line.debitAmount)
    )
    const creditTotal = addCurrencyArray(
      formik.values.journalEntryLines.map((line) => line.creditAmount)
    )
    return {
      debitTotal: formatCurrency(debitTotal),
      creditTotal: formatCurrency(creditTotal),
    }
  }, [formik.values])

  const showManualFields = !journalEntry?.automated

  const totalError =
    showManualFields && totals.debitTotal !== totals.creditTotal

  const headerText = journalEntry
    ? 'Edit journal entry'
    : 'Create journal entry'

  const submitButtonText = journalEntry ? 'Save Changes' : 'Create'

  return (
    <Modal size="small" open={open} onClose={onClose} closeIcon>
      <FormikProvider value={formik}>
        <Modal.Content style={{ padding: 32 }}>
          <Text as="h2" style={{ marginBottom: 32 }}>
            {headerText}
          </Text>

          <FormikDateInput
            name={getFieldName<typeof formik.values>('journalEntryDate')}
            label="Journal Entry Date"
            schema={yup.string().required('Required')}
            required
          />
          <Grid style={{ marginTop: 8, marginBottom: 8 }}>
            {showManualFields && (
              <JournalEntryLineInputs
                formik={formik}
                userId={userId}
                totals={totals}
                totalError={totalError}
                setJournalEntryLinesToDelete={setJournalEntryLinesToDelete}
              />
            )}
            <GridRowColumn short>
              <Divider />
            </GridRowColumn>
            {showManualFields && (
              <Grid.Row>
                <Grid.Column width={9}>
                  <FormikDropdown
                    name="type"
                    options={valsToDropdownOptions(COMMON_JOURNAL_ENTRY_TYPES)}
                    fullWidth
                    label={
                      <Text as="h3">
                        Type
                        <Tooltip
                          popup={{
                            title: 'Type',
                            body: 'Labels this journal entry as one of these common scenarios in the notes/details.',
                          }}
                        />
                      </Text>
                    }
                  />
                </Grid.Column>
                <Grid.Column width={7}>
                  <FormikInput
                    name="transactionId"
                    fullWidth
                    rightIcon={transactionObj.description ? 'check' : undefined}
                    iconColor="green"
                    schema={yup
                      .string()
                      .nullable()
                      .matches(
                        /^$|^[0-9]+$/, // number with decimals or empty string
                        'Please enter a valid number (no decimals)'
                      )}
                    type="number"
                    label={
                      <Text as="h3">
                        Transaction ID
                        <Tooltip
                          popup={{
                            title: 'Transaction ID',
                            body: 'Link this journal entry to a transaction by entering its transaction ID.',
                          }}
                        />
                      </Text>
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row className="short">
              <Grid.Column width={9}>
                <FormikTextArea
                  name={getFieldName<typeof formik.values>('details')}
                  label="Notes/Details"
                  schema={yup.string().nullable()}
                />
              </Grid.Column>
              <Grid.Column width={7} verticalAlign="top">
                {transactionObj.description && (
                  <Text>{transactionObj.description}</Text>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            variant="actionLink"
            style={{ marginRight: 24 }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            onClick={() => formik.handleSubmit()}
            loading={loading}
            disabled={totalError || Boolean(transactionObj.error)}
            type="submit"
          >
            {submitButtonText}
          </Button>
        </Modal.Actions>
      </FormikProvider>
    </Modal>
  )
}
