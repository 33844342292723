import { Grid } from 'semantic-ui-react'
import { Card, Text, GridRowColumn } from '../../BaseComponents'
import { getFetchError } from '../../../reducers/fetch'
import { useReselector } from '../../../utils/sharedHooks'
import { FETCH_INSIGHTS_SUMMARY_KEY } from './financeActions'

export const CurrentInfoCard = ({
  headerText,
  subheader,
  bottomLeftText,
  bottomRightText,
}: {
  headerText?: string | number
  subheader?: string | number
  bottomLeftText?: string | number
  bottomRightText?: string | number
}) => {
  const insightsSummaryError = useReselector(
    getFetchError,
    FETCH_INSIGHTS_SUMMARY_KEY
  )

  if (insightsSummaryError) {
    return (
      <Card
        type="subsection"
        backgroundColor="stone40"
        fullWidth
        style={{ height: '100%', padding: 20, minHeight: 149 }}
      >
        <Text as="h2" style={{ marginBottom: 4 }}>
          $ -
        </Text>
        <Text as="bodySm" color="darkGray">
          Something went wrong - please try again in a bit.
        </Text>
      </Card>
    )
  }
  return (
    <Card
      type="subsection"
      backgroundColor="stone40"
      fullWidth
      style={{ height: '100%', padding: 20, minHeight: 149 }}
    >
      <Grid>
        <GridRowColumn>
          <Text as="h2" style={{ marginBottom: 4 }}>
            {headerText}
          </Text>
          <Text as="bodySm" color="darkGray">
            {subheader}
          </Text>
        </GridRowColumn>
        <Grid.Row
          verticalAlign="bottom"
          style={{ position: 'absolute', bottom: 0, width: '100%' }}
        >
          <Grid.Column computer={9} tablet={8} mobile={8}>
            <Text as="bodySm" color="darkGray">
              {bottomLeftText}
            </Text>
          </Grid.Column>
          <Grid.Column computer={7} tablet={8} mobile={8}>
            <Text as="bodySm" color="darkGray">
              {bottomRightText}
            </Text>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}
