import moment from 'moment'
import { useState } from 'react'
import {
  Accordion,
  AccordionTitleProps,
  Divider,
  Grid,
} from 'semantic-ui-react'
import { Card, Icon, Label, Text } from '../../../../components/BaseComponents'
import Tooltip from './Tooltip'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { selectActiveQuarterlyTaxEstimateDetails } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { useReselector } from '../../../../utils/sharedHooks'

const headerStyle = {
  display: 'inline-block',
}

const dueDateStyle = {
  margin: '8 0',
}

const stepLabelStyle = {
  width: '48px',
  height: '48px',
  margin: '16px 0 12px 0',
}

const QTE_WITHHOLDING_ENABLED =
  process.env.VITE_QTE_WITHHOLDING_ENABLED === 'true'

const QTETimeline = () => {
  const activeQuarterDetails = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )
  const {
    taxQuarter,
    clientNotifiedAt: notifiedAt,
    irsPaymentDueAt,
    calculationStartsAt,
    newUserCutOffAt,
  } = activeQuarterDetails ?? {}

  const [accordionValue, setAccordionValue] = useState<number | null>(0)

  const handleAccordionClick = (_e: unknown, data: AccordionTitleProps) => {
    const { taxquarter } = data
    if (taxquarter === accordionValue) {
      setAccordionValue(null)
    } else {
      setAccordionValue(taxquarter)
    }
  }

  if (!activeQuarterDetails) {
    return null
  }
  return (
    <Card backgroundColor="stone40" type="body">
      <Grid>
        <Grid.Row verticalAlign="middle">
          <Grid.Column>
            <Accordion>
              <Accordion.Title
                style={{
                  padding: '0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                taxquarter={0}
                active={accordionValue === 0}
                onClick={handleAccordionClick}
              >
                <Text style={headerStyle} as="h2">
                  Timeline
                </Text>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip
                    style={{ float: 'right', marginRight: '10px' }}
                    popup={{
                      title: 'Timeline',
                      body: 'This section helps you keep track of where you are in the quarterly tax process.',
                      link: 'https://support.joinheard.com/hc/en-us/sections/4416200689303-Quarterly-Taxes',
                    }}
                    labelComponent={
                      <Icon
                        size="1x"
                        name="question circle outline"
                        icon={regular('question-circle')}
                        color="mediumGray"
                      />
                    }
                  />
                  <Icon
                    size="1x"
                    style={{ float: 'right' }}
                    icon={accordionValue === 0 ? solid('minus') : solid('plus')}
                  />
                </div>
              </Accordion.Title>
              <Accordion.Content active={accordionValue === 0}>
                {/* Step 1 */}
                <div>
                  <Label style={stepLabelStyle} $circular>
                    <Text as="bodyMd">1</Text>
                  </Label>
                  <Text style={dueDateStyle} as="h3">
                    {QTE_WITHHOLDING_ENABLED ? (
                      <>
                        Between{' '}
                        {moment
                          .utc(newUserCutOffAt)
                          .format(DATE_FORMATS.DISPLAY_LONG)}{' '}
                        and{' '}
                      </>
                    ) : (
                      'By '
                    )}
                    {moment
                      .utc(calculationStartsAt)
                      .subtract(1, 'day')
                      .format(DATE_FORMATS.DISPLAY_LONG)}
                  </Text>
                  <Text>
                    {QTE_WITHHOLDING_ENABLED
                      ? 'Complete your quarterly checklist'
                      : 'Update your tax information'}
                  </Text>
                  <Divider style={{ margin: '24px 0' }} />
                </div>

                {/* Step 2 */}
                <div>
                  <Label style={stepLabelStyle} $circular>
                    <Text as="bodyMd">2</Text>
                  </Label>
                  <Text style={dueDateStyle} as="h3">
                    By{' '}
                    {moment.utc(notifiedAt).format(DATE_FORMATS.DISPLAY_LONG)}
                  </Text>
                  <Text>Heard sends you your Q{taxQuarter} estimates</Text>
                  {QTE_WITHHOLDING_ENABLED && (
                    <Text style={{ marginTop: 8 }}>
                      (if quarterly checklist was completed by deadline)
                    </Text>
                  )}
                  <Divider />
                </div>

                {/* Step 3 */}
                <div>
                  <Label style={stepLabelStyle} $circular>
                    <Text as="bodyMd">3</Text>
                  </Label>
                  <Text style={dueDateStyle} as="h3">
                    By{' '}
                    {moment
                      .utc(irsPaymentDueAt)
                      .format(DATE_FORMATS.DISPLAY_LONG)}
                  </Text>
                  <Text>
                    Submit your quarterly tax payments to the IRS and your home
                    state
                  </Text>
                </div>
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

export default QTETimeline
