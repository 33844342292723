import { captureMessage, captureException } from '@sentry/react'

import { getStore } from '../store'
import { getUserId } from '../reducers/auth/user.selectors'

export const logSentryError = (error: unknown) => {
  const state = getStore()?.getState()
  const userId = state ? getUserId(state) : undefined

  captureException(error, { user: { id: userId?.toString() } })
}

// This should be coming from Sentry but it's not being exported properly
interface ScopeContext {
  User: {
    id?: string | number
  }
  extra: Record<string, unknown>
  tags: {
    [key: string]: string
  }
}

export const logSentryMessage = (
  message: string,
  captureContext?: Partial<ScopeContext>
) => {
  const state = getStore()?.getState()
  const userId = state ? getUserId(state) : undefined

  captureMessage(message, {
    ...captureContext,
    user: { id: userId?.toString() },
  })
}
