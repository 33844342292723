import { createSelector } from 'reselect'
import {
  chain,
  filter,
  flatten,
  groupBy,
  isNil,
  orderBy,
  pickBy,
  sortBy,
} from 'lodash'
import { Moment } from 'moment/moment'
import moment from 'moment-timezone'

import { filterNulls, ReduxState } from '../../utils/typeHelpers'
import {
  Transaction,
  TransactionCategoryIdentifier,
} from '../../reducers/admin/allTransactions.slice'
import { TRANSACTIONS_SORT_BY } from '../../reducers/finances/transactionFiltersReducer'
import { getAllTransactionCategories } from '../Reports/reports.selectors'
import { TransactionRuleUse } from '../../reducers/admin/transactionRulesReducer'
import { DATE_FORMATS, isoToUTCDateTime } from '../../utils/dateHelpers'
import { addCurrencyArray } from '../../utils/currencyHelpers'
import { getCurrentUser } from '../../selectors/user.selectors'
import { isTransactionLocked } from './helpers'
import { selectActiveQuarterlyTaxEstimateDetails } from '../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { DateTime } from 'luxon'

export const getUsersTransactions = (state: ReduxState) =>
  state.transactions.byId

const getUsersTransactionsOrdered = (state: ReduxState) =>
  state.transactions.allIds
export const getByTransactionCategoryId = (state: ReduxState) =>
  state.transactions.byTransactionCategoryId

const getAdminAllTransactions = (state: ReduxState) =>
  state.admin.allTransactions

const getAdminTransactionsById = createSelector(
  getAdminAllTransactions,
  (allTransactionState) => allTransactionState.byId
)

const getAdminByTransactionCategoryId = createSelector(
  getAdminAllTransactions,
  (allTransactionState) => allTransactionState.byTransactionCategoryId
)

export const selectAdminQueriedTransactionIds = createSelector(
  getAdminAllTransactions,
  (allTransactionState) => allTransactionState.queriedTransactionIds
)

// Returns transaction ids that can be bulk edited (ie not disabled)
export const selectEnabledAdminQueriedTransactionIds = createSelector(
  getAdminTransactionsById,
  selectAdminQueriedTransactionIds,
  (transactionMap, queriedTransactions) =>
    queriedTransactions.filter((id) => !transactionMap[id]?.confirmedAt)
)

export const selectAdminQueriedTransactionsCount = createSelector(
  getAdminAllTransactions,
  (allTransactionState) => allTransactionState.queriedTransactionsCount
)

const getAdminTransactionsForUserIdByCategoryId = createSelector(
  getAdminByTransactionCategoryId,
  (_: unknown, userId: number | null) => userId,
  (adminTransactionsByCategoryId, userId) => {
    return userId && adminTransactionsByCategoryId[userId]
      ? adminTransactionsByCategoryId[userId]
      : null
  }
)

export const getUserTransactionById = createSelector(
  [
    getUsersTransactions,
    (_: unknown, id: string | number | undefined) => id,
    (_: unknown, __: unknown, splitIndex?: number) => splitIndex,
  ],
  (userTransactions, id, splitIndex) => {
    if (!id || !userTransactions) return null
    if (
      typeof splitIndex === 'number' &&
      userTransactions[id]?.splitTransactions
    )
      return userTransactions[id].splitTransactions[splitIndex]
    return userTransactions[id]
  }
)

export const getUserNeedsClarificationTransactionsSelector = createSelector(
  [getUsersTransactions],
  (userTransactions) =>
    filter(
      userTransactions,
      (transaction) => transaction.requestedClarificationAt !== null
    )
)

const filterForYear = (year: string, transactions: Transaction[]) =>
  filter(
    transactions,
    (transaction) => moment.utc(transaction.date).year().toString() === year
  )

const getUserNeedsClarificationTransactionsWithoutUserNote = createSelector(
  [getUserNeedsClarificationTransactionsSelector],
  (transactions) => transactions.filter((t) => !t.notes)
)

export const getUserNeedsClarificationTransactionsWithoutUserNoteForYear =
  createSelector(
    [
      getUserNeedsClarificationTransactionsWithoutUserNote,
      (_: unknown, year: string | undefined) => year,
    ],
    (needsClarificationTransactions, year) =>
      year
        ? filterForYear(year, needsClarificationTransactions)
        : needsClarificationTransactions
  )

const getUserUncategorizedAndNotPersonalTransactionsSelector = createSelector(
  [getUsersTransactions],
  (userTransactions) =>
    filter(
      userTransactions,
      (transaction) =>
        !transaction.transactionCategoryId && transaction.type !== 'personal'
    )
)

const getUserUncategorizedAndNotPersonalTransactionsWithoutUserNote =
  createSelector(
    [getUserUncategorizedAndNotPersonalTransactionsSelector],
    (uncategorizedTransactions) =>
      uncategorizedTransactions.filter((t) => !t.notes)
  )

export const getUserUncategorizedAndNotPersonalTransactionsWithoutUserNoteForYear =
  createSelector(
    [
      getUserUncategorizedAndNotPersonalTransactionsWithoutUserNote,
      (_: unknown, year: string | undefined) => year,
    ],
    (uncategorizedTransactions, year) =>
      year
        ? filterForYear(year, uncategorizedTransactions)
        : uncategorizedTransactions
  )

export const getUserTransactionsByCategoryName = createSelector(
  [
    getUsersTransactions,
    getAllTransactionCategories,
    (_: unknown, categoryName: string) => categoryName,
  ],
  (userTransactions, allTransactionCategories, categoryName) => {
    if (!allTransactionCategories) {
      return []
    }

    const allTransactionCategoriesArray = Object.values(
      allTransactionCategories
    )

    if (allTransactionCategoriesArray.length === 0) {
      return []
    }
    const transactionCategoryId = allTransactionCategoriesArray.find(
      (c) => c.name === categoryName
    )?.id
    const transactionsArray = Object.values(userTransactions)

    if (transactionCategoryId) {
      return transactionsArray.filter(
        (t) => t.transactionCategoryId === transactionCategoryId
      )
    }

    return []
  }
)

const selectTransactionsByCategoryIdentifier = createSelector(
  getUsersTransactions,
  getAllTransactionCategories,
  (_: unknown, categoryIdentifier: TransactionCategoryIdentifier) =>
    categoryIdentifier,
  (userTransactions, allTransactionCategories, categoryIdentifier) => {
    const allTransactionCategoriesArray = Object.values(
      allTransactionCategories
    )

    const transactionCategoryId = allTransactionCategoriesArray.find(
      (c) => c.identifier === categoryIdentifier
    )?.id

    if (transactionCategoryId) {
      return Object.values(userTransactions).filter(
        (t) => t.transactionCategoryId === transactionCategoryId
      )
    }

    return []
  }
)

export const getUserTransactionsByCategoryNameForYear = createSelector(
  [
    getUserTransactionsByCategoryName,
    (_: unknown, __: string, year?: string) => year,
  ],
  (transactions, year) =>
    year ? filterForYear(year, transactions) : transactions
)

export const selectUserTransactionsByCategoryIdentifierForYear = createSelector(
  [
    selectTransactionsByCategoryIdentifier,
    (_: unknown, __: string, year?: string) => year,
  ],
  (transactions, year) =>
    year ? filterForYear(year, transactions) : transactions
)

export const getAllTransactionFilters = (state: ReduxState) =>
  state.transactionFilters

export const filterUserTransactions = createSelector(
  [
    getAllTransactionFilters,
    (_: unknown, transactionType: string | number) => transactionType,
    getUsersTransactions,
    getUsersTransactionsOrdered,
  ],
  (filters, transactionType, userTransactions, orderedTransactions) => {
    let transactionsList = filterNulls(
      orderedTransactions.map((transactionId) =>
        userTransactions[Number(transactionId)]
          ? userTransactions[Number(transactionId)]
          : null
      )
    )

    switch (transactionType) {
      case 'all':
        break

      case 'business':
        transactionsList = transactionsList.filter((transaction) => {
          return transaction.type && transaction.type === 'business'
        })
        break
      case 'personal':
        transactionsList = transactionsList.filter((transaction) => {
          return transaction.type && transaction.type === 'personal'
        })
        break
      case 'review':
        transactionsList = transactionsList.filter((transaction) => {
          return transaction.requestedClarificationAt
        })
        break

      default:
        break
    }

    if (filters.startDate) {
      const startDate = moment
        .utc(filters.startDate, 'MM-DD-YYYY')
        .startOf('day')
      transactionsList = filter(transactionsList, (transaction) => {
        return moment.utc(transaction.date).isSameOrAfter(startDate)
      })
    }
    if (filters.endDate) {
      const endDate = moment.utc(filters.endDate, 'MM-DD-YYYY').endOf('day')

      transactionsList = filter(transactionsList, (transaction) =>
        moment.utc(transaction.date).isSameOrBefore(endDate)
      )
    }

    if (filters.transactionCategoryId) {
      const filterCatId =
        Number(filters.transactionCategoryId) === -1
          ? null
          : Number(filters.transactionCategoryId)

      transactionsList = filter(transactionsList, (transaction) => {
        // Include split transactions that have sub transaction with category
        if (transaction.splitTransactions?.length) {
          return transaction.splitTransactions.some(
            (splitTransaction) =>
              splitTransaction.transactionCategoryId === filterCatId
          )
        } else {
          return transaction.transactionCategoryId === filterCatId
        }
      })

      // Filter split transactions by category
      transactionsList = transactionsList.map((transaction) => ({
        ...transaction,
        splitTransactions: transaction.splitTransactions?.filter(
          (splitTransaction) =>
            splitTransaction.transactionCategoryId === filterCatId
        ),
      }))

      // If we're attempting to filter for uncategorized items don't items don't include personal transactions since
      if (!filterCatId) {
        transactionsList = transactionsList.filter(
          (transaction) => transaction.type !== 'personal'
        )
      }
    }

    if (filters.financialAccountId) {
      transactionsList = filter(
        transactionsList,
        (transaction) =>
          transaction.financialAccountId === filters.financialAccountId
      )
    }

    transactionsList = sortBy(transactionsList, (transaction) => {
      switch (filters.sortVal) {
        case TRANSACTIONS_SORT_BY.DATE:
          return new Date(transaction.date).getTime() * -1
        case TRANSACTIONS_SORT_BY.DESCRIPTION:
          return transaction.description.toLowerCase()
        case TRANSACTIONS_SORT_BY.AMOUNT:
          return transaction.amountInCents
        case TRANSACTIONS_SORT_BY.ACCOUNT:
          return transaction.financialAccountId
        case TRANSACTIONS_SORT_BY.TYPE:
          return transaction.type
        case TRANSACTIONS_SORT_BY.CATEGORY:
          if (transaction.type === 'personal') {
            return 'personal'
          }
          return transaction.transactionCategory?.name.toLowerCase()
        case TRANSACTIONS_SORT_BY.ID:
        default:
          return transaction.id
      }
    })

    if (filters.sortAsc) {
      transactionsList = transactionsList.reverse()
    }

    // Count length AFTER we filter but BEFORE we truncate by page
    const count = Object.keys(transactionsList).length

    // Now that we've safely counted, we can paginate
    if (filters.page) {
      transactionsList = transactionsList.slice(
        (Number(filters.page) - 1) * Number(filters.limit),
        Number(filters.page) * Number(filters.limit)
      )
    }

    return {
      transactions: transactionsList,
      count,
    }
  }
)

const DEFAULT_CATEGORY = {
  transactionIds: [],
  totalCount: 0,
}

export const getProfitAndLossTransactionsByCategoryId = createSelector(
  getByTransactionCategoryId,
  getAdminTransactionsForUserIdByCategoryId,
  (_: unknown, userId: number | null) => userId,
  (_: unknown, __: unknown, transactionCategoryId: number | null) =>
    transactionCategoryId,
  (transactionsByCategoryId, adminTransactionsForUser, userId, categoryId) => {
    // Admin path
    if (userId) {
      return (
        adminTransactionsForUser?.[categoryId || 'null'] || DEFAULT_CATEGORY
      )
    }
    // User path
    return transactionsByCategoryId[categoryId || 'null'] || DEFAULT_CATEGORY
  }
)

// Transaction redux needs to be refactored, but this is a band aid to find any transactions that may be in split transactions
const findTransactionOrSplitTransaction = (
  transactionsById: { [key: string]: Transaction },
  transactionId: number
) => {
  if (transactionsById[transactionId]) {
    return transactionsById[transactionId]
  }

  for (const transaction of Object.values(transactionsById)) {
    const matchingSplitTransaction = transaction.splitTransactions?.find(
      (innerTransaction) => innerTransaction.id === transactionId
    )
    if (matchingSplitTransaction) {
      return matchingSplitTransaction
    }
  }

  return undefined
}

export const getProfitAndLossTransaction = createSelector(
  [
    getUsersTransactions,
    getAdminTransactionsById,
    (_: unknown, userId: number | null) => userId,
    (_: unknown, __: unknown, transactionId: number) => transactionId,
  ],
  (userTransactions, adminTransactionById, userId, transactionId) => {
    if (userId) {
      return findTransactionOrSplitTransaction(
        adminTransactionById,
        transactionId
      )
    }

    return findTransactionOrSplitTransaction(userTransactions, transactionId)
  }
)

export const selectQueriedTransactions = createSelector(
  getAdminTransactionsById,
  selectAdminQueriedTransactionIds,
  (transactions, ids) =>
    Object.values(transactions).filter((transaction) =>
      ids.includes(transaction.id)
    )
)

// Do not use these for counts because queried transactions are paginated.  use `fetchTransactionCounts` instead
export const selectQueriedPendingTransactions = createSelector(
  selectQueriedTransactions,
  (queriedTransactions) =>
    queriedTransactions.filter((transaction) => !transaction.confirmedAt)
)

export const selectUncategorizedTransactions = createSelector(
  selectQueriedTransactions,
  (queriedTransactions) =>
    queriedTransactions.filter(
      (transaction) => !transaction.transactionCategoryId
    )
)

export const selectRequestedClarificationTransactions = createSelector(
  selectQueriedTransactions,
  (queriedTransactions) =>
    queriedTransactions.filter(
      (transaction) => transaction.requestedClarificationAt
    )
)

export const selectUncategorizedTransactionsNotNeedClarification =
  createSelector(
    getUserUncategorizedAndNotPersonalTransactionsWithoutUserNoteForYear,
    (transactions) =>
      transactions.filter((t) => t.requestedClarificationAt === null)
  )

export const distinctTransactionsByDateDESC = (transactions: Transaction[]) =>
  chain(transactions)
    .uniqBy((t) => t.id)
    .sortBy((t) => -new Date(t.date))
    .value()

export const selectUncategorizedAndNeedsClarificationTransactions =
  createSelector(
    [
      getUserUncategorizedAndNotPersonalTransactionsWithoutUserNoteForYear,
      getUserNeedsClarificationTransactionsWithoutUserNoteForYear,
    ],
    (uncategorized, needsClarification) =>
      distinctTransactionsByDateDESC([...uncategorized, ...needsClarification])
  )

export const getAllTransactions = (state: ReduxState) =>
  state.admin.allTransactions.byId

const getAllTransactionList = createSelector(
  getAllTransactions,
  (transactions) => Object.values(transactions)
)

export const getTransactionsByUserId = createSelector(
  getAllTransactionList,
  (_: unknown, userId: number | string | undefined) => userId,
  (transactions, userId) =>
    userId
      ? transactions.filter(
          (trans) => trans.userId.toString() === userId.toString()
        )
      : []
)

export const selectTransactionRulesById = (state: ReduxState) =>
  state.admin.transactionRules

export const selectTransactionRulesByType = createSelector(
  [selectTransactionRulesById, (_: unknown, type: string) => type],
  (rules, type) => {
    if (type === 'active') {
      return filter(rules, (rule) => rule?.archivedAt === null)
    } else {
      return rules
    }
  }
)

export const selectSimilarRules = createSelector(
  selectTransactionRulesById,
  (_: unknown, newRuleUse: TransactionRuleUse | undefined) => newRuleUse,
  (_: unknown, __: unknown, newRuleText: string | null | undefined) =>
    newRuleText,
  (allRules, newRuleUse, newRuleText) => {
    if (!newRuleUse || !newRuleText) {
      return []
    }

    const similarNameRoles = Object.values(allRules).filter(({ ruleText }) =>
      ruleText.toLowerCase().includes(newRuleText.toLowerCase())
    )

    if (newRuleUse === TransactionRuleUse.CATEGORIZATION) {
      return similarNameRoles
    }

    return similarNameRoles.filter(
      ({ ruleUse }) => ruleUse === TransactionRuleUse.CLARIFICATION
    )
  }
)

export const getNeedsClarificationTransactionsForUserSelector = createSelector(
  getTransactionsByUserId,
  (userTransactions) =>
    userTransactions.filter(
      (transaction) => transaction?.requestedClarificationAt !== null
    )
)

export const getUncategorizedTransactionsForUserSelector = createSelector(
  getTransactionsByUserId,
  (userTransactions) =>
    userTransactions.filter(
      (transaction) =>
        transaction.transactionCategoryId === null &&
        transaction.excludedAt === null
    )
)

export const getUnconfirmedTransactionsForUserSelector = createSelector(
  getTransactionsByUserId,
  (userTransactions) =>
    userTransactions.filter((transaction) => {
      return (
        transaction.confirmedAt === null &&
        transaction.type &&
        transaction.excludedAt === null
      )
    })
)

export const getGroupedImportedTransactionsForUser = createSelector(
  getTransactionsByUserId,
  (userTransactions) => {
    const importedTransactions = userTransactions.filter((transaction) =>
      Boolean(transaction.importedOn)
    )

    return groupBy(importedTransactions, (transaction) =>
      moment.utc(transaction.importedOn).format(DATE_FORMATS.DISPLAY_SHORT)
    )
  }
)

// Financial Accounts

//////////////////////////////
/// User Facing Selectors ////
//////////////////////////////d

export const getUserTransactionsSelector = createSelector(
  [
    getUsersTransactions,
    (_: unknown, args: { startDate: Moment; endDate: Moment }) => args,
  ],
  (userTransactions, args) => {
    const filteredTransactions = filter(userTransactions, (transaction) => {
      const transactionDateInTimezone = moment.utc(transaction.date)

      return transactionDateInTimezone.isBetween(
        args.startDate,
        args.endDate,
        undefined,
        '[]'
      )
    })

    return sortBy(filteredTransactions, 'date')
  }
)

export const selectFilteredGroupedUserTransactions = createSelector(
  getUserTransactionsSelector,
  getAllTransactionCategories,
  (transactions, categories) => {
    const flattenedTransactions = transactions.flatMap((txn) => {
      if (txn.splitTransactions && txn.splitTransactions.length > 0) {
        return txn.splitTransactions
      }
      return txn
    })
    const groupedTransactions = groupBy(
      // Filter out transactions that shouldn't be shown
      flattenedTransactions.filter(
        (t) => !t.deletedAt && !t.excludedAt && !t.splitAt
      ),
      'transactionCategoryId'
    )

    const groupedIncome = pickBy(
      groupedTransactions,
      (_, key) => categories[key]?.accountType === 'Income'
    )

    // Grouped transactions without a category are under 'null'.  Find those that are over 0
    const unCatIncome =
      groupedTransactions['null']?.filter(
        ({ amountInCents }) => amountInCents > 0
      ) || []

    // Don't add the category unless there are transactions in it
    if (unCatIncome.length) {
      groupedIncome['null'] = unCatIncome
    }

    const groupedExpenses = pickBy(
      groupedTransactions,
      (_, key) => categories[key]?.accountType === 'Expenses'
    )

    const unCatExpenses =
      groupedTransactions['null']?.filter(
        ({ amountInCents }) => amountInCents < 0
      ) || []

    if (unCatExpenses.length) {
      groupedExpenses['null'] = unCatExpenses
    }

    const totalIncome = addCurrencyArray(
      flatten(Object.values(groupedIncome)).map((trans) => trans.amountInCents)
    )
    const totalExpenses = addCurrencyArray(
      flatten(Object.values(groupedExpenses)).map(
        (trans) => trans.amountInCents
      )
    )

    return {
      groupedIncome,
      groupedExpenses,
      totalIncome,
      totalExpenses,
    }
  }
)

export const getTransactionsByType = createSelector(
  [
    getUsersTransactions,
    (_: unknown, transactionType: string) => transactionType,
  ],
  (userTransactions, transactionType) => {
    switch (transactionType) {
      case null:
        return Object.values(userTransactions)
      case 'uncategorized':
        return filter(
          userTransactions,
          (transaction) => transaction.transactionCategoryId === null
        )
      case 'income':
        return filter(
          userTransactions,
          (transaction) =>
            !isNil(transaction.transactionCategory) &&
            ['Income'].includes(transaction.transactionCategory.accountType)
        )
      case 'businessExpenses':
        return filter(
          userTransactions,
          (transaction) =>
            !isNil(transaction.transactionCategory) &&
            ['Expenses'].includes(
              transaction.transactionCategory.accountType
            ) &&
            transaction.type === 'business'
        )
      case 'categorizedExpenses':
        return filter(
          userTransactions,
          (transaction) =>
            !isNil(transaction.transactionCategory) &&
            !isNil(transaction.transactionCategoryId) &&
            ['Expenses'].includes(transaction.transactionCategory.accountType)
        )
      case 'categorized':
        return filter(
          userTransactions,
          (transaction) =>
            !isNil(transaction.transactionCategory) &&
            !isNil(transaction.transactionCategoryId)
        )
      default:
        return Object.values(userTransactions)
    }
  }
)

export const getTransactionsByCategory = createSelector(
  [
    getUsersTransactions,
    (_: unknown, transactionCategoryId: string | number | null | undefined) =>
      transactionCategoryId,
  ],
  (transactions, transactionCategoryId) => {
    if (!transactionCategoryId) return []
    let filteredTransactions = filter(
      transactions,
      (transaction) =>
        Number(transaction.transactionCategoryId) ===
        Number(transactionCategoryId)
    )
    filteredTransactions = orderBy(filteredTransactions, ['date'], ['desc'])
    return filteredTransactions
  }
)

export const selectTransactionsByCategoryAndYear = createSelector(
  getTransactionsByCategory,
  (_: unknown, __: unknown, year: string | number) => year,
  (transactions, year) =>
    transactions.filter(
      (transaction) =>
        year === 'all' ||
        moment(transaction.date).isBetween(
          moment().year(Number(year)).startOf('year'),
          moment().year(Number(year)).endOf('year')
        )
    )
)

export const selectTransactionSplitInfoById = createSelector(
  getUsersTransactions,
  (_: unknown, transactionId: string | number) => transactionId,
  (_: unknown, __: unknown, parentId?: string | number) => parentId,
  (transactions, transactionId, parentId) => {
    if (!transactions || !parentId || !transactions[parentId]) return null
    const transaction = transactions[parentId]
    if (!transaction?.splitTransactions) return null
    const splitIndex = transaction.splitTransactions.findIndex(
      (transaction) => transaction.id === transactionId
    )
    return {
      splitIndex,
      totalSplits: transaction.splitTransactions.length,
    }
  }
)

/** Selects from user or admin transactions based on currentUser */
export const selectTransactionById = createSelector(
  [
    (state) =>
      getCurrentUser(state)?.admin
        ? getAdminTransactionsById(state)
        : getUsersTransactions(state),
    (_: unknown, transactionId: number) => transactionId,
  ],
  (transactions, transactionId) => transactions[transactionId]
)

export const selectIsSplitTransaction = createSelector(
  selectTransactionById,
  (transaction) => {
    return (
      transaction?.splitTransactions &&
      transaction?.splitTransactions?.length > 0
    )
  }
)

/**
 * Child/split txns are not included in this selector.
 * Use splitFrom instead of id as input parameter.
 */
export const selectIsLockedTransaction = createSelector(
  selectTransactionById,
  getCurrentUser,
  (transaction, currentUser) => {
    return isTransactionLocked({
      date: transaction.date,
      userLockedYear: currentUser?.booksLockedForUserYear,
      adminLockedYear: currentUser?.booksLockedForAdminYear,
      isAdmin: currentUser?.admin,
    })
  }
)

export const selectTransactionsNeedReviewForQTE = createSelector(
  [
    getUserNeedsClarificationTransactionsSelector,
    selectActiveQuarterlyTaxEstimateDetails,
  ],
  (transactions, activeQuarterDetails) => {
    // We should always have activeQuarterDetails, but as a fallback,
    // just return all transactions that need review
    if (!activeQuarterDetails) {
      return {
        count: transactions.length,
        allReviewed: transactions.length === 0,
      }
    }
    const needsReview = transactions.filter((transaction) => {
      const transactionDate = isoToUTCDateTime(transaction.date)
      return (
        transactionDate >=
          isoToUTCDateTime(activeQuarterDetails.bookkeepingPeriodStartsAt) &&
        transactionDate <=
          isoToUTCDateTime(activeQuarterDetails.bookkeepingPeriodEndsAt)
      )
    })
    return { count: needsReview.length, allReviewed: needsReview.length === 0 }
  }
)

/** used to get the transactions that need review for a specific month formatted as 'yyyy-MM'
 * count used to alert the user in the monthly reports page **/
export const selectTransactionsNeedReviewForMonth = createSelector(
  getUserNeedsClarificationTransactionsSelector,
  (_: unknown, month: string | undefined) => month,
  (transactions, month) => {
    if (!month) {
      return null
    }
    const needsReview = transactions.filter((transaction) => {
      const transactionDate = DateTime.fromISO(transaction.date)
      return (
        transactionDate >=
          DateTime.fromFormat(month, 'yyyy-MM').startOf('month') &&
        transactionDate <= DateTime.fromFormat(month, 'yyyy-MM').endOf('month')
      )
    })
    return { count: needsReview.length, transactions: needsReview }
  }
)
