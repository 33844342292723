import * as React from 'react'
import { useBooleanFlagValue } from '@openfeature/react-sdk'

// BL
import { UserDocument } from '../userDocuments.slice'

// UI
import { Grid } from 'semantic-ui-react'
import DocumentsSearchBar, {
  DocumentSearchBarOnChangeData,
} from '../sharedComponents/DocumentsSearchBar'
import { GridRowColumn, Text } from '../../../components/BaseComponents'
import { FEATURE_FLAG_KEYS } from '../../OpenFeature'

interface DocumentsToolbarProps {
  preamble: React.ReactNode
  documents: UserDocument[]
  onSearch: (_data: DocumentSearchBarOnChangeData) => void
  rightElement?: React.ReactNode
}

const TabPaneToolbar: React.FC<DocumentsToolbarProps> = ({
  preamble: description,
  documents,
  onSearch,
  rightElement,
}) => {
  const enableLowerFrictionStatements = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableLowerFrictionStatements,
    false
  )
  return (
    <Grid className="tab-category-pane-toolbar">
      {enableLowerFrictionStatements && (
        <GridRowColumn>
          {typeof description === 'string' ? (
            <Text>{description}</Text>
          ) : (
            description
          )}
        </GridRowColumn>
      )}
      <Grid.Row>
        <Grid.Column width={5}>
          <DocumentsSearchBar documents={documents} onChange={onSearch} />
        </Grid.Column>
        <Grid.Column width={6} />
        <Grid.Column width={5}>{rightElement}</Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default TabPaneToolbar
