import { useState } from 'react'

import DatePicker from '../components/BaseComponents/DatePicker'
import { DATE_FORMATS } from '../utils/dateHelpers'

export const DateSelector = () => {
  const [date, setDate] = useState('07-15-2022')

  return (
    <DatePicker clearable value={date} onChange={(date) => setDate(date)} />
  )
}

export const DatePickerTime = () => {
  const [date, setDate] = useState('2022-07-15 00:00:00')

  return (
    <DatePicker
      clearable
      value={date}
      onChange={setDate}
      dateFormat={DATE_FORMATS.TIMESTAMP}
    />
  )
}

export const DateSelectorLabeled = () => {
  const [date, setDate] = useState('')

  return (
    <DatePicker
      clearable
      value={date}
      onChange={(date) => setDate(date)}
      label="My Date:"
      description="Description here"
      placeholder="Enter date"
      error="You have an error"
    />
  )
}

export const RangeDateSelectorLabeled = () => {
  const [dateRange, setDateRange] = useState(['', ''])
  const [startDate, endDate] = dateRange

  return (
    <DatePicker
      clearable
      value={`${startDate} - ${endDate}`}
      onChange={(range) => {
        setDateRange(range)
      }}
      label="My Date:"
      description="Description here"
      placeholder="Enter date"
      selectsRange
      startDate={startDate}
      endDate={endDate}
    />
  )
}
