import { Loader } from 'semantic-ui-react'

import { Tab } from '../../../components/BaseComponents'
import { useReselector, useToggle } from '../../../utils/sharedHooks'
import {
  getUserTransactionById,
  selectTransactionSplitInfoById,
} from '../transactions.selectors'
import FileUploadModal from '../../../components/FileUpload/FileUploadModal'
import { UploadDocumentType } from '../../../constants/businessConstants'
import { DetailsPanel } from './DetailsPanel'
import { NotesPanel } from './NotesPanel'
import '../TransactionsPanel.scss'

interface TransactionDetailsProps {
  transactionId: number
  review?: boolean
  parentId?: number
  isManualTransaction?: boolean
  defaultActiveIndex?: number
  setPreventClose: (value: boolean) => void
}

const TransactionDetails = ({
  transactionId,
  review,
  parentId,
  isManualTransaction,
  defaultActiveIndex = 0,
  setPreventClose,
}: TransactionDetailsProps) => {
  const [fileModalOpen, toggleFileModal] = useToggle(false)

  const splitInfo = useReselector(
    selectTransactionSplitInfoById,
    transactionId,
    parentId
  )

  const transactionIdToFind = parentId || transactionId
  const transaction = useReselector(
    getUserTransactionById,
    transactionIdToFind,
    splitInfo?.splitIndex
  )

  if (!transaction) return <Loader active inline />

  return (
    <>
      <Tab
        className="transaction-details-tabs"
        defaultActiveIndex={defaultActiveIndex}
        panes={[
          {
            menuItem: 'Details',
            render: () => (
              <DetailsPanel
                transaction={transaction}
                review={review}
                splitIndex={splitInfo?.splitIndex}
                totalSplits={splitInfo?.totalSplits}
                isManualTransaction={isManualTransaction}
              />
            ),
          },
          {
            menuItem: 'Notes & Receipts',
            render: () => (
              <NotesPanel
                transaction={transaction}
                toggleFileModal={toggleFileModal}
                splitIndex={splitInfo?.splitIndex}
                parentId={parentId}
                setPreventClose={setPreventClose}
              />
            ),
          },
        ]}
      />
      <FileUploadModal
        open={fileModalOpen}
        close={() => {
          toggleFileModal()
          setPreventClose(false)
        }}
        documentType={UploadDocumentType.RECEIPT}
        userFacing
        extraPayload={{ transactionId: Number(transaction.id) }}
      />
    </>
  )
}

export default TransactionDetails
