import { useFormik, FormikProvider } from 'formik'
import { DateTime } from 'luxon'
import * as yup from 'yup'
import { Image, Grid } from 'semantic-ui-react'

import {
  Modal,
  Button,
  Text,
  GridRowColumn,
  FormikInput,
  getFieldName,
  Link,
  makeReqPhoneNumberSchema,
  makeReqBoolSchema,
  FormikCheckbox,
} from '../../components/BaseComponents'
import { useReselector } from '../../utils/sharedHooks'
import { getCurrentUser } from '../../selectors/user.selectors'
import { updateUser } from '../../actions/userActions'
import { useAppDispatch } from '../../utils/typeHelpers'
import {
  createSmsConsentStatus,
  SmsConsentSource,
  SmsConsentStatusOption,
} from './smsConsentReducer'
import { markUserActionItemCompleteIfExists } from '../Dashboard/UserActionItems/service'
import { UserActionItemActionItemIdentifiers } from '../Dashboard/UserActionItems/userActionItems.slice'
import { useAnalyticsTrack } from '../Amplitude'

export const SmsConsentModal = ({
  open,
  source,
  onClose,
}: {
  open: boolean
  source: SmsConsentSource
  onClose?: () => void
}) => {
  const currentUser = useReselector(getCurrentUser)
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()

  const handleClose = async () => {
    if (currentUser && !currentUser?.smsLoginModalViewedAt) {
      await updateUser(currentUser?.id, {
        smsLoginModalViewedAt: DateTime.now().toISO(),
      })(dispatch)
    }
    onClose?.()
  }

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      consent: false,
    },
    validationSchema: yup.object({
      phoneNumber: makeReqPhoneNumberSchema(),
      consent: makeReqBoolSchema({ trueOnly: true }),
    }),
    onSubmit: async (values) => {
      /*
        on submitting, three things should happen:
        1. sms_consent_statuses row is created for this user with the status of opt in
        2. sms-opt-in user action item is mark completed if it exists
        3. user.sms_login_modal_viewed_at is set to the current date which will close the modal
      */
      if (!values.consent) return

      const res = await dispatch(
        createSmsConsentStatus({
          mobilePhoneNumber: values.phoneNumber,
          status: SmsConsentStatusOption.OPT_IN,
          source,
        })
      )

      if (res) {
        await markUserActionItemCompleteIfExists(
          UserActionItemActionItemIdentifiers.smsOptIn,
          track
        )
        await handleClose()
      }
    },
  })

  return (
    <Modal open={open} onClose={handleClose} closeIcon size="small">
      <Modal.Header>
        <Text as="h2">Get Important Tax and Bookkeeping Reminders via SMS</Text>
      </Modal.Header>
      <FormikProvider value={formik}>
        <Modal.Content>
          <Grid>
            <GridRowColumn>
              <Image src="https://heard-images.s3.amazonaws.com/assets/phone.svg" />
            </GridRowColumn>
            <GridRowColumn short>
              <Text>
                We heard you! It&apos;s here! Now you can get important updates,
                reminders, and notifications regarding your practice&apos;s
                finances via SMS, like quarterly payment reminders.
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <FormikInput
                name={getFieldName<typeof formik.values>('phoneNumber')}
                required
                label="Mobile phone number"
                placeholder="555-555-5555"
                componentType="phone"
                style={{ width: '70%' }}
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikCheckbox
                name={getFieldName<typeof formik.values>('consent')}
                variant="default"
                label={
                  <Text color="mediumGray">
                    You agree to receive recurring text messages from Heard
                    about account updates and service reminders at the mobile
                    phone number provided. Message frequency varies. Message and
                    data rates may apply. Reply STOP to cancel. View our{' '}
                    <Link
                      newPage
                      href="https://www.joinheard.com/terms-of-service"
                    >
                      Terms of Service
                    </Link>{' '}
                    and{' '}
                    <Link newPage href="https://www.joinheard.com/privacy">
                      Privacy Policy.
                    </Link>
                  </Text>
                }
              />
            </GridRowColumn>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={formik.submitForm}
            disabled={!formik.isValid}
            loading={formik.isSubmitting}
          >
            Get Reminders
          </Button>
        </Modal.Actions>
      </FormikProvider>
    </Modal>
  )
}
