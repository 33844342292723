import moment from 'moment'
import { useCallback, useState } from 'react'
import {
  fetchAllocationGuidesSummary,
  postCreateAllocationGuide,
  POST_CREATE_ALLOCATION_GUIDE_KEY,
} from '../../actions/allocationGuideActions'
import { selectMissingAllocationGuides } from '../../selectors/allocationGuideSelectors'
import { getCurrentUser } from '../../selectors/user.selectors'
import { useAppDispatch } from '../../utils/typeHelpers'
import { getFetchError } from '../../reducers/fetch'
import { Alert, Button, Card, Dropdown, Text } from '../BaseComponents'
import { Dimmer, Loader } from 'semantic-ui-react'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { useReselector } from '../../utils/sharedHooks'

const CreateAllocationGuideCard = () => {
  const dispatch = useAppDispatch()

  const [month, setMonth] = useState<string>()
  const [loading, setLoading] = useState(false)

  const neededMonths = useReselector(selectMissingAllocationGuides)

  const user = useReselector(getCurrentUser)

  const createAllocationGuide = useCallback(async () => {
    setLoading(true)
    if (user && month) {
      await postCreateAllocationGuide(user, month)(dispatch)
      await fetchAllocationGuidesSummary()(dispatch)
      setLoading(false)
    }
  }, [dispatch, user, month])

  const options = neededMonths.map((month) => ({
    text: moment(month).format(DATE_FORMATS.MONTH_YEAR),
    value: moment(month).format('YYYY-MM'),
  }))

  const error = useReselector(getFetchError, POST_CREATE_ALLOCATION_GUIDE_KEY)

  return (
    <Card type="subsection" backgroundColor="stone40">
      <Text as="h2">Create a New Allocation Guide</Text>
      <br />
      {loading && (
        <Dimmer active inverted>
          <Loader inverted>Loading new allocation guide</Loader>
        </Dimmer>
      )}
      <Text as="bodyMd">
        Select a month to create an allocation guide. Income and expenses totals
        may be incomplete until monthly bookkeeping is finalized.
        <br />
        <br />
        The guide automatically updates with finalized values on the 15th of the
        following month.
        <br />
      </Text>
      <br />
      <Dropdown
        fullWidth
        label="Select Month"
        onChange={setMonth}
        options={options}
        placeholder="Date"
        required
        value={month}
      />
      <br />
      {error && (
        <>
          <Alert type="error">
            <b>{error.message}</b>
            <br />
            Income and Expenses are missing for the selected month.
          </Alert>
          <br />
        </>
      )}
      <Button
        loading={loading}
        disabled={loading}
        fullWidth
        onClick={createAllocationGuide}
      >
        Create Guide
      </Button>
    </Card>
  )
}

export default CreateAllocationGuideCard
