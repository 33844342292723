import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { GEP_PER_PERSON_PRICE } from '../helpers'
import { fetchContractors, fetchEmployees } from '../payrollActions'
import {
  Text,
  Card,
  Button,
  Alert,
  GridRowColumn,
  Icon,
} from '../../../components/BaseComponents'
import { useAppDispatch } from '../../../utils/typeHelpers'

const CreateTeamMember = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isEmployee, setIsEmployee] = useState(true)
  const [numOfEmp, setNumOfEmp] = useState(0)

  const navigateToCreate = useCallback(() => {
    if (isEmployee) {
      navigate('/payroll/create_employee')
    } else {
      navigate('/payroll/create_contractor')
    }
  }, [isEmployee, navigate])

  useEffect(() => {
    const fetch = async () => {
      const empRes = await fetchEmployees()(dispatch)
      const contRes = await fetchContractors()(dispatch)

      if (empRes && contRes) {
        setNumOfEmp(empRes.length + contRes.length)
      }
    }

    fetch()
  }, [dispatch])

  return (
    <Card>
      <Grid>
        <GridRowColumn>
          <Text as="h1">Add a new team member</Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text>Congrats on growing your team!</Text>
        </GridRowColumn>
        <Grid.Row />
        {Boolean(numOfEmp) && (
          <GridRowColumn short>
            <Alert>
              <b>Note:</b> You are currently paying for{' '}
              <b>{numOfEmp} team member(s)</b>. Adding a new team member will
              add <b>${GEP_PER_PERSON_PRICE}/month</b> to your plan.
            </Alert>
          </GridRowColumn>
        )}
        <Grid.Row>
          <Grid.Column width={6}>
            <Button
              onClick={() => setIsEmployee(true)}
              variant="toggle"
              fullWidth
              style={{ height: 150 }}
              active={isEmployee}
            >
              <Text as="h2">Employee</Text>
              <br />
              <Text textAlign="center">
                A person who works for wages or a set salary and receives
                benefits.
              </Text>
            </Button>
          </Grid.Column>
          <Grid.Column width={6}>
            <Button
              fullWidth
              variant="toggle"
              onClick={() => setIsEmployee(false)}
              style={{ height: 150 }}
              active={!isEmployee}
            >
              <Text as="h2">Contractor</Text>
              <br />
              <Text textAlign="center">
                A person who provides a service for pay.
              </Text>
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row />
        <GridRowColumn>
          <Text>
            Don’t worry — you can always remove or change the status of team
            members in the future!
          </Text>
        </GridRowColumn>
        <GridRowColumn width={3}>
          <Button onClick={navigateToCreate} fullWidth>
            Continue
            <Icon icon={regular('arrow-right')} style={{ marginLeft: 8 }} />
          </Button>
        </GridRowColumn>
        <Grid.Row />
        <Grid.Row />
      </Grid>
    </Card>
  )
}

export default CreateTeamMember
