import Text from '../components/BaseComponents/Text'

export const Display = () => <Text as="display">Display</Text>

export const DisplayGreen = () => (
  <Text as="display" color="green">
    Display
  </Text>
)

export const Display2 = () => <Text as="display2">Display</Text>

export const Display3 = () => <Text as="display3">Display</Text>

export const Heading1 = () => <Text as="h1">Heading1</Text>

export const Heading2 = () => <Text as="h2">Heading2</Text>

export const Heading3 = () => <Text as="h3">Heading3</Text>

export const BodyLarge = () => <Text as="bodyLg">Body Large</Text>

export const BodyMedium = () => <Text as="bodyMd">Body Medium</Text>

export const BodySmall = () => <Text as="bodySm">Body Small</Text>

export const Eyebrow = () => <Text as="eyebrow">Eyebrow</Text>
