import { useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import { fetchCheckoutSession } from '../../../../../actions/settings/billingActions'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../utils/sharedHooks'
import { getCurrentUser } from '../../../../../selectors/user.selectors'
import { isProduction } from '../../../../../utils/envHelpers'
import { SubStepIdentifiers } from '../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import {
  createUserEoyReviewProgress,
  updateUserEoyReviewProgress,
} from '../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { selectUserEoyReviewProgressForSubstepIdentifier } from '../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.selector'
import { selectAnnualTaxFilingFormById } from '../../annualTaxFilingForms.selector'
import { selectEoyReviewStepForIdentifier } from '../Shared/ReviewStepsandProgresses/endOfYearReviewSteps.selector'
import { logSentryMessage } from '../../../../../utils/sentryHelpers'
import { useAppDispatch } from '../../../../../utils/typeHelpers'

const ADDITIONAL_1040_PRICE_ID = isProduction()
  ? 'price_1Kl0yiJy69P78a4E0LpOAuk0'
  : 'price_1Mdng5Jy69P78a4EDnZwbahV'

// Payment flow
// 1. User hits payment button, we create eoy review step, fetch checkout session from backend
// 2. On success BE marks eoy step as complete, we nav user back to tax checklist with ?paymentSuccess=true
// 3. When FE gets ?paymentSuccess=true it marks that `madePayment` in that eoy review step, payment button disabled
// 4. If there is a mismatch with query param and eoy progress complete we log sentry error
const useTaxChecklistPayment = ({ formId }: { formId: number }) => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const user = useReselector(getCurrentUser)
  const [searchParams] = useSearchParams()
  const paymentSuccess = searchParams.get('paymentSuccess') === 'true'
  const form = useReselector(selectAnnualTaxFilingFormById, formId)

  const step = useReselector(
    selectEoyReviewStepForIdentifier,
    SubStepIdentifiers.completePayment
  )

  const progress = useReselector(
    selectUserEoyReviewProgressForSubstepIdentifier,
    SubStepIdentifiers.completePayment
  )

  const paymentPending = progress?.responses?.[0].madePayment || paymentSuccess

  useEffect(() => {
    const setProgress = async () => {
      if (!progress?.id || !paymentSuccess) {
        return
      }

      const response = await updateUserEoyReviewProgress(progress.id, {
        responses: [{ madePayment: true }],
      })(dispatch)

      // Using the response here instead of using selector response gives a little more time for the backend hooks to complete
      if (response && !response.completedAt) {
        logSentryMessage(
          'Payment success url hit but progress not marked as complete',
          {
            extra: {
              formId,
            },
            tags: {
              error_type: 'tax_checklist',
            },
          }
        )
      }
    }
    setProgress()
  }, [dispatch, formId, paymentSuccess, progress?.id])

  const { callback, loading } = useAsyncCallback(async () => {
    if (!user?.id || !form?.id || !step?.id) {
      return
    }

    if (!progress?.id) {
      const res = await createUserEoyReviewProgress({
        annualTaxFilingId: form.annualTaxFilingId,
        endOfYearReviewStepId: step.id,
      })(dispatch)

      if (!res) {
        return
      }
    }

    const res = await fetchCheckoutSession({
      userId: user.id,
      lineItems: [
        {
          priceId: ADDITIONAL_1040_PRICE_ID,
          quantity: 1,
        },
      ],
      success_url: {
        url: `${location.pathname}?paymentSuccess=true`,
      },
      cancel_url: {
        url: location.pathname,
      },
      mode: 'payment',
      metadata: {
        priceId: ADDITIONAL_1040_PRICE_ID,
        filingFormId: formId.toString(),
      },
    })(dispatch)

    if (res) {
      window.location.href = res.url
    }
  })

  return { paymentLoading: loading, makePayment: callback, paymentPending }
}

export default useTaxChecklistPayment
