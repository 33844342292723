import { useEffect, useState, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'
import { Grid, Divider, Loader } from 'semantic-ui-react'
import * as yup from 'yup'
import { isEmpty } from 'lodash'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Button,
  Card,
  FormikDropdown,
  FormikInput,
  FormikLocationSearchInput,
  getFieldNames,
  GridRowColumn,
  Icon,
  makeNumberSchema,
  makeReqStringSchema,
  Popup,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { STATES } from '../../../../constants/locationConstants'
import {
  getAboundTaxDocForPayerByContractorId,
  getCurrentAboundPayer,
  selectAboundContractorsByUUID,
  selectAreAll1099sSubmitted,
  selectContractorIsFromPreviousTaxYears,
} from '../aboundAnnualTaxFilings.selector'
import {
  AboundContractor,
  AboundTaxDocument,
  AboundTaxDocumentDataToSend,
  ABOUND_CONTRACTOR_UPDATE_KEY,
  ABOUND_TAX_FILINGS_CREATE_KEY,
  ABOUND_TAX_FILINGS_UPDATE_KEY,
  createAboundTaxDocumentRequest,
  fetchAboundContractorDetails,
  fetchAboundContractors,
  fetchAboundPayer,
  fetchAboundTaxDocumentsForPayer,
  FETCH_ABOUND_CONTRACTOR_DETAIL_KEY,
  updateAboundContractorRequest,
  updateAboundTaxDocumentRequest,
  FETCH_ABOUND_PAYER_KEY,
} from '../aboundAnnualTaxFilings.slice'
import {
  centsToDollars,
  dollarsToCents,
} from '../../../../utils/currencyHelpers'
import File1099sPage from './File1099sPage'
import { getIsFetchingOrNotStarted } from '../../../../reducers/fetch'
import Ten99FilingErrors from './Ten99FilingErrors'
import { identificationNumberProps } from '../../../../components/BaseComponents/Input'
import { Colors } from '../../../../styles/theme'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  fetchAllAnnualTaxDetailsIfNeeded,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const statesWithExtraRequirements = ['AL', 'DE', 'KS', 'MI', 'ND', 'OR', 'PA']

const commonYupSchema = {
  firstName: makeReqStringSchema({ field: 'first name' }),
  lastName: makeReqStringSchema({ field: 'last name' }),
  businessName: yup.string().nullable(),
  address: makeReqStringSchema({ field: 'address' }),
  city: makeReqStringSchema({ field: 'city' }),
  state: makeReqStringSchema({ field: 'state' }),
  zipcode: makeNumberSchema({
    field: 'zipcode',
    numDigits: 5,
    allowLeadingZero: true,
  }),
  country: makeReqStringSchema({ field: 'country' }),
  taxIdNumber: makeNumberSchema({
    field: 'tax identification number',
    numDigits: 9,
    allowLeadingZero: true,
  }),
  compensation: makeNumberSchema({
    field: 'compensation',
    allowedDecimals: 2,
    minNumber: 600,
  }),
}

const compensationPopup = (
  <Popup
    content={
      <Grid>
        <GridRowColumn>
          <Text as="h3">How to calculate the total paid to a contractor</Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text>
            You can check all transactions within your <b>Profit & Loss</b>{' '}
            related to a specific contractor. Then, you&apos;ll add up all of
            those transactions to get the sum total.
          </Text>
        </GridRowColumn>
      </Grid>
    }
    trigger={
      <Icon
        icon={regular('question-circle')}
        style={{ marginLeft: 8, color: Colors.mediumGray }}
      />
    }
  />
)

const payerStateIdPopup = (
  <Popup
    content={
      <Grid>
        <GridRowColumn>
          <Text as="h3">Payer State ID</Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text>
            This is the identification number assigned by a state to the
            respective business and is required when the payer reports state tax
            withheld from the payee&apos;s income on their 1099 Forms.
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text>
            If you live in Alabama, Delaware, Kansas, Mississippi, North Dakota,
            Oregon, or Pennsylvania, you&apos;ll need to enter your Payer State
            ID.
          </Text>
        </GridRowColumn>
      </Grid>
    }
    trigger={
      <Icon
        icon={regular('question-circle')}
        style={{ marginLeft: 8, color: Colors.mediumGray }}
      />
    }
  />
)

const ContractorDetailForm = ({
  contractor,
  ten99,
  goToPrevStep,
}: {
  contractor: AboundContractor
  ten99?: AboundTaxDocument
  goToPrevStep: () => void
}) => {
  const dispatch = useAppDispatch()
  const payerProfile = useReselector(getCurrentAboundPayer)
  const [processingRequests, setProcessingRequests] = useState(false)
  const [ssnType, setSsnType] = useState('password')
  const taxYear = useReselector(selectCurrentAnnualTaxYear)

  const needsPayerStateId = statesWithExtraRequirements.includes(
    payerProfile?.state || ''
  )
  const validationSchema = useMemo(
    () =>
      needsPayerStateId
        ? yup.object({
            ...commonYupSchema,
            payerStateId: makeReqStringSchema({ field: 'payer state id' }),
          })
        : yup.object(commonYupSchema),
    [needsPayerStateId]
  )

  const formik = useFormik({
    initialValues: {
      firstName: contractor.firstName || '',
      lastName: contractor.lastName || '',
      address: contractor.address,
      city: contractor.city || '',
      state: contractor.state || '',
      zipcode: contractor.zipcode || '',
      country: contractor.country || 'US',
      taxIdNumber: '',
      payerStateId: ten99?.payerStateId || '',
      filingState: ten99?.filingState || '',
      businessName: contractor.businessName || '',
      compensation: centsToDollars(ten99?.compensationInCents) || 0,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
    onSubmit: async ({
      firstName,
      lastName,
      businessName,
      address,
      city,
      state,
      zipcode,
      country,
      taxIdNumber,
      compensation,
      payerStateId,
    }) => {
      const contractorProfileData = {
        firstName,
        lastName,
        address,
        city,
        state,
        zipcode,
        country,
        taxIdNumber: taxIdNumber.replace(/\D/g, ''),
      } as AboundContractor

      if (businessName && !isEmpty(businessName)) {
        contractorProfileData.businessName = businessName
      }
      const ten99Data = {
        compensationInCents: dollarsToCents(compensation),
        contractorProfileId: contractor.id,
        filingState: payerProfile?.state,
      } as AboundTaxDocumentDataToSend
      if (payerStateId) {
        ten99Data.payerStateId = payerStateId
      }

      setProcessingRequests(true)
      const updatedContractor = await updateAboundContractorRequest(
        contractor.id,
        contractorProfileData
      )(dispatch)
      let createOrUpdated1099 = null
      if (ten99?.id) {
        createOrUpdated1099 = await updateAboundTaxDocumentRequest(
          ten99.id,
          ten99Data
        )(dispatch)
      } else {
        createOrUpdated1099 =
          await createAboundTaxDocumentRequest(ten99Data)(dispatch)
      }
      setProcessingRequests(false)
      if (updatedContractor && createOrUpdated1099) {
        goToPrevStep()
      }
    },
  })

  const { submitForm } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <GridRowColumn width={12}>
        <Card backgroundColor="stone40">
          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <FormikInput
                  label="First Name"
                  name={fieldNames.firstName}
                  fullWidth
                  required
                />
              </Grid.Column>
              <Grid.Column width={6}>
                <FormikInput
                  label="Last Name"
                  name={fieldNames.lastName}
                  fullWidth
                  required
                />
              </Grid.Column>
            </Grid.Row>
            <GridRowColumn>
              <FormikInput
                label="Business Name"
                description={'If different from above.'}
                name={fieldNames.businessName}
                fullWidth
              />
            </GridRowColumn>
            <GridRowColumn width={16}>
              <FormikInput
                label="Tax Identification Number"
                description={
                  <>
                    If this contractor has a Business Name, enter their EIN.
                    Otherwise, enter their SSN.
                  </>
                }
                type={ssnType}
                placeholder="xxx-xx-xxxx"
                onRightIconClick={() =>
                  setSsnType((type) =>
                    type === 'password' ? 'text' : 'password'
                  )
                }
                rightIcon={ssnType === 'password' ? 'eye' : 'eye slash'}
                name={fieldNames.taxIdNumber}
                fullWidth
                required
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikLocationSearchInput
                label="Street Address"
                placeholder="Street Address"
                required
                description={
                  'Please double-check that this matches the address on their W-9.'
                }
                fullWidth
                name={fieldNames.address}
                singleLineAddressOnly
              />
            </GridRowColumn>
            <Grid.Row>
              <Grid.Column width={6}>
                <FormikInput
                  label="City"
                  fullWidth
                  name={fieldNames.city}
                  required
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <FormikDropdown
                  label="State"
                  name={fieldNames.state}
                  options={STATES}
                  fullWidth
                  required
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <FormikInput
                  label="Zipcode"
                  componentType="number"
                  name={fieldNames.zipcode}
                  fullWidth
                  required
                  {...identificationNumberProps}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <FormikDropdown
                  label="Country"
                  name={fieldNames.country}
                  options={[{ text: 'United States', value: 'US' }]}
                  fullWidth
                  required
                />
              </Grid.Column>
            </Grid.Row>
            {needsPayerStateId && (
              <GridRowColumn width={5}>
                <FormikInput
                  label={<>Payer State ID{payerStateIdPopup}</>}
                  componentType="number"
                  name={fieldNames.payerStateId}
                  fullWidth
                  required
                  {...identificationNumberProps}
                />
              </GridRowColumn>
            )}
            <Grid.Row>
              <Grid.Column width={5}>
                <FormikInput
                  label={
                    <>
                      Amount Paid to Recipient in {taxYear}
                      {compensationPopup}
                    </>
                  }
                  componentType="currency"
                  name={fieldNames.compensation}
                  fullWidth
                  required
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card>
      </GridRowColumn>
      <Divider />
      <Ten99FilingErrors
        fetchKeys={[
          ABOUND_CONTRACTOR_UPDATE_KEY,
          ABOUND_TAX_FILINGS_CREATE_KEY,
          ABOUND_TAX_FILINGS_UPDATE_KEY,
        ]}
      />
      <Grid.Row>
        <Grid.Column width={2}>
          <Button variant="secondary" fullWidth onClick={goToPrevStep}>
            Back
          </Button>
        </Grid.Column>
        <Grid.Column width={12} />
        <Grid.Column width={2}>
          <Button
            disabled={!formik.isValid || processingRequests}
            fullWidth
            onClick={submitForm}
          >
            {processingRequests ? (
              <Loader active inline="centered" />
            ) : (
              'Confirm'
            )}
          </Button>
        </Grid.Column>
      </Grid.Row>
    </FormikProvider>
  )
}
const ContractorDetailAnd1099Panel = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { contractorUUID } = useParams<{ contractorUUID: string }>()
  const contractor = useReselector(
    selectAboundContractorsByUUID,
    contractorUUID
  )
  const ten99 = useReselector(
    getAboundTaxDocForPayerByContractorId,
    contractor?.id
  )

  useEffect(() => {
    dispatch(fetchAboundPayer())
    dispatch(fetchAboundContractors())
    dispatch(fetchAboundTaxDocumentsForPayer())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    if (contractor?.id) {
      dispatch(fetchAboundContractorDetails()(contractor.id))
    }
  }, [contractor?.id, contractorUUID, dispatch])

  const fetchingContractorDetails = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ABOUND_CONTRACTOR_DETAIL_KEY(contractor?.id)
  )
  const fetchingPayer = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ABOUND_PAYER_KEY
  )
  const fetchingAnnualTaxDetails = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ALL_ANNUAL_TAX_DETAILS_KEY
  )
  const all1099sSubmitted = useReselector(selectAreAll1099sSubmitted)

  useEffect(() => {
    if (all1099sSubmitted) {
      navigate('/taxes/annual')
    }
  }, [all1099sSubmitted, navigate])

  const contractorFromPast = useReselector(
    selectContractorIsFromPreviousTaxYears,
    contractor?.uuid
  )

  const content = useMemo(() => {
    if (contractor) {
      return (
        <Grid centered>
          <Ten99FilingErrors
            fetchKeys={[FETCH_ABOUND_CONTRACTOR_DETAIL_KEY(contractor.id)]}
          />
          <GridRowColumn width={12}>
            <div style={{ textAlign: 'center', marginBottom: -12 }}>
              <Icon
                icon={regular('circle-user')}
                color={'green'}
                style={{ height: 64 }}
              />
            </div>
          </GridRowColumn>
          <GridRowColumn width={12}>
            <Text textAlign="center" as="h1">
              {`1099-NEC: ${contractor?.firstName} ${contractor?.lastName}`}
            </Text>
            <Text textAlign="center" style={{ marginTop: 32 }}>
              {contractorFromPast
                ? 'Please update the information below for this contractor. This should match the information in their W-9. For security purposes, we are unable to store the TIN. You will need to re-enter it below.'
                : 'Please enter the information below for this contractor. This should match the information in their W-9.'}
            </Text>
          </GridRowColumn>
          <ContractorDetailForm
            ten99={ten99}
            goToPrevStep={() =>
              navigate('/taxes/annual/file_1099_nec/contractors')
            }
            contractor={contractor}
          />
        </Grid>
      )
    }
    return null
  }, [contractor, contractorFromPast, navigate, ten99])

  return (
    <File1099sPage
      isLoading={
        fetchingContractorDetails || fetchingPayer || fetchingAnnualTaxDetails
      }
      contentHeader={<Text as="h3">File 1099-NECs</Text>}
      content={content}
    />
  )
}

export default ContractorDetailAnd1099Panel
