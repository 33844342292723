import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Image, List } from 'semantic-ui-react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  GridRowColumn,
  Text,
  TileSelector,
} from '../../../../../../components/BaseComponents'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { INCOME_LIABILITIES_SCREENS, IncomeAndLiabilitiesStepProps } from '.'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import { TaxListQuestionId } from '../../service'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
  selectTaxListQuestionResponsesByQuestionIds,
} from '../../taxChecklist.selectors'
import { NoneApply, useTiles } from '../../Shared/UseTiles'
import DocumentAddedAlert from '../../Shared/SpecialtyAlerts'
import { rentalPropertyQuestionIds } from './RentalPropertyPanel'
import { investmentsQuestionIds } from './InvestmentsPanel'
import { retirementContributedQuestionIds } from './RetirementContributedPanel'
import {
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  deleteTaxQuestionnaireResponses,
} from '../../taxChecklistQuestion.actions'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import useProgressSteps from '../../Shared/ReviewStepsandProgresses/useProgressSteps'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'

export const IncomeAndLiabilitiesQuestionIds = [
  TaxListQuestionId.own_rental_property,
  TaxListQuestionId.own_investments,
  TaxListQuestionId.retirement_contributed,
]

const IncomeAndLiabilitiesPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
}: IncomeAndLiabilitiesStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()
  const dispatch = useAppDispatch()
  const { fetchKeys: progressFetchKeys } = useProgressSteps()

  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    ...progressFetchKeys,
  ])

  const questionOwnRentalProperty = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.own_rental_property,
    taxYear
  )
  const ownRentalPropertyResponses = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.own_rental_property,
    Number(formId)
  )
  const questionOwnInvestments = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.own_investments,
    taxYear
  )
  const ownInvestmentsResponses = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.own_investments,
    Number(formId)
  )
  const questionRetirementContributed = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.retirement_contributed,
    taxYear
  )
  const retirementContributedResponses = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.retirement_contributed,
    Number(formId)
  )

  const rentalPropertyFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    rentalPropertyQuestionIds,
    Number(formId)
  )
  const investmentsFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    investmentsQuestionIds.filter(
      (qId) =>
        qId !== TaxListQuestionId.number_of_brokerage_statements &&
        qId !== TaxListQuestionId.received_dividends
    ), // Taxfyle always requires these questions so don't clear them
    Number(formId)
  )
  const retirementContributedFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    retirementContributedQuestionIds,
    Number(formId)
  )
  const brokerageStatementResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    [TaxListQuestionId.number_of_brokerage_statements],
    Number(formId)
  )
  const receivedDividendsResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    [TaxListQuestionId.received_dividends],
    Number(formId)
  )

  const responseNumberProperties = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_of_rental_properties,
    Number(formId)
  )

  const {
    tiles: selections,
    setTiles,
    noneApply,
    tileColumnStyle,
    tileClicked,
    tileWidth,
    tilesToResponses,
    anyResponseSelected,
  } = useTiles()

  const tileHeight = 180

  useEffect(() => {
    setTiles([
      {
        icon: light('house-building'),
        text: questionOwnRentalProperty?.question?.text ?? '',
        questionId: TaxListQuestionId.own_rental_property,
        response: ownRentalPropertyResponses?.[0],
      },
      {
        icon: light('display-chart-up-circle-dollar'),
        text: questionOwnInvestments?.question?.text ?? '',
        subtext: '(Stocks, bonds, investment properties)',
        questionId: TaxListQuestionId.own_investments,
        response: ownInvestmentsResponses?.[0],
      },
      {
        icon: light('island-tropical'),
        text: questionRetirementContributed?.question?.text ?? '',
        popup: {
          title: 'Applicable retirement plans',
          body: (
            <List as="ul">
              <List.Item as="li">Roth IRA</List.Item>
              <List.Item as="li">Traditional IRA</List.Item>
              <List.Item as="li">SEP IRA</List.Item>
              <List.Item as="li">Simple IRA</List.Item>
              <List.Item as="li">Solo 401(k)</List.Item>
            </List>
          ),
        },
        questionId: TaxListQuestionId.retirement_contributed,
        response: retirementContributedResponses?.[0],
      },
    ])
  }, [
    ownRentalPropertyResponses,
    ownInvestmentsResponses,
    setTiles,
    retirementContributedResponses,
    questionOwnRentalProperty,
    questionOwnInvestments,
    questionRetirementContributed,
  ])

  const saveAndContinue = useAsyncCallback(async () => {
    const responseData: Partial<TaxChecklistResponse>[] = []
    const completedSteps = [SubStepIdentifiers.incomeAndLiabilities]
    const incompleteSteps: SubStepIdentifiers[] = []
    // if screen conditions change, make sure to also update in IncomeAndLiabilities/index
    const didOwnRentalProperty =
      selections.find(
        (s) => s.questionId === TaxListQuestionId.own_rental_property
      )?.response?.value === true
    const rentalPropertyFollowUpIds =
      rentalPropertyFollowUpQuestionResponses.map((r) => r.id)

    const didOwnInvestments =
      selections.find((s) => s.questionId === TaxListQuestionId.own_investments)
        ?.response?.value === true
    const ownInvestmentsFollowUpIds = investmentsFollowUpQuestionResponses.map(
      (r) => r.id
    )

    const didContributeToRetirement =
      selections.find(
        (s) => s.questionId === TaxListQuestionId.retirement_contributed
      )?.response?.value === true
    const retirementContributedFollowUpIds =
      retirementContributedFollowUpQuestionResponses.map((r) => r.id)

    let deletionSucceeded = true
    const idsToDelete: number[] = []
    if (!didOwnRentalProperty) {
      if (rentalPropertyFollowUpIds.length > 0) {
        idsToDelete.push(...rentalPropertyFollowUpIds)
      }
      if (responseNumberProperties?.[0]?.value !== 0) {
        responseData.push({
          id: responseNumberProperties?.[0]?.id,
          value: 0,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.number_of_rental_properties,
        })
      }
    }
    if (!didOwnInvestments) {
      if (ownInvestmentsFollowUpIds.length) {
        idsToDelete.push(...ownInvestmentsFollowUpIds)
      }
      if (brokerageStatementResponses?.[0]?.value !== 0) {
        responseData.push({
          id: brokerageStatementResponses?.[0]?.id,
          value: 0,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.number_of_brokerage_statements,
        })
      }
      if (receivedDividendsResponses?.[0]?.value !== false) {
        responseData.push({
          id: receivedDividendsResponses?.[0]?.id,
          value: false,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.received_dividends,
        })
      }
    }
    if (!didContributeToRetirement && retirementContributedFollowUpIds.length) {
      idsToDelete.push(...retirementContributedFollowUpIds)
    }
    if (idsToDelete.length > 0) {
      deletionSucceeded = Boolean(
        await dispatch(deleteTaxQuestionnaireResponses(idsToDelete))
      )
    }
    //process progresses for the step and substeps
    if (deletionSucceeded) {
      //there should always be a completed progress for the first tile page upon submission
      if (didOwnRentalProperty) {
        if (!rentalPropertyFollowUpIds.length) {
          incompleteSteps.push(SubStepIdentifiers.rentalProperties)
        }
      } else {
        completedSteps.push(SubStepIdentifiers.rentalProperties)
      }

      if (didOwnInvestments) {
        if (!ownInvestmentsFollowUpIds.length) {
          incompleteSteps.push(SubStepIdentifiers.investments)
        }
      } else {
        completedSteps.push(SubStepIdentifiers.investments)
      }

      if (didContributeToRetirement) {
        if (!retirementContributedFollowUpIds.length) {
          incompleteSteps.push(SubStepIdentifiers.retirement)
        }
      } else {
        completedSteps.push(SubStepIdentifiers.retirement)
      }
    }

    let nextScreen = INCOME_LIABILITIES_SCREENS.lessCommonScenariosIntro
    if (didOwnRentalProperty) {
      nextScreen = INCOME_LIABILITIES_SCREENS.rentalProperty
    } else if (didOwnInvestments) {
      nextScreen = INCOME_LIABILITIES_SCREENS.investments
    } else if (didContributeToRetirement) {
      nextScreen = INCOME_LIABILITIES_SCREENS.retirementContributed
    }
    if (deletionSucceeded) {
      goToNextStep(
        [...tilesToResponses(), ...responseData],
        nextScreen ?? null,
        { completedSteps, incompleteSteps }
      )
    }
  })

  return (
    <Grid>
      <GridRowColumn
        columnStyle={{ display: 'flex', justifyContent: 'center' }}
      >
        <Image src="https://heard-images.s3.amazonaws.com/assets/chart-tablet.svg" />
      </GridRowColumn>
      <GridRowColumn>
        <Text as="display2" textAlign="center">
          Income and Liabilities
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([8, 14], true)}>
        <Text as="bodyLg" textAlign="center">
          Let us know if any of these applied to you in {taxYear}, so we can
          personalize the questionnaire for you.
        </Text>
      </GridRowColumn>
      <GridRowColumn columnStyle={tileColumnStyle}>
        {selections.map((s) => (
          <TileSelector
            key={s.questionId}
            icon={s.icon}
            active={Boolean(s.response?.value)}
            text={s.text}
            width={tileWidth}
            height={tileHeight}
            onClick={() => tileClicked(s.questionId)}
            popup={s.popup}
            subtext={s.subtext}
          />
        ))}
      </GridRowColumn>
      <GridRowColumn short columnStyle={tileColumnStyle}>
        <NoneApply
          width={noneApply.width}
          selected={noneApply.selected}
          setSelection={noneApply.clearTiles}
        />
      </GridRowColumn>
      {anyResponseSelected && (
        <GridRowColumn short {...makeGridConfig([8, 14], true)}>
          <DocumentAddedAlert>
            When you see the “add file” icon, we&apos;ve added a form
            requirement to your Upload Documents checklist. This list is at the
            end of the questionnaire, which is when you&apos;ll upload your
            documents.
          </DocumentAddedAlert>
        </GridRowColumn>
      )}
      <Grid.Row />
      <FormFlowFooter
        continueDisabled={
          (!noneApply.selected && !anyResponseSelected) ||
          saveAndContinue.loading
        }
        onBack={() => goBack(previousScreen ?? null)}
        onForward={saveAndContinue.callback}
        loading={isUpdating || saveAndContinue.loading}
      />
    </Grid>
  )
}

export default IncomeAndLiabilitiesPanel
