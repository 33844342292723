import React, { useState, useEffect } from 'react'
import {
  Button,
  Header,
  Icon,
  Message,
  Modal,
  TextArea,
} from 'semantic-ui-react'
import { getFetchError } from '../../../../reducers/fetch'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import {
  releaseUserTaxCalculations,
  RELEASE_USER_TAX_CALCULATIONS_KEY,
  UserTaxCalculation,
  UserTaxCalculationStatuses,
} from '../userTaxCalculation.slice'
import { useReselector } from '../../../../utils/sharedHooks'

interface ButtonProps {
  calculation: UserTaxCalculation
}

interface ModalProps {
  open: boolean
  onClose: () => void
  eligibleUserTaxCalculation: UserTaxCalculation
}

const ReleaseAndNotifyButton: React.FC<ButtonProps> = ({ calculation }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const buttonText = 'Release & Notify'

  return (
    <>
      {modalOpen && (
        <ReleaseAndNotifyModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          eligibleUserTaxCalculation={calculation}
        />
      )}
      <Button
        style={{ display: 'block', margin: '0 auto' }}
        primary
        basic
        onClick={() => setModalOpen(true)}
        content={buttonText}
        disabled={
          !(calculation.status === UserTaxCalculationStatuses.calculated)
        }
      />
    </>
  )
}

const ReleaseAndNotifyModal: React.FC<ModalProps> = ({
  open,
  onClose,
  eligibleUserTaxCalculation,
}) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const [outputMessage, setOutputMessage] = useState('')

  const dispatch = useAppDispatch()
  const error = useReselector(getFetchError, RELEASE_USER_TAX_CALCULATIONS_KEY)

  useEffect(() => {
    if (error) {
      setLoading(false)
      setComplete(false)
    }
  }, [error])

  const logOutputMessage = (message: string) => {
    setOutputMessage((prev) => `${prev} \n${new Date().toJSON()} - ${message}`)
  }

  /*
    Creates, updates, deletes user_tax_estimates, sends a SendGrid email to each customer containing their estimate,  and sets calculation statuses to "saved_and_notified"
  */
  const releaseEstimatesAndNotify = async () => {
    const userTaxCalculationId = eligibleUserTaxCalculation.id

    // 1
    logOutputMessage('Releasing single tax calculation...')
    await dispatch(
      releaseUserTaxCalculations({
        ids: [userTaxCalculationId],
      })
    )
    logOutputMessage('Releasing single tax calculation...done')

    logOutputMessage('Done!')
  }

  const onBeginClick = async () => {
    setLoading(true)
    setComplete(false)
    await releaseEstimatesAndNotify()
    setLoading(false)
    setComplete(true)
  }

  return (
    <Modal
      closeOnDimmerClick={false}
      closeOnEscape={false}
      centered={false}
      open={open}
      onClose={onClose}
    >
      <Header icon>
        <Icon name="send" />
        Release &amp; Notify
      </Header>
      <Modal.Content>
        <Header as="h4">The following actions will be performed</Header>
        <ol>
          <li>
            Set calculation statuses to <b>complete</b>
          </li>
          <li>Release estimates to the tax center</li>
          <li>Notify customers of their estimates via Sendgrid email</li>
        </ol>
        <TextArea
          rows={10}
          placeholder="Status"
          disabled
          value={outputMessage}
          style={{ width: '100%', margin: '1em 0' }}
        />
        {error && (
          <Message error header="An error occurred" content={error?.message} />
        )}
      </Modal.Content>
      <Modal.Actions>
        {complete && !error && (
          <Button primary onClick={onClose}>
            Done
          </Button>
        )}
        {(!complete || error) && (
          <>
            <Button disabled={loading} basic color="red" onClick={onClose}>
              <Icon name="remove" /> Cancel
            </Button>
            <Button
              disabled={loading}
              loading={loading}
              primary
              onClick={onBeginClick}
            >
              <Icon name="checkmark" /> Begin
            </Button>
          </>
        )}
      </Modal.Actions>
    </Modal>
  )
}

export default ReleaseAndNotifyButton
