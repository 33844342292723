import { Fragment, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Divider, Table } from 'semantic-ui-react'

import { Button, Label, Text } from '../../../components/BaseComponents'
import ExtensionPaymentsForm from './ExtensionPaymentsForm'
import { useReselector } from '../../../utils/sharedHooks'
import { selectAnnualTaxFilingForId } from './adminAnnualTaxFilings.selector'
import {
  annualTaxFilingFormTaxfyleJobStatusLabelColor,
  annualTaxFilingFormTaxfyleJobStepLabelColor,
} from './AnnualTaxFilingTableData'
import { AnnualTaxFilingExtensionPayment } from '../../Taxes/AnnualTaxes/annualTaxFilingForms.slice'

const ExtensionCell = ({
  filingId,
  handleModalOpen,
  modalFilingFormTypeNameContext,
  closeModal,
}: {
  filingId: number
  handleModalOpen: (
    modalView: string,
    modalUserContext: string,
    filingFormId?: number,
    filingFormTypeName?: string,
    filingFormExtensionPayments?: AnnualTaxFilingExtensionPayment[] | null,
    filingFormIsExtended?: boolean | null
  ) => void
  modalFilingFormTypeNameContext: string
  closeModal: () => void
}) => {
  const filing = useReselector(selectAnnualTaxFilingForId, filingId)
  const filingTaxForms = filing?.annualTaxFilingForms
  const user = filing?.annualTaxFilings

  const statusLabel = useMemo(() => {
    if (filing?.extensionTaxfyleJob) {
      return <Label color="green">Completed</Label>
    }
    if (filing?.extensionStartedAt) {
      return <Label color="yellow">In Progress</Label>
    }

    return <Label color="red">Not Started</Label>
  }, [filing?.extensionStartedAt, filing?.extensionTaxfyleJob])

  if (!filing || !user) {
    return null
  }

  return (
    <Table.Cell>
      {filingTaxForms?.some((form) => form.isExtended) && (
        <>
          <div style={{ display: 'flex' }}>
            <Text style={{ paddingRight: 10 }}>Extension status:</Text>
            {statusLabel}
          </div>

          {filing.extensionTaxfyleJob && (
            <>
              <div style={{ display: 'flex' }}>
                <Text style={{ paddingRight: 10 }}>Job status:</Text>
                <Label
                  color={
                    annualTaxFilingFormTaxfyleJobStatusLabelColor[
                      filing.extensionTaxfyleJob.jobStatus
                    ]
                  }
                >
                  {filing.extensionTaxfyleJob.jobStatus}
                </Label>
              </div>
              <div style={{ display: 'flex' }}>
                <Text style={{ paddingRight: 10 }}>Job step:</Text>
                <Label
                  color={
                    filing.extensionTaxfyleJob.jobStep
                      ? annualTaxFilingFormTaxfyleJobStepLabelColor[
                          filing.extensionTaxfyleJob.jobStep
                        ]
                      : undefined
                  }
                >
                  {filing.extensionTaxfyleJob.jobStep ?? 'No Job Step'}
                </Label>
              </div>
              <Link
                to={`${process.env.TAXFYLE_BASE_URL}/jobs/${filing.extensionTaxfyleJob.jobId}`}
                target="_blank"
              >
                Extension Taxfyle Link
              </Link>
            </>
          )}

          <Divider />
        </>
      )}
      {filingTaxForms?.map((form) => (
        <Fragment key={`form-${form.id}`}>
          {!form.isExtended && 'No extension needed'}
          {form.isExtended && (
            <ExtensionPaymentsForm
              key={`${form.id}-extension-payments-form`}
              paymentInfo={form.extensionPayments}
              isEditing={false}
              userId={user.id}
              filingFormId={form.id}
              filingFormType={modalFilingFormTypeNameContext}
              closeModal={closeModal}
              isFormExtended={form.isExtended}
            />
          )}
          <Button
            variant="link"
            onClick={() =>
              handleModalOpen(
                'Extension Information',
                `${user.firstName} ${user.lastName}`,
                form.id,
                form.formType.displayName,
                form.extensionPayments,
                form.isExtended
              )
            }
          >
            Update
          </Button>
          <Divider />
        </Fragment>
      ))}
    </Table.Cell>
  )
}

export default ExtensionCell
