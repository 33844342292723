import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Alert,
  Card,
  FormikDropdown,
  FormikInput,
  FormikLabelError,
  FormikRadioButton,
  getFieldName,
  GridRowColumn,
  Icon,
  Link,
} from '../../components/BaseComponents'
import { useReselector } from '../../utils/sharedHooks'
import { getFinancialProfile } from '../../selectors/user.selectors'
import { fetchUserDocuments } from '../UserDocuments/userDocuments.slice'
import { fetchUserDocumentCategoriesIfNeeded } from '../Admin/UserDocumentCategories/userDocumentCategories.slice'
import FileUploadFormInput from '../../components/FileUpload/FileUploadFormInput'
import {
  UPDATE_FINANCIAL_PROFILE_KEY,
  updateFinancialProfile,
} from '../../actions/financialProfileActions'
import { centsToDollars, dollarsToCents } from '../../utils/currencyHelpers'
import PracticeProfileFormCta from './PracticeProfileFormCta'
import {
  FILING_STATUSES,
  FilingStatusOptions,
  TAX_ENTITY_TYPES,
  TaxEntityTypeOptions,
} from '../Taxes/taxConstants'
import { getFetchError } from '../../reducers/fetch'
import PageHeader from '../../components/shared/PageHeader'
import { useAppDispatch } from '../../utils/typeHelpers'
import { UserDocumentCategoryIdentifier } from '../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { DateTime } from 'luxon'

export const shouldDisplaySpouse = (
  filingStatus: FILING_STATUSES | null | undefined
) => Boolean(filingStatus && ['married_filing_jointly'].includes(filingStatus))

const TaxInformationForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const taxYear = DateTime.now().year
  const fp = useReselector(getFinancialProfile)
  const error = useReselector(
    getFetchError,
    UPDATE_FINANCIAL_PROFILE_KEY(fp?.id)
  )

  useEffect(() => {
    dispatch(fetchUserDocuments())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
  }, [dispatch])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      filingStatus: fp?.filingStatus,
      taxEntityType: fp?.taxEntityType,
      itemizedDeduction: fp?.itemizedDeduction,
      numberOfDependents: fp?.numberOfDependents,
      wIncomeInCents: fp?.wIncomeInCents && centsToDollars(fp?.wIncomeInCents),
      scorpPracticeWIncomeInCents:
        fp?.scorpPracticeWIncomeInCents &&
        centsToDollars(fp?.scorpPracticeWIncomeInCents),
      federalWithholdingInCents:
        fp?.federalWithholdingInCents &&
        centsToDollars(fp?.federalWithholdingInCents),
      stateWithholdingInCents:
        fp?.stateWithholdingInCents &&
        centsToDollars(fp?.stateWithholdingInCents),
      otherIndividualIncomeInCents:
        fp?.otherIndividualIncomeInCents &&
        centsToDollars(fp?.otherIndividualIncomeInCents),
      spouseFirstName: fp?.spouseFirstName,
      spouseLastName: fp?.spouseLastName,
      spouseWIncomeInCents:
        fp?.spouseWIncomeInCents && centsToDollars(fp?.spouseWIncomeInCents),
      spouseFederalWithholdingInCents:
        fp?.spouseFederalWithholdingInCents &&
        centsToDollars(fp?.spouseFederalWithholdingInCents),
      spouseStateWithholdingInCents:
        fp?.spouseStateWithholdingInCents &&
        centsToDollars(fp?.spouseStateWithholdingInCents),
      spouseIndividualIncomeInCents:
        fp?.spouseIndividualIncomeInCents &&
        centsToDollars(fp?.spouseIndividualIncomeInCents),
    },

    onSubmit: async ({
      filingStatus,
      taxEntityType,
      itemizedDeduction,
      numberOfDependents,
      wIncomeInCents,
      scorpPracticeWIncomeInCents,
      federalWithholdingInCents,
      stateWithholdingInCents,
      otherIndividualIncomeInCents,
      spouseFirstName,
      spouseLastName,
      spouseWIncomeInCents,
      spouseFederalWithholdingInCents,
      spouseStateWithholdingInCents,
      spouseIndividualIncomeInCents,
    }) => {
      const res = await dispatch(
        updateFinancialProfile(fp?.id, {
          filingStatus,
          taxEntityType,
          itemizedDeduction,
          numberOfDependents,
          wIncomeInCents: dollarsToCents(wIncomeInCents),
          scorpPracticeWIncomeInCents: dollarsToCents(
            scorpPracticeWIncomeInCents
          ),
          federalWithholdingInCents: dollarsToCents(federalWithholdingInCents),
          stateWithholdingInCents: dollarsToCents(stateWithholdingInCents),
          otherIndividualIncomeInCents: dollarsToCents(
            otherIndividualIncomeInCents
          ),
          spouseFirstName,
          spouseLastName,
          spouseWIncomeInCents: dollarsToCents(spouseWIncomeInCents),
          spouseFederalWithholdingInCents: dollarsToCents(
            spouseFederalWithholdingInCents
          ),
          spouseStateWithholdingInCents: dollarsToCents(
            spouseStateWithholdingInCents
          ),
          spouseIndividualIncomeInCents: dollarsToCents(
            spouseIndividualIncomeInCents
          ),
        })
      )

      if (res) {
        navigate('/practice/profile')
      }
    },
  })

  const { values, submitForm, isSubmitting, isValid } = formik

  const displaySpouse = shouldDisplaySpouse(values.filingStatus)
  const isScorp = values.taxEntityType === TAX_ENTITY_TYPES.form_1120_s

  return (
    <Grid>
      <GridRowColumn>
        <Link to="/practice/profile">
          <Icon icon={regular('arrow-left')} style={{ marginRight: 8 }} />
          Back to Practice Profile
        </Link>
      </GridRowColumn>
      <GridRowColumn>
        <PageHeader header={`${taxYear} Tax Information`} />
      </GridRowColumn>
      <GridRowColumn>
        <Card type="subsection" backgroundColor="stone40">
          <FormikProvider value={formik}>
            <Grid>
              {error && (
                <GridRowColumn>
                  <Alert type="error">{error.message}</Alert>
                </GridRowColumn>
              )}
              <GridRowColumn computer={9} tablet={9} mobile={16}>
                <FormikDropdown
                  name={getFieldName<typeof values>('filingStatus')}
                  label="Filing Status"
                  fullWidth
                  options={FilingStatusOptions}
                />
              </GridRowColumn>
              <GridRowColumn computer={9} tablet={9} mobile={16}>
                <FormikDropdown
                  name={getFieldName<typeof values>('taxEntityType')}
                  label="Tax Entity Type"
                  fullWidth
                  options={TaxEntityTypeOptions}
                />
              </GridRowColumn>

              <GridRowColumn>
                <FormikLabelError
                  label="Deduction Type"
                  name={getFieldName<typeof values>('itemizedDeduction')}
                />
              </GridRowColumn>
              <Grid.Row className="short">
                <Grid.Column computer={3} tablet={3} mobile={8}>
                  <FormikRadioButton
                    name={getFieldName<typeof values>('itemizedDeduction')}
                    label="Standard"
                    value={false}
                  />
                </Grid.Column>
                <Grid.Column computer={3} tablet={3} mobile={8}>
                  <FormikRadioButton
                    name={getFieldName<typeof values>('itemizedDeduction')}
                    label="Itemized"
                    value
                  />
                </Grid.Column>
              </Grid.Row>
              <GridRowColumn computer={9} tablet={9} mobile={16}>
                <FormikInput
                  name={getFieldName<typeof values>('numberOfDependents')}
                  label="Number of Dependents"
                  fullWidth
                />
              </GridRowColumn>

              <Grid.Row />

              {isScorp && (
                <GridRowColumn computer={9} tablet={9} mobile={16}>
                  <FormikInput
                    name={getFieldName<typeof values>(
                      'scorpPracticeWIncomeInCents'
                    )}
                    label="YTD W-2 Private Practice"
                    fullWidth
                    componentType="currency"
                  />
                </GridRowColumn>
              )}

              <GridRowColumn computer={9} tablet={9} mobile={16}>
                <FormikInput
                  name={getFieldName<typeof values>('wIncomeInCents')}
                  label="YTD W-2 Income"
                  fullWidth
                  componentType="currency"
                />
              </GridRowColumn>

              <GridRowColumn computer={9} tablet={9} mobile={16}>
                <FormikInput
                  name={getFieldName<typeof values>(
                    'federalWithholdingInCents'
                  )}
                  label="YTD Federal Withholding"
                  fullWidth
                  componentType="currency"
                />
              </GridRowColumn>

              <GridRowColumn computer={9} tablet={9} mobile={16}>
                <FormikInput
                  name={getFieldName<typeof values>('stateWithholdingInCents')}
                  label="YTD State Withholding"
                  fullWidth
                  componentType="currency"
                />
              </GridRowColumn>

              <GridRowColumn computer={9} tablet={9} mobile={16}>
                <FormikInput
                  name={getFieldName<typeof values>(
                    'otherIndividualIncomeInCents'
                  )}
                  label="YTD 1099 Income"
                  fullWidth
                  componentType="currency"
                />
              </GridRowColumn>

              <Grid.Row />

              {displaySpouse && (
                <>
                  <GridRowColumn computer={9} tablet={9} mobile={16}>
                    <FormikInput
                      name={getFieldName<typeof values>('spouseFirstName')}
                      label="Spouse First Name"
                      fullWidth
                    />
                  </GridRowColumn>

                  <GridRowColumn computer={9} tablet={9} mobile={16}>
                    <FormikInput
                      name={getFieldName<typeof values>('spouseLastName')}
                      label="Spouse Last Name"
                      fullWidth
                    />
                  </GridRowColumn>

                  <GridRowColumn computer={9} tablet={9} mobile={16}>
                    <FormikInput
                      name={getFieldName<typeof values>('spouseWIncomeInCents')}
                      label="Spouse YTD W-2 Income"
                      fullWidth
                      componentType="currency"
                    />
                  </GridRowColumn>

                  <GridRowColumn computer={9} tablet={9} mobile={16}>
                    <FormikInput
                      name={getFieldName<typeof values>(
                        'spouseFederalWithholdingInCents'
                      )}
                      label="Spouse YTD Federal Withholding"
                      fullWidth
                      componentType="currency"
                    />
                  </GridRowColumn>

                  <GridRowColumn computer={9} tablet={9} mobile={16}>
                    <FormikInput
                      name={getFieldName<typeof values>(
                        'spouseStateWithholdingInCents'
                      )}
                      label="Spouse YTD State Withholding"
                      fullWidth
                      componentType="currency"
                    />
                  </GridRowColumn>

                  <GridRowColumn computer={9} tablet={9} mobile={16}>
                    <FormikInput
                      name={getFieldName<typeof values>(
                        'spouseIndividualIncomeInCents'
                      )}
                      label="Spouse YTD 1099"
                      fullWidth
                      componentType="currency"
                    />
                  </GridRowColumn>

                  <Grid.Row />
                </>
              )}

              <GridRowColumn>
                <FileUploadFormInput
                  label="Prior Year Tax Return"
                  documentCat={UserDocumentCategoryIdentifier.finalReturn}
                  taxYear={(taxYear - 1).toString()}
                />
              </GridRowColumn>
              <Grid.Row />

              <PracticeProfileFormCta
                submitForm={submitForm}
                isSubmitting={isSubmitting}
                isValid={isValid}
              />
            </Grid>
          </FormikProvider>
        </Card>
      </GridRowColumn>
    </Grid>
  )
}

export default TaxInformationForm
