import { CSSProperties, ReactNode } from 'react'

import { Button } from '../../BaseComponents'
import { FinancialAccount } from '../../../reducers/finances/financialAccountsReducer'
import { FinancialAccountWithAdminInfo } from '../../../reducers/admin/financialAccountsReducer'
import { useHeardPlaid } from './helpers'
import { ButtonProps, ButtonVariant } from '../../BaseComponents/Button'
import { useAnalyticsTrack } from '../../../features/Amplitude'

const PlaidLink = ({
  account = null,
  redirectUri,
  redirectLink = '/accounts#connected-institutions',
  redirect = true,
  buttonVariant = 'primary',
  buttonSize,
  style,
  reconnectButtonText = 'Reconnect',
  connectButtonText = 'Connect an account',
  disconnected = false,
}: {
  account?: FinancialAccount | FinancialAccountWithAdminInfo | null
  redirectUri?: string
  redirectLink?: string
  redirect?: boolean
  buttonVariant?: ButtonVariant
  buttonSize?: ButtonProps['size']
  style?: CSSProperties
  reconnectButtonText?: string | ReactNode
  connectButtonText?: string | ReactNode
  disconnected?: boolean
}) => {
  const track = useAnalyticsTrack()

  const { open, ready } = useHeardPlaid({
    account,
    redirectLink: redirect ? redirectLink : '',
    redirectUri,
    disconnected,
  })

  if (account) {
    return (
      <Button
        variant={buttonVariant}
        size={buttonSize}
        onClick={() => {
          if (disconnected) {
            track('clicked plaid reconnect', {
              fi_name: account.plaidInstitutionName ?? '',
            })
          } else {
            track('clicked plaid add_remove', {
              fi_name: account.plaidInstitutionName ?? '',
            })
          }
          open()
        }}
        disabled={!ready}
        style={style}
      >
        {reconnectButtonText}
      </Button>
    )
  }
  return (
    <Button
      variant={buttonVariant}
      size={buttonSize}
      onClick={() => {
        track('clicked new financial institution')
        open()
      }}
      disabled={!ready}
      style={style}
    >
      {connectButtonText}
    </Button>
  )
}
export default PlaidLink
