import axios from 'axios'
import { normalize } from 'normalizr'
import { keyBy } from 'lodash'

import { fetchIfNeededWrapper, fetchWrapper } from '../../reducers/fetch'
import { PayrollProfile, setPayrollProfile } from './payrollProfile.slice'
import {
  EmployeeWithoutJob,
  removeEmployee,
  setMultipleEmployees,
} from './employee.slice'
import { employeeSchema } from '../../schema'
import {
  addEmployeeJob,
  addMultipleEmployeeJobs,
  removeEmployeeJob,
  updateCompensations,
  updateSingleCompensation,
} from './employeeJob.slice'
import {
  removeContractor,
  setMultipleContractors,
  setSingleContractor,
} from './contractor.slice'
import { setPayrollCompany } from './payrollCompany.slice'
import {
  setAllLocations,
  setSingleLocation,
} from './payrollCompanyLocation.slice'
import { setPayrolls, setSinglePayroll } from './payroll.slice'
import { setPaySchedule } from './paySchedule.slice'
import { Dispatch } from '../../utils/typeHelpers'
import {
  setAdminSingleUser,
  UserWithAdminInfo,
} from '../../reducers/admin/allUsersReducer'
import { WcEnrollmentRequirement } from './payrollWcEnrollmentRequirement.slice'
import {
  Gusto_Company,
  Gusto_CompanyAddress,
  Gusto_CompanyBankAccount,
  Gusto_CompanyFederalTaxes,
  Gusto_CompanyFederalTaxesInput,
  Gusto_CompanyIndustry,
  Gusto_CompanyInput,
  Gusto_CompanyOnboardingStatus,
  Gusto_Contractor,
  Gusto_ContractorInput,
  Gusto_ContractorPayment,
  Gusto_ContractorPaymentInput,
  Gusto_ContractorPaymentListResponse,
  Gusto_Employee,
  Gusto_EmployeeBankAccount,
  Gusto_EmployeeContractorOnboarding,
  Gusto_EmployeeContractorOnboardingStatus,
  Gusto_EmployeeFederalTaxes,
  Gusto_EmployeeHomeAddress,
  Gusto_EmployeeInput,
  Gusto_EmployeeJob,
  Gusto_EmployeeJobInput,
  Gusto_EmployeePaymentMethod,
  Gusto_EmployeeStateTaxes,
  Gusto_EmployeeStateTaxesInput,
  Gusto_EmployeeTermination,
  Gusto_EmployeeWorkAddress,
  Gusto_FormPdf,
  Gusto_GustoForm,
  Gusto_JobCompensation,
  Gusto_JobCompensationInput,
  Gusto_OffCycleInput,
  Gusto_PaymentConfig,
  Gusto_PaymentSpeed,
  Gusto_PayPeriod,
  Gusto_Payroll,
  Gusto_PayrollListParameters,
  Gusto_PaySchedule,
  Gusto_PayScheduleInput,
  Gusto_Paystub,
  Gusto_TOS,
  Gusto_UpdatePayroll,
} from './generated_gusto_types'

export const FETCH_PAYROLL_PROFILE_KEY = 'FETCH_PAYROLL_PROFILE_KEY'
export const fetchPayrollProfileIfNeeded = (alwaysFetch?: boolean) =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_PAYROLL_PROFILE_KEY,
    fetchFunction: async (dispatch) => {
      const res = await axios.get<PayrollProfile | null>(
        '/finances/api/v2/payroll_profiles'
      )
      dispatch(setPayrollProfile(res.data))
      return res.data
    },
    alwaysFetch,
  })

export const POST_PAYROLL_PROFILE_KEY = 'POST_PAYROLL_PROFILE_KEY'
export const postPayrollProfile = (payload: Gusto_CompanyInput) =>
  fetchWrapper({
    fetchKey: POST_PAYROLL_PROFILE_KEY,
    fetchFunction: async (dispatch) => {
      const res = await axios.post<PayrollProfile>(
        '/finances/api/v2/payroll/enroll',
        payload
      )
      dispatch(setPayrollProfile(res.data))
      return res.data
    },
  })

export const putPayrollProfile = (
  profile: Partial<PayrollProfile> & { id: number }
) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const res = await axios.put<PayrollProfile>(
        `/finances/api/v2/payroll_profiles/${profile.id}`,
        profile
      )
      dispatch(setPayrollProfile(res.data))
      return res.data
    },
  })

export const POST_MIGRATE_PAYROLL_KEY = 'POST_MIGRATE_PAYROLL_KEY'
export const postMigratePayroll = (payload: { code: string }) =>
  fetchWrapper({
    fetchKey: POST_MIGRATE_PAYROLL_KEY,
    fetchFunction: async (dispatch) => {
      const res = await axios.post<PayrollProfile>(
        '/finances/api/v2/payroll/migrate/',
        payload
      )
      dispatch(setPayrollProfile(res.data))
      return res.data
    },
  })

export const fetchPayrollAuthLink = () =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<{ authUrl: string }>('/finances/api/v2/payroll/auth_link')
        .then((json) => json.data),
  })

export const ONBOARDING_FLOWS = [
  'add_bank_info',
  'verify_bank_info',
  'state_setup',
  'sign_all_forms',
].join(',')

export const WORKERS_COMP_FLOW = 'company_workers_compensation'

export const postCreateGustoFlow = (flowType: string) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .post<{
          url: string
          expires_at: string
        }>('/finances/api/v2/payroll/create_flow', { flow_type: flowType })
        .then((json) => json.data),
  })

export const FETCH_PAYROLL_KEY = 'FETCH_PAYROLL_KEY'
export const fetchAllPayrolls = (params?: Gusto_PayrollListParameters) =>
  fetchWrapper({
    fetchKey: FETCH_PAYROLL_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<Gusto_Payroll[]>(
        '/finances/api/v2/payroll/companies/payrolls',
        {
          params: {
            // Payrolls don't come back without these and they're easy to miss so make them defaults
            processing_statuses: ['processed', 'unprocessed'],
            ...params,
          },
        }
      )

      dispatch(setPayrolls(keyBy(json.data, 'payroll_uuid')))

      return json.data
    },
  })

export const PUT_PAYROLL_KEY = 'PUT_PAYROLL_KEY'
export const putPayrollByUuid = (
  payrollUuid: string,
  payload: Gusto_UpdatePayroll
) =>
  fetchWrapper({
    fetchKey: PUT_PAYROLL_KEY,
    fetchFunction: async (dispatch) => {
      const newPayload = { ...payload }

      const res = await axios.put<Gusto_Payroll>(
        `/finances/api/v2/payroll/companies/payrolls/${payrollUuid}`,
        newPayload
      )

      dispatch(setSinglePayroll(res.data))
      return res.data
    },
  })

export const fetchSinglePayroll = (
  payrollUuid: string,
  params?: { include?: string[] }
) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const res = await axios.get<Gusto_Payroll>(
        `/finances/api/v2/payroll/companies/payrolls/${payrollUuid}`,
        { params }
      )
      dispatch(setSinglePayroll(res.data))
      return res.data
    },
  })

export const PUT_PREPARE_PAYROLL_CALCULATE_KEY = 'PUT_PAYROLL_CALCULATE_KEY'
export const putPreparePayroll = (payrollUuid: string) =>
  fetchWrapper({
    fetchKey: PUT_PREPARE_PAYROLL_CALCULATE_KEY,
    fetchFunction: async (dispatch) => {
      const res = await axios.put<Gusto_Payroll>(
        `/finances/api/v2/payroll/companies/payrolls/${payrollUuid}/prepare`
      )

      dispatch(setSinglePayroll(res.data))
      return res.data
    },
  })

export const PUT_PAYROLL_CALCULATE_KEY = 'PUT_PAYROLL_CALCULATE_KEY'
export const putCalculatePayroll = (payrollUuid: string) =>
  fetchWrapper({
    fetchKey: PUT_PAYROLL_CALCULATE_KEY,
    fetchFunction: async () => {
      await axios.put<undefined>(
        `/finances/api/v2/payroll/companies/payrolls/${payrollUuid}/calculate`
      )

      return true
    },
  })

export const PUT_PAYROLL_SUBMIT_KEY = 'PUT_PAYROLL_SUBMIT_KEY'
export const putSubmitPayroll = (payrollUuid: string) =>
  fetchWrapper({
    fetchKey: PUT_PAYROLL_SUBMIT_KEY,
    fetchFunction: async () => {
      await axios.put<undefined>(
        `/finances/api/v2/payroll/companies/payrolls/${payrollUuid}/submit`
      )
      return true
    },
  })

export const PUT_CREATE_OFF_CYCLE_PAYROLL_KEY =
  'PUT_CREATE_OFF_CYCLE_PAYROLL_KEY'
export const putCreateOffCyclePayroll = (payload: Gusto_OffCycleInput) =>
  fetchWrapper({
    fetchKey: PUT_CREATE_OFF_CYCLE_PAYROLL_KEY,
    fetchFunction: async (dispatch) => {
      const res = await axios.post<Gusto_Payroll>(
        '/finances/api/v2/payroll/companies/payrolls/create_offcycle',
        {
          ...payload,
          off_cycle: true,
        }
      )
      dispatch(setSinglePayroll(res.data))
      return res.data
    },
  })

export const putCancelPayroll = (payrollUuid: string) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const res = await axios.put<Gusto_Payroll>(
        `/finances/api/v2/payroll/companies/payrolls/${payrollUuid}/cancel`
      )
      dispatch(setSinglePayroll(res.data))
      return res.data
    },
  })

export const FETCH_EMPLOYEE_PAYSTUB_KEY = 'FETCH_EMPLOYEE_PAYSTUB_KEY'
export const fetchEmployeePaystub = (
  payrollUuid: string,
  employeeUuid: string
) =>
  fetchWrapper({
    fetchKey: FETCH_EMPLOYEE_PAYSTUB_KEY,
    fetchFunction: async () => {
      const res = await axios.get<Blob>(
        `/finances/api/v2/payroll/companies/payrolls/${payrollUuid}/employees/${employeeUuid}/pay_stub`,
        { responseType: 'blob' }
      )
      return res.data
    },
  })

export const fetchEmployeePaystubs = (employeeUuid: string) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<
          Gusto_Paystub[]
        >(`/finances/api/v2/payroll/employees/${employeeUuid}/pay_stubs`)
        .then((res) => res.data),
  })

export const fetchPayrollCompany = () =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const res = await axios.get<Gusto_Company | null>(
        '/finances/api/v2/payroll/companies'
      )
      dispatch(setPayrollCompany(res.data))
      return res.data
    },
  })

export const POST_ACCEPT_COMPANY_TOS_KEY = 'POST_ACCEPT_COMPANY_TOS_KEY'
export const postAcceptCompanyTOS = () =>
  fetchWrapper({
    fetchKey: POST_ACCEPT_COMPANY_TOS_KEY,
    fetchFunction: () =>
      axios
        .post<Gusto_TOS>(
          '/finances/api/v2/payroll/partner_managed_companies/accept_terms_of_service'
        )
        .then((json) => json.data),
  })

export const postRetrieveCompanyTos = () =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .post<Gusto_TOS>(
          '/finances/api/v2/payroll/partner_managed_companies/retrieve_terms_of_service'
        )
        .then((json) => json.data),
  })

export const FETCH_COMPANY_ONBOARDING_STATUS_KEY =
  'FETCH_COMPANY_ONBOARDING_STATUS_KEY'
export const fetchCompanyOnboardingStatus = () =>
  fetchWrapper({
    fetchKey: FETCH_COMPANY_ONBOARDING_STATUS_KEY,
    fetchFunction: () =>
      axios
        .get<Gusto_CompanyOnboardingStatus>(
          '/finances/api/v2/payroll/partner_managed_companies/onboarding_status'
        )
        .then((json) => json.data),
  })

export const PUT_FINISH_ONBOARDING_KEY = 'PUT_FINISH_ONBOARDING_KEY'
export const putFinishOnboarding = () =>
  fetchWrapper({
    fetchKey: PUT_FINISH_ONBOARDING_KEY,
    fetchFunction: () =>
      axios
        .put<Gusto_CompanyOnboardingStatus>(
          '/finances/api/v2/payroll/partner_managed_companies/finish_onboarding'
        )
        .then((json) => json.data),
  })

export const putApproveDemoCompany = () =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const res = await axios.put<Gusto_Company>(
        '/finances/api/v2/payroll/partner_managed_companies/approve_demo_company'
      )

      dispatch(setPayrollCompany(res.data))
      return res.data
    },
  })

export const POST_CONTACTOR_PAYMENT_KEY = 'POST_CONTACTOR_PAYMENT_KEY'
export const postPayContractor = (payload: Gusto_ContractorPaymentInput) =>
  fetchWrapper({
    fetchKey: POST_CONTACTOR_PAYMENT_KEY,
    fetchFunction: () =>
      axios
        .post<Gusto_ContractorPayment>(
          '/finances/api/v2/payroll/companies/contractor_payments',
          payload
        )
        .then((json) => json.data),
  })

export const fetchContractorPayments = (params: {
  start_date: string
  end_date: string
  contractor_uuid?: string
}) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<Gusto_ContractorPaymentListResponse>(
          '/finances/api/v2/payroll/companies/contractor_payments',
          { params }
        )
        .then((json) => json.data),
  })

export const fetchContractorPayment = (contractorPaymentUuid: string) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<Gusto_ContractorPayment>(
          `/finances/api/v2/payroll/companies/contractor_payments/${contractorPaymentUuid}`
        )
        .then((json) => json.data),
  })

export const cancelContractorPayment = (contractorPaymentUuid: string) =>
  fetchWrapper({
    fetchFunction: async () => {
      await axios.delete<undefined>(
        `/finances/api/v2/payroll/companies/contractor_payments/${contractorPaymentUuid}`
      )
      return true
    },
  })

export const FETCH_PAY_SCHEDULE_KEY = 'FETCH_PAY_SCHEDULE_KEY'
export const fetchAllPaySchedules = () =>
  fetchWrapper({
    fetchKey: FETCH_PAY_SCHEDULE_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<Gusto_PaySchedule[]>(
        '/finances/api/v2/payroll/companies/pay_schedules'
      )

      const activeSchedule = json.data.find((schedule) => schedule.active)
      if (activeSchedule) {
        dispatch(setPaySchedule(activeSchedule))
      }

      return json.data
    },
  })

export const POST_PAY_SCHEDULE_KEY = 'POST_PAY_SCHEDULE_KEY'
export const postPaySchedule = (payload: Gusto_PayScheduleInput) =>
  fetchWrapper({
    fetchKey: POST_PAY_SCHEDULE_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<Gusto_PaySchedule>(
        '/finances/api/v2/payroll/companies/pay_schedules',
        payload
      )
      dispatch(setPaySchedule(json.data))
      return json.data
    },
  })

export const fetchSinglePaySchedule = (payScheduleUuid: string) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const json = await axios.get<Gusto_PaySchedule>(
        `/finances/api/v2/payroll/companies/pay_schedules/${payScheduleUuid}`
      )
      dispatch(setPaySchedule(json.data))
      return json.data
    },
  })

export const PUT_UPDATE_PAY_SCHEDULE_KEY = 'PUT_UPDATE_PAY_SCHEDULE_KEY'
export const putUpdatePaySchedule = (
  payScheduleUuid: string,
  payload: { version: string; auto_pilot?: boolean }
) =>
  fetchWrapper({
    fetchKey: PUT_UPDATE_PAY_SCHEDULE_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.put<Gusto_PaySchedule>(
        `/finances/api/v2/payroll/companies/pay_schedules/${payScheduleUuid}`,
        payload
      )
      dispatch(setPaySchedule(json.data))
      return json.data
    },
  })

export const fetchPayPeriods = () =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<
          Gusto_PayPeriod[]
        >('/finances/api/v2/payroll/companies/pay_periods')
        .then((json) => json.data),
  })

export const FILLING_STATUSES = [
  'Single',
  'Married',
  'Head of Household',
  'Exempt from withholding',
]

export const fetchEmployeeFederalTaxes = (employeeUuid: string) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<Gusto_EmployeeFederalTaxes>(
          `/finances/api/v2/payroll/employees/${employeeUuid}/federal_taxes`
        )
        .then((json) => json.data),
  })

export const PUT_UPDATE_EMPLOYEE_FEDERAL_TAXES_KEY =
  'PUT_UPDATE_EMPLOYEE_FEDERAL_TAXES_KEY'
export const putUpdateEmployeeFederalTaxes = (
  employeeUuid: string,
  payload: Partial<Gusto_EmployeeFederalTaxes>
) =>
  fetchWrapper({
    fetchKey: PUT_UPDATE_EMPLOYEE_FEDERAL_TAXES_KEY,
    fetchFunction: () =>
      axios
        .put<Gusto_EmployeeFederalTaxes>(
          `/finances/api/v2/payroll/employees/${employeeUuid}/federal_taxes`,
          payload
        )
        .then((json) => json.data),
  })

export const fetchEmployeeStateTaxes = (employeeUuid: string) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<
          Gusto_EmployeeStateTaxes[]
        >(`/finances/api/v2/payroll/employees/${employeeUuid}/state_taxes`)
        .then((json) => json.data),
  })

export const PUT_UPDATE_EMPLOYEE_STATE_TAXES_KEY =
  'PUT_UPDATE_EMPLOYEE_STATE_TAXES_KEY'
export const putUpdateEmployeeStateTaxes = (
  employeeUuid: string,
  payload: Gusto_EmployeeStateTaxesInput
) =>
  fetchWrapper({
    fetchKey: PUT_UPDATE_EMPLOYEE_STATE_TAXES_KEY,
    fetchFunction: () =>
      axios
        .put<Gusto_EmployeeStateTaxes>(
          `/finances/api/v2/payroll/employees/${employeeUuid}/state_taxes`,
          payload
        )
        .then((json) => json.data),
  })

export const FETCH_COMPANY_FEDERAL_TAXES_KEY = 'FETCH_COMPANY_FEDERAL_TAXES_KEY'
export const fetchCompanyFederalTaxes = () =>
  fetchWrapper({
    fetchKey: FETCH_COMPANY_FEDERAL_TAXES_KEY,
    fetchFunction: () =>
      axios
        .get<Gusto_CompanyFederalTaxes>(
          '/finances/api/v2/payroll/companies/federal_tax_details'
        )
        .then((json) => json.data),
  })

export const PUT_UPDATE_COMPANY_FEDERAL_TAXES_KEY =
  'PUT_UPDATE_COMPANY_FEDERAL_TAXES_KEY'
export const putUpdateCompanyFederalTaxes = (
  payload: Gusto_CompanyFederalTaxesInput
) =>
  fetchWrapper({
    fetchKey: PUT_UPDATE_COMPANY_FEDERAL_TAXES_KEY,
    fetchFunction: () =>
      axios
        .put<Gusto_CompanyFederalTaxes>(
          '/finances/api/v2/payroll/companies/federal_tax_details',
          payload
        )
        .then((json) => json.data),
  })

export const fetchCompanyIndustry = () =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<Gusto_CompanyIndustry>(
          '/finances/api/v2/payroll/companies/industry_selection'
        )
        .then((json) => json.data),
  })

export const PUT_UPDATE_COMPANY_INDUSTRY_KEY = 'PUT_UPDATE_COMPANY_INDUSTRY_KEY'
export const putUpdateCompanyIndustry = (
  payload: Omit<Gusto_CompanyIndustry, 'company_uuid'>
) =>
  fetchWrapper({
    fetchKey: PUT_UPDATE_COMPANY_INDUSTRY_KEY,
    fetchFunction: () =>
      axios
        .put<Gusto_CompanyIndustry>(
          '/finances/api/v2/payroll/companies/industry_selection',
          payload
        )
        .then((json) => json.data),
  })

export const fetchAllCompanyForms = () =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<Gusto_GustoForm[]>('/finances/api/v2/payroll/company_forms')
        .then((json) => json.data),
  })

export const fetchSingleCompanyForm = (formUuid: string) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<Gusto_GustoForm>(`/finances/api/v2/payroll/forms/${formUuid}`)
        .then((json) => json.data),
  })

export const fetchCompanyFormPdf = (formUuid: string) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<Gusto_FormPdf>(`/finances/api/v2/payroll/forms/${formUuid}/pdf`)
        .then((json) => json.data),
  })

export const fetchAllEmployeeForms = (employeeUuid: string) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<
          Gusto_GustoForm[]
        >(`/finances/api/v2/payroll/employees/${employeeUuid}/forms`)
        .then((json) => json.data),
  })

export const fetchSingleEmployeeForm = (
  employeeUuid: string,
  formUuid: string
) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<Gusto_GustoForm>(
          `/finances/api/v2/payroll/employees/${employeeUuid}/forms/${formUuid}`
        )
        .then((json) => json.data),
  })

export const fetchEmployeeFormPdf = (employeeId: string, formUuid: string) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<Gusto_FormPdf>(
          `/finances/api/v2/payroll/employees/${employeeId}/forms/${formUuid}/pdf`
        )
        .then((json) => json.data),
  })

export const fetchAllContractorForms = (contractorUuid: string) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<
          Gusto_GustoForm[]
        >(`/finances/api/v2/payroll/contractors/${contractorUuid}/forms`)
        .then((json) => json.data),
  })

export const fetchSingleContractorForm = (
  contractorUuid: string,
  formUuid: string
) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<Gusto_GustoForm>(
          `/finances/api/v2/payroll/contractors/${contractorUuid}/forms/${formUuid}`
        )
        .then((json) => json.data),
  })

export const fetchContractorFormPdf = (
  contractorUuid: string,
  formUuid: string
) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<Gusto_FormPdf>(
          `/finances/api/v2/payroll/contractors/${contractorUuid}/forms/${formUuid}/pdf`
        )
        .then((json) => json.data),
  })

// Be careful with this call!  It's for signing employee forms AS the employee, not the employer
export const putSignEmployeeForm = (
  employeeUuid: string,
  formUuid: string,
  payload: { agree: boolean; signature_text: string }
) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .put<Gusto_GustoForm>(
          `/finances/api/v2/payroll/employees/${employeeUuid}/forms/${formUuid}/sign`,
          payload
        )
        .then((json) => json.data),
  })

export const FETCH_EMPLOYEES_KEY = 'FETCH_EMPLOYEE_KEY'
export const fetchEmployees = () =>
  fetchWrapper({
    fetchKey: FETCH_EMPLOYEES_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<Gusto_Employee[]>(
        '/finances/api/v2/payroll/company_employees'
      )

      const data = normalize<
        unknown,
        {
          employees: { [key: string]: EmployeeWithoutJob }
          employeeJobs: { [key: string]: Gusto_EmployeeJob }
        }
      >(json.data, [employeeSchema])

      dispatch(setMultipleEmployees(data.entities.employees))
      dispatch(addMultipleEmployeeJobs(data.entities.employeeJobs))

      return json.data
    },
  })

export const POST_CREATE_EMPLOYEE_KEY = 'POST_CREATE_EMPLOYEE_KEY'
export const postCreateEmployee = (payload: Gusto_EmployeeInput) =>
  fetchWrapper({
    fetchKey: POST_CREATE_EMPLOYEE_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<Gusto_Employee>(
        '/finances/api/v2/payroll/company_employees',
        payload
      )

      const data = normalize<
        unknown,
        {
          employees: { [key: string]: EmployeeWithoutJob }
          employeeJobs: { [key: string]: Gusto_EmployeeJob }
        }
      >(json.data, employeeSchema)

      dispatch(setMultipleEmployees(data.entities.employees))
      dispatch(addMultipleEmployeeJobs(data.entities.employeeJobs))

      return json.data
    },
  })

export const fetchSingleEmployee = (employeeUuid: string) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const json = await axios.get<Gusto_Employee>(
        `/finances/api/v2/payroll/employees/${employeeUuid}`
      )
      const data = normalize<
        unknown,
        {
          employees: { [key: string]: EmployeeWithoutJob }
          employeeJobs: { [key: string]: Gusto_EmployeeJob }
        }
      >(json.data, employeeSchema)

      dispatch(setMultipleEmployees(data.entities.employees))
      dispatch(addMultipleEmployeeJobs(data.entities.employeeJobs))

      return data
    },
  })

export const PUT_UPDATE_EMPLOYEE_KEY = 'PUT_UPDATE_EMPLOYEE_KEY'
export const putUpdateEmployee = (
  employeeUuid: string,
  payload: Gusto_EmployeeInput & { version: string }
) =>
  fetchWrapper({
    fetchKey: PUT_UPDATE_EMPLOYEE_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.put<Gusto_Employee>(
        `/finances/api/v2/payroll/employees/${employeeUuid}`,
        payload
      )

      const data = normalize<
        unknown,
        {
          employees: { [key: string]: EmployeeWithoutJob }
          employeeJobs: { [key: string]: Gusto_EmployeeJob }
        }
      >(json.data, employeeSchema)

      dispatch(setMultipleEmployees(data.entities.employees))
      dispatch(addMultipleEmployeeJobs(data.entities.employeeJobs))

      return data
    },
  })

export const deleteEmployee = (employeeUuid: string) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      await axios.delete<undefined>(
        `/finances/api/v2/payroll/employees/${employeeUuid}`
      )
      dispatch(removeEmployee(employeeUuid))
      return true
    },
  })

export const deleteContractor = (contractorUuid: string) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      await axios.delete<undefined>(
        `/finances/api/v2/payroll/contractors/${contractorUuid}`
      )
      dispatch(removeContractor(contractorUuid))
      return true
    },
  })

export const postCreateEmployeeTermination = (
  employeeUuid: string,
  payload: { effective_date: string; run_termination_payroll: boolean }
) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .post<
          Gusto_EmployeeTermination[]
        >(`/finances/api/v2/payroll/employees/${employeeUuid}/terminations`, payload)
        .then((json) => json.data),
  })

export const fetchEmployeeTerminations = (employeeUuid: string) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<
          Gusto_EmployeeTermination[]
        >(`/finances/api/v2/payroll/employees/${employeeUuid}/terminations`)
        .then((json) => json.data),
  })

export const fetchEmployeeHomeAddresses = (employeeUuid?: string) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<
          Gusto_EmployeeHomeAddress[]
        >(`/finances/api/v2/payroll/employees/${employeeUuid}/home_addresses`)
        .then((json) => json.data),
  })

export const postCreateEmployeeHomeAddress = (
  employeeUuid: string,
  payload: Omit<
    Gusto_EmployeeHomeAddress,
    'version' | 'active' | 'country' | 'uuid'
  >
) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .post<Gusto_EmployeeHomeAddress>(
          `/finances/api/v2/payroll/employees/${employeeUuid}/home_address`,
          payload
        )
        .then((json) => json.data),
  })

export const PUT_UPDATE_EMPLOYEE_HOME_ADDRESS_KEY =
  'PUT_UPDATE_EMPLOYEE_HOME_ADDRESS_KEY'
export const putUpdateEmployeeHomeAddress = (
  addressUuid: string,
  payload: Omit<Gusto_EmployeeHomeAddress, 'active' | 'country' | 'uuid'>
) =>
  fetchWrapper({
    fetchKey: PUT_UPDATE_EMPLOYEE_HOME_ADDRESS_KEY,
    fetchFunction: () =>
      axios
        .put<Gusto_EmployeeHomeAddress>(
          `/finances/api/v2/payroll/employees/${addressUuid}/home_address`,
          payload
        )
        .then((json) => json.data),
  })

export const fetchEmployeeWorkAddresses = (employeeUuid?: string) =>
  fetchWrapper({
    fetchFunction: () =>
      employeeUuid
        ? axios
            .get<
              Gusto_EmployeeWorkAddress[]
            >(`/finances/api/v2/payroll/employees/${employeeUuid}/work_addresses`)
            .then((json) => json.data)
        : undefined,
  })

export const postCreateEmployeeWorkAddress = (
  employeeId: string,
  payload: {
    location_uuid: string
  }
) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .post<Gusto_EmployeeWorkAddress>(
          `/finances/api/v2/payroll/employees/${employeeId}/work_address`,
          payload
        )
        .then((json) => json.data),
  })

export const putUpdateEmployeeWorkAddress = (
  addressId: string,
  payload: {
    version: string
    location_uuid: string
  }
) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .put<Gusto_EmployeeWorkAddress>(
          `/finances/api/v2/payroll/employees/${addressId}/work_address`,
          payload
        )
        .then((json) => json.data),
  })

export const fetchEmployeeOnboardingStatus = (employeeUuid: string) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<Gusto_EmployeeContractorOnboarding>(
          `/finances/api/v2/payroll/employees/${employeeUuid}/onboarding_status`
        )
        .then((json) => json.data),
  })

export const PUT_EMPLOYEE_ONBOARDING_STATUS_KEY =
  'PUT_EMPLOYEE_ONBOARDING_STATUS_KEY'
export const putEmployeeOnboardingStatus = (
  employeeUuid: string,
  newStatus: Gusto_EmployeeContractorOnboardingStatus
) =>
  fetchWrapper({
    fetchKey: PUT_EMPLOYEE_ONBOARDING_STATUS_KEY,
    fetchFunction: () =>
      axios
        .put<Gusto_EmployeeContractorOnboarding>(
          `/finances/api/v2/payroll/employees/${employeeUuid}/onboarding_status`,
          {
            onboarding_status: newStatus,
          }
        )
        .then((json) => json.data),
  })

export const fetchContractorOnboardingStatus = (contractorUuid: string) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<Gusto_EmployeeContractorOnboarding>(
          `/finances/api/v2/payroll/contractors/${contractorUuid}/onboarding_status`
        )
        .then((json) => json.data),
  })

export const PUT_CONTRACTOR_ONBOARDING_STATUS_KEY =
  'PUT_CONTRACTOR_ONBOARDING_STATUS_KEY'
export const putContractorOnboardingStatus = (
  contractorUuid: string,
  newStatus: Gusto_EmployeeContractorOnboardingStatus
) =>
  fetchWrapper({
    fetchKey: PUT_CONTRACTOR_ONBOARDING_STATUS_KEY,
    fetchFunction: () =>
      axios
        .put<Gusto_EmployeeContractorOnboarding>(
          `/finances/api/v2/payroll/contractors/${contractorUuid}/onboarding_status`,
          {
            onboarding_status: newStatus,
          }
        )
        .then((json) => json.data),
  })

export const FETCH_CONTRACTORS_KEY = 'FETCH_CONTRACTORS_KEY'
export const fetchContractors = () =>
  fetchWrapper({
    fetchKey: FETCH_CONTRACTORS_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<Gusto_Contractor[]>(
        '/finances/api/v2/payroll/companies/contractors'
      )

      dispatch(setMultipleContractors(keyBy(json.data, 'uuid')))
      return json.data
    },
  })

export const POST_CREATE_CONTRACTOR_KEY = 'POST_CREATE_CONTRACTOR_KEY'
export const postCreateContractor = (payload: Gusto_ContractorInput) =>
  fetchWrapper({
    fetchKey: POST_CREATE_CONTRACTOR_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<Gusto_Contractor>(
        '/finances/api/v2/payroll/companies/contractors',
        payload
      )

      dispatch(setSingleContractor(json.data))
      return json.data
    },
  })

export const fetchSingleContractor = (contractorUuid: string) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const json = await axios.get<Gusto_Contractor>(
        `/finances/api/v2/payroll/contractors/${contractorUuid}`
      )

      dispatch(setSingleContractor(json.data))
      return json.data
    },
  })

export const PUT_UPDATE_CONTRACTOR_KEY = 'PUT_UPDATE_CONTRACTOR_KEY'
export const putUpdateContractor = (
  contractorUuid: string,
  payload: Partial<Gusto_Contractor>
) =>
  fetchWrapper({
    fetchKey: PUT_UPDATE_CONTRACTOR_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.put<Gusto_Contractor>(
        `/finances/api/v2/payroll/contractors/${contractorUuid}`,
        payload
      )

      dispatch(setSingleContractor(json.data))
      return json.data
    },
  })

export const POST_CREATE_EMPLOYEE_JOB_KEY = 'POST_CREATE_EMPLOYEE_JOB_KEY'
export const postCreateEmployeeJob = (
  employeeUuid: string,
  payload: Gusto_EmployeeJobInput
) =>
  fetchWrapper({
    fetchKey: POST_CREATE_EMPLOYEE_JOB_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<Gusto_EmployeeJob>(
        `/finances/api/v2/payroll/employees/${employeeUuid}/jobs`,
        payload
      )

      dispatch(addEmployeeJob(json.data))
      return json.data
    },
  })

export const fetchEmployeeJobs = (employeeUuid: string) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const json = await axios.get<Gusto_EmployeeJob[]>(
        `/finances/api/v2/payroll/employees/${employeeUuid}/jobs`
      )
      dispatch(addMultipleEmployeeJobs(keyBy(json.data, 'uuid')))
      return json.data
    },
  })

export const fetchEmployeeJob = (jobUuid: string) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const json = await axios.get<Gusto_EmployeeJob>(
        `/finances/api/v2/payroll/jobs/${jobUuid}`
      )

      dispatch(addEmployeeJob(json.data))
      return json.data
    },
  })

export const PUT_UPDATE_EMPLOYEE_JOB_KEY = 'PUT_UPDATE_EMPLOYEE_JOB_KEY'
export const putUpdateEmployeeJob = (
  jobUuid: string,
  payload: Partial<Gusto_EmployeeJobInput> & { version: string }
) =>
  fetchWrapper({
    fetchKey: PUT_UPDATE_EMPLOYEE_JOB_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.put<Gusto_EmployeeJob>(
        `/finances/api/v2/payroll/jobs/${jobUuid}`,
        payload
      )
      dispatch(addEmployeeJob(json.data))
      return json.data
    },
  })

export const deleteEmployeeJob = (employeeUuid: string, jobUuid: string) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      await axios.delete<undefined>(`/finances/api/v2/payroll/jobs/${jobUuid}`)
      dispatch(removeEmployeeJob({ jobUuid, employeeUuid }))
      return true
    },
  })

export const fetchAllJobCompensations = (jobUuid: string) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const res = await axios.get<Gusto_JobCompensation[]>(
        `/finances/api/v2/payroll/jobs/${jobUuid}/compensations`
      )

      dispatch(updateCompensations({ jobUuid, compensations: res.data }))

      return res.data
    },
  })

export const fetchSingleJobCompensation = (compensationUuid: string) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const res = await axios.get<Gusto_JobCompensation>(
        `/finances/api/v2/payroll/compensations/${compensationUuid}`
      )

      dispatch(updateSingleCompensation(res.data))

      return res.data
    },
  })

export const PUT_UPDATE_JOB_COMPENSATION_KEY = 'PUT_UPDATE_JOB_COMPENSATION_KEY'
export const putUpdateJobCompensation = (
  compensationUuid: string,
  payload: Gusto_JobCompensationInput
) =>
  fetchWrapper({
    fetchKey: PUT_UPDATE_JOB_COMPENSATION_KEY,
    fetchFunction: async (dispatch) => {
      const res = await axios.put<Gusto_JobCompensation>(
        `/finances/api/v2/payroll/compensations/${compensationUuid}`,
        payload
      )

      dispatch(updateSingleCompensation(res.data))

      return res.data
    },
  })

export const FETCH_LOCATIONS_KEY = 'FETCH_LOCATIONS_KEY'

export const fetchAllCompanyLocations = () =>
  fetchWrapper({
    fetchKey: FETCH_LOCATIONS_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<Gusto_CompanyAddress[]>(
        '/finances/api/v2/payroll/companies/locations'
      )

      dispatch(setAllLocations(keyBy(json.data, 'uuid')))

      return json.data
    },
  })

export const POST_CREATE_COMPANY_LOCATION_KEY =
  'POST_CREATE_COMPANY_LOCATION_KEY'
export const postCreateCompanyLocation = (
  payload: Omit<
    Gusto_CompanyAddress,
    'version' | 'country' | 'active' | 'uuid' | 'company_uuid'
  >
) =>
  fetchWrapper({
    fetchKey: POST_CREATE_COMPANY_LOCATION_KEY,
    fetchFunction: async (dispatch) => {
      const res = await axios.post<Gusto_CompanyAddress>(
        '/finances/api/v2/payroll/companies/locations',
        payload
      )

      dispatch(setSingleLocation(res.data))

      return res.data
    },
  })

export const fetchSingleCompanyLocation = (locationUuid: string) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const res = await axios.get<Gusto_CompanyAddress>(
        `/finances/api/v2/payroll/companies/locations/${locationUuid}`
      )

      dispatch(setSingleLocation(res.data))

      return res.data
    },
  })

export const PUT_UPDATE_COMPANY_LOCATION_KEY = 'PUT_UPDATE_COMPANY_LOCATION_KEY'
export const putUpdateCompanyLocation = (
  locationUuid: string,
  payload: Gusto_CompanyAddress
) =>
  fetchWrapper({
    fetchKey: PUT_UPDATE_COMPANY_LOCATION_KEY,
    fetchFunction: async (dispatch) => {
      const res = await axios.put<Gusto_CompanyAddress>(
        `/finances/api/v2/payroll/companies/locations/${locationUuid}`,
        payload
      )

      dispatch(setSingleLocation(res.data))

      return res.data
    },
  })

export const postCreateBankAccount = (payload: {
  routing_number: string
  account_number: string
  account_type: 'Checking' | 'Savings'
}) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .post<Gusto_CompanyBankAccount>(
          '/finances/api/v2/payroll/companies/bank_accounts',
          payload
        )
        .then((json) => json.data),
  })

export const fetchBankAccounts = () =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<
          Gusto_CompanyBankAccount[]
        >('/finances/api/v2/payroll/companies/bank_accounts')
        .then((json) => json.data),
  })

export const putVerifyBankAccount = (
  uuid: string,
  payload: { deposit_1: number; deposit_2: number }
) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .put<Gusto_CompanyBankAccount>(
          `/finances/api/v2/payroll/companies/bank_accounts/${uuid}/verify`,
          payload
        )
        .then((json) => json.data),
  })

export const fetchEmployeeBankAccounts = (employeeUuid: string) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<
          Gusto_EmployeeBankAccount[]
        >(`/finances/api/v2/payroll/employees/${employeeUuid}/bank_accounts`)
        .then((json) => json.data),
  })

export const POST_CREATE_EMPLOYEE_BANK_ACCOUNT_KEY =
  'POST_CREATE_EMPLOYEE_BANK_ACCOUNT_KEY'
export const postCreateEmployeeBankAccount = (
  employeeUuid: string,
  payload: {
    name: string
    routing_number: string
    account_number: string
    account_type: 'Checking' | 'Savings'
  }
) =>
  fetchWrapper({
    fetchKey: POST_CREATE_EMPLOYEE_BANK_ACCOUNT_KEY,
    fetchFunction: () =>
      axios
        .post<Gusto_EmployeeBankAccount>(
          `/finances/api/v2/payroll/employees/${employeeUuid}/bank_accounts`,
          payload
        )
        .then((json) => json.data),
  })

export const deleteEmployeeBankAccount = (
  employeeUuid: string,
  bankAccountUuid: string
) =>
  fetchWrapper({
    fetchFunction: async () => {
      await axios.delete<undefined>(
        `/finances/api/v2/payroll/employees/${employeeUuid}/bank_accounts/${bankAccountUuid}`
      )
      return true
    },
  })

export const fetchEmployeePaymentMethod = (employeeUuid: string) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<Gusto_EmployeePaymentMethod>(
          `/finances/api/v2/payroll/employees/${employeeUuid}/payment_method`
        )
        .then((json) => json.data),
  })

export const PUT_UPDATE_EMPLOYEE_PAYMENT_METHOD_KEY =
  'PUT_UPDATE_EMPLOYEE_PAYMENT_METHOD_KEY'
export const putUpdateEmployeePaymentMethod = (
  employeeUuid: string,
  payload: Gusto_EmployeePaymentMethod
) =>
  fetchWrapper({
    fetchKey: PUT_UPDATE_EMPLOYEE_PAYMENT_METHOD_KEY,
    fetchFunction: () =>
      axios
        .put<Gusto_EmployeePaymentMethod>(
          `/finances/api/v2/payroll/employees/${employeeUuid}/payment_method`,
          payload
        )
        .then((json) => json.data),
  })

export const fetchPaymentConfigs = () =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<Gusto_PaymentConfig>(
          '/finances/api/v2/payroll/companies/payment_configs'
        )
        .then((json) => json.data),
  })

export const PUT_UPDATE_PAYMENT_CONFIG_KEY = 'PUT_UPDATE_PAYMENT_CONFIG_KEY'
export const putUpdatePaymentConfig = (payload: {
  fast_payment_limit?: number
  payment_speed?: Gusto_PaymentSpeed
}) =>
  fetchWrapper({
    fetchKey: PUT_UPDATE_PAYMENT_CONFIG_KEY,
    fetchFunction: () =>
      axios
        .put<Gusto_PaymentConfig>(
          '/finances/api/v2/payroll/companies/payment_configs',
          payload
        )
        .then((json) => json.data),
  })

// Admin Heard endpoints not connected to Gusto
export const fetchPayrollForUser = (userId: number | string) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<PayrollProfile | null>(
          `/finances/api/v2/admin/payroll/payroll_profile/${userId}`
        )
        .then((json) => json.data),
  })

export const ENABLE_PAYROLL_KEY = 'ENABLE_PAYROLL_KEY'
export const enablePayroll = (userId: number, needsMigration: boolean) =>
  fetchWrapper({
    fetchKey: ENABLE_PAYROLL_KEY,
    fetchFunction: async (dispatch) => {
      const res = await axios.post<UserWithAdminInfo>(
        '/finances/api/v2/admin/payroll/enable_payroll',
        {
          userId,
          needsMigration,
        }
      )

      dispatch(setAdminSingleUser(res.data))

      return res.data
    },
  })

export const disablePayroll = (userId: number, payrollProfileId?: number) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const res = await axios.post<UserWithAdminInfo>(
        '/finances/api/v2/admin/payroll/disable_payroll',
        {
          userId,
          payrollProfileId,
        }
      )

      dispatch(setAdminSingleUser(res.data))

      return res.data
    },
  })

export const fetchPayrollUsers = () =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<
          PayrollProfile[]
        >('/finances/api/v2/admin/payroll/payroll_profiles')
        .then((json) => json.data),
  })

export const FETCH_PAYROLLS_FOR_USER_KEY = (userId: number) =>
  `FETCH_PAYROLLS_FOR_USER_${userId}_KEY`
export const fetchPayrollsForUser = (
  userId: number,
  params?: Gusto_PayrollListParameters
) =>
  fetchWrapper({
    fetchKey: FETCH_PAYROLLS_FOR_USER_KEY(userId),
    fetchFunction: () =>
      axios
        .get<
          Gusto_Payroll[]
        >(`/finances/api/v2/admin/payroll/companies/payrolls/${userId}`, { params })
        .then((json) => json.data),
  })

export const markPayrollInactive = (userId: number) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .post('/finances/api/v2/admin/payroll/mark_inactive', { userId })
        .then((json) => json.data),
  })

export const setupFinishEmployeeSetup =
  (employeeUuid: string, stateTaxInput: Gusto_EmployeeStateTaxesInput) =>
  async (dispatch: Dispatch) => {
    try {
      // Step 1 - Answer the state tax questions for the employee.  There are specific ones an employer needs to answer
      const updateStateRes = await dispatch(
        putUpdateEmployeeStateTaxes(employeeUuid, stateTaxInput)
      )

      if (!updateStateRes) {
        return false
      }

      // Step 2 - refetch employee to ensure they are up to date
      return dispatch(fetchSingleEmployee(employeeUuid))
    } catch (_) {
      return false
    }
  }

export const downloadPayrollReport = (bookkeepingReportId: number | string) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get(
          `/finances/api/v2/admin/bookkeeping_reports/${bookkeepingReportId}/generate_payroll_report`,
          { responseType: 'blob' }
        )
        .then((json) => json.data),
  })

export const fetchPayrollWcEnrollmentRequirements = () =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<WcEnrollmentRequirement>(
          '/finances/api/v2/payroll/workers_compensation/requirements'
        )
        .then((json) => json.data),
  })
