import React from 'react'
import { Image } from 'semantic-ui-react'
import styles from './styles.module.scss'
import { Text } from '../BaseComponents'

interface ILandingPageProps {
  children?: React.ReactNode
  imageAlt: string
  imageSrc: string
  imageHeight: number
  imageWidth: number
  title: string
  description?: string
  customColor?: string
}

export const StepHeaders: React.FC<ILandingPageProps> = ({
  children,
  imageAlt,
  imageSrc,
  imageHeight,
  imageWidth,
  title,
  description = '',
  customColor = '',
}) => {
  const imageContainerClass =
    customColor === 'green' ? styles.greenBackground : styles.defaultBackground

  return (
    <div className={styles.container}>
      <div className={`${styles.imageContainer} ${imageContainerClass}`}>
        <Image
          src={imageSrc}
          alt={imageAlt}
          width={imageWidth}
          height={imageHeight}
        />
      </div>

      <div className={styles.bodyContainer}>
        <h1 className={styles.header}>{title}</h1>

        {description && (
          <Text className={styles.descriptionMarginHeader}>{description}</Text>
        )}
      </div>
      {children && <div className={styles.childrenContainer}>{children}</div>}
    </div>
  )
}
