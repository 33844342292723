import { useCallback, useState } from 'react'

import {
  fetchCompanyFormPdf,
  fetchContractorFormPdf,
  fetchEmployeeFormPdf,
} from '../payrollActions'
import { openFileInTab } from '../../../utils/aws'
import { Button, Table } from '../../../components/BaseComponents'
import SignFormModal from './SignFormModal'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { Gusto_GustoForm } from '../generated_gusto_types'

const FormRow = ({
  form,
  employeeUuid,
  contractorUuid,
  allowSign,
  onSign,
}: {
  form: Gusto_GustoForm
  employeeUuid?: string
  contractorUuid?: string
  allowSign?: boolean
  onSign?: () => void
}) => {
  const dispatch = useAppDispatch()
  const [showModal, setShowModal] = useState(false)
  const [requiresSign, setRequiresSign] = useState(form.requires_signing)

  const downloadDoc = useCallback(async () => {
    let res

    if (employeeUuid) {
      res = await fetchEmployeeFormPdf(employeeUuid, form.uuid)(dispatch)
    } else if (contractorUuid) {
      res = await fetchContractorFormPdf(contractorUuid, form.uuid)(dispatch)
    } else {
      res = await fetchCompanyFormPdf(form.uuid)(dispatch)
    }

    if (res?.document_url) {
      openFileInTab(res.document_url)
    }
  }, [contractorUuid, dispatch, employeeUuid, form.uuid])

  // Note contractor forms aren't signed
  const onFormSigned = useCallback(
    (val: boolean) => {
      onSign?.()
      setRequiresSign(val)
    },
    [onSign]
  )

  return (
    <Table.Row>
      <Table.Cell>
        <Button
          variant="link"
          onClick={downloadDoc}
          style={{ textAlign: 'left' }}
        >
          {form.title}
        </Button>
      </Table.Cell>
      <Table.Cell>{form.description}</Table.Cell>
      {allowSign && (
        <>
          <Table.Cell>{requiresSign ? 'Unsigned' : 'Signed'}</Table.Cell>
          <Table.Cell>
            {requiresSign && (
              <Button variant="link" onClick={() => setShowModal(true)}>
                Sign Form
              </Button>
            )}
          </Table.Cell>
        </>
      )}
      {showModal && (
        <SignFormModal
          form={form}
          onFormSigned={onFormSigned}
          closeModal={() => setShowModal(false)}
          employeeUuid={employeeUuid}
        />
      )}
    </Table.Row>
  )
}

const GustoFormsTable = ({
  forms,
  employeeUuid,
  contractorUuid,
  allowSign,
  onSign,
}: {
  forms: Gusto_GustoForm[]
  employeeUuid?: string
  contractorUuid?: string
  allowSign?: boolean
  onSign?: () => void
}) => (
  <Table basic>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={4}>Document</Table.HeaderCell>
        <Table.HeaderCell width={8}>Description</Table.HeaderCell>
        {allowSign && (
          <>
            <Table.HeaderCell width={2}>Status</Table.HeaderCell>
            <Table.HeaderCell width={2}>Action</Table.HeaderCell>
          </>
        )}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {forms.map((form) => (
        <FormRow
          key={form.uuid}
          form={form}
          employeeUuid={employeeUuid}
          contractorUuid={contractorUuid}
          allowSign={allowSign}
          onSign={onSign}
        />
      ))}
    </Table.Body>
  </Table>
)

export default GustoFormsTable
