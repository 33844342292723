import { useState } from 'react'
import { Table, Confirm } from 'semantic-ui-react'
import Moment from 'react-moment'

import { Button } from '../../BaseComponents'
import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import {
  adminDeleteUserImportantDate,
  UserImportantDate,
} from '../../../features/Dashboard/UserImportantDates/userImportantDates.slice'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useAppDispatch } from '../../../utils/typeHelpers'

const UserImportantDateRow = ({
  userImportantDate,
  user,
}: {
  userImportantDate: UserImportantDate
  user: UserWithAdminInfo
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const dispatch = useAppDispatch()
  const removeAction = async () => {
    const res = await adminDeleteUserImportantDate(
      userImportantDate.id,
      user.id
    )(dispatch)
    if (res) {
      setConfirmOpen(false)
    }
  }

  return (
    <Table.Row>
      <Table.Cell>{userImportantDate.id}</Table.Cell>
      <Table.Cell>{userImportantDate.actionItem.description}</Table.Cell>
      <Table.Cell>
        {userImportantDate.actionItem.actionItemCategory?.title}
      </Table.Cell>
      <Table.Cell>
        {userImportantDate.createdAt && (
          <Moment format={DATE_FORMATS.DISPLAY_SHORT}>
            {userImportantDate.createdAt}
          </Moment>
        )}
      </Table.Cell>
      <Table.Cell error={!userImportantDate.readAt}>
        {userImportantDate.readAt && (
          <Moment format={DATE_FORMATS.DISPLAY_SHORT}>
            {userImportantDate.readAt}
          </Moment>
        )}
      </Table.Cell>
      <Table.Cell>
        <Button variant="link" onClick={() => setConfirmOpen(true)}>
          Remove
        </Button>
        <Confirm
          size="mini"
          cancelButton="Cancel"
          confirmButton="Remove Date"
          content={
            "Are you sure you want to remove this date from the User's Dashboard?"
          }
          open={confirmOpen}
          onCancel={() => setConfirmOpen(false)}
          onConfirm={() => removeAction()}
        />
      </Table.Cell>
    </Table.Row>
  )
}
export const UserImportantDatesTable = ({
  importantDates,
  user,
}: {
  importantDates: UserImportantDate[]
  user: UserWithAdminInfo
}) => {
  return (
    <Table striped compact="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Id</Table.HeaderCell>
          <Table.HeaderCell>Todo Name</Table.HeaderCell>
          <Table.HeaderCell>Category</Table.HeaderCell>
          <Table.HeaderCell>Assigned On</Table.HeaderCell>
          <Table.HeaderCell>Completed On</Table.HeaderCell>
          <Table.HeaderCell>Delete</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {importantDates.map((item, key) => {
          return (
            <UserImportantDateRow
              userImportantDate={item}
              user={user}
              key={key}
            />
          )
        })}
      </Table.Body>
    </Table>
  )
}
