import { Fragment } from 'react'
import { Divider, Grid } from 'semantic-ui-react'
import { range } from 'lodash'

import {
  GridRowColumn,
  Text,
  Link,
  GridColumnSpacer,
} from '../../../../components/BaseComponents'
import AnnualTaxFaq from './AnnualTaxFaq'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectSortedTaxReturnDocumentsForYear } from '../../../UserDocuments/userDocuments.selector'
import { UserDocument } from '../../../UserDocuments/userDocuments.slice'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'

const AnnualTaxFilingFormTaxDocument = ({
  document,
}: {
  document: UserDocument
}) =>
  document.signedUrl ? (
    <GridRowColumn short>
      <Link href={document.signedUrl} newPage as="secondaryLink">
        {document.fileDescription}
      </Link>
    </GridRowColumn>
  ) : null

const ReturnSection = ({
  title,
  documents,
}: {
  title: string
  documents: UserDocument[]
}) =>
  documents.length ? (
    <>
      <GridRowColumn>
        <Text as="eyebrow">{title}</Text>
      </GridRowColumn>
      {documents.map((doc) => (
        <AnnualTaxFilingFormTaxDocument key={doc.id} document={doc} />
      ))}
    </>
  ) : null

const ReturnYear = ({ year }: { year: number }) => {
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)

  const businessTaxReturnsForYear = useReselector(
    selectSortedTaxReturnDocumentsForYear,
    year.toString()
  ).business

  const personalTaxReturnsForYear = useReselector(
    selectSortedTaxReturnDocumentsForYear,
    year.toString()
  ).personal

  const otherTaxReturnsForYear = useReselector(
    selectSortedTaxReturnDocumentsForYear,
    year.toString()
  ).other

  if (
    !businessTaxReturnsForYear.length &&
    !personalTaxReturnsForYear.length &&
    !otherTaxReturnsForYear.length
  ) {
    return (
      <GridRowColumn>
        <Text as="bodyLg" color="darkGray">
          {currentTaxYear === year.toString() ? (
            <>
              When you are finished filing taxes with us, a copy of your returns
              will show here.
              <br />
              <br />
              If you&apos;ve already completed your taxes, but you don&apos;t
              see your returns here, please upload them in the Tax Documents
              section of the Documents page.
            </>
          ) : (
            <>
              It looks like we don’t have your {year} tax returns. You can
              upload your return via the Tax Documents section of the Documents
              page.
            </>
          )}{' '}
          <Link to="/practice/documents?tab=tax" size="large">
            Go to Documents
          </Link>
        </Text>
      </GridRowColumn>
    )
  }

  return (
    <>
      <ReturnSection title="business" documents={businessTaxReturnsForYear} />
      <ReturnSection title="personal" documents={personalTaxReturnsForYear} />
      <ReturnSection title="other" documents={otherTaxReturnsForYear} />
    </>
  )
}

const PastReturnsPanel = () => {
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)

  //todo is current year conditional?

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column computer={9} tablet={16} mobile={16}>
          <Grid>
            <GridRowColumn>
              <Text as="bodyLg">
                Not seeing one of your returns? You can upload them in the Tax
                Documents section of the Documents page.{' '}
                <Link to="/practice/documents?tab=tax" size="large">
                  Go to Documents
                </Link>
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Divider />
            </GridRowColumn>
            {/*loop through all years from current to 2021*/}
            {range(Number(currentTaxYear), 2020, -1).map((year) => (
              <Fragment key={year}>
                <GridRowColumn>
                  <Text as="h3">{year} Tax Returns</Text>
                </GridRowColumn>
                <ReturnYear year={year} />
                <GridRowColumn>
                  <Divider />
                </GridRowColumn>
              </Fragment>
            ))}
          </Grid>
        </Grid.Column>
        <GridColumnSpacer mobile tablet />
        <Grid.Column computer={6} tablet={16} mobile={16}>
          <AnnualTaxFaq />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default PastReturnsPanel
