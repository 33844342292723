import { useCallback } from 'react'
import {
  EventScheduledEvent,
  PopupModal,
  useCalendlyEventListener,
} from 'react-calendly'

import { useReselector, useTimeoutRef } from '../../utils/sharedHooks'
import { fetchUserCallEvents } from '../../actions/userCallEventsActions'
import { getCurrentUser } from '../../selectors/user.selectors'
import { useAppDispatch } from '../../utils/typeHelpers'

const CalendlyPopupModal = ({
  open,
  onClose,
  url,
  onScheduled,
}: {
  open: boolean
  onClose: () => void
  onScheduled?: (eventUri?: string) => void
  url: string
}) => {
  const dispatch = useAppDispatch()
  const fetchUserEventsTimeout = useTimeoutRef()
  const user = useReselector(getCurrentUser)

  const refetchEvents = useCallback(() => {
    // `useCalendlyEventListener` may exist in multiple components so only trigger if this modal is open
    if (open) {
      fetchUserEventsTimeout.current = setTimeout(() => {
        dispatch(fetchUserCallEvents())
      }, 1000)
    }
  }, [dispatch, fetchUserEventsTimeout, open])

  const onEventScheduled = useCallback(
    async (e: EventScheduledEvent) => {
      await refetchEvents()
      onScheduled?.(e.data.payload.event.uri)
    },
    [onScheduled, refetchEvents]
  )

  useCalendlyEventListener({
    onEventScheduled,
  })

  const rootElement = document.getElementById('App')

  if (!rootElement) {
    return null
  }

  return (
    <PopupModal
      onModalClose={() => {
        // Optimally we would just depend on `useCalendlyEventListener` but cancel/reschedule events don't trigger the callback so also do a refetch on close
        // `useCalendlyEventListener` should still be used so the page will update in the background while the modal is still open
        refetchEvents()
        onClose()
      }}
      open={open}
      rootElement={rootElement}
      url={url}
      prefill={{
        name:
          user?.firstName &&
          user?.lastName &&
          `${user.firstName} ${user.lastName}`,
        email: user?.email,
      }}
    />
  )
}

export default CalendlyPopupModal
