export const ACCOUNT_TYPE_OPTIONS = [
  { text: 'Assets', value: 'Assets' },
  { text: 'Liabilities', value: 'Liabilities' },
  { text: 'Income', value: 'Income' },
  { text: 'Expenses', value: 'Expenses' },
  { text: 'Equity', value: 'Equity' },
  { text: 'Other', value: 'Other' },
]

export const FINANCIAL_STATEMENT_OPTIONS = [
  { text: 'Balance Sheet', value: 'balance_sheet' },
  { text: 'Income Statement', value: 'income_statement' },
]

export const GL_SORT_OPTIONS = [
  { text: 'A-Z (default)', value: 'A-Z' },
  { text: 'Amount (ascending)', value: 'Amount ↑' },
  { text: 'Amount (descending)', value: 'Amount ↓' },
]

export enum GL_SORTS {
  alphabetical = 'A-Z',
  amount_asc = 'Amount ↑',
  amount_desc = 'Amount ↓',
}
