import { createSelector } from 'reselect'
import { ReduxState } from '../../../utils/typeHelpers'
import { RecordSnapshotEntityName } from './recordSnapshot.slice'

const getRecordSnapshotsByKey = (state: ReduxState) =>
  state.admin.allRecordSnapshots

export const getFinancialProfileRecordSnapshotForUserAndYear = createSelector(
  [
    getRecordSnapshotsByKey,
    (
      _: unknown,
      args: {
        userId: number
        year: string
      }
    ) => args,
  ],
  (recordSnapshots, args) =>
    recordSnapshots[
      `${RecordSnapshotEntityName.financialProfile}-${args.userId}-${args.year}`
    ]
)
