import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Image } from 'semantic-ui-react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  GridRowColumn,
  Text,
  TileSelector,
} from '../../../../../../components/BaseComponents'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { LIFE_CHANGES_SCREENS, LifeChangesStepProps } from '.'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import { TaxListQuestionId } from '../../service'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
  selectTaxListQuestionResponsesByQuestionIds,
} from '../../taxChecklist.selectors'
import { NoneApply, useTiles } from '../../Shared/UseTiles'
import DocumentAddedAlert from '../../Shared/SpecialtyAlerts'
import { soldHomeQuestionIds } from './SoldHomePanel'
import { purchasedHomeQuestionIds } from './PurchasedHomePanel'
import {
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  deleteTaxQuestionnaireResponses,
} from '../../taxChecklistQuestion.actions'
import useProgressSteps from '../../Shared/ReviewStepsandProgresses/useProgressSteps'
import {
  CHANGES_IN_YEAR,
  SubStepIdentifiers,
} from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'

export const lifeChangesQuestionIds = [
  TaxListQuestionId.sold_home,
  TaxListQuestionId.home_purchase,
]

const LifeChangePanel = ({
  goBack,
  goToNextStep,
  previousScreen,
}: LifeChangesStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()
  const dispatch = useAppDispatch()
  const { updateProgressData, fetchKeys: progressFetchKeys } = useProgressSteps(
    { steps: CHANGES_IN_YEAR }
  )

  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    ...progressFetchKeys,
  ])

  const questionSoldHome = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.sold_home,
    taxYear
  )
  const soldHomeResponses = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.sold_home,
    Number(formId)
  )
  const questionPurchasedHome = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_purchase,
    taxYear
  )
  const purchasedHomeResponses = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_purchase,
    Number(formId)
  )

  const soldHomeFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    soldHomeQuestionIds,
    Number(formId)
  )
  const purchasedHomeFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    purchasedHomeQuestionIds,
    Number(formId)
  )

  const {
    tiles: selections,
    setTiles,
    noneApply,
    tileColumnStyle,
    tileClicked,
    tileHeight,
    tileWidth,
    tilesToResponses,
    anyResponseSelected,
  } = useTiles()

  useEffect(() => {
    setTiles([
      {
        icon: light('sign-hanging'),
        text: questionSoldHome?.question?.text ?? '',
        questionId: TaxListQuestionId.sold_home,
        response: soldHomeResponses?.[0],
      },
      {
        icon: light('key'),
        text: questionPurchasedHome?.question?.text ?? '',
        questionId: TaxListQuestionId.home_purchase,
        response: purchasedHomeResponses?.[0],
      },
    ])
  }, [
    soldHomeResponses,
    purchasedHomeResponses,
    setTiles,
    questionSoldHome,
    questionPurchasedHome,
  ])

  const saveAndContinue = useAsyncCallback(async () => {
    // if screen conditions change, make sure to also update in LifeChanges/index
    let nextScreen = LIFE_CHANGES_SCREENS.miscQuestions
    const didSellHome =
      selections.find((s) => s.questionId === TaxListQuestionId.sold_home)
        ?.response?.value === true
    const soldHomeFollowUpIds = soldHomeFollowUpQuestionResponses.map(
      (r) => r.id
    )
    const didPurchaseHome =
      selections.find((s) => s.questionId === TaxListQuestionId.home_purchase)
        ?.response?.value === true
    const purchasedHomeFollowUpIds = purchasedHomeFollowUpQuestionResponses.map(
      (r) => r.id
    )

    if (didSellHome) {
      nextScreen = LIFE_CHANGES_SCREENS.soldHome
    } else if (didPurchaseHome) {
      nextScreen = LIFE_CHANGES_SCREENS.purchasedHome
    }

    let deletionSucceeded = true
    const idsToDelete: number[] = []
    if (!didSellHome && soldHomeFollowUpIds.length) {
      idsToDelete.push(...soldHomeFollowUpIds)
    }
    if (!didPurchaseHome && purchasedHomeFollowUpIds.length) {
      idsToDelete.push(...purchasedHomeFollowUpIds)
    }
    if (idsToDelete.length > 0) {
      deletionSucceeded = Boolean(
        await dispatch(deleteTaxQuestionnaireResponses(idsToDelete))
      )
    }

    //process progresses for the step and substeps
    let progressUpdateSucceeded = false
    if (deletionSucceeded) {
      //there should always be a completed progress for the first tile page upon submission

      const completedSteps = [SubStepIdentifiers.bigLifeChanges]
      const incompleteSteps: SubStepIdentifiers[] = []

      if (didSellHome) {
        if (!soldHomeFollowUpIds.length) {
          incompleteSteps.push(SubStepIdentifiers.soldHome)
        }
      } else {
        completedSteps.push(SubStepIdentifiers.soldHome)
      }

      if (didPurchaseHome) {
        if (!purchasedHomeFollowUpIds.length) {
          incompleteSteps.push(SubStepIdentifiers.purchasedHome)
        }
      } else {
        completedSteps.push(SubStepIdentifiers.purchasedHome)
      }

      progressUpdateSucceeded = await updateProgressData({
        completedSteps,
        incompleteSteps,
      })
    }
    if (deletionSucceeded && progressUpdateSucceeded) {
      await goToNextStep(tilesToResponses(), nextScreen ?? null)
    }
  })

  return (
    <Grid>
      <GridRowColumn
        columnStyle={{ display: 'flex', justifyContent: 'center' }}
      >
        <Image src="https://heard-images.s3.amazonaws.com/assets/calendar-day-dec-31.svg" />
      </GridRowColumn>
      <GridRowColumn>
        <Text as="display2" textAlign="center">
          Tell us about your year
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([8, 14], true)}>
        <Text as="bodyLg" textAlign="center">
          Let us know if any of these life events happened in {taxYear}, so we
          can personalize this questionnaire for you.
        </Text>
      </GridRowColumn>
      <GridRowColumn columnStyle={tileColumnStyle}>
        {selections.map((s) => (
          <TileSelector
            key={s.questionId}
            icon={s.icon}
            active={Boolean(s.response?.value)}
            text={s.text}
            width={tileWidth}
            height={tileHeight}
            onClick={() => tileClicked(s.questionId)}
          />
        ))}
      </GridRowColumn>
      <GridRowColumn short columnStyle={tileColumnStyle}>
        <NoneApply
          width={noneApply.width}
          selected={noneApply.selected}
          setSelection={noneApply.clearTiles}
        />
      </GridRowColumn>
      {anyResponseSelected && (
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <DocumentAddedAlert>
            When you see the “add file” icon, we&apos;ve added a form
            requirement to your Upload Documents checklist. This list is at the
            end of the questionnaire, which is when you&apos;ll upload your
            documents.
          </DocumentAddedAlert>
        </GridRowColumn>
      )}
      <Grid.Row />
      <FormFlowFooter
        continueDisabled={
          (!noneApply.selected && !anyResponseSelected) ||
          isUpdating ||
          saveAndContinue.loading
        }
        onBack={() => goBack(previousScreen ?? null)}
        onForward={saveAndContinue.callback}
        loading={isUpdating || saveAndContinue.loading}
      />
    </Grid>
  )
}

export default LifeChangePanel
