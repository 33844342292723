import { Table } from 'semantic-ui-react'
import CurrencyFormatLabel from '../../shared/CurrencyFormatLabel'
import { InsightsTransactionsAPIResponse } from '../shared/utils'
import { formatCurrencyFromCents } from '../../../utils/currencyHelpers'
import {
  DATE_FORMATS_LUXON,
  formatISOFromUTC,
} from '../../../utils/dateHelpers'
import {
  useIsDeviceWidth,
  DeviceWidth,
} from '../../../utils/deviceWidthHelpers'

const ExpensesBreakdownTransactions = ({
  currentTransactions,
  transactionCategoryName,
}: {
  currentTransactions: InsightsTransactionsAPIResponse[]
  transactionCategoryName: string
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  return (
    <>
      {currentTransactions.map((transaction) => (
        <Table.Row key={transaction.id}>
          <Table.Cell textAlign="left">
            {formatISOFromUTC(
              transaction.date,
              DATE_FORMATS_LUXON.DISPLAY_SHORT
            )}
          </Table.Cell>
          <Table.Cell textAlign="left">
            {transaction.description.substring(0, 40)}
          </Table.Cell>
          {!isMobile && (
            <Table.Cell textAlign="right">{transactionCategoryName}</Table.Cell>
          )}
          <Table.Cell textAlign="right">
            <CurrencyFormatLabel
              value={formatCurrencyFromCents(transaction.amount_in_cents)}
            />
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  )
}

export default ExpensesBreakdownTransactions
