//BL
import { USER_DOCUMENT_TYPES_OPTIONS } from '../../constants/businessConstants'

interface TabCategoryKeysModel {
  BANK_STATEMENTS: string
  INCORPORATION_DOCUMENTS: string
  OTHER: string
  RECEIPTS: string
  TAX: string
  BOOKKEEPING: string
}

export const TabCategoryKeys: TabCategoryKeysModel = {
  BANK_STATEMENTS: USER_DOCUMENT_TYPES_OPTIONS[1].value,
  INCORPORATION_DOCUMENTS: USER_DOCUMENT_TYPES_OPTIONS[3].value,
  OTHER: USER_DOCUMENT_TYPES_OPTIONS[2].value,
  RECEIPTS: USER_DOCUMENT_TYPES_OPTIONS[4].value,
  TAX: USER_DOCUMENT_TYPES_OPTIONS[0].value,
  BOOKKEEPING: USER_DOCUMENT_TYPES_OPTIONS[5].value,
}

export const TABLE_MONTH_FORMAT = 'MMMM'

export const SERVICE_PLACEHOLDER_DOCUMENT_DATE_FORMAT = 'MM/DD/YY'

export enum DescriptionType {
  MERCHANT = 'merchant',
  INCOME = 'income',
}

export enum BookkeepingInternalNames {
  LARGE_ASSET_RECEIPTS = 'assets_greater_2500_receipts',
  CONTRACTOR_PAYMENTS_REPORT = 'contractor_payments_report',
  PAYMENT_PROCESSOR_REPORTS = 'payment_processor_reports',
}
