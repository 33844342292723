import { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import moment from 'moment'

import { useAppDispatch } from '../../../../utils/typeHelpers'

// BL
import { ManualTransactionModel } from '../../../../reducers/admin/allTransactions.slice'
import { UPLOAD_COPY } from '../../copyConstants'
import { ScreenData, UploadScreenProps } from './index'
import {
  createSingleManualTransaction,
  validateSingleManualTransaction,
} from '../../transactions.slice'

// UI
import LockedBooksMessage from '../../LockedBooksMessage'
import { getUserBooksLockedForUserYear } from '../../../../reducers/auth/user.selectors'
import { parseErrorFromCatch } from '../../../../utils/errorHelpers'
import {
  Button,
  TextArea,
  DatePicker,
  Text,
  Input,
  Modal,
  GridRowColumn,
} from '../../../../components/BaseComponents'
import {
  useIsDeviceWidth,
  DeviceWidth,
} from '../../../../utils/deviceWidthHelpers'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { useReselector } from '../../../../utils/sharedHooks'

const UploadSingleTransactionButton = ({
  loading,
  onSaveClicked,
  buttonDisabled,
}: {
  loading: boolean
  onSaveClicked: () => Promise<void>
  buttonDisabled: boolean
}) => (
  <Button
    loading={loading}
    onClick={onSaveClicked}
    disabled={buttonDisabled || loading}
    fullWidth
  >
    Add Transaction
  </Button>
)

const UploadMultipleTransactionsButton = ({
  goToBulkUploadScreen,
  loading,
}: {
  goToBulkUploadScreen: () => void
  loading: boolean
}) => (
  <div style={{ display: 'block', width: '100%' }}>
    <Text textAlign="center">{UPLOAD_COPY.LANDING_SCREEN_BULK_SUBTITLE}</Text>
    <br />
    <Button disabled={loading} onClick={goToBulkUploadScreen} fullWidth>
      Add Multiple Transactions
    </Button>
  </div>
)

const UploadLandingScreen = ({
  onUploadComplete,
  goToScreenWithData,
  finishFlow,
}: UploadScreenProps) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [amountInDollars, setAmountInDollars] = useState('0.00')
  const [date, setDate] = useState('')
  const [description, setDescription] = useState('')
  const [serverError, setServerError] = useState<string | null>(null)
  const booksLockedForUserYear = useReselector(getUserBooksLockedForUserYear)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  // Navigates to a specific screen
  const goToBulkUploadScreen = () => goToScreenWithData({ index: 1 })
  const goToDuplicatesScreen = (data: ScreenData) =>
    goToScreenWithData({ index: 3, data })

  // Enable or disable single transaction button
  useEffect(() => {
    const descriptionTrimmed = description.trim()

    if (
      Number(amountInDollars) === 0 ||
      !amountInDollars ||
      !moment(date, DATE_FORMATS.INPUT).isValid() ||
      !descriptionTrimmed
    ) {
      setButtonDisabled(true)
      return
    }

    setButtonDisabled(false)
  }, [amountInDollars, description, date])

  // Invoked when "Add Single Transaction" button is clicked
  const onSaveClicked = async () => {
    setLoading(true)
    const data: ManualTransactionModel = {
      amountInDollars,
      date,
      description: description.trim(),
    }

    try {
      const { errors, warnings } = await validateSingleManualTransaction(data)

      if (errors.length > 0) {
        onUploadComplete?.({ success: false })
        finishFlow()
      } else if (
        warnings.spreadsheetDuplicates.length > 0 ||
        warnings.databaseDuplicates.length > 0
      ) {
        const screenData = {
          validTransactions: [],
          ...warnings,
        }
        goToDuplicatesScreen(screenData)
      } else {
        const result = await createSingleManualTransaction(data)(dispatch)
        data.id = result?.id
        if (result) {
          onUploadComplete?.({
            success: true,
            data: [data],
          })
        } else {
          onUploadComplete?.({ success: false })
        }

        finishFlow()
      }
      setServerError(null)
    } catch (err) {
      setServerError(parseErrorFromCatch(err))
    }
    setLoading(false)
  }

  return (
    <>
      <Modal.Header>{UPLOAD_COPY.GENERIC_SCREEN_TITLE}</Modal.Header>
      {!isMobile && (
        <Modal.Description>
          <Text>
            If you have business transactions from <b>this year </b> that aren’t
            reflected in your connected bank accounts, please add them below.
          </Text>
        </Modal.Description>
      )}
      <Modal.Content>
        <Grid doubling stackable>
          {booksLockedForUserYear && (
            <GridRowColumn>
              <LockedBooksMessage year={booksLockedForUserYear} />
            </GridRowColumn>
          )}
          <GridRowColumn>
            <DatePicker
              fullWidth={isMobile}
              disabled={loading}
              label="Date"
              value={date}
              maxDate={new Date()}
              onChange={(value) => setDate(value)}
              name="transactionDate"
              placeholder={DATE_FORMATS.INPUT}
            />
          </GridRowColumn>
          <GridRowColumn>
            <Input
              fullWidth={isMobile}
              onChange={(value) => setAmountInDollars(value)}
              value={amountInDollars}
              placeholder="Amount"
              label="Amount"
              description="Use negative dollar amounts for expenses (-$10.00) and positive dollar amounts for income ($10.00)"
              componentType="number"
            />
          </GridRowColumn>
          <GridRowColumn>
            <TextArea
              disabled={loading}
              label="Description"
              placeholder="e.g. USPS Withdrawal"
              value={description}
              onChange={(value) => setDescription(value)}
            />
          </GridRowColumn>
          {serverError && (
            <GridRowColumn>
              <Text className="server-error">{serverError}</Text>
            </GridRowColumn>
          )}
          <GridRowColumn only="computer tablet">
            <UploadSingleTransactionButton
              loading={loading}
              onSaveClicked={onSaveClicked}
              buttonDisabled={buttonDisabled}
            />
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        {isMobile ? (
          <Grid stackable doubling style={{ display: 'block', width: '100%' }}>
            <GridRowColumn>
              <UploadSingleTransactionButton
                loading={loading}
                onSaveClicked={onSaveClicked}
                buttonDisabled={buttonDisabled}
              />
            </GridRowColumn>
            <GridRowColumn>
              <Button variant="secondary" fullWidth onClick={finishFlow}>
                Cancel
              </Button>
            </GridRowColumn>
          </Grid>
        ) : (
          <UploadMultipleTransactionsButton
            goToBulkUploadScreen={goToBulkUploadScreen}
            loading={loading}
          />
        )}
      </Modal.Actions>
    </>
  )
}

export default UploadLandingScreen
