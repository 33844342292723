import { Breadcrumb as SemanticBreadcrumb } from 'semantic-ui-react'
import { insertBetween } from '../../utils/arrayHelpers'
import Link from './Link'
import Text from './Text'

export type BreadcrumbSection = {
  label: string
  to?: string
}

interface Props {
  crumbs: BreadcrumbSection[]
}

const Breadcrumb = ({ crumbs }: Props) => (
  <SemanticBreadcrumb size="small">
    {insertBetween(
      crumbs.map(({ label, to }) => (
        <SemanticBreadcrumb.Section key={`Breadcrumb_${label}_${to}`}>
          {to ? (
            <Link to={to} size="xSmall">
              {label}
            </Link>
          ) : (
            <Text as="bodySm">{label}</Text>
          )}
        </SemanticBreadcrumb.Section>
      )),
      <SemanticBreadcrumb.Divider icon="right chevron" />
    )}
  </SemanticBreadcrumb>
)

export default Breadcrumb
