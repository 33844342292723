/*
  Summaries a user's allocation rules
*/
import CurrencyFormatLabel from '../shared/CurrencyFormatLabel'
import { formatCurrencyFromCents } from '../../utils/currencyHelpers'
import { AllocationRule } from '../../reducers/finances/allocationRulesReducer'
import { Link, Table, Text } from '../BaseComponents'
import { useReselector } from '../../utils/sharedHooks'
import { getAllocationRules } from '../../selectors/allocationGuideSelectors'

const AllocationRulesCard = () => {
  const allocationRules = useReselector(getAllocationRules)
  const renderAmount = (rule: AllocationRule) => {
    if (rule.ruleType === 'percentage') {
      return `${rule.percentage} %`
    } else {
      return (
        <CurrencyFormatLabel
          value={formatCurrencyFromCents(rule.amountInCents)}
        />
      )
    }
  }
  return (
    <>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Text as="h3">Your Current Allocation Guide:</Text>
        <Link
          to="/allocation-guides/edit"
          trackMessage="clicked edit allocation guide"
        >
          Edit
        </Link>
      </div>

      <Table>
        <Table.Body>
          {allocationRules &&
            Object.values(allocationRules).map((rule) => (
              <Table.Row key={rule.id}>
                <Table.Cell>{rule.ruleName}</Table.Cell>
                <Table.Cell>{renderAmount(rule)}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </>
  )
}

export default AllocationRulesCard
