import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button, Form, Grid, Header, Modal } from 'semantic-ui-react'

import {
  createTransactionRule,
  fetchAllTransactionRulesIfNeeded,
  FETCH_ALL_TRANSACTION_RULES_KEY,
} from '../../../actions/admin/transactionRuleActions'
import { TRANSACTION_TYPE_OPTIONS } from '../../../constants/transactionConstants'
import {
  RULE_TYPE_OPTIONS,
  RULE_USE_OPTIONS,
} from '../../../constants/transactionRuleConstants'
import './RuleCreation.scss'
import SimilarRuleItem from './SimilarRuleItem'
import {
  getAllTransactionCategories,
  getTransactionCategorySelector,
} from '../../Reports/reports.selectors'
import {
  TransactionRuleCategoryType,
  TransactionRuleType,
  TransactionRuleUse,
} from '../../../reducers/admin/transactionRulesReducer'
import { getIsFetching } from '../../../reducers/fetch'
import { useReselector } from '../../../utils/sharedHooks'
import { selectSimilarRules } from '../../Transactions/transactions.selectors'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface RuleCreationModalProps {
  modalOpen: boolean
  setModalOpen: Dispatch<SetStateAction<boolean>>
  userId: number
  description?: string | null
  categoryType?: TransactionRuleCategoryType | null
  categoryId?: number | null
}

const RuleCreationModal = ({
  modalOpen,
  setModalOpen,
  userId,
  description,
  categoryType,
  categoryId,
}: RuleCreationModalProps) => {
  const [ruleUse, setRuleUse] = useState<TransactionRuleUse>()
  const [ruleText, setRuleText] = useState(description)
  const [ruleType, setRuleType] = useState<TransactionRuleType>()
  const [transactionCategoryType, setTransactionCategoryType] =
    useState(categoryType)
  const [transactionCategoryId, setTransactionCategoryId] = useState(categoryId)
  const [amountInCents, setAmountInCents] = useState<string>()
  const [checkRule, setCheckRule] = useState(false)
  const [createdRule, setCreatedRule] = useState(false)
  const [errorCreatingRule, setErrorCreatingRule] = useState(false)
  const similarRules = useReselector(selectSimilarRules, ruleUse, ruleText)
  const isFetching = useReselector(
    getIsFetching,
    FETCH_ALL_TRANSACTION_RULES_KEY
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchAllTransactionRulesIfNeeded())
  }, [dispatch])

  const transactionCategories = useReselector(getAllTransactionCategories)

  const selectedCategory = useReselector(
    getTransactionCategorySelector,
    transactionCategoryId
  )

  const submitRule = async () => {
    if (!ruleType || !ruleUse || !ruleText) {
      return
    }
    const result = await createTransactionRule({
      userId,
      ruleUse,
      ruleText,
      ruleType,
      amountInCents,
      transactionCategoryType,
      transactionCategoryId,
    })(dispatch)
    if (result) {
      setCreatedRule(true)
    } else {
      setErrorCreatingRule(true)
    }
  }

  const renderRuleCreationForm = () => {
    const mapOptions = () => {
      return Object.keys(transactionCategories).map((key) => {
        const category = transactionCategories[key]
        return {
          value: category.id,
          text: `${category.id}: ${category.name}`,
        }
      })
    }

    const CATEGORY_OPTIONS = mapOptions()

    const reviewRuleButtonDisabled = (): boolean => {
      if (ruleUse === undefined) return true
      if (ruleText === undefined || ruleText === null || ruleText.length === 0)
        return true
      if (ruleType === undefined) return true

      if (ruleUse === TransactionRuleUse.CATEGORIZATION) {
        if (transactionCategoryType === undefined) return true
        if (
          transactionCategoryType === 'business' &&
          transactionCategoryId === undefined
        )
          return true
      }

      return false
    }

    return (
      <>
        <Modal.Header as="h4">Create Rule</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field required>
              <Form.Dropdown
                required
                fluid
                selection
                label="Rule Use"
                placeholder="Select Rule Use"
                name="ruleUse"
                value={ruleUse}
                options={RULE_USE_OPTIONS}
                selectOnBlur={false}
                onChange={(name, target) => {
                  setRuleUse(target.value as TransactionRuleUse)
                }}
              />
            </Form.Field>
            {ruleUse === TransactionRuleUse.CATEGORIZATION && (
              <Grid.Column>
                <Form.Field required>
                  <Form.Input
                    required
                    fluid
                    label="Rule Text"
                    placeholder="Enter Exact Text e.g. 'PsychologyToday'"
                    name="ruleText"
                    value={ruleText}
                    onChange={(name, target) => setRuleText(target.value)}
                  />
                </Form.Field>
                <Form.Field required>
                  <Form.Dropdown
                    required
                    fluid
                    selection
                    label="Rule Type — how we want to match"
                    placeholder="Select Rule Type"
                    name="ruleType"
                    value={ruleType}
                    options={RULE_TYPE_OPTIONS}
                    selectOnBlur={false}
                    onChange={(name, target) => {
                      setRuleType(target.value as TransactionRuleType)
                    }}
                  />
                </Form.Field>
                <Form.Field required>
                  <Form.Dropdown
                    required
                    fluid
                    selection
                    label="Transaction Category Type"
                    placeholder="Select Transaction Category Type"
                    name="transactionCategoryType"
                    value={transactionCategoryType || undefined}
                    options={TRANSACTION_TYPE_OPTIONS}
                    selectOnBlur={false}
                    onChange={(name, target) => {
                      setTransactionCategoryType(
                        target.value as TransactionRuleCategoryType
                      )
                    }}
                  />
                </Form.Field>
                <Form.Field required>
                  <Form.Dropdown
                    required={transactionCategoryType === 'business'}
                    fluid
                    selection
                    label="Transaction Category Id"
                    placeholder="Select Transaction Category Id"
                    name="transactionCategoryId"
                    value={transactionCategoryId || undefined}
                    options={CATEGORY_OPTIONS}
                    selectOnBlur={false}
                    onChange={(name, target) =>
                      setTransactionCategoryId(Number(target.value))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    fluid
                    label="Amount in cents (optional)"
                    placeholder="Enter amount in cents"
                    name="amountInCents"
                    value={amountInCents}
                    onChange={(name, target) => {
                      // string here to allow "-" value. Gets coerced to number on insert to db
                      setAmountInCents(target.value)
                    }}
                  />
                  <i>Remember to use negative sign for expenses </i>
                </Form.Field>
              </Grid.Column>
            )}
            {ruleUse === TransactionRuleUse.CLARIFICATION && (
              <Grid.Column>
                <Form.Field required>
                  <Form.Input
                    required
                    fluid
                    label="Rule Text"
                    placeholder="Enter Exact Text e.g. 'PsychologyToday'"
                    name="ruleText"
                    value={ruleText}
                    onChange={(name, target) => setRuleText(target.value)}
                  />
                </Form.Field>
                <Form.Field required>
                  <Form.Dropdown
                    required
                    fluid
                    selection
                    label="Rule Type — how we want to match"
                    placeholder="Select Rule Type"
                    name="ruleType"
                    value={ruleType}
                    options={RULE_TYPE_OPTIONS}
                    selectOnBlur={false}
                    onChange={(name, target) => {
                      setRuleType(target.value as TransactionRuleType)
                    }}
                  />
                </Form.Field>
              </Grid.Column>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button basic primary onClick={() => setModalOpen(!modalOpen)}>
            Cancel
          </Button>
          <Button
            primary
            disabled={reviewRuleButtonDisabled()}
            onClick={() => setCheckRule(true)}
          >
            Review Rule
          </Button>
        </Modal.Actions>
      </>
    )
  }

  const renderRuleReviewContent = () => (
    <>
      <Modal.Header as="h4">Reviewing Rule</Modal.Header>
      <Modal.Content>
        <p>
          Currently checking for similar rules that might conflict with this
          one. Remember, rules are symbol sensitive, and there should only be
          one rule per match word.
        </p>
        <div className="background">
          <Header as="h6">Your New Rule:</Header>
          {ruleUse === TransactionRuleUse.CATEGORIZATION && (
            <p>
              Categorizing transactions that match <b>{ruleText}</b> with rule
              type <b>{ruleType}</b>
              <br />
              as <b>{selectedCategory?.name || 'Awaiting Categorization'}</b>,
              applicable to <b>{userId ? `User ${userId}` : 'All Users'}</b>
            </p>
          )}
          {ruleUse === TransactionRuleUse.CLARIFICATION && (
            <p>
              Mark transactions that match <b>{ruleText}</b> as &#39;Needs
              Clarification&#39;
            </p>
          )}
        </div>
        <br />
        <div className="background">
          {similarRules.length === 0 && (
            <Header as="h6">Rule looks good.</Header>
          )}
          {similarRules.length > 0 && (
            <div>
              <Header as="h6">Similar rules:</Header>
              <ul>
                {similarRules.map((rule) => (
                  <SimilarRuleItem key={rule.id} rule={rule} />
                ))}
              </ul>
              <p>
                Are you sure that the new rule you are creating is <b>unique</b>{' '}
                and <b>necessary</b>?
              </p>
            </div>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button basic primary onClick={() => setModalOpen(!modalOpen)}>
          Cancel
        </Button>
        <Button primary onClick={submitRule}>
          Create & Apply Rule
        </Button>
      </Modal.Actions>
    </>
  )

  const renderSuccessfulMessage = () => (
    <>
      <Modal.Header as="h4">Rule successfully created!</Modal.Header>

      <Modal.Actions
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button basic primary onClick={() => setModalOpen(!modalOpen)}>
          Close
        </Button>
      </Modal.Actions>
    </>
  )

  const renderErrorMessage = () => (
    <>
      <Modal.Header as="h4">
        There was an error creating transaction rule.
      </Modal.Header>
      <Modal.Actions
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button basic primary onClick={() => setModalOpen(!modalOpen)}>
          Close
        </Button>
      </Modal.Actions>
    </>
  )

  return isFetching ? null : (
    <Modal
      className="ruleCreationModal"
      size="small"
      dimmer="inverted"
      open={modalOpen}
      onClose={() => setModalOpen(!modalOpen)}
    >
      {!checkRule && renderRuleCreationForm()}
      {checkRule &&
        !createdRule &&
        !errorCreatingRule &&
        renderRuleReviewContent()}
      {createdRule && !errorCreatingRule && renderSuccessfulMessage()}
      {errorCreatingRule && !createdRule && renderErrorMessage()}
    </Modal>
  )
}

export default RuleCreationModal
