import { createSelector } from 'reselect'
import { difference, filter, times } from 'lodash'
import moment from 'moment'

import { ReduxState } from '../utils/typeHelpers'

export const getAllocationRules = (state: ReduxState) => state.allocationRules

export const getAllocationRuleArray = createSelector(
  [getAllocationRules],
  (allocationRules) => Object.values(allocationRules)
)

export const getAllocationGuides = (state: ReduxState) =>
  state.allocationGuides.byId

export const getAllocationGuideById = createSelector(
  [
    getAllocationGuides,
    (_: unknown, allocationGuideId: number | string | undefined) =>
      allocationGuideId,
  ],
  (allocationGuides, allocationGuideId) =>
    allocationGuideId ? allocationGuides[Number(allocationGuideId)] : undefined
)

export const selectMissingAllocationGuides = createSelector(
  getAllocationGuides,
  (guides) => {
    const neededMonths = times(12).map((value) =>
      moment().subtract(value, 'month').format('YYYY-MM')
    )
    const allocationGuideMonths = Object.values(guides).map(
      (guide) => guide.date
    )
    return difference(neededMonths, allocationGuideMonths)
  }
)

/*
  Admin Allocation Guide Selectors
*/

export const adminGetUserAllocationGuides = createSelector(
  [getAllocationGuides, (_: unknown, userId: number) => userId],
  (allocationGuides, userId) =>
    filter(allocationGuides, (guide) => guide.userId === userId)
)

/*

  Admin allocation rule selectors
*/

export const adminGetUserAllocationRules = createSelector(
  [getAllocationRules, (_: unknown, userId: number) => userId],
  (allocationRules, userId) =>
    filter(allocationRules, (rule) => rule.userId === userId)
)
