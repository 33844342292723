import { createSelector } from 'reselect'
import moment from 'moment'
import orderBy from 'lodash/orderBy'

import { ReduxState } from '../utils/typeHelpers'

export const getCalendlyLinks = (state: ReduxState) => state.calendlyLinks

// Admin
export const getAllUserCallEvents = (state: ReduxState) =>
  state.admin.allUserCallEvents

export const getAllUserCallEventsByUserId = createSelector(
  getAllUserCallEvents,
  (_: unknown, userId: number) => userId,
  (byUserId, userId) => (byUserId[userId] ? byUserId[userId] : undefined)
)

export const getAllUserCallEventById = createSelector(
  getAllUserCallEventsByUserId,
  (_: unknown, __: unknown, callEventId: number) => callEventId,
  (allUserCallEvents, callEventId) =>
    allUserCallEvents?.[callEventId]
      ? allUserCallEvents[callEventId]
      : undefined
)

export const getAdminCalendlyLinkByUserEventId = createSelector(
  getAllUserCallEventById,
  getCalendlyLinks,
  (callEvent, calendlyLinks) =>
    callEvent?.adminCalendlyLinkId &&
    calendlyLinks[callEvent.adminCalendlyLinkId]
      ? calendlyLinks[callEvent.adminCalendlyLinkId]
      : undefined
)

export const getCurrentYearAllUserCallEventIds = createSelector(
  getAllUserCallEventsByUserId,
  (callEvents) =>
    callEvents
      ? Object.values(callEvents)
          .filter((callEvent) => {
            return callEvent.year === moment().year().toString()
          })
          .map((callEvent) => callEvent.id)
      : []
)

export const getPastAllUserCallEventsIds = createSelector(
  getAllUserCallEventsByUserId,
  (callEvents) =>
    callEvents
      ? Object.values(callEvents)
          .filter((callEvent) => callEvent.year !== moment().year().toString())
          .map((callEvent) => callEvent.id)
      : []
)

export const getAllUserCallEventsSorted = createSelector(
  getAllUserCallEventsByUserId,
  (callEvents) => callEvents && orderBy(callEvents, ['startTime'], ['desc'])
)

export const getCalendlyLinkIds = createSelector(
  getCalendlyLinks,
  (calendlyLinks) => Object.values(calendlyLinks).map((link) => link.id)
)

export const getCalendlyLinkById = createSelector(
  getCalendlyLinks,
  (_: unknown, id: number | undefined) => id,
  (calendlyLinks, id) =>
    id && calendlyLinks[id] ? calendlyLinks[id] : undefined
)
