import { useEffect, useMemo } from 'react'
import {
  ChartOfAccount,
  fetchChartOfAccountsIfNeeded,
} from '../../features/ChartOfAccounts/chartOfAccount.slice'
import {
  fetchTransactionCategoriesIfNeeded,
  TransactionCategory,
  TransactionAccountType,
} from '../../features/Reports/reports.slice'
import { getCurrentChartOfAccount } from '../../features/ChartOfAccounts/chartOfAccount.selectors'
import {
  getAllTransactionCategories,
  mapTransactionCategories,
  selectCategoriesByType,
  selectNonExpenseAndIncomeCategories,
} from '../../features/Reports/reports.selectors'
import { Dropdown } from '../BaseComponents'
import { DropdownProps } from '../BaseComponents/Dropdown'
import { useReselector } from '../../utils/sharedHooks'
import { TransactionCategoryIdentifier } from '../../reducers/admin/allTransactions.slice'
import { useAppDispatch } from '../../utils/typeHelpers'

export interface TransactionCategoryDropdownProps
  extends Omit<DropdownProps<number, false, boolean | undefined>, 'onChange'> {
  optionStyle: 'normal' | 'combineIdAndValue'
  onChange: (value: number | undefined) => void
  isTaxCompliant?: boolean
  disabled?: boolean
  accountType?: TransactionAccountType
  limitToCategoryNames?: string[]
  limitToCategoryIdentifiers?: TransactionCategoryIdentifier[]
  transactionAmount?: number
  open?: boolean
}

/*
  A Dropdown containing all non-archived transaction categories contained within the
  current Chart Of Account (non-archived, most recent COA)
*/
const TransactionCategoryDropdown = ({
  disabled,
  onChange,
  isTaxCompliant = false,
  clearable = true,
  optionStyle,
  value,
  accountType,
  limitToCategoryNames = [],
  limitToCategoryIdentifiers = [],
  transactionAmount,
  ...rest
}: TransactionCategoryDropdownProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    function performAsync() {
      dispatch(fetchTransactionCategoriesIfNeeded())
      dispatch(fetchChartOfAccountsIfNeeded())
    }

    performAsync()
  }, [dispatch])

  const currentCOA: ChartOfAccount | undefined = useReselector(
    getCurrentChartOfAccount
  )
  const allTransactionCategories = useReselector(getAllTransactionCategories)
  const expenseCategories = useReselector(selectCategoriesByType, 'Expenses')
  const incomeCategories = useReselector(selectCategoriesByType, 'Income')
  const otherCategories = useReselector(selectNonExpenseAndIncomeCategories)

  const categoriesToDisplay = useMemo(() => {
    if (transactionAmount) {
      if (transactionAmount < 0) {
        return { ...expenseCategories, ...otherCategories }
      }
      if (transactionAmount > 0) {
        return { ...incomeCategories, ...otherCategories }
      }
    }
    return allTransactionCategories
  }, [
    transactionAmount,
    expenseCategories,
    incomeCategories,
    otherCategories,
    allTransactionCategories,
  ])

  const coaCategories = useMemo(() => {
    const categories: { [key: string]: TransactionCategory } = {}
    if (currentCOA) {
      currentCOA.chartOfAccountCategories.forEach((coaCategory) => {
        // Lookup transaction category
        const lookupResult =
          categoriesToDisplay[coaCategory.transactionCategoryId]

        // filter by account type if applicable, otherwise return all
        if (
          lookupResult &&
          ((!accountType && limitToCategoryNames.length === 0) || // no filtering
            (accountType && accountType === lookupResult.accountType) || // filter by account type
            (limitToCategoryNames.length > 0 &&
              limitToCategoryNames.includes(lookupResult.name))) // filter by list of category names
        ) {
          categories[lookupResult.id] = lookupResult
        }

        if (
          lookupResult &&
          ((!accountType && limitToCategoryIdentifiers.length === 0) || // no filtering
            (accountType && accountType === lookupResult.accountType) || // filter by category type
            (limitToCategoryIdentifiers.length > 0 &&
              limitToCategoryIdentifiers.includes(lookupResult.identifier))) // filter by list of category identifiers
        ) {
          categories[lookupResult.id] = lookupResult
        }
      })
    }
    return categories
  }, [
    currentCOA,
    categoriesToDisplay,
    accountType,
    limitToCategoryNames,
    limitToCategoryIdentifiers,
  ])

  const options =
    optionStyle === 'combineIdAndValue'
      ? Object.values(coaCategories).map((category) => ({
          value: category.id,
          text: `${category.id}: ${category.name}`,
          disabled: Boolean(category.archivedAt),
          label: { className: isTaxCompliant ? 'highlight' : '' },
        }))
      : mapTransactionCategories(coaCategories)

  /* 
    If the current value does not exist within current COA's options, 
    locate within allTransactionCategories, then add it to options as disabled.

    Note: If the user clears/changes this value, it cannot be undone. 
    The dropdown will re-populate without the category because it belongs to an archived COA.
  */
  const valueAsNumber = Number(value)
  const result = options.find((o) => o.value === valueAsNumber)

  if (!result) {
    const category = categoriesToDisplay[valueAsNumber]

    if (category) {
      options.push({
        value: category.id,
        text:
          optionStyle === 'combineIdAndValue'
            ? `${category.id}: ${category.name}`
            : category.name,
        disabled: true,
        label: { className: isTaxCompliant ? 'highlight' : '' },
      })
    }
  }

  return (
    <Dropdown
      highlightColor="orange"
      placeholder="Select Category"
      value={value ?? undefined}
      options={options}
      onChange={onChange}
      disabled={disabled}
      clearable={clearable}
      search
      {...rest}
    />
  )
}

export default TransactionCategoryDropdown
