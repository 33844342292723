import CurrencyFormatLabel from '../../shared/CurrencyFormatLabel'
import {
  INSIGHTS_TYPE,
  InsightsRowAPIResponse,
  formatPercentage,
} from '../shared/utils'
import { formatCurrencyFromCents } from '../../../utils/currencyHelpers'
import { Link } from 'react-router-dom'
import { Label, Table } from '../../BaseComponents'
import {
  useIsDeviceWidth,
  DeviceWidth,
} from '../../../utils/deviceWidthHelpers'

const ExpenseRowTags = ({
  isTopExpense,
  insightsType,
}: {
  isTopExpense: boolean
  insightsType: INSIGHTS_TYPE
}) => {
  return (
    isTopExpense &&
    insightsType === INSIGHTS_TYPE.EXPENSES && (
      <Label color="neutral" style={{ marginBottom: 5 }}>
        Top Expense
      </Label>
    )
  )
}

const InsightsRow = ({
  insightsRow,
  isTopExpense,
  onTransactionsClick,
  insightsType,
}: {
  insightsRow: InsightsRowAPIResponse
  isTopExpense: boolean
  onTransactionsClick: () => void
  insightsType: INSIGHTS_TYPE
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const formatTransactionLabel = (count: number): string => {
    if (isMobile) {
      return ''
    }
    if (count === 1) {
      return 'transaction'
    }
    return 'transactions'
  }

  return (
    <Table.Row>
      <Table.Cell>
        {insightsRow.transaction_category_name}
        {isMobile && (
          <ExpenseRowTags
            isTopExpense={isTopExpense}
            insightsType={insightsType}
          />
        )}
      </Table.Cell>
      {!isMobile && (
        <Table.Cell textAlign="right">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'right',
              alignItems: 'right',
              gap: 8,
            }}
          >
            <ExpenseRowTags
              isTopExpense={isTopExpense}
              insightsType={insightsType}
            />
          </div>
        </Table.Cell>
      )}
      {!isMobile && (
        <Table.Cell textAlign="center">
          {formatPercentage(insightsRow.percent_of_total_cents_decimal)}
        </Table.Cell>
      )}
      <Table.Cell textAlign="center">
        <CurrencyFormatLabel
          value={formatCurrencyFromCents(
            insightsRow?.transactions_sum_in_cents
          )}
        />
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Link onClick={onTransactionsClick} to="#">
          {insightsRow.transactions_count}{' '}
          {formatTransactionLabel(insightsRow.transactions_count)}
        </Link>
      </Table.Cell>
    </Table.Row>
  )
}

export default InsightsRow
