import { Grid } from 'semantic-ui-react'
import {
  Button,
  Card,
  GridRowColumn,
  Modal,
  Text,
  TextArea,
} from '../../../../components/BaseComponents'
import { Transaction } from '../../../../reducers/admin/allTransactions.slice'
import CurrencyFormatLabel from '../../../../components/shared/CurrencyFormatLabel'
import { centsToDollars } from '../../../../utils/currencyHelpers'
import { DateTime } from 'luxon'
import { DATE_FORMATS_LUXON } from '../../../../utils/dateHelpers'
import { useState } from 'react'

const SampleTransactionNotesModal = ({
  isOpen,
  onClose,
  transaction,
  transactionNote,
  onNoteChange,
  onSave,
}: {
  isOpen: boolean
  onClose: () => void
  transaction: Transaction
  transactionNote: string | undefined
  onNoteChange: (value: string) => void
  onSave: () => Promise<void>
}) => {
  const [isSaving, setIsSaving] = useState(false)

  const handleSave = async () => {
    setIsSaving(true)
    try {
      await onSave()
    } catch (error) {
      console.error('Failed to save note:', error)
    } finally {
      setIsSaving(false)
    }
  }
  return (
    <Modal size="tiny" open={isOpen} onClose={onClose} closeIcon>
      <Modal.Header>Add a note</Modal.Header>
      <Modal.Content>
        <GridRowColumn>
          <Card backgroundColor="stone">
            <Grid>
              <GridRowColumn>
                <Text as="eyebrow" color="darkGray">
                  Transaction
                </Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text>
                  Date:{' '}
                  {DateTime.fromISO(transaction.date).toFormat(
                    DATE_FORMATS_LUXON.DISPLAY_SHORT
                  )}
                </Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text>Description: {transaction.description}</Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text>
                  Amount:{' '}
                  <CurrencyFormatLabel
                    value={centsToDollars(transaction.amountInCents)}
                  />
                </Text>
              </GridRowColumn>
            </Grid>
          </Card>
        </GridRowColumn>
        <GridRowColumn>
          <TextArea
            label="Note"
            rows={2}
            value={transactionNote || ''}
            onChange={(value) => onNoteChange(value)}
          />
        </GridRowColumn>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="actionLink" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={isSaving} loading={isSaving}>
          Save Note
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default SampleTransactionNotesModal
