import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Container,
  Header,
  Loader,
  Dimmer,
  Table,
  Button,
  Message,
  Label,
} from 'semantic-ui-react'
import { Dispatch, ReduxState } from '../../../utils/typeHelpers'
import UserDocumentCategoryModal from './UserDocumentCategoryModal'
import {
  FETCH_DOCUMENT_CATEGORIES_KEY,
  fetchUserDocumentCategoriesIfNeeded,
  UserDocumentCategory,
} from './userDocumentCategories.slice'
import { getUserDocumentCategoriesById } from './userDocumentCategories.selectors'
import { DateTime } from 'luxon'
import { DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import { Checkbox } from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { selectIsFetchingForKeys } from '../../../reducers/fetch'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps

interface UserDocumentCategoriesRowProps {
  category: UserDocumentCategory
}

const UserDocumentCategoriesRow: React.FC<UserDocumentCategoriesRowProps> = ({
  category,
}) => {
  const [updateModal, setUpdateModal] = useState(false)
  return (
    <Table.Row>
      <Table.Cell>{category.id}</Table.Cell>
      <Table.Cell>{category.internalName}</Table.Cell>
      <Table.Cell>{category.type}</Table.Cell>
      <Table.Cell>{category.title}</Table.Cell>
      <Table.Cell>{category.description}</Table.Cell>
      <Table.Cell>
        <Checkbox checked={Boolean(category.isAnnual)} disabled />
      </Table.Cell>
      <Table.Cell>
        <Checkbox checked={Boolean(category.isInternal)} disabled />
      </Table.Cell>
      <Table.Cell>
        {category.archivedAt
          ? DateTime.fromISO(category.archivedAt).toFormat(
              DATE_FORMATS_LUXON.DISPLAY_SHORT
            )
          : ''}
      </Table.Cell>
      <Table.Cell>
        {category.requiredFor?.map((category) => (
          <Label key={category} className="tags" horizontal>
            {category}
          </Label>
        ))}
      </Table.Cell>
      <Table.Cell>
        <Button className="link" onClick={() => setUpdateModal(true)}>
          Update
        </Button>
      </Table.Cell>
      {updateModal && (
        <UserDocumentCategoryModal
          userDocumentCategory={category}
          close={() => setUpdateModal(false)}
          open={updateModal}
        />
      )}
    </Table.Row>
  )
}
const UserDocumentCategoriesPanel = ({
  allUserDocumentCategories,
  fetchUserDocumentCategoriesIfNeeded,
}: Props) => {
  const isFetching = useReselector(selectIsFetchingForKeys, [
    FETCH_DOCUMENT_CATEGORIES_KEY,
  ])
  const [createModalOpen, setCreateModalOpen] = useState(false)
  useEffect(() => {
    fetchUserDocumentCategoriesIfNeeded()
  }, [fetchUserDocumentCategoriesIfNeeded])

  const [showArchived, setShowArchived] = useState(false)
  const [filteredCategories, setFilteredCategories] = useState(
    Object.values(allUserDocumentCategories)
  )

  useEffect(() => {
    if (showArchived) {
      setFilteredCategories(Object.values(allUserDocumentCategories))
    } else {
      setFilteredCategories(
        Object.values(allUserDocumentCategories).filter(
          (category) => !category.archivedAt
        )
      )
    }
  }, [showArchived, allUserDocumentCategories])

  return (
    <Container id="userDocumentCategoriesPanel">
      <Header as="h3">User Document Categories</Header>
      <br />
      {isFetching && (
        <Dimmer active inverted>
          <Loader inverted>Fetching all document Categories</Loader>
        </Dimmer>
      )}
      {!isFetching && (
        <div>
          <div className="flexbox">
            <div>
              <Button secondary onClick={() => setCreateModalOpen(true)}>
                Create New User Document Category
              </Button>
              <UserDocumentCategoryModal
                close={() => setCreateModalOpen(false)}
                open={createModalOpen}
              />
            </div>
          </div>
          <Message className="noBorder blue">
            User Document Categories are used to tag and categorize documents.
          </Message>
          <Checkbox
            label="Show Archived"
            checked={showArchived}
            onChange={setShowArchived}
          />
          <Table striped compact="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Internal Name</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Annual</Table.HeaderCell>
                <Table.HeaderCell>Internal</Table.HeaderCell>
                <Table.HeaderCell>Archived At</Table.HeaderCell>
                <Table.HeaderCell>Required For</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {filteredCategories.length === 0 && (
              <Table.Body>
                <Table.Row colSpan="8">
                  <Table.Cell>No Document Categories created yet</Table.Cell>
                </Table.Row>
              </Table.Body>
            )}
            {filteredCategories.length > 0 && (
              <Table.Body>
                {filteredCategories.map((category) => (
                  <UserDocumentCategoriesRow
                    category={category}
                    key={category.id}
                  />
                ))}
              </Table.Body>
            )}
          </Table>
        </div>
      )}
    </Container>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  allUserDocumentCategories: getUserDocumentCategoriesById(state),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchUserDocumentCategoriesIfNeeded: () =>
    dispatch(fetchUserDocumentCategoriesIfNeeded()),
})

export default connect<
  StateProps,
  DispatchProps,
  Record<string, unknown>,
  ReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(UserDocumentCategoriesPanel)
