import React from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb } from 'semantic-ui-react'

interface Props {
  firstName?: string
  lastName?: string
  userId?: number
}

const BalanceSheetBreadcrumbs: React.FC<Props> = ({
  firstName,
  lastName,
  userId,
}) => {
  return (
    <Breadcrumb size="small">
      <Breadcrumb.Section>
        {' '}
        <Link to="/">Dashboard</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right chevron" />
      <Breadcrumb.Section>
        <Link to="/admin/finances/list-users">Users</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right chevron" />
      <Breadcrumb.Section>
        <Link to={`/admin/finances/records/${userId}`}>
          {firstName} {lastName}
        </Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right chevron" />
      <Breadcrumb.Section active>Balance Sheet</Breadcrumb.Section>
    </Breadcrumb>
  )
}
export default BalanceSheetBreadcrumbs
