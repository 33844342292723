import { CSSProperties } from 'react'
import { Popup as SemanticPopup, StrictPopupProps } from 'semantic-ui-react'
import styled, { createGlobalStyle } from 'styled-components'
import { omit } from 'lodash'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Colors } from '../../styles/theme'
import Icon from './Icon'
import { createClassName } from '../../utils/styleHelpers'

// Because popups are global components (not following the typical DOM hierarchy), in order to
// consistently style all popups we need to use global styling. This `GlobalPopupStyle` is applied
// to the top of `<App>`.
export const GlobalPopupStyle = createGlobalStyle({
  'div.ui.popup.popup-content': {
    '&.no-border': {
      backgroundColor: Colors.blush,
      border: 'none',
      borderRadius: 4,
      dropShadow: '0px 0px 20px 0px #0000001A',
      padding: '8px 16px',

      '&:before': {
        backgroundColor: `${Colors.blush} !important`,
        boxShadow: 'none !important',
      },
    },

    '&.bordered': {
      backgroundColor: Colors.white,
      padding: '.7em .89em',

      '&:before': {
        backgroundColor: `${Colors.blush} !important`,
        zIndex: -1,
      },
    },
  },
})

const DefaultTrigger = () => (
  <Icon
    icon={regular('question-circle')}
    style={{ marginLeft: 10 }}
    color="mediumGray"
    size="sm"
  />
)

export interface PopupProps extends Omit<StrictPopupProps, 'children'> {
  inline?: boolean
  popupClassname?: string
  popupStyle?: CSSProperties
  bordered?: boolean
}

const Popup = styled(
  ({
    className,
    popupClassname,
    style,
    trigger = <DefaultTrigger />,
    popupStyle,
    bordered,
    ...rest
  }: PopupProps) => (
    <SemanticPopup
      className={createClassName([
        'popup-content',
        popupClassname,
        bordered ? 'bordered' : 'no-border',
      ])}
      trigger={
        <span className={className} style={style}>
          {trigger}
        </span>
      }
      style={popupStyle}
      {...omit(rest, 'inline')}
    />
  )
)(({ inline = false }) =>
  inline
    ? {
        '&&&': { display: 'inline' },
      }
    : {}
)

export default Popup
