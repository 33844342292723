import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { DateTime, Interval } from 'luxon'
import styled from 'styled-components'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Card,
  GridRowColumn,
  Icon,
  Popup,
  Text,
} from '../../../../components/BaseComponents'
import { selectImportantDatesForYear } from '../annualTaxFilings.selector'
import { useReselector, useScrollRef } from '../../../../utils/sharedHooks'
import { DATE_FORMATS_LUXON } from '../../../../utils/dateHelpers'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import { useAnalyticsTrack } from '../../../Amplitude'
import { Colors, FontWeight } from '../../../../styles/theme'
import { selectQtesForImportantDates } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { fetchAllQuarterlyTaxEstimateDetailsIfNeeded } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const QTE_BACKGROUND_SELECTED =
  'linear-gradient(90deg, #E2EAFE 66.06%, #FBF3EA 91.33%)'
const QTE_BACKGROUND_DEFAULT =
  'linear-gradient(90deg, #F3F6FF 72.12%, #FBF3EA 100%)'
const IMPORTANT_DATE_BACKGROUND_SELECTED =
  'linear-gradient(90deg, #D8FEE2 71.41%, #FBE2C9 96.52%)'
const IMPORTANT_DATE_BACKGROUND_DEFAULT =
  'linear-gradient(90deg, #F1FEF5 71.41%, #FBF3EA 96.52%)'

const getBackground = (isQte: boolean | undefined, isSelected: boolean) => {
  if (isQte) {
    return isSelected ? QTE_BACKGROUND_SELECTED : QTE_BACKGROUND_DEFAULT
  }
  return isSelected
    ? IMPORTANT_DATE_BACKGROUND_SELECTED
    : IMPORTANT_DATE_BACKGROUND_DEFAULT
}

const DateItem = styled(
  ({
    date,
    qteIndex,
    onClick,
    className,
    isQte,
  }: {
    date: {
      name: string
      shortName: string
      startDate: DateTime | null
      endDate: DateTime | null
    }
    heightOffset: number
    isSelected: boolean
    isQte?: boolean
    qteIndex?: number
    onClick: () => void
    startImportantDate: DateTime
    className?: string
  }) => {
    // The popup needs an anchor to base itself off of.  This is needed because the trigger is absolutely positioned
    const triggerRef = useRef<HTMLDivElement>(null)

    const startIcon = useMemo(() => {
      if (qteIndex === undefined) {
        return null
      }

      return (
        <Icon
          icon={regular('circle-quarter-stroke')}
          style={{ marginRight: 10, transform: `rotate(${90 * qteIndex}deg)` }}
          color="blue"
        />
      )
    }, [qteIndex])

    if (!date.startDate || !date.endDate) {
      return null
    }

    const onHoverText = isQte ? date.name : date.shortName

    return (
      <Popup
        context={triggerRef}
        trigger={
          <div
            onClick={onClick}
            role="presentation"
            className={className}
            ref={triggerRef}
          >
            {startIcon}
            <Text className="dateName">{date.shortName}</Text>
            <Icon
              icon={regular('flag-checkered')}
              color="orange"
              className="flag-icon"
            />
          </div>
        }
        content={onHoverText}
      />
    )
  }
)(({ isSelected, date, startImportantDate, heightOffset, isQte }) => {
  if (!date.endDate || !date.startDate) {
    return {}
  }

  const leftOffset =
    ((date.startDate.diff(startImportantDate, 'days').toObject().days || 0) /
      365) *
    100
  const width =
    ((date.endDate.diff(date.startDate, 'days').toObject().days || 0) / 365) *
    100

  return {
    background: getBackground(isQte, isSelected),
    boxShadow: isSelected ? '0px 4px 12px 0px rgba(0, 0, 0, 0.08)' : undefined,
    top: heightOffset * 50 + 10,
    position: 'absolute',
    height: 36,
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    cursor: 'pointer',
    width: `${width}%`,
    left: `${leftOffset}%`,

    '.dateName': {
      fontWeight: isSelected ? `${FontWeight.SEMIBOLD} !important` : undefined,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      paddingRight: 20,
    },

    '.flag-icon': {
      position: 'absolute',
      right: 10,
    },

    '&:hover': {
      background: isQte
        ? QTE_BACKGROUND_SELECTED
        : IMPORTANT_DATE_BACKGROUND_SELECTED,
      boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.08)',

      '.dateName': {
        fontWeight: `${FontWeight.SEMIBOLD} !important`,
      },
    },
  }
})

const DateCard = ({
  year,
  date,
  selected,
}: {
  year: string
  date?: ReturnType<typeof selectImportantDatesForYear>[number]
  selected?: boolean
}) => {
  const { scrollToRef, scrollRef } = useScrollRef()
  const qtes = useReselector(selectQtesForImportantDates, year)

  useEffect(() => {
    if (selected) {
      scrollToRef()
    }
  }, [scrollToRef, selected])

  const dateString = useMemo(
    () =>
      date
        ? `Due ${date.endDate?.toFormat(DATE_FORMATS_LUXON.DISPLAY_SHORT)}`
        : `${qtes
            .map((qte) =>
              DateTime.fromISO(qte.irsPaymentDueAt).toFormat(
                DATE_FORMATS_LUXON.DISPLAY_SHORT
              )
            )
            .join('  |  ')}`,
    [date, qtes]
  )

  return (
    <GridRowColumn {...makeGridConfig([8, 14], true)}>
      <span ref={scrollRef} />
      <Card
        type={selected ? 'section' : 'subsection'}
        backgroundColor={selected ? 'stone40' : 'white'}
      >
        <Grid>
          <GridRowColumn>
            <Text as="eyebrow" color="darkGray">
              {dateString}
            </Text>
            <Text as="h3" style={{ marginTop: 5 }}>
              {date ? date.name : 'Quarterly Tax Estimates'}
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            {date ? (
              date.description
            ) : (
              <Text as="bodyLg">
                Each quarter you’ll update your tax information. Then, Heard
                will use that tax information to calculate your quarterly tax
                estimate.
              </Text>
            )}
          </GridRowColumn>
        </Grid>
      </Card>
    </GridRowColumn>
  )
}

const ImportantDatesLegend = styled.div({
  display: 'flex',
  height: 32,
  marginTop: 10,
  marginBottom: 16, // spec is 40px (surrounding card padding + 16)
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 40,
})

const ImportantDates = ({ year }: { year: string }) => {
  const dispatch = useAppDispatch()
  const [selectedIndex, setSelectedIndex] = useState<number>()
  const dates = useReselector(selectImportantDatesForYear, year)
  const qtes = useReselector(selectQtesForImportantDates, year)
  const yearNum = Number(year)

  const startImportantDate = DateTime.fromObject({
    year: yearNum,
    month: 10,
    day: 1,
  })
  const endImportantDate = DateTime.fromObject({
    year: yearNum + 1,
    month: 9,
    day: 30,
  })

  const track = useAnalyticsTrack()

  // Splits apart date range into months for use in labels and sections
  const months = useMemo(
    () =>
      Interval.fromDateTimes(startImportantDate, endImportantDate)
        .splitBy({ months: 1 })
        .map((interval) => interval.start?.toFormat('LLL ‘yy')),
    [endImportantDate, startImportantDate]
  )

  useEffect(() => {
    dispatch(fetchAllQuarterlyTaxEstimateDetailsIfNeeded())
  }, [dispatch])

  // The qte dates sit just below all the other important dates.  The offset/on click implementation is dependent on dates.length
  const qteItemIndex = dates.length

  const onDateItemClick = useCallback(
    (index: number, item: string) => {
      track('annual tax important dates item click', {
        item,
      })

      setSelectedIndex(index)
    },
    [track]
  )

  return (
    <>
      <GridRowColumn only="computer tablet">
        <Card type="subsection" backgroundColor="stone40">
          <Grid>
            <Grid.Row />
            {/*month labels*/}
            <Grid.Row columns="equal">
              {months.map((month) => (
                <Grid.Column key={month}>
                  <Text as="eyebrow" color="darkGray" textAlign="center">
                    {month}
                  </Text>
                </Grid.Column>
              ))}
            </Grid.Row>

            {/*month section containers*/}
            <Grid.Row columns="equal" className="short">
              {months.map((month, index) => (
                <Grid.Column
                  key={month}
                  style={{
                    height: (dates.length + 1) * 50 + 30,
                    backgroundColor: Colors.white,
                    ...(index && {
                      borderLeft: `1px solid ${Colors.lightGray}`,
                    }),
                  }}
                />
              ))}

              {/*important dates that float in timeline*/}
              {dates.map((date, index) => (
                <DateItem
                  key={date.name}
                  date={date}
                  heightOffset={index}
                  isSelected={index === selectedIndex}
                  onClick={() => onDateItemClick(index, date.name)}
                  startImportantDate={startImportantDate}
                />
              ))}

              {/*qte dates that float in timeline*/}
              {qtes.map((qte, index) => (
                <DateItem
                  key={qte.taxQuarter}
                  date={{
                    name: `Q${qte.taxQuarter} Estimates`,
                    shortName: `Q${qte.taxQuarter}`,
                    startDate: DateTime.fromISO(qte.newUserCutOffAt),
                    endDate: DateTime.fromISO(qte.irsPaymentDueAt),
                  }}
                  // these all sit on same line
                  heightOffset={qteItemIndex}
                  isSelected={selectedIndex === qteItemIndex}
                  isQte
                  qteIndex={index}
                  onClick={() =>
                    onDateItemClick(
                      qteItemIndex,
                      `Q${qte.taxQuarter} Estimates`
                    )
                  }
                  startImportantDate={startImportantDate}
                />
              ))}
            </Grid.Row>
          </Grid>
          <ImportantDatesLegend>
            <Text as="eyebrow" color="darkGray">
              <Icon
                icon={regular('circle-quarter-stroke')}
                style={{ marginRight: 10 }}
                color="blue"
              />
              Quarterly Tax Estimates
            </Text>
            <Text as="eyebrow" color="darkGray">
              <Icon
                icon={regular('flag-checkered')}
                style={{ marginRight: 10 }}
                color="orange"
              />
              Due Date
            </Text>
          </ImportantDatesLegend>
        </Card>
      </GridRowColumn>
      <GridRowColumn>
        <Grid>
          {/*important date information cards*/}
          {dates.map((date, index) => (
            <DateCard
              year={year}
              date={date}
              key={date.name}
              selected={index === selectedIndex}
            />
          ))}
          {/*QTE card*/}
          <DateCard selected={selectedIndex === qteItemIndex} year={year} />
        </Grid>
      </GridRowColumn>
    </>
  )
}

export default ImportantDates
