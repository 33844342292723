import { UnknownAction } from '@reduxjs/toolkit'

import appReducer from './appReducer'
import { ReduxState } from '../utils/typeHelpers'
import { userLogout } from './rootReducer.actions'

const rootReducer = (
  state: Partial<ReduxState> | undefined,
  action: UnknownAction
) => {
  if (action.type === userLogout.type) {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}
export default rootReducer
