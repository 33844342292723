import { useState } from 'react'
import { Grid } from 'semantic-ui-react'
import Moment from 'react-moment'

import CurrencyFormatLabel from '../../components/shared/CurrencyFormatLabel'
import { centsToDollars } from '../../utils/currencyHelpers'
import { updateUserTransaction } from './transactions.slice'
import { TransactionOrSplit } from '../../reducers/admin/allTransactions.slice'
import {
  Button,
  TextArea,
  Text,
  Alert,
  Modal,
} from '../../components/BaseComponents'
import { useReselector } from '../../utils/sharedHooks'
import { getCurrentUser } from '../../selectors/user.selectors'
import { updateUser } from '../../actions/userActions'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { useAppDispatch } from '../../utils/typeHelpers'

interface TransactionNoteModalProps {
  open: boolean
  close: () => void
  transaction: TransactionOrSplit
}
const TransactionNoteModal = ({
  open,
  close,
  transaction,
}: TransactionNoteModalProps) => {
  const dispatch = useAppDispatch()
  const [transactionNote, setTransactionNote] = useState(
    transaction.notes || ''
  )
  const user = useReselector(getCurrentUser)
  const [updating, setUpdating] = useState(false)
  const [error, setError] = useState(false)

  const saveNote = async () => {
    setUpdating(true)
    setError(false)

    const prevLocked = user?.booksLockedForUserYear

    // Hacky way to get around adding notes if books are locked
    if (prevLocked) {
      await updateUser(user.id, { booksLockedForUserYear: null })(dispatch)
    }

    const updated = await updateUserTransaction(transaction.id, {
      notes: transactionNote,
      notesLastUpdatedAt: new Date().toISOString(),
    })(dispatch)

    if (prevLocked) {
      await updateUser(user.id, {
        booksLockedForUserYear: prevLocked,
      })(dispatch)
    }

    if (updated) {
      await setUpdating(false)
      close()
    } else {
      await setError(true)
    }
  }

  return (
    <Modal
      size="tiny"
      className="transactionNoteModalProps"
      open={open}
      onClose={close}
      closeIcon
    >
      <Modal.Header>Leave us a note</Modal.Header>
      <Modal.Content>
        <Grid>
          {error && (
            <Grid.Row>
              <Grid.Column>
                <Alert type="error">
                  There was an error saving this note! Please try again.
                </Alert>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column>
              <Text as="bodyMd">
                <i>
                  Let us know any further details on this transaction and what
                  it was for. We&#39;ll help you categorize it accordingly!
                </i>
              </Text>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Text as="bodyMd">
                <b>Date:</b>{' '}
                <Moment format={DATE_FORMATS.DISPLAY_SHORT} utc>
                  {transaction?.date}
                </Moment>
              </Text>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Text as="bodyMd">
                <b>Description:</b> {transaction.description}
              </Text>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Text as="bodyMd">
                <b>Amount:</b>{' '}
                <CurrencyFormatLabel
                  value={centsToDollars(transaction.amountInCents)}
                />
              </Text>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <TextArea
                label="Note"
                rows={2}
                placeholder="If unsure, leave a note about what this transaction is for and we’ll help you categorize!"
                value={transactionNote}
                onChange={(value) => setTransactionNote(value)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={saveNote}
          disabled={updating}
          loading={updating}
        >
          Save Note and Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default TransactionNoteModal
