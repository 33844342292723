import { Grid } from 'semantic-ui-react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import { IconButton, Text } from '../BaseComponents'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  ModalAnimation,
  ModalLottieAnimation,
} from '../../features/Onboarding/config'
import './Carousel.scss'
import 'swiper/css'
import 'swiper/css/pagination'
import { ReactNode } from 'react'

export type Slide = {
  animationTitle: string
  animationUrl?: string
  lottieAnimation?: object
  lottieLoop?: boolean
  title: string
  description: ReactNode
}

interface Props {
  slides: Slide[]
}

export default ({ slides }: Props) => (
  <Grid>
    <Grid.Row>
      <Grid.Column verticalAlign="middle" textAlign="center">
        <Swiper
          modules={[Navigation, Pagination]}
          navigation={{ prevEl: '#prev-slide', nextEl: '#next-slide' }}
          pagination={{
            clickable: true,
            el: '#carousel-pagination-custom',
            renderBullet: (_index, className) =>
              `<span class="${className}" style="margin-bottom: 2px"></span>`,
          }}
        >
          {slides.map((slide) => {
            return (
              <SwiperSlide key={slide.title}>
                {slide.animationUrl && (
                  <ModalAnimation
                    url={slide.animationUrl}
                    title={slide.animationTitle}
                  />
                )}
                {slide.lottieAnimation && (
                  <ModalLottieAnimation
                    animationData={slide.lottieAnimation}
                    loop={Boolean(slide.lottieLoop)}
                  />
                )}
                <Text as="display">{slide.title}</Text>
                <Text as="bodyLg">{slide.description}</Text>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column verticalAlign="middle" textAlign="center">
        <IconButton
          id={'prev-slide'}
          icon={regular('angle-left')}
          size={'sm'}
        />
        <div
          id="carousel-pagination-custom"
          style={{ display: 'inline', margin: '0 20px 5px 20px' }}
        />
        <IconButton
          id={'next-slide'}
          icon={regular('angle-right')}
          size={'sm'}
        />
      </Grid.Column>
    </Grid.Row>
  </Grid>
)
