import { useState, ReactElement } from 'react'
import { Divider, Grid, Icon } from 'semantic-ui-react'
import { chunk } from 'lodash'

import {
  selectOtherLocations,
  selectPayrollCompany,
} from '../payroll.selectors'
import LocationsPanel from './LocationsPanel'
import WorkAddressModal from './WorkAddressModal'
import {
  Link,
  Text,
  Button,
  Alert,
  GridRowColumn,
} from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'

// This splits columns into multiple rows
const LocationRows = ({ subComps }: { subComps: ReactElement[] }) => {
  const rows = chunk(subComps, 4)

  return (
    <>
      {rows.map((row, index) => (
        <Grid.Row key={index} style={{ marginTop: 15 }}>
          {row}
        </Grid.Row>
      ))}
    </>
  )
}

// This is for both signup and settings
const CompanyLocationsTab = () => {
  const [showModal, setShowModal] = useState(false)
  const otherLocations = useReselector(selectOtherLocations)
  const company = useReselector(selectPayrollCompany)

  if (!company) {
    return null
  }

  return (
    <Grid>
      <Grid.Row />
      <GridRowColumn>
        <Alert>
          This holds information about where your company and your employees are
          situated. This is tricky — especially with WFH — as we need the
          correct information in order to correctly pay your payroll taxes.
          Please keep this up to date.
        </Alert>
      </GridRowColumn>
      <Grid.Row />
      <LocationsPanel title="Main Company Locations" />
      <GridRowColumn>
        <Text>
          Need to edit this? This may have payroll tax consequences.{' '}
          <Link to="/conversations">Contact the Heard Team</Link>
        </Text>
      </GridRowColumn>
      <Divider />
      <GridRowColumn>
        <Text as="bodyLg">
          <b>
            <Icon name="map marker alternate" />
            Other Company Locations
          </b>
        </Text>
      </GridRowColumn>
      <GridRowColumn short>
        <Text>
          This includes employees’ remote work addresses. This is needed as we
          need to know where your employees work, in order to correctly file
          your payroll taxes.
        </Text>
      </GridRowColumn>

      <LocationRows
        subComps={[
          ...otherLocations.map(
            ({ uuid, street_1, street_2, city, state }, index) => (
              <Grid.Column width={4} key={uuid}>
                <Grid className="bordered-grid location-grid">
                  <GridRowColumn>
                    <Text>
                      <b>Location #{index + 1}</b>
                    </Text>
                  </GridRowColumn>
                  <GridRowColumn short>
                    <Text>
                      {street_1} {street_2 || ''}
                    </Text>
                  </GridRowColumn>
                  <GridRowColumn short>
                    <Text>
                      {city}, {state}
                    </Text>
                  </GridRowColumn>
                </Grid>
              </Grid.Column>
            )
          ),
          <Grid.Column width={4} key="addNew">
            <Grid
              className="bordered-grid location-grid"
              style={{ borderStyle: 'dashed' }}
            >
              <GridRowColumn spacer={3} width={10} style={{ paddingTop: 40 }}>
                <Button onClick={() => setShowModal(true)} variant="link">
                  <Icon name="add circle" />
                  Add a location
                </Button>
              </GridRowColumn>
            </Grid>
          </Grid.Column>,
        ]}
      />
      {showModal && <WorkAddressModal closeModal={() => setShowModal(false)} />}
    </Grid>
  )
}

export default CompanyLocationsTab
