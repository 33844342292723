import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { difference, keyBy, omitBy, uniq } from 'lodash'
import { NormalizedSchema } from 'normalizr'
import { TransactionEdit } from './allTransactionEdits.slice'
import { UserDocument } from '../../features/UserDocuments/userDocuments.slice'
import { JournalEntry } from '../../features/JournalEntries/types'
import { TransactionRuleCategoryType } from './transactionRulesReducer'

export interface ManualTransactionModel {
  amountInDollars: string
  description: string
  date: string
  id?: number
  transactionCategoryId?: number | null
  receipts?: UserDocument[]
}

export type SplitTransaction = Omit<Transaction, 'accountTransactions'>

export enum TransactionCategoryIdentifier {
  business_fees_licenses_permits = 'business_fees_licenses_permits',
  computer_hardware = 'computer_hardware',
  insurance_health = 'insurance_health',
  insurance_life = 'insurance_life',
  parking_tolls = 'parking_tolls',
  client_discounts_refunds = 'client_discounts_refunds',
  meals_entertainment = 'meals_entertainment',
  dues_subscriptions = 'dues_subscriptions',
  payroll_salaries_wages = 'payroll_salaries_wages',
  testing_reference_materials = 'testing_reference_materials',
  individual_therapy_income_modern_health = 'individual_therapy_income_modern_health',
  individual_therapy_income_reflect = 'individual_therapy_income_reflect',
  individual_therapy_income_simple_practice = 'individual_therapy_income_simple_practice',
  individual_therapy_income_ivy_pay = 'individual_therapy_income_ivy_pay',
  individual_therapy_income_lyra_health = 'individual_therapy_income_lyra_health',
  individual_therapy_income_ap_intego = 'individual_therapy_income_ap_intego',
  maintenance_repair = 'maintenance_repair',
  commissions_fees = 'commissions_fees',
  individual_therapy_income_advekit = 'individual_therapy_income_advekit',
  individual_therapy_income_square = 'individual_therapy_income_square',
  individual_therapy_income_bcbsm = 'individual_therapy_income_bcbsm',
  individual_therapy_income_betterhelp = 'individual_therapy_income_betterhelp',
  individual_therapy_income_beacon_health = 'individual_therapy_income_beacon_health',
  individual_therapy_income_value_options = 'individual_therapy_income_value_options',
  insurance_pet = 'insurance_pet',
  individual_therapy_income_noridian = 'individual_therapy_income_noridian',
  individual_therapy_income_bluechoice = 'individual_therapy_income_bluechoice',
  individual_therapy_income_anthem_blue_cross = 'individual_therapy_income_anthem_blue_cross',
  individual_therapy_income_wellmark = 'individual_therapy_income_wellmark',
  individual_therapy_income_theranest = 'individual_therapy_income_theranest',
  insurance_workers_compensation = 'insurance_workers_compensation',
  individual_therapy_income_aetna = 'individual_therapy_income_aetna',
  individual_therapy_income_medical_mutual = 'individual_therapy_income_medical_mutual',
  individual_therapy_income_ohiohealthy = 'individual_therapy_income_ohiohealthy',
  small_business_grant_ca = 'small_business_grant_ca',
  insurance_disability = 'insurance_disability',
  other_income = 'other_income',
  guaranteed_payments = 'guaranteed_payments',
  insurance_general = 'insurance_general',
  legal_fees = 'legal_fees',
  payroll_contractors = 'payroll_contractors',
  retail_income = 'retail_income',
  postage_freight = 'postage_freight',
  uniforms_laundry = 'uniforms_laundry',
  mde_services_fps = 'mde_services_fps',
  payroll_suta_expense = 'payroll_suta_expense',
  payroll_workers_compensation = 'payroll_workers_compensation',
  travel_expenses = 'travel_expenses',
  credit_card_payments = 'credit_card_payments',
  psychotherapy_fees = 'psychotherapy_fees',
  speaking_income = 'speaking_income',
  uncategorized_income = 'uncategorized_income',
  professional_development = 'professional_development',
  taxes_franchise_taxes = 'taxes_franchise_taxes',
  teaching_income = 'teaching_income',
  hsa_contribution = 'hsa_contribution',
  payroll_expenses_fees = 'payroll_expenses_fees',
  uncategorized = 'uncategorized',
  communication_expenses = 'communication_expenses',
  consulting_income = 'consulting_income',
  debt_repayment = 'debt_repayment',
  equipment = 'equipment',
  furniture = 'furniture',
  owners_distribution = 'owners_distribution',
  taxes_citylocal_taxes = 'taxes_citylocal_taxes',
  charitable_donation = 'charitable_donation',
  retirement_contribution = 'retirement_contribution',
  insurance_dental = 'insurance_dental',
  accumulated_depreciation = 'accumulated_depreciation',
  loans_tofrom_shareholder = 'loans_tofrom_shareholder',
  accounts_payable = 'accounts_payable',
  payroll_payable = 'payroll_payable',
  retirement_payable = 'retirement_payable',
  business_loan = 'business_loan',
  retained_earnings = 'retained_earnings',
  gifts = 'gifts',
  customers_cash_accounts = 'customers_cash_accounts',
  customers_credit_card_accounts = 'customers_credit_card_accounts',
  advertising_and_marketing = 'advertising_and_marketing',
  continuing_education = 'continuing_education',
  insurance_professional_liability = 'insurance_professional_liability',
  rent_and_lease = 'rent_and_lease',
  other_expenses = 'other_expenses',
  professional_fees = 'professional_fees',
  internet = 'internet',
  utilities = 'utilities',
  accounting_fees = 'accounting_fees',
  therapy_income = 'therapy_income',
  bank_service_charges = 'bank_service_charges',
  credit_card_merchant_fees = 'credit_card_merchant_fees',
  insurance_auto = 'insurance_auto',
  interest_expense = 'interest_expense',
  money_transfer = 'money_transfer',
  payroll_employee_benefits = 'payroll_employee_benefits',
  payroll_payroll_taxes = 'payroll_payroll_taxes',
  payroll_reimbursable_expenses = 'payroll_reimbursable_expenses',
  software_services = 'software_services',
  tax_expense = 'tax_expense',
  taxes_and_licenses = 'taxes_and_licenses',
  fraudulent_charges = 'fraudulent_charges',
  student_loan_repayment = 'student_loan_repayment',
  contractor_labor = 'contractor_labor',
  office_expenses = 'office_expenses',
  payroll_expenses_unemployment_insurance = 'payroll_expenses_unemployment_insurance',
  supervision_income = 'supervision_income',
  clinical_supervision_fees = 'clinical_supervision_fees',
  insurance_property = 'insurance_property',
  amortization_expense = 'amortization_expense',
  auto_related_expenses = 'auto_related_expenses',
  reversal = 'reversal',
  supplies = 'supplies',
  owners_investments = 'owners_investments',
  taxes_federal_taxes = 'taxes_federal_taxes',
  small_business_grant = 'small_business_grant',
  taxes_state_taxes = 'taxes_state_taxes',
  payroll_expenses_401k = 'payroll_expenses_401k',
  depreciation_expense = 'depreciation_expense',
  refunds = 'refunds',
  individual_therapy_income_venmo = 'individual_therapy_income_venmo',
  individual_therapy_income_eap = 'individual_therapy_income_eap',
  individual_therapy_income_coa = 'individual_therapy_income_coa',
  individual_therapy_income_united_healthcare = 'individual_therapy_income_united_healthcare',
  individual_therapy_income_cigna = 'individual_therapy_income_cigna',
  individual_therapy_income_magellan = 'individual_therapy_income_magellan',
  individual_therapy_income_alma = 'individual_therapy_income_alma',
  individual_therapy_income_talkspace = 'individual_therapy_income_talkspace',
  individual_therapy_income_teladoc = 'individual_therapy_income_teladoc',
  individual_therapy_income_group_practice = 'individual_therapy_income_group_practice',
  individual_therapy_income_kip = 'individual_therapy_income_kip',
  individual_therapy_income_insurance = 'individual_therapy_income_insurance',
  individual_therapy_income_paypal = 'individual_therapy_income_paypal',
  individual_therapy_income_telemedica = 'individual_therapy_income_telemedica',
  equipment_furniture = 'equipment_furniture',
  business_loan_lt_1_year_term = 'business_loan_lt_1_year_term',
  business_vehicle = 'business_vehicle',
  business_vehicle_loan = 'business_vehicle_loan',
  computer_hardware_lt_2500 = 'computer_hardware_lt_2500',
  equipment_lt_2500 = 'equipment_lt_2500',
  furniture_lt_2500 = 'furniture_lt_2500',
  insurance_vision = 'insurance_vision',
  interest_income = 'interest_income',
  loans_to_shareholder = 'loans_to_shareholder',
  loans_from_shareholder = 'loans_from_shareholder',
  loan_repayment = 'loan_repayment',
  loan_repayment_car = 'loan_repayment_car',
  account_receivable = 'account_receivable',
  business_loan_gte_1_year_term = 'business_loan_gte_1_year_term',
  cash_checking_account = 'cash_checking_account',
  cash_savings_account = 'cash_savings_account',
  computer_hardware_gte_2500 = 'computer_hardware_gte_2500',
  equipment_gte_2500 = 'equipment_gte_2500',
  furniture_gte_2500 = 'furniture_gte_2500',
  land = 'land',
  lt_securities_bond_investment = 'lt_securities_bond_investment',
}

export type TransactionType = TransactionRuleCategoryType | 'split' | null
export interface Transaction {
  id: number
  financialProfileId?: number
  userId: number
  date: string
  description: string
  type: TransactionType
  notes?: string | null
  notesLastUpdatedAt?: string | null
  transactionCategoryId: number | null
  transactionCategory?: {
    accountType: string
    name: string
    identifier: TransactionCategoryIdentifier
    isTaxCompliant: boolean
  } | null
  amountInCents: number
  financialAccountId?: number | null
  financialAccount?: {
    name: string
    mask: string | null
    inactive: boolean
  }
  plaidTransactionId?: string | null
  accountTransactions?: {
    plaidInstitutionName: string | null
    name: string
    mask: string | null
    subtype?: string | null
  }
  excludedAt?: string | null
  confirmedAt?: string | null
  autocategorizedAt?: string | null
  autocategorizedTransactionRuleId?: number | null
  requestedClarificationAt?: string | null
  notifiedRequestedClarificationAt?: string | null
  manuallyCreated?: boolean
  importedOn?: string | null
  splitFrom?: number | null
  splitAt?: string | null
  splitTransactions?: SplitTransaction[]
  reconciliationId?: number | null
  createdAt?: string
  updatedAt?: string
  deletedAt?: string | null
  manuallyDedupedAt?: string | null
  receipts?: UserDocument[]
  adminNotes?: string | null
  user?: {
    email: string
    firstName: string
    id: number
    lastName: string
    booksLockedForUserYear: string | null
    booksLockedForAdminYear: string | null
  }
  journalEntries?: JournalEntry[]
  transactionEdits?: number[] | TransactionEdit[]
  FinancialProfileId?: number
  nickname?: string
  _count?: {
    receipts: number | null
  }
}

export type TransactionOrSplit = Transaction | SplitTransaction

export type PostTransactionPayload = Partial<
  Omit<
    Transaction,
    | 'confirmedAt'
    | 'createdAt'
    | 'updatedAt'
    | 'deletedAt'
    | 'excludedAt'
    | 'splitAt'
    | 'importedOn'
    | 'requestedClarificationAt'
    | 'notifiedRequestedClarificationAt'
  >
> & {
  confirmedAt?: number | null
  createdAt?: number | null
  updatedAt?: number | null
  deletedAt?: number | null
  excludedAt?: number | null
  splitAt?: number | null
  importedOn?: number | null
  requestedClarificationAt?: number | null
  notifiedRequestedClarificationAt?: number | null
}

export interface AdminTransactionState {
  byId: { [key: string]: Transaction }
  allIds: number[]
  byTransactionCategoryId: {
    [userId: number]: {
      [key: string]: {
        transactionIds: number[]
        totalCount: number | null
      }
    }
    null?: {
      transactionIds: number[]
      totalCount: number | null
    }
  }
  queriedTransactionIds: number[]
  queriedTransactionsCount: number | null
  transactionsCount?: {
    pendingCount: number
    uncategorizedCount: number
    requestedClarificationCount: number
  }
}

const initialState: AdminTransactionState = {
  byId: {},
  allIds: [],
  byTransactionCategoryId: {},
  queriedTransactionIds: [],
  queriedTransactionsCount: null,
}

export interface TransactionResponseEntities {
  transactions?: { [key: string]: Transaction }
  transactionEdits?: { [key: string]: TransactionEdit }
  journalEntries?: { [key: string]: JournalEntry }
}

export interface SplitTransactionModel {
  amountInCents: number
  type: string
  categoryId: number | null
}

const allTransactionSlice = createSlice({
  initialState,
  name: 'adminAllTransactions',
  reducers: {
    receiveAdminTransactionsByCategory: (
      state,
      action: PayloadAction<{
        userId: number
        transactionCategoryId: number
        transactionIds: number[]
        count: number
      }>
    ) => {
      if (!state.byTransactionCategoryId[action.payload.userId]) {
        state.byTransactionCategoryId[action.payload.userId] = {}
      }

      state.byTransactionCategoryId[action.payload.userId][
        action.payload.transactionCategoryId
      ] = {
        transactionIds: action.payload.transactionIds,
        totalCount: action.payload.count,
      }
    },
    receiveAdminAllTransactions: (
      state,
      action: PayloadAction<
        NormalizedSchema<TransactionResponseEntities, string[] | string>
      >
    ) => {
      state.byId = {
        ...state.byId,
        ...keyBy(action.payload.entities.transactions || {}, 'id'),
      }
      state.allIds = uniq([
        ...state.allIds,
        ...Object.keys(action.payload.entities.transactions || {}).map((val) =>
          Number(val)
        ),
      ])
    },
    receiveAdminSingleTransaction: (
      state,
      action: PayloadAction<Transaction>
    ) => {
      state.byId = {
        ...state.byId,
        [action.payload.id]: action.payload,
      }
      state.allIds = uniq([...state.allIds, action.payload.id])
    },
    receiveQueriedTransactionData: (
      state,
      action: PayloadAction<{ count: number; ids: number[] }>
    ) => {
      state.queriedTransactionIds = action.payload.ids
      state.queriedTransactionsCount = action.payload.count
    },
    clearQueriedTransactions: (state) => {
      state.queriedTransactionIds = []
      state.queriedTransactionsCount = 0
    },
    receiveAdminTransactionsCounts: (
      state,
      action: PayloadAction<{
        pendingCount: number
        uncategorizedCount: number
        requestedClarificationCount: number
      }>
    ) => {
      state.transactionsCount = action.payload
    },
    deleteAdminTransaction: (state, action: PayloadAction<Transaction>) => {
      state.allIds = state.allIds.filter((id) => id !== action.payload.id)
      delete state.byId[action.payload.id]
    },
    deleteMultipleTransactions: (state, action: PayloadAction<number[]>) => {
      state.allIds = difference(state.allIds, action.payload)
      state.byId = omitBy(state.byId, (trans) =>
        action.payload.includes(trans.id)
      )
    },
    addJournalEntryToTransaction: (
      state,
      action: PayloadAction<{
        transactionId: number
        journalEntry: JournalEntry
      }>
    ) => {
      const transaction = state.byId[action.payload.transactionId]
      if (transaction) {
        state.byId[action.payload.transactionId] = {
          ...transaction,
          journalEntries: uniq([
            ...(transaction?.journalEntries || []),
            action.payload.journalEntry,
          ]),
        }
      }
    },
    addJournalEntryToSplitTransaction: (
      state,
      action: PayloadAction<{
        transactionId: number
        journalEntry: JournalEntry
        parentId: number
      }>
    ) => {
      const { transactionId, journalEntry, parentId } = action.payload
      const transaction = state.byId[parentId]
      if (!transaction?.splitTransactions) return
      const updatedSplitTransactions = transaction.splitTransactions.map(
        (split: Transaction) => {
          if (split.id === transactionId) {
            return {
              ...split,
              journalEntries: uniq([
                ...(split?.journalEntries || []),
                journalEntry,
              ]),
            }
          }
          return split
        }
      )
      state.byId[parentId] = {
        ...transaction,
        splitTransactions: updatedSplitTransactions,
      }
    },
    removeJournalEntryFromTransaction: (
      state,
      action: PayloadAction<{
        transactionId: number
        journalEntry: JournalEntry
      }>
    ) => {
      const transaction = state.byId[action.payload.transactionId]
      if (transaction?.journalEntries) {
        state.byId[action.payload.transactionId] = {
          ...transaction,
          journalEntries: (transaction?.journalEntries || []).filter(
            (id) => id !== action.payload.journalEntry
          ),
        }
      }
    },
    removeJournalEntryFromSplitTransaction: (
      state,
      action: PayloadAction<{
        transactionId: number
        journalEntry: JournalEntry
        parentId: number
      }>
    ) => {
      const { transactionId, journalEntry, parentId } = action.payload
      const transaction = state.byId[parentId]
      if (!transaction?.splitTransactions) return
      const updatedSplitTransactions = transaction.splitTransactions.map(
        (split: Transaction) => {
          if (split.id === transactionId) {
            return {
              ...split,
              journalEntries: (split?.journalEntries || []).filter(
                (id) => id !== journalEntry
              ),
            }
          }
          return split
        }
      )

      state.byId[parentId] = {
        ...transaction,
        splitTransactions: updatedSplitTransactions,
      }
    },
  },
})

export default allTransactionSlice.reducer

export const {
  receiveAdminTransactionsByCategory,
  receiveAdminAllTransactions,
  receiveAdminSingleTransaction,
  receiveAdminTransactionsCounts,
  receiveQueriedTransactionData,
  deleteAdminTransaction,
  deleteMultipleTransactions,
  clearQueriedTransactions,
  addJournalEntryToTransaction,
  addJournalEntryToSplitTransaction,
  removeJournalEntryFromTransaction,
  removeJournalEntryFromSplitTransaction,
} = allTransactionSlice.actions
