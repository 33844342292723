import { createSelector } from 'reselect'
import { ReduxState } from '../../utils/typeHelpers'
import {
  CatchUpBookkeepingStatus,
  CatchUpBookkeepingStatusType,
} from './catchUpBookkeepingStatus.slice'
import {
  getCurrentUser,
  selectPrimaryMembership,
} from '../../selectors/user.selectors'
import { DateTime } from 'luxon'
import { Membership } from '../../reducers/auth/userReducer'

const selectCatchUpBookkeepingStatus = (state: ReduxState) =>
  state.catchUpBookkeepingStatus

export const selectCatchUpBookkeepingIsCompleteForUser = createSelector(
  selectCatchUpBookkeepingStatus,
  (_: unknown, userId?: number) => userId,
  (catchUpBookkeepingStatus, userId) => {
    if (!userId) {
      return false
    }
    return (
      catchUpBookkeepingStatus[userId]?.status ===
      CatchUpBookkeepingStatusType.complete
    )
  }
)

export const selectCatchUpBookkeepingStatusForCurrentUser = createSelector(
  selectCatchUpBookkeepingStatus,
  getCurrentUser,
  (catchUpBookkeepingStatus, user) => {
    if (!user) {
      return null
    }
    return catchUpBookkeepingStatus[user.id]
  }
)

export const selectIsCatchUpBookkeepingComplete = createSelector(
  selectCatchUpBookkeepingStatusForCurrentUser,
  (status) => status?.status === CatchUpBookkeepingStatusType.complete
)

export const isCUBKUnpaid = (
  membership: Membership | null,
  status: CatchUpBookkeepingStatus | null
) =>
  Boolean(
    membership?.startDate &&
      (!membership.trialEndDate ||
        DateTime.now() > DateTime.fromISO(membership.trialEndDate)) &&
      status?.paymentRequired &&
      !status.paidAt
  )

export const selectCatchUpBookkeepingIsUnpaid = createSelector(
  selectPrimaryMembership,
  selectCatchUpBookkeepingStatusForCurrentUser,
  isCUBKUnpaid
)

export const selectTrialUserHasUnpaidCatchUpBookkeeping = createSelector(
  selectPrimaryMembership,
  selectCatchUpBookkeepingIsUnpaid,
  (membership, unpaidBk) =>
    Boolean(
      membership?.trialEndDate &&
        DateTime.now() > DateTime.fromISO(membership.trialEndDate) &&
        unpaidBk
    )
)
