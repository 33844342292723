import React from 'react'

// BL
import { UserDocument } from '../userDocuments.slice'
import { UserDocumentCategory } from '../../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { TabCategoryKeys } from '../constants'
import { getDocumentTag } from '../service'
import { USER_DOCUMENT_COLORS } from '../../../constants/colorConstants'

// UI
import { Label } from 'semantic-ui-react'
import { Colors } from '../../../styles/theme'

interface UserDocumentTagProps {
  category?: UserDocumentCategory
  document: UserDocument
}

const DEFAULT_HEX_COLOR = Colors.stone

const UserDocumentTag: React.FC<UserDocumentTagProps> = ({
  category,
  document,
}) => {
  let hex = !document.userDocumentCategoryId
    ? DEFAULT_HEX_COLOR
    : USER_DOCUMENT_COLORS.slice(0)[
        document.userDocumentCategoryId % USER_DOCUMENT_COLORS.length
      ]

  const documentTag = getDocumentTag(document, category)

  if (document.documentType === TabCategoryKeys.BANK_STATEMENTS) {
    hex = USER_DOCUMENT_COLORS[0]
  }

  if (!documentTag) {
    return null
  }

  return <Label style={{ backgroundColor: hex }}>{documentTag}</Label>
}

export default UserDocumentTag
