import { useParams } from 'react-router-dom'
import { Divider, Grid } from 'semantic-ui-react'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Button,
  FormikLabelError,
  FormikLocationSearchInput,
  FormikRadioToggleButton,
  GridRowColumn,
  Icon,
  Text,
  makeReqBoolSchema,
  makeReqStringSchema,
  Card,
  IconButton,
} from '../../../../../../components/BaseComponents'
import { useReselector } from '../../../../../../utils/sharedHooks'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../../taxChecklist.selectors'
import { TaxListQuestionId } from '../../service'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { IncomeAndLiabilitiesStepProps } from '.'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import DocumentAddedAlert from '../../Shared/SpecialtyAlerts'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import { getNewRentalGroupId, selectRentalPropertyResponses } from './helpers'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { UPDATE_USER_EOY_REVIEW_PROGRESS_KEY } from '../../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { UPSERT_USER_TAX_QUESTIONNAIRE_KEY } from '../../taxChecklistQuestion.actions'

export const rentalPropertyQuestionIds = [
  TaxListQuestionId.rental_property_address,
  TaxListQuestionId.rental_property_first_time,
]

const RentalPropertyPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: IncomeAndLiabilitiesStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()

  const questionPropertyAddress = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.rental_property_address,
    taxYear
  )
  const questionFirstTimeRentingProperty = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.rental_property_first_time,
    taxYear
  )
  const responseValuesByGroupId = useReselector(
    selectRentalPropertyResponses,
    Number(formId)
  )

  const responseNumberProperties = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_of_rental_properties,
    Number(formId)
  )

  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    UPDATE_USER_EOY_REVIEW_PROGRESS_KEY,
  ])

  const formik = useFormik({
    initialValues: {
      rentalProperties: Object.values(responseValuesByGroupId ?? {}).map(
        (property) => ({
          groupId: property.groupId,
          propertyAddress: property.propertyAddress?.value,
          firstTimeRentingProperty: property.firstTimeRentingProperty?.value,
        })
      ),
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      rentalProperties: yup.array().of(
        yup.object().shape({
          propertyAddress: makeReqStringSchema(),
          firstTimeRentingProperty: makeReqBoolSchema(),
        })
      ),
    }),
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      values.rentalProperties.forEach((rentalProperty) => {
        const currentProperty =
          responseValuesByGroupId?.[rentalProperty.groupId]
        if (
          rentalProperty.propertyAddress &&
          rentalProperty.propertyAddress !==
            currentProperty?.propertyAddress?.value
        ) {
          responseData.push({
            id: currentProperty?.propertyAddress?.id,
            value: rentalProperty.propertyAddress,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.rental_property_address,
            groupId: rentalProperty.groupId,
          })
        }
        if (
          rentalProperty.firstTimeRentingProperty !== null &&
          rentalProperty.firstTimeRentingProperty !==
            currentProperty?.firstTimeRentingProperty?.value
        ) {
          responseData.push({
            id: currentProperty?.firstTimeRentingProperty?.id,
            value: rentalProperty.firstTimeRentingProperty,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.rental_property_first_time,
            groupId: rentalProperty.groupId,
          })
        }
      })

      if (
        values.rentalProperties.length !== responseNumberProperties?.[0]?.value
      ) {
        responseData.push({
          id: responseNumberProperties?.[0]?.id,
          value: values.rentalProperties.length,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.number_of_rental_properties,
        })
      }

      await goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.rentalProperties],
      })
    },
  })

  const { isValid, submitForm, isSubmitting, values } = formik

  const questionsGridConfig = makeGridConfig([8, 14], true)
  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn>
          <Text as="display2" textAlign="center">
            Rental Properties
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([10, 14], true)}>
          <Text as="bodyLg">
            Your tax preparer will reach out to you to gather more information
            regarding this rental property for tax filing purposes.
          </Text>
        </GridRowColumn>
        <Grid.Row />
        <FieldArray name="rentalProperties">
          {({ push, remove }) => (
            <>
              {values.rentalProperties?.map(({ groupId }, index) => {
                const addressFieldName = `rentalProperties.${index}.propertyAddress`
                const firstTimeRentingPropertyFieldName = `rentalProperties.${index}.firstTimeRentingProperty`
                return (
                  <>
                    <GridRowColumn key={groupId} {...questionsGridConfig}>
                      <Card backgroundColor="stone40">
                        <Grid>
                          <GridRowColumn short style={{ paddingBottom: 0 }}>
                            <IconButton
                              icon={light('trash-can')}
                              style={{
                                cursor: 'pointer',
                                float: 'right',
                              }}
                              onClick={() => remove(index)}
                            />
                          </GridRowColumn>
                          <GridRowColumn style={{ paddingTop: 0 }}>
                            <FormikLocationSearchInput
                              label={questionPropertyAddress.question?.text}
                              placeholder="Street Name, City, State, ZIP"
                              fullWidth
                              name={addressFieldName}
                              singleLineAddressOnly
                            />
                          </GridRowColumn>
                          <GridRowColumn>
                            <FormikLabelError
                              name={firstTimeRentingPropertyFieldName}
                              label={
                                questionFirstTimeRentingProperty.question?.text
                              }
                            />
                          </GridRowColumn>
                          <Grid.Row
                            className="short"
                            style={{ justifyContent: 'center' }}
                          >
                            <Grid.Column width={8} style={{ paddingRight: 0 }}>
                              <FormikRadioToggleButton
                                fullWidth
                                name={firstTimeRentingPropertyFieldName}
                                value
                              >
                                Yes
                              </FormikRadioToggleButton>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <FormikRadioToggleButton
                                fullWidth
                                name={firstTimeRentingPropertyFieldName}
                                value={false}
                              >
                                No
                              </FormikRadioToggleButton>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Card>
                    </GridRowColumn>
                    <GridRowColumn {...questionsGridConfig}>
                      <Divider />
                    </GridRowColumn>
                  </>
                )
              })}
              <GridRowColumn>
                <Button
                  style={{ margin: '0 auto' }}
                  variant="secondary"
                  onClick={() =>
                    push({
                      groupId: getNewRentalGroupId(),
                      propertyAddress: null,
                      firstTimeRentingProperty: null,
                    })
                  }
                  size="large"
                >
                  <Icon style={{ marginRight: 8 }} icon={light('plus')} />
                  Add Address
                </Button>
              </GridRowColumn>
            </>
          )}
        </FieldArray>
        <Grid.Row />
        <GridRowColumn short {...questionsGridConfig}>
          <DocumentAddedAlert>
            Profit and loss statement for rental properties added to checklist.
          </DocumentAddedAlert>
        </GridRowColumn>
        <Grid.Row />
        <FormFlowFooter
          continueDisabled={
            !isValid || values.rentalProperties.length === 0 || isSubmitting
          }
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isSubmitting || isUpdating}
        />
      </Grid>
    </FormikProvider>
  )
}

export default RentalPropertyPanel
