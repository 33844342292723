import React from 'react'
import { Icon, List, Popup } from 'semantic-ui-react'

const CalculationListItem = ({
  header,
  value,
  tooltip,
  width,
}: {
  header: string
  value?: string | React.ReactElement | null
  tooltip?: React.ReactNode
  width?: string
}) => (
  <List.Item>
    <List.Content verticalAlign="middle" floated="right">
      {value}
      {tooltip && (
        <Popup
          hoverable
          style={{ width: width ?? 300 }}
          flowing
          trigger={
            <Icon
              style={{ fontSize: '12px', margin: '0 0 0 5px' }}
              name="question circle"
            />
          }
          content={tooltip}
        />
      )}
    </List.Content>
    <List.Header style={{ color: 'grey' }}>{header}</List.Header>
  </List.Item>
)

export default CalculationListItem
