import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Grid, Container, Divider } from 'semantic-ui-react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

import {
  Button,
  Text,
  TextArea,
  TileSelector,
} from '../../../components/BaseComponents'
import { PRIORITIES, SIGNUP_PATHS } from '../helpers'
import SignupHeader from '../SignupHeader'
import {
  fetchUserFocusAreas,
  createUserFocusAreas,
  updateUserFocusAreas,
} from '../userFocusAreaActions'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { UserFocusAreas } from '../userFocusArea.slice'
import { inOperator, useAppDispatch } from '../../../utils/typeHelpers'
import { useSignupPageUpdate } from '../../../services/onboardingService'
import { getUserFocusAreas } from '../userFocusArea.selecter'
import { useAnalyticsView } from '../../Amplitude'
import {
  useNavigateWithPersistParams,
  useReselector,
} from '../../../utils/sharedHooks'

const PriorityButton = ({
  priorities,
  setPriorities,
  name,
  icon,
  text,
}: {
  priorities: string[]
  setPriorities: Dispatch<SetStateAction<string[]>>
  name: string
  icon: IconDefinition
  text: string
}) => {
  const onClick = useCallback(() => {
    setPriorities((priorities: string[]) =>
      priorities.includes(name)
        ? priorities.filter((p) => p !== name)
        : [...priorities, name]
    )
  }, [name, setPriorities])

  const active = priorities.includes(name)

  return (
    <TileSelector
      icon={icon}
      text={text}
      fullWidth
      disabled={!priorities.includes(name) && priorities.length >= 3}
      onClick={onClick}
      active={active}
    />
  )
}

const PracticePriorities = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigateWithPersistParams()
  const [loading, setLoading] = useState(false)

  const currentUser = useReselector(getCurrentUser)
  const userFocusAreas = useReselector(getUserFocusAreas)

  const [priorities, setPriorities] = useState<string[]>([])

  const [textBoxShown, setTextBoxShown] = useState(false)
  const [extraHelp, setExtraHelp] = useState('')
  const pageView = useAnalyticsView()

  useEffect(() => {
    pageView('sign up practice priorities')
  }, [pageView])

  useSignupPageUpdate(SIGNUP_PATHS.practicePriorities)

  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser?.id) return
      const areas = await fetchUserFocusAreas(currentUser.id)(dispatch)

      setPriorities(
        (areas &&
          Object.keys(areas).filter(
            (focusArea) =>
              inOperator(focusArea, areas) && areas[focusArea] === true
          )) ||
          []
      )
    }
    fetchData()
  }, [currentUser?.id, dispatch])

  const submitUserFocusAreas = async () => {
    if (!currentUser) return
    setLoading(true)

    const data: Omit<UserFocusAreas, 'id' | 'userId'> = Object.keys(
      PRIORITIES
    ).reduce((payload, priority) => {
      return { ...payload, [priority]: priorities.includes(priority) }
    }, {})

    const payload: Omit<UserFocusAreas, 'id'> = {
      ...data,
      userId: currentUser.id,
    }

    if (extraHelp) payload.other = extraHelp

    let response

    if (userFocusAreas) {
      response = await updateUserFocusAreas(currentUser.id, payload)(dispatch)
    } else {
      response = await createUserFocusAreas(payload)(dispatch)
    }
    setLoading(false)

    if (response) {
      navigate(SIGNUP_PATHS.aboutYourPractice)
    }
  }

  return (
    <Container style={{ paddingTop: 64 }}>
      <Grid>
        <SignupHeader currentStep={1} />
        <Grid.Row>
          <Grid.Column>
            <Text as="h1">
              Hi, {currentUser?.firstName || ''}! How can we help your practice?
            </Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Text as="bodyLg">
              Choose the top 3 areas you want to focus on. We’ll address your
              highest priorities first, and then cover the rest listed below.
            </Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={2} />
          <Grid.Column width={3}>
            <PriorityButton
              priorities={priorities}
              setPriorities={setPriorities}
              {...PRIORITIES['taxesAndDeductions']}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <PriorityButton
              priorities={priorities}
              setPriorities={setPriorities}
              {...PRIORITIES['budgeting']}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <PriorityButton
              priorities={priorities}
              setPriorities={setPriorities}
              {...PRIORITIES['payingMyself']}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <PriorityButton
              priorities={priorities}
              setPriorities={setPriorities}
              {...PRIORITIES['practicingInMultipleStates']}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={2} />
          <Grid.Column width={3}>
            <PriorityButton
              priorities={priorities}
              setPriorities={setPriorities}
              {...PRIORITIES['businessOwnership']}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <PriorityButton
              priorities={priorities}
              setPriorities={setPriorities}
              {...PRIORITIES['incorporatingMyBusiness']}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <PriorityButton
              priorities={priorities}
              setPriorities={setPriorities}
              {...PRIORITIES['startingMyPractice']}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <PriorityButton
              priorities={priorities}
              setPriorities={setPriorities}
              {...PRIORITIES['growingMyPractice']}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Button variant="link" onClick={() => setTextBoxShown(true)}>
              Don&apos;t see your goals listed?
            </Button>
          </Grid.Column>
        </Grid.Row>
        {textBoxShown && (
          <Grid.Row>
            <Grid.Column width={4} />
            <Grid.Column width={6}>
              <Text as="h3">Tell us how we can help you:</Text>
              <TextArea
                value={extraHelp}
                onChange={(val) => setExtraHelp(val)}
                placeholder="Describe it here"
                style={{ marginTop: 10 }}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row />
        <Divider />
        <Grid.Row />
        <Grid.Row>
          <Grid.Column width={14} />
          <Grid.Column width={2}>
            <Button
              fullWidth
              onClick={submitUserFocusAreas}
              disabled={priorities.length < 3 || loading}
              id="btn-signup-practice-priorities"
              loading={loading}
            >
              Continue
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row />
      </Grid>
    </Container>
  )
}

export default PracticePriorities
