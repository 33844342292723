import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum TransactionRuleUse {
  CATEGORIZATION = 'CATEGORIZATION',
  CLARIFICATION = 'CLARIFICATION',
}

export enum TransactionRuleType {
  EXACT_MATCH = 'EXACT_MATCH',
  CONTAINS_WORD = 'CONTAINS_WORD',
  REGEX = 'REGEX',
  PLAID_CATEGORY = 'PLAID_CATEGORY',
}

export enum TransactionRuleCategoryType {
  personal = 'personal',
  business = 'business',
}

export interface TransactionRule {
  id: number
  ruleUse: TransactionRuleUse
  ruleText: string
  ruleType: TransactionRuleType
  transactionCategoryType: TransactionRuleCategoryType | null
  transactionCategoryId: number | null
  userId: number | null
  amountInCents: number | null
  createdAt: string
  updatedAt: string
  archivedAt?: string | null
}

export interface TransactionRulesState {
  [key: number]: TransactionRule
}

const transactionRulesSlice = createSlice({
  name: 'transactionRules',
  initialState: {} as TransactionRulesState,
  reducers: {
    receiveTransactionRules: (
      state,
      action: PayloadAction<{ [key: number]: TransactionRule }>
    ) => ({ ...state, ...action.payload }),
    receiveSingleTransactionRule: (
      state,
      action: PayloadAction<TransactionRule>
    ) => {
      state[action.payload.id] = action.payload
    },
    receiveDeleteTransactionRule: (state, action: PayloadAction<number>) => {
      delete state[action.payload]
    },
  },
})

export default transactionRulesSlice.reducer

export const {
  receiveTransactionRules,
  receiveSingleTransactionRule,
  receiveDeleteTransactionRule,
} = transactionRulesSlice.actions
