import { CSSProperties, FC, ReactElement } from 'react'
import { orderBy } from 'lodash'
import moment from 'moment'
import { List } from 'semantic-ui-react'

import { Text } from '../../../../components/BaseComponents'
import { UserImportantDate } from '../userImportantDates.slice'
import ImportantDateListItem from './ImportantDateListItem'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'

interface Props {
  indicateUnreadItems: boolean
  userImportantDates?: UserImportantDate[]
  groupItemsByMonth: boolean
  style?: CSSProperties
}

const ImportantDateList: FC<Props> = ({
  userImportantDates,
  indicateUnreadItems,
  groupItemsByMonth,
  style,
}) => {
  const renderItem = (userImportantDate: UserImportantDate) => (
    <ImportantDateListItem
      key={`user-important-date-${userImportantDate.id}`}
      userImportantDate={userImportantDate}
      indicateUnreadItems={indicateUnreadItems}
    />
  )

  const renderMonthHeader = (text: string) => (
    <Text style={{ marginTop: 20 }} as="eyebrow">
      {text}
    </Text>
  )

  let els: ReactElement[] | undefined

  if (groupItemsByMonth) {
    // Ensure data is sorted asc
    els = []
    const sorted = orderBy(
      userImportantDates,
      (d) => {
        const endDate = d.endsAt || d.actionItem.endsAt
        return endDate && new Date(endDate)
      },
      'asc'
    )

    // Keep track of headers already rendered
    const monthNames: { [key: string]: string } = {}

    for (const userImportantDate of sorted) {
      const monthName = moment
        .utc(userImportantDate.endsAt || userImportantDate.actionItem.endsAt)
        .format(DATE_FORMATS.MONTH_YEAR)

      // If we haven't rendered a header yet, do so before rendering the item
      if (!monthNames[monthName]) {
        monthNames[monthName] = monthName
        els.push(renderMonthHeader(monthName))
      }

      // Render the item
      els.push(renderItem(userImportantDate))
    }
  } else {
    els = userImportantDates?.map(renderItem)
  }

  return <List style={style}>{els}</List>
}

export default ImportantDateList
