import { useEffect, useMemo } from 'react'
import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'

import {
  Modal,
  Button,
  Text,
  FormikDropdown,
  Alert,
} from '../../BaseComponents'
import {
  ADMIN_CREATE_USER_IMPORTANT_DATE_KEY,
  adminCreateUserImportantDate,
} from '../../../features/Dashboard/UserImportantDates/userImportantDates.slice'
import { useReselector } from '../../../utils/sharedHooks'
import { getFetchError } from '../../../reducers/fetch'
import {
  fetchAllImportantDates,
  selectImportantDates,
} from '../../../features/Admin/ActionItems/allActionItems.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface UserImportantDateCreationModalProps {
  open: boolean
  close: () => void
  userId: number
}

const UserImportantDateCreationModal = ({
  open,
  close,
  userId,
}: UserImportantDateCreationModalProps) => {
  const dispatch = useAppDispatch()
  const error = useReselector(
    getFetchError,
    ADMIN_CREATE_USER_IMPORTANT_DATE_KEY
  )

  const allImportantDates = useReselector(selectImportantDates)
  const importantDateOptions = useMemo(
    () =>
      allImportantDates.map((date) => ({
        text: date.description,
        value: date.id,
      })),
    [allImportantDates]
  )

  useEffect(() => {
    fetchAllImportantDates()(dispatch)
  }, [dispatch])

  const validationSchema = yup.object({
    actionItemId: yup.number().required('You must select an important Date.'),
  })

  const formik = useFormik({
    initialValues: {
      actionItemId: 0,
    },
    validationSchema,
    onSubmit: async (actionItemId: { actionItemId: number }) => {
      const res = await adminCreateUserImportantDate(
        userId,
        actionItemId
      )(dispatch)
      if (res) {
        close()
      }
    },
  })

  return (
    <Modal
      size="tiny"
      className="userImportantDatesCreationModal"
      open={open}
      onClose={close}
    >
      <Modal.Content>
        <Text as="h2">Assign an important date for User to complete</Text>
        <p>
          This allows you to add an important date for the user to complete.
          This will show up on a user&#39;s Dashboard.
        </p>
        {error && <Alert type="error">{error.message}</Alert>}
        <FormikProvider value={formik}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <FormikDropdown
                  label="Select Important Date"
                  placeholder="Select Option"
                  name="actionItemId"
                  options={importantDateOptions}
                  fullWidth
                  required
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={6}>
                <Button variant="secondary" onClick={close} fullWidth>
                  Cancel
                </Button>
              </Grid.Column>
              <Grid.Column width={4} />
              <Grid.Column width={6}>
                <Button
                  onClick={() => formik.handleSubmit()}
                  type="submit"
                  fullWidth
                >
                  Create
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </FormikProvider>
      </Modal.Content>
    </Modal>
  )
}

export default UserImportantDateCreationModal
