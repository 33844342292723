import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum BookkeepingReportStatus {
  done = 'done',
  reconciled = 'reconciled',
  categorized = 'categorized',
  in_progress = 'in_progress',
  not_started = 'not_started',
  missing_info = 'missing_info',
  plaid_error = 'plaid_error',
}

export type BookkeepingStatus = {
  bookkeepingReportId: number
  createdAt: string
  id: number
  status: BookkeepingReportStatus
  updatedAt: string
}

export interface BookkeepingReport {
  id: number
  financialProfileId: number
  // In YYYY-MM
  date: string
  startDate: string
  endDate: string
  dueDate: string
  reconciledAt: string | null
  completedAt: string | null
  reports: {
    financialProfile: {
      firstName: string
      lastName: string
      bookkeeperId?: number | null
    }
    userId: number
  }
  statuses: BookkeepingStatus[]
  documentsCount: string
}

interface BookkeepingReportState {
  [id: string]: BookkeepingReport
}

const bookkeepingReportsSlice = createSlice({
  name: 'bookkeepingReports',
  initialState: {} as BookkeepingReportState,
  reducers: {
    receiveAllBookkeepingReports: (
      state,
      action: PayloadAction<{ [key: string]: BookkeepingReport }>
    ) => ({ ...state, ...action.payload }),
    receiveSingleBookkeepingReport: (
      state,
      action: PayloadAction<BookkeepingReport>
    ) => {
      state[action.payload.id] = action.payload
    },
  },
})

export const { receiveSingleBookkeepingReport, receiveAllBookkeepingReports } =
  bookkeepingReportsSlice.actions

export default bookkeepingReportsSlice.reducer
