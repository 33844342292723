import { Grid } from 'semantic-ui-react'
import { Text } from '../../../../components/BaseComponents'

export const SampleTransactionCardHeader = () => (
  <Grid.Row style={{ marginBottom: 0 }}>
    <Grid.Column width={2}>
      <Text as="eyebrow">Date</Text>
    </Grid.Column>
    <Grid.Column width={3}>
      <Text as="eyebrow">Description</Text>
    </Grid.Column>
    <Grid.Column width={2}>
      <Text as="eyebrow">Amount</Text>
    </Grid.Column>
    <Grid.Column width={3}>
      <Text as="eyebrow">Type</Text>
    </Grid.Column>
    <Grid.Column width={4}>
      <Text as="eyebrow">Category</Text>
    </Grid.Column>
  </Grid.Row>
)

export default SampleTransactionCardHeader
