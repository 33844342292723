import { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Button,
  Card,
  GridRowColumn,
  Icon,
  IconButton,
  Link,
  Popup,
  Text,
  BorderedIcon,
} from '../../../../components/BaseComponents'
import {
  useFetchResponseOnInterval,
  useReselector,
} from '../../../../utils/sharedHooks'
import { selectAllFilingFormsComplete } from '../annualTaxFilingForms.selector'
import { ANNUAL_TAX_FILING_FORM_TYPES } from '../Questionnaires/constants'
import {
  getAnnualTaxFilingForYearSelector,
  selectFormsWithCompletedTqForYear,
  selectHasTqStartedForCurrentYear,
  selectIsBeforeExtensionDeadline,
} from '../annualTaxFilings.selector'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { fetchAnnualTaxFilingsIfNeeded } from '../annualTaxFilings.slice'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import {
  AnnualTaxFilingForm,
  FETCH_TAXFYLE_JOB_URL_KEY,
  fetchAnnualTaxFilingFormsIfNeeded,
  fetchTaxFyleExtensionJobUrl,
  fetchTaxFyleJobUrl,
  TaxfyleJob,
  TaxfyleJobStatus,
  TaxfyleJobStep,
} from '../annualTaxFilingForms.slice'
import { getFetchError, getIsFetching } from '../../../../reducers/fetch'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const MessagesCard = ({
  filingId,
  form,
  taxfyleJob,
  isExtension,
}: {
  filingId?: number
  form?: AnnualTaxFilingForm
  taxfyleJob?: TaxfyleJob | null
  isExtension?: boolean
}) => {
  const allMessagesRead = !taxfyleJob?.hasUnreadMessages
  const loading = useReselector(getIsFetching, FETCH_TAXFYLE_JOB_URL_KEY)
  const error = useReselector(getFetchError, FETCH_TAXFYLE_JOB_URL_KEY)
  const isBeforeExtensionDeadline = useReselector(
    selectIsBeforeExtensionDeadline
  )

  const fetchApiCall = useMemo(
    () => (isExtension ? fetchTaxFyleExtensionJobUrl : fetchTaxFyleJobUrl),
    [isExtension]
  )

  // Taxfyle links expire after 5 minutes, so refresh every 4 minutes
  const linkRes = useFetchResponseOnInterval(
    fetchApiCall,
    240000,
    form?.id || filingId
  )

  const buttonDisabled =
    !taxfyleJob ||
    loading ||
    Boolean(error) ||
    [
      TaxfyleJobStatus.unclaimed,
      TaxfyleJobStatus.underConstruction,
      TaxfyleJobStatus.infoGathering,
    ].includes(taxfyleJob.jobStatus)

  const buttonContent = useMemo(() => {
    if (buttonDisabled) {
      return 'Coming Soon'
    }

    if (allMessagesRead) {
      return (
        <>
          <Icon icon={regular('envelope-open')} style={{ marginRight: 10 }} />
          View
        </>
      )
    }

    return (
      <>
        <Icon icon={regular('envelope')} style={{ marginRight: 10 }} />
        Reply
      </>
    )
  }, [buttonDisabled, allMessagesRead])

  const subTitle = useMemo(() => {
    if (!isExtension) {
      switch (taxfyleJob?.jobStep) {
        case TaxfyleJobStep.jobStarted:
        case TaxfyleJobStep.draftRejected:
          return 'Your tax return draft is in progress.'
        case TaxfyleJobStep.openItems:
          return 'They have questions about your information.'
        case TaxfyleJobStep.draftInReview:
          return 'Your tax return draft is ready for review.'
        case TaxfyleJobStep.draftApproved:
          return 'Preparing tax filing for your signature.'
        case TaxfyleJobStep.authorizationRequested:
          return 'Your tax filing is ready to sign.'
        case TaxfyleJobStep.authorizationSigned:
          return 'Filing your tax return with the IRS.'
        case TaxfyleJobStep.returnFiled:
          return 'Your tax return was filed successfully!'
        default:
          break
      }
    }

    if (
      taxfyleJob?.jobStatus &&
      [
        TaxfyleJobStatus.claimed,
        TaxfyleJobStatus.onHold,
        TaxfyleJobStatus.idle,
      ].includes(taxfyleJob.jobStatus)
    ) {
      return isExtension
        ? 'Your extension request has been started'
        : 'Your return has been started.'
    }

    if (isExtension) {
      return 'Extension Request for Tax Return(s)'
    } else if (form?.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1040) {
      return 'Personal tax return (Form 1040)'
    }

    return 'Business tax return (Form 1120-S)'
  }, [
    form?.formType.name,
    isExtension,
    taxfyleJob?.jobStatus,
    taxfyleJob?.jobStep,
  ])

  const icon = useMemo(() => {
    if (isExtension) {
      return regular('comments')
    } else if (form?.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1040) {
      return regular('user')
    }

    return regular('buildings')
  }, [form?.formType.name, isExtension])

  const completeText = useMemo(() => {
    if (isExtension) {
      return 'extension request messages'
    } else if (
      form?.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1120_s
    ) {
      return 'business tax return (Form 1120-S) messages'
    }

    return 'personal tax return (Form 1040) messages'
  }, [form?.formType.name, isExtension])

  if (
    (taxfyleJob?.jobStatus &&
      [TaxfyleJobStatus.completed, TaxfyleJobStatus.canceled].includes(
        taxfyleJob.jobStatus
      )) ||
    (isExtension && !isBeforeExtensionDeadline)
  ) {
    return (
      <Card type="subsection">
        <Text>
          {linkRes?.jobUrl && <Link href={linkRes?.jobUrl}>View</Link>}
          {!linkRes?.jobUrl && 'View'} {completeText}
        </Text>
        {error && (
          <Text as="bodyLg" color="red" style={{ marginTop: 5 }}>
            Error loading messages
          </Text>
        )}
      </Card>
    )
  }

  return (
    <Card type="subsection" backgroundColor="natural">
      <Grid>
        <Grid.Row>
          <Grid.Column width={1} verticalAlign="middle">
            <BorderedIcon icon={icon} height={40} wrapperColor="white" />
          </Grid.Column>
          <Grid.Column width={11}>
            <Text as="h2">
              {allMessagesRead
                ? 'Message your Tax Preparer'
                : 'Respond to Your Tax Preparer'}{' '}
              <Popup
                content={
                  <>
                    <Text as="h3">What do I use this for?</Text>
                    <br />
                    <Text>
                      When your tax preparer needs you to confirm information,
                      upload documents or review your{' '}
                      {isExtension ? 'extension request' : 'tax return'}, you’ll
                      message them here.
                    </Text>
                  </>
                }
              />
            </Text>
            <Text as="bodyLg" color="darkGray" style={{ marginTop: 5 }}>
              {subTitle}
            </Text>
            {error && (
              <Text as="bodyLg" color="red" style={{ marginTop: 5 }}>
                Error loading messages
              </Text>
            )}
          </Grid.Column>
          <Grid.Column width={4} verticalAlign="middle">
            <Link
              href={linkRes?.jobUrl}
              style={{ opacity: 1 }}
              disabled={buttonDisabled}
            >
              <Button
                variant={allMessagesRead ? 'secondary' : 'primary'}
                disabled={buttonDisabled}
                fullWidth
              >
                {buttonContent}
              </Button>
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

const CLOSED_TAXFYLE_MESSAGES_OUTRO = (year: string) =>
  `closed-taxfyle-messages-outro-${year}`

const MessageCardList = ({ inTaxCenter }: { inTaxCenter?: boolean }) => {
  const dispatch = useAppDispatch()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const annualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    taxYear
  )
  const hasTQStarted = useReselector(selectHasTqStartedForCurrentYear)
  const allFilingsComplete = useReselector(
    selectAllFilingFormsComplete,
    annualTaxFiling?.id
  )

  const extensionTaxFilingJob = annualTaxFiling?.extensionTaxfyleJob
  const completedTqForms = useReselector(
    selectFormsWithCompletedTqForYear,
    taxYear
  )

  const [closed, setClosed] = useState(true)

  useEffect(() => {
    setClosed(
      localStorage.getItem(CLOSED_TAXFYLE_MESSAGES_OUTRO(taxYear)) === 'true'
    )
  }, [taxYear])

  const onClose = useCallback(() => {
    localStorage.setItem(CLOSED_TAXFYLE_MESSAGES_OUTRO(taxYear), 'true')
    setClosed(true)
  }, [taxYear])

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingsIfNeeded())
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
  }, [dispatch])

  if (process.env.VITE_SHOW_TAX_FYLE_INTRO !== 'true' || !hasTQStarted) {
    return null
  }

  if (allFilingsComplete && !inTaxCenter) {
    if (closed) {
      return null
    }

    return (
      <GridRowColumn>
        <Card type="subsection">
          <Grid>
            <Grid.Row>
              <Grid.Column width={1} verticalAlign="middle">
                <Icon icon={solid('exclamation-circle')} size="xl" />
              </Grid.Column>
              <IconButton
                icon={regular('close')}
                style={{ position: 'absolute', top: 0, right: 20 }}
                color="darkGray"
                size="lg"
                onClick={onClose}
              />
              <Grid.Column width={15}>
                <Text as="h3">Tax Preparer Messages</Text>
                <br />
                <Text as="bodyLg">
                  Conversations with your tax preparer are now closed. If you
                  have any follow-up questions, start a new conversation below
                  with a Heard rep. To view messages, go to the{' '}
                  <Link to="/taxes/annual">Annual Tax Center</Link>.
                </Text>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card>
      </GridRowColumn>
    )
  }

  return (
    <>
      {extensionTaxFilingJob && (
        <GridRowColumn>
          <MessagesCard
            taxfyleJob={extensionTaxFilingJob}
            filingId={annualTaxFiling.id}
            isExtension
          />
        </GridRowColumn>
      )}
      {completedTqForms.map((form) => (
        <GridRowColumn key={form.id}>
          <MessagesCard form={form} taxfyleJob={form.taxfyleJob} />
        </GridRowColumn>
      ))}
    </>
  )
}

export default MessageCardList
