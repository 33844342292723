import { FC } from 'react'
import moment from 'moment'
import { Card, Text } from '../../../../components/BaseComponents'
import { Grid } from 'semantic-ui-react'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'

interface Props {
  cutOffAt?: string
  activeTaxQuarter?: string
}

const JoinedTooLateCard: FC<Props> = ({ cutOffAt, activeTaxQuarter }) => (
  <Grid.Row>
    <Grid.Column>
      <Grid.Row>
        <Grid.Column>
          <Text>
            Since you joined Heard after our quarterly taxes deadline,
            {moment.utc(cutOffAt).format(DATE_FORMATS.DISPLAY_LONG)}, we&apos;re
            unable to calculate your Q{activeTaxQuarter} quarterly tax
            estimates. Don&apos;t worry– this is a one-time instance: Moving
            forward, Heard will calculate your estimates. For this quarter, we
            encourage you to use the guidelines below on how much to pay.
          </Text>
        </Grid.Column>
      </Grid.Row>

      <Card type="subsection">
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Text as="h3">
                How much should I pay for my quarterly tax estimates?
              </Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Text>
                We recommend paying the amount in taxes you paid last year, to
                keep you safe! You&apos;ll take the amount you paid last year
                and divide it by 4 to determine your quarterly estimate. For
                example, if you paid $1,000 last year for federal taxes,
                you&apos;ll divide it by 4 and pay $250 for this quarter&apos;s
                tax payment. You&apos;ll do the same calculation for your state
                quarterly tax payment (if it applies).
              </Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Text>
                Again, this is still an estimate, and it&apos;s normal to owe
                some money at year end or have a refund coming your way. As long
                as you pay 100% of your prior year tax liability (the amount you
                owed in taxes last year), you should not owe money for interest
                and/or penalties from the IRS or state taxing authorities.
              </Text>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </Grid.Column>
  </Grid.Row>
)

export default JoinedTooLateCard
