import AhaMomentsBaseComponent from '../AhaMomentsBaseComponent'
import { Link, Text } from '../../../../components/BaseComponents'
import { Image } from 'semantic-ui-react'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../../OpenFeature'

const BookkeepingAtHeard = ({
  handleContinue,
  handleBack,
}: {
  handleContinue: () => void
  handleBack: () => void
}) => {
  const shouldShowAltTextForAutoRecon = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableLowerFrictionStatements,
    false
  )
  return (
    <AhaMomentsBaseComponent
      title="How bookkeeping works"
      subtitle="Bookkeeping at Heard"
      description="Our bookkeepers will handle most of the work. However, we may occasionally need your guidance to ensure your books are as accurate as possible."
      media={
        <Image src="https://heard-images.s3.amazonaws.com/assets/heard_bookkeeping.png" />
      }
      features={[
        {
          imgSrc: 'https://heard-images.s3.amazonaws.com/assets/two_hearts.png',
          title: 'We categorize your transactions',
          description:
            'Our bookkeepers are industry experts, focusing exclusively on private practice finances.',
        },
        {
          imgSrc:
            'https://heard-images.s3.amazonaws.com/assets/magnifying_glass_checklist.png',
          title: 'We reconcile your books every month',
          description: shouldShowAltTextForAutoRecon
            ? 'We’ll reconcile your accounts automatically when possible, but occasionally we may need you to upload a bank statement.'
            : 'This is a standard accounting process in which we check your books for accuracy. We use your bank statements for this, which is why it’s important to upload them as soon as possible each month.',
        },
        {
          imgSrc:
            'https://heard-images.s3.amazonaws.com/assets/question_coin.svg',
          title: 'You may need to occasionally clarify transactions',
          description:
            "We'll seek your input if we aren't sure what a transaction is for. This ensures we can catch personal transactions and categorize your books accurately.",
        },
      ]}
      learnMoreItems={[
        {
          title: 'Why do I need to clarify transactions?',
          content: (
            <Text>
              Occasionally, some transactions aren&apos;t descriptive, so we
              need you to clarify what they were for so we can assign the
              correct category. This is common for any personal transactions you
              might have.
            </Text>
          ),
        },
        {
          title: 'What is reconciliation?',
          content: (
            <Text>
              This is a standard practice to ensure all transactions are
              accounted for. We use your bank statements to check that your
              balances match what we have in your books.
              <br />
              <Link
                newPage
                href="https://support.joinheard.com/hc/en-us/articles/23393924789015-How-can-I-ensure-accurate-bookkeeping"
                style={{ textDecoration: 'underline' }}
              >
                Learn more
              </Link>
            </Text>
          ),
        },
        {
          title: 'Why do I need to upload bank statements every month?',
          content: (
            <>
              <Text>
                We reconcile your books by checking them against your bank
                statements. We recommend uploading them as soon as they&apos;re
                available to expedite your monthly bookkeeping.
              </Text>
              <br />
              <Text>
                Depending on your financial institution, you may be able to set
                up{' '}
                <Link
                  newPage
                  href="https://support.joinheard.com/hc/en-us/articles/23569956149399-Bank-Statements-at-Heards"
                  style={{ textDecoration: 'underline' }}
                >
                  <b>automated statement uploads</b>
                </Link>
                . If your account doesn&apos;t support one of these methods,
                you&apos;ll need to upload your statements manually.
              </Text>
            </>
          ),
        },
      ]}
      backButtonText="Back"
      continueButtonText="Continue"
      handleContinue={handleContinue}
      handleBack={handleBack}
    />
  )
}

export default BookkeepingAtHeard
