import { useEffect, useState } from 'react'
import Moment from 'react-moment'
import { Button, Divider, Header } from 'semantic-ui-react'
import { useAppDispatch } from '../../../utils/typeHelpers'

import TaxProfileModal from './TaxProfileModal'
import { fetchAdminUsersDocuments } from '../../../actions/admin/userDocumentsActions'
import moment from 'moment'
import { fetchUserDocumentCategoriesIfNeeded } from '../../../features/Admin/UserDocumentCategories/userDocumentCategories.slice'
import { getUserById } from '../../../selectors/user.selectors'
import { selectCurrentAnnualTaxYear } from '../../../features/Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { useReselector } from '../../../utils/sharedHooks'
import { TaxProfileContent } from './helpers'
import {
  fetchFinancialProfileSnapshotForUser,
  fetchUserSnapshot,
  RecordSnapshotEntityName,
} from '../../../features/Admin/RecordSnapshots/recordSnapshot.slice'
import { getFinancialProfileRecordSnapshotForUserAndYear } from '../../../features/Admin/RecordSnapshots/recordSnapshot.selector'
import { getIsFetching } from '../../../reducers/fetch'
import { DATE_FORMATS } from '../../../utils/dateHelpers'

interface Props {
  userId: number
}

const TaxProfileCard: React.FC<Props> = ({ userId }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const dispatch = useAppDispatch()
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)

  useEffect(() => {
    dispatch(fetchUserDocumentCategoriesIfNeeded())
    dispatch(fetchAdminUsersDocuments(userId))
    dispatch(fetchFinancialProfileSnapshotForUser({ userId }))
  }, [dispatch, userId])

  const user = useReselector(getUserById, userId)

  const currentYearFPSnapshot = useReselector(
    getFinancialProfileRecordSnapshotForUserAndYear,
    { userId, year: currentTaxYear }
  )
  const isLoadingCurrentYearFPSnapshot = useReselector(
    getIsFetching,
    fetchUserSnapshot(userId, RecordSnapshotEntityName.financialProfile)
  )

  if (!user?.financialProfile) {
    return null
  }

  const profile = user.financialProfile
  const thisYear = moment().year()

  const TaxYearSnapshot = () => {
    if (thisYear !== Number(currentTaxYear)) {
      return (
        <div>
          <Header as="h4">{currentTaxYear} Tax Information Snapshot</Header>
          <div style={{ padding: '1em' }}>
            <div style={{ fontStyle: 'italic' }}>
              {!isLoadingCurrentYearFPSnapshot && currentYearFPSnapshot && (
                <TaxProfileContent
                  profile={currentYearFPSnapshot.data}
                  userId={userId}
                />
              )}
            </div>
          </div>
          <Divider />
          <Divider />
        </div>
      )
    }
    return null
  }

  return (
    <>
      <div className="flexbox">
        <Header as="h4">Current Tax Information</Header>
        <Button className="link" onClick={() => setModalOpen(true)}>
          Edit
        </Button>
        {modalOpen && user && (
          <TaxProfileModal
            user={user}
            open={modalOpen}
            close={() => setModalOpen(false)}
          />
        )}
      </div>
      <div style={{ padding: '1em' }}>
        <div style={{ fontStyle: 'italic' }}>
          <p>
            Last Reviewed By Customer:{' '}
            {profile?.taxProfileLastReviewed ? (
              <Moment utc format={DATE_FORMATS.DISPLAY_SHORT}>
                {profile?.taxProfileLastReviewed}
              </Moment>
            ) : (
              'Never'
            )}
          </p>
          <p>
            Last Updated:{' '}
            {profile?.updatedAt ? (
              <Moment format={DATE_FORMATS.DISPLAY_SHORT}>
                {profile?.updatedAt}
              </Moment>
            ) : (
              'Never'
            )}
          </p>
          <TaxProfileContent profile={profile} userId={userId} />
        </div>
      </div>
      <Divider />
      <Divider />
      <TaxYearSnapshot />
    </>
  )
}

export default TaxProfileCard
