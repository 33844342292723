import { useState, useEffect } from 'react'
import Moment from 'react-moment'
import { Header } from 'semantic-ui-react'
import {
  REACTIVATE_USER_SUBSCRIPTION_KEY,
  reactivateUserSubscription,
} from '../../../actions/settings/billingActions'
import { sendUserCancellationMessageZap } from '../../../actions/zapierActions'
import {
  useAnalyticsTrack,
  useAnalyticsView,
} from '../../../features/Amplitude'
import { selectHeardProductSubscription } from '../../../reducers/subscription.slice'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useReselector } from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { Alert, Text, Button, Modal } from '../../BaseComponents'
import { useNavigate } from 'react-router-dom'
import { selectErrorsForKeys } from '../../../reducers/fetch'

export const ReactivatePlanModal = ({
  open,
  close,
  redirectUrl,
}: {
  open: boolean
  close: () => void
  redirectUrl?: string
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const subscription = useReselector(selectHeardProductSubscription)
  const [disabled, setDisabled] = useState(false)
  const track = useAnalyticsTrack()
  const pageView = useAnalyticsView()
  const currentUser = useReselector(getCurrentUser)

  const error = useReselector(selectErrorsForKeys, [
    REACTIVATE_USER_SUBSCRIPTION_KEY,
  ])

  useEffect(() => {
    pageView('reactivate plan modal')
  }, [pageView])

  const reactivatePlan = async () => {
    setDisabled(true)
    track('submit reactivate plan request')

    const result = await reactivateUserSubscription(subscription?.id)(dispatch)
    if (result) {
      sendUserCancellationMessageZap(
        currentUser,
        'reactivated_cancelling_account'
      )
      setDisabled(false)
      close()
      if (redirectUrl) {
        navigate(redirectUrl)
      } else {
        location.reload()
      }
    }
  }

  return (
    <Modal
      size={'small'}
      dimmer={'inverted'}
      className="ReactivatePlanModal"
      open={open}
      onClose={close}
    >
      <Modal.Header>
        <Header as="h2">Reactive your Heard Plan</Header>
      </Modal.Header>
      <Modal.Content>
        {error.length > 0 && <Alert type="error">{error[0].message}</Alert>}
        <Text as="bodyLg">
          You have full access to Heard until your billing cycle ends on{' '}
          <Moment format={DATE_FORMATS.DISPLAY_LONG} unix>
            {subscription?.nextBillingDate}
          </Moment>
          .
        </Text>
        <br />
        <Text as="bodyLg">
          Reactivating your Heard plan means that you will be charged on your
          next billing date.
        </Text>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
        <Button variant="primary" onClick={reactivatePlan} disabled={disabled}>
          Reactivate Plan
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
