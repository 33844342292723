import { useCallback, useState } from 'react'
import { Pagination } from 'semantic-ui-react'
import { PaginationProps } from 'semantic-ui-react/dist/commonjs/addons/Pagination/Pagination'
import {
  OffsetPaginatedResponse,
  PrismaOffsetPagination,
} from '../../../utils/fetchHelpers'
import {
  TypeColumn,
  LoadingView,
  AdminBaseTable,
  TableRowProps,
} from './AdminBaseTable'
import { useAsync } from '../../../utils/sharedHooks'

export interface OffsetPaginatedTableProps<T extends object> {
  columns: TypeColumn<T>[]
  fetchData: (
    pagination: PrismaOffsetPagination
  ) => Promise<OffsetPaginatedResponse<T>>
  tableRowPropsConfig?: TableRowProps<T>
  paginationProps?: PrismaOffsetPagination
}

/*
 * Hook for fetching and rendering a paginated table with offset-based pagination.
 *
 * Offset-based pagination is useful for small datasets, as it allows for fetching
 * any page and sorting by any column. However, it can be inefficient for large datasets
 * as it requires skipping a large number of items to get to a specific page.
 */
export const useOffsetPaginatedTable = <T extends { id: number }>({
  columns,
  fetchData,
  tableRowPropsConfig,
  paginationProps = {
    skip: 0,
    take: 20,
  },
}: OffsetPaginatedTableProps<T>) => {
  const [selectedPage, setSelectedPage] = useState(1)
  const [pagination, setPagination] =
    useState<PrismaOffsetPagination>(paginationProps)

  const [responseData, setResponseData] = useState<OffsetPaginatedResponse<T>>({
    data: [],
    pagination: {
      totalItems: 0,
      ...paginationProps,
    },
  })

  const { loading, error } = useAsync(
    useCallback(async () => {
      const response = await fetchData(pagination)
      setResponseData(response)
      return response
    }, [pagination, fetchData])
  )

  const updateItem = (item: T) => {
    const updatedData = responseData.data.map((dataItem) =>
      dataItem.id === item.id
        ? {
            ...dataItem,
            ...item,
          }
        : dataItem
    )
    setResponseData({
      data: updatedData,
      pagination: responseData.pagination,
    })
  }

  const handlePageChange = (_: unknown, { activePage }: PaginationProps) => {
    if (!activePage) {
      return
    }

    const activePageNumber = Number(activePage)
    setPagination((prev) => ({
      ...prev,
      skip: (activePageNumber - 1) * pagination.take,
      take: pagination.take,
    }))

    setSelectedPage(activePageNumber)
  }

  const resetPaginationAndActivePage = () => {
    setPagination({
      ...pagination,
      skip: 0,
    })
    setSelectedPage(1)
  }

  const renderTable = () => {
    if (loading) {
      return <LoadingView />
    }
    return (
      <>
        <Pagination
          activePage={selectedPage}
          totalPages={Math.ceil(
            responseData.pagination.totalItems / Math.abs(pagination.take)
          )}
          onPageChange={handlePageChange}
        />
        <div style={{ overflow: 'auto', margin: '10px 10px 10px 0px' }}>
          <AdminBaseTable
            columns={columns}
            data={responseData.data}
            tableRowPropsConfig={tableRowPropsConfig}
          />
        </div>
      </>
    )
  }

  return {
    renderTable,
    updateItem,
    fetchError: error,
    resetPaginationAndActivePage,
  }
}
