import React, { useEffect, useState } from 'react'

// BL
import { UserDocument } from '../userDocuments.slice'
import { documentsSearchBar as copy } from '../copyConstants'

// UI
import { Input } from '../../../components/BaseComponents'

export interface DocumentSearchBarOnChangeData {
  filtered: UserDocument[]
  original: UserDocument[]
  value: string
}

export interface DocumentSearchBarProps {
  documents: UserDocument[]
  onChange: (_data: DocumentSearchBarOnChangeData) => void
}

const DocumentsSearchBar: React.FC<DocumentSearchBarProps> = ({
  documents,
  onChange,
}) => {
  const [value, setValue] = useState('')

  // When value changes, filter documents and call onChange prop above
  useEffect(() => {
    const filtered = documents.filter((doc) => {
      const fileName = doc.awsFileName.toLowerCase()
      const description = doc.fileDescription?.toLowerCase()
      const query = value.toLocaleLowerCase()
      return fileName.includes(query) || description?.includes(query)
    })

    onChange({
      filtered,
      original: documents,
      value,
    })
  }, [value, onChange, documents])

  return (
    <div className="search-bar">
      <Input
        leftIcon="search"
        rightIcon={value.length > 0 ? 'x' : undefined}
        type="search"
        onChange={(value: string) => setValue(value)}
        placeholder={copy.static.placeholderText}
        value={value}
        onRightIconClick={() => setValue('')}
      />
    </div>
  )
}

export default DocumentsSearchBar
