import { useMemo, useState } from 'react'
import { Icon, Loader } from 'semantic-ui-react'

import { adminUpdateUserTransaction } from '../../../actions/admin/transactionActions'
import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import { Button, Modal } from '../../BaseComponents'
import { useAppDispatch } from '../../../utils/typeHelpers'

export const useToggleTransactionConfirmed = ({
  transaction,
  onUpdated = () => undefined,
}: {
  transaction: Transaction
  onUpdated?: () => void
}) => {
  const dispatch = useAppDispatch()
  const [isUpdating, setUpdating] = useState(false)
  const [open, setOpen] = useState(false)

  const unconfirming = useMemo(
    () => Boolean(transaction.confirmedAt),
    [transaction]
  )
  const ineligible = useMemo(
    () =>
      !transaction.type ||
      (transaction.type === 'business' && !transaction.transactionCategoryId),
    [transaction]
  )

  const acceptTransaction = async (confirmed: boolean) => {
    const confirmedAt = confirmed ? Date.now() : null
    setUpdating(true)
    const data = {
      confirmedAt,
      requestedClarificationAt: null,
      notifiedRequestedClarificationAt: null,
    }

    await adminUpdateUserTransaction(transaction.id, data)(dispatch)
    setUpdating(false)
    onUpdated()
  }

  const renderModal = () => (
    <Modal onClose={() => setOpen(false)} open={open} size="tiny">
      <Modal.Header>
        {unconfirming ? 'Unconfirm ' : 'Confirm '} Transaction
      </Modal.Header>
      <Modal.Content>
        Are you sure you want to {unconfirming ? 'unconfirm' : 'confirm'} this
        transaction? This action creates new journal entries.
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => setOpen(false)}
          variant="actionLink"
          style={{ marginRight: 36 }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            acceptTransaction(!unconfirming)
            setOpen(false)
          }}
        >
          {unconfirming ? 'Unconfirm' : 'Confirm'}
        </Button>
      </Modal.Actions>
    </Modal>
  )
  return {
    renderModal,
    openModal: () => setOpen(true),
    isUpdating,
    unconfirming,
    ineligible,
  }
}

const ConfirmTransactionButton = ({
  transaction,
}: {
  transaction: Transaction
}) => {
  const { renderModal, isUpdating, openModal, unconfirming, ineligible } =
    useToggleTransactionConfirmed({
      transaction,
    })

  if (isUpdating) {
    return <Loader active inline />
  }

  return (
    <>
      {renderModal()}
      {unconfirming ? (
        <span>
          <Icon name="check circle" color="green" />
          <br />
          <Button
            onClick={() => openModal()}
            style={{ color: '#B3B3B3 !important', marginRight: 6 }}
            variant="link"
          >
            Unconfirm
          </Button>
        </span>
      ) : (
        <Button
          disabled={ineligible}
          onClick={() => openModal()}
          style={{ marginRight: 6 }}
          variant="link"
        >
          Confirm
        </Button>
      )}
    </>
  )
}

export default ConfirmTransactionButton
