export enum UserDocumentCategoryIdentifier {
  draftReturnForm1040 = 'draft_return_form_1040',
  draftReturnForm1120s = 'draft_return_form_1120_s',
  finalReturnForm1040 = 'final_return_form_1040',
  finalReturnForm1120s = 'final_return_form_1120_s',
  draftReturn = 'draft_return',
  finalReturn = 'final_return',
  extensionConfirmation = 'extension_confirmation',
  extensionConfirmationForm4868 = 'extension_confirmation_form_4868',
  extensionConfirmationForm7004 = 'extension_confirmation_form_7004',
  form1099 = 'form_1099',
  qtePayment = 'qte_payment',
  q1Payment = 'q1_payment',
  q2Payment = 'q2_payment',
  q3Payment = 'q3_payment',
  q4Payment = 'q4_payment',
  annualTaxPaymentReceipt = 'annual_tax_payment_receipt',
  carRegistration = 'car_registration',
  charity = 'charity',
  extensionTaxOrganizer = 'extension_tax_organizer',
  taxOrganizerForm1040 = 'tax_organizer_form_1040',
  taxOrganizerForm1120s = 'tax_organizer_form_1120_s',
  form1095a = 'form_1095_a',
  form1095b = 'form_1095_b',
  form1095c = 'form_1095_c',
  form1095abc = 'form_1095_a_b_c',
  form1098 = 'form_1098',
  form1098e = 'form_1098_e',
  form1098t = 'form_1098_t',
  form1099b = 'form_1099_b',
  form1099c = 'form_1099_c',
  form1099div = 'form_1099_div',
  form1099g = 'form_1099_g',
  form1099int = 'form_1099_int',
  form1099k = 'form_1099_k',
  form1099misc = 'form_1099_misc',
  form1099nec = 'form_1099_nec',
  form1099r = 'form_1099_r',
  form1099sa = 'form_1099_sa',
  form1099ssa = 'form_1099_ssa',
  form5498 = 'form_5498',
  form941 = 'form_941',
  formW2 = 'form_w2',
  formW2Spouse = 'form_w2_spouse',
  formW2g = 'form_w2g',
  formW3 = 'form_w3',
  homeDepreciationSchedule = 'home_depreciation_schedule',
  homePurchaseDocuments = 'home_purchase_documents',
  homeSaleDocuments = 'home_sale_documents',
  mileageTracker = 'mileage_tracker',
  offerInCompromise = 'offer_in_compromise',
  officialGovernmentId = 'official_government_id',
  profitAndLoss = 'profit_and_loss',
  propertyTaxDocuments = 'property_tax_documents',
  rentalDocuments = 'rental_documents',
  scheduleK1 = 'schedule_k_1',
  spouseOfficialGovernmentId = 'spouse_official_government_id',
  cp261Notice = 'cp261_notice',
  einDocuments = 'ein_documents',
  form2553 = 'form_2553',
  sCorpDocuments = 's_corp_documents',
  taxNotice = 'tax_notice',
  businessIncorporation = 'business_incorporation',
  stateRegistration = 'state_registration',
  taxConsultationTranscript = 'tax_consultation_transcript',
}
