import { Link } from '../../../components/BaseComponents'

export const shared = {
  profileExplainer:
    'Please look over your Tax Information and confirm any changes to your business. This helps us calculate accurate tax estimates.',
}

export const taxProfilePanel = {
  taxProfileBreadcrumbText: 'Tax Information',
  taxCenterBreadcrumbText: 'Taxes',
  header: 'Update your Tax Information',
  lockedProfileContent:
    'Your Tax Information is currently locked as we calculate your quarterly tax estimate. Should you need to make changes, please reach out to our team directly.',
}

export const taxProfileForm = {
  navigateAwayConfirmation:
    'Are you sure you want to leave? Any changes will be lost.',
  partOneHeader: 'How you file',
  partTwoHeader: 'Where you file',
  partThreeHeader: 'Additional Resources',
  validationErrorHeader: 'There were some errors with your submission',
  validationErrorContent: (numErrors: number) =>
    `Please review ${
      numErrors === 1 ? '1 error' : `${numErrors} errors`
    } above.`,
  saveErrorHeader: 'There was an error saving the form',
  saveErrorContent: (message: string) => `${message}. Please try again.`,
  saveSuccessHeader: 'Your data was submitted successfully',
  saveSuccessContent: 'Redirecting you to the Tax Center momentarily ...',
  saveButtonText: 'Save Changes',
  cancelButtonText: 'Cancel',
  zeroDollarIfNonePlaceholder: '$0 if none',
  fieldGroups: {
    filingStatus: {
      title: 'What is your filing status?',
      tooltip: () => (
        <Link
          newPage
          href="https://support.joinheard.com/hc/en-us/articles/4763580296727"
        >
          Which one am I?
        </Link>
      ),
      single: 'Single',
      marriedFilingJointly: 'Married Filing Jointly',
      marriedFilingSeparately: 'Married Filing Separately',
      headOfHousehold: 'Head of Household',
      qualifyingWidow: 'Qualifying Widow',
    },
    numberOfDependents: {
      title: 'Number of Dependents',
      tooltip: '',
      placeholder: '0, 1, 2, 3 etc',
    },
    taxEntityType: {
      title: 'Tax Entity Type',
      placeholder: 'Tax Entity Type',
      tooltip: () => (
        <Link
          newPage
          href="https://support.joinheard.com/hc/en-us/articles/4761703149463"
        >
          How do I know what to pick?
        </Link>
      ),
    },
    w2IncomeInCents: {
      title: (activeTaxYear: string) =>
        `Your YTD W-2 Income (${activeTaxYear})`,
      tooltip:
        'This should represent any YTD employee wages you have earned this year through an employer. If you are fully self-employed and therefore do not hold a W-2 position, this number will be $0. If you are an S corporation and therefore pay yourself as an employee, this number should include your reasonable salary, along with any other W-2 income you earn (if applicable).',
      placeholder: '$',
    },
    otherIndividualIncomeInCents: {
      title: (activeTaxYear: string) =>
        `Your YTD 1099 Income (${activeTaxYear})`,
      tooltip:
        'This should represent any YTD income not from your practice or an employer',
      placeholder: '$',
    },
    federalWithholdingInCents: {
      title: (activeTaxYear: string) =>
        `Year-to-Date Federal Withholding (${activeTaxYear})`,
      tooltip: () => (
        <Link
          newPage
          href="https://support.joinheard.com/hc/en-us/articles/4761711959063"
        >
          Where do I find this?
        </Link>
      ),
    },
    stateWithholdingInCents: {
      title: (activeTaxYear: string) =>
        `Year-to-Date State Withholding (${activeTaxYear})`,
      tooltip: () => (
        <Link
          newPage
          href="https://support.joinheard.com/hc/en-us/articles/4761711959063"
        >
          Where do I find this?
        </Link>
      ),
    },
    deductionInCents: {
      title: 'Which deduction would you like to take?',
      tooltip: () => (
        <Link
          newPage
          href="https://support.joinheard.com/hc/en-us/articles/4761707432983"
        >
          What does this mean?
        </Link>
      ),
      standard: 'Standard',
      itemized: 'Itemized',
      itemizedPlaceholder: '$',
    },
    spouse: {
      placeholder: 'Enter a value',
      placeholderCurrency: '$',
      firstName: "Spouse's First Name",
      lastName: "Spouse's Last Name",
      ytdW2: (activeTaxYear: string) =>
        `Spouse's YTD W-2 Income (${activeTaxYear})`,
      ytdW2Tooltip:
        'This should represent YTD employee wages your spouse has earned this year through an employer.',
      indivIncome: (activeTaxYear: string) =>
        `Spouse's YTD 1099 Income (${activeTaxYear})`,
      indivIncomeTooltip:
        'This should represent YTD 1099 income your spouse has earned this year.',
      federalWithholdingInCents: (activeTaxYear: string) =>
        `Spouse's Year-to-Date Federal Withholding (${activeTaxYear})`,
      stateWithholdingInCents: (activeTaxYear: string) =>
        `Spouse's Year-to-Date State Withholding (${activeTaxYear})`,
      withholdingTooltip: () => (
        <Link
          newPage
          href="https://support.joinheard.com/hc/en-us/articles/4761711959063"
        >
          Where do I find this?
        </Link>
      ),
      paystub: "Please enter your spouse's pay stub",
      uploadPaystubButtonText: 'Upload Pay Stub',
      uploadNewerPaystubButtonText: 'Upload a Newer Pay Stub',
      paystubFilenameFallback: 'View Pay Stub >',
    },
    relocation: {
      relocatedThisQuarter: 'Did you move states this quarter?',
      relocatedPreviousState: 'Which state did you leave or move away from?',
      dropdownPlaceholder: 'Choose a state',
      relocatedPreviousStateAt: 'When did you move?',
      verifyHomeState: 'Please verify your home state',
      verifyNewHomeState:
        'Which state did you move to? We will now consider this your current home state.',
    },
    multistate: {
      dropdownPlaceholder: 'Choose an option',
      sawMultistateClientsThisQuarter:
        'Do you see clients in states other than your home state?',
      incomePerStateSubtitle: () => (
        <div>
          This number helps us determine how much you owe to each state based on
          your income.{' '}
          {
            <Link
              newPage
              href="https://support.joinheard.com/hc/en-us/articles/4763301299991"
            >
              How do I calculate this?
            </Link>
          }
        </div>
      ),
      incomePerStateTitle: 'Please share the % of income made in each state',
      tooltip: '',
    },
    priorYearTaxReturn: {
      buttonText: 'Upload Tax Return',
      taxReturnContent:
        'Great news! It looks like we have your Prior Year Tax Returns (2021) on file already.',
      missingTaxReturnContent:
        'It looks like we are missing your Prior Year Tax Returns (2021). You can upload it here:',
    },
  },
}
