import { useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import {
  UPDATE_EMAIL_KEY,
  updateUserPassword,
  VERIFY_PASSWORD_KEY,
  verifyPassword,
} from '../../../actions/authActions'
import {
  Alert,
  Button,
  FormikInput,
  getFieldName,
  GridRowColumn,
  makePasswordSchema,
  makeReqStringSchema,
  Modal,
  Text,
} from '../../BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { selectErrorsForKeys } from '../../../reducers/fetch'
import { useAppDispatch } from '../../../utils/typeHelpers'

const ChangePassword = () => {
  const dispatch = useAppDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const errors = useReselector(selectErrorsForKeys, [
    VERIFY_PASSWORD_KEY,
    UPDATE_EMAIL_KEY,
  ])

  const formik = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
    onSubmit: async ({ password, newPassword }) => {
      const passwordResponse = await verifyPassword({ password })(dispatch)

      if (!passwordResponse) {
        return
      }

      const updatePasswordResponse = await updateUserPassword({
        newPassword,
      })(dispatch)

      if (updatePasswordResponse) {
        setModalOpen(false)
        // Reload page to properly load auth changes
        location.reload()
      }
    },
  })

  return (
    <FormikProvider value={formik}>
      <Button variant="link" onClick={() => setModalOpen(true)}>
        Change password
      </Button>
      <Modal
        size="tiny"
        dimmer="inverted"
        className="changePasswordModal"
        open={modalOpen}
        closeIcon
        onClose={() => setModalOpen(false)}
      >
        <Modal.Header>Change Your Password</Modal.Header>
        <Modal.Content>
          <Grid>
            {Boolean(errors.length) && (
              <GridRowColumn>
                <Alert type="error">{errors[0].message}</Alert>
              </GridRowColumn>
            )}
            <GridRowColumn>
              <Text>
                Use at least 8 characters. Don’t use a password from another
                site, and don’t include any personal information such as your
                name or date of birth.
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <FormikInput
                name={getFieldName<typeof formik.values>('password')}
                label="Current Password"
                required
                fullWidth
                schema={makeReqStringSchema()}
                type="password"
              />
            </GridRowColumn>

            <GridRowColumn>
              <FormikInput
                name={getFieldName<typeof formik.values>('newPassword')}
                label="New Password"
                description="Your password needs to have more than 8 characters, and must include a number and a symbol."
                required
                fullWidth
                schema={makePasswordSchema()}
                type="password"
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikInput
                name={getFieldName<typeof formik.values>('newPasswordConfirm')}
                label="Confirm New Password"
                required
                fullWidth
                schema={makeReqStringSchema({
                  field: 'confirm password',
                }).test(
                  'Password Test',
                  'Passwords do not match',
                  (confirmPassword) =>
                    confirmPassword === formik.values.newPassword
                )}
                type="password"
              />
            </GridRowColumn>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button variant="secondary" onClick={() => setModalOpen(false)}>
            Close
          </Button>
          <Button disabled={!formik.isValid} onClick={formik.submitForm}>
            Change Password
          </Button>
        </Modal.Actions>
      </Modal>
    </FormikProvider>
  )
}

export default ChangePassword
