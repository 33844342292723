import axios from 'axios'
import { keyBy } from 'lodash'

import { fetchWrapper } from '../../reducers/fetch'
import {
  BookkeepingReport,
  BookkeepingReportStatus,
  receiveAllBookkeepingReports,
  receiveSingleBookkeepingReport,
} from '../../reducers/finances/bookkeepingReportsReducer'

export const fetchAdminUsersBookkeepingReports = (userId: number | string) =>
  fetchWrapper({
    defaultErrorMessage:
      'There was an error fetching all user bookkeeping reports.',
    fetchFunction: (dispatch) =>
      axios
        .get<{
          reports: BookkeepingReport[]
          financialProfileId: number
        }>(`/finances/api/v1/admin/bookkeeping_reports/${userId}`)
        .then((json) => {
          dispatch(receiveAllBookkeepingReports(keyBy(json.data.reports, 'id')))
          return json.data
        }),
  })

/*
Fetch all reports
*/

export const fetchAllUsersBookkeepingReports = () =>
  fetchWrapper({
    defaultErrorMessage:
      'There was an error fetching all user bookkeeping reports.',
    fetchFunction: (dispatch) => {
      return axios
        .get<BookkeepingReport[]>('/finances/api/v1/admin/bookkeeping_reports')
        .then((json) => {
          dispatch(receiveAllBookkeepingReports(keyBy(json.data, 'id')))
          return json.data
        })
    },
  })

////////// UPDATING USER'S BOOK STATUS

export const updateUsersBookkeepingReportStatus = (
  reportId: string,
  data: {
    status: BookkeepingReportStatus
    bookkeepingReportId: string
  }
) =>
  fetchWrapper({
    defaultErrorMessage:
      'There was an error fetching all user bookkeeping reports.',
    fetchFunction: (dispatch) =>
      axios
        .post<BookkeepingReport>(
          `/finances/api/v1/admin/bookkeeping_reports/${reportId}/statuses`,
          data
        )
        .then((json) => {
          dispatch(receiveSingleBookkeepingReport(json.data))
          return json.data
        }),
  })

export const createBookkeepingReportsFromYear = ({
  userId,
  year,
}: {
  userId: number
  year: string
}) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error generating bookkeeping reports.',
    defaultValue: false,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<{
        reports: BookkeepingReport[]
        financialProfileId: string
      }>(
        `/finances/api/v1/admin/bookkeeping_reports/${userId}/generate_from_year/${year}`
      )

      dispatch(receiveAllBookkeepingReports(keyBy(json.data.reports, 'id')))
      return json.data
    },
  })
