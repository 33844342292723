import { useCallback, useEffect, useMemo, useState } from 'react'
import { Container } from 'semantic-ui-react'
import moment from 'moment'

import {
  FETCH_CONTRACTORS_KEY,
  fetchContractors,
  POST_CONTACTOR_PAYMENT_KEY,
  postPayContractor,
} from '../../payrollActions'

import PayrollLoading from '../PayrollLoading'
import ContPayrollSelectContractorTab from './ContPayrollSelectContractorTab'
import ContPayrollPaymentInputTab from './ContPayrollPaymentInputTab'
import ContPayrollReviewTab from './ContPayrollReviewTab'
import ContPayrollConfirmationTab from './ContPayrollConfirmationTab'
import { selectContractorByUuid } from '../../payroll.selectors'
import { Card } from '../../../../components/BaseComponents'
import {
  invalidateFetch,
  selectIsFetchingForKeys,
} from '../../../../reducers/fetch'
import { useReselector } from '../../../../utils/sharedHooks'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const ContractorPayroll = () => {
  const dispatch = useAppDispatch()
  const [currentTab, setCurrentTab] = useState(0)
  const [selectedUuid, setSelectedUuid] = useState<string>()
  const [payDate, setPayDate] = useState('')
  const [hours, setHours] = useState('0.00')
  const [bonus, setBonus] = useState('0.00')
  const [reimbursement, setReimbursement] = useState('0.00')
  const [wage, setWage] = useState('0.00')
  const contractor = useReselector(selectContractorByUuid, selectedUuid)
  const loading = useReselector(selectIsFetchingForKeys, [
    FETCH_CONTRACTORS_KEY,
    POST_CONTACTOR_PAYMENT_KEY,
  ])

  useEffect(() => {
    fetchContractors()(dispatch)
  }, [dispatch])

  // Once the contractor is selected set values to defaults
  useEffect(() => {
    setBonus('0.00')
    setReimbursement('0.00')
    setWage('0.00')
    setHours(contractor?.wage_type === 'Hourly' ? '40.00' : '0')
  }, [contractor?.uuid, contractor?.wage_type])

  const clearPostError = useCallback(() => {
    dispatch(invalidateFetch(POST_CONTACTOR_PAYMENT_KEY))
  }, [dispatch])

  const submitPayroll = useCallback(async () => {
    if (!selectedUuid || !contractor?.uuid) {
      return
    }

    // Note: This call is REALLY slow
    const res = await postPayContractor({
      date: moment(payDate).format(DATE_FORMATS.GUSTO_SUBMIT),
      contractor_uuid: contractor.uuid,
      wage: contractor?.wage_type === 'Fixed' ? Number(wage) : undefined,
      hours: contractor?.wage_type === 'Hourly' ? Number(hours) : undefined,
      bonus: Number(bonus),
      reimbursement: Number(reimbursement),
    })(dispatch)

    if (res) {
      setCurrentTab((tab) => tab + 1)
    }
  }, [
    bonus,
    contractor?.uuid,
    contractor?.wage_type,
    dispatch,
    hours,
    payDate,
    reimbursement,
    selectedUuid,
    wage,
  ])

  const content = useMemo(() => {
    if (currentTab === 0) {
      if (loading) {
        return <PayrollLoading text="Fetching Payroll" />
      } else {
        return (
          <ContPayrollSelectContractorTab
            goForward={(selectedUuid) => {
              setSelectedUuid(selectedUuid)
              setCurrentTab(1)
            }}
            selectedUuid={selectedUuid}
          />
        )
      }
    }

    if (!selectedUuid) {
      return null
    }

    if (currentTab === 1) {
      return (
        <ContPayrollPaymentInputTab
          goForward={({ hours, bonus, reimbursement, payDate, wage }) => {
            if (hours) {
              setHours(hours)
            }
            if (wage) {
              setWage(wage)
            }
            setBonus(bonus)
            setReimbursement(reimbursement)
            setPayDate(payDate)
            setCurrentTab(2)
          }}
          goBack={() => setCurrentTab(0)}
          contractorUuid={selectedUuid}
          payDate={payDate}
          bonus={bonus}
          reimbursement={reimbursement}
          hours={hours}
          wage={wage}
        />
      )
    } else if (currentTab === 2) {
      if (loading) {
        return (
          <PayrollLoading
            text="We’re submitting your payroll..."
            subtext="Please do not close or refresh this page"
          />
        )
      } else {
        return (
          <ContPayrollReviewTab
            goBack={() => {
              clearPostError()
              setCurrentTab(1)
            }}
            goForward={submitPayroll}
            contractorUuid={selectedUuid}
            payDate={payDate}
            reimbursement={reimbursement}
            bonus={bonus}
            hours={hours}
            wage={wage}
          />
        )
      }
    } else {
      return (
        <ContPayrollConfirmationTab
          contractorUuid={selectedUuid}
          payDate={payDate}
          hours={hours}
          bonus={bonus}
          reimbursement={reimbursement}
          wage={wage}
        />
      )
    }
  }, [
    bonus,
    clearPostError,
    currentTab,
    hours,
    loading,
    payDate,
    reimbursement,
    selectedUuid,
    submitPayroll,
    wage,
  ])

  return (
    <Container id="run-payroll">
      <Card>{content}</Card>
    </Container>
  )
}

export default ContractorPayroll
