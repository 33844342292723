import { useMemo, useState } from 'react'
import { Container, Divider, Grid } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import { FormikProvider, useFormik } from 'formik'

import {
  UPDATE_FINANCIAL_PROFILE_KEY,
  updateFinancialProfile,
} from '../../../../../../actions/financialProfileActions'
import {
  Button,
  Card,
  FormikDateInput,
  FormikInput,
  getFieldName,
  GridRowColumn,
  makeDateSchema,
  makeReqEmailSchema,
  makeReqPhoneNumberSchema,
  makeReqStringSchema,
  makeSsnSchema,
  Text,
} from '../../../../../../components/BaseComponents'
import { getCurrentUser } from '../../../../../../selectors/user.selectors'
import { useReselector } from '../../../../../../utils/sharedHooks'
import {
  DATE_FORMATS,
  DATE_FORMATS_LUXON,
} from '../../../../../../utils/dateHelpers'
import { Form1040DetailsProps, isForm1040DetailsScreen } from '.'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../../taxChecklist.selectors'
import { TaxListQuestionId } from '../../service'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import { FinancialProfile } from '../../../../../../reducers/auth/userReducer'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { UPSERT_USER_TAX_QUESTIONNAIRE_KEY } from '../../taxChecklistQuestion.actions'
import { useSetScreen } from '../../../../../../components/FormFlow/formFlow'
import ReadOnlyForm from '../../Shared/ReadOnlyForm'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'
import { select1040FormForYear } from '../../../annualTaxFilingForms.selector'

export const spouseQuestionIds = [
  TaxListQuestionId.spouse_first_name,
  TaxListQuestionId.spouse_last_name,
  TaxListQuestionId.spouse_date_of_birth,
  TaxListQuestionId.spouse_ssn,
  TaxListQuestionId.spouse_occupation,
  TaxListQuestionId.spouse_phone,
  TaxListQuestionId.spouse_email,
]

const SpousePanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
  taxYear,
}: Form1040DetailsProps) => {
  const dispatch = useAppDispatch()
  const form1040 = useReselector(select1040FormForYear, taxYear)
  const user = useReselector(getCurrentUser)
  const financialProfile = user?.financialProfile
  //const spouseSsnTokenId = useReselector(getSkyflowSpouseSsnToken)?.skyflow_id
  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    UPDATE_FINANCIAL_PROFILE_KEY(financialProfile?.id),
    // UPDATE_SKYFLOW_TOKEN_KEY,
    // CREATE_SKYFLOW_TOKEN_KEY,
  ])
  const { currentScreen } = useSetScreen()
  const ssnMask = '***-**-****'

  const questionSpouseFirstName = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.first_name,
    taxYear
  )
  const responseSpouseFirstName = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.spouse_first_name,
    form1040?.id
  )
  const questionSpouseLastName = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.last_name,
    taxYear
  )
  const responseSpouseLastName = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.spouse_last_name,
    form1040?.id
  )
  const questionSpouseDob = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.date_of_birth,
    taxYear
  )
  const responseSpouseDob = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.spouse_date_of_birth,
    form1040?.id
  )
  const questionSpouseSsn = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.ssn,
    taxYear
  )
  const responseSpouseSsn = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.spouse_ssn,
    form1040?.id
  )
  const questionSpouseOccupation = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.occupation,
    taxYear
  )
  const responseSpouseOccupation = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.spouse_occupation,
    form1040?.id
  )
  const questionSpousePhone = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.spouse_phone,
    taxYear
  )
  const responseSpousePhone = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.spouse_phone,
    form1040?.id
  )
  const questionSpouseEmail = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.spouse_email,
    taxYear
  )
  const responseSpouseEmail = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.spouse_email,
    form1040?.id
  )

  // const createOrUpdateSsn = (
  //   spouseFirstName: string,
  //   spouseLastName: string,
  //   spouseSsn: string
  // ) => {
  //   if (user) {
  //     if (spouseSsnTokenId && spouseSsn) {
  //       return updateSkyflowToken({
  //         skyflowId: spouseSsnTokenId,
  //         table: 'social_security_numbers',
  //         token: {
  //           skyflow_id: spouseSsnTokenId,
  //           admin_id: user.ownerId,
  //           first_name: spouseFirstName,
  //           last_name: spouseLastName,
  //           user_id: user.id,
  //           relationship: ['spouse'],
  //           social_security_number: spouseSsn,
  //         },
  //       })(dispatch)
  //     } else {
  //       return createSkyflowToken({
  //         table: 'social_security_numbers',
  //         token: {
  //           admin_id: user.ownerId,
  //           first_name: spouseFirstName,
  //           last_name: spouseLastName,
  //           user_id: user.id,
  //           relationship: ['spouse'],
  //           social_security_number: spouseSsn,
  //         },
  //       })(dispatch)
  //     }
  //   }
  //   return null
  // }
  const isInitialValid = Boolean(
    (responseSpouseFirstName?.[0]?.value ||
      financialProfile?.spouseFirstName) &&
      (responseSpouseLastName?.[0]?.value ||
        financialProfile?.spouseLastName) &&
      (responseSpouseDob?.[0]?.value || financialProfile?.spouseDateOfBirth) &&
      (responseSpouseOccupation?.[0]?.value ||
        financialProfile?.spouseOccupation) &&
      (responseSpousePhone?.[0]?.value ||
        financialProfile?.spousePhoneNumber) &&
      (responseSpouseEmail?.[0]?.value ||
        financialProfile?.spouseEmailAddress) &&
      responseSpouseSsn?.[0]?.value //|| spouseSsnTokenId )
  )

  const formik = useFormik({
    initialValues: {
      spouseFirstName:
        responseSpouseFirstName?.[0]?.value ??
        financialProfile?.spouseFirstName,
      spouseLastName:
        responseSpouseLastName?.[0]?.value ?? financialProfile?.spouseLastName,
      spouseDob:
        responseSpouseDob?.[0]?.value ??
        (financialProfile?.spouseDateOfBirth
          ? DateTime.fromISO(financialProfile.spouseDateOfBirth).toFormat(
              DATE_FORMATS_LUXON.INPUT
            )
          : undefined),
      spouseSsn: null,
      spouseOccupation:
        responseSpouseOccupation?.[0]?.value ??
        financialProfile?.spouseOccupation,
      confirmSpouseSsn: null,
      spousePhone:
        responseSpousePhone?.[0]?.value ?? financialProfile?.spousePhoneNumber,
      spouseEmail:
        responseSpouseEmail?.[0]?.value ?? financialProfile?.spouseEmailAddress,
    },
    isInitialValid,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      const fpUpdates: Partial<FinancialProfile> = {}
      if (
        typeof values.spouseFirstName === 'string' &&
        values.spouseFirstName !== responseSpouseFirstName?.[0]?.value
      ) {
        responseData.push({
          id: responseSpouseFirstName?.[0]?.id,
          value: values.spouseFirstName,
          annualTaxFilingFormId: form1040?.id,
          questionId: TaxListQuestionId.spouse_first_name,
        })
        fpUpdates.spouseFirstName = values.spouseFirstName
      }
      if (
        typeof values.spouseLastName === 'string' &&
        values.spouseLastName !== responseSpouseLastName?.[0]?.value
      ) {
        responseData.push({
          id: responseSpouseLastName?.[0]?.id,
          value: values.spouseLastName,
          annualTaxFilingFormId: form1040?.id,
          questionId: TaxListQuestionId.spouse_last_name,
        })
        fpUpdates.spouseLastName = values.spouseLastName
      }
      if (values.spouseDob !== responseSpouseDob?.[0]?.value) {
        responseData.push({
          id: responseSpouseDob?.[0]?.id,
          value: values.spouseDob,
          annualTaxFilingFormId: form1040?.id,
          questionId: TaxListQuestionId.spouse_date_of_birth,
        })
        fpUpdates.spouseDateOfBirth =
          typeof values.spouseDob === 'string'
            ? DateTime.fromFormat(
                values.spouseDob,
                DATE_FORMATS_LUXON.INPUT
              ).toISO()
            : undefined
      }
      if (
        values.spouseSsn &&
        typeof values.spouseSsn === 'string' &&
        typeof values.spouseFirstName === 'string' &&
        typeof values.spouseLastName === 'string'
      ) {
        // const skyflowId = await createOrUpdateSsn(
        //   values.spouseFirstName,
        //   values.spouseLastName,
        //   values.spouseSsn
        // )
        // if (skyflowId) {
        //   responseData.push({
        //     id: responseSpouseSsn?.[0]?.id,
        //     value: skyflowId,
        //     annualTaxFilingFormId: form1040?.id,
        //     questionId: TaxListQuestionId.spouse_ssn,
        //   })
        // } else {
        //   return false
        // }
      }
      if (
        typeof values.spouseOccupation === 'string' &&
        values.spouseOccupation !== responseSpouseOccupation?.[0]?.value
      ) {
        responseData.push({
          id: responseSpouseOccupation?.[0]?.id,
          value: values.spouseOccupation,
          annualTaxFilingFormId: form1040?.id,
          questionId: TaxListQuestionId.spouse_occupation,
        })
        fpUpdates.spouseOccupation = values.spouseOccupation
      }
      if (
        typeof values.spousePhone === 'string' &&
        values.spousePhone !== responseSpousePhone?.[0]?.value
      ) {
        responseData.push({
          id: responseSpousePhone?.[0]?.id,
          value: values.spousePhone,
          annualTaxFilingFormId: form1040?.id,
          questionId: TaxListQuestionId.spouse_phone,
        })
        fpUpdates.spousePhoneNumber = values.spousePhone
      }
      if (
        typeof values.spouseEmail === 'string' &&
        values.spouseEmail !== responseSpouseEmail?.[0]?.value
      ) {
        responseData.push({
          id: responseSpouseEmail?.[0]?.id,
          value: values.spouseEmail,
          annualTaxFilingFormId: form1040?.id,
          questionId: TaxListQuestionId.spouse_email,
        })
        fpUpdates.spouseEmailAddress = values.spouseEmail
      }
      if (financialProfile?.id && Object.keys(fpUpdates).length > 0) {
        dispatch(updateFinancialProfile(financialProfile.id, fpUpdates))
      }
      const success = await goToNextStep(
        responseData,
        isForm1040DetailsScreen(currentScreen) ? currentScreen : null
      )
      if (success) {
        // skipcq: JS-0357 - complaining about using before defined
        setEditing(false)
      }

      return true
    },
  })

  const { values, submitForm, isValid, resetForm, isSubmitting } = formik
  const [editing, setEditing] = useState(!isValid)

  const continueSurvey = async () => {
    if (isValid) {
      const success = await submitForm()
      if (success) {
        await goToNextStep(null, nextScreen ?? null)
      }
    }
  }

  const readOnly = useMemo(() => {
    return [
      { ...questionSpouseFirstName, responseText: values.spouseFirstName },
      { ...questionSpouseLastName, responseText: values.spouseLastName },
      { ...questionSpouseDob, responseText: values.spouseDob },
      {
        ...questionSpouseSsn,
        responseText:
          values.spouseSsn || responseSpouseSsn?.[0]?.value // || spouseSsnTokenId
            ? ssnMask
            : '',
      },
      { ...questionSpouseOccupation, responseText: values.spouseOccupation },
      { ...questionSpousePhone, responseText: values.spousePhone },
      { ...questionSpouseEmail, responseText: values.spouseEmail },
    ]
  }, [
    questionSpouseFirstName,
    questionSpouseLastName,
    questionSpouseDob,
    questionSpouseSsn,
    questionSpouseOccupation,
    questionSpousePhone,
    questionSpouseEmail,
    //spouseSsnTokenId,
    responseSpouseSsn,
    values,
  ])

  return (
    // skipcq: JS-0415 - complaining about too many nested components
    <>
      <FormikProvider value={formik}>
        <Container style={{ marginTop: 56 }} text textAlign="left">
          <Grid>
            <GridRowColumn>
              <Text as="display2" textAlign="center">
                Your Spouse
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text as="bodyLg">
                Please look over the following and update anything that has
                changed. These answers will appear on your tax return.
              </Text>
            </GridRowColumn>
            {/* READ ONLY FORM */}
            {!editing && (
              <GridRowColumn>
                <ReadOnlyForm
                  title="Spouse's Personal Details"
                  setEditing={() => setEditing(true)}
                  readOnlyData={readOnly}
                />
              </GridRowColumn>
            )}
            {/* EDITABLE FORM */}
            {editing && (
              // skipcq: JS-0415 - nested components
              <GridRowColumn>
                <Card type="subsection" backgroundColor="stone40">
                  <Grid>
                    <GridRowColumn>
                      <Text as="h2">Spouse&apos;s Personal Details</Text>
                    </GridRowColumn>
                    <GridRowColumn short>
                      <Divider />
                    </GridRowColumn>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <FormikInput
                          label={questionSpouseFirstName?.question?.text}
                          name={getFieldName<typeof values>('spouseFirstName')}
                          schema={makeReqStringSchema({
                            field: 'spouse first name',
                          })}
                          fullWidth
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <FormikInput
                          label={questionSpouseLastName?.question?.text}
                          name={getFieldName<typeof values>('spouseLastName')}
                          schema={makeReqStringSchema({
                            field: 'spouse last name',
                          })}
                          fullWidth
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <GridRowColumn width={8}>
                      <FormikDateInput
                        maxDate={new Date()}
                        label={questionSpouseDob?.question?.text}
                        placeholder="MM-DD-YYYY"
                        schema={makeDateSchema({
                          field: 'date of birth',
                          format: DATE_FORMATS.INPUT,
                          strict: true,
                        })}
                        name={getFieldName<typeof values>('spouseDob')}
                        fullWidth
                      />
                    </GridRowColumn>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <FormikInput
                          label={questionSpouseSsn?.question?.text}
                          name={getFieldName<typeof values>('spouseSsn')}
                          fullWidth
                          placeholder={
                            responseSpouseSsn?.[0]?.value // || spouseSsnTokenId
                              ? ssnMask
                              : '123-45-6789'
                          }
                          format="###-##-####"
                          schema={makeSsnSchema({
                            field: 'social security number',
                            required: false,
                            // !(
                            //   spouseSsnTokenId || responseSpouseSsn?.[0]?.value
                            // ),
                          })}
                          componentType="pattern"
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <FormikInput
                          label={`Confirm ${questionSpouseSsn?.question?.text}`}
                          name={getFieldName<typeof values>('confirmSpouseSsn')}
                          fullWidth
                          placeholder={
                            responseSpouseSsn?.[0]?.value // || spouseSsnTokenId
                              ? ssnMask
                              : '123-45-6789'
                          }
                          format="###-##-####"
                          schema={makeSsnSchema({
                            field: 'social security number',
                            required: false,
                            // !(
                            //   spouseSsnTokenId || responseSpouseSsn?.[0]?.value
                            // ),
                          }).oneOf(
                            [values.spouseSsn],
                            'Social Security Number does not match'
                          )}
                          componentType="pattern"
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <GridRowColumn width={8}>
                      <FormikInput
                        label={questionSpouseOccupation?.question?.text}
                        name={getFieldName<typeof values>('spouseOccupation')}
                        schema={makeReqStringSchema({
                          field: 'spouse occupation',
                        })}
                        fullWidth
                      />
                    </GridRowColumn>
                    <GridRowColumn width={8}>
                      <FormikInput
                        label={questionSpousePhone?.question?.text}
                        name={getFieldName<typeof values>('spousePhone')}
                        schema={makeReqPhoneNumberSchema()}
                        fullWidth
                        componentType="phone"
                      />
                    </GridRowColumn>
                    <GridRowColumn>
                      <FormikInput
                        label={questionSpouseEmail?.question?.text}
                        name={getFieldName<typeof values>('spouseEmail')}
                        schema={makeReqEmailSchema({
                          field: 'spouse email',
                        })}
                        fullWidth
                      />
                    </GridRowColumn>
                    <GridRowColumn short>
                      <Divider />
                    </GridRowColumn>
                    <GridRowColumn
                      columnStyle={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 20,
                      }}
                    >
                      <Button
                        disabled={!isInitialValid}
                        onClick={() => {
                          resetForm()
                          setEditing(false)
                        }}
                        style={{ border: 0 }}
                        variant="secondary"
                      >
                        Cancel
                      </Button>
                      <Button
                        loading={isUpdating}
                        variant="secondary"
                        onClick={submitForm}
                        disabled={!isValid || isUpdating || isSubmitting}
                      >
                        Save
                      </Button>
                    </GridRowColumn>
                  </Grid>
                </Card>
              </GridRowColumn>
            )}
          </Grid>
        </Container>
      </FormikProvider>
      <Grid>
        <FormFlowFooter
          continueDisabled={!isValid || isUpdating || isSubmitting || editing}
          loading={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={continueSurvey}
        />
      </Grid>
    </>
  )
}

export default SpousePanel
