import { TileSelectorProps } from '../../../../../components/BaseComponents/TileSelector'
import { Button, Text } from '../../../../../components/BaseComponents'
import { TaxListQuestionId } from '../service'
import { useReselector } from '../../../../../utils/sharedHooks'
import {
  everyResponseInQuestionListIsFalse,
  selectTaxListQuestionResponsesByQuestionIds,
} from '../taxChecklist.selectors'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { TaxChecklistResponse } from '../taxChecklistQuestion.slice'
import {
  REVIEW_QUERY_PARAM,
  useSetScreen,
} from '../../../../../components/FormFlow/formFlow'

interface TileProps extends Omit<TileSelectorProps, 'active'> {
  questionId: TaxListQuestionId
  response?: Partial<TaxChecklistResponse>
}

export const useTiles = () => {
  const { formId } = useParams()
  const { currentScreen, setScreen } = useSetScreen()
  const [tiles, setTiles] = useState<TileProps[]>([])
  const [noneApplySelected, setNoneApplySelected] = useState(false)
  const [searchParams] = useSearchParams()
  const [tileWidth, setTileWidth] = useState(162)
  const [tileHeight, setTileHeight] = useState(150)
  const reviewing = searchParams.get(REVIEW_QUERY_PARAM) === 'true'
  const noneApplyIsSelected = useReselector(
    everyResponseInQuestionListIsFalse,
    tiles.map((tile) => tile.questionId),
    Number(formId)
  )
  const questionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    tiles.map((tile) => tile.questionId),
    Number(formId)
  )

  useEffect(() => {
    setNoneApplySelected(noneApplyIsSelected)
  }, [noneApplyIsSelected])

  const maxColumns =
    tiles.length < 3 ? tiles.length : tiles.length === 4 ? 2 : 3
  const tileGap = 16
  const tileColumnStyle = {
    display: 'flex',
    justifyContent: 'center',
    gap: 16,
  }

  const tileClicked = useCallback(
    (questionId: TaxListQuestionId) => {
      const newTileSelections = tiles.map((tile) => {
        if (tile.questionId === questionId) {
          return {
            ...tile,
            response: {
              ...(tile.response ?? {}),
              value: !tile.response?.value,
            },
          }
        }
        return tile
      })
      setTiles(newTileSelections)
      setNoneApplySelected(false)
      if (reviewing) {
        // If coming from a review screen, puts the user back into the normal
        // form flow since tile selections usually have conditional follow-up screens
        setScreen(currentScreen, true)
      }
    },
    [tiles, currentScreen, setScreen, reviewing]
  )

  const clearTiles = useCallback(() => {
    const newSelections = tiles.map((tile) => ({
      ...tile,
      response: {
        ...(tile.response ?? {}),
        value: false,
      },
    }))
    setTiles(newSelections)
    setNoneApplySelected((sn) => !sn)
  }, [tiles])

  const tilesToResponses = useCallback(
    () =>
      tiles.flatMap((tile) => {
        const oldResponse = questionResponses.find(
          (r) => r.questionId === tile.questionId
        )
        if (
          !oldResponse?.value ||
          tile.response?.value !== oldResponse?.value
        ) {
          return {
            id: tile.response?.id,
            questionId: tile.questionId,
            annualTaxFilingFormId: Number(formId),
            value: tile.response?.value ?? false,
          }
        }
        return []
      }),
    [tiles, formId, questionResponses]
  )

  const anyResponseSelected = useMemo(() => {
    return tiles.some((s) => Boolean(s.response?.value)) && !noneApplySelected
  }, [tiles, noneApplySelected])

  return {
    tiles,
    setTiles,
    noneApply: {
      selected: noneApplySelected,
      clearTiles,
      width: tileWidth * maxColumns + tileGap * (maxColumns - 1),
    },
    tilesToResponses,
    tileColumnStyle,
    tileClicked,
    tileWidth,
    tileHeight,
    setTileWidth,
    setTileHeight,
    anyResponseSelected,
  }
}

export const NoneApply = ({
  width,
  selected,
  setSelection,
  ...rest
}: Pick<TileProps, 'width'> & {
  selected: boolean
  setSelection: () => void
}) => {
  return (
    <Button
      variant="toggle"
      style={{ height: 83, width }}
      onClick={setSelection}
      active={selected}
      {...rest}
    >
      <Text as="h3" textAlign="center">
        None of these apply to me
      </Text>
    </Button>
  )
}
