import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Button, TileSelector } from '../components/BaseComponents'
import { useToggle } from '../utils/sharedHooks'

export const PrimaryButton = () => <Button variant="primary">Primary</Button>
export const PrimaryButtonDisabled = () => (
  <Button variant="primary" disabled>
    Primary
  </Button>
)

export const PrimaryDarkButton = () => (
  <Button dark variant="primary">
    Primary Dark
  </Button>
)
export const PrimaryDarkButtonDisabled = () => (
  <Button dark variant="primary" disabled>
    Primary Dark Disabled
  </Button>
)

export const SecondaryButton = () => (
  <Button variant="secondary">Secondary</Button>
)
export const SecondaryButtonDisabled = () => (
  <Button variant="secondary" disabled>
    Secondary
  </Button>
)

export const SecondaryDarkButton = () => (
  <Button dark variant="secondary">
    Secondary Dark
  </Button>
)
export const SecondaryDarkButtonDisabled = () => (
  <Button dark variant="secondary" disabled>
    Secondary Dark Disabled
  </Button>
)

export const ToggleButton = () => {
  const [active, toggle] = useToggle()

  return (
    <Button variant="toggle" active={active} onClick={toggle} fullWidth>
      Toggle {active.toString()}
    </Button>
  )
}

export const SelectorButton = () => {
  const [active, toggle] = useToggle()

  return (
    <Button variant="selector" active={active} onClick={toggle}>
      Selector {active.toString()}
    </Button>
  )
}

export const LinkButton = () => <Button variant="link">Link Button</Button>
export const LinkButtonDisabled = () => (
  <Button variant="link" disabled>
    Link Button
  </Button>
)

export const SecondaryLinkButton = () => (
  <Button variant="secondaryLink">Secondary Link Button</Button>
)
export const SecondaryLinkButtonDisabled = () => (
  <Button variant="secondaryLink" disabled>
    Secondary Link Button
  </Button>
)

export const TooltipLinkButton = () => (
  <Button variant="tooltipLink">Tooltip Link Button</Button>
)
export const TooltipLinkButtonDisabled = () => (
  <Button variant="tooltipLink" disabled>
    Tooltip Link Button
  </Button>
)

export const ActionLinkButton = () => (
  <Button variant="actionLink">Action Link Button</Button>
)
export const ActionLinkButtonDisabled = () => (
  <Button variant="actionLink" disabled>
    Action Link Button
  </Button>
)

export const SmallButton = () => (
  <Button variant="primary" size="small">
    Small Primary
  </Button>
)

export const MediumButton = () => (
  <Button variant="primary" size="medium">
    Medium Primary
  </Button>
)

export const LargeButton = () => (
  <Button variant="primary" size="large">
    Large Primary
  </Button>
)

export const FullWidthButton = () => (
  <Button icon="calendar" variant="primary" fullWidth>
    Full Width
  </Button>
)

export const TileSelectorStory = () => {
  const [active, toggle] = useToggle()

  return (
    <TileSelector
      active={active}
      onClick={toggle}
      text="Tile Selector"
      icon={regular('cat-space')}
    />
  )
}
