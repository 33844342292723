import axios from 'axios'
import { Moment } from 'moment'
import { normalize } from 'normalizr'

import {
  Transaction,
  SplitTransactionModel,
  PostTransactionPayload,
  TransactionResponseEntities,
  receiveAdminAllTransactions,
  deleteAdminTransaction,
  receiveQueriedTransactionData,
  deleteMultipleTransactions,
  receiveAdminTransactionsCounts,
} from '../reducers/admin/allTransactions.slice'
import { fetchWrapper } from '../reducers/fetch'
import { StripeSubscription } from '../reducers/subscription.slice'
import { transactionListSchema, transactionSchema } from '../schema'
import { receiveJournalEntries } from '../reducers/admin/journalEntryReducer'

export interface FilteredTransactionsPayload {
  startDate?: Moment
  endDate?: Moment
  financialAccountId?: number | null
  limit?: number | string
  includeExcluded?: boolean
  includeSplitChildren?: boolean
  sortName?: string
  sortDirection?: 'asc' | 'desc'
  userId: number
}

export const FETCH_FILTERED_TRANSACTIONS_KEY = 'FETCH_FILTERED_TRANSACTIONS_KEY'
export const fetchFilteredTransactions = (
  params: FilteredTransactionsPayload
) =>
  fetchWrapper({
    defaultErrorMessage: 'Error fetching filtered transactions',
    fetchKey: FETCH_FILTERED_TRANSACTIONS_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<{
        count: number
        financialProfileId: number | null
        transactions: Transaction[]
      }>('/finances/api/v1/admin/transactions', {
        params: {
          ...params,
          startDate: params.startDate?.format('MM-DD-YYYY'),
          endDate: params.endDate?.format('MM-DD-YYYY'),
        },
      })
      const normalizedData = normalize<
        Transaction,
        TransactionResponseEntities,
        string[]
      >(json.data.transactions, transactionListSchema)
      dispatch(receiveAdminAllTransactions(normalizedData))
      if (normalizedData.entities.journalEntries) {
        dispatch(receiveJournalEntries(normalizedData.entities.journalEntries))
      }
      dispatch(
        receiveQueriedTransactionData({
          count: json.data.count,
          ids: json.data.transactions.map(({ id }) => id),
        })
      )
      return json.data
    },
  })

export const FETCH_RECONCILIATION_TRANSACTIONS_KEY =
  'FETCH_RECONCILIATION_TRANSACTIONS_KEY'
export const fetchReconciliationTransactions = (
  userId: number,
  financialAccountId: number,
  startDate: Moment,
  endDate: Moment
) =>
  fetchWrapper({
    defaultErrorMessage: 'Error fetching filtered transactions',
    fetchKey: FETCH_FILTERED_TRANSACTIONS_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<{
        count: number
        financialProfileId: number | null
        transactions: Transaction[]
      }>(
        `/finances/api/v1/admin/transactions/reconciliation/${userId}/${financialAccountId}/${startDate.format(
          'MM-DD-YYYY'
        )}/${endDate.format('MM-DD-YYYY')}`
      )
      const normalizedData = normalize<
        Transaction,
        TransactionResponseEntities,
        string[]
      >(json.data.transactions, transactionListSchema)
      dispatch(receiveAdminAllTransactions(normalizedData))
      dispatch(
        receiveQueriedTransactionData({
          count: json.data.count,
          ids: json.data.transactions.map(({ id }) => id),
        })
      )
      return json.data
    },
  })

// Bulk Transactions Update

export type BulkUpdatePayload = PostTransactionPayload & {
  transactionIds: number[]
}
export const BULK_UPDATE_USER_TRANSACTIONS_KEY =
  'BULK_UPDATE_USER_TRANSACTIONS_KEY'
export const bulkUpdateUserTransactions = (data: BulkUpdatePayload) =>
  fetchWrapper({
    defaultErrorMessage: 'Error updating all transactions',
    fetchKey: BULK_UPDATE_USER_TRANSACTIONS_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<Transaction[]>(
        '/finances/api/v1/admin/transactions/bulk',
        data
      )
      const normalizedData = normalize<
        Transaction,
        TransactionResponseEntities,
        string[]
      >(json.data, transactionListSchema)
      dispatch(receiveAdminAllTransactions(normalizedData))
      if (normalizedData.entities.journalEntries) {
        dispatch(receiveJournalEntries(normalizedData.entities.journalEntries))
      }
      return json.data
    },
  })

// Fetch all Transaction Counts
export const fetchTransactionCounts = ({
  userId,
  startDate,
  endDate,
}: {
  userId: number | string | undefined
  startDate?: Moment
  endDate?: Moment
}) =>
  fetchWrapper({
    defaultErrorMessage: 'Error fetching transaction counts',
    defaultValue: {
      pendingCount: 0,
      uncategorizedCount: 0,
      requestedClarificationCount: 0,
    },
    fetchFunction: async (dispatch) => {
      const json = await axios.get<{
        pendingCount: number
        uncategorizedCount: number
        requestedClarificationCount: number
      }>('/finances/api/v1/admin/transactions/count', {
        params: {
          userId,
          startDate: startDate?.format('MM-DD-YYYY'),
          endDate: endDate?.format('MM-DD-YYYY'),
        },
      })
      dispatch(receiveAdminTransactionsCounts(json.data))
      return json.data
    },
  })

/*
  Create a transaction
  Currently via the Account Reconciliation panel
*/

export const adminCreateTransaction = (data: Partial<Transaction>) =>
  fetchWrapper({
    defaultErrorMessage: 'Error creating transaction',
    shouldHandleError: false,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<Transaction>(
        '/finances/api/v1/transactions',
        data
      )
      const normalizedData = normalize<
        Transaction,
        TransactionResponseEntities,
        string[]
      >(json.data, transactionSchema)
      dispatch(receiveAdminAllTransactions(normalizedData))
      return json.data
    },
  })

// Delete a single transaction

export const adminDeleteTransaction = (id: number) =>
  fetchWrapper({
    defaultErrorMessage: 'Error deleting transaction',
    shouldHandleError: false,
    fetchFunction: async (dispatch) => {
      const json = await axios.delete<Transaction>(
        `/finances/api/v1/admin/transactions/delete/${id}`
      )
      dispatch(deleteAdminTransaction(json.data))
      return json.data
    },
  })

export const adminBulkDeleteTransaction = (ids: number[]) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const json = await axios.post<{ message: string }>(
        '/finances/api/v1/admin/transactions/bulk_delete',
        { ids }
      )

      dispatch(deleteMultipleTransactions(ids))
      return json.data
    },
  })

// Split a single transaction
export const adminSplitTransaction = (
  id: string | number,
  data: SplitTransactionModel[]
) =>
  fetchWrapper({
    defaultErrorMessage: 'Error splitting transaction',
    shouldHandleError: false,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<Transaction>(
        `/finances/api/v1/admin/transactions/${id}/split`,
        data
      )
      const normalizedData = normalize<
        Transaction,
        TransactionResponseEntities,
        string
      >(json.data, transactionSchema)
      dispatch(receiveAdminAllTransactions(normalizedData))
      return json.data
    },
  })

export const ADMIN_REQUEST_SUBSCRIPTIONS_BY_USER_ID =
  'REQUEST_SUBSCRIPTIONS_BY_USER_ID'

export const adminFetchSubscriptionsByUserId = (userId: number) =>
  fetchWrapper({
    fetchKey: ADMIN_REQUEST_SUBSCRIPTIONS_BY_USER_ID,
    fetchFunction: async () => {
      const json = await axios.get<StripeSubscription[]>(
        `/finances/api/v1/admin/${userId}/subscriptions`
      )

      return json.data
    },
  })
export const ADMIN_REQUEST_SUBSCRIPTIONS_BY_SUBSCRIPTION_ID =
  'REQUEST_SUBSCRIPTIONS_BY_SUBSCRIPTION_ID'
export const adminFetchSubscriptionsBySubscriptionId = (
  userId?: number,
  subscriptionId?: string
) =>
  fetchWrapper({
    fetchKey: ADMIN_REQUEST_SUBSCRIPTIONS_BY_SUBSCRIPTION_ID,
    fetchFunction: async () => {
      const json = await axios.get<StripeSubscription>(
        `/finances/api/v1/admin/${userId}/subscriptions/${subscriptionId}`
      )

      return json.data
    },
  })
