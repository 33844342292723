import { CSSProperties } from 'react'
import { Placeholder, StrictPlaceholderProps } from 'semantic-ui-react'

const PlaceholderParagraph = (
  props: StrictPlaceholderProps & { style?: CSSProperties }
) => (
  <Placeholder {...props}>
    <Placeholder.Paragraph>
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder.Paragraph>
    <Placeholder.Paragraph>
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder.Paragraph>
  </Placeholder>
)

export default PlaceholderParagraph
