import { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { Tab, TabPane, Table } from 'semantic-ui-react'
import { DateTime } from 'luxon'

import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import { Colors } from '../../../styles/theme'
import { TransactionCategoryLabelReadOnly } from '../../shared/TransactionCategoryLabel'
import CurrencyFormatLabel from '../../shared/CurrencyFormatLabel'
import { centsToDollars } from '../../../utils/currencyHelpers'
import { useAsync } from '../../../utils/sharedHooks'
import { fetchTransaction } from '../../../actions/admin/v2/adminTransactionActions'
import { Alert, Loader, Text } from '../../BaseComponents'
import RowNotes from './RowNotes'
import TransactionChangeLogsPanel from '../../../features/Admin/TransactionChangeLogs/TransactionChangeLogsPanel'
import TransactionJournalEntriesPanel from '../../../features/JournalEntries/components/TransactionJournalEntriesPanel'
import { JournalEntry } from '../../../features/JournalEntries/types'
import { DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import { TransactionEditReview } from '../UserTransactionsPanels/TransactionTableExpandedRow'

const StyledPane: React.FC<PropsWithChildren> = ({ children }) => (
  <TabPane
    style={{
      border: `1px solid ${Colors.lightGray}`,
      borderTop: 'none',
      minHeight: '100px',
      paddingLeft: '40px',
      paddingRight: '40px',
    }}
  >
    {children}
  </TabPane>
)

const updateJournalEntry = (
  transaction: Transaction,
  journalEntry: MaybeJournalEntry
): Transaction => {
  if (journalEntry && typeof journalEntry !== 'boolean') {
    const replaceWithUpdated = (je: JournalEntry) =>
      je.id === journalEntry.id ? journalEntry : je

    if (!transaction.splitAt) {
      return {
        ...transaction,
        journalEntries:
          transaction.journalEntries?.map(replaceWithUpdated) ?? [],
      }
    } else {
      const updatedSplitTransactions =
        transaction.splitTransactions?.map((split) => ({
          ...split,
          journalEntries: split.journalEntries?.map(replaceWithUpdated),
        })) ?? []
      return { ...transaction, splitTransactions: updatedSplitTransactions }
    }
  }

  return transaction
}

const deleteJournalEntry = (
  transaction: Transaction,
  journalEntry: MaybeJournalEntry
): Transaction => {
  if (journalEntry && typeof journalEntry !== 'boolean') {
    const filterDeleted = (je: JournalEntry) => je.id !== journalEntry.id

    if (!transaction.splitAt) {
      return {
        ...transaction,
        journalEntries: transaction.journalEntries?.filter(filterDeleted) ?? [],
      }
    } else {
      const updatedSplitTransactions =
        transaction.splitTransactions?.map((split) => ({
          ...split,
          journalEntries: split.journalEntries?.filter(filterDeleted),
        })) ?? []
      return { ...transaction, splitTransactions: updatedSplitTransactions }
    }
  }

  return transaction
}

const createJournalEntry = (
  transaction: Transaction,
  journalEntry: MaybeJournalEntry
): Transaction => {
  if (journalEntry && typeof journalEntry !== 'boolean') {
    if (!transaction.splitAt) {
      return {
        ...transaction,
        journalEntries: [...(transaction.journalEntries ?? []), journalEntry],
      }
    } else {
      const updatedSplit = transaction.splitTransactions?.find(
        (split) => split.id === transaction.id
      )
      updatedSplit?.journalEntries?.push(journalEntry)
      const updatedSplitTransactions =
        transaction.splitTransactions?.map((split) =>
          split.id === updatedSplit?.id ? updatedSplit : split
        ) ?? []
      return { ...transaction, splitTransactions: updatedSplitTransactions }
    }
  }

  return transaction
}

export type MaybeJournalEntry = JournalEntry | boolean | undefined
export enum JournalEntryUpdateType {
  CREATE,
  UPDATE,
  DELETE,
}

const JournalEntriesPanel = ({
  transaction,
  updateTransaction,
}: {
  transaction: Transaction
  updateTransaction: (transaction: Transaction) => void
}) => {
  const onUpdate = useCallback(
    (journalEntry: MaybeJournalEntry, type: JournalEntryUpdateType) => {
      const updatedTransaction =
        type === JournalEntryUpdateType.DELETE
          ? deleteJournalEntry(transaction, journalEntry)
          : type === JournalEntryUpdateType.CREATE
            ? createJournalEntry(transaction, journalEntry)
            : updateJournalEntry(transaction, journalEntry)

      updateTransaction(updatedTransaction)
    },
    [transaction, updateTransaction]
  )
  return (
    <StyledPane>
      {!transaction.splitAt && (
        <TransactionJournalEntriesPanel
          transaction={transaction}
          onUpdate={onUpdate}
          ignoreRedux
        />
      )}
      {transaction.splitAt &&
        transaction.splitTransactions?.map((split) => (
          <TransactionJournalEntriesPanel
            key={`journal-entries-panel-for-${split.id}`}
            transaction={split}
            onUpdate={onUpdate}
            ignoreRedux
          />
        ))}
    </StyledPane>
  )
}

const ChangeLogPanel = ({ transaction }: { transaction: Transaction }) => (
  <StyledPane>
    <TransactionChangeLogsPanel transactionId={transaction.id} />
  </StyledPane>
)

const SplitTransactionPanel = ({
  transaction,
}: {
  transaction: Transaction
}) => {
  const hasSplitTransactions = useMemo(
    () =>
      transaction.splitTransactions && transaction.splitTransactions.length > 0,
    [transaction.splitTransactions]
  )

  return hasSplitTransactions ? (
    <StyledPane>
      <Table selectable compact striped celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Category</Table.HeaderCell>
            <Table.HeaderCell>Notes</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {transaction.splitTransactions?.map((split) => (
            <Table.Row key={`split-transaction-${split.id}`}>
              <Table.Cell width="4">{split.description}</Table.Cell>
              <Table.Cell width="2">
                <CurrencyFormatLabel
                  value={centsToDollars(split.amountInCents)}
                />
              </Table.Cell>
              <Table.Cell width="2">{split.type}</Table.Cell>
              <Table.Cell width="4">
                <TransactionCategoryLabelReadOnly
                  transactionType={split?.type}
                  transactionCategoryId={split?.transactionCategoryId}
                />
              </Table.Cell>
              <Table.Cell>
                <RowNotes transaction={split} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </StyledPane>
  ) : (
    <StyledPane>
      <Text>No split transactions found for this transaction</Text>
    </StyledPane>
  )
}

const ReceiptsPanel = ({ transaction }: { transaction: Transaction }) => {
  return (
    <StyledPane>
      {transaction.receipts?.length ? (
        <Table selectable compact striped celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Receipt</Table.HeaderCell>
              <Table.HeaderCell>Customer Note</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {transaction.receipts.map((receipt) => (
              <Table.Row key={`receipt-${receipt.id}`}>
                <Table.Cell width="4">
                  <a
                    href={receipt.signedUrl ?? undefined}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {receipt.fileDescription}
                  </a>
                </Table.Cell>
                <Table.Cell width="2">{receipt.customerDescription}</Table.Cell>
                <Table.Cell width="2">
                  {DateTime.fromISO(receipt.updatedAt).toFormat(
                    DATE_FORMATS_LUXON.DISPLAY_SHORT
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <Text>No receipts found for this transaction</Text>
      )}
    </StyledPane>
  )
}

const ClarificationPanel = ({
  transaction,
  onConfirm,
}: {
  transaction: Transaction
  onConfirm: () => void
}) => {
  const transactionEdit =
    transaction.transactionEdits &&
    transaction.transactionEdits.length > 0 &&
    typeof transaction.transactionEdits[0] !== 'number'
      ? transaction.transactionEdits[0]
      : null

  return (
    <StyledPane>
      {transactionEdit ? (
        <TransactionEditReview
          transaction={transaction}
          initialTransactionEdit={transactionEdit}
          handleTransactionEditCompletion={onConfirm}
          ignoreRedux
        />
      ) : (
        <Text>User has not reviewed transaction yet</Text>
      )}
    </StyledPane>
  )
}

const RowDrawer = ({
  row,
  colSpan,
  onTransactionUpdate,
  closeDrawer,
}: {
  row: Transaction
  colSpan: number
  onTransactionUpdate: (id: number) => void
  closeDrawer: () => void
}) => {
  const [transaction, setTransaction] = useState<Transaction | undefined>(
    undefined
  )
  const { loading, error } = useAsync(
    useCallback(async () => {
      const result = await fetchTransaction({
        id: row.id,
        select: [
          'splitTransactions',
          'journalEntries',
          'receipts',
          'transactionEdits',
        ],
      })
      setTransaction(result)
      return result
    }, [row.id])
  )

  return (
    <Table.Cell colSpan={colSpan} style={{ minHeight: '100px' }}>
      <Loader loading={loading} />
      {!loading && error && (
        <Alert title="Error loading transaction details" type="error" />
      )}
      {!loading && !error && transaction && (
        <Tab
          panes={[
            {
              menuItem: {
                key: 'split',
                icon: 'code branch',
                content: 'Split Transactions',
              },
              render: () => <SplitTransactionPanel transaction={transaction} />,
            },
            {
              menuItem: {
                key: 'changes',
                icon: 'clock outline',
                content: 'Change Log',
              },
              render: () => <ChangeLogPanel transaction={transaction} />,
            },
            {
              menuItem: {
                key: 'journal-entries',
                icon: 'table',
                content: 'Journal Entries',
              },
              render: () => (
                <JournalEntriesPanel
                  transaction={transaction}
                  updateTransaction={setTransaction}
                />
              ),
            },
            {
              menuItem: {
                key: 'receipts',
                icon: 'file outline',
                content: 'Receipts',
              },
              render: () => <ReceiptsPanel transaction={transaction} />,
            },
            ...(row.requestedClarificationAt
              ? [
                  {
                    menuItem: {
                      key: 'clarification',
                      icon: 'question circle outline',
                      content: 'Clarification',
                    },
                    render: () => (
                      <ClarificationPanel
                        transaction={transaction}
                        onConfirm={() => {
                          onTransactionUpdate(row.id)
                          closeDrawer()
                        }}
                      />
                    ),
                  },
                ]
              : []),
          ]}
        />
      )}
    </Table.Cell>
  )
}

export default RowDrawer
