import ImageOffset from '../../../../components/shared/ImageOffset'

const PAYSTUB_LINK =
  'https://heard-images.s3.amazonaws.com/assets/example_paystub.svg'

const PAYSTUB_ZOOM_CONFIG = {
  grossIncome: {
    backgroundPosition: '0 -280px',
    height: 111,
    backgroundSize: '260%',
  },
  withholdings: {
    backgroundPosition: '-373px -157px',
    height: 169,
    backgroundSize: '274%',
  },
}

const ExamplePaystub = ({
  zoomTo,
}: {
  zoomTo: keyof typeof PAYSTUB_ZOOM_CONFIG
}) => (
  <ImageOffset
    imageLink={PAYSTUB_LINK}
    linkText="View example paystub"
    width={308}
    {...PAYSTUB_ZOOM_CONFIG[zoomTo]}
  />
)

export default ExamplePaystub
