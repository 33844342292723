import { useMemo } from 'react'
import { Grid, Image } from 'semantic-ui-react'

import { GridRowColumn, Text } from '../../../../../components/BaseComponents'
import { EXTENSION_REQUEST_SCREENS } from '../Combined'
import { makeGridConfig } from '../../../../../components/BaseComponents/Grid'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import { useNavigateWithLocation } from '../../../../../utils/routeHelpers'
import { TAX_ENTITY_TYPES } from '../../../taxConstants'
import { filingStatusQuestionIds } from '../../TaxChecklist/Form1040/Details/FilingStatusPanel'
import { locationInfoQuestionIds } from '../../TaxChecklist/Form1040/Details/LocationInfo'
import { spouseQuestionIds } from '../../TaxChecklist/Form1040/Details/SpousePanel'
import { getPersonalQuestionIds } from '../../TaxChecklist/Shared/PersonalDetails/CheckYourDetailsPanel'
import { ReviewSubSectionForFormIds } from '../../TaxChecklist/Shared/ReviewSubSection'
import { Form1040DetailsProps } from '../../TaxChecklist/Form1040/Details'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../utils/sharedHooks'
import { getEstimatesByYear } from '../../../QuarterlyTaxEstimates/userTaxEstimates.selector'
import { estimatedTaxQuestionIds } from './EstimateTaxes'
import {
  selectSortedTaxReturnDocumentsForYear,
  selectUserTaxDocumentsForCategory,
} from '../../../../UserDocuments/userDocuments.selector'
import { submitExtensionRequest } from '../../TaxChecklist/taxChecklistQuestion.actions'
import { getAnnualTaxFilingForYearSelector } from '../../annualTaxFilings.selector'
import {
  getListOfDocumentQuestionsAndResponses,
  getQuestionAndResponseForAdditionalQsTaxEstimates,
} from '../helpers'
import { markUserActionItemCompleteIfExists } from '../../../../Dashboard/UserActionItems/service'
import { UserActionItemActionItemIdentifiers } from '../../../../Dashboard/UserActionItems/userActionItems.slice'
import { UserDocumentCategoryIdentifier } from '../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { useAppDispatch } from '../../../../../utils/typeHelpers'
import { select1040FormForYear } from '../../annualTaxFilingForms.selector'

const ReviewPanel = ({
  goBack,
  previousScreen,
  taxYear,
  isReadOnly,
}: Form1040DetailsProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigateWithLocation()

  const estimates = useReselector(getEstimatesByYear, taxYear)
  const form1040 = useReselector(select1040FormForYear, taxYear)

  const annualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    taxYear
  )

  const prevYearPersonalReturns = useReselector(
    selectSortedTaxReturnDocumentsForYear,
    (Number(taxYear) - 1).toString()
  ).personal
  const govIds = useReselector(
    selectUserTaxDocumentsForCategory,
    UserDocumentCategoryIdentifier.officialGovernmentId,
    taxYear
  )
  const spouseGovIds = useReselector(
    selectUserTaxDocumentsForCategory,
    UserDocumentCategoryIdentifier.spouseOfficialGovernmentId,
    taxYear
  )

  const documentQuestionsAndResponses = useMemo(() => {
    return getListOfDocumentQuestionsAndResponses({
      taxYear,
      prevYearPersonalReturns,
      govIds,
      spouseGovIds,
    })
  }, [govIds, prevYearPersonalReturns, spouseGovIds, taxYear])

  const estimatesQuestionsAndResponses = useMemo(() => {
    return getQuestionAndResponseForAdditionalQsTaxEstimates(estimates)
  }, [estimates])

  const submitExtensionRequestAndNavigate = useAsyncCallback(async () => {
    const success = await submitExtensionRequest(annualTaxFiling?.id)(dispatch)

    if (success) {
      await markUserActionItemCompleteIfExists(
        UserActionItemActionItemIdentifiers.submitExtension1040({
          year: taxYear,
        })
      )
      navigate('/taxes/annual')
    }
  })

  const sharedProps = {
    readOnly: isReadOnly,
    formIds: form1040?.id ? [form1040.id.toString()] : [],
  }

  return (
    <Grid>
      <GridRowColumn
        columnStyle={{ display: 'flex', justifyContent: 'center' }}
      >
        <Image src="https://heard-images.s3.amazonaws.com/assets/magnifying-glass-checkmark.svg" />
      </GridRowColumn>
      <GridRowColumn>
        <Text as="display2" textAlign="center">
          Review
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([8, 14], true)}>
        <Text as="bodyLg" textAlign="center">
          {isReadOnly
            ? 'You can no longer edit your Extension Request responses, but you can view them anytime.'
            : 'Review your answers for accuracy. Click edit to go back to the previous section.'}
        </Text>
      </GridRowColumn>
      <Grid.Row />
      <ReviewSubSectionForFormIds
        title="confirm details"
        questionIds={getPersonalQuestionIds(TAX_ENTITY_TYPES.form_1040)}
        editScreen={EXTENSION_REQUEST_SCREENS.confirmDetails}
        {...sharedProps}
      />
      <ReviewSubSectionForFormIds
        title="filing status"
        questionIds={filingStatusQuestionIds}
        editScreen={EXTENSION_REQUEST_SCREENS.filingStatus}
        {...sharedProps}
      />
      <ReviewSubSectionForFormIds
        title="your spouse"
        questionIds={spouseQuestionIds}
        editScreen={EXTENSION_REQUEST_SCREENS.spouse}
        {...sharedProps}
      />
      <ReviewSubSectionForFormIds
        title="Update location information"
        questionIds={locationInfoQuestionIds}
        editScreen={EXTENSION_REQUEST_SCREENS.locationInfo}
        {...sharedProps}
      />
      <ReviewSubSectionForFormIds
        title="quarterly tax payments"
        questionIds={[]}
        additionalInfo={estimatesQuestionsAndResponses}
        editScreen={EXTENSION_REQUEST_SCREENS.estimateTaxQuarterPayments}
        {...sharedProps}
      />
      <ReviewSubSectionForFormIds
        title="estimate taxes"
        questionIds={estimatedTaxQuestionIds}
        additionalInfo={documentQuestionsAndResponses}
        editScreen={EXTENSION_REQUEST_SCREENS.estimateTax}
        {...sharedProps}
      />
      {!isReadOnly && (
        <FormFlowFooter
          isSubmit
          continueDisabled={submitExtensionRequestAndNavigate.loading}
          loading={submitExtensionRequestAndNavigate.loading}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitExtensionRequestAndNavigate.callback}
        />
      )}
    </Grid>
  )
}

export default ReviewPanel
