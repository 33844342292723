import { createSelector } from 'reselect'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ReduxState } from '../../utils/typeHelpers'
import { transactionFilterToQueryUrlService } from '../../services/transactionFilterToQueryUrlService'

// These match db columns so be careful when changing
export const TRANSACTIONS_SORT_BY = {
  ID: 'id',
  DATE: 'date',
  DESCRIPTION: 'description',
  AMOUNT: 'amountInCents',
  ACCOUNT: 'financialAccountId',
  TYPE: 'type',
  CATEGORY: 'transactionCategoryId',
  NOTES: 'notesLastUpdatedAt',
  NICKNAME: 'nickname',
}

export interface TransactionFiltersState {
  startDate: string | null
  endDate: string | null
  page: string
  limit: string
  // value of -1 will filter transactions without category
  transactionCategoryId: string | null
  transactionsType: string
  financialAccountId: number | null
  sortVal: string
  sortAsc: boolean
  userId: number | null
  currentTaxYear: string | null
  lastReviewDate: string | null
  description: string | null
  notes: string | null
}

const initialState: TransactionFiltersState = {
  startDate: null,
  endDate: null,
  page: '1',
  limit: '100',
  transactionCategoryId: null,
  transactionsType: 'all',
  financialAccountId: null,
  sortVal: TRANSACTIONS_SORT_BY.DATE,
  sortAsc: false,
  userId: null,
  currentTaxYear: null,
  lastReviewDate: null,
  description: null,
  notes: null,
}

export type UpdateFilterPayload = Partial<TransactionFiltersState>

const transactionFiltersSlice = createSlice({
  name: 'transactionFilters',
  initialState,
  reducers: {
    clearTransactionFilters: (
      _state,
      action: PayloadAction<{ limit: string } | undefined>
    ) => ({ ...initialState, limit: action?.payload?.limit || '100' }),
    updateTransactionFilters: (
      state,
      action: PayloadAction<UpdateFilterPayload>
    ) => ({
      ...state,
      ...action.payload,
      // If a filter changes by anything other than page reset the page
      page: action.payload['page'] || initialState['page'],
    }),
    clearAndUpdateTransactionFilters: (
      _state,
      action: PayloadAction<UpdateFilterPayload>
    ) => ({
      ...initialState,
      ...action.payload,
    }),
  },
})

export const selectTransactionFilters = (state: ReduxState) =>
  state.transactionFilters

export const selectTransactionFilterQuery = createSelector(
  selectTransactionFilters,
  (filters) =>
    filters.userId
      ? transactionFilterToQueryUrlService({
          ...filters,
          userId: filters.userId,
        })
      : null
)

export const selectIsNotesTab = createSelector(
  selectTransactionFilters,
  (filters) => filters.transactionsType === 'notes'
)

export const selectIsTableExpandable = createSelector(
  selectTransactionFilters,
  (filters) => filters.transactionsType === 'requestedClarification'
)

export default transactionFiltersSlice.reducer

export const {
  clearTransactionFilters,
  updateTransactionFilters,
  clearAndUpdateTransactionFilters,
} = transactionFiltersSlice.actions
