import { ReactNode } from 'react'
import Moment from 'react-moment'
import {
  QuarterlyTaxEstimateDetail,
  QuarterlyTaxEstimateDetailStatuses,
} from '../quarterlyTaxEstimateDetails.slice'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { IconButton, Table } from '../../../../components/BaseComponents'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

interface Props {
  details: QuarterlyTaxEstimateDetail
  onEditClick: () => void
}

const StyledCell = ({
  children,
  status,
}: {
  children: ReactNode
  status: QuarterlyTaxEstimateDetailStatuses
}) => (
  <Table.Cell positive={status === QuarterlyTaxEstimateDetailStatuses.active}>
    {children}
  </Table.Cell>
)

const QuarterlyTaxEstimateDetailsRow = ({ details, onEditClick }: Props) => (
  <Table.Row>
    <StyledCell status={details.status}>
      {details.status !== QuarterlyTaxEstimateDetailStatuses.past && (
        <IconButton icon={regular('pencil')} onClick={onEditClick} />
      )}
    </StyledCell>
    <StyledCell status={details.status}>{details.taxYear}</StyledCell>
    <StyledCell status={details.status}>Q{details.taxQuarter}</StyledCell>
    <StyledCell status={details.status}>{details.status}</StyledCell>
    <StyledCell status={details.status}>
      <Moment utc format={DATE_FORMATS.DISPLAY_SHORT}>
        {details.irsQuarterStartsAt}
      </Moment>
    </StyledCell>
    <StyledCell status={details.status}>
      <Moment utc format={DATE_FORMATS.DISPLAY_SHORT}>
        {details.irsQuarterEndsAt}
      </Moment>
    </StyledCell>
    <StyledCell status={details.status}>
      <Moment utc format={DATE_FORMATS.DISPLAY_SHORT}>
        {details.irsPaymentDueAt}
      </Moment>
    </StyledCell>
    <StyledCell status={details.status}>
      <Moment utc format={DATE_FORMATS.DISPLAY_SHORT}>
        {details.newUserCutOffAt}
      </Moment>
    </StyledCell>
    <StyledCell status={details.status}>
      <Moment utc format={DATE_FORMATS.DISPLAY_SHORT}>
        {details.calculationStartsAt}
      </Moment>
    </StyledCell>
    <StyledCell status={details.status}>
      <Moment utc format={DATE_FORMATS.DISPLAY_SHORT}>
        {details.clientNotifiedAt}
      </Moment>
    </StyledCell>
    <StyledCell status={details.status}>
      <Moment utc format={DATE_FORMATS.DISPLAY_SHORT}>
        {details.bookkeepingPeriodStartsAt}
      </Moment>
    </StyledCell>
    <StyledCell status={details.status}>
      <Moment utc format={DATE_FORMATS.DISPLAY_SHORT}>
        {details.bookkeepingPeriodEndsAt}
      </Moment>
    </StyledCell>
    <StyledCell status={details.status}>
      {details.bookkeepingPeriodTotalMonths}
    </StyledCell>
    <StyledCell status={details.status}>
      {details.edgeCaseUserIds ? details.edgeCaseUserIds.join(', ') : ''}
    </StyledCell>
    <StyledCell status={details.status}>
      <Moment format={DATE_FORMATS.DISPLAY_SHORT}>{details.updatedAt}</Moment>
    </StyledCell>
  </Table.Row>
)

export default QuarterlyTaxEstimateDetailsRow
