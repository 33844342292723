import { FC } from 'react'
import { Icon } from 'semantic-ui-react'

import Button, { ButtonProps } from '../../../components/BaseComponents/Button'

const UploadBackButton: FC<ButtonProps> = (props) => (
  <Button {...props} variant="link">
    <Icon name="arrow left" style={{ marginTop: -7 }} />
    Back
  </Button>
)

export default UploadBackButton
