import {
  Store,
  configureStore,
  createListenerMiddleware,
} from '@reduxjs/toolkit'

import rootReducer from './reducers'
import { PreloadedState } from './utils/session'
import { ReduxState } from './utils/typeHelpers'
import { receiveFinancialProfile } from './reducers/auth/userReducer'
import { getFinancialProfile } from './selectors/user.selectors'
import { invalidateFetch } from './reducers/fetch'
import { FETCH_USER_TAX_QUESTIONNAIRE_KEY } from './features/Taxes/AnnualTaxes/TaxChecklist/taxChecklistQuestion.actions'
import { selectCurrentAnnualTaxYear } from './features/Admin/AnnualTaxDetails/annualTaxDetails.selector'

let store: Store<ReduxState> | undefined

const listenerMiddleware = createListenerMiddleware<ReduxState>()

// If the filing status changes we need to invalidate the tax questionnaire responses because the BE is changing the questions based on it
listenerMiddleware.startListening({
  actionCreator: receiveFinancialProfile,
  effect: (action, { getOriginalState, dispatch }) => {
    const state = getOriginalState()
    const oldFilingStatus = getFinancialProfile(state)?.filingStatus
    const filingStatusChanged =
      oldFilingStatus && oldFilingStatus !== action.payload.filingStatus
    const year = selectCurrentAnnualTaxYear(state)

    if (filingStatusChanged && year) {
      dispatch(invalidateFetch(FETCH_USER_TAX_QUESTIONNAIRE_KEY(year)))
    }
  },
})

export const createReduxStore = (
  preloadedState: PreloadedState,
  skipDevChecks?: boolean
) => {
  // https://redux-toolkit.js.org/api/getDefaultMiddleware there is additional middleware for development but this
  // slows down tests and can cause failures
  const options = skipDevChecks
    ? {
        serializableCheck: false,
        immutableCheck: false,
        actionCreatorCheck: false,
      }
    : undefined

  store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware(options).prepend(listenerMiddleware.middleware),
  })

  return store
}

// Use sparingly. Components should use useSelector/useDispatch/connect
export const getStore = () => store
