import React from 'react'

import { Grid, Header, Icon, Button } from 'semantic-ui-react'

interface GridRowWrapperProps {
  title: string
  subtitle: string
  buttonText: string
  buttonDisabled: boolean
  buttonOnClick: () => void
  buttonHidden: boolean
  checked?: boolean
  children?: React.ReactNode
}

const GridRowWrapper: React.FC<GridRowWrapperProps> = ({
  title,
  subtitle,
  buttonText,
  checked,
  buttonDisabled,
  children,
  buttonHidden,
  buttonOnClick,
}) => (
  <Grid.Row className={checked ? 'checked' : ''} stretched>
    <Grid.Column width={13}>
      <Grid.Row>
        <Header as="h4">
          <Icon name={checked ? 'check circle' : 'check circle outline'} />
          <Header.Content>{title}</Header.Content>
        </Header>
      </Grid.Row>
      <Grid.Row>
        <Header as="h6">{subtitle}</Header>
      </Grid.Row>
    </Grid.Column>
    <Grid.Column width={3}>
      <Button
        onClick={buttonOnClick}
        disabled={buttonDisabled}
        fluid
        primary
        style={{ display: buttonHidden ? 'none' : 'inline' }}
      >
        {buttonText}
      </Button>
    </Grid.Column>

    {children}
  </Grid.Row>
)

export default GridRowWrapper
