import { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import {
  Container,
  Header,
  Grid,
  Button,
  Breadcrumb,
  Divider,
} from 'semantic-ui-react'
import { CSVLink } from 'react-csv'
import moment from 'moment'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  DEFAULT_START_DATE,
  DEFAULT_END_DATE,
} from '../../../constants/businessConstants'
import {
  fetchAdminExpensesBreakdown,
  fetchAdminProfitsBreakdown,
  fetchAdminProfitAndLoss,
  fetchAdminUncategorizedBreakdown,
  fetchAdminOtherBreakdown,
} from '../../../actions/reportActions'

import {
  fetchAdminExpensesBreakdown as fetchAdminExpensesBreakdownV2,
  fetchAdminProfitsBreakdown as fetchAdminProfitsBreakdownV2,
  fetchAdminProfitAndLoss as fetchAdminProfitAndLossV2,
  fetchAdminUncategorizedBreakdown as fetchAdminUncategorizedBreakdownV2,
  fetchAdminOtherBreakdown as fetchAdminOtherBreakdownV2,
} from '../../../actions/reportActionsV2'

import { adminFetchSingleUserIfNeeded } from '../../../actions/admin/adminAllUsersActions'
import { fetchTransactionCategoriesIfNeeded } from '../reports.slice'
import { getUserById } from '../../../selectors/user.selectors'
import '../Reports.scss'
import ProfitAndLossReports from '../ProfitAndLossReports'
import { transactionFilterToQueryUrlService } from '../../../services/transactionFilterToQueryUrlService'
import { MAX_TRANSACTION_LIMIT } from '../../../constants/transactionConstants'
import { DatePicker, Icon } from '../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { fetchFilteredTransactions } from '../../../actions/adminActions'
import { useReselector } from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'

export interface ProfitLossLineItem {
  transactionCategoryId: number
  sum: string
}

const AdminProfitAndLossPanel = () => {
  const { userId: userIdParam } = useParams()
  const [searchParams] = useSearchParams()
  const confirmed = searchParams.get('confirmed') === 'true'
  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  const userId = Number(userIdParam)
  const dispatch = useAppDispatch()
  const user = useReselector(getUserById, userId)

  const pageTitle = `${confirmed ? 'Journal Entry ' : ''}Profit and Loss`

  const [profitsObj, setProfitsObj] = useState<{
    profits: ProfitLossLineItem[]
    sum: string | number
  }>()
  const [expensesObj, setExpensesObj] = useState<{
    expenses: ProfitLossLineItem[]
    sum: string | number
  }>()
  const [uncategorizedObj, setUncategorizedObj] = useState<{
    sum: number
    transactionCategoryId: number | null
  }>()
  const [otherObj, setOtherObject] = useState<{
    otherExpenses: ProfitLossLineItem[]
    sum: string | number
  }>()
  const [csvData, setCsvData] = useState<string[][]>()
  const [startDate, setStartDate] = useState(
    DEFAULT_START_DATE.format(DATE_FORMATS.INPUT)
  )
  const [endDate, setEndDate] = useState(
    DEFAULT_END_DATE.format(DATE_FORMATS.INPUT)
  )
  const [csvFetching, setCsvFetching] = useState(false)

  useEffect(() => {
    dispatch(fetchTransactionCategoriesIfNeeded())
    dispatch(adminFetchSingleUserIfNeeded(userId))
  }, [dispatch, userId])

  useEffect(() => {
    const fetch = async () => {
      const params = {
        userId,
        startDate: startDate
          ? moment(startDate, DATE_FORMATS.INPUT)
          : undefined,
        endDate: endDate ? moment(endDate, DATE_FORMATS.INPUT) : undefined,
      }

      const [profits, expenses, uncategorized, other] = await Promise.all([
        confirmed
          ? fetchAdminProfitsBreakdownV2(params)()
          : fetchAdminProfitsBreakdown(params)(),
        confirmed
          ? fetchAdminExpensesBreakdownV2(params)(dispatch)
          : fetchAdminExpensesBreakdown(params)(dispatch),
        confirmed
          ? fetchAdminUncategorizedBreakdownV2(params)()
          : fetchAdminUncategorizedBreakdown(params)(),
        confirmed
          ? fetchAdminOtherBreakdownV2(params)()
          : fetchAdminOtherBreakdown(params)(),
        dispatch(
          fetchFilteredTransactions(
            transactionFilterToQueryUrlService({
              userId,
              startDate: params.startDate?.format(DATE_FORMATS.INPUT),
              endDate: params.endDate?.format(DATE_FORMATS.INPUT),
              limit: MAX_TRANSACTION_LIMIT,
            })
          )
        ),
      ])

      setProfitsObj(profits)
      setExpensesObj(expenses)
      setUncategorizedObj(uncategorized)
      setOtherObject(other)
    }
    fetch()
  }, [confirmed, dispatch, endDate, startDate, userId])

  useEffect(() => {
    if (csvData && csvLink?.current?.link && !csvFetching) {
      csvLink?.current?.link.click()
    }
  }, [csvData, csvFetching])

  const fetchCSVData = useCallback(async () => {
    setCsvFetching(true)

    const params = {
      userId,
      startDate: startDate ? moment(startDate, DATE_FORMATS.INPUT) : undefined,
      endDate: endDate ? moment(endDate, DATE_FORMATS.INPUT) : undefined,
    }

    const profitAndLoss = confirmed
      ? await fetchAdminProfitAndLossV2(params)()
      : await fetchAdminProfitAndLoss(params)()
    const csv: string[][] = []

    if (profitAndLoss) {
      csv.push([
        `${pageTitle} Statement for:`,
        user?.financialProfile?.businessName || '',
        `${user?.firstName} ${user?.lastName}`,
      ])
      csv.push(['', '', ''])

      csv.push(['Income', '', ''])
      csv.push(['Category', 'Account Type', 'Amount in Dollars'])

      if (profitAndLoss?.profits) {
        profitAndLoss.profits.forEach((profit) => {
          csv.push([profit.name, profit.account_type, profit.sum])
        })
      }

      csv.push(['>> Income Total', '...', profitAndLoss.profitSum.toString()])
      csv.push(['', '', ''])

      csv.push(['Expenses', '', ''])

      csv.push(['Category', 'Account Type', 'Amount in Dollars'])
      if (profitAndLoss?.expenses) {
        profitAndLoss.expenses.forEach((expense) => {
          csv.push([expense.name, expense.account_type, expense.sum])
        })
      }

      csv.push(['', '', ''])
      csv.push([
        '>> Expenses Total',
        '...',
        profitAndLoss.expensesSum.toString(),
      ])
      const netProfit =
        Number(profitAndLoss.profitSum) + Number(profitAndLoss.expensesSum) || 0
      csv.push(['', '', ''])
      csv.push(['Net Profit', '...', netProfit.toString()])

      csv.push(['Other', '', ''])

      csv.push(['Category', 'Account Type', 'Amount in Dollars'])
      if (profitAndLoss?.other) {
        profitAndLoss.other.forEach((other) => {
          csv.push([other.name, other.account_type, other.sum])
        })
      }

      csv.push(['', '', ''])
      csv.push(['>> Other Total', '...', profitAndLoss.otherSum.toString()])
    }

    setCsvData(csv)
    setCsvFetching(false)
  }, [
    confirmed,
    endDate,
    pageTitle,
    startDate,
    user?.financialProfile?.businessName,
    user?.firstName,
    user?.lastName,
    userId,
  ])

  const handleDateRangeChange = useCallback(
    ([startDate, endDate]: [string, string]) => {
      setStartDate(startDate)
      setEndDate(endDate)
    },
    []
  )

  const csvFileName = `${moment(startDate, DATE_FORMATS.INPUT).format(
    'YYYY-MM-DD'
  )}-${moment(endDate, DATE_FORMATS.INPUT).format(
    'YYYY-MM-DD'
  )}-profit-and-loss-${user?.firstName}-${user?.lastName}.csv`

  return (
    <Container id="profitAndLossReport">
      <Breadcrumb size="small">
        <Breadcrumb.Section>
          {' '}
          <Link to="/">Dashboard</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section>
          <Link to="/admin/finances/list-users">Users</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section>
          <Link to={`/admin/finances/records/${userId}`}>
            {user?.firstName} {user?.lastName}
          </Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>{pageTitle}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider />

      <div className="backdrop">
        <Grid stackable doubling className="statementTitle">
          <Grid.Row>
            <Grid.Column width={6}>
              <Header as="h2">{pageTitle} Statement</Header>
            </Grid.Column>
            <Grid.Column width={6}>
              <DatePicker
                clearable
                value={`${startDate} - ${endDate}`}
                onChange={handleDateRangeChange}
                selectsRange
                startDate={startDate}
                endDate={endDate}
              />
            </Grid.Column>

            <Grid.Column width={4}>
              <Button loading={csvFetching} onClick={fetchCSVData}>
                <Icon icon={regular('download')} style={{ marginRight: 8 }} />
                Download {pageTitle}
              </Button>
              {csvData && (
                <CSVLink
                  data={csvData}
                  filename={csvFileName}
                  className="hidden"
                  target="_blank"
                  ref={csvLink}
                />
              )}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign="center">
            <Grid.Column width={16}>
              <Header as="h4">
                {user?.financialProfile?.businessName ||
                  `${user?.firstName} ${user?.lastName}`}
              </Header>
              <Header as="h3" className="compact">
                {pageTitle} Statement
              </Header>
              <Header as="h6" className="compact">
                for the period {startDate} — {endDate}
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ProfitAndLossReports
          startDate={startDate}
          endDate={endDate}
          profitsObj={profitsObj}
          expensesObj={expensesObj}
          uncategorizedObj={uncategorizedObj}
          otherObj={otherObj}
          fetchingData={false}
          userIdForAdmin={userId}
          confirmed={confirmed}
        />
      </div>
    </Container>
  )
}

export default AdminProfitAndLossPanel
