import { Label, List, Table } from 'semantic-ui-react'
import { isNil } from 'lodash'

import CurrencyFormatLabel from '../../../../components/shared/CurrencyFormatLabel'
import { centsToDollars } from '../../../../utils/currencyHelpers'
import AdjustmentsPopup from './AdjustmentsPopup'
import CalculationListItem from './CalculationListItem'
import EditCalculationsModal from './EditCalculationsModal'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectIsCalculatorDefaultVersion,
  selectIsSingleStateCalculation,
  selectUserTaxCalculationById,
} from '../../../../selectors/userTaxCalculation.selectors'
import { CalculationOutputsModal } from '../../../Taxes/QuarterlyTaxEstimates/components/EstimateBreakdown/CalculationOutputs'

const CalculationOutputsAdjustedCell = ({
  id,
  calculationEditHidden,
}: {
  id: number
  calculationEditHidden: boolean
}) => {
  const calc = useReselector(selectUserTaxCalculationById, id)
  const isSingleStateCalc = useReselector(selectIsSingleStateCalculation, id)
  const isDefaultBreakDown = useReselector(selectIsCalculatorDefaultVersion, id)

  if (!calc) {
    return null
  }

  const { federalQuarterlyEstimateInCents, calculatedByUserId, notes } = calc

  return (
    <Table.Cell verticalAlign="top">
      <List>
        <CalculationListItem
          header="Federal Quarterly"
          value={
            !isNil(federalQuarterlyEstimateInCents) ? (
              <CurrencyFormatLabel
                value={centsToDollars(federalQuarterlyEstimateInCents)}
              />
            ) : null
          }
          width="400px"
          tooltip={
            isSingleStateCalc && isDefaultBreakDown ? (
              <AdjustmentsPopup taxCalculationId={id} isFederal />
            ) : null
          }
        />
        {calc.stateQuarterlyEstimates?.map((s) => (
          <CalculationListItem
            key={`state-qte-${s.state}`}
            header={`${s.state} Quarterly`}
            width="400px"
            value={
              typeof s.value !== 'undefined' && s.value !== null ? (
                <CurrencyFormatLabel value={s.value} />
              ) : (
                ''
              )
            }
            tooltip={
              isSingleStateCalc && isDefaultBreakDown ? (
                <AdjustmentsPopup isFederal={false} taxCalculationId={id} />
              ) : null
            }
          />
        ))}
      </List>
      {isSingleStateCalc && calculatedByUserId && (
        <Label
          style={{ margin: '1em 0', width: '100%', textAlign: 'center' }}
          basic
          color="teal"
          content="Final Estimates Overwritten"
        />
      )}
      {notes && (
        <p style={{ fontStyle: 'italic' }}>
          <span style={{ fontWeight: 'bold' }}>Notes: </span>&quot;
          {notes}&quot;
        </p>
      )}
      {isSingleStateCalc && isDefaultBreakDown && (
        <CalculationOutputsModal calc={calc} />
      )}
      <EditCalculationsModal id={id} readOnly={calculationEditHidden} />
    </Table.Cell>
  )
}

export default CalculationOutputsAdjustedCell
