import { useEffect, useState } from 'react'
import { Container, Header, Loader, Dimmer } from 'semantic-ui-react'

import { fetchAllUsersBookkeepingReports } from '../../actions/admin/bookkeepingReportActions'
import { fetchAllUsersIfNeeded } from '../../actions/admin/adminAllUsersActions'
import BookkeepingReportTable from './BookkeepingReportTable'
import { useReselector } from '../../utils/sharedHooks'
import { selectBookkeepingReports } from '../../selectors/bookkeepingReportsSelectors'
import { useAppDispatch } from '../../utils/typeHelpers'

const AllBookkeepingReportsPanel = () => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const reports = useReselector(selectBookkeepingReports)

  useEffect(() => {
    const fetch = async () => {
      await Promise.all([
        dispatch(fetchAllUsersIfNeeded()),
        dispatch(fetchAllUsersBookkeepingReports()),
      ])

      setLoading(false)
    }

    fetch()
  }, [dispatch])

  return (
    <Container id="allReportsPanel">
      <Header as="h3">All Bookkeeping Reports</Header>
      <br />
      {loading ? (
        <Dimmer active inverted>
          <Loader inverted>Loading all reports</Loader>
        </Dimmer>
      ) : (
        <BookkeepingReportTable reports={reports} />
      )}
    </Container>
  )
}

export default AllBookkeepingReportsPanel
