import { useCallback, useEffect, useState } from 'react'
import HelloSign from 'hellosign-embedded'

import {
  HelloSignResponse,
  fetchHelloSignSignatureStatus,
  fetchHelloSignUrls,
} from '../../actions/helloSignActions'
import { OnboardingTaskIdents } from '../../features/Onboarding/config'
import { useReselector, useTimeoutRef } from '../../utils/sharedHooks'
import { getUserActionItemByActionItemIdentifier } from '../../features/Dashboard/UserActionItems/userActionItems.selectors'
import {
  fetchUserActionItemsIfNeeded,
  updateUserActionItem,
} from '../../features/Dashboard/UserActionItems/userActionItems.slice'
import { SignBAAAgreementStatus } from '../../features/Dashboard/UserActionItems/userActionItemStatuses'
import { useAnalyticsTrack } from '../../features/Amplitude'
import { useAppDispatch } from '../../utils/typeHelpers'

const baaHelloSignClient = new HelloSign()
const HELLOSIGN_CLIENT_ID = process.env.VITE_HELLOSIGN_CLIENT_ID
const SKIP_DOMAIN_VERIFICATION = process.env.VITE_ENV !== 'production'

export const useHelloSignBAA = () => {
  const dispatch = useAppDispatch()
  const [fetching, setFetching] = useState(true)
  const [signUrls, setSignUrls] = useState<HelloSignResponse>()
  const [signUrlError, setSignUrlError] = useState(false)
  const [signedUrlLoading, setSignedUrlLoading] = useState(false)
  const [baaSigned, setBaaSigned] = useState(false)
  const timeoutRef = useTimeoutRef()

  const track = useAnalyticsTrack()

  useEffect(() => {
    dispatch(fetchUserActionItemsIfNeeded())
  }, [dispatch])

  const userActionItem = useReselector(
    getUserActionItemByActionItemIdentifier,
    OnboardingTaskIdents.SIGN_BAA_AGREEMENT
  )

  const baa = signUrls?.baa_url
  const onBaaSigned = useCallback(async () => {
    if (userActionItem?.id && !userActionItem.completedAt) {
      await updateUserActionItem(userActionItem.id, {
        id: userActionItem.id,
        status: SignBAAAgreementStatus.signed,
        completedAt: new Date().toISOString(),
      })(dispatch)
      track('completed onboarding item sign BAA agreement', {
        status: 'done',
      })
      track('completed todo', {
        completed_source: 'auto-completed',
        todo_description: userActionItem.actionItem.description,
      })
    }
  }, [
    dispatch,
    userActionItem?.id,
    userActionItem?.completedAt,
    userActionItem?.actionItem,
    track,
  ])

  const fetchExternal = useCallback(() => {
    fetchHelloSignUrls()(dispatch).then((signUrls) => {
      if (signUrls) {
        setSignUrls(signUrls)
        setSignUrlError(false)
      } else {
        setSignUrlError(true)
      }
      setFetching(false)
    })
  }, [dispatch])

  useEffect(() => {
    fetchExternal()
  }, [fetchExternal])

  useEffect(() => {
    const setEventHandler = () => {
      baaHelloSignClient.on('sign', async () => {
        setSignUrlError(false)
        setSignedUrlLoading(true)
        const updatedSignUrls = await fetchHelloSignUrls()(dispatch)
        setSignUrls(updatedSignUrls)
        await onBaaSigned()
        setSignedUrlLoading(false)
      })

      baaHelloSignClient.on('close', () => {
        setFetching(true)
        timeoutRef.current = setTimeout(async () => {
          const response = await fetchHelloSignSignatureStatus()(dispatch)
          if (response?.signatureStatus === 'signed') {
            setBaaSigned(true)
          } else {
            fetchExternal()
          }
          setFetching(false)
        }, 500)
      })
    }
    setEventHandler()
  }, [dispatch, onBaaSigned, timeoutRef, fetchExternal])

  useEffect(() => {
    // If the BAA is already signed go through signing flow
    if (baaSigned) {
      onBaaSigned()
    }
  }, [onBaaSigned, baaSigned])

  useEffect(() => {
    // since baa_url can take on the types string and an object, only when the baa_url.signed
    // flag is true does that indicate that the BAA has been signed.
    if (typeof baa !== 'string' && baa?.signed) {
      setBaaSigned(true)
    }
  }, [baa, signUrls])

  const baaUrl = baa && baa.url ? baa.url : ''
  const openSignatureModal = () => {
    baaHelloSignClient.open(baaUrl, {
      skipDomainVerification: SKIP_DOMAIN_VERIFICATION,
      clientId: HELLOSIGN_CLIENT_ID,
    })
  }

  return {
    fetching,
    baaSigned,
    signUrlError,
    signedUrlLoading,
    baa,
    openSignatureModal,
  }
}
