import axios from 'axios'
import {
  PrismaCursorPagination,
  CursorPaginatedResponse,
} from '../../../utils/fetchHelpers'
import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import { OrderBy } from '../../../components/Admin/PaginatedTable/AdminBaseTable'
import { serializeOrderBy } from '../../../utils/sortHelpers'

const V2_TRANSACTIONS_API_BASE = '/finances/api/v2/admin/transactions'

export type TransactionFilter = {
  userId?: number
  financialAccountId?: number
  transactionsType?: string
  transactionCategoryId?: number
  startDate?: string
  endDate?: string
  lastReviewDate?: string
  currentTaxYear?: string
  includePersonal?: boolean
  includeSplitChildren?: boolean
  notesLastUpdatedAtStart?: string
  notesLastUpdatedAtEnd?: string
  descriptionSearch?: string
  notesSearch?: string
}

export const fetchPaginatedTransactions = async ({
  filter,
  pagination,
  orderBy,
  select = [],
}: {
  filter?: TransactionFilter
  pagination?: PrismaCursorPagination
  orderBy?: OrderBy
  select?: string[]
}) => {
  const res = await axios.get<CursorPaginatedResponse<Transaction>>(
    V2_TRANSACTIONS_API_BASE,
    {
      params: {
        ...(filter ? filter : {}),
        take: 10,
        ...(pagination ? pagination : {}),
        orderBy: serializeOrderBy(orderBy),
        selectRelation: select,
      },
      paramsSerializer: {
        indexes: false,
      },
    }
  )
  return res.data
}

export const countTransactions = async (filter?: TransactionFilter) => {
  const res = await axios.get<{ count: number }>(
    `${V2_TRANSACTIONS_API_BASE}/count`,
    {
      params: {
        ...(filter ? filter : {}),
      },
      paramsSerializer: {
        indexes: false,
      },
    }
  )
  return res.data.count
}

export const fetchTransaction = async ({
  id,
  select,
}: {
  id: number
  select?: string[]
}) => {
  const res = await axios.get<Transaction>(
    `${V2_TRANSACTIONS_API_BASE}/${id}`,
    {
      params: {
        selectRelation: select,
      },
      paramsSerializer: {
        indexes: false,
      },
    }
  )
  return res.data
}
