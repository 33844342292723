import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import {
  deleteEmployee,
  fetchEmployeeOnboardingStatus,
  putEmployeeOnboardingStatus,
  fetchContractorOnboardingStatus,
  putContractorOnboardingStatus,
  deleteContractor,
} from '../payrollActions'
import { Button, Modal, Text } from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import { useReselector } from '../../../utils/sharedHooks'
import { selectTeamMemberByUuid } from '../payroll.selectors'
import { useAppDispatch } from '../../../utils/typeHelpers'
import {
  Gusto_EmployeeContractorOnboarding,
  Gusto_EmployeeContractorOnboardingStatus,
} from '../generated_gusto_types'
import OnboardEmployeeModal from './OnboardEmployeeModal'

// This modal is for employees only since it's a more complex flow

const OnboardingCTA = ({
  teamMemberUuid,
  isEmployee,
}: {
  teamMemberUuid: string
  isEmployee: boolean
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [onboardingState, setOnboardingState] =
    useState<Gusto_EmployeeContractorOnboarding>()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false)

  const teamMember = useReselector(
    selectTeamMemberByUuid,
    teamMemberUuid,
    isEmployee
  )

  // Lists the missing required steps for onboarding an employee
  const requiredIncompleteSteps = useMemo(
    () =>
      (onboardingState?.onboarding_steps || []).filter(
        (step) => !step.completed && step.required
      ),
    [onboardingState?.onboarding_steps]
  )

  // Contractors will automatically go into onboarded state once flow is complete.  Employees require another few steps
  const showCompleteOnboarding =
    isEmployee &&
    onboardingState?.onboarding_status ===
      'self_onboarding_awaiting_admin_review'

  const deleteOnboardingTeamMember = useCallback(async () => {
    if (isEmployee) {
      await deleteEmployee(teamMemberUuid)(dispatch)
    } else {
      await deleteContractor(teamMemberUuid)(dispatch)
    }
    navigate('/payroll/employees')
  }, [teamMemberUuid, isEmployee, dispatch, navigate])

  // Fetch team member onboarding status
  useEffect(() => {
    const fetch = async () => {
      const res = isEmployee
        ? await fetchEmployeeOnboardingStatus(teamMemberUuid)(dispatch)
        : await fetchContractorOnboardingStatus(teamMemberUuid)(dispatch)

      if (res) {
        setOnboardingState(res)
      }
    }
    if (!teamMember?.onboarded) {
      fetch()
    }
  }, [dispatch, isEmployee, teamMember?.onboarded, teamMemberUuid])

  useEffect(() => {
    const updateTeamMemberStatus = async (
      newStatus: Gusto_EmployeeContractorOnboardingStatus
    ) => {
      const res = isEmployee
        ? await putEmployeeOnboardingStatus(teamMemberUuid, newStatus)(dispatch)
        : await putContractorOnboardingStatus(
            teamMemberUuid,
            newStatus
          )(dispatch)

      if (res) {
        setOnboardingState(res)
      }
    }

    // https://docs.gusto.com/embedded-payroll/reference/put-v1-employees-employee_id-onboarding_status
    // https://docs.gusto.com/embedded-payroll/reference/put-v1-contractors-contractor_uuid-onboarding_status
    switch (onboardingState?.onboarding_status) {
      case 'admin_onboarding_incomplete':
      case 'self_onboarding_pending_invite':
      case 'self_onboarding_not_invited':
        // Update team member onboarding status to 'self_onboarding_invited' to allow them to onboard
        updateTeamMemberStatus('self_onboarding_invited')
        break
      case 'self_onboarding_completed_by_employee':
        updateTeamMemberStatus('self_onboarding_awaiting_admin_review')
        break
      default:
        break
    }
  }, [dispatch, isEmployee, onboardingState?.onboarding_status, teamMemberUuid])

  if (!teamMember || teamMember?.onboarded) {
    return null
  }

  const teamMemberText = isEmployee ? 'Employee' : 'Contractor'

  return (
    <Grid.Row>
      <Grid.Column width={8}>
        {onboardingState &&
          onboardingState.onboarding_status !== 'onboarding_completed' && (
            <>
              <Text>
                <b>Missing steps:</b>
              </Text>
              {requiredIncompleteSteps.map((step) => (
                <Text key={step.id} style={{ marginBottom: 5 }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&bull; {step.title}
                </Text>
              ))}
            </>
          )}
      </Grid.Column>
      {showCompleteOnboarding && (
        <>
          <Grid.Column width={6}>
            <Button onClick={() => setOnboardingModalOpen(true)} variant="link">
              Complete onboarding
            </Button>
          </Grid.Column>
          {/*We don't want this to load until the status is correct - otherwise some questions may be missing*/}
          <OnboardEmployeeModal
            open={onboardingModalOpen}
            onClose={() => setOnboardingModalOpen(false)}
            employeeUuid={teamMemberUuid}
          />
        </>
      )}
      <Grid.Column width={2}>
        <Button
          variant="link"
          onClick={() => setDeleteModalOpen(true)}
          style={{ color: Colors.red }}
        >
          Delete {teamMemberText}
        </Button>
      </Grid.Column>
      <Modal
        open={deleteModalOpen}
        size="tiny"
        closeIcon
        onClose={() => setDeleteModalOpen(false)}
      >
        <Modal.Header>Delete {teamMemberText}</Modal.Header>
        <Modal.Content>
          <Text>
            <b>
              Please confirm that you will like to remove this {teamMemberText}.
              Deleting a team member is an action that cannot be reversed.
            </b>
          </Text>
        </Modal.Content>
        <Modal.Actions>
          <Button variant="secondary" onClick={() => setDeleteModalOpen(false)}>
            Cancel
          </Button>
          <Button onClick={deleteOnboardingTeamMember} variant="warning">
            Delete {teamMemberText}
          </Button>
        </Modal.Actions>
      </Modal>
    </Grid.Row>
  )
}

export default OnboardingCTA
