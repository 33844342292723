import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  deleteAdminTransaction,
  receiveAdminAllTransactions,
  Transaction,
} from './allTransactions.slice'
import { Reconciliation } from './financialAccountsReducer'
import { isNil } from 'lodash'

export interface FinancialAccountReconciliationWithTransactions
  extends Reconciliation {
  transactions?: Transaction[]
}

export interface FinancialAccountReconciliationState {
  [key: number]: FinancialAccountReconciliationWithTransactions
}

const allFinancialAccountReconciliationsSlice = createSlice({
  name: 'allFinancialAccountReconciliations',
  initialState: {} as FinancialAccountReconciliationState,
  reducers: {
    receiveReconciliations: (
      state,
      action: PayloadAction<FinancialAccountReconciliationState>
    ) => ({ ...state, ...action.payload }),
    receiveSingleReconciliation: (
      state,
      action: PayloadAction<FinancialAccountReconciliationWithTransactions>
    ) => {
      state[action.payload.id] = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(receiveAdminAllTransactions, (state, action) => {
      const transactionsObj = action.payload.entities.transactions
      const reconciliationList = Object.values(state)

      if (transactionsObj) {
        for (const t of Object.values(transactionsObj)) {
          if (!isNil(t.reconciliationId)) {
            // Add this transaction to its reconciliation
            if (state[t.reconciliationId]) {
              if (!state[t.reconciliationId].transactions) {
                state[t.reconciliationId].transactions = []
              }

              state[t.reconciliationId].transactions?.push(t)
            }
          } else {
            // Remove this transaction from any reconciliation
            for (const r of reconciliationList) {
              const exists = r.transactions?.findIndex(
                (tran) => tran.id === t.id
              )
              if (exists && exists > -1) {
                if (state[r.id]) {
                  state[r.id].transactions?.splice(exists, 1)
                }
              }
            }
          }
        }
      }
    })

    builder.addCase(deleteAdminTransaction, (state, action) => {
      const transaction = action.payload
      if (transaction?.deletedAt) {
        const recos = Object.values(state)

        for (const r of recos) {
          const index = r.transactions?.findIndex(
            (t) => t.id === transaction.id
          )

          if (index && index > -1) {
            state[r.id].transactions?.splice(index, 1)
          }
        }
      }
    })
  },
})

export const { receiveReconciliations, receiveSingleReconciliation } =
  allFinancialAccountReconciliationsSlice.actions

export default allFinancialAccountReconciliationsSlice.reducer
