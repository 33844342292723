import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { Moment } from 'moment'
import { keyBy, uniq, uniqBy } from 'lodash'

import { Dispatch } from '../../utils/typeHelpers'
import {
  ManualTransactionModel,
  Transaction,
} from '../../reducers/admin/allTransactions.slice'
import { fetchIfNeededWrapper, fetchWrapper } from '../../reducers/fetch'
import { FilteredTransactionsPayload } from '../../actions/adminActions'
import { receiveSingleUserDocument } from '../UserDocuments/userDocuments.slice'
import {
  FinancialAccount,
  receiveCreateFinancialAccount,
} from '../../reducers/finances/financialAccountsReducer'
import { UploadedFile } from '../../components/FileUpload/FileUploadModal'

export interface TransactionsState {
  byId: { [key: string]: Transaction }
  byTransactionCategoryId: {
    [key: number]: {
      transactionIds: number[]
      totalCount: number | null
    }
    null?: {
      transactionIds: number[]
      totalCount: number | null
    }
  }
  allIds: number[]
}

const initialState: TransactionsState = {
  byId: {},
  byTransactionCategoryId: {},
  allIds: [],
}

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    receiveTransactions: (state, action: PayloadAction<Transaction[]>) => {
      state.byId = { ...state.byId, ...keyBy(action.payload, 'id') }
      state.allIds = uniq([
        ...state.allIds,
        ...action.payload.map((trans) => trans.id),
      ])
    },
    receiveSingleTransaction: (state, action: PayloadAction<Transaction>) => {
      if (!action.payload.splitFrom) {
        // updates a single parent transaction (whether it's split or not)
        return {
          ...state,
          byId: {
            ...state.byId,
            [action.payload.id]: action.payload,
          },
          allIds: uniq([...state.allIds, action.payload.id]),
        }
      } else {
        // updates a single child transaction within a split parent transaction
        const parentTxn = state.byId[action.payload.splitFrom]
        return {
          ...state,
          byId: {
            ...state.byId,
            [action.payload.splitFrom]: {
              ...state.byId[action.payload.splitFrom],
              splitTransactions: parentTxn.splitTransactions?.map((txn) =>
                txn.id === action.payload.id ? action.payload : txn
              ),
            },
          },
        }
      }
    },
    deleteTransaction: (state, action: PayloadAction<number>) => {
      delete state.byId[action.payload]
      state.allIds = state.allIds.filter((id) => id !== action.payload)
    },
    receiveTransactionsByCategory: (
      state,
      action: PayloadAction<{
        transactionCategoryId: number
        transactionIds: number[]
        count: number
      }>
    ) => {
      state.byTransactionCategoryId[action.payload.transactionCategoryId] = {
        transactionIds: action.payload.transactionIds,
        totalCount: action.payload.count,
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(receiveSingleUserDocument, (state, action) => {
      const document = action.payload.userDocument
      if (document.transactionId) {
        // Updates receipts for a non-split or parent transaction
        if (state.byId[document.transactionId]) {
          state.byId[document.transactionId].receipts = uniqBy(
            [...(state.byId[document.transactionId].receipts || []), document],
            'id'
          )
        } else {
          const childTxn = Object.values(state.byId)
            .flatMap((cg) => cg.splitTransactions)
            .find((splitT) => splitT?.id === document.transactionId)
          // Updates receipts for a split transaction
          if (childTxn) {
            childTxn.receipts = uniqBy(
              [...(childTxn.receipts || []), document],
              'id'
            )
          }
        }
      }
    })
  },
})

export default transactionsSlice.reducer

/*
  Transaction Actions
*/
export const {
  receiveTransactions,
  receiveSingleTransaction,
  receiveTransactionsByCategory,
  deleteTransaction,
} = transactionsSlice.actions

export const USER_TRANSACTION_KEY = 'USER_TRANSACTION_KEY'
export const fetchUserTransactions = (alwaysFetch = false) =>
  fetchIfNeededWrapper({
    alwaysFetch,
    fetchKey: USER_TRANSACTION_KEY,
    defaultErrorMessage: 'Error fetching all transactions',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<{
        transactions: Transaction[]
        count: number
      }>('/finances/api/v1/transactions')
      dispatch(receiveTransactions(json.data.transactions))
      return json.data
    },
  })

export const fetchFilteredUserTransactions = (
  params: Omit<FilteredTransactionsPayload, 'userId'>
) =>
  fetchWrapper({
    fetchKey: USER_TRANSACTION_KEY,
    defaultErrorMessage: 'Error fetching all transactions',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<{
        transactions: Transaction[]
        count: number
      }>('/finances/api/v1/transactions', {
        params: {
          ...params,
          startDate: params.startDate?.format('MM-DD-YYYY'),
          endDate: params.endDate?.format('MM-DD-YYYY'),
        },
      })

      dispatch(receiveTransactions(json.data.transactions))
      return json.data
    },
  })

export const UPDATE_USER_TRANSACTION_KEY = 'UPDATE_USER_TRANSACTION_KEY'
export const updateUserTransaction = (
  id: string | number,
  data: Partial<
    Omit<Transaction, 'excludedAt'> & { excludedAt?: number | null }
  >
) =>
  fetchWrapper({
    fetchKey: UPDATE_USER_TRANSACTION_KEY,
    defaultErrorMessage: 'Error updating transaction.',
    fetchFunction: async (dispatch) => {
      const json = await axios.post<Transaction>(
        `/finances/api/v1/transactions/${id}`,
        data
      )
      if (json.data.manuallyDedupedAt) {
        dispatch(deleteTransaction(json.data.id))
      } else {
        dispatch(receiveSingleTransaction(json.data))
      }

      return json.data
    },
  })

const USER_TRANSACTIONS_BY_CATEGORY_ID_KEY =
  'USER_TRANSACTIONS_BY_CATEGORY_ID_KEY'
export const fetchUserTransactionsByCategoryId = ({
  id,
  limit,
  startDate,
  endDate,
}: {
  id: number | null
  limit: string | null
  startDate: Moment | undefined
  endDate: Moment | undefined
}) =>
  fetchWrapper({
    fetchKey: `${USER_TRANSACTIONS_BY_CATEGORY_ID_KEY}${id || ''}`,
    defaultErrorMessage: 'Error updating transaction.',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<{
        transactionCategoryId: number
        transactionIds: number[]
        count: number
      }>('/finances/api/v1/transactions/by_category', {
        params: {
          startDate: startDate?.format('YYYY-MM-DD'),
          endDate: endDate?.format('YYYY-MM-DD'),
          limit,
          transactionCategoryId: id,
        },
      })
      dispatch(receiveTransactionsByCategory(json.data))
      return json.data
    },
  })

export const CREATE_MANUAL_TRANSACTION_KEY = 'CREATE_MANUAL_TRANSACTION_KEY'
// The calling function is responsible for implementing try/catch
export const createSingleManualTransaction = (
  data: Omit<ManualTransactionModel, 'receipts'> & {
    receipt?: UploadedFile | null
  }
) =>
  fetchWrapper({
    fetchKey: CREATE_MANUAL_TRANSACTION_KEY,
    fetchFunction: async (dispatch: Dispatch) => {
      const json = await axios.post<{
        transaction: Transaction
        financialAccount: FinancialAccount
      }>('/finances/api/v1/manual_transactions/create_single', data)
      dispatch(receiveSingleTransaction(json.data.transaction))
      dispatch(receiveCreateFinancialAccount(json.data.financialAccount))
      return json.data.transaction
    },
  })

// The calling function is responsible for implementing try/catch
export const createMultipleManualTransactions =
  (data: Array<ManualTransactionModel>) => async (dispatch: Dispatch) => {
    const json = await axios.post<{
      transactions: Transaction[]
      financialAccount: FinancialAccount
    }>('/finances/api/v1/manual_transactions/create_multiple', data)

    dispatch(receiveTransactions(json.data.transactions))
    dispatch(receiveCreateFinancialAccount(json.data.financialAccount))
    return json.data.transactions
  }

// The calling function is responsible for implementing try/catch
export const validateSingleManualTransaction = async (
  data: ManualTransactionModel
) => {
  const json = await axios.post<{
    errors: string[]
    warnings: {
      spreadsheetDuplicates: { [key: string]: number }
      databaseDuplicates: string[]
    }
  }>('/finances/api/v1/manual_transactions/validate_single', data)
  return json.data
}

// The calling function is responsible for implementing try/catch
export const validateMultipleManualTransactions = async (
  data: Array<ManualTransactionModel>
) => {
  const json = await axios.post<{
    errors: string[]
    warnings: {
      spreadsheetDuplicates: { [key: string]: number }
      databaseDuplicates: string[]
    }
  }>('/finances/api/v1/manual_transactions/validate_multiple', data)
  return json.data
}

const USER_EOY_REVIEW_TRANSACTION_KEY = 'USER_EOY_REVIEW_TRANSACTION_KEY'
export const fetchUserEndOfYearReviewTransactions = (year: string) =>
  fetchIfNeededWrapper({
    fetchKey: USER_EOY_REVIEW_TRANSACTION_KEY,
    defaultErrorMessage: 'Error fetching all eoy review transactions',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<{
        transactions: Transaction[]
        count: number
      }>(`/finances/api/v1/transactions/end_of_year_review?year=${year}`)

      dispatch(receiveTransactions(json.data.transactions))
      return json.data
    },
  })

export const VALIDATE_DUPLICATE_TRANSACTION_KEY =
  'VALIDATE_DUPLICATE_TRANSACTION_KEY'
/**
 * Defined as having the same category, date and amount
 */
export const validateDuplicateTransactions = (
  data: Partial<Transaction>,
  transactionId?: number
) =>
  fetchWrapper({
    fetchKey: VALIDATE_DUPLICATE_TRANSACTION_KEY,
    fetchFunction: async () => {
      const json = await axios.post<Transaction[]>(
        '/finances/api/v1/transactions/end_of_year_review/list_duplicates',
        data
      )
      return transactionId
        ? json.data.filter((t) => t.id !== transactionId)
        : json.data
    },
  })

export const DELETE_MANUAL_TRANSACTION_KEY = 'DELETE_MANUAL_TRANSACTION_KEY'
export const deleteManualTransaction = (id: string | number) =>
  fetchWrapper({
    defaultErrorMessage: 'Error deleting transaction',
    fetchKey: DELETE_MANUAL_TRANSACTION_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.delete<string>(
        `/finances/api/v1/manual_transactions/delete_single/${id}`
      )
      dispatch(deleteTransaction(Number(json.data)))
      return true
    },
  })

export const RESTORE_MANUAL_TRANSACTION_KEY = 'RESTORE_MANUAL_TRANSACTION_KEY'
export const restoreManualTransaction = (id: number) =>
  fetchWrapper({
    defaultErrorMessage: 'Error restoring transaction',
    fetchKey: RESTORE_MANUAL_TRANSACTION_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.patch<Transaction>(
        `/finances/api/v1/manual_transactions/restore_single/${id}`
      )
      dispatch(receiveSingleTransaction(json.data))
      return true
    },
  })
