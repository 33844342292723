import { Grid } from 'semantic-ui-react'
import moment from 'moment'

import { getContractorName } from '../helpers'
import { formatCurrency } from '../../../utils/currencyHelpers'
import { Alert, Table, Text } from '../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import {
  Gusto_Contractor,
  Gusto_ContractorPayment,
} from '../generated_gusto_types'

const ContractorPaymentsTab = ({
  contractor,
  contractorPayments,
}: {
  contractor: Gusto_Contractor
  contractorPayments: Gusto_ContractorPayment[]
}) => (
  <Grid>
    <Grid.Row>
      <Grid.Column>
        <Text as="bodyMd">
          This lists all payments you’ve made to {getContractorName(contractor)}
        </Text>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <Table basic>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>Pay Day</Table.HeaderCell>
              <Table.HeaderCell width={2}>Hours</Table.HeaderCell>
              <Table.HeaderCell width={3}>Wages</Table.HeaderCell>
              <Table.HeaderCell width={3}>Bonus</Table.HeaderCell>
              <Table.HeaderCell width={3}>Reimbursements</Table.HeaderCell>
              <Table.HeaderCell textAlign="right" width={3}>
                Total
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {contractorPayments.map((payment) => {
              const wages =
                Number(payment.wage) ||
                Number(payment.hourly_rate) * Number(payment.hours)
              const total =
                wages + Number(payment.bonus) + Number(payment.reimbursement)
              return (
                <Table.Row key={payment.uuid}>
                  <Table.Cell>
                    {moment(payment.date).format(DATE_FORMATS.DISPLAY_SHORT)}
                  </Table.Cell>
                  <Table.Cell>{payment.hours}</Table.Cell>
                  <Table.Cell>{formatCurrency(wages)}</Table.Cell>
                  <Table.Cell>{formatCurrency(payment.bonus)}</Table.Cell>
                  <Table.Cell>
                    {formatCurrency(payment.reimbursement)}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <b>{formatCurrency(total)}</b>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Grid.Column>
    </Grid.Row>
    {!contractorPayments.length && (
      <Grid.Row>
        <Grid.Column>
          <Alert title="Pay Stubs">
            All future contractor payment details will display here.
          </Alert>
        </Grid.Column>
      </Grid.Row>
    )}
  </Grid>
)

export default ContractorPaymentsTab
