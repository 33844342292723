import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReduxState } from '../../utils/typeHelpers'
import { DateTime } from 'luxon'
import { DATE_FORMATS_LUXON } from '../../utils/dateHelpers'

export interface JournalEntryLine {
  id: number
  transactionCategoryId: number | null
  financialAccountId: number | null
  entryType: string
  amountInCents: number
  journalEntry: {
    id: number
    journalEntryDate: string
    details: string
    automated: boolean
    transaction?: {
      id: number
      description: string
    }
  }
}

export interface JournalEntryLineState {
  [journalEntryLineId: number]: JournalEntryLine
}

const journalEntryLineSlice = createSlice({
  name: 'journalEntryLines',
  initialState: {} as JournalEntryLineState,
  reducers: {
    receiveJournalEntryLines: (
      state,
      action: PayloadAction<JournalEntryLineState>
    ) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export default journalEntryLineSlice.reducer

export const { receiveJournalEntryLines } = journalEntryLineSlice.actions

// For ALL tax team members.  Think of as smaller admin DB
export const selectJournalEntryLines = (state: ReduxState) =>
  state.journalEntryLines

export const selectJournalEntrLineByDate = createSelector(
  selectJournalEntryLines,
  (_: unknown, startDate: string | undefined) => startDate,
  (_: unknown, __: unknown, endDate: string | undefined) => endDate,
  (journalEntryLines, startDate, endDate) => {
    let filteredJournalEntryLines = Object.values(journalEntryLines)
    if (startDate) {
      filteredJournalEntryLines = filteredJournalEntryLines.filter(
        (journalEntry) =>
          DateTime.fromISO(
            journalEntry.journalEntry.journalEntryDate
          ).toUTC() >=
          DateTime.fromFormat(startDate, DATE_FORMATS_LUXON.INPUT)
            .startOf('day')
            .toUTC()
      )
    }
    if (endDate) {
      filteredJournalEntryLines = filteredJournalEntryLines.filter(
        (journalEntry) =>
          DateTime.fromISO(
            journalEntry.journalEntry.journalEntryDate
          ).toUTC() <=
          DateTime.fromFormat(endDate, DATE_FORMATS_LUXON.INPUT)
            .endOf('day')
            .toUTC()
      )
    }
    return filteredJournalEntryLines
  }
)

export const selectJournalEntryLineIdsByCategoryId = createSelector(
  selectJournalEntrLineByDate,
  (
    _: unknown,
    __: unknown,
    ___: unknown,
    categoryId?: number | string | null
  ) => categoryId,
  (journalEntryLines, categoryId) =>
    journalEntryLines
      .filter(
        (journalEntry) => journalEntry.transactionCategoryId === categoryId
      )
      .map((journalEntry) => journalEntry.id)
)

export const selectJournalEntrLineById = createSelector(
  selectJournalEntryLines,
  (_: unknown, id: number) => id,
  (journalEntryLines, id) => journalEntryLines[id] ?? null
)
