import { Grid, Image } from 'semantic-ui-react'
import { GridRowColumn, Text } from '../../../../components/BaseComponents'
import { TAX_PROFILE_STEP, useTaxProfileSteps } from './helpers'
import TaxProfileFormFooter from './TaxProfileFormFooter'
import TaxProfileAccordions, {
  useTaxProfileAccordionCopy,
} from './TaxProfileAccordions'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'

const PersonalTaxIncomeIntro = () => {
  const { goToPreviousStep, goToNextStep } = useTaxProfileSteps(
    TAX_PROFILE_STEP.personalTaxIncomeIntro
  )

  const faqCopy = useTaxProfileAccordionCopy()

  return (
    <Grid>
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/orange_paystub.svg"
          style={{ height: 120, width: 120 }}
        />
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([12, 16, 16], true)} short>
        <Text as="display2" textAlign="center">
          Next, we’ll ask you to enter your taxable income.
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([12, 16, 16], true)} short>
        <Text as="bodyLg" textAlign="center">
          If you have W-2 income, we recommend grabbing your{' '}
          <b>most recent paystub</b> for this step.
        </Text>
      </GridRowColumn>

      <Grid.Row />

      <TaxProfileAccordions
        faqs={[
          faqCopy.canUseOldPaystub,
          faqCopy.whereFindOldPaystub,
          faqCopy.whatIfDoNotEarnW2,
        ]}
      />

      <TaxProfileFormFooter
        submitOrContinue={() => goToNextStep()}
        goToPreviousStep={goToPreviousStep}
      />
    </Grid>
  )
}

export default PersonalTaxIncomeIntro
