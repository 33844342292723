import { ReactNode } from 'react'
import { Grid, Loader } from 'semantic-ui-react'

import { GridRowColumn } from '../../../../components/BaseComponents'

const File1099sPage = ({
  isLoading,
  contentHeader,
  content,
}: {
  isLoading: boolean
  contentHeader: ReactNode
  content: ReactNode
}) => {
  const loadingAnimation = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Loader centered inline active />
    </div>
  )

  return (
    <Grid>
      <GridRowColumn
        verticalAlign="middle"
        columnStyle={{
          display: 'inline-flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {contentHeader}
      </GridRowColumn>
      <GridRowColumn>{isLoading ? loadingAnimation : content} </GridRowColumn>
    </Grid>
  )
}

export default File1099sPage
