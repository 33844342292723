import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import {
  Alert,
  FormikInput,
  FormikLabelError,
  FormikRadioToggleButton,
  getFieldName,
  GridRowColumn,
  makeNumberSchema,
  makeReqBoolSchema,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { getFinancialProfile } from '../../../../selectors/user.selectors'
import {
  UPDATE_FINANCIAL_PROFILE_KEY,
  updateFinancialProfile,
} from '../../../../actions/financialProfileActions'
import {
  getProjectedW2Income,
  TAX_PROFILE_STEP,
  TaxesProfileFlowAnswerProps,
  useTaxProfileSteps,
} from './helpers'
import {
  dollarsToCents,
  formatCurrency,
} from '../../../../utils/currencyHelpers'
import TaxProfileFormFooter from './TaxProfileFormFooter'
import { getFetchError } from '../../../../reducers/fetch'
import TaxProfileAccordions, {
  useTaxProfileAccordionCopy,
} from './TaxProfileAccordions'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { selectActiveQuarterlyTaxEstimateDetails } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { LabelDescription } from '../../../../components/BaseComponents/Input'
import { W2IncomeWarning } from './W2IncomeWarning'
import { useAnalyticsTrack } from '../../../Amplitude'
import { QuarterlyTaxEstimateDetail } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import PaystubVsEstimateAccordion, {
  PaystubAccordionContent,
} from './PaystubVsEstimateAccordion'

const scorpW2IncomeCopy = (
  activeQuarterDetails?: QuarterlyTaxEstimateDetail
) => {
  switch (activeQuarterDetails?.taxQuarter) {
    case '1':
      return "Enter income earned through your private practice for pay periods starting January 1st through February 28th. You'll find this on the first paycheck you paid yourself in March."
    case '2':
      return "Enter income earned through your private practice for pay periods starting January 1st through April 30th. You'll find this on the first paycheck you paid yourself in May."
    case '3':
      return "Enter income earned through your private practice for pay periods starting January 1st through July 31st. You'll find this on the first paycheck you paid yourself in August."
    case '4':
      return "Enter income earned through your private practice for pay periods starting January 1st through November 30st. You'll find this on the first paycheck you paid yourself in December."
    // should never happen, but fall back to generic copy
    default:
      return 'Please enter income from your most recent paystub. Do not enter your net profit.'
  }
}

const ScorpW2PrivatePracticeIncome = ({
  formFlowAnswers,
  setFormFlowAnswers,
}: TaxesProfileFlowAnswerProps) => {
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()
  const fp = useReselector(getFinancialProfile)
  const error = useReselector(
    getFetchError,
    UPDATE_FINANCIAL_PROFILE_KEY(fp?.id)
  )
  const { goToPreviousStep, goToNextStep } = useTaxProfileSteps(
    TAX_PROFILE_STEP.scorpW2Earnings
  )

  const activeQuarterDetails = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )

  const scorpW2IncomeSubcopy = scorpW2IncomeCopy(activeQuarterDetails)

  const someIncomeEntered =
    formFlowAnswers.scorpPracticeWIncome !== null ||
    formFlowAnswers.estimatedAnnualScorpPracticeW2Income !== null

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      enterScorpPaystubW2: someIncomeEntered
        ? formFlowAnswers.scorpPracticeWIncome !== null
        : undefined,
      scorpPracticeWIncome: formFlowAnswers.scorpPracticeWIncome,
      estimatedAnnualScorpPracticeW2Income:
        formFlowAnswers.estimatedAnnualScorpPracticeW2Income,
    },
    onSubmit: async ({
      enterScorpPaystubW2,
      scorpPracticeWIncome,
      estimatedAnnualScorpPracticeW2Income,
    }) => {
      const res = await dispatch(
        updateFinancialProfile(fp?.id, {
          scorpPracticeWIncomeInCents: enterScorpPaystubW2
            ? dollarsToCents(scorpPracticeWIncome)
            : null,
          estimatedAnnualScorpPracticeW2IncomeInCents: enterScorpPaystubW2
            ? null
            : dollarsToCents(estimatedAnnualScorpPracticeW2Income),
        })
      )

      if (res) {
        const newAnswers = {
          ...formFlowAnswers,
          scorpPracticeWIncome,
          estimatedAnnualScorpPracticeW2Income,
        }

        if (enterScorpPaystubW2) {
          newAnswers.estimatedAnnualScorpPracticeW2Income = null
        } else {
          newAnswers.scorpPracticeWIncome = null
        }

        setFormFlowAnswers(newAnswers)
        track('scorp all w2 income submitted', {
          scorp_practice_w_income_in_cents: enterScorpPaystubW2
            ? dollarsToCents(scorpPracticeWIncome)
            : null,
          enter_scorp_paystub_w2: Boolean(enterScorpPaystubW2),
          estimated_annual_scorp_practice_w_income: enterScorpPaystubW2
            ? null
            : dollarsToCents(estimatedAnnualScorpPracticeW2Income),
          user_id: fp?.userId ?? null,
        })
        goToNextStep(res)
      }
    },
  })

  const { values, isSubmitting, isValid, submitForm, touched } = formik

  const projectedScorpW2Income = getProjectedW2Income({
    ytdIncomeInDollars: values.scorpPracticeWIncome,
    quarterDetails: activeQuarterDetails,
  })

  const faqCopy = useTaxProfileAccordionCopy()

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn {...makeGridConfig([12, 16, 16], true)}>
          <Text as="display2" textAlign="center">
            Your S corporation W-2 earnings
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([12, 16, 16], true)}>
          <Text as="bodyLg" textAlign="center">
            We need to know your income for the year to calculate your tax
            bracket. You can tell us how much you’ve made so far by entering the
            year-to-date (YTD) amount from your paystub, or an estimate of how
            much you will make for the entire year.
          </Text>
        </GridRowColumn>
        {error && (
          <GridRowColumn {...makeGridConfig([12, 16, 16], true)}>
            <Alert type="error">{error.message}</Alert>
          </GridRowColumn>
        )}
        <GridRowColumn />
        <GridRowColumn {...makeGridConfig([8, 12, 16], true)}>
          <FormikLabelError
            name={getFieldName<typeof values>('enterScorpPaystubW2')}
            label="How would you like to enter income?"
            description="Click on “How should I choose?” below if you need help deciding how to enter income."
            schema={makeReqBoolSchema()}
          />
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([8, 12, 16], true)} short>
          <FormikRadioToggleButton
            name={getFieldName<typeof values>('enterScorpPaystubW2')}
            value
            fullWidth
          >
            Year-to-date from paystub
          </FormikRadioToggleButton>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([8, 12, 16], true)} short>
          <FormikRadioToggleButton
            name={getFieldName<typeof values>('enterScorpPaystubW2')}
            value={false}
            fullWidth
          >
            Estimated income
          </FormikRadioToggleButton>
        </GridRowColumn>

        <Grid.Row />

        {values.enterScorpPaystubW2 && (
          <GridRowColumn {...makeGridConfig([8, 12, 16], true)}>
            <Grid>
              <GridRowColumn>
                <LabelDescription
                  required
                  label="Year-to-Date W-2 gross income earned as an employee of your S corp"
                  description={scorpW2IncomeSubcopy}
                />
              </GridRowColumn>
              <Grid.Row>
                <Grid.Column width={8}>
                  <FormikInput
                    name={getFieldName<typeof values>('scorpPracticeWIncome')}
                    required
                    fullWidth
                    componentType="currency"
                    placeholder="$"
                    schema={makeNumberSchema({
                      allowedDecimals: 2,
                      greaterThanZero: true,
                    })}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Alert
                    style={{
                      padding: 15,
                      border: 'green 1px solid !important',
                    }}
                    customIcon={' '} //no icon
                    title={
                      projectedScorpW2Income
                        ? formatCurrency(projectedScorpW2Income, {
                            hideDecimalsIfZero: true,
                          })
                        : '$--'
                    }
                  >
                    projected annually
                  </Alert>
                </Grid.Column>
              </Grid.Row>

              <W2IncomeWarning
                inputTouched={touched.scorpPracticeWIncome}
                w2Income={values.scorpPracticeWIncome}
                isScorpW2Input
              />
            </Grid>
          </GridRowColumn>
        )}

        {values.enterScorpPaystubW2 === false && (
          <GridRowColumn {...makeGridConfig([8, 12, 16], true)}>
            <FormikInput
              name={getFieldName<typeof values>(
                'estimatedAnnualScorpPracticeW2Income'
              )}
              required
              fullWidth
              componentType="currency"
              schema={makeNumberSchema({
                allowedDecimals: 2,
                greaterThanZero: true,
              })}
              placeholder="$"
              label={`${activeQuarterDetails?.taxYear} Annual gross income for W-2 S corporation`}
              description="Enter in the expected W-2 gross income you expect to earn as an employee of your S corp. Do not enter your net profit."
            />
          </GridRowColumn>
        )}

        <Grid.Row />
        <GridRowColumn {...makeGridConfig([12, 16, 16], true)}>
          <PaystubVsEstimateAccordion content={PaystubAccordionContent.scorp} />
        </GridRowColumn>
        <Grid.Row />
        <TaxProfileAccordions
          faqs={[
            faqCopy.onlyWantIncludeBusinessProfit,
            faqCopy.whyNeedEnterPaystubIncome,
            faqCopy.howDoIEstimate,
            faqCopy.whatIsYearToDate,
            faqCopy.whatIsGrossIncome,
          ]}
        />

        <TaxProfileFormFooter
          submitOrContinue={submitForm}
          goToPreviousStep={goToPreviousStep}
          continueDisabled={!isValid || isSubmitting}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default ScorpW2PrivatePracticeIncome
