import axios from 'axios'
import { fetchWrapper } from '../reducers/fetch'

export interface BaaUrl {
  url: string
  fileUrl: string | null
  signed: boolean
  signatureId: string
  detailsUrl: string
}

export interface HelloSignResponse {
  baa_url: '' | BaaUrl
  service_url: ''
  error?: 'hellosign failures'
  unsignedCount?: number
}

export const fetchHelloSignUrls = () =>
  fetchWrapper({
    defaultErrorMessage: 'Unable to fetch urls',
    fetchFunction: async () => {
      const json = await axios.get<HelloSignResponse>(
        '/finances/api/v1/signatures'
      )
      return json.data
    },
  })

export const fetchHelloSignSignatureStatus = () =>
  fetchWrapper({
    defaultErrorMessage: 'Unable to fetch status',
    fetchFunction: async () => {
      const json = await axios.get<{ signatureStatus: string | null }>(
        '/finances/api/v1/signatures/status'
      )
      return json.data
    },
  })

/*
  Admin actions
*/
export const viewHelloSignUrls = (userId: number) =>
  fetchWrapper({
    defaultErrorMessage: 'Unable to fetch urls',
    defaultValue: false,
    fetchFunction: async () => {
      const response = await axios.get<HelloSignResponse>(
        `/finances/api/v1/admin/signatures/${userId}`
      )
      return response.data
    },
  })
