import { useEffect, useState } from 'react'
import {
  Card,
  Header,
  Button,
  Loader,
  Grid,
  Popup,
  Modal,
  Label,
  Icon,
  Message,
  Form,
} from 'semantic-ui-react'

import {
  fetchUserFinancialAccounts,
  createUserFinancialAccount,
} from '../../../actions/admin/adminFinancialAccountActions'
import AccountCard from '../../Finances/Accounts/AccountCard'
import { User } from '../../../reducers/auth/userReducer'
import { filterNulls, useAppDispatch } from '../../../utils/typeHelpers'
import { useReselector } from '../../../utils/sharedHooks'
import {
  getManualFinancialAccountsByUserId,
  getPlaidOnlyFinancialAccountsByUserId,
  selectAdminPlaidItemsByUserId,
} from '../../../selectors/financeSelectors'
import { Dropdown } from '../../BaseComponents'
import { fetchUserPlaidItems } from '../../../actions/admin/adminPlaidItemActions'
import { AdminInstitutionCard } from '../../Finances/Accounts/AdminInstitutionCard'

const ACCOUNT_TYPE_OPTIONS = [
  { text: 'depository', value: 'depository' },
  { text: 'credit', value: 'credit' },
  { text: 'loan', value: 'loan' },
]

const ACCOUNT_SUBTYPE_OPTIONS = [
  { text: 'checking', value: 'checking' },
  { text: 'savings', value: 'savings' },
  { text: 'credit card', value: 'credit card' },
  { text: 'line of credit', value: 'line of credit' },
  { text: 'auto', value: 'auto' },
]

const FinancialAccountCreateModal = ({
  user,
  open,
  close,
}: {
  user: User
  open: boolean
  close: () => void
}) => {
  const dispatch = useAppDispatch()

  const [plaidInstitutionName, setPlaidInstitutionName] = useState('')
  const [name, setName] = useState('')
  const [mask, setMask] = useState('')
  const [type, setType] = useState('')
  const [subtype, setSubtype] = useState('')
  const [created, setCreated] = useState(false)
  const [plaidAccountId, setPlaidAccountId] = useState<number>()

  const plaidAccounts = useReselector(
    getPlaidOnlyFinancialAccountsByUserId,
    user.id
  )

  const plaidAccountOptions = plaidAccounts.map((account) => ({
    text: `${account.plaidInstitutionName} ${account.name}: ${account.mask}`,
    value: account.id,
  }))

  const handleCreateAccount = async () => {
    if (!user.financialProfile?.id) {
      return
    }

    const res = await createUserFinancialAccount(user.id, {
      financialProfileId: user.financialProfile?.id,
      accountId: 'manual',
      accountType: 'manual',
      plaidInstitutionName,
      name,
      mask,
      type,
      subtype,
      plaidAccountId,
    })(dispatch)
    if (res) {
      setCreated(true)
    }
  }

  useEffect(() => {
    if (plaidAccountId) {
      const plaidAccount = plaidAccounts.find(
        (account) => account.id === plaidAccountId
      )
      setPlaidInstitutionName(plaidAccount?.plaidInstitutionName || '')
      setName(`Manual ${plaidAccount?.name || ''}`)
      setMask(plaidAccount?.mask || '')
      setType(plaidAccount?.type || '')
      setSubtype(plaidAccount?.subtype || '')
    }
  }, [plaidAccountId, plaidAccounts])

  const onClose = () => {
    setCreated(false)
    close()
  }

  const renderContent = () => {
    if (created) {
      return (
        <Modal.Content>
          <Icon name="check circle" color="green" size="large" />
          <p>
            Account successfully created! You can now upload transactions
            manually.
          </p>
        </Modal.Content>
      )
    } else {
      return (
        <Modal.Content>
          <Message warning>
            <b>
              Warning: You are creating a manual financial account for this
              user.
            </b>
            <br />
            This means that the user is unable to connect this account via
            Plaid, and this account now requires you to manually upload
            transactions
            <br />
            <b>Please only use as a last resort.</b>
          </Message>
          <Form>
            <Form.Group>
              <Dropdown
                fluid
                clearable
                label="Plaid Connected Account"
                placeholder="Select Account"
                options={plaidAccountOptions}
                onChange={setPlaidAccountId}
                value={plaidAccountId}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                required
                fluid
                label="Institution Name"
                placeholder="Institution Name"
                onChange={(name, target) =>
                  setPlaidInstitutionName(target.value)
                }
                name="plaidInstitutionName"
                value={plaidInstitutionName}
              />
              <Form.Input
                required
                fluid
                label="Account Name"
                placeholder="Account Name"
                onChange={(name, target) => setName(target.value)}
                name="name"
                value={name}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                width={8}
                required
                fluid
                label="Last 4 numbers of Account"
                placeholder="****"
                onChange={(name, target) => setMask(target.value)}
                name="mask"
                value={mask}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Dropdown
                required
                fluid
                label="Acount Type"
                placeholder="Select Account Type"
                options={ACCOUNT_TYPE_OPTIONS}
                onChange={setType}
                value={type}
              />
              <Dropdown
                required
                fluid
                label="Account Subtype"
                placeholder="Select Subtype"
                options={ACCOUNT_SUBTYPE_OPTIONS}
                onChange={setSubtype}
                value={subtype}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
      )
    }
  }

  return (
    <Modal
      size={'small'}
      dimmer={'inverted'}
      className="financialAccountCreateModal"
      open={open}
      onClose={onClose}
    >
      <Modal.Header>
        <Header as="h4">
          Create a Manual Financial Account for {user.firstName} {user.lastName}
        </Header>
      </Modal.Header>
      {renderContent()}
      <Modal.Actions>
        <Button onClick={onClose}>{created ? 'Close' : 'Cancel'}</Button>
        {!created && (
          <Button
            primary
            disabled={
              !subtype || !type || !mask || !name || !plaidInstitutionName
            }
            onClick={handleCreateAccount}
          >
            Create Account
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  )
}

const FinancialAccountsCard = ({ user }: { user: User }) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)

  const manualAccounts = useReselector(
    getManualFinancialAccountsByUserId,
    user.id
  )

  const adminPlaidItemsByUserId = useReselector(
    selectAdminPlaidItemsByUserId,
    user.id
  )

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchUserFinancialAccounts(user.id))
      await dispatch(fetchUserPlaidItems(user.id))
      setLoading(false)
    }
    fetch()
  }, [dispatch, user.id])

  return (
    <Card fluid className="financialAccountsCard">
      <Card.Content>
        <Grid stackable doubling className="noPadding">
          <Grid.Row>
            <Grid.Column width={6} verticalAlign="middle">
              <Header as="h4">Financial Institutions</Header>
            </Grid.Column>
            <Grid.Column
              width={6}
              verticalAlign="middle"
              floated="right"
              textAlign="right"
            >
              <div>
                <Button className="link" onClick={() => setModalOpen(true)}>
                  Add Manual Account
                </Button>
                <Popup
                  trigger={
                    <Label $circular>
                      <Icon name="question" style={{ margin: 0 }} />
                    </Label>
                  }
                  content="Use for accounts that cannot be connected via Plaid"
                  basic
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched columns={3} verticalAlign="top" className="short">
            {adminPlaidItemsByUserId &&
              !loading &&
              filterNulls(adminPlaidItemsByUserId).map((institution) => (
                <Grid.Column stretched key={institution.id}>
                  <AdminInstitutionCard
                    plaidItem={institution}
                    userId={user.id}
                  />
                </Grid.Column>
              ))}
            {manualAccounts &&
              !loading &&
              filterNulls(manualAccounts).map((account) => (
                <Grid.Column stretched key={account.id}>
                  <AccountCard account={account} isAdmin userId={user.id} />
                </Grid.Column>
              ))}
          </Grid.Row>
          {loading && <Loader active inline="centered" />}
        </Grid>
        <FinancialAccountCreateModal
          close={() => setModalOpen(false)}
          open={modalOpen}
          user={user}
        />
      </Card.Content>
    </Card>
  )
}

export default FinancialAccountsCard
