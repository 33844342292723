import { Grid, Image } from 'semantic-ui-react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Accordion,
  Card,
  GridColumnSpacer,
  GridRowColumn,
  Icon,
  Link,
  Text,
} from '../../../../components/BaseComponents'

const CheckListItem = ({ text }: { text: string }) => (
  <GridRowColumn columnStyle={{ display: 'flex', alignItems: 'center' }}>
    <Icon icon={light('check')} color="green" style={{ marginRight: 20 }} />
    <Text>{text}</Text>
  </GridRowColumn>
)

export enum PaystubAccordionContent {
  scorp,
  sprop,
  spouse,
}

const PaystubVsEstimateAccordion = ({
  content,
}: {
  content: PaystubAccordionContent
}) => (
  <Accordion
    title="How should I choose?"
    content={
      <Grid>
        <Grid.Row>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <Card
              backgroundColor="natural"
              type="subsection"
              style={{ height: '100%' }}
            >
              <Grid>
                <GridRowColumn centerContent>
                  <Image
                    src="https://heard-images.s3.amazonaws.com/assets/green_check.svg"
                    style={{ height: 100, width: 100 }}
                  />
                </GridRowColumn>
                <GridRowColumn>
                  <Text as="h2" textAlign="center">
                    Year-to-date from paystub
                  </Text>
                </GridRowColumn>
                <GridRowColumn short>
                  <Text textAlign="center">
                    {content === PaystubAccordionContent.spouse
                      ? 'How much your spouse has made so far this year'
                      : 'How much you’ve made so far this year'}
                  </Text>
                </GridRowColumn>
                <GridRowColumn>
                  <Text>
                    {content === PaystubAccordionContent.spouse
                      ? 'Choose this if your spouse:'
                      : 'Choose this if you:'}
                  </Text>
                </GridRowColumn>
                {content === PaystubAccordionContent.scorp && (
                  <>
                    <CheckListItem text="Have received payroll payments from your S Corporation for the entire year" />
                    <CheckListItem text="Don’t expect the amount you pay yourself via payroll to change this year" />
                  </>
                )}
                {content === PaystubAccordionContent.sprop && (
                  <>
                    <CheckListItem text="Have a job that doesn’t vary in pay throughout the year" />
                    <CheckListItem text="Don’t expect your gross income to significantly change this year" />
                  </>
                )}
                {content === PaystubAccordionContent.spouse && (
                  <>
                    <CheckListItem text="Has a job that doesn’t vary in pay throughout the year" />
                    <CheckListItem text="Doesn’t expect their gross income to significantly change this year" />
                  </>
                )}
                <GridRowColumn>
                  <Link
                    href="https://heard-images.s3.amazonaws.com/assets/example_paystub.svg"
                    newPage
                    as="secondaryLink"
                  >
                    View example paystub
                  </Link>
                </GridRowColumn>
              </Grid>
            </Card>
          </Grid.Column>
          <GridColumnSpacer tablet mobile />
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <Card
              backgroundColor="natural"
              type="subsection"
              style={{ height: '100%' }}
            >
              <Grid>
                <GridRowColumn centerContent>
                  <Image
                    src="https://heard-images.s3.amazonaws.com/assets/green_calendar.svg"
                    style={{ height: 100, width: 100 }}
                  />
                </GridRowColumn>
                <GridRowColumn>
                  <Text as="h2" textAlign="center">
                    Estimated income
                  </Text>
                </GridRowColumn>
                <GridRowColumn short>
                  <Text textAlign="center">
                    {content === PaystubAccordionContent.spouse
                      ? 'How much you estimate your spouse will make for the year'
                      : 'How much you estimate you’ll make for the year'}
                  </Text>
                </GridRowColumn>
                <GridRowColumn>
                  <Text>
                    {' '}
                    {content === PaystubAccordionContent.spouse
                      ? 'Choose this if your spouse:'
                      : 'Choose this if you:'}
                  </Text>
                </GridRowColumn>

                {content === PaystubAccordionContent.scorp && (
                  <>
                    <CheckListItem text="Earn income that is variable throughout the year (e.g. hourly income where hours are variable, frequent commissions or bonuses)" />
                    <CheckListItem text="Had or expect changes to the amount you pay yourself from your S Corporation this year (e.g. significant raise, parental or other leave)" />
                    <CheckListItem text="Started paying yourself via W-2 partially through the year" />
                  </>
                )}
                {content === PaystubAccordionContent.sprop && (
                  <>
                    <CheckListItem text="Earn income that is variable throughout the year (e.g. hourly income where hours are variable, frequent commissions or bonuses)" />
                    <CheckListItem text="Had or expect changes to your gross income this year (e.g. significant raise, unpaid parental leave)" />
                    <CheckListItem text="Started a new job, or ended a job partially through the year" />
                  </>
                )}
                {content === PaystubAccordionContent.spouse && (
                  <>
                    <CheckListItem text="Earns income that is variable throughout the year (e.g. hourly income where hours are variable, frequent commissions or bonuses)" />
                    <CheckListItem text="Has or expects changes to their gross income this year (e.g. significant raise, unpaid parental leave)" />
                    <CheckListItem text="Started a new job, or ended a job partially through the year" />
                  </>
                )}
              </Grid>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    }
    variant="text"
  />
)

export default PaystubVsEstimateAccordion
