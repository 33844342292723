import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ReactNode } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { Colors } from '../../../styles/theme'
import {
  useIsDeviceWidth,
  DeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { IconButton, Text, Card } from '../../BaseComponents'

export const DisplaySampleDataBanner = ({
  imageUrl,
  headerText,
  bodyContent,
  onClose,
}: {
  imageUrl: string
  headerText: string
  bodyContent: ReactNode | string
  onClose?: () => void
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  return (
    <Card
      type="subsection"
      backgroundColor="natural"
      style={{
        border: `1px solid ${Colors['lightGray']}`,
        padding: '40px 20px',
      }}
    >
      {onClose && (
        <IconButton
          icon={regular('x')}
          color="darkGray"
          style={{ position: 'absolute', right: 16, top: 16 }}
          onClick={onClose}
        />
      )}
      <Grid centered>
        <Grid.Row verticalAlign="middle">
          <Grid.Column computer={3} tablet={6} mobile={16} textAlign="center">
            <Image
              src={imageUrl}
              alt="banner"
              style={{
                height: 150,
              }}
            />
          </Grid.Column>
          <Grid.Column computer={12} tablet={10} mobile={16}>
            <Text
              as="h2"
              style={{ marginBottom: 16 }}
              textAlign={isMobile ? 'center' : undefined}
            >
              {headerText}
            </Text>
            <Text
              as="bodySm"
              color="darkGray"
              textAlign={isMobile ? 'center' : undefined}
            >
              {bodyContent}
            </Text>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}
