import { Grid } from 'semantic-ui-react'

import {
  Alert,
  Button,
  GridRowColumn,
  Modal,
  Text,
} from '../../../../../components/BaseComponents'
import {
  DESTROY_SINGLE_USER_DOCUMENT_KEY,
  destroySingleUserDocument,
} from '../../../../UserDocuments/userDocuments.slice'
import { useReselector } from '../../../../../utils/sharedHooks'
import { getFetchError, getIsFetching } from '../../../../../reducers/fetch'
import { useAppDispatch } from '../../../../../utils/typeHelpers'

const DeleteDocumentModal = ({
  open,
  close,
  documentId,
}: {
  open: boolean
  close: () => void
  documentId: number
}) => {
  const dispatch = useAppDispatch()
  const error = useReselector(getFetchError, DESTROY_SINGLE_USER_DOCUMENT_KEY)
  const isDeleting = useReselector(
    getIsFetching,
    DESTROY_SINGLE_USER_DOCUMENT_KEY
  )

  const deleteAnyway = async () => {
    const res = await destroySingleUserDocument(documentId)(dispatch)
    if (res) {
      close()
    }
  }

  return (
    <Modal size="tiny" open={open} onClose={close} closeIcon>
      <Modal.Header>Are you sure you want to delete this file?</Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text>
              This will permanently delete the file from everywhere in your
              Heard account.
            </Text>
          </GridRowColumn>
          {error && (
            <GridRowColumn>
              <Alert type="error">{error.message}</Alert>
            </GridRowColumn>
          )}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="actionLink" onClick={close}>
          Cancel
        </Button>
        <Button
          variant="warning"
          onClick={deleteAnyway}
          disabled={isDeleting}
          loading={isDeleting}
        >
          Delete
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default DeleteDocumentModal
