import { useState } from 'react'

// BL
import { TransactionRowModel } from '../shared'
import { toUTCDateString } from '../service'

// UI
import { Table, Button, Icon } from 'semantic-ui-react'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
const MAX_DESCRIPTION_LENGTH = 40

interface Props {
  transaction: TransactionRowModel
  onCheckButtonClicked: (t: TransactionRowModel) => Promise<void>
  onDeleteClicked: (t: TransactionRowModel) => Promise<void>
}

const PanelTableRow = ({
  transaction,
  onCheckButtonClicked,
  onDeleteClicked,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const className = transaction.matched ? 'matched' : 'unmatched'

  function handleError(err: unknown) {
    alert(err)
    setLoading(false)
    setDeleting(false)
  }

  const onCheckButtonClickedChild = async () => {
    setLoading(true)

    try {
      await onCheckButtonClicked(transaction)
      setLoading(false)
    } catch (err) {
      handleError(err)
    }
  }

  const onDeletePrompt = async () => {
    const result = confirm('Are you sure you want to delete this transaction?')
    if (result) {
      try {
        setDeleting(true)
        await onDeleteClicked(transaction)
        setDeleting(false)
      } catch (err) {
        handleError(err)
      }
    }

    return
  }

  transaction.description =
    transaction.description.length > MAX_DESCRIPTION_LENGTH
      ? `${transaction.description.substring(0, MAX_DESCRIPTION_LENGTH)}...`
      : transaction.description

  return (
    <Table.Row className={className} key={transaction.id}>
      <Table.Cell>
        <Button
          onClick={onDeletePrompt}
          icon="delete"
          className="delete-transaction-button mute"
          disabled={deleting}
          loading={deleting}
        />
      </Table.Cell>
      <Table.Cell>
        {toUTCDateString({
          value: transaction.date,
          format: DATE_FORMATS.DISPLAY_SHORT,
        })}
      </Table.Cell>
      <Table.Cell>
        {transaction.manualUpload ? <Icon name="upload" size="large" /> : ''}
      </Table.Cell>
      <Table.Cell>{transaction.description.substring(0, 50)}</Table.Cell>
      <Table.Cell>{transaction.debit}</Table.Cell>
      <Table.Cell>{transaction.credit}</Table.Cell>
      <Table.Cell>
        {transaction.matched && (
          <Button
            disabled={loading}
            loading={loading}
            onClick={onCheckButtonClickedChild}
            className="mute matched"
            icon={`${loading ? 'circle outline' : 'check'}`}
          />
        )}
        {!transaction.matched && (
          <Button
            disabled={loading}
            loading={loading}
            onClick={onCheckButtonClickedChild}
            style={{ background: 'transparent' }}
            className="unmatched"
            icon="circle outline"
          />
        )}
      </Table.Cell>
    </Table.Row>
  )
}

export default PanelTableRow
