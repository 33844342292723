import { useEffect, useMemo, useState } from 'react'
import { Grid, Image, List } from 'semantic-ui-react'
import moment from 'moment'
import { DateTime } from 'luxon'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Button,
  GridRowColumn,
  IconButton,
  Link,
  Popup,
  Text,
} from '../../../../../../components/BaseComponents'
import { fetchFilteredUserTransactions } from '../../../../../Transactions/transactions.slice'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { fetchTransactionCategoriesIfNeeded } from '../../../../../Reports/reports.slice'
import { TransactionOrSplit } from '../../../../../../reducers/admin/allTransactions.slice'
import { Colors } from '../../../../../../styles/theme'
import { getTransactionCategoryById } from '../../../../../Reports/reports.selectors'
import {
  addCurrencyArray,
  formatCurrencyFromCents,
} from '../../../../../../utils/currencyHelpers'
import TransactionNoteModal from '../../../../../Transactions/TransactionNoteModal'
import { DATE_FORMATS_LUXON } from '../../../../../../utils/dateHelpers'
import { selectFilteredGroupedUserTransactions } from '../../../../../Transactions/transactions.selectors'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { ReviewIncomeAndExpensesProps } from '.'
import {
  selectReviewBooksCompleted,
  selectUserEoyReviewProgressForSubstepIdentifier,
} from '../ReviewStepsandProgresses/userEndOfYearReviewProgress.selector'
import { selectUserBooksLockedForCurrentTaxYear } from '../../../../../../reducers/auth/user.selectors'
import { SubStepIdentifiers } from '../ReviewStepsandProgresses/stepProgress.helpers'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'

const TRANSACTIONS_TO_SHOW = 10
const TransactionRow = ({
  transaction,
}: {
  transaction: TransactionOrSplit
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const { date, description, notes, amountInCents } = transaction
  const firstReviewCompleted = useReselector(selectReviewBooksCompleted, false)
  const booksLocked = useReselector(selectUserBooksLockedForCurrentTaxYear)
  const isFinalReview = useMemo(
    () => firstReviewCompleted && booksLocked,
    [firstReviewCompleted, booksLocked]
  )

  return (
    <Grid.Row verticalAlign="middle">
      <Grid.Column width={3}>
        <Text as="bodyLg">
          {DateTime.fromISO(date)
            .toUTC()
            .toFormat(DATE_FORMATS_LUXON.DISPLAY_SHORT)}
        </Text>
      </Grid.Column>
      <Grid.Column width={10} style={{ overflow: 'hidden' }}>
        <Text as="bodyLg">{description}</Text>
        <Text as="bodySm">{notes}</Text>
      </Grid.Column>
      <Grid.Column
        width={3}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Text as="bodyLg" textAlign="right">
          {formatCurrencyFromCents(amountInCents)}
        </Text>
        {!isFinalReview && (
          <Button
            variant="link"
            onClick={() => setModalOpen(true)}
            style={{ alignSelf: 'end', marginTop: 4 }}
          >
            {notes ? 'Edit Note' : 'Add Note'}
          </Button>
        )}
      </Grid.Column>
      {/*check for modal open used here for opt*/}
      {modalOpen && (
        <TransactionNoteModal
          transaction={transaction}
          open={modalOpen}
          close={() => setModalOpen(false)}
        />
      )}
    </Grid.Row>
  )
}

export const TQTransactionCategoryRow = ({
  transactions,
  defaultTitle,
}: {
  transactions: TransactionOrSplit[]
  defaultTitle: string
}) => {
  const [open, setOpen] = useState(false)
  const [showAll, setShowAll] = useState(
    transactions.length <= TRANSACTIONS_TO_SHOW
  )
  const category = useReselector(
    getTransactionCategoryById,
    transactions[0]?.transactionCategoryId
  )

  const incomeForCat = useMemo(
    () =>
      addCurrencyArray(transactions.map(({ amountInCents }) => amountInCents)),
    [transactions]
  )

  return (
    <GridRowColumn
      spacer={3}
      width={10}
      columnStyle={{ backgroundColor: Colors.stone40, borderRadius: 8 }}
    >
      <Grid>
        <Grid.Row
          style={{ height: 88, cursor: 'pointer' }}
          verticalAlign="middle"
          onClick={() => setOpen((prev) => !prev)}
        >
          <Grid.Column width={9}>
            <Text as="h3">{category?.name || defaultTitle}</Text>
          </Grid.Column>
          <Grid.Column width={6}>
            <Text textAlign="right" as="bodyLg">
              {formatCurrencyFromCents(incomeForCat)}
            </Text>
          </Grid.Column>
          <Grid.Column width={1}>
            <IconButton icon={open ? light('minus') : light('plus')} />
          </Grid.Column>
        </Grid.Row>
        {open && (
          <>
            {transactions.map((transaction, index) =>
              showAll || index < TRANSACTIONS_TO_SHOW ? (
                <TransactionRow
                  key={transaction.id}
                  transaction={transaction}
                />
              ) : null
            )}
            <Grid.Row>
              <Grid.Column width={8}>
                <Text color="darkGray">
                  Showing {Math.min(transactions.length, TRANSACTIONS_TO_SHOW)}{' '}
                  of {transactions.length}
                </Text>
              </Grid.Column>
              {!showAll && (
                <Grid.Column
                  width={8}
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  <Button
                    variant="secondaryLink"
                    onClick={() => setShowAll(true)}
                  >
                    View all transactions
                  </Button>
                </Grid.Column>
              )}
            </Grid.Row>
            <Grid.Row className="short" />
          </>
        )}
      </Grid>
    </GridRowColumn>
  )
}

const ReviewIncome = ({
  goToNextStep,
  goBack,
  nextScreen,
  previousScreen,
}: ReviewIncomeAndExpensesProps) => {
  const dispatch = useAppDispatch()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const initialIncomeReviewProgress = useReselector(
    selectUserEoyReviewProgressForSubstepIdentifier,
    SubStepIdentifiers.reviewIncomeInitial
  )
  const finalIncomeReviewProgress = useReselector(
    selectUserEoyReviewProgressForSubstepIdentifier,
    SubStepIdentifiers.reviewIncomeFinal
  )
  const firstReviewCompleted = useReselector(selectReviewBooksCompleted, false)
  const booksLocked = useReselector(selectUserBooksLockedForCurrentTaxYear)
  const isFinalReview = useMemo(
    () => firstReviewCompleted && booksLocked,
    [firstReviewCompleted, booksLocked]
  )

  // not updating these to Luxon for now because
  // they're used in the transaction selectors and would be high risk to change
  const startDate = useMemo(
    () => moment().utc().year(Number(taxYear)).startOf('year'),
    [taxYear]
  )
  const endDate = useMemo(
    () => moment().utc().year(Number(taxYear)).endOf('year'),
    [taxYear]
  )

  const incomeTransactions = useReselector(
    selectFilteredGroupedUserTransactions,
    { startDate, endDate }
  )

  const saveAndContinue = useAsyncCallback(() =>
    goToNextStep(
      isFinalReview
        ? finalIncomeReviewProgress?.id
        : initialIncomeReviewProgress?.id,
      nextScreen
    )
  )

  const { totalIncome, groupedIncome } = incomeTransactions

  useEffect(() => {
    dispatch(fetchFilteredUserTransactions({ startDate, endDate }))
  }, [dispatch, endDate, startDate])

  useEffect(() => {
    dispatch(fetchTransactionCategoriesIfNeeded())
  }, [dispatch])

  return (
    <>
      <Grid>
        <Grid.Row />
        <Grid.Row />
        <GridRowColumn centerContent>
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/payroll.svg"
            style={{ height: 120 }}
          />
        </GridRowColumn>
        <GridRowColumn spacer={4} width={8} short>
          <Text as="h1" textAlign="center">
            Review {taxYear} Income
          </Text>
        </GridRowColumn>
        {!isFinalReview && (
          <GridRowColumn spacer={4} width={8}>
            <Text as="bodyLg">
              Please look through your income. Your review allows the Heard Team
              to finish your year-end bookkeeping, minimize how much you&apos;ll
              owe in taxes and ensure an accurate tax return.
            </Text>
          </GridRowColumn>
        )}
        {isFinalReview && (
          <>
            <GridRowColumn spacer={4} width={8}>
              <Text as="bodyLg">
                You&apos;ve reviewed your income before, but it&apos;s important
                to do a final check and make sure these numbers correctly
                represent your business.
              </Text>
            </GridRowColumn>
            <GridRowColumn spacer={4} width={8}>
              <Text as="bodyLg">
                If something seems off, please reach out to your bookkeeper via{' '}
                <Link to="/conversations">Conversations</Link>.
              </Text>
            </GridRowColumn>
          </>
        )}
        {!isFinalReview && (
          <GridRowColumn spacer={4} width={8}>
            <Popup
              trigger={
                <Button variant="tooltipLink">
                  If something seems off, leave a note.
                </Button>
              }
              content={
                <>
                  <Text as="h3">How do I know if my income seems off?</Text>
                  <List bulleted>
                    <List.Item>
                      <Text as="bodyLg">
                        Does the total income encapsulate your entire year?
                      </Text>
                    </List.Item>
                    <List.Item>
                      <Text as="bodyLg">
                        Does this number feel low or high?
                      </Text>
                    </List.Item>
                    <List.Item>
                      <Text as="bodyLg">
                        Does anything seem mis-categorized?
                      </Text>
                    </List.Item>
                    <List.Item>
                      <Text as="bodyLg">
                        The income reported in your bookkeeping must match or be
                        higher than the income reported on the 1099s you&apos;ve
                        received.
                      </Text>
                    </List.Item>
                  </List>
                </>
              }
            />
          </GridRowColumn>
        )}
        <Grid.Row />
        <GridRowColumn
          spacer={3}
          width={10}
          columnStyle={{ backgroundColor: Colors.stone, borderRadius: 8 }}
        >
          <Grid>
            <Grid.Row style={{ height: 88 }} verticalAlign="middle">
              <Grid.Column width={8}>
                <Text as="h3">Total Income</Text>
              </Grid.Column>
              <Grid.Column width={8}>
                <Text textAlign="right" as="h3">
                  {formatCurrencyFromCents(totalIncome)}
                </Text>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </GridRowColumn>
        {Object.values(groupedIncome).map((transactions) => (
          <TQTransactionCategoryRow
            key={transactions[0]?.transactionCategoryId || 'null'}
            transactions={transactions}
            defaultTitle="Uncategorized Income"
          />
        ))}
      </Grid>
      <Grid>
        <FormFlowFooter
          onBack={() => goBack(previousScreen ?? null)}
          onForward={saveAndContinue.callback}
          loading={saveAndContinue.loading}
          continueDisabled={saveAndContinue.loading}
        />
      </Grid>
    </>
  )
}

export default ReviewIncome
