import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { noop } from 'lodash'
import { IParsedAddress, parseAddress } from 'addresser'

import Input, { InputProps } from '../BaseComponents/Input'
import './LocationSearchInput.scss'
import Text from '../BaseComponents/Text'

export const parseAddressString = (addressString?: string | null) => {
  try {
    const address:
      | (IParsedAddress & { addressLine2?: string })
      | {
          addressLine1?: string
          addressLine2?: string
          placeName?: string
          stateAbbreviation?: string
          zipCode?: string
        } = addressString ? parseAddress(addressString) : {}
    const internalSingleLineAddressString = `${address.addressLine1 || ''}${
      address.addressLine2 || ''
    }`
    return {
      parsedAddress: address,
      singleLineAddressString: internalSingleLineAddressString,
    }
  } catch (_) {
    return {
      parsedAddress: {},
      singleLineAddressString: '',
    }
  }
}

export interface LocationSearchInputProps
  extends Omit<InputProps, 'onChange' | 'onSelect' | 'componentType'> {
  onSelect?: (location: google.maps.GeocoderResult) => void
  // If true this field will only serve as address line 1
  streetAddressOnly?: boolean
  // If true this field will only serve as the combo of the first and second line address only
  singleLineAddressOnly?: boolean
  onChange: (value: string) => void
  // If streetAddressOnly or singleLineAddressOnly are true this callback will trigger
  onChangeFullAddress?: (
    parsedAddress: ReturnType<typeof parseAddressString>
  ) => void
}

const LocationSearchInput = ({
  streetAddressOnly,
  singleLineAddressOnly,
  onChange,
  onChangeFullAddress,
  onSelect,
  value,
  ...rest
}: LocationSearchInputProps) => {
  const searchForValue = (searchValue: string) =>
    geocodeByAddress(searchValue)
      .then((results) => {
        const result = results[0]

        if (result) {
          onSelect?.(result)
          if (streetAddressOnly || singleLineAddressOnly) {
            const parsedAddress = parseAddressString(result.formatted_address)
            onChangeFullAddress?.(parsedAddress)
            if (singleLineAddressOnly) {
              onChange(parsedAddress.singleLineAddressString)
            } else {
              onChange(parsedAddress.parsedAddress.addressLine1 || '')
            }
          } else {
            onChange(result.formatted_address)
          }
        }
      })
      .catch(noop)

  return (
    <PlacesAutocomplete
      value={value}
      onChange={onChange}
      onSelect={searchForValue}
      searchOptions={{
        componentRestrictions: { country: 'us' },
        types: ['address'],
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => {
        const inputProps = getInputProps()

        return (
          <>
            <Input
              onChange={(newValue) =>
                inputProps.onChange({ target: { value: newValue } })
              }
              value={inputProps.value || ''}
              onKeyDown={inputProps.onKeyDown}
              fullWidth
              componentType="location"
              {...rest}
              onBlur={(evt) => {
                inputProps.onBlur(evt)
                rest.onBlur?.(evt)
              }}
            />
            {Boolean(suggestions.length) && (
              <div className="autocomplete-dropdown-container">
                {suggestions.map((sug) => (
                  <div
                    {...getSuggestionItemProps(sug)}
                    key={sug.id}
                    className={`option ${
                      sug.description === value ? 'selected' : ''
                    }`}
                  >
                    <Text>{sug.description}</Text>
                  </div>
                ))}
              </div>
            )}
          </>
        )
      }}
    </PlacesAutocomplete>
  )
}

export default LocationSearchInput
