import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { selectHasAppAccess } from '../../selectors/user.selectors'
import { useReselector } from '../../utils/sharedHooks'
import Loader from '../BaseComponents/Loader'

interface Props {
  component: React.ElementType
}

const PrivateRoute = ({ component: RouteComponent, ...rest }: Props) => {
  const location = useLocation()
  const userHasAppAccess = useReselector(selectHasAppAccess)
  const auth = useReselector((state) => state.auth.security)

  if (auth.isAuthenticated) {
    if (localStorage.getItem('targetLink')) {
      localStorage.removeItem('targetLink')
    }
    return userHasAppAccess === null ? (
      <Loader loading />
    ) : (
      <RouteComponent {...rest} />
    )
  }

  localStorage.setItem(
    'targetLink',
    location.pathname + location.search + location.hash
  )
  return <Navigate to="/login" />
}

export default PrivateRoute
