import { DateTime } from 'luxon'
import { createSelector } from 'reselect'

import {
  getTaxListQuestionResponseByFormId,
  selectTaxListQuestionState,
} from '../../taxChecklist.selectors'
import { TaxListQuestionId } from '../../service'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'

export const getNewRentalGroupId = () =>
  `rental-property-${DateTime.now().toMillis()}`

export const getNewRetirementPlanGroupId = () =>
  `retirement-plan-${DateTime.now().toMillis()}`

export const selectRentalPropertyResponses = createSelector(
  selectTaxListQuestionState,
  (_: unknown, formId: number) => formId,
  (allQuestions, formId) => {
    const responsePropertyAddress = getTaxListQuestionResponseByFormId(
      allQuestions,
      TaxListQuestionId.rental_property_address,
      formId
    )

    const responseFirstTimeRentingProperty = getTaxListQuestionResponseByFormId(
      allQuestions,
      TaxListQuestionId.rental_property_first_time,
      formId
    )

    return [
      ...responsePropertyAddress,
      ...responseFirstTimeRentingProperty,
    ]?.reduce(
      (acc, curr) => {
        const groupId = curr.groupId ?? getNewRentalGroupId()
        if (!acc[groupId]) {
          acc[groupId] = {
            groupId,
            propertyAddress: null,
            firstTimeRentingProperty: null,
          }
        }
        if (curr.questionId === TaxListQuestionId.rental_property_address) {
          acc[groupId].propertyAddress = curr
        } else if (
          curr.questionId === TaxListQuestionId.rental_property_first_time
        ) {
          acc[groupId].firstTimeRentingProperty = curr
        }
        return acc
      },
      {} as Record<
        string,
        {
          groupId: string
          propertyAddress: TaxChecklistResponse | null
          firstTimeRentingProperty: TaxChecklistResponse | null
        }
      >
    )
  }
)

export const selectRetirementContributionResponses = createSelector(
  selectTaxListQuestionState,
  (_: unknown, formId: number) => formId,
  (allQuestions, formId) => {
    const responseRetirementPlanType = getTaxListQuestionResponseByFormId(
      allQuestions,
      TaxListQuestionId.retirement_plan_type,
      formId
    )

    const responseRetirementContributedAmountInCents =
      getTaxListQuestionResponseByFormId(
        allQuestions,
        TaxListQuestionId.retirement_contributed_amount_in_cents,
        formId
      )

    const responseNeedsHelp = getTaxListQuestionResponseByFormId(
      allQuestions,
      TaxListQuestionId.retirement_needs_help,
      formId
    )

    const responseRetirementRolledOver = getTaxListQuestionResponseByFormId(
      allQuestions,
      TaxListQuestionId.retirement_plan_rolled_over,
      formId
    )

    const responseRetirementDistribution = getTaxListQuestionResponseByFormId(
      allQuestions,
      TaxListQuestionId.retirement_distribution,
      formId
    )

    return [
      ...responseRetirementPlanType,
      ...responseRetirementContributedAmountInCents,
      ...responseNeedsHelp,
      ...responseRetirementRolledOver,
      ...responseRetirementDistribution,
    ]?.reduce(
      (acc, curr) => {
        const groupId = curr.groupId ?? getNewRetirementPlanGroupId()
        if (!acc[groupId]) {
          acc[groupId] = {
            groupId,
            retirementPlanType: null,
            retirementContributedAmountInCents: null,
            needsHelp: null,
            retirementRolledOver: null,
            retirementDistribution: null,
          }
        }
        if (curr.questionId === TaxListQuestionId.retirement_plan_type) {
          acc[groupId].retirementPlanType = curr
        } else if (
          curr.questionId ===
          TaxListQuestionId.retirement_contributed_amount_in_cents
        ) {
          acc[groupId].retirementContributedAmountInCents = curr
        } else if (
          curr.questionId === TaxListQuestionId.retirement_needs_help
        ) {
          acc[groupId].needsHelp = curr
        } else if (
          curr.questionId === TaxListQuestionId.retirement_plan_rolled_over
        ) {
          acc[groupId].retirementRolledOver = curr
        } else if (
          curr.questionId === TaxListQuestionId.retirement_distribution
        ) {
          acc[groupId].retirementDistribution = curr
        }
        return acc
      },
      {} as Record<
        string,
        {
          groupId: string
          retirementPlanType: TaxChecklistResponse | null
          retirementContributedAmountInCents: TaxChecklistResponse | null
          needsHelp: TaxChecklistResponse | null
          retirementRolledOver: TaxChecklistResponse | null
          retirementDistribution: TaxChecklistResponse | null
        }
      >
    )
  }
)
