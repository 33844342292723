import React from 'react'
import { Divider, Grid } from 'semantic-ui-react'
import {
  Button,
  GridRowColumn,
  Icon,
  Link,
  Text,
} from '../../../components/BaseComponents'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

type AhaMomentsHeaderFooterProps = {
  backButtonText: string
  continueButtonText: string
  handleContinue: () => void
  handleBack: () => void
  continueDisabled?: boolean
  children: React.ReactNode
}

export const AhaMomentsBookeepingHeader = () => (
  <Grid>
    <Grid.Column width={14}>
      <Link
        href="/dashboard"
        style={{
          display: 'inline',
          fontSize: 14,
          marginTop: 16,
        }}
      >
        <Icon
          style={{ display: 'inline', marginRight: 8, color: '#000' }}
          icon={regular('arrow-left')}
        />
        <Text as="bodyXs" style={{ display: 'inline' }}>
          Back to Dashboard
        </Text>
      </Link>
    </Grid.Column>
  </Grid>
)

const Footer = ({
  backButtonText,
  continueButtonText,
  handleBack,
  handleContinue,
  continueDisabled,
}: Pick<
  AhaMomentsHeaderFooterProps,
  | 'backButtonText'
  | 'continueButtonText'
  | 'handleBack'
  | 'handleContinue'
  | 'continueDisabled'
>) => (
  <Grid
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: 800,
      padding: 0,
      margin: '0 auto',
    }}
  >
    <GridRowColumn>
      <Divider style={{ width: '100%' }} />
    </GridRowColumn>
    <GridRowColumn style={{ display: 'flex', flexDirection: 'row' }}>
      <Button
        variant="secondary"
        size="medium"
        style={{ display: 'inline', flexDirection: 'row' }}
        onClick={handleBack}
      >
        {backButtonText}
      </Button>
      <Button
        variant="primary"
        size="medium"
        style={{ display: 'inline', float: 'right' }}
        onClick={handleContinue}
        disabled={continueDisabled}
      >
        {continueButtonText}
      </Button>
    </GridRowColumn>
  </Grid>
)

const AhaMomentsHeaderFooter: React.FC<AhaMomentsHeaderFooterProps> = ({
  backButtonText,
  continueButtonText,
  handleContinue,
  handleBack,
  continueDisabled,
  children,
}) => {
  return (
    <>
      <AhaMomentsBookeepingHeader />
      {children}
      <Footer
        backButtonText={backButtonText}
        continueButtonText={continueButtonText}
        handleBack={handleBack}
        handleContinue={handleContinue}
        continueDisabled={continueDisabled}
      />
    </>
  )
}

export default AhaMomentsHeaderFooter
