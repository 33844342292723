import { useCallback, useState } from 'react'
import { Grid } from 'semantic-ui-react'

import {
  Button,
  Checkbox,
  GridRowColumn,
  Link,
  Modal,
  Popup,
  Text,
} from '../../../../components/BaseComponents'
import { getAnnualTaxFilingForYearSelector } from '../annualTaxFilings.selector'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  needContractorFilingOption,
  updateAnnualTaxFilings,
} from '../annualTaxFilings.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const Ten99OptOutModal = ({
  year,
  open,
  onClose,
}: {
  year: string
  open: boolean
  onClose: () => void
}) => {
  const dispatch = useAppDispatch()
  const [confirmationChecked, setConfirmationChecked] = useState(false)
  const filing = useReselector(getAnnualTaxFilingForYearSelector, year)

  const onConfirm = useCallback(async () => {
    if (!filing?.id) {
      return
    }
    const res = await updateAnnualTaxFilings(filing?.id, {
      needContractorFiling: needContractorFilingOption.no,
    })(dispatch)

    if (res) {
      onClose()
    }
  }, [dispatch, filing?.id, onClose])

  if (!filing) {
    return null
  }

  return (
    <Modal open={open} onClose={onClose} closeIcon size="small">
      <Modal.Header>Opt out of Contractor Payroll (1099-NEC)</Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text as="bodyLg">
              Please check the box below to opt out of Heard preparing {year}{' '}
              Contractor Payroll (Form 1099-NEC).
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Button variant="tooltipLink" style={{ padding: 2 }}>
              <Popup
                wide
                content={
                  <>
                    <Text as="h3">
                      Who needs a 1099-NEC? (must fit all criteria below)
                    </Text>
                    <Text>
                      <ul>
                        <li>
                          Their tax entity is a LLC (single-member or
                          partnership) or a sole proprietor. See box 3 on their
                          W-9.
                        </li>
                        <li>
                          You’ve paid them <b>over $600</b> in {year}
                        </li>
                        <li>
                          You’ve paid them with{' '}
                          <b>cash, checks or bank transfers</b>
                        </li>
                      </ul>
                    </Text>
                  </>
                }
                trigger="How do I know if I need to file one?"
              />
            </Button>
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              Still have questions? Read our{' '}
              <Link
                href="https://support.joinheard.com/hc/en-us/articles/18872262968727"
                newPage
              >
                FAQs
              </Link>
            </Text>
          </GridRowColumn>

          <GridRowColumn>
            <Checkbox
              variant="default"
              label={`I understand that by opting out, Heard will not file Contractor Payroll (Form 1099-NECs) on my behalf for ${year}.`}
              checked={confirmationChecked}
              onChange={setConfirmationChecked}
            />
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={!confirmationChecked} onClick={onConfirm}>
          Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default Ten99OptOutModal
