import { useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'

import {
  Button,
  FormikCheckbox,
  FormikInput,
  GridRowColumn,
  makeReqStringSchema,
  Modal,
} from '../../../components/BaseComponents'
import { putSignEmployeeForm } from '../payrollActions'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { Gusto_GustoForm } from '../generated_gusto_types'

const validationSchema = yup.object({
  agree: yup.boolean().isTrue(),
  name: makeReqStringSchema({ field: 'name' }),
})

const SignFormModal = ({
  form,
  employeeUuid,
  onFormSigned,
  closeModal,
}: {
  form: Gusto_GustoForm
  employeeUuid?: string
  onFormSigned: (val: boolean) => void
  closeModal: () => void
}) => {
  const { name, uuid } = form
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      agree: false,
      name: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!employeeUuid) {
        return
      }
      setLoading(true)
      const res = await putSignEmployeeForm(employeeUuid, uuid, {
        signature_text: values.name,
        agree: values.agree,
      })(dispatch)

      setLoading(false)
      if (res) {
        onFormSigned(res.requires_signing)
        closeModal()
      }
    },
  })

  const title =
    name === 'employee_direct_deposit'
      ? 'Sign Employee Direct Deposit Authorization'
      : 'Sign Form'
  const checkboxLabel =
    name === 'employee_direct_deposit'
      ? 'I allow Heard to directly deposit my pay into the bank account I’ve provided. This will remain in effect until I’ve notified Heard in writing to cancel this authorization.'
      : 'I’ve read the document and give authorization. This will remain in effect until I’ve notified Heard in writing to cancel this authorization.'

  return (
    <Modal open size="small" closeIcon onClose={closeModal}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <FormikProvider value={formik}>
          <Grid>
            <GridRowColumn>
              <FormikCheckbox name="agree" label={checkboxLabel} />
            </GridRowColumn>
            <GridRowColumn>
              <FormikInput
                name="name"
                label="Signature"
                placeholder="Enter full name"
                fullWidth
              />
            </GridRowColumn>
          </Grid>
        </FormikProvider>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal} variant="secondary">
          Cancel
        </Button>
        <Button onClick={formik.submitForm} loading={loading}>
          Sign
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default SignFormModal
