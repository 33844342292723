import { useContext, useMemo } from 'react'
import { Grid, List } from 'semantic-ui-react'
import { GridRowColumn, Modal, Text, Button } from '../../BaseComponents'
import { selectPlaidItems } from '../../../selectors/financeSelectors'
import PlaidLink from '../../shared/plaid/PlaidLink'
import { PlaidLinkContext } from './PlaidLinkContext'
import { useReselector } from '../../../utils/sharedHooks'

export const DuplicateInstitutionModal = () => {
  const plaidInstitutions = useReselector(selectPlaidItems)
  const { duplicateInstitution, setDuplicateInstitution } =
    useContext(PlaidLinkContext)

  const institution = useMemo(() => {
    if (!duplicateInstitution || !plaidInstitutions) return null
    return Object.values(plaidInstitutions).find(
      (institution) => institution.institutionId === duplicateInstitution
    )
  }, [duplicateInstitution, plaidInstitutions])

  return (
    <Modal
      open={Boolean(institution)}
      closeIcon
      onClose={() => setDuplicateInstitution('')}
    >
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text as="h2"> Connection already exists </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              You have a connection to <b>{institution?.institutionName}</b>{' '}
              with the following accounts:
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <List as="ul">
              {institution?.accounts?.map((account) => {
                if (account.inactive === false) {
                  return (
                    <List.Item as="li" key={account.id}>
                      {account.name} {account.mask}
                    </List.Item>
                  )
                }
                return null
              })}
            </List>
          </GridRowColumn>
          <GridRowColumn short>
            <Text>
              We&apos;ll direct you back to Plaid, where you&apos;ll be able to
              add/remove accounts via your existing connection.
            </Text>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="secondary"
          style={{ marginRight: 8 }}
          onClick={() => setDuplicateInstitution('')}
        >
          Cancel
        </Button>
        <PlaidLink
          reconnectButtonText="Back to Plaid"
          connectButtonText="Back to Plaid"
          account={institution?.accounts[0]}
          disconnected={false}
        />
      </Modal.Actions>
    </Modal>
  )
}
