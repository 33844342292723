import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NormalizedSchema } from 'normalizr'

export interface CalendlyLink {
  id: number
  adminUserId: number
  calendlyLink?: string
  userFacingEventName?: string
  userFacingDescription?: string
  year?: string
  quarter?: '1' | '2' | '3' | '4'
  createdAt: string
  updatedAt: string
}

export interface CalendlyLinkState {
  [id: number]: CalendlyLink
}

const initialState: CalendlyLinkState = {}

const calendlyLinkSlice = createSlice({
  name: 'adminCalendlyLink',
  initialState,
  reducers: {
    receiveCalendlyLinks: (
      state,
      action: PayloadAction<
        NormalizedSchema<
          { adminCalendlyLink?: { [p: number]: CalendlyLink } | undefined },
          number[] | number
        >
      >
    ) => ({
      ...state,
      ...action.payload.entities.adminCalendlyLink,
    }),
  },
})

export default calendlyLinkSlice.reducer

export const { receiveCalendlyLinks } = calendlyLinkSlice.actions
