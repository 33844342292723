import { useCallback, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import {
  fetchAllOnboardingSteps,
  fetchUserOnboardingStepsIfNeeded,
  markCompletedAtAndStartedAtForSteps,
} from '../../UserOnboardingSteps/onboarding.actions'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  getAllOnboardingSteps,
  getUserOnboardingSteps,
  getUserStepIdByOnboardingStepId,
} from '../../UserOnboardingSteps/onboarding.selectors'
import { BOOKKEEPING_STEPS, ahaMomentBookkeepingStepsOrder } from './utils'

export const useBookkeepingSteps = (defaultStep: BOOKKEEPING_STEPS) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const allOnboardingSteps = useReselector(getAllOnboardingSteps)
  const allUserOnboardingSteps = useReselector(getUserOnboardingSteps)

  // Get the current step from the URL or use the default step as fallback
  const currentStepString = searchParams.get('step') || defaultStep
  const currentStep = Object.values(BOOKKEEPING_STEPS).includes(
    currentStepString as BOOKKEEPING_STEPS
  )
    ? (currentStepString as BOOKKEEPING_STEPS)
    : defaultStep

  const currentStepIdentifier = Object.values(allOnboardingSteps).find(
    (step) => step.identifier.toLowerCase() === currentStep
  )?.identifier

  const currentStepId = useReselector(
    getUserStepIdByOnboardingStepId,
    currentStepIdentifier
  )

  const isStepCompleted = currentStepId
    ? Boolean(allUserOnboardingSteps[currentStepId]?.completedAt)
    : false

  const nextStep =
    ahaMomentBookkeepingStepsOrder[
      ahaMomentBookkeepingStepsOrder.indexOf(currentStep) + 1
    ]

  const nextStepIdentifier = Object.values(allOnboardingSteps).find(
    (step) => step.identifier.toLowerCase() === nextStep
  )?.identifier

  const nextStepId = useReselector(
    getUserStepIdByOnboardingStepId,
    nextStepIdentifier
  )

  useEffect(() => {
    dispatch(fetchUserOnboardingStepsIfNeeded())
    dispatch(fetchAllOnboardingSteps())
  }, [dispatch])

  const goToStep = useCallback(
    (newStep: 1 | -1 | BOOKKEEPING_STEPS) => {
      if (typeof newStep === 'string') {
        setSearchParams({ step: newStep })
        return
      }

      const currentStepIndex =
        ahaMomentBookkeepingStepsOrder.indexOf(currentStep)

      if (
        currentStepIndex + newStep >= 0 &&
        currentStepIndex + newStep < ahaMomentBookkeepingStepsOrder.length
      ) {
        setSearchParams({
          step: ahaMomentBookkeepingStepsOrder[currentStepIndex + newStep],
        })
      }
    },
    [currentStep, setSearchParams]
  )

  const markCompletedAtAndStartedAtForEachStep = useCallback(async () => {
    if (currentStepId) {
      if (!isStepCompleted) {
        await markCompletedAtAndStartedAtForSteps({
          currentStepId,
          nextStepId,
        })(dispatch)
      }
    }
  }, [currentStepId, nextStepId, dispatch, isStepCompleted])

  const goToNextStep = useCallback(async () => {
    try {
      await markCompletedAtAndStartedAtForEachStep()

      if (currentStep === BOOKKEEPING_STEPS.transactionsClarifiedSuccess) {
        navigate('/dashboard')
      } else {
        goToStep(1)
      }
    } catch (error) {
      console.error('Failed to complete step:', error)
    }
  }, [currentStep, navigate, goToStep, markCompletedAtAndStartedAtForEachStep])

  const goToPreviousStep = useCallback(() => {
    if (currentStep === BOOKKEEPING_STEPS.learnHowBookkeepingWorks) {
      navigate('/dashboard')
    } else {
      goToStep(-1)
    }
  }, [goToStep, currentStep, navigate])

  return { currentStep, goToNextStep, goToPreviousStep }
}
