import { useState } from 'react'
import { Dropdown as SemanticDropdown } from 'semantic-ui-react'

import Dropdown from '../components/BaseComponents/Dropdown'
import { Text } from '../components/BaseComponents'
import { Colors } from '../styles/theme'

const dropdownOptions = [
  {
    icon: 'calendar' as const,
    text: 'value1',
    value: 'text1',
  },
  {
    text: 'value2',
    value: 'text2',
  },
  {
    text: 'value3',
    value: 'text3',
  },
]

export const DefaultDropdown = () => {
  const [value, setValue] = useState<string>()
  return (
    <Dropdown
      options={dropdownOptions}
      placeholder="Select a value..."
      value={value}
      onChange={setValue}
    />
  )
}

export const ClearableDropdown = () => {
  const [value, setValue] = useState<string>()

  return (
    <Dropdown
      clearable
      options={dropdownOptions}
      placeholder="Select a value..."
      value={value}
      onChange={setValue}
    />
  )
}

export const MultipleDropdown = () => {
  const [value, setValue] = useState<string[]>([])
  return (
    <Dropdown
      clearable
      multiple
      // Options shorted here to because there's not enough space to display them
      options={[
        { text: 'a', value: 'a' },
        { text: 'b', value: 'b' },
      ]}
      placeholder="Select a value..."
      value={value}
      onChange={setValue}
    />
  )
}

export const ErrorDropdown = () => {
  const [value, setValue] = useState<string>()

  return (
    <Dropdown
      clearable
      error="There is an error"
      options={dropdownOptions}
      placeholder="Select a value..."
      value={value}
      onChange={setValue}
    />
  )
}

export const LabeledDropdown = () => {
  const [value, setValue] = useState<string>()

  return (
    <Dropdown
      clearable
      label="Label"
      options={dropdownOptions}
      placeholder="Select a value..."
      value={value}
      onChange={setValue}
    />
  )
}

export const DescriptiveDropdown = () => {
  const [value, setValue] = useState<string>()
  return (
    <Dropdown
      clearable
      description="I am a description"
      label="Label"
      options={dropdownOptions}
      placeholder="Select a value..."
      value={value}
      onChange={setValue}
    />
  )
}

export const DisabledDropdown = () => (
  <Dropdown
    clearable
    description="I am a description"
    disabled
    label="Label"
    options={dropdownOptions}
    placeholder="Select a value..."
  />
)

export const FullWidthDropdown = () => {
  const [value, setValue] = useState<string>()

  return (
    <Dropdown
      clearable
      fullWidth
      description="I am a description"
      label="Label"
      options={dropdownOptions}
      placeholder="Select a value..."
      value={value}
      onChange={setValue}
    />
  )
}

export const CheckedDropdown = () => {
  const [value, setValue] = useState('all')

  return (
    <Dropdown
      variant="checked"
      value={value}
      options={[
        { text: 'All Expenses', value: 'all' },
        { text: 'Top Expenses', value: 'top' },
        { text: 'Deductible Expenses', value: 'deductible' },
        { text: 'Payroll & Contractors', value: 'payroll' },
        { text: 'Rent & Lease', value: 'rent' },
        { text: 'Communication', value: 'com' },
        { text: 'Software Services', value: 'soft' },
      ]}
      onChange={setValue}
      dividerAfter={[2]}
    />
  )
}

export const CheckedMultipleDropdown = () => {
  const [value, setValue] = useState<string[]>([])

  return (
    <Dropdown
      placeholder="Select a value..."
      variant="checked"
      value={value}
      options={[
        { text: 'All Expenses', value: 'all' },
        { text: 'Top Expenses', value: 'top' },
        { text: 'Deductible Expenses', value: 'deductible' },
        { text: 'Payroll & Contractors', value: 'payroll' },
        { text: 'Rent & Lease', value: 'rent' },
        { text: 'Communication', value: 'com' },
        { text: 'Software Services', value: 'soft' },
      ]}
      onChange={setValue}
      dividerAfter={[2]}
      multiple
      clearable
    />
  )
}

export const CustomMenuDropdown = () => {
  const [value, setValue] = useState<string>()

  return (
    <Dropdown
      placeholder="Select a value..."
      onClick={(e) => {
        e.preventDefault()
      }}
    >
      <SemanticDropdown.Menu>
        {dropdownOptions?.map((option) => (
          <SemanticDropdown.Item
            onClick={() => setValue(option.value)}
            key={option.value}
            style={{ backgroundColor: Colors.green }}
          >
            {value === option.value && <Text>Selected</Text>}
            <Text>option.text</Text>
          </SemanticDropdown.Item>
        ))}
      </SemanticDropdown.Menu>
    </Dropdown>
  )
}
