import moment from 'moment'
import { FinancialAccount } from '../../reducers/finances/financialAccountsReducer'
import { Transaction } from '../../reducers/admin/allTransactions.slice'
import { TAX_ENTITY_TYPES, TAX_ENTITY_TYPES_TYPE } from '../Taxes/taxConstants'
import { TRANSACTION_CATEGORY_IDENTIFIERS } from './constants'
import { Link, Text } from '../../components/BaseComponents'
import { TransactionChangeLog } from '../Admin/TransactionChangeLogs/transactionChangeLog.slice'
import { TransactionCategory } from '../Reports/reports.slice'

export function isTransactionLocked({
  date,
  userLockedYear,
  adminLockedYear,
  isAdmin,
}: {
  date: string
  userLockedYear: string | null | undefined
  adminLockedYear: string | null | undefined
  isAdmin: boolean | null | undefined
}) {
  /**
   * Transaction is locked for an admin if:
   * books are locked for admins && transaction's date <= end of admin locked year
   *
   * Transaction is locked for a user if:
   * a) books are locked for user && transaction's date <= end of user locked year
   * b) transaction's date < start of last year
   */

  // Use .utc() to prevent local GMT offset from being appended
  const transactionDate = moment.utc(date)

  const isLocked = false
  if (isAdmin) {
    if (adminLockedYear) {
      const endOfLockedYear = moment.utc(adminLockedYear).endOf('year')
      return transactionDate.isSameOrBefore(endOfLockedYear)
    }
  } else {
    // a
    if (userLockedYear) {
      const endOfLockedYear = moment.utc(userLockedYear).endOf('year')
      return transactionDate.isSameOrBefore(endOfLockedYear)
    }
    // b
    const startOfLastYear = moment().utc().startOf('year').subtract(1, 'year')
    return transactionDate.isBefore(startOfLastYear)
  }

  return isLocked
}

export const formatAccountName = (
  account?: Pick<FinancialAccount, 'name' | 'mask' | 'inactive'>
) =>
  `${account?.name}${account?.mask ? `: ${account.mask}` : ''}${
    account?.inactive ? ' (inactive)' : ''
  }`

export const formatAccountNameWithInstitution = (
  account?: Pick<
    FinancialAccount,
    'name' | 'mask' | 'inactive' | 'plaidInstitutionName'
  >
) => {
  let formattedName = formatAccountName(account)
  if (account?.plaidInstitutionName) {
    formattedName = `${account.plaidInstitutionName} - ${formattedName}`
  }
  return formattedName
}

export const formatTransactionAccountName = (
  { accountTransactions }: Transaction,
  account?: Pick<FinancialAccount, 'name' | 'mask' | 'inactive'>
) => {
  if (account) {
    return formatAccountName(account)
  }

  // Fallback to information on transaction.  It has more limited information
  return `${accountTransactions?.name}${
    accountTransactions?.mask ? `: ${accountTransactions.mask}` : ''
  }`
}

export const isPersonalExpense = (categoryIdentifier = '') => {
  return [
    TRANSACTION_CATEGORY_IDENTIFIERS.mealsAndEntertainment,
    TRANSACTION_CATEGORY_IDENTIFIERS.officeExpenses,
    TRANSACTION_CATEGORY_IDENTIFIERS.travelExpenses,
  ].includes(categoryIdentifier)
}

export const isOfficeExpense = (categoryIdentifier = '') => {
  return [
    TRANSACTION_CATEGORY_IDENTIFIERS.computerHardware,
    TRANSACTION_CATEGORY_IDENTIFIERS.equipmentFurniture,
    TRANSACTION_CATEGORY_IDENTIFIERS.equipment,
    TRANSACTION_CATEGORY_IDENTIFIERS.furniture,
  ].includes(categoryIdentifier)
}

export const isMedicalExpense = (categoryIdentifier = '') => {
  return [
    TRANSACTION_CATEGORY_IDENTIFIERS.insuranceDental,
    TRANSACTION_CATEGORY_IDENTIFIERS.insuranceHealth,
  ].includes(categoryIdentifier)
}

export const getRecategorizationExplanation = (
  fromCategory?: TransactionCategory | null,
  toCategory?: TransactionCategory | null,
  taxEntityType?: TAX_ENTITY_TYPES_TYPE | null
) => {
  const isSoleProp =
    taxEntityType === TAX_ENTITY_TYPES.form_1040 ||
    taxEntityType === TAX_ENTITY_TYPES.schedule_c
  const isScorp = taxEntityType && !isSoleProp

  if (
    isSoleProp &&
    fromCategory?.identifier ===
      TRANSACTION_CATEGORY_IDENTIFIERS.ownersDistribution &&
    toCategory?.identifier ===
      TRANSACTION_CATEGORY_IDENTIFIERS.payrollSalariesAndWages
  ) {
    return {
      reason: (
        <Text>
          We don&apos;t categorize paying yourself as{' '}
          <b>Payroll: Salaries & Wages</b>
          because this is reserved only for when you&apos;re paying other people
          through your payroll provider. A sole proprietor cannot receive form
          W-2 and pay themselves wages through payroll.
        </Text>
      ),
      importance: (
        <Text>
          <b>Owner&apos;s Distributions</b> need to be categorized accurately
          because if they&apos;re over or under stated in your books, then you
          may pay too much or too little for your overall tax payment.
        </Text>
      ),
    }
  }

  if (
    isScorp &&
    fromCategory?.identifier ===
      TRANSACTION_CATEGORY_IDENTIFIERS.ownersDistribution &&
    isPersonalExpense(toCategory?.identifier)
  ) {
    return {
      reason: (
        <Text>
          This transaction was categorized as an{' '}
          <b>Owner&apos;s Distribution</b> because it was a transfer from your
          business bank account to a personal bank account or it was for a
          personal expense.
        </Text>
      ),
      importance: (
        <Text>
          We need to make sure all distributions are correct or else your{' '}
          <Link href="https://www.irs.gov/pub/irs-pdf/f1065sk1.pdf" newPage>
            Schedule K
          </Link>{' '}
          from your 1120S will be wrong. Schedule K goes into your Form 1040,
          which would also make your personal tax return wrong if we over or
          under stated <b>Owner&apos;s Distributions</b>.
        </Text>
      ),
    }
  }

  if (
    fromCategory?.identifier ===
      TRANSACTION_CATEGORY_IDENTIFIERS.moneyTransfer &&
    toCategory?.identifier ===
      TRANSACTION_CATEGORY_IDENTIFIERS.ownersDistribution
  ) {
    return {
      reason: (
        <Text>
          This transaction was categorized as a <b>Money Transfer</b> because it
          was a transfer between two business bank accounts.
        </Text>
      ),
      importance: (
        <Text>
          It&apos;s important to categorize these asset transfers without
          affecting one&apos;s Profit and Loss statement. The balance of the
          account should always be $0, because whatever is going in should have
          an “out” in another account.
        </Text>
      ),
    }
  }

  if (
    fromCategory?.identifier ===
      TRANSACTION_CATEGORY_IDENTIFIERS.ownersDistribution &&
    (toCategory?.identifier === TRANSACTION_CATEGORY_IDENTIFIERS.taxesFederal ||
      toCategory?.identifier === TRANSACTION_CATEGORY_IDENTIFIERS.taxesState)
  ) {
    const isFederal =
      toCategory.identifier === TRANSACTION_CATEGORY_IDENTIFIERS.taxesFederal
    return {
      reason: (
        <Text>
          Tax payments need to be paid by the individual tax payer, not the
          business itself. Therefore, {isFederal ? 'federal' : 'state'} tax
          payments are personal expenses, which is why we&apos;ve categorized
          this as an <b>Owner&apos;s Distribution</b>.
        </Text>
      ),
      importance: (
        <Text>
          When tax payments are in a practice owner&apos;s books and Profit &
          Loss statement, this may lead to inaccurate quarterly tax estimates
          and an inaccurate financial summary of how the practice is doing.
        </Text>
      ),
    }
  }

  if (
    fromCategory?.identifier ===
      TRANSACTION_CATEGORY_IDENTIFIERS.officeExpenses &&
    isOfficeExpense(toCategory?.identifier)
  ) {
    let displayCategory = 'equipment'
    if (
      toCategory?.identifier ===
        TRANSACTION_CATEGORY_IDENTIFIERS.equipmentFurniture ||
      toCategory?.identifier === TRANSACTION_CATEGORY_IDENTIFIERS.furniture
    ) {
      displayCategory = 'furniture'
    }
    if (
      toCategory?.identifier ===
      TRANSACTION_CATEGORY_IDENTIFIERS.computerHardware
    ) {
      displayCategory = 'computer hardware'
    }
    return {
      reason: (
        <Text>
          An asset such as {displayCategory} that costs less than $2500 is
          expensed immediately in a Profit & Loss statement, and will be
          categorized as <b>Office Expense</b>. When it costs more than $2500,
          it has to be depreciated and will use the specific asset category:{' '}
          <b>{toCategory?.name}</b>.
        </Text>
      ),
      importance: (
        <Text>
          Mis-categorization can lead to inaccurate books, and it also affects
          the accuracy of the tax return if an asset is capitalized while it
          should&apos;ve been expensed, or vice versa.
        </Text>
      ),
    }
  }

  if (
    fromCategory?.identifier ===
      TRANSACTION_CATEGORY_IDENTIFIERS.ownersDistribution &&
    isMedicalExpense(toCategory?.identifier)
  ) {
    return {
      reason: (
        <Text>
          Since medical expenses are not business expenses, they&apos;re
          categorized as <b>Owner&apos;s Distributions</b> as a way of
          transferring funds out of a business checking account to cover
          personal expenses.
        </Text>
      ),
      importance: (
        <Text>
          Medical expenses such as surgeries, medication prescriptions,
          optometry expenses, dental and vision care are personal expenses and
          don&apos;t go in one&apos;s books (but they may be tax deductible on
          Form 1040).
        </Text>
      ),
    }
  }

  if (
    (fromCategory?.identifier ===
      TRANSACTION_CATEGORY_IDENTIFIERS.professionalDevelopment &&
      toCategory?.identifier ===
        TRANSACTION_CATEGORY_IDENTIFIERS.continuingEducation) ||
    (fromCategory?.identifier ===
      TRANSACTION_CATEGORY_IDENTIFIERS.continuingEducation &&
      toCategory?.identifier ===
        TRANSACTION_CATEGORY_IDENTIFIERS.professionalDevelopment)
  ) {
    return {
      reason: (
        <Text>
          <b>Continuing Education</b> is therapy and mental health-related.{' '}
          <b>Professional Development</b> is typically more business and
          entrepreneurial-related.
        </Text>
      ),
      importance: (
        <Text>
          We want to make sure the identical transactions are categorized
          consistently across the board.
        </Text>
      ),
    }
  }

  return null
}

export const getTaxCompliantExplanation = (
  categoryIdentifier?: string,
  taxEntityType?: TAX_ENTITY_TYPES_TYPE | null
) => {
  const isSoleProp =
    taxEntityType === TAX_ENTITY_TYPES.form_1040 ||
    taxEntityType === TAX_ENTITY_TYPES.schedule_c
  const isScorp = taxEntityType && !isSoleProp
  switch (categoryIdentifier) {
    case TRANSACTION_CATEGORY_IDENTIFIERS.ownersDistribution:
      return (
        <Text color="darkGray" as="bodySm">
          Owner&apos;s Distribution is a transfer of money from a business bank
          account to a personal bank account.
        </Text>
      )
    case TRANSACTION_CATEGORY_IDENTIFIERS.moneyTransfer:
      return (
        <Text color="darkGray" as="bodySm">
          Money Transfer is a transfer of money between any business checking
          and savings accounts.
        </Text>
      )
    case TRANSACTION_CATEGORY_IDENTIFIERS.officeExpenses:
      return (
        <Text color="darkGray" as="bodySm">
          Office Expenses are any costs related to maintaining and operating the
          business. This could range from office supplies to certain pieces of
          furniture and equipment (that are less than $2500 value).
        </Text>
      )
    case TRANSACTION_CATEGORY_IDENTIFIERS.creditCardPayments:
      return (
        <Text color="darkGray" as="bodySm">
          Credit Card Payments are made out of a company&apos;s checking account
          in order to pay down any revolving credit which they have accumulated
          on their business line of credit. This category can only be used if
          the payment was made from a business checking account to pay down a
          business credit card.
        </Text>
      )
    case TRANSACTION_CATEGORY_IDENTIFIERS.payrollSalariesAndWages:
      if (isSoleProp)
        return (
          <Text color="darkGray" as="bodySm">
            Payroll: Salaries & Wages (Sole prop) are pay made to a practice
            owner&apos;s employees. When the practice owner pays themselves,
            it&apos;s an Owner&apos;s Distribution.
          </Text>
        )
      else if (isScorp)
        return (
          <Text color="darkGray" as="bodySm">
            Payroll: Salaries & Wages (S corp) is used when an S corp owner runs
            payroll and pays themselves as a W-2 employee.
          </Text>
        )
      return null
    case TRANSACTION_CATEGORY_IDENTIFIERS.ownersInvestments:
      if (isSoleProp)
        return (
          <Text color="darkGray" as="bodySm">
            Owner&apos;s Investments (Sole prop) is money from a personal
            account transferred to a business account to keep the practice up
            and running. It can also include paying for expenses on behalf of
            the business.
          </Text>
        )
      else if (isScorp)
        return (
          <Text color="darkGray" as="bodySm">
            Owner&apos;s Investments (S corp) are funds from a
            shareholder&apos;s personal account which are deposited into the
            business account in order to keep the practice up and running.
          </Text>
        )
      return null
    case TRANSACTION_CATEGORY_IDENTIFIERS.payrollTaxes:
      return (
        <Text color="darkGray" as="bodySm">
          Payroll Taxes are the withdrawal from an employee&apos;s paycheck to
          pay a variety of taxes. These tax withdrawals are split between the
          employer and employee, and are based on the paycheck amount.
        </Text>
      )
    case TRANSACTION_CATEGORY_IDENTIFIERS.insuranceHealth:
      if (isSoleProp)
        return (
          <Text color="darkGray" as="bodySm">
            Insurance - Health (Sole prop) payments aren&apos;t deductible as a
            business expense, but they are tax deductible on a personal income
            tax return.
          </Text>
        )
      else if (isScorp)
        return (
          <Text color="darkGray" as="bodySm">
            Insurance - Health (S corp) payments are run through payroll.
            They&apos;re deductible as a business expense as well as on the S
            corp owner&apos;s income tax return.
          </Text>
        )
      return null
    case TRANSACTION_CATEGORY_IDENTIFIERS.payrollContractors:
      return (
        <Text color="darkGray" as="bodySm">
          Payroll: Contractors are payments made to 1099 independent
          contractors.
        </Text>
      )
    case TRANSACTION_CATEGORY_IDENTIFIERS.payrollExpensesAndFees:
      return (
        <Text color="darkGray" as="bodySm">
          Payroll Expenses: Fees are payments made to a payroll provider.
        </Text>
      )
    case TRANSACTION_CATEGORY_IDENTIFIERS.equipment:
      return (
        <Text color="darkGray" as="bodySm">
          Equipment is a necessary expense for a practice&apos;s office or home
          office that&apos;s more than $2500 in value. This includes computer
          equipment.
        </Text>
      )
    case TRANSACTION_CATEGORY_IDENTIFIERS.equipmentFurniture:
      return (
        <Text color="darkGray" as="bodySm">
          Furniture is a necessary expense for a practice&apos;s office or home
          office that&apos;s more than $2500 in value.
        </Text>
      )
    case TRANSACTION_CATEGORY_IDENTIFIERS.computerHardware:
      return (
        <Text color="darkGray" as="bodySm">
          Computer: Hardware includes necessary hardware costing more than
          $2500.
        </Text>
      )
    case TRANSACTION_CATEGORY_IDENTIFIERS.taxesFederal:
      return (
        <Text color="darkGray" as="bodySm">
          Federal Taxes are payments for income tax to the IRS, including
          quarterly tax payments.These payments are categorized as personal
          expenses.
        </Text>
      )
    case TRANSACTION_CATEGORY_IDENTIFIERS.taxesState:
      if (isSoleProp)
        return (
          <Text color="darkGray" as="bodySm">
            State Taxes(Sole prop) are payments made to the state where the
            business operations in, including quarterly tax payments.
            They&apos;re categorized as personal expenses.
          </Text>
        )
      return null
    case TRANSACTION_CATEGORY_IDENTIFIERS.taxesFranchise:
      if (isScorp)
        return (
          <Text color="darkGray" as="bodySm">
            Franchise Taxes (S corp) are taxes that states charge on
            corporations and other business entities such as LLCs for the
            privilege of incorporating or doing business in their state.
          </Text>
        )
      return null
    case TRANSACTION_CATEGORY_IDENTIFIERS.retirementContribution:
      if (isSoleProp)
        return (
          <Text color="darkGray" as="bodySm">
            Retirement Contributions (Sole prop) are funds set aside for
            qualified retirement accounts. These contributions could be made
            into 401 (k) plan, 401(k) match received from an employer, IRA, Roth
            IRA, etc.
          </Text>
        )
      return null
    case TRANSACTION_CATEGORY_IDENTIFIERS.payrollEmployeeBenefits:
      return (
        <Text color="darkGray" as="bodySm">
          Payroll: Employee Benefits are used to categorize an S corp
          owner&apos;s retirement contributions, since S corps have retirement
          plans through payroll that can be deducted. It also refers to employee
          benefit packages in general.
        </Text>
      )
    case TRANSACTION_CATEGORY_IDENTIFIERS.rentAndLease:
      return (
        <Text color="darkGray" as="bodySm">
          Rent is a common expense but sometimes requires a 1099-MISC to be
          filed. If you&apos;re paying rent to an individual (via checks or
          direct money transfers) then you may need to file a 1099-MISC to
          report the rents paid.
        </Text>
      )
    case TRANSACTION_CATEGORY_IDENTIFIERS.gifts:
      return (
        <Text color="darkGray" as="bodySm">
          A business gift is given in the course of your trade or business.
          Additional context on categorization of business gifts needs to be
          provided. The IRS maximum gift per person is $25 for a deduction.
        </Text>
      )
    case TRANSACTION_CATEGORY_IDENTIFIERS.otherExpenses:
      return (
        <Text color="darkGray" as="bodySm">
          Other expense is a highly audited expense category by the IRS. If the
          transaction doesn&apos;t fall into an applicable category, we&apos;ll
          use the notes to describe the expense in the tax return.
        </Text>
      )
    case TRANSACTION_CATEGORY_IDENTIFIERS.uniformAndLaundry:
      return (
        <Text color="darkGray" as="bodySm">
          Clothing used for personal reasons cannot be deducted. The IRS rules
          that clothing that has the business branding are typically deemed
          “business use”; clothing that does not have company branding is
          typically deemed “personal use” and is non-deductible.
        </Text>
      )
    default:
      return null
  }
}

export const categoryLastChangedByBookkeeper = (
  userId: number,
  changeLogs?: TransactionChangeLog[]
) => {
  const latestLog = changeLogs
    ?.filter((log) => log.columnName === 'transactionCategoryId')
    .sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })[0]

  return latestLog?.authorId !== userId
}
