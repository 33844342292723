import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Grid, List } from 'semantic-ui-react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import { FORM_1120_S_SCREENS, Form1120sProps } from '.'
import {
  GridRowColumn,
  Text,
  TileSelector,
} from '../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { NoneApply, useTiles } from '../Shared/UseTiles'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../taxChecklist.selectors'
import { TaxListQuestionId } from '../service'
import { deleteTaxQuestionnaireResponses } from '../taxChecklistQuestion.actions'
import { SubStepIdentifiers } from '../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { TaxChecklistResponse } from '../taxChecklistQuestion.slice'
import { useAppDispatch } from '../../../../../utils/typeHelpers'

export const uncommonScenariosQuestionIds = [
  TaxListQuestionId.scorp_owned_other_businesses,
  TaxListQuestionId.invested_in_rnd,
  TaxListQuestionId.sold_fixed_assets,
  TaxListQuestionId.engaged_in_real_estate,
]

const UncommonScenariosPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: Form1120sProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()
  const dispatch = useAppDispatch()
  const questionOwnedOtherBiz = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.scorp_owned_other_businesses,
    taxYear
  )
  const responsesOwnedOtherBiz = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.scorp_owned_other_businesses,
    Number(formId)
  )
  const questionRnd = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.invested_in_rnd,
    taxYear
  )
  const responsesRnd = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.invested_in_rnd,
    Number(formId)
  )
  const questionSoldfixedAssets = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.sold_fixed_assets,
    taxYear
  )
  const responsesSoldfixedAssets = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.sold_fixed_assets,
    Number(formId)
  )
  const questionRealEstate = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.engaged_in_real_estate,
    taxYear
  )
  const responsesRealEstate = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.engaged_in_real_estate,
    Number(formId)
  )
  const responsesNumberOfRealEstate = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_of_real_estate,
    Number(formId)
  )
  // Derived taxfyle question
  const responsesHasBusinessInventory = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.business_inventory,
    Number(formId)
  )
  const responsesNumberOfK1s = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_of_k1s_scorp,
    Number(formId)
  )
  const responseNumberOtherBiz = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.scorp_number_other_businesses,
    Number(formId)
  )

  const {
    tiles,
    setTiles,
    noneApply,
    tileColumnStyle,
    tileClicked,
    tileWidth,
    setTileWidth,
    tilesToResponses,
    anyResponseSelected,
  } = useTiles()

  // If only R&D is selected, we want to skip the follow-up questions
  const rndOnly = useMemo(() => {
    const selected = tiles.filter((t) => t.response?.value)
    return Boolean(
      selected.length === 1 &&
        selected[0].questionId === TaxListQuestionId.invested_in_rnd
    )
  }, [tiles])

  useEffect(() => {
    setTileWidth(192)
    setTiles([
      {
        icon: light('buildings'),
        text: questionOwnedOtherBiz?.question?.text ?? '',
        questionId: TaxListQuestionId.scorp_owned_other_businesses,
        response: responsesOwnedOtherBiz?.[0],
      },
      {
        icon: light('flask-vial'),
        text: questionRnd?.question?.text ?? '',
        questionId: TaxListQuestionId.invested_in_rnd,
        response: responsesRnd?.[0],
      },
      {
        icon: light('computer'),
        text: questionSoldfixedAssets?.question?.text ?? '',
        questionId: TaxListQuestionId.sold_fixed_assets,
        response: responsesSoldfixedAssets?.[0],
      },
      {
        icon: light('sign-hanging'),
        text: questionRealEstate?.question?.text ?? '',
        questionId: TaxListQuestionId.engaged_in_real_estate,
        response: responsesRealEstate?.[0],
      },
    ])
  }, [
    setTiles,
    responsesOwnedOtherBiz,
    responsesRnd,
    responsesSoldfixedAssets,
    responsesRealEstate,
    questionOwnedOtherBiz,
    questionRnd,
    questionSoldfixedAssets,
    questionRealEstate,
    setTileWidth,
  ])

  const tileOtherBiz = useMemo(
    () =>
      tiles.find(
        (t) => t.questionId === TaxListQuestionId.scorp_owned_other_businesses
      ),
    [tiles]
  )
  const tileRnd = useMemo(
    () => tiles.find((t) => t.questionId === TaxListQuestionId.invested_in_rnd),
    [tiles]
  )
  const tileSoldAssets = useMemo(
    () =>
      tiles.find((t) => t.questionId === TaxListQuestionId.sold_fixed_assets),
    [tiles]
  )
  const tileRealEstate = useMemo(
    () =>
      tiles.find(
        (t) => t.questionId === TaxListQuestionId.engaged_in_real_estate
      ),
    [tiles]
  )

  const saveAndContinue = useAsyncCallback(async () => {
    const responseData: Partial<TaxChecklistResponse>[] = []
    const didOwnOtherBiz = tiles.find(
      (t) => t.questionId === TaxListQuestionId.scorp_owned_other_businesses
    )?.response?.value
    const didSellAssets = tiles.find(
      (t) => t.questionId === TaxListQuestionId.sold_fixed_assets
    )?.response?.value
    const didHaveRealEstate = tiles.find(
      (t) => t.questionId === TaxListQuestionId.engaged_in_real_estate
    )?.response?.value

    const idsToDelete: number[] = []
    if (!didOwnOtherBiz) {
      if (responseNumberOtherBiz?.[0]?.value !== 0) {
        responseData.push({
          id: responseNumberOtherBiz?.[0]?.id,
          value: 0,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.scorp_number_other_businesses,
        })
      }
      if (responsesNumberOfK1s?.[0]?.value !== 1) {
        responseData.push({
          id: responsesNumberOfK1s?.[0]?.id,
          value: 1, // at least 1 for their therapy biz
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.number_of_k1s_scorp,
        })
      }
    }
    if (!didSellAssets && responsesHasBusinessInventory?.[0]?.value !== false) {
      responseData.push({
        id: responsesHasBusinessInventory?.[0]?.id,
        value: false,
        annualTaxFilingFormId: Number(formId),
        questionId: TaxListQuestionId.business_inventory,
      })
    }
    if (!didHaveRealEstate && responsesNumberOfRealEstate?.[0]?.value !== 0) {
      responseData.push({
        id: responsesNumberOfRealEstate?.[0]?.id,
        value: 0,
        annualTaxFilingFormId: Number(formId),
        questionId: TaxListQuestionId.number_of_real_estate,
      })
    }

    let deletionSucceeded = true
    if (idsToDelete.length > 0) {
      deletionSucceeded = Boolean(
        await dispatch(deleteTaxQuestionnaireResponses(idsToDelete))
      )
    }
    const goToScreen =
      anyResponseSelected && !rndOnly
        ? (nextScreen ?? null)
        : FORM_1120_S_SCREENS.review
    if (deletionSucceeded) {
      const completedSteps: SubStepIdentifiers[] = []
      const incompleteSteps: SubStepIdentifiers[] = []
      if (goToScreen === FORM_1120_S_SCREENS.review) {
        completedSteps.push(SubStepIdentifiers.lessCommonScenarios1120s)
      } else {
        incompleteSteps.push(SubStepIdentifiers.lessCommonScenarios1120s)
      }
      await goToNextStep([...tilesToResponses(), ...responseData], goToScreen, {
        completedSteps,
        incompleteSteps,
      })
    }
  })

  return (
    <>
      <Container style={{ marginTop: 56 }} text textAlign="left">
        <Grid>
          <GridRowColumn>
            <Text as="display2" textAlign="center">
              Less Common Scenarios
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="bodyLg">
              These are scenarios that are not common for most therapists, but
              they do allow us to get a fuller picture of your tax situation.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="bodyLg">
              Please select all that apply for your business in {taxYear}.
            </Text>
          </GridRowColumn>
          <GridRowColumn columnStyle={tileColumnStyle}>
            {tileOtherBiz && (
              <TileSelector
                key={tileOtherBiz.questionId}
                icon={tileOtherBiz.icon}
                active={Boolean(tileOtherBiz.response?.value)}
                text={tileOtherBiz.text}
                width={tileWidth}
                height={207}
                onClick={() => tileClicked(tileOtherBiz.questionId)}
                popup={{
                  title: 'Owned businesses other than therapy practice',
                  body: (
                    <Grid>
                      <GridRowColumn>
                        <List bulleted>
                          <List.Item>
                            <Text>
                              You receive income from and spend money on this
                              business in a separate business bank account than
                              the one in Heard.
                            </Text>
                          </List.Item>
                          <List.Item>
                            <Text>
                              None of these transactions from this business are
                              in Heard
                            </Text>
                          </List.Item>
                        </List>
                      </GridRowColumn>
                    </Grid>
                  ),
                }}
              />
            )}
            {tileRnd && (
              <TileSelector
                key={tileRnd.questionId}
                icon={tileRnd.icon}
                active={Boolean(tileRnd.response?.value)}
                text={tileRnd.text}
                width={tileWidth}
                height={207}
                onClick={() => tileClicked(tileRnd.questionId)}
                popup={{
                  title: 'Invested in research & development of new technology',
                  body: (
                    <Text>
                      Your business has invested in research and the development
                      for new products that your business is offering or for
                      improving old products.
                    </Text>
                  ),
                }}
              />
            )}
          </GridRowColumn>
          <GridRowColumn columnStyle={tileColumnStyle}>
            {tileSoldAssets && (
              <TileSelector
                key={tileSoldAssets.questionId}
                icon={tileSoldAssets.icon}
                active={Boolean(tileSoldAssets.response?.value)}
                text={tileSoldAssets.text}
                width={tileWidth}
                height={207}
                onClick={() => tileClicked(tileSoldAssets.questionId)}
                popup={{
                  title: 'Sold fixed assets',
                  body: (
                    <Text>
                      Select this if you&apos;ve sold any assets over $2,500,{' '}
                      <b>that aren&apos;t already recorded in Heard</b> and fall
                      into one of these categories: Equipment, Furniture or
                      Computer.
                    </Text>
                  ),
                }}
              />
            )}
            {tileRealEstate && (
              <TileSelector
                key={tileRealEstate.questionId}
                icon={tileRealEstate.icon}
                active={Boolean(tileRealEstate.response?.value)}
                text={tileRealEstate.text}
                width={tileWidth}
                height={207}
                onClick={() => tileClicked(tileRealEstate.questionId)}
                popup={{
                  title: 'Engaged in rental real estate',
                  body: (
                    <Text>
                      Your bought or sold property, buildings, etc. through your
                      business. For example, if you bought an office space
                      through your business, that would count.
                    </Text>
                  ),
                }}
              />
            )}
          </GridRowColumn>
          <GridRowColumn columnStyle={tileColumnStyle}>
            <NoneApply
              width={noneApply.width}
              selected={noneApply.selected}
              setSelection={noneApply.clearTiles}
            />
          </GridRowColumn>
        </Grid>
      </Container>
      <Grid>
        <FormFlowFooter
          continueDisabled={
            (!noneApply.selected && !anyResponseSelected) ||
            saveAndContinue.loading
          }
          onBack={() => goBack(previousScreen ?? null)}
          onForward={saveAndContinue.callback}
          loading={saveAndContinue.loading}
        />
      </Grid>
    </>
  )
}

export default UncommonScenariosPanel
