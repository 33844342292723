// BL
import { requiredDocumentUploadButton as copy } from '../../copyConstants'
import { openUploadModal, useFeatureDispatch } from '../../state'
import { UserDocument } from '../../userDocuments.slice'

// UI
import { Button } from '../../../../components/BaseComponents'
import { useAnalyticsTrack } from '../../../Amplitude'

interface UploadRequiredDocumentButtonProps {
  document: UserDocument
}

const UploadRequiredDocumentButton = ({
  document,
}: UploadRequiredDocumentButtonProps) => {
  const dispatch = useFeatureDispatch()
  const track = useAnalyticsTrack()

  const openModal = () => {
    track('clicked document upload', {
      document_category: document.documentType || '',
      document_subcategory: document.userDocumentCategoryId || '',
    })
    dispatch?.(
      openUploadModal(
        document.documentType || '',
        document.userDocumentCategoryId
      )
    )
  }
  return (
    <Button onClick={openModal} variant="secondary">
      {copy.static.buttonText}
    </Button>
  )
}

export default UploadRequiredDocumentButton
