import { orderBy } from 'lodash'
import { createSelector } from 'reselect'

import { ReduxState } from '../../../utils/typeHelpers'
import { ABOUND_STATUS, TIN_VERIFICATION_STATUS } from './Ten99Filing/constants'
import { selectCurrentAnnualTaxDetails } from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { isoToUTCDateTime } from '../../../utils/dateHelpers'

const aboundAnnualTaxFilings = (state: ReduxState) =>
  state.aboundAnnualTaxFilings

export const getCurrentAboundPayer = createSelector(
  aboundAnnualTaxFilings,
  (state) => state.payer
)
export const getContractorsSortedByCreatedAt = createSelector(
  aboundAnnualTaxFilings,
  (state) =>
    orderBy(
      Object.values(state.contractors || {}),
      [(contractor) => contractor.createdAt],
      'desc'
    )
)

export const selectAboundContractorsByUUID = createSelector(
  aboundAnnualTaxFilings,
  (_: unknown, uuid: string | undefined) => uuid,
  (state, uuid) => (state.contractors && uuid ? state.contractors[uuid] : null)
)

export const getAboundTaxDocsForPayer = createSelector(
  aboundAnnualTaxFilings,
  (state) => state.taxDocs
)

export const selectAboundTaxDocsForPayerForYear = createSelector(
  getAboundTaxDocsForPayer,
  (_: unknown, year: string | undefined) => year,
  (docsById, year) =>
    docsById
      ? Object.values(docsById).filter((doc) => doc.calendarYear === year)
      : []
)

export const selectPayerHasAboundTaxDocsForYear = createSelector(
  selectAboundTaxDocsForPayerForYear,
  (docs) => docs.length > 0
)

export const getAboundTaxDocForPayerByContractorId = createSelector(
  aboundAnnualTaxFilings,
  (_: unknown, id: number | undefined) => id,
  (state, id) => (state.taxDocs && id ? state.taxDocs[id] : undefined)
)

export const selectCurrent1099sCount = createSelector(
  getAboundTaxDocsForPayer,
  (ten99s) => {
    const ten99StateVals = Object.values(ten99s || {})

    return ten99StateVals.length
  }
)

export const selectAreAll1099sSubmitted = createSelector(
  getAboundTaxDocsForPayer,
  (ten99s) => {
    const ten99StateVals = Object.values(ten99s || {})

    return ten99StateVals.length
      ? !ten99StateVals.some(
          (ten99) => ten99.aboundStatus === ABOUND_STATUS.not_started
        )
      : false
  }
)

export const selectAreAll1099sApprovedForFilingWithAbound = createSelector(
  getAboundTaxDocsForPayer,
  (ten99s) => {
    const ten99StateVals = Object.values(ten99s || {})
    return ten99StateVals.length
      ? ten99StateVals.every((ten99) => ten99.approvedByUser)
      : false
  }
)
export const selectAreAll1099sFiled = createSelector(
  getAboundTaxDocsForPayer,
  (ten99s) => {
    const ten99StateVals = Object.values(ten99s || {})
    return ten99StateVals.length
      ? !ten99StateVals.some(
          (ten99) => ten99.aboundStatus !== ABOUND_STATUS.done
        )
      : false
  }
)

export const selectLastFiled1099 = createSelector(
  getAboundTaxDocsForPayer,
  selectAreAll1099sFiled,
  (ten99s, allFiled) => {
    if (allFiled) {
      const ten99StateVals = Object.values(ten99s || {})
      const mostRecentlyUpdated = orderBy(
        Object.values(ten99StateVals || {}),
        ['updatedAt'],
        ['desc']
      )
      return mostRecentlyUpdated[0]
    }
    return null
  }
)

export const selectHasAnyContractorsFromPreviousTaxYears = createSelector(
  [getContractorsSortedByCreatedAt, selectCurrentAnnualTaxDetails],
  (contractors, annualTaxDetails) => {
    if (annualTaxDetails) {
      const { ten99NECFormsStartAt } = annualTaxDetails
      if (!contractors) {
        return false
      }
      return contractors.some((contractor) => {
        const createdAt = contractor.createdAt ?? ''
        return (
          isoToUTCDateTime(createdAt) < isoToUTCDateTime(ten99NECFormsStartAt)
        )
      })
    }
    return false
  }
)

export const selectContractorIsFromPreviousTaxYears = createSelector(
  [selectAboundContractorsByUUID, selectCurrentAnnualTaxDetails],
  (contractor, annualTaxDetails) => {
    if (annualTaxDetails && contractor?.createdAt) {
      const { ten99NECFormsStartAt } = annualTaxDetails
      return (
        isoToUTCDateTime(contractor.createdAt) <
        isoToUTCDateTime(ten99NECFormsStartAt)
      )
    }
    return false
  }
)

export const contractorIsEditable = createSelector(
  [selectAboundContractorsByUUID, getAboundTaxDocsForPayer],
  (contractor, taxDocs) => {
    if (contractor) {
      if (!taxDocs) {
        return true
      }
      const taxDoc = taxDocs[contractor.id]
      return (
        !taxDoc ||
        !taxDoc.approvedByUser ||
        contractor.tinVerificationStatus === TIN_VERIFICATION_STATUS.mismatch
      )
    }
    return false
  }
)
