import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { uniqBy } from 'lodash'
import {
  Gusto_EmployeeJob,
  Gusto_FlsaStatusEnum,
  Gusto_JobCompensation,
} from './generated_gusto_types'

export const PaymentUnit: Record<
  NonNullable<Gusto_EmployeeJob['payment_unit']>,
  NonNullable<Gusto_EmployeeJob['payment_unit']>
> = {
  Hour: 'Hour',
  Week: 'Week',
  Month: 'Month',
  Year: 'Year',
  Paycheck: 'Paycheck',
}

export const FlsaStatus: Record<Gusto_FlsaStatusEnum, Gusto_FlsaStatusEnum> = {
  Exempt: 'Exempt',
  'Salaried Nonexempt': 'Salaried Nonexempt',
  Nonexempt: 'Nonexempt',
  Owner: 'Owner',
  'Commission Only Exempt': 'Commission Only Exempt',
  'Commission Only Nonexempt': 'Commission Only Nonexempt',
  Unknown: 'Unknown',
}

export interface EmployeeJobState {
  [key: string]: Gusto_EmployeeJob
}

const employeeJobSlice = createSlice({
  name: 'employeeJob',
  initialState: {} as EmployeeJobState,
  reducers: {
    addEmployeeJob: (state, action: PayloadAction<Gusto_EmployeeJob>) => {
      state[action.payload.uuid] = action.payload
    },
    addMultipleEmployeeJobs: (
      state,
      action: PayloadAction<{ [key: string]: Gusto_EmployeeJob }>
    ) => ({ ...state, ...action.payload }),
    updateCompensations: (
      state,
      action: PayloadAction<{
        jobUuid: string
        compensations: Gusto_JobCompensation[]
      }>
    ) => {
      if (state[action.payload.jobUuid]) {
        state[action.payload.jobUuid].compensations =
          action.payload.compensations
      }
    },
    updateSingleCompensation: (
      state,
      action: PayloadAction<Gusto_JobCompensation>
    ) => {
      if (state[action.payload.job_uuid]) {
        state[action.payload.job_uuid].compensations = uniqBy(
          [action.payload, ...state[action.payload.job_uuid].compensations],
          'uuid'
        )
      }
    },
    removeEmployeeJob: (
      state,
      action: PayloadAction<{ jobUuid: string; employeeUuid: string }>
    ) => {
      delete state[action.payload.jobUuid]
    },
  },
})

export const {
  addEmployeeJob,
  addMultipleEmployeeJobs,
  removeEmployeeJob,
  updateCompensations,
  updateSingleCompensation,
} = employeeJobSlice.actions

export default employeeJobSlice.reducer
