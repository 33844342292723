import { createRef, RefObject } from 'react'
import { createSelector } from 'reselect'
import { flatten } from 'lodash'
import moment from 'moment'
import { FormikProps, FormikValues } from 'formik'
import { DateTime } from 'luxon'

import { filterNulls, ReduxState } from '../../../utils/typeHelpers'
import {
  getAnnualTaxFilingForms,
  select1040FormForYear,
  select1120sFormForYear,
  selectAnnualTaxFilingFormById,
  selectFilingFormsForCurrentTaxYear,
  selectFilingFormsForYear,
  selectIsTwoFormFiler,
} from './annualTaxFilingForms.selector'
import {
  AnnualTaxFiling,
  ConfirmAnnualTaxFiling,
  DependentRelationship,
  DependentsDetails,
  needContractorFilingOption,
} from './annualTaxFilings.slice'
import { AnnualTaxFilingNonNormalized } from '../../Admin/Taxes/adminAnnualTaxFilings.slice'
import {
  ANNUAL_TAX_FILING_FORM_TYPES,
  TaxFormType,
} from './Questionnaires/constants'
import {
  getFinancialProfile,
  selectMembershipStart,
} from '../../../selectors/user.selectors'
import {
  selectAnnualTaxDetails,
  selectCurrentAnnualTaxDetails,
  selectCurrentAnnualTaxYear,
  selectIsCurrentTaxYear,
  selectTaxDetailsByYear,
} from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  AnnualTaxFilingForm,
  AnnualTaxFilingFormQuestionnaireStatus,
  TaxfyleJobStatus,
  TaxfyleJobStep,
} from './annualTaxFilingForms.slice'
import {
  selectAboundTaxDocsForPayerForYear,
  selectCurrent1099sCount,
  selectLastFiled1099,
} from './aboundAnnualTaxFilings.selector'
import { AnnualTaxFilingStepStatus } from './constants'
import { Text } from '../../../components/BaseComponents'
import {
  convertUtcToLocalDate,
  isoToUTCDateTime,
} from '../../../utils/dateHelpers'
import { AnnualTaxDetail } from '../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { TAX_ENTITY_TYPES } from '../taxConstants'
import { AboundTaxDocument } from './aboundAnnualTaxFilings.slice'
import { selectReviewBooksCompleted } from './TaxChecklist/Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.selector'

const getAnnualTaxFilings = (state: ReduxState) => state.annualTaxFilings

export const getAnnualTaxFilingById = createSelector(
  getAnnualTaxFilings,
  (_: unknown, id: number | undefined) => id,
  (annualTaxFilings, id) =>
    id && annualTaxFilings[id] ? annualTaxFilings[id] : undefined
)

const selectAnnualTaxFilingByFormId = createSelector(
  getAnnualTaxFilings,
  selectAnnualTaxFilingFormById,
  (taxFilings, form) =>
    Object.values(taxFilings).find(
      (filing) => filing.id === form?.annualTaxFilingId
    )
)

export const getAnnualTaxFilingForYearSelector = createSelector(
  [getAnnualTaxFilings, (_: unknown, year: string) => year],
  (annualTaxFilings, year) =>
    Object.values(annualTaxFilings).find(
      (annualTaxFiling) => annualTaxFiling.year === year
    )
)

const selectAnnualTaxFilingForCurrentTaxYear = createSelector(
  [getAnnualTaxFilings, selectCurrentAnnualTaxYear],
  (annualTaxFilings, taxYear) =>
    Object.values(annualTaxFilings).find(
      (annualTaxFiling) => annualTaxFiling.year === taxYear
    )
)

const selectTaxDetailsByFormYear = createSelector(
  selectAnnualTaxFilingFormById,
  selectAnnualTaxDetails,
  (form, annualTaxDetails) =>
    form
      ? Object.values(annualTaxDetails).find((d) => d.taxYear === form.year)
      : null
)

export const selectAnnualTaxFilingForYearUnconfirmed = createSelector(
  getAnnualTaxFilingForYearSelector,
  (taxFiling) =>
    taxFiling?.confirmedFormNeeds &&
    [ConfirmAnnualTaxFiling.no, ConfirmAnnualTaxFiling.unsure].includes(
      taxFiling.confirmedFormNeeds
    )
)

export const selectExpectedAnnualTaxFormNeeds = createSelector(
  getAnnualTaxFilingForYearSelector,
  getFinancialProfile,
  (annualTaxFiling, fp) => {
    if (annualTaxFiling?.annualTaxFormNeeds) {
      return annualTaxFiling.annualTaxFormNeeds
    } else if (fp?.taxEntityType === TAX_ENTITY_TYPES.form_1120_s) {
      return [TaxFormType.form1120s]
    }
    return [TaxFormType.form1040]
  }
)

export const selectExpectedAnnualTaxFormNeedsForCurrentTaxYear = createSelector(
  selectAnnualTaxFilingForCurrentTaxYear,
  getFinancialProfile,
  (annualTaxFiling, fp) => {
    if (annualTaxFiling?.annualTaxFormNeeds) {
      return annualTaxFiling.annualTaxFormNeeds
    } else if (fp?.taxEntityType === TAX_ENTITY_TYPES.form_1120_s) {
      return [TaxFormType.form1120s]
    }
    return [TaxFormType.form1040]
  }
)

export enum LateFilingStatus {
  notRelevant = 'NOT_RELEVANT',
  tskMissed = 'TSK_MISSED',
  cybNeeded = 'CYB_NEEDED',
  cybMissed = 'CYB_MISSED',
  taxChecklistNeeded = 'TAX_CHECKLIST_NEEDED',
  taxChecklistMissed = 'TAX_CHECKLIST_MISSED',
}

interface LateFilingStatusType {
  status: LateFilingStatus
  applicableTo: TaxFormType[] //notRelevant status will have empty array
}

interface LateFilingDeadlines {
  form1040: {
    cybDeadline: DateTime | null
    taxChecklistDeadline: DateTime | null
  }
  form1120s: {
    cybDeadline: DateTime | null
    taxChecklistDeadline: DateTime | null
  }
}

export const daysBeforeToShowLateFilingAlert = 14

const selectLateTaxFilingDeadlinesForCurrentTaxYear = createSelector(
  selectCurrentAnnualTaxDetails,
  (taxDetails): LateFilingDeadlines => ({
    form1040: {
      cybDeadline: taxDetails?.taxQuestionnaireDueDates?.form_1040?.cutoffDate
        ? convertUtcToLocalDate(
            taxDetails?.taxQuestionnaireDueDates?.form_1040?.cutoffDate
          )
            .minus({ days: 16 })
            .endOf('day')
        : null,
      taxChecklistDeadline: taxDetails?.taxQuestionnaireDueDates?.form_1040
        ?.cutoffDate
        ? convertUtcToLocalDate(
            taxDetails?.taxQuestionnaireDueDates?.form_1040?.cutoffDate
          ).endOf('day')
        : null,
    },
    form1120s: {
      cybDeadline: taxDetails?.taxQuestionnaireDueDates?.form_1120_s?.cutoffDate
        ? convertUtcToLocalDate(
            taxDetails?.taxQuestionnaireDueDates?.form_1120_s?.cutoffDate
          )
            .minus({ days: 17 })
            .endOf('day')
        : null,
      taxChecklistDeadline: taxDetails?.taxQuestionnaireDueDates?.form_1120_s
        ?.cutoffDate
        ? convertUtcToLocalDate(
            taxDetails?.taxQuestionnaireDueDates?.form_1120_s?.cutoffDate
          ).endOf('day')
        : null,
    },
  })
)

export const selectLateFilingStatusType = createSelector(
  selectAnnualTaxFilingForCurrentTaxYear,
  selectFilingFormsForCurrentTaxYear,
  selectExpectedAnnualTaxFormNeedsForCurrentTaxYear,
  selectReviewBooksCompleted,
  selectLateTaxFilingDeadlinesForCurrentTaxYear,
  (
    filing,
    filingForms,
    formNeeds,
    reviewBooksCompleted,
    deadlines
  ): LateFilingStatusType => {
    const allTqCompleted =
      filingForms.length > 0 &&
      filingForms.every(
        (f) =>
          f.questionnaireResponseStatus ===
          AnnualTaxFilingFormQuestionnaireStatus.submitted
      )

    //if the user is a single 1040 filer, use the 1040 CYB deadline. For 1120-S or two form filers, always use the 1120-S CYB deadline
    const cybDeadline =
      formNeeds.length === 1 && formNeeds.includes(TaxFormType.form1040)
        ? deadlines.form1040.cybDeadline
        : deadlines.form1120s.cybDeadline

    const now = DateTime.now()

    if (filing?.optedOutAt || allTqCompleted) {
      return {
        status: LateFilingStatus.notRelevant,
        applicableTo: [],
      }
      //the deadline for completing their TSK is the same as the CYB deadline
    } else if (
      !filing?.pretaxSurveySubmittedAt &&
      cybDeadline &&
      now >= cybDeadline
    ) {
      return {
        status: LateFilingStatus.tskMissed,
        applicableTo: formNeeds,
      }
    } else if (!reviewBooksCompleted && cybDeadline && now < cybDeadline) {
      return {
        status: LateFilingStatus.cybNeeded,
        applicableTo: formNeeds,
      }
    } else if (!reviewBooksCompleted && cybDeadline && now >= cybDeadline) {
      return {
        status: LateFilingStatus.cybMissed,
        applicableTo: formNeeds,
      }
    } else if (formNeeds.length === 1) {
      //if the user is a single filer, the copy is the same so there's no need to set applicableTo
      //since we check in the first condition whether all of the TQs are completed, we can assume the TQ for this form isn't completed
      const deadline = formNeeds.includes(TaxFormType.form1040)
        ? deadlines.form1040.taxChecklistDeadline
        : deadlines.form1120s.taxChecklistDeadline
      // if the deadline doesn't exist, don't show alerts or take them out of the process
      if (!deadline) {
        return {
          status: LateFilingStatus.notRelevant,
          applicableTo: [],
        }
      } else if (now < deadline) {
        return {
          status: LateFilingStatus.taxChecklistNeeded,
          applicableTo: formNeeds,
        }
      } else {
        return {
          status: LateFilingStatus.taxChecklistMissed,
          applicableTo: formNeeds,
        }
      }
    } else if (formNeeds.length > 1) {
      const status1120s = filingForms.find(
        (form) =>
          form.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1120_s
      )?.questionnaireResponseStatus

      //The 1120-S is a prereq for the 1040, so if the 1120-S TQ is incomplete, the status applies to both forms
      if (status1120s !== AnnualTaxFilingFormQuestionnaireStatus.submitted) {
        const deadline = deadlines.form1120s.taxChecklistDeadline
        // if the deadline doesn't exist, don't show alerts or take them out of the process
        if (!deadline) {
          return {
            status: LateFilingStatus.notRelevant,
            applicableTo: [],
          }
        } else if (now < deadline) {
          return {
            status: LateFilingStatus.taxChecklistNeeded,
            applicableTo: formNeeds,
          }
        } else {
          return {
            status: LateFilingStatus.taxChecklistMissed,
            applicableTo: formNeeds,
          }
        }
        //we know that not all TQs are complete, so this means only the 1040 TQ is incomplete
      } else {
        const deadline = deadlines.form1040.taxChecklistDeadline
        // if the deadline doesn't exist, don't show alerts or take them out of the process
        if (!deadline) {
          return {
            status: LateFilingStatus.notRelevant,
            applicableTo: [],
          }
        } else if (now < deadline) {
          return {
            status: LateFilingStatus.taxChecklistNeeded,
            applicableTo: [TaxFormType.form1040],
          }
        } else {
          return {
            status: LateFilingStatus.taxChecklistMissed,
            applicableTo: [TaxFormType.form1040],
          }
        }
      }
    } else {
      //shouldn't reach here, but default to notRelevant
      return {
        status: LateFilingStatus.notRelevant,
        applicableTo: [],
      }
    }
  }
)

export const selectRelevantLateFilingDeadline = createSelector(
  selectLateFilingStatusType,
  selectLateTaxFilingDeadlinesForCurrentTaxYear,
  (lateFilingStatusType, allDeadlines) => {
    const { status, applicableTo } = lateFilingStatusType
    const relevantTaxFormType = applicableTo.includes(TaxFormType.form1120s)
      ? TaxFormType.form1120s
      : TaxFormType.form1040
    if (
      [
        LateFilingStatus.tskMissed,
        LateFilingStatus.cybNeeded,
        LateFilingStatus.cybMissed,
      ].includes(status)
    ) {
      return allDeadlines[relevantTaxFormType].cybDeadline
    } else if (
      [
        LateFilingStatus.taxChecklistMissed,
        LateFilingStatus.taxChecklistNeeded,
      ].includes(status)
    ) {
      return allDeadlines[relevantTaxFormType].taxChecklistDeadline
    } else {
      return null
    }
  }
)

export const selectShowLateFilingAlert = createSelector(
  selectLateFilingStatusType,
  selectLateTaxFilingDeadlinesForCurrentTaxYear,
  (lateFilingStatusType, allDeadlines) => {
    const relevantTaxFormType = lateFilingStatusType.applicableTo?.includes(
      TaxFormType.form1120s
    )
      ? TaxFormType.form1120s
      : TaxFormType.form1040

    const now = DateTime.now()

    const status = lateFilingStatusType.status

    const deadline =
      status === LateFilingStatus.cybNeeded
        ? allDeadlines[relevantTaxFormType].cybDeadline
        : status === LateFilingStatus.taxChecklistNeeded
          ? allDeadlines[relevantTaxFormType].taxChecklistDeadline
          : //if the status isn't cybNeeded or taxChecklistNeeded, don't show the alert
            null
    return (
      deadline &&
      now > deadline.minus({ days: daysBeforeToShowLateFilingAlert })
    )
  }
)

/**
 * This selector is used in the signup flow to see whether the current time is past when the user is
 * eligible to receive annual tax services for the current annual tax year
 */
export const selectAnnualTaxNotAvailable = createSelector(
  getFinancialProfile,
  selectCurrentAnnualTaxDetails,
  (fp, annualTaxDetails) => {
    const is1040 = fp?.taxEntityType === TAX_ENTITY_TYPES.form_1040

    if (!annualTaxDetails?.taxQuestionnaireDueDates) {
      return true
    }

    const now = DateTime.now()

    const form1040LateJoinerFinalCutoffDate = convertUtcToLocalDate(
      annualTaxDetails.taxQuestionnaireDueDates.form_1040
        .lateJoinerFinalCutoffDate
    ).endOf('day')

    const form1120sLateJoinerFinalCutoffDate = convertUtcToLocalDate(
      annualTaxDetails.taxQuestionnaireDueDates.form_1120_s
        .lateJoinerFinalCutoffDate
    ).endOf('day')
    if (is1040) {
      return now > form1040LateJoinerFinalCutoffDate
    } else {
      // We will use the 1120-s cutoff for non s-props, including s-corps, c-corps, and other
      return now > form1120sLateJoinerFinalCutoffDate
    }
  }
)

/**
 * This selector is used in the annual tax center to determine if a user started
 * at heard early enough to receive annual tax services for the current annual tax year
 */
const selectAnnualTaxDisqualified = createSelector(
  getFinancialProfile,
  selectMembershipStart,
  selectCurrentAnnualTaxDetails,
  (fp, membershipStart, annualTaxDetails) => {
    const is1040 = fp?.taxEntityType === TAX_ENTITY_TYPES.form_1040
    if (!membershipStart) return true

    if (!annualTaxDetails?.taxQuestionnaireDueDates) {
      return true
    }

    const form1040LateJoinerFinalCutoffDate = convertUtcToLocalDate(
      annualTaxDetails.taxQuestionnaireDueDates.form_1040
        .lateJoinerFinalCutoffDate
    ).endOf('day')

    const form1120sLateJoinerFinalCutoffDate = convertUtcToLocalDate(
      annualTaxDetails.taxQuestionnaireDueDates.form_1120_s
        .lateJoinerFinalCutoffDate
    ).endOf('day')
    if (is1040) {
      return (
        DateTime.fromISO(membershipStart) > form1040LateJoinerFinalCutoffDate
      )
    } else {
      // We will use the 1120-s cutoff for non s-props, including s-corps, c-corps, and other
      return (
        DateTime.fromISO(membershipStart) > form1120sLateJoinerFinalCutoffDate
      )
    }
  }
)

export const selectUserHasBoth1040And1120sForCurrentYear = createSelector(
  getAnnualTaxFilingForYearSelector,
  (taxFiling) =>
    Boolean(
      taxFiling &&
        [TaxFormType.form1040, TaxFormType.form1120s].every((form) =>
          taxFiling?.annualTaxFormNeeds?.includes(form)
        )
    )
)

export interface DependentForm {
  key: string
  firstName: string
  lastName: string
  relationship: DependentRelationship
  dob: string
  ssn: string
  formRef: RefObject<FormikProps<FormikValues>>
}

export const createNewDependent = (
  details?: DependentsDetails
): DependentForm => {
  const nameArray = details ? details.fullname?.split(' ') : []
  const lastName = nameArray.pop() ?? ''
  const firstName = nameArray.join(' ') ?? ''
  return {
    key: `${Math.random()}${moment().utc().format('x')}`,
    firstName,
    lastName,
    relationship: details?.relationship ?? '',
    dob: details?.dob ?? '',
    ssn: '',
    formRef: createRef<FormikProps<FormikValues>>(),
  }
}

export const selectDependentsForYear = createSelector(
  getAnnualTaxFilingForYearSelector,
  (taxFiling) => taxFiling?.dependentsDetails?.map(createNewDependent) || []
)

export const FILTER_TAX_FILING_UNANSWERED = 'unanswered'
export const FILTER_TAX_FILING_NO_SERVICES = 'noServices'

export enum AnnualTaxPanelDisplay {
  userDisqualified,
  userMissedDeadline,
  noTaxFiling,
  taxSeasonKickoffIncomplete,
  noTaxServices,
  incompleteTaxFilingForm,
  annualTaxProgress,
}

export const selectAnnualTaxDisplay = createSelector(
  selectAnnualTaxDisqualified,
  selectLateFilingStatusType,
  selectAnnualTaxFilingForCurrentTaxYear,
  selectFilingFormsForCurrentTaxYear,
  (_: unknown, skipDeadline?: boolean) => skipDeadline,
  (
    userDisqualified,
    lateFilingStatusType,
    taxFiling,
    filingForms,
    skipDeadline
  ) => {
    if (userDisqualified) {
      return AnnualTaxPanelDisplay.userDisqualified
    } else if (
      !skipDeadline &&
      [
        LateFilingStatus.tskMissed,
        LateFilingStatus.cybMissed,
        LateFilingStatus.taxChecklistMissed,
      ].includes(lateFilingStatusType.status)
    ) {
      return AnnualTaxPanelDisplay.userMissedDeadline
    } else if (!taxFiling) {
      return AnnualTaxPanelDisplay.noTaxFiling
    } else if (!taxFiling.pretaxSurveySubmittedAt) {
      return AnnualTaxPanelDisplay.taxSeasonKickoffIncomplete
    } else if (taxFiling.optedOutAt || taxFiling.annualTaxFormNeeds === null) {
      return AnnualTaxPanelDisplay.noTaxServices
    } else if (
      taxFiling.pretaxSurveySubmittedAt &&
      filingForms.some(
        ({ questionnaireResponseStatus }) =>
          questionnaireResponseStatus !==
          AnnualTaxFilingFormQuestionnaireStatus.submitted
      )
    ) {
      return AnnualTaxPanelDisplay.incompleteTaxFilingForm
    }

    return AnnualTaxPanelDisplay.annualTaxProgress
  }
)

// Step 1 User starts annual taxes with this entry point
const getTaxSeasonKickoffStatus = (
  taxFiling: AnnualTaxFiling | null | undefined,
  taxDetails: AnnualTaxDetail | null | undefined
) => {
  if (!taxFiling?.pretaxSurveySubmittedAt) {
    return AnnualTaxFilingStepStatus.inProgress
  } else if (moment(taxDetails?.taxSeasonKickoffDueAt).isBefore()) {
    return AnnualTaxFilingStepStatus.completeLocked
  }

  return AnnualTaxFilingStepStatus.completeUnlocked
}

export const selectTaxSeasonKickoffStatusByYear = createSelector(
  getAnnualTaxFilingForYearSelector,
  selectTaxDetailsByYear,
  getTaxSeasonKickoffStatus
)

export const selectTaxSeasonKickoffStatusByFormId = createSelector(
  selectAnnualTaxFilingByFormId,
  selectTaxDetailsByFormYear,
  getTaxSeasonKickoffStatus
)

// Step 2 Nec contractor filing.  This is optional based on tsk responses
const getContractorNecStartDateFromTaxDetails = (
  taxDetails: AnnualTaxDetail | undefined | null
) =>
  taxDetails?.ten99NECFormsStartAt
    ? isoToUTCDateTime(taxDetails?.ten99NECFormsStartAt)
    : null

const selectContractorNecStartDateByFormYear = createSelector(
  selectTaxDetailsByFormYear,
  getContractorNecStartDateFromTaxDetails
)

const selectContractorNecStartDateByYear = createSelector(
  selectTaxDetailsByYear,
  getContractorNecStartDateFromTaxDetails
)

export const selectContractorNecEndByYear = createSelector(
  selectTaxDetailsByYear,
  (taxDetails) =>
    taxDetails?.ten99NECFormsStartAt
      ? isoToUTCDateTime(taxDetails?.ten99NECFormsDueAt)
      : null
)

/**
 * Would be better to put this in the aboundAnnualTaxFilings.selector,
 * but it's creating a circular dependency. Can maybe refactor later.
 */
export const selectSubmitted1099sAfterDeadline = createSelector(
  selectContractorNecEndByYear,
  selectAboundTaxDocsForPayerForYear,
  (dueDate, docs) =>
    docs.some(
      (d) =>
        (!d.submittedAt && dueDate && DateTime.now() > dueDate) ||
        (d.submittedAt && dueDate && isoToUTCDateTime(d.submittedAt) > dueDate)
    )
)

const getFileNecStatus = (
  current1099sCount: number,
  lastFiled1099: AboundTaxDocument | null,
  contractorNecStart: DateTime | null
) => {
  if (lastFiled1099) {
    return AnnualTaxFilingStepStatus.completeLocked
  } else if (current1099sCount !== 0) {
    return AnnualTaxFilingStepStatus.inProgress
  }

  return contractorNecStart && DateTime.now() > contractorNecStart
    ? AnnualTaxFilingStepStatus.notStarted
    : AnnualTaxFilingStepStatus.upcoming
}

export const selectFileNecStatusByYear = createSelector(
  selectCurrent1099sCount,
  selectLastFiled1099,
  selectContractorNecStartDateByYear,
  getFileNecStatus
)

export const selectFileNecStatusByFormId = createSelector(
  selectCurrent1099sCount,
  selectLastFiled1099,
  selectContractorNecStartDateByFormYear,
  getFileNecStatus
)

// Abound will break 4/1, All contractor filings must be complete by this time
export const NEC_CUTOFF = DateTime.fromObject({ month: 4, day: 1, year: 2024 })

const shouldShowNeedContractorFiling = (
  taxFiling: AnnualTaxFiling | AnnualTaxFilingNonNormalized | undefined,
  necStatus: ReturnType<typeof getFileNecStatus>
) =>
  Boolean(
    taxFiling?.needContractorFiling &&
      [
        needContractorFilingOption.yes,
        needContractorFilingOption.unsure,
      ].includes(taxFiling?.needContractorFiling) &&
      ([
        AnnualTaxFilingStepStatus.completeLocked,
        AnnualTaxFilingStepStatus.inProgress,
      ].includes(necStatus) ||
        DateTime.now() < NEC_CUTOFF)
  )

export const selectShowNeedContractorFilingByFormId = createSelector(
  selectAnnualTaxFilingByFormId,
  selectFileNecStatusByFormId,
  shouldShowNeedContractorFiling
)

export const selectShowNeedContractorFilingByYear = createSelector(
  getAnnualTaxFilingForYearSelector,
  selectFileNecStatusByYear,
  shouldShowNeedContractorFiling
)

// Step 3 Extension requirement
// Todo this should come from the backend, prob on annual tax details
export const selectExtensionStartDate = createSelector(
  selectCurrentAnnualTaxYear,
  (taxYear) =>
    DateTime.fromObject({
      year: Number(taxYear) + 1,
      month: 2,
      day: 14,
      hour: 9,
    })
)

const shouldShow1040Extension = (
  filing: AnnualTaxFiling | undefined,
  form: AnnualTaxFilingForm | undefined,
  taxDetails: AnnualTaxDetail
) => {
  const NOW = DateTime.now()
  const needs1040Extension = Boolean(
    form?.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1040 &&
      form.isExtended
  )
  // extension survey will be turned off 3 days before the deadline
  const isBefore1040ExtensionSurveyCutoff =
    NOW <
    convertUtcToLocalDate(
      taxDetails.irsFormDueDates.form_1040.irs.dueDate
    ).minus({ days: 3 })
  return (
    needs1040Extension &&
    (isBefore1040ExtensionSurveyCutoff || Boolean(filing?.extensionTaxfyleJob))
  )
}

const shouldShow1120sExtension = (
  filing: AnnualTaxFiling | undefined,
  form: AnnualTaxFilingForm | undefined,
  taxDetails: AnnualTaxDetail
) => {
  const NOW = DateTime.now()
  const needs1120sExtension = Boolean(
    form?.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1120_s &&
      form.isExtended
  )
  // extension survey will be turned off 3 days before the deadline
  const isBefore1120sExtensionSurveyCutoff =
    NOW <
    convertUtcToLocalDate(
      taxDetails.irsFormDueDates.form_1120_s.irs.dueDate
    ).minus({ days: 3 })
  return (
    needs1120sExtension &&
    (isBefore1120sExtensionSurveyCutoff || Boolean(filing?.extensionTaxfyleJob))
  )
}

const shouldShowFileExtension = (
  filing: AnnualTaxFiling | undefined,
  forms: AnnualTaxFilingForm[],
  taxDetails?: AnnualTaxDetail
) => {
  if (!taxDetails) {
    return false
  }
  const form1040 = forms.find(
    (form) => form.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1040
  )
  const show1040Extension = shouldShow1040Extension(
    filing,
    form1040,
    taxDetails
  )
  const form1120s = forms.find(
    (form) => form.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1120_s
  )
  const show1120sExtension = shouldShow1120sExtension(
    filing,
    form1120s,
    taxDetails
  )

  return show1040Extension || show1120sExtension
}

export const selectShowFileExtensionForYear = createSelector(
  selectFilingFormsForYear,
  getAnnualTaxFilingForYearSelector,
  selectCurrentAnnualTaxDetails,
  (forms, filing, taxDetails) => {
    return shouldShowFileExtension(filing, forms, taxDetails)
  }
)

export const selectShowFileExtensionByFormId = createSelector(
  selectAnnualTaxFilingByFormId,
  getAnnualTaxFilingForms,
  selectCurrentAnnualTaxDetails,
  (filing, forms, taxDetails) => {
    return shouldShowFileExtension(filing, Object.values(forms), taxDetails)
  }
)

/**
 * Returns if the current date time is before the filing extension deadline
 */
export const selectIsBeforeExtensionDeadline = createSelector(
  selectCurrentAnnualTaxDetails,
  getAnnualTaxFilingForms,
  (taxDetailsForYear, allForms) => {
    const formsForYear = Object.values(allForms).filter(
      (f) => f.year === taxDetailsForYear?.taxYear
    )
    if (formsForYear.length === 0) return false
    const personalFiling = formsForYear.find(
      (form) => form.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1040
    )
    if (personalFiling) {
      if (taxDetailsForYear?.irsFormDueDates?.form_1040?.irs?.dueDate) {
        const utcDate = isoToUTCDateTime(
          taxDetailsForYear.irsFormDueDates.form_1040.irs.dueDate
        )
        const endOfDayUserLocalTime = DateTime.fromObject({
          year: utcDate.year,
          month: utcDate.month,
          day: utcDate.day,
        }).endOf('day')
        return DateTime.now() < endOfDayUserLocalTime
      }
      return false
    }

    if (taxDetailsForYear?.irsFormDueDates?.form_1120_s?.irs?.dueDate) {
      const utcDate = isoToUTCDateTime(
        taxDetailsForYear.irsFormDueDates.form_1120_s.irs.dueDate
      )
      const endOfDayUserLocalTime = DateTime.fromObject({
        year: utcDate.year,
        month: utcDate.month,
        day: utcDate.day,
      }).endOf('day')
      return DateTime.now() < endOfDayUserLocalTime
    }
    return false
  }
)

export const selectIsBefore1040Deadline = createSelector(
  selectAnnualTaxDetails,
  selectCurrentAnnualTaxYear,
  (taxDetails, taxYear) => {
    const taxDetailsForYear = Object.values(taxDetails).find(
      (d) => d.taxYear === taxYear
    )
    if (taxDetailsForYear?.irsFormDueDates?.form_1040?.irs.dueDate) {
      const utcDate = isoToUTCDateTime(
        taxDetailsForYear.irsFormDueDates.form_1040.irs.dueDate
      )
      const endOfDayUserLocalTime = DateTime.fromObject({
        year: utcDate.year,
        month: utcDate.month,
        day: utcDate.day,
      }).endOf('day')
      return (
        taxDetailsForYear?.irsFormDueDates?.form_1040?.irs?.dueDate &&
        DateTime.now() < endOfDayUserLocalTime
      )
    }
    return false
  }
)

export const selectIsBefore1120sDeadline = createSelector(
  selectAnnualTaxDetails,
  selectCurrentAnnualTaxYear,
  (taxDetails, taxYear) => {
    const taxDetailsForYear = Object.values(taxDetails).find(
      (d) => d.taxYear === taxYear
    )
    if (taxDetailsForYear?.irsFormDueDates.form_1120_s.irs.dueDate) {
      const utcDate = isoToUTCDateTime(
        taxDetailsForYear.irsFormDueDates.form_1120_s.irs.dueDate
      )
      const endOfDayUserLocalTime = DateTime.fromObject({
        year: utcDate.year,
        month: utcDate.month,
        day: utcDate.day,
      }).endOf('day')
      return (
        taxDetailsForYear?.irsFormDueDates?.form_1120_s?.irs?.dueDate &&
        DateTime.now() < endOfDayUserLocalTime
      )
    }
    return false
  }
)

const getFileExtensionStatus = (
  filing: AnnualTaxFiling | undefined,
  needsNec: boolean,
  tskStatus: ReturnType<typeof getTaxSeasonKickoffStatus>,
  necFilingStatus: ReturnType<typeof getFileNecStatus>,
  isBeforeExtensionDeadline: boolean,
  extensionStartDate: DateTime
) => {
  if (
    filing?.extensionTaxfyleJob &&
    ([TaxfyleJobStatus.completed, TaxfyleJobStatus.canceled].includes(
      filing?.extensionTaxfyleJob?.jobStatus
    ) ||
      !isBeforeExtensionDeadline)
  ) {
    return AnnualTaxFilingStepStatus.completeLocked
  } else if (filing?.extensionStartedAt) {
    return AnnualTaxFilingStepStatus.inProgress
  } else if (extensionStartDate <= DateTime.now()) {
    return AnnualTaxFilingStepStatus.notStarted
  } else if (
    [
      AnnualTaxFilingStepStatus.completeLocked,
      AnnualTaxFilingStepStatus.completeUnlocked,
    ].includes(tskStatus) &&
    (!needsNec || necFilingStatus === AnnualTaxFilingStepStatus.completeLocked)
  ) {
    return AnnualTaxFilingStepStatus.comingSoon
  }

  return AnnualTaxFilingStepStatus.upcoming
}

export const selectFileExtensionStatusForYear = createSelector(
  getAnnualTaxFilingForYearSelector,
  selectShowNeedContractorFilingByYear,
  selectTaxSeasonKickoffStatusByYear,
  selectFileNecStatusByYear,
  selectIsBeforeExtensionDeadline,
  selectExtensionStartDate,
  getFileExtensionStatus
)

export const selectShowFileExtensionStatusByFormId = createSelector(
  selectAnnualTaxFilingByFormId,
  selectShowNeedContractorFilingByFormId,
  selectTaxSeasonKickoffStatusByFormId,
  selectFileNecStatusByFormId,
  selectIsBeforeExtensionDeadline,
  selectExtensionStartDate,
  getFileExtensionStatus
)

export const selectExtensionSurveyComplete = createSelector(
  getAnnualTaxFilingForYearSelector,
  (filing) => Boolean(filing?.extensionTaxfyleJob)
)

// This is subtly different than above - this will only return true if there is a survey and it's not complete.
export const selectHasIncompleteExtension = createSelector(
  selectShowFileExtensionForYear,
  selectFileExtensionStatusForYear,
  (showExtensionSurvey, extensionStatus) =>
    showExtensionSurvey &&
    extensionStatus !== AnnualTaxFilingStepStatus.completeLocked
)

// Step 4 Tax questionnaire or tax checklist
export const getTQStatusForForm = (
  filingForm: AnnualTaxFilingForm | null | undefined,
  annualTaxDetail: AnnualTaxDetail | null | undefined,
  needsNec: boolean,
  tskStatus: ReturnType<typeof getTaxSeasonKickoffStatus>,
  necFilingStatus: ReturnType<typeof getFileNecStatus>,
  needFileExtension: boolean,
  fileExtensionStatus: ReturnType<typeof getFileExtensionStatus>
) => {
  if (
    filingForm?.questionnaireResponseStatus ===
    AnnualTaxFilingFormQuestionnaireStatus.started
  ) {
    return AnnualTaxFilingStepStatus.inProgress
  } else if (
    filingForm?.questionnaireResponseStatus ===
    AnnualTaxFilingFormQuestionnaireStatus.submitted
  ) {
    return AnnualTaxFilingStepStatus.completeLocked
  }

  const isForm1120_s =
    filingForm?.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1120_s

  const startTime = isForm1120_s
    ? annualTaxDetail?.taxQuestionnaireDueDates?.form_1120_s.startAt
    : annualTaxDetail?.taxQuestionnaireDueDates?.form_1040.startAt

  if (
    needFileExtension &&
    fileExtensionStatus !== AnnualTaxFilingStepStatus.completeLocked
  ) {
    return AnnualTaxFilingStepStatus.upcoming
  }

  if (startTime && DateTime.fromISO(startTime) <= DateTime.now()) {
    return AnnualTaxFilingStepStatus.notStarted
  } else if (
    [
      AnnualTaxFilingStepStatus.completeLocked,
      AnnualTaxFilingStepStatus.completeUnlocked,
    ].includes(tskStatus) &&
    (!needsNec ||
      necFilingStatus === AnnualTaxFilingStepStatus.completeLocked) &&
    (!needFileExtension ||
      fileExtensionStatus === AnnualTaxFilingStepStatus.completeLocked)
  ) {
    return AnnualTaxFilingStepStatus.comingSoon
  }

  return AnnualTaxFilingStepStatus.upcoming
}

export const select1040TQStatusByYear = createSelector(
  select1040FormForYear,
  selectTaxDetailsByYear,
  selectShowNeedContractorFilingByYear,
  selectTaxSeasonKickoffStatusByYear,
  selectFileNecStatusByYear,
  selectShowFileExtensionForYear,
  selectFileExtensionStatusForYear,
  getTQStatusForForm
)

export const select1120TQStatusByYear = createSelector(
  select1120sFormForYear,
  selectTaxDetailsByYear,
  selectShowNeedContractorFilingByYear,
  selectTaxSeasonKickoffStatusByYear,
  selectFileNecStatusByYear,
  selectShowFileExtensionForYear,
  selectFileExtensionStatusForYear,
  getTQStatusForForm
)

export const selectFormTQStatus = createSelector(
  selectAnnualTaxFilingFormById,
  selectTaxDetailsByFormYear,
  selectShowNeedContractorFilingByFormId,
  selectTaxSeasonKickoffStatusByFormId,
  selectFileNecStatusByFormId,
  selectShowFileExtensionByFormId,
  selectShowFileExtensionStatusByFormId,
  getTQStatusForForm
)

export const selectTqIsReadOnly = createSelector(
  selectFormTQStatus,
  (status) => status === AnnualTaxFilingStepStatus.completeLocked
)

const formHasStatus = (
  filingForm: AnnualTaxFilingForm | null | undefined,
  steps: TaxfyleJobStep[],
  statuses: TaxfyleJobStatus[] = []
) =>
  Boolean(
    filingForm?.taxfyleJob &&
      // Check if the steps match any of the ones sent in
      ((filingForm.taxfyleJob.jobStep &&
        steps.includes(filingForm.taxfyleJob.jobStep)) ||
        // If statues are sent in check if any of filing forms include that status
        statuses.includes(filingForm.taxfyleJob.jobStatus))
  )

// Step 5 Tax preparation status
const TAX_PREP_IN_PROGRESS_STEPS = [TaxfyleJobStep.openItems]

const TAX_PREP_IN_PROGRESS_STATUSES = [
  TaxfyleJobStatus.unclaimed,
  TaxfyleJobStatus.claimed,
  TaxfyleJobStatus.idle,
  TaxfyleJobStatus.onHold,
  TaxfyleJobStatus.infoGathering,
]

const TAX_PREP_COMPLETE = [
  TaxfyleJobStep.jobStarted,
  TaxfyleJobStep.draftInReview,
  TaxfyleJobStep.draftApproved,
  TaxfyleJobStep.draftRejected,
  TaxfyleJobStep.authorizationRequested,
  TaxfyleJobStep.authorizationSigned,
  TaxfyleJobStep.returnFiled,
]

export const selectTaxPreparationStatusForForm = createSelector(
  selectAnnualTaxFilingFormById,
  selectFormTQStatus,
  (form, tqStatus) => {
    if (formHasStatus(form, TAX_PREP_COMPLETE)) {
      return AnnualTaxFilingStepStatus.completeLocked
    } else if (
      formHasStatus(
        form,
        TAX_PREP_IN_PROGRESS_STEPS,
        TAX_PREP_IN_PROGRESS_STATUSES
      )
    ) {
      return AnnualTaxFilingStepStatus.inProgress
    } else if (tqStatus === AnnualTaxFilingStepStatus.completeLocked) {
      // Once the TQ for the form is complete tax prep should display as coming soon
      return AnnualTaxFilingStepStatus.comingSoon
    }

    return AnnualTaxFilingStepStatus.upcoming
  }
)

// Step 6 Tax draft review
const TAX_DRAFT_REVIEW_IN_PROGRESS = [
  TaxfyleJobStep.draftRejected,
  TaxfyleJobStep.jobStarted,
]

const TAX_DRAFT_REVIEW_READY_TO_REVIEW = [TaxfyleJobStep.draftInReview]

const TAX_DRAFT_REVIEW_COMPLETE = [
  TaxfyleJobStep.draftApproved,
  TaxfyleJobStep.authorizationRequested,
  TaxfyleJobStep.authorizationSigned,
  TaxfyleJobStep.returnFiled,
]

export const selectDraftReviewStatusForForm = createSelector(
  selectAnnualTaxFilingFormById,
  selectTaxPreparationStatusForForm,
  (form, taxPrepStatus) => {
    if (
      formHasStatus(form, TAX_DRAFT_REVIEW_COMPLETE, [
        TaxfyleJobStatus.completed,
      ])
    ) {
      return AnnualTaxFilingStepStatus.completeLocked
    } else if (formHasStatus(form, TAX_DRAFT_REVIEW_IN_PROGRESS)) {
      return AnnualTaxFilingStepStatus.inProgress
    } else if (formHasStatus(form, TAX_DRAFT_REVIEW_READY_TO_REVIEW)) {
      return AnnualTaxFilingStepStatus.readyForReview
    } else if (taxPrepStatus === AnnualTaxFilingStepStatus.completeLocked) {
      return AnnualTaxFilingStepStatus.comingSoon
    }

    return AnnualTaxFilingStepStatus.upcoming
  }
)

// Step 7 Tax return filing
const TAX_RETURN_FILING_AUTHORIZATION_REQUESTED = [
  TaxfyleJobStep.authorizationRequested,
]
const TAX_RETURN_FILING_IN_PROGRESS = [
  TaxfyleJobStep.authorizationSigned,
  TaxfyleJobStep.draftApproved,
]
const TAX_RETURN_FILING_COMPLETE = [TaxfyleJobStep.returnFiled]

const getFilingStatusForForm = (
  form: AnnualTaxFilingForm | null | undefined
) => {
  if (
    formHasStatus(form, TAX_RETURN_FILING_COMPLETE, [
      TaxfyleJobStatus.completed,
    ])
  ) {
    return AnnualTaxFilingStepStatus.completeLocked
  } else if (formHasStatus(form, TAX_RETURN_FILING_IN_PROGRESS)) {
    return AnnualTaxFilingStepStatus.inProgress
  } else if (formHasStatus(form, TAX_RETURN_FILING_AUTHORIZATION_REQUESTED)) {
    return AnnualTaxFilingStepStatus.readyToSign
  }

  return AnnualTaxFilingStepStatus.upcoming
}

export const selectFilingStatusForForm = createSelector(
  selectAnnualTaxFilingFormById,
  getFilingStatusForForm
)

export const selectFilingStatusFor1120s = createSelector(
  select1120sFormForYear,
  getFilingStatusForForm
)

export const selectFilingStatusFor1040 = createSelector(
  select1040FormForYear,
  getFilingStatusForForm
)

export const selectImportantDatesForYear = createSelector(
  getAnnualTaxFilingForYearSelector,
  selectTaxDetailsByYear,
  selectShowFileExtensionForYear,
  selectExtensionStartDate,
  selectShowNeedContractorFilingByYear,
  selectIsTwoFormFiler,
  selectContractorNecStartDateByYear,
  selectContractorNecEndByYear,
  (
    filing,
    taxDetails,
    showFileExtension,
    extensionStartDate,
    showContractorFiling,
    isTwoFormFiler,
    contractorNecStart,
    contractorNecEnd
  ) => {
    if (!filing || !taxDetails) {
      return []
    }

    const nextYear = Number(filing.year) + 1
    const needs1040 = filing.annualTaxFormNeeds?.includes(TaxFormType.form1040)
    const needs1120S = filing.annualTaxFormNeeds?.includes(
      TaxFormType.form1120s
    )

    return filterNulls(
      flatten([
        {
          name: 'Tax Season Kickoff',
          shortName: 'Tax Season Kickoff',
          startDate: isoToUTCDateTime(taxDetails.taxSeasonKickoffStartAt),
          endDate: isoToUTCDateTime(taxDetails.taxSeasonKickoffDueAt),
          description: (
            <Text as="bodyLg">
              Tell us the support you need this year so we can match you with
              the right resources.
            </Text>
          ),
        },
        showContractorFiling
          ? {
              name: '1099-NEC',
              shortName: '1099-NEC',
              startDate: contractorNecStart,
              endDate: contractorNecEnd,
              description: (
                <Text as="bodyLg">
                  You’ll fill in information about your contractor so Heard can
                  file their 1099-NEC for you. A task will pop up around
                  mid-November to guide you through the process.
                </Text>
              ),
            }
          : undefined,
        showFileExtension
          ? {
              name: 'Extension request',
              shortName: 'Extension request',
              startDate: extensionStartDate,
              endDate:
                !isTwoFormFiler && needs1040
                  ? DateTime.fromObject({
                      year: nextYear,
                      month: 4,
                      day: 1,
                    })
                  : DateTime.fromObject({
                      year: nextYear,
                      month: 3,
                      day: 1,
                    }),
              description: (
                <Text as="bodyLg">
                  {needs1120S
                    ? "You'll fill in high level information about your business and its income so your tax preparer can file an extension request on your behalf."
                    : "You'll fill in high level information about you, your business, your family, your income and the taxes you've already paid so your tax preparer can file an extension request on your behalf."}
                </Text>
              ),
            }
          : undefined,
        needs1120S
          ? [
              {
                name: 'Business Tax Checklist',
                shortName: 'Business Tax Checklist',
                startDate:
                  taxDetails.taxQuestionnaireDueDates &&
                  isoToUTCDateTime(
                    taxDetails.taxQuestionnaireDueDates?.form_1120_s.startAt
                  ),
                endDate: showFileExtension
                  ? DateTime.fromObject({
                      year: nextYear,
                      month: 9,
                      day: 1,
                    })
                  : taxDetails.taxQuestionnaireDueDates &&
                    isoToUTCDateTime(
                      taxDetails.taxQuestionnaireDueDates?.form_1120_s.endAt
                    ),
                description: (
                  <Text as="bodyLg">
                    You’ll review your business income and expenses and upload
                    documents into the Tax Checklist.
                  </Text>
                ),
              },
            ]
          : undefined,
        needs1040
          ? [
              {
                name: 'Personal Tax Checklist',
                shortName: 'Personal Tax Checklist',
                startDate:
                  taxDetails.taxQuestionnaireDueDates &&
                  isoToUTCDateTime(
                    taxDetails.taxQuestionnaireDueDates.form_1040.startAt
                  ),
                endDate: showFileExtension
                  ? DateTime.fromObject({
                      year: nextYear,
                      month: 10,
                      day: 1,
                    })
                  : taxDetails.taxQuestionnaireDueDates &&
                    isoToUTCDateTime(
                      taxDetails.taxQuestionnaireDueDates.form_1040.endAt
                    ),
                description: (
                  <Text as="bodyLg">
                    You’ll share any life changes, enter your deductions and
                    credits, and upload documents into the Tax Checklist.
                  </Text>
                ),
              },
            ]
          : undefined,
      ])
    )
  }
)

export const selectHasTqStartedForCurrentYear = createSelector(
  selectCurrentAnnualTaxDetails,
  (taxDetails) => {
    const startTime = taxDetails?.taxQuestionnaireDueDates?.form_1120_s.startAt
    return (
      process.env.VITE_TQ_ENABLED === 'true' ||
      (startTime && DateTime.fromISO(startTime) < DateTime.now())
    )
  }
)

export const selectFormsWithCompletedTqForYear = createSelector(
  selectFilingFormsForYear,
  selectTaxDetailsByYear,
  selectShowNeedContractorFilingByYear,
  selectTaxSeasonKickoffStatusByYear,
  selectFileNecStatusByYear,
  selectShowFileExtensionForYear,
  selectFileExtensionStatusForYear,
  (forms, ...rest) =>
    forms.filter(
      (f) =>
        getTQStatusForForm(f, ...rest) ===
        AnnualTaxFilingStepStatus.completeLocked
    )
)

export const selectAllowEditTaxSeasonKickoffForYear = createSelector(
  selectIsCurrentTaxYear,
  selectShowFileExtensionForYear,
  selectFileExtensionStatusForYear,
  selectFilingFormsForYear,
  (isCurrentTaxYear, showFileExtension, extensionStatus, filingForms) => {
    // Don't allow editing of tsk for not current year.  Right now this isn't blocked by annual tax details tsk due at
    if (!isCurrentTaxYear) {
      return false
    } else if (
      // If file extension is required and has been started don't allow editing
      showFileExtension &&
      [
        AnnualTaxFilingStepStatus.completeLocked,
        AnnualTaxFilingStepStatus.inProgress,
      ].includes(extensionStatus)
    ) {
      return false
    }

    // If any of the tax checklists have been started don't allow editing
    return filingForms.every(
      (form) =>
        form.questionnaireResponseStatus ===
        AnnualTaxFilingFormQuestionnaireStatus.notStarted
    )
  }
)
/**
 * Returns if user has a taxfyle job or extension for current tax year
 */
export const hasTaxfyleJobOrExtensionInProgress = createSelector(
  getAnnualTaxFilingForYearSelector,
  selectFilingFormsForYear,
  selectShowFileExtensionForYear,
  // getFileExtensionStatus,
  (filing, forms, fileExtended) => {
    if (!filing) return false
    // Checks if filing has extension and if extension in progress
    if (fileExtended) {
      return false
    }
    // Checks if any of the forms associated to the tax filing as a Taxfyle Job
    return forms.some(
      (form: AnnualTaxFilingForm) =>
        form.taxfyleJob &&
        form.taxfyleJob.jobStep !== TaxfyleJobStep.returnFiled &&
        form.taxfyleJob.jobStatus !== TaxfyleJobStatus.completed
    )
  }
)
