import { Text } from '../../../../../components/BaseComponents'

// Scorp
export const ScorpPrivatePracticeIncomeTooltip = () => (
  <>
    <Text as="h3">W-2 Private Practice Income (YTD)</Text>
    <br />
    <Text>
      This is the total year-to-date W-2 income that you earn as an employee of
      your S corporation.
      <br />
      <br />
      When you entered your W-2 income, you had a choice of entering in either
      how much you’ve made so far from your W-2 paystub year-to-date (YTD), or
      estimating how much you think you’ll make for the entire year (Estimated).
      <br />
      <br />
      Since you entered <b>year-to-date</b> income, we calculated how much we
      think you’ll make for the entire year in the “Projected Income” column.
    </Text>
  </>
)

export const ScorpEstimatedPrivatePracticeIncomeTooltip = () => (
  <>
    <Text as="h3">W-2 private practice income (Estimated)</Text>
    <br />
    <Text>
      This is the total year-to-date W-2 income that you earn as an employee of
      your S corporation.
      <br />
      <br />
      When you entered your W-2 income, you had a choice of entering in either
      how much you’ve made so far from your W-2 paystub year-to-date (YTD), or
      estimating how much you think you’ll make for the entire year (Estimated).
      <br />
      <br />
      Since you entered <b>estimated</b> income, this number already accounts
      for the entire year, and does not need to be projected.
    </Text>
  </>
)

export const ScorpProjectedAnnualIncomeTooltip = () => (
  <>
    <Text as="h3">Projected Annual Income for S Corporations</Text>
    <br />
    <Text>
      We take your total year-to-date income to estimate how much you’ll make
      for the rest of the year.
    </Text>
    <br />
    <Text>This can include income from:</Text>
    <ul>
      <li>
        <Text>Your private practice</Text>
      </li>
      <li>
        <Text>Other jobs outside of your practice</Text>
      </li>
      <li>
        <Text>
          Your spouse’s income earned so far this year (if married filing
          jointly)
        </Text>
      </li>
    </ul>
  </>
)

export const Scorp1099IncomeTooltip = () => (
  <>
    <Text as="h3">1099 Income</Text>
    <br />
    <Text>
      This is additional 1099 income you make on the side of your practice (e.g.
      consulting, speaking, supervision). We use the actual amount of 1099
      income you’ve earned this year. Since contractor income can vary, we don’t
      estimate what this amount will be for the year.
    </Text>
  </>
)

export const ScorpSelfEmploymentTooltip = () => (
  <>
    <Text as="h3">Why is the self-employment tax being shown?</Text>
    <br />
    <Text>
      We’re showing the self-employment tax because when you updated your
      quarterly tax information, you shared that you (or your spouse, if
      applicable) have earned 1099 income this quarter. Any 1099 income earned
      on the side of your private practice is subject to the self-employment
      tax.
    </Text>
  </>
)

// Sprop
export const SpropProjectedAnnualIncomeTooltip = () => (
  <>
    <Text as="h3">Projected Annual Income for Sole Proprietors</Text>
    <br />
    <Text>
      We use your year-to-date (YTD) net income to estimate how much you’ll make
      for the rest of the year. In addition to your net income, we include any
      additional YTD income that you may make on the side of your practice, as
      well as your spouse’s income (if you’re married filing jointly).
    </Text>
  </>
)

export const Sprop1099IncomeTooltip = () => (
  <>
    <Text as="h3">Additional 1099 Income for Sole Proprietors</Text>
    <br />
    <Text>
      This is additional 1099 income you make on the side of your practice (e.g.
      consulting, speaking, supervision){' '}
      <b>and isn’t already recorded in Heard.</b> We use the actual amount of
      1099 income you’ve earned this year. Since contractor income can vary, we
      don’t estimate what this amount will be for the year.
    </Text>
  </>
)

export const SpropSelfEmploymentTooltip = () => (
  <>
    <Text as="h3">Self-Employment Tax</Text>
    <br />
    <Text>
      This tax collection is dedicated to help fund Medicare and Social
      Security. Normally, employers and employees split the tax rate (each pays
      7.65%).
      <br />
      <br />
      Self-employed business owners still have to contribute to Medicare and
      Social Security, only they’re responsible for paying both portions of the
      tax, which adds up to 15.3%.
      <br />
      <br />
      Your 1099 income is subject to the Social Security portion of the
      self-employment tax until your income reaches the cap amount.
    </Text>
  </>
)

// Scorp and sprop
export const PrivatePracticeNetIncomeTooltip = () => (
  <>
    <Text as="h3">Private Practice Net Income</Text>
    <br />
    <Text>
      This is the amount of money that remains after deducting all expenses from
      your revenue, including operating expenses, taxes, interest payments,
      depreciation, and other non-operating expenses. This figure is what is
      used to calculate the taxes owed for your business.
    </Text>
  </>
)

export const OtherW2IncomeTooltip = () => (
  <>
    <Text as="h3">Other W-2 Income (YTD)</Text>
    <br />
    <Text>
      This is the total year-to-date W-2 income that you earn on the side of
      your private practice. Examples of W-2 jobs: working at a hospital,
      agency, non-profit, etc.
      <br />
      <br />
      Since you entered <b>year-to-date</b> income, we calculated how much we
      think you’ll make for the entire year in the “Projected Income” column.
    </Text>
  </>
)

export const OtherW2IncomeEstimatedTooltip = () => (
  <>
    <Text as="h3">Other W-2 Income (Estimated)</Text>
    <br />
    <Text>
      This is the total W-2 income that you earn on the side of your private
      practice. Examples of W-2 jobs: working at a hospital, agency, non-profit,
      etc.
      <br />
      <br />
      Since you entered <b>estimated income</b>, this number already accounts
      for the entire year, and does not need to be projected.
    </Text>
  </>
)

// todo this really should be coming from the admin/federal_income_tax/current endpoint but it's admin only and not by year
export const StandardDeductionTooltip = ({ year }: { year: string }) => (
  <>
    <Text as="h3">Standard Deduction Amounts for {year}</Text>
    <br />
    <Text>Single filers: $14,600</Text>
    <Text>Married filing jointly: $29,200</Text>
  </>
)

export const QBIDeductionTooltip = () => (
  <>
    <Text as="h3">Qualified Business Income (QBI) Deduction</Text>
    <br />
    <Text>
      This deduction allows eligible taxpayers (small business owners,
      self-employed people who earn 1099 income) to deduct up to 20% of their
      qualified business income from federal income tax.
    </Text>
  </>
)

export const DeductiblePotionSelfEmploymentTooltip = () => (
  <>
    <Text as="h3">Deductible Portion of Self-Employment Tax</Text>
    <br />
    <Text>
      The IRS allows self-employed taxpayers to claim 50% of what they pay in
      self-employment tax as an income tax deduction. This helps lower you
      year-end tax bill.
    </Text>
  </>
)

export const TotalTaxableIncomeTooltip = () => (
  <>
    <Text as="h3">Total Taxable Income</Text>
    <br />
    <Text>
      We take your projected annual income and subtract tax deductions to get
      your total taxable income (currently we’re only using the standard
      deduction and QBI deduction).
    </Text>
  </>
)

export const ProjectedAnnualTaxBillTooltip = () => (
  <>
    <Text as="h3">Projected Annual Tax Bill</Text>
    <br />
    <Text>
      We’ve used the IRS federal income tax rates to calculate your projected
      annual tax bill based off your taxable income.
      <br />
      <br />
      Your taxable income and your filing status (aka tax bracket) determine
      which tax rate will be used for calculating how much you’ll owe in taxes
      for the year.
    </Text>
  </>
)

export const ProjectedFederalIncomeTaxTooltip = () => (
  <>
    <Text as="h3">Projected federal income tax</Text>
    <br />
    <Text>
      Taxable income and filing status (aka tax bracket) determine which federal
      tax rates apply to you, and how much in taxes you&apos;ll owe for the
      year.
    </Text>
  </>
)

export const PercentageDueThisQuarterTooltip = () => (
  <>
    <Text as="h3">Percentage Due This Quarter</Text>
    <br />
    <Text>
      Every quarter, we recommend paying a percentage of your estimated annual
      tax bill, including previous payments. Our goal is for you to have paid
      the following percentages of your total estimated tax bill after each
      quarter:
      <br />
      <br />
      Q1: 25% of your estimated bill
      <br />
      Q2: 50% of your estimated bill
      <br />
      Q3: 75% of your estimated bill
      <br />
      Q4: 100% of your estimated bill
    </Text>
  </>
)

export const QuarterlyYTDTaxBillTooltip = () => (
  <>
    <Text as="h3">Quarterly YTD Tax Bill (Gross)</Text>
    <br />
    <Text>
      This is the amount of taxes you owe to date, before withholdings or prior
      quarterly tax payments are factored in.
      <br />
      <br />
      (Unless it’s Q1: then no previous payments will be factored in).
    </Text>
  </>
)

export const QuarterlyTaxesPaidToDateTooltip = () => (
  <>
    <Text as="h3">Quarterly Taxes Paid-to-Date</Text>
    <br />
    <Text>
      This is the total amount of previous quarterly tax estimate payments
      you’ve made this year. We factor in your previous quarterly tax estimate
      payments to ensure you’re paying enough throughout the year.
      <br />
      <br />
      (Unless it’s Q1: then no previous payments will show or be factored in).
    </Text>
  </>
)

export const TaxWithholdingsPaidToDateTooltip = () => (
  <>
    <Text as="h3">Tax Withholdings Paid-to-Date</Text>
    <br />
    <Text>
      This is the total amount of tax withholdings you’ve paid this year. We
      subtract these withholdings from your year-to-date quarterly tax bill to
      get your final estimate.
    </Text>
  </>
)

export const ZeroEstimateTooltip = () => (
  <>
    <Text as="h3">Why is my estimate $0?</Text>
    <br />
    <Text>
      There are several reasons why you may receive a $0 estimate:
      <ul>
        <li>
          Your tax withholdings from a W-2 job exceeded the quarterly estimate
          from your private practice
        </li>
        <li>
          You’re operating at a net loss (and if applicable: you have sufficient
          W-2 withholdings and no additional freelance income to pay quarterly
          taxes on)
        </li>
        <li>
          Based on what you have paid in prior quarters of the current tax year,
          you have already paid enough to cover your estimated tax for the year
          (unless it’s Q1)
        </li>
      </ul>
    </Text>
  </>
)
