import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Divider, Grid } from 'semantic-ui-react'
import moment from 'moment'
import { FormikProvider, useFormik } from 'formik'

import PayrollSetupList from './PayrollSetupList'
import {
  selectFirstEmployee,
  selectFirstEmployeeJob,
} from '../payroll.selectors'
import {
  postCreateEmployeeJob,
  fetchEmployees,
  putUpdateEmployeeJob,
  putUpdateJobCompensation,
  putUpdateEmployeeHomeAddress,
  fetchEmployeeHomeAddresses,
  PUT_UPDATE_EMPLOYEE_HOME_ADDRESS_KEY,
  PUT_UPDATE_EMPLOYEE_JOB_KEY,
  POST_CREATE_EMPLOYEE_JOB_KEY,
  PUT_UPDATE_JOB_COMPENSATION_KEY,
  postCreateEmployeeHomeAddress,
} from '../payrollActions'
import GustoAddress from '../GustoAddress'
import PrefilledNoticeContainer from './PrefilledNoticeContainer'
import {
  flsaDropdownOptions,
  GEP_ENROLL_PATHS,
  useEmployeeWorkLocations,
} from '../helpers'
import { FlsaStatus, PaymentUnit } from '../employeeJob.slice'
import PayrollError from '../PayrollError'
import {
  Button,
  Card,
  FormikDateInput,
  FormikDropdown,
  FormikInput,
  getFieldNames,
  GridRowColumn,
  Link,
  makeDateSchema,
  makeNumberSchema,
  makeReqStringSchema,
  Text,
} from '../../../components/BaseComponents'
import { LabelDescription } from '../../../components/BaseComponents/Input'
import { useReselector } from '../../../utils/sharedHooks'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { Gusto_EmployeeHomeAddress } from '../generated_gusto_types'
import WorkAddressModal from '../Settings/WorkAddressModal'

const JobForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const homeAddressRef = useRef<GustoAddress>(null)
  const [addAddressModal, setAddAddressModal] = useState(false)

  const employee = useReselector(selectFirstEmployee)
  const job = useReselector(selectFirstEmployeeJob)
  const [homeAddress, setHomeAddress] =
    useState<Gusto_EmployeeHomeAddress | null>()

  const { locationOptions, defaultAddress, setWorkLocation } =
    useEmployeeWorkLocations(employee?.uuid)

  useEffect(() => {
    const fetch = async () => {
      if (!employee?.uuid) {
        return
      }
      const allHomeAddresses = await dispatch(
        fetchEmployeeHomeAddresses(employee.uuid)
      )

      setHomeAddress(
        allHomeAddresses?.find((address) => address.active) || null
      )
    }

    fetch()
  }, [dispatch, employee?.uuid])

  const formik = useFormik({
    initialValues: {
      hire_date: job?.hire_date
        ? moment(job?.hire_date, DATE_FORMATS.GUSTO_SUBMIT).format(
            DATE_FORMATS.INPUT
          )
        : '',
      job_title: job?.title || 'Owner',
      rate: job?.compensations[0]?.rate || '0',
      work_location: defaultAddress || '',
      flsa_status: job?.compensations[0]?.flsa_status || FlsaStatus.Exempt,
      payment_unit: job?.compensations[0]?.payment_unit || PaymentUnit.Year,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!employee?.uuid) {
        return
      }

      const setWorkAddressSuccess = await setWorkLocation(values.work_location)
      if (!setWorkAddressSuccess) {
        return
      }

      // Set home address
      const address = homeAddressRef.current?.getAddressWithoutPhone()

      if (!address) {
        return
      }

      let homeAddressRes

      if (!homeAddress) {
        homeAddressRes = await postCreateEmployeeHomeAddress(
          employee.uuid,
          address
        )(dispatch)
      } else {
        homeAddressRes = await putUpdateEmployeeHomeAddress(homeAddress.uuid, {
          ...address,
          version: homeAddress.version,
        })(dispatch)
      }

      if (!homeAddressRes) {
        return
      }

      // Set here to update version
      setHomeAddress(homeAddressRes)

      // Set Job
      let compensationUuid
      let compensationVersion

      if (job?.uuid) {
        const res = await putUpdateEmployeeJob(job.uuid, {
          version: job.version,
          title: values.job_title,
          hire_date: moment(values.hire_date, DATE_FORMATS.INPUT).format(
            DATE_FORMATS.GUSTO_SUBMIT
          ),
        })(dispatch)

        if (!res) {
          return
        }

        compensationUuid = res.compensations[0]?.uuid
        compensationVersion = res.compensations[0]?.version
      } else {
        const res = await postCreateEmployeeJob(employee.uuid, {
          title: values.job_title,
          hire_date: moment(values.hire_date, DATE_FORMATS.INPUT).format(
            DATE_FORMATS.GUSTO_SUBMIT
          ),
        })(dispatch)

        if (!res) {
          return
        }
        compensationUuid = res.compensations[0]?.uuid
        compensationVersion = res.compensations[0]?.version
      }

      // Set compensation
      if (!compensationUuid || !compensationVersion) {
        return
      }

      const res = await putUpdateJobCompensation(compensationUuid, {
        rate: values.rate,
        payment_unit: values.payment_unit,
        version: compensationVersion,
        flsa_status: values.flsa_status,
      })(dispatch)

      if (res) {
        navigate(GEP_ENROLL_PATHS.employeeFederal)
      }
    },
  })

  const { isSubmitting, submitForm } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn>
          <Text as="h2">Your Job and Compensation details</Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text>
            (The questions below may feel confusing, which is why we have
            pre-filled some fields with our recommendations). If you have
            questions, you can reach out via{' '}
            <Link to="/conversations">Conversations</Link>
          </Text>
        </GridRowColumn>
        <Divider />
        <GridRowColumn>
          <Text as="h2">Add yourself as an employee</Text>
        </GridRowColumn>
        <PayrollError fetchKey={PUT_UPDATE_EMPLOYEE_HOME_ADDRESS_KEY} />
        <PayrollError fetchKey={PUT_UPDATE_EMPLOYEE_JOB_KEY} />
        <PayrollError fetchKey={POST_CREATE_EMPLOYEE_JOB_KEY} />
        <PayrollError fetchKey={PUT_UPDATE_JOB_COMPENSATION_KEY} />
        <GridRowColumn width={12}>
          <FormikInput
            name={fieldNames.job_title}
            label="Job Title"
            required
            schema={makeReqStringSchema({ field: 'job title' })}
          />
        </GridRowColumn>
        <GridRowColumn width={12}>
          <FormikDateInput
            name={fieldNames.hire_date}
            required
            label="Start Date"
            description="We recommend that your start date should be the day you enroll in payroll (today). If not, you may be required to file prior payroll taxes."
            schema={makeDateSchema({ field: 'hire date' })}
          />
        </GridRowColumn>
        <GridRowColumn>
          <LabelDescription
            required
            label="Employee Type"
            description={
              <Text>
                We recommend Salary/No Overtime as your employee classification.
                {'  '}
                <Link
                  href="https://support.joinheard.com/hc/en-us/articles/7479624872855"
                  newPage
                >
                  Help me with this
                </Link>
              </Text>
            }
          />
        </GridRowColumn>
        <GridRowColumn short width={10}>
          <FormikDropdown
            name={fieldNames.flsa_status}
            options={flsaDropdownOptions}
            fullWidth
            schema={makeReqStringSchema({ field: 'employee type' })}
          />
        </GridRowColumn>
        <GridRowColumn>
          <LabelDescription
            required
            label="Compensation"
            description={
              <Text>
                We recommend setting a reasonable salary at around 40% of your
                expected income this year.{' '}
                <Link
                  href="https://support.joinheard.com/hc/en-us/articles/7475592736919"
                  newPage
                >
                  What is a reasonable salary and why does it matter?
                </Link>
              </Text>
            }
          />
        </GridRowColumn>
        <Grid.Row className="short">
          <Grid.Column width={6}>
            <FormikInput
              name={fieldNames.rate}
              componentType="currency"
              required
              fullWidth
              schema={makeNumberSchema({ field: 'rate', allowedDecimals: 2 })}
            />
          </Grid.Column>
          <Grid.Column width={1} verticalAlign="middle">
            <Text>Per</Text>
          </Grid.Column>
          <Grid.Column width={5}>
            <FormikDropdown
              name={fieldNames.payment_unit}
              optionValues={Object.values(PaymentUnit).filter(
                (name) => name !== 'Paycheck'
              )}
              required
              fullWidth
              schema={makeReqStringSchema({ field: 'payment unit' })}
            />
          </Grid.Column>
        </Grid.Row>

        {homeAddress !== undefined && (
          <GridRowColumn width={12}>
            <LabelDescription label="What is your home address?" />
            <GustoAddress
              ref={homeAddressRef}
              {...homeAddress}
              hasPhoneNumber={false}
            />
          </GridRowColumn>
        )}
        <GridRowColumn width={12}>
          <FormikDropdown
            name={fieldNames.work_location}
            options={locationOptions}
            label="What is your primary working address?"
            description="If you work from home, this would be your home address."
            fullWidth
            schema={makeReqStringSchema({ field: 'Work Location' })}
          />
        </GridRowColumn>
        <GridRowColumn short>
          <Text>
            Location missing?{' '}
            <Button variant="link" onClick={() => setAddAddressModal(true)}>
              Add work address here
            </Button>
          </Text>
        </GridRowColumn>
        <Grid.Row />
        <Grid.Row>
          <Grid.Column width={6}>
            <Button
              fullWidth
              variant="secondary"
              onClick={() => navigate(GEP_ENROLL_PATHS.employee)}
            >
              Back
            </Button>
          </Grid.Column>
          <Grid.Column width={4} />
          <Grid.Column width={6}>
            <Button fullWidth onClick={submitForm} loading={isSubmitting}>
              Save & Continue
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {addAddressModal && (
        <WorkAddressModal closeModal={() => setAddAddressModal(false)} />
      )}
    </FormikProvider>
  )
}

const PayrollEnrollEmployeeJob = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchEmployees())
  }, [dispatch])

  return (
    <Container>
      <Card>
        <Grid>
          <GridRowColumn>
            <Text as="h1">Setting Up Payroll</Text>
          </GridRowColumn>
          <Divider />
          <PrefilledNoticeContainer />
          <Grid.Row>
            <Grid.Column width={9}>
              <JobForm />
            </Grid.Column>
            <Grid.Column width={7}>
              <PayrollSetupList />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row />
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollEnrollEmployeeJob
