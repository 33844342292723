import Carousel from '../components/Onboarding/Carousel'
import { call } from '../assets/lottie/onboarding.json'

export const CarouselDefault = () => (
  <Carousel
    slides={[
      {
        animationTitle: 'Welcome Animation',
        lottieAnimation: call,
        lottieLoop: true,
        title: 'Welcome to Heard!',
        description:
          "We're glad you're here! Congrats on taking the first step to simplifying your financial back-office.",
      },
      {
        animationTitle: 'Clipboard',
        lottieAnimation: call,
        title: 'Set up Your Account',
        description:
          'First, we need to collect a few things from you to get started with your bookkeeping as soon as possible.',
      },
      {
        animationTitle: 'Telephone',
        lottieAnimation: call,
        title: "Let's Get Acquainted",
        description:
          "Then, you'll have the option to join a group webinar with the Heard Onboarding Team to set you up for success.",
      },
    ]}
  />
)
