import moment from 'moment'
import { FC, useState } from 'react'
import { Button, Dropdown, Modal, Text } from '../../BaseComponents'

interface Props {
  onClose: () => void
  onCreateReports: (_year: string) => Promise<void>
  loading: boolean
}

const GenerateMissingReportsModal: FC<Props> = ({
  onClose,
  onCreateReports,
  loading,
}) => {
  const [selectedOption, setSelectedOption] = useState('')

  const thisYear = moment().format('YYYY')
  const lastYear = moment().subtract(1, 'y').format('YYYY')
  const options = [
    {
      key: thisYear,
      value: thisYear,
      text: thisYear,
    },
    {
      key: lastYear,
      value: lastYear,
      text: lastYear,
    },
  ]

  return (
    <Modal open onClose={onClose}>
      <Modal.Header>Generate Missing Reports</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Text as="h1">Choose a starting year</Text>
          <Text style={{ padding: '1em' }}>
            For example if you select {lastYear}, any <b>missing books</b> from
            January of {lastYear} through the current month will be generated.
          </Text>
          <Dropdown
            options={options}
            onChange={setSelectedOption}
            value={selectedOption}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="actionLink" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={loading || !selectedOption}
          loading={loading}
          onClick={() => onCreateReports(selectedOption)}
          content="Generate"
        />
      </Modal.Actions>
    </Modal>
  )
}

export default GenerateMissingReportsModal
