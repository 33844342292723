import UploadLandingScreen from './UploadLandingScreen'
import { UploadCSVModal } from './UploadCSVPanel'
import { UploadReviewModal } from './UploadReviewPanel'
import UploadDuplicateScreen from './UploadDuplicateScreen'
import { ManualTransactionModel } from '../../../../reducers/admin/allTransactions.slice'
import { UploadSuccessPayload } from '../Module'

export interface UploadScreenProps {
  onUploadComplete?: (_payload: {
    success: boolean
    data?: UploadSuccessPayload[]
  }) => void
  goToScreenWithData: (_payload: { index: number; data?: ScreenData }) => void
  finishFlow: () => void
  data?: ScreenData
}

export default [
  UploadLandingScreen,
  UploadCSVModal,
  UploadReviewModal,
  UploadDuplicateScreen,
]

export interface ScreenData {
  validTransactions?: Array<ManualTransactionModel>
  databaseDuplicates?: string[]
  spreadsheetDuplicates?: { [key: string]: number }
  spreadsheetFile?: File
  models?: ManualTransactionModel[]
}
