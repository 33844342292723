import { createSlice } from '@reduxjs/toolkit'

export interface UserFocusAreas {
  id: number
  userId: number
  taxesAndDeductions?: boolean
  budgeting?: boolean
  payingMyself?: boolean
  practicingInMultipleStates?: boolean
  businessOwnership?: boolean
  incorporatingMyBusiness?: boolean
  startingMyPractice?: boolean
  growingMyPractice?: boolean
  other?: string
}

const initialState: UserFocusAreas | null = null

const userFocusAreaSlice = createSlice({
  name: 'userFocusArea',
  initialState: initialState as UserFocusAreas | null,
  reducers: {
    setUserFocusAreas: (state, action) => action.payload,
  },
})

export const { setUserFocusAreas } = userFocusAreaSlice.actions

export default userFocusAreaSlice.reducer
