import { FC } from 'react'
import { Grid } from 'semantic-ui-react'
import {
  Button,
  GridRowColumn,
  Modal,
  Text,
} from '../../../../../../components/BaseComponents'
import { Colors } from '../../../../../../styles/theme'

interface Props {
  open: boolean
  close: () => void
  removeChildcareProvider: () => void
}

const RemoveChildcareProviderModal: FC<Props> = ({
  open,
  close,
  removeChildcareProvider,
}) => {
  return (
    <Modal size="tiny" open={open} onClose={close} closeIcon>
      <Modal.Header>
        Are you sure you want to delete the Care Provider?
      </Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text>
              This will permanently delete the details of the Care Provider
            </Text>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="actionLink"
          onClick={close}
          style={{ color: Colors.black, paddingRight: 32, paddingLeft: 32 }}
        >
          Cancel
        </Button>
        <Button
          variant="warning"
          onClick={() => {
            removeChildcareProvider()
            close()
          }}
        >
          Delete
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default RemoveChildcareProviderModal
