import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { keyBy } from 'lodash'

import { fetchIfNeededWrapper, fetchWrapper } from '../../../reducers/fetch'
import { ActionItem } from '../../Admin/ActionItems/allActionItems.slice'

export interface UserImportantDate {
  id: number
  endsAt: string | null
  readAt?: string
  createdAt?: string
  deletedAt?: string
  actionItem: ActionItem
}

interface UserImportantDatesState {
  [key: number]: UserImportantDate
}

const initialState: UserImportantDatesState = {}

const userImportantDatesSlice = createSlice({
  name: 'userImportantDates',
  initialState,
  reducers: {
    receiveUserImportantDates: (
      state,
      action: PayloadAction<{
        [key: number]: UserImportantDate
      }>
    ) => ({
      ...state,
      ...action.payload,
    }),
    receiveUpdatedImportantDate: (
      state,
      action: PayloadAction<UserImportantDate>
    ) => {
      state[action.payload.id] = action.payload
    },
    removeUserImportantDate: (state, action: PayloadAction<number>) => {
      delete state[action.payload]
    },
  },
})

export default userImportantDatesSlice.reducer

const {
  receiveUserImportantDates,
  receiveUpdatedImportantDate,
  removeUserImportantDate,
} = userImportantDatesSlice.actions

export const FETCH_USER_IMPORTANT_DATES_KEY = 'FETCH_USER_IMPORTANT_DATES_KEY'

export const fetchUserImportantDatesIfNeeded = () =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_USER_IMPORTANT_DATES_KEY,
    defaultErrorMessage: 'Error fetching user important dates',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<UserImportantDate[]>(
        '/finances/api/v1/user_important_dates'
      )

      dispatch(receiveUserImportantDates(keyBy(json.data, 'id')))

      return json.data
    },
  })

export interface UpdateUserImportantDatePayload
  extends Omit<UserImportantDate, 'actionItem' | 'readAt' | 'endsAt'> {
  readAt: number | null
}

export const updateUserImportantDate = (
  id: number,
  data: UpdateUserImportantDatePayload
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error updating user important date.',
    fetchFunction: async (dispatch) => {
      const json = await axios.put<UserImportantDate>(
        `/finances/api/v1/user_important_dates/${id}`,
        data
      )

      dispatch(receiveUpdatedImportantDate(json.data))
      return json.data
    },
  })

/*
  //////
  Actions below can be called by admins only 
  /////
*/

export const ADMIN_FETCH_USER_ACTION_ITEMS_KEY =
  'ADMIN_FETCH_USER_ACTION_ITEMS_KEY'
export const adminFetchUserImportantDates = (userId: number) =>
  fetchWrapper({
    fetchKey: ADMIN_FETCH_USER_ACTION_ITEMS_KEY,
    defaultErrorMessage: 'Error fetching user action items',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<UserImportantDate[]>(
        `/finances/api/v1/admin/${userId}/user_important_dates`
      )

      dispatch(receiveUserImportantDates(keyBy(json.data, 'id')))
      return json.data
    },
  })

export const ADMIN_CREATE_USER_IMPORTANT_DATE_KEY =
  'ADMIN_CREATE_USER_IMPORTANT_DATE_KEY'
export const adminCreateUserImportantDate = (
  userId: number,
  data: {
    actionItemId: number | undefined
  }
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error updating the user action item.',
    fetchKey: ADMIN_CREATE_USER_IMPORTANT_DATE_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<UserImportantDate>(
        `/finances/api/v1/admin/${userId}/user_important_dates`,
        data
      )

      dispatch(receiveUpdatedImportantDate(json.data))
      return json.data
    },
  })

export const adminDeleteUserImportantDate = (id: number, userId: number) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error updating the user action item.',
    fetchFunction: async (dispatch) => {
      const json = await axios.delete<UserImportantDate>(
        `/finances/api/v1/admin/${userId}/user_important_dates/${id}`
      )

      dispatch(removeUserImportantDate(id))
      return json.data
    },
  })
