import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Alert,
  GridRowColumn,
  Icon,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectActiveQuarterlyTaxEstimateDetails } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { getProjectedW2Income, highIncomeThreshold } from './helpers'

export const W2IncomeWarning = ({
  w2Income,
  inputTouched,
  isScorpW2Input,
}: {
  w2Income: number | null | undefined
  inputTouched: boolean | undefined
  isScorpW2Input?: boolean
}) => {
  const activeQuarterDetails = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )

  const projectedW2Income = getProjectedW2Income({
    ytdIncomeInDollars: w2Income,
    quarterDetails: activeQuarterDetails,
  })

  const showHighIncomeWarning = Boolean(
    projectedW2Income &&
      projectedW2Income.intValue >= highIncomeThreshold.intValue
  )

  // Formik doesn't have a proper typing here
  const showZeroEntryWarning = Number(w2Income) === 0 && isScorpW2Input

  if (showHighIncomeWarning || showZeroEntryWarning) {
    return (
      <GridRowColumn>
        <Alert
          customIcon={<Icon icon={regular('stars')} />}
          title={
            showZeroEntryWarning
              ? 'We strongly recommend entering W-2 income you paid yourself as an employee.'
              : 'This income seems uncommonly high.'
          }
          type="warn"
        >
          Double-check that you&apos;ve entered year-to-date income (this is how
          much you&apos;ve earned so far this year). This is simply a suggestion
          to ensure accuracy for your entries.
          <br />
          <br />
          If you’d prefer to enter in how much you think you’ll make for the
          entire year, select “Estimated income” above.
        </Alert>
      </GridRowColumn>
    )
  } else if (inputTouched) {
    return (
      <GridRowColumn>
        <Alert
          customIcon={<Icon icon={regular('stars')} />}
          title="Does this projection seem right to you?"
        >
          If you feel like this doesn’t accurately reflect your income for the
          year, you can enter total income for the year by selecting “Estimated
          income” above.
        </Alert>
      </GridRowColumn>
    )
  }

  return null
}
