import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { TrialStatus } from '../../reducers/auth/userReducer'

export const C_CORP_TAX_ENTITY = 'form_1120'

export const SIGNUP_PATHS = {
  referred: '/signup/r/:recipientCode',
  aboutYou: '/signup',
  practicePriorities: '/signup/practice-priorities',
  aboutYourPractice: '/signup/about-your-practice',
  disqualified: '/signup/disqualified',
  waitlist: '/signup/waitlist',
  consult: '/signup/consult',
  practiceJourney: '/signup/practice-journey',
  connectBank: '/signup/connect-bank',
  previousYearTax: '/signup/opt-in-tax',
  choosePlan: '/signup/choose-plan',
  success: '/signup/success',
}

export const SIGNUP_STEPS = [
  'About You',
  'Your Practice',
  'Choose Plan',
  'Payment',
]
export const SIGNUP_STEPS_2024 = [
  'About You',
  'Your Practice',
  'Choose Plan',
  'Your Tax Needs',
  'Payment',
]

export const SIGNUP_STEPS_FREE_TRIAL_2024 = [
  'About You',
  'Your Practice',
  'Choose Plan',
  'Payment',
]

export const PRIORITIES = {
  taxesAndDeductions: {
    name: 'taxesAndDeductions',
    text: 'Taxes and Deductions',
    icon: light('building-columns'),
  },
  budgeting: {
    name: 'budgeting',
    icon: light('money-bill'),
    text: 'Budgeting',
  },
  payingMyself: {
    name: 'payingMyself',
    icon: light('money-check-dollar'),
    text: 'Paying Myself',
  },
  practicingInMultipleStates: {
    name: 'practicingInMultipleStates',
    icon: light('book-atlas'),
    text: 'Practicing in Multiple States',
  },
  businessOwnership: {
    name: 'businessOwnership',
    icon: light('briefcase'),
    text: 'Business Ownership',
  },
  incorporatingMyBusiness: {
    name: 'incorporatingMyBusiness',
    icon: light('books'),
    text: 'Incorporating My Business',
  },
  startingMyPractice: {
    name: 'startingMyPractice',
    icon: light('seedling'),
    text: 'Starting My Practice',
  },
  growingMyPractice: {
    name: 'growingMyPractice',
    icon: light('chart-line-up'),
    text: 'Growing My Practice',
  },
}

export const JOURNEY_SELECTIONS = {
  gettingStarted: {
    value: 'getting_started',
    imageUrl:
      'https://heard-images.s3.amazonaws.com/assets/green_chair_table.svg',
    title: 'Getting Started',
    subtitle: 'You’re preparing to launch your own private practice.',
    recs: [
      'Opened a business checking account',
      'A goal of setting up your practice for success',
    ],
  },
  soloPractice: {
    value: 'solo',
    imageUrl:
      'https://heard-images.s3.amazonaws.com/assets/yellow_chair_table.svg',
    title: 'Solo Practice',
    subtitle: 'You launched your private practice and you’re seeing clients.',
    recs: [
      'A dedicated business checking account',
      'A goal of sustaining or growing your client base',
    ],
  },
  groupPractice: {
    value: 'group',
    imageUrl:
      'https://heard-images.s3.amazonaws.com/assets/two_green_chairs.svg',
    title: 'Group Practice',
    subtitle:
      'Your practice has two or more therapists and you have between 2-10 employees and contractors.',
    recs: [
      'Payroll set up (or need to set up payroll)',
      'A goal of helping your employees thrive',
    ],
  },
}

export const FREE_TRIAL_DAYS = 30
export const FREE_TRIAL_14_DAYS = 14

/* 
  Values added to this array will make someone eligible for receiving a free trial.
  Think of each value as an allowable "promo code" that may be adde to the free
  trial param key, e.g. if "test123" exists within the array, and a user accesses
  signup with /signup?trial=test123, they will be eligible for receiving a free trial.
  If a user accesses signup with /signup?trial=true, they will not be eligible b/c
  true does not exist within the array

*/

export const FREE_TRIAL_PROMO = 'freetrialpromo'
export const ELIGIBLE_FREE_TRIAL_PARAM_VALUES: string[] = [
  'referral',
  FREE_TRIAL_PROMO,
]

/*
  We only allow users to signup for a free trial if 
   1. the value of search param "trial" exists within our eligible array
   2. they haven't redeemed the trial yet
   3. the entry level plan is not enabled
*/
export const isFreeTrialEnabled = (
  freeTrialSearchParamValue: string | null,
  userFreeTrialStatus: TrialStatus,
  basicPlanEnabled: boolean
) =>
  Boolean(
    freeTrialSearchParamValue &&
      ELIGIBLE_FREE_TRIAL_PARAM_VALUES.includes(freeTrialSearchParamValue) &&
      userFreeTrialStatus === TrialStatus.unredeemed &&
      !basicPlanEnabled
  )

/**
  Referrals have 30 day free trial
  freetrialpromo is the regular 14 day free trial
 */
export const fetchFreeTrialLength = (freeTrialParam: string | null) => {
  return freeTrialParam === FREE_TRIAL_PROMO
    ? FREE_TRIAL_14_DAYS
    : FREE_TRIAL_DAYS
}
