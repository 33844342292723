import { useCallback, useEffect, useState } from 'react'
import { Divider, Grid } from 'semantic-ui-react'

import {
  Modal,
  GridRowColumn,
  Text,
  Button,
  Card,
  Link,
  Input,
  Popup,
  Dropdown,
} from '../../components/BaseComponents'
import { LabelDescription } from '../../components/BaseComponents/Input'
import { markUserActionItemCompleteIfExists } from '../Dashboard/UserActionItems/service'
import { PostOnboardingTaskIdents } from './config'
import { updateUser } from '../../actions/userActions'
import { useReselector } from '../../utils/sharedHooks'
import { getCurrentUser } from '../../selectors/user.selectors'
import { PayrollSetup } from '../../reducers/auth/userReducer'
import { useAnalyticsTrack } from '../Amplitude'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../utils/typeHelpers'

enum PayrollProvider {
  gusto = 'gusto',
  other = 'other',
  noPayroll = 'noPayroll',
}

const PayrollAccessModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  const dispatch = useAppDispatch()
  const currentUser = useReselector(getCurrentUser)
  const [payrollProvider, setPayrollProvider] = useState<PayrollProvider>()
  const [isInterestedGEP, setIsInterestedGEP] = useState<boolean>()
  const [otherProvider, setOtherProvider] = useState('')
  const track = useAnalyticsTrack()
  const navigate = useNavigate()

  useEffect(() => {
    if (open) {
      track('viewed payroll questionnaire modal')
    }
  }, [open, track])

  const onConfirm = useCallback(() => {
    let payrollSetup
    if (payrollProvider === PayrollProvider.gusto) {
      payrollSetup = PayrollSetup.gusto
    } else if (payrollProvider === PayrollProvider.other) {
      payrollSetup = PayrollSetup.other
    } else if (payrollProvider === PayrollProvider.noPayroll) {
      payrollSetup = PayrollSetup.needsPayroll
    }

    const NOW = Date.now()

    dispatch(
      updateUser(currentUser?.id, {
        payrollSetup,
        payrollProvider:
          payrollProvider === PayrollProvider.other ? otherProvider : undefined,
        interestedInGepAt: isInterestedGEP ? NOW : undefined,
        gepRequestFormSubmittedAt: NOW,
      })
    )
    markUserActionItemCompleteIfExists(
      PostOnboardingTaskIdents.SET_UP_PAYROLL_ACCESS,
      (event, properties) => track(event, properties)
    )
    track('clicked payroll questionnaire modal submit button', {
      payroll_provider: payrollProvider ?? 'none',
    })

    onClose()

    if (
      payrollSetup === PayrollSetup.other ||
      (payrollSetup === PayrollSetup.gusto && !isInterestedGEP) ||
      (payrollSetup === PayrollSetup.needsPayroll && !isInterestedGEP)
    ) {
      navigate('/dashboard')
    }

    if (
      (payrollSetup === PayrollSetup.gusto && isInterestedGEP) ||
      (payrollSetup === PayrollSetup.needsPayroll && isInterestedGEP)
    ) {
      navigate('/payroll/welcome')
    }
  }, [
    currentUser?.id,
    dispatch,
    isInterestedGEP,
    onClose,
    otherProvider,
    payrollProvider,
    track,
    navigate,
  ])

  const interestedLabel =
    payrollProvider === PayrollProvider.gusto
      ? 'Would you like to migrate your Gusto payroll to Heard?'
      : 'Are you interested in setting up payroll on Heard?'
  const interestedDescription =
    payrollProvider === PayrollProvider.gusto
      ? 'Migrating your payroll allows you to manage your payroll directly within Heard.'
      : 'Heard has it’s own Payroll via the Gusto Embedded Payroll (GEP) platform. You are able to utilize the Gusto platform on Heard. '

  return (
    <Modal open={open} closeIcon onClose={onClose} size="small">
      <Modal.Header>Request Access to Heard Payroll</Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn short>
            <Text as="bodyLg">
              Please take a moment to answer a few questions. We need this
              information to review your payroll needs.
            </Text>
          </GridRowColumn>
          <Divider />
          <GridRowColumn width={12}>
            <Dropdown
              required
              label="Who is your payroll provider?"
              options={[
                { text: 'Gusto', value: PayrollProvider.gusto },
                { text: 'Other Provider', value: PayrollProvider.other },
                {
                  text: 'I don’t have payroll set up yet',
                  value: PayrollProvider.noPayroll,
                },
              ]}
              onChange={setPayrollProvider}
              value={payrollProvider}
              fullWidth
              afterLabel={
                <Popup
                  content={
                    <>
                      <Text as="h3">Payroll Provider</Text>
                      <Text>
                        A Payroll Provider helps an employer run payroll and
                        administer employment tax obligations for their
                        employees.
                      </Text>
                      <Text>
                        Examples of Payroll Providers are Gusto, QuickBooks,
                        OnPay, Square, etc.
                      </Text>
                    </>
                  }
                />
              }
            />
          </GridRowColumn>
          {payrollProvider &&
            [PayrollProvider.gusto, PayrollProvider.noPayroll].includes(
              payrollProvider
            ) && (
              <>
                <GridRowColumn>
                  <LabelDescription
                    label={interestedLabel}
                    description={interestedDescription}
                  />
                </GridRowColumn>
                <Grid.Row className="short">
                  <Grid.Column width={3}>
                    <Button
                      variant={
                        isInterestedGEP === true ? 'primary' : 'secondary'
                      }
                      onClick={() => setIsInterestedGEP(true)}
                      fullWidth
                    >
                      Yes
                    </Button>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Button
                      variant={
                        isInterestedGEP === false ? 'primary' : 'secondary'
                      }
                      onClick={() => setIsInterestedGEP(false)}
                      fullWidth
                    >
                      No
                    </Button>
                  </Grid.Column>
                </Grid.Row>
                {payrollProvider === PayrollProvider.noPayroll &&
                  isInterestedGEP && (
                    <GridRowColumn>
                      <Card backgroundColor="stone">
                        <Text as="bodyLg">
                          Great, we can review your payroll needs! We’ll note
                          this and check in during the onboarding call.
                        </Text>
                      </Card>
                    </GridRowColumn>
                  )}
              </>
            )}
          {payrollProvider === PayrollProvider.other && (
            <>
              <GridRowColumn>
                <Input
                  label="Payroll Provider"
                  description="Who is your Payroll Provider. e.g. QuickBooks, OnPay, Square, etc."
                  placeholder="Payroll Provider"
                  value={otherProvider}
                  onChange={setOtherProvider}
                />
              </GridRowColumn>
              <GridRowColumn>
                <Card backgroundColor="stone">
                  <Text as="bodyLg">
                    We’ll need you to grant us access to your payroll provider.
                    Take a look at these resources to learn how to do that.
                  </Text>
                  <br />
                  <ul>
                    <li>
                      <Link
                        href="https://quickbooks.intuit.com/learn-support/en-us/help-article/manage-users/set-external-accountant-user-quickbooks-desktop/L2acpmzY8_US_en_US"
                        newPage
                      >
                        QuickBooks
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="https://help.onpay.com/hc/en-us/articles/360043161532-Add-and-manage-Outside-Collaborator-users-#:~:text=Adding%20a%20new%20Outside%20Collaborator,-Go%20to%20Company&text=Scroll%20down%20to%20Company%20Users,Bookkeeper%2C%20Franchisor%2C%20or%20Other"
                        newPage
                      >
                        OnPay
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="https://squareup.com/help/us/en/article/6316-add-an-administrator-or-authorized-representative#:~:text=Sign%20in%20to%20Settings%20%3E%20Account,address%20or%20mobile%20phone%20number"
                        newPage
                      >
                        Square
                      </Link>
                    </li>
                  </ul>
                </Card>
              </GridRowColumn>
            </>
          )}
        </Grid>
      </Modal.Content>
      {(payrollProvider === PayrollProvider.other ||
        isInterestedGEP !== undefined) && (
        <Modal.Actions>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button onClick={onConfirm}>Submit</Button>
        </Modal.Actions>
      )}
    </Modal>
  )
}

export default PayrollAccessModal
