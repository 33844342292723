import { CSSProperties, FC, ReactNode } from 'react'
import { Text } from '../../../../components/BaseComponents'
import { Colors, FontWeight } from '../../../../styles/theme'

interface Props {
  text: string
  prominentText: ReactNode
  style?: CSSProperties
  icon?: ReactNode
}

const defaultStyle = {
  color: Colors.orange,
}

const DeadlineLabel: FC<Props> = ({ text, prominentText, icon, ...rest }) => (
  <Text as="bodySm" style={defaultStyle} {...rest}>
    {icon}
    {text}{' '}
    <span style={{ fontWeight: FontWeight.SEMIBOLD }}>{prominentText}</span>
  </Text>
)

export default DeadlineLabel
