import axios from 'axios'
import { fetchWrapper } from '../../../../../reducers/fetch'
import { StandardDeduction } from '../IncomeTaxDetailsHelpers'

//Client-side models
export interface FederalIncomeTaxEstimateDetails {
  id: number
  effectiveFrom: string
  current: boolean
  updatedBy?: number
  standardDeduction: StandardDeduction
  dependentDeductionInCents?: number
  deductibleSelfEmploymentRate: number
  qbiDeductionRate: number
  qbiDeductionIncomeLimitInCents: number
  selfEmploymentReductionRate: number
  socialSecurityTaxMaxIncomeInCents: number
  socialSecurityTaxRate: number
  medicareTaxRate: number
  taxRateBrackets: JSON
  createdAt: string
  updatedAt: string
}

export type FederalIncomeTaxDetailsPayload = Omit<
  FederalIncomeTaxEstimateDetails,
  'id' | 'createdAt' | 'updatedAt' | 'effectiveFrom'
> & { effectiveFrom: Date }

// Keys used within our fetchWrapper. Sometimes used by UI components for ascertaining the request's state
export const FETCH_ALL_FEDERAL_INCOME_TAX_DETAILS_KEY =
  'FETCH_ALL_FEDERAL_INCOME_TAX_DETAILS_KEY'
export const CREATE_SINGLE_FEDERAL_INCOME_TAX_DETAILS_KEY =
  'CREATE_SINGLE_FEDERAL_INCOME_TAX_DETAILS_KEY'
export const FETCH_CURRENT_FEDERAL_INCOME_TAX_DETAILS_KEY =
  'FETCH_CURRENT_FEDERAL_INCOME_TAX_DETAILS_KEY'

// Action functions
export const createSingleFederalIncomeTaxDetails = (
  data: Partial<FederalIncomeTaxDetailsPayload>
) =>
  fetchWrapper({
    fetchKey: CREATE_SINGLE_FEDERAL_INCOME_TAX_DETAILS_KEY,
    defaultErrorMessage: 'Error creating the federal income tax details',
    fetchFunction: async () => {
      const json = await axios.post<FederalIncomeTaxEstimateDetails>(
        '/finances/api/v1/admin/federal_income_tax',
        data
      )
      return json.data
    },
  })

export const getFederalDetails = () =>
  fetchWrapper({
    fetchKey: FETCH_ALL_FEDERAL_INCOME_TAX_DETAILS_KEY,
    fetchFunction: async () => {
      const json = await axios.get<FederalIncomeTaxEstimateDetails[]>(
        '/finances/api/v1/admin/federal_income_tax'
      )
      return json.data
    },
  })

export const getCurrentFederalDetails = () =>
  fetchWrapper({
    fetchKey: FETCH_CURRENT_FEDERAL_INCOME_TAX_DETAILS_KEY,
    fetchFunction: async () => {
      const json = await axios.get<FederalIncomeTaxEstimateDetails>(
        '/finances/api/v1/admin/federal_income_tax/current'
      )
      return json.data
    },
  })
