import { useMemo } from 'react'
import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Alert,
  FormikInput,
  getFieldName,
  GridRowColumn,
  Icon,
  Link,
  makeNumberSchema,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { getFinancialProfile } from '../../../../selectors/user.selectors'
import {
  UPDATE_FINANCIAL_PROFILE_KEY,
  updateFinancialProfile,
} from '../../../../actions/financialProfileActions'
import {
  TAX_PROFILE_STEP,
  TaxesProfileFlowAnswerProps,
  useTaxProfileSteps,
  withholdingRecommendedMinimumPercentage,
} from './helpers'
import {
  addCurrencyArray,
  centsToCurrency,
  dollarsToCents,
  dollarsToCurrency,
} from '../../../../utils/currencyHelpers'
import TaxProfileFormFooter from './TaxProfileFormFooter'
import { getFetchError } from '../../../../reducers/fetch'
import TaxProfileAccordions, {
  useTaxProfileAccordionCopy,
} from './TaxProfileAccordions'
import ExamplePaystub from './ExamplePaystub'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useAnalyticsTrack } from '../../../Amplitude'

const SpouseWithholdingForm = ({
  formFlowAnswers,
  setFormFlowAnswers,
}: TaxesProfileFlowAnswerProps) => {
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()
  const fp = useReselector(getFinancialProfile)

  // Only one of these can be true at a time but this cleans up the logic a bit and makes it easier to extend in future
  const totalSpouseWIncome = addCurrencyArray([
    centsToCurrency(fp?.spouseWIncomeInCents),
    centsToCurrency(fp?.estimatedAnnualSpouseW2IncomeInCents),
  ])

  const error = useReselector(
    getFetchError,
    UPDATE_FINANCIAL_PROFILE_KEY(fp?.id)
  )
  const { goToPreviousStep, goToNextStep } = useTaxProfileSteps(
    TAX_PROFILE_STEP.spouseWithholding
  )

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      spouseFederalWithholding: formFlowAnswers.spouseFederalWithholding,
      spouseStateWithholding: formFlowAnswers.spouseStateWithholding,
    },
    onSubmit: async ({ spouseFederalWithholding, spouseStateWithholding }) => {
      const res = await dispatch(
        updateFinancialProfile(fp?.id, {
          spouseFederalWithholdingInCents: dollarsToCents(
            spouseFederalWithholding
          ),
          spouseStateWithholdingInCents: dollarsToCents(spouseStateWithholding),
        })
      )

      if (res) {
        setFormFlowAnswers({
          ...formFlowAnswers,
          spouseFederalWithholding,
          spouseStateWithholding,
        })
        track('spouse withholding submitted', {
          spouse_federal_withholding_in_cents: dollarsToCents(
            spouseFederalWithholding
          ),
          spouse_state_withholding_in_cents: dollarsToCents(
            spouseStateWithholding
          ),
          user_id: fp?.userId ?? null,
        })
        goToNextStep(res)
      }
    },
  })

  const { values, submitForm, isValid, isSubmitting } = formik

  const spouseFederalWithholdingSchema = makeNumberSchema({
    allowedDecimals: 2,
    required: false,
  })
    .test(
      'spouseFederalWithholdingValidation',
      "Your spouse's withholdings must be less than the W-2 income you shared on a previous step. Please double-check your spouse's paystub.",
      (spouseFederalWithholding) => {
        const withholding = dollarsToCurrency(spouseFederalWithholding).value

        return !withholding || totalSpouseWIncome > withholding
      }
    )
    .test(
      'spouseFederalWithholdingGreaterThanZeroValidation',
      'Withholdings cannot be $0. Please double-check your paystub.',
      (spouseFederalWithholding) => {
        //don't throw an error when they first arrive to the page
        if (!spouseFederalWithholding) {
          return true
        }
        const withholding = dollarsToCurrency(spouseFederalWithholding).value

        return withholding > 0
      }
    )

  const spouseStateWithholdingSchema = makeNumberSchema({
    allowedDecimals: 2,
  }).test(
    'spouseStateWithholdingValidation',
    "Your spouse's withholdings must be less than the W-2 income you shared on a previous step. Please double-check your spouse's paystub.",
    (spouseStateWithholding) => {
      const withholding = dollarsToCurrency(spouseStateWithholding).value

      return !withholding || totalSpouseWIncome > withholding
    }
  )

  const withholdingPercentage = useMemo(
    () =>
      Math.floor(
        (Number(values.spouseFederalWithholding) / totalSpouseWIncome) * 100
      ),
    [values.spouseFederalWithholding, totalSpouseWIncome]
  )

  const faqCopy = useTaxProfileAccordionCopy()

  return (
    <FormikProvider value={formik}>
      <Grid>
        <ExamplePaystub zoomTo="withholdings" />
        <GridRowColumn {...makeGridConfig([12, 16, 16], true)}>
          <Text as="display2" textAlign="center">
            What are your spouse’s year-to-date W-2 tax withholdings?
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([12, 16, 16], true)}>
          <Text as="bodyLg" textAlign="center">
            These are the total amounts withheld from <b>all</b> of your
            spouse’s W-2 wages so far this year. This is on their paystub.
          </Text>
        </GridRowColumn>
        {error && (
          <GridRowColumn>
            <Alert type="error">{error.message}</Alert>
          </GridRowColumn>
        )}
        <Grid.Row />
        <GridRowColumn {...makeGridConfig([8, 12, 16], true)}>
          <FormikInput
            name={getFieldName<typeof values>('spouseFederalWithholding')}
            label="Spouse’s Year-to-Date Federal Tax Withholding"
            required
            fullWidth
            componentType="currency"
            schema={spouseFederalWithholdingSchema}
          />
        </GridRowColumn>
        {Boolean(
          values.spouseFederalWithholding &&
            withholdingPercentage < withholdingRecommendedMinimumPercentage
        ) && (
          <GridRowColumn short {...makeGridConfig([8, 12, 16], true)}>
            <Alert
              customIcon={<Icon icon={regular('stars')} />}
              title={`Your spouse is withholding ${withholdingPercentage}% of their income`}
            >
              If your spouse withholds less than{' '}
              {withholdingRecommendedMinimumPercentage}% of their income, they
              will likely owe taxes at the end of the year. Learn how to
              calculate your spouse’s withholdings{' '}
              <Link
                href="https://apps.irs.gov/app/tax-withholding-estimator"
                newPage
              >
                here
              </Link>
              .
            </Alert>
          </GridRowColumn>
        )}
        <GridRowColumn {...makeGridConfig([8, 12, 16], true)}>
          <FormikInput
            name={getFieldName<typeof values>('spouseStateWithholding')}
            label="Spouse’s Year-to-Date State Tax Withholding"
            required
            fullWidth
            componentType="currency"
            schema={spouseStateWithholdingSchema}
          />
        </GridRowColumn>
        <Grid.Row />
        <TaxProfileAccordions
          faqs={[
            faqCopy.whyShareSpouseTaxWithholdings,
            faqCopy.whatIfHomeStateNoTax,
            faqCopy.whatAreSpouseYTDTaxWithholdings,
          ]}
        />
        <TaxProfileFormFooter
          submitOrContinue={submitForm}
          goToPreviousStep={goToPreviousStep}
          continueDisabled={!isValid || isSubmitting}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default SpouseWithholdingForm
