import { ElementType } from 'react'

interface Props {
  component: ElementType
}

const PublicRoute = ({ component: RouteComponent, ...rest }: Props) => (
  <RouteComponent {...rest} />
)

export default PublicRoute
