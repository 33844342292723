import { DateTime } from 'luxon'
import { useUsersnapCustomDisplayLogicWidget } from '../../../components/shared/UsersnapSurvey'
import { useReselector } from '../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { selectAllFilingFormsComplete } from './annualTaxFilingForms.selector'
import { getAnnualTaxFilingForYearSelector } from './annualTaxFilings.selector'
import {
  fetchAnnualTaxFilingsIfNeeded,
  updateAnnualTaxFilings,
} from './annualTaxFilings.slice'
import { useEffect } from 'react'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'

const UsersnapTaxSeasonNPSSurvey = () => {
  const dispatch = useAppDispatch()

  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const taxFiling = useReselector(getAnnualTaxFilingForYearSelector, taxYear)
  const allFormsComplete = useReselector(
    selectAllFilingFormsComplete,
    taxFiling?.id
  )

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingsIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
  }, [dispatch])

  useUsersnapCustomDisplayLogicWidget({
    projectKey: process.env.VITE_USERSNAP_TAX_FYLE_PROJECT_KEY,
    shouldShow:
      // Only show the survey if the user has completed all forms and has not submitted their tax NPS
      allFormsComplete && taxFiling && !taxFiling.taxNpsSubmittedAt,
    // Only show the survey 3 times and then wait 3 days before showing it each time
    maxTimesShown: 3,
    showAgainAfterDays: 3,
    onSubmit: () => {
      if (!taxFiling || taxFiling.taxNpsSubmittedAt) return
      dispatch(
        updateAnnualTaxFilings(taxFiling?.id, {
          taxNpsSubmittedAt: DateTime.now().toISO(),
        })
      )
    },
  })

  return null
}

export default UsersnapTaxSeasonNPSSurvey
