import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import {
  Card,
  GridRowColumn,
  Pill,
  Text,
} from '../../components/BaseComponents'
import YourBusinessSection from './YourBusinessSection'
import { useReselector, useScrollRef } from '../../utils/sharedHooks'
import {
  getFinancialProfile,
  selectIsCurrentUserScorp,
} from '../../selectors/user.selectors'
import TaxInformationSection from './TaxInformationSection'
import PersonalInformationSection from './PersonalInformationSection'
import { fetchUserDocumentCategoriesIfNeeded } from '../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { fetchUserDocuments } from '../UserDocuments/userDocuments.slice'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'

import { getTaxEntityName } from '../Taxes/taxConstants'
import { useAppDispatch } from '../../utils/typeHelpers'

const PracticeProfile = () => {
  const dispatch = useAppDispatch()
  const isScorp = useReselector(selectIsCurrentUserScorp)
  const fp = useReselector(getFinancialProfile)
  const taxEntityName = getTaxEntityName(fp?.taxEntityType)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const [searchParams] = useSearchParams()
  const section = searchParams.get('section')
  const {
    scrollRef: yourBusinessScrollRef,
    scrollToRef: scrollToYourBusiness,
  } = useScrollRef()
  const {
    scrollRef: taxInformationScrollRef,
    scrollToRef: scrollToTaxInformation,
  } = useScrollRef()
  const {
    scrollRef: personalInformationScrollRef,
    scrollToRef: scrollToPersonalInformation,
  } = useScrollRef()

  // Scroll to sections based on query param
  useEffect(() => {
    if (section === 'business') {
      scrollToYourBusiness()
    } else if (section === 'tax') {
      scrollToTaxInformation()
    } else if (section === 'personal') {
      scrollToPersonalInformation()
    }
  }, [
    scrollToPersonalInformation,
    scrollToTaxInformation,
    scrollToYourBusiness,
    section,
  ])

  useEffect(() => {
    dispatch(fetchUserDocuments())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
  }, [dispatch])

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          marginLeft: isMobile ? 20 : 0,
        }}
      >
        <Text
          as={isMobile ? 'h2' : 'h1'}
          style={{ marginRight: 16, marginBottom: isMobile ? 8 : 0 }}
        >
          Practice Profile
        </Text>
        <Pill color={isScorp ? 'tertiary' : 'neutral'}>{taxEntityName}</Pill>
      </div>
      <Card type="subsection" backgroundColor="stone40">
        <Grid>
          <GridRowColumn>
            <span ref={yourBusinessScrollRef} />
            <YourBusinessSection />
          </GridRowColumn>
          <GridRowColumn>
            <span ref={taxInformationScrollRef} />
            <TaxInformationSection />
          </GridRowColumn>
          <GridRowColumn>
            <span ref={personalInformationScrollRef} />
            <PersonalInformationSection />
          </GridRowColumn>
        </Grid>
      </Card>
    </>
  )
}

export default PracticeProfile
