// Action item category
import axios from 'axios'
import { keyBy } from 'lodash'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { fetchIfNeededWrapper, fetchWrapper } from '../../../reducers/fetch'
import { ReduxState } from '../../../utils/typeHelpers'

export enum ActionItemCategoryInternalName {
  annual_taxes = 'annual_taxes',
  quarterly_taxes = 'quarterly_taxes',
  onboarding = 'onboarding',
  yearEndDocuments = 'year-end-documents',
}

export interface ActionItemCategory {
  id: number
  internalName: ActionItemCategoryInternalName
  title: string

  // These won't come back on the subqueries
  createdAt?: null | string
  updatedAt?: null | string
}

export interface ActionItemCategoryState {
  [key: string]: ActionItemCategory
}

const allActionItemCategoriesSlice = createSlice({
  name: 'allActionItemCategories',
  initialState: {} as ActionItemCategoryState,
  reducers: {
    receiveAllActionItemCategories: (
      state,
      action: PayloadAction<{ [key: string]: ActionItemCategory }>
    ) => ({ ...state, ...action.payload }),
    receiveSingleActionItemCategory: (
      state,
      action: PayloadAction<ActionItemCategory>
    ) => {
      state[action.payload.id] = action.payload
    },
  },
})

export const {
  receiveSingleActionItemCategory,
  receiveAllActionItemCategories,
} = allActionItemCategoriesSlice.actions

export default allActionItemCategoriesSlice.reducer

export const FETCH_ALL_ACTION_ITEM_CATEGORIES_KEY =
  'FETCH_ALL_ACTION_ITEM_CATEGORIES_KEY'
export const fetchAllActionItemCategoriesIfNeeded = () =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_ALL_ACTION_ITEM_CATEGORIES_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<ActionItemCategory[]>(
        '/finances/api/v1/admin/action_item/categories'
      )

      dispatch(receiveAllActionItemCategories(keyBy(json.data, 'id')))
      return json.data
    },
  })

export const CREATE_ACTION_ITEM_CATEGORY_KEY = 'CREATE_ACTION_ITEM_CATEGORY_KEY'
export const createActionItemCategories = (data: {
  title: string
  internalName: string
}) =>
  fetchWrapper({
    defaultValue: false,
    fetchKey: CREATE_ACTION_ITEM_CATEGORY_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<ActionItemCategory>(
        '/finances/api/v1/admin/action_item/categories',
        data
      )

      dispatch(receiveSingleActionItemCategory(json.data))
      return json.data
    },
  })

export const selectActionItemCategories = (state: ReduxState) =>
  state.admin.allActionItemCategories
