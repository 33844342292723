import { useCallback, useState, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Grid, Divider } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { sortBy } from 'lodash'
import { fetchTransactionCategoriesIfNeeded } from '../Reports/reports.slice'
import { transactionFilterToQueryUrlService } from '../../services/transactionFilterToQueryUrlService'
import { getTransactionCategoryOptions } from '../Reports/reports.selectors'
import {
  clearTransactionFilters,
  updateTransactionFilters,
} from '../../reducers/finances/transactionFiltersReducer'
import { fetchFilteredUserTransactions } from './transactions.slice'
import { usePrevious, useReselector } from '../../utils/sharedHooks'
import { selectFinancialAccounts } from '../../selectors/financeSelectors'
import { formatAccountNameWithInstitution } from './helpers'
import {
  Button,
  Icon,
  Text,
  Dropdown,
  DatePicker,
  GridRowColumn,
} from '../../components/BaseComponents'
import {
  DeviceWidth,
  DeviceWidthCombo,
  useIsDeviceWidth,
} from '../../utils/deviceWidthHelpers'
import { getCurrentUser } from '../../selectors/user.selectors'
import { useAppDispatch } from '../../utils/typeHelpers'

interface Props {
  transactionsType?: 'expenses' | 'income'
}

const UserTransactionsFilter = ({ transactionsType }: Props) => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const defaultCat = searchParams.get('categoryId')
  const paramStartDate = searchParams.get('startDate')
  const paramEndDate = searchParams.get('endDate')
  const [showFilters, setShowFilters] = useState(Boolean(defaultCat))
  const categories = useReselector(
    getTransactionCategoryOptions,
    transactionsType,
    true
  )
  const transactionFilters = useReselector((state) => state.transactionFilters)
  const prevTransactionFilters = usePrevious(transactionFilters)
  const financialAccounts = useReselector(selectFinancialAccounts)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const isMobileOrTablet = useIsDeviceWidth(DeviceWidthCombo.mobileOrTablet)
  const currentUser = useReselector(getCurrentUser)

  useEffect(() => {
    dispatch(
      clearTransactionFilters({ limit: isMobileOrTablet ? '10' : '100' })
    )
    if (defaultCat) {
      dispatch(
        updateTransactionFilters({
          transactionCategoryId: defaultCat,
        })
      )
    }
    if (paramStartDate) {
      dispatch(
        updateTransactionFilters({
          startDate: paramStartDate,
        })
      )
    }
    if (paramEndDate) {
      dispatch(
        updateTransactionFilters({
          endDate: paramEndDate,
        })
      )
    }
  }, [defaultCat, dispatch, isMobileOrTablet, paramEndDate, paramStartDate])

  const fADropdownOptions = useMemo(() => {
    const options = Object.values(financialAccounts).map((account) => ({
      text: formatAccountNameWithInstitution(account),
      value: account.id,
    }))
    return sortBy(options, 'text')
  }, [financialAccounts])

  useEffect(() => {
    dispatch(fetchTransactionCategoriesIfNeeded())
  }, [dispatch])

  const fetchTransactions = useCallback(
    () =>
      currentUser?.id &&
      dispatch(
        fetchFilteredUserTransactions(
          transactionFilterToQueryUrlService({
            ...transactionFilters,
            userId: currentUser.id,
          })
        )
      ),
    [dispatch, transactionFilters, currentUser?.id]
  )

  useEffect(() => {
    if (
      JSON.stringify(transactionFilters) !==
      JSON.stringify(prevTransactionFilters)
    ) {
      fetchTransactions()
    }
  }, [transactionFilters, prevTransactionFilters, fetchTransactions])

  const handleDateRangeChange = useCallback(
    ([startDate, endDate]: [string, string]) => {
      searchParams.delete('startDate')
      searchParams.delete('endDate')
      setSearchParams(searchParams)
      dispatch(updateTransactionFilters({ startDate, endDate }))
    },
    [dispatch, searchParams, setSearchParams]
  )

  const {
    startDate,
    endDate,
    transactionCategoryId,
    transactionsType: filterType,
    financialAccountId,
  } = transactionFilters

  return (
    <Grid className="transactionFilter" style={{ margin: '5px 0px' }}>
      <Grid.Row className="short">
        <Grid.Column mobile={10} tablet={6} computer={5} style={{ padding: 0 }}>
          <DatePicker
            value={
              !startDate && !endDate
                ? ''
                : `${startDate || ''} - ${endDate || ''}`
            }
            onChange={handleDateRangeChange}
            startDate={startDate || undefined}
            endDate={endDate || undefined}
            selectsRange
            clearable
            fullWidth
            placeholder="Filter by date"
          />
        </Grid.Column>
        <Grid.Column
          mobile={6}
          tablet={4}
          computer={3}
          style={{ paddingRight: 0 }}
        >
          <Button
            active={showFilters}
            variant="secondary"
            onClick={() => setShowFilters((val) => !val)}
            fullWidth
          >
            <Icon
              color="black"
              icon={regular('filter')}
              style={{ marginRight: 8 }}
            />
            Filters
          </Button>
        </Grid.Column>
      </Grid.Row>

      {showFilters && (
        <>
          <GridRowColumn columnStyle={{ padding: 0 }}>
            <Text as="eyebrow" color="darkGray">
              Filter Transactions:
            </Text>
          </GridRowColumn>
          <Grid.Row
            className="short"
            style={
              isMobile
                ? { display: 'flex', flexDirection: 'column', gap: 12 }
                : undefined
            }
          >
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              style={{ padding: 0 }}
            >
              <Dropdown
                placeholder="Category"
                value={Number(transactionCategoryId) || undefined}
                options={categories}
                onChange={(value) =>
                  dispatch(
                    updateTransactionFilters({
                      transactionCategoryId:
                        typeof value === 'number' ? value.toString() : value,
                    })
                  )
                }
                disabled={filterType === 'uncategorized'}
                search
                clearable
                fullWidth
              />
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              style={isMobile ? { padding: 0 } : { paddingRight: 0 }}
            >
              <Dropdown
                placeholder="Account"
                value={financialAccountId || undefined}
                options={fADropdownOptions}
                onChange={(value) =>
                  dispatch(
                    updateTransactionFilters({ financialAccountId: value })
                  )
                }
                clearable
                fullWidth
              />
            </Grid.Column>
          </Grid.Row>
          <Divider style={{ marginBottom: 0 }} />
        </>
      )}
    </Grid>
  )
}

export default UserTransactionsFilter
