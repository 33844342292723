import { FC, useMemo, useState, useRef, useEffect, useCallback } from 'react'
import { Accordion } from 'semantic-ui-react'
import { FinancialAccountWithAdminInfo } from '../../../reducers/admin/financialAccountsReducer'
import { TransactionCategory } from '../../Reports/reports.slice'
import { LedgerAccount, GeneralLedgerLineItem } from '../types'
import { createGeneralLedgerViewModel } from '../service'
import GeneralLedgerRow from './GeneralLedgerRow'
import { useSearchParams } from 'react-router-dom'

export interface GeneralLedgerProps {
  generalLedgerItems: LedgerAccount[]
  financialAccountsById: {
    [key: string]: FinancialAccountWithAdminInfo
  }
  transactionCategoriesById: {
    [key: string]: TransactionCategory
  }
  accountFilter: string | undefined
  statementFilter: string | undefined
  sortBy: string
}

export interface GeneralLedgerAccount {
  name: string
  type: 'transactionCategory' | 'financialAccount'
  id: number
  key: string
  netTotalInCents: number
  balanceBroughtDownDetails: {
    sumDebits: number
    sumCredits: number
  }
  lineItems: GeneralLedgerLineItem[]
  totalDebitsInCents: number
  totalCreditsInCents: number
  accountFilter?: string
  statementFilter?: string
  sortBy?: string
  accountType?: string | null
  financialStatement?: string | null
}

export interface GeneralLedgerViewModel {
  accounts: GeneralLedgerAccount[]
}

export const NUM_ITEMS_IN_COLLAPSED_STATE = 10

const GeneralLedger: FC<GeneralLedgerProps> = ({
  generalLedgerItems,
  transactionCategoriesById,
  financialAccountsById,
  accountFilter,
  statementFilter,
  sortBy,
}) => {
  const [searchParams] = useSearchParams()
  const listItemRef = useRef<HTMLDivElement | null>(null)

  const [openAccordionIndexes, setOpenAccordionIndexes] = useState(
    new Set<number>()
  )
  const [scrolledToRef, setScrolledToRef] = useState(false)

  const itemToScrollTo = useMemo(() => {
    if (searchParams.get('transactionCategoryId')) {
      return `category-${searchParams.get('transactionCategoryId')}`
    }
    if (searchParams.get('financialAccountId')) {
      return `financial-${searchParams.get('financialAccountId')}`
    }
    return null
  }, [searchParams])

  // Transform journalEntries into an easier model to work with
  const viewModel = useMemo(
    () =>
      createGeneralLedgerViewModel({
        generalLedgerItems,
        financialAccountsById,
        transactionCategoriesById,
        accountFilter,
        statementFilter,
        sortBy,
      }),
    [
      generalLedgerItems,
      financialAccountsById,
      transactionCategoriesById,
      accountFilter,
      statementFilter,
      sortBy,
    ]
  )

  const onAccordionClick = useCallback(
    (index: number) => {
      const clonedSet = new Set(openAccordionIndexes)

      if (clonedSet.has(index)) {
        clonedSet.delete(index)
      } else {
        clonedSet.add(index)
      }

      setOpenAccordionIndexes(clonedSet)
    },
    [openAccordionIndexes]
  )

  /*
    If this page is routed to with the financialAccountId or transactionCategoryId
    searchParams, scroll to the account with that id and automatically open it.
    This sets the scrolledToRef state to true so that it only scrolls once and not
    every time the viewModel updates (ex: on filters or sorts). 
  */
  useEffect(() => {
    if (itemToScrollTo && listItemRef.current && viewModel && !scrolledToRef) {
      listItemRef.current.scrollIntoView({ behavior: 'smooth' })
      const indexOfListItemRef = viewModel.accounts.findIndex(
        (account) => account.key === itemToScrollTo
      )
      if (indexOfListItemRef !== -1) {
        onAccordionClick(indexOfListItemRef)
      }
      setScrolledToRef(true)
    }
  }, [scrolledToRef, itemToScrollTo, viewModel, onAccordionClick])

  return (
    <Accordion>
      {viewModel.accounts.map((account, i) => {
        return (
          <GeneralLedgerRow
            account={account}
            active={openAccordionIndexes?.has(i)}
            index={i}
            key={account.key}
            onRowClick={onAccordionClick}
            forwardedRef={account.key === itemToScrollTo ? listItemRef : null}
          />
        )
      })}
    </Accordion>
  )
}

export default GeneralLedger
