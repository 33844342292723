import { Link, useNavigate, useParams } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Button,
  GridRowColumn,
  Icon,
  Text,
} from '../../../../../components/BaseComponents'
import { CalcBreakdown } from './CalculationOutputs'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectTaxCalculationByTaxQuarter } from '../../../../../selectors/userTaxCalculation.selectors'

const EstimateBreakdownV2 = () => {
  const { taxQuarter } = useParams()
  const navigate = useNavigate()
  const taxCalc = useReselector(selectTaxCalculationByTaxQuarter, taxQuarter)

  if (!taxCalc) {
    return null
  }

  return (
    <Grid>
      <GridRowColumn>
        <Link to="/taxes/quarterly">
          <Icon icon={regular('arrow-left')} style={{ marginRight: 8 }} />
          Back to Quarterly Taxes
        </Link>
      </GridRowColumn>
      <GridRowColumn>
        <Text as="h1">How were your quarterly tax estimates calculated?</Text>
      </GridRowColumn>
      <GridRowColumn>
        <CalcBreakdown calcId={taxCalc.id} />
      </GridRowColumn>
      <GridRowColumn spacer={6} width={4}>
        <Button
          variant="secondary"
          onClick={() => navigate('/taxes/quarterly')}
          fullWidth
        >
          Back to Quarterly Taxes
        </Button>
      </GridRowColumn>
      <Grid.Row />
    </Grid>
  )
}

export default EstimateBreakdownV2
