import { useMemo } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import TaxCenterPromptCard, {
  PromptCardStatus,
  TaxPromptCardButton,
  TaxPromptCardInfo,
} from '../components/TaxCenterPromptCard'
import {
  GridRowColumn,
  Icon,
  Text,
} from '../../../../components/BaseComponents'
import { AnnualTaxFilingForm } from '../annualTaxFilingForms.slice'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  select1120TQStatusByYear,
  selectShowFileExtensionForYear,
} from '../annualTaxFilings.selector'
import { AnnualTaxFilingStepStatus } from '../constants'
import { selectTaxDetailsByYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { isoToUTCDateTime } from '../../../../utils/dateHelpers'
import { select1120sFormForYear } from '../annualTaxFilingForms.selector'
import { FilingStatusByForm } from '../Overview/DetailedFormStatusItem'

const TaxChecklistActionCard = ({
  year,
  status,
}: {
  year: string
  status: PromptCardStatus
}) => {
  const taxDetails = useReselector(selectTaxDetailsByYear, year)
  const filingForm = useReselector(select1120sFormForYear, year)
  const hasExtension = useReselector(selectShowFileExtensionForYear, year)

  const getDueDate = useMemo(() => {
    if (status !== PromptCardStatus.COMING_SOON && taxDetails) {
      if (hasExtension) {
        return isoToUTCDateTime(
          taxDetails.irsFormDueDates.form_1120_s.internal.extendedDueDate
        )
      }
      return isoToUTCDateTime(
        taxDetails.irsFormDueDates.form_1120_s.internal.dueDate
      )
    }
    return undefined
  }, [taxDetails, hasExtension, status])

  return (
    <Grid>
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/businessEvents.svg"
          style={{ width: 363, height: 190 }}
        />
      </GridRowColumn>
      <GridRowColumn spacer={3} width={10}>
        <Text textAlign="center">
          You’ll confirm 2023 income and expenses, review your business
          information and upload tax documents. Your tax preparer will use this
          information to complete your business tax return.
        </Text>
      </GridRowColumn>
      <TaxPromptCardInfo
        timeToComplete="30 minutes - 1 hour"
        docInfo={`${year} tax documents you have on hand`}
        dueDate={getDueDate}
        startDate={
          !hasExtension &&
          status === PromptCardStatus.COMING_SOON &&
          taxDetails?.taxQuestionnaireDueDates?.form_1120_s.startAt
            ? isoToUTCDateTime(
                taxDetails?.taxQuestionnaireDueDates?.form_1120_s.startAt
              )
            : undefined
        }
        extraListItem={
          hasExtension && (
            <Text>
              <Text as="bodySm">
                <Icon
                  icon={regular('hourglass-clock')}
                  style={{ marginRight: 15 }}
                  size="1x"
                />
                Available when extension request is complete
              </Text>
            </Text>
          )
        }
      />
      <TaxPromptCardButton
        status={status}
        actionLink={`/taxes/annual/tax_checklist/${filingForm?.id}`}
      />
    </Grid>
  )
}

const TaxChecklistPromptCard1120s = ({
  filingForm,
}: {
  filingForm: AnnualTaxFilingForm
}) => {
  const { year } = filingForm
  const formStatus = useReselector(select1120TQStatusByYear, year)

  const status = useMemo(() => {
    switch (formStatus) {
      case AnnualTaxFilingStepStatus.inProgress:
        return PromptCardStatus.IN_PROGRESS
      case AnnualTaxFilingStepStatus.completeLocked:
        return PromptCardStatus.DONE
      case AnnualTaxFilingStepStatus.notStarted:
        return PromptCardStatus.ACTION_REQUIRED
      case AnnualTaxFilingStepStatus.upcoming:
      case AnnualTaxFilingStepStatus.comingSoon:
        return PromptCardStatus.COMING_SOON
      default:
        return formStatus satisfies never
    }
  }, [formStatus])

  if (![PromptCardStatus.DONE, PromptCardStatus.IN_PROGRESS].includes(status)) {
    return (
      <TaxCenterPromptCard
        body={{
          [PromptCardStatus.COMING_SOON]: (
            <TaxChecklistActionCard year={year} status={status} />
          ),
          [PromptCardStatus.ACTION_REQUIRED]: (
            <TaxChecklistActionCard year={year} status={status} />
          ),
          [PromptCardStatus.IN_PROGRESS]: null,
          [PromptCardStatus.DONE]: null,
        }}
        icon={regular('seal-question')}
        status={status}
        title="Business Tax Checklist (Form 1120-S)"
      />
    )
  }

  return <FilingStatusByForm filingForm={filingForm} />
}

export default TaxChecklistPromptCard1120s
