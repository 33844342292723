import { useFlag } from '@openfeature/react-sdk'
import Markdown from 'markdown-to-jsx'

import { Alert } from '../BaseComponents'
import { FEATURE_FLAG_KEYS } from '../../features/OpenFeature'
import { Colors } from '../../styles/theme'

const useMaintenanceBanner = () => {
  const maintenanceMessage = useFlag(FEATURE_FLAG_KEYS.maintenanceMessage, {
    message: null,
  })

  if (!maintenanceMessage.value.message) {
    return null
  }
  return (
    <Alert style={{ borderRadius: 0 }} type="announcement">
      <Markdown>{maintenanceMessage.value.message}</Markdown>
    </Alert>
  )
}

const MaintenanceBanner = () => {
  const banner = useMaintenanceBanner()
  return banner
}

export const MaintenanceBannerWithBorder = () => {
  const message = useMaintenanceBanner()
  if (!message) {
    return null
  }

  return (
    <div style={{ border: `3px solid ${Colors.black}` }}>
      <MaintenanceBanner />
    </div>
  )
}

export default MaintenanceBanner
