import AhaMomentsBaseComponent from '../AhaMomentsBaseComponent'
import { Text } from '../../../../components/BaseComponents'

const HowToClarifyTransactions = ({
  handleContinue,
  handleBack,
}: {
  handleContinue: () => void
  handleBack: () => void
}) => {
  return (
    <AhaMomentsBaseComponent
      title="How bookkeeping works"
      subtitle="How to clarify transactions"
      description={
        <>
          Sometimes, a transaction can have an unclear description or purpose.
          This is when we need your input to <b>clarify</b> the transaction.
        </>
      }
      media={
        <iframe
          src="https://www.loom.com/embed/a8860aa90adb41708ba88c4b874e34c3"
          allow="autoplay; fullscreen"
          allowFullScreen
          width="100%"
          height="300px"
          title="How to clarify transactions"
          sandbox="allow-scripts allow-same-origin allow-popups"
        />
      }
      features={[
        {
          imgSrc:
            'https://heard-images.s3.amazonaws.com/assets/coffee_checkmark.png',
          title: 'Identify personal transactions',
          description:
            "Assign these as “Personal” so they don't affect your business numbers.",
        },
        {
          imgSrc:
            'https://heard-images.s3.amazonaws.com/assets/notepad_pen.png',
          title: 'Provide context for unclear transactions',
          description:
            "Add a note to let us know what a transaction was for. We'll select the appropriate category.",
        },
      ]}
      learnMoreItems={[
        {
          title: "What if I don't know which category to choose?",
          content: (
            <Text>
              Don&apos;t worry! Leave a note explaining what the transaction was
              used for. Our bookkeeping team will then select the appropriate
              category.
            </Text>
          ),
        },
        {
          title: 'Why is it important to identify personal transactions?',
          content: (
            <Text>
              Your business and personal transactions should be kept separate
              for tax and liability reasons. It also helps streamline
              bookkeeping and provide accurate business records.
            </Text>
          ),
        },
        {
          title: 'What happens to personal transactions?',
          content: (
            <Text>
              Personal transactions are removed from your numbers and will no
              longer affect your business statements, reports, or insights.
            </Text>
          ),
        },
      ]}
      backButtonText="Back"
      continueButtonText="Continue"
      handleContinue={handleContinue}
      handleBack={handleBack}
    />
  )
}

export default HowToClarifyTransactions
