import { useCallback, useEffect, useState } from 'react'
import { Grid, Icon, Divider } from 'semantic-ui-react'

import {
  fetchAllPayrolls,
  fetchAllPaySchedules,
  fetchContractors,
  fetchEmployees,
  fetchPayrollWcEnrollmentRequirements,
} from '../payrollActions'
import { getFrequencyText } from '../helpers'
import {
  selectNetPayroll,
  selectNumberOfTeamMembers,
  selectPayrolls,
  selectPaySchedule,
} from '../payroll.selectors'
import AutoEnrollModal from '../RunPayroll/AutoEnrollModal'
import { formatCurrency } from '../../../utils/currencyHelpers'
import {
  Button,
  Text,
  Link,
  Alert,
  Modal,
  GridRowColumn,
} from '../../../components/BaseComponents'
import PayrollScheduleForm from '../Enroll/PayrollScheduleForm'
import { NextWcEnrollmentPanel } from './WorkersCompensationTab'
import { WcEnrollmentRequirement } from '../payrollWcEnrollmentRequirement.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { Gusto_PaySchedule } from '../generated_gusto_types'
import { clearPayrolls } from '../payroll.slice'
import { useReselector } from '../../../utils/sharedHooks'
import PaymentConfigSettings from './PaymentConfigSettings'
const WC_FEATURE_FLAG = process.env.VITE_WORKERS_COMP_ENABLED

const PaySchedulePanel = ({
  paySchedule,
  openModal,
}: {
  paySchedule?: Gusto_PaySchedule | null
  openModal: () => void
}) => {
  if (!paySchedule) {
    return null
  }

  const autoText = paySchedule.auto_pilot ? 'On' : 'Off'
  return (
    <Grid className="bordered-grid green-top">
      <GridRowColumn short>
        <Text as="h3">Pay Schedule</Text>
      </GridRowColumn>
      <Grid.Row>
        <Grid.Column width={7}>
          <Text>
            <Icon name="calendar outline" />
            <b>Pay Frequency</b>
          </Text>
        </Grid.Column>
        <Grid.Column width={9}>
          <Text>
            Need to change this?{' '}
            <Link to="/conversations">Contact Support</Link>
          </Text>
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn short>
        <Text style={{ paddingLeft: 23 }}>{getFrequencyText(paySchedule)}</Text>
      </GridRowColumn>
      <Divider />
      <Grid.Row>
        <Grid.Column width={14}>
          <Text>
            <Icon name="sync" />
            Automatic Payroll:{' '}
          </Text>
        </Grid.Column>
        <Grid.Column width={2}>
          <Button onClick={openModal} variant="link">
            Edit
          </Button>
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn>
        <Text style={{ paddingLeft: 23 }}>{autoText}</Text>
      </GridRowColumn>
      <Divider />
      <GridRowColumn>
        <PaymentConfigSettings />
      </GridRowColumn>
    </Grid>
  )
}

const PayrollDetailsTab = () => {
  const dispatch = useAppDispatch()
  const [fetched, setFetched] = useState(false)
  const { netPaidToEmployees, netPayrollTaxes } =
    useReselector(selectNetPayroll)
  const numberOfTeamMembers = useReselector(selectNumberOfTeamMembers)
  const payrolls = useReselector(selectPayrolls)
  const [autoEnrollModalOpen, setAutoEnrollModalOpen] = useState(false)
  const paySchedule = useReselector(selectPaySchedule)
  const [payrollScheduleModalOpen, setPayrollScheduleModalOpen] =
    useState(false)
  const [enrollmentRequirements, setEnrollmentRequirements] =
    useState<WcEnrollmentRequirement>()

  useEffect(() => {
    const fetch = async () => {
      const [requirementsRes] = await Promise.all([
        fetchPayrollWcEnrollmentRequirements()(dispatch),
        fetchEmployees()(dispatch),
        fetchContractors()(dispatch),
        fetchAllPayrolls({ include: ['totals'] })(dispatch),
        fetchAllPaySchedules()(dispatch),
      ])

      if (requirementsRes) {
        setEnrollmentRequirements(requirementsRes)
      }
      setFetched(true)
    }

    fetch()
  }, [dispatch])

  const onPayrollScheduleSet = useCallback(async () => {
    setPayrollScheduleModalOpen(false)

    setFetched(false)
    await fetchAllPaySchedules()(dispatch)
    setFetched(true)
  }, [dispatch])

  const onClose = useCallback(() => {
    setAutoEnrollModalOpen(false)
    // Changing the pay schedule will invalidate upcoming payrolls - the previous ones need to be wiped and refreshed
    dispatch(clearPayrolls())
    dispatch(fetchAllPayrolls({ include: ['totals'] }))
  }, [dispatch])

  const notEnrolledInAuto = Boolean(paySchedule && !paySchedule.auto_pilot)

  return (
    <Grid>
      <Grid.Row />
      {WC_FEATURE_FLAG && fetched && enrollmentRequirements && (
        <NextWcEnrollmentPanel
          enrollmentRequirements={enrollmentRequirements}
          suppressIfEnrolled
        />
      )}
      {paySchedule && (
        <AutoEnrollModal
          modalOpen={autoEnrollModalOpen}
          onClose={onClose}
          paySchedule={paySchedule}
        />
      )}

      {fetched && !paySchedule && (
        <Alert type="error">
          It looks like you don&apos;t have a payroll schedule{' '}
          <Button
            variant="link"
            onClick={() => setPayrollScheduleModalOpen(true)}
          >
            Click to set
          </Button>
          <Modal
            closeIcon
            onClose={() => setPayrollScheduleModalOpen(false)}
            open={payrollScheduleModalOpen}
          >
            <Modal.Header>Set Payroll Schedule</Modal.Header>
            <Modal.Content>
              <PayrollScheduleForm
                onSuccess={onPayrollScheduleSet}
                onCancel={() => setPayrollScheduleModalOpen(false)}
                saveText="Save"
              />
            </Modal.Content>
          </Modal>
        </Alert>
      )}

      {notEnrolledInAuto && (
        <Grid.Row>
          <Alert type="info" style={{ width: '100%' }}>
            <Grid.Column width={16}>
              <Text>
                It looks like you haven’t set up Automatic Payroll! We recommend
                setting it up for your ease of use.
              </Text>
              <Button
                variant="link"
                onClick={() => setAutoEnrollModalOpen(true)}
              >
                Set up now
              </Button>
            </Grid.Column>
          </Alert>
        </Grid.Row>
      )}
      <Grid.Row />
      <Grid.Row>
        <Grid.Column width={8}>
          <PaySchedulePanel
            paySchedule={paySchedule}
            openModal={() => setAutoEnrollModalOpen(true)}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          {fetched && (
            <Grid className="bordered-grid">
              <GridRowColumn short>
                <Text as="h3">Your Payroll Summary</Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text>Take a look at some quick stats about your Payroll!</Text>
              </GridRowColumn>
              <Divider />
              <Grid.Row className="short">
                <Grid.Column width={11}>
                  <Text>
                    <b>Number of Payrolls Run</b>
                  </Text>
                </Grid.Column>
                <Grid.Column textAlign="right" width={5}>
                  <Text>{Object.values(payrolls).length} total</Text>
                </Grid.Column>
              </Grid.Row>
              <Divider className="short" />
              <Grid.Row>
                <Grid.Column width={11}>
                  <Text>
                    <b>Total number of Team Members</b>
                  </Text>
                </Grid.Column>
                <Grid.Column textAlign="right" width={5}>
                  <Text>{numberOfTeamMembers}</Text>
                </Grid.Column>
              </Grid.Row>
              <Divider className="short" />
              <Grid.Row>
                <Grid.Column width={11}>
                  <Text>
                    <b>Total net paid to Employees</b>
                  </Text>
                </Grid.Column>
                <Grid.Column textAlign="right" width={5}>
                  <Text>{formatCurrency(netPaidToEmployees)}</Text>
                </Grid.Column>
              </Grid.Row>
              <Divider className="short" />
              <Grid.Row>
                <Grid.Column width={11}>
                  <Text>
                    <b>Total paid in Payroll Taxes</b>
                  </Text>
                </Grid.Column>
                <Grid.Column textAlign="right" width={5}>
                  <Text>{formatCurrency(netPayrollTaxes)}</Text>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </Grid.Column>
      </Grid.Row>

      <Grid.Row />
      <Grid.Row />
      <Grid.Row />
      <Grid.Row />
    </Grid>
  )
}

export default PayrollDetailsTab
