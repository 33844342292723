import { useCallback, useEffect, useState } from 'react'

// BL
import { DocumentTabPane } from '.'
import { receipts as copy } from '../copyConstants'

// UI
import { DocumentSearchBarOnChangeData } from '../sharedComponents/DocumentsSearchBar'
import TabPaneToolbar from '../sharedComponents/TabPaneToolbar'
import UserDocumentsTable from '../sharedComponents/Table/UserDocumentsTable'
import { ReceiptRow } from '../sharedComponents/Table/UserDocumentsTableRow'

const Receipts: DocumentTabPane = ({ documents, tableOptions }) => {
  const [filteredDocuments, setFilteredDocuments] = useState(documents)

  useEffect(() => {
    setFilteredDocuments(documents)
  }, [documents])

  const onSearch = useCallback((data: DocumentSearchBarOnChangeData) => {
    setFilteredDocuments(data.filtered)
  }, [])

  return (
    <>
      <TabPaneToolbar
        preamble="Heard doesn't require receipts, but you may use this section for your own records. We recommend keeping track of any receipts over $75 in value."
        documents={documents}
        onSearch={onSearch}
        rightElement={null}
      />
      <UserDocumentsTable
        columns={copy.static.tableColumns}
        documents={filteredDocuments}
        RowComponent={ReceiptRow}
        tableOptions={tableOptions}
      />
    </>
  )
}

Receipts.paneDescription = copy.static.paneDescription
Receipts.paneTitle = copy.static.paneTitle

export default Receipts
