import { orderBy } from 'lodash'
import { Reconciliation } from '../../../reducers/admin/financialAccountsReducer'
import { BookkeepingReport } from '../../../reducers/finances/bookkeepingReportsReducer'

const sortByEndDateDesc = (reconciliations: Reconciliation[]) =>
  orderBy(reconciliations, 'endingBalanceDate', 'desc')

export const findCurrentReconciliation = (
  bookkeepingReport: BookkeepingReport | undefined,
  accountReconciliations: Reconciliation[]
) => {
  if (!bookkeepingReport) return null

  return (
    sortByEndDateDesc(accountReconciliations).find(
      (recon) => recon.bookkeepingReportId === bookkeepingReport.id
    ) ?? null
  )
}

export const findPreviousReconciliation = (
  bookkeepingReport: BookkeepingReport | undefined,
  accountReconciliations: Reconciliation[]
) => {
  if (!bookkeepingReport) return null

  const previousRecons = sortByEndDateDesc(accountReconciliations).filter(
    (recon) => recon.endingBalanceDate < bookkeepingReport.startDate
  )

  return previousRecons.length > 0 ? previousRecons[0] : null
}

export const findNextReconciliation = (
  bookkeepingReport: BookkeepingReport | undefined,
  accountReconciliations: Reconciliation[]
) => {
  if (!bookkeepingReport) return null

  const nextRecons = sortByEndDateDesc(accountReconciliations).filter(
    (recon) => recon.endingBalanceDate > bookkeepingReport.endDate
  )

  // Reconciliations are sorted by date in descending order
  return nextRecons.length > 0 ? nextRecons[nextRecons.length - 1] : null
}

export const getRecoverabilityMetadata = (
  bookkeepingReport: BookkeepingReport | undefined,
  accountReconciliations: Reconciliation[]
) => {
  const reconciliation = findCurrentReconciliation(
    bookkeepingReport,
    accountReconciliations
  )
  const previousReconciliation = findPreviousReconciliation(
    bookkeepingReport,
    accountReconciliations
  )

  let recoverable = false
  let recovering = false
  if (
    reconciliation?.type === 'auto' &&
    reconciliation.status === 'incomplete'
  ) {
    recoverable =
      previousReconciliation?.type === 'auto' &&
      previousReconciliation?.status === 'complete'
  } else if (
    reconciliation?.type === 'manual' &&
    reconciliation.status === 'incomplete'
  ) {
    const nextReconciliation = findNextReconciliation(
      bookkeepingReport,
      accountReconciliations
    )
    recovering = nextReconciliation?.status === 'blocked'
  }

  return {
    reconciliation,
    recoverable,
    recovering,
    previousReconciliation,
  }
}
