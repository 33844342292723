import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Image } from 'semantic-ui-react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  GridRowColumn,
  Text,
  TileSelector,
} from '../../../../../../components/BaseComponents'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { INCOME_LIABILITIES_SCREENS, IncomeAndLiabilitiesStepProps } from '.'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import { TaxListQuestionId } from '../../service'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
  selectTaxListQuestionResponsesByQuestionIds,
} from '../../taxChecklist.selectors'
import { NoneApply, useTiles } from '../../Shared/UseTiles'
import DocumentAddedAlert, {
  TeamReachOutAlert,
} from '../../Shared/SpecialtyAlerts'
import {
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  deleteTaxQuestionnaireResponses,
} from '../../taxChecklistQuestion.actions'
import { lessCommonScenarioFollowUpQuestionIds } from './LessCommonScenariosPanel'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { UPDATE_USER_EOY_REVIEW_PROGRESS_KEY } from '../../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'

export const lessCommonScenariosQuestionIds = [
  TaxListQuestionId.crypto_made_txns,
  TaxListQuestionId.k1_income,
  TaxListQuestionId.foreign_income_or_taxes,
  TaxListQuestionId.disability_income,
  TaxListQuestionId.gave_large_gifts,
]

const LessCommonScenariosIntroPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
}: IncomeAndLiabilitiesStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()

  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    UPDATE_USER_EOY_REVIEW_PROGRESS_KEY,
  ])

  const dispatch = useAppDispatch()

  const questionCryptoTxns = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.crypto_made_txns,
    taxYear
  )
  const cryptoTxnsResponses = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.crypto_made_txns,
    Number(formId)
  )
  const questionIncomeFromK1 = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.k1_income,
    taxYear
  )
  const incomeFromK1Responses = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.k1_income,
    Number(formId)
  )
  const questionForeignIncomeOrTaxes = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.foreign_income_or_taxes,
    taxYear
  )
  const foreignIncomeOrTaxesResponses = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.foreign_income_or_taxes,
    Number(formId)
  )
  const questionDisabilityIncome = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.disability_income,
    taxYear
  )
  const disabilityIncomeResponses = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.disability_income,
    Number(formId)
  )
  const questionGaveLargeGifts = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.gave_large_gifts,
    taxYear
  )
  const gaveLargeGiftsResponses = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.gave_large_gifts,
    Number(formId)
  )

  const followUpResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    lessCommonScenarioFollowUpQuestionIds.filter(
      (qId) =>
        qId !== TaxListQuestionId.number_of_crypto_statements &&
        qId !== TaxListQuestionId.number_of_k1s_sprop
    ),
    Number(formId)
  )

  const numberCryptoStatementsResponses = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_of_crypto_statements,
    Number(formId)
  )
  const numberK1sResponses = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_of_k1s_sprop,
    Number(formId)
  )

  const {
    tiles: selections,
    setTiles,
    noneApply,
    tileColumnStyle,
    tileClicked,
    tileWidth,
    tilesToResponses,
    anyResponseSelected,
  } = useTiles()

  const tileHeight = 180

  useEffect(() => {
    setTiles([
      {
        icon: light('bitcoin-sign'),
        text: questionCryptoTxns?.question?.text ?? '',
        subtext: '(cryptocurrency)',
        questionId: TaxListQuestionId.crypto_made_txns,
        response: cryptoTxnsResponses?.[0],
      },
      {
        icon: light('envelope-open-dollar'),
        text: questionIncomeFromK1?.question?.text ?? '',
        popup: {
          title: 'Received income from K-1s',
          body: (
            <Grid>
              <GridRowColumn>
                <Text>
                  This applies to you if you have a partnership or a corporation
                  outside of Heard, or if you’re a shareholder in any business
                  outside of your therapy practice.
                </Text>
              </GridRowColumn>
            </Grid>
          ),
        },
        subtext: '(Partnerships, S corps, Trusts)',
        questionId: TaxListQuestionId.k1_income,
        response: incomeFromK1Responses?.[0],
      },
      {
        icon: light('globe'),
        text: questionForeignIncomeOrTaxes?.question?.text ?? '',
        questionId: TaxListQuestionId.foreign_income_or_taxes,
        response: foreignIncomeOrTaxesResponses?.[0],
      },
      {
        icon: light('wheelchair'),
        text: questionDisabilityIncome?.question?.text ?? '',
        questionId: TaxListQuestionId.disability_income,
        response: disabilityIncomeResponses?.[0],
      },
      {
        icon: light('gifts'),
        text: questionGaveLargeGifts?.question?.text ?? '',
        questionId: TaxListQuestionId.gave_large_gifts,
        response: gaveLargeGiftsResponses?.[0],
      },
    ])
  }, [
    cryptoTxnsResponses,
    incomeFromK1Responses,
    setTiles,
    foreignIncomeOrTaxesResponses,
    disabilityIncomeResponses,
    gaveLargeGiftsResponses,
    questionCryptoTxns,
    questionIncomeFromK1,
    questionForeignIncomeOrTaxes,
    questionDisabilityIncome,
    questionGaveLargeGifts,
  ])

  const saveAndContinue = useAsyncCallback(async () => {
    const responseData: Partial<TaxChecklistResponse>[] = []
    // if screen conditions change, make sure to also update in IncomeAndLiabilities/index
    const didCryptoTxns =
      selections.find(
        (s) => s.questionId === TaxListQuestionId.crypto_made_txns
      )?.response?.value === true

    const hasIncomeFromK1 =
      selections.find((s) => s.questionId === TaxListQuestionId.k1_income)
        ?.response?.value === true

    let deletionSucceeded = true
    const idsToDelete: number[] = []

    if (!didCryptoTxns) {
      idsToDelete.push(
        followUpResponses.find(
          (r) => r.questionId === TaxListQuestionId.crypto_sold
        )?.id ?? 0,
        followUpResponses.find(
          (r) => r.questionId === TaxListQuestionId.crypto_statement
        )?.id ?? 0
      )
      if (numberCryptoStatementsResponses?.[0]?.value !== 0) {
        responseData.push({
          id: numberCryptoStatementsResponses?.[0]?.id,
          value: 0,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.number_of_crypto_statements,
        })
      }
    }
    if (!hasIncomeFromK1 && numberK1sResponses?.[0]?.value !== 0) {
      responseData.push({
        id: numberK1sResponses?.[0]?.id,
        value: 0,
        annualTaxFilingFormId: Number(formId),
        questionId: TaxListQuestionId.number_of_k1s_sprop,
      })
    }
    if (idsToDelete.length > 0) {
      deletionSucceeded = Boolean(
        await dispatch(deleteTaxQuestionnaireResponses(idsToDelete))
      )
    }

    if (didCryptoTxns || hasIncomeFromK1) {
      if (deletionSucceeded) {
        await goToNextStep(
          tilesToResponses(),
          INCOME_LIABILITIES_SCREENS.lessCommonScenarios ?? null
        )
      }
    } else {
      if (deletionSucceeded) {
        await goToNextStep(
          [...tilesToResponses(), ...responseData],
          INCOME_LIABILITIES_SCREENS.review ?? null,
          { completedSteps: [SubStepIdentifiers.lessCommonScenarios1040] }
        )
      }
    }
  })

  const hasDisabilityIncome =
    selections.find((s) => s.questionId === TaxListQuestionId.disability_income)
      ?.response?.value === true
  const gaveLargeGifts =
    selections.find((s) => s.questionId === TaxListQuestionId.gave_large_gifts)
      ?.response?.value === true
  const hasForeignIncomeOrTaxes =
    selections.find(
      (s) => s.questionId === TaxListQuestionId.foreign_income_or_taxes
    )?.response?.value === true

  const gridConfig = makeGridConfig([8, 12], true)
  return (
    <Grid>
      <GridRowColumn
        columnStyle={{ display: 'flex', justifyContent: 'center' }}
      >
        <Image src="https://heard-images.s3.amazonaws.com/assets/coins.svg" />
      </GridRowColumn>
      <GridRowColumn>
        <Text as="display2" textAlign="center">
          Less Common Scenarios
        </Text>
      </GridRowColumn>
      <GridRowColumn {...gridConfig}>
        <Text as="bodyLg" textAlign="center">
          We know the following are less common, but we want to cover all the
          bases. Let us know if any of these scenarios applied to you in{' '}
          {taxYear}.
        </Text>
      </GridRowColumn>
      <GridRowColumn
        {...makeGridConfig([8, 12], true)}
        columnStyle={{ ...tileColumnStyle, flexWrap: 'wrap' }}
      >
        {selections.map((s) => (
          <TileSelector
            key={s.questionId}
            icon={s.icon}
            active={Boolean(s.response?.value)}
            text={s.text}
            width={tileWidth}
            height={tileHeight}
            onClick={() => tileClicked(s.questionId)}
            popup={s.popup}
            subtext={s.subtext}
          />
        ))}
      </GridRowColumn>
      <GridRowColumn short columnStyle={tileColumnStyle}>
        <NoneApply
          width={noneApply.width}
          selected={noneApply.selected}
          setSelection={noneApply.clearTiles}
        />
      </GridRowColumn>
      {anyResponseSelected && (
        <GridRowColumn short {...gridConfig}>
          <DocumentAddedAlert>
            When you see the “add file” icon, we&apos;ve added a form
            requirement to your Upload Documents checklist. This list is at the
            end of the questionnaire, which is when you&apos;ll upload your
            documents.
          </DocumentAddedAlert>
        </GridRowColumn>
      )}
      {hasDisabilityIncome && (
        <GridRowColumn short {...gridConfig}>
          <DocumentAddedAlert>
            Form 1099-SSA for disability income added to checklist
          </DocumentAddedAlert>
        </GridRowColumn>
      )}
      {gaveLargeGifts && (
        <GridRowColumn short {...gridConfig}>
          <TeamReachOutAlert>
            Your tax preparer will follow up later regarding a gift tax return.
          </TeamReachOutAlert>
        </GridRowColumn>
      )}
      {hasForeignIncomeOrTaxes && (
        <GridRowColumn short {...gridConfig}>
          <TeamReachOutAlert>
            Your tax preparer will reach out to you to gather more information
            regarding foreign income or tax payments.
          </TeamReachOutAlert>
        </GridRowColumn>
      )}
      <Grid.Row />
      <FormFlowFooter
        continueDisabled={
          (!noneApply.selected && !anyResponseSelected) ||
          saveAndContinue.loading
        }
        onBack={() => goBack(previousScreen ?? null)}
        onForward={saveAndContinue.callback}
        loading={isUpdating || saveAndContinue.loading}
      />
    </Grid>
  )
}

export default LessCommonScenariosIntroPanel
