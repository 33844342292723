import axios from 'axios'
import { keyBy } from 'lodash'

import {
  receiveAllRoles,
  Role,
  selectAllRoles,
} from '../../reducers/admin/allRolesReducer'
import { fetchIfNeededWrapper } from '../../reducers/fetch'

export const FETCH_ALL_ROLES_KEY = 'FETCH_ALL_ROLES_KEY'

export const fetchAllRolesIfNeeded = () =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_ALL_ROLES_KEY,
    defaultErrorMessage: 'Error fetching all roles',
    defaultValueSelector: selectAllRoles,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<Role[]>('/finances/api/v1/admin/roles')
      const roles = keyBy(json.data, 'id')
      dispatch(receiveAllRoles(roles))

      return roles
    },
  })
