import { Divider, Grid } from 'semantic-ui-react'
import {
  Alert,
  FormikLabelError,
  FormikRadioToggleButton,
  GridRowColumn,
  Popup,
  Text,
  getFieldName,
  makeReqBoolSchema,
} from '../../../../../../components/BaseComponents'
import { FormikProvider, useFormik } from 'formik'
import { useReselector } from '../../../../../../utils/sharedHooks'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../../taxChecklist.selectors'
import { TaxListQuestionId } from '../../service'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { useParams } from 'react-router-dom'
import { LifeChangesStepProps } from '.'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import DocumentAddedAlert from '../../Shared/SpecialtyAlerts'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { UPDATE_USER_EOY_REVIEW_PROGRESS_KEY } from '../../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { UPSERT_USER_TAX_QUESTIONNAIRE_KEY } from '../../taxChecklistQuestion.actions'

export const miscQuestionIds = [
  TaxListQuestionId.audited_last_year,
  TaxListQuestionId.offer_in_compromise,
  TaxListQuestionId.irs_installment_plan,
  TaxListQuestionId.debt_cancellation,
  TaxListQuestionId.paid_house_employee,
]

const MiscQuestionsPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: LifeChangesStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()
  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    UPDATE_USER_EOY_REVIEW_PROGRESS_KEY,
  ])

  const questionAudited = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.audited_last_year,
    taxYear
  )
  const responseAudited = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.audited_last_year,
    Number(formId)
  )

  const questionOfferInCompromise = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.offer_in_compromise,
    taxYear
  )
  const responseOfferInCompromise = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.offer_in_compromise,
    Number(formId)
  )

  const questionInstallmentPlan = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.irs_installment_plan,
    taxYear
  )
  const responseInstallmentPlan = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.irs_installment_plan,
    Number(formId)
  )

  const questionDebtCancellation = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.debt_cancellation,
    taxYear
  )
  const responseDebtCancellation = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.debt_cancellation,
    Number(formId)
  )
  const questionPaidHouseEmployee = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.paid_house_employee,
    taxYear
  )
  const responsePaidHouseEmployee = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.paid_house_employee,
    Number(formId)
  )

  const formik = useFormik({
    initialValues: {
      audited: responseAudited?.[0]?.value,
      offerInCompromise: responseOfferInCompromise?.[0]?.value,
      installmentPlan: responseInstallmentPlan?.[0]?.value,
      debtCancellation: responseDebtCancellation?.[0]?.value,
      paidHouseEmployee: responsePaidHouseEmployee?.[0]?.value,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      if (values.audited !== responseAudited?.[0]?.value) {
        responseData.push({
          id: responseAudited?.[0]?.id,
          value: values.audited,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.audited_last_year,
        })
      }
      if (values.offerInCompromise !== responseOfferInCompromise?.[0]?.value) {
        responseData.push({
          id: responseOfferInCompromise?.[0]?.id,
          value: values.offerInCompromise,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.offer_in_compromise,
        })
      }
      if (values.installmentPlan !== responseInstallmentPlan?.[0]?.value) {
        responseData.push({
          id: responseInstallmentPlan?.[0]?.id,
          value: values.installmentPlan,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.irs_installment_plan,
        })
      }
      if (values.debtCancellation !== responseDebtCancellation?.[0]?.value) {
        responseData.push({
          id: responseDebtCancellation?.[0]?.id,
          value: values.debtCancellation,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.debt_cancellation,
        })
      }
      if (values.paidHouseEmployee !== responsePaidHouseEmployee?.[0]?.value) {
        responseData.push({
          id: responsePaidHouseEmployee?.[0]?.id,
          value: values.paidHouseEmployee,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.paid_house_employee,
        })
      }

      await goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.miscellaneousQuestions],
      })
    },
  })

  const { values, isValid, isSubmitting, submitForm } = formik

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn>
          <Text as="display2" textAlign="center">
            Miscellaneous Questions
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <Text as="bodyLg" textAlign="center">
            We just have a few more questions to ask about your year.
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikLabelError
            name={getFieldName('audited')}
            label={questionAudited.question?.text}
            schema={makeReqBoolSchema()}
          />
        </GridRowColumn>
        <Grid.Row className="short" style={{ justifyContent: 'center' }}>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName('audited')}
              value
            >
              Yes
            </FormikRadioToggleButton>
          </Grid.Column>
          <Grid.Column width={3}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName('audited')}
              value={false}
            >
              No
            </FormikRadioToggleButton>
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <Divider />
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikLabelError
            name={getFieldName('offerInCompromise')}
            label={questionOfferInCompromise.question?.text}
            schema={makeReqBoolSchema()}
          />
        </GridRowColumn>
        <Grid.Row className="short" style={{ justifyContent: 'center' }}>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName('offerInCompromise')}
              value
            >
              Yes
            </FormikRadioToggleButton>
          </Grid.Column>
          <Grid.Column width={3}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName('offerInCompromise')}
              value={false}
            >
              No
            </FormikRadioToggleButton>
          </Grid.Column>
        </Grid.Row>
        {values.offerInCompromise && (
          <GridRowColumn short {...makeGridConfig([6, 14], true)}>
            <DocumentAddedAlert>
              Documentation on the details of the Offer in Compromise added to
              checklist
            </DocumentAddedAlert>
          </GridRowColumn>
        )}
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <Divider />
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikLabelError
            name={getFieldName('installmentPlan')}
            label={questionInstallmentPlan.question?.text}
            schema={makeReqBoolSchema()}
          />
        </GridRowColumn>
        <Grid.Row className="short" style={{ justifyContent: 'center' }}>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName('installmentPlan')}
              value
            >
              Yes
            </FormikRadioToggleButton>
          </Grid.Column>
          <Grid.Column width={3}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName('installmentPlan')}
              value={false}
            >
              No
            </FormikRadioToggleButton>
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <Divider />
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikLabelError
            name={getFieldName('debtCancellation')}
            label={questionDebtCancellation.question?.text}
            schema={makeReqBoolSchema()}
          />
        </GridRowColumn>
        <Grid.Row className="short" style={{ justifyContent: 'center' }}>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName('debtCancellation')}
              value
            >
              Yes
            </FormikRadioToggleButton>
          </Grid.Column>
          <Grid.Column width={3}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName('debtCancellation')}
              value={false}
            >
              No
            </FormikRadioToggleButton>
          </Grid.Column>
        </Grid.Row>
        {values.debtCancellation && (
          <GridRowColumn short {...makeGridConfig([6, 14], true)}>
            <Alert type="info">
              Got it! Your tax preparer will follow up later regarding this
              matter.
            </Alert>
          </GridRowColumn>
        )}
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <Divider />
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikLabelError
            name={getFieldName('paidHouseEmployee')}
            label={questionPaidHouseEmployee.question?.text}
            schema={makeReqBoolSchema()}
            afterLabel={
              <Popup
                content={
                  <Grid>
                    <GridRowColumn>
                      <Text as="h3">Household Employees</Text>
                    </GridRowColumn>
                    <GridRowColumn short>
                      <Text>
                        Household employees include: housekeepers, maids,
                        babysitters, gardeners, and others who perform household
                        work in or around your private residence as your
                        employee.
                      </Text>
                    </GridRowColumn>
                    <GridRowColumn>
                      <Text>
                        Household employees do <b>NOT</b> include repairmen,
                        plumbers, contractors and other business people who
                        provide their services as independent contractors.
                      </Text>
                    </GridRowColumn>
                    <GridRowColumn>
                      <Text>
                        Household workers are your employees if you can control
                        not only the work they do, but also how they do it.
                      </Text>
                    </GridRowColumn>
                  </Grid>
                }
              />
            }
          />
        </GridRowColumn>
        <Grid.Row className="short" style={{ justifyContent: 'center' }}>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName('paidHouseEmployee')}
              value
            >
              Yes
            </FormikRadioToggleButton>
          </Grid.Column>
          <Grid.Column width={3}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName('paidHouseEmployee')}
              value={false}
            >
              No
            </FormikRadioToggleButton>
          </Grid.Column>
        </Grid.Row>
        {values.paidHouseEmployee && (
          <GridRowColumn short {...makeGridConfig([6, 14], true)}>
            <Alert type="info">
              Got it! Your tax preparer will follow up later regarding this
              matter.
            </Alert>
          </GridRowColumn>
        )}
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <Divider />
        </GridRowColumn>
        <Grid.Row />
        <FormFlowFooter
          continueDisabled={!isValid || isUpdating || isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isUpdating || isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default MiscQuestionsPanel
