import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Gusto_Payroll } from './generated_gusto_types'

export interface PayrollState {
  [key: string]: Gusto_Payroll
}

const payrollSlice = createSlice({
  name: 'payroll',
  initialState: {} as PayrollState,
  reducers: {
    setPayrolls: (
      state,
      action: PayloadAction<{ [p: string]: Gusto_Payroll }>
    ) => ({
      ...state,
      ...action.payload,
    }),
    setSinglePayroll: (state, action: PayloadAction<Gusto_Payroll>) => {
      state[action.payload.payroll_uuid] = action.payload
    },
    clearPayrolls: () => ({}),
  },
})

export const { setPayrolls, setSinglePayroll, clearPayrolls } =
  payrollSlice.actions

export default payrollSlice.reducer
