import { Component } from 'react'
import { connect } from 'react-redux'
import { Grid } from 'semantic-ui-react'

import {
  updateTransactionFilters,
  TransactionFiltersState,
} from '../../../reducers/finances/transactionFiltersReducer'
import { Dispatch, ReduxState } from '../../../utils/typeHelpers'
import { Pagination } from '../../BaseComponents'

type ParentProps = { scrollToRef?: () => void; transactionsCount: number }
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = ParentProps & StateProps & DispatchProps

class TransactionPagination extends Component<Props> {
  handlePageChange = (page: number) => {
    this.props.updateTransactionFilters({ page: page.toString() })
    this.props.scrollToRef?.()
  }

  render() {
    const pagesNeeded = Math.ceil(
      this.props.transactionsCount / Number(this.props.limit)
    )
    return (
      <Grid.Row>
        <Pagination
          currentPage={Number(this.props.transactionFilters.page)}
          onPageClick={this.handlePageChange}
          pages={pagesNeeded}
        />
      </Grid.Row>
    )
  }
}

const mapStateToProps = (state: ReduxState) => ({
  transactionFilters: state.transactionFilters,
  limit: state.transactionFilters.limit,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateTransactionFilters: (payload: Partial<TransactionFiltersState>) =>
    dispatch(updateTransactionFilters(payload)),
})

export default connect<StateProps, DispatchProps, ParentProps, ReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(TransactionPagination)
