import { useCallback } from 'react'

import {
  AnnualTaxFiling,
  createAnnualTaxFilings,
  submitAnnualTaxFiling,
  updateAnnualTaxFilings,
} from './annualTaxFilings.slice'
import { useReselector } from '../../../utils/sharedHooks'
import { getAnnualTaxFilingForYearSelector } from './annualTaxFilings.selector'
import { useAppDispatch } from '../../../utils/typeHelpers'

export const useSaveAnnualTaxFilingFormData = (year: string) => {
  const dispatch = useAppDispatch()
  const currentAnnualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    year
  )

  return useCallback(
    async (data: Partial<AnnualTaxFiling> & { message?: string }) => {
      // If we are submitting we don't want to update the survey, but we may need to call the create endpoint
      const shouldSubmit = Boolean(data.pretaxSurveySubmittedAt)
      let res
      if (currentAnnualTaxFiling?.id && !shouldSubmit) {
        res = await updateAnnualTaxFilings(
          currentAnnualTaxFiling.id,
          data
        )(dispatch)
      } else if (!currentAnnualTaxFiling?.id) {
        res = await createAnnualTaxFilings({ ...data, year })(dispatch)
      }

      const id = currentAnnualTaxFiling?.id || res?.id

      if (id && shouldSubmit) {
        res = await submitAnnualTaxFiling(id, data)(dispatch)
      }

      return res
    },
    [currentAnnualTaxFiling?.id, dispatch, year]
  )
}
