import { Grid } from 'semantic-ui-react'
import { useState, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useBooleanFlagValue } from '@openfeature/react-sdk'

// BL
import { fetchUserDocumentCategoriesIfNeeded } from '../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { FeatureStateProvider } from './state'

// UI
import DeleteDocumentConfirm from './sharedComponents/Modals/DeleteDocumentConfirm'
import './style.scss'
import { Tab, Alert, GridRowColumn } from '../../components/BaseComponents'
import {
  selectBookkeepingDocuments,
  selectIncorporationDocuments,
  selectOtherDocuments,
  selectReceiptDocuments,
  selectTaxDocuments,
  selectUserBankDocuments,
} from './userDocuments.selector'
import { useReselector } from '../../utils/sharedHooks'
import { createTabPanes } from './tabPanes'
import { fetchUserDocuments } from './userDocuments.slice'
import { useAnalyticsView } from '../Amplitude'
import PageHeader from '../../components/shared/PageHeader'
import { useAppDispatch } from '../../utils/typeHelpers'
import { FEATURE_FLAG_KEYS } from '../OpenFeature'

const DEFAULT_SELECTED_TAB_INDEX = 0

const TABS: { [key: string]: number } = {
  bank: 0,
  bookkeeping: 1,
  tax: 2,
  incorporation: 3,
  receipts: 4,
  other: 5,
}

const TAB_INDEXES: { [key: number]: string } = {
  0: 'bank',
  1: 'bookkeeping',
  2: 'tax',
  3: 'incorporation',
  4: 'receipts',
  5: 'other',
}

const UserDocuments = () => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const defaultTab = searchParams.get('tab')
  const [selectedTabPaneIndex, setSelectedTabPaneIndex] = useState(
    (defaultTab && TABS[defaultTab]) || DEFAULT_SELECTED_TAB_INDEX
  )

  const pageView = useAnalyticsView()

  useEffect(() => {
    pageView('documents')
  }, [pageView])

  useEffect(() => {
    dispatch(fetchUserDocuments())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
  }, [dispatch])

  const updateTab = (newIndex: number) => {
    setSearchParams({ tab: TAB_INDEXES[newIndex] }, { replace: true })
    setSelectedTabPaneIndex(newIndex)
  }

  const bankStatementDocuments = useReselector(selectUserBankDocuments)
  const bookkeepingDocuments = useReselector(selectBookkeepingDocuments)
  const taxRelatedDocuments = useReselector(selectTaxDocuments)
  const incorporationDocuments = useReselector(selectIncorporationDocuments)
  const receiptDocuments = useReselector(selectReceiptDocuments)
  const otherDocuments = useReselector(selectOtherDocuments)

  // 4 - Construct our tab panes
  const panes = useMemo(
    () =>
      createTabPanes({
        bankStatementDocuments,
        bookkeepingDocuments,
        taxRelatedDocuments,
        incorporationDocuments,
        receiptDocuments,
        otherDocuments,
      }),
    [
      bankStatementDocuments,
      bookkeepingDocuments,
      incorporationDocuments,
      otherDocuments,
      receiptDocuments,
      taxRelatedDocuments,
    ]
  )
  const description = useMemo(
    () => panes[selectedTabPaneIndex].paneDescription,
    [panes, selectedTabPaneIndex]
  )

  const enableLowerFrictionStatements = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableLowerFrictionStatements,
    false
  )

  return (
    <div>
      <FeatureStateProvider>
        <PageHeader header="Your Documents" />
        <div id="documentsPanel">
          <Grid>
            {!enableLowerFrictionStatements && (
              <GridRowColumn>
                <Alert type="info" className="page-description">
                  {description}
                </Alert>
              </GridRowColumn>
            )}
            <GridRowColumn>
              <Tab
                panes={panes}
                onTabClick={updateTab}
                defaultActiveIndex={selectedTabPaneIndex}
              />
            </GridRowColumn>
            <GridRowColumn>
              <DeleteDocumentConfirm />
            </GridRowColumn>
          </Grid>
        </div>
      </FeatureStateProvider>
    </div>
  )
}

export default UserDocuments
