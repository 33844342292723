import { Grid, Icon } from 'semantic-ui-react'
import currency from 'currency.js'

import { formatCurrency } from '../../../../utils/currencyHelpers'
import { Popup, Text } from '../../../../components/BaseComponents'
import { Gusto_Payroll } from '../../generated_gusto_types'

const PaymentOverview = ({
  payroll: { totals },
}: {
  payroll: Gusto_Payroll
}) => {
  const {
    net_pay_debit,
    employer_taxes,
    company_debit,
    employee_taxes,
    reimbursement_debit,
  } = totals || {}

  return (
    <Grid.Row className="payment-overview-emp" textAlign="center">
      <Grid.Column width={4}>
        <div className="title-wrapper">
          <Text as="bodyLg">Employee Wages</Text>
          <Popup
            content="This consists of employee wages, reimbursements, and bonuses paid in this payroll cycle."
            trigger={<Icon name="info circle" />}
          />
        </div>
        <Text as="bodyLg" className="amount">
          {formatCurrency(
            currency(net_pay_debit || 0).add(reimbursement_debit || 0)
          )}
        </Text>
      </Grid.Column>
      <Grid.Column width={1}>
        <Text as="bodyLg" className="math-symbol">
          +
        </Text>
      </Grid.Column>
      <Grid.Column width={3}>
        <div className="title-wrapper">
          <Text as="bodyLg">Payroll Taxes</Text>
          <Popup
            content="This includes federal and state payroll taxes paid in this payroll cycle."
            trigger={<Icon name="info circle" />}
          />
        </div>
        <Text className="amount" as="bodyLg">
          {formatCurrency(
            currency(employer_taxes || 0).add(employee_taxes || 0)
          )}
        </Text>
      </Grid.Column>
      <Grid.Column width={1}>
        <Text className="math-symbol" as="bodyLg">
          =
        </Text>
      </Grid.Column>
      <Grid.Column width={7}>
        <div className="title-wrapper">
          <Text as="bodyLg" color="green">
            Total Withdrawn from Bank Account
          </Text>
          <Popup
            content="This is the amount that will be taken from your attached bank account for this payroll cycle."
            trigger={<Icon name="info circle" />}
          />
        </div>
        <Text as="bodyLg" color="green" className="amount">
          <b>{formatCurrency(company_debit)}</b>
        </Text>
      </Grid.Column>
    </Grid.Row>
  )
}

export default PaymentOverview
