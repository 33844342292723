import moment from 'moment'
import { TaxFilingUser } from '../../../Taxes/AnnualTaxes/annualTaxFilings.slice'
import { AdminAnnualTaxFiling } from '../adminAnnualTaxFilings.slice'

export const fetchTSKResponses = (
  filing: AdminAnnualTaxFiling,
  currentAnnualTaxYear: string,
  user: TaxFilingUser
) => {
  const tskResponses: string[][] = []

  tskResponses.push([
    'Tax Season Kickoff Responses For:',
    `${user?.firstName} ${user?.lastName}`,
  ])

  tskResponses.push([
    'Based on your business information and entity type, we plan on filing the following forms for you:',
    ...(filing?.annualTaxFormNeeds || []),
  ])

  tskResponses.push([
    'Based on your tax information, we believe your filing status to be:',
    ...(filing?.annualTaxFormNeeds || []),
  ])

  tskResponses.push([
    `I have confirmed that my prior year (${moment(currentAnnualTaxYear, 'YYYY')
      .subtract(1, 'year')
      .year()} tax return is uploaded`,
    filing?.priorYearTaxReturnUploaded ? 'TRUE' : 'FALSE',
  ])

  tskResponses.push([
    `Do you have an urgent need that requires your ${moment(
      currentAnnualTaxYear,
      'YYYY'
    ).year()} tax return to be expedited?`,
    filing?.expedited?.toString() || '',
  ])

  if (filing?.expedited) {
    tskResponses.push([
      'Please indicate your reason for needing an expedited tax return',
      filing?.expeditedReason || '',
    ])

    tskResponses.push([
      'Please enter the requested date by which you would like your tax return expedited',
      filing?.expeditedDueDate || '',
    ])
  }

  tskResponses.push([
    'Do you need to file any 1099-NECs?',
    filing?.needContractorFiling ?? '',
  ])

  tskResponses.push([
    'Anything else tax-related that we should know?',
    filing?.additionalTaxInformation || '',
  ])
  return tskResponses
}
