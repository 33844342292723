import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Divider, Grid } from 'semantic-ui-react'
import { useFormik, FormikProvider } from 'formik'
import moment from 'moment'

import PayrollSetupList from './PayrollSetupList'
import { User } from '../../../reducers/auth/userReducer'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { selectFirstEmployee } from '../payroll.selectors'
import {
  postCreateEmployee,
  fetchAllCompanyLocations,
  fetchEmployees,
  putUpdateEmployee,
  POST_CREATE_EMPLOYEE_KEY,
  PUT_UPDATE_EMPLOYEE_KEY,
} from '../payrollActions'
import PrefilledNoticeContainer from './PrefilledNoticeContainer'
import { GEP_ENROLL_PATHS, getMinDateOfBirth } from '../helpers'
import PayrollError from '../PayrollError'
import {
  Button,
  Card,
  FormikDateInput,
  FormikInput,
  getFieldNames,
  GridRowColumn,
  makeDateSchema,
  makeNumberSchema,
  makeReqEmailSchema,
  makeReqStringSchema,
  Text,
} from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useAppDispatch } from '../../../utils/typeHelpers'

const EmployeeForm = ({ user }: { user: User }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [ssnType, setSsnType] = useState('password')

  const maxDateOfBirth = getMinDateOfBirth()

  const employee = useReselector(selectFirstEmployee)

  const prefilledDob = useMemo(() => {
    if (employee?.date_of_birth) {
      return moment(employee.date_of_birth, DATE_FORMATS.GUSTO_SUBMIT).format(
        DATE_FORMATS.INPUT
      )
    } else if (user.dateOfBirth) {
      return moment(user.dateOfBirth).format(DATE_FORMATS.INPUT)
    }

    return ''
  }, [employee?.date_of_birth, user.dateOfBirth])

  const formik = useFormik({
    initialValues: {
      first_name: user.firstName || '',
      last_name: user.lastName || '',
      dob: prefilledDob,
      email: employee?.email || user.personalEmail || user.email,
      ssn: '',
    },
    onSubmit: async ({ first_name, last_name, dob, email, ssn }) => {
      // create/update employee
      if (employee?.uuid) {
        const res = await putUpdateEmployee(employee.uuid, {
          version: employee.version,
          first_name,
          last_name,
          date_of_birth: moment(dob, DATE_FORMATS.INPUT).format(
            DATE_FORMATS.GUSTO_SUBMIT
          ),
          email,
          ssn,
        })(dispatch)

        if (!res) {
          return
        }
      } else {
        const res = await postCreateEmployee({
          first_name,
          last_name,
          date_of_birth: moment(dob, DATE_FORMATS.INPUT).format(
            DATE_FORMATS.GUSTO_SUBMIT
          ),
          email,
          ssn,
        })(dispatch)

        if (!res) {
          return
        }
      }

      navigate(GEP_ENROLL_PATHS.employeeJob)
    },
  })

  const { submitForm, isSubmitting } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn>
          <Text as="h2">Add yourself as an employee</Text>
        </GridRowColumn>
        <Divider />
        <GridRowColumn>
          <Text as="h2">Employment Details</Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text>
            In order to pay yourself as an employee, you need to set yourself up
            as an employee.
          </Text>
        </GridRowColumn>
        <PayrollError fetchKey={POST_CREATE_EMPLOYEE_KEY} />
        <PayrollError fetchKey={PUT_UPDATE_EMPLOYEE_KEY} />
        <GridRowColumn width={8}>
          <FormikInput
            name={fieldNames.first_name}
            label="First Name"
            required
            fullWidth
            schema={makeReqStringSchema({ field: 'first name' })}
          />
        </GridRowColumn>
        <GridRowColumn width={8}>
          <FormikInput
            name={fieldNames.last_name}
            label="Last Name"
            required
            fullWidth
            schema={makeReqStringSchema({ field: 'last name' })}
          />
        </GridRowColumn>
        <GridRowColumn width={8}>
          <FormikDateInput
            name={fieldNames.dob}
            maxDate={maxDateOfBirth.toDate()}
            required
            label="Date of Birth"
            fullWidth
            schema={makeDateSchema({
              field: 'date of birth',
              maxDate: maxDateOfBirth.format(DATE_FORMATS.INPUT),
            })}
          />
        </GridRowColumn>
        <GridRowColumn width={12}>
          <FormikInput
            name={fieldNames.email}
            label="Personal Email"
            description="We’ll send information regarding your payroll and personal pay to your email address."
            fullWidth
            required
            schema={makeReqEmailSchema({ field: 'email' })}
          />
        </GridRowColumn>
        <GridRowColumn width={12}>
          <FormikInput
            name={fieldNames.ssn}
            type={ssnType}
            placeholder="xxx-xx-xxxx"
            label="Social Security number"
            description={
              <>
                We need this to enroll you in payroll.{' '}
                <b>Heard doesn’t store this data.</b>
              </>
            }
            onRightIconClick={() =>
              setSsnType((type) => (type === 'password' ? 'text' : 'password'))
            }
            rightIcon={ssnType === 'password' ? 'eye' : 'eye slash'}
            required
            fullWidth
            schema={makeNumberSchema({
              field: 'social security number',
              numDigits: 9,
              allowLeadingZero: true,
            })}
          />
        </GridRowColumn>
        <Grid.Row />
        <Grid.Row />
        <Grid.Row>
          <Grid.Column width={6}>
            <Button
              fullWidth
              variant="secondary"
              onClick={() => navigate(GEP_ENROLL_PATHS.locations)}
            >
              Back
            </Button>
          </Grid.Column>
          <Grid.Column width={4} />
          <Grid.Column width={6}>
            <Button fullWidth onClick={submitForm} loading={isSubmitting}>
              Save & Continue
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </FormikProvider>
  )
}

const PayrollEnrollEmployee = () => {
  const dispatch = useAppDispatch()
  const user = useReselector(getCurrentUser)
  const [fetched, setFetched] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchAllCompanyLocations())
      await dispatch(fetchEmployees())
      setFetched(true)
    }
    fetch()
  }, [dispatch])

  return (
    <Container>
      <Card>
        <Grid>
          <GridRowColumn>
            <Text as="h1">Setting Up Payroll</Text>
          </GridRowColumn>
          <Divider />
          <PrefilledNoticeContainer />
          <Grid.Row>
            <Grid.Column width={9}>
              {user && fetched && <EmployeeForm user={user} />}
            </Grid.Column>
            <Grid.Column width={7}>
              <PayrollSetupList />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollEnrollEmployee
