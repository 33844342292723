import { useStringFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../OpenFeature'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import {
  TaxfyleCall,
  TaxfyleJob,
  TaxfyleJobStatus,
} from '../AnnualTaxes/annualTaxFilingForms.slice'
import {
  FETCH_ACTIVE_TAX_CONSULTATIONS,
  FETCH_TAX_CONSULTATION_URL,
  JobServices,
  PilotFeatureFlagValues,
  fetchActiveTaxConsultations,
  fetchTaxConsultationAdvisor,
  fetchTaxConsultationUrl,
} from './taxConsultation.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'
import {
  Button,
  Card,
  GridRowColumn,
  Label,
  Link,
  Text,
} from '../../../components/BaseComponents'
import {
  useFetchResponse,
  useFetchResponseOnInterval,
  useReselector,
} from '../../../utils/sharedHooks'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Grid } from 'semantic-ui-react'
import {
  selectErrorsForKeys,
  selectIsFetchingForKeys,
} from '../../../reducers/fetch'
import { DateTime } from 'luxon'

const JobPortal = ({
  textOnly = false,
  jobLink,
  children,
}: {
  textOnly?: boolean
  jobLink?: string
  children: ReactNode
}) => {
  const loading = useReselector(selectIsFetchingForKeys, [
    FETCH_TAX_CONSULTATION_URL,
  ])

  if (textOnly) {
    return <Link href={jobLink}>{children}</Link>
  }
  return (
    <Link href={jobLink}>
      <Button loading={loading}>{children}</Button>
    </Link>
  )
}

const ConsultationPortal = ({
  consultation,
  call,
}: {
  consultation: TaxfyleJob
  call?: TaxfyleCall
}) => {
  // Taxfyle links expire after 5 minutes, so refresh every 4 minutes
  const jobLink = useFetchResponseOnInterval(
    fetchTaxConsultationUrl,
    240000,
    consultation.jobId
  )
  const advisorName = useFetchResponse(
    fetchTaxConsultationAdvisor,
    consultation.jobId
  )
  const loading = useReselector(selectIsFetchingForKeys, [
    FETCH_TAX_CONSULTATION_URL,
  ])
  const errors = useReselector(selectErrorsForKeys, [
    FETCH_ACTIVE_TAX_CONSULTATIONS,
    FETCH_TAX_CONSULTATION_URL,
  ])
  const flag = useStringFlagValue(
    FEATURE_FLAG_KEYS.taxAdvisoryPilot,
    PilotFeatureFlagValues.noPilot
  )
  const displayTime = useMemo(() => {
    if (call?.scheduledAt) {
      const callEndsAt = consultation.services.includes(
        JobServices.consultation60Min
      )
        ? DateTime.fromISO(call.scheduledAt).plus({ minutes: 60 })
        : DateTime.fromISO(call.scheduledAt).plus({ minutes: 30 })
      // format 2:30-3:00PM PT
      return `${DateTime.fromISO(call.scheduledAt).toLocaleString(DateTime.TIME_SIMPLE)}-${callEndsAt.toLocaleString(DateTime.TIME_SIMPLE)}`
    }
    return ''
  }, [call?.scheduledAt, consultation.services])

  if (
    flag === PilotFeatureFlagValues.userScheduled &&
    consultation.jobStatus === TaxfyleJobStatus.infoGathering
  ) {
    return (
      <Card backgroundColor="stone40">
        <Grid>
          <GridRowColumn>
            <Label
              color="orange"
              icon={regular('thumbtack')}
              style={{ marginBottom: 32 }}
            >
              Needs Action
            </Label>
          </GridRowColumn>
          <GridRowColumn short>
            <Text as="h2">Schedule tax advice consult</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              Provide your schedule availability to speak to your tax advisor.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <JobPortal jobLink={jobLink}>Schedule consult</JobPortal>
          </GridRowColumn>
          {errors.length > 0 && (
            <GridRowColumn>
              <Text color="red">Error loading consultation.</Text>
            </GridRowColumn>
          )}
        </Grid>
      </Card>
    )
  }

  if (
    flag === PilotFeatureFlagValues.userScheduled &&
    consultation.jobStatus === TaxfyleJobStatus.unclaimed
  ) {
    return (
      <Card backgroundColor="stone40">
        <Grid>
          <GridRowColumn>
            <Label
              color="neutral"
              icon={regular('thumbs-up')}
              style={{ marginBottom: 32 }}
            >
              No Action Needed
            </Label>
          </GridRowColumn>
          <GridRowColumn short>
            <Text as="h2">We&apos;re matching you to a tax advisor</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              Thanks for providing your availability. Once a tax advisor
              confirms your appointment, your call details will be updated here.
              In the meantime, feel free to upload additional documents you may
              want to review on your call by clicking the button below.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <JobPortal jobLink={jobLink}>Update Details</JobPortal>
          </GridRowColumn>
          {errors.length > 0 && (
            <GridRowColumn>
              <Text color="red">Error loading consultation.</Text>
            </GridRowColumn>
          )}
        </Grid>
      </Card>
    )
  }

  if (consultation.jobStatus === TaxfyleJobStatus.completed) {
    const oneWeekAfterCompletion = consultation.jobCompletedAt
      ? DateTime.fromISO(consultation.jobCompletedAt)
          .plus({ days: 7 })
          .endOf('day')
          .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
      : 'one week after the completion of your consultation'
    return (
      <Card backgroundColor="stone40">
        <Grid>
          <GridRowColumn>
            <Text as="h2">Tax consult completed</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              Thank you for participating in Heard&apos;s tax advisory user
              research. A transcript of your recent consultation has been
              generated and saved under the &quot;Other&quot; category within
              your{' '}
              <Link to="/practice/documents?tab=other">Heard documents</Link>.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              If you haven&apos;t already, please take a moment to share your
              experience by filling out a brief survey. We value your feedback
              and use this information to improve our product.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Link
              href="https://docs.google.com/forms/d/e/1FAIpQLSdD_ypY9lX0u6OXG6v5rv7wvnDe5zwfZ4ud5EKKHhmQyLC6qg/viewform?usp=sf_link"
              newPage
            >
              <Button>Provide your feedback</Button>
            </Link>
          </GridRowColumn>
          {!loading && (
            <GridRowColumn>
              <Text>
                The consultation portal will be available until{' '}
                {oneWeekAfterCompletion}. Until then, you can view your messages{' '}
                <JobPortal jobLink={jobLink} textOnly>
                  here
                </JobPortal>
                .
              </Text>
            </GridRowColumn>
          )}
        </Grid>
      </Card>
    )
  }

  if (call?.scheduledAt) {
    return (
      <Card backgroundColor="stone40">
        <Grid>
          <GridRowColumn>
            <Text as="h2">Tax consult scheduled</Text>
          </GridRowColumn>
          <GridRowColumn>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text as="eyebrow">Date</Text>
              <Text as="bodyLg">
                {DateTime.fromISO(call.scheduledAt).toLocaleString(
                  DateTime.DATE_MED_WITH_WEEKDAY
                )}
              </Text>
            </div>
          </GridRowColumn>
          <GridRowColumn>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text as="eyebrow">Time</Text>
              <Text as="bodyLg">{displayTime}</Text>
            </div>
          </GridRowColumn>
          <GridRowColumn>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text as="eyebrow">Tax Advisor</Text>
              <Text as="bodyLg">{advisorName}</Text>
            </div>
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              Your tax advisor will call you at your meeting time. In the
              meantime, you can send additional documents, cancel*, or
              reschedule by using the “Send message” button below.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <JobPortal jobLink={jobLink}>Send message</JobPortal>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="bodySm">
              *You must provide 24 hour notice to cancel or reschedule, or you
              may be charged a fee.{' '}
            </Text>
          </GridRowColumn>
        </Grid>
      </Card>
    )
  }

  return null
}

export const TaxAdvisoryPilot = () => {
  const flag = useStringFlagValue(
    FEATURE_FLAG_KEYS.taxAdvisoryPilot,
    PilotFeatureFlagValues.noPilot
  )
  const [consultations, setConsultations] = useState<TaxfyleJob[]>([])
  const [scheduledCalls, setScheduledCalls] = useState<TaxfyleCall[]>([])
  const dispatch = useAppDispatch()

  useEffect(() => {
    const load = async () => {
      const res = await dispatch(fetchActiveTaxConsultations())
      if (res) {
        setConsultations(res.taxfyleJobs)
        setScheduledCalls(res.taxfyleCalls)
      }
    }
    if (flag !== PilotFeatureFlagValues.noPilot) {
      load()
    }
  }, [dispatch, flag])

  if (flag === PilotFeatureFlagValues.noPilot) {
    return null
  }

  return (
    <>
      {consultations.map((consultation) => (
        <ConsultationPortal
          key={consultation.jobId}
          consultation={consultation}
          call={scheduledCalls.find(
            (sc) => sc.taxfyleJobId === consultation.jobId
          )}
        />
      ))}
    </>
  )
}
