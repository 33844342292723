import { Text } from '../../../../../components/BaseComponents'

export const AnnualGrossIncomeTooltip = () => (
  <>
    <Text as="h3">Annual Gross Income</Text>
    <br />
    <Text>The total income of you (and your spouse, if applicable).</Text>
    <br />
    <Text>
      <i>Form 1040, Line 9</i>
    </Text>
  </>
)

export const AnnualizedPPNetProfitTooltip = () => (
  <>
    <Text as="h3">Annualized Private Practice Net Profit</Text>
    <br />
    <Text>
      We annualize this number by taking your Year-to-Date (YTD) Net Profit and
      predict how much you’ll make for the rest of the year.
    </Text>
    <br />
    <Text>
      <i>
        For example: If you made $1,000 for each of the last 3 quarters, we will
        project that you will make $1,000 in Q4, resulting in a total of $4,000.
      </i>
    </Text>
  </>
)

export const AnnualizedW2IncomeTooltip = ({
  isScorp,
}: {
  isScorp: boolean
}) => (
  <>
    <Text as="h3">Annualized Total W-2 Income</Text>
    <br />
    <Text>
      The total Year-To-Date (YTD) W-2 income of you (and your spouse, if
      applicable). We then annualize it by predicting how much you’ll make the
      rest of the year.
    </Text>
    <br />
    <Text>
      This can include W-2 income from:
      <ul>
        {isScorp && <li>Your private practice</li>}
        <li>Other jobs outside of your practice</li>
        <li>Your spouse</li>
      </ul>
    </Text>
  </>
)

export const YTD1099IncomeTooltip = () => (
  <>
    <Text as="h3">YTD 1099 Income</Text>
    <br />
    <Text>
      We use the actual amount of 1099 income you’ve earned so far, since
      contractor income can vary throughout the year. We don’t annualize this
      number.
    </Text>
  </>
)

export const TaxableIncomeTooltip = () => (
  <>
    <Text as="h3">Taxable Income</Text>
    <br />
    <Text>
      Taxable Income is your Annual Gross Income, adjusted for deductions. Some
      deductions are:
      <ul>
        <li>Standard Deduction</li>
        <li>QBI Deduction</li>
        <li>Deductible portion of Self-Employment Tax</li>
      </ul>
    </Text>
    <br />
    <Text>
      <i>Form 1040, Line 15</i>
    </Text>
  </>
)

export const AnnualTaxLiabilityTooltip = ({
  isState,
}: {
  isState: boolean
}) => (
  <>
    <Text as="h3">Annual Tax Liability (Gross)</Text>
    <br />
    <Text>
      The Annual Tax Liability (Gross) uses the{' '}
      {isState ? 'tax table' : 'IRS’s tables'} to calculate your tax liability
      based off your taxable income. It does not include withholdings.
    </Text>
    <br />
    <Text>
      <i>Form 1040, Line 24</i>
    </Text>
  </>
)

export const YTDTaxLiabilityGrossTooltip = () => (
  <>
    <Text as="h3">YTD Tax Liability (Gross)</Text>
    <br />
    <Text>
      YTD Tax Liability (Gross) adjusts your Annual Tax Liability for this
      portion of the year.
    </Text>
    <br />
    <Text>
      <i>
        For example: <br />
        If your Annual Tax Liability was $1,000, the portion owed for Jan - Q3
        would be $750.
      </i>
    </Text>
  </>
)

export const YTDTaxLiabilityNetTooltip = () => (
  <>
    <Text as="h3">YTD Tax Liability (Net)</Text>
    <br />
    <Text>
      The difference between Gross YTD Tax Liability and Total YTD Withholding.
    </Text>
  </>
)

export const TotalPreviousQTEPaymentsTooltip = () => (
  <>
    <Text as="h3">Total previous quarterly tax estimate payments</Text>
    <br />
    <Text>
      We factor in your previous quarterly tax estimate payments to ensure
      you’re paying enough throughout the year.
    </Text>
    <br />
    <Text>
      <i>
        For example:
        <br />
        If you’ve underpaid in previous quarters, you’ll have a higher Q3
        payment to “catch up”.
      </i>
    </Text>
  </>
)

export const AnnualTaxLiabilityNetTooltip = () => (
  <>
    <Text as="h3">Annual Tax Liability (Net)</Text>
    <br />
    <Text>
      This is how much you owe in taxes for the year after your withholdings
      have been factored in.
    </Text>
    <br />
    <Text>
      <i>
        For example:
        <br />
        You owe $10,000 for the year and your withholdings are $3,000. Your net
        annual tax liability would be $7,000.
      </i>
    </Text>
  </>
)
