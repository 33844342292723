import axios from 'axios'
import { keyBy } from 'lodash'
import { Moment } from 'moment'
import { DateTime } from 'luxon'
import {
  FinancialAccountReconciliationWithTransactions,
  receiveReconciliations,
  receiveSingleReconciliation,
} from '../../reducers/admin/allFinancialAccountReconciliationsReducer'
import { fetchIfNeededWrapper, fetchWrapper } from '../../reducers/fetch'
import { Reconciliation } from '../../reducers/admin/financialAccountsReducer'
import { getReconciliationById } from '../../selectors/reconciliations.selectors'
import { dollarsToCents } from '../../utils/currencyHelpers'

export interface ReconciliationUpsertModel {
  accountId: number
  endingBalanceDate: Moment
  endingBalanceInCents: number
  startingBalanceDate: Moment
  startingBalanceInCents: number
  status: string
  type: 'manual' | 'auto'
  bookkeepingReportId: number
}

export interface VirtualStatementTransaction {
  transactionId: number | null
  date: string
  description: string | null
  amountInCents: number
  balanceInCents: number | null
  deltaInCents: number | null
  potentialBreak: boolean
  displayStatus: boolean
}

export interface VirtualStatementReconciliation {
  id: number
  startingBalanceInCents: number
  endingBalanceInCents: number
  startingBalanceDate: string
  endingBalanceDate: string
  status: string
  financialAccount: {
    id: number
    plaidInstitutionName: string
    name: string
    mask: string
    type: string
    subtype: string
  }
}

type VirtualStatementReportData = {
  id: number
  date: string | null
}
export interface VirtualStatementData {
  reconciliation: VirtualStatementReconciliation
  statement: VirtualStatementTransaction[]
  statementUrl: string
  currentReport: VirtualStatementReportData | null
  previousReport: VirtualStatementReportData | null
}

export interface ReconWithAnalyticsScenarioResponse {
  reconciliations: FinancialAccountReconciliationWithTransactions[]
  analyticsScenario?: string | null
}
/*
  GET One
*/
export const getFetchSingleAccountRecKey = (id: number | string) =>
  `FETCH_SINGLE_ACCOUNT_REC_KEY${id}`
export const fetchSingleAccountReconciliationIfNeeded = (id: number | string) =>
  fetchIfNeededWrapper({
    fetchKey: getFetchSingleAccountRecKey(id),
    defaultErrorMessage: 'Error fetching single account reconciliation',
    shouldHandleError: false,
    defaultValueSelector: (state) => getReconciliationById(state, id),
    fetchFunction: async (dispatch) => {
      const json =
        await axios.get<FinancialAccountReconciliationWithTransactions>(
          `/finances/api/v1/admin/reconciliations/${id}`
        )
      dispatch(receiveSingleReconciliation(json.data))
      return json.data
    },
  })

/*
  Get all for User
*/

export const fetchAllFinancialAccountReconciliationsForUser = (
  userId: number
) =>
  fetchWrapper({
    defaultErrorMessage:
      'There was an error fetching all user financial account reconciliations.',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<Reconciliation[]>(
        `/finances/api/v1/admin/reconciliations/${userId}/list`
      )
      dispatch(receiveReconciliations(keyBy(json.data, 'id')))
      return json.data
    },
  })

/*
  UPSERT
*/

export const upsertAccountReconciliation = (
  id: number | undefined,
  data: ReconciliationUpsertModel
) =>
  fetchWrapper({
    defaultErrorMessage: 'Error upserting account reconciliation',
    shouldHandleError: false,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<
        FinancialAccountReconciliationWithTransactions[]
      >(`/finances/api/v1/admin/reconciliations/${id ? id : ''}`, {
        ...data,
        startingBalanceDate: data.startingBalanceDate.format('YYYY-MM-DD'),
        endingBalanceDate: data.endingBalanceDate.format('YYYY-MM-DD'),
      })
      dispatch(receiveReconciliations(keyBy(json.data, 'id')))

      // Upsert can update multiple reconciliations, but we only want to return the one we're updating
      if (json.data.length === 1) {
        return json.data[0]
      } else {
        const updatedRecon = json.data.find((recon) => recon.id === id)
        if (updatedRecon) {
          return updatedRecon
        }
      }

      throw new Error('Error upserting account reconciliation')
    },
  })

export const performAutoReconciliation = (
  accountId: number,
  bookkeepingReportId: number
) =>
  fetchWrapper({
    shouldHandleError: false,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<ReconWithAnalyticsScenarioResponse>(
        `/finances/api/v1/admin/accounts/${accountId}/auto_reconciliation`,
        { bookkeepingReportId }
      )
      dispatch(receiveReconciliations(keyBy(json.data.reconciliations, 'id')))
      if (json.data.reconciliations.length === 0) {
        throw new Error('Error performing auto reconciliation')
      }
      return {
        reconciliation: json.data.reconciliations[0],
        analyticsScenario: json.data.analyticsScenario,
      }
    },
  })

export const getVirtualStatement = (reconciliationId: number) =>
  fetchWrapper({
    shouldHandleError: false,
    fetchFunction: async () => {
      const json = await axios.get<VirtualStatementData>(
        `/finances/api/v1/admin/reconciliations/${reconciliationId}/virtual_statement`
      )
      return json.data
    },
  })

export const RECOVER_RECONCILIATION_KEY = 'RECOVER_RECONCILIATION_KEY'
export const recoverAutoReconciliation = (
  reconciliationId: number,
  endDate: DateTime,
  endAmount: string
) =>
  fetchWrapper({
    defaultErrorMessage: 'Error recovering reconciliation',
    fetchKey: RECOVER_RECONCILIATION_KEY,
    fetchFunction: async (dispatch) => {
      const endDateISO = endDate.toISODate()
      const endAmountInCents = dollarsToCents(Number(endAmount))
      const json = await axios.post<ReconWithAnalyticsScenarioResponse>(
        `/finances/api/v1/admin/reconciliations/${reconciliationId}/recover`,
        {
          endDate: endDateISO,
          endAmountInCents,
        }
      )
      dispatch(receiveReconciliations(keyBy(json.data.reconciliations, 'id')))

      // Recover can update multiple reconciliations, but we only want to return the one we're recovering
      const reconciliation = json.data.reconciliations.find(
        (recon) => recon.id === reconciliationId
      )
      if (!reconciliation) {
        throw new Error('Error recovering reconciliation')
      }

      return {
        reconciliation,
        analyticsScenario: json.data.analyticsScenario,
      }
    },
  })
