import { CSSProperties } from 'react'
import { Grid } from 'semantic-ui-react'

import { Card, GridColumnSpacer } from '../../../../components/BaseComponents'
import type { Colors } from '../../../../styles/theme'
import { UserTaxEstimate } from '../userTaxEstimates.slice'
import LeftColumn from './row-components/LeftColumn'
import RightColumn from './row-components/RightColumn'
import { useToggle } from '../../../../utils/sharedHooks'
import TaxEstimateCta from './row-components/TaxEstimateCta'

const UserTaxEstimateSummary = ({
  estimate,
  overrideBgColor,
  style,
}: {
  estimate: UserTaxEstimate | null
  overrideBgColor?: keyof typeof Colors
  style?: CSSProperties
}) => {
  const [toggleOpen, setToggleOpen] = useToggle(false)

  return (
    <Grid.Column style={style}>
      <Card type="body" backgroundColor={overrideBgColor}>
        <Grid>
          <Grid.Row>
            <LeftColumn estimateId={estimate?.id} />
            <GridColumnSpacer mobile />
            <RightColumn estimateId={estimate?.id} toggleOpen={toggleOpen} />
          </Grid.Row>
          <TaxEstimateCta
            estimateId={estimate?.id}
            toggleOpen={toggleOpen}
            setToggleOpen={setToggleOpen}
          />
        </Grid>
      </Card>
    </Grid.Column>
  )
}

export default UserTaxEstimateSummary
