import axios from 'axios'
import { fetchWrapper } from '../reducers/fetch'

export const fetchInvoicePaymentUrl = (invoiceId: string) =>
  fetchWrapper({
    fetchFunction: async () => {
      const response = await axios.get<string>(
        `/finances/api/v2/invoices/${invoiceId}`
      )
      return response.data
    },
  })
