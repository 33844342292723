import { DateTime } from 'luxon'
import { useReselector, useTimeoutRef } from '../../../utils/sharedHooks'
import {
  fetchUserOnboardingStepsIfNeeded,
  updateUserOnboardingStep,
} from './onboarding.actions'
import { OnboardingStepId } from './onboarding.reducer'
import { getUserOnboardingStepByOnboardingStepId } from './onboarding.selectors'
import { useAppDispatch } from '../../../utils/typeHelpers'

const REFETCH_ONBOARDING_STEPS_TIMEOUT = 2000

export const useCompleteOnboardingStepIfNeeded = (
  stepIdentifier: OnboardingStepId
) => {
  const dispatch = useAppDispatch()
  const userOnboardingStep = useReselector(
    getUserOnboardingStepByOnboardingStepId,
    stepIdentifier
  )
  const fetchUserOnboardingStepsTimeout = useTimeoutRef()
  /**
   *  Marks the onboarding step as complete if it hasn't been already and conditionally re-fetches all onboarding steps
   *  We conditionally re-fetch all onboarding steps because the user may have completed a step that unlocks other steps
   * @param shouldRefetchAllOnboardingSteps - If true, fetch all onboarding steps after marking the step as complete
   */
  const markComplete = async ({
    shouldRefetchAllOnboardingSteps,
  }: {
    shouldRefetchAllOnboardingSteps?: boolean
  } = {}) => {
    if (!userOnboardingStep) {
      return
    }

    if (!userOnboardingStep.completedAt) {
      await updateUserOnboardingStep({
        id: userOnboardingStep.id,
        updatableFields: { completedAt: DateTime.now().toISO() },
      })(dispatch)
      if (shouldRefetchAllOnboardingSteps) {
        fetchUserOnboardingStepsTimeout.current = setTimeout(() => {
          // Use timeout because we want to give the backend time to create or update
          // any dependent steps before fetching
          dispatch(
            fetchUserOnboardingStepsIfNeeded({
              expanded: true,
              alwaysFetch: true,
            })
          )
        }, REFETCH_ONBOARDING_STEPS_TIMEOUT)
      }
    }
  }
  return { markComplete }
}
