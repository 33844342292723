import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import { useAppDispatch } from '../../../../../utils/typeHelpers'
import { useReselector } from '../../../../../utils/sharedHooks'
import {
  selectStatusForTaxEstimate,
  selectUserTaxEstimateById,
} from '../../userTaxEstimates.selector'
import { fetchUserDocuments } from '../../../../UserDocuments/userDocuments.slice'
import {
  Button,
  GridColumnSpacer,
} from '../../../../../components/BaseComponents'
import { EstimateStatus } from '../../service'
import UpdateQuarterlyTaxEstimateModal from '../UpdateQuarterlyTaxEstimateModal'
import { UserTaxEstimate } from '../../userTaxEstimates.slice'
import { selectIsSingleStateCalculationByTaxEstimateId } from '../../../../../selectors/userTaxCalculation.selectors'
import { buildQueryParams } from '../../../../../utils/routeHelpers'
import useTaxPaymentInfoFlyout from '../TaxPaymentInfoFlyout'

export const createLinkToTaxBreakdown = (
  estimate: UserTaxEstimate | null,
  queryParams?: Parameters<typeof buildQueryParams>[0]
) =>
  `/taxes/quarterly/${estimate?.taxQuarter}/estimate-breakdown?section=${estimate?.type === 'federal' ? 'federal' : 'state'}${queryParams ? `&${buildQueryParams(queryParams)}` : ''}`

const TaxEstimateCta = ({
  estimateId,
  toggleOpen,
  setToggleOpen,
}: {
  estimateId?: number
  toggleOpen: boolean
  setToggleOpen: () => void
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const estimate = useReselector(selectUserTaxEstimateById, estimateId)
  const status = useReselector(selectStatusForTaxEstimate, estimateId)
  const isSingleStateCalc = useReselector(
    selectIsSingleStateCalculationByTaxEstimateId,
    estimateId
  )
  const [modalOpen, setModalOpen] = useState(false)

  const openFlyout = useTaxPaymentInfoFlyout({
    estimateId,
    openQTEModal: () => setModalOpen(true),
  })

  useEffect(() => {
    dispatch(fetchUserDocuments())
  }, [dispatch])

  const isFederal = estimate?.type === 'federal'

  return (
    <Grid.Row className="short">
      {(status === EstimateStatus.upcoming ||
        status === EstimateStatus.paid ||
        status === EstimateStatus.joinedTooLate ||
        status === EstimateStatus.unpaidWithResponse) && (
        <>
          <Grid.Column computer={8} tablet={8} mobile={10} />
          <Grid.Column
            computer={4}
            tablet={4}
            mobile={6}
            style={{ display: 'flex', justifyContent: 'end' }}
          >
            {status === EstimateStatus.paid && (
              <Button onClick={setToggleOpen} variant="secondaryLink">
                {toggleOpen ? 'Hide details' : 'Show Details'}
              </Button>
            )}
          </Grid.Column>
          <GridColumnSpacer mobile />
          <Grid.Column only="mobile" width={10} />
          <Grid.Column
            computer={4}
            tablet={4}
            mobile={6}
            style={{ display: 'flex', justifyContent: 'end' }}
          >
            <Button onClick={() => setModalOpen(true)} variant="secondaryLink">
              Update Payment
            </Button>
          </Grid.Column>
        </>
      )}

      {(status === EstimateStatus.readyForPayment ||
        status === EstimateStatus.estimateIsZero) &&
        (isFederal && isSingleStateCalc ? (
          <>
            <Grid.Column only="computer tablet" width={10} />
            <Grid.Column computer={6} tablet={6} mobile={16}>
              <Button
                onClick={() => navigate(createLinkToTaxBreakdown(estimate))}
                fullWidth
              >
                {status === EstimateStatus.estimateIsZero
                  ? 'View Details'
                  : 'View Details & Pay'}
              </Button>
            </Grid.Column>
          </>
        ) : (
          <>
            <Grid.Column only="computer tablet" width={8} />
            <Grid.Column computer={4} tablet={4} mobile={16}>
              <Button fullWidth onClick={openFlyout}>
                View & Pay
              </Button>
            </Grid.Column>
            <GridColumnSpacer mobile />
            <Grid.Column computer={4} tablet={4} mobile={16}>
              <Button
                onClick={() => setModalOpen(true)}
                variant="secondary"
                fullWidth
              >
                Mark as Paid
              </Button>
            </Grid.Column>
          </>
        ))}
      {status === EstimateStatus.unpaidWithoutResponse && (
        <>
          <Grid.Column only="computer tablet" width={12} />
          <Grid.Column
            computer={4}
            tablet={4}
            mobile={16}
            style={{ display: 'flex', justifyContent: 'end' }}
          >
            <Button onClick={() => setModalOpen(true)}>Update Payment</Button>
          </Grid.Column>
        </>
      )}
      {estimate && (
        <UpdateQuarterlyTaxEstimateModal
          open={modalOpen}
          close={() => setModalOpen(false)}
          estimate={estimate}
        />
      )}
    </Grid.Row>
  )
}

export default TaxEstimateCta
