import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ActionItemCategoryCreationModal from './ActionItemCategoryCreationModal'
import {
  Container,
  Loader,
  Dimmer,
  Table,
  Button,
  Message,
  Icon,
  Confirm,
} from 'semantic-ui-react'
import moment from 'moment'

import {
  Dispatch,
  ReduxState,
  useAppDispatch,
} from '../../../utils/typeHelpers'
import { fetchUserDocumentCategoriesIfNeeded } from '../UserDocumentCategories/userDocumentCategories.slice'
import { Tooltip, fetchAllTooltips } from './allTooltips.slice'
import { Modal, Link, Text } from '../../../components/BaseComponents'
import TodoAndImportantDateCreationModal from './TodoAndImportantDateCreationModal'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import {
  ActionItemCategory,
  fetchAllActionItemCategoriesIfNeeded,
  selectActionItemCategories,
} from './actionItemCategories.slice'
import {
  ActionItem,
  deleteActionItem,
  fetchAllImportantDates,
  fetchAllTodos,
  selectImportantDates,
  selectTodos,
} from './allActionItems.slice'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps

const TodoDeletionConfirm = ({
  open,
  close,
  item,
}: {
  open: boolean
  close: () => void
  item: ActionItem
}) => {
  const dispatch = useAppDispatch()

  const deleteTodo = async () => {
    const result = await deleteActionItem(item.id)(dispatch)
    if (result) close()
  }

  const renderContent = () => {
    return (
      <Modal.Content>
        <Text as="h3">ToDo to be removed</Text>
        <p>{item.description}</p>
      </Modal.Content>
    )
  }

  return (
    <Confirm
      open={open}
      header={`⚠️ Warning: You are deleting ToDo #${item.id} ⚠️ `}
      content={renderContent()}
      cancelButton="Cancel"
      confirmButton={
        <Button negative primary>
          Delete ToDo
        </Button>
      }
      onCancel={close}
      onConfirm={deleteTodo}
    />
  )
}

const ImportantDateDeletionConfirm = ({
  open,
  close,
  item,
}: {
  open: boolean
  close: () => void
  item: ActionItem
}) => {
  const dispatch = useAppDispatch()

  const deleteImportantDate = async () => {
    const result = await deleteActionItem(item.id)(dispatch)
    if (result) close()
  }

  const renderContent = () => {
    return (
      <Modal.Content>
        <Text as="h3">Important Date to be removed</Text>
        <Text>{item.description}</Text>
      </Modal.Content>
    )
  }

  return (
    <Confirm
      open={open}
      header={`⚠️ Warning: You are deleting Important Date #${item.id} ⚠️ `}
      content={renderContent()}
      cancelButton="Cancel"
      confirmButton={
        <Button negative primary>
          Delete
        </Button>
      }
      onCancel={close}
      onConfirm={deleteImportantDate}
    />
  )
}

const TodoRow = ({
  allActionItemCategories,
  allTooltips,
  item,
}: {
  allActionItemCategories: { [_: string]: ActionItemCategory }
  allTooltips: { [_: number]: Tooltip }
  item: ActionItem
}) => {
  const [todoOpen, setTodoOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const endsAt = item.endsAt
    ? moment(item.endsAt).format(DATE_FORMATS.DISPLAY_SHORT)
    : null

  return (
    <Table.Row>
      <Table.Cell>{item.id}</Table.Cell>
      <Table.Cell>{item.description}</Table.Cell>
      <Table.Cell>{item.actionLink}</Table.Cell>
      <Table.Cell>{endsAt}</Table.Cell>
      <Table.Cell>
        {allActionItemCategories[item.actionItemCategoryId]?.title}
      </Table.Cell>
      <Table.Cell>
        {item.tooltipId && (
          <>
            <Button onClick={() => setTooltipOpen(true)}>View</Button>
            <Modal size="tiny" className="viewTooltipModal" open={tooltipOpen}>
              <Modal.Content>
                <Text as="h2">{allTooltips[item.tooltipId]?.title}</Text>
                <Text>{allTooltips[item.tooltipId]?.body}</Text>
                <Link href={allTooltips[item.tooltipId]?.link || ''}>
                  {allTooltips[item.tooltipId]?.link}
                </Link>
              </Modal.Content>
              <Modal.Actions>
                <Button secondary onClick={() => setTooltipOpen(false)}>
                  Close
                </Button>
              </Modal.Actions>
            </Modal>
          </>
        )}
      </Table.Cell>
      <Table.Cell>
        <Button className="link" onClick={() => setTodoOpen(true)}>
          Edit
        </Button>
        {todoOpen && (
          <TodoAndImportantDateCreationModal
            actionItem={item}
            close={() => setTodoOpen(false)}
            open={todoOpen}
            isTodo
          />
        )}
      </Table.Cell>
      <Table.Cell>
        <Button onClick={() => setDeleteOpen(true)} floated="right" icon basic>
          <Icon name="trash alternate outline" />
        </Button>
        {deleteOpen && (
          <TodoDeletionConfirm
            open={deleteOpen}
            close={() => setDeleteOpen(false)}
            item={item}
          />
        )}
      </Table.Cell>
    </Table.Row>
  )
}

const ImportantDateRow = ({
  allActionItemCategories,
  allTooltips,
  item,
}: {
  allActionItemCategories: { [_: string]: ActionItemCategory }
  allTooltips: { [_: number]: Tooltip }
  item: ActionItem
}) => {
  const [importantDateOpen, setImportantDateOpen] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const endsAt = item.endsAt
    ? moment(item.endsAt).format(DATE_FORMATS.DISPLAY_SHORT)
    : null
  const startsAt = item.startsAt
    ? moment(item.startsAt).format(DATE_FORMATS.DISPLAY_SHORT)
    : null

  return (
    <Table.Row>
      <Table.Cell>{item.id}</Table.Cell>
      <Table.Cell>{item.description}</Table.Cell>
      <Table.Cell>{startsAt || ''}</Table.Cell>
      <Table.Cell>{endsAt || ''}</Table.Cell>
      <Table.Cell>
        {allActionItemCategories[item.actionItemCategoryId]?.title}
      </Table.Cell>
      <Table.Cell>
        {item.tooltipId && (
          <Button onClick={() => setTooltipOpen(true)}>View</Button>
        )}
        {tooltipOpen && item.tooltipId && (
          <Modal size="tiny" className="viewTooltipModal" open={tooltipOpen}>
            <Modal.Content>
              <Text as="h2">{allTooltips[item.tooltipId]?.title}</Text>
              <p>{allTooltips[item.tooltipId]?.body}</p>
              <Link href={allTooltips[item.tooltipId]?.link || ''}>
                {allTooltips[item.tooltipId]?.link}
              </Link>
            </Modal.Content>
            <Modal.Actions>
              <Button secondary onClick={() => setTooltipOpen(false)}>
                Close
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </Table.Cell>
      <Table.Cell>
        <Button className="link" onClick={() => setImportantDateOpen(true)}>
          Edit
        </Button>
        {importantDateOpen && (
          <TodoAndImportantDateCreationModal
            actionItem={item}
            close={() => setImportantDateOpen(false)}
            open={importantDateOpen}
          />
        )}
      </Table.Cell>
      <Table.Cell>
        <Button onClick={() => setDeleteOpen(true)} floated="right" icon basic>
          <Icon name="trash alternate outline" />
        </Button>
        {deleteOpen && (
          <ImportantDateDeletionConfirm
            open={deleteOpen}
            close={() => setDeleteOpen(false)}
            item={item}
          />
        )}
      </Table.Cell>
    </Table.Row>
  )
}

const AllActionItemsPanel = ({
  fetchAllTodos,
  fetchAllImportantDates,
  fetchAllActionItemCategoriesIfNeeded,
  fetchUserDocumentCategoriesIfNeeded,
  fetchAllTooltips,
  allActionItemCategories,
  allTodos,
  allImportantDates,
  allTooltips,
}: Props) => {
  const [fetching, setFetching] = useState(true)
  const [categoryModalOpen, setCategoryModalOpen] = useState(false)
  const [todoOpen, setTodoOpen] = useState(false)
  const [importantDateOpen, setImportantDateOpen] = useState(false)

  useEffect(() => {
    async function batchFetch() {
      await Promise.all([
        fetchAllTodos(),
        fetchAllImportantDates(),
        fetchAllActionItemCategoriesIfNeeded(),
        fetchUserDocumentCategoriesIfNeeded(),
        fetchAllTooltips(),
      ])
      setFetching(false)
    }
    batchFetch()
  }, [
    fetchAllTodos,
    fetchAllImportantDates,
    fetchAllActionItemCategoriesIfNeeded,
    fetchUserDocumentCategoriesIfNeeded,
    fetchAllTooltips,
  ])

  return (
    <Container id="allActionItemsPanel">
      <Text as="h3">All Action Items and Categories</Text>
      <br />
      {fetching && (
        <Dimmer active inverted>
          <Loader inverted>Fetching all action items</Loader>
        </Dimmer>
      )}
      {!fetching && (
        <div>
          <div className="flexbox">
            <Text as="h3">Action Item Categories</Text>
            <div>
              <Button secondary onClick={() => setCategoryModalOpen(true)}>
                Create New Category
              </Button>
              <ActionItemCategoryCreationModal
                close={() => setCategoryModalOpen(false)}
                open={categoryModalOpen}
              />
            </div>
          </div>
          <Message className="noBorder blue">
            Action Item Categories are used to group various Action Items into a
            collection.
          </Message>
          <Table striped compact="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Internal Name</Table.HeaderCell>
                <Table.HeaderCell>Title</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.values(allActionItemCategories).map((category) => (
                <Table.Row key={category.id}>
                  <Table.Cell>{category.id}</Table.Cell>
                  <Table.Cell>{category.internalName}</Table.Cell>
                  <Table.Cell>{category.title}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <div className="flexbox">
            <Text as="h3">ToDos</Text>
            <div>
              <Button secondary onClick={() => setTodoOpen(true)}>
                Create New ToDo
              </Button>
              {todoOpen && (
                <TodoAndImportantDateCreationModal
                  close={() => setTodoOpen(false)}
                  open={todoOpen}
                  isTodo
                />
              )}
            </div>
          </div>

          <Message className="noBorder blue">
            These ToDos will be shown to users in their Dashboard. To assign a
            todo for all users set it as default.
          </Message>

          <Table striped compact="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Id</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Action Link</Table.HeaderCell>
                <Table.HeaderCell>Deadline</Table.HeaderCell>
                <Table.HeaderCell>Category</Table.HeaderCell>
                <Table.HeaderCell>View Tooltip</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
                <Table.HeaderCell>Delete</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.values(allTodos).map((todo) => (
                <TodoRow
                  item={todo}
                  key={todo.id}
                  allActionItemCategories={allActionItemCategories}
                  allTooltips={allTooltips}
                />
              ))}
            </Table.Body>
          </Table>

          <div className="flexbox">
            <Text as="h3">Important Dates</Text>
            <div>
              <Button secondary onClick={() => setImportantDateOpen(true)}>
                Create New Important Date
              </Button>
              {importantDateOpen && (
                <TodoAndImportantDateCreationModal
                  close={() => setImportantDateOpen(false)}
                  open={importantDateOpen}
                />
              )}
            </div>
          </div>

          <Message className="noBorder blue">
            These Important Dates will be shown to users in their Dashboard. To
            assign an Important Date for all users set it as default.
          </Message>

          <Table striped compact="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Id</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Start Date</Table.HeaderCell>
                <Table.HeaderCell>End Date</Table.HeaderCell>
                <Table.HeaderCell>Category</Table.HeaderCell>
                <Table.HeaderCell>View Tooltip</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
                <Table.HeaderCell>Delete</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.values(allImportantDates).map((importantDate) => (
                <ImportantDateRow
                  item={importantDate}
                  key={importantDate.id}
                  allActionItemCategories={allActionItemCategories}
                  allTooltips={allTooltips}
                />
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </Container>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  allActionItems: state.admin.allActionItems,
  allTodos: selectTodos(state),
  allImportantDates: selectImportantDates(state),
  allActionItemCategories: selectActionItemCategories(state),
  allTooltips: state.admin.allTooltips,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAllTodos: () => dispatch(fetchAllTodos()),
  fetchAllImportantDates: () => dispatch(fetchAllImportantDates()),
  fetchAllActionItemCategoriesIfNeeded: () =>
    dispatch(fetchAllActionItemCategoriesIfNeeded()),
  fetchUserDocumentCategoriesIfNeeded: () =>
    dispatch(fetchUserDocumentCategoriesIfNeeded()),
  fetchAllTooltips: () => dispatch(fetchAllTooltips()),
})

export default connect<
  StateProps,
  DispatchProps,
  Record<string, unknown>,
  ReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(AllActionItemsPanel)
