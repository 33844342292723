import React, { useState } from 'react'
import { Button, Form, FormField, Modal, TextArea } from 'semantic-ui-react'
import { adminUpdateUserTransaction } from '../../../actions/admin/transactionActions'
import { centsToDollars } from '../../../utils/currencyHelpers'
import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import './index.scss'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface Props {
  modalOpen: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  transaction: Transaction
  onUpdated?: () => void
}
const AdminNotesModal: React.FC<Props> = ({
  modalOpen,
  setModalOpen,
  transaction,
  onUpdated = () => undefined,
}) => {
  const [adminNotes, setAdminNotes] = useState(transaction.adminNotes || '')
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  const createOrUpdateAdminNotes = async () => {
    setLoading(true)
    await adminUpdateUserTransaction(Number(transaction.id), {
      adminNotes,
    })(dispatch)
    onUpdated()
    setLoading(false)
    setModalOpen(false)
  }

  return (
    <Modal
      id="adminNotesModal"
      centered={false}
      open={modalOpen}
      onOpen={() => setModalOpen(true)}
      onClose={() => {
        setAdminNotes(transaction.adminNotes || '')
        setModalOpen(false)
      }}
    >
      <Modal.Header>
        {transaction.adminNotes ? 'Update' : 'Create'} Admin Note
      </Modal.Header>
      <Modal.Content>
        <h2>Transaction Details</h2>
        <p>
          <b>Id:</b> {transaction.id}
        </p>
        <p>
          <b>Description:</b> {transaction.description}
        </p>
        <p>
          <b>Amount:</b> {centsToDollars(transaction.amountInCents)}
        </p>

        <h2>Admin Note</h2>
        <Form>
          <Form.Field
            as={FormField}
            control={TextArea}
            value={adminNotes}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setAdminNotes(e.target.value)
            }
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            setAdminNotes(transaction.adminNotes || '')
            setModalOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button primary disabled={loading} onClick={createOrUpdateAdminNotes}>
          {transaction.adminNotes ? 'Update' : 'Create'}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default AdminNotesModal
