import { useMemo } from 'react'
import { sortBy, isEqual } from 'lodash'

export type GroupedDropdownValue = string | number

export interface ChildDropdownOption<T extends GroupedDropdownValue> {
  value: T
  text: string | number
}

export interface ParentDropdownOption<T extends GroupedDropdownValue>
  extends ChildDropdownOption<T> {
  options: ChildDropdownOption<T>[]
  statementPermissions?: string | null
}

export const arraysHaveSameValues = <T,>(arr1: T[], arr2: T[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false
  }

  const sortedArr1 = sortBy(arr1)
  const sortedArr2 = sortBy(arr2)

  return isEqual(sortedArr1, sortedArr2)
}

/*
  If no values are selected, return undefined
      If only one value is selected, return the text of the selected child option
      If multiple values are selected and they all belong to the same parent, return the parent text
      If multiple values are selected and they belong to different parents, return 'Multiple'
*/
export const groupedDropdownLabel = <T extends GroupedDropdownValue>(
  value: T[],
  options: ParentDropdownOption<T>[],
  childOptionMap: {
    [key: string]: ChildDropdownOption<T> & {
      parent: ParentDropdownOption<T>
    }
  }
) => {
  if (!value.length) return undefined
  const firstOption = childOptionMap[value[0]]
  if (value.length === 1) {
    return firstOption?.text?.toString()
  }
  if (!firstOption?.parent?.value) {
    return 'Other'
  }
  const firstParentValue = firstOption.parent.value
  const parent = options.find((option) => option.value === firstParentValue)
  if (!parent) return 'Other'
  const parentChildrenIds = parent?.options.map((child) => child.value) || []
  if (arraysHaveSameValues(value, parentChildrenIds)) {
    return `${parent?.text?.toString()} (all)`
  }
  for (const val of value) {
    if (childOptionMap[val]?.parent?.value !== firstParentValue) {
      return 'Multiple'
    }
  }
  return `${parent?.text?.toString()} (multiple)`
}

export const generateChildOptionMap = <T extends GroupedDropdownValue>(
  options: ParentDropdownOption<T>[]
) => {
  const childOptions: {
    [key: GroupedDropdownValue]: ChildDropdownOption<T> & {
      parent: ParentDropdownOption<T>
    }
  } = {}
  for (const option of options) {
    for (const childOption of option.options) {
      if (childOption.value) {
        childOptions[childOption.value] = {
          ...childOption,
          parent: option,
        }
      }
    }
  }
  return childOptions
}

export const useGroupedDropdownLabel = <T extends GroupedDropdownValue>(
  value: T[],
  options: ParentDropdownOption<T>[]
) => {
  const childOptionMap = useMemo(() => {
    return generateChildOptionMap(options)
  }, [options])

  const text = useMemo(() => {
    return groupedDropdownLabel(value, options, childOptionMap)
  }, [value, options, childOptionMap])

  return { text, childOptionMap }
}
