import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Divider, Grid, Icon } from 'semantic-ui-react'
import currency from 'currency.js'
import moment from 'moment'

import { selectContractorByUuid } from '../../payroll.selectors'
import {
  getContractorName,
  getContractorWageText,
  getMinPayDate,
} from '../../helpers'
import HourlyCell from '../PayrollInput/HourlyCell'
import FixedCell from '../PayrollInput/FixedCell'
import ContractorPayrollHeader from './ContractorPayrollHeader'
import LastContractorPayments from './LastContractorPayments'
import { formatCurrency } from '../../../../utils/currencyHelpers'
import {
  Button,
  DatePicker,
  GridRowColumn,
  Table,
  Text,
} from '../../../../components/BaseComponents'
import { FlsaStatus } from '../../employeeJob.slice'
import { useReselector } from '../../../../utils/sharedHooks'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'

const ContPayrollPaymentInputTab = ({
  contractorUuid,
  payDate: initPayDate,
  hours: initHours,
  bonus: initBonus,
  reimbursement: initReimbursement,
  wage: initWage,
  goForward,
  goBack,
}: {
  contractorUuid: string
  payDate: string
  hours: string
  bonus: string
  wage: string
  reimbursement: string
  goForward: (_: {
    payDate: string
    hours?: string
    wage?: string
    bonus: string
    reimbursement: string
  }) => void
  goBack: () => void
}) => {
  const hoursRef = useRef<HourlyCell>(null)
  const bonusRef = useRef<FixedCell>(null)
  const reimRef = useRef<FixedCell>(null)
  const wageRef = useRef<FixedCell>(null)
  const contractor = useReselector(selectContractorByUuid, contractorUuid)
  const [payDate, setPayDate] = useState(
    initPayDate ? moment(initPayDate).format(DATE_FORMATS.INPUT) : ''
  )
  const [total, setTotal] = useState(0)

  // User payment configs don't affect contractors.  It will always be 4 for contractors with direct deposit
  const minPayDate = useMemo(() => getMinPayDate(4).toDate(), [])

  // We're using the standard payroll's components which use event listeners.  It's a little overkill for contractor
  // payments since there is only one row but it's cleaner to use the same components instead of duplicating them
  const valueChanged = useCallback(() => {
    if (!contractor?.uuid) {
      return
    }
    setTotal(
      currency(contractor.hourly_rate || 0)
        .multiply(hoursRef.current?.calculate().hours || 0)
        .add(bonusRef.current?.calculate().amount || 0)
        .add(reimRef.current?.calculate().amount || 0)
        .add(wageRef.current?.calculate().amount || 0).value
    )

    // Not sure why the refs aren't dependencies since their existence directly effects the result
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contractor?.hourly_rate,
    contractor?.uuid,
    hoursRef.current,
    bonusRef.current,
    reimRef.current,
    wageRef.current,
  ])

  useEffect(() => {
    document.addEventListener(`inputChange${contractorUuid}`, valueChanged)

    return () => {
      document.removeEventListener(`inputChange${contractorUuid}`, valueChanged)
    }
  }, [contractorUuid, valueChanged])

  // Set the initial total value
  useEffect(() => {
    valueChanged()
  }, [valueChanged])

  useEffect(() => {
    if (!payDate && minPayDate) {
      setPayDate(moment(minPayDate).format(DATE_FORMATS.INPUT))
    }
  }, [minPayDate, payDate])

  const submit = useCallback(() => {
    goForward({
      payDate: moment(payDate, DATE_FORMATS.INPUT).format(
        DATE_FORMATS.GUSTO_SUBMIT
      ),
      hours: hoursRef.current?.calculate().hours,
      wage: wageRef.current?.calculate().amount,
      bonus: bonusRef.current?.calculate().amount || '',
      reimbursement: reimRef.current?.calculate().amount || '',
    })
  }, [goForward, payDate])

  if (!contractor) {
    return null
  }

  const isHourly = contractor.wage_type === 'Hourly'

  return (
    <Grid>
      <GridRowColumn>
        <ContractorPayrollHeader currentTab={1} />
      </GridRowColumn>
      <Grid.Row>
        <Grid.Column width={10}>
          <Grid>
            <GridRowColumn>
              <Text as="h1">
                Select a date to pay {getContractorName(contractor)}
              </Text>
            </GridRowColumn>

            {minPayDate && (
              <GridRowColumn>
                <DatePicker
                  value={payDate}
                  onChange={setPayDate}
                  minDate={minPayDate}
                  placeholder="Please enter a date"
                />
              </GridRowColumn>
            )}
          </Grid>
        </Grid.Column>
        <Grid.Column width={6}>
          <LastContractorPayments contractorUuid={contractorUuid} />
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <GridRowColumn>
        <Text as="h3">
          {isHourly ? 'Enter hours and payments' : 'Enter payments'}
        </Text>
      </GridRowColumn>
      <GridRowColumn>
        <Text>
          To pay this contractor, please enter the{' '}
          {isHourly ? 'number of hours worked.' : 'amount of wages owed.'}
        </Text>
      </GridRowColumn>
      <GridRowColumn>
        <Table basic="very">
          <Table.Header>
            <Table.Row style={{ height: 80 }}>
              <Table.HeaderCell width={4}>Contractor</Table.HeaderCell>
              <Table.HeaderCell width={5}>
                {isHourly && (
                  <>
                    Hours (H)
                    <br />
                    <br />
                  </>
                )}
                Bonus (B)
              </Table.HeaderCell>
              <Table.HeaderCell width={5}>
                {!isHourly && (
                  <>
                    Wage (W)
                    <br />
                    <br />
                  </>
                )}
                Reimbursement (R)
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>Total</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <b>{getContractorName(contractor, true)}</b>
                <br />
                {getContractorWageText(contractor)}
              </Table.Cell>
              <Table.Cell>
                {isHourly && (
                  <HourlyCell
                    employeeUuid={contractorUuid}
                    hourlyComp={{
                      name: 'Regular',
                      hours: initHours,
                      job_uuid: '',
                      compensation_multiplier: 1,
                      // This is a hack to get contractor payroll hourly comp to match types.  It isn't used in calculation
                      flsa_status: FlsaStatus.Nonexempt,
                    }}
                    ref={hoursRef}
                  />
                )}
                <FixedCell
                  employeeUuid={contractorUuid}
                  fixedComp={{
                    name: 'Bonus',
                    amount: initBonus,
                    job_uuid: '',
                  }}
                  ref={bonusRef}
                />
              </Table.Cell>
              <Table.Cell>
                {!isHourly && (
                  <FixedCell
                    employeeUuid={contractorUuid}
                    fixedComp={{
                      name: 'Wage',
                      amount: initWage,
                      job_uuid: '',
                    }}
                    ref={wageRef}
                  />
                )}
                <FixedCell
                  employeeUuid={contractorUuid}
                  fixedComp={{
                    name: 'Reimbursement',
                    amount: initReimbursement,
                    job_uuid: '',
                  }}
                  ref={reimRef}
                />
              </Table.Cell>
              <Table.Cell>
                <b>{formatCurrency(total)}</b>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </GridRowColumn>
      <Grid.Row>
        <Grid.Column width={4}>
          <Button variant="secondary" onClick={goBack} fullWidth>
            Go Back
          </Button>
        </Grid.Column>
        <Grid.Column width={8} />
        <Grid.Column width={4}>
          <Button onClick={submit} fullWidth>
            Continue <Icon name="arrow right" />
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default ContPayrollPaymentInputTab
