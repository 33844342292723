import { Label } from '../../../../components/BaseComponents'
import {
  getUserById,
  selectPrimaryMembershipForUser,
} from '../../../../selectors/user.selectors'
import { useReselector } from '../../../../utils/sharedHooks'
import { canAutoGenerateBS } from './helpers'

const NeedsManualBalanceSheetLabel = ({ userId }: { userId?: number }) => {
  const user = useReselector(getUserById, userId)

  const membership = useReselector(selectPrimaryMembershipForUser, userId)
  const canAutoGenerate = canAutoGenerateBS(
    membership?.startDate,
    user?.financialProfile?.entityChangeElectionDate
  )
  if (!canAutoGenerate) {
    return <Label color="red">Manual Balance Sheet for 2023 Taxes Needed</Label>
  }

  return null
}

export default NeedsManualBalanceSheetLabel
