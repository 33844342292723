import axios from 'axios'
import { keyBy } from 'lodash'
import { fetchWrapper } from '../../reducers/fetch'
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link/src/types'
import {
  receivePlaidItems,
  PlaidItem,
} from '../../reducers/finances/plaidItemReducer'
import { fetchFinancialAccountsIfNeeded } from '../financialAccountActions'

export const fetchLinkToken = (redirectUri: string | undefined) =>
  fetchWrapper({
    defaultErrorMessage: 'Unable to grab token',
    fetchFunction: () =>
      axios
        .get<{ link_token: string }>('/finances/api/v1/plaid/link_token', {
          params: {
            redirectUri,
          },
        })
        .then((json) => json.data),
  })

export const fetchUpdateLinkToken = (
  redirectUri: string | undefined,
  plaidItemId: number | null,
  disconnected = false,
  automaticStatements = false
) =>
  fetchWrapper({
    defaultErrorMessage: 'Unable to grab Plaid update link',
    fetchFunction: () => {
      if (!plaidItemId) {
        return null
      }
      const params = {
        redirectUri,
        automaticStatements: automaticStatements || undefined,
        disconnected: disconnected || undefined,
      }
      return axios
        .get<{
          link_token: string
        }>(`/finances/api/v1/plaid/update_link_token/${plaidItemId}`, {
          params,
        })
        .then((json) => json.data)
    },
  })

export const fetchAccessToken = (
  publicToken: string,
  metadata: PlaidLinkOnSuccessMetadata
) =>
  fetchWrapper({
    defaultErrorMessage: 'Unable to grab token',
    fetchFunction: (dispatch) =>
      axios
        .post<PlaidItem[]>('/finances/api/v1/plaid/access_token', {
          public_token: publicToken,
          metadata,
        })
        .then((json) => {
          dispatch(fetchFinancialAccountsIfNeeded(true))
          dispatch(receivePlaidItems(keyBy(json.data, 'id')))
          return json.data
        }),
  })
