export interface PrismaOffsetPagination {
  skip: number
  take: number
}

export interface PrismaCursorPagination {
  cursor?: string
  take?: number
}

export interface OffsetPaginatedResponse<T> {
  data: T[]
  pagination: Partial<PrismaOffsetPagination> & {
    totalItems: number
  }
}

export interface CursorPaginatedResponse<T> {
  data: T[]
  pagination: {
    nextCursor?: string
  }
}

export const buildUrlWithOffsetPagination = ({
  fetchUrlBase,
  additionalQueryParams,
  pagination,
}: {
  fetchUrlBase: string
  additionalQueryParams?: string
  pagination: PrismaOffsetPagination
}) => {
  const take = pagination.take === undefined ? '' : `&take=${pagination.take}`
  const skip = pagination.skip === undefined ? '' : `?skip=${pagination.skip}`
  return `${fetchUrlBase}${skip}${take}${additionalQueryParams || ''}`
}
