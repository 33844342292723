import axios from 'axios'
import { keyBy } from 'lodash'

import {
  FinancialAccount,
  receiveDeleteFinancialAccount,
  receiveFinancialAccounts,
  receiveUpdateFinancialAccount,
  TransactionSyncState,
} from '../reducers/finances/financialAccountsReducer'
import { fetchIfNeededWrapper, fetchWrapper } from '../reducers/fetch'
import { PlaidAccount } from 'react-plaid-link'
import { fetchPlaidItemsIfNeeded } from './plaidItemActions'
import {
  receiveUpdatePlaidItem,
  PlaidItem,
} from '../reducers/finances/plaidItemReducer'

interface FinancialAccountUpdateRequest {
  markedPersonalAt?: Date | null
  isHidden?: boolean
  transactionSyncState?: TransactionSyncState
  onboardingAnswers?: {
    [key: string]: boolean | undefined
  }
}

export const FETCH_FINANCIAL_ACCOUNT_KEY = 'FETCH_FINANCIAL_ACCOUNT_KEY'
export const fetchFinancialAccountsIfNeeded = (alwaysFetch = false) =>
  fetchIfNeededWrapper({
    defaultErrorMessage: 'There was an error fetching your connected accounts.',
    alwaysFetch,
    fetchKey: FETCH_FINANCIAL_ACCOUNT_KEY,
    fetchFunction: (dispatch) =>
      axios
        .get<FinancialAccount[]>('/finances/api/v1/accounts')
        .then((json) => {
          dispatch(receiveFinancialAccounts(keyBy(json.data, 'id')))
          return json.data
        }),
  })

export const updateFinancialAccount = (
  financialAccountId: number,
  data: FinancialAccountUpdateRequest
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error updating your financial account',
    fetchFunction: (dispatch) =>
      axios
        .put<FinancialAccount>(
          `/finances/api/v1/accounts/${financialAccountId}`,
          data
        )
        .then((json) => {
          dispatch(receiveUpdateFinancialAccount(json.data))
          return json.data
        }),
  })

export const deleteFinancialAccount = (financialAccountId: number) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error removing the financial account.',
    fetchFunction: (dispatch) =>
      axios
        .delete<{
          deletedId: number
          financialProfileId: number
        }>(`/finances/api/v1/accounts/${financialAccountId}`)
        .then((json) => {
          dispatch(receiveDeleteFinancialAccount(json.data.deletedId))
          return json.data
        }),
  })

// Updating Plaid
export const updatePlaidItem = (
  plaidItemId: number | null,
  data: {
    markedCannotReconnectAt?: Date | null
    needsReconnection?: boolean | null
    connectedOn?: Date
    disconnectedOn?: null
    errorObject?: null
    automaticStatements?: boolean
  }
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error updating the PlaidItem.',
    fetchFunction: (dispatch) => {
      if (!plaidItemId) {
        return null
      }
      return axios
        .post<PlaidItem>(`/finances/api/v1/plaid/items/${plaidItemId}`, data)
        .then((json) => {
          // Refetch financial accounts after update
          dispatch(fetchFinancialAccountsIfNeeded(true))
          dispatch(receiveUpdatePlaidItem(json.data))
          return json.data
        })
    },
  })

// deletes a user's plaid item, only used during onboarding
export const deletePlaidItem = (plaidItemId: number) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error deleting the Plaid Item.',
    fetchFunction: (dispatch) =>
      axios
        .delete<number>(`/finances/api/v1/plaid/items/${plaidItemId}`)
        .then((json) => {
          dispatch(fetchFinancialAccountsIfNeeded(true))
          dispatch(fetchPlaidItemsIfNeeded(true))
          return json.data
        }),
  })

// Send Plaid link event data to our db
export const createPlaidLinkEvent = (
  data:
    | {
        institutionId?: string | null
        linkSessionId: string
        errorCode: string
        errorType: string | null
        exitStatus: string | null
        eventType: string
      }
    | {
        institutionId?: string
        accounts: PlaidAccount[]
        linkSessionId: string
        eventType: string
      }
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error creating a plaid link event.',
    fetchFunction: () => {
      return axios.post<Record<string, unknown>>(
        '/finances/api/v1/plaid/link_event',
        data
      )
    },
  })

// Updating Plaid
export const updateAccounts = (
  plaidItemId: number | null,
  data: { plaidAccounts: PlaidAccount[] }
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error updating the Plaid accounts.',
    fetchFunction: (dispatch) => {
      if (!plaidItemId) {
        return null
      }
      return axios
        .post<PlaidItem>(`/finances/api/v1/accounts/${plaidItemId}`, data)
        .then((json) => {
          // Refetch financial accounts after update
          dispatch(fetchFinancialAccountsIfNeeded(true))
          dispatch(receiveUpdatePlaidItem(json.data))
          return json.data
        })
    },
  })

export enum StatementStatusType {
  PLAID_STATEMENT = 'plaid_statement',
  LIMITED_BANK_ACCESS = 'limited_bank_access',
  NO_SUPPORT = 'no_support',
}
export interface StatementStatus {
  id: number
  status: StatementStatusType
}

export const ACCOUNT_UPLOAD_STATUSES_KEY = 'ACCOUNT_UPLOAD_STATUSES_KEY'
export const getAccountUploadStatuses = () =>
  fetchWrapper({
    fetchKey: ACCOUNT_UPLOAD_STATUSES_KEY,
    defaultErrorMessage: 'There was an error getting upload statuses.',
    fetchFunction: () =>
      axios
        .get<
          StatementStatus[]
        >('/finances/api/v1/accounts/statement_upload_statuses')
        .then((json) => {
          return json.data
        }),
  })
