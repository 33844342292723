import { ElementType, useCallback, useMemo } from 'react'
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

import { ALL_ROUTES } from '../components/Routes/config'

// This is a way to get around connected components needing route params.  If your component is a FC use `useParams` instead
// Usage: if mapStateToProps uses params you need to 1. Set the connected component to a variable 2. Export default as withRouter(CON_COMP_NAME)
// If mapStateToProps doesn't use params, but the inner component does, you can just wrap it within the connect (or use above)
export function withRouter(Component: ElementType) {
  return function <U>(props: U) {
    const params = useParams()
    const location = useLocation()
    return <Component params={params} location={location} {...props} />
  }
}

// Used to pick out the current route from the route config.  Useful if you need to gather extra info from that object
// ie for background colors that need to be pulled out in the route wrappers
export const useRouteConfig = () => {
  const location = useLocation()

  const currentConfig = useMemo(
    () =>
      Object.entries(ALL_ROUTES).find(([path]) =>
        matchPath(path, location.pathname)
      ),
    [location.pathname]
  )

  return {
    key: currentConfig?.[0],
    config: currentConfig?.[1],
  }
}

export const useNavigateWithLocation = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return useCallback(
    (path: string) => {
      navigate(path, { state: { from: location } })
    },
    [location, navigate]
  )
}

export const buildQueryParams = (
  queryParams: Record<string, string | boolean | number>
) =>
  Object.entries(queryParams)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
