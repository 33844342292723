import { useMemo, useCallback, useEffect } from 'react'
import { Table } from 'semantic-ui-react'
import { DateTime } from 'luxon'

import { DatePicker, Text } from '../../../../components/BaseComponents'
import { GroupedDropdown } from '../../../../components/shared/GroupedDropdown'
import { useGroupedDropdownLabel } from '../../../../components/shared/groupedDropdownHelpers'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import { getCurrentUser } from '../../../../selectors/user.selectors'
import { compareArrays } from '../../../Admin/Documents/helpers'
import { selectAdminOrUserGroupedFinancialAccountOptions } from '../../../../selectors/financeSelectors'
import { userUpdateStatement } from '../../userDocuments.slice'
import { DATE_FORMATS, DATE_FORMATS_LUXON } from '../../../../utils/dateHelpers'
import { selectUserDocumentCategoryByDocument } from '../../userDocuments.selector'
import DocumentFileNameCell from './DocumentFileNameCell'
import DocumentActionCell from './DocumentActionCell'
import { fetchFinancialAccountsIfNeeded } from '../../../../actions/financialAccountActions'
import { RowComponentProps } from './UserDocumentsTableRow'

export const OnboardingStatementRow = ({
  document,
  editable = true,
}: RowComponentProps) => {
  const dispatch = useAppDispatch()
  const user = useReselector(getCurrentUser)
  const category = useReselector(selectUserDocumentCategoryByDocument, document)
  const financialAccountOptions = useReselector(
    selectAdminOrUserGroupedFinancialAccountOptions,
    user?.id
  )

  useEffect(() => {
    dispatch(fetchFinancialAccountsIfNeeded())
  }, [dispatch])

  const handleStatementMonthChange = useCallback(
    (statementMonth?: string) =>
      document.id &&
      dispatch(
        userUpdateStatement(document.id, {
          statementMonth,
          statement: {
            statementMonth,
          },
        })
      ),
    [dispatch, document]
  )
  const groupedDropdownValue = useMemo(() => {
    if (!document.statement?.statementFinancialAccounts?.length) {
      return []
    }
    return document.statement.statementFinancialAccounts?.map(
      (account) => account.financialAccountId
    )
  }, [document.statement])

  const handleStatementFinancialAccountChange = useCallback(
    (newFinancialAccountIds: (string | number)[]) => {
      if (!document.statement?.id) return
      const statementFinancialAccounts =
        document.statement.statementFinancialAccounts || []
      const statementFinancialAccountIds = statementFinancialAccounts.map(
        (account) => account.financialAccountId
      )
      const { newVals, removedVals } = compareArrays(
        statementFinancialAccountIds,
        newFinancialAccountIds
      )

      /*
          newFinancialAccountIds should all be numbers but this ensures that only numbers 
          are passed to the backend, which expects an array of IDs 
        */
      const accountsToCreate = newVals.filter(
        (val) => typeof val === 'number'
      ) as number[]
      const accountsToDelete = removedVals.filter(
        (val) => typeof val === 'number'
      ) as number[]

      dispatch(
        userUpdateStatement(document.id, {
          statementFinancialAccounts: {
            accountsToCreate,
            accountsToDelete,
          },
        })
      )
    },
    [dispatch, document]
  )

  const statementMonth = useMemo(() => {
    if (editable) {
      return (
        <div className="monthPicker">
          <DatePicker
            placeholder="Select month"
            value={document.statement?.statementMonth || undefined}
            onChange={handleStatementMonthChange}
            showMonthYearPicker
            dateFormat={DATE_FORMATS.YEAR_MONTH}
            placeholderText="MM/YYYY"
            style={{ width: 160 }}
          />
        </div>
      )
    }
    if (document.statement?.statementMonth) {
      return (
        <Text>
          {DateTime.fromFormat(
            document.statement?.statementMonth,
            DATE_FORMATS_LUXON.YEAR_MONTH
          ).toFormat(DATE_FORMATS_LUXON.MONTH_YEAR)}
        </Text>
      )
    }
    return null
  }, [document.statement?.statementMonth, editable, handleStatementMonthChange])

  const { text } = useGroupedDropdownLabel(
    groupedDropdownValue,
    financialAccountOptions
  )

  return (
    <Table.Row key={document.id}>
      <DocumentFileNameCell category={category} document={document} />
      <Table.Cell>{statementMonth}</Table.Cell>
      <Table.Cell>
        {editable ? (
          <GroupedDropdown
            value={groupedDropdownValue}
            onChange={handleStatementFinancialAccountChange}
            options={financialAccountOptions}
            allowOneParent
            height={50}
            width={240}
          />
        ) : (
          <Text>{text}</Text>
        )}
      </Table.Cell>
      {editable && <DocumentActionCell document={document} />}
    </Table.Row>
  )
}
