import { createSelector } from 'reselect'
import { ReduxState, filterNulls } from '../utils/typeHelpers'
import {
  getAllTransactions,
  getTransactionsByUserId,
} from '../features/Transactions/transactions.selectors'
import { JournalEntry } from '../features/JournalEntries/types'

export const selectJournalEntries = (state: ReduxState) => state.journalEntries

export const selectJournalEntriesById = createSelector(
  selectJournalEntries,
  (_: unknown, id: number) => id,
  (journalEntries, id) => (journalEntries[id] ? journalEntries[id] : null)
)

export const selectJournalEntriesByUserId = createSelector(
  selectJournalEntries,
  (_: unknown, userId: number | null) => userId,
  (journalEntries, id) =>
    Object.values(journalEntries).filter(
      (journalEntry) => journalEntry.userId === id
    )
)

export const selectJournalEntryTransactionsByUserId = createSelector(
  getTransactionsByUserId,
  selectJournalEntries,
  (transactions, journalEntries) =>
    transactions.map((transaction) => ({
      ...transaction,
      journalEntries: filterNulls(
        transaction.journalEntries?.map(
          (journalEntry) => journalEntries[journalEntry.id]
        ) || []
      ),
    }))
)

export const selectJournalEntryTransactionsByIds = createSelector(
  getAllTransactions,
  selectJournalEntries,
  (_: unknown, transactionIds: number[]) => transactionIds,
  (transactions, journalEntries, transactionIds) => {
    return filterNulls(
      transactionIds.map((transactionId) => {
        if (!transactions[transactionId]) return null
        return {
          ...transactions[transactionId],
          journalEntries: filterNulls(
            transactions[transactionId].journalEntries || []
          ),
        }
      })
    )
  }
)

export const selectJournalEntriesByIds = createSelector(
  selectJournalEntries,
  (_: unknown, journalEntryIds: number[]) => journalEntryIds,
  (journalEntries, journalEntryIds) =>
    Object.values(journalEntries).filter((journalEntry: JournalEntry) =>
      journalEntryIds.includes(journalEntry.id)
    )
)

export const selectJournalEntryTransactionById = createSelector(
  getAllTransactions,
  selectJournalEntries,
  (_: unknown, transactionId: number) => transactionId,
  (transactions, journalEntries, transactionId) => {
    const transaction = transactions[transactionId]
    if (!transaction) return null
    return {
      ...transaction,
      journalEntries: filterNulls(
        transaction.journalEntries?.map(
          (journalEntry) => journalEntries[journalEntry.id]
        ) || []
      ),
    }
  }
)

export const selectJournalEntrySplitTransactionById = createSelector(
  getAllTransactions,
  selectJournalEntries,
  (_: unknown, transactionId: number) => transactionId,
  (_: unknown, __: unknown, parentId: number | null | undefined) => parentId,
  (transactions, journalEntries, transactionId, parentId) => {
    if (!parentId) {
      return null
    }
    const parentTransaction = transactions[parentId]
    if (!parentTransaction) return null
    const splitTransaction = parentTransaction.splitTransactions?.find(
      (splitTransaction) => splitTransaction.id === transactionId
    )
    if (!splitTransaction) return null
    return {
      ...splitTransaction,
      journalEntries: filterNulls(
        splitTransaction.journalEntries?.map(
          (journalEntry) => journalEntries[journalEntry.id]
        ) || []
      ),
    }
  }
)
