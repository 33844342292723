import { useCallback, useEffect, useMemo, useState } from 'react'
import { OrderBy } from '../components/Admin/PaginatedTable/AdminBaseTable'
import { isArray } from 'lodash'

export const useTableSort = <T>({
  // Values to sort
  values,
  // Default sort order
  defaultSortBy,
  // Sorting function.  This hook will do the pagination and sort order portion of sorting
  sortFunction,
  // Page size
  pageSize = 50,
}: {
  defaultSortBy: string
  values: T[]
  sortFunction: (
    values: T[],
    sortBy: string,
    sortDir?: 'ascending' | 'descending'
  ) => T[]
  pageSize?: number
}) => {
  const [sortBy, setSortBy] = useState(defaultSortBy)
  const [sortDir, setSortDir] = useState<'ascending' | 'descending'>(
    'descending'
  )
  const [page, setPage] = useState(1)

  const resort = useCallback(
    (newSortDir: string) => {
      setPage(1)
      if (newSortDir === sortBy) {
        setSortDir((prevDir) =>
          prevDir === 'ascending' ? 'descending' : 'ascending'
        )
      } else {
        setSortDir('descending')
        setSortBy(newSortDir)
      }
    },
    [sortBy]
  )

  // If values change (ie filtered) set page back at 1
  useEffect(() => {
    setPage(1)
  }, [values])

  const getHeaderCellProps = useCallback(
    (headerSortBy: string) => ({
      sorted: headerSortBy === sortBy ? sortDir : undefined,
      onClick: () => resort(headerSortBy),
    }),
    [resort, sortBy, sortDir]
  )

  const paginationProps = useMemo(
    () => ({
      currentPage: page,
      onPageClick: setPage,
      pages: Math.ceil(values.length / pageSize),
    }),
    [page, pageSize, values.length]
  )

  const sortedValues = useMemo(() => {
    const offset = pageSize * (page - 1)
    const sortedProfiles = sortFunction(values, sortBy, sortDir)

    if (sortDir === 'descending') {
      sortedProfiles.reverse()
    }

    return sortedProfiles.slice(offset, offset + pageSize)
  }, [page, pageSize, sortBy, sortDir, sortFunction, values])

  return {
    // Sorted and paginated values
    sortedValues,
    // Header cell props.  Usage `<Header.Cell {...getHeaderCellProps(SORT_ORDER.USER)}>User</Header.Cell>`
    getHeaderCellProps,
    // Pagination props.  Usage `<Pagination {...paginationProps} />`
    paginationProps,
  }
}

export const serializeOrderBy = (
  orderBy: OrderBy | OrderBy[] | undefined
): string[] => {
  const normalize = (sort: OrderBy) =>
    `${sort.column}:${sort.direction === 'ascending' ? 'asc' : 'desc'}`
  return isArray(orderBy)
    ? orderBy.map(normalize)
    : orderBy
      ? [normalize(orderBy)]
      : []
}
