import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Divider, Grid, Image } from 'semantic-ui-react'
import {
  Accordion,
  Card,
  GridRowColumn,
  Icon,
  Link,
  Radio,
  Text,
} from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import { useAnalyticsView } from '../../Amplitude'
import { useEffect } from 'react'

interface ISetupBankAccountPreScreenCardProps {
  hasBusinessCheckingAccount: boolean | undefined
  setHasBusinessCheckingAccount: (hasBusinessCheckingAccount: boolean) => void
}

export const SetupBankAccountPreScreenCard = ({
  hasBusinessCheckingAccount,
  setHasBusinessCheckingAccount,
}: ISetupBankAccountPreScreenCardProps) => {
  const pageView = useAnalyticsView()

  useEffect(() => {
    pageView('bank account prescreen')
  }, [pageView])

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={5}>
          <Image
            src={
              'https://heard-images.s3.amazonaws.com/assets/pavilion_with_green_background.png'
            }
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Grid>
            <GridRowColumn>
              <Text
                as="h3"
                style={{
                  marginBottom: 8,
                }}
              >
                Do you have a checking account used only for your business?
              </Text>
              <Text>
                If you have personal and business transactions in the same
                account, select <b>No</b>.
              </Text>
            </GridRowColumn>
            <GridRowColumn
              style={{
                margin: '0 0 10px 0',
                padding: '0',
              }}
            >
              <Radio
                label="Yes"
                checked={hasBusinessCheckingAccount}
                onChange={() => setHasBusinessCheckingAccount(true)}
              />
            </GridRowColumn>
            <GridRowColumn
              style={{
                margin: '0 0 10px 0',
                padding: '0',
              }}
            >
              <Radio
                label="No"
                checked={hasBusinessCheckingAccount === false}
                onChange={() => setHasBusinessCheckingAccount(false)}
              />
            </GridRowColumn>

            {hasBusinessCheckingAccount && (
              <GridRowColumn>
                <Card
                  style={{
                    backgroundColor: Colors.stone,
                  }}
                >
                  <Text>Great. You can connect it in the next step.</Text>
                </Card>
              </GridRowColumn>
            )}
            {hasBusinessCheckingAccount === false && (
              <GridRowColumn>
                <Card
                  style={{
                    backgroundColor: Colors.stone,
                  }}
                >
                  <Text
                    style={{
                      marginBottom: 5,
                    }}
                  >
                    <b>No problem.</b>
                  </Text>
                  <Text
                    style={{
                      marginBottom: 5,
                    }}
                  >
                    Heard requires a checking account used only for your
                    business. We recommend opening a new one with any bank or
                    institution, then coming back to connect it.
                  </Text>
                  <Link
                    href={
                      'https://support.joinheard.com/hc/en-us/articles/23407015266967-Using-a-dedicated-business-bank-account-for-your-practice#open_business_account'
                    }
                    newPage
                  >
                    Where can I open a business checking account?
                    <Icon
                      icon={regular('arrow-right')}
                      style={{ marginLeft: 8 }}
                    />
                  </Link>
                </Card>
              </GridRowColumn>
            )}

            <Divider />

            <GridRowColumn>
              <Accordion
                title="Why does my account need to be only for business?"
                content={
                  <Text>
                    It’s important to keep your business-related transactions
                    separate from your personal transactions, so we can give you
                    accurate tax estimates, reports, and helpful insights.{' '}
                    <br />
                    <br />
                    You should deposit all practice income and pay all business
                    expenses from your business account. This saves time, avoids
                    bookkeeping errors, and protects you and your personal
                    assets in the long run.
                  </Text>
                }
                variant="text"
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                }}
              />
              <Accordion
                title="What if I don’t have a business account at all?"
                content={
                  <Text>
                    You can open a business checking account with any bank or
                    provider, then come back when you’re ready. We recommend
                    going with a larger institution. They’re more compatible
                    with Heard’s features, including automatic monthly statement
                    uploads.
                    <br />
                    <br />
                    <Text>Some popular banks with Heard customers:</Text>
                    <br />
                    <ul style={{ paddingLeft: 10, marginTop: 0 }}>
                      <li>
                        <Link
                          as="secondaryLink"
                          href="https://www.bluevine.com/partner/heard-checking"
                          newPage
                        >
                          Bluevine
                        </Link>
                      </li>
                      <li>
                        <Link
                          as="secondaryLink"
                          href="https://www.bankofamerica.com/smallbusiness/deposits/business-advantage-banking/"
                          newPage
                        >
                          Bank of America
                        </Link>
                      </li>
                      <li>
                        <Link
                          as="secondaryLink"
                          href="https://www.becu.org/business-banking/checking"
                          newPage
                        >
                          BECU
                        </Link>
                      </li>
                      <li>
                        <Link
                          as="secondaryLink"
                          href="https://www.chase.com/business/banking/checking"
                          newPage
                        >
                          Chase
                        </Link>
                      </li>
                      <li>
                        <Link
                          as="secondaryLink"
                          href="https://www.td.com/us/en/small-business/checking-accounts"
                          newPage
                        >
                          TD Bank
                        </Link>
                      </li>
                      <li>
                        <Link
                          as="secondaryLink"
                          href="https://www.wellsfargo.com/biz/checking/"
                          newPage
                        >
                          Wells Fargo
                        </Link>
                      </li>
                    </ul>
                    <Link
                      href={
                        'https://support.joinheard.com/hc/en-us/articles/23407015266967-Using-a-dedicated-business-bank-account-for-your-practice#open_business_account'
                      }
                      newPage
                    >
                      Learn more
                      <Icon
                        icon={regular('square-up-right')}
                        style={{ marginLeft: 6 }}
                      />
                    </Link>
                  </Text>
                }
                variant="text"
              />
            </GridRowColumn>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
