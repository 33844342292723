import { ReactNode } from 'react'
import { Grid } from 'semantic-ui-react'

import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { GridRowColumn, Popup, Text } from '../../../components/BaseComponents'

export const DetailsTooltip = ({
  label,
  content,
  track,
  openOnHover,
}: {
  label: string | ReactNode
  content: string | ReactNode
  track?: () => void
  openOnHover?: boolean
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  return (
    <Popup
      hoverable
      content={
        // max width of 200 to account for tiny devices ~320px in width
        <Grid style={isMobile ? { maxWidth: 200 } : undefined}>
          <GridRowColumn>
            <Text as="h3" style={{ marginBottom: 8 }}>
              {label}
            </Text>
            {content}
          </GridRowColumn>
        </Grid>
      }
      onOpen={() => track?.()}
      position={isMobile ? 'top right' : 'top center'}
      on={openOnHover ? 'hover' : 'click'}
    />
  )
}

export default DetailsTooltip
