import { Grid } from 'semantic-ui-react'
import { times } from 'lodash'

import {
  Accordion,
  Button,
  Checkbox,
  GridRowColumn,
  Pill,
  Text,
} from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'

const getStyleFromNum = (num: number) => ({
  // Offset to avoid certain colors
  backgroundColor: Object.values(Colors)[num + 7],
})

const GridStories = () => (
  <Grid>
    <Grid.Row>
      {times(16).map((num) => (
        <Grid.Column width={1} key={num} style={getStyleFromNum(num)}>
          <Text>{num + 1}</Text>
        </Grid.Column>
      ))}
    </Grid.Row>

    <Grid.Row />

    <Grid.Row>
      {times(8).map((num) => (
        <Grid.Column width={2} key={num} style={getStyleFromNum(num)}>
          <Text>{num + 1}</Text>
        </Grid.Column>
      ))}
    </Grid.Row>

    <Grid.Row />

    <Grid.Row>
      {times(4).map((num) => (
        <Grid.Column width={4} key={num} style={getStyleFromNum(num)}>
          <Text>{num + 1}</Text>
        </Grid.Column>
      ))}
    </Grid.Row>

    <Grid.Row />

    <Grid.Row>
      {times(2).map((num) => (
        <Grid.Column width={8} key={num} style={getStyleFromNum(num)}>
          <Text>{num + 1}</Text>
        </Grid.Column>
      ))}
    </Grid.Row>

    <Grid.Row />

    {times(3).map((num) => (
      <GridRowColumn columnStyle={{ backgroundColor: Colors.green }} key={num}>
        <Text>Full rows (default)</Text>
      </GridRowColumn>
    ))}

    <Grid.Row />

    {times(3).map((num) => (
      <GridRowColumn
        short
        columnStyle={{ backgroundColor: Colors.orange }}
        key={num}
      >
        <Text>Short rows</Text>
      </GridRowColumn>
    ))}

    <Grid.Row />

    <GridRowColumn columnStyle={{ backgroundColor: Colors.yellow }}>
      <Text>Text sitting on other text</Text>
      <Text>Within same column</Text>
    </GridRowColumn>

    <Grid.Row />

    <GridRowColumn columnStyle={{ backgroundColor: Colors.yellow }}>
      <Text>
        Text sitting on other text
        <br />
        With text break in same column
      </Text>
    </GridRowColumn>

    <Grid.Row />

    <Grid.Row columns="equal">
      <Grid.Column style={{ backgroundColor: Colors.teal }}>
        <Text>Text within val</Text>
      </Grid.Column>
      <Grid.Column style={{ backgroundColor: Colors.yellow }}>
        <Text>that is non divisible</Text>
      </Grid.Column>
      <Grid.Column style={{ backgroundColor: Colors.orange }}>
        <Text>by 16</Text>
      </Grid.Column>
    </Grid.Row>

    <Grid.Row />

    <GridRowColumn
      centerContent
      columnStyle={{ backgroundColor: Colors.purple }}
    >
      <Pill>Centered content within row</Pill>
    </GridRowColumn>

    <Grid.Row />

    <GridRowColumn columnStyle={{ backgroundColor: Colors.magenta }}>
      <Accordion title="title 1" content="content 1" variant="default" />
      <Accordion title="title 2" content="content 2" variant="default" />
    </GridRowColumn>

    <Grid.Row />

    <GridRowColumn columnStyle={{ backgroundColor: Colors.accentGreen }}>
      <Button>button 1</Button>
      <Button>button 2</Button>
    </GridRowColumn>

    <Grid.Row />

    <GridRowColumn columnStyle={{ backgroundColor: Colors.navy }}>
      <Checkbox label="checkbox 1" />
      <br />
      <Checkbox label="checkbox 2" />
    </GridRowColumn>
  </Grid>
)

export default GridStories
