import { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, Loader, Dimmer } from 'semantic-ui-react'
import moment from 'moment'
import { orderBy, range } from 'lodash'
import { DateTime } from 'luxon'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import './payrollHistory.scss'

import {
  selectNetPayroll,
  selectPayrollByUuid,
  selectPayrollCanBeCanceled,
  selectPayrolls,
} from '../payroll.selectors'
import { getIsFetchingOrNotStarted } from '../../../reducers/fetch'
import {
  FETCH_PAYROLL_KEY,
  fetchAllPayrolls,
  putCancelPayroll,
} from '../payrollActions'
import { formatCurrency } from '../../../utils/currencyHelpers'
import {
  Modal,
  Text,
  Button,
  Table,
  Dropdown,
  Popup,
  Link,
  Alert,
  GridRowColumn,
  Icon,
  valsToDropdownOptions,
} from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import { useReselector } from '../../../utils/sharedHooks'
import { DATE_FORMATS, DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import { useAppDispatch } from '../../../utils/typeHelpers'

const CancelModal = ({ uuid, close }: { uuid: string; close: () => void }) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)

  const cancelPayroll = useCallback(async () => {
    setLoading(true)
    // This is a very slow call (4 sec)
    const res = await putCancelPayroll(uuid)(dispatch)

    setLoading(false)
    if (res) {
      close()
    }
  }, [close, dispatch, uuid])

  return (
    <Modal open size="tiny" onClose={close} closeIcon>
      <Modal.Header>Cancel Payroll</Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text>
              <b>You are canceling payroll. Are you sure you want to cancel?</b>
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Text>
              The information you have entered for this payroll will not be
              saved.
            </Text>
          </GridRowColumn>
          {loading ? (
            <>
              <GridRowColumn>
                <Dimmer active inverted>
                  <Loader active />
                </Dimmer>
              </GridRowColumn>
              <Grid.Row />
            </>
          ) : (
            <>
              <GridRowColumn>
                <Button
                  onClick={cancelPayroll}
                  style={{ backgroundColor: Colors.red }}
                  fullWidth
                >
                  Cancel Payroll
                </Button>
              </GridRowColumn>
              <GridRowColumn>
                <Button variant="secondary" onClick={close} fullWidth>
                  Go Back
                </Button>
              </GridRowColumn>
            </>
          )}
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

const PayrollRow = ({ uuid }: { uuid: string }) => {
  const [showModal, setShowModal] = useState(false)
  const payroll = useReselector(selectPayrollByUuid, uuid)
  const showCancel = useReselector(selectPayrollCanBeCanceled, uuid)

  if (!payroll) {
    return null
  }

  const {
    pay_period: { pay_schedule_uuid, start_date, end_date },
    processed_date,
    totals,
  } = payroll

  const companyDebit = totals?.company_debit || '0.00'

  const type = pay_schedule_uuid ? 'Regular' : 'Off-Cycle'

  return (
    <Table.Row>
      <Table.Cell style={{ paddingLeft: 10 }}>
        <Text>
          {processed_date
            ? moment(processed_date).format(DATE_FORMATS.DISPLAY_SHORT)
            : ''}
        </Text>
        {showCancel && (
          <Button onClick={() => setShowModal(true)} variant="link">
            Cancel
          </Button>
        )}
      </Table.Cell>
      <Table.Cell>{type}</Table.Cell>
      <Table.Cell>
        {start_date && end_date
          ? `${moment(start_date).format(
              DATE_FORMATS.DISPLAY_SHORT
            )} - ${moment(end_date).format(DATE_FORMATS.DISPLAY_SHORT)}`
          : ''}
      </Table.Cell>
      <Table.Cell>{formatCurrency(companyDebit)}</Table.Cell>
      <Table.Cell>
        <Link to={`/payroll/history/${uuid}`}>View Details</Link>
      </Table.Cell>
      {showModal && (
        <CancelModal uuid={uuid} close={() => setShowModal(false)} />
      )}
    </Table.Row>
  )
}

const EmployeePayrollHistoryTab = () => {
  const dispatch = useAppDispatch()
  const payrollFetching = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_PAYROLL_KEY
  )
  const [year, setYear] = useState(DateTime.now().year)
  const payrolls = useReselector(selectPayrolls)
  const { netDebit, netPayrollTaxes, netPaidToEmployees } = useReselector(
    selectNetPayroll,
    year
  )

  const yearOptions = valsToDropdownOptions(
    range(2020, DateTime.now().year + 1).reverse()
  )

  useEffect(() => {
    // Gusto has limitation of fetching 3 months ahead
    const endDate = DateTime.min(
      DateTime.fromObject({ year }).endOf('year'),
      DateTime.now().plus({ months: 3 }).minus({ day: 1 })
    )

    dispatch(
      fetchAllPayrolls({
        end_date: endDate.toFormat(DATE_FORMATS_LUXON.GUSTO_SUBMIT),
        start_date: DateTime.fromObject({ year })
          .startOf('year')
          .toFormat(DATE_FORMATS_LUXON.GUSTO_SUBMIT),
        payroll_types: ['off_cycle', 'regular'],
        include: ['totals'],
      })
    )
  }, [dispatch, year])

  const payrollsToShow = useMemo(
    () =>
      orderBy(
        Object.values(payrolls).filter(
          ({ processed_date }) =>
            processed_date && moment(processed_date).year() === year
        ),
        (payroll) => payroll.processed_date,
        ['desc']
      ),
    [payrolls, year]
  )

  return (
    <Grid>
      <GridRowColumn>
        <Alert>
          This is a list of all the payrolls you’ve made. Toggle between years
          to review your payroll history, view payroll details or cancel a
          payroll.
        </Alert>
      </GridRowColumn>
      <Grid.Row />
      <Grid.Row>
        <Grid.Column width={12}>
          <Text as="h2">Payroll Summaries</Text>
        </Grid.Column>
        <Grid.Column width={4}>
          <Dropdown options={yearOptions} value={year} onChange={setYear} />
        </Grid.Column>
      </Grid.Row>
      {!payrollFetching && (
        <GridRowColumn>
          <div className="payroll-history-details-container">
            <div className="gep-details-container">
              <div className="title-container">
                <Text>Total Amount Debited from Bank</Text>
                <Popup
                  content="This is the amount that will be taken from your attached bank account for this payroll cycle."
                  trigger={<Icon icon={solid('info-circle')} />}
                />
              </div>
              <br />
              <Text as="h1">{formatCurrency(netDebit)}</Text>
            </div>
            <div className="gep-details-container">
              <div className="title-container">
                <Text>Total Amount Paid in Payroll Taxes</Text>
                <Popup
                  content="This includes federal and state payroll taxes paid in this payroll cycle."
                  trigger={<Icon icon={solid('info-circle')} />}
                />
              </div>
              <br />
              <Text as="h1">{formatCurrency(netPayrollTaxes)}</Text>
            </div>
            <div className="gep-details-container">
              <div className="title-container">
                <Text>Total Amount Paid to Employees</Text>
                <Popup
                  content="This consists of employee wages, reimbursements, and bonuses paid in this payroll cycle."
                  trigger={<Icon icon={solid('info-circle')} />}
                />
              </div>
              <br />
              <Text as="h1">{formatCurrency(netPaidToEmployees)}</Text>
            </div>
          </div>
        </GridRowColumn>
      )}
      <Grid.Row />
      <GridRowColumn>
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date Processed</Table.HeaderCell>
              <Table.HeaderCell>Payroll Type</Table.HeaderCell>
              <Table.HeaderCell>Payroll Period</Table.HeaderCell>
              <Table.HeaderCell>Total Debited</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {payrollsToShow.map(({ payroll_uuid }) => (
              <PayrollRow key={payroll_uuid} uuid={payroll_uuid} />
            ))}
          </Table.Body>
        </Table>
      </GridRowColumn>
    </Grid>
  )
}

export default EmployeePayrollHistoryTab
