import axios from 'axios'
import { normalize } from 'normalizr'
import {
  adminCalendlyLinksSchema,
  userCallEventListSchema,
  userCallEventSchema,
} from '../../schema'

import { receiveAdminUserCallEvents } from '../../reducers/admin/allUserCallEventsReducer'
import { fetchIfNeededWrapper, fetchWrapper } from '../../reducers/fetch'
import {
  CalendlyLink,
  receiveCalendlyLinks,
} from '../../reducers/calendlyLink.slice'
import { getAllUserCallEventsByUserId } from '../../selectors/userCallEventSelectors'
import {
  UserCallEvent,
  UserCallEventEntities,
} from '../../reducers/finances/userCallEventsReducer'

export const fetchAdminUserCallEventsIfNeeded = (
  userId: number,
  alwaysFetch?: boolean
) =>
  fetchIfNeededWrapper({
    fetchKey: `ADMIN_FETCH_USER_CALL_EVENTS_KEY_${userId}}`,
    defaultErrorMessage: "There was an error fetching this user's call events.",
    defaultValueSelector: (state) =>
      getAllUserCallEventsByUserId(state, userId),
    alwaysFetch,
    fetchFunction: (dispatch) =>
      axios
        .get<{
          userCallEvents: UserCallEvent[]
          userId: number
        }>(`/finances/api/v1/admin/user_call_events/${userId}`)
        .then((json) => {
          const normalizedData = normalize<
            UserCallEvent,
            { userCallEvents?: { [key: number]: UserCallEvent } }
          >(json.data.userCallEvents, userCallEventListSchema)

          dispatch(
            receiveAdminUserCallEvents({
              data: normalizedData,
              userId: json.data.userId,
            })
          )
          return normalizedData.entities.userCallEvents || {}
        }),
  })

interface UpdateCallEventPayload
  extends Omit<
    UserCallEvent,
    'startTime' | 'endTime' | 'skippedAt' | 'missedOn' | 'canceledAt'
  > {
  startTime: number | null
  endTime: number | null
  skippedAt: number | null
  missedOn: number | null
  canceledAt: number | null
}

export const updateAdminUserCallEvent = (
  eventId: number,
  data: Partial<UpdateCallEventPayload>
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error fetching user call events',
    fetchFunction: (dispatch) =>
      axios
        .put<UserCallEvent>(
          `/finances/api/v1/admin/user_call_events/${eventId}`,
          data
        )
        .then((json) => {
          const normalizedData = normalize<
            unknown,
            UserCallEventEntities,
            number
          >(json.data, userCallEventSchema)
          dispatch(
            receiveAdminUserCallEvents({
              userId: json.data.userId,
              data: normalizedData,
            })
          )
          dispatch(receiveCalendlyLinks(normalizedData))
          return json.data
        }),
  })

const FETCH_CALENDLY_KEY = 'FETCH_CALENDLY_KEY'

export const fetchCalendlyLinks = () =>
  fetchWrapper({
    fetchKey: FETCH_CALENDLY_KEY,
    defaultErrorMessage: 'There was an error fetching calendly links',
    fetchFunction: (dispatch) =>
      axios
        .get<CalendlyLink[]>('/finances/api/v1/admin/admin_calendly_links')
        .then((json) => {
          const normalizedData = normalize<
            unknown,
            { adminCalendlyLink?: { [key: number]: CalendlyLink } },
            number[]
          >(json.data, [adminCalendlyLinksSchema])
          dispatch(receiveCalendlyLinks(normalizedData))
          return normalizedData.entities.adminCalendlyLink
        }),
  })

interface CreateUpdateCalendlyLinkPayload {
  adminUserId: number
  calendlyLink: string
  userFacingEventName: string
  userFacingDescription: string
  quarter?: '1' | '2' | '3' | '4'
  year?: string
}

export const updateCalendlyLink = (
  id: number,
  data: CreateUpdateCalendlyLinkPayload
) =>
  fetchWrapper({
    fetchKey: FETCH_CALENDLY_KEY,
    defaultErrorMessage: 'There was an error updating calendly link',
    fetchFunction: (dispatch) =>
      axios
        .put<CalendlyLink>(
          `/finances/api/v1/admin/admin_calendly_links/${id}`,
          data
        )
        .then((json) => {
          const normalizedData = normalize<
            unknown,
            { adminCalendlyLink?: { [key: number]: CalendlyLink } },
            number[]
          >(json.data, adminCalendlyLinksSchema)
          dispatch(receiveCalendlyLinks(normalizedData))
          return normalizedData.entities.adminCalendlyLink
        }),
  })

export const createCalendlyLink = (data: CreateUpdateCalendlyLinkPayload) =>
  fetchWrapper({
    fetchKey: FETCH_CALENDLY_KEY,
    defaultErrorMessage: 'There was an error updating calendly link',
    fetchFunction: (dispatch) =>
      axios
        .post<CalendlyLink>('/finances/api/v1/admin/admin_calendly_links', data)
        .then((json) => {
          const normalizedData = normalize<
            unknown,
            { adminCalendlyLink?: { [key: number]: CalendlyLink } },
            number
          >(json.data, adminCalendlyLinksSchema)
          dispatch(receiveCalendlyLinks(normalizedData))
          return normalizedData.entities.adminCalendlyLink
        }),
  })
