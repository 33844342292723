export const TRUE_FALSE_OPTIONS = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
]

export const BUSINESS_ENTITY_OPTIONS = [
  { text: 'Sole Proprietorship', value: 'sprop' },
  { text: 'LLC (Single Member)', value: 'llc_single' },
  { text: 'PLLC (Single Member)', value: 'pllc_single' },
  { text: 'Professional Corporation (Single Member)', value: 'pc_single' },
  { text: 'Domestic Corporation', value: 'dc' },
  { text: 'Other', value: 'other' },
]

export const getLegalEntityName = (entity?: string | null) =>
  BUSINESS_ENTITY_OPTIONS.find((option) => option.value === entity)?.text ||
  entity ||
  ''

export const YEAR_PRACTICE_STARTED_OPTIONS = [
  { text: '2023', value: '2023' },
  { text: '2022', value: '2022' },
  { text: '2021', value: '2021' },
  { text: '2020', value: '2020' },
  { text: '2019', value: '2019' },
  { text: '2018 or earlier', value: '2018 or earlier' },
]

export const PRACTICE_TYPE_OPTIONS = [
  { text: 'Solo', value: 'solo' },
  { text: 'Group Practice', value: 'group' },
]

export const NUMBER_OF_PROVIDER_OPTIONS = [
  {
    text: '0',
    value: '0',
  },
  {
    text: '1-5',
    value: '1-5',
  },
  {
    text: '6-10',
    value: '6-10',
  },
  {
    text: '10+',
    value: '10+',
  },
]
