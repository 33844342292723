import { useCallback, useState } from 'react'
import { Grid } from 'semantic-ui-react'

import {
  PUT_UPDATE_PAY_SCHEDULE_KEY,
  putUpdatePaymentConfig,
  putUpdatePaySchedule,
} from '../payrollActions'
import {
  Button,
  Modal,
  Toggle,
  Text,
  GridRowColumn,
  Link,
  Alert,
} from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { getFetchError } from '../../../reducers/fetch'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { Gusto_PaySchedule } from '../generated_gusto_types'

const AutoEnrollModal = ({
  modalOpen,
  onClose,
  paySchedule,
}: {
  modalOpen: boolean
  onClose: () => void
  paySchedule: Gusto_PaySchedule
}) => {
  const dispatch = useAppDispatch()
  const [modalAutomated, setModalAutomated] = useState(paySchedule.auto_pilot)
  const error = useReselector(getFetchError, PUT_UPDATE_PAY_SCHEDULE_KEY)

  const onSave = useCallback(async () => {
    if (modalAutomated === paySchedule.auto_pilot) {
      onClose()
      return
    }

    await dispatch(
      putUpdatePaymentConfig({
        payment_speed: '4-day',
      })
    )

    const res = await putUpdatePaySchedule(paySchedule.uuid, {
      version: paySchedule.version,
      auto_pilot: modalAutomated,
    })(dispatch)

    if (res) {
      onClose()
    }
  }, [
    paySchedule.uuid,
    paySchedule.auto_pilot,
    paySchedule.version,
    modalAutomated,
    dispatch,
    onClose,
  ])

  const cancelModal = useCallback(() => {
    onClose()
    setModalAutomated(paySchedule.auto_pilot)
  }, [onClose, paySchedule.auto_pilot])

  return (
    <Modal open={modalOpen} size="tiny" closeIcon onClose={cancelModal}>
      <Modal.Header>Update Automatic Payroll</Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text>
              Automatic Payroll allows us to run your payroll automatically,
              right on schedule! Each pay period, we&apos;ll automatically run
              payroll for you, one day before your payroll deadline.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Alert type="warn">
              This will turn your payment speed to 4 day.
            </Alert>
          </GridRowColumn>
          {error && (
            <GridRowColumn>
              <Alert type="error">
                There was an error setting up automatic payroll.{'  '}
                <Link
                  href="https://support.gusto.com/article/999755421000000/Use-and-manage-Payroll-on-AutoPilot"
                  newPage
                >
                  Learn more here.
                </Link>
                {'  '}If you have further questions, please{'  '}
                <Link to="/conversations">contact us</Link>.
              </Alert>
            </GridRowColumn>
          )}

          <Grid.Row verticalAlign="middle">
            <Grid.Column width={10}>
              <Text as="bodyMd">Automatic Payroll</Text>
            </Grid.Column>
            <Grid.Column width={2}>
              <Text as="bodyMd">{modalAutomated ? 'ON' : 'OFF'}</Text>
            </Grid.Column>
            <Grid.Column width={4}>
              <Toggle
                onChange={(checked) => setModalAutomated(checked)}
                checked={modalAutomated}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cancelModal} variant="secondary">
          Cancel
        </Button>
        <Button onClick={onSave}>Save</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default AutoEnrollModal
