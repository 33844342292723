import { useNavigate } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { formatPhoneNumber } from 'react-phone-number-input'
import moment from 'moment'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { useReselector } from '../../utils/sharedHooks'
import {
  getCurrentUser,
  getFinancialProfile,
  selectIsCurrentUserScorp,
} from '../../selectors/user.selectors'
import { Accordion, IconButton, Text } from '../../components/BaseComponents'
import PracticeProfileEntry from './PracticeProfileEntry'
import { UploadDocumentType } from '../../constants/businessConstants'
import { getLegalEntityName } from '../../constants/onboardingConstants'
import {
  CP261Tooltip,
  EinLetterTooltip,
  Form2553Tooltip,
  IncorporationDocTooltip,
  StateRegistrationTooltip,
} from './PracticeProfileTooltips'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { formatEin } from '../../components/Finances/shared/utils'
import { UserDocumentCategoryIdentifier } from '../Admin/UserDocumentCategories/userDocumentCategory.constants'

const YourBusinessSection = () => {
  const navigate = useNavigate()
  const isScorp = useReselector(selectIsCurrentUserScorp)
  const user = useReselector(getCurrentUser)
  const fp = useReselector(getFinancialProfile)

  return (
    <Accordion
      initialOpen
      title={
        <>
          <Text as="h2">Your Business</Text>
          <IconButton
            icon={regular('pen-to-square')}
            style={{ position: 'absolute', right: 55, zIndex: 1000 }}
            onClick={() => navigate('/practice/profile/editBusiness')}
          />
        </>
      }
      content={
        <Grid>
          <PracticeProfileEntry
            label="Business Name"
            value={fp?.businessName}
          />
          <PracticeProfileEntry
            label="Business Entity"
            value={getLegalEntityName(fp?.businessEntity)}
          />
          <PracticeProfileEntry
            label="Business EIN Number"
            value={formatEin(fp?.einNumber)}
          />
          <PracticeProfileEntry
            label="Year Practice Started"
            value={fp?.yearPracticeStarted}
          />
          <Grid.Row />
          <PracticeProfileEntry
            label="Business Address"
            value={fp?.businessAddress}
          />
          <PracticeProfileEntry
            label="State of Residence"
            value={fp?.homeState}
          />
          {isScorp && (
            <>
              <PracticeProfileEntry
                label="Business Registration State"
                value={fp?.scorpRegistrationState}
              />
              <PracticeProfileEntry
                label="S Corp Election Date"
                value={
                  fp?.entityChangeElectionDate &&
                  moment
                    .utc(fp.entityChangeElectionDate)
                    .format(DATE_FORMATS.DISPLAY_LONG)
                }
              />
            </>
          )}
          <Grid.Row />
          <PracticeProfileEntry
            label="Business Phone"
            value={user?.phoneNumber && formatPhoneNumber(user?.phoneNumber)}
          />
          <PracticeProfileEntry label="Business Email" value={user?.email} />
          <Grid.Row />

          {isScorp ? (
            <>
              <PracticeProfileEntry
                label="Form 2553"
                popupContent={<Form2553Tooltip />}
                documentCat={UserDocumentCategoryIdentifier.form2553}
              />
              <PracticeProfileEntry
                label="CP261 Letter"
                popupContent={<CP261Tooltip />}
                documentCat={UserDocumentCategoryIdentifier.cp261Notice}
              />
              <PracticeProfileEntry
                label="Incorporation Document"
                popupContent={<IncorporationDocTooltip />}
                documentCat={
                  UserDocumentCategoryIdentifier.businessIncorporation
                }
                documentType={UploadDocumentType.INCORPORATION}
              />
            </>
          ) : (
            <>
              <PracticeProfileEntry
                label="EIN Letter"
                popupContent={<EinLetterTooltip />}
                documentCat={UserDocumentCategoryIdentifier.einDocuments}
              />
              <PracticeProfileEntry
                label="State Registration"
                popupContent={<StateRegistrationTooltip />}
                documentCat={UserDocumentCategoryIdentifier.stateRegistration}
                documentType={UploadDocumentType.INCORPORATION}
              />
            </>
          )}
        </Grid>
      }
    />
  )
}

export default YourBusinessSection
