import { DateTime } from 'luxon'
import currency from 'currency.js'
import { Grid } from 'semantic-ui-react'

import { Colors } from '../../styles/theme'
import { formatCurrency } from '../../utils/currencyHelpers'
import { Expense } from '../../actions/reportActions'
import { DATE_FORMATS_LUXON } from '../../utils/dateHelpers'
import { GridRowColumn, Text } from '../../components/BaseComponents'
import { useReselector } from '../../utils/sharedHooks'
import { getCurrentUser } from '../../selectors/user.selectors'
import { getAllTransactionCategories } from './reports.selectors'

const ViewItem = ({
  item,
  isProfit,
}: {
  item: Expense
  isProfit?: boolean
}) => {
  const allTransactionCategories = useReselector(getAllTransactionCategories)

  const categoryName =
    allTransactionCategories[item?.transactionCategoryId]?.name ||
    'Uncategorized'
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Text>{categoryName}</Text>
      <Text
        style={{
          color: isProfit ? Colors.green : Colors.red,
          fontWeight: 'semibold',
        }}
      >
        {formatCurrency(item?.sum)}
      </Text>
    </div>
  )
}

const ProfitAndLossPDF = ({
  startDate,
  endDate,
  expensesObj,
  profitsObj,
}: {
  startDate: string | undefined
  endDate: string | undefined
  expensesObj: { expenses: Expense[]; sum: number | string } | null
  profitsObj: { profits: Expense[]; sum: number | string } | null
  otherObj?: { otherExpenses?: Expense[]; sum?: number | string } | null
}) => {
  const user = useReselector(getCurrentUser)

  if (!user) {
    return null
  }

  const netIncome = formatCurrency(
    currency(profitsObj?.sum || 0).add(expensesObj?.sum || 0)
  )

  return (
    <div style={{ margin: '0 30px' }}>
      <div
        style={{
          backgroundColor: Colors.stone,
          borderTop: `7px ${Colors.green} solid`,
          padding: '40px 30px 20px',
          margin: '0 -30px',
        }}
      >
        <Text as="bodyLg">
          {user.firstName} {user.lastName}
        </Text>
        <Text as="h1" color="teal">
          Profit and Loss Statement
        </Text>
        <Text as="bodySm">
          For the period:{' '}
          {startDate &&
            DateTime.fromFormat(startDate, DATE_FORMATS_LUXON.INPUT).toFormat(
              DATE_FORMATS_LUXON.DISPLAY_SHORT
            )}{' '}
          to{' '}
          {endDate &&
            DateTime.fromFormat(endDate, DATE_FORMATS_LUXON.INPUT).toFormat(
              DATE_FORMATS_LUXON.DISPLAY_SHORT
            )}
        </Text>
      </div>

      <Grid>
        <Grid.Row />
        {expensesObj && profitsObj && (
          <>
            <GridRowColumn>
              <Text>
                <b>Income</b>
              </Text>
              {profitsObj.profits.map((profit) => (
                <ViewItem
                  key={profit.transactionCategoryId}
                  item={profit}
                  isProfit
                />
              ))}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text>Total</Text>
                <Text>{formatCurrency(profitsObj?.sum)}</Text>
              </div>
            </GridRowColumn>

            <GridRowColumn>
              <Text>
                <b>Expenses</b>
              </Text>
              {expensesObj.expenses.map((expense) => (
                <ViewItem key={expense.transactionCategoryId} item={expense} />
              ))}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text>Total</Text>
                <Text>{formatCurrency(expensesObj?.sum)}</Text>
              </div>
            </GridRowColumn>

            <GridRowColumn>
              <Text>
                <b>Profits</b>
              </Text>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text>Net Profit</Text>
                <Text>
                  <b>{formatCurrency(netIncome)}</b>
                </Text>
              </div>
            </GridRowColumn>
          </>
        )}

        <GridRowColumn>
          <Text as="bodySm">
            Compiled On:{' '}
            {DateTime.now().toFormat(DATE_FORMATS_LUXON.DISPLAY_SHORT)}
          </Text>
        </GridRowColumn>
      </Grid>
    </div>
  )
}

export default ProfitAndLossPDF
