import React from 'react'
import { Divider, List, ListContent } from 'semantic-ui-react'

const AnnualizedAmountsPopup = ({
  periodTitle,
  periodAmount,
  annualizedAmount,
  periodDateString,
}: {
  periodTitle: string
  periodAmount: string | React.ReactElement
  annualizedAmount: string | React.ReactElement
  periodDateString: string
}) => (
  <List>
    <List.Item>
      <List.Content verticalAlign="middle" floated="right">
        {periodAmount}
      </List.Content>
      <List.Header style={{ fontWeight: 'normal' }} verticalAlign="middle">
        {periodTitle} <br />
        <i style={{ fontSize: '95%' }}>{periodDateString}</i>
      </List.Header>
    </List.Item>
    <Divider />
    <List.Item>
      <ListContent verticalAlign="middle" floated="right">
        <b>{annualizedAmount}</b>
      </ListContent>
      <List.Header>{periodTitle} Annualized</List.Header>
    </List.Item>
  </List>
)

export default AnnualizedAmountsPopup
