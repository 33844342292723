import { DateTime } from 'luxon'
import { ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import { updateUserOnboardingStep } from '../../../Onboarding/UserOnboardingSteps/onboarding.actions'
import {
  GettingStartedOnboardingStepId,
  GettingStartedOnboardingStep,
  GettingStartedUserOnboardingStepExpanded,
} from '../../../Onboarding/UserOnboardingSteps/onboarding.reducer'
import { selectGettingStartedUserOnboardingStepsWithGroups } from '../../../Onboarding/UserOnboardingSteps/onboarding.selectors'
import OnboardingItemRow, { OnboardingItemRowProps } from './OnboardingItemRow'
import {
  AutomaticStatementUploadModal,
  GroupOnboardingModal,
  ViewMissingStatementsAccordionForCatchupBookkeeping,
} from './OnboardingItemCTAContent'
import { useHeardPlaid } from '../../../../components/shared/plaid/helpers'

type OnboardingItemBaseContent = Pick<
  OnboardingItemRowProps,
  | 'header'
  | 'description'
  | 'imageUrl'
  | 'timer'
  | 'skippable'
  | 'additionalDetails'
  | 'onActionClick'
>

const defaultTimer = {
  unit: 'minutes',
  value: 5,
}

export const useGettingStartedOnboardingSteps = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const gettingStartedSteps = useReselector(
    selectGettingStartedUserOnboardingStepsWithGroups
  )
  // Item specific state management
  const [groupOnboardingModalOpen, setGroupOnboardingModalOpen] =
    useState(false)
  const [
    automaticStatementUploadModalOpen,
    setAutomaticStatementUploadModalOpen,
  ] = useState(false)

  const { open: openPlaidModal } = useHeardPlaid({
    redirectLink: '',
  })

  const gettingStartedContentMapping: Record<
    GettingStartedOnboardingStepId,
    OnboardingItemBaseContent
  > = {
    [GettingStartedOnboardingStep.joinGroupOnboardingSession]: {
      header: 'Join a group onboarding session',
      description:
        'Our Onboarding Team will walk you through key features in Heard.',
      imageUrl: 'https://heard-images.s3.amazonaws.com/assets/laptop-video.svg',
      timer: defaultTimer,
      skippable: false,
      additionalDetails: (
        <GroupOnboardingModal
          open={groupOnboardingModalOpen}
          setOpen={setGroupOnboardingModalOpen}
        />
      ),
      onActionClick: () => {
        setGroupOnboardingModalOpen(true)
      },
    },
    [GettingStartedOnboardingStep.uploadBankStatements]: {
      header: 'Upload missing bank statements',
      description:
        'Help us complete your books by uploading your bank statements for catch-up bookkeeping.',
      imageUrl:
        'https://heard-images.s3.amazonaws.com/assets/statement-upload-manual.svg',
      timer: defaultTimer,
      skippable: false,
      additionalDetails: (
        <ViewMissingStatementsAccordionForCatchupBookkeeping />
      ),
      onActionClick: () => {
        navigate('/practice/documents?tab=bank', {
          state: {
            initiatedBy: GettingStartedOnboardingStep.uploadBankStatements,
          },
        })
      },
    },
    [GettingStartedOnboardingStep.setupAutomaticStatementUpload]: {
      header: 'Set up automatic statement uploads',
      description:
        'Save time with automatic statement uploads—if there’s a bookkeeping issue, we can handle it automatically. Available for select financial institutions.',
      imageUrl: 'https://heard-images.s3.amazonaws.com/assets/rocket.svg',
      timer: defaultTimer,
      skippable: true,
      additionalDetails: (
        <AutomaticStatementUploadModal
          open={automaticStatementUploadModalOpen}
          setOpen={setAutomaticStatementUploadModalOpen}
        />
      ),
      onActionClick: (stepId) => {
        setAutomaticStatementUploadModalOpen(true)
        if (stepId) {
          dispatch(
            updateUserOnboardingStep({
              id: stepId,
              updatableFields: { completedAt: DateTime.now().toISO() },
            })
          )
        }
      },
    },
    [GettingStartedOnboardingStep.clarifyUncategorizedTransactions]: {
      header: 'Clarify unknown transactions',
      description:
        'We weren’t sure how to categorize some of your transactions. Please clarify so we can complete your catch-up bookkeeping.',
      imageUrl:
        'https://heard-images.s3.amazonaws.com/assets/review-transactions.svg',
      timer: defaultTimer,
      skippable: false,
      onActionClick: () => {
        navigate('/transactions/review')
      },
    },
    [GettingStartedOnboardingStep.connectBusinessBankAccount]: {
      header: 'Connect business bank account',
      description:
        'Please connect your primary business bank account. We use this to sync your transactions and start your bookkeeping.',
      imageUrl:
        'https://heard-images.s3.amazonaws.com/assets/bank-with-shadow.svg',
      timer: defaultTimer,
      skippable: false,
      onActionClick: () => {
        openPlaidModal()
      },
    },
  }

  const getContentForStep = (
    step: GettingStartedUserOnboardingStepExpanded
  ): OnboardingItemRowProps => {
    const stepIdentifier = step.onboardingStep.identifier
    const content = gettingStartedContentMapping[stepIdentifier]
    const userOnboardingStepId = step.id

    const userInteractionFields = {
      started: Boolean(step.startedAt),
      completed: Boolean(step.completedAt),
      skipped: Boolean(step.skippedAt),
    }

    const onSkipClick = () => {
      if (!step.skippedAt) {
        dispatch(
          updateUserOnboardingStep({
            id: step.id,
            updatableFields: { skippedAt: DateTime.now().toISO() },
          })
        )
      }
    }
    const onActionClick = (stepId?: number) => {
      if (!step.startedAt) {
        dispatch(
          updateUserOnboardingStep({
            id: step.id,
            updatableFields: { startedAt: DateTime.now().toISO() },
          })
        )
      }
      content.onActionClick(stepId)
    }
    if (content.skippable) {
      return {
        ...content,
        ...userInteractionFields,
        onActionClick,
        onSkipClick,
        stepId: userOnboardingStepId,
      }
    }
    return {
      ...content,
      ...userInteractionFields,
      onActionClick,
      stepId: userOnboardingStepId,
    }
  }

  // Map of step identifier to content
  const gettingStartedStepsContent = gettingStartedSteps.reduce(
    (acc, step) => ({
      ...acc,
      [step.onboardingStep.identifier]: (
        <OnboardingItemRow {...getContentForStep(step)} />
      ),
    }),
    {} as Record<GettingStartedOnboardingStepId, ReactNode>
  )
  return gettingStartedStepsContent
}
