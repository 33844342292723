import { useEffect, useState } from 'react'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { Card, Header, Table, Button } from 'semantic-ui-react'

import BookkeepingStatusLabel from './BookkeepingStatusLabel'
import {
  fetchAdminUsersBookkeepingReports,
  createBookkeepingReportsFromYear,
} from '../../../actions/admin/bookkeepingReportActions'
import { User } from '../../../reducers/auth/userReducer'
import { useReselector } from '../../../utils/sharedHooks'
import { selectBookkeepingReportsByUserId } from '../../../selectors/bookkeepingReportsSelectors'
import GenerateMissingReportsModal from './GenerateMissingReportsModal'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useAppDispatch } from '../../../utils/typeHelpers'

const BookkeepingReportsCard = ({ user }: { user: User }) => {
  const dispatch = useAppDispatch()
  const [creatingReports, setCreatingReports] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  useEffect(() => {
    fetchAdminUsersBookkeepingReports(user.id)(dispatch)
  }, [dispatch, user.id])

  const createReports = async (year: string) => {
    setCreatingReports(true)
    await createBookkeepingReportsFromYear({
      userId: user.id,
      year,
    })(dispatch)
    setCreatingReports(false)
    setModalOpen(false)
  }

  const reports = useReselector(selectBookkeepingReportsByUserId, user.id)

  return (
    <Card fluid className="bookkeepingReportsCard">
      <Card.Header>
        <Header as="h4">Monthly Books</Header>
        <Button
          variant="link"
          loading={creatingReports}
          disabled={creatingReports}
          onClick={() => setModalOpen(true)}
        >
          Generate Missing Books...
        </Button>
      </Card.Header>

      <Card.Content>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={6}>Month</Table.HeaderCell>
              <Table.HeaderCell width={6}>Status</Table.HeaderCell>
              <Table.HeaderCell width={4} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {reports.map((report) => (
              <Table.Row key={report.id}>
                <Table.Cell>
                  <b>
                    <Moment format={DATE_FORMATS.MONTH_YEAR}>
                      {report.date}
                    </Moment>
                  </b>
                </Table.Cell>
                <Table.Cell>
                  <BookkeepingStatusLabel reportId={report.id.toString()} />
                </Table.Cell>
                <Table.Cell>
                  <Link
                    to={`/admin/finances/records/${user.id}/monthly-books/${report.id}`}
                  >
                    <Button className="link">view</Button>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card.Content>
      {modalOpen && (
        <GenerateMissingReportsModal
          onClose={() => setModalOpen(false)}
          loading={creatingReports}
          onCreateReports={createReports}
        />
      )}
    </Card>
  )
}

export default BookkeepingReportsCard
