import axios from 'axios'
import { createSelector } from 'reselect'
import { keyBy } from 'lodash'

import { ActionItemCategory } from './actionItemCategories.slice'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchWrapper } from '../../../reducers/fetch'

import { ReduxState } from '../../../utils/typeHelpers'
import { Tooltip } from './allTooltips.slice'

// This will be null for standard action items
export enum ActionItemType {
  // Type of "todo" (not a typo)
  actionItem = 'action_item',
  // Type of "important dates"
  importantDate = 'important_date',
}

export interface ActionItem {
  id: number
  description: string
  actionLink: null | string
  subtext: string | null
  active: boolean
  actionItemCategoryId: number
  actionItemCategory: ActionItemCategory
  type: ActionItemType | null
  tooltip?: Tooltip
  canMarkComplete: boolean | null
  canMarkNotApplicable: boolean | null
  userDocumentCategoryId?: number | null
  identifier?: null | string
  default?: null | string
  startsAt?: null | string
  endsAt?: null | string
  showToUserAt?: string | null
  hideFromUserAt?: string | null
  tooltipId?: null | number
  deletedAt?: null | string
  createdAt: null | string
  updatedAt: null | string
}

export interface AllActionItemsState {
  [key: string]: ActionItem
}

const allActionItemsSlice = createSlice({
  name: 'allActionItems',
  initialState: {} as AllActionItemsState,
  reducers: {
    receiveAllActionItems: (
      state,
      action: PayloadAction<AllActionItemsState>
    ) => ({
      ...state,
      ...action.payload,
    }),
    receiveSingleActionItem: (state, action: PayloadAction<ActionItem>) => ({
      ...state,
      [action.payload.id]: action.payload,
    }),
    removeActionItem: (state, action: PayloadAction<number>) => {
      delete state[action.payload]
    },
  },
})

export const {
  receiveAllActionItems,
  receiveSingleActionItem,
  removeActionItem,
} = allActionItemsSlice.actions

export default allActionItemsSlice.reducer

/*
  Create Action Item
*/
interface ActionItemData {
  description?: string
  actionLink?: string
  active?: boolean
  actionItemCategoryId?: number
}

export const CREATE_ACTION_ITEMS_KEY = 'CREATE_ACTION_ITEMS_KEY'
export const createActionItem = (data: ActionItemData) =>
  fetchWrapper({
    fetchKey: CREATE_ACTION_ITEMS_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<ActionItem>(
        '/finances/api/v1/admin/action_item',
        data
      )
      dispatch(receiveSingleActionItem(json.data))
      return json.data
    },
  })

export const UPDATE_ACTION_ITEM_KEY = (id?: number) =>
  `UPDATE_ACTION_ITEMS_KEY${id}`
export const updateActionItem = (id: number, data: ActionItemData) =>
  fetchWrapper({
    fetchKey: UPDATE_ACTION_ITEM_KEY(id),
    fetchFunction: async (dispatch) => {
      const json = await axios.put<ActionItem>(
        `/finances/api/v1/admin/action_item/${id}`,
        data
      )
      dispatch(receiveSingleActionItem(json.data))
      return json.data
    },
  })

export const deleteActionItem = (id: number) =>
  fetchWrapper({
    defaultValue: false,
    fetchFunction: async (dispatch) => {
      const json = await axios.delete<ActionItem>(
        `/finances/api/v1/admin/action_item/${id}`
      )
      dispatch(deleteActionItem(id))
      return json.data
    },
  })

export const fetchAllImportantDates = () =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const json = await axios.get<ActionItem[]>(
        '/finances/api/v1/admin/important_dates'
      )

      dispatch(receiveAllActionItems(keyBy(json.data, 'id')))
      return json.data
    },
  })

export const FETCH_TODO_KEY = 'FETCH_TODO_KEY'
export const fetchAllTodos = () =>
  fetchWrapper({
    fetchKey: FETCH_TODO_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<ActionItem[]>(
        '/finances/api/v1/admin/action_item/todos'
      )
      dispatch(receiveAllActionItems(keyBy(json.data, 'id')))
      return json.data
    },
  })

export const selectActionItems = (state: ReduxState) =>
  state.admin.allActionItems

export const selectTodos = createSelector(selectActionItems, (actionItems) =>
  Object.values(actionItems).filter(
    (item) => item.type === ActionItemType.actionItem
  )
)

export const selectImportantDates = createSelector(
  selectActionItems,
  (actionItems) =>
    Object.values(actionItems).filter(
      (item) => item.type === ActionItemType.importantDate
    )
)
