import { useState } from 'react'
import { Header, Grid, Button, Radio, Form, Modal } from 'semantic-ui-react'

import { updateUserFinancialProfile } from '../../../actions/admin/adminAllUsersActions'
import { FinancialProfile } from '../../../reducers/auth/userReducer'
import { useAppDispatch } from '../../../utils/typeHelpers'

const FinancialProfileFormModal = ({
  open,
  close,
  profile,
}: {
  open: boolean
  close: () => void
  profile: FinancialProfile
}) => {
  const dispatch = useAppDispatch()
  const [submitting, setSubmitting] = useState(false)
  const [bankAccountReadAccess, setBankAccountReadAccess] = useState(
    profile.bankAccountReadAccess || false
  )
  const [lastStatementAvailableDay, setLastStatementAvailableDay] = useState(
    profile.lastStatementAvailableDay
  )

  const [
    preventMonthlyStatementNotifications,
    setPreventMonthlyStatementNotifications,
  ] = useState(profile.preventMonthlyStatementNotifications)

  const submitForm = async () => {
    await setSubmitting(true)
    await updateUserFinancialProfile(profile.id, {
      bankAccountReadAccess,
      lastStatementAvailableDay: lastStatementAvailableDay
        ? lastStatementAvailableDay
        : null,
      preventMonthlyStatementNotifications: preventMonthlyStatementNotifications
        ? preventMonthlyStatementNotifications
        : null,
    })(dispatch)
    await setSubmitting(false)
    close()
  }

  return (
    <Modal
      size={'small'}
      dimmer={'inverted'}
      className="financialProfileFormModal"
      open={open}
      onClose={close}
    >
      <Modal.Content>
        <Grid stackable doubling>
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={14}>
              <Header as="h2">Update Financial Account Info</Header>
              <p>
                The following fields have effects on how / when a user gets
                reminded to upload their monthly statements
              </p>
            </Grid.Column>
            <Grid.Column width={2}>
              <Button circular icon="close" onClick={() => close()} />{' '}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}>
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Form.Group grouped className="required">
                      <label>Bank Account Read Access</label>
                      <p>
                        <i>
                          Set as &#34;yes&#34; if user has given us read account
                          to their bank accounts, so we no longer need them to
                          upload their own statements
                        </i>
                      </p>
                      <Form.Field>
                        <Radio
                          label="Yes"
                          name="bankAccountReadAccess"
                          checked={bankAccountReadAccess}
                          onChange={() =>
                            setBankAccountReadAccess(!bankAccountReadAccess)
                          }
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="No"
                          name="bankAccountReadAccess"
                          checked={!bankAccountReadAccess}
                          onChange={() =>
                            setBankAccountReadAccess(!bankAccountReadAccess)
                          }
                        />
                      </Form.Field>
                    </Form.Group>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Statement Available Date</label>
                    <p>
                      <i>
                        If user has told us that statements are not available
                        until DAY NUMBER, input that DAY NUMBER
                      </i>
                    </p>
                    <p>
                      <i>i.e. put 10 if statement is available on the 10th</i>
                    </p>

                    <Form.Input
                      name="lastStatementAvailableDay"
                      placeholder="Statement Available Day"
                      value={lastStatementAvailableDay}
                      onChange={(event, target) => {
                        const numberValue = Number(target.value)
                        if (!isNaN(numberValue)) {
                          setLastStatementAvailableDay(numberValue)
                        }
                      }}
                      fluid
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Field>
                  <Form.Group grouped className="required">
                    <label>Prevent statement notifications</label>
                    <p>
                      <i>
                        Set as Yes if for any reason, we want to prevent this
                        user from getting monthly statement upload notifications
                      </i>
                    </p>
                    <Form.Field>
                      <Radio
                        label="Yes"
                        name="preventMonthlyStatementNotifications"
                        checked={Boolean(preventMonthlyStatementNotifications)}
                        onChange={() =>
                          setPreventMonthlyStatementNotifications(
                            !preventMonthlyStatementNotifications
                          )
                        }
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label="No"
                        name="preventMonthlyStatementNotifications"
                        checked={!preventMonthlyStatementNotifications}
                        onChange={() =>
                          setPreventMonthlyStatementNotifications(
                            !preventMonthlyStatementNotifications
                          )
                        }
                      />
                    </Form.Field>
                  </Form.Group>
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column floated="right" width={6} onClick={() => submitForm()}>
              <Button fluid primary loading={submitting}>
                Save
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

export default FinancialProfileFormModal
