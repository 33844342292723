import { Form } from 'semantic-ui-react'
import CurrencyInput from 'react-currency-input-field'

// BL
import { FinancialAccountReconciliationWithTransactions } from '../../../../reducers/admin/allFinancialAccountReconciliationsReducer'

// UI
import { FinancialAccountSumResponse } from '../../../../actions/admin/adminFinancialAccountActions'
import { selectTransactionCategoriesAsDropdownOptions } from '../../../../features/Reports/reports.selectors'
import { useReselector } from '../../../../utils/sharedHooks'
import { DatePicker, Dropdown } from '../../../BaseComponents'
import { utcMomentToUTCDate } from '../../../../utils/dateHelpers'

interface CustomFormLabelProps {
  title: string
  subtitle: string
}

const CustomFormLabel = ({ title, subtitle }: CustomFormLabelProps) => (
  <span>
    <strong>{title}</strong>
    {subtitle && (
      <span
        style={{
          backgroundColor: 'yellow',
          margin: '3px',
          padding: '1px 5px',
          display: 'inline-block',
        }}
      >
        {subtitle}
      </span>
    )}
  </span>
)

export interface GetterSetter<T> {
  get: T
  set: (val: T) => void
}

export interface FormFields {
  account: GetterSetter<FinancialAccountSumResponse | null>
  amount: GetterSetter<number>
  categoryId: GetterSetter<number>
  date: GetterSetter<string>
  description: GetterSetter<string>
}

interface TransactionFormProps {
  formFields: FormFields
  loading: boolean
  reconciliation: FinancialAccountReconciliationWithTransactions
}

const PanelTransactionForm = ({
  formFields,
  loading,
  reconciliation,
}: TransactionFormProps) => {
  const { name, mask, plaidInstitutionName } = formFields.account.get || {}

  const categoryOptions = useReselector(
    selectTransactionCategoriesAsDropdownOptions
  )

  // Wrapping components so they can be used within <Form.Field/>
  const DatePickerInput = () => (
    <DatePicker
      onChange={(value) => formFields.date.set(value)}
      minDate={utcMomentToUTCDate(reconciliation.startingBalanceDate)}
      maxDate={utcMomentToUTCDate(reconciliation.endingBalanceDate)}
      value={formFields.date.get}
    />
  )

  const CategoryDropdown = () => (
    <Dropdown
      placeholder="Select Category"
      value={formFields.categoryId.get}
      options={categoryOptions}
      onChange={(categoryId) =>
        formFields.categoryId.set(categoryId !== undefined ? categoryId : -1)
      }
      search
      clearable
    />
  )
  // --- end wrap

  return (
    <Form loading={loading}>
      <Form.Group widths="equal">
        <Form.Input
          onChange={(_e, data) => formFields.description.set(data.value)}
          value={formFields.description.get}
          fluid
          label="Description"
          placeholder="e.g. Starbucks"
        />
        <Form.Field control={CategoryDropdown} label="Category" />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          disabled
          fluid
          label="Bank Account"
          value={`${plaidInstitutionName} - ${name} ${mask}`}
        />
        <Form.Field control={DatePickerInput} label="Date" />
      </Form.Group>

      <CustomFormLabel
        title="Amount"
        subtitle="A negative amount indicates a debit, e.g. —$xxx.xx"
      />
      {/* Currency Input became problematic when wrapped. Faking it by using custom label */}
      <CurrencyInput
        prefix="$"
        onValueChange={(
          _: unknown,
          __: unknown,
          values?: { float: number | null }
        ) => {
          formFields.amount.set(values?.float || 0)
        }}
        defaultValue={formFields.amount.get}
      />
    </Form>
  )
}

export default PanelTransactionForm
