import { NumericFormat } from 'react-number-format'
import { Grid, SemanticWIDTHSNUMBER } from 'semantic-ui-react'
import styled from 'styled-components'
import { Colors } from '../../styles/theme'
import { GridRowColumn, Text } from '../BaseComponents'
import { ReactNode } from 'react'

export const NumberFormatWrapper = styled(Grid.Column)({
  height: '48px !important',
  width: '48px !important',
  position: 'relative',
  justifyContent: 'center',
  borderRadius: '50%',
  backgroundColor: Colors.moss,
  fontSize: '16px',
})

const NumberIconList = ({
  list,
  width = 12,
}: {
  list: ReactNode[]
  width: SemanticWIDTHSNUMBER
}) => {
  return (
    <GridRowColumn width={width}>
      <Grid>
        {list.map((row, idx) => {
          return (
            <Grid.Row key={typeof row === 'string' ? row : idx}>
              <NumberFormatWrapper>
                <NumericFormat
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                  prefix="0"
                  displayType={'text'}
                  value={idx + 1}
                />
              </NumberFormatWrapper>
              <Grid.Column verticalAlign="middle" width={14}>
                <Text>{row}</Text>
              </Grid.Column>
            </Grid.Row>
          )
        })}
      </Grid>
    </GridRowColumn>
  )
}

export default NumberIconList
