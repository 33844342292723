import { UserDocument } from './userDocuments.slice'

/* Service */
export const serviceCopy = {
  static: {
    defaultPlaceholderDocumentName: 'Required Document',
  },
}

/* Tab Panes */
export const bankStatementsPane = {
  static: {
    paneDescription:
      'Please use this section to upload your monthly business bank account and business credit card statements. We use these to reconcile your books each month and ensure that all of your income and expenses are accounted for. If you have granted our team read-only access to your bank account(s), you do not need to upload statements.',
    paneTitle: 'Bank Statements',
    statementMonthNoValue: 'N/A',
    tableColumns: [
      {
        sortBy: 'createdAt',
        title: 'Date Uploaded',
      },
      {
        sortBy: 'fileName',
        title: 'File Name',
      },
      {
        sortBy: 'tag',
        title: 'Statement Month',
      },
      {
        sortBy: 'financialAccount',
        title: 'Financial Account',
      },
      {
        sortBy: null,
        title: 'Actions',
      },
    ],
    uploadErrorInvalidDate:
      'The statement date must be earlier than the current month/year',
    uploadErrorNoFilesSelected: 'You must select at least 1 document to upload',
    uploadModalHeaderText: 'Upload Statements',
    uploadStatementFormYearLabel: 'Select Statement Year',
    uploadStatementFormMonthLabel: 'Select Statement Month',
  },
  dynamic: {},
}

export const bookkeepingPane = {
  static: {
    modalDocumentTypeLabel: 'Select a Bookkeeping Document Type',
    modalDocumentDescriptionLabel:
      'If unsure, describe the purpose of this document',
    modalDocumentPlaceholder: 'Enter description...',
    modalNoDocumentTypesAvailable: 'No document types are available',
    paneDescription:
      'Please use this section to upload any bookkeeping-specific documents.',
    paneTitle: 'Bookkeeping',
    tableColumns: [
      {
        sortBy: 'fileName',
        title: 'File Name',
      },
      {
        sortBy: 'description',
        title: 'Description',
      },
      {
        sortBy: 'tag',
        title: 'Document Type',
      },
      {
        sortBy: null,
        title: 'Actions',
      },
    ],
  },
  dynamic: {},
}

export const taxPane = {
  static: {
    learnMoreTitle: 'Learn more about your Tax Files & Terminology',
    learnMoreBodyText: 'TBD',
    modalDocumentTypeLabel: 'Select a Tax Document Type',
    modalDocumentDescriptionLabel:
      'If unsure, describe the purpose of this document',
    modalDocumentPlaceholder: 'Enter description...',
    modalNoDocumentTypesAvailable: 'No document types are available',
    paneDescription:
      'Please use this section to upload any tax-specific documents, such as prior year tax returns.',
    paneTitle: 'Taxes',
    tableColumns: [
      {
        sortBy: 'fileName',
        title: 'File Name',
      },
      {
        sortBy: 'description',
        title: 'Description',
      },
      {
        sortBy: 'tag',
        title: 'Form Type',
      },
      {
        sortBy: null,
        title: 'Actions',
      },
    ],
    uploadModalHeaderText: 'Upload Tax Documents',
  },
  dynamic: {},
}

export const incorporationDocumentsPane = {
  static: {
    modalDocumentTypeLabel: 'Select Incorporation Document Type',
    modalDocumentDescriptionLabel:
      'If unsure, describe the purpose of this document',
    modalDocumentPlaceholder: 'Enter description...',
    modalNoDocumentTypesAvailable: 'No document types are available',
    paneDescription:
      'Please use this section to upload any entity formation documents, such as articles of incorporation, LLC approval, S corporation Form 2553, etc.',
    paneTitle: 'Incorporation',
    tableColumns: [
      {
        sortBy: 'fileName',
        title: 'File Name',
      },
      {
        sortBy: 'description',
        title: 'Description',
      },
      {
        sortBy: 'tag',
        title: 'Form Type',
      },
      {
        sortBy: null,
        title: 'Actions',
      },
    ],
    uploadModalHeaderText: 'Upload Incorporation Documents',
  },
  dynamic: {},
}

export const receipts = {
  static: {
    paneDescription:
      'This section displays your uploaded receipts. It is not required to upload receipts to Heard, but feel free to use this section to stay organized! We recommend keeping track of receipts that are more than $75 in value for your records.',
    paneTitle: 'Receipts',
    tableColumns: [
      {
        sortBy: 'createdAt',
        title: 'Date Uploaded',
      },
      {
        sortBy: 'fileName',
        title: 'File Name',
      },
      {
        sortBy: null,
        title: '',
      },
      {
        sortBy: null,
        title: '',
      },
    ],
  },
}

export const other = {
  static: {
    modalDocumentDescriptionLabel:
      'If unsure, describe the purpose of this document',
    modalDocumentPlaceholder: 'Enter description...',
    paneDescription:
      "Have anything else you want to share? Please use this section to upload any other documents you'd like to share with our team.",
    paneTitle: 'Other',
    tableColumns: [
      {
        sortBy: 'createdAt',
        title: 'Date uploaded',
      },
      {
        sortBy: 'fileName',
        title: 'File Name',
      },
      {
        sortBy: 'description',
        title: 'Description',
      },
      {
        sortBy: null,
        title: 'Actions',
      },
    ],
    uploadModalHeaderText: 'Upload Documents',
    modalDocumentTypeLabel: 'Select A Document Type',
    modalNoDocumentTypesAvailable: 'No document types are available',
  },
  dynamic: {},
}

/* Shared Components */
export const documentsSearchBar = {
  static: {
    placeholderText: 'Search document name',
  },
}

export const actionButton = {
  static: {
    confirmButtonLoadingText: 'Deleting...',
    confirmButtonNotLoadingText: 'Delete',
    deleteMenuItemText: 'Delete File',
  },
  dynamic: {
    confirmContent: (document: UserDocument) => {
      return `Are you sure you want to delete ${
        document.fileDescription || 'the selected document'
      }? Once deleted, this file cannot be retrieved.`
    },
  },
}

export const requiredDocumentUploadButton = {
  static: {
    buttonText: 'Upload',
  },
}
