import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Divider, Grid } from 'semantic-ui-react'
import moment from 'moment'

import { fetchContractorPayment, fetchContractors } from '../payrollActions'
import { selectContractorByUuid } from '../payroll.selectors'
import { useAppDispatch } from '../../../utils/typeHelpers'
import ContractorPaymentTable from '../RunPayroll/ContractorPayroll/ContractorPaymentTable'
import ContactSupport from './ContactSupport'
import { formatCurrency } from '../../../utils/currencyHelpers'
import { Link, Text, Card } from '../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useReselector } from '../../../utils/sharedHooks'
import { Gusto_ContractorPayment } from '../generated_gusto_types'

const formatDate = (date: string) =>
  moment(date).format(DATE_FORMATS.DISPLAY_SHORT)

const PayrollOverview = ({
  payment,
  total,
}: {
  payment: Gusto_ContractorPayment | undefined
  total: number
}) => {
  if (!payment) {
    return null
  }
  return (
    <Grid className="bordered-grid green-top">
      <Grid.Row className="short">
        <Text as="h1">Payroll Details</Text>
      </Grid.Row>
      <Divider className="short" />
      <Grid.Row>
        <Grid.Column width={6}>
          <b>Funds Debited :</b>
        </Grid.Column>
        <Grid.Column width={10} textAlign="right">
          <Text as="bodyMd">{formatCurrency(total)}</Text>
        </Grid.Column>
      </Grid.Row>
      <Divider className="short" />
      <Grid.Row>
        <Grid.Column width={6}>
          <Text as="bodyMd">
            <b>Pay Date: </b>
          </Text>
        </Grid.Column>
        <Grid.Column width={10} textAlign="right">
          <Text as="bodyMd">{formatDate(payment.date)}</Text>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row />
      <Grid.Row />
      <Grid.Row />
    </Grid>
  )
}

const PayrollHistoryContractorDetails = () => {
  const dispatch = useAppDispatch()
  const [payment, setPayment] = useState<Gusto_ContractorPayment>()
  const contractor = useReselector(
    selectContractorByUuid,
    payment?.contractor_uuid
  )

  const { uuid } = useParams()

  useEffect(() => {
    const fetch = async () => {
      if (!uuid) {
        return
      }
      const res = await fetchContractorPayment(uuid)(dispatch)

      if (res) {
        setPayment(res)
      }
    }
    fetch()
    dispatch(fetchContractors())
  }, [dispatch, uuid])

  const total = payment
    ? Number(payment.wage_total) +
      Number(payment.reimbursement) +
      Number(payment.bonus)
    : 0

  return (
    <Container>
      <Card>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Text as="h1">Payroll History</Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="short">
            <Grid.Column>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Link to="/payroll/history">Payroll History</Link>
                {payment && (
                  <Text as="bodyMd" style={{ paddingTop: 3 }}>
                    <b>
                      &nbsp;&rsaquo;&nbsp;<b>Contractor Payment:</b>{' '}
                      {moment(payment.date).format(DATE_FORMATS.DISPLAY_SHORT)}
                    </b>
                  </Text>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row />
          <Grid.Row>
            <Grid.Column width={7}>
              <PayrollOverview payment={payment} total={total} />
            </Grid.Column>
            <Grid.Column width={9}>
              <ContactSupport />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row />
          <Grid.Row>
            <Grid.Column>
              {payment && contractor && (
                <ContractorPaymentTable
                  contractor={contractor}
                  hours={payment.hours}
                  bonus={payment.bonus}
                  wages={payment.wage_total}
                  reimbursement={payment.reimbursement}
                  total={total}
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row />
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollHistoryContractorDetails
