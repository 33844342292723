import { FC } from 'react'

// This file imports all DocumentTabPanes & converts them into a shape compatible with semantic-ui
// BL
import { UserDocument } from '../userDocuments.slice'
import {
  GroupByType,
  TableOptions,
} from '../sharedComponents/Table/UserDocumentsTable'
import {
  sortDocumentsByFileNameAsc,
  sortDocumentsByNameAscWithinCreatedAtDesc,
  sortDocumentsByNameAscWithinStatementDateDesc,
} from '../service'

// UI
import BankStatements from './BankStatements'
import Bookkeeping from './Bookkeeping'
import Tax from './Tax'
import IncorporationDocuments from './IncorporationDocuments'
import Receipts from './Receipts'
import Other from './Other'

/* These properties can be accessed from the imported TabPane object, e.g
 *  import { BankStatements } from './tabPanes'
 *  BankStatements.paneDescription
 *
 * Implemented so we can keep content assignment encapsulated within each Panel
 * and have reference to it from a parent component
 */
export interface DocumentTabPane extends FC<DocumentTabPaneProps> {
  paneDescription: string
  paneTitle: string
}
/** --- */

export interface DocumentTabPaneProps {
  documents: UserDocument[]
  tableOptions: TableOptions
}

// Populates a collection of TabPanes compatible with semantic-ui Tab component
export const createTabPanes = ({
  bankStatementDocuments,
  bookkeepingDocuments,
  taxRelatedDocuments,
  incorporationDocuments,
  receiptDocuments,
  otherDocuments,
}: {
  bankStatementDocuments: UserDocument[]
  bookkeepingDocuments: UserDocument[]
  taxRelatedDocuments: UserDocument[]
  incorporationDocuments: UserDocument[]
  receiptDocuments: UserDocument[]
  otherDocuments: UserDocument[]
}) => [
  {
    menuItem: BankStatements.paneTitle,
    paneDescription: BankStatements.paneDescription,
    render: () => (
      <BankStatements
        documents={bankStatementDocuments}
        tableOptions={{
          sortFn: sortDocumentsByNameAscWithinStatementDateDesc,
          groupBy: GroupByType.StatementYear,
        }}
      />
    ),
  },
  {
    menuItem: Bookkeeping.paneTitle,
    paneDescription: Bookkeeping.paneDescription,
    render: () => (
      <Bookkeeping
        documents={bookkeepingDocuments}
        tableOptions={{
          sortFn: sortDocumentsByNameAscWithinCreatedAtDesc,
          groupBy: GroupByType.CreatedAtYear,
        }}
      />
    ),
  },
  {
    menuItem: Tax.paneTitle,
    paneDescription: Tax.paneDescription,
    render: () => (
      <Tax
        documents={taxRelatedDocuments}
        tableOptions={{
          sortFn: sortDocumentsByNameAscWithinCreatedAtDesc,
          groupBy: GroupByType.CategoryYear,
        }}
      />
    ),
  },
  {
    menuItem: IncorporationDocuments.paneTitle,
    paneDescription: IncorporationDocuments.paneDescription,
    render: () => (
      <IncorporationDocuments
        documents={incorporationDocuments}
        tableOptions={{
          sortFn: sortDocumentsByFileNameAsc,
          groupBy: GroupByType.None,
        }}
      />
    ),
  },
  {
    menuItem: Receipts.paneTitle,
    paneDescription: Receipts.paneDescription,
    render: () => (
      <Receipts
        documents={receiptDocuments}
        tableOptions={{
          sortFn: sortDocumentsByNameAscWithinCreatedAtDesc,
          groupBy: GroupByType.MonthWithinYear,
        }}
      />
    ),
  },
  {
    menuItem: Other.paneTitle,
    paneDescription: Other.paneDescription,
    render: () => (
      <Other
        documents={otherDocuments}
        tableOptions={{
          sortFn: sortDocumentsByNameAscWithinCreatedAtDesc,
          groupBy: GroupByType.None,
        }}
      />
    ),
  },
]
