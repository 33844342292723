import { Grid, Image } from 'semantic-ui-react'
import {
  Button,
  Card,
  GridRowColumn,
  Icon,
  Text,
} from '../../../components/BaseComponents'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom'
import { useReselector } from '../../../utils/sharedHooks'
import {
  selectCompletedAtForOnboardingStep,
  selectHasUserCompletedOnboardingPhase,
  selectLastStartedAtStepForOnboardingPhase,
} from '../../Onboarding/UserOnboardingSteps/onboarding.selectors'
import {
  BookkeepingOnboardingStep,
  OnboardingStepPhase,
} from '../../Onboarding/UserOnboardingSteps/onboarding.reducer'
import {
  DATE_FORMATS_LUXON,
  formatISOFromUTC,
} from '../../../utils/dateHelpers'
import { useEffect } from 'react'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { fetchUserOnboardingStepsIfNeeded } from '../../Onboarding/UserOnboardingSteps/onboarding.actions'

export interface LearnWithHeardItemProps {
  imageBackgroundColor: string
  imageSrc: string
  imageAlt: string
  title: string
  description: string
  navigateTo: string
  timeItTakes: string
  key: string
}

const LearnWithHeardItem = ({
  imageBackgroundColor,
  imageSrc,
  imageAlt,
  title,
  description,
  navigateTo,
  timeItTakes,
}: LearnWithHeardItemProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const ahaMomentBookkeepingPhaseComplete = useReselector(
    selectHasUserCompletedOnboardingPhase,
    OnboardingStepPhase.ahaMomentBookkeeping
  )

  const bookkeepingPhaseCompletedAtDate = useReselector(
    selectCompletedAtForOnboardingStep,
    BookkeepingOnboardingStep.transactionsClarifiedSuccess
  )

  const lastStartedAtStepforBookkeeping = useReselector(
    selectLastStartedAtStepForOnboardingPhase,
    OnboardingStepPhase.ahaMomentBookkeeping
  )

  const getNavigationUrl = () => {
    // if the user has completed the bookkeeping phase, navigate to the base url to start at the begining
    if (ahaMomentBookkeepingPhaseComplete) {
      return navigateTo
    }
    // else navigate to the last step the user started
    if (lastStartedAtStepforBookkeeping) {
      const stepId =
        lastStartedAtStepforBookkeeping.onboardingStepId.toLowerCase()
      return `${navigateTo}?step=${stepId}`
    }

    return navigateTo
  }

  const navigateToUrl = getNavigationUrl()

  useEffect(() => {
    dispatch(fetchUserOnboardingStepsIfNeeded())
  }, [dispatch])

  return (
    <Card
      type="section"
      backgroundColor={
        ahaMomentBookkeepingPhaseComplete ? 'stone40' : 'natural'
      }
      style={{ boxShadow: 'none', border: 'none' }}
    >
      <Card.Content>
        <Grid style={{ padding: 12 }}>
          <GridRowColumn
            centerContent
            style={{
              backgroundColor: imageBackgroundColor,
              margin: 15,
            }}
          >
            <Image
              src={imageSrc}
              alt={imageAlt}
              width={100}
              height={100}
              style={{
                margin: 20,
              }}
            />
          </GridRowColumn>
          <GridRowColumn>
            <Text as="h2">{title}</Text>
            <br />
            <Text as="bodySm">{description}</Text>
          </GridRowColumn>
          <Text as="bodySm" style={{ color: 'gray' }}>
            {bookkeepingPhaseCompletedAtDate &&
              `Reviewed on ${formatISOFromUTC(
                bookkeepingPhaseCompletedAtDate,
                DATE_FORMATS_LUXON.DISPLAY_SHORT
              )}`}
          </Text>
          <GridRowColumn>
            <Button
              variant={
                ahaMomentBookkeepingPhaseComplete ? 'secondary' : 'primary'
              }
              onClick={() => navigate(navigateToUrl)}
            >
              {ahaMomentBookkeepingPhaseComplete ? 'Review' : 'Learn More'}
              <Icon icon={regular('arrow-right')} style={{ marginLeft: 8 }} />
            </Button>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="bodySm">
              <Icon
                icon={regular('timer')}
                style={{ marginRight: 10 }}
                size="1x"
              />
              {timeItTakes}
            </Text>
          </GridRowColumn>
        </Grid>
      </Card.Content>
    </Card>
  )
}

export default LearnWithHeardItem
