import { useEffect, useMemo } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import moment from 'moment'

import {
  useAsyncCallback,
  useReselector,
} from '../../../../../../utils/sharedHooks'
import { fetchFilteredUserTransactions } from '../../../../../Transactions/transactions.slice'
import { fetchTransactionCategoriesIfNeeded } from '../../../../../Reports/reports.slice'
import {
  GridRowColumn,
  Link,
  Text,
} from '../../../../../../components/BaseComponents'
import { Colors } from '../../../../../../styles/theme'
import { formatCurrencyFromCents } from '../../../../../../utils/currencyHelpers'
import { TQTransactionCategoryRow } from './ReviewIncomePanel'
import { selectFilteredGroupedUserTransactions } from '../../../../../Transactions/transactions.selectors'
import { ReviewIncomeAndExpensesProps } from '.'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import {
  selectReviewBooksCompleted,
  selectUserEoyReviewProgressForSubstepIdentifier,
} from '../ReviewStepsandProgresses/userEndOfYearReviewProgress.selector'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { selectUserBooksLockedForCurrentTaxYear } from '../../../../../../reducers/auth/user.selectors'
import { SubStepIdentifiers } from '../ReviewStepsandProgresses/stepProgress.helpers'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'

const ReviewExpenses = ({
  goToNextStep,
  goBack,
  previousScreen,
}: ReviewIncomeAndExpensesProps) => {
  const dispatch = useAppDispatch()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const initialExpenseReviewProgress = useReselector(
    selectUserEoyReviewProgressForSubstepIdentifier,
    SubStepIdentifiers.reviewExpensesInitial
  )
  const finalExpenseReviewProgress = useReselector(
    selectUserEoyReviewProgressForSubstepIdentifier,
    SubStepIdentifiers.reviewExpensesFinal
  )
  const firstReviewCompleted = useReselector(selectReviewBooksCompleted, false)
  const booksLocked = useReselector(selectUserBooksLockedForCurrentTaxYear)
  const isFinalReview = useMemo(
    () => firstReviewCompleted && booksLocked,
    [firstReviewCompleted, booksLocked]
  )

  // not updating these to Luxon for now because
  // they're used in the transaction selectors and would be high risk to change
  const startDate = useMemo(
    () => moment().utc().year(Number(taxYear)).startOf('year'),
    [taxYear]
  )
  const endDate = useMemo(
    () => moment().utc().year(Number(taxYear)).endOf('year'),
    [taxYear]
  )

  const expensesTransactions = useReselector(
    selectFilteredGroupedUserTransactions,
    { startDate, endDate }
  )

  const saveAndContinue = useAsyncCallback(() =>
    goToNextStep(
      isFinalReview
        ? finalExpenseReviewProgress?.id
        : initialExpenseReviewProgress?.id
    )
  )

  const { totalExpenses, groupedExpenses } = expensesTransactions

  useEffect(() => {
    dispatch(fetchFilteredUserTransactions({ startDate, endDate }))
  }, [dispatch, endDate, startDate])

  useEffect(() => {
    dispatch(fetchTransactionCategoriesIfNeeded())
  }, [dispatch])

  return (
    <>
      <Grid>
        <Grid.Row />
        <Grid.Row />
        <GridRowColumn centerContent>
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/payroll.svg"
            style={{ height: 120 }}
          />
        </GridRowColumn>
        <GridRowColumn spacer={4} width={8} short>
          <Text as="h1" textAlign="center">
            Review {taxYear} Expenses
          </Text>
        </GridRowColumn>
        {!isFinalReview && (
          <>
            <GridRowColumn spacer={4} width={8}>
              <Text as="bodyLg">
                Please look through your expenses. Your review allows the Heard
                Team to finish your year-end bookkeeping, minimize how much
                you&apos;ll owe in taxes and ensure an accurate tax return.
              </Text>
            </GridRowColumn>
            <GridRowColumn spacer={4} width={8}>
              <Text as="bodyLg">
                If something seems off, leave a note explaining why.
              </Text>
            </GridRowColumn>
          </>
        )}
        {isFinalReview && (
          <>
            <GridRowColumn spacer={4} width={8}>
              <Text as="bodyLg">
                You&apos;ve reviewed your annual expenses before, but it&apos;s
                important to do a final check and make sure these numbers
                correctly represent your business.
              </Text>
            </GridRowColumn>
            <GridRowColumn spacer={4} width={8}>
              <Text as="bodyLg">
                If something seems off, please reach out to your bookkeeper via{' '}
                <Link to="/conversations">Conversations</Link>.
              </Text>
            </GridRowColumn>
          </>
        )}
        <Grid.Row />
        <GridRowColumn
          spacer={3}
          width={10}
          columnStyle={{ backgroundColor: Colors.stone, borderRadius: 8 }}
        >
          <Grid>
            <Grid.Row style={{ height: 88 }} verticalAlign="middle">
              <Grid.Column width={8}>
                <Text as="h3">Total Expenses</Text>
              </Grid.Column>
              <Grid.Column width={8}>
                <Text textAlign="right" as="h3">
                  {formatCurrencyFromCents(totalExpenses)}
                </Text>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </GridRowColumn>
        {Object.values(groupedExpenses).map((transactions) => (
          <TQTransactionCategoryRow
            key={transactions[0]?.transactionCategoryId || 'null'}
            transactions={transactions}
            defaultTitle="Uncategorized Expenses"
          />
        ))}
      </Grid>
      <Grid>
        <FormFlowFooter
          onBack={() => goBack(previousScreen ?? null)}
          onForward={saveAndContinue.callback}
          loading={saveAndContinue.loading}
          continueDisabled={saveAndContinue.loading}
        />
      </Grid>
    </>
  )
}

export default ReviewExpenses
