import {
  forwardRef,
  useImperativeHandle,
  useRef,
  cloneElement,
  ReactElement,
} from 'react'
import generatePDF, { Margin } from 'react-to-pdf'

export type PdfWrapperHandle = { generatePDF: () => void }

/*
This component facilitates the generation of PDF documents from its child content.
It accomplishes this by creating an invisible container for the child content and 
uses the 'react-to-pdf' library to convert it into a PDF document.

Example Usage:
    const pdfRef = useRef<PdfWrapperHandle>(null);
    const downloadPdf = () => {
        pdfRef.current?.generatePDF();
    }
    return (
        <Button onClick={downloadPdf} />
        <PdfWrapper ref={pdfRef} filename="example.pdf" width={1000}>
            <ExampleComponent />
        </PdfWrapper>
    )
*/
const PdfWrapper = forwardRef(
  (
    {
      children,
      filename,
      width = 1100,
      // If true then this component won't be mirrored on screen
      hideComponent = false,
      // There is a natural border around content but if there are components that need to be flush with edges this should be set to true
      hideMargin = false,
    }: {
      children: ReactElement
      filename: string
      width?: number
      hideComponent?: boolean
      hideMargin?: boolean
    },
    ref
  ) => {
    const pdfRef = useRef(null)

    useImperativeHandle(ref, () => ({
      generatePDF: () =>
        generatePDF(pdfRef, {
          filename,
          page: { margin: hideMargin ? Margin.NONE : Margin.SMALL },
        }),
    }))

    return (
      <>
        {!hideComponent && children}
        <div
          ref={pdfRef}
          style={{
            position: 'absolute',
            left: -9999,
            width,
          }}
        >
          {cloneElement(children, { isPdf: true })}
        </div>
      </>
    )
  }
)

export default PdfWrapper
