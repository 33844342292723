import { useEffect, useState } from 'react'
import { Container, Divider, Grid } from 'semantic-ui-react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import SignupHeader from '../SignupHeader'
import { Button, Card, Icon, Text } from '../../../components/BaseComponents'
import AboutPlaidRow from './AboutPlaidRow'
import { SkipConnectBankModal } from './ConnectBankModals'
import ConnectedBanks from './ConnectedBanks'
import { selectActivePlaidFinancialAccounts } from '../../../selectors/financeSelectors'
import { SIGNUP_PATHS } from '../helpers'
import { useSignupPageUpdate } from '../../../services/onboardingService'
import { useAnalyticsView } from '../../Amplitude'
import {
  useNavigateWithPersistParams,
  useReselector,
} from '../../../utils/sharedHooks'
import { PlaidLinkContextProvider } from '../../../components/Finances/Accounts/PlaidLinkContext'

const ConnectBank = () => {
  const navigate = useNavigateWithPersistParams()
  const [skipModalOpen, setSkipModalOpen] = useState(false)
  const accounts = useReselector(selectActivePlaidFinancialAccounts)
  const hasAccount = Boolean(Object.values(accounts).length)
  useSignupPageUpdate(SIGNUP_PATHS.connectBank)
  const pageView = useAnalyticsView()

  useEffect(() => {
    pageView('sign up connect bank')
  }, [pageView])

  return (
    <Container style={{ paddingTop: 64 }}>
      <Grid>
        <SignupHeader currentStep={2} />
        <Grid.Row>
          <Grid.Column>
            <Card backgroundColor="sunrise">
              <Grid>
                <Grid.Row>
                  <Grid.Column
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Icon icon={light('institution')} size="3x" color="oak" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Text as="h1" textAlign="center">
                      Connect bank account with Plaid
                    </Text>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="short">
                  <Grid.Column>
                    <Text as="bodyLg" textAlign="center">
                      Please connect any checking, savings and credit card
                      accounts you use for your business.
                    </Text>
                  </Grid.Column>
                </Grid.Row>
                <PlaidLinkContextProvider>
                  <ConnectedBanks />
                </PlaidLinkContextProvider>
              </Grid>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <AboutPlaidRow />
        <Grid.Row />
        <Divider />
        <Grid.Row />
        <Grid.Row>
          <Grid.Column width={2}>
            <Button
              variant="secondary"
              fullWidth
              onClick={() => navigate(SIGNUP_PATHS.practiceJourney)}
            >
              Back
            </Button>
          </Grid.Column>
          <Grid.Column width={11} />
          <Grid.Column width={3}>
            <Button
              fullWidth
              variant={hasAccount ? 'primary' : 'secondary'}
              id="btn-signup-connect-accounts"
              onClick={() =>
                hasAccount
                  ? navigate(SIGNUP_PATHS.choosePlan)
                  : setSkipModalOpen(true)
              }
            >
              {hasAccount ? 'Continue' : 'Skip for Now'}
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row />
      </Grid>
      {skipModalOpen && (
        <SkipConnectBankModal closeModal={() => setSkipModalOpen(false)} />
      )}
    </Container>
  )
}

export default ConnectBank
