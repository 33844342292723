import { Dispatch, SetStateAction } from 'react'

import { adminDestroyUserDocument } from '../../../actions/admin/userDocumentsActions'
import { UserDocument } from '../../UserDocuments/userDocuments.slice'
import { Button, Modal } from '../../../components/BaseComponents'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface Props {
  modalOpen: boolean
  setModalOpen: Dispatch<SetStateAction<boolean>>
  document: UserDocument
}

const DeleteDocumentModal = ({ modalOpen, setModalOpen, document }: Props) => {
  const dispatch = useAppDispatch()
  const handleDelete = async () => {
    await adminDestroyUserDocument(document.id)(dispatch)
    setModalOpen(false)
  }

  return (
    <Modal
      size="small"
      dimmer="inverted"
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      closeIcon
    >
      <Modal.Content>
        Are you sure you want to delete {document.fileDescription}? This is a
        permanent deletion.
      </Modal.Content>
      <Modal.Actions
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button
          variant="secondary"
          onClick={() => {
            setModalOpen(false)
          }}
        >
          Cancel
        </Button>

        <Button color="red" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default DeleteDocumentModal
