import { useCallback, useEffect, useMemo, useState } from 'react'
import { Divider, Grid } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import {
  FinancialAccountWithAdminInfo,
  Reconciliation,
} from '../../../../reducers/admin/financialAccountsReducer'
import {
  Alert,
  Button,
  DatePicker,
  Input,
  Modal,
  Text,
} from '../../../BaseComponents'
import { DATE_FORMATS_LUXON } from '../../../../utils/dateHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import {
  RECOVER_RECONCILIATION_KEY,
  recoverAutoReconciliation,
} from '../../../../actions/admin/accountReconciliationActions'
import { getFetchError, getIsFetching } from '../../../../reducers/fetch'
import { ENABLE_ADMIN, useAnalyticsTrack } from '../../../../features/Amplitude'
import { selectUserDocumentsByReportId } from '../../../../features/UserDocuments/userDocuments.selector'
import { centsToDollars } from '../../../../utils/currencyHelpers'

export interface AutoReconciliationRecoveryModalProps {
  userId: string
  reportId: number | string
  account: FinancialAccountWithAdminInfo
  reconciliation: Reconciliation | null
  previousReconciliation: Reconciliation | null
  open: boolean
  close: (reconciliation: Reconciliation | null) => Promise<void>
}
const AutoReconciliationRecoveryModal = ({
  userId,
  reportId,
  account,
  reconciliation,
  previousReconciliation,
  open,
  close,
}: AutoReconciliationRecoveryModalProps) => {
  const dispatch = useAppDispatch()
  const [startDate, setStartDate] = useState<DateTime | null>(null)
  const [endDate, setEndDate] = useState<DateTime | null>(null)
  const [endAmount, setEndAmount] = useState<string>('0')
  const track = useAnalyticsTrack(ENABLE_ADMIN)

  const isFetching = useReselector(getIsFetching, RECOVER_RECONCILIATION_KEY)
  const error = useReselector(getFetchError, RECOVER_RECONCILIATION_KEY)
  const statements = useReselector(
    selectUserDocumentsByReportId,
    userId,
    reportId
  )
  const statementDoc = useMemo(
    () =>
      statements?.find((doc) =>
        doc?.statement?.statementFinancialAccounts?.some(
          (sfa) => sfa.financialAccountId === account.id
        )
      ),
    [account, statements]
  )

  useEffect(() => {
    if (previousReconciliation) {
      setStartDate(DateTime.fromISO(previousReconciliation.endingBalanceDate))
    }
  }, [previousReconciliation])

  const onSubmit = useCallback(async () => {
    if (reconciliation?.id && endDate && endAmount) {
      const result = await recoverAutoReconciliation(
        reconciliation?.id,
        endDate,
        endAmount
      )(dispatch)
      if (result) {
        if (result.analyticsScenario) {
          track(result.analyticsScenario, {
            accountId: account.id,
            reconciliationId: result.reconciliation.id,
            endDate: endDate.toISODate(),
            endAmount,
          })
        }
        close(result.reconciliation)
      }
    }
  }, [
    reconciliation?.id,
    account.id,
    endDate,
    endAmount,
    close,
    dispatch,
    track,
  ])

  return (
    <Modal
      open={open}
      size="small"
      centered
      closeIcon
      onClose={() => close(null)}
    >
      <Modal.Header as="h3">Auto-reconciliation failed</Modal.Header>
      <Modal.Content>
        {error && <Alert type="error">{error.message}</Alert>}
        {!error && (
          <Alert type="error" title="Balances couldn't be matched">
            Please enter the ending date and balance from the bank statement to
            reattempt auto-reconciliation.
          </Alert>
        )}
        <Grid style={{ paddingTop: 20 }}>
          <Grid.Row>
            <Grid.Column widescreen={8}>
              <Text>{account.plaidInstitutionName}</Text>
              <Text>
                <strong>{account.name}</strong>: {account.mask}
              </Text>
              <Text>
                {account.type} -- {account.subtype}
              </Text>
              <div style={{ paddingTop: 10 }}>
                {statementDoc?.statement?.statementSource ===
                'plaidStatement' ? (
                  <Text as="bodyXs">Statement pulled via Plaid Statements</Text>
                ) : (
                  <Text as="bodyXs">
                    <strong>Manual Upload: </strong>Requesting statement from
                    the customer on the 7th
                  </Text>
                )}
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <Button
                variant="secondary"
                disabled={!statementDoc?.signedUrl}
                style={{ float: 'right' }}
                onClick={() => {
                  if (statementDoc?.signedUrl) {
                    window.open(statementDoc?.signedUrl, '_blank')
                  }
                }}
              >
                {statementDoc?.signedUrl
                  ? 'View Bank Statement'
                  : 'Waiting on Bank Statement'}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider />

        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Input
                label="Starting Date"
                disabled
                componentType="date"
                leftIcon="calendar outline"
                value={startDate?.toFormat(DATE_FORMATS_LUXON.INPUT) || ''}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Input
                label="Starting Balance"
                componentType="currency"
                value={
                  previousReconciliation?.endingBalanceInCents
                    ? centsToDollars(
                        previousReconciliation.endingBalanceInCents
                      ).toString()
                    : '0'
                }
                disabled
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <DatePicker
                label="Ending Date"
                disabled={isFetching}
                minDate={startDate?.toJSDate()}
                maxDate={DateTime.now()
                  .minus({ day: 1 })
                  .startOf('day')
                  .toJSDate()}
                placeholder="Select date"
                value={endDate?.toFormat(DATE_FORMATS_LUXON.INPUT) || ''}
                onChange={(value: string) => {
                  setEndDate(
                    DateTime.fromFormat(value, DATE_FORMATS_LUXON.INPUT, {
                      zone: 'utc',
                    })
                  )
                }}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Input
                label="Ending Balance"
                componentType="currency"
                value={endAmount}
                onChange={setEndAmount}
                disabled={isFetching}
                placeholder="0.00"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="secondary"
          disabled={isFetching}
          onClick={() => close(null)}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={isFetching}
          loading={isFetching}
          onClick={onSubmit}
        >
          Auto-Reconcile
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default AutoReconciliationRecoveryModal
