import { Moment } from 'moment'
import { Grid } from 'semantic-ui-react'
import { getUserTransactionsSelector } from '../../../features/Transactions/transactions.selectors'
import MobileTableRow from '../../../features/Transactions/TransactionTable/MobileTableRow'
import { GridRowColumn } from '../../BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'

export const ReportsTransactionTable = ({
  startDate,
  endDate,
}: {
  startDate: Moment
  endDate: Moment
}) => {
  const transactions = useReselector(getUserTransactionsSelector, {
    startDate,
    endDate,
  })

  return (
    <Grid>
      <GridRowColumn short>
        {transactions.map((transaction) => (
          <MobileTableRow
            key={transaction.id}
            transaction={transaction}
            reports
          />
        ))}
      </GridRowColumn>
    </Grid>
  )
}
