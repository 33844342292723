import React, { useState, Dispatch, SetStateAction, useEffect } from 'react'
import GridRowWrapper from './shared/GridRowWrapper'
import { Button, Dropdown, DropdownProps, Form } from 'semantic-ui-react'

interface ReplyToRowProps {
  selectedReplyTo?: number
  editingIndex: number | null
  setEditingIndex: Dispatch<SetStateAction<number | null>>
  setSelectedReplyTo: Dispatch<SetStateAction<number | undefined>>
  disableEditing: boolean
}

const COMPONENT_INDEX = 1

const ReplyToRow: React.FC<ReplyToRowProps> = ({
  selectedReplyTo,
  editingIndex,
  setEditingIndex,
  setSelectedReplyTo,
  disableEditing,
}) => {
  const resetForm = () => {
    setEditingIndex(null)
    setDropdownValue(selectedReplyTo)
  }

  const onDropdownChange = (_e: unknown, data: DropdownProps) => {
    if (data.value) {
      setDropdownValue(Number(data.value))
    }
  }

  const onSaveClicked = () => {
    if (dropdownValue) {
      setSelectedReplyTo(dropdownValue)
      setEditingIndex(null)
    }
  }

  useEffect(() => {
    setDropdownValue(selectedReplyTo)
  }, [selectedReplyTo])

  const [dropdownValue, setDropdownValue] = useState(selectedReplyTo)
  const optionText = "The Customer's AM"
  const buttonText = selectedReplyTo ? 'Edit Reply-To' : 'Add Reply-To'
  const subtitle = selectedReplyTo
    ? optionText
    : 'Who should receive Customer replies?'

  const options = [
    {
      text: optionText,
      value: 1,
      key: 1,
    },
  ]

  return (
    <GridRowWrapper
      buttonHidden={editingIndex === COMPONENT_INDEX}
      buttonDisabled={editingIndex !== null || disableEditing}
      buttonText={buttonText}
      subtitle={subtitle}
      title="Reply-To"
      checked={Boolean(selectedReplyTo)}
      buttonOnClick={() => setEditingIndex(COMPONENT_INDEX)}
    >
      {editingIndex === COMPONENT_INDEX && (
        <Form style={{ width: '300px' }}>
          <Form.Field>
            <label>Heard Staff</label>
            <Dropdown
              selection
              selectOnBlur={false}
              placeholder="Choose a group"
              options={options}
              value={dropdownValue}
              onChange={onDropdownChange}
            />
          </Form.Field>
          <Button
            onClick={onSaveClicked}
            className="form-button"
            disabled={!dropdownValue}
            primary
          >
            Save
          </Button>
          <Button onClick={resetForm} basic>
            Cancel
          </Button>
        </Form>
      )}
    </GridRowWrapper>
  )
}

export default ReplyToRow
