import { FC, useCallback, useMemo, useState } from 'react'
import moment from 'moment'

// BL
import { UserDocument } from '../../userDocuments.slice'
import {
  groupDocumentsByYear,
  groupDocumentsByMonthWithinYear,
  sortDocuments,
} from '../../service'

// UI
import UserDocumentsTableHeader, {
  TableHeaderConfig,
} from './UserDocumentsTableHeader'
import { Table, Text } from '../../../../components/BaseComponents'
import {
  getUserDocumentCategoriesById,
  selectUserDocumentCategoryList,
} from '../../../Admin/UserDocumentCategories/userDocumentCategories.selectors'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectTaxUserDocuments } from '../../../Taxes/AnnualTaxes/taxUserDocuments.selector'
import { RowComponentProps } from './UserDocumentsTableRow'

export enum GroupByType {
  MonthWithinYear,
  CreatedAtYear,
  None,
  StatementYear,
  CategoryYear,
}

export interface TableOptions {
  sortFn: (_a: UserDocument, _b: UserDocument) => number
  groupBy: GroupByType
}

interface UserDocumentsTableProps {
  columns: TableHeaderConfig[]
  documents: UserDocument[]
  RowComponent: FC<RowComponentProps>
  tableOptions: TableOptions
  onCheck?: (id: number, checked: boolean) => void
  fixed?: boolean
}

const EMPTY_COLUMN = {
  sortBy: null,
  title: '',
}

const useDocSort = (documents: UserDocument[]) => {
  const categories = useReselector(selectUserDocumentCategoryList)
  const [sortVal, setSortVal] = useState<string | null>(null)
  const [sortAsc, setSortAsc] = useState(false)

  const sort = useCallback(
    (value: string | null) => {
      if (value && sortVal === value) {
        setSortAsc((sortAsc) => !sortAsc)
      } else {
        setSortVal(value)
        setSortAsc(false)
      }
    },
    [sortVal]
  )

  const sortedDocs = useMemo(
    () =>
      sortDocuments({
        docs: documents,
        sortAsc,
        sortByVal: sortVal,
        cats: categories,
      }),
    [categories, documents, sortAsc, sortVal]
  )

  return { sort, sortedDocs, sortVal, sortAsc }
}

const renderRowsForGroup = ({
  group,
  RowComponent,
  onCheck,
}: {
  group: UserDocument[]
  RowComponent: FC<RowComponentProps>
  onCheck?: (id: number, checked: boolean) => void
}) =>
  group.map((doc) => (
    <RowComponent
      // rows with an "Upload" action button all have -1 as the doc Id, which messes up the sorting
      key={`group-row-${doc.id}-${doc.userDocumentCategoryId}`}
      document={doc}
      onCheck={onCheck}
    />
  ))

const renderMonthRow = ({
  groups,
  ...rest
}: {
  groups: Map<number, UserDocument[]>
  RowComponent: FC<RowComponentProps>
  columns: TableHeaderConfig[]
  onCheck?: (id: number, checked: boolean) => void
}) =>
  [...groups.keys()].map((month) => (
    <MonthRow
      key={`document-table-group-${month}`}
      groups={groups}
      month={month}
      {...rest}
    />
  ))

const GroupedByYearTable = ({
  columns,
  year,
  groups,
  RowComponent,
  onCheck,
}: {
  columns: TableHeaderConfig[]
  year: number
  groups: Map<number, UserDocument[]>
  RowComponent: FC<RowComponentProps>
  onCheck?: (id: number, checked: boolean) => void
}) => {
  const [open, setOpen] = useState(true)
  const { sort, sortedDocs, sortVal, sortAsc } = useDocSort(
    groups.get(year) || []
  )

  return (
    <>
      <UserDocumentsTableHeader
        type="Primary"
        columns={[
          { title: year.toString(), sortBy: null, width: columns[0].width },
          ...columns
            .slice(1)
            .map((col) => ({ ...EMPTY_COLUMN, width: col.width })),
        ]}
        onClick={() => setOpen((open) => !open)}
        isOpen={open}
      />
      {open && (
        <>
          <UserDocumentsTableHeader
            type="Tertiary"
            columns={columns}
            onSort={(value) => sort(value)}
            sortAsc={sortAsc}
            sortVal={sortVal}
          />
          <Table.Body>
            {renderRowsForGroup({
              group: sortedDocs,
              RowComponent,
              onCheck,
            })}
          </Table.Body>
        </>
      )}
      <Table.Row className="spacer" />
    </>
  )
}

const MonthRow = ({
  columns,
  groups,
  RowComponent,
  month,
  onCheck,
}: {
  columns: TableHeaderConfig[]
  groups: Map<number, UserDocument[]>
  RowComponent: FC<RowComponentProps>
  month: number
  onCheck?: (id: number, checked: boolean) => void
}) => {
  const monthName = moment().month(month).format('MMMM')
  const [open, setOpen] = useState(true)
  const { sort, sortedDocs, sortVal, sortAsc } = useDocSort(
    groups.get(month) || []
  )

  return (
    <>
      <UserDocumentsTableHeader
        type="Secondary"
        columns={[
          { title: monthName, sortBy: null },
          ...columns
            .slice(1)
            .map((col) => ({ ...EMPTY_COLUMN, width: col.width })),
        ]}
        onClick={() => setOpen((open) => !open)}
        isOpen={open}
      />
      {open && (
        <>
          <UserDocumentsTableHeader
            type="Tertiary"
            columns={columns}
            onSort={(value) => sort(value)}
            sortAsc={sortAsc}
            sortVal={sortVal}
          />
          <Table.Body>
            {renderRowsForGroup({
              group: sortedDocs,
              RowComponent,
              onCheck,
            })}
          </Table.Body>
        </>
      )}

      <Table.Row className="spacer" />
    </>
  )
}

const GroupMonthWithinYear = ({
  columns,
  groups,
  RowComponent,
  year,
  onCheck,
}: {
  columns: TableHeaderConfig[]
  groups: Map<number, Map<number, UserDocument[]>>
  RowComponent: FC<RowComponentProps>
  year: number
  onCheck?: (id: number, checked: boolean) => void
}) => {
  const yearGroup = groups.get(year)
  const [open, setOpen] = useState(true)

  if (!yearGroup) {
    return null
  }

  return (
    <>
      <UserDocumentsTableHeader
        type="Primary"
        columns={[
          { title: year.toString(), sortBy: null },
          ...columns
            .slice(1)
            .map((col) => ({ ...EMPTY_COLUMN, width: col.width })),
        ]}
        onClick={() => setOpen((open) => !open)}
        isOpen={open}
      />
      {open &&
        renderMonthRow({ groups: yearGroup, RowComponent, columns, onCheck })}
    </>
  )
}

const NoGrouping = ({
  documents,
  columns,
  RowComponent,
  onCheck,
}: {
  documents: UserDocument[]
  columns: Array<{ sortBy: string | null; title: string }>
  RowComponent: FC<RowComponentProps>
  onCheck?: (id: number, checked: boolean) => void
}) => {
  const { sort, sortedDocs, sortVal, sortAsc } = useDocSort(documents)

  return (
    <>
      {documents.length > 0 && (
        <UserDocumentsTableHeader
          type="Tertiary"
          columns={columns}
          onSort={(value) => sort(value)}
          sortAsc={sortAsc}
          sortVal={sortVal}
        />
      )}
      <Table.Body>
        {renderRowsForGroup({
          group: sortedDocs,
          RowComponent,
          onCheck,
        })}
      </Table.Body>
    </>
  )
}

const UserDocumentsTable = ({
  columns,
  documents,
  RowComponent,
  tableOptions,
  onCheck,
  fixed = true,
}: UserDocumentsTableProps) => {
  const sortedDocuments = useMemo(
    () => documents.sort(tableOptions.sortFn),
    [documents, tableOptions.sortFn]
  )
  const documentCategories = useReselector(getUserDocumentCategoriesById)
  const taxUserDocuments = useReselector(selectTaxUserDocuments)
  const tableContent = useMemo(() => {
    switch (tableOptions.groupBy) {
      case GroupByType.StatementYear:
      case GroupByType.CategoryYear:
      case GroupByType.CreatedAtYear: {
        const groups = groupDocumentsByYear(
          sortedDocuments,
          tableOptions.groupBy,
          documentCategories,
          taxUserDocuments
        )

        return [...groups.keys()]
          .sort((a, b) => b - a)
          .map((year) => (
            <GroupedByYearTable
              key={`document-table-group-${year}`}
              groups={groups}
              year={year}
              columns={columns}
              RowComponent={RowComponent}
              onCheck={onCheck}
            />
          ))
      }
      case GroupByType.MonthWithinYear: {
        const groups = groupDocumentsByMonthWithinYear(sortedDocuments)

        return [...groups.keys()]
          .sort((a, b) => b - a)
          .map((year) => (
            <GroupMonthWithinYear
              key={`document-table-group-${year}`}
              year={year}
              RowComponent={RowComponent}
              columns={columns}
              groups={groups}
              onCheck={onCheck}
            />
          ))
      }
      case GroupByType.None:
        return (
          <NoGrouping
            documents={sortedDocuments}
            columns={columns}
            RowComponent={RowComponent}
            onCheck={onCheck}
          />
        )
      default:
        return tableOptions.groupBy satisfies never
    }
  }, [
    RowComponent,
    columns,
    documentCategories,
    onCheck,
    sortedDocuments,
    tableOptions.groupBy,
    taxUserDocuments,
  ])

  if (sortedDocuments.length === 0) {
    return (
      <div className="centered">
        <br />
        <br />
        <Text as="h3">No Documents Yet!</Text>
      </div>
    )
  }
  return (
    <Table
      fixed={fixed}
      id="user-documents-table"
      basic="very"
      singleLine={false}
      striped
      compact
    >
      {tableContent}
    </Table>
  )
}

export default UserDocumentsTable
