import React, { useEffect } from 'react'
import { Menu, Sticky } from 'semantic-ui-react'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { History } from '../../Histories/histories.slice'
import { selectAllQuarterlyTaxEstimateDetailsSorted } from '../../QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { fetchAllQuarterlyTaxEstimateDetailsIfNeeded } from '../../QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import { datasourceForTab } from '../service'
import {
  UserTaxCalculation,
  UserTaxCalculationTabNames,
} from '../userTaxCalculation.slice'
import { useReselector } from '../../../../utils/sharedHooks'

interface Props {
  activeTab: UserTaxCalculationTabNames
  calculations: UserTaxCalculation[]
  contextRef: React.RefObject<HTMLElement>
  onTabChange: (_tabName: UserTaxCalculationTabNames) => void
  financialProfileHistoriesThisYear: History[]
}

const CalculationTabMenu: React.FC<Props> = ({
  calculations,
  contextRef,
  onTabChange,
  activeTab,
  financialProfileHistoriesThisYear,
}) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchAllQuarterlyTaxEstimateDetailsIfNeeded())
  }, [dispatch])

  const allQuarterlyTaxEstimateDetails = useReselector(
    selectAllQuarterlyTaxEstimateDetailsSorted
  )

  const singleState = datasourceForTab({
    calculations,
    tabName: UserTaxCalculationTabNames.singleState,
    allQuarterlyTaxEstimateDetails,
    financialProfileHistoriesThisYear,
  })
  const multiState = datasourceForTab({
    calculations,
    tabName: UserTaxCalculationTabNames.multiState,
    allQuarterlyTaxEstimateDetails,
    financialProfileHistoriesThisYear,
  })
  const edgeCases = datasourceForTab({
    calculations,
    tabName: UserTaxCalculationTabNames.edgeCases,
    allQuarterlyTaxEstimateDetails,
    financialProfileHistoriesThisYear,
  })

  return (
    <Sticky offset={0} context={contextRef}>
      <Menu id="qteCalculationTabMenu" pointing secondary>
        <Menu.Item
          name={`${UserTaxCalculationTabNames.all} (${calculations.length})`}
          active={activeTab === UserTaxCalculationTabNames.all}
          onClick={() => onTabChange(UserTaxCalculationTabNames.all)}
        />
        <Menu.Item
          name={`${UserTaxCalculationTabNames.singleState} (${singleState.length})`}
          active={activeTab === UserTaxCalculationTabNames.singleState}
          onClick={() => onTabChange(UserTaxCalculationTabNames.singleState)}
        />
        <Menu.Item
          name={`${UserTaxCalculationTabNames.multiState} (${multiState.length})`}
          active={activeTab === UserTaxCalculationTabNames.multiState}
          onClick={() => onTabChange(UserTaxCalculationTabNames.multiState)}
        />
        <Menu.Item
          name={`${UserTaxCalculationTabNames.edgeCases} (${edgeCases.length})`}
          active={activeTab === UserTaxCalculationTabNames.edgeCases}
          onClick={() => onTabChange(UserTaxCalculationTabNames.edgeCases)}
        />
      </Menu>
    </Sticky>
  )
}

export default CalculationTabMenu
