import { useNavigate } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import moment from 'moment'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Button,
  Card,
  GridRowColumn,
  Text,
  Icon,
} from '../../../components/BaseComponents'
import { selectPaySchedule, selectUpcomingPayroll } from '../payroll.selectors'
import PayrollCalendar from './PayrollCalendar'
import { Colors } from '../../../styles/theme'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useReselector } from '../../../utils/sharedHooks'

const formatDate = (date: string | undefined) => {
  return date ? moment(date).format(DATE_FORMATS.DISPLAY_SHORT) : ''
}

const UpcomingPayroll = () => {
  const navigate = useNavigate()

  const upcomingPayroll = useReselector(selectUpcomingPayroll)
  const paySchedule = useReselector(selectPaySchedule)

  if (!upcomingPayroll) {
    return null
  }

  const {
    payroll_uuid,
    processed,
    pay_period: { start_date, end_date },
    payroll_deadline,
  } = upcomingPayroll

  const isAutoPilot = Boolean(paySchedule?.auto_pilot)

  return (
    <GridRowColumn>
      <Card style={{ marginTop: 10 }} backgroundColor="stone">
        <Grid>
          <Grid.Row>
            <Grid.Column width={6}>
              <Grid>
                {processed ? (
                  <>
                    {start_date && end_date && (
                      <GridRowColumn>
                        <Text as="h2">
                          You&#39;ve run payroll for Pay Period:
                          <br />
                          {formatDate(start_date)} - {formatDate(end_date)}
                        </Text>
                      </GridRowColumn>
                    )}
                    <GridRowColumn>
                      <Text>
                        Great work, you are all set! An automatic email will
                        notify you when it is time to run another regular
                        payroll.
                      </Text>
                    </GridRowColumn>
                  </>
                ) : (
                  <>
                    <GridRowColumn>
                      <Text as="h2">Regular Payroll</Text>
                    </GridRowColumn>
                    {start_date && end_date && (
                      <GridRowColumn short>
                        <Text>
                          {formatDate(start_date)} - {formatDate(end_date)}
                        </Text>
                      </GridRowColumn>
                    )}
                    {isAutoPilot ? (
                      <GridRowColumn>
                        <Text>
                          Because you have set up Automatic Payroll, no further
                          action is needed.
                          <br />
                          Your next payroll will automatically run on{' '}
                          <b>{formatDate(payroll_deadline)}</b>
                        </Text>
                      </GridRowColumn>
                    ) : (
                      <GridRowColumn>
                        <Text>
                          Ready to compensate your employees for their hard
                          work? Run a payroll cycle by clicking the button
                          below.
                        </Text>
                      </GridRowColumn>
                    )}
                    <GridRowColumn width={10} spacer={3}>
                      <Button
                        onClick={() =>
                          navigate(
                            `/payroll/run_payroll/process/${payroll_uuid}`
                          )
                        }
                        fullWidth
                      >
                        <Icon
                          icon={light('circle-arrow-right')}
                          style={{ marginRight: 10 }}
                        />
                        Run Payroll
                      </Button>
                    </GridRowColumn>
                    {isAutoPilot && (
                      <GridRowColumn>
                        <Text style={{ paddingTop: 5 }}>
                          Please click the above button if you would like to run
                          payroll manually or run it before the automated date.
                        </Text>
                      </GridRowColumn>
                    )}
                  </>
                )}
              </Grid>
            </Grid.Column>
            <Grid.Column width={10} style={{ backgroundColor: Colors.white }}>
              <PayrollCalendar payroll={upcomingPayroll} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </GridRowColumn>
  )
}

export default UpcomingPayroll
