import { Divider, Grid } from 'semantic-ui-react'
import { Button } from '../../../../../components/BaseComponents'

const CalculateSafeHarborFooter = ({
  goToPreviousStep,
  submitOrContinue,
  continueDisabled,
  loading,
  nextText = 'Continue',
}: {
  goToPreviousStep: () => void
  submitOrContinue: () => void
  continueDisabled?: boolean
  loading?: boolean
  nextText?: string
}) => (
  <>
    <Grid.Row />
    <Divider />
    <Grid.Row />
    <Grid.Row>
      <Grid.Column width={8}>
        <Button onClick={() => goToPreviousStep()} variant="secondary">
          Back
        </Button>
      </Grid.Column>
      <Grid.Column
        width={8}
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Button
          onClick={submitOrContinue}
          disabled={continueDisabled}
          loading={loading}
        >
          {nextText}
        </Button>
      </Grid.Column>
    </Grid.Row>
  </>
)

export default CalculateSafeHarborFooter
