import { ReactNode } from 'react'
import { Divider, List, Table } from 'semantic-ui-react'

import CurrencyFormatLabel from '../../../../components/shared/CurrencyFormatLabel'
import AnnualizedAmountsPopup from './AnnualizedAmountsPopup'
import CalculationListItem from './CalculationListItem'
import {
  addCurrencyArray,
  centsToCurrency,
  centsToDollars,
} from '../../../../utils/currencyHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectUsesYTDW2Input,
  selectIsSingleStateCalculation,
  selectUserTaxCalculationById,
} from '../../../../selectors/userTaxCalculation.selectors'
import QtePaymentsPopup from './QtePaymentsPopup'
import { Text } from '../../../../components/BaseComponents'

const ListItem = ({
  title,
  amountComponent,
  amountInCents,
}: {
  title: ReactNode
  amountComponent?: ReactNode
  amountInCents?: number | null
}) => (
  <List.Item>
    <List.Content verticalAlign="middle" floated="right">
      {amountComponent}
      {amountInCents === null || amountInCents === undefined ? null : (
        <CurrencyFormatLabel value={centsToDollars(amountInCents)} />
      )}
    </List.Content>
    <List.Header verticalAlign="middle">{title}</List.Header>
  </List.Item>
)

const CalculationInputsCell = ({
  id,
  bookkeepingPeriod,
}: {
  id: number
  bookkeepingPeriod: string
}) => {
  const calc = useReselector(selectUserTaxCalculationById, id)
  const isSingleStateCalc = useReselector(selectIsSingleStateCalculation, id)
  const isAfter2023Q1 = useReselector(selectUsesYTDW2Input, id)

  if (!calc) {
    return null
  }

  const {
    annualizedIncomeInCents,
    periodIncomeInCents,
    otherIndividualIncomeInCents,
    spouseIndividualIncomeInCents,
    annualizedExpensesInCents,
    periodExpensesInCents,
    wIncomeInCents,
    spouseWIncomeInCents,
    scorpPracticeWIncomeInCents,
    annualizedTotalWIncomeInCents,
    annualizedSpouseWIncomeInCents,
    annualizedScorpPracticeWIncomeInCents,
    annualizedWIncomeInCents,
    filingStates,
    filingStatus,
    taxEntityType,
    stateWithholdingInCents,
    spouseStateWithholdingInCents,
    federalWithholdingInCents,
    spouseFederalWithholdingInCents,
    estimatedAnnualW2IncomeInCents,
    estimatedAnnualSpouseW2IncomeInCents,
    estimatedAnnualScorpPracticeW2IncomeInCents,
  } = calc

  const totalOther1099IncomeInCents = centsToCurrency(
    otherIndividualIncomeInCents || 0
  ).add(spouseIndividualIncomeInCents || 0).value

  const selfFedWithholding = centsToCurrency(federalWithholdingInCents)
  const spouseFedWithholding = centsToCurrency(spouseFederalWithholdingInCents)
  const selfStateWithholding = centsToCurrency(stateWithholdingInCents)
  const spouseStateWithholding = centsToCurrency(spouseStateWithholdingInCents)

  const totalFederalWithholding =
    spouseFedWithholding.add(selfFedWithholding).value
  const totalStateWithholding =
    spouseStateWithholding.add(selfStateWithholding).value

  const totalYtdW2Income = addCurrencyArray([
    wIncomeInCents === null ? 0 : centsToDollars(annualizedWIncomeInCents),
    spouseWIncomeInCents === null
      ? 0
      : centsToDollars(annualizedSpouseWIncomeInCents),
    scorpPracticeWIncomeInCents === null
      ? 0
      : centsToDollars(annualizedScorpPracticeWIncomeInCents),
  ])

  const totalEstimatedIncome = addCurrencyArray([
    estimatedAnnualW2IncomeInCents === null
      ? 0
      : centsToDollars(annualizedWIncomeInCents),
    estimatedAnnualSpouseW2IncomeInCents === null
      ? 0
      : centsToDollars(annualizedSpouseWIncomeInCents),
    estimatedAnnualScorpPracticeW2IncomeInCents === null
      ? 0
      : centsToDollars(annualizedScorpPracticeWIncomeInCents),
  ])

  return (
    <Table.Cell verticalAlign="top">
      <List>
        {isSingleStateCalc ? (
          <>
            <CalculationListItem
              header="Tax Entity Type"
              value={taxEntityType ?? 'N/A'}
            />
            <CalculationListItem header="Filing Status" value={filingStatus} />
            <CalculationListItem
              header="Filing State(s)"
              value={filingStates.join(', ')}
            />

            <Divider inverted />

            <CalculationListItem
              header="1099 Practice Income"
              value={
                <CurrencyFormatLabel
                  value={centsToDollars(annualizedIncomeInCents)}
                />
              }
              tooltip={
                <AnnualizedAmountsPopup
                  periodTitle="1099 Income"
                  periodAmount={
                    <CurrencyFormatLabel
                      value={centsToDollars(periodIncomeInCents)}
                    />
                  }
                  periodDateString={bookkeepingPeriod}
                  annualizedAmount={
                    <CurrencyFormatLabel
                      value={centsToDollars(annualizedIncomeInCents)}
                    />
                  }
                />
              }
            />

            <CalculationListItem
              header="Expense Deductions"
              value={
                <CurrencyFormatLabel
                  value={centsToDollars(annualizedExpensesInCents)}
                />
              }
              tooltip={
                <AnnualizedAmountsPopup
                  periodTitle="Expense Deductions"
                  periodAmount={
                    <CurrencyFormatLabel
                      value={centsToDollars(periodExpensesInCents)}
                    />
                  }
                  periodDateString={bookkeepingPeriod}
                  annualizedAmount={
                    <CurrencyFormatLabel
                      value={centsToDollars(annualizedExpensesInCents)}
                    />
                  }
                />
              }
            />
            <CalculationListItem
              header={
                isAfter2023Q1 ? 'W2 YTD Income' : 'W2 Income (Expected Annual)'
              }
              value={<CurrencyFormatLabel value={totalYtdW2Income} />}
              tooltip={
                <List>
                  {wIncomeInCents !== null && (
                    <ListItem
                      title="W2 YTD Income"
                      amountInCents={wIncomeInCents}
                    />
                  )}
                  {spouseWIncomeInCents !== null && (
                    <ListItem
                      title="Spouse's W2 YTD Income"
                      amountInCents={spouseWIncomeInCents}
                    />
                  )}
                  {scorpPracticeWIncomeInCents !== null && (
                    <ListItem
                      title="S-corp Practice YTD Income"
                      amountInCents={scorpPracticeWIncomeInCents}
                    />
                  )}

                  <Divider />
                  <ListItem
                    title={<b>Total Annualized YTD Income</b>}
                    amountComponent={
                      <CurrencyFormatLabel value={totalYtdW2Income} />
                    }
                  />
                </List>
              }
            />

            <CalculationListItem
              header="W2 Estimated Income"
              value={<CurrencyFormatLabel value={totalEstimatedIncome} />}
              tooltip={
                <List>
                  {estimatedAnnualW2IncomeInCents !== null && (
                    <ListItem
                      title="W2 Estimated Income"
                      amountInCents={estimatedAnnualW2IncomeInCents}
                    />
                  )}
                  {estimatedAnnualSpouseW2IncomeInCents !== null && (
                    <ListItem
                      title="Spouse's W2 Estimated Income"
                      amountInCents={estimatedAnnualSpouseW2IncomeInCents}
                    />
                  )}
                  {estimatedAnnualScorpPracticeW2IncomeInCents !== null && (
                    <ListItem
                      title="S-corp Practice Estimated Income"
                      amountInCents={
                        estimatedAnnualScorpPracticeW2IncomeInCents
                      }
                    />
                  )}

                  <Divider />
                  <ListItem
                    title={<b>Total Annualized Estimated Income</b>}
                    amountComponent={
                      <CurrencyFormatLabel value={totalEstimatedIncome} />
                    }
                  />
                </List>
              }
            />
            {isAfter2023Q1 && (
              <CalculationListItem
                header="W2 Annualized Income"
                value={
                  <CurrencyFormatLabel
                    value={centsToDollars(annualizedTotalWIncomeInCents)}
                  />
                }
              />
            )}
            <CalculationListItem
              header="YTD Other 1099 Income"
              value={
                <CurrencyFormatLabel value={totalOther1099IncomeInCents} />
              }
              tooltip={
                <List>
                  <ListItem
                    title="Other 1099 Income"
                    amountInCents={otherIndividualIncomeInCents}
                  />
                  <ListItem
                    title="Spouse's Other 1099 Income"
                    amountInCents={spouseIndividualIncomeInCents}
                  />
                  <Divider />
                  <ListItem
                    title={<b>Total Other 1099 Income</b>}
                    amountInCents={totalOther1099IncomeInCents}
                  />
                </List>
              }
            />
            <CalculationListItem
              header="YTD total federal withholding"
              value={<CurrencyFormatLabel value={totalFederalWithholding} />}
              tooltip={
                <>
                  <Text>
                    Individual Federal withholding{' '}
                    <CurrencyFormatLabel value={selfFedWithholding.value} />
                  </Text>
                  <Text>
                    Spouse Federal withholding{' '}
                    <CurrencyFormatLabel value={spouseFedWithholding.value} />
                  </Text>
                </>
              }
            />
            <CalculationListItem
              header="YTD total state withholding"
              value={<CurrencyFormatLabel value={totalStateWithholding} />}
              tooltip={
                <>
                  <Text>
                    Individual State withholding{' '}
                    <CurrencyFormatLabel value={selfStateWithholding.value} />
                  </Text>
                  <Text>
                    Spouse State withholding{' '}
                    <CurrencyFormatLabel value={spouseStateWithholding.value} />
                  </Text>
                </>
              }
            />
            <CalculationListItem
              header="QTE Payments"
              tooltip={<QtePaymentsPopup id={id} />}
            />
          </>
        ) : (
          <i>N/A (Multi-state filer)</i>
        )}
      </List>
    </Table.Cell>
  )
}

export default CalculationInputsCell
