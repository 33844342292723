import { Modal, Text } from '../../../../components/BaseComponents'

interface ClarifyingModalProps {
  open: boolean
  onClose: () => void
  title: string
  content: React.ReactNode
  actions: React.ReactNode
  closeIcon?: boolean
}

const ClarifyingModal = ({
  open,
  onClose,
  title,
  content,
  actions,
  closeIcon = true,
}: ClarifyingModalProps) => {
  return (
    <Modal open={open} onClose={onClose} closeIcon={closeIcon} size="small">
      <Modal.Header>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text as="display3">{title}</Text>
        </div>
      </Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions>{actions}</Modal.Actions>
    </Modal>
  )
}

export default ClarifyingModal
