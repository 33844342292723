import { useMemo } from 'react'
import { Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DateTime, Interval } from 'luxon'
import {
  Text,
  Card,
  Table,
  Label,
  IconButton,
  Button,
  Accordion,
} from '../../../components/BaseComponents'
import {
  JournalEntry,
  JournalEntryLineItem,
  JournalEntryTransaction,
  JournalEntryType,
} from '../types'
import { getUserFinancialAccounts } from '../../../selectors/financeSelectors'
import { filterObjNils } from '../../../utils/typeHelpers'
import { getAccountName } from '../service'
import { useReselector, useToggle } from '../../../utils/sharedHooks'
import { getAllTransactionCategories } from '../../Reports/reports.selectors'
import { centsToDollars } from '../../../utils/currencyHelpers'
import CurrencyFormatLabel from '../../../components/shared/CurrencyFormatLabel'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../utils/dateHelpers'
import { EditJournalEntryModal } from './EditJournalEntryModal'
import { DeleteJournalEntryModal } from './DeleteJournalEntryModal'

const JournalEntryLine = ({
  lineItem,
  userId,
}: {
  lineItem: JournalEntryLineItem
  userId: number
}) => {
  const {
    financialAccountId,
    transactionCategoryId,
    entryType,
    amountInCents,
  } = lineItem

  const financialAccountsObj = useReselector(
    getUserFinancialAccounts,
    userId ?? 0
  )
  const financialAccountsById = useMemo(
    () => filterObjNils(financialAccountsObj),
    [financialAccountsObj]
  )
  const transactionCategoriesById = useReselector(getAllTransactionCategories)

  const accountName = useMemo(() => {
    return getAccountName({
      financialAccountsById,
      financialAccountId: `${financialAccountId ?? ''}`,
      transactionCategoriesById,
      transactionCategoryId: `${transactionCategoryId ?? ''}`,
    })
  }, [
    financialAccountId,
    transactionCategoryId,
    transactionCategoriesById,
    financialAccountsById,
  ])

  return (
    <Table.Row>
      <Table.Cell>
        <Text>{accountName}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text>
          {entryType === JournalEntryType.DEBIT ? (
            <CurrencyFormatLabel value={centsToDollars(amountInCents)} />
          ) : (
            ''
          )}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text>
          {entryType === JournalEntryType.CREDIT ? (
            <CurrencyFormatLabel value={centsToDollars(amountInCents)} />
          ) : (
            ''
          )}
        </Text>
      </Table.Cell>
    </Table.Row>
  )
}

export const JournalEntryLinesTable = ({
  journalEntryLines,
  userId,
}: {
  journalEntryLines: JournalEntryLineItem[]
  userId: number
}) => {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={8}>
            <Text color="darkGray" as="eyebrow">
              Account
            </Text>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Text color="darkGray" as="eyebrow">
              Debit
            </Text>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Text color="darkGray" as="eyebrow">
              Credit
            </Text>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {journalEntryLines.map((lineItem) => (
          <JournalEntryLine
            lineItem={lineItem}
            key={lineItem.id}
            userId={userId}
          />
        ))}
      </Table.Body>
    </Table>
  )
}

export const JournalEntryCard = ({
  journalEntry,
  userId,
  deleting = false,
}: {
  journalEntry: JournalEntry
  userId: number
  deleting?: boolean
}) => {
  const [isEditing, toggleEdit] = useToggle()
  const [isDeleting, toggleDelete] = useToggle()

  const { journalEntryDate, details, journalEntryLines, automated } =
    journalEntry

  return (
    <Card type="subsection" backgroundColor={deleting ? 'stone' : 'stone40'}>
      <Grid>
        <Grid.Row verticalAlign="top">
          <Grid.Column width={11}>
            <Text as="h3" style={{ marginBottom: 8 }}>
              {isoToUTCDateTime(journalEntryDate).toFormat(
                DATE_FORMATS_LUXON.INPUT
              )}
            </Text>
            <Text color="darkGray">
              <i>{details}</i>
            </Text>
          </Grid.Column>
          {!deleting && (
            <Grid.Column width={5} floated="right">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 16,
                }}
              >
                {automated && <Label icon={regular('bolt')}>Auto</Label>}
                {!automated && (
                  <Label icon={regular('hand')} color="dark">
                    Manual
                  </Label>
                )}
                <IconButton
                  icon={regular('edit')}
                  color="darkGray"
                  onClick={toggleEdit}
                />
                {!automated && (
                  <IconButton
                    icon={regular('trash-alt')}
                    onClick={toggleDelete}
                    color="darkGray"
                  />
                )}
              </div>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      <JournalEntryLinesTable
        journalEntryLines={journalEntryLines}
        userId={userId}
      />
      {isEditing && (
        <EditJournalEntryModal
          open={isEditing}
          onClose={toggleEdit}
          journalEntry={journalEntry}
        />
      )}
      {isDeleting && (
        <DeleteJournalEntryModal
          open={isDeleting}
          onClose={toggleDelete}
          journalEntry={journalEntry}
          userId={userId}
        />
      )}
    </Card>
  )
}

export const JournalEntryTransactionRow = ({
  transaction,
  userId,
  dateRange,
}: {
  transaction: JournalEntryTransaction
  userId: number
  dateRange: [string, string]
}) => {
  const [isCreating, toggleCreate] = useToggle()

  const { journalEntriesWithinRange, journalEntriesOutsideRange } =
    useMemo(() => {
      if (!dateRange?.[0] || !dateRange?.[1])
        return {
          journalEntriesWithinRange: transaction.journalEntries,
          journalEntriesOutsideRange: [],
        }

      const startDate = DateTime.fromFormat(
        dateRange[0],
        DATE_FORMATS_LUXON.INPUT,
        { zone: 'utc' }
      ).startOf('day')
      const endDate = DateTime.fromFormat(
        dateRange[1],
        DATE_FORMATS_LUXON.INPUT,
        { zone: 'utc' }
      ).endOf('day')
      const rangeInterval = Interval.fromDateTimes(startDate, endDate)

      const entriesWithinRange = transaction.journalEntries.filter(
        (journalEntry) =>
          rangeInterval.contains(
            isoToUTCDateTime(journalEntry.journalEntryDate)
          )
      )
      const entriesOutsideRange = transaction.journalEntries.filter(
        (journalEntry) =>
          !rangeInterval.contains(
            isoToUTCDateTime(journalEntry.journalEntryDate)
          )
      )

      return {
        journalEntriesWithinRange: entriesWithinRange,
        journalEntriesOutsideRange: entriesOutsideRange,
      }
    }, [transaction.journalEntries, dateRange])

  return (
    <Grid.Row>
      <Grid.Column width={6} gap={8}>
        {transaction.type === 'without_transaction' ? (
          <>
            <Text as="h3" style={{ marginBottom: 8 }}>
              N/A
            </Text>
            <Text>
              These journal entries aren&apos;t linked to a transaction
            </Text>
          </>
        ) : (
          <>
            <Text as="h3" style={{ marginBottom: 8 }}>
              {transaction?.description}
            </Text>
            <Text style={{ marginBottom: 8 }}> ID: {transaction?.id} </Text>
            <Button
              variant="secondaryWhite"
              onClick={toggleCreate}
              size="small"
              style={{ marginTop: 12 }}
            >
              Create Journal Entry
            </Button>
            {isCreating && (
              <EditJournalEntryModal
                open={isCreating}
                onClose={toggleCreate}
                transactionId={transaction?.id?.toString()}
              />
            )}
          </>
        )}
      </Grid.Column>
      <Grid.Column width={10}>
        {journalEntriesWithinRange.map((journalEntry) => (
          <JournalEntryCard
            journalEntry={journalEntry}
            key={journalEntry.id}
            userId={userId}
          />
        ))}
        {journalEntriesOutsideRange.length > 0 && (
          <Accordion
            title={`${journalEntriesOutsideRange.length} journal entr${
              journalEntriesOutsideRange.length === 1 ? 'y' : 'ies'
            } outside of date filter`}
            content={
              <>
                {journalEntriesOutsideRange.map((journalEntry) => (
                  <JournalEntryCard
                    journalEntry={journalEntry}
                    key={journalEntry.id}
                    userId={userId}
                  />
                ))}
              </>
            }
            variant="text"
          />
        )}
      </Grid.Column>
    </Grid.Row>
  )
}
