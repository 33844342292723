import { useEffect, useState } from 'react'
import { Image } from 'semantic-ui-react'

import { Text } from '../BaseComponents'

// Put this in app to see crash
export const BuggyComponent = () => {
  const [isCrash, setIsCrash] = useState(false)

  useEffect(() => {
    setTimeout(() => setIsCrash(true), 3000)
  }, [])

  if (isCrash) {
    // Simulate a JS error
    throw new Error('I crashed!')
  }

  return <Text>Ok</Text>
}

const ErrorBoundaryScreen = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: window.innerHeight,
    }}
  >
    <Image
      src="https://heard-images.s3.amazonaws.com/assets/looking-at-tablet.svg"
      style={{ width: 200, height: 213, marginBottom: 20 }}
    />
    <Text as="h1" style={{ marginBottom: 5 }}>
      Something went wrong on our end.
    </Text>
    <Text as="bodyLg">
      Our team’s fixing it! Refresh this page in a few minutes.
    </Text>
  </div>
)

export default ErrorBoundaryScreen
