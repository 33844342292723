import Lottie from 'lottie-react'

import './runPayroll.scss'

import gustoLoading from '../../../assets/lottie/gustoLoading.json'
import { Modal, Text } from '../../../components/BaseComponents'

const PayrollLoading = ({
  text,
  subtext,
}: {
  text: string
  subtext?: string
}) => (
  <Modal open className="payroll-loading-modal">
    <div className="content-wrapper">
      <Lottie animationData={gustoLoading} loop />
      <div className="text-wrapper">
        <Text as="h1" color="white" textAlign="center">
          {text}
        </Text>
        <br />
        <Text as="h3" color="white" textAlign="center">
          {subtext}
        </Text>
      </div>
    </div>
  </Modal>
)

export default PayrollLoading
