import { createContext, useState, ReactNode, useCallback } from 'react'
import { fetchFinancialAccountsIfNeeded } from '../../../actions/financialAccountActions'
import { fetchPlaidItemsIfNeeded } from '../../../actions/plaidItemActions'
import { PostPlaidAuthModal } from './PostPlaidAuthModal'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface PlaidError {
  message: string
  errorCode: string | number
}

export enum PlaidPostAuthModalType {
  UPGRADE_FROM_MANUAL_STATEMENTS = 'UPGRADE_FROM_MANUAL_STATEMENTS',
  UPGRADE_FROM_LBA = 'UPGRADE_FROM_LBA',
}

export interface PostAuthModal {
  type: PlaidPostAuthModalType
  institutionId: string
  institutionName: string
}
interface PlaidLinkContextType {
  duplicateInstitution: string
  setDuplicateInstitution: (duplicateInstitution: string) => void
  plaidError: PlaidError | null
  setPlaidError: (plaidError: PlaidError | null) => void
  clearPlaidError: () => void
  postAuthModal: PostAuthModal | null
  setPostAuthModal: (postAuthModal: PostAuthModal | null) => void
}

export const PlaidLinkContext = createContext<PlaidLinkContextType>({
  duplicateInstitution: '',
  setDuplicateInstitution: (_duplicateInstitution) => {},
  plaidError: null,
  setPlaidError: (_plaidError) => {},
  clearPlaidError: () => {},
  postAuthModal: null,
  setPostAuthModal: () => {},
})

export const PlaidLinkContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [duplicateInstitution, setDuplicateInstitution] = useState('')
  const [plaidError, setPlaidError] = useState<PlaidError | null>(null)
  const [postAuthModal, setPostAuthModal] = useState<PostAuthModal | null>(null)
  const dispatch = useAppDispatch()

  const clearPlaidError = useCallback(async () => {
    await Promise.all([
      dispatch(fetchFinancialAccountsIfNeeded(true)),
      dispatch(fetchPlaidItemsIfNeeded(true)),
    ])
    setPlaidError(null)
  }, [dispatch])

  return (
    <PlaidLinkContext.Provider
      value={{
        duplicateInstitution,
        setDuplicateInstitution,
        plaidError,
        setPlaidError,
        clearPlaidError,
        postAuthModal,
        setPostAuthModal,
      }}
    >
      {children}
      <PostPlaidAuthModal />
    </PlaidLinkContext.Provider>
  )
}
