import { useContext, useEffect, useState } from 'react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Checkbox, Grid } from 'semantic-ui-react'
import {
  Icon,
  Text,
  GridRowColumn,
  Alert,
} from '../../../components/BaseComponents'
import { PlaidLinkContext } from '../../../components/Finances/Accounts/PlaidLinkContext'
import { InstitutionList } from '../../../components/Finances/Accounts/InstitutionList'
import PlaidLink from '../../../components/shared/plaid/PlaidLink'
import { selectPlaidItems } from '../../../selectors/financeSelectors'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { ButtonVariant } from '../../../components/BaseComponents/Button'
import { fetchPlaidItemsIfNeeded } from '../../../actions/plaidItemActions'
import { useAnalyticsTrack, useAnalyticsView } from '../../Amplitude'
import { isNil } from 'lodash'
import { useReselector } from '../../../utils/sharedHooks'

interface IConnectBusinessAccountsProps {
  cantFindBank: boolean
  setCantFindBank: (cantFindBank: boolean) => void
  showSkipButton: boolean
  setShowSkipButton: (showSkipButton: boolean) => void
}

export const ConnectBusinessAccounts = ({
  cantFindBank,
  setCantFindBank,
  showSkipButton,
  setShowSkipButton,
}: IConnectBusinessAccountsProps) => {
  const dispatch = useAppDispatch()
  const { plaidError } = useContext(PlaidLinkContext)
  const plaidInstitutions = useReselector(selectPlaidItems)
  const [connectButtonVariant, setConnectButtonVariant] = useState<
    ButtonVariant | undefined
  >('primary')

  const pageView = useAnalyticsView()
  const track = useAnalyticsTrack()

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchPlaidItemsIfNeeded())
    }
    pageView('onboarding items connect financial accounts')

    fetch()
  }, [dispatch, plaidInstitutions, pageView])

  useEffect(() => {
    if (Object.keys(plaidInstitutions).length > 0) {
      setConnectButtonVariant('secondary')
    } else {
      setConnectButtonVariant('primary')
    }
  }, [plaidInstitutions])

  useEffect(() => {
    if (!isNil(plaidError)) {
      pageView('viewed plaid error')
    }
  }, [plaidError, pageView])

  const handleCantFindBank = () => {
    setCantFindBank(!cantFindBank)
    setShowSkipButton(!showSkipButton)

    if (cantFindBank) {
      track('clicked cant find bank')
    }

    if (showSkipButton) {
      track('skipped connect business account')
    }
  }

  return (
    <Grid>
      <Grid.Column width={10}>
        <Text as="h2">Connect business accounts</Text>
        <br />
        <Text as="bodySm">
          Add any of the following used for your business:
        </Text>
        <ul style={{ paddingLeft: '18px' }}>
          <li>Business checking or savings accounts</li>
          <li>Business credit cards</li>
          <li>
            <b>Do not connect personal accounts-</b> even if they have business
            transactions
          </li>
        </ul>

        <InstitutionList isOnboarding />

        <Grid>
          <GridRowColumn
            columnStyle={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'nowrap',
              marginTop: 12,
            }}
          >
            <PlaidLink
              buttonVariant={connectButtonVariant}
              redirect={false}
              connectButtonText={
                <>
                  <Icon
                    icon={regular('plus')}
                    size="sm"
                    style={{ marginRight: 8 }}
                  />
                  Connect Account
                </>
              }
            />
          </GridRowColumn>
        </Grid>

        <Checkbox
          style={{ margin: '18px 0' }}
          checked={cantFindBank}
          onChange={() => handleCantFindBank()}
          label="I can't find my bank or institution in Plaid"
          value="cantFindBank"
        />

        {cantFindBank && (
          <Alert type="info">
            <Text as="bodySm">
              <b>
                Plaid may not be compatible with some smaller banks and credit
                unions.
              </b>
            </Text>
            <Text as="bodySm">
              If you have any other business accounts, please connect them now.
              We’ll follow up with further instructions later.
            </Text>
          </Alert>
        )}

        {plaidError && (
          <Alert type="error">
            <Text as="bodySm">
              Your account(s) could not be connected due to a Plaid error.
              Please try again in a few minutes. If you keep experiencing
              issues, feel free to skip this step for now.
            </Text>
          </Alert>
        )}
      </Grid.Column>
    </Grid>
  )
}
