import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { fetchAllUsersIfNeeded } from '../../../actions/admin/adminAllUsersActions'
import { Dispatch, ReduxState } from '../../../utils/typeHelpers'
import {
  filterForActiveCustomers,
  replacePlaceholdersInString,
} from './service'
import {
  NewZendeskBulkMessage,
  createZendeskBulkMessage,
  fetchAllZendeskBulkMessagesIfNeeded,
} from './zendeskBulkMessage.slice'

// UI
import {
  Accordion,
  Button,
  Confirm,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Label,
  List,
  Message,
  Modal,
} from 'semantic-ui-react'
import './index.scss'
import RecipientsRow from './components/RecipientsRow'
import ReplyToRow from './components/ReplyToRow'
import SubjectRow from './components/SubjectRow'
import MessageRow from './components/MessageRow'
import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import { parseErrorFromCatch } from '../../../utils/errorHelpers'
import { useReselector } from '../../../utils/sharedHooks'
import { getCurrentUser } from '../../../selectors/user.selectors'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps

const panels = [
  {
    key: '0',
    title: 'When send is clicked',
    content: {
      content: (
        <ul>
          <li>
            A <b>new Zendesk ticket</b> will be created for <b>each Customer</b>
          </li>
          <li>
            Tickets will be <b>assigned to the Customer&lsquo;s AM</b> on Heard
          </li>
          <li>
            Tickets will have a <b>solved status</b> for 5 days, before being
            marked closed by an automation
          </li>
        </ul>
      ),
    },
  },
  {
    key: '1',
    title: 'When a Customer replies',
    content: {
      content: (
        <ul>
          <li>
            If the ticket status is solved, their AM receives the email reply
          </li>
          <li>
            If the ticket status is closed, an unassigned ticket is created
            (which references the original ticket)
          </li>
        </ul>
      ),
    },
  },
]

const MAX_ALLOWED_WEEKLY_MESSAGE_COUNT = 5

const ZendeskBulkMessagePanel: React.FC<Props> = ({
  fetchAllUsersIfNeeded,
  fetchAllZendeskBulkMessagesIfNeeded,
  allUsers,
  allZendeskBulkMessages,
  createZendeskBulkMessage,
}) => {
  // root-level state
  const [activeCustomers, setActiveCustomers] = useState<UserWithAdminInfo[]>()
  const [editingIndex, setEditingIndex] = useState<number | null>(null)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [successBannerVisible, setSuccessBannerVisible] = useState(false)
  const currentUser = useReselector(getCurrentUser)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const startOfWorkweekLocalTime = moment().startOf('isoWeek') // This current week's Monday

  // row-level state
  const [selectedAmId, setSelectedAmId] = useState<number>()
  const [selectedReplyTo, setSelectedReplyTo] = useState<number>()
  const [selectedRecipientsUserId, setSelectedRecipientsUserId] = useState<
    number[]
  >([])
  const [subject, setSubject] = useState<string>('')
  const [messageBody, setMessageBody] = useState<string>('')

  // Fetch all users on component load
  useEffect(() => {
    async function performAsync() {
      await fetchAllUsersIfNeeded()
      await fetchAllZendeskBulkMessagesIfNeeded()
    }
    performAsync()
  }, [fetchAllUsersIfNeeded, fetchAllZendeskBulkMessagesIfNeeded])

  // Once all users are received, filter only for customers with 'active' status
  useEffect(() => {
    const users = Object.values(allUsers)

    if (users) {
      const filtered = filterForActiveCustomers(Object.values(allUsers))
      setActiveCustomers(filtered)
    }
  }, [allUsers])

  const userExceededWeeklyMessageAllowance = useMemo(() => {
    let numMessagesSentThisWorkweek = 0

    // Once all existing ZendeskBulkMessages are received, determine if any were created since
    // beginning of this workweek
    if (allZendeskBulkMessages) {
      const bulkMessages = Object.values(allZendeskBulkMessages)

      if (bulkMessages && bulkMessages.length > 0) {
        bulkMessages.forEach((message) => {
          // Database value is stored in UTC, our start of workweek is local time,
          // so we need to convert db value to local, then compare
          const createdAtLocal = moment.utc(message.createdAt).local()

          if (createdAtLocal.isAfter(startOfWorkweekLocalTime)) {
            numMessagesSentThisWorkweek += 1
          }
        })
      }

      return numMessagesSentThisWorkweek >= MAX_ALLOWED_WEEKLY_MESSAGE_COUNT
    }

    return false
  }, [allZendeskBulkMessages, startOfWorkweekLocalTime])

  // Hide success banner after 5 seconds
  useEffect(() => {
    if (successBannerVisible) {
      setTimeout(() => {
        setSuccessBannerVisible(false)
      }, 5000)
    }
  }, [successBannerVisible])

  const openModal = () => setPreviewOpen(true)
  const closeModal = () => setPreviewOpen(false)

  const reset = () => {
    setSelectedAmId(undefined)
    setSelectedReplyTo(undefined)
    setSelectedRecipientsUserId([])
    setSubject('')
    setMessageBody('')
    setLoading(false)
    setEditingIndex(null)
  }

  const renderDetails = () => {
    let recipients
    let toText

    if (activeCustomers && (selectedAmId || selectedRecipientsUserId)) {
      if (selectedRecipientsUserId.length > 0) {
        recipients = selectedRecipientsUserId.map((id) => allUsers[id])
      } else if (selectedAmId === -1) {
        recipients = activeCustomers
      } else {
        recipients = activeCustomers.filter((a) => a.ownerId === selectedAmId)
      }

      const firstRecipient = recipients.length > 0 ? recipients[0] : null
      const previewAm = firstRecipient?.owner

      if (firstRecipient?.firstName) {
        toText = `${firstRecipient?.firstName} ${firstRecipient?.lastName} (Customer #1 of ${recipients.length})`
      } else {
        toText = `User's Name (Customer #1 of ${recipients.length})`
      }
      return (
        <List divided selection className="message-preview">
          {selectedRecipientsUserId.length > 0 && (
            <List.Item>
              <p>Note: You are sending a message to a list of User Ids</p>
            </List.Item>
          )}

          <List.Item>
            <Label horizontal>To</Label>
            {toText}
          </List.Item>
          <List.Item>
            <Label horizontal>From</Label>
            Heard Support {'<support@joinheard.com>'} (Replies sent to{' '}
            {firstRecipient?.firstName}&apos;s AM, {previewAm?.firstName}{' '}
            {previewAm?.lastName})
          </List.Item>
          <List.Item>
            <Label horizontal>Subject</Label>
            {subject}
          </List.Item>
          <List.Item>
            <Label horizontal>Body</Label>
            <br />
            <br />

            {firstRecipient?.firstName && (
              <textarea disabled className="message-preview">
                {replacePlaceholdersInString(messageBody, firstRecipient)}
              </textarea>
            )}
            {!firstRecipient?.firstName && (
              <textarea disabled className="message-preview">
                {messageBody}
              </textarea>
            )}
          </List.Item>
        </List>
      )
    }

    return <>No recipients or CSM selected</>
  }

  const onSendClicked = async (e: React.MouseEvent) => {
    if (!activeCustomers || !currentUser?.id) {
      return
    }

    const buttonText = (e.target as HTMLElement).innerText
    const toDescription = buttonText.replace('Send to ', '')

    let recipientIds = []
    if (
      typeof selectedAmId === 'undefined' &&
      selectedRecipientsUserId.length > 0
    ) {
      recipientIds = selectedRecipientsUserId
    } else if (selectedAmId === -1) {
      recipientIds = activeCustomers.map((a) => a.id)
    } else {
      recipientIds = activeCustomers
        .filter((a) => a.ownerId === selectedAmId)
        .map((f) => f.id)
    }

    const data: NewZendeskBulkMessage = {
      createdBy: currentUser?.id,
      subject,
      messageBody,
      toDescription,
      recipientIds,
    }

    try {
      setLoading(true)
      await createZendeskBulkMessage(data)
      setPreviewOpen(false)
      setSuccessBannerVisible(true)
      reset()
    } catch (err) {
      const message = parseErrorFromCatch(err)
      setErrorMessage(message)
    }

    setLoading(false)
  }

  let sendButtonText = 'Send'

  if (
    activeCustomers &&
    (selectedAmId || selectedRecipientsUserId.length > 0)
  ) {
    if (selectedAmId === -1 && selectedRecipientsUserId.length > 0) {
      sendButtonText = `Send to following customers - ${selectedRecipientsUserId.length}`
    } else if (selectedAmId === -1) {
      sendButtonText = `Send to all active customers - ${activeCustomers.length}`
    } else {
      const recipients = activeCustomers.filter(
        (a) => a.ownerId === selectedAmId
      )
      if (recipients.length > 0) {
        const am = recipients[0].owner
        sendButtonText = am
          ? `Send to ${am.firstName} ${am.lastName}'s active customers - ${recipients.length}`
          : ''
      }
    }
  }

  return (
    <Container id="zendeskBulkMessagePanel">
      <Header as="h3">Zendesk Bulk Messaging</Header>
      {successBannerVisible && (
        <Message success>
          <Message.Header>Success</Message.Header>
          <p>Zendesk bulk message was created successfully</p>
        </Message>
      )}
      {userExceededWeeklyMessageAllowance && (
        <Message
          icon="hand paper"
          info
          header="Hey there"
          content={`We only allow ${MAX_ALLOWED_WEEKLY_MESSAGE_COUNT} Zendesk bulk messages per workweek. You can send more messages next Monday.`}
        />
      )}
      <Grid celled padded>
        {activeCustomers && (
          <>
            <RecipientsRow
              activeCustomers={activeCustomers}
              editingIndex={editingIndex}
              setEditingIndex={setEditingIndex}
              selectedAmId={selectedAmId}
              setSelectedAmId={setSelectedAmId}
              selectedRecipientsUserIds={selectedRecipientsUserId}
              setSelectedRecipientsUserIds={setSelectedRecipientsUserId}
              disableEditing={userExceededWeeklyMessageAllowance}
            />
            <ReplyToRow
              selectedReplyTo={selectedReplyTo}
              editingIndex={editingIndex}
              setSelectedReplyTo={setSelectedReplyTo}
              setEditingIndex={setEditingIndex}
              disableEditing={userExceededWeeklyMessageAllowance}
            />
            <SubjectRow
              subject={subject}
              editingIndex={editingIndex}
              setEditingIndex={setEditingIndex}
              setSubject={setSubject}
              disableEditing={userExceededWeeklyMessageAllowance}
            />
            <MessageRow
              messageBody={messageBody}
              editingIndex={editingIndex}
              setEditingIndex={setEditingIndex}
              setMessageBody={setMessageBody}
              disableEditing={userExceededWeeklyMessageAllowance}
            />
          </>
        )}
        {!activeCustomers && (
          <Message header="No users exist" content="Please try refreshing" />
        )}
      </Grid>
      <div className="toolbar">
        {(selectedAmId || selectedReplyTo || subject || messageBody) && (
          <Button onClick={reset} floated="left">
            Reset Form
          </Button>
        )}
        {(selectedAmId || selectedRecipientsUserId.length > 0) &&
          selectedReplyTo &&
          subject &&
          messageBody && (
            <>
              <Button
                onClick={openModal}
                disabled={editingIndex !== null}
                primary
                floated="right"
                icon
                labelPosition="right"
              >
                Next
                <Icon name="arrow right" />
              </Button>
              <Modal
                onClose={closeModal}
                onOpen={openModal}
                open={previewOpen}
                closeOnEscape={false}
                closeOnDimmerClick={false}
              >
                <Modal.Header>Review</Modal.Header>
                <Modal.Content scrolling>
                  <Accordion panels={panels} />
                  <Divider horizontal>Preview</Divider>
                  <Modal.Description>{renderDetails()}</Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                  <Button disabled={loading} onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button
                    disabled={loading}
                    primary
                    content={sendButtonText}
                    icon="send"
                    onClick={onSendClicked}
                    loading={loading}
                  />
                </Modal.Actions>
              </Modal>
            </>
          )}
      </div>
      <Confirm
        open={errorMessage !== null}
        cancelButton={null}
        header="An error occurred"
        content={errorMessage}
        onConfirm={() => setErrorMessage(null)}
      />
    </Container>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  allUsers: state.admin.allUsers.byId,
  allZendeskBulkMessages: state.admin.allZendeskBulkMessages,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createZendeskBulkMessage: (data: NewZendeskBulkMessage) =>
    dispatch(createZendeskBulkMessage(data)),
  fetchAllUsersIfNeeded: () => dispatch(fetchAllUsersIfNeeded()),
  fetchAllZendeskBulkMessagesIfNeeded: () =>
    dispatch(fetchAllZendeskBulkMessagesIfNeeded()),
})

export default connect<StateProps, DispatchProps, unknown, ReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ZendeskBulkMessagePanel)
