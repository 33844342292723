import { useEffect } from 'react'
import { logSentryError, logSentryMessage } from '../../../utils/sentryHelpers'
import { useHeardPlaid } from './helpers'
import { FinancialAccount } from '../../../reducers/finances/financialAccountsReducer'
import { FinancialAccountWithAdminInfo } from '../../../reducers/admin/financialAccountsReducer'

const PlaidOauthLink = ({
  account = null,
  redirectLink,
  redirectUri,
}: {
  account?: FinancialAccount | FinancialAccountWithAdminInfo | null
  redirectLink: string
  redirectUri?: string
}) => {
  const linkToken = localStorage.getItem('link_token')

  if (!linkToken) {
    logSentryMessage('No link token for Plaid Oauth ')
  }

  const { open, ready, error } = useHeardPlaid({
    account,
    linkToken: linkToken || undefined,
    redirectLink,
    redirectUri,
    receivedRedirectUri: window.location.href,
  })

  // automatically re-initialize Link
  useEffect(() => {
    if (ready) {
      open()
    }
    if (error) {
      logSentryError(error.error)
    }
  }, [ready, open, error])

  return null
}
export default PlaidOauthLink
