import { Text } from '../BaseComponents'
import { Colors } from '../../styles/theme'

const TermsOfServiceCopy = () => (
  <div
    style={{
      color: Colors.black,
      padding: 16,
      backgroundColor: Colors.stone40,
      border: 4,
    }}
  >
    <Text as="bodyMd">Last Updated: February 2022</Text>
    <br />
    <Text as="bodyMd">
      Our mission at Heard Technologies, Inc., a Delaware company, (“Heard,”
      “we,” “our” or “us”) is to empower mental health providers (“Therapist,”
      “Customer” or “you”) to focus on their clients by using our combination of
      smart software and real humans to master their financial lives with modern
      bookkeeping solutions (the “Services”). The websites and software
      applications governed by this policy include joinheard.com and
      dashboard.joinheard.com.
    </Text>
    <br />

    <Text as="bodyMd">
      By using the Service, you are subject to the following Terms of Use
      (“Terms”). Every time users (“users,” “you” or “your”) visit or use
      features of the Service, you agree to be bound by these Terms. These Terms
      outline your rights, obligations and restrictions regarding your use of
      the Service, please read them carefully. If you do not agree to be bound
      by the Terms and all applicable laws, you should discontinue use of the
      Service immediately. Heard may modify the Terms from time to time and each
      modification will be effective when it is posted on the Service. You agree
      to be bound to any changes to the Terms through your continued use of the
      Service.
    </Text>
    <br />

    <Text as="bodyMd">
      In addition to these Terms, we have adopted We encourage you to read this
      Privacy Policy and our Terms of Use and Cookie Policy carefully. Please
      read our Privacy Policy carefully for information relating to our
      collection, use, and disclosure of your personal information.
    </Text>
    <br />

    <Text as="bodyMd">
      <b>1. OUR SERVICES</b>
    </Text>
    <br />

    <Text as="bodyMd">
      The Services consist of cloud-based financial tools and services which may
      include (i) bookkeeping, accounting, and access to banking services, (ii)
      financial information storage and reporting, (iii) tax preparation and
      advisory services, and (iv) the service of communicating information to,
      from and among third party service providers, as per the Therapist&#39;s
      instructions. The Therapist may make certain selections of Services that
      they wish to procure through the websites or the Account.
    </Text>
    <br />

    <Text as="bodyMd">
      Heard will provide the Services to the Therapist until terminated by
      either Heard or the Therapist, subject to the payment of applicable fees
      and compliance with these Terms. As part of the Services, Heard hereby
      grants to the Therapist a non-exclusive, non-transferable, non-assignable
      right to use the Services, as per these Terms. The Therapist acknowledges
      that the Services are cloud-based and hosted services and no copies of the
      Services or Heard platform will be delivered to Therapist. The Services
      shall be used by Therapist solely for Therapist&#39;s own purposes and
      Heard does not convey any right, title or interest in the Services or
      Heard platform to Therapist.
    </Text>
    <br />

    <Text as="bodyMd">
      <i>Financial Transactions</i>
    </Text>
    <br />

    <Text as="bodyMd">
      Heard shall not perform any financial transactions for the Therapist. The
      Therapist may, however, use the Services to communicate instructions to a
      third-party servicer, such as Plaid, to perform a financial transaction on
      behalf of the Therapist pursuant to the third-party servicer&#39;s privacy
      policy and terms of service.
    </Text>
    <br />

    <Text as="bodyMd">
      <i>Errors</i>
    </Text>
    <br />

    <Text as="bodyMd">
      Therapist shall immediately notify Heard of any errors by Heard,
      Therapist, or third-party service provider in the supply or use of the
      Services. Where practicable, Heard shall use commercially reasonable
      efforts to investigate errors, but makes no representation as to its
      ability to correct the error. Therapist shall provide Heard with any
      information necessary to investigate an error.
    </Text>
    <br />

    <Text as="bodyMd">
      <b>2. THERAPIST ACCOUNTS</b>
    </Text>
    <br />

    <Text as="bodyMd">
      Heard shall provide the Therapist with a unique and private account
      accessible through the Service (the “Account”). The Therapist has
      exclusive responsibility for the use of the Account. Heard will invite the
      Therapist to enter certain preferences and specifications within the
      website or the Account that will apply to the Services; Therapist assumes
      exclusive responsibility for such selections even if they contain errors
      by Therapist or result in losses to Therapist. Any additional terms and
      conditions posted to the websites with respect to the Account or specific
      Service preferences selected by the Therapist are incorporated herein by
      reference.
    </Text>
    <br />

    <Text as="bodyMd">
      Except as required to deliver the Services or as otherwise required by
      law, Heard shall not grant any third-party access to your Account.
    </Text>
    <br />

    <Text as="bodyMd">
      Therapist shall notify Heard by email to contact@joinheard.com immediately
      of any loss or disclosure, whether voluntary or otherwise, of any Account
      password or access.
    </Text>
    <br />

    <Text as="bodyMd">
      Therapist shall provide, at Therapist&#39;s own expense, all necessary
      hardware, applications, and internet connectivity necessary to access the
      Services. Therapist acknowledges that the internet can be unpredictable in
      performance, unsecure and may, from time to time, impede access to the
      Services or performance hereunder. Therapist agrees that Heard is not
      responsible for any internet outages, unsecure WIFI or other connections
      or any other interference with Therapist&#39;s use of or access to the
      Services or security breaches arising from any Therapist device and
      Therapist waives any and all claims against Heard in connection
      therewith.‍
    </Text>
    <br />

    <Text as="bodyMd">
      <b>3. LIMITATIONS</b>
    </Text>
    <br />

    <Text as="bodyMd">Prohibited Customers</Text>
    <br />

    <Text as="bodyMd">
      The following persons are prohibited from using the Services: (i) Persons
      who appear on the U.S. Department of the Treasury, Office of Foreign
      Assets Control (OFAC), Specially Designated Nationals List (SDN); (ii)
      Persons who are less than 18 years of age; and (iii) Persons, or their
      Affiliates who have procured services from Heard and have been terminated
      by Heard. Heard reserves the right to decline to provide Services or
      terminate Services to any business. In any case, any business that is
      illegal or operates in support of illegal activity is prohibited from
      using the Services.
    </Text>
    <br />

    <Text as="bodyMd">Limitations on Use</Text>
    <br />

    <Text as="bodyMd">
      Therapist shall not itself and shall not permit any Therapist employees or
      affiliates, or any other third party to, (i) permit any party to access or
      use the Services other than the Therapist personnel authorized under this
      Agreement; (ii) modify, adapt, alter or translate any software underlying
      the Services; (iii) license, lease, rent, loan, distribute, or otherwise
      transfer the Services to any third party; (iv) except if, and solely to
      the extent that, such a restriction is impermissible under Law, reverse
      engineer, decompile, disassemble, or otherwise derive or determine or
      attempt to derive or determine the source code (or algorithms, structure
      or organization) of any software underlying the Services; (v) use or copy
      the any software underlying the Services except as expressly allowed
      hereunder; (vi) conduct or promote any illegal activities while using the
      Services; (vii) use the Services to generate unsolicited email
      advertisements or spam; (viii) use the Services to stalk, harass or harm
      another individual; (ix) use any high volume automatic, electronic or
      manual process to access, search or harvest information from the Services
      (including without limitation robots, spiders or scripts); (x) except if,
      and solely to the extent that, such a restriction is impermissible under
      Law, interfere in any way with the proper functioning of the Services or
      interfere with or disrupt any servers or networks connected to the
      Services, or disobey any requirements, procedures, policies or regulations
      of networks connected to the Services; (xi) attempt to gain access to
      secured portions of the Services to which it does not possess access
      rights; (xii) upload or transmit any form of virus, worm, Trojan horse, or
      other malicious code; (xiii) use any robot, spider, other automatic
      device, or manual process to extract, “screen scrape”, monitor, “mine”, or
      copy any static or dynamic web page on the Services or the content
      contained on any such web page for commercial use without our prior
      express written permission; (xiv) impersonate any person or entity, or
      otherwise misrepresent its affiliation with a person or entity; (xv)
      mirror or frame the Services or any content, place pop-up windows over its
      pages, or otherwise affect the display of its pages; or (xvi) publicly
      display or publicly perform any part of the Services. Therapist may not
      use the Services for any purpose other than a purpose for which the
      Services are expressly designed. If Therapist is prohibited under
      applicable law from using the Services, Therapist may not use them.
    </Text>
    <br />

    <Text as="bodyMd">
      <b>4. INTELLECTUAL PROPERTY RIGHTS</b>
    </Text>
    <br />

    <Text as="bodyMd">
      We and our licensors own and retain all proprietary rights in the Service.
      The Service or any portion of the Service may not be reproduced,
      duplicated, copied, sold, resold, visited, or otherwise exploited for any
      commercial purpose without express written consent of Heard. You may not
      frame or utilize framing techniques to enclose any trademark, logo, or
      other proprietary information (including images, text, page layout, or
      form) of Heard without express written consent. You may not use any meta
      tags or any other “hidden text” utilizing Heard&#39;s name or trademarks
      without the express written consent of Heard. You may not (directly or
      indirectly) decipher, decompile, disassemble, reverse engineer, or
      otherwise attempt to derive source code or underlying ideas or algorithms
      of any part of the Service, or modify, translate, or otherwise create
      derivative works of any part of the Service. Any modification of content,
      or any portion thereof, or use of the content for any other purpose
      constitutes an infringement of trademark or other proprietary rights of
      Heard or third parties, and any unauthorized use terminates the permission
      to use the Service granted by Heard.
    </Text>
    <br />

    <Text as="bodyMd">
      All content included on this Service, such as text, graphics, logos,
      button icons, images, audio clips, digital downloads, data compilations,
      and software, is the property of Heard or its content/software suppliers
      or third-party partners and protected by United States and international
      copyright laws. The compilation of all content on this site is the
      exclusive property of Heard and protected by U.S. and international
      copyright laws. All software used on this site is the property of Heard or
      its software suppliers or third-party partners and protected by United
      States and international copyright laws.{' '}
    </Text>
    <br />

    <Text as="bodyMd">
      The marks appearing on this Service, including but not limited to Heard
      and any respective logos, emblems, slogans and designs, are trademarks of
      Heard. All other marks used on this Service are the property of their
      respective owners.
    </Text>
    <br />

    <Text as="bodyMd">
      <b>5. CONSENT</b>
    </Text>
    <br />

    <Text as="bodyMd">
      Therapist hereby authorizes Heard to, directly or through third parties,
      make any inquiries and conduct any investigation to verify Therapist
      identity.
    </Text>
    <br />

    <Text as="bodyMd">
      The Services requires certain information concerning Therapist, including
      but not limited to Therapist name, address, phone number, email address,
      bank account information and other account information. Therapist agrees
      that all information it provides to Heard shall be complete and accurate
      and Therapist shall promptly correct any errors in the information
      provided to Heard.
    </Text>
    <br />

    <Text as="bodyMd">
      Subject to the Heard Privacy Policy, incorporated herein by reference, and
      any other separate agreement between Therapist and Heard, Therapist and
      each of Therapist personnel hereby grant Heard the right to collect,
      store, use and disclose Therapist data for the purpose of providing the
      Services and its integration with Heard&#39;s third party partners. Where
      Therapist data includes data concerning third parties, Therapist states
      that it has obtained the necessary consents for Heard to collect, process,
      store such data hereunder from the relevant data subjects.
    </Text>
    <br />

    <Text as="bodyMd">
      Subject to any separate agreement between Therapist and Heard, Therapist
      hereby authorizes Heard and each third-party partner to each obtain from
      the others and disclose to the others Therapist data in so far as is
      necessary to supply their respective services either hereunder or under
      their respective third-party partner agreements. Where required by law,
      Heard will disclose Therapist data to law enforcement agencies.
    </Text>
    <br />

    <Text as="bodyMd">
      <b>6. THERAPIST DATA</b>
    </Text>
    <br />

    <Text as="bodyMd">Accuracy</Text>
    <br />

    <Text as="bodyMd">
      Therapist has sole responsibility for the accuracy, appropriateness, and
      completeness of all provided data. Heard will use the data it is provided
      in performing the Services and is not responsible for reviewing,
      validating, or otherwise confirming the accuracy, appropriateness or
      completeness of Therapist provided data.
    </Text>
    <br />

    <Text as="bodyMd">Feedback</Text>
    <br />

    <Text as="bodyMd">
      In the event that Therapist provides Heard any ideas, thoughts,
      criticisms, suggested improvements or other feedback related to the
      Services (collectively “Feedback”), Customer agrees that Heard may use the
      Feedback to modify the Services and that Therapist will not be due any
      compensation, including any royalty related to the product or service that
      incorporates the Feedback. Therapist hereby grants Heard a worldwide,
      royalty-free, fully paid, perpetual, irrevocable license to use,
      reproduce, modify, translate, distribute, perform, display, import, sell,
      offer for sale, make, have made, and otherwise exploit the Feedback in any
      form, media, or technology, whether now known or hereafter developed, and
      to allow others to do the same. This is true whether Therapist provides
      the Feedback on the Services or through any other method of communication
      with Heard.
    </Text>
    <br />

    <Text as="bodyMd">Limitations</Text>
    <br />

    <Text as="bodyMd">
      Therapist shall not take possession of or enter into the Account any data:
      (i) that Therapist does not have the lawful right to copy, transmit,
      distribute, and display (including any Therapist data that would violate
      any confidentiality or fiduciary obligations that Therapist might have
      with respect to the Therapist data); (ii) for which Therapist does not
      have the consent or permission from the owner of any personally
      identifiable information contained in the Therapist data; (iii) that
      infringes, misappropriates or otherwise violates any Intellectual Property
      Rights or violates any privacy rights of any third party; (iv) that is
      false or misleading; (v) that is defamatory, obscene, or offensive; (vi)
      that violates, or encourages any conduct that would violate, any laws or
      regulation or would give rise to civil or criminal liability; or (vii)
      that contains any viruses, trojan horses, spyware, malware, worms, time
      bombs, cancelbots, or other disabling devices or other harmful component
      intended to damage, detrimentally interfere with, surreptitiously
      intercept or expropriate any system, data or personal information.
    </Text>
    <br />

    <Text as="bodyMd">
      <b>7. INDEMNIFICATION</b>
    </Text>
    <br />

    <Text as="bodyMd">
      Therapist shall defend, indemnify, and hold harmless Heard, its employees,
      officers, directors, affiliates, suppliers, licensors, third party
      partners and other customers against any and all liability including
      damages, recoveries, deficiencies, interest, penalties, losses and
      reasonable attorney&#39;s fees arising out of or relating to: (i) any
      breach of the terms hereof; (ii) any breach of a third party partner
      service agreement; (iii) any violation of any laws; (iv) any use of
      Therapist data by Therapist or other third party; (v) Therapist use of the
      Services, including in combination with any third party service; (vi) any
      transaction; (vii) any financial transaction occurring as a result of data
      communicated via the Services; (viii) any act or omission of any third
      party partner or Therapist; (ix) costs incurred by Heard enforcing the
      terms hereof or responding to any subpoena relating to Therapist or
      Therapist data; (x) any claim by a governmental taxing authority; or (xi)
      any dispute between Therapist and any third party or Therapist personnel.
    </Text>
    <br />

    <Text as="bodyMd">
      <b>8. TERMINATION AND SUSPENSION</b>
    </Text>
    <br />

    <Text as="bodyMd">
      Heard may, in its sole discretion, terminate or suspend your access to all
      or part of the Service, for any reason, including without limitation, your
      breach of these Terms. In the event these Terms are terminated, the
      restrictions regarding content appearing on the Service, and the
      representations and warranties, indemnities, and limitations of
      liabilities set forth in these Terms will survive termination. In the
      event that you are unsatisfied with the services provided by Heard, your
      sole remedy is to terminate your use of the Service.
    </Text>
    <br />

    <Text as="bodyMd">
      <b>9. FEES, PAYMENT, AND REFUNDS</b>
    </Text>

    <Text as="bodyMd">Fees</Text>
    <br />

    <Text as="bodyMd">
      Access to the Services may require Therapist to pay fees, as may be
      further described on the websites or in the Account. All fees are in U.S.
      dollars and are non-refundable, unless otherwise provided herein. Heard
      reserves the right to amend the fees by posting new fees on the websites,
      in the Account, or by providing notification via email; such changes shall
      take effect within thirty (30) days unless accepted by Therapist earlier.
    </Text>
    <br />

    <Text as="bodyMd">Payment</Text>
    <br />

    <Text as="bodyMd">
      Therapist shall authorize Heard and Heard&#39;s third party partners, such
      as Stripe, to collect payment of fees from a credit card, debit card or
      other means of payment; Therapist authorizes Heard to charge all fees and
      other amounts owing hereunder from such payment method.
    </Text>
    <br />

    <Text as="bodyMd">
      Without limitation, Heard reserves the right to suspend the Services until
      all fees or other amounts owing hereunder are paid in full or terminate
      your access to the Service for late payment.
    </Text>
    <br />

    <Text as="bodyMd">
      Fees quoted do not include, and Therapist shall pay, and hold Heard
      harmless from all sales, use, gross receipts, value-added, personal
      property or other taxes, and all applicable duties, tariffs, assessments,
      export and import fees or similar charges (including interest and
      penalties imposed thereon) on the transaction contemplated herein, other
      than taxes based on the net income or profits of Heard.
    </Text>
    <br />

    <Text as="bodyMd">Refunds</Text>
    <br />

    <Text as="bodyMd">
      In the event of termination of the Services for any reason except for
      Therapist&#39;s breach of this Agreement, Heard may provide Therapist with
      a refund of any pre-paid, but unused fees related to such Services.
      Therapist is not entitled to any refund for Heard termination of the
      Services based upon Therapist&#39;s breach.
    </Text>
    <br />

    <Text as="bodyMd">
      <b>10. NOT LEGAL, TAX, OR ACCOUNTING ADVICE</b>
    </Text>
    <br />

    <Text as="bodyMd">
      Heard may provide Therapist with legal, tax or accounting information as
      part of Heard&#39;s tax preparation, payroll processing, and advisory
      services or on the Heard website. Unless such information was provided by
      a Heard licensed professional pursuant to a written agreement between
      Therapist and Heard, such information is not intended to constitute
      specific legal, tax, accounting, or other professional advice, and may not
      reflect recent developments in the law, may not be complete, and may not
      be accurate in or applicable to Therapist&#39;s particular circumstances.
      As such, Therapist agrees not to act based on any information provided by
      Heard, unless such information was provided by a Heard licensed
      professional pursuant to a written agreement between Therapist and Heard,
      or before obtaining advice from other professional counsel qualified in
      the applicable subject matter and jurisdiction.
    </Text>
    <br />

    <Text as="bodyMd">
      <b>11. NO WARRANTIES BY HEARD</b>
    </Text>
    <br />

    <Text as="bodyMd">Content; Third Party Servicers</Text>
    <br />

    <Text as="bodyMd">
      Content from third party partners, other users, suppliers, advertisers,
      and other third parties may be made available to Therapist through the
      Services. Therapist agrees that Heard is not responsible for any such
      content. Heard does not make any guarantees about the accuracy, currency,
      suitability, or quality of the information in such content and Heard
      assumes no responsibility for unintended, objectionable, inaccurate,
      misleading, or unlawful content made available by other users,
      advertisers, and other third parties or violation of any third-party
      rights related to such content. The Services may contain links to websites
      not operated by Heard. Heard is not responsible for the content, products,
      materials, or practices (including privacy practices) of such websites.
      Heard makes no warranty, representation, endorsement, or guarantee
      regarding, and accepts no responsibility for, the quality, content, nature
      or reliability of third-party websites, products, or services accessible
      by hyperlink or otherwise from the websites or Services including but not
      limited to third party partner services. Heard provides these links and
      integrations for Therapist&#39;s convenience only and does not control
      such third parties. Heard&#39;s inclusion of links to such links or
      integrations does not imply any endorsement of the materials on such
      third-party services or any association with their operators. The Services
      may contain links to sites that are operated by Heard but which operate
      under different terms. It is Therapist&#39;s responsibility to review the
      privacy policies and terms and conditions of any other site Customer
      visits or contacts with, including through integration with the Heard
      services. CUSTOMER AGREES THAT IN NO EVENT WILL HEARD BE LIABLE TO
      THERAPIST IN CONNECTION WITH ANY WEBSITES, CONTENT, MATERIALS, OR
      PRACTICES OF ANY THIRD PARTY.
    </Text>
    <br />

    <Text as="bodyMd">Services</Text>
    <br />

    <Text as="bodyMd">
      THE SERVICES AND ALL MATERIAL OR CONTENT AVAILABLE THROUGH THE SERVICE ARE
      PROVIDED “AS IS”, “AS AVAILABLE”, WITH ALL FAULTS AND WITHOUT WARRANTIES,
      REPRESENTATIONS OR CONDITIONS OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED
      BY APPLICABLE LAW, HEARD HEREBY DISCLAIMS ALL WARRANTIES, EXPRESS,
      IMPLIED, STATUTORY, OR OTHERWISE, REPRESENTATIONS OR CONDITIONS, WHETHER
      WRITTEN OR ORAL, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
      TITLE, NONINFRINGEMENT, SECURITY, RELIABILITY, COMPLETENESS, QUIET
      ENJOYMENT, ACCURACY, QUALITY, INTEGRATION OR FITNESS FOR A PARTICULAR
      PURPOSE, AND ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR
      TRADE. HEARD DOES NOT WARRANT THAT THE SERVICE WILL OPERATE WITHOUT
      INTERRUPTION OR BE ERROR FREE. NO ADVICE OR INFORMATION, WHETHER ORAL OR
      WRITTEN, OBTAINED BY CUSTOMER FROM THE SERVICES OR ANY MATERIALS OR
      CONTENT AVAILABLE THROUGH THE SERVICES WILL CREATE ANY WARRANTY REGARDING
      ANY OF THE Heard ENTITIES OR THE SERVICES THAT IS NOT EXPRESSLY STATED IN
      THIS AGREEMENT. CUSTOMER ASSUMES ALL RISK FOR ANY DAMAGE THAT MAY RESULT
      FROM CUSTOMER&#39;S USE OF OR ACCESS TO THE SERVICES, CUSTOMER&#39;S
      DEALING WITH ANY OTHER USER, AND ANY MATERIALS OR CONTENT AVAILABLE
      THROUGH THE SERVICES. CUSTOMER UNDERSTANDS AND AGREES THAT CUSTOMER USES
      THE SERVICES, AND USES, ACCESSES, DOWNLOADS, OR OTHERWISE OBTAINS
      MATERIALS OR CONTENT THROUGH THE SERVICES AND ANY ASSOCIATED SITES OR
      SERVICES, AT CUSTOMER&#39;S OWN DISCRETION AND RISK, AND THAT CUSTOMER IS
      SOLELY RESPONSIBLE FOR ANY DAMAGE TO CUSTOMER&#39;S PROPERTY (INCLUDING
      CUSTOMER&#39;S COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH
      THE SERVICES), OR THE LOSS OF DATA THAT RESULTS FROM THE USE OF THE
      SERVICES OR THE DOWNLOAD OR USE OF MATERIAL OR CONTENT.
    </Text>
    <br />

    <Text as="bodyMd">
      SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER OF WARRANTIES AND CUSTOMER
      MAY HAVE OTHER RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION
    </Text>
    <br />

    <Text as="bodyMd">
      <b>12. LIMITATION OF LIABILITY</b>
    </Text>
    <br />

    <Text as="bodyMd">
      IN NO EVENT WILL HEARD BE LIABLE FOR DAMAGES ARISING OUT OF OR IN ANY WAY
      CONNECTED TO THIS AGREEMENT EXCEEDING FEES PAID IN RESPECT OF THE SERVICES
      DURING THE THREE (3) MONTHS PRIOR TO THE EVENT GIVING RISE TO LIABILITY.
      THE EXISTENCE OF ONE OR MORE CLAIMS UNDER THIS AGREEMENT WILL NOT INCREASE
      THE MAXIMUM LIABILITY AMOUNT. IN NO EVENT SHALL HEARD&#39;S SUPPLIERS OR
      THIRD-PARTY SERVICERS HAVE ANY LIABILITY ARISING OUT OF OR IN ANY WAY
      CONNECTED TO THIS AGREEMENT.
    </Text>
    <br />

    <Text as="bodyMd">
      IN NO EVENT SHALL HEARD BE LIABLE TO CUSTOMER FOR ANY (I) SPECIAL,
      INDIRECT, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES, (II) LOST
      SAVINGS, PROFITS, DATA, USE, OR GOODWILL, (III) BUSINESS INTERRUPTION EVEN
      IF NOTIFIED IN ADVANCE OF SUCH POSSIBILITY, OR (IV) PERSONAL OR PROPERTY
      DAMAGE ARISING OUT OF OR IN ANY WAY CONNECTED TO THIS AGREEMENT,
      REGARDLESS OF CAUSE OF ACTION OR THE THEORY OF LIABILITY, WHETHER IN
      CONTRACT, TORT (INCLUDING NEGLIGENCE, GROSS NEGLIGENCE, FUNDAMENTAL
      BREACH, BREACH OF A FUNDAMENTAL TERM) OR OTHERWISE. IN NO EVENT SHALL
      HEARD BE LIABLE FOR PROCUREMENT OR COSTS OF SUBSTITUTE PRODUCTS OR
      SERVICES. THE FOREGOING LIMITATIONS WILL APPLY NOTWITHSTANDING ANY FAILURE
      OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY HEREIN.
    </Text>
    <br />

    <Text as="bodyMd">
      TO THE EXTENT THAT HEARD MAY NOT, AS A MATTER OF LAW, DISCLAIM ANY IMPLIED
      WARRANTY OR LIMIT LIABILITIES, THE SCOPE AND DURATION OF SUCH WARRANTY AND
      THE EXTENT OF HEARD&#39;S LIABILITY WILL BE THE MINIMUM PERMITTED UNDER
      SUCH LAW.
    </Text>
    <br />

    <Text as="bodyMd">
      <b>13. NOTICES</b>
    </Text>
    <br />

    <Text as="bodyMd">
      By providing Heard with Therapist&#39;s mobile telephone number, Therapist
      consents to receiving text messages and calls at that number as requested
      for account verification, invitations, and other purposes related to the
      Services. While Heard does not charge a fee for text messages or calls,
      Therapist&#39;s carrier may charge standard messaging, data, and other
      fees. Therapist is responsible for these charges. Heard may send and
      receive text messages and make calls through cellular telephone operators
      or other networks, and the level of reliability may vary. Heard is not
      responsible for the timeliness or final delivery of the message, as this
      is out of our control and is the responsibility of the cellular telephone
      operator or other networks.
    </Text>
    <br />

    <Text as="bodyMd">
      <b>14. JURISDICTION AND SEVERABILITY</b>
    </Text>
    <br />

    <Text as="bodyMd">
      Heard operates the Service from within the United States. Heard makes no
      representations that content and materials on the Service are legal or
      appropriate for use from outside the United States. If you choose to
      access the Service from other locations, you do so at your own risk and
      are responsible for compliance with any and all local laws. You may not
      use the Service in violation of U.S. export laws and regulations.
    </Text>
    <br />

    <Text as="bodyMd">
      These Terms are personal to you, and are not assignable, transferable, or
      sublicensable by you except with Heard&#39;s prior written consent. Heard
      may assign, transfer, or delegate any of its rights and obligations
      hereunder without consent.
    </Text>
    <br />

    <Text as="bodyMd">
      These Terms will be governed by and construed in accordance with the laws
      of the state of Delaware. Any action brought to enforce these Terms or
      matters related to the Service will be brought in either the State or
      Federal Courts of the state of Delaware. Any claim or cause of action you
      have with respect to use of the Service must be commenced within one (1)
      year after the claim arises. In any action or proceeding to enforce rights
      under the Terms, the prevailing party will be entitled to recover costs
      and attorneys&#39; fees.
    </Text>
    <br />

    <Text as="bodyMd">
      If any provision of these Terms is deemed void, unlawful, or otherwise
      unenforceable for any reason, that provision will be severed from these
      Terms and the remaining provisions of these Terms will remain in force.
    </Text>
    <br />

    <Text as="bodyMd">
      <b>15. HOW TO CONTACT US</b>
    </Text>
    <br />

    <Text as="bodyMd">
      If you have any questions about these Terms, please contact us at:
    </Text>
    <br />

    <Text as="bodyMd">Email address: contact@joinheard.com</Text>
    <br />

    <Text as="bodyMd">Postal address: 500 Yale Ave N, Seattle, WA 98109</Text>
    <br />
  </div>
)

export default TermsOfServiceCopy
