import { Modal } from '../../BaseComponents'
import AnnualTaxesContent from './AnnualTaxesContent'
import './style.scss'

interface Props {
  feature: 'annual-taxes'
}

/**
 * Displays a CTA "upgrade" modal for a specific feature
 * e.g. When a user's subscription does not include Annual Taxes, we render this component
 * to advertise the feature & potentially capture an upgrade
 *
 * Extendable for future features by adding a type onto props and rendering its specific html
 */
const UpgradeToPremiumModal: React.FC<Props> = ({ feature }) => {
  let content

  switch (feature) {
    case 'annual-taxes':
      content = <AnnualTaxesContent />
      break
    default:
      content = null
  }

  const mountNode = document.querySelector('.content-wrapper')

  return (
    <Modal
      open
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      content={content}
      centered={false}
      mountNode={mountNode}
      style={{ border: 'none' }}
    />
  )
}

export default UpgradeToPremiumModal
