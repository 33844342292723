import { useCallback, useEffect, useMemo, useState } from 'react'
import { orderBy, sortBy } from 'lodash'
import { Divider, Grid } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  Dropdown,
  GridRowColumn,
  Label,
  Link,
  Pagination,
  Table,
  Text,
  IconButton,
  Loader,
  Button,
} from '../../BaseComponents'
import {
  EXPENSE_BREAKDOWN_PAGE_SIZE,
  FinancialInsightsButtonType,
  INSIGHTS_TYPE,
  InsightsRowAPIResponse,
  InsightsTransactionsAPIResponse,
  MONTHS_ARR,
  applyCategoryFilter,
  computeEndDateForInsightsExpensesBreakdownTable,
  generateSampleInsightsRowAPIResponses,
  generateSampleInsightsTransactionsAPIResponses,
  getIncomeOrExpenseLabel,
  isTopExpense,
} from './utils'
import ExpensesBreakdownTransactions from '../InsightsExpensesPanel/ExpensesBreakdownTransactions'
import { DropdownType, InsightsDividedCard } from './InsightsDividedCard'
import { valsToDropdownOptions } from '../../BaseComponents/Dropdown'
import {
  fetchInsightsCategoryTransactions,
  fetchInsightsTableData,
  FETCH_INSIGHTS_TABLE_DATA_KEY,
  FETCH_INSIGHTS_CATEGORY_TRANSACTIONS_KEY,
  FETCH_INSIGHTS_TABLE_DROPDOWN_KEY,
} from './financeActions'
import { DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import {
  useGetEndDateForFinancialInsights,
  useReselector,
  useTrackFinancialInsightsButtonClick,
} from '../../../utils/sharedHooks'
import {
  selectFirstErrorMessageForKeys,
  selectIsFetchingForKeys,
} from '../../../reducers/fetch'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import InsightsRow from '../InsightsExpensesPanel/InsightsRow'
import { useAppDispatch } from '../../../utils/typeHelpers'

const InsightsBreakdownTable = ({
  inputDate,
  insightsType,
  shouldDisplaySampleData = false,
  years,
}: {
  inputDate?: string
  insightsType: INSIGHTS_TYPE
  shouldDisplaySampleData?: boolean
  years: number[]
}) => {
  const dispatch = useAppDispatch()
  const [insightsRows, setInsightsRows] = useState<InsightsRowAPIResponse[]>([])
  const [filteredInsightsRows, setFilteredInsightsRows] = useState<
    InsightsRowAPIResponse[]
  >([])
  const [currentPageInsightsRows, setCurrentPageInsightsRows] = useState<
    InsightsRowAPIResponse[]
  >([])
  const [currentPage, setCurrentPage] = useState(1)
  const [categoryFilter, setCategoryFilter] = useState('all')
  const [yearFilter, setYearFilter] = useState<number>()
  const [monthFilter, setMonthFilter] = useState(MONTHS_ARR[0].value)
  const [yearOptions, setYearOptions] = useState<number[]>([])
  const [selectedInsightsRow, setSelectedInsightsRow] =
    useState<InsightsRowAPIResponse | null>(null)
  const [insightTransactions, setInsightsTransactions] = useState<
    InsightsTransactionsAPIResponse[]
  >([])
  const [currentTransactions, setCurrentTransactions] = useState<
    InsightsTransactionsAPIResponse[]
  >([])
  const [transactionsPages, setTransactionsPages] = useState(1)
  const [topExpenseCategoryIds, setTopExpenseCategoryIds] = useState<number[]>(
    []
  )
  const [isYearOptionsEmpty, setIsYearOptionsEmpty] = useState(false)

  const currentDate = useMemo(
    () =>
      inputDate
        ? DateTime.fromFormat(inputDate, DATE_FORMATS_LUXON.INPUT)
        : DateTime.now(),
    [inputDate]
  )

  const getEndDate = useGetEndDateForFinancialInsights(currentDate)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const trackInsights = useTrackFinancialInsightsButtonClick()

  useEffect(() => {
    setYearOptions(years)
    setYearFilter(years[0])
  }, [dispatch, shouldDisplaySampleData, years, isYearOptionsEmpty])

  const { filteredYearOptions, filteredMonthOptions } = useMemo(() => {
    const endDate = getEndDate()
    const filteredYears = [
      endDate.year,
      ...yearOptions.filter((year) => year < endDate.year),
    ]
    if ((yearFilter ?? 0) > filteredYears[0]) {
      setYearFilter(filteredYears[0])
    }
    let filteredMonths = [...MONTHS_ARR]
    if (yearFilter === endDate.year) {
      filteredMonths = filteredMonths.filter(
        (monthOption) =>
          monthOption.value === 'ytd' ||
          parseInt(monthOption.value) < endDate.month
      )
    }
    return {
      filteredYearOptions: valsToDropdownOptions(filteredYears),
      filteredMonthOptions: filteredMonths,
    }
  }, [yearOptions, yearFilter, getEndDate])

  useEffect(() => {
    const fetchBreakdownData = async (startDate: string, endDate: string) => {
      const breakdownData = shouldDisplaySampleData
        ? generateSampleInsightsRowAPIResponses()
        : await fetchInsightsTableData(
            insightsType,
            startDate,
            endDate
          )(dispatch)

      if (breakdownData && breakdownData.length < 1) {
        setIsYearOptionsEmpty(true)
      }

      const sortedBreakdown = orderBy(
        breakdownData,
        'transactions_sum_in_cents',
        insightsType === INSIGHTS_TYPE.EXPENSES ? 'asc' : 'desc'
      )

      const topExpenseCategoryIds = sortedBreakdown
        .slice(0, 3)
        .map((expenseBreakdown) => expenseBreakdown.transaction_category_id)

      setTopExpenseCategoryIds(topExpenseCategoryIds)

      setInsightsRows(sortedBreakdown)
    }

    if (!yearFilter) {
      return
    }

    const startMonthNumber = monthFilter === 'ytd' ? '0' : monthFilter
    const startDate = DateTime.fromObject({
      year: yearFilter,
      month: parseInt(startMonthNumber) + 1,
      day: 1,
    })

    const endDate = computeEndDateForInsightsExpensesBreakdownTable(
      startDate,
      getEndDate(),
      yearFilter,
      monthFilter
    )

    const formattedStartDate = startDate.toFormat(DATE_FORMATS_LUXON.TIMESTAMP)
    const formattedEndDate = endDate.toFormat(DATE_FORMATS_LUXON.TIMESTAMP)

    fetchBreakdownData(formattedStartDate, formattedEndDate)
  }, [
    yearFilter,
    monthFilter,
    dispatch,
    currentDate,
    getEndDate,
    insightsType,
    shouldDisplaySampleData,
  ])

  useEffect(() => {
    const categoryFilteredExpensesBreakdown = applyCategoryFilter(
      insightsRows,
      categoryFilter,
      topExpenseCategoryIds
    )
    setFilteredInsightsRows(categoryFilteredExpensesBreakdown)
  }, [categoryFilter, insightsRows, topExpenseCategoryIds])

  useEffect(() => {
    const pageRows = filteredInsightsRows.slice(
      (currentPage - 1) * EXPENSE_BREAKDOWN_PAGE_SIZE,
      currentPage * EXPENSE_BREAKDOWN_PAGE_SIZE
    )
    setCurrentPageInsightsRows(pageRows)
  }, [currentPage, filteredInsightsRows, currentTransactions])

  useEffect(() => {
    const fetchInsightsTransactionData = async (
      transactionCategoryId: number,
      startDate: string,
      endDate: string
    ) => {
      const fetchedTransactions = shouldDisplaySampleData
        ? generateSampleInsightsTransactionsAPIResponses(
            transactionCategoryId,
            insightsRows
          )
        : await fetchInsightsCategoryTransactions(
            transactionCategoryId,
            startDate,
            endDate
          )(dispatch)

      if (fetchedTransactions) {
        const orderdDescTransactions = sortBy(
          fetchedTransactions,
          'date'
        ).reverse()

        setInsightsTransactions(orderdDescTransactions)
        setTransactionsPages(
          Math.ceil(fetchedTransactions.length / EXPENSE_BREAKDOWN_PAGE_SIZE)
        )
        setCurrentTransactions(
          orderdDescTransactions.slice(0, EXPENSE_BREAKDOWN_PAGE_SIZE)
        )
      }
    }

    if (yearFilter && monthFilter && selectedInsightsRow) {
      const { transaction_category_id } = selectedInsightsRow
      const startMonthNumber = monthFilter === 'ytd' ? '0' : monthFilter

      const startDate = DateTime.fromObject({
        year: yearFilter,
        month: parseInt(startMonthNumber) + 1,
        day: 1,
      })

      const endDate = computeEndDateForInsightsExpensesBreakdownTable(
        startDate,
        getEndDate(),
        yearFilter,
        monthFilter
      )

      fetchInsightsTransactionData(
        transaction_category_id,
        startDate.toFormat(DATE_FORMATS_LUXON.TIMESTAMP),
        endDate.toFormat(DATE_FORMATS_LUXON.TIMESTAMP)
      )
    }
  }, [
    selectedInsightsRow,
    dispatch,
    monthFilter,
    yearFilter,
    getEndDate,
    shouldDisplaySampleData,
    insightsRows,
  ])

  const handleCategoryFilterChange = (category: string) => {
    setCurrentPage(1)
    setCategoryFilter(category)
    trackInsights({
      button:
        insightsType === INSIGHTS_TYPE.EXPENSES
          ? FinancialInsightsButtonType.EXPENSES_FILTER
          : FinancialInsightsButtonType.INCOME_FILTER,
      insightsType,
    })
  }

  const handleYearFilterChange = (year: number) => {
    setCurrentPage(1)
    setYearFilter(year)
    trackInsights({
      button: FinancialInsightsButtonType.YEAR_FILTER,
      insightsType,
    })
  }

  const handleMonthFilterChange = (month: string) => {
    setCurrentPage(1)
    setMonthFilter(month)
    trackInsights({
      button: FinancialInsightsButtonType.MONTH_FILTER,
      insightsType,
    })
  }

  const handleSelectedExpenseBreakdown = (
    expenseBreakdown: InsightsRowAPIResponse
  ) => {
    setSelectedInsightsRow(expenseBreakdown)
    setCurrentPage(1)
    trackInsights({
      button: FinancialInsightsButtonType.CATEGORY_TRANSACTIONS,
      insightsType,
    })
  }

  const handlePageChange = useCallback(
    (page: number) => {
      if (selectedInsightsRow) {
        const start = (page - 1) * EXPENSE_BREAKDOWN_PAGE_SIZE
        const end = start + EXPENSE_BREAKDOWN_PAGE_SIZE
        setCurrentTransactions(insightTransactions.slice(start, end))
      }
      setCurrentPage(page)
      trackInsights({
        button: FinancialInsightsButtonType.TABLE_PAGE,
        insightsType,
      })
    },
    [selectedInsightsRow, insightTransactions, trackInsights, insightsType]
  )

  const pages = useMemo(() => {
    if (selectedInsightsRow) {
      return Math.ceil(insightTransactions.length / EXPENSE_BREAKDOWN_PAGE_SIZE)
    } else {
      return Math.ceil(
        filteredInsightsRows.length / EXPENSE_BREAKDOWN_PAGE_SIZE
      )
    }
  }, [
    filteredInsightsRows.length,
    insightTransactions.length,
    selectedInsightsRow,
  ])

  const onTransactionsExitClick = () => {
    setSelectedInsightsRow(null)
    setCurrentPage(1)
  }

  const queryLoading = useReselector(selectIsFetchingForKeys, [
    FETCH_INSIGHTS_TABLE_DROPDOWN_KEY,
    FETCH_INSIGHTS_CATEGORY_TRANSACTIONS_KEY,
    FETCH_INSIGHTS_TABLE_DATA_KEY,
  ])

  const error = useReselector(selectFirstErrorMessageForKeys, [
    FETCH_INSIGHTS_TABLE_DROPDOWN_KEY,
    FETCH_INSIGHTS_CATEGORY_TRANSACTIONS_KEY,
    FETCH_INSIGHTS_TABLE_DATA_KEY,
  ])

  const getCategoryDropdownOptionsForInsightsType = (
    insightsType: INSIGHTS_TYPE
  ) => {
    const commonOptions = insightsRows.map((insightsRow) => ({
      text: insightsRow.transaction_category_name,
      value: insightsRow.transaction_category_name,
    }))

    const typeSpecificOptions =
      insightsType === INSIGHTS_TYPE.EXPENSES
        ? [
            { text: 'All Expenses', value: 'all' },
            { text: 'Top Expenses', value: 'top' },
          ]
        : [{ text: 'All Categories', value: 'all' }]

    return [...typeSpecificOptions, ...commonOptions]
  }

  const displayYearOptionsEmptyMessage = (insightsType: INSIGHTS_TYPE) => {
    return (
      <GridRowColumn>
        <Grid>
          <GridRowColumn style={{ textAlign: 'center' }}>
            <Text as="h3">No {getIncomeOrExpenseLabel(insightsType)} Yet</Text>
          </GridRowColumn>
          <GridRowColumn style={{ textAlign: 'center' }}>
            <Text color="darkGray">
              Your categorized{' '}
              {getIncomeOrExpenseLabel(insightsType).toLowerCase()} will show up
              here
            </Text>
          </GridRowColumn>
          <GridRowColumn style={{ textAlign: 'center' }}>
            <Link to={'/accounts#connected-institutions'}>
              <Button className="link">Link Business Account</Button>
            </Link>
          </GridRowColumn>
        </Grid>
      </GridRowColumn>
    )
  }

  const displayMonthEmptyMessage = (insightsType: INSIGHTS_TYPE) => {
    return (
      <GridRowColumn>
        <Grid>
          <GridRowColumn style={{ textAlign: 'center' }}>
            <Text as="h3">No {getIncomeOrExpenseLabel(insightsType)}</Text>
          </GridRowColumn>
          <GridRowColumn style={{ textAlign: 'center' }}>
            <Text color="darkGray">
              You have no {getIncomeOrExpenseLabel(insightsType).toLowerCase()}{' '}
              for this month.
            </Text>
          </GridRowColumn>
        </Grid>
      </GridRowColumn>
    )
  }

  const displayErrorMessage = () => {
    return (
      <GridRowColumn>
        <Grid>
          <GridRowColumn style={{ textAlign: 'center' }}>
            <Text as="h3">Something Went Wrong</Text>
          </GridRowColumn>
          <GridRowColumn style={{ textAlign: 'center' }}>
            <Text color="darkGray">Please try again in a bit.</Text>
          </GridRowColumn>
        </Grid>
      </GridRowColumn>
    )
  }

  return (
    <>
      <GridRowColumn short>
        <Divider style={{ marginBottom: 0.5, marginTop: 12.5 }} />
      </GridRowColumn>
      {selectedInsightsRow ? (
        queryLoading ? (
          <GridRowColumn>
            <Loader loading />
          </GridRowColumn>
        ) : (
          <>
            <GridRowColumn
              columnStyle={{ display: 'flex', alignItems: 'center' }}
            >
              <Text color="darkGray" style={{ marginRight: 8 }}>
                showing {selectedInsightsRow.transactions_count} transactions
                for
              </Text>
              <Label>
                <span style={{ marginRight: 8 }}>
                  {selectedInsightsRow.transaction_category_name}
                </span>
                <IconButton
                  onClick={onTransactionsExitClick}
                  name="close"
                  icon={regular('close')}
                  size="xl"
                />
              </Label>
            </GridRowColumn>

            <GridRowColumn>
              <Table unstackable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      width={isMobile ? 11 : 4}
                      textAlign="left"
                    >
                      <Text as="eyebrow" color="darkGray">
                        Date
                      </Text>
                    </Table.HeaderCell>
                    <Table.HeaderCell width={isMobile ? 4 : 5} textAlign="left">
                      <Text as="eyebrow" color="darkGray">
                        Description
                      </Text>
                    </Table.HeaderCell>
                    {!isMobile && (
                      <Table.HeaderCell width={4} textAlign="right">
                        <Text as="eyebrow" color="darkGray">
                          Category
                        </Text>
                      </Table.HeaderCell>
                    )}
                    <Table.HeaderCell
                      width={isMobile ? 1 : 3}
                      textAlign="right"
                    >
                      <Text as="eyebrow" color="darkGray">
                        Amount
                      </Text>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <ExpensesBreakdownTransactions
                    currentTransactions={currentTransactions}
                    transactionCategoryName={
                      selectedInsightsRow.transaction_category_name
                    }
                  />
                </Table.Body>
              </Table>
              <GridRowColumn short style={{ marginTop: 0 }}>
                <Divider />
              </GridRowColumn>
            </GridRowColumn>
          </>
        )
      ) : (
        <>
          <Grid.Row
            className="short"
            verticalAlign="middle"
            style={{ paddingLeft: 12 }}
          >
            <Grid.Column>
              <InsightsDividedCard
                columns={[
                  {
                    content: (
                      <Dropdown
                        variant="checked"
                        value={yearFilter}
                        options={filteredYearOptions}
                        onChange={handleYearFilterChange}
                        fullWidth
                      />
                    ),
                  },
                  {
                    content: (
                      <Dropdown
                        variant="checked"
                        value={monthFilter}
                        options={filteredMonthOptions}
                        onChange={handleMonthFilterChange}
                        dividerAfter={[0]}
                        fullWidth
                      />
                    ),
                  },
                  {
                    content: (
                      <Dropdown
                        variant="checked"
                        value={categoryFilter}
                        options={getCategoryDropdownOptionsForInsightsType(
                          insightsType
                        )}
                        onChange={handleCategoryFilterChange}
                        dividerAfter={
                          insightsRows?.length
                            ? [insightsType === INSIGHTS_TYPE.EXPENSES ? 1 : 0]
                            : undefined
                        }
                        fullWidth
                      />
                    ),
                    isLastColumn: true,
                  },
                ]}
                type={DropdownType.dropdown}
              />
            </Grid.Column>
          </Grid.Row>
          {queryLoading ? (
            <GridRowColumn style={{ minHeight: 400 }}>
              <Loader loading />
            </GridRowColumn>
          ) : isYearOptionsEmpty && !shouldDisplaySampleData ? (
            displayYearOptionsEmptyMessage(insightsType)
          ) : error ? (
            displayErrorMessage()
          ) : insightsRows.length === 0 ? (
            displayMonthEmptyMessage(insightsType)
          ) : (
            <Table unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={isMobile ? 10 : 5}>
                    <Text as="eyebrow" color="darkGray">
                      Category
                    </Text>
                  </Table.HeaderCell>
                  {!isMobile && <Table.HeaderCell width={3} />}
                  {!isMobile && (
                    <Table.HeaderCell width={2} textAlign="center">
                      <Text as="eyebrow" color="darkGray">
                        PERCENT OF TOTAL
                      </Text>
                    </Table.HeaderCell>
                  )}
                  <Table.HeaderCell width={3} textAlign="center">
                    <Text as="eyebrow" color="darkGray">
                      AMOUNT
                    </Text>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3} textAlign="center">
                    {!isMobile && (
                      <Text as="eyebrow" color="darkGray">
                        TRANSACTIONS
                      </Text>
                    )}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {currentPageInsightsRows.map((currentPageInsightsRow) => (
                  <InsightsRow
                    key={currentPageInsightsRow.transaction_category_id}
                    insightsRow={currentPageInsightsRow}
                    isTopExpense={isTopExpense(
                      topExpenseCategoryIds,
                      currentPageInsightsRow.transaction_category_id
                    )}
                    onTransactionsClick={() =>
                      handleSelectedExpenseBreakdown(currentPageInsightsRow)
                    }
                    insightsType={insightsType}
                  />
                ))}
              </Table.Body>
            </Table>
          )}
        </>
      )}
      <GridRowColumn short>
        <Text color="darkGray" as="bodySm" textAlign="center">
          Transactions shown are the most recent categorizations by your
          bookkeeper. If you see transaction that needs to be recategorized, go
          to the{' '}
          <Link size="small" to="/transactions">
            Transactions
          </Link>{' '}
          tab.
        </Text>
      </GridRowColumn>
      <GridRowColumn short>
        {pages > 1 && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination
              currentPage={currentPage}
              onPageClick={handlePageChange}
              pages={selectedInsightsRow ? transactionsPages : pages}
            />
          </div>
        )}
      </GridRowColumn>
    </>
  )
}
export default InsightsBreakdownTable
