import { useState, useEffect, FC } from 'react'
import { Transition } from 'semantic-ui-react'

import { Alert } from '../../../components/BaseComponents'

interface UploadMessageProps {
  content: string
  fadeAfterMS?: number
  success: boolean
}

const UploadMessage: FC<UploadMessageProps> = ({
  content,
  fadeAfterMS,
  success,
}) => {
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    if (fadeAfterMS) {
      setTimeout(() => setVisible(false), fadeAfterMS)
    }
  }, [fadeAfterMS])

  let el

  if (success) {
    el = <Alert type="info">{content}</Alert>
  } else {
    el = <Alert type="error">{content}</Alert>
  }

  if (fadeAfterMS) {
    el = (
      <Transition visible={visible} animation="fade" duration={500}>
        <div style={{ display: 'flex' }}>{el}</div>
      </Transition>
    )
  }

  return el
}

export default UploadMessage
