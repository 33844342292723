import { useCallback, useEffect, useMemo, useState } from 'react'
import { Menu } from 'semantic-ui-react'
import moment from 'moment'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import UserDocumentsTable, {
  GroupByType,
} from '../../UserDocuments/sharedComponents/Table/UserDocumentsTable'
import { UserDocument } from '../../UserDocuments/userDocuments.slice'
import { RowComponentProps } from '../../UserDocuments/sharedComponents/Table/UserDocumentsTableRow'
import {
  sortDocumentsByFileNameAsc,
  sortDocumentsByNameAscWithinCreatedAtDesc,
  sortDocumentsByNameAscWithinStatementDateDesc,
} from '../../UserDocuments/service'
import {
  Card,
  Checkbox,
  Dropdown,
  Icon,
  Link,
  Popup,
  Table,
} from '../../../components/BaseComponents'
import {
  generateMonthlyStatementOptions,
  UploadDocumentType,
} from '../../../constants/businessConstants'
import { compareArrays, documentCategoryOptions } from './helpers'
import DeleteDocumentModal from './DeleteDocumentModal'
import MoveDocumentModal from './MoveFileModal'
import RenameDocumentModal from './RenameDocumentModal'
import { getUserDocumentCategoriesById } from '../UserDocumentCategories/userDocumentCategories.selectors'
import {
  adminUpdateUserDocument,
  adminUpdateStatement,
} from '../../../actions/admin/userDocumentsActions'
import { getUserByFinancialProfileId } from '../../../selectors/user.selectors'
import { useReselector } from '../../../utils/sharedHooks'
import {
  selectAdminOrUserFinancialAccountOptions,
  selectAdminOrUserGroupedFinancialAccountOptions,
} from '../../../selectors/financeSelectors'
import { fetchUserFinancialAccounts } from '../../../actions/admin/adminFinancialAccountActions'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { GroupedDropdown } from '../../../components/shared/GroupedDropdown'
import { FEATURE_FLAG_KEYS } from '../../OpenFeature'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { User } from '../../../reducers/auth/userReducer'
import { useAppDispatch } from '../../../utils/typeHelpers'

const AdminRowStartCells = ({
  doc,
  onCheck,
}: {
  doc: UserDocument
  onCheck?: (id: number, checked: boolean) => void
}) => {
  const [checked, setChecked] = useState(false)
  return (
    <>
      <Table.Cell>
        <Checkbox
          checked={checked}
          onClick={() => {
            onCheck?.(doc.id, !checked)
            setChecked((checkedBoxes) => !checkedBoxes)
          }}
        />
      </Table.Cell>
      <Table.Cell>
        {moment(doc.createdAt).format(DATE_FORMATS.DISPLAY_SHORT)}
      </Table.Cell>
      <Table.Cell>
        {doc.signedUrl ? (
          <Link href={doc.signedUrl} newPage>
            {doc.fileDescription}
          </Link>
        ) : (
          doc.fileDescription
        )}
      </Table.Cell>
    </>
  )
}

const AdminRowActions = ({
  documentType,
  doc,
}: {
  documentType: UploadDocumentType
  doc: UserDocument
}) => {
  const [deleteDocumentModalOpen, setDeleteDocumentModalOpen] = useState(false)
  const [moveDocumentModalOpen, setMoveDocumentModalOpen] = useState(false)
  const [renameDocumentModalOpen, setRenameDocumentModalOpen] = useState(false)

  return (
    <Table.Cell>
      <Popup
        on="click"
        trigger={
          <Icon
            icon={solid('ellipsis-vertical')}
            style={{ cursor: 'pointer' }}
          />
        }
        content={
          <Menu secondary vertical>
            {documentType !== UploadDocumentType.RECEIPT && (
              <Menu.Item
                name="Move File"
                onClick={() => setMoveDocumentModalOpen(true)}
              >
                Move File
                <Icon icon={solid('upload')} />
              </Menu.Item>
            )}
            <Menu.Item
              name="Delete File"
              onClick={() => setDeleteDocumentModalOpen(true)}
            >
              Delete File
              <Icon icon={solid('trash')} />
            </Menu.Item>
            <Menu.Item
              name="Rename File"
              onClick={() => setRenameDocumentModalOpen(true)}
            >
              Rename File
              <Icon icon={regular('edit')} />
            </Menu.Item>
          </Menu>
        }
      />
      <DeleteDocumentModal
        modalOpen={deleteDocumentModalOpen}
        setModalOpen={setDeleteDocumentModalOpen}
        document={doc}
      />
      <MoveDocumentModal
        modalOpen={moveDocumentModalOpen}
        setModalOpen={setMoveDocumentModalOpen}
        document={doc}
        fileType={documentType}
      />
      <RenameDocumentModal
        modalOpen={renameDocumentModalOpen}
        setModalOpen={setRenameDocumentModalOpen}
        document={doc}
      />
    </Table.Cell>
  )
}

const AdminMultiBankStatementRow = ({
  document,
  onCheck,
}: RowComponentProps) => {
  const dispatch = useAppDispatch()
  const user: User | undefined = useReselector(
    getUserByFinancialProfileId,
    document.financialProfileId
  )

  // if Bank Statement modal fetch financial account information when needed
  useEffect(() => {
    if (user?.id) {
      dispatch(fetchUserFinancialAccounts(user.id, true))
    }
  }, [user?.id, dispatch])

  const financialAccountOptions = useReselector(
    selectAdminOrUserGroupedFinancialAccountOptions,
    user?.id
  )

  const handleStatementMonthChange = useCallback(
    (statementMonth?: string) =>
      document.id &&
      dispatch(
        adminUpdateStatement(document.id, {
          statementMonth,
          statement: {
            statementMonth,
          },
        })
      ),
    [dispatch, document]
  )

  const groupedDropdownValue = useMemo(() => {
    if (!document.statement?.statementFinancialAccounts?.length) {
      return []
    }
    return document.statement.statementFinancialAccounts?.map(
      (account) => account.financialAccountId
    )
  }, [document.statement])

  const handleStatementFinancialAccountChange = useCallback(
    (newFinancialAccountIds: (string | number)[]) => {
      if (!document.statement?.id) return
      const statementFinancialAccounts =
        document.statement.statementFinancialAccounts || []
      const statementFinancialAccountIds = statementFinancialAccounts.map(
        (account) => account.financialAccountId
      )
      const { newVals, removedVals } = compareArrays(
        statementFinancialAccountIds,
        newFinancialAccountIds
      )

      /*
        newFinancialAccountIds should all be numbers but this ensures that only numbers 
        are passed to the backend, which expects an array of IDs 
      */
      const accountsToCreate = newVals.filter(
        (val) => typeof val === 'number'
      ) as number[]
      const accountsToDelete = removedVals.filter(
        (val) => typeof val === 'number'
      ) as number[]

      dispatch(
        adminUpdateStatement(document.id, {
          statementFinancialAccounts: {
            accountsToCreate,
            accountsToDelete,
          },
        })
      )
    },
    [dispatch, document]
  )

  return (
    <Table.Row key={document.id}>
      <AdminRowStartCells doc={document} onCheck={onCheck} />
      <Table.Cell>
        <Dropdown
          fullWidth
          placeholder="Select Statement Month"
          value={document.statement?.statementMonth || undefined}
          options={generateMonthlyStatementOptions()}
          onChange={handleStatementMonthChange}
          clearable
        />
      </Table.Cell>
      <Table.Cell style={{ width: 50 }}>
        <GroupedDropdown
          value={groupedDropdownValue}
          onChange={handleStatementFinancialAccountChange}
          options={financialAccountOptions}
          allowOneParent
        />
      </Table.Cell>
      <AdminRowActions
        documentType={UploadDocumentType.STATEMENT}
        doc={document}
      />
    </Table.Row>
  )
}

const AdminBankStatementRow = ({ document, onCheck }: RowComponentProps) => {
  const dispatch = useAppDispatch()
  const user = useReselector(
    getUserByFinancialProfileId,
    document.financialProfileId
  )

  // if Bank Statement modal fetch financial account information when needed
  useEffect(() => {
    if (user?.id) {
      dispatch(fetchUserFinancialAccounts(user.id, true))
    }
  }, [user?.id, dispatch])

  const financialAccountOptions = useReselector(
    selectAdminOrUserFinancialAccountOptions,
    user?.id
  )

  const handleStatementMonthChange = useCallback(
    (statementMonth?: string) =>
      user?.id &&
      dispatch(
        adminUpdateUserDocument(document.id, {
          statementMonth: statementMonth || null,
        })
      ),
    [dispatch, user?.id, document.id]
  )

  const handleStatementFinancialAccountChange = useCallback(
    (statementFinancialAccountId?: number) =>
      user?.id &&
      dispatch(
        adminUpdateUserDocument(document.id, {
          statementFinancialAccountId: statementFinancialAccountId || null,
        })
      ),
    [dispatch, user?.id, document.id]
  )

  return (
    <Table.Row key={document.id}>
      <AdminRowStartCells doc={document} onCheck={onCheck} />
      <Table.Cell>
        <Dropdown
          fullWidth
          placeholder="Select Document Category"
          value={document.statementMonth || undefined}
          options={generateMonthlyStatementOptions()}
          onChange={handleStatementMonthChange}
          clearable
        />
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          fullWidth
          placeholder="Select Financial Account"
          value={document.statementFinancialAccountId || undefined}
          options={financialAccountOptions}
          onChange={handleStatementFinancialAccountChange}
          clearable
        />
      </Table.Cell>
      <AdminRowActions
        documentType={UploadDocumentType.STATEMENT}
        doc={document}
      />
    </Table.Row>
  )
}

const AdminReceiptRow = ({ document, onCheck }: RowComponentProps) => (
  <Table.Row key={document.id}>
    <AdminRowStartCells doc={document} onCheck={onCheck} />
    <Table.Cell>
      {document.transactionId ? document.transactionId : null}
    </Table.Cell>
    <AdminRowActions documentType={UploadDocumentType.RECEIPT} doc={document} />
  </Table.Row>
)

const AdminOtherRow = ({ document, onCheck }: RowComponentProps) => (
  <Table.Row key={document.id}>
    <AdminRowStartCells doc={document} onCheck={onCheck} />
    <Table.Cell>{document.customerDescription}</Table.Cell>
    <Table.Cell>
      {document.needsReview && <Icon icon={regular('edit')} />}
    </Table.Cell>
    <AdminRowActions documentType={UploadDocumentType.OTHER} doc={document} />
  </Table.Row>
)

// Document type is needed but both the Tax and Incorporation rows are identical otherwise so use a generator
const makeAdminTaxIncorporationRow =
  (documentType: UploadDocumentType) =>
  ({ document, onCheck }: RowComponentProps) => {
    const dispatch = useAppDispatch()
    const user = useReselector(
      getUserByFinancialProfileId,
      document.financialProfileId
    )
    const documentCategories = useReselector(getUserDocumentCategoriesById)
    const filteredDocumentCategories = useMemo(
      () => documentCategoryOptions(documentCategories, documentType, true),
      [documentCategories]
    )

    const handleDocumentCategoryChange = useCallback(
      (userDocumentCategoryId: number | undefined) =>
        user?.id &&
        dispatch(
          adminUpdateUserDocument(document.id, {
            userDocumentCategoryId:
              userDocumentCategoryId === undefined
                ? null
                : userDocumentCategoryId,
          })
        ),
      [dispatch, document.id, user?.id]
    )

    // Admins can't select archived categories but still show the category if it's already set
    const placeholderText =
      document.userDocumentCategoryId &&
      documentCategories[document.userDocumentCategoryId]?.archivedAt
        ? documentCategories[document.userDocumentCategoryId]?.title
        : 'Select Document Category'

    return (
      <Table.Row key={document.id}>
        <AdminRowStartCells doc={document} onCheck={onCheck} />
        <Table.Cell>
          <Dropdown
            search
            fullWidth
            placeholder={placeholderText}
            value={document.userDocumentCategoryId || undefined}
            // the undefined value should be in documentCategoryOptions but a lot of code uses it
            options={[
              { value: undefined, text: '(none)' },
              ...filteredDocumentCategories,
            ]}
            onChange={handleDocumentCategoryChange}
          />
        </Table.Cell>
        <AdminRowActions documentType={documentType} doc={document} />
      </Table.Row>
    )
  }

const ROW_COMP_MAPPING = {
  statement: AdminBankStatementRow,
  receipt: AdminReceiptRow,
  other: AdminOtherRow,
  tax: makeAdminTaxIncorporationRow(UploadDocumentType.TAX),
  incorporation: makeAdminTaxIncorporationRow(UploadDocumentType.INCORPORATION),
  bookkeeping: makeAdminTaxIncorporationRow(UploadDocumentType.BOOKKEEPING),
  multiStatement: AdminMultiBankStatementRow,
}

const TABLE_OPTIONS = {
  statement: {
    sortFn: sortDocumentsByNameAscWithinStatementDateDesc,
    groupBy: GroupByType.StatementYear,
  },
  tax: {
    sortFn: sortDocumentsByNameAscWithinCreatedAtDesc,
    groupBy: GroupByType.CategoryYear,
  },
  incorporation: {
    sortFn: sortDocumentsByFileNameAsc,
    groupBy: GroupByType.None,
  },
  receipt: {
    sortFn: sortDocumentsByNameAscWithinCreatedAtDesc,
    groupBy: GroupByType.MonthWithinYear,
  },
  other: {
    sortFn: sortDocumentsByNameAscWithinCreatedAtDesc,
    groupBy: GroupByType.None,
  },
  bookkeeping: {
    sortFn: sortDocumentsByNameAscWithinCreatedAtDesc,
    groupBy: GroupByType.CreatedAtYear,
  },
}

const AdminDocumentTableWrapper = ({
  documentType,
  documents,
  onCheck,
}: {
  documentType: UploadDocumentType
  documents: UserDocument[]
  onCheck: (id: number, checked: boolean) => void
}) => {
  const releaseStatementUpload = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.multiStatementUpload,
    false
  )

  const columns = useMemo(() => {
    const tableColumns = [
      { sortBy: null, title: '', width: 2 as const },
      { sortBy: 'createdAt', title: 'Date Uploaded' },
      { sortBy: 'fileName', title: 'File Name' },
    ]

    if (documentType === UploadDocumentType.STATEMENT) {
      tableColumns.push({ sortBy: 'tag', title: 'Statement Month' })
      tableColumns.push({ sortBy: 'account', title: 'Financial Account' })
    } else if (documentType === UploadDocumentType.RECEIPT) {
      tableColumns.push({ sortBy: 'transactionId', title: 'Transaction ID' })
    } else if (documentType === UploadDocumentType.OTHER) {
      tableColumns.push({ sortBy: 'description', title: 'Description' })
      tableColumns.push({ sortBy: 'needsReview', title: 'Needs Review' })
    } else {
      tableColumns.push({ sortBy: 'tag', title: 'Document Category' })
    }

    return [
      ...tableColumns,
      { sortBy: null, title: 'Actions', width: 2 as const },
    ]
  }, [documentType])

  const type =
    documentType === UploadDocumentType.STATEMENT && releaseStatementUpload
      ? 'multiStatement'
      : documentType

  return (
    <Card>
      <UserDocumentsTable
        columns={columns}
        documents={documents}
        RowComponent={ROW_COMP_MAPPING[type]}
        tableOptions={TABLE_OPTIONS[documentType]}
        onCheck={onCheck}
        fixed={type !== 'multiStatement'}
      />
    </Card>
  )
}

export default AdminDocumentTableWrapper
