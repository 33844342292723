import moment from 'moment'
import { fetchAdminProfitAndLoss } from '../../../../actions/reportActions'
import { TaxFilingUser } from '../../../Taxes/AnnualTaxes/annualTaxFilings.slice'

export const fetchProfitLossCSV = async (
  currentAnnualTaxYear: string,
  user: TaxFilingUser
) => {
  const CSV_START = moment(`${currentAnnualTaxYear}-01-01', 'YYYY-MM-DD`)
  const CSV_END = moment(`${currentAnnualTaxYear}-12-31', 'YYYY-MM-DD`)

  const profitAndLoss = await fetchAdminProfitAndLoss({
    userId: user.id,
    startDate: CSV_START,
    endDate: CSV_END,
  })()
  const pnlCsv = []

  pnlCsv.push([
    'Profit and Loss Statement for:',
    '',
    `${user.firstName} ${user.lastName}`,
  ])

  pnlCsv.push(['', '', ''])

  pnlCsv.push(['Income', '', ''])
  pnlCsv.push(['Category', 'Account Type', 'Amount in Dollars'])

  if (profitAndLoss?.profits) {
    profitAndLoss.profits.forEach((profit) => {
      pnlCsv.push([profit?.name, profit?.account_type, profit?.sum])
    })
  }

  pnlCsv.push(['>> Income Total', '...', profitAndLoss.profitSum.toString()])
  pnlCsv.push(['', '', ''])

  pnlCsv.push(['Expenses', '', ''])

  pnlCsv.push(['Category', 'Account Type', 'Amount in Dollars'])
  if (profitAndLoss?.expenses) {
    profitAndLoss.expenses.forEach((expense) => {
      pnlCsv.push([expense?.name, expense?.account_type, expense?.sum])
    })
  }
  pnlCsv.push(['', '', ''])
  pnlCsv.push([
    '>> Expenses Total',
    '...',
    profitAndLoss.expensesSum.toString(),
  ])
  const netProfit =
    Number(profitAndLoss.profitSum) + Number(profitAndLoss.expensesSum) || 0
  pnlCsv.push(['', '', ''])
  pnlCsv.push(['Net Profit', '...', netProfit.toString()])
  return pnlCsv
}
