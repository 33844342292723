import { Transaction } from '../../reducers/admin/allTransactions.slice'

export enum JournalEntryType {
  DEBIT = 'debit',
  CREDIT = 'credit',
}

export interface JournalEntryTransaction {
  date?: string
  description?: string
  id?: number
  journalEntries: JournalEntry[]
  type: 'personal' | 'business' | 'split' | null | 'without_transaction'
}

export interface JournalEntry {
  id: number
  userId: number
  transactionId?: number
  transactionDescription?: string
  transactionCategoryId: number
  journalEntryDate: string
  details: string
  automated: boolean
  journalEntryLines: JournalEntryLineItem[]
  lastUpdatedBy?: number
  transaction?: Transaction
}

export interface JournalEntryLineItem {
  id: number
  amountInCents: number
  transactionCategoryId?: number | null
  financialAccountId?: number | null
  journalEntryId: number
  entryType: JournalEntryType
  createdAt: string
  deletedAt: string | null
  updatedAt: string
}

export interface NewJournalEntryLineItem extends Partial<JournalEntryLineItem> {
  debitAmount: string
  creditAmount: string
  accountOrCategoryId: string | null
}

export interface SubmitJournalEntryBody {
  userId: number
  transactionId?: number
  journalEntryDate?: string
  details?: string
  journalEntryLines?: Partial<JournalEntryLineItem>[]
  journalEntryLinesToDelete?: number[]
  lastUpdatedBy?: number
}

export interface InitialJournalEntryFormValues {
  journalEntryDate: string
  details?: string
  journalEntryLines: NewJournalEntryLineItem[]
  transactionId: string | null
  type: string | null
}

export interface GeneralLedgerLineItem extends JournalEntryLineItem {
  transactionDescription?: string
  journalEntryDetails?: string
  journalEntryDate?: string
}

export interface LedgerAccount {
  financialAccountId: number | null
  transactionCategoryId: number | null
  sumDebits: number
  sumCredits: number
  balanceBroughtDown?: {
    sumDebits: number
    sumCredits: number
  }
  sum: number
  journalEntryLines: GeneralLedgerLineItem[]
}
