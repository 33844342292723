import { useCallback, useEffect, useMemo, useState } from 'react'
import { DateTime } from 'luxon'

import {
  selectIsNotesTab,
  selectTransactionFilters,
} from '../../../reducers/finances/transactionFiltersReducer'
import { UserWithAdminInfo } from '../../../reducers/admin/v2/allUsersReducerV2'
import {
  selectCurrentAnnualTaxYear,
  selectTaxDetailsByYear,
} from '../../../features/Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import {
  TransactionFilter,
  countTransactions,
  fetchPaginatedTransactions,
  fetchTransaction,
} from '../../../actions/admin/v2/adminTransactionActions'
import { fetchUserFinancialAccounts } from '../../../actions/admin/adminFinancialAccountActions'
import {
  DATE_FORMATS_LUXON,
  formatISOFromUTC,
  convertInputToIso,
} from '../../../utils/dateHelpers'
import {
  formatAccountName,
  isTransactionLocked,
} from '../../../features/Transactions/helpers'
import {
  useAsync,
  useAsyncCallback,
  useReselector,
} from '../../../utils/sharedHooks'
import { filterNulls, useAppDispatch } from '../../../utils/typeHelpers'
import { PrismaCursorPagination } from '../../../utils/fetchHelpers'
import { centsToDollars } from '../../../utils/currencyHelpers'
import { Alert, Text } from '../../BaseComponents'
import {
  PaginationStyle,
  useCursorPaginatedTable,
} from '../PaginatedTable/CursorPaginatedTable'
import { OrderBy, TypeColumn } from '../PaginatedTable/AdminBaseTable'
import CurrencyFormatLabel from '../../shared/CurrencyFormatLabel'
import TransactionTypeButton from '../shared/TransactionTypeButton'
import TransactionCategoryDropdown from '../shared/TransactionCategoryDropdown'
import TransactionFilters from '../TransactionFilters'
import RowActions from './RowActions'
import RowDrawer from './RowDrawer'
import RowNotes from './RowNotes'
import RowSelector from './RowSelector'
import RowTags from './RowTags'
import BulkTransactionActions, {
  OnActionParams,
} from '../shared/BulkTransactionActions'
import { getSelectedTransactionIds } from '../../../reducers/admin/selectedTransactionsReducer'
import TransactionHeaderCheckbox from '../shared/TransactionHeaderCheckbox'
import AdminExportTransactionCsv from '../../AdminExportTransactionCsv'

export type TransactionTableProps = {
  user: UserWithAdminInfo
}

export const PaginatedTransactionTable = ({ user }: TransactionTableProps) => {
  const dispatch = useAppDispatch()
  const transactionFilters = useReselector(selectTransactionFilters)
  const isNotesTab = useReselector(selectIsNotesTab)
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const annualTaxDetails = useReselector(selectTaxDetailsByYear, currentTaxYear)
  const [filter, setFilter] = useState<TransactionFilter>({})
  const [selected, setSelected] = useState<Transaction[]>([])
  const selectedTransactionKeys = useReselector(getSelectedTransactionIds)

  const {
    loading: noteCountPending,
    error: noteCountError,
    result: noteCount,
  } = useAsync(
    useCallback(
      async () => (isNotesTab ? await countTransactions(filter) : 0),
      [filter, isNotesTab]
    )
  )

  useEffect(() => {
    // Needed to fetch financial accounts for filter dropdown
    dispatch(fetchUserFinancialAccounts(user.id))
  }, [dispatch, user.id])

  const reviewDate =
    formatISOFromUTC(
      annualTaxDetails?.taxQuestionnaireDueDates?.form_1040.startAt,
      DATE_FORMATS_LUXON.GUSTO_SUBMIT
    ) || DateTime.utc().toFormat(DATE_FORMATS_LUXON.GUSTO_SUBMIT)

  const cols: TypeColumn<Transaction>[] = [
    {
      header: {
        key: 'selector',
        render: () => <TransactionHeaderCheckbox transactions={transactions} />, // skipcq: JS-0357
      },
      accessor: null,
      render: (row) => <RowSelector row={row} user={user} />,
    },
    {
      header: 'Id',
      accessor: 'id',
      width: '1',
    },
    {
      header: 'Date',
      accessor: null,
      render: (row) =>
        row.date
          ? DateTime.fromISO(row.date).toFormat(
              DATE_FORMATS_LUXON.DISPLAY_SHORT
            )
          : '',
      width: '2',
      sortColumn: 'createdAt',
    },
    {
      header: 'Account',
      accessor: null,
      render: (row) => (
        <span>
          {row.financialAccount
            ? formatAccountName(row.financialAccount)
            : 'N/A'}
        </span>
      ),
      width: '3',
      sortColumn: 'financialAccount',
    },
    {
      header: 'Amount',
      accessor: null,
      render: (row) => (
        <CurrencyFormatLabel value={centsToDollars(row.amountInCents)} />
      ),
      width: '1',
      sortColumn: 'amountInCents',
    },
    {
      header: 'Description',
      accessor: 'description',
      width: '6',
      sortColumn: 'description',
    },
    {
      header: 'Notes',
      accessor: null,
      render: (row) => <RowNotes transaction={row} />,
      width: '5',
      sortColumn: 'notes',
    },
    {
      header: 'Expense Type',
      accessor: null,
      render: (row) => (
        <TransactionTypeButton
          transaction={row}
          onUpdate={(transaction) => updateTransactions([transaction])} // skipcq: JS-0357
        />
      ),
      width: '4',
    },
    {
      header: 'Category',
      accessor: null,
      render: (row) => (
        <TransactionCategoryDropdown
          transaction={row}
          onUpdate={(transaction) => updateTransactions([transaction])} // skipcq: JS-0357
        />
      ),
      width: '4',
      sortColumn: 'transactionCategory',
    },
    {
      header: 'Tags',
      accessor: null,
      render: (row) => <RowTags row={row} />,
      width: '4',
    },
    {
      header: 'Actions',
      accessor: null,
      render: (row) => (
        <RowActions
          row={row}
          onTransactionUpdate={onTransactionUpdate.callback} // skipcq: JS-0357
          onTransactionDelete={
            (transaction) => removeTransactions([transaction]) // skipcq: JS-0357
          }
        />
      ),
      width: '3',
    },
  ]

  const relationsToSelect = useMemo(
    () => ['financialAccount', 'receiptCount'],
    []
  )

  const fetchData = useCallback(
    (pagination: PrismaCursorPagination, orderBy?: OrderBy) => {
      const asyncFetchData = () => {
        const transactionCategoryId =
          transactionFilters.transactionCategoryId &&
          transactionFilters.transactionCategoryId !== '1'
            ? Number(transactionFilters.transactionCategoryId)
            : undefined
        const transactionsType =
          transactionFilters.transactionsType !== 'all'
            ? transactionFilters.transactionsType
            : undefined
        const startDate = convertInputToIso(
          transactionFilters.startDate ?? undefined,
          { startOf: 'day' }
        )
        const endDate = convertInputToIso(
          transactionFilters.endDate ?? undefined,
          { endOf: 'day' }
        )

        const filter: TransactionFilter = {
          userId: user.id,
          transactionsType,
          transactionCategoryId,
          financialAccountId:
            transactionFilters.financialAccountId ?? undefined,
          startDate: startDate && !isNotesTab ? startDate : undefined,
          endDate: endDate && !isNotesTab ? endDate : undefined,
          notesLastUpdatedAtStart:
            startDate && isNotesTab ? startDate : undefined,
          notesLastUpdatedAtEnd: endDate && isNotesTab ? endDate : undefined,
          includePersonal: transactionFilters.transactionsType === 'excluded',
          descriptionSearch: transactionFilters.description ?? undefined,
          notesSearch: transactionFilters.notes ?? undefined,
        }

        if (transactionsType === 'recentlyUpdated') {
          filter.currentTaxYear = currentTaxYear
          filter.lastReviewDate = reviewDate
        }

        setFilter(filter)

        return fetchPaginatedTransactions({
          filter,
          pagination,
          orderBy,
          select: relationsToSelect,
        })
      }

      return asyncFetchData()
    },
    [
      user.id,
      transactionFilters,
      currentTaxYear,
      reviewDate,
      isNotesTab,
      relationsToSelect,
    ]
  )

  const tableRowPropsConfig = {
    negative: (row: Transaction) => !row.type,
    positive: (row: Transaction) => row.confirmedAt !== null,
    warning: (row: Transaction) => Boolean(row.type && !row.confirmedAt),
    disabled: (row: Transaction) =>
      isTransactionLocked({
        date: row.date,
        userLockedYear: user.booksLockedForUserYear,
        adminLockedYear: user.booksLockedForAdminYear,
        isAdmin: true,
      }),
    renderDrawer: (row: Transaction, closeDrawer: () => void) => (
      <RowDrawer
        row={row}
        colSpan={cols.length + 1}
        onTransactionUpdate={onTransactionUpdate.callback} // skipcq: JS-0357
        closeDrawer={closeDrawer}
      />
    ),
  }

  const {
    renderTable,
    items: transactions,
    getItem: getTransaction,
    updateItems: updateTransactions,
    removeItems: removeTransactions,
    fetchError,
    resetPagination,
  } = useCursorPaginatedTable<Transaction>({
    columns: cols,
    tableRowPropsConfig,
    fetchData,
    paginationStyle: PaginationStyle.LoadMore,
    paginationProps: { take: 50 },
    wrapperStyle: { minHeight: '400px' },
    initialOrderBy: { column: 'createdAt', direction: 'descending' },
  })

  // Some bulk actions need the full transaction object
  useEffect(() => {
    const selectTransactions = filterNulls(
      selectedTransactionKeys.map(getTransaction)
    )
    setSelected(selectTransactions)
  }, [selectedTransactionKeys, getTransaction])

  const onTransactionUpdate = useAsyncCallback(
    useCallback(
      async (id: number) => {
        const transaction = await fetchTransaction({
          id,
          select: relationsToSelect,
        })
        if (transaction) {
          updateTransactions([transaction])
        }
      },
      [updateTransactions, relationsToSelect]
    ),
    false
  )

  const onBulkAction = (params: OnActionParams) => {
    if (params.type === 'delete') {
      removeTransactions(params.transactionIds)
    } else {
      updateTransactions(params.transactions)
    }
  }

  return (
    <div style={{ marginBottom: '10px' }}>
      <AdminExportTransactionCsv fetchData={fetchData} />

      <TransactionFilters
        userId={user.id}
        onFilterChange={() => resetPagination()}
        supportV2
      />
      <BulkTransactionActions
        selectedTransactions={selected}
        onAction={onBulkAction}
      />

      {isNotesTab && !noteCountPending && (
        <Text as="h3">
          Notes left after confirmation{' '}
          {noteCount && noteCount > 0 ? `(${noteCount})` : ''}
        </Text>
      )}

      {(fetchError || noteCountError || onTransactionUpdate.error) && (
        <Alert
          type="error"
          title={
            fetchError?.message ??
            noteCountError?.message ??
            onTransactionUpdate?.error?.message
          }
        />
      )}
      {renderTable()}
    </div>
  )
}
