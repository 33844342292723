import { Grid, Image } from 'semantic-ui-react'

import { Text } from '../../../../components/BaseComponents'
import { ACTION_ITEM_TYPE } from './UserActionItemTabPane'

const UserActionItemEmptyState = ({
  tabPaneType,
}: {
  tabPaneType: ACTION_ITEM_TYPE
}) => {
  if (tabPaneType === ACTION_ITEM_TYPE.ACTIVE) {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column style={{ display: 'flex', justifyContent: 'center' }}>
            <Image
              src="https://heard-images.s3.us-west-1.amazonaws.com/assets/celebration_confentti.png"
              style={{ height: 120 }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Text as="h1" textAlign="center">
              You&apos;re done!
            </Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="short">
          <Grid.Column>
            <Text as="bodyLg" textAlign="center">
              You&apos;ve finished all your to dos!
            </Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row />
      </Grid>
    )
  } else {
    return (
      <Grid>
        <Grid.Row />
        <Grid.Row>
          <Grid.Column>
            <Text as="bodyLg" textAlign="center">
              You don&apos;t have any{' '}
              {tabPaneType === ACTION_ITEM_TYPE.UPCOMING
                ? 'upcoming'
                : 'complete'}{' '}
              to dos.
            </Text>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default UserActionItemEmptyState
