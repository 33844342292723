import { useParams } from 'react-router-dom'
import { Divider, Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import {
  FormikInput,
  FormikLabelError,
  FormikRadioToggleButton,
  GridRowColumn,
  Text,
  getFieldName,
  makeNumberSchema,
  makeReqBoolSchema,
  Popup,
} from '../../../../../../components/BaseComponents'
import { useReselector } from '../../../../../../utils/sharedHooks'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../../taxChecklist.selectors'
import { TaxListQuestionId } from '../../service'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { IncomeAndLiabilitiesStepProps } from '.'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import DocumentAddedAlert from '../../Shared/SpecialtyAlerts'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { UPDATE_USER_EOY_REVIEW_PROGRESS_KEY } from '../../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { UPSERT_USER_TAX_QUESTIONNAIRE_KEY } from '../../taxChecklistQuestion.actions'

export const lessCommonScenarioFollowUpQuestionIds = [
  TaxListQuestionId.crypto_sold,
  TaxListQuestionId.crypto_statement,
  TaxListQuestionId.number_of_crypto_statements,
  TaxListQuestionId.number_of_k1s_sprop,
]
const LessCommonScenariosPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: IncomeAndLiabilitiesStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()

  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    UPDATE_USER_EOY_REVIEW_PROGRESS_KEY,
  ])

  const cryptoTxnsResponses = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.crypto_made_txns,
    Number(formId)
  )

  const incomeFromK1Responses = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.k1_income,
    Number(formId)
  )

  const questionCryptoSold = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.crypto_sold,
    taxYear
  )
  const responseCryptoSold = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.crypto_sold,
    Number(formId)
  )

  const questionCryptoStatement = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.crypto_statement,
    taxYear
  )
  const responseCryptoStatement = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.crypto_statement,
    Number(formId)
  )

  const howManyCryptoStatements = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.number_of_crypto_statements,
    taxYear
  )
  const responseHowManyCryptoStatements = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_of_crypto_statements,
    Number(formId)
  )

  const questionHowManyK1s = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.number_of_k1s_sprop,
    taxYear
  )
  const responseHowManyK1s = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_of_k1s_sprop,
    Number(formId)
  )

  const formik = useFormik({
    initialValues: {
      cryptoSold: responseCryptoSold?.[0]?.value,
      cryptoStatement: responseCryptoStatement?.[0]?.value,
      howManyK1s: responseHowManyK1s?.[0]?.value,
      howManyCryptoStatements: responseHowManyCryptoStatements?.[0]?.value,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      if (values.cryptoSold !== responseCryptoSold?.[0]?.value) {
        responseData.push({
          id: responseCryptoSold?.[0]?.id,
          value: values.cryptoSold,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.crypto_sold,
        })
      }
      if (values.cryptoStatement !== responseCryptoStatement?.[0]?.value) {
        responseData.push({
          id: responseCryptoStatement?.[0]?.id,
          value: values.cryptoStatement,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.crypto_statement,
        })
      }
      if (
        values.howManyCryptoStatements !==
          responseHowManyCryptoStatements?.[0]?.value ||
        !responseHowManyCryptoStatements?.[0]?.value
      ) {
        responseData.push({
          id: responseHowManyCryptoStatements?.[0]?.id,
          value: Number(values.howManyCryptoStatements ?? 0),
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.number_of_crypto_statements,
        })
      }
      if (values.howManyK1s !== Number(responseHowManyK1s?.[0]?.value)) {
        responseData.push({
          id: responseHowManyK1s?.[0]?.id,
          value: Number(values.howManyK1s ?? 0),
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.number_of_k1s_sprop,
        })
      }
      await goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.lessCommonScenarios1040],
      })
    },
  })

  const { values, submitForm, isSubmitting, isValid } = formik
  const questionsGridConfig = makeGridConfig([8, 14], true)

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn>
          <Text as="display2" textAlign="center">
            Less Common Scenarios
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <Text as="bodyLg" textAlign="center">
            We have a few more questions based upon your selections from the
            previous screen.
          </Text>
        </GridRowColumn>
        {cryptoTxnsResponses?.[0].value && (
          <>
            <GridRowColumn {...questionsGridConfig}>
              <FormikLabelError
                name={getFieldName<typeof values>('cryptoSold')}
                label={questionCryptoSold.question?.text}
                schema={makeReqBoolSchema()}
              />
            </GridRowColumn>
            <Grid.Row className="short" style={{ justifyContent: 'center' }}>
              <Grid.Column width={4} style={{ paddingRight: 0 }}>
                <FormikRadioToggleButton
                  fullWidth
                  name={getFieldName<typeof values>('cryptoSold')}
                  value
                >
                  Yes
                </FormikRadioToggleButton>
              </Grid.Column>
              <Grid.Column width={4}>
                <FormikRadioToggleButton
                  fullWidth
                  name={getFieldName<typeof values>('cryptoSold')}
                  value={false}
                >
                  No
                </FormikRadioToggleButton>
              </Grid.Column>
            </Grid.Row>
            <GridRowColumn short {...questionsGridConfig}>
              <Divider />
            </GridRowColumn>
            <GridRowColumn {...questionsGridConfig}>
              <FormikLabelError
                name={getFieldName<typeof values>('cryptoStatement')}
                label={questionCryptoStatement.question?.text}
                schema={makeReqBoolSchema()}
                afterLabel={
                  <Popup
                    content={
                      <Grid>
                        <GridRowColumn>
                          <Text as="h3">Cryptocurrency</Text>
                        </GridRowColumn>
                        <GridRowColumn short>
                          <Text>
                            Not every crypto company will issue you a 1099-B. If
                            you haven’t received one in time to complete the
                            questionnaire, you’ll export your cryptocurrency
                            transaction history instead. Each company should
                            have a way for you to download your transactions as
                            a CSV file.
                          </Text>
                        </GridRowColumn>
                      </Grid>
                    }
                  />
                }
              />
            </GridRowColumn>
            <Grid.Row className="short" style={{ justifyContent: 'center' }}>
              <Grid.Column width={4} style={{ paddingRight: 0 }}>
                <FormikRadioToggleButton
                  fullWidth
                  name={getFieldName<typeof values>('cryptoStatement')}
                  value
                >
                  Yes
                </FormikRadioToggleButton>
              </Grid.Column>
              <Grid.Column width={4}>
                <FormikRadioToggleButton
                  fullWidth
                  name={getFieldName<typeof values>('cryptoStatement')}
                  value={false}
                >
                  No
                </FormikRadioToggleButton>
              </Grid.Column>
            </Grid.Row>
            {values.cryptoStatement === true && (
              <GridRowColumn short {...questionsGridConfig}>
                <FormikInput
                  name={getFieldName<typeof values>('howManyCryptoStatements')}
                  label={howManyCryptoStatements.question?.text}
                  componentType="number"
                  schema={makeNumberSchema({ allowedDecimals: 0 })}
                  placeholder="Enter number"
                  prefix=""
                  decimalScale={0}
                  fullWidth
                />
              </GridRowColumn>
            )}
            {values.cryptoStatement === false && (
              <GridRowColumn short {...questionsGridConfig}>
                <DocumentAddedAlert>
                  Exported CSV file of cryptocurrency transactions added to
                  checklist
                </DocumentAddedAlert>
              </GridRowColumn>
            )}
            <GridRowColumn short {...questionsGridConfig}>
              <Divider />
            </GridRowColumn>
          </>
        )}

        {incomeFromK1Responses?.[0].value && (
          <>
            <GridRowColumn {...questionsGridConfig}>
              <FormikInput
                name={getFieldName<typeof values>('howManyK1s')}
                label={questionHowManyK1s.question?.text}
                componentType="number"
                prefix=""
                schema={makeNumberSchema({ allowedDecimals: 0 })}
                placeholder="Enter number"
                decimalScale={0}
                fullWidth
              />
            </GridRowColumn>
            <GridRowColumn short {...questionsGridConfig}>
              <Divider />
            </GridRowColumn>
          </>
        )}

        <GridRowColumn short {...questionsGridConfig} />
        <Grid.Row />
        <FormFlowFooter
          continueDisabled={!isValid || isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isUpdating || isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default LessCommonScenariosPanel
