import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import {
  Alert,
  FormikInput,
  FormikLabelError,
  FormikRadioToggleButton,
  getFieldName,
  GridColumnSpacer,
  GridRowColumn,
  makeNumberSchema,
  makeReqBoolSchema,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { getFinancialProfile } from '../../../../selectors/user.selectors'
import {
  UPDATE_FINANCIAL_PROFILE_KEY,
  updateFinancialProfile,
} from '../../../../actions/financialProfileActions'
import {
  getProjectedW2Income,
  TAX_PROFILE_STEP,
  TaxesProfileFlowAnswerProps,
  useTaxProfileSteps,
} from './helpers'
import {
  dollarsToCents,
  formatCurrency,
} from '../../../../utils/currencyHelpers'
import TaxProfileFormFooter from './TaxProfileFormFooter'
import { getFetchError } from '../../../../reducers/fetch'
import TaxProfileAccordions, {
  useTaxProfileAccordionCopy,
} from './TaxProfileAccordions'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { LabelDescription } from '../../../../components/BaseComponents/Input'
import { selectActiveQuarterlyTaxEstimateDetails } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { W2IncomeWarning } from './W2IncomeWarning'
import { useAnalyticsTrack } from '../../../Amplitude'
import { QuarterlyTaxEstimateDetail } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import PaystubVsEstimateAccordion, {
  PaystubAccordionContent,
} from './PaystubVsEstimateAccordion'

const spouseW2IncomeCopy = (
  activeQuarterDetails?: QuarterlyTaxEstimateDetail
) => {
  switch (activeQuarterDetails?.taxQuarter) {
    case '1':
      return "Enter income earned from your spouse's W-2 job(s) for pay periods starting January 1st through February 28th. You'll find this on the first paycheck they received in March."
    case '2':
      return "Enter income earned from your spouse's W-2 job(s) for pay periods starting January 1st through April 30th. You'll find this on the first paycheck they received in May."
    case '3':
      return "Enter income earned from your spouse's W-2 job(s) for pay periods starting January 1st through July 31st. You'll find this on the first paycheck they received in August."
    case '4':
      return "Enter income earned from your spouse's W-2 job(s) for pay periods starting January 1st through November 30st. You'll find this on the first paycheck they received in December."
    // should never happen, but fall back to generic copy
    default:
      return 'Please enter income from your most recent paystub. Do not enter your net profit.'
  }
}

const SpouseW2Form = ({
  formFlowAnswers,
  setFormFlowAnswers,
}: TaxesProfileFlowAnswerProps) => {
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()
  const fp = useReselector(getFinancialProfile)
  const error = useReselector(
    getFetchError,
    UPDATE_FINANCIAL_PROFILE_KEY(fp?.id)
  )
  const { goToPreviousStep, goToNextStep } = useTaxProfileSteps(
    TAX_PROFILE_STEP.spouseW2
  )

  const someIncomeEntered =
    formFlowAnswers.spouseWIncome !== null ||
    formFlowAnswers.estimatedAnnualSpouseW2Income !== null

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      spouseHasWIncome: someIncomeEntered ? true : undefined,
      enterSpouseW2: someIncomeEntered
        ? formFlowAnswers.spouseWIncome !== null
        : undefined,
      spouseWIncome: formFlowAnswers.spouseWIncome,
      estimatedAnnualSpouseW2Income:
        formFlowAnswers.estimatedAnnualSpouseW2Income,
    },
    onSubmit: async ({
      spouseHasWIncome,
      spouseWIncome,
      enterSpouseW2,
      estimatedAnnualSpouseW2Income,
    }) => {
      const res = await dispatch(
        updateFinancialProfile(fp?.id, {
          spouseWIncomeInCents:
            spouseHasWIncome && enterSpouseW2
              ? dollarsToCents(spouseWIncome)
              : null,
          estimatedAnnualSpouseW2IncomeInCents:
            spouseHasWIncome && !enterSpouseW2
              ? dollarsToCents(estimatedAnnualSpouseW2Income)
              : null,
          // User will skip the withholding page if answered no - clear these values in this case
          ...(!spouseHasWIncome && {
            spouseFederalWithholdingInCents: null,
            spouseStateWithholdingInCents: null,
          }),
        })
      )

      if (res) {
        const newAnswers = {
          ...formFlowAnswers,
          spouseWIncome,
          estimatedAnnualSpouseW2Income,
        }

        if (spouseHasWIncome) {
          if (enterSpouseW2) {
            newAnswers.estimatedAnnualSpouseW2Income = null
          } else {
            newAnswers.spouseWIncome = null
          }
        } else {
          newAnswers.spouseWIncome = null
          newAnswers.estimatedAnnualSpouseW2Income = null
          newAnswers.spouseFederalWithholding = null
          newAnswers.spouseStateWithholding = null
        }
        setFormFlowAnswers(newAnswers)
        track('spouse w2 income submitted', {
          spouse_has_w2_income: spouseHasWIncome ?? false,
          enter_spouse_paystub_w2: Boolean(enterSpouseW2),
          spouse_w2_income_in_cents:
            spouseHasWIncome && enterSpouseW2
              ? dollarsToCents(spouseWIncome)
              : null,
          estimated_annual_spouse_w2_income_in_cents:
            spouseHasWIncome && !enterSpouseW2
              ? dollarsToCents(estimatedAnnualSpouseW2Income)
              : null,
          user_id: fp?.userId ?? null,
        })
        goToNextStep(res)
      }
    },
  })

  const { values, isSubmitting, isValid, submitForm, validateForm, touched } =
    formik

  const activeQuarterDetails = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )

  const spouseW2IncomeSubcopy = spouseW2IncomeCopy(activeQuarterDetails)

  const projectedSpouseW2Income = getProjectedW2Income({
    ytdIncomeInDollars: values.spouseWIncome,
    quarterDetails: activeQuarterDetails,
  })

  const faqCopy = useTaxProfileAccordionCopy()

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn {...makeGridConfig([12, 16, 16], true)}>
          <Text as="display2" textAlign="center">
            Your spouse’s W-2 income
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([12, 16, 16], true)}>
          <Text as="bodyLg" textAlign="center">
            We need to know your spouse’s total income for the year to calculate
            your tax bracket.
          </Text>
        </GridRowColumn>

        {error && (
          <GridRowColumn>
            <Alert type="error">{error.message}</Alert>
          </GridRowColumn>
        )}
        <GridRowColumn short {...makeGridConfig([8, 12, 16], true)}>
          <FormikLabelError
            name={getFieldName<typeof values>('spouseHasWIncome')}
            schema={makeReqBoolSchema()}
            label="Does your spouse have a W-2 job?"
            description="A W-2 job is where a person is considered an employee and receives a W-2 tax form each year that reports their income and tax withholdings."
          />
        </GridRowColumn>
        <Grid.Row>
          <Grid.Column computer={5} tablet={4} only="tablet computer" />
          <Grid.Column computer={3} tablet={4} mobile={16}>
            <FormikRadioToggleButton
              name={getFieldName<typeof values>('spouseHasWIncome')}
              value
              fullWidth
              onClick={validateForm}
            >
              Yes
            </FormikRadioToggleButton>
          </Grid.Column>
          <GridColumnSpacer mobile />
          <Grid.Column computer={3} tablet={4} mobile={16}>
            <FormikRadioToggleButton
              name={getFieldName<typeof values>('spouseHasWIncome')}
              value={false}
              onClick={validateForm}
              fullWidth
            >
              No
            </FormikRadioToggleButton>
          </Grid.Column>
        </Grid.Row>

        {values.spouseHasWIncome && (
          <>
            <GridRowColumn {...makeGridConfig([8, 12, 16], true)}>
              <FormikLabelError
                name={getFieldName<typeof values>('enterSpouseW2')}
                label="How would you like to enter income?"
                description="Click on “How should I choose?” below if you need help deciding how to enter income."
                schema={makeReqBoolSchema()}
              />
            </GridRowColumn>
            <GridRowColumn {...makeGridConfig([8, 12, 16], true)} short>
              <FormikRadioToggleButton
                name={getFieldName<typeof values>('enterSpouseW2')}
                value
                fullWidth
              >
                Year-to-date from paystub
              </FormikRadioToggleButton>
            </GridRowColumn>
            <GridRowColumn {...makeGridConfig([8, 12, 16], true)} short>
              <FormikRadioToggleButton
                name={getFieldName<typeof values>('enterSpouseW2')}
                value={false}
                fullWidth
              >
                Estimated income
              </FormikRadioToggleButton>
            </GridRowColumn>

            <Grid.Row />

            {values.enterSpouseW2 && (
              <GridRowColumn {...makeGridConfig([8, 12, 16], true)}>
                <Grid>
                  <GridRowColumn>
                    <LabelDescription
                      required
                      label="Spouse's year-to-date W-2 Gross Income"
                      description={spouseW2IncomeSubcopy}
                    />
                  </GridRowColumn>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <FormikInput
                        name={getFieldName<typeof values>('spouseWIncome')}
                        required
                        fullWidth
                        componentType="currency"
                        placeholder="$"
                        schema={makeNumberSchema({
                          allowedDecimals: 2,
                          greaterThanZero: true,
                        })}
                      />
                    </Grid.Column>
                    {projectedSpouseW2Income && (
                      <Grid.Column width={8}>
                        <Alert
                          style={{ padding: 15 }}
                          customIcon={' '} //no icon
                          title={`${formatCurrency(projectedSpouseW2Income, { hideDecimalsIfZero: true })} projected annually`}
                        />
                      </Grid.Column>
                    )}
                  </Grid.Row>
                  <W2IncomeWarning
                    inputTouched={touched.spouseWIncome}
                    w2Income={values.spouseWIncome}
                  />
                </Grid>
              </GridRowColumn>
            )}

            {values.enterSpouseW2 === false && (
              <GridRowColumn {...makeGridConfig([8, 12, 16], true)}>
                <FormikInput
                  name={getFieldName<typeof values>(
                    'estimatedAnnualSpouseW2Income'
                  )}
                  required
                  fullWidth
                  componentType="currency"
                  schema={makeNumberSchema({
                    allowedDecimals: 2,
                    greaterThanZero: true,
                  })}
                  placeholder="$"
                  label={`${activeQuarterDetails?.taxYear} Annual gross income for your spouse’s W-2 job `}
                  description="Please include the total income from all their W-2 job(s)."
                />
              </GridRowColumn>
            )}

            <GridRowColumn {...makeGridConfig([12, 16, 16], true)}>
              <PaystubVsEstimateAccordion
                content={PaystubAccordionContent.spouse}
              />
            </GridRowColumn>
          </>
        )}

        <Grid.Row />
        <TaxProfileAccordions
          faqs={[
            faqCopy.whyShareSpouseIncome,
            faqCopy.whatIsW2Job,
            faqCopy.howDoIEstimate,
            faqCopy.whatDoesYTDMeanSpouse,
            faqCopy.whatIsGrossIncome,
          ]}
        />
        <TaxProfileFormFooter
          submitOrContinue={submitForm}
          goToPreviousStep={goToPreviousStep}
          continueDisabled={!isValid || isSubmitting}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default SpouseW2Form
