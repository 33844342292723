import { List } from 'semantic-ui-react'
import { Card, Link, Text } from '../../components/BaseComponents'

const SupportCenterCard = () => {
  return (
    <Card type="subsection" backgroundColor="stone40">
      <Text as="h2">Support Center</Text>
      <br />
      <Text>
        If you have questions about your practice&apos;s finances or how to use
        Heard, message us via <Link to="/conversations">Conversations</Link>.
      </Text>
      <List bulleted>
        <List.Item>
          <Link
            href="https://support.joinheard.com/hc/en-us/categories/360006112673-Frequently-Asked-Questions"
            newPage
          >
            FAQs
          </Link>
        </List.Item>
        <List.Item>
          <Link
            href="https://support.joinheard.com/hc/en-us/categories/1500000154101-Bookkeeping-and-Tax-Resources"
            newPage
          >
            Bookkeeping and Tax Resources
          </Link>
        </List.Item>
        <List.Item>
          <Link href="https://www.joinheard.com/resources" newPage>
            Resource Hub
          </Link>
        </List.Item>
      </List>
    </Card>
  )
}

export default SupportCenterCard
