import { createSelector } from 'reselect'
import { ReduxState } from '../../utils/typeHelpers'

export const getTransactionRules = (state: ReduxState) =>
  state.admin.transactionRules

export const selectTransactionRuleById = createSelector(
  [getTransactionRules, (_: unknown, id: number | undefined) => id],
  (transactionRules, id) => {
    if (!id || !transactionRules) return null
    return transactionRules[id]
  }
)
