import { useCallback, useEffect, useState } from 'react'
import { Confirm, Container, Divider, Grid } from 'semantic-ui-react'
import moment from 'moment'

import { PayrollSetup, User } from '../../../reducers/auth/userReducer'
import { getUserById } from '../../../selectors/user.selectors'
import {
  disablePayroll,
  FETCH_PAYROLLS_FOR_USER_KEY,
  fetchPayrollForUser,
  fetchPayrollsForUser,
} from '../../Payroll/payrollActions'
import { PayrollProfile } from '../../Payroll/payrollProfile.slice'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useReselector } from '../../../utils/sharedHooks'
import { getIsFetchingOrNotStarted } from '../../../reducers/fetch'
import {
  Button,
  Card,
  Dropdown,
  GridRowColumn,
  Loader,
  Modal,
  Table,
  Text,
  Input,
  DatePicker,
} from '../../../components/BaseComponents'
import { adminUpdateUserWithId } from '../../../actions/admin/adminAllUsersActions'
import EnrollPayrollModal from './EnrollPayrollModal'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { Gusto_Payroll } from '../../Payroll/generated_gusto_types'

const EditPayrollProvider = ({ user }: { user: User }) => {
  const { payrollProvider, id } = user
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)
  const [newProvider, setNewProvider] = useState(payrollProvider || '')

  const updateProvider = useCallback(async () => {
    await adminUpdateUserWithId(id, {
      payrollProvider: newProvider,
    })(dispatch)

    setOpen(false)
  }, [dispatch, newProvider, id])

  return (
    <>
      <Text>
        <b>Payroll Provider:</b> {payrollProvider}
      </Text>
      <Button variant="link" onClick={() => setOpen(true)}>
        edit
      </Button>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Modal.Header>Update provider</Modal.Header>
        <Modal.Content>
          <Input
            label="Payroll Provider"
            value={newProvider}
            onChange={setNewProvider}
          />
          <br />
          <Button onClick={updateProvider}>Submit</Button>
        </Modal.Content>
      </Modal>
    </>
  )
}

const EditGustoPlan = ({ user }: { user: User }) => {
  const { id, gustoOnboardedAt, gustoPlan } = user
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)
  const [newPlan, setNewPlan] = useState(gustoPlan || '')
  const [newOnboardedAt, setNewOnboardedAt] = useState(
    gustoOnboardedAt
      ? moment.utc(gustoOnboardedAt).format(DATE_FORMATS.INPUT)
      : ''
  )

  const updateProvider = useCallback(async () => {
    await adminUpdateUserWithId(id, {
      gustoPlan: newPlan,
      gustoOnboardedAt: newOnboardedAt,
    })(dispatch)

    setOpen(false)
  }, [dispatch, id, newOnboardedAt, newPlan])

  return (
    <>
      <Text>
        <b>Gusto onboarded on:</b>{' '}
        {gustoOnboardedAt &&
          moment(gustoOnboardedAt).format(DATE_FORMATS.DISPLAY_SHORT)}
      </Text>
      <Text>
        <b>Gusto Plan: </b> {gustoPlan}
      </Text>
      <Button variant="link" onClick={() => setOpen(true)}>
        edit
      </Button>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Modal.Header>Update Gusto Plan Details</Modal.Header>
        <Modal.Content>
          <Input label="Gusto Plan" value={newPlan} onChange={setNewPlan} />
          <br />
          <DatePicker
            label="Gusto onboarded at"
            value={newOnboardedAt}
            onChange={setNewOnboardedAt}
          />
          <br />
          <Button onClick={updateProvider}>Submit</Button>
        </Modal.Content>
      </Modal>
    </>
  )
}

const OnboardedDashboard = ({
  payrollProfile,
}: {
  payrollProfile: PayrollProfile
}) => {
  const dispatch = useAppDispatch()
  const loading = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_PAYROLLS_FOR_USER_KEY(payrollProfile.userId)
  )
  const [payrolls, setPayrolls] = useState<Gusto_Payroll[]>([])

  useEffect(() => {
    const fetch = async () => {
      const res = await fetchPayrollsForUser(payrollProfile.userId, {
        start_date: moment().format(DATE_FORMATS.GUSTO_SUBMIT),
        end_date: moment()
          .add(3, 'months')
          .subtract(1, 'day')
          .format(DATE_FORMATS.GUSTO_SUBMIT),
        payroll_types: ['regular', 'off_cycle'],
      })(dispatch)

      if (res) {
        setPayrolls(res.slice(0, 3))
      }
    }

    fetch()
  }, [dispatch, payrollProfile.userId])

  return (
    <>
      <GridRowColumn>
        <Text as="h3">Payroll Details</Text>
      </GridRowColumn>
      <GridRowColumn short>
        <Text>
          <b>Gusto Company UUID</b>: {payrollProfile.gustoCompanyUuid}
        </Text>
      </GridRowColumn>
      <GridRowColumn short>
        <Text>
          <b>Number of Team Members:</b>{' '}
          {String(payrollProfile.numberOfTeamMembers)}
        </Text>
      </GridRowColumn>

      <GridRowColumn short>
        <Text>Upcoming {payrolls.length} Payrolls</Text>
      </GridRowColumn>

      <GridRowColumn short>
        <Loader loading={loading} />
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Pay Period</Table.HeaderCell>
              <Table.HeaderCell>Deadline to Process Payroll</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          {payrolls.map((payroll) => (
            <Table.Row key={payroll.payroll_uuid}>
              <Table.Cell>
                {moment
                  .utc(payroll.pay_period.start_date, DATE_FORMATS.GUSTO_SUBMIT)
                  .format(DATE_FORMATS.DISPLAY_SHORT)}
                {' - '}
                {moment
                  .utc(payroll.pay_period.end_date, DATE_FORMATS.GUSTO_SUBMIT)
                  .format(DATE_FORMATS.DISPLAY_SHORT)}
              </Table.Cell>
              <Table.Cell>
                {moment
                  .utc(payroll.payroll_deadline, DATE_FORMATS.GUSTO_SUBMIT)
                  .format(DATE_FORMATS.DISPLAY_SHORT)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table>
      </GridRowColumn>
      <GridRowColumn short>
        <Text>
          <i>To view all Payrolls for this user, please visit Gusto</i>
        </Text>
      </GridRowColumn>
    </>
  )
}

const PayrollSetupOptions = [
  { value: PayrollSetup.none, text: 'No Payroll Needed' },
  { value: PayrollSetup.heard, text: 'Heard Payroll' },
  { value: PayrollSetup.gusto, text: 'Gusto' },
  { value: PayrollSetup.other, text: 'Other Payroll' },
  { value: PayrollSetup.needsPayroll, text: 'Needs Payroll' },
  { value: PayrollSetup.unknown, text: 'Unknown' },
]

const PayrollTabPanel = ({ userId }: { userId: number }) => {
  const dispatch = useAppDispatch()
  const user = useReselector(getUserById, userId)
  const [modalOpen, setModalOpen] = useState(false)
  const [payrollProfile, setPayrollProfile] = useState<PayrollProfile | null>()
  const [confirmOpen, setConfirmOpen] = useState(false)

  const fetchPayrollProfile = useCallback(async () => {
    const res = await fetchPayrollForUser(userId)(dispatch)
    setPayrollProfile(res)
  }, [dispatch, userId])

  const onModalClose = useCallback(() => {
    setModalOpen(false)
    fetchPayrollProfile()
  }, [fetchPayrollProfile])

  useEffect(() => {
    fetchPayrollProfile()
  }, [userId, dispatch, fetchPayrollProfile])

  const unenrollUser = useCallback(async () => {
    const res = await disablePayroll(userId, payrollProfile?.id)(dispatch)
    if (res) {
      setPayrollProfile(undefined)
      setConfirmOpen(false)
    }
  }, [dispatch, payrollProfile?.id, userId])

  const updatePayrollStatus = useCallback(
    (payrollSetup: PayrollSetup) =>
      adminUpdateUserWithId(userId, { payrollSetup })(dispatch),
    [dispatch, userId]
  )

  if (!user) {
    return null
  }

  const { financialProfile, payrollSetup, payrollEnabledAt } = user

  return (
    <Container>
      <Card>
        <Grid>
          <GridRowColumn>
            <Text as="h2">Heard Payroll</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              <b>Business Name:</b> {financialProfile?.businessName}
            </Text>
            <Text>
              <b>Tax entity:</b> {financialProfile?.taxEntityType}
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              <b>Payroll Set up:</b>
            </Text>
            <Dropdown
              options={PayrollSetupOptions}
              value={payrollSetup}
              onChange={updatePayrollStatus}
            />
            <br />
            <Text>
              <b>Payroll Provider:</b> {user.payrollProvider}
            </Text>
          </GridRowColumn>
          <Divider />
          {payrollSetup === PayrollSetup.heard &&
            payrollProfile?.onboardingCompleted && (
              <OnboardedDashboard payrollProfile={payrollProfile} />
            )}
          {payrollSetup === PayrollSetup.gusto && (
            <>
              <GridRowColumn>
                <Text as="h3">Payroll Details</Text>
              </GridRowColumn>
              <GridRowColumn>
                <EditGustoPlan user={user} />
              </GridRowColumn>
              <GridRowColumn>
                <Text>
                  <i>
                    To view all additional payroll details for this user, please
                    visit Gusto.com and log in using Heard Payroll email.
                  </i>
                </Text>
              </GridRowColumn>
            </>
          )}
          {payrollSetup === PayrollSetup.other && (
            <>
              <GridRowColumn>
                <Text as="h3">Payroll Details</Text>
              </GridRowColumn>
              <GridRowColumn>
                <EditPayrollProvider user={user} />
              </GridRowColumn>
              <GridRowColumn>
                <Text>
                  <i>
                    This User has elected to run payroll through another
                    provider. This means that we will:
                    <ul>
                      <li>Not be able to support with any payroll questions</li>
                      <li>Need to request their Payroll Reports</li>
                    </ul>
                  </i>
                </Text>
              </GridRowColumn>
            </>
          )}
          {payrollSetup === PayrollSetup.unknown && (
            <>
              <GridRowColumn>
                <Text as="h3">Payroll Details</Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text>
                  <i>
                    Please gather Payroll details for this user.
                    <br />
                    As a reminder, all S-Corps should set up and run payroll
                  </i>
                </Text>
              </GridRowColumn>
            </>
          )}
          <Divider />
          {payrollEnabledAt ? (
            <>
              <GridRowColumn short>
                <Text>
                  <b>Payroll Enabled On:</b>{' '}
                  {moment(payrollEnabledAt).format(DATE_FORMATS.DISPLAY_SHORT)}
                </Text>
              </GridRowColumn>
              {payrollProfile && (
                <GridRowColumn short>
                  <Text>
                    <b>Payroll Profile Active:</b>{' '}
                    {String(payrollProfile.isActive)}
                  </Text>
                  <Text>
                    <b>Onboarding Completed:</b>{' '}
                    {String(payrollProfile.onboardingCompleted)}
                  </Text>
                  <Text>
                    <b>Needs Migration:</b>{' '}
                    {String(payrollProfile.needsMigration)}
                  </Text>
                </GridRowColumn>
              )}
              <GridRowColumn>
                <Button variant="warning" onClick={() => setConfirmOpen(true)}>
                  Un-Enroll User in Payroll
                </Button>
              </GridRowColumn>
              <Confirm
                size="tiny"
                open={confirmOpen}
                cancelButton="Cancel"
                confirmButton="Disable Payroll for User"
                content="Are you sure you want to disable Payroll for this User?"
                onCancel={() => setConfirmOpen(false)}
                onConfirm={unenrollUser}
              />
            </>
          ) : (
            <>
              <GridRowColumn>
                <Button variant="secondary" onClick={() => setModalOpen(true)}>
                  Enroll User in Payroll
                </Button>
              </GridRowColumn>
              <EnrollPayrollModal
                open={modalOpen}
                close={onModalClose}
                userId={user.id}
              />
            </>
          )}
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollTabPanel
