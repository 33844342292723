import { Grid } from 'semantic-ui-react'
import {
  FormikDateInput,
  FormikLabelError,
  FormikRadioToggleButton,
  GridRowColumn,
  Text,
  getFieldName,
  makeDateSchema,
  makeReqStringSchema,
} from '../../../../../../components/BaseComponents'
import { FormikProvider, useFormik } from 'formik'
import { useReselector } from '../../../../../../utils/sharedHooks'
import {
  selectTaxListQuestion,
  selectTaxListQuestionOptionByOptionId,
  selectTaxListQuestionResponsesByFormId,
} from '../../taxChecklist.selectors'
import { TaxListOptionId, TaxListQuestionId } from '../../service'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { useParams } from 'react-router-dom'
import { DATE_FORMATS } from '../../../../../../utils/dateHelpers'
import { LifeChangesStepProps } from '.'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import DocumentAddedAlert from '../../Shared/SpecialtyAlerts'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { DateTime } from 'luxon'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { UPDATE_USER_EOY_REVIEW_PROGRESS_KEY } from '../../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { UPSERT_USER_TAX_QUESTIONNAIRE_KEY } from '../../taxChecklistQuestion.actions'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'

export const purchasedHomeQuestionIds = [
  TaxListQuestionId.home_purchase_date,
  TaxListQuestionId.home_purchase_residence_type,
]

const PurchasedHomePanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: LifeChangesStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()
  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    UPDATE_USER_EOY_REVIEW_PROGRESS_KEY,
  ])

  const questionPurchaseDate = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_purchase_date,
    taxYear
  )
  const responsePurchaseDate = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_purchase_date,
    Number(formId)
  )

  const questionResidenceType = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_purchase_residence_type,
    taxYear
  )
  const responseResidenceType = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_purchase_residence_type,
    Number(formId)
  )
  const residencyTypeOptionPrimary = useReselector(
    selectTaxListQuestionOptionByOptionId,
    TaxListQuestionId.home_purchase_residence_type,
    taxYear,
    TaxListOptionId.primary_residence
  )
  const residencyTypeOptionSecondary = useReselector(
    selectTaxListQuestionOptionByOptionId,
    TaxListQuestionId.home_purchase_residence_type,
    taxYear,
    TaxListOptionId.secondary_residence
  )
  const residencyTypeOptionRental = useReselector(
    selectTaxListQuestionOptionByOptionId,
    TaxListQuestionId.home_purchase_residence_type,
    taxYear,
    TaxListOptionId.rental_home
  )

  const formik = useFormik({
    initialValues: {
      purchaseDate: responsePurchaseDate?.[0]?.value,
      residenceType: responseResidenceType?.[0]?.value,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      if (values.purchaseDate !== responsePurchaseDate?.[0]?.value) {
        responseData.push({
          id: responsePurchaseDate?.[0]?.id,
          value: values.purchaseDate,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.home_purchase_date,
        })
      }
      if (values.residenceType !== responseResidenceType?.[0]?.value) {
        responseData.push({
          id: responseResidenceType?.[0]?.id,
          value: values.residenceType,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.home_purchase_residence_type,
        })
      }
      await goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.purchasedHome],
      })
    },
  })

  const { values, isSubmitting, isValid, submitForm } = formik

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn>
          <Text as="display2" textAlign="center">
            Purchased a Home
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikDateInput
            minDate={DateTime.fromISO(taxYear).startOf('year').toJSDate()}
            maxDate={DateTime.fromISO(taxYear).endOf('year').toJSDate()}
            fullWidth
            name={getFieldName('purchaseDate')}
            label={questionPurchaseDate.question?.text}
            schema={makeDateSchema({
              field: 'date',
              format: DATE_FORMATS.INPUT,
              strict: true,
            })}
          />
        </GridRowColumn>
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <DocumentAddedAlert>
            Purchase documents added to checklist
          </DocumentAddedAlert>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikLabelError
            name={getFieldName('residenceType')}
            label={questionResidenceType.question?.text}
            schema={makeReqStringSchema({ field: 'selection' })}
          />
        </GridRowColumn>
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <FormikRadioToggleButton
            fullWidth
            name={getFieldName('residenceType')}
            value={residencyTypeOptionPrimary?.id.toString() ?? ''}
          >
            {residencyTypeOptionPrimary?.text}
          </FormikRadioToggleButton>
        </GridRowColumn>
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <FormikRadioToggleButton
            fullWidth
            name={getFieldName('residenceType')}
            value={residencyTypeOptionSecondary?.id.toString() ?? ''}
          >
            {residencyTypeOptionSecondary?.text}
          </FormikRadioToggleButton>
        </GridRowColumn>
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <FormikRadioToggleButton
            fullWidth
            name={getFieldName('residenceType')}
            value={residencyTypeOptionRental?.id.toString() ?? ''}
          >
            {residencyTypeOptionRental?.text}
          </FormikRadioToggleButton>
        </GridRowColumn>
        {values.residenceType === residencyTypeOptionRental?.id && (
          <GridRowColumn short {...makeGridConfig([6, 14], true)}>
            <DocumentAddedAlert>
              Depreciation schedule added to checklist
            </DocumentAddedAlert>
          </GridRowColumn>
        )}
        <Grid.Row />
        <FormFlowFooter
          continueDisabled={!isValid || isUpdating || isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isUpdating || isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default PurchasedHomePanel
