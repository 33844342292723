import { createSelector } from 'reselect'
import { orderBy } from 'lodash'

import { getUserFinancialAccounts } from './financeSelectors'
import { ReduxState } from '../utils/typeHelpers'

const getReconciliationState = (state: ReduxState) =>
  state.admin.allFinancialAccountReconciliations

export const getReconciliationById = createSelector(
  getReconciliationState,
  (_: unknown, id: string | number | undefined) => id,
  (byId, id) => (id ? byId[Number(id)] : undefined)
)

export const getOrderedReconciliationsByUserId = createSelector(
  [getUserFinancialAccounts, getReconciliationState],
  (financialAccountsById, reconciliationsById) => {
    if (!financialAccountsById) {
      return []
    }
    const financialAccountIds = Object.keys(financialAccountsById)
    const reconciliations = Object.values(reconciliationsById).filter((r) =>
      financialAccountIds.includes(r.accountId.toString())
    )

    return orderBy(
      reconciliations,
      (reconciliation) => new Date(reconciliation.startingBalanceDate),
      ['desc']
    )
  }
)
