import { LabelColorMap } from '../components/BaseComponents/Label'
import { Colors } from '../styles/theme'

export const EXPENSES_COLORS = Object.keys(LabelColorMap)

export const BRIGHT_COLORS = [
  Colors.blue,
  Colors.purple,
  Colors.red,
  Colors.magenta,
  Colors.orange,
  Colors.yellow,
  Colors.accentGreen,
  Colors.teal,
]
export const USER_DOCUMENT_COLORS = [
  Colors.lightBlue,
  Colors.lightPurple,
  Colors.lightRed,
  Colors.lightMagenta,
  Colors.orange,
  Colors.lightYellow,
  Colors.lightGreen,
  Colors.lightTeal,
]
