import { useCallback, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { keyBy } from 'lodash'

import { UPLOAD_COPY, TRANSACTIONS_TABLE_DESCRIPTION } from './copyConstants'
import { getUserNeedsClarificationTransactionsSelector } from './transactions.selectors'
import { fetchUserTransactions } from './transactions.slice'
import './TransactionsPanel.scss'
import {
  Dropdown,
  GridRowColumn,
  Label,
  Tab,
} from '../../components/BaseComponents'
import { useReselector, useTimeoutRef } from '../../utils/sharedHooks'
import { fetchUserDocuments } from '../UserDocuments/userDocuments.slice'
import UploadTransactions, { UploadSuccessPayload } from './Upload/Module'
import UploadMessage from './Upload/UploadMessage'
import TransactionsTable from './TransactionTable'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'
import PageHeader from '../../components/shared/PageHeader'

import { UndoTransactionContextProvider } from './UndoTransactionContext'
import { useAppDispatch } from '../../utils/typeHelpers'

const TAB_INDEXES: { [key: number]: string } = {
  0: 'all',
  1: 'review',
  2: 'business',
  3: 'personal',
}

type TableViews = {
  value: number
  text: string
  type: keyof typeof TRANSACTIONS_TABLE_DESCRIPTION
}[]

const TransactionsPanel = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const needsClarificationTransactions = useReselector(
    getUserNeedsClarificationTransactionsSelector
  )
  const needsReviewLength = needsClarificationTransactions.length
  const tableViews: TableViews = [
    { value: 0, text: 'All', type: 'all' },
    {
      value: 1,
      text:
        isMobile && needsReviewLength > 0
          ? `Needs Review (${needsReviewLength})`
          : 'Needs Review',
      type: 'review',
    },
    { value: 2, text: 'Business', type: 'business' },
    { value: 3, text: 'Personal', type: 'personal' },
  ]
  const tableViewsMap = keyBy(tableViews, 'type')
  const [fetching, setFetching] = useState(true)
  const { type } = useParams<{ type: string | undefined }>()
  const [activeIndex, setActiveIndex] = useState(() => {
    switch (type) {
      case tableViewsMap.review.type:
        return tableViewsMap.review.value
      case tableViewsMap.business.type:
        return tableViewsMap.business.value
      case tableViewsMap.personal.type:
        return tableViewsMap.personal.value
      default:
        return tableViewsMap.all.value
    }
  })
  const bannerTimeoutRef = useTimeoutRef()
  const [completeBanner, setCompleteBanner] = useState<{
    success: boolean
    message: string
  }>()

  useEffect(() => {
    async function batchFetch() {
      await Promise.all([
        dispatch(fetchUserDocuments()),
        dispatch(fetchUserTransactions()),
      ])
      setFetching(false)
    }
    batchFetch()
  }, [dispatch, setFetching])

  const onUploadComplete = useCallback(
    ({
      success,
      data,
    }: {
      success: boolean
      data?: UploadSuccessPayload[]
    }) => {
      let message

      if (success) {
        message =
          data?.length === 1
            ? UPLOAD_COPY.SINGLE_TRANSACTION_SUCCESS_MESSAGE
            : UPLOAD_COPY.MULTIPLE_TRANSACTIONS_SUCCESS_MESSAGE
      } else {
        message = UPLOAD_COPY.SINGLE_TRANSACTION_ERROR_MESSAGE
      }
      setCompleteBanner({ success, message })

      bannerTimeoutRef.current = setTimeout(
        () => setCompleteBanner(undefined),
        5500
      )
    },
    [bannerTimeoutRef]
  )

  const updateTab = (newIndex: number) => {
    const newTab = TAB_INDEXES[newIndex]
    setActiveIndex(newIndex)
    navigate(`/transactions/${newTab}`, { replace: true })
  }

  return (
    <UndoTransactionContextProvider>
      <div
        id="transactionsPanel"
        style={isMobile ? { margin: '0 10px' } : undefined}
      >
        <Grid>
          <GridRowColumn>
            <PageHeader
              header="Transactions"
              extraContent={
                <UploadTransactions onUploadComplete={onUploadComplete} />
              }
            />
          </GridRowColumn>
          {completeBanner && (
            <GridRowColumn>
              <UploadMessage
                success={completeBanner.success}
                content={completeBanner.message}
                fadeAfterMS={5000}
              />
            </GridRowColumn>
          )}
          {<Grid.Row />}
        </Grid>
        {isMobile && (
          <>
            <Dropdown
              fullWidth
              value={activeIndex}
              options={tableViews}
              onChange={updateTab}
            />
            <TransactionsTable
              fetching={fetching}
              filterable
              review={activeIndex === tableViewsMap.review.value}
              tableType={tableViews[activeIndex].type}
            />
          </>
        )}
        {!isMobile && (
          <Tab
            menu={{ style: { marginBottom: isMobile ? 0 : 32 } }}
            activeIndex={activeIndex}
            defaultActiveIndex={activeIndex}
            onTabClick={updateTab}
            panes={[
              {
                menuItem: tableViewsMap.all.text,
                render: () => (
                  <TransactionsTable
                    fetching={fetching}
                    filterable
                    tableType={tableViewsMap.all.type}
                  />
                ),
              },
              {
                menuItem: tableViewsMap.review.text,
                ornament:
                  needsReviewLength > 0 ? (
                    <Label color="red" $circular>
                      {needsReviewLength}
                    </Label>
                  ) : null,
                render: () => (
                  <TransactionsTable
                    fetching={fetching}
                    filterable
                    review
                    tableType={tableViewsMap.review.type}
                  />
                ),
              },
              {
                menuItem: tableViewsMap.business.text,
                render: () => (
                  <TransactionsTable
                    fetching={fetching}
                    filterable
                    tableType={tableViewsMap.business.type}
                  />
                ),
              },
              {
                menuItem: tableViewsMap.personal.text,
                render: () => (
                  <TransactionsTable
                    fetching={fetching}
                    filterable
                    tableType={tableViewsMap.personal.type}
                  />
                ),
              },
            ]}
          />
        )}
      </div>
    </UndoTransactionContextProvider>
  )
}

export default TransactionsPanel
