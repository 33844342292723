import { useMemo, useState } from 'react'
import { Container, Grid, GridColumn } from 'semantic-ui-react'
import moment from 'moment'
import { DateTime } from 'luxon'

import Components from '../../../stories'
import { Colors } from '../../../styles/theme'
import {
  SampleAnnouncementBanner,
  SampleErrorAlert,
  SampleInfoAlert,
  SampleWarnAlert,
  CloseableAlert,
  CustomIconAlert,
} from '../../../stories/Alert.stories'
import SampleForm from '../../../stories/Form.stories'
import SampleTable from '../../../stories/Table.stories'
import {
  DisabledPagination,
  EightPagination,
  LongPagination,
  SevenPagination,
  ShortPagination,
} from '../../../stories/Pagination.stories'
import {
  Tab,
  Text,
  Label,
  Toggle,
  Card,
  Button,
} from '../../../components/BaseComponents'
import { logSentryError, logSentryMessage } from '../../../utils/sentryHelpers'
import { DATE_FORMATS, DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import GridStories from './Grid.stories'

const getPropValue = (value: unknown) => {
  if (value === null || value === undefined) {
    return ''
  } else if (typeof value === 'object') {
    return JSON.stringify(value)
  } else if (
    typeof value === 'string' ||
    typeof value === 'boolean' ||
    typeof value === 'function' ||
    typeof value === 'number'
  ) {
    return value.toString()
  } else {
    return ''
  }
}

const StoryBookComponents = ({ showProps }: { showProps: boolean }) => (
  <>
    {Object.entries(Components).map(([componentKey, components]) => (
      <div key={`${componentKey}_div`} style={{ paddingBottom: 24 }}>
        <Text as="h1">{componentKey}</Text>
        <Grid key={`${componentKey}_Grid`} style={{ marginTop: 1 }}>
          {Object.entries(components).map(([elementKey, Element]) => {
            const { props } = Element()

            return (
              <GridColumn
                key={`${componentKey}_${elementKey}_GridColumn`}
                width={4}
                style={{ backgroundColor: Colors.moss }}
              >
                <Text as="h3" style={{ marginBottom: 2 }}>
                  {elementKey.replace(/([a-z])([A-Z])/g, '$1 $2')}
                </Text>
                <Element key={`${componentKey}_${elementKey}`} />
                <br />
                {showProps &&
                  Object.entries(props)
                    .filter(([prop]) => prop !== 'children')
                    .map(([prop, value]) => (
                      <Text
                        key={`${componentKey}_${elementKey}_${prop}`}
                        style={{ marginBottom: 2 }}
                        as="bodyMd"
                      >
                        {prop}: {getPropValue(value)}
                      </Text>
                    ))}
              </GridColumn>
            )
          })}
        </Grid>
      </div>
    ))}
  </>
)

const Storybook = () => {
  const [showProps, setShowProps] = useState(false)

  const panes = useMemo(
    () => [
      {
        menuItem: 'Components',
        render: () => <StoryBookComponents showProps={showProps} />,
      },
      {
        menuItem: 'Form',
        render: () => (
          <Tab.Pane>
            <SampleForm />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Alert',
        render: () => (
          <Tab.Pane>
            <SampleAnnouncementBanner />
            <SampleErrorAlert />
            <SampleInfoAlert />
            <SampleWarnAlert />
            <CustomIconAlert />
            <CloseableAlert />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Table',
        render: () => (
          <Tab.Pane>
            <div style={{ background: 'white', padding: 16 }}>
              <SampleTable />
            </div>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Pagination',
        render: () => (
          <Tab.Pane>
            <Text as="h3">Short Pagination</Text>
            <br />
            <ShortPagination />
            <br />
            <Text as="h3">Seven Page Pagination</Text>
            <br />
            <SevenPagination />
            <br />
            <Text as="h3">Eight Page Pagination</Text>
            <br />
            <EightPagination />
            <br />
            <Text as="h3">Long Pagination</Text>
            <br />
            <LongPagination />
            <br />
            <Text as="h3">Disabled Pagination</Text>
            <br />
            <DisabledPagination />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Date formats',
        ornament: <Label>5</Label>,
        render: () => (
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Text as="h2">Moment</Text>
                {Object.entries(DATE_FORMATS).map(([key, val]) => (
                  <Text key={key}>
                    {key} - <b>{moment().subtract(1, 'day').format(val)}</b>
                  </Text>
                ))}
              </Grid.Column>
              <Grid.Column width={8}>
                <Text as="h2">Luxon</Text>
                {Object.entries(DATE_FORMATS_LUXON).map(([key, val]) => (
                  <Text key={key}>
                    {key} -{' '}
                    <b>{DateTime.now().minus({ days: 1 }).toFormat(val)}</b>
                  </Text>
                ))}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ),
      },
      {
        menuItem: 'Sentry',
        render: () => (
          <>
            <Button onClick={() => logSentryMessage('test sentry message')}>
              Trigger Sentry Message
            </Button>
            <br />
            <Button
              onClick={() => logSentryError(new Error('test sentry error'))}
            >
              Trigger Sentry Error
            </Button>
          </>
        ),
      },
      {
        menuItem: 'Grid',
        render: () => <GridStories />,
      },
    ],
    [showProps]
  )

  return (
    <Container id="HeardStorybook">
      <Card>
        <Text as="h3" style={{ marginBottom: '1em' }}>
          Heard Storybook
        </Text>
        <Toggle
          checked={showProps}
          label="Show props"
          onClick={() => setShowProps((checked) => !checked)}
        />
        <br />
        <br />
        <Tab
          footer={<Text as="h3">This is a global footer for all tabs</Text>}
          header={<Text as="h2">This is a global header for all tabs</Text>}
          panes={panes}
        />
      </Card>
    </Container>
  )
}

export default Storybook
