import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Alert, Icon } from '../../../../../components/BaseComponents'
import { CSSProperties, ReactNode } from 'react'

const DocumentAddedAlert = ({ children }: { children: ReactNode }) => {
  return (
    <Alert customIcon={<Icon icon={regular('file-circle-plus')} size="1x" />}>
      {children}
    </Alert>
  )
}

export const TeamReachOutAlert = ({ children }: { children: ReactNode }) => {
  return (
    <Alert customIcon={<Icon icon={solid('info-circle')} size="1x" />}>
      {children}
    </Alert>
  )
}

export const CalculationAlert = ({
  children,
  contentStyle,
  innerTextStyle,
}: {
  children: ReactNode
  contentStyle?: CSSProperties
  innerTextStyle?: CSSProperties
}) => {
  return (
    <Alert
      contentStyle={contentStyle}
      innerTextStyle={innerTextStyle}
      customIcon={<Icon icon={regular('calculator')} />}
    >
      {children}
    </Alert>
  )
}

export default DocumentAddedAlert
