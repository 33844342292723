import { useState } from 'react'
import Checkbox from '../components/BaseComponents/Checkbox'
import Radio from '../components/BaseComponents/Radio'
import Toggle from '../components/BaseComponents/Toggle'

export const CheckboxDefault = () => {
  const [checked, setChecked] = useState(false)

  return (
    <Checkbox
      checked={checked}
      onChange={setChecked}
      label="Checkbox label"
      variant="default"
    />
  )
}

export const CheckboxLongLabel = () => {
  const [checked, setChecked] = useState(false)

  return (
    <Checkbox
      checked={checked}
      onChange={setChecked}
      label="Checkbox label that is really long and long and long and long"
      variant="default"
    />
  )
}

export const CheckboxOld = () => {
  const [checked, setChecked] = useState(false)

  return (
    <Checkbox checked={checked} onChange={setChecked} label="Checkbox label" />
  )
}

export const CheckboxDisabled = () => (
  <Checkbox checked={false} disabled onChange={() => {}} />
)

export const CheckboxDisabledChecked = () => (
  <Checkbox checked disabled onChange={() => {}} />
)

export const CheckboxError = () => {
  const [checked, setChecked] = useState(false)

  return <Checkbox checked={checked} error onChange={setChecked} />
}

export const ToggleCheckbox = () => {
  const [checked, setChecked] = useState(false)

  return <Toggle checked={checked} onChange={setChecked} />
}

export const ToggleCheckboxDisabled = () => (
  <Toggle checked={false} disabled onChange={() => {}} />
)

export const ToggledCheckboxDisabled = () => (
  <Toggle checked disabled onChange={() => {}} />
)

export const RadioCheckbox = () => {
  const [checked, setChecked] = useState(false)

  return <Radio checked={checked} onChange={setChecked} />
}

export const RadioCheckboxDisabled = () => (
  <Radio checked={false} disabled onChange={() => {}} />
)

export const RadioCheckboxCheckedDisabled = () => (
  <Radio checked disabled onChange={() => {}} />
)

export const RadioCheckboxError = () => {
  const [checked, setChecked] = useState(false)

  return <Radio checked={checked} error onChange={setChecked} />
}
