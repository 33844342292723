import { useMemo } from 'react'
import moment from 'moment/moment'

import { Table, Text } from '../../../components/BaseComponents'
import { PayrollProfile } from '../payrollProfile.slice'
import { Colors } from '../../../styles/theme'

const NET_NEW_FORECAST = 10
const MIGRATED_FORECAST = 20

const getCellColor = ({
  month,
  expectedCount,
  actualCount,
}: {
  month: string
  actualCount: number
  expectedCount: number
}) => {
  const isCurrentMonth = moment().format('MMM') === month
  const isUpcomingMonth = moment(month, 'MMM').isAfter(moment().endOf('month'))

  if (isUpcomingMonth) {
    return Colors.white
  }

  if (!isCurrentMonth) {
    return actualCount < expectedCount ? Colors.lightRed : Colors.lightGreen
  }

  return actualCount < expectedCount ? Colors.lightOrange : Colors.lightGreen
}

const PayrollForecast = ({
  payrollProfiles,
}: {
  payrollProfiles: PayrollProfile[]
}) => {
  const monthMap = useMemo(
    () =>
      moment.monthsShort().map((month) => {
        const formattedMonth = `${month}-${moment().year()}`
        const filteredProfiles = payrollProfiles.filter(
          (profile) =>
            moment.utc(profile.onboardedAt).format('MMM-YYYY') ===
            formattedMonth
        )
        return {
          month,
          migrated: filteredProfiles.filter(
            (profile) => profile.migratedFromGusto
          ).length,
          netNew: filteredProfiles.filter(
            (profile) => !profile.migratedFromGusto
          ).length,
        }
      }),
    [payrollProfiles]
  )

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.Cell />
          {moment.monthsShort().map((month) => (
            <Table.Cell key={month}>{month}</Table.Cell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Row>
        <Table.Cell>Migrated</Table.Cell>
        {monthMap.map((monthData) => (
          <Table.Cell
            key={`migrated-${monthData.month}`}
            style={{
              backgroundColor: getCellColor({
                month: monthData.month,
                actualCount: monthData.migrated,
                expectedCount: MIGRATED_FORECAST,
              }),
            }}
          >
            <Text>
              <b>Forecast:</b> {MIGRATED_FORECAST}
            </Text>
            <Text>
              <b>Actual:</b> {monthData.migrated}
            </Text>
          </Table.Cell>
        ))}
      </Table.Row>
      <Table.Row>
        <Table.Cell>Net New Heard Payroll</Table.Cell>
        {monthMap.map((monthData) => (
          <Table.Cell
            key={`netNew-${monthData.month}`}
            style={{
              backgroundColor: getCellColor({
                month: monthData.month,
                actualCount: monthData.netNew,
                expectedCount: NET_NEW_FORECAST,
              }),
            }}
          >
            <Text>
              <b>Forecast:</b> {NET_NEW_FORECAST}
            </Text>
            <Text>
              <b>Actual:</b> {monthData.netNew}
            </Text>
          </Table.Cell>
        ))}
      </Table.Row>
      <Table.Row>
        <Table.Cell>Total Added for Month</Table.Cell>
        {monthMap.map((monthData) => (
          <Table.Cell
            key={`total-${monthData.month}`}
            style={{
              backgroundColor: getCellColor({
                month: monthData.month,
                actualCount: monthData.migrated + monthData.netNew,
                expectedCount: NET_NEW_FORECAST + MIGRATED_FORECAST,
              }),
            }}
          >
            <Text>
              <b>Forecast:</b> {NET_NEW_FORECAST + MIGRATED_FORECAST}
            </Text>
            <Text>
              <b>Actual:</b> {monthData.migrated + monthData.netNew}
            </Text>
          </Table.Cell>
        ))}
      </Table.Row>
      <Table.Row>
        <Table.Cell>Total Cumulative</Table.Cell>
        {monthMap.map((monthData, index) => {
          const actualCount = monthMap
            .slice(0, index + 1)
            .reduce((prev, data) => data.netNew + data.migrated + prev, 0)
          const expectedCount =
            (NET_NEW_FORECAST + MIGRATED_FORECAST) * (index + 1)

          return (
            <Table.Cell
              key={`cumulative-${monthData.month}`}
              style={{
                backgroundColor: getCellColor({
                  month: monthData.month,
                  actualCount,
                  expectedCount,
                }),
              }}
            >
              <Text>
                <b>Forecast:</b> {expectedCount}
              </Text>
              <Text>
                <b>Actual:</b> {actualCount}
              </Text>
            </Table.Cell>
          )
        })}
      </Table.Row>
    </Table>
  )
}

export default PayrollForecast
