import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

// BL
import { DocumentTabPane } from '.'
import { taxPane as copy } from '../copyConstants'
import {
  closeUploadModal,
  openUploadModal,
  useFeatureDispatch,
  useFeatureState,
} from '../state'
import { TabCategoryKeys as ModalTypes } from '../constants'

// UI
import { DocumentSearchBarOnChangeData } from '../sharedComponents/DocumentsSearchBar'
import TabPaneToolbar from '../sharedComponents/TabPaneToolbar'
import UploadDocumentButton from '../sharedComponents/Buttons/UploadDocumentButton'
import UserDocumentsTable from '../sharedComponents/Table/UserDocumentsTable'
import { TaxOrIncorporationRow } from '../sharedComponents/Table/UserDocumentsTableRow'
import FileUploadModal from '../../../components/FileUpload/FileUploadModal'
import { UploadDocumentType } from '../../../constants/businessConstants'
import { useAnalyticsTrack } from '../../Amplitude'
import { fetchTaxUserDocumentsIfNeeded } from '../../Taxes/AnnualTaxes/taxUserDocuments.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'

const Tax: DocumentTabPane = ({ tableOptions, documents }) => {
  const dispatch = useFeatureDispatch()
  const appDispatch = useAppDispatch()
  const [filteredDocuments, setFilteredDocuments] = useState(documents)
  const { activeUploadModal, userDocumentCategoryId } = useFeatureState()
  const [searchParams] = useSearchParams()
  const track = useAnalyticsTrack()

  useEffect(() => {
    const documentCategoryId = searchParams.get('documentCategoryId')
    if (documentCategoryId)
      dispatch?.(openUploadModal(ModalTypes.TAX, parseInt(documentCategoryId)))
  }, [searchParams, dispatch])

  useEffect(() => {
    appDispatch(fetchTaxUserDocumentsIfNeeded())
  }, [appDispatch])

  useEffect(() => {
    setFilteredDocuments(documents)
  }, [documents])

  const onSearch = useCallback((data: DocumentSearchBarOnChangeData) => {
    setFilteredDocuments(data.filtered)
  }, [])

  const openModal = () => {
    track('clicked document upload', {
      document_category: ModalTypes.TAX,
      document_subcategory: '',
    })
    dispatch?.(openUploadModal(ModalTypes.TAX))
  }

  return (
    <>
      <TabPaneToolbar
        preamble="Share tax-related documents here, such as prior-year tax returns."
        documents={documents}
        onSearch={onSearch}
        rightElement={<UploadDocumentButton onClick={openModal} />}
      />
      <UserDocumentsTable
        columns={copy.static.tableColumns}
        documents={filteredDocuments}
        RowComponent={TaxOrIncorporationRow}
        tableOptions={tableOptions}
      />
      <FileUploadModal
        open={activeUploadModal === ModalTypes.TAX}
        close={() => dispatch?.(closeUploadModal())}
        documentType={UploadDocumentType.TAX}
        userFacing
        categoryId={userDocumentCategoryId}
      />
    </>
  )
}

Tax.paneDescription = copy.static.paneDescription
Tax.paneTitle = copy.static.paneTitle

export default Tax
