import {
  TransactionRuleType,
  TransactionRuleUse,
} from '../reducers/admin/transactionRulesReducer'
import { valsToDropdownOptions } from '../components/BaseComponents'

export const RULE_USE_OPTIONS = valsToDropdownOptions([
  TransactionRuleUse.CLARIFICATION,
  TransactionRuleUse.CATEGORIZATION,
])

export const RULE_TYPE_OPTIONS = valsToDropdownOptions([
  TransactionRuleType.EXACT_MATCH,
  TransactionRuleType.CONTAINS_WORD,
  TransactionRuleType.REGEX,
])
