import { useNavigate } from 'react-router-dom'
import { Grid, Icon } from 'semantic-ui-react'

import { selectContractorByUuid } from '../../payroll.selectors'
import ContractorPayrollHeader from './ContractorPayrollHeader'
import ContractorPayrollOverview from './ContractorPayrollOverview'
import { getContractorWages } from '../../helpers'
import { Button, Text } from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'

const ContPayrollConfirmationTab = ({
  contractorUuid,
  hours,
  wage,
  bonus,
  reimbursement,
  payDate,
}: {
  contractorUuid: string
  payDate: string
  hours: string
  wage: string
  bonus: string
  reimbursement: string
}) => {
  const navigate = useNavigate()
  const contractor = useReselector(selectContractorByUuid, contractorUuid)

  if (!contractor) {
    return null
  }

  const { total } = getContractorWages({
    contractor,
    bonus,
    hours,
    wage,
    reimbursement,
  })

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <ContractorPayrollHeader currentTab={3} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Text as="h1">You&apos;re all set!</Text>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Text as="h3">
            We&apos;ll debit funds on the date listed below to pay your
            contractors
          </Text>
        </Grid.Column>
      </Grid.Row>
      <ContractorPayrollOverview payDate={payDate} total={total} />
      <Grid.Row />
      <Grid.Row>
        <Grid.Column width={4}>
          <Button
            variant="secondary"
            onClick={() => navigate('/payroll/history?tab=contractor')}
            fullWidth
          >
            View Payment History
          </Button>
        </Grid.Column>
        <Grid.Column width={4}>
          <Button onClick={() => navigate('/payroll/run_payroll')} fullWidth>
            <Icon name="arrow left" />
            Back to Dashboard
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row />
    </Grid>
  )
}

export default ContPayrollConfirmationTab
