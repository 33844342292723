import { isEmpty } from 'lodash'
import { formatCurrencyFromCents } from '../../../../utils/currencyHelpers'
import { UserDocument } from '../../../UserDocuments/userDocuments.slice'
import { UserTaxEstimate } from '../../QuarterlyTaxEstimates/userTaxEstimates.slice'
import { NO_STATE_QUARTERLY_TAX_ESTIMATES } from '../../taxConstants'
import { AdditionalQuestionIds } from '../TaxChecklist/service'

import { splitTaxYearQuarter } from '../../QuarterlyTaxEstimates/helpers'

const getQuestionFromAdditionalQuestionIds = (
  questionId: AdditionalQuestionIds,
  prevTaxYear?: number
) => {
  switch (questionId) {
    case AdditionalQuestionIds.previous_year_business_return_names:
      return `Your ${prevTaxYear} Business Tax Return(s)`
    case AdditionalQuestionIds.previous_year_personal_return_names:
      return `Your ${prevTaxYear} Personal Tax Return(s)`
    case AdditionalQuestionIds.official_government_id_filename:
      return 'Official Government ID'
    case AdditionalQuestionIds.official_government_id_spouse_filename:
      return 'Spouse Official Government ID'
    default:
      return ''
  }
}

const getQuestionAndResponseForAdditionalQsDocuments = ({
  questionId,
  prevTaxYear,
  userDocuments,
}: {
  questionId: AdditionalQuestionIds
  prevTaxYear: number
  userDocuments: UserDocument[]
}) => {
  return {
    questionId,
    question: getQuestionFromAdditionalQuestionIds(questionId, prevTaxYear),
    response: isEmpty(userDocuments)
      ? "I don't have this"
      : userDocuments.map((doc) => doc.fileDescription).join(', '),
  }
}

export const getListOfDocumentQuestionsAndResponses = ({
  taxYear,
  prevYearPersonalReturns,
  govIds,
  spouseGovIds,
  prevYearBizReturns,
}: {
  taxYear: string
  prevYearPersonalReturns?: UserDocument[]
  govIds: UserDocument[]
  spouseGovIds: UserDocument[]
  prevYearBizReturns?: UserDocument[]
}) => {
  const documentQuestionsAndResponses = []
  const prevTaxYear = Number(taxYear) - 1
  if (prevYearBizReturns !== undefined) {
    documentQuestionsAndResponses.push(
      getQuestionAndResponseForAdditionalQsDocuments({
        questionId: AdditionalQuestionIds.previous_year_business_return_names,
        prevTaxYear,
        userDocuments: prevYearBizReturns,
      })
    )
  }
  if (prevYearPersonalReturns !== undefined) {
    documentQuestionsAndResponses.push(
      getQuestionAndResponseForAdditionalQsDocuments({
        questionId: AdditionalQuestionIds.previous_year_personal_return_names,
        prevTaxYear,
        userDocuments: prevYearPersonalReturns,
      })
    )
  }
  documentQuestionsAndResponses.push(
    getQuestionAndResponseForAdditionalQsDocuments({
      questionId: AdditionalQuestionIds.official_government_id_filename,
      prevTaxYear,
      userDocuments: govIds,
    })
  )
  if (spouseGovIds?.length > 0) {
    documentQuestionsAndResponses.push(
      getQuestionAndResponseForAdditionalQsDocuments({
        questionId:
          AdditionalQuestionIds.official_government_id_spouse_filename,
        prevTaxYear,
        userDocuments: spouseGovIds,
      })
    )
  }
  return documentQuestionsAndResponses
}

const estimateResponseText = (estimate: UserTaxEstimate) => {
  return estimate.quarterPaid
    ? formatCurrencyFromCents(estimate.amountPaidInCents)
    : 'I did not make a payment this quarter'
}

export const getQuestionAndResponseForAdditionalQsTaxEstimates = (
  userTaxEstimates: UserTaxEstimate[]
) => {
  const quarterEstimatedTaxQuestionAndResponse = []

  for (const estimate of userTaxEstimates) {
    if (
      estimate.filingState &&
      NO_STATE_QUARTERLY_TAX_ESTIMATES.includes(estimate.filingState)
    ) {
      continue
    }
    const { quarter: quarterString } = splitTaxYearQuarter(estimate.taxQuarter)

    const quarter = `Q${quarterString}`
    if (estimate.filingState) {
      const state = estimate.filingState
      quarterEstimatedTaxQuestionAndResponse.push({
        questionId: `${quarter}_${state}_estimated_tax`,
        question: `${quarter} ${state} Tax Payment Amount `,
        response: estimateResponseText(estimate),
      })
    } else {
      const text = `${quarter} Federal Tax Payment Amount`
      switch (quarter) {
        case 'Q1':
          quarterEstimatedTaxQuestionAndResponse.push({
            questionId: AdditionalQuestionIds.Q1_federal_estimated_tax_payment,
            question: text,
            response: estimateResponseText(estimate),
          })
          break
        case 'Q2':
          quarterEstimatedTaxQuestionAndResponse.push({
            questionId: AdditionalQuestionIds.Q2_federal_estimated_tax_payment,
            question: text,
            response: estimateResponseText(estimate),
          })
          break
        case 'Q3':
          quarterEstimatedTaxQuestionAndResponse.push({
            questionId: AdditionalQuestionIds.Q3_federal_estimated_tax_payment,
            question: text,
            response: estimateResponseText(estimate),
          })
          break
        case 'Q4':
          quarterEstimatedTaxQuestionAndResponse.push({
            questionId: AdditionalQuestionIds.Q4_federal_estimated_tax_payment,
            question: text,
            response: estimateResponseText(estimate),
          })
          break
        default:
          break
      }
    }
  }
  return quarterEstimatedTaxQuestionAndResponse
}
