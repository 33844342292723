import { createSelector } from 'reselect'
import { orderBy } from 'lodash'
import moment from 'moment'

import { ReduxState } from '../../../utils/typeHelpers'
import { AnnualTaxDetailStatuses } from './annualTaxDetails.slice'
import { selectMembershipStart } from '../../../selectors/user.selectors'
import { DateTime } from 'luxon'
import { isoToUTCDateTime } from '../../../utils/dateHelpers'
import {
  TAX_ENTITY_TYPES,
  TAX_ENTITY_TYPES_TYPE,
} from '../../Taxes/taxConstants'

export const selectAnnualTaxDetails = (state: ReduxState) =>
  state.annualTaxDetails

/*
  Select the single "current" record if exists
*/
export const selectCurrentAnnualTaxDetails = createSelector(
  [selectAnnualTaxDetails],
  (annualTaxDetails) =>
    Object.values(annualTaxDetails).find(
      (d) => d.status === AnnualTaxDetailStatuses.current
    )
)

/*
  Select the single "current" year if exists, if not default
*/
export const selectCurrentAnnualTaxYear = createSelector(
  [selectCurrentAnnualTaxDetails],
  (annualTaxDetails): string => {
    const now = moment()
    const taxYearDefault =
      now.month() < 10
        ? now.subtract(1, 'years').year().toString()
        : now.year().toString()
    return annualTaxDetails?.taxYear || taxYearDefault
  }
)

/*
  Selects all records sorted by taxYear desc
*/
export const selectAllAnnualTaxDetailsSorted = createSelector(
  [selectAnnualTaxDetails],
  (annualTaxDetails) => {
    return orderBy(
      Object.values(annualTaxDetails),
      [(d) => Number(d.taxYear)],
      ['desc']
    )
  }
)

/*
   Select by year
*/
export const selectTaxDetailsByYear = createSelector(
  selectAnnualTaxDetails,
  (_: unknown, year: string) => year,
  (annualTaxDetails, year) => {
    return Object.values(annualTaxDetails).find((d) => d.taxYear === year)
  }
)

export const selectIsCurrentTaxYear = createSelector(
  selectTaxDetailsByYear,
  (annualTaxDetails) =>
    Boolean(
      annualTaxDetails &&
        annualTaxDetails.status === AnnualTaxDetailStatuses.current
    )
)

export const selectIsLateJoinerByYear = createSelector(
  selectTaxDetailsByYear,
  selectMembershipStart,
  (annualTaxDetails, membershipStart) =>
    Boolean(
      annualTaxDetails?.newUserCutOffAt &&
        membershipStart &&
        DateTime.fromISO(annualTaxDetails.newUserCutOffAt).startOf('day') <
          DateTime.fromISO(membershipStart)
    )
)

export const selectCanOptIntoPreviousTaxYear = createSelector(
  selectCurrentAnnualTaxDetails,
  (_: unknown, taxEntity?: TAX_ENTITY_TYPES_TYPE | null) => taxEntity,
  (taxDetails, taxEntity) => {
    if (!taxDetails) {
      return false
    }
    const optInCutOff =
      taxEntity === TAX_ENTITY_TYPES.form_1040
        ? isoToUTCDateTime(
            taxDetails.taxQuestionnaireDueDates?.form_1040.endAt ??
              `${Number(taxDetails.taxYear) + 1}-04-01`
          )
        : isoToUTCDateTime(
            taxDetails.taxQuestionnaireDueDates?.form_1120_s.endAt ??
              `${Number(taxDetails.taxYear) + 1}-03-01`
          )
    return (
      DateTime.now() > isoToUTCDateTime(taxDetails.newUserCutOffAt) &&
      DateTime.now() <= optInCutOff.endOf('day').plus({ months: 3 })
    )
  }
)
