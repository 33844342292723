import styled from 'styled-components'
import { Loader } from 'semantic-ui-react'
import { Icon, Text } from '../../../../components/BaseComponents'
import { Colors } from '../../../../styles/theme'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectCatchUpBookkeepingIsCompleteForUser } from '../../../CatchUpBookkeepingStatus/catchUpBookkeepingStatus.selector'
import { getCurrentUser } from '../../../../selectors/user.selectors'
import { getIsFetchingOrNotStarted } from '../../../../reducers/fetch'
import { FETCH_USER_CATCH_UP_BOOKKEEPING_STATUS } from '../../../CatchUpBookkeepingStatus/catchUpBookkeepingStatus.slice'

const BookkeepingStatusWrapper = styled.div`
  border-radius: 100px;
  background: ${Colors.stone40};
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 24px;
  margin-top: 16px;
`
const completeIcon = <Icon icon={solid('circle-check')} />
const refreshIcon = <Icon icon={solid('refresh')} />

const CatchUpBookkeepingStatus = () => {
  const currentUser = useReselector(getCurrentUser)
  const catchUpBKComplete = useReselector(
    selectCatchUpBookkeepingIsCompleteForUser,
    currentUser?.id
  )
  const fetchingStatus = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_USER_CATCH_UP_BOOKKEEPING_STATUS
  )

  return (
    <BookkeepingStatusWrapper>
      {fetchingStatus ? (
        <div style={{ height: '4em', padding: '1em' }}>
          <Loader active inline />
        </div>
      ) : (
        <>
          {catchUpBKComplete ? completeIcon : refreshIcon}
          <div>
            <Text as="bodySm" style={{ fontWeight: 500 }}>
              {catchUpBKComplete
                ? 'Your catch-up bookkeeping is complete!'
                : 'Catch-up bookkeeping in progress'}
            </Text>
            <Text as="bodyXs">
              {catchUpBKComplete
                ? 'Complete the rest of your onboarding below to get the most out of Heard.'
                : 'We’ll let you know if we need to you to clarify something.'}
            </Text>
          </div>
        </>
      )}
    </BookkeepingStatusWrapper>
  )
}

export default CatchUpBookkeepingStatus
