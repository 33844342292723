import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { shallowEqual } from 'react-redux'
import { createSelector } from 'reselect'
import { filter } from 'lodash'

import {
  ReduxState,
  Dispatch,
  GetState,
  filterNulls,
} from '../utils/typeHelpers'
import {
  DEFAULT_ERROR,
  handleApiError,
  parseErrorFromCatch,
} from '../utils/errorHelpers'
import { isAxiosError } from 'axios'

// See styleguide.md for more information about this slice
export interface FetchState {
  [key: string]: {
    isLoading: boolean
    didInvalidate: boolean
    receivedAt: number | null
    error?: {
      message: string
      statusCode?: number
    }
  }
}

const fetchSlice = createSlice({
  name: 'fetch',
  initialState: {} as FetchState,
  reducers: {
    resolveFetch: (state, action: PayloadAction<string>) => ({
      ...state,
      [action.payload]: {
        isLoading: false,
        didInvalidate: false,
        receivedAt: Date.now(),
      },
    }),
    rejectFetch: (
      state,
      action: PayloadAction<{
        key: string
        error: {
          message: string
          statusCode?: number
        }
      }>
    ) => ({
      ...state,
      [action.payload.key]: {
        isLoading: false,
        didInvalidate: false,
        receivedAt: Date.now(),
        error: action.payload.error,
      },
    }),
    startFetch: (state, action: PayloadAction<string>) => ({
      ...state,
      [action.payload]: {
        isLoading: true,
        didInvalidate: false,
        receivedAt: null,
        error: undefined,
      },
    }),
    invalidateFetch: (state, action: PayloadAction<string>) => ({
      ...state,
      [action.payload]: {
        isLoading: false,
        didInvalidate: true,
        receivedAt: null,
        error: undefined,
      },
    }),
  },
})

export default fetchSlice.reducer

export const { resolveFetch, rejectFetch, startFetch, invalidateFetch } =
  fetchSlice.actions

export const getAllFetchState = (state: ReduxState) => state.fetch

const getFetchStateForKey = createSelector(
  getAllFetchState,
  (_: unknown, key: string | undefined) => key,
  (fetchState, fetchKey) => (fetchKey ? fetchState[fetchKey] : undefined)
)

export const shouldFetch = createSelector(
  getFetchStateForKey,
  (fetchStateForKey) =>
    !fetchStateForKey ||
    fetchStateForKey.didInvalidate ||
    (!fetchStateForKey.receivedAt && !fetchStateForKey.isLoading)
)

export const getIsFetching = createSelector(
  getFetchStateForKey,
  (fetchStateForKey) => Boolean(fetchStateForKey?.isLoading)
)

export const getFetchError = createSelector(
  getFetchStateForKey,
  (fetchStateForKey) => fetchStateForKey?.error
)

export const selectFetchSuccess = createSelector(
  getFetchStateForKey,
  (fetchStateForKey) =>
    fetchStateForKey &&
    !fetchStateForKey.didInvalidate &&
    !fetchStateForKey.isLoading &&
    !fetchStateForKey.error
)

export const getIsFetchingOrNotStarted = createSelector(
  getFetchStateForKey,
  (fetchStateForKey) =>
    !fetchStateForKey || Boolean(fetchStateForKey?.isLoading)
)

export const selectFetchStateForKeys = createSelector(
  getAllFetchState,
  (_: unknown, keys: string[]) => keys,
  (fetchState, keys) => filter(fetchState, (_, key) => keys.includes(key)),
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  }
)

export const selectErrorsForKeys = createSelector(
  selectFetchStateForKeys,
  (fetchStates) =>
    filterNulls(fetchStates.map((fetchStateForKey) => fetchStateForKey.error))
)

export const selectFirstErrorMessageForKeys = createSelector(
  selectErrorsForKeys,
  (errors) => errors.find((err) => err)?.message
)

export const selectIsFetchingForKeys = createSelector(
  selectFetchStateForKeys,
  (fetchStates) =>
    fetchStates.some((fetchStateForKey) => fetchStateForKey?.isLoading)
)

// If `fetchWrapper` does not handle errors only the result of `fetchFunction` (T) can be returned.
export function fetchWrapper<T, U = undefined>(_: {
  fetchFunction: (_: Dispatch) => Promise<T> | T
  fetchKey?: string
  defaultErrorMessage?: string
  overrideErrorMessage?: string
  defaultValue?: U
  shouldHandleError: false
  handleError?: (_: Dispatch) => void
}): (_: Dispatch) => Promise<T>

// If `fetchWrapper` handles errors the result of `fetchFunction` (T) or default value (U) can be returned.
export function fetchWrapper<T, U = undefined>(_: {
  fetchFunction: (_: Dispatch) => Promise<T> | T
  fetchKey?: string
  defaultErrorMessage?: string
  overrideErrorMessage?: string
  defaultValue?: U
  shouldHandleError?: boolean
  handleError?: (_: Dispatch) => void
}): (_: Dispatch) => Promise<T | U>

export function fetchWrapper<T, U = undefined>({
  fetchKey,
  defaultErrorMessage = DEFAULT_ERROR,
  overrideErrorMessage,
  fetchFunction,
  defaultValue,
  shouldHandleError = true,
  handleError,
}: {
  fetchFunction: (_: Dispatch) => Promise<T> | T
  fetchKey?: string
  defaultErrorMessage?: string
  overrideErrorMessage?: string
  defaultValue?: U
  shouldHandleError?: boolean
  handleError?: (_: Dispatch) => void
}): (_: Dispatch) => Promise<T | U> {
  return async function (dispatch: Dispatch) {
    if (fetchKey) {
      dispatch(startFetch(fetchKey))
    }

    try {
      const result = await fetchFunction(dispatch)
      if (fetchKey) {
        dispatch(resolveFetch(fetchKey))
      }
      return result
    } catch (error) {
      const parsedError = parseErrorFromCatch(error)
      const statusCode = isAxiosError(error)
        ? error.response?.status
        : undefined
      if (fetchKey) {
        dispatch(
          rejectFetch({
            key: fetchKey,
            error: {
              message:
                overrideErrorMessage || parsedError || defaultErrorMessage,
              statusCode,
            },
          })
        )
      }

      dispatch(handleApiError(error))
      handleError?.(dispatch)

      if (!shouldHandleError) {
        throw error
      }
      return defaultValue as U
    }
  }
}

export const fetchIfNeededWrapper =
  <T, U = undefined>({
    fetchKey,
    defaultErrorMessage,
    overrideErrorMessage,
    fetchFunction,
    defaultValue,
    defaultValueSelector,
    shouldHandleError = true,
    alwaysFetch = false,
  }: {
    fetchFunction: (_: Dispatch) => Promise<T> | T
    fetchKey: string
    defaultErrorMessage?: string
    overrideErrorMessage?: string
    defaultValue?: U
    defaultValueSelector?: (state: ReduxState) => U
    shouldHandleError?: boolean
    alwaysFetch?: boolean
  }) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState()
    const newDefaultValue = defaultValueSelector
      ? defaultValueSelector(state)
      : defaultValue

    if (!alwaysFetch && !shouldFetch(state, fetchKey)) {
      return Promise.resolve(newDefaultValue as U)
    }

    return fetchWrapper<T, U>({
      fetchKey,
      defaultErrorMessage,
      overrideErrorMessage,
      fetchFunction,
      defaultValue: newDefaultValue,
      shouldHandleError,
    })(dispatch)
  }
