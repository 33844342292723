import { useEffect, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { Grid } from 'semantic-ui-react'
import * as yup from 'yup'

import {
  Alert,
  Button,
  FormikCheckbox,
  FormikDropdown,
  FormikLabelError,
  getFieldName,
  GridRowColumn,
  Link,
  Loader,
  Modal,
  Text,
} from '../../../../components/BaseComponents'
import { fetchUserDocumentCategoriesIfNeeded } from '../../UserDocumentCategories/userDocumentCategories.slice'
import { fetchAdminUsersDocuments } from '../../../../actions/admin/userDocumentsActions'
import {
  ADMIN_UPDATE_ANNUAL_TAX_FILING_KEY,
  AdminAnnualTaxFiling,
  updateAdminAnnualTaxFiling,
} from '../../Taxes/adminAnnualTaxFilings.slice'
import { selectAdminTaxReturnDocumentsForTaxYear } from '../../../UserDocuments/userDocuments.selector'
import { useReselector } from '../../../../utils/sharedHooks'
import { TaxFormType } from '../../../Taxes/AnnualTaxes/Questionnaires/constants'
import {
  ConfirmAnnualTaxFiling,
  needContractorFilingOption,
} from '../../../Taxes/AnnualTaxes/annualTaxFilings.slice'
import { getFetchError, getIsFetching } from '../../../../reducers/fetch'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../../utils/dateHelpers'
import { getUserById } from '../../../../selectors/user.selectors'
import { selectTaxDetailsByYear } from '../annualTaxDetails.selector'
import { fetchTaxUserDocumentsIfNeededForUser } from '../../../Taxes/AnnualTaxes/taxUserDocuments.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const TaxSeasonKickoffModal = ({
  open,
  close,
  filing,
}: {
  open: boolean
  close: () => void
  filing: AdminAnnualTaxFiling
}) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const isSubmitting = useReselector(
    getIsFetching,
    ADMIN_UPDATE_ANNUAL_TAX_FILING_KEY
  )
  const error = useReselector(getFetchError, ADMIN_UPDATE_ANNUAL_TAX_FILING_KEY)
  const user = useReselector(getUserById, filing.userId)
  const taxDetails = useReselector(selectTaxDetailsByYear, filing.year)
  const userStartedAfterCutoff =
    taxDetails?.newUserCutOffAt &&
    user?.createdAt &&
    isoToUTCDateTime(user?.createdAt) >
      isoToUTCDateTime(taxDetails?.newUserCutOffAt)
  const [financialProfileId, setFinancialProfileId] = useState(-1)

  const formik = useFormik({
    initialValues: {
      annualTaxFormNeeds: filing.annualTaxFormNeeds,
      confirmedFormNeeds: filing.confirmedFormNeeds,
      optedOut: Boolean(filing.optedOutAt),
      needContractorFiling: filing.needContractorFiling,
      isLateJoiner: Boolean(filing.isLateJoiner),
    },
    validateOnMount: true,
    onSubmit: async ({
      annualTaxFormNeeds,
      confirmedFormNeeds,
      needContractorFiling,
      optedOut,
      isLateJoiner,
    }) => {
      const res = await updateAdminAnnualTaxFiling(filing.id, {
        annualTaxFormNeeds,
        needContractorFiling,
        // This val is set as undefined on clear but we want to set it as null
        confirmedFormNeeds: confirmedFormNeeds || null,
        // Don't overwrite opted out date if it was set previously
        optedOutAt:
          !filing.optedOutAt && optedOut
            ? new Date().toISOString()
            : filing.optedOutAt && !optedOut
              ? null
              : undefined,
        // If opted out, late joiner should be cleared
        isLateJoiner:
          optedOut && !filing.optedOutAt
            ? null
            : Boolean(filing.isLateJoiner) !== isLateJoiner
              ? isLateJoiner
              : undefined,
      })(dispatch)
      if (res) {
        close()
      }
    },
  })

  useEffect(() => {
    const fetch = async () => {
      const [_catRes, userDocRes, _taxUserDocRes] = await Promise.all([
        dispatch(fetchUserDocumentCategoriesIfNeeded()),
        dispatch(fetchAdminUsersDocuments(filing.userId)),
        dispatch(
          fetchTaxUserDocumentsIfNeededForUser(filing.userId, filing.year)
        ),
      ])
      if (userDocRes?.financialProfileId) {
        setFinancialProfileId(userDocRes.financialProfileId)
      }

      setLoading(false)
    }

    if (open) {
      fetch()
    }
  }, [dispatch, filing, open])

  const taxReturnsForYear = useReselector(
    selectAdminTaxReturnDocumentsForTaxYear,
    financialProfileId,
    filing.year
  )
  const { values, submitForm, setFieldValue, isValid } = formik

  return (
    <FormikProvider value={formik}>
      <Modal onClose={close} open={open} closeIcon size="tiny">
        <Modal.Header>Tax Season Kickoff: {filing.userId}</Modal.Header>
        <Modal.Content>
          <Grid>
            {loading && (
              <GridRowColumn>
                <Loader />
              </GridRowColumn>
            )}
            {error && (
              <GridRowColumn>
                <Alert>{error.message}</Alert>
              </GridRowColumn>
            )}
            <GridRowColumn>
              <FormikDropdown
                label="Annual Tax Form Needs:"
                name={getFieldName<typeof values>('annualTaxFormNeeds')}
                optionValues={[TaxFormType.form1120s, TaxFormType.form1040]}
                multiple
                clearable
                fullWidth
                onChange={(val) => {
                  // If options are edited set opted out at as false
                  if (Array.isArray(val) && val.length) {
                    setFieldValue('optedOut', false)
                  }
                }}
              />
              <Text>Currently Saved Values:</Text>
              <ul>
                {filing.annualTaxFormNeeds?.map((formNeed) => (
                  <li key={formNeed}>
                    <Text>{formNeed}</Text>
                  </li>
                ))}
              </ul>
            </GridRowColumn>
            <GridRowColumn>
              <FormikDropdown
                label="User has confirmed their form needs:"
                description="If this is no/unsure (1) set tax form needs (2) clear this val to unblock the TSK:"
                name={getFieldName<typeof values>('confirmedFormNeeds')}
                optionValues={[
                  ConfirmAnnualTaxFiling.yes,
                  ConfirmAnnualTaxFiling.no,
                  ConfirmAnnualTaxFiling.unsure,
                ]}
                clearable
                fullWidth
              />
              <Text>Currently Saved Value: {filing.confirmedFormNeeds}</Text>
            </GridRowColumn>
            <GridRowColumn>
              <FormikLabelError
                name={getFieldName<typeof values>('optedOut')}
                schema={yup
                  .boolean()
                  .test(
                    'opted out',
                    "If there are no tax needs you must opt out.  If you don't mean to opt out this user, then set form needs",
                    (value) =>
                      value || Boolean(values.annualTaxFormNeeds?.length)
                  )}
              />
              <FormikCheckbox
                variant="default"
                name={getFieldName<typeof values>('optedOut')}
                label="Opted out"
                onChange={(val) => {
                  // Clear form needs if opted out
                  if (val) {
                    setFieldValue(
                      getFieldName<typeof values>('annualTaxFormNeeds'),
                      null
                    )
                    setFieldValue(
                      getFieldName<typeof values>('isLateJoiner'),
                      false
                    )
                  }
                }}
              />
              <Text>
                Currently Saved Value:{' '}
                {filing.optedOutAt
                  ? isoToUTCDateTime(filing.optedOutAt).toFormat(
                      DATE_FORMATS_LUXON.DISPLAY_LONG
                    )
                  : 'Not opted out'}
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              {Boolean(
                userStartedAfterCutoff &&
                  !values.isLateJoiner &&
                  !values.optedOut
              ) && (
                <Text as="bodySm" color="red">
                  This user may be a late joiner based on their createdAt date.
                  If so, please check this box.
                </Text>
              )}
              <FormikCheckbox
                variant="default"
                name={getFieldName<typeof values>('isLateJoiner')}
                label="Is Late Joiner"
                disabled={values.optedOut}
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikDropdown
                label="Needs contractor filing:"
                name={getFieldName<typeof values>('needContractorFiling')}
                optionValues={[
                  needContractorFilingOption.yes,
                  needContractorFilingOption.no,
                  needContractorFilingOption.unsure,
                ]}
                clearable
                fullWidth
              />
              <Text>Currently Saved Value: {filing.needContractorFiling}</Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text as="h3">{filing.year} Tax Returns</Text>
              {taxReturnsForYear?.length === 0 ? (
                <Text>
                  There are no PYTR for this User. Please instruct them to
                  upload
                </Text>
              ) : (
                <>
                  <Text>
                    Here are the {filing.year} returns that we have on file:
                  </Text>
                  {taxReturnsForYear.map((document) => (
                    <Link
                      href={document.signedUrl || '#'}
                      newPage
                      key={`docRow-${document.id}`}
                    >
                      {document.fileDescription}
                    </Link>
                  ))}
                </>
              )}
            </GridRowColumn>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={close}>Close</Button>
          <Button
            onClick={submitForm}
            variant="warning"
            loading={isSubmitting}
            disabled={!isValid}
          >
            Update TSK Responses
          </Button>
        </Modal.Actions>
      </Modal>
    </FormikProvider>
  )
}

export default TaxSeasonKickoffModal
