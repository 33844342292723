import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { hasIn, keyBy } from 'lodash'

import { FinancialProfile, User } from '../../../reducers/auth/userReducer'
import { fetchWrapper } from '../../../reducers/fetch'

export interface History {
  id: number
  modelId: number
  modelName: string
  operation: 'UPDATE' | 'CREATE' | 'DELETE'
  oldVersion: FinancialProfile | User
  newVersion: FinancialProfile | User
  changedBy: number
  createdAt: string
  deletedAt: string
  updatedAt: string
}

export function isVersionUser(item: User | FinancialProfile): item is User {
  return hasIn(item, 'email')
}

export interface HistoriesState {
  [key: string]: History
}

const historiesSlice = createSlice({
  name: 'histories',
  initialState: {} as HistoriesState,
  reducers: {
    receiveHistories: (
      state,
      action: PayloadAction<{ [key: string]: History }>
    ) => ({ ...state, ...action.payload }),
  },
})

export default historiesSlice.reducer

export const { receiveHistories } = historiesSlice.actions

export const FETCH_HISTORIES_KEY = 'FETCH_HISTORIES_KEY'
export const ADMIN_FETCH_HISTORIES_KEY = 'ADMIN_FETCH_HISTORIES_KEY'

export const fetchHistoriesForModel = ({
  modelName,
  newVersionJsonWhere,
}: {
  modelName: string
  newVersionJsonWhere: string
}) =>
  fetchWrapper({
    fetchKey: FETCH_HISTORIES_KEY + modelName,
    defaultErrorMessage: `Error fetching ${modelName} histories`,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<History[]>(
        `/finances/api/v1/histories?modelName=${modelName}&newVersionJsonWhere=${newVersionJsonWhere}`
      )

      dispatch(receiveHistories(keyBy(json.data, 'id')))

      return json.data
    },
  })
