import axios from 'axios'
import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit'
import { fetchWrapper } from '../../reducers/fetch'
import { ReduxState } from '../../utils/typeHelpers'
import { getCurrentUser } from '../../selectors/user.selectors'

export enum SmsConsentStatusOption {
  OPT_IN = 'opt_in',
  OPT_OUT = 'opt_out',
}

export enum SmsConsentSource {
  SIGN_UP = 'sign_up',
  LOGIN_MODAL = 'login_modal',
  ACTION_ITEM = 'action_item',
  PRACTICE_PROFILE = 'practice_profile',
}

export interface SmsConsentStatus {
  id: number
  userId: number
  mobilePhoneNumber?: string | null
  status: SmsConsentStatusOption
  source?: SmsConsentSource
  updatedAt: string
  createdAt: string
  deletedAt?: string
}

export interface SmsConsentStatusState {
  [userId: number]: SmsConsentStatus
}

const initialState: SmsConsentStatusState = {}

const userSmsConsentSlice = createSlice({
  name: 'smsConsentStatuses',
  initialState,
  reducers: {
    receiveSingleSmsConsent: (
      state,
      action: PayloadAction<SmsConsentStatus>
    ) => {
      state[action.payload.userId] = action.payload
    },
  },
})

// Exported so we can access within appReducer.ts
export default userSmsConsentSlice.reducer

// These functions are used to manipulate our Redux state
export const { receiveSingleSmsConsent } = userSmsConsentSlice.actions

const FETCH_USER_SMS_CONSENT_STATUS_KEY = 'FETCH_USER_SMS_CONSENT_STATUS_KEY'
const CREATE_USER_SMS_CONSENT_STATUS_KEY = 'CREATE_USER_SMS_CONSENT_STATUS_KEY'
const ADMIN_FETCH_USER_SMS_CONSENT_STATUS_KEY =
  'ADMIN_FETCH_USER_SMS_CONSENT_STATUS_KEY'

export const fetchSmsConsentStatus = () =>
  fetchWrapper({
    fetchKey: FETCH_USER_SMS_CONSENT_STATUS_KEY,
    defaultErrorMessage: 'Error fetching user sms consent status',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<SmsConsentStatus>(
        '/finances/api/v1/communications/sms/status'
      )
      dispatch(receiveSingleSmsConsent(json.data))
      return json.data
    },
  })

// This is used for both creating AND updating numbers.  If wants to change their permissions a new "record" is created.
// The BE will return the latest record
export const createSmsConsentStatus = (data: {
  mobilePhoneNumber?: string | null
  status: SmsConsentStatusOption
  source: SmsConsentSource
}) =>
  fetchWrapper({
    fetchKey: CREATE_USER_SMS_CONSENT_STATUS_KEY,
    defaultErrorMessage: 'Error creating user sms consent status',
    fetchFunction: async (dispatch) => {
      const json = await axios.post<SmsConsentStatus>(
        '/finances/api/v1/communications/sms/status',
        data
      )
      dispatch(receiveSingleSmsConsent(json.data))
      return json.data
    },
  })

export const adminFetchSmsConsentStatus = (userId: number) =>
  fetchWrapper({
    fetchKey: ADMIN_FETCH_USER_SMS_CONSENT_STATUS_KEY,
    defaultErrorMessage: 'Error fetching user sms consent status',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<SmsConsentStatus>(
        `/finances/api/v1/admin/${userId}/communications/sms/status`
      )
      dispatch(receiveSingleSmsConsent(json.data))
      return json.data
    },
  })

export const selectSmsConsentStatuses = (state: ReduxState) =>
  state.smsConsentStatuses

export const getSmsConsentStatusByUserId = createSelector(
  selectSmsConsentStatuses,
  (_: unknown, userId: number | undefined) => userId,
  (smsConsentStatuses, userId) =>
    userId && smsConsentStatuses[userId] ? smsConsentStatuses[userId] : null
)

export const getSmsConsentStatus = createSelector(
  getCurrentUser,
  selectSmsConsentStatuses,
  (user, smsConsentStatuses) =>
    user?.id && smsConsentStatuses[user.id] ? smsConsentStatuses[user.id] : null
)
