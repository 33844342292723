import { useParams } from 'react-router-dom'
import { Container, Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import { TaxListQuestionId } from '../service'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../taxChecklist.selectors'
import { TaxChecklistResponse } from '../taxChecklistQuestion.slice'
import {
  FormikInput,
  GridRowColumn,
  Text,
  makeNumberSchema,
  getFieldNames,
} from '../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import { Form1120sProps } from '.'
import { SubStepIdentifiers } from '../Shared/ReviewStepsandProgresses/stepProgress.helpers'

export const uncommonScenarioFollowUpQuestionIds = [
  TaxListQuestionId.scorp_number_other_businesses,
  TaxListQuestionId.number_fixed_assets_sold,
  TaxListQuestionId.number_of_real_estate,
]

const UncommonScenariosContinuedPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: Form1120sProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()
  const questionNumberOtherBiz = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.scorp_number_other_businesses,
    taxYear
  )
  const responseNumberOtherBiz = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.scorp_number_other_businesses,
    Number(formId)
  )
  const questionNumberFixedAssets = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.number_fixed_assets_sold,
    taxYear
  )
  const responseNumberFixedAssets = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_fixed_assets_sold,
    Number(formId)
  )
  const questionNumberRealEstate = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.number_of_real_estate,
    taxYear
  )
  const responseNumberRealEstate = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_of_real_estate,
    Number(formId)
  )

  // Derived TF question
  const responsesHasBusinessInventory = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.business_inventory,
    Number(formId)
  )
  const responsesNumberOfK1s = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_of_k1s_scorp,
    Number(formId)
  )

  // Parent responses
  const responseOwnedOtherBiz = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.scorp_owned_other_businesses,
    Number(formId)
  )
  const responseSoldfixedAssets = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.sold_fixed_assets,
    Number(formId)
  )
  const responseRealEstate = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.engaged_in_real_estate,
    Number(formId)
  )

  const formik = useFormik({
    initialValues: {
      otherBiz: responseNumberOtherBiz?.[0]?.value,
      fixedAssets: responseNumberFixedAssets?.[0]?.value,
      rentals: responseNumberRealEstate?.[0]?.value,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      if (
        values.otherBiz !== responseNumberOtherBiz?.[0]?.value ||
        !responseNumberOtherBiz?.[0]?.value
      ) {
        responseData.push({
          id: responseNumberOtherBiz?.[0]?.id,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.scorp_number_other_businesses,
          value: Number(values.otherBiz ?? 0),
        })
      }
      const numberOfK1sValue = Number(values.otherBiz ?? 0) + 1
      if (numberOfK1sValue !== responsesNumberOfK1s?.[0]?.value) {
        responseData.push({
          id: responsesNumberOfK1s?.[0]?.id,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.number_of_k1s_scorp,
          value: numberOfK1sValue,
        })
      }
      if (
        values.fixedAssets !== responseNumberFixedAssets?.[0]?.value ||
        !responseNumberFixedAssets?.[0]?.value
      ) {
        responseData.push({
          id: responseNumberFixedAssets?.[0]?.id,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.number_fixed_assets_sold,
          value: Number(values.fixedAssets ?? 0),
        })
      }
      const hasBusinessInventoryValue = Number(values.fixedAssets ?? 0) > 0
      if (
        hasBusinessInventoryValue !== responsesHasBusinessInventory?.[0]?.value
      ) {
        responseData.push({
          id: responsesHasBusinessInventory?.[0]?.id,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.business_inventory,
          value: hasBusinessInventoryValue,
        })
      }
      if (
        values.rentals !== responseNumberRealEstate?.[0]?.value ||
        !responseNumberRealEstate?.[0]?.value
      ) {
        responseData.push({
          id: responseNumberRealEstate?.[0]?.id,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.number_of_real_estate,
          value: Number(values.rentals ?? 0),
        })
      }
      await goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.lessCommonScenarios1120s],
      })
    },
  })

  const { isSubmitting, isValid, submitForm } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <>
      <FormikProvider value={formik}>
        <Container style={{ marginTop: 56 }} text textAlign="left">
          <Grid>
            <GridRowColumn>
              <Text as="display2" textAlign="center">
                Less Common Scenarios
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text as="bodyLg" textAlign="center">
                We have a few more questions based on your selections from the
                previous screen.
              </Text>
            </GridRowColumn>
            {responseOwnedOtherBiz?.[0]?.value && (
              <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
                <FormikInput
                  name={fieldNames.otherBiz}
                  label={questionNumberOtherBiz?.question?.text}
                  schema={makeNumberSchema({
                    required: Boolean(responseOwnedOtherBiz?.[0]?.value),
                  })}
                  type="number"
                  fullWidth
                />
              </GridRowColumn>
            )}
            {responseSoldfixedAssets?.[0]?.value && (
              <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
                <FormikInput
                  name={fieldNames.fixedAssets}
                  label={questionNumberFixedAssets?.question?.text}
                  schema={makeNumberSchema({
                    required: Boolean(responseSoldfixedAssets?.[0]?.value),
                  })}
                  type="number"
                  fullWidth
                />
              </GridRowColumn>
            )}
            {responseRealEstate?.[0]?.value && (
              <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
                <FormikInput
                  name={fieldNames.rentals}
                  label={questionNumberRealEstate?.question?.text}
                  schema={makeNumberSchema({
                    required: Boolean(responseRealEstate?.[0]?.value),
                  })}
                  type="number"
                  fullWidth
                />
              </GridRowColumn>
            )}
          </Grid>
        </Container>
      </FormikProvider>
      <Grid>
        <FormFlowFooter
          continueDisabled={!isValid || isSubmitting}
          loading={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
        />
      </Grid>
    </>
  )
}

export default UncommonScenariosContinuedPanel
