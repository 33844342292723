import { Dispatch, SetStateAction, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useReselector } from '../../../../utils/sharedHooks'
import { getFinancialProfile } from '../../../../selectors/user.selectors'
import { filterNulls } from '../../../../utils/typeHelpers'
import { FILING_STATUSES, TAX_ENTITY_TYPES } from '../../taxConstants'
import { FinancialProfile } from '../../../../reducers/auth/userReducer'
import { dollarsToCurrency } from '../../../../utils/currencyHelpers'
import { QuarterlyTaxEstimateDetail } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'

export enum TAX_PROFILE_STEP {
  aboutYou = 'aboutYou',
  personalTaxIncomeIntro = 'personalTaxIncomeIntro',
  scorpW2Earnings = 'scorpW2Earnings',
  scorpAdditionalW2Income = 'scorpAdditionalW2Income',
  incomeOutsideOfPractice = 'incomeOutsideOfPractice',
  withholdings = 'withholdings',
  ten99Income = 'ten99Income',
  spouseW2 = 'spouseW2',
  spouseWithholding = 'spouseWithholding',
  spouse1099Income = 'spouse1099Income',
  finish = 'finish',
}

export type TaxesProfileFlowAnswers = {
  filingStatusSame?: boolean
  stateSame?: boolean
  registrationStateSame?: boolean
  federalWithholding: number | null
  stateWithholding: number | null
  wIncome: number | null
  spouseWIncome: number | null
  spouseFederalWithholding: number | null
  spouseStateWithholding: number | null
  otherIndividualIncome: number | null
  spouseIndividualIncome: number | null
  scorpPracticeWIncome: number | null
  estimatedAnnualW2Income: number | null
  estimatedAnnualSpouseW2Income: number | null
  estimatedAnnualScorpPracticeW2Income: number | null
}

export type TaxesProfileFlowAnswerProps = {
  formFlowAnswers: TaxesProfileFlowAnswers
  setFormFlowAnswers: Dispatch<SetStateAction<TaxesProfileFlowAnswers>>
}

//this percentage is stored as a whole number, so this would be 15%
export const withholdingRecommendedMinimumPercentage = 15

//this is the amount of income that is considered high income, and we'll warn users about it
export const highIncomeThreshold = dollarsToCurrency(200000)

//This logic should be consistent with how we annualize W-2 income in the backend
//Currently is (YTD income * 12)/(bookkeeping months + 1)
export const getProjectedW2Income = ({
  ytdIncomeInDollars,
  quarterDetails,
}: {
  ytdIncomeInDollars?: number | null
  quarterDetails?: QuarterlyTaxEstimateDetail | null
}) => {
  if (!quarterDetails || !ytdIncomeInDollars) {
    return null
  }

  const monthCount = quarterDetails.bookkeepingPeriodTotalMonths

  return dollarsToCurrency(ytdIncomeInDollars).multiply(12 / Number(monthCount))
}

export const useTaxProfileSteps = (currentStep: TAX_PROFILE_STEP) => {
  const [_, setSearchParams] = useSearchParams()
  const reduxFp = useReselector(getFinancialProfile)

  const calculateSteps = useCallback((fp: FinancialProfile) => {
    const isScorp = fp.taxEntityType === TAX_ENTITY_TYPES.form_1120_s
    const showDisplayWithholdings = Boolean(
      (isScorp &&
        (fp.scorpPracticeWIncomeInCents ||
          fp.estimatedAnnualScorpPracticeW2IncomeInCents)) ||
        fp.wIncomeInCents ||
        fp.estimatedAnnualW2IncomeInCents
    )
    const showDisplaySpouseWithholdings = Boolean(
      fp.spouseWIncomeInCents || fp.estimatedAnnualSpouseW2IncomeInCents
    )
    const shouldDisplaySpouse =
      fp.filingStatus &&
      [
        FILING_STATUSES.married_filing_jointly,
        FILING_STATUSES.qualifying_widow,
      ].includes(fp.filingStatus)

    return filterNulls([
      TAX_PROFILE_STEP.aboutYou,
      TAX_PROFILE_STEP.personalTaxIncomeIntro,
      isScorp ? TAX_PROFILE_STEP.scorpW2Earnings : null,
      isScorp ? TAX_PROFILE_STEP.scorpAdditionalW2Income : null,
      isScorp ? null : TAX_PROFILE_STEP.incomeOutsideOfPractice,
      showDisplayWithholdings ? TAX_PROFILE_STEP.withholdings : null,
      TAX_PROFILE_STEP.ten99Income,
      shouldDisplaySpouse ? TAX_PROFILE_STEP.spouseW2 : null,
      shouldDisplaySpouse && showDisplaySpouseWithholdings
        ? TAX_PROFILE_STEP.spouseWithholding
        : null,
      shouldDisplaySpouse ? TAX_PROFILE_STEP.spouse1099Income : null,
      TAX_PROFILE_STEP.finish,
    ])
  }, [])

  const goToStep = useCallback(
    (newIndex: 1 | -1, updatedFP?: FinancialProfile) => {
      const fpToCheck = updatedFP || reduxFp
      if (!fpToCheck) {
        return
      }

      // Use updated financial profile to calculate next step.  Default to current fp state
      const steps = calculateSteps(fpToCheck)

      const currentStepIndex = steps.indexOf(currentStep)

      // Go forward or backward in stack
      setSearchParams({ step: steps[currentStepIndex + newIndex] })
    },
    [calculateSteps, currentStep, reduxFp, setSearchParams]
  )

  const goToNextStep = useCallback(
    (updatedFP?: FinancialProfile) => goToStep(1, updatedFP),
    [goToStep]
  )

  const goToPreviousStep = useCallback(
    (updatedFP?: FinancialProfile) => goToStep(-1, updatedFP),
    [goToStep]
  )

  return { goToNextStep, goToPreviousStep }
}
