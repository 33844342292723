import { Grid, Image } from 'semantic-ui-react'
import {
  selectHeardProductPlanInterval,
  selectHeardProductPlanPrice,
  selectHeardProductSubscription,
  StripeInterval,
} from '../../reducers/subscription.slice'
import { useReselector } from '../../utils/sharedHooks'
import { Button, GridRowColumn, Text } from '../../components/BaseComponents'
import { makeGridConfig } from '../../components/BaseComponents/Grid'
import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { DATE_FORMATS_LUXON } from '../../utils/dateHelpers'
import { getDefaultPrice } from './helpers'
import { selectIsCurrentUserScorp } from '../../selectors/user.selectors'
import {
  centsToCurrency,
  formatCurrencyFromCents,
} from '../../utils/currencyHelpers'
import { useNavigate } from 'react-router-dom'

const DiscountComplete = () => {
  const navigate = useNavigate()
  const sub = useReselector(selectHeardProductSubscription)

  const currentSubscriptionInterval =
    useReselector(selectHeardProductPlanInterval) ?? StripeInterval.month

  const isMonthly = currentSubscriptionInterval === StripeInterval.month

  const isScorp = useReselector(selectIsCurrentUserScorp)

  const userPrice = useReselector(selectHeardProductPlanPrice)

  const getSavedAmounts = (
    isMonthly: boolean,
    isScorp: boolean,
    userPrice: number | null
  ) => {
    const defaultPrice = getDefaultPrice(isMonthly, isScorp)
    const currentPrice = centsToCurrency(userPrice ?? defaultPrice)

    let newAmountChargedInCents
    let amountSavedInCents

    if (isMonthly) {
      // for monthly plans, we offer 50% off for 3 months
      amountSavedInCents = currentPrice.multiply(0.5)
      newAmountChargedInCents = currentPrice.subtract(amountSavedInCents)
    } else {
      amountSavedInCents = currentPrice.multiply(0.2)
      newAmountChargedInCents = currentPrice.subtract(amountSavedInCents)
      // for annual plans, we offer 20% off their next plan
    }

    return {
      newAmountChargedInCents,
      amountSavedInCents,
    }
  }

  const savedAmounts = getSavedAmounts(isMonthly, isScorp, userPrice)

  const header = isMonthly
    ? 'You are now getting 50% off your next 3 months'
    : 'You are now getting 20% your next year of Heard'

  const body = useMemo(() => {
    if (isMonthly) {
      return (
        <Text as="bodyLg">
          You&apos;ll be charged{' '}
          {formatCurrencyFromCents(savedAmounts.newAmountChargedInCents)} for
          the next 3 months. You should see a confirmation email from Stripe
          soon.
        </Text>
      )
    } else {
      return (
        <Text as="bodyLg">
          When your subscription renews on{' '}
          {sub?.nextBillingDate &&
            DateTime.fromSeconds(sub?.nextBillingDate).toFormat(
              DATE_FORMATS_LUXON.DISPLAY_SIMPLE
            )}
          , you&apos;ll be charged{' '}
          {formatCurrencyFromCents(savedAmounts.newAmountChargedInCents)}, a
          savings of {formatCurrencyFromCents(savedAmounts.amountSavedInCents)}{' '}
          for the next year. You should see a confirmation email from Stripe
          soon.
        </Text>
      )
    }
  }, [isMonthly, sub, savedAmounts])

  return (
    <Grid>
      <GridRowColumn />
      <GridRowColumn />
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/party-hat.svg"
          style={{ height: 180, width: 180 }}
        />
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([8, 16], true)}>
        <Text textAlign="center" as="h1">
          {header}
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([8, 16], true)}>
        {body}
        <br />
        <Text as="bodyLg">
          We&apos;re always working to improve Heard to address our therapist
          community&apos;s feedback, and are glad that you&apos;ll be able to
          take advantage of all your benefits.
        </Text>
      </GridRowColumn>
      <GridRowColumn centerContent>
        <Button onClick={() => navigate('/')}>Go to Home</Button>
      </GridRowColumn>
    </Grid>
  )
}

export default DiscountComplete
