import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { Dictionary, keyBy } from 'lodash'
import { mapTokenToSkyflowRequest } from '../utils/skyflowHelpers'
import { fetchIfNeededWrapper, fetchWrapper } from './fetch'

type SkyflowTable = 'social_security_numbers' | 'bank_account_details'

export interface SkyflowToken {
  skyflow_id: string
  admin_id: number // AKA ownerId
  first_name: string
  last_name: string
  user_id: number
}

export interface SkyflowSsnToken extends SkyflowToken {
  relationship: ['user' | 'dependent' | 'spouse']
  social_security_number: string
}

export interface SkyflowBankToken extends SkyflowToken {
  bank_account_number?: string
  bank_routing_number?: string
}

export type SkyflowAnyToken = SkyflowSsnToken | SkyflowBankToken

export interface SkyflowTokenRequest {
  skyflowId?: string
  table: SkyflowTable
  token: SkyflowAnyToken
}

export interface SkyflowTokenPostRequest
  extends Omit<SkyflowTokenRequest, 'token'> {
  token:
    | Omit<SkyflowSsnToken, 'skyflow_id'>
    | Omit<SkyflowBankToken, 'skyflow_id'>
}

export interface SkyflowTokenUpdateResponse {
  skyflow_id: string
  tokens: Partial<SkyflowAnyToken>
}

export interface SkyflowTokenListResponse {
  records: { fields: SkyflowAnyToken }[]
}

const initialState: { [key: string]: SkyflowAnyToken } = {}

const skyflowSlice = createSlice({
  name: 'skyflowTokens',
  initialState,
  reducers: {
    setTokens: (state, action: PayloadAction<Dictionary<SkyflowAnyToken>>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    createToken: (state, action: PayloadAction<SkyflowAnyToken>) => {
      state[action.payload.skyflow_id] = action.payload
    },
    deleteToken: (state, action: PayloadAction<string>) => {
      delete state[action.payload]
    },
  },
})

const { setTokens, createToken, deleteToken } = skyflowSlice.actions
export default skyflowSlice.reducer

export const FETCH_ALL_SKYFLOW_SSN_TOKENS = 'FETCH_ALL_SKYFLOW_SSN_TOKENS'
export const fetchSkyflowSsnTokensIfNeeded = (userId: string) =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_ALL_SKYFLOW_SSN_TOKENS,
    defaultErrorMessage: 'Error retrieving data',
    fetchFunction: async (dispatch) => {
      const res = await axios.get<SkyflowTokenListResponse>(
        '/finances/api/v1/skyflow_tokens/bulkList',
        { params: { userId, table: 'social_security_numbers' } }
      )
      const tokenMap = res.data.records?.map((r) => r.fields)
      dispatch(setTokens(keyBy(tokenMap, 'skyflow_id')))
    },
  })

export const FETCH_ALL_SKYFLOW_BANK_TOKENS = 'FETCH_ALL_SKYFLOW_BANK_TOKENS'
export const fetchSkyflowBankTokensIfNeeded = (userId: string) =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_ALL_SKYFLOW_BANK_TOKENS,
    defaultErrorMessage: 'Error retrieving data',
    fetchFunction: async (dispatch) => {
      const res = await axios.get<SkyflowTokenListResponse>(
        '/finances/api/v1/skyflow_tokens/bulkList',
        {
          params: { userId, table: 'bank_account_details' },
          validateStatus: (status) => status === 200,
        }
      )
      const tokenMap = res.data.records?.map((r) => r.fields)
      dispatch(setTokens(keyBy(tokenMap, 'skyflow_id')))
    },
  })

export const CREATE_SKYFLOW_TOKEN_KEY = 'CREATE_SKYFLOW_TOKEN_KEY'
export const createSkyflowToken = ({ token, table }: SkyflowTokenPostRequest) =>
  fetchWrapper({
    fetchKey: CREATE_SKYFLOW_TOKEN_KEY,
    fetchFunction: async (dispatch) => {
      const res = await axios.post<SkyflowTokenUpdateResponse>(
        '/finances/api/v1/skyflow_tokens',
        {
          table,
          records: [
            {
              fields: mapTokenToSkyflowRequest(token),
            },
          ],
        },
        { validateStatus: (status) => status === 201 }
      )
      if (res.data.skyflow_id) {
        dispatch(
          createToken({
            ...token,
            skyflow_id: res.data.skyflow_id,
            ...res.data.tokens,
          })
        )
        return res.data.skyflow_id
      } else {
        return null
      }
    },
  })

export const UPDATE_SKYFLOW_TOKEN_KEY = 'UPDATE_SKYFLOW_TOKEN_KEY'
export const updateSkyflowToken = ({
  skyflowId,
  table,
  token,
}: SkyflowTokenRequest) =>
  fetchWrapper({
    fetchKey: UPDATE_SKYFLOW_TOKEN_KEY,
    fetchFunction: async (dispatch) => {
      const res = await axios.put<SkyflowTokenUpdateResponse>(
        '/finances/api/v1/skyflow_tokens',
        {
          skyflowId,
          table,
          record: {
            fields: mapTokenToSkyflowRequest(token),
          },
        },
        { validateStatus: (status) => status === 200 }
      )
      dispatch(
        createToken({
          ...token,
          skyflow_id: res.data.skyflow_id,
          ...res.data.tokens,
        })
      )
      return res.data.skyflow_id
    },
  })

export const DELETE_SKYFLOW_TOKEN_KEY = 'DELETE_SKYFLOW_TOKEN_KEY'
export const deleteSkyflowToken = (skyflowId: string, table: SkyflowTable) =>
  fetchWrapper({
    fetchKey: DELETE_SKYFLOW_TOKEN_KEY,
    fetchFunction: async (dispatch) => {
      const recordType =
        table === 'social_security_numbers'
          ? 'social_security_number'
          : 'bank_account_detail'

      const res = await axios.delete<{
        skyflow_id: string
        deleted: boolean
      }>('/finances/api/v1/skyflow_tokens', {
        validateStatus: (status: number) => status === 200,
        params: {
          skyflowId,
          table,
          recordType,
        },
      })
      if (res.data.deleted) {
        dispatch(deleteToken(res.data.skyflow_id))
        return true
      }
      return false
    },
  })
