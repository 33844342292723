import {
  MouseEvent as ReactMouseEvent,
  ReactNode,
  Fragment,
  CSSProperties,
} from 'react'
import {
  Dropdown as SemanticDropdown,
  DropdownItemProps,
  SemanticICONS,
  StrictDropdownProps,
  DropdownMenu,
  DropdownItem,
  DropdownDivider,
} from 'semantic-ui-react'
import styled from 'styled-components'
import { isNil } from 'lodash'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Colors, FontWeight, Fonts } from '../../styles/theme'
import { InputError, LabelDescription, LabelDescriptionProps } from './Input'
import Icon from './Icon'

export type DropdownValue = boolean | number | string | undefined

// Helper function to turn an array of DropdownValues into dropdown options
export const valsToDropdownOptions = <T extends DropdownValue>(
  optionValues: readonly T[],
  formatLabel?: (enumValue: T) => string
) =>
  optionValues.map((val) => ({
    key: val?.toString() || 'undefined',
    text: formatLabel ? formatLabel(val) : val?.toString() || 'undefined',
    value: val,
  }))

export interface DropdownOption<T extends DropdownValue = string> {
  key?: string | number
  value?: T
  text?: ReactNode
  icon?: SemanticICONS
  onClick?: (
    e: ReactMouseEvent<HTMLDivElement, MouseEvent>,
    props: DropdownItemProps
  ) => void
  selected?: boolean
}

export type DropDownVariant = 'default' | 'text' | 'checked'

type onChange<
  T extends DropdownValue,
  M extends boolean | undefined,
  C extends boolean | undefined,
> = M extends true
  ? // Multiple dropdowns return an array (even if they are also clearable)
    (_: T[]) => void
  : C extends true
    ? (_: T | undefined) => void
    : (_: T) => void

interface Props<
  T extends DropdownValue,
  M extends boolean | undefined,
  C extends boolean | undefined,
> extends Omit<StrictDropdownProps, 'error' | 'selection' | 'onChange'>,
    LabelDescriptionProps {
  options?: DropdownOption<T>[]
  color?: keyof typeof Colors
  highlightColor?: keyof typeof Colors
  $iconColor?: keyof typeof Colors
  error?: string
  fontSize?: string | number
  fontWeight?: number
  fullWidth?: boolean
  variant?: DropDownVariant
  style?: CSSProperties
  multiple?: M
  clearable?: C
  onChange?: onChange<T, M, C>
  dividerAfter?: number[]
}

const { black, gray, lightRed, mediumGray, red, white } = Colors

export const UnstyledDropdown = <
  T extends DropdownValue,
  M extends boolean | undefined = undefined,
  C extends boolean | undefined = undefined,
>({
  className,
  description,
  error,
  fullWidth,
  label,
  required,
  variant = 'default',
  onChange,
  afterLabel,
  value,
  dividerAfter,
  ...rest
}: Props<T, M, C>) => (
  <div className={className}>
    <LabelDescription
      label={label}
      description={description}
      required={required}
      afterLabel={afterLabel}
    />
    <SemanticDropdown
      error={Boolean(error)}
      fluid={fullWidth}
      selection={Boolean(variant === 'default' && rest.options) || undefined}
      // A value -> undefined doesn't properly reset a dropdown.  Setting an empty string works properly
      // https://github.com/Semantic-Org/Semantic-UI-React/issues/3625
      value={isNil(value) ? '' : value}
      onChange={(_, { value: newValue }) => {
        // Dropdowns that are clearable but NOT multiple will return empty strings that need to be handled
        if (rest.clearable && !rest.multiple) {
          // When a value is cleared it becomes an empty string but we want to set undefined as the value
          const clearableValue = newValue === '' ? undefined : newValue
          // @ts-expect-error types returned from semantic UI are bad and there isn't a reason to cast since the return
          // types are properly set
          onChange?.(clearableValue)
        } else {
          // @ts-expect-error see above comment about Semantic UI typing
          onChange?.(newValue)
        }
      }}
      selectOnBlur={false}
      upward={false}
      scrolling={variant === 'checked' || undefined}
      {...rest}
    >
      {
        // Display sent children or checked variant dropdown menu
        rest.children || variant !== 'checked' ? (
          rest.children
        ) : (
          // DropdownMenu component is required to use DropdownDivider component
          <DropdownMenu>
            {rest.options?.map((option, idx) => {
              const isSelected = rest.multiple
                ? (Array.isArray(value) ? value : []).includes(
                    option.value || ''
                  )
                : option.value === value

              const renderDivider = dividerAfter?.includes(idx)

              const elements = []
              elements.push(
                <DropdownItem
                  value={option.value}
                  selected={isSelected}
                  onClick={(_: unknown, { value: newValue }) => {
                    // onClick is needed here because onChange isn't called within the DropdownMenu component
                    if (rest.multiple) {
                      const multipleValue = Array.isArray(value)
                        ? [...value]
                        : []
                      const selectedIndex = multipleValue.indexOf(
                        newValue || ''
                      )
                      if (selectedIndex !== -1) {
                        multipleValue.splice(selectedIndex, 1)
                        // @ts-expect-error semantic ui types
                        onChange?.(multipleValue)
                      } else {
                        // @ts-expect-error semantic ui types
                        onChange?.([...multipleValue, newValue])
                      }
                    } else if (rest.clearable && !rest.multiple) {
                      const clearableValue =
                        newValue === '' ? undefined : newValue
                      // @ts-expect-error semantic ui types
                      onChange?.(clearableValue)
                    } else {
                      // @ts-expect-error semantic ui types
                      onChange?.(newValue)
                    }
                  }}
                >
                  {isSelected && (
                    <Icon
                      icon={regular('check')}
                      color="green"
                      style={{ marginRight: 8 }}
                    />
                  )}
                  {option.text}
                </DropdownItem>
              )
              if (renderDivider) {
                elements.push(<DropdownDivider />)
              }
              return (
                <Fragment key={option.value?.toString()}>{elements}</Fragment>
              )
            })}
          </DropdownMenu>
        )
      }
    </SemanticDropdown>
    <InputError error={error} />
  </div>
)

const Dropdown = styled(UnstyledDropdown)(
  ({
    color = 'black',
    highlightColor,
    $iconColor,
    disabled,
    error,
    fontSize,
    fontWeight = FontWeight.REGULAR,
    variant = 'default',
  }) => {
    return {
      '&&&&': {
        'div[highlightcolor]': {
          'div.text': {
            color: highlightColor
              ? `${Colors[highlightColor]} !important`
              : undefined,
          },
        },

        '.ui.dropdown': {
          ...(variant === 'default' && {
            alignItems: 'center',
            backgroundColor: `${error ? lightRed : white} !important`,
            border: disabled
              ? 'none'
              : `1px solid ${error ? red : gray} !important`,
            borderRadius: 8,
            display: 'inline-flex',
            justifyContent: 'start',
            padding: '12px 16px',

            '&.multiple > div.text.default': {
              margin: 0,
            },

            'div.text, div.item': {
              ...Fonts.bodyMd,

              '&.default': {
                color: error ? red : mediumGray,
              },

              '&:not(.default)': {
                color: error ? red : black,
              },
            },

            ...(fontSize && {
              'div.text': {
                fontSize,
                fontWeight: `${fontWeight} !important`,
              },
            }),

            'i.icon': {
              right: 10,
              fontSize: '1.1em',
            },

            '.menu': {
              border: `1px solid ${error ? red : gray} !important`,
              borderRadius: '0 0 8px 8px',
              borderTop: 'none !important',
              // Semantic UI max height for large screens - we want tablet/mobile to not be capped at small size
              maxHeight: '16.02857143rem',
            },

            '.item > .ui.label': {
              // Hides the label that sits before option values
              display: 'none',
            },

            '.ui.label.highlight': {
              '& + .text': {
                color: highlightColor ? Colors[highlightColor] : undefined,
              },
            },
            '.search': { height: '100%' },
          }),

          ...(variant === 'text' && {
            alignItems: 'center',
            display: 'flex',

            'div.text, div.item': Fonts.bodyMd,

            'div.text': {
              color: Colors[color],
              fontWeight: 500,

              ...(fontSize && { fontSize }),
            },

            'div.item': {
              alignItems: 'center',
              display: 'flex',
            },

            'i.icon:before': {
              color: Colors[color],
            },

            '.menu': {
              'i.icon:before': {
                color: $iconColor ? Colors[$iconColor] : Colors[color],
              },
            },
          }),

          ...(variant === 'checked' && {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'start',
            backgroundColor: Colors['stone40'],
            padding: 12,
            borderRadius: 8,

            '.menu': {
              marginTop: 8,
              borderRadius: 8,
              // Semantic UI max height for large screens - we want tablet/mobile to not be capped at small size
              maxHeight: '16.02857143rem',

              '.selected': {
                color: `${Colors['green']} !important`,
                background: 'none',
              },
            },

            'div.text, div.item': Fonts.bodyMd,

            'div.text': {
              color: `${Colors['black']} !important`,
              fontWeight: 500,

              ...(fontSize && { fontSize }),
            },
          }),
        },
      },
    }
  }
) as typeof UnstyledDropdown

export default Dropdown

type DefaultDropdownProps = Props<DropdownValue, undefined, undefined>

export type { DefaultDropdownProps, Props as DropdownProps }
