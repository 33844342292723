import { Grid } from 'semantic-ui-react'
import {
  Alert,
  Button,
  GridRowColumn,
  Icon,
  Text,
} from '../../../components/BaseComponents'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import TransactionDeleteModal from '../TransactionDeleteModal'
import { useMemo, useState } from 'react'
export const DetailsAlert = ({
  isUserManualTransaction,
  isManualTransaction,
  transactionId,
  isReconciled,
}: {
  isUserManualTransaction: boolean
  isManualTransaction: boolean
  transactionId: number
  isReconciled: boolean
}) => {
  const [open, setOpen] = useState(false)

  const content = useMemo(() => {
    if (isUserManualTransaction) {
      return {
        icon: light('pencil'),
        body: 'Manually added transaction',
        button: 'Delete',
      }
    } else if (!isUserManualTransaction && isManualTransaction) {
      return {
        icon: light('sync'),
        body: 'Manually added by Heard',
        button: 'Mark as Duplicate',
      }
    } else {
      return {
        icon: light('sync'),
        body: 'Synced transaction',
        button: 'Mark as Duplicate',
      }
    }
  }, [isUserManualTransaction, isManualTransaction])

  return (
    <Alert
      customIcon={<Icon icon={content.icon} size="sm" />}
      type="info"
      style={{ marginBottom: 16 }}
    >
      <Grid>
        {!isReconciled && (
          <Grid.Row>
            <Grid.Column width={10}>
              <Text as="bodySm">{content.body}</Text>
            </Grid.Column>

            <Grid.Column width={6}>
              <Button
                style={{ width: '100%', height: '100%' }}
                variant="actionLink"
                onClick={() => setOpen(true)}
              >
                <Text
                  color={isUserManualTransaction ? 'red' : 'black'}
                  as="bodyXs"
                >
                  {content.button}
                </Text>
              </Button>
            </Grid.Column>
          </Grid.Row>
        )}
        {isReconciled && (
          <GridRowColumn>
            <Text as="bodySm">{content.body}</Text>
          </GridRowColumn>
        )}
      </Grid>
      {open && (
        <TransactionDeleteModal
          open={open}
          close={() => setOpen(false)}
          transactionId={transactionId}
          isManualTransaction={isUserManualTransaction}
        />
      )}
    </Alert>
  )
}

export default DetailsAlert
