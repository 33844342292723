import { AllocationRule } from '../../reducers/finances/allocationRulesReducer'

export const DEFAULT_TAX_SAVINGS = '30'
export const DEFAULT_OWNERS_DRAW = '50'
export const DEFAULT_OPERATING_EXPENSES = '15'
export const DEFAULT_RETIREMENT = '5'

export const DEFAULT_ALLOCATION_RULES: (AllocationRule & { hint?: string })[] =
  [
    {
      ruleName: 'Tax savings',
      percentage: DEFAULT_TAX_SAVINGS,
      hint: 'This is the percentage amount we suggest for you to put aside for federal and state taxes. This is just an estimate.',
      createdAt: '',
      id: null,
      ruleType: 'percentage',
      updatedAt: '',
      amountInCents: null,
    },
    {
      ruleName: 'Owners draw',
      percentage: DEFAULT_OWNERS_DRAW,
      hint: 'This is the percentage amount that you will be “paying” yourself.',
      createdAt: '',
      id: null,
      ruleType: 'percentage',
      updatedAt: '',
      amountInCents: null,
    },
    {
      ruleName: 'Retirement',
      percentage: DEFAULT_RETIREMENT,
      hint: 'This is the percentage amount that you want to put away towards retirement.',
      createdAt: '',
      id: null,
      ruleType: 'percentage',
      updatedAt: '',
      amountInCents: null,
    },
    {
      ruleName: 'Rainy day expenses',
      percentage: DEFAULT_OPERATING_EXPENSES,
      hint: 'This is the percentage amount that you want to put aside for any non-recurring expenses in your practice.',
      createdAt: '',
      id: null,
      ruleType: 'percentage',
      updatedAt: '',
      amountInCents: null,
    },
  ]

export const EMPTY_ALLOCATION_RULE: AllocationRule = {
  ruleName: '',
  percentage: null,
  createdAt: '',
  id: null,
  ruleType: 'percentage',
  updatedAt: '',
  amountInCents: null,
}
