import { useEffect, useState } from 'react'
import { Grid, Image, Divider } from 'semantic-ui-react'

import { Button, Text, Icon } from '../../../../components/BaseComponents'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../../utils/deviceWidthHelpers'
import PayrollAccessModal from '../../../Onboarding/PayrollAccessModal'
import { GEP_PER_PERSON_PRICE, GEP_SUB_PRICE } from '../../helpers'
import { useAnalyticsTrack, useAnalyticsView } from '../../../Amplitude'

const WelcomeToPayroll = () => {
  const [isOpen, setIsOpen] = useState(false)
  const pageView = useAnalyticsView()
  const track = useAnalyticsTrack()

  useEffect(() => {
    pageView('payroll upsell welcome')
  }, [pageView])

  const whyAddPayroll = [
    {
      title: 'Calculate your salary',
      description:
        'If you are taxed as an S-Corp, our team can help calculate your reasonable salary.',
    },
    {
      title: 'Simplifies tax forms',
      description:
        'Easily generate and store W-2 and 1099 forms for employees and contractors come tax time.',
    },
    {
      title: 'Payroll taxes filed for you',
      description: 'We file payroll taxes for you every time you run payroll.',
    },
    {
      title: 'Hands-on support',
      description:
        'Our payroll team will support you throughout the year should you run into any issues when running payroll.',
    },
  ]

  const isPayrollForMe = [
    'You own a group practice and pay your employees or contractors',
    'You’re an S corp and required to pay yourself a reasonable income through a W-2 salary',
    'You are a sole proprietor and pay your contractors',
  ]

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column computer={8} mobile={16} style={{ textAlign: 'center' }}>
          <Image
            src={
              'https://heard-images.s3.amazonaws.com/assets/welcome-to-payroll.svg'
            }
            alt={'documents illustration'}
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: 24,
            }}
          />
          <div style={{ textAlign: 'center' }}>
            <Text as={'display'} style={{ display: 'inline' }}>
              ${GEP_SUB_PRICE}
            </Text>
            <Text as={'bodyLg'} style={{ display: 'inline' }}>
              /month
            </Text>
          </div>
          <Text as={'bodyXs'}>
            Plus ${GEP_PER_PERSON_PRICE} per employee / month
          </Text>
          <Button
            style={{ margin: '15px auto 20px auto', width: '256px' }}
            onClick={() => {
              track('clicked payroll upsell welcome page button')
              setIsOpen(true)
            }}
          >
            Get Started
          </Button>
          <Text
            as={'bodyXs'}
            color={'darkGray'}
            style={{ width: 230, margin: '0 auto 46px auto' }}
          >
            Already paying for Gusto payroll? You’ll no longer be charged by
            Gusto and will only be charged by Heard going forward.
          </Text>
        </Grid.Column>
        <Grid.Column
          computer={8}
          mobile={16}
          style={{ padding: isMobile ? '' : '0 50px' }}
        >
          <Text as={'h1'} style={{ marginBottom: 16 }}>
            Welcome to Heard Payroll
          </Text>
          <Text
            style={{
              marginBottom: 20,
              textAlign: isMobile ? 'left' : 'justify',
              marginRight: isMobile ? 0 : 24,
            }}
          >
            Heard partners with Gusto so you can pay yourself, employees, and
            contractors in minutes. Keep your practice’s finances in one place.
          </Text>
          <Text as={'h2'} style={{ marginBottom: 16 }}>
            Why add payroll?
          </Text>
          <Grid>
            {whyAddPayroll.map((item) => (
              <Grid.Row key={item.title}>
                <Grid.Column width={2}>
                  <Icon icon={light('check')} color={'green'} />
                </Grid.Column>
                <Grid.Column width={14}>
                  <Text as={'h3'} style={{ marginBottom: 6 }}>
                    {item.title}
                  </Text>
                  <Text as={'bodySm'}>{item.description}</Text>
                </Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
          <Divider style={{ margin: '32px 0' }} />
          <Text as={'h2'} style={{ marginBottom: 28 }}>
            Is payroll right for me?
          </Text>
          <Grid>
            {isPayrollForMe.map((content) => (
              <Grid.Row key={content}>
                <Grid.Column style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon icon={light('arrow-right')} color={'green'} />
                </Grid.Column>
                <Grid.Column width={14}>
                  <Text>{content}</Text>
                </Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <PayrollAccessModal open={isOpen} onClose={() => setIsOpen(false)} />
    </Grid>
  )
}

export default WelcomeToPayroll
