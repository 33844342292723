import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum AccountTypes {
  CREDIT = 'credit',
  DEPOSITORY = 'depository',
}

export enum FinancialType {
  PLAID = 'plaid',
  MANUAL = 'manual',
  USER_MANUAL_UPLOAD = 'user_manual_upload',
}

export enum TransactionSyncState {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  PENDING = 'pending',
}

export interface FinancialAccount {
  FinancialProfileId: number
  accountId: string
  accountType: string
  accounts: {
    userId: number
    errorObject: null | {
      error_code: string
      error_message: string
    }
    needsReconnection: boolean
    disconnectedOn?: string
    markedCannotReconnectAt: string
  }
  createdAt: string
  financialProfileId: number
  id: number
  inactive: boolean
  mask: string | null
  name: string
  plaidInstitutionId: string | null
  plaidInstitutionName: string | null
  plaidItemId: number | null
  subtype: string
  type: AccountTypes | null
  updatedAt: string
  markedPersonalAt: string | null
  manualAccount: {
    name: string
    inactive: boolean
  } | null
  isHidden: boolean | null
  transactionSyncState: TransactionSyncState | null
  statementPermissions: string | null
  onboardingAnswers: {
    [identifier: string]: boolean
  }
  bankAccessEnabledAt: string | null
}

export interface FinancialAccountState {
  [key: number]: FinancialAccount
}

const financialAccountsSlice = createSlice({
  name: 'financialAccounts',
  initialState: {} as FinancialAccountState,
  reducers: {
    receiveFinancialAccounts: (
      _,
      action: PayloadAction<{ [key: number]: FinancialAccount }>
    ) => ({
      ...action.payload,
    }),
    receiveCreateFinancialAccount: (
      state,
      action: PayloadAction<FinancialAccount>
    ) => {
      return {
        ...state,
        [action.payload.id]: action.payload,
      }
    },
    receiveDeleteFinancialAccount: (state, action: PayloadAction<number>) => {
      delete state[action.payload]
    },
    receiveUpdateFinancialAccount: (
      state,
      action: PayloadAction<FinancialAccount>
    ) => {
      state[action.payload.id] = action.payload
    },
  },
})

export default financialAccountsSlice.reducer

export const {
  receiveFinancialAccounts,
  receiveCreateFinancialAccount,
  receiveDeleteFinancialAccount,
  receiveUpdateFinancialAccount,
} = financialAccountsSlice.actions
