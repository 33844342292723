import { useParams } from 'react-router-dom'
import { DeductionsAndCreditsStepProps } from '.'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { TaxListQuestionId } from '../../service'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../../taxChecklist.selectors'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import { FormikProvider, useFormik } from 'formik'
import { Divider, Grid } from 'semantic-ui-react'
import {
  FormikLabelError,
  FormikRadioToggleButton,
  GridRowColumn,
  Text,
  getFieldName,
  makeReqBoolSchema,
} from '../../../../../../components/BaseComponents'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import DocumentAddedAlert from '../../Shared/SpecialtyAlerts'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { UPDATE_USER_EOY_REVIEW_PROGRESS_KEY } from '../../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { UPSERT_USER_TAX_QUESTIONNAIRE_KEY } from '../../taxChecklistQuestion.actions'

export const ownedHomeQuestionIds = [
  TaxListQuestionId.paid_mortgage_interest,
  TaxListQuestionId.refinanced_home,
  TaxListQuestionId.eco_home_improvements,
  TaxListQuestionId.paid_property_taxes,
]
const OwnedHomePanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: DeductionsAndCreditsStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()

  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    UPDATE_USER_EOY_REVIEW_PROGRESS_KEY,
  ])

  const questionPaidMortgageInterest = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.paid_mortgage_interest,
    taxYear
  )
  const responsePaidMortgageInterest = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.paid_mortgage_interest,
    Number(formId)
  )

  const questionRefinancedHome = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.refinanced_home,
    taxYear
  )
  const responseRefinancedHome = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.refinanced_home,
    Number(formId)
  )

  const questionEcoHomeImprovements = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.eco_home_improvements,
    taxYear
  )
  const responseEcoHomeImprovements = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.eco_home_improvements,
    Number(formId)
  )

  const questionPaidPropertyTaxes = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.paid_property_taxes,
    taxYear
  )
  const responsePaidPropertyTaxes = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.paid_property_taxes,
    Number(formId)
  )

  const formik = useFormik({
    initialValues: {
      paidMortgageInterest: responsePaidMortgageInterest?.[0]?.value,
      refinancedHome: responseRefinancedHome?.[0]?.value,
      ecoHomeImprovements: responseEcoHomeImprovements?.[0]?.value,
      paidPropertyTaxes: responsePaidPropertyTaxes?.[0]?.value,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      if (
        values.paidMortgageInterest !== responsePaidMortgageInterest?.[0]?.value
      ) {
        responseData.push({
          id: responsePaidMortgageInterest?.[0]?.id,
          value: values.paidMortgageInterest,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.paid_mortgage_interest,
        })
      }
      if (values.refinancedHome !== responseRefinancedHome?.[0]?.value) {
        responseData.push({
          id: responseRefinancedHome?.[0]?.id,
          value: values.refinancedHome,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.refinanced_home,
        })
      }
      if (
        values.ecoHomeImprovements !== responseEcoHomeImprovements?.[0]?.value
      ) {
        responseData.push({
          id: responseEcoHomeImprovements?.[0]?.id,
          value: values.ecoHomeImprovements,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.eco_home_improvements,
        })
      }
      if (values.paidPropertyTaxes !== responsePaidPropertyTaxes?.[0]?.value) {
        responseData.push({
          id: responsePaidPropertyTaxes?.[0]?.id,
          value: values.paidPropertyTaxes,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.paid_property_taxes,
        })
      }
      await goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.ownedHome],
      })
    },
  })

  const { values, isSubmitting, submitForm, isValid } = formik

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn>
          <Text as="display2" textAlign="center">
            Home Owner
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikLabelError
            name={getFieldName<typeof values>('paidMortgageInterest')}
            label={questionPaidMortgageInterest.question?.text}
            schema={makeReqBoolSchema()}
          />
        </GridRowColumn>
        <Grid.Row className="short" style={{ justifyContent: 'center' }}>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName<typeof values>('paidMortgageInterest')}
              value
            >
              Yes
            </FormikRadioToggleButton>
          </Grid.Column>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName<typeof values>('paidMortgageInterest')}
              value={false}
            >
              No
            </FormikRadioToggleButton>
          </Grid.Column>
        </Grid.Row>
        {values.paidMortgageInterest && (
          <GridRowColumn short {...makeGridConfig([6, 14], true)}>
            <DocumentAddedAlert>
              Form 1098 added to checklist
            </DocumentAddedAlert>
          </GridRowColumn>
        )}
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <Divider />
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikLabelError
            name={getFieldName<typeof values>('refinancedHome')}
            label={questionRefinancedHome.question?.text}
            schema={makeReqBoolSchema()}
          />
        </GridRowColumn>
        <Grid.Row className="short" style={{ justifyContent: 'center' }}>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName<typeof values>('refinancedHome')}
              value
            >
              Yes
            </FormikRadioToggleButton>
          </Grid.Column>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName<typeof values>('refinancedHome')}
              value={false}
            >
              No
            </FormikRadioToggleButton>
          </Grid.Column>
        </Grid.Row>
        {values.refinancedHome && (
          <GridRowColumn short {...makeGridConfig([6, 14], true)}>
            <DocumentAddedAlert>
              Added to checklist:
              <li>Form 1098 for the original mortgage</li>
              <li>Form 1098 for the new mortgage</li>
            </DocumentAddedAlert>
          </GridRowColumn>
        )}
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <Divider />
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikLabelError
            name={getFieldName<typeof values>('ecoHomeImprovements')}
            label={questionEcoHomeImprovements.question?.text}
            schema={makeReqBoolSchema()}
          />
        </GridRowColumn>
        <Grid.Row className="short" style={{ justifyContent: 'center' }}>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName<typeof values>('ecoHomeImprovements')}
              value
            >
              Yes
            </FormikRadioToggleButton>
          </Grid.Column>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName<typeof values>('ecoHomeImprovements')}
              value={false}
            >
              No
            </FormikRadioToggleButton>
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <Divider />
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikLabelError
            name={getFieldName<typeof values>('paidPropertyTaxes')}
            label={questionPaidPropertyTaxes.question?.text}
            schema={makeReqBoolSchema()}
          />
        </GridRowColumn>
        <Grid.Row className="short" style={{ justifyContent: 'center' }}>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName<typeof values>('paidPropertyTaxes')}
              value
            >
              Yes
            </FormikRadioToggleButton>
          </Grid.Column>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName<typeof values>('paidPropertyTaxes')}
              value={false}
            >
              No
            </FormikRadioToggleButton>
          </Grid.Column>
        </Grid.Row>
        {values.paidPropertyTaxes && (
          <GridRowColumn short {...makeGridConfig([6, 14], true)}>
            <DocumentAddedAlert>
              Summary of property taxes you paid added to checklist
            </DocumentAddedAlert>
          </GridRowColumn>
        )}
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <Divider />
        </GridRowColumn>
        <Grid.Row />
        <FormFlowFooter
          continueDisabled={!isValid || isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isUpdating || isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default OwnedHomePanel
