import { ReactNode, useCallback, useState } from 'react'
import {
  Loader,
  Dimmer,
  SemanticWIDTHS,
  Table,
  Icon,
  TableCellProps,
} from 'semantic-ui-react'

export const LoadingView = () => (
  <Dimmer active inverted>
    <Loader>Loading</Loader>
  </Dimmer>
)

export type RenderableHeader = {
  key: string
  render: () => ReactNode
}

export interface TypeColumn<T extends object> {
  header: string | RenderableHeader
  accessor: keyof T | null
  width?: SemanticWIDTHS
  render?: (
    row: T,
    drawerState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  ) => ReactNode
  cellProps?: TableCellProps
  sortColumn?: string
}

export type OrderBy = {
  column: string
  direction: 'ascending' | 'descending'
}

export interface AdminBaseTableProps<T extends { id: number }> {
  columns: TypeColumn<T>[]
  data: T[]
  tableRowPropsConfig?: TableRowProps<T>
  orderBy?: OrderBy
  onOrderByChange?: (orderBy: OrderBy) => void
}

export type TableRowProps<T> = {
  negative?: (row: T) => boolean
  warning?: (row: T) => boolean
  positive?: (row: T) => boolean
  disabled?: (row: T) => boolean
  renderDrawer?: (row: T, closeDrawer: () => void) => ReactNode
}

const AdminBaseTableRow = <T extends { id: number }>({
  columns,
  item,
  tableRowPropsConfig,
}: {
  columns: TypeColumn<T>[]
  item: T
  tableRowPropsConfig?: TableRowProps<T>
}) => {
  const drawerState = useState(false)
  const [drawerOpen, setDrawerOpen] = drawerState

  const closeDrawer = () => setDrawerOpen(false)

  return (
    <>
      <Table.Row
        negative={tableRowPropsConfig?.negative?.(item)}
        positive={tableRowPropsConfig?.positive?.(item)}
        warning={tableRowPropsConfig?.warning?.(item)}
        disabled={tableRowPropsConfig?.disabled?.(item)}
        onDoubleClick={() => setDrawerOpen(!drawerOpen)}
        style={{
          cursor: tableRowPropsConfig?.renderDrawer ? 'pointer' : 'default',
        }}
      >
        {tableRowPropsConfig?.renderDrawer && (
          <Table.Cell
            onClick={() => setDrawerOpen(!drawerOpen)}
            width={1}
            collapsing
          >
            <Icon name={drawerOpen ? 'caret down' : 'caret right'} size="big" />
          </Table.Cell>
        )}

        {columns.map((column) => {
          const objectVal = column.accessor ? item[column.accessor] : ''
          return (
            <Table.Cell
              {...column.cellProps}
              key={`${item.id}-${column.header.toString()}`}
              width={column.width}
            >
              {column.render
                ? column.render(item, drawerState)
                : typeof objectVal === 'string' || typeof objectVal === 'number'
                  ? objectVal
                  : ''}
            </Table.Cell>
          )
        })}
      </Table.Row>
      {drawerOpen && tableRowPropsConfig?.renderDrawer && (
        <Table.Row key={`${item.id}-drawer`}>
          {tableRowPropsConfig.renderDrawer(item, closeDrawer)}
        </Table.Row>
      )}
    </>
  )
}

export const AdminBaseTable = <T extends { id: number }>({
  columns,
  data,
  tableRowPropsConfig,
  orderBy,
  onOrderByChange,
}: AdminBaseTableProps<T>) => {
  const onColumnHeaderClick = useCallback(
    (column?: string) => {
      if (column && onOrderByChange) {
        if (orderBy?.column === column) {
          onOrderByChange({
            column,
            direction:
              orderBy.direction === 'ascending' ? 'descending' : 'ascending',
          })
        } else {
          onOrderByChange({ column, direction: 'ascending' })
        }
      }
    },
    [orderBy, onOrderByChange]
  )

  return (
    <Table
      selectable
      compact
      striped
      celled
      sortable={columns.some((col) => col.sortColumn)}
    >
      <Table.Header>
        <Table.Row>
          {tableRowPropsConfig?.renderDrawer && <Table.HeaderCell />}

          {columns.map((col) =>
            typeof col.header === 'string' ? (
              <Table.HeaderCell
                key={col.header}
                sorted={
                  orderBy?.column === col.sortColumn
                    ? orderBy?.direction
                    : undefined
                }
                onClick={() => onColumnHeaderClick(col.sortColumn)}
              >
                {col.header}
              </Table.HeaderCell>
            ) : (
              <Table.HeaderCell key={col.header.key}>
                {col.header.render()}
              </Table.HeaderCell>
            )
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map((item) => (
          <AdminBaseTableRow
            key={item.id}
            columns={columns}
            item={item}
            tableRowPropsConfig={tableRowPropsConfig}
          />
        ))}
      </Table.Body>
    </Table>
  )
}
