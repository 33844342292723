import { useEffect, useMemo, useState } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Alert,
  Button,
  GridRowColumn,
  Icon,
  Link,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  getAboundTaxDocsForPayer,
  selectAreAll1099sApprovedForFilingWithAbound,
  selectAreAll1099sFiled,
} from '../aboundAnnualTaxFilings.selector'
import {
  fetchAboundPayer,
  fetchAboundContractors,
  fetchAboundTaxDocumentsForPayer,
} from '../aboundAnnualTaxFilings.slice'
import TaxCenterPromptCard, {
  PromptCardStatus,
  TaxPromptCardButton,
  TaxPromptCardInfo,
} from '../components/TaxCenterPromptCard'
import { zeroToTenIntToWord } from './constants'
import { ContractorList } from './ContractorList'
import {
  NEC_CUTOFF,
  selectContractorNecEndByYear,
  selectFileNecStatusByYear,
} from '../annualTaxFilings.selector'
import { AnnualTaxFilingStepStatus } from '../constants'
import Ten99OptOutModal from './Ten99OptOutModal'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const ContractorActionCard = ({
  year,
  status,
}: {
  year: string
  status: PromptCardStatus
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const dueDate = useReselector(selectContractorNecEndByYear, year)
  const necStatus = useReselector(selectFileNecStatusByYear, year)
  const showMissedDeadlineAlert = useMemo(() => {
    if (
      [
        AnnualTaxFilingStepStatus.upcoming,
        AnnualTaxFilingStepStatus.completeLocked,
      ].includes(necStatus)
    ) {
      return false
    }
    return dueDate && DateTime.now() >= dueDate
  }, [dueDate, necStatus])

  return (
    <Grid>
      {showMissedDeadlineAlert && (
        <GridRowColumn>
          <Alert
            type="warn"
            customIcon={<Icon icon={solid('calendar-exclamation')} size="1x" />}
          >
            <Text as="h3">Missed the deadline? Heard can still help.</Text>
            <Text>
              We can file your 1099-NEC for you if you paid a contractor more
              than $600. The sooner you file, the less you’ll pay in late-filing
              penalty fees.
            </Text>
          </Alert>
        </GridRowColumn>
      )}
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/contractor.svg"
          style={{ width: 452, height: 260 }}
        />
      </GridRowColumn>
      <GridRowColumn spacer={3} width={10}>
        <Text textAlign="center">
          In the Tax Season Kickoff, you indicated that you paid or might pay
          contractors in {year}. If you’re unsure whether you should file a
          1099-NEC, read our{' '}
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/18872262968727"
            newPage
          >
            FAQs
          </Link>
          .
        </Text>
      </GridRowColumn>
      {necStatus === AnnualTaxFilingStepStatus.notStarted && (
        <GridRowColumn centerContent>
          <Button variant="actionLink" onClick={() => setModalOpen(true)}>
            I don’t need to file a 1099-NEC
          </Button>
        </GridRowColumn>
      )}
      <TaxPromptCardButton
        status={status}
        actionLink="/taxes/annual/file_1099_nec/verify_business"
      />
      <TaxPromptCardInfo
        timeToComplete="15 minutes"
        docInfo="Completed W-9 form for each contractor"
        dueDate={dueDate}
      />
      <Ten99OptOutModal
        year={year}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </Grid>
  )
}

const Ten99Card = ({ year }: { year: string }) => {
  const dispatch = useAppDispatch()
  const ten99s = useReselector(getAboundTaxDocsForPayer)
  const all1099sFiled = useReselector(selectAreAll1099sFiled)
  const all1099sApprovedForFilingWithAbound = useReselector(
    selectAreAll1099sApprovedForFilingWithAbound
  )

  const ten99Count = useMemo(() => Object.values(ten99s || {}).length, [ten99s])
  const numberText =
    ten99Count > 10
      ? 'more than ten'
      : zeroToTenIntToWord[ten99Count as keyof typeof zeroToTenIntToWord]

  const necStatus = useReselector(selectFileNecStatusByYear, year)

  const status = useMemo(() => {
    switch (necStatus) {
      case AnnualTaxFilingStepStatus.inProgress:
        return PromptCardStatus.IN_PROGRESS
      case AnnualTaxFilingStepStatus.completeLocked:
        return PromptCardStatus.DONE
      case AnnualTaxFilingStepStatus.notStarted:
        return PromptCardStatus.ACTION_REQUIRED
      case AnnualTaxFilingStepStatus.upcoming:
        return PromptCardStatus.COMING_SOON
      default:
        return necStatus satisfies never
    }
  }, [necStatus])

  useEffect(() => {
    dispatch(fetchAboundPayer())
    dispatch(fetchAboundContractors())
    dispatch(fetchAboundTaxDocumentsForPayer())
  }, [dispatch])

  return (
    <TaxCenterPromptCard
      body={{
        [PromptCardStatus.COMING_SOON]: (
          <ContractorActionCard year={year} status={status} />
        ),
        [PromptCardStatus.ACTION_REQUIRED]: (
          <ContractorActionCard year={year} status={status} />
        ),
        // There are 2 in progress states for this card -
        // 1) If user is in 1099 flow and hasn't submitted
        // 2) User has submitted but 1099s haven't been filed
        [PromptCardStatus.IN_PROGRESS]: all1099sApprovedForFilingWithAbound ? (
          <>
            {DateTime.now() < NEC_CUTOFF && (
              <Text as="bodyLg">
                We’re filing <b>{numberText}</b> 1099-NEC{ten99Count > 1 && 's'}{' '}
                for you starting in January.
              </Text>
            )}
            <br />
            <ContractorList />
          </>
        ) : (
          <ContractorActionCard year={year} status={status} />
        ),
        [PromptCardStatus.DONE]: (
          <>
            {all1099sFiled ? (
              <Text>
                All 1099-NECs have been filed. Please send these documents to
                your contractors.
              </Text>
            ) : (
              DateTime.now() < NEC_CUTOFF && (
                <Text>
                  We&apos;re filing <b>{numberText}</b> 1099-NEC
                  {ten99Count > 1 && 's'} for you.
                </Text>
              )
            )}
            <br />
            <ContractorList />
          </>
        ),
      }}
      icon={regular('user-pen')}
      status={status}
      title={
        all1099sFiled
          ? 'You’ve completed the Contractor Payroll (Form 1099-NEC) filings'
          : 'Contractor Payroll (Form 1099-NEC)'
      }
    />
  )
}

export default Ten99Card
