import {
  Alert,
  Button,
  Card,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import { Grid, Image, Divider } from 'semantic-ui-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { fetchUserTransactions } from '../../../Transactions/transactions.slice'
import { Transaction } from '../../../../reducers/admin/allTransactions.slice'
import ClarifyingModal from './ClarifyingTransactionsModal'
import { useReselector } from '../../../../utils/sharedHooks'
import { DateTime } from 'luxon'
import { sampleTransactionData } from './SampleTransactionData'
import SampleTransactionCard from './SampleTransactionCard'
import { filterUserTransactions } from '../../../Transactions/transactions.selectors'
import { Colors } from '../../../../styles/theme'
import { fetchTransactionCategoriesIfNeeded } from '../../../Reports/reports.slice'
import { isNil } from 'lodash'
import { updateUserOnboardingStep } from '../../UserOnboardingSteps/onboarding.actions'
import { getUserStepIdByOnboardingStepId } from '../../UserOnboardingSteps/onboarding.selectors'
import { BookkeepingOnboardingStep } from '../../UserOnboardingSteps/onboarding.reducer'
import { AhaMomentsBookeepingHeader } from '../AhaMomentsHeaderFooter'
import { TransactionRuleCategoryType } from '../../../../reducers/admin/transactionRulesReducer'
import SampleTransactionCardHeader from './SampleTransactionCardHeader'

const ClarifyTransactionsExample = ({
  handleContinue,
  handleBack,
}: {
  handleContinue: () => void
  handleBack: () => void
}) => {
  const dispatch = useAppDispatch()
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)
  const [isSkipModalOpen, setIsSkipModalOpen] = useState(false)
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true)
  const [transactionsToDisplay, setTransactionsToDisplay] = useState<
    Transaction[]
  >([])
  const [displaySampleTransaction, setDisplaySampleTransaction] =
    useState(false)
  const [
    isClarifyingConfirmationModalOpen,
    setIsClarifyingConfirmationModalOpen,
  ] = useState(false)
  const { transactions } = useReselector(filterUserTransactions, 'all')
  const currentStepId = useReselector(
    getUserStepIdByOnboardingStepId,
    BookkeepingOnboardingStep.clarifyTransactionsExample
  )

  useEffect(() => {
    async function fetch() {
      await Promise.all([
        dispatch(fetchUserTransactions()),
        dispatch(fetchTransactionCategoriesIfNeeded()),
      ])
    }
    fetch()
  }, [dispatch])

  const handleContinueWithSampleData = () => {
    setIsClarifyingConfirmationModalOpen(true)
  }

  const handleContinueInClarifyingModal = () => {
    setIsClarifyingConfirmationModalOpen(false)
    handleContinue()
  }

  const amazonOrVenmoTransactionsToDisplay = useMemo(() => {
    return transactions
      .filter(
        (transaction) =>
          transaction.description.toLowerCase().includes('amazon') ||
          transaction.description.toLowerCase().includes('venmo')
      )
      .slice(0, 5)
  }, [transactions])

  useEffect(() => {
    const shouldDisplaySample = amazonOrVenmoTransactionsToDisplay.length === 0
    setDisplaySampleTransaction(shouldDisplaySample)

    setTransactionsToDisplay(
      shouldDisplaySample
        ? sampleTransactionData
        : amazonOrVenmoTransactionsToDisplay
    )
  }, [amazonOrVenmoTransactionsToDisplay])

  const updateSampleTransaction = useCallback(
    (transactionId: number, updates: Partial<Transaction>) => {
      setTransactionsToDisplay((currentTransactions) =>
        currentTransactions.map((transaction) =>
          transaction.id === transactionId
            ? { ...transaction, ...updates }
            : transaction
        )
      )
    },
    []
  )
  useEffect(() => {
    const allTransactionsValid = transactionsToDisplay.every((transaction) => {
      const hasType = !isNil(transaction.type)

      // If it's a personal transaction, only type is required
      if (transaction.type === TransactionRuleCategoryType.personal) {
        return hasType
      }

      // else - need type AND (category OR note)
      const hasCategory =
        !isNil(transaction.transactionCategory?.name) &&
        transaction.transactionCategory?.name.trim() !== ''

      const hasNote =
        !isNil(transaction.notes) && transaction.notes.trim() !== ''

      return hasType && (hasCategory || hasNote)
    })

    setContinueButtonDisabled(!allTransactionsValid)
  }, [transactionsToDisplay])

  const handleSkipContinue = async () => {
    if (currentStepId) {
      try {
        await updateUserOnboardingStep({
          id: currentStepId,
          updatableFields: { skippedAt: DateTime.now().toISO() },
        })(dispatch)
      } catch (error) {
        console.error('Error updating onboarding step:', error)
      }
    }
    handleContinue()
  }

  const handleOpenModal = (setter: (value: boolean) => void) => () =>
    setter(true)
  const handleCloseModal = (setter: (value: boolean) => void) => () =>
    setter(false)

  return (
    <>
      <AhaMomentsBookeepingHeader />
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 1000,
          padding: 0,
          margin: '0 auto',
        }}
      >
        <GridRowColumn style={{ padding: '16px 0' }}>
          <GridRowColumn style={{ marginBottom: 45 }}>
            <Text as="display2">Learn How Bookkeeping Works</Text>
          </GridRowColumn>
          <Card backgroundColor="natural">
            <Grid>
              <Grid.Column
                width={4}
                verticalAlign="middle"
                style={{ marginRight: 10 }}
              >
                <Image src="https://heard-images.s3.amazonaws.com/assets/clarify-transactions.png" />
              </Grid.Column>
              <Grid.Column width={11}>
                <Text as="display3">Let&apos;s clarify some transactions!</Text>
                <br />
                <GridRowColumn>
                  <Text>
                    We&apos;ve identified some unclear transactions. Here&apos;s
                    how to provide your input:
                  </Text>
                </GridRowColumn>
                <br />
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={1} style={{ padding: 0, margin: 0 }}>
                      <GridRowColumn>
                        <Image
                          src="https://heard-images.s3.amazonaws.com/assets/number-1.png"
                          alt="Step 1"
                          style={{
                            marginTop: 5,
                            marginLeft: 10,
                            width: 25,
                            height: 25,
                          }}
                        />
                      </GridRowColumn>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <GridRowColumn>
                        <Text>
                          <strong>Select a type</strong>
                          <br />
                          Mark the transaction as either{' '}
                          <strong>Business</strong> or
                          <strong> Personal</strong>.
                        </Text>
                      </GridRowColumn>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={1} style={{ padding: 0, margin: 0 }}>
                      <GridRowColumn>
                        <Image
                          src="https://heard-images.s3.amazonaws.com/assets/number-2.png"
                          alt="Step 2"
                          style={{ width: 25, height: 25, marginLeft: 10 }}
                        />
                      </GridRowColumn>
                    </Grid.Column>
                    <Grid.Column width={14}>
                      <GridRowColumn style={{ marginBottom: 20 }}>
                        <Text>
                          <strong>Select a category or add a note</strong>
                          <br />
                          If it&apos;s a business transaction, select a
                          category. If you&apos;re not sure which category to
                          use, you can add a note by clicking{' '}
                          <b style={{ color: Colors.accentGreen }}>Add Note.</b>
                        </Text>
                      </GridRowColumn>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <GridRowColumn style={{ marginTop: 20 }}>
                  <Button
                    onClick={handleOpenModal(setIsVideoModalOpen)}
                    variant="link"
                  >
                    Watch tutorial video
                  </Button>
                </GridRowColumn>
              </Grid.Column>
            </Grid>
          </Card>
        </GridRowColumn>
        <br />
        {displaySampleTransaction && (
          <Alert type="warn">
            <Text as="bodySm">
              Note: This is <b>sample data</b> for demonstration purposes only.
            </Text>
          </Alert>
        )}

        <br />
        <SampleTransactionCardHeader />
        {transactionsToDisplay.map((transaction) => (
          <SampleTransactionCard
            key={transaction.id}
            transaction={transaction}
            displaySampleTransaction={displaySampleTransaction}
            updateSampleTransaction={updateSampleTransaction}
          />
        ))}
        <GridRowColumn>
          <Text>
            Don&apos;t have time for this now? Don&apos;t worry, your progress
            is automatically saved.
          </Text>
          <br />
          <Divider />
        </GridRowColumn>
        <Grid.Row>
          <Grid.Column width={2}>
            <Button variant="secondary" size="medium" onClick={handleBack}>
              Back
            </Button>
          </Grid.Column>
          <Grid.Column
            width={14}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="secondary"
              size="medium"
              style={{ marginRight: '10px' }}
              onClick={handleOpenModal(setIsSkipModalOpen)}
            >
              Skip for now
            </Button>

            <Button
              variant="primary"
              size="medium"
              disabled={continueButtonDisabled}
              onClick={
                displaySampleTransaction
                  ? handleContinueWithSampleData
                  : handleContinue
              }
            >
              Continue
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {isVideoModalOpen && (
        <ClarifyingModal
          open={isVideoModalOpen}
          onClose={handleCloseModal(setIsVideoModalOpen)}
          title="How to clarify transactions"
          content={
            <iframe
              src="https://www.loom.com/embed/a8860aa90adb41708ba88c4b874e34c3"
              width="100%"
              height="500px"
              allowFullScreen
              title="How to clarify transactions"
              sandbox="allow-scripts allow-same-origin allow-popups"
            />
          }
          actions={null}
        />
      )}
      {isSkipModalOpen && (
        <ClarifyingModal
          open={isSkipModalOpen}
          onClose={handleCloseModal(setIsSkipModalOpen)}
          title="Are you sure you want to skip?"
          content={
            <>
              {displaySampleTransaction ? (
                <Text>
                  You will likely need to clarify some of your actual
                  transactions later, so doing this step will help you learn how
                  to do so quickly and easily.
                </Text>
              ) : (
                <Text>
                  You will likely need to clarify these transactions later, so
                  this will help complete your books faster!
                </Text>
              )}
              <br />
              <Text>
                You can also take a break and come back to this step another
                time.
              </Text>
            </>
          }
          actions={
            <>
              <Button
                variant="actionLink"
                style={{ marginRight: '20px' }}
                onClick={handleSkipContinue}
              >
                Skip
              </Button>
              <Button positive onClick={handleCloseModal(setIsSkipModalOpen)}>
                Return to clarifying
              </Button>
            </>
          }
        />
      )}
      {isClarifyingConfirmationModalOpen && (
        <ClarifyingModal
          open={isClarifyingConfirmationModalOpen}
          onClose={handleCloseModal(setIsClarifyingConfirmationModalOpen)}
          title="Thanks for clarifying!"
          content={
            <>
              <Image>
                <img
                  src="https://heard-images.s3.amazonaws.com/assets/clarifying.png"
                  alt="Clarify transactions"
                />
              </Image>
              <Text>
                Transactions from vendors like Amazon and Venmo usually require
                clarification, since it&apos;s hard to tell if they&apos;re
                personal or for your business.
              </Text>
              <br />
              <Text>
                We&apos;ll let you know if we need you to do this again in the
                future!
              </Text>
            </>
          }
          actions={
            <Button positive onClick={handleContinueInClarifyingModal}>
              Continue Onboarding
            </Button>
          }
        />
      )}
    </>
  )
}

export default ClarifyTransactionsExample
