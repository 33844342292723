import { useCallback, useMemo } from 'react'
import type { Colors } from '../../../styles/theme'
import PaginationButton from './PaginationButton'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'

const PAGE_BREAK = '...'

interface Props {
  currentPage: number
  className?: string
  disabled?: boolean
  hoverColor?: keyof typeof Colors
  onPageClick?: (page: number) => void
  pages: number
}

const PaginationPages = ({
  currentPage,
  className,
  disabled = false,
  onPageClick,
  pages,
}: Props) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  // Display a maximum of 5 page elements on mobile and 7 on desktop/tablet
  const maxPageElements = useMemo(() => (isMobile ? 5 : 7), [isMobile])
  const paginationElementCount =
    pages > maxPageElements ? maxPageElements : pages

  const shouldRenderBreaks = useMemo(
    () => pages > maxPageElements,
    [pages, maxPageElements]
  )
  const inFirstSection = useMemo(
    () => currentPage <= (isMobile ? 2 : 4),
    [currentPage, isMobile]
  )
  const inLastSection = useMemo(
    () => currentPage >= pages - (isMobile ? 1 : 3),
    [currentPage, pages, isMobile]
  )

  const getButtonContents = useCallback<(index: number) => string>(
    (index: number) => {
      if (!shouldRenderBreaks) {
        return `${index + 1}`
      }

      /**
       * Determines what page numbers to display and where the page breaks (...)
       * should fall based on the current page and the number of pages
       */
      const mobileContentsMap: { [key: number]: string | number } = {
        0: '1',
        1: inFirstSection ? '2' : PAGE_BREAK,
        2: inFirstSection ? '3' : inLastSection ? pages - 2 : currentPage,
        3: inLastSection ? pages - 1 : PAGE_BREAK,
        4: pages,
      }

      // Non-mobile version
      const contentsMap: { [key: number]: string | number } = {
        0: '1',
        1: inFirstSection ? '2' : PAGE_BREAK,
        2: inFirstSection ? '3' : inLastSection ? pages - 4 : currentPage - 1,
        3: inFirstSection ? '4' : inLastSection ? pages - 3 : currentPage,
        4: inFirstSection ? '5' : inLastSection ? pages - 2 : currentPage + 1,
        5: inLastSection ? pages - 1 : PAGE_BREAK,
        6: pages,
      }

      return isMobile ? `${mobileContentsMap[index]}` : `${contentsMap[index]}`
    },
    [
      currentPage,
      inFirstSection,
      inLastSection,
      pages,
      shouldRenderBreaks,
      isMobile,
    ]
  )

  const isCurrentPage = useCallback<(page: number, index: number) => boolean>(
    (page: number, index: number) => {
      if (!shouldRenderBreaks || inFirstSection) {
        return page - 1 === index
      }

      if (inLastSection) {
        return maxPageElements - (pages - page) - 1 === index
      }

      return index === (isMobile ? 2 : 3)
    },
    [
      inFirstSection,
      inLastSection,
      pages,
      shouldRenderBreaks,
      isMobile,
      maxPageElements,
    ]
  )

  return (
    <>
      {[...Array(paginationElementCount)].map((_key, index) => {
        const buttonContents = getButtonContents(index)
        const buttonPage = Number.parseInt(buttonContents)
        const $hoverable = buttonContents !== PAGE_BREAK

        return (
          <PaginationButton
            $current={isCurrentPage(currentPage, index)}
            disabled={disabled}
            $hoverable={$hoverable}
            key={`PaginationButton_${className}_${index}`}
            {...($hoverable && { onClick: () => onPageClick?.(buttonPage) })}
          >
            {buttonContents}
          </PaginationButton>
        )
      })}
    </>
  )
}

export default PaginationPages

export type { Props as PaginationProps }
