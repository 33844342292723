import { getContractorName } from '../../helpers'
import { formatCurrency } from '../../../../utils/currencyHelpers'
import { Table } from '../../../../components/BaseComponents'
import { Gusto_Contractor } from '../../generated_gusto_types'

const ContractorPaymentTable = ({
  contractor,
  hours,
  bonus,
  wages,
  reimbursement,
  total,
}: {
  contractor: Gusto_Contractor
  hours: string
  bonus: string
  wages: number | string
  reimbursement: string
  total: number
}) => (
  <Table basic="very">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell color="green">Name</Table.HeaderCell>
        <Table.HeaderCell color="green">Hours</Table.HeaderCell>
        <Table.HeaderCell color="green">Wages</Table.HeaderCell>
        <Table.HeaderCell color="green">Reimbursements</Table.HeaderCell>
        <Table.HeaderCell color="green">Bonus</Table.HeaderCell>
        <Table.HeaderCell color="green">Total Amount</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      <Table.Row>
        <Table.Cell style={{ paddingLeft: 10 }}>
          {getContractorName(contractor, true)}
        </Table.Cell>
        <Table.Cell>{hours}</Table.Cell>
        <Table.Cell>{formatCurrency(wages)}</Table.Cell>
        <Table.Cell>{formatCurrency(reimbursement)}</Table.Cell>
        <Table.Cell>{formatCurrency(bonus)}</Table.Cell>
        <Table.Cell>{formatCurrency(total)}</Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
)

export default ContractorPaymentTable
