import { useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu } from 'semantic-ui-react'

import AdminNotesModal from '../../../features/Admin/Transactions/AdminNotesModal'
import { useDeleteTransaction } from '../shared/DeleteTransactionButton'
import { useToggleTransactionClarification } from '../shared/NeedsClarificationButton'
import { useEditManualTransaction } from '../shared/EditTransactionModule'
import { useToggleTransactionConfirmed } from '../shared/ConfirmTransactionButton'
import { useClearDuplicateTransaction } from '../shared/MarkedDuplicateTransactionButton'
import { Transaction } from '../../../reducers/admin/allTransactions.slice'

const RowActions = ({
  row,
  onTransactionUpdate,
  onTransactionDelete,
}: {
  row: Transaction
  onTransactionUpdate: (id: number) => void
  onTransactionDelete: (id: number) => void
}) => {
  const onUpdated = () => onTransactionUpdate(row.id)
  const onDeleted = () => onTransactionDelete(row.id)

  const [adminNotesModalOpen, setAdminNotesModalOpen] = useState(false)

  const { openModal: openDuplicateModal, renderModal: renderDuplicateModal } =
    useClearDuplicateTransaction({ transaction: row, onUpdated })

  const {
    unconfirming,
    ineligible: ineligibleForConfirm,
    openModal: openConfirmModal,
    renderModal: renderConfirmModal,
  } = useToggleTransactionConfirmed({ transaction: row, onUpdated })

  const [isEditingTransaction, setEditingTransaction] = useState(false)
  const { openModal: openEditModal, renderModal: renderEditModal } =
    useEditManualTransaction({
      transaction: row,
      onUpdated,
      onChildBeginLoading: () => setEditingTransaction(true),
      onChildEndLoading: () => setEditingTransaction(false),
      disabled: isEditingTransaction,
    })

  const { toggleClarification } = useToggleTransactionClarification({
    transaction: row,
    onUpdated,
  })

  const { renderConfirm: renderConfirmForDelete, showConfirm: confirmDelete } =
    useDeleteTransaction({
      transaction: row,
      onDeleted,
    })

  return (
    <>
      <Dropdown
        text="Actions"
        direction="left"
        style={{ whiteSpace: 'nowrap' }}
      >
        <DropdownMenu>
          <DropdownItem
            text="Edit Admin Notes"
            onClick={() => setAdminNotesModalOpen(true)}
          />

          {row.manuallyDedupedAt && (
            <DropdownItem
              text="Clear duplicate"
              onClick={() => openDuplicateModal()}
            />
          )}

          {process.env.VITE_HIDE_TRXN_CONFIRM_BUTTON !== 'true' && (
            <DropdownItem
              text={
                row.requestedClarificationAt
                  ? 'Remove Clarification Request'
                  : 'Request Clarification'
              }
              onClick={() => toggleClarification()}
            />
          )}

          <DropdownItem
            text={unconfirming ? 'Unconfirm' : 'Confirm'}
            onClick={() => openConfirmModal()}
            disabled={ineligibleForConfirm}
          />

          {row.manuallyCreated && !row.reconciliationId && !row.confirmedAt && (
            <DropdownItem
              text="Edit Manual Transaction"
              onClick={() => openEditModal()}
            />
          )}

          <DropdownItem
            text="Delete Transaction"
            onClick={() => confirmDelete()}
          />
        </DropdownMenu>
      </Dropdown>
      <AdminNotesModal
        modalOpen={adminNotesModalOpen}
        setModalOpen={setAdminNotesModalOpen}
        transaction={row}
        onUpdated={onUpdated}
      />
      {renderDuplicateModal()}
      {renderConfirmModal()}
      {renderEditModal()}
      {renderConfirmForDelete()}
    </>
  )
}

export default RowActions
