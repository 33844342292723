import { useCallback, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import { Alert, GridRowColumn } from '../../../../components/BaseComponents'
import {
  AnnualTaxFiling,
  CREATE_ANNUAL_TAX_FILING_KEY,
  FETCH_TAX_FILINGS_KEY,
  fetchAnnualTaxFilingsIfNeeded,
  SUBMIT_ANNUAL_TAX_FILING_KEY,
  UPDATE_ANNUAL_TAX_FILING_KEY,
} from '../annualTaxFilings.slice'
import { useReselector } from '../../../../utils/sharedHooks'
import TaxFormPanel from './TaxFormPanel'
import {
  getIsFetchingOrNotStarted,
  selectFirstErrorMessageForKeys,
} from '../../../../reducers/fetch'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { fetchUserTaxEstimates } from '../../QuarterlyTaxEstimates/userTaxEstimates.slice'
import { UserActionItemActionItemIdentifiers } from '../../../Dashboard/UserActionItems/userActionItems.slice'
import { markUserActionItemCompleteIfExists } from '../../../Dashboard/UserActionItems/service'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { useSaveAnnualTaxFilingFormData } from '../helpers'
import Additional1040Panel from './Additional1040Panel'
import ImportantDatesPanel from './ImportantDatesPanel'
import SurveyReviewPanel from './SurveyReviewPanel'
import ContractorNecPanel from './ContractorNecPanel'
import PageHeader from '../../../../components/shared/PageHeader'
import {
  selectAllowEditTaxSeasonKickoffForYear,
  selectAnnualTaxFilingForYearUnconfirmed,
} from '../annualTaxFilings.selector'
import {
  useFormFlow,
  useSetScreen,
} from '../../../../components/FormFlow/formFlow'
import { useAnalyticsTrack } from '../../../Amplitude'
import { useAppDispatch } from '../../../../utils/typeHelpers'

export interface TaxSeasonKickoffStepProps {
  year: string
  goToNextStep: (
    data: Partial<AnnualTaxFiling> | null,
    newScreen: TAX_SURVEY_CONTENT_SCREENS | null
  ) => void
  goBack: (newScreen: TAX_SURVEY_CONTENT_SCREENS | null) => void
}

export enum TAX_SURVEY_CONTENT_SCREENS {
  formSelection = 'formSelection',
  additionalForms = 'additionalForms',
  contractorNec = 'contractorNec',
  importantDates = 'importantDates',
  review = 'review',
}

const AnnualTaxSurvey = () => {
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()
  const year = useReselector(selectCurrentAnnualTaxYear)
  const location = useLocation()
  const loading = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_TAX_FILINGS_KEY
  )
  const error = useReselector(selectFirstErrorMessageForKeys, [
    CREATE_ANNUAL_TAX_FILING_KEY,
    UPDATE_ANNUAL_TAX_FILING_KEY,
    SUBMIT_ANNUAL_TAX_FILING_KEY,
  ])

  const saveTaxFiling = useSaveAnnualTaxFilingFormData(year)
  const allowEditSurvey = useReselector(
    selectAllowEditTaxSeasonKickoffForYear,
    year
  )
  const filingUnconfirmed = useReselector(
    selectAnnualTaxFilingForYearUnconfirmed,
    year
  )

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingsIfNeeded())
    dispatch(fetchUserTaxEstimates())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
  }, [dispatch])

  const { setScreen, currentScreen } = useSetScreen({
    backLink: '/taxes/annual',
    reviewScreen: TAX_SURVEY_CONTENT_SCREENS.review,
  })

  useEffect(() => {
    // These 2 effects seem unrelated but setSearchParams can prevent navigation from working properly
    if (
      (year && !location.pathname.includes(year)) ||
      !allowEditSurvey ||
      filingUnconfirmed
    ) {
      // Navigate back to tax center if the user isn't supposed to be in this flow or url is invalid
      setScreen(null)
    } else if (!currentScreen) {
      // Set current screen as form selection if it's not currently set
      setScreen(TAX_SURVEY_CONTENT_SCREENS.formSelection, true)
    }
  }, [
    allowEditSurvey,
    currentScreen,
    filingUnconfirmed,
    location.pathname,
    setScreen,
    year,
  ])

  const saveFormData = useCallback(
    async (
      data: Partial<AnnualTaxFiling> | null,
      newScreen: TAX_SURVEY_CONTENT_SCREENS | null
    ) => {
      if (data) {
        const res = await saveTaxFiling({
          surveyLastUpdatedAt: new Date().toISOString(),
          ...data,
        })

        // Return false on failure
        if (!res) {
          return false
        }
      }

      if (data?.pretaxSurveySubmittedAt) {
        markUserActionItemCompleteIfExists(
          UserActionItemActionItemIdentifiers.completeTaxSeasonKickoff({
            year,
          }),
          (event, properties) => track(event, properties)
        )
        markUserActionItemCompleteIfExists(
          UserActionItemActionItemIdentifiers.completeTaxSeasonKickoffLateSignup(
            { year }
          ),
          (event, properties) => track(event, properties)
        )
      }

      setScreen(newScreen)

      return true
    },
    [saveTaxFiling, setScreen, year, track]
  )

  const screenConfig = useMemo(() => {
    const props = { year, goToNextStep: saveFormData, goBack: setScreen }

    return [
      {
        component: () => <TaxFormPanel {...props} />,
        step: 0,
        screenName: TAX_SURVEY_CONTENT_SCREENS.formSelection,
      },
      {
        component: () => <Additional1040Panel {...props} />,
        step: 0,
        screenName: TAX_SURVEY_CONTENT_SCREENS.additionalForms,
      },
      {
        component: () => <ContractorNecPanel {...props} />,
        step: 0,
        screenName: TAX_SURVEY_CONTENT_SCREENS.contractorNec,
      },
      {
        component: () => <ImportantDatesPanel {...props} />,
        step: 1,
        screenName: TAX_SURVEY_CONTENT_SCREENS.importantDates,
      },
      {
        component: () => <SurveyReviewPanel {...props} />,
        step: 2,
        screenName: TAX_SURVEY_CONTENT_SCREENS.review,
      },
    ]
  }, [saveFormData, setScreen, year])

  const { progressBar, content } = useFormFlow({
    steps: ['Forms', 'Important Dates', 'Review'],
    screens: screenConfig,
  })

  return (
    <Grid>
      <GridRowColumn>
        <PageHeader header="Tax Season Kickoff" />
      </GridRowColumn>
      <GridRowColumn>{progressBar}</GridRowColumn>
      {error && (
        <GridRowColumn>
          <Alert type="error">{error}</Alert>
        </GridRowColumn>
      )}
      <GridRowColumn>{!loading && content}</GridRowColumn>
    </Grid>
  )
}

export default AnnualTaxSurvey
