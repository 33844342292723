import { useEffect, useMemo, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Grid, Icon, Divider } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { array, object } from 'yup'

import { EMPTY_ALLOCATION_RULE } from './AllocationConstants'
import { AllocationRule } from '../../reducers/finances/allocationRulesReducer'
import {
  Alert,
  Button,
  Card,
  FormikInput,
  Input,
  makeNumberSchema,
  makeReqStringSchema,
  Popup,
  Text,
} from '../BaseComponents'
import { Fonts } from '../../styles/theme'
import { useAnalyticsTrack } from '../../features/Amplitude'

const validationSchema = object({
  rules: array().of(
    object({
      ruleName: makeReqStringSchema({ field: 'allocation name' }),
      percentage: makeNumberSchema({
        field: 'Percentage',
        allowedDecimals: 2,
      }),
    })
  ),
})

interface Props {
  initialRules: AllocationRule[]
  percentageSum: number
  progressForward: () => void
  updateRules: (rule: AllocationRule[]) => void
}

const AllocationRuleForm = ({
  initialRules,
  updateRules,
  progressForward,
  percentageSum,
}: Props) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const isOnboarding = searchParams.get('onboarding') === 'true'
  const scrollRef = useRef<HTMLSpanElement>(null)

  const hasPercentageError = useMemo(
    () => Number(percentageSum) > 100,
    [percentageSum]
  )

  const track = useAnalyticsTrack()

  const formik = useFormik<{ rules: (AllocationRule & { hint?: string })[] }>({
    enableReinitialize: true,
    initialValues: {
      rules: initialRules,
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, formikHelpers) => {
      track('clicked review on allocation guide edit')
      const errors = await formikHelpers.validateForm(values)

      if ((!errors.rules || errors.rules.length === 0) && !hasPercentageError) {
        progressForward()
      }

      return
    },
  })

  useEffect(() => {
    updateRules(formik.values.rules)
  }, [formik.values.rules, updateRules])

  const handleRemove = (idx: number) => {
    const newRules = [...formik.values.rules]
    newRules.splice(idx, 1)
    formik.setFieldValue('rules', newRules)
    if (updateRules) {
      updateRules(newRules)
    }
  }

  const suggestionContent = () => (
    // React will warn us saying that a `<ul>` can't be a descendent of `<p>`, and since `<Text>`
    // renders a `<p>` we need to manually style a div with the `Fonts.bodyMd` text styles.
    <div style={Fonts.bodyMd}>
      We suggest the following allocation percentages to get started:
      <ul>
        <li>
          <b>Tax savings:</b> 30%
        </li>
        <li>
          <b>Owner&apos;s draw:</b> 50%
        </li>
        <li>
          <b>Rainy Day Expenses:</b> 15%
        </li>
        <li>
          <b>Retirement:</b> 5%
        </li>
      </ul>
    </div>
  )

  return (
    <FormikProvider value={formik}>
      <Card type="section">
        <Grid doubling stackable padded centered>
          <Grid.Row>
            <Alert customIcon="💡" style={{ textAlign: 'left' }}>
              This is based off your <b>monthly profit</b>, or what&apos;s left
              of your earnings once you&apos;ve paid your expenses for the
              month.{' '}
              <Popup
                trigger={
                  <Button
                    inline
                    variant="link"
                    style={{ textDecoration: 'underline' }}
                  >
                    We&apos;ve added our suggestions
                  </Button>
                }
                inline
                content={suggestionContent()}
                position="bottom right"
              />{' '}
              but feel free to adjust your allocations based on your personal
              circumstances.
            </Alert>
          </Grid.Row>
          <span ref={scrollRef} />
          {((formik.errors.rules?.length ?? 0) > 0 || hasPercentageError) && (
            <Grid.Row>
              <Alert type="error" style={{ textAlign: 'left' }}>
                Please correct the errors in your form!
              </Alert>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column width={8}>
              <Text as="h3">
                <b>Allocation Type</b>
              </Text>
            </Grid.Column>
            <Grid.Column width={6}>
              <Text as="h3">
                <b>Percentage of Profit to Allocate</b>
              </Text>
            </Grid.Column>
          </Grid.Row>
          {formik.values.rules.length === 0 && (
            <Grid.Row>
              <Grid.Column width={14}>
                <Alert type="error">
                  Please add Allocations here in order to build your Allocation
                  Guide!
                </Alert>
              </Grid.Column>
            </Grid.Row>
          )}
          {formik.values.rules.map((rule, idx) => (
            <Grid.Row
              key={`form-rule-row-${idx}`}
              verticalAlign="top"
              stretched
            >
              <Grid.Column width={8}>
                {/* For formik, in order to properly tie an input from a property that's an array,
                    we need to specify the index in the `name` prop of the form field. In this
                    case, we are retrieving the field name `rules` from the overall `formik.values`
                    object, appending a templated string `[${idx}]` which will resolve to something
                    like `[0]` for the 0th index, `[1]` for the first, and so on, then appending
                    `.ruleName` for the `ruleName `property of each rule.
                    
                    Boiling it down the `name` prop of this particular input will resolve to
                    `rules[0].ruleName` and `rules[0].percentage` for the one below. */}
                <FormikInput
                  name={`rules[${idx}].ruleName`}
                  placeholder="Fill in a name for this allocation"
                  fullWidth
                />
                {rule.hint && (
                  <Text as="bodySm" color="mediumGray">
                    {rule.hint}
                  </Text>
                )}
              </Grid.Column>
              <Grid.Column width={6} textAlign="right">
                <FormikInput
                  fullWidth
                  name={`rules[${idx}].percentage`}
                  placeholder="Enter percentage"
                  rightIcon="percent"
                  step="1"
                  type="number"
                />
              </Grid.Column>
              <Grid.Column
                width={1}
                style={{ alignItems: 'center', display: 'flex' }}
              >
                <Button onClick={() => handleRemove(idx)} floated="right" icon>
                  <Icon name="trash alternate outline" />
                </Button>
              </Grid.Column>
            </Grid.Row>
          ))}
          <Grid.Row>
            <Grid.Column width={5} floated="right">
              <Button
                fullWidth
                onClick={() => {
                  formik.setFieldValue('rules', [
                    ...formik.values.rules,
                    /* 
                      Note: this assumes the rule being created is a percentage 
                      If we were to update this, please look at EMPTY_ALLOCATION_RULE
                     */
                    EMPTY_ALLOCATION_RULE,
                  ])
                  formik.validateForm()
                }}
                variant="secondary"
              >
                Add additional Allocations
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column width={8} />
            <Grid.Column width={6}>
              <Text as="h3">
                <b>Total % of Profit Allocated</b>
              </Text>
              <br />
              <Input
                fullWidth
                rightIcon="percent"
                value={String(percentageSum)}
                readOnly={Boolean(percentageSum)}
                className="readOnly"
                name="value"
                error={
                  hasPercentageError &&
                  'Your total percentage allocated is greater than 100%'
                }
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>

      <Divider />

      <div className="flexbox">
        <Button
          variant="secondary"
          onClick={() => navigate(isOnboarding ? '/' : '/allocation-guides')}
        >
          Cancel
        </Button>
        <Button
          disabled={formik.values.rules.length === 0}
          id="btn-allocation-guide-creation-review"
          onClick={() => formik.handleSubmit()}
          type="submit"
        >
          Review
        </Button>
      </div>
    </FormikProvider>
  )
}

export default AllocationRuleForm
