import { createSelector } from 'reselect'
import { orderBy } from 'lodash'
import moment, { Moment } from 'moment'

import { UserImportantDate } from './userImportantDates.slice'
import { ReduxState } from '../../../utils/typeHelpers'

export const userImportantDates = (state: ReduxState) =>
  Object.values(state.userImportantDates)

export const getNonDeletedImportantDates = createSelector(
  [userImportantDates],
  (userImportantDates) =>
    userImportantDates.filter(
      (userImportantDates) =>
        !userImportantDates.deletedAt &&
        !userImportantDates.actionItem.deletedAt
    )
)

export const getUserImportantDatesBetween = createSelector(
  [
    userImportantDates,
    (
      _: unknown,
      args: {
        startDateParam: Moment
        endDateParam: Moment
        count: number | null
        filterCondition?: (importantDate: UserImportantDate) => boolean
      }
    ) => args,
  ],
  (userImportantDates, args) => {
    // .endsAt is required for user_important_dates, so we're sorting based on this property
    // select override or pick default ends at
    const ordered: UserImportantDate[] = orderBy(
      userImportantDates,
      (d) => {
        const endDate = d.endsAt || d.actionItem.endsAt
        return endDate && new Date(endDate)
      },
      ['asc']
    )

    // Filter so we are left with important dates falling between startDate and endDate (both inclusive)
    const filtered = ordered.filter((d) => {
      if (
        moment
          .utc(d.endsAt || d.actionItem.endsAt)
          .isBetween(args.startDateParam, args.endDateParam, undefined, '[]')
      ) {
        if (args.filterCondition) {
          return args.filterCondition(d)
        }
        return true
      }

      return false
    })

    if (args.count !== null) {
      return filtered.slice(0, args.count)
    }

    return filtered
  }
)
