import { createSelector } from 'reselect'
import moment from 'moment'
import { filter, groupBy, orderBy } from 'lodash'

import { ReduxState } from '../utils/typeHelpers'
import { BookkeepingReport } from '../reducers/finances/bookkeepingReportsReducer'

const selectBookkeepingReports = (state: ReduxState) => state.bookkeepingReports

/*
  User Facing
*/
export const sortBookkeepingReportsByYear = createSelector(
  [selectBookkeepingReports],
  (bookkeepingReports) => {
    const byYear: { [year: string]: BookkeepingReport[] } = groupBy(
      bookkeepingReports,
      (report: BookkeepingReport) => moment(report.date).format('YYYY')
    )

    if (byYear) {
      Object.keys(byYear).forEach((key) => {
        const year = byYear[key]
        byYear[key] = orderBy(year, ['date'], ['desc'])
      })
    }

    return byYear
  }
)

export const selectActiveReportByReportId = createSelector(
  selectBookkeepingReports,
  (_: unknown, bookkeepingReportId: string | undefined) => bookkeepingReportId,
  (reports, bookkeepingReportId) =>
    Object.values(reports).find(
      (item) => Number(item.id) === Number(bookkeepingReportId)
    )
)

export const selectActiveReportByDate = createSelector(
  selectBookkeepingReports,
  (_: unknown, bookkeepingReportDate: string | undefined) =>
    bookkeepingReportDate,
  (reports, bookkeepingReportDate) =>
    Object.values(reports).find((item) => item.date === bookkeepingReportDate)
)

/*
  Admin facing
*/
export const getBookkeepingReportsByBookkeeper = createSelector(
  [
    selectBookkeepingReports,
    (_: unknown, bookkeeperId: number | string) => bookkeeperId,
  ],
  (allBookkeepingReports, bookkeeperId) => {
    if (bookkeeperId === 'All Books') {
      return Object.values(allBookkeepingReports)
    } else if (bookkeeperId) {
      return filter(
        allBookkeepingReports,
        (item) =>
          item.reports?.financialProfile?.bookkeeperId === Number(bookkeeperId)
      )
    }
    return []
  }
)

export const getIncompleteReports = createSelector(
  [selectBookkeepingReports],
  (allBookkeepingReports) => {
    return Object.keys(allBookkeepingReports)
      .filter((key) => !allBookkeepingReports[key].completedAt)
      .reduce((obj: { [key: string]: BookkeepingReport }, key) => {
        obj[key] = allBookkeepingReports[key]
        return obj
      }, {})
  }
)
export const sortReports = (
  reports: BookkeepingReport[],
  sortColumnName: string,
  sortDirection: string
) => {
  const sort = sortDirection === 'ascending' ? 'asc' : 'desc'
  let result = reports

  if (sortColumnName === 'status') {
    result = orderBy(
      reports,
      (report: BookkeepingReport) => report.statuses[0]?.status,
      [sort]
    )
  } else {
    result = orderBy(reports, [sortColumnName], [sort])
  }
  return result
}
