import { schema } from 'normalizr'

// Finances

// // Define a users schema
// const user = new schema.Entity('users');

// // Define your comments schema
// const comment = new schema.Entity('comments', {
//   commenter: user
// });

// // Define your article
// const article = new schema.Entity('articles', {
//   author: user,
//   comments: [comment]
// });

export const transactionEditSchema = new schema.Entity('transactionEdits')
export const journalEntriesSchema = new schema.Entity('journalEntries')
// uses schema.Object to normalize the journal entries within the split transaction but not the split transaction itself
export const splitTransactionsSchema = new schema.Object({
  journalEntries: [journalEntriesSchema],
})
export const transactionSchema = new schema.Entity('transactions', {
  transactionEdits: [transactionEditSchema],
  journalEntries: [journalEntriesSchema],
  splitTransactions: [splitTransactionsSchema],
})
export const transactionListSchema = [transactionSchema]

export const adminCalendlyLinksSchema = new schema.Entity('adminCalendlyLink')
export const userCallEventSchema = new schema.Entity('userCallEvents', {
  adminCalendlyLinks: adminCalendlyLinksSchema,
})
export const userCallEventListSchema = [userCallEventSchema]

export const annualTaxFilingFormSchema = new schema.Entity(
  'annualTaxFilingForms'
)

// Commenting so im compatible with my api version
export const annualTaxFilingSchema = new schema.Entity('annualTaxFilings', {
  annualTaxFilingForms: [annualTaxFilingFormSchema],
})
export const annualTaxFilingListSchema = [annualTaxFilingSchema]

export const employeeJobSchema = new schema.Entity(
  'employeeJobs',
  {},
  {
    idAttribute: 'uuid',
  }
)

export const employeeSchema = new schema.Entity(
  'employees',
  {
    jobs: [employeeJobSchema],
  },
  {
    idAttribute: 'uuid',
  }
)

export const ticketCommentSchema = new schema.Entity('ticketComment')

export const ticketSchema = new schema.Entity('ticket', {
  comments: [ticketCommentSchema],
})
