import axios from 'axios'
import { keyBy } from 'lodash'

import {
  AllocationGuide,
  AllocationGuideSummary,
  receiveAllAllocationGuides,
  receiveAllocationGuidesSummaries,
  receiveSingleAllocationGuide,
} from '../reducers/finances/allocationGuidesReducer'
import {
  AllocationRule,
  receiveAllAllocationRules,
} from '../reducers/finances/allocationRulesReducer'
import { fetchIfNeededWrapper, fetchWrapper } from '../reducers/fetch'
import { User } from '../reducers/auth/userReducer'

/*
  Fetch all Allocation Guides
*/

export const FETCH_ALLOCATION_GUIDE_KEY = 'FETCH_ALLOCATION_GUIDE_KEY'
export const fetchAllocationGuidesIfNeeded = () =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_ALLOCATION_GUIDE_KEY,
    defaultErrorMessage: 'There was an error fetching all allocation guides.',
    fetchFunction: (dispatch) =>
      axios
        .get<AllocationGuide[]>('/finances/api/v1/allocation_guides')
        .then((json) => {
          dispatch(receiveAllAllocationGuides(keyBy(json.data, 'id')))
          return json.data
        }),
  })

/*
  Returns summary of allocation guide amounts to date
*/
export const fetchAllocationGuidesSummary = () =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error fetching the allocation summary.',
    fetchFunction: (dispatch) =>
      axios
        .get<
          AllocationGuideSummary[]
        >('/finances/api/v1/allocation_guides/summary')
        .then((json) => {
          dispatch(receiveAllocationGuidesSummaries(json.data))
          return json.data
        }),
  })

/*
  Edit Allocation guide

  This uses a custom endpoint that updates a single allocation guide
*/
export const editAllocationGuide = (id: number) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error editing this allocation guides.',
    fetchFunction: (dispatch) =>
      axios
        .put<AllocationGuide>(`/finances/api/v1/allocation_guides/${id}`)
        .then((json) => {
          dispatch(receiveSingleAllocationGuide(json.data))
          return json.data
        }),
  })

/*
  Creates an allocation guide for the specified month
*/
export const POST_CREATE_ALLOCATION_GUIDE_KEY =
  'POST_CREATE_ALLOCATION_GUIDE_KEY'

export const postCreateAllocationGuide = (user: User, date: string) =>
  fetchWrapper({
    fetchKey: POST_CREATE_ALLOCATION_GUIDE_KEY,
    fetchFunction: (dispatch) =>
      axios
        .post<AllocationGuide>('/finances/api/v1/allocation_guides', {
          user,
          date,
        })
        .then((json) => {
          dispatch(receiveSingleAllocationGuide(json.data))
          return json.data
        }),
  })

/*
  ADMIN ACTIONS
*/

const getFetchAdminUserAllocationGuideFetchKey = (userId: number | string) =>
  `FETCH_ADMIN_USER_ALLOCATION_GUIDE_KEY_${userId}`
export const fetchAdminUserAllocationGuides = (userId: number | string) =>
  fetchIfNeededWrapper({
    fetchKey: getFetchAdminUserAllocationGuideFetchKey(userId),
    defaultErrorMessage: 'There was an error fetching all allocation guides.',
    fetchFunction: (dispatch) => {
      return axios
        .get<{
          userId: number
          allocationGuides: AllocationGuide[]
          allocationRules: AllocationRule[]
        }>(`/finances/api/v1/admin/allocation_guides/${userId}`)
        .then((json) => {
          dispatch(
            receiveAllAllocationGuides(keyBy(json.data.allocationGuides, 'id'))
          )
          dispatch(
            receiveAllAllocationRules(keyBy(json.data.allocationRules, 'id'))
          )
          return json.data
        })
    },
  })

export const FETCH_ADMIN_ALL_ALLOCATION_GUIDES_KEY =
  'FETCH_ADMIN_ALL_ALLOCATION_GUIDES_KEY'
export const fetchAdminAllAllocationGuides = () =>
  fetchWrapper({
    fetchKey: FETCH_ADMIN_ALL_ALLOCATION_GUIDES_KEY,
    defaultErrorMessage: 'There was an error fetching all allocation guides.',
    fetchFunction: (dispatch) =>
      axios
        .get<AllocationGuide[]>('/finances/api/v1/admin/allocation_guides')
        .then((json) => {
          dispatch(receiveAllAllocationGuides(keyBy(json.data, 'id')))
          return json.data
        }),
  })

export const notifyUserAllocationGuide = (guideId: number) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error fetching all allocation guides.',
    defaultValue: false,
    fetchFunction: (dispatch) =>
      axios
        .post<AllocationGuide>(
          `/finances/api/v1/admin/allocation_guides/${guideId}/notify`,
          {}
        )
        .then((json) => {
          dispatch(receiveSingleAllocationGuide(json.data))
          return json.data
        }),
  })
