import { useState } from 'react'

// BL
import {
  useFeatureDispatch,
  closeDeleteConfirm,
  useFeatureState,
} from '../../state'
import { actionButton as copy } from '../../copyConstants'
import { destroySingleUserDocument } from '../../userDocuments.slice'

// UI
import { Button, Modal } from '../../../../components/BaseComponents'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const DeleteDocumentConfirm = () => {
  const [loading, setLoading] = useState(false)
  const featureDispatch = useFeatureDispatch()
  const dispatch = useAppDispatch()
  const { documentToDelete: document } = useFeatureState()

  const closeConfirm = () => {
    featureDispatch?.(closeDeleteConfirm())
  }

  const onCancel = () => {
    if (loading) {
      return
    }
    closeConfirm()
  }

  const resetState = () => {
    setLoading(false)
  }

  const onConfirm = async () => {
    if (loading) {
      return
    }

    if (!document) {
      return
    }

    setLoading(true)

    await destroySingleUserDocument(document.id)(dispatch)

    closeConfirm()
    resetState()
  }

  return (
    document && (
      <Modal size="small" dimmer="inverted" open onClose={onCancel}>
        <Modal.Content>{copy.dynamic.confirmContent(document)}</Modal.Content>
        <Modal.Actions>
          <Button variant="secondary" onClick={onConfirm}>
            {loading
              ? copy.static.confirmButtonLoadingText
              : copy.static.confirmButtonNotLoadingText}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  )
}

export default DeleteDocumentConfirm
