import React, { CSSProperties, useEffect } from 'react'
import moment from 'moment'
import { List } from 'semantic-ui-react'
import { fetchAllQuarterlyTaxEstimateDetailsIfNeeded } from '../../QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { selectQuarterlyTaxEstimateDetailsByYearAndQuarter } from '../../QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { useReselector } from '../../../../utils/sharedHooks'

interface Props {
  calendarQuarter: string
  calendarYear: string
  style?: CSSProperties
}

const QTEDateDetails: React.FC<Props> = ({
  calendarQuarter,
  calendarYear,
  style,
}) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchAllQuarterlyTaxEstimateDetailsIfNeeded())
  }, [dispatch])

  const details = useReselector(
    selectQuarterlyTaxEstimateDetailsByYearAndQuarter,
    calendarYear,
    calendarQuarter
  )

  if (!details) {
    return (
      <div>
        <i>
          Date details do not currently exist for {calendarYear}{' '}
          {calendarQuarter}
        </i>
      </div>
    )
  }
  return (
    <List relaxed="very" divided verticalAlign="middle" style={style}>
      <List.Item>
        <List.Content floated="right">
          {`${moment
            .utc(details?.bookkeepingPeriodStartsAt)
            .format(DATE_FORMATS.DISPLAY_SHORT)} - ${moment
            .utc(details?.bookkeepingPeriodEndsAt)
            .format(DATE_FORMATS.DISPLAY_SHORT)}`}
        </List.Content>
        <List.Header>Bookkeeping Period</List.Header>
      </List.Item>
      <List.Item>
        <List.Content floated="right">
          {`${moment
            .utc(details?.newUserCutOffAt)
            .format(DATE_FORMATS.DISPLAY_SHORT)}`}
        </List.Content>
        <List.Header>New User Cut-Off</List.Header>
      </List.Item>
      <List.Item>
        <List.Content floated="right">
          {`${moment
            .utc(details?.newUserCutOffAt)
            .format(DATE_FORMATS.DISPLAY_SHORT)} - ${moment
            .utc(details?.calculationStartsAt)
            .subtract('1', 'd')
            .format(DATE_FORMATS.DISPLAY_SHORT)}`}
        </List.Content>
        <List.Header>Profile Review Period</List.Header>
      </List.Item>
      <List.Item>
        <List.Content floated="right">
          {`${moment
            .utc(details?.calculationStartsAt)
            .format(DATE_FORMATS.DISPLAY_SHORT)} - ${moment
            .utc(details?.clientNotifiedAt)
            .subtract('1', 'd')
            .format(DATE_FORMATS.DISPLAY_SHORT)}`}
        </List.Content>
        <List.Header>Calculate, Review, Upload Period</List.Header>
      </List.Item>
      <List.Item>
        <List.Content floated="right">
          {`${moment
            .utc(details?.clientNotifiedAt)
            .format(DATE_FORMATS.DISPLAY_SHORT)}`}
        </List.Content>
        <List.Header>Notify Customers</List.Header>
      </List.Item>
      <List.Item>
        <List.Content floated="right">
          {`${moment
            .utc(details?.irsPaymentDueAt)
            .format(DATE_FORMATS.DISPLAY_SHORT)}`}
        </List.Content>
        <List.Header>IRS Payment</List.Header>
      </List.Item>
      <List.Item>
        <List.Content floated="right">
          {`${moment
            .utc(details?.irsPaymentDueAt)
            .add(1, 'd')
            .format(DATE_FORMATS.DISPLAY_SHORT)}`}
        </List.Content>
        <List.Header>Tax Information Unlocked</List.Header>
      </List.Item>
    </List>
  )
}

export default QTEDateDetails
