import { GridRowColumn, Link } from '../BaseComponents'

const ImageOffset = ({
  imageLink,
  linkText,
  backgroundSize = '100%',
  ...rest
}: {
  imageLink: string
  linkText: string
  // ie '-373px -157px' where x offset is first then y offset is second.  If none is sent the image with stick to the top left
  backgroundPosition?: string
  // ie '200%' how zoomed in the image is.  Defaults to 100% which is not zoomed in
  backgroundSize?: string
  height: number
  width: number
}) => (
  <>
    <GridRowColumn centerContent>
      <div
        style={{
          backgroundImage: `url(${imageLink})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize,
          ...rest,
        }}
      />
    </GridRowColumn>
    <GridRowColumn centerContent short>
      <Link newPage href={imageLink} as="secondaryLink">
        {linkText}
      </Link>
    </GridRowColumn>
  </>
)

export default ImageOffset
