import { CSSProperties, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useAnalyticsTrack, Attributes } from '../../features/Amplitude'

import { Colors, Fonts } from '../../styles/theme'
import Button from './Button'

interface Props {
  as?: 'link' | 'secondaryLink'
  children?: ReactNode
  className?: string
  color?: keyof typeof Colors
  fullWidth?: boolean
  hoverColor?: keyof typeof Colors
  href?: string
  newPage?: boolean
  style?: CSSProperties
  to?: string
  trackMessage?: string
  trackAttributes?: Attributes
  disabled?: boolean
  // todo these sizes correspond to the incorrect font sizes to help make matching easier
  size?: 'large' | 'medium' | 'small' | 'xSmall'
}

const StyledAnchor = styled.a<
  Pick<Props, 'fullWidth' | 'color' | 'hoverColor' | 'disabled'> & {
    fontStyle?: keyof typeof Fonts
  }
>(({ fontStyle = 'link', fullWidth, color, hoverColor, disabled }) => ({
  ...Fonts[fontStyle],
  fontWeight: 500,
  width: fullWidth ? '100%' : 'initial',
  ...(color && { color: Colors[color] }),
  '&:hover': {
    color: hoverColor && Colors[hoverColor],
  },
  ...(disabled && { pointerEvents: 'none' }),
}))

const linkFontSize = {
  large: 18,
  medium: 16,
  small: 14,
  xSmall: 12,
}

// Link will either have a `to` which will be an internal navigation or `href` for external navigation
const Link = ({
  as = 'link',
  children,
  fullWidth,
  href,
  newPage,
  to,
  trackMessage,
  trackAttributes,
  style,
  size,
  ...rest
}: Props) => {
  const navigate = useNavigate()
  const track = useAnalyticsTrack()

  const handleTrack = () => {
    if (trackMessage) track(trackMessage, trackAttributes)
  }

  const handleOnClick = (to: string) => {
    handleTrack()

    navigate(to)
  }

  if (to) {
    return (
      <Button
        fullWidth={fullWidth}
        inline
        onClick={() => handleOnClick(to)}
        style={{
          ...Fonts[as],
          fontWeight: 500,
          ...(size && { fontSize: linkFontSize[size] }),
          ...style,
        }}
        variant="link"
        {...rest}
      >
        {children}
      </Button>
    )
  } else if (href) {
    const extraProps = newPage
      ? { target: '_blank', rel: 'noopener noreferrer' }
      : {}

    return (
      <StyledAnchor
        fontStyle={as}
        href={href}
        style={{
          ...(size && { fontSize: linkFontSize[size] }),
          ...style,
        }}
        onClick={handleTrack}
        {...extraProps}
        {...rest}
      >
        {children}
      </StyledAnchor>
    )
  }

  return null
}

export default Link
