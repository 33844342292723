import { useEffect } from 'react'
import { Container, Divider, Grid } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'

import PayrollSetupList from './PayrollSetupList'
import { GEP_ENROLL_PATHS } from '../helpers'
import { fetchPayrollProfileIfNeeded } from '../payrollActions'
import {
  Button,
  Card,
  GridRowColumn,
  Text,
} from '../../../components/BaseComponents'
import { useAppDispatch } from '../../../utils/typeHelpers'

const PayrollEnrollComplete = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchPayrollProfileIfNeeded(true))
  }, [dispatch])

  return (
    <Container>
      <Card>
        <Grid>
          <GridRowColumn>
            <Text as="h1">Setting Up Payroll</Text>
          </GridRowColumn>
          <Divider />
          <Grid.Row>
            <Grid.Column width={9}>
              <Grid>
                <GridRowColumn>
                  <Text as="h2">You&apos;ve completed your Payroll Setup!</Text>
                </GridRowColumn>
                <Divider />
                <GridRowColumn>
                  <Text>
                    We are sharing your company details with your state for
                    approval, and you’ll be notified via email once you have
                    been approved to run payroll. This usually takes 1-2
                    business days. You can also track of your approval status on
                    the Heard dashboard.
                  </Text>
                </GridRowColumn>
                <GridRowColumn>
                  <Text>We’ll be in touch shortly!</Text>
                </GridRowColumn>
                <GridRowColumn>
                  <Text>The Heard Team</Text>
                </GridRowColumn>
                <Grid.Row />
                <GridRowColumn width={7}>
                  <Button
                    onClick={() => navigate(GEP_ENROLL_PATHS.intro)}
                    fullWidth
                  >
                    Back to payroll home
                  </Button>
                </GridRowColumn>
              </Grid>
            </Grid.Column>
            <Grid.Column width={7}>
              <PayrollSetupList />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollEnrollComplete
