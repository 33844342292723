import { useEffect } from 'react'
import { Message, Table } from 'semantic-ui-react'

import { fetchAllFinancialAccountReconciliationsForUser } from '../../../../actions/admin/accountReconciliationActions'
import { getOrderedReconciliationsByUserId } from '../../../../selectors/reconciliations.selectors'
import FinancialAccountReconciliationsTableRow from './FinancialAccountReconciliationsTableRow'
import { getUserFinancialAccounts } from '../../../../selectors/financeSelectors'
import { useReselector } from '../../../../utils/sharedHooks'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const FinancialAccountReconciliationsTable = ({
  userId,
}: {
  userId: number
}) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (userId) {
      dispatch(fetchAllFinancialAccountReconciliationsForUser(userId))
    }
  }, [dispatch, userId])

  const financialAccountsById = useReselector(getUserFinancialAccounts, userId)
  const financialAccountReconciliations = useReselector(
    getOrderedReconciliationsByUserId,
    userId
  )

  return financialAccountReconciliations.length > 0 ? (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Id</Table.HeaderCell>
          <Table.HeaderCell>Start Date</Table.HeaderCell>
          <Table.HeaderCell>Start Balance</Table.HeaderCell>
          <Table.HeaderCell>End Date</Table.HeaderCell>
          <Table.HeaderCell>End Balance</Table.HeaderCell>
          <Table.HeaderCell>Account</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {financialAccountReconciliations.map((r) => (
          <FinancialAccountReconciliationsTableRow
            key={r.id}
            financialAccount={
              financialAccountsById
                ? financialAccountsById[r.accountId]
                : undefined
            }
            financialAccountReconciliation={r}
          />
        ))}
      </Table.Body>
    </Table>
  ) : (
    <Message warning content="User has no reconciliations yet" />
  )
}

export default FinancialAccountReconciliationsTable
