import React from 'react'
import { Card, Header } from 'semantic-ui-react'
import FinancialAccountReconciliationsTable from '../Reconciliation/Components/FinancialAccountReconciliationsTable'

interface Props {
  userId: number
}

const FinancialAccountReconciliationsCard: React.FC<Props> = ({ userId }) => (
  <Card fluid>
    <div className="flexbox">
      <Card.Header>
        <Header as="h4">Reconciliations</Header>
      </Card.Header>
    </div>
    <Card.Content>
      <FinancialAccountReconciliationsTable userId={userId} />
    </Card.Content>
  </Card>
)

export default FinancialAccountReconciliationsCard
