import { useNavigate } from 'react-router-dom'
import { Divider, Grid } from 'semantic-ui-react'

import { Button, GridRowColumn } from '../../components/BaseComponents'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'

const PracticeProfileFormCta = ({
  submitForm,
  isValid,
  isSubmitting,
}: {
  submitForm: () => void
  isValid: boolean
  isSubmitting: boolean
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const navigate = useNavigate()

  return (
    <>
      <Divider />

      {isMobile ? (
        <>
          <GridRowColumn>
            <Button onClick={submitForm} fullWidth>
              Save Changes
            </Button>
          </GridRowColumn>
          <GridRowColumn short>
            <Button
              fullWidth
              onClick={() => navigate('/practice/profile')}
              variant="secondary"
            >
              Cancel
            </Button>
          </GridRowColumn>
        </>
      ) : (
        <Grid.Row>
          <Grid.Column computer={2} tablet={4}>
            <Button
              fullWidth
              onClick={() => navigate('/practice/profile')}
              variant="secondary"
            >
              Cancel
            </Button>
          </Grid.Column>
          <Grid.Column computer={11} tablet={8} />
          <Grid.Column computer={3} tablet={4}>
            <Button
              onClick={submitForm}
              fullWidth
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
            >
              Save Changes
            </Button>
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  )
}

export default PracticeProfileFormCta
