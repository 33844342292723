import { Container, Grid, Image } from 'semantic-ui-react'

import SignupHeader from '../SignupHeader'
import {
  Button,
  GridRowColumn,
  Link,
  Text,
} from '../../../components/BaseComponents'

const BookConsult = () => (
  <Container style={{ paddingTop: 64 }}>
    <Grid>
      <SignupHeader hideProgress />
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/messages.svg"
          style={{ height: 180, width: 180 }}
        />
      </GridRowColumn>
      <GridRowColumn>
        <Text as="display" textAlign="center">
          Let’s chat
        </Text>
      </GridRowColumn>
      <GridRowColumn spacer={2} width={12}>
        <Text textAlign="center">
          Based on your answers, we need more information from you to determine
          if we can serve you. Book a free 30 minute consult with our team to
          learn if Heard is right for your practice.
        </Text>
      </GridRowColumn>
      <Grid.Row />
      <GridRowColumn spacer={6} width={4}>
        <Link href="https://www.joinheard.com/welcome-form">
          <Button fullWidth>Book a Free Consult</Button>
        </Link>
      </GridRowColumn>
      <GridRowColumn spacer={6} width={4}>
        <Link href="https://www.joinheard.com">
          <Button variant="secondary" fullWidth>
            Return to Site
          </Button>
        </Link>
      </GridRowColumn>
    </Grid>
  </Container>
)

export default BookConsult
