export const TRANSACTIONS_TABLE_DESCRIPTION = {
  all: 'Below are all transactions from your business bank accounts and/or credit cards that are connected to Heard.',
  review:
    "Your bookkeeper needs your input on how these transactions should be categorized. Please categorize them or if you're not sure, leave a note describing its purpose.",
  business:
    "These transactions are business-related income and expense. They're included in your financial reporting and profit and loss.",
  personal:
    "These transactions are personal or not related to your business income and expenses. Don't worry— they're not included in your financial reporting and profit and loss.",
}

export const getLockedTransactionDescription = (year = '') => ({
  lockedBannerMessage:
    'Please note that you cannot upload transactions that are from the previous tax year. If you need to access or make changes to past books, please reach out to our team.',
  adminLockedBannerMessage: `This user's books are currently locked to admins for ${year}.`,
  adminUserLockedBannerMessage: `This user's books are currently locked to user edits for ${year}.`,
  supplementalLockDescription:
    'Please note that some transactions have been locked and cannot be edited. They will have a lock icon beside them.',
  transactionRowLockIconTooltip:
    'Your books have been locked for the year. Please contact us to make any changes.',
  adminTransactionRowLockIconTooltip:
    "This user's books have been locked to admins for this year!",
})

export const UPLOAD_COPY = {
  GENERIC_SCREEN_TITLE: 'Add transactions',
  LANDING_SCREEN_SUBTITLE:
    'Do you have business transactions from this year that are not reflected in your business bank accounts or credit cards? Add them into Heard by filling out the form below. You can also bulk upload multiple transactions',
  LANDING_SCREEN_BULK_SUBTITLE: 'Need to add more than one transaction?',
  REVIEW_SCREEN_TITLE:
    'Review your transactions ({transaction_count} uploaded)',
  REVIEW_SCREEN_SUBTITLE:
    'Please confirm the below transactions have been recorded correctly. If you notice any errors, hover over the transaction to make changes as needed.',
  DUPLICATE_SPREADSHEET_SUBTITLE:
    'These transactions look familiar! Please confirm they are not duplicates.',
  DUPLICATE_DATABASE_SUBTITLE:
    'This transaction already exists on Heard. Please confirm it is not a duplicate.',
  SINGLE_TRANSACTION_SUCCESS_MESSAGE:
    'Your transaction has been added successfully',
  SINGLE_TRANSACTION_ERROR_MESSAGE:
    'The transaction could not be added. Please try again.',
  MULTIPLE_TRANSACTIONS_SUCCESS_MESSAGE:
    'Your transactions have been added successfully',
  SIGN_EXPLAINER:
    'Please ensure expenses are notated as negative dollar amounts (with a minus sign) and income as positive dollar amounts.',
}
