import { useMemo } from 'react'
import { Alert, GridRowColumn } from '../../../../components/BaseComponents'
import { selectErrorsForKeys } from '../../../../reducers/fetch'
import { useReselector } from '../../../../utils/sharedHooks'

const Ten99FilingErrors = ({ fetchKeys }: { fetchKeys: string[] }) => {
  const errors = useReselector(selectErrorsForKeys, fetchKeys)
  return useMemo(
    () => (
      <>
        {errors.map((error) => (
          <GridRowColumn key={error.message}>
            <Alert type="error" title={error.message} />
            <br />
          </GridRowColumn>
        ))}
      </>
    ),
    [errors]
  )
}

export default Ten99FilingErrors
