import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { CSVDownload } from 'react-csv'
import { useParams } from 'react-router-dom'
import { Container, Divider, Grid, Loader, Dimmer } from 'semantic-ui-react'
import { adminFetchSingleUserIfNeeded } from '../../../actions/admin/adminAllUsersActions'
import { fetchUserFinancialAccounts } from '../../../actions/admin/adminFinancialAccountActions'
import {
  Button,
  Card,
  DatePicker,
  Pagination,
  Text,
  GridRowColumn,
  Dropdown,
  Input,
} from '../../../components/BaseComponents'
import Breadcrumb, {
  BreadcrumbSection,
} from '../../../components/BaseComponents/Breadcrumb'
import { getUserFinancialAccounts } from '../../../selectors/financeSelectors'
import { getUserById } from '../../../selectors/user.selectors'
import { useReselector, useToggle } from '../../../utils/sharedHooks'
import {
  parseJournalEntryDataToCsv,
  getInitialDateFilters,
} from '../../JournalEntries/service'
import {
  fetchJournalEntries,
  fetchEntriesWithoutTransactions,
  FETCH_JOURNAL_ENTRY_TRANSACTIONS_KEY,
  FETCH_ENTRIES_WITHOUT_TRANSACTIONS_KEY,
  CREATE_JOURNAL_ENTRY_KEY,
} from '../../../actions/admin/journalEntryActions'
import { getAllTransactionCategories } from '../reports.selectors'
import { fetchTransactionCategoriesIfNeeded } from '../reports.slice'
import { JournalEntryTransactions } from '../../JournalEntries/components/JETransactions'
import { EditJournalEntryModal } from '../../JournalEntries/components/EditJournalEntryModal'
import {
  selectJournalEntriesByIds,
  selectJournalEntryTransactionsByIds,
} from '../../../selectors/journalEntrySelectors'
import { debounce } from 'lodash'
import { filterNulls, useAppDispatch } from '../../../utils/typeHelpers'
import { selectIsFetchingForKeys } from '../../../reducers/fetch'

const PAGE_SIZE = 10

const AUTOMATED_FILTER_OPTIONS = [
  { value: 'manual', text: 'Manual' },
  { value: 'automated', text: 'Automated' },
]

const AdminGeneralJournalPanel = () => {
  const [{ count, transactionIds }, setJournalEntryData] = useState<{
    count: number
    transactionIds: number[]
  }>({ count: 0, transactionIds: [] })
  const [entryIdsWithoutTransaction, setEntryIdsWithoutTransaction] = useState<
    number[]
  >([])
  const [dateRange, setDateRange] = useState<[string, string]>(
    getInitialDateFilters()
  )
  const [currentPage, setCurrentPage] = useState(1)
  const [csvData, setCsvData] = useState('')
  const [automatedFilter, setAutomatedFilter] = useState<string>()
  const [transactionIdFilter, setTransactionIdFilter] = useState<string>('')
  const [isCreating, toggleCreate] = useToggle()

  const { userId: userIdParam } = useParams<{ userId: string }>()
  const userId = Number(userIdParam)

  const user = useReselector(getUserById, userId)

  const dispatch = useAppDispatch()

  const financialAccounts = useReselector(getUserFinancialAccounts, userId)
  const transactionCategories = useReselector(getAllTransactionCategories)
  const journalEntryTransactions = useReselector(
    selectJournalEntryTransactionsByIds,
    transactionIds
  )
  const entriesWithoutTransactions = useReselector(
    selectJournalEntriesByIds,
    entryIdsWithoutTransaction
  )

  const loading = useReselector(selectIsFetchingForKeys, [
    FETCH_JOURNAL_ENTRY_TRANSACTIONS_KEY,
    FETCH_ENTRIES_WITHOUT_TRANSACTIONS_KEY,
  ])

  const createLoading = useReselector(selectIsFetchingForKeys, [
    CREATE_JOURNAL_ENTRY_KEY,
  ])

  const pages = Math.ceil(count / PAGE_SIZE)
  const usersLink = '/admin/finances/list-users'

  const breadcrumbs = useMemo<BreadcrumbSection[]>(
    () => [
      { label: 'Dashboard', to: '/' },
      { label: 'Users', to: usersLink },
      {
        label: `${user?.firstName} ${user?.lastName}`,
        to: `/admin/finances/records/${userId}`,
      },
      { label: 'General Journal' },
    ],
    [user?.firstName, user?.lastName, userId, usersLink]
  )

  useEffect(() => {
    const fetch = async () => {
      const res = await fetchJournalEntries({
        userId,
        startDate: dateRange[0],
        endDate: dateRange[1],
        limit: PAGE_SIZE,
        page: currentPage,
        journalEntryType: automatedFilter ?? undefined,
        transactionId: transactionIdFilter
          ? parseInt(transactionIdFilter)
          : undefined,
      })(dispatch)
      const numPages = Math.ceil(res.count / PAGE_SIZE)
      // call endpoint to get journal entries without transactions if the user is on the last page
      if (
        !transactionIdFilter &&
        automatedFilter !== 'automated' &&
        currentPage === (numPages || 1)
      ) {
        const manualRes = await fetchEntriesWithoutTransactions({
          userId,
          startDate: dateRange[0],
          endDate: dateRange[1],
        })(dispatch)
        setEntryIdsWithoutTransaction(
          manualRes.map((journalEntry) => journalEntry.id)
        )
      } else {
        setEntryIdsWithoutTransaction([])
      }

      setJournalEntryData({
        count: res.count,
        transactionIds: filterNulls(
          res.transactions.map((transaction) => transaction.id)
        ),
      })
    }

    if (
      (dateRange[0] && dateRange[1] && createLoading === false) ||
      transactionIdFilter
    ) {
      fetch()
    } else {
      setJournalEntryData({ count: 0, transactionIds: [] })
      setEntryIdsWithoutTransaction([])
    }
  }, [
    dispatch,
    currentPage,
    dateRange,
    userId,
    transactionIdFilter,
    automatedFilter,
    createLoading,
  ])

  useEffect(() => {
    dispatch(adminFetchSingleUserIfNeeded(userId))
    dispatch(fetchUserFinancialAccounts(userId))
    dispatch(fetchTransactionCategoriesIfNeeded(true))
  }, [dispatch, userId])

  const debouncedSetTransactionId = useMemo(
    () =>
      debounce((value) => {
        setCurrentPage(1)
        setTransactionIdFilter(value)
      }, 1000),
    []
  )

  /**
   * This useEffect clears the csvData state variable in order to "unmount" the CSVDownload
   * component below. When the CSVDownload component is rendered in the DOM, it immediately
   * triggers a download of a CSV file with the given data. However, if the user wants to download
   * the CSV a second time, a download would not trigger if the CSVDownload component stayed
   * rendered, so setting the data to an empty string will unmount the CSVDownload component until
   * the next time the user presses "Download CSV", which will then populate the csvData state
   * variable, mount the CSVDownload component again, and start the download of the data again.
   */
  useEffect(() => {
    if (csvData) {
      setCsvData('')
    }
  }, [csvData])

  const handleDateChange = (dateRange: [string, string]) => {
    setCurrentPage(1)
    setDateRange(dateRange)
  }

  const handleAutomatedFilterChange = (value: string | undefined) => {
    setCurrentPage(1)
    setAutomatedFilter(value)
  }

  const handleDownloadCsv = async () => {
    const { transactions } =
      (await fetchJournalEntries({
        userId,
        startDate: dateRange[0],
        endDate: dateRange[1],
      })(dispatch)) ?? []

    if (transactions.length) {
      setCsvData(
        parseJournalEntryDataToCsv({
          journalEntryTransactions: transactions,
          financialAccounts,
          transactionCategories,
          sortFunction: (
            { transaction: { date: date1 } },
            { transaction: { date: date2 } }
          ) => moment(date1).diff(date2),
        })
      )
    }
  }

  return (
    <Container>
      <Breadcrumb crumbs={breadcrumbs} />
      <Divider />
      <Card>
        <Grid>
          <Grid.Row stretched>
            <Grid.Column width={8} floated="left">
              <Text as="h1">General Journal</Text>
              <Text>
                {user?.financialProfile?.businessName ??
                  `${user?.firstName} ${user?.lastName}`}
              </Text>
            </Grid.Column>
            <Grid.Column width={4}>
              <Button variant="secondaryWhite" onClick={toggleCreate}>
                Create Journal Entry
              </Button>
              {isCreating && (
                <EditJournalEntryModal
                  open={isCreating}
                  onClose={toggleCreate}
                />
              )}
            </Grid.Column>
            <Grid.Column width={4} floated="right">
              <Button
                disabled={!transactionIds.length}
                onClick={handleDownloadCsv}
              >
                Download CSV
              </Button>
              {csvData ? <CSVDownload data={csvData} target="_blank" /> : null}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row verticalAlign="middle">
            <Grid.Column computer={6} tablet={7} mobile={8}>
              <DatePicker
                clearable
                onChange={handleDateChange}
                style={{ width: 270 }}
                endDate={dateRange[1]}
                selectsRange
                startDate={dateRange[0]}
                value={`${dateRange[0]} - ${dateRange[1]}`}
              />
            </Grid.Column>
            <Grid.Column computer={5} tablet={4} mobile={8}>
              <Dropdown
                placeholder="Filter by manual/auto"
                value={automatedFilter}
                onChange={handleAutomatedFilterChange}
                options={AUTOMATED_FILTER_OPTIONS}
                clearable
                fullWidth
              />
            </Grid.Column>
            <Grid.Column computer={5} tablet={3} mobile={8}>
              <Input
                placeholder="Filter by transaction ID"
                onChange={debouncedSetTransactionId}
                fullWidth
                type="number"
              />
            </Grid.Column>
          </Grid.Row>
          <GridRowColumn />
        </Grid>
        <Divider />
        {loading ? (
          <Dimmer active inverted>
            <Loader inverted>Loading journal entries</Loader>
          </Dimmer>
        ) : (
          <JournalEntryTransactions
            userId={userId}
            transactions={journalEntryTransactions}
            entriesWithoutTransactions={entriesWithoutTransactions}
            dateRange={dateRange}
          />
        )}
        {pages > 1 && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination
              currentPage={currentPage}
              onPageClick={(page) => setCurrentPage(page)}
              pages={pages}
            />
          </div>
        )}
      </Card>
    </Container>
  )
}

export default AdminGeneralJournalPanel
