import { createSelector } from 'reselect'

import { ReduxState } from '../../../utils/typeHelpers'
import {
  SCorpUserDocumentCategoriesIdents,
  UserDocumentCategory,
} from './userDocumentCategories.slice'
import { selectUserActionItemById } from '../../Dashboard/UserActionItems/userActionItems.selectors'
import { PostOnboardingTaskIdents } from '../../Onboarding/config'
import { UserDocumentCategoryIdentifier } from './userDocumentCategory.constants'

export const getUserDocumentCategoriesById = (state: ReduxState) =>
  state.admin.allUserDocumentCategories

export const selectUserDocumentCategoryList = createSelector(
  getUserDocumentCategoriesById,
  (docCategories) => Object.values(docCategories)
)

export const selectUserDocumentCategory = createSelector(
  getUserDocumentCategoriesById,
  (_: unknown, id: number | undefined | null) => id,
  (userDocumentCategories, id) => {
    return id && userDocumentCategories[id] ? userDocumentCategories[id] : null
  }
)

export const getUserDocCategoryByInternalNameFromCategoryList = (
  userDocumentCategories: UserDocumentCategory[],
  internalName: string | undefined
) =>
  userDocumentCategories.find(
    (category) => category.internalName === internalName
  )

export const selectUserDocumentCategoryByInternalName = createSelector(
  selectUserDocumentCategoryList,
  (_: unknown, internalName: string | undefined) => internalName,
  getUserDocCategoryByInternalNameFromCategoryList
)

export const selectDocumentCategoryByUserActionItemId = createSelector(
  selectUserActionItemById,
  selectUserDocumentCategoryList,
  (userActionItem, userDocumentCategories) => {
    if (!userActionItem) {
      return null
    }

    const {
      actionItem: { identifier },
      templateValue,
    } = userActionItem

    let internalName

    switch (identifier) {
      case PostOnboardingTaskIdents.UPLOAD_BUSINESS_INCORPORATION_DOCUMENTS:
        internalName = UserDocumentCategoryIdentifier.businessIncorporation
        break
      case PostOnboardingTaskIdents.UPLOAD_FORM_2553:
        internalName = UserDocumentCategoryIdentifier.form2553
        break
      case PostOnboardingTaskIdents.UPLOAD_CP261_NOTICE:
        internalName = UserDocumentCategoryIdentifier.cp261Notice
        break
      case PostOnboardingTaskIdents.UPLOAD_TAX_RETURN_1040:
        if (templateValue) {
          internalName = UserDocumentCategoryIdentifier.finalReturnForm1040
        }
        break

      case PostOnboardingTaskIdents.UPLOAD_TAX_RETURN_1120S:
        if (templateValue) {
          internalName = UserDocumentCategoryIdentifier.finalReturnForm1120s
        }
        break
      default:
        break
    }

    return getUserDocCategoryByInternalNameFromCategoryList(
      userDocumentCategories,
      internalName
    )
  }
)

export const selectUserDocumentCategoriesByInternalNames = createSelector(
  selectUserDocumentCategoryList,
  (_: unknown, internalNames: string[]) => internalNames,
  (userDocumentCategories, internalNames) =>
    userDocumentCategories.filter((category) =>
      internalNames.includes(category.internalName)
    )
)

export const selectScorpUserDocumentCategoryIds = createSelector(
  getUserDocumentCategoriesById,
  (userDocumentCategories) =>
    Object.keys(userDocumentCategories).filter((key) =>
      SCorpUserDocumentCategoriesIdents.some((pattern) =>
        new RegExp(pattern).test(userDocumentCategories[key].internalName)
      )
    )
)
