import { Grid, Image } from 'semantic-ui-react'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { Card, Text } from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import { useEffect } from 'react'
import { useAnalyticsView } from '../../Amplitude'
import { FEATURE_FLAG_KEYS } from '../../OpenFeature'

export const PlaidIntroPage = () => {
  const pageView = useAnalyticsView()
  const enableLowerFrictionStatements = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableLowerFrictionStatements,
    false
  )

  useEffect(() => {
    pageView('statements splash')
  }, [pageView])
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={5}>
          <Image
            src={'https://heard-images.s3.amazonaws.com/assets/green-check.png'}
            style={{
              padding: 80,
              backgroundColor: '#E4F2E6',
            }}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Text as="h2">We have your bank statements</Text>
          <Text>
            Because you gave us access to your statements via Plaid,
            there&apos;s no need for you to upload them now or in the future.
          </Text>
          <Card style={{ backgroundColor: Colors.stone, padding: 14 }}>
            <Text>
              <b>Why does Heard need these?</b>
            </Text>
            {enableLowerFrictionStatements ? (
              <Text>
                We need your statements to reconcile your books, a standard
                procedure to ensure completeness and accuracy. In the future,
                you will only need to upload statements if there&apos;s a Plaid
                connection error.
              </Text>
            ) : (
              <Text>
                We need statements to perform a monthly check to close our your
                books. This is a standard accounting practice to ensure
                completeness and accuracy.
              </Text>
            )}
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
