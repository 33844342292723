import { createSelector } from 'reselect'
import { ReduxState } from '../../../../../../utils/typeHelpers'
import { TAX_ENTITY_TYPES_TYPE } from '../../../../taxConstants'
import { SubStepIdentifiers } from './stepProgress.helpers'
import { keyBy } from 'lodash'

export const getAllEoyReviewSteps = (state: ReduxState) =>
  state.allEoyReviewSteps

export const selectAllEoyReviewSteps = createSelector(
  getAllEoyReviewSteps,
  (allStepsById) => Object.values(allStepsById)
)

export const getAllEoyReviewStepsForTaxEntityType = createSelector(
  [
    getAllEoyReviewSteps,
    (_: unknown, taxEntityType?: TAX_ENTITY_TYPES_TYPE | null) => taxEntityType,
  ],
  (allEoyReviewSteps, taxEntityType) => {
    if (!allEoyReviewSteps) {
      return null
    }

    if (!taxEntityType) {
      return Object.values(allEoyReviewSteps)
    }

    return Object.values(allEoyReviewSteps).filter((step) =>
      step.requiredForTaxEntityTypes?.includes(taxEntityType)
    )
  }
)

export const selectEoyReviewStepForIdentifier = createSelector(
  [
    getAllEoyReviewSteps,
    (_: unknown, identifier: SubStepIdentifiers) => identifier,
  ],
  (allEoyReviewSteps, identifier) => {
    return allEoyReviewSteps[identifier] ? allEoyReviewSteps[identifier] : null
  }
)

export const selectEoyReviewStepsInList = createSelector(
  getAllEoyReviewSteps,
  (_: unknown, identifiers: SubStepIdentifiers[]) => identifiers,
  (allEoyReviewSteps, identifiers) =>
    identifiers.map((identifier) => allEoyReviewSteps[identifier])
)

export const selectEoyReviewStepById = createSelector(
  getAllEoyReviewSteps,
  (stepsByIdentifier) => keyBy(stepsByIdentifier, 'id')
)
