import axios from 'axios'
import { fetchWrapper } from '../../../reducers/fetch'
import {
  ChartAPIResponse,
  INSIGHTS_TYPE,
  InsightsRowAPIResponse,
  InsightsTransactionsAPIResponse,
  SummaryAPIResponse,
  UncategorizedTransactionResponse,
} from './utils'

export const FETCH_INSIGHTS_BARCHART_DATA_KEY =
  'FETCH_INSIGHTS_BARCHART_DATA_KEY'
export const fetchInsightsBarchartData = (
  type: INSIGHTS_TYPE,
  startDate: string,
  endDate: string
) =>
  fetchWrapper({
    fetchKey: FETCH_INSIGHTS_BARCHART_DATA_KEY,
    defaultErrorMessage:
      'Unable to get financial insights data for the bar chart.',
    fetchFunction: async () => {
      const res = await axios.get<ChartAPIResponse[]>(
        '/finances/api/v1/financial_insights/barchart',
        { params: { type, startDate, endDate } }
      )
      return res.data
    },
  })

export const FETCH_INSIGHTS_SUMMARY_KEY = 'FETCH_INSIGHTS_SUMMARY_KEY'
export const fetchInsightsSummary = (
  type: INSIGHTS_TYPE,
  month: number,
  year: number
) =>
  fetchWrapper({
    fetchKey: FETCH_INSIGHTS_SUMMARY_KEY,
    defaultErrorMessage:
      'Unable to get financial insights data for the summary.',
    fetchFunction: async () => {
      const res = await axios.get<SummaryAPIResponse[]>(
        '/finances/api/v1/financial_insights/summary',
        { params: { type, month, year } }
      )
      return res.data
    },
  })

export const FETCH_INSIGHTS_TABLE_DROPDOWN_KEY =
  'FETCH_INSIGHTS_TABLE_DROPDOWN_KEY'
export const fetchInsightsTableDropdownOptions = (type: INSIGHTS_TYPE) =>
  fetchWrapper({
    fetchKey: FETCH_INSIGHTS_TABLE_DROPDOWN_KEY,
    defaultErrorMessage:
      'Unable to get financial insights data for the breakdown.',
    fetchFunction: async () => {
      const res = await axios.get<number[]>(
        '/finances/api/v1/financial_insights/uitable/year-dropdown-options',
        { params: { type } }
      )
      return res.data
    },
  })

export const FETCH_INSIGHTS_TABLE_DATA_KEY = 'FETCH_INSIGHTS_BREAKDOWN_KEY'
export const fetchInsightsTableData = (
  type: INSIGHTS_TYPE,
  startDate: string,
  endDate: string
) =>
  fetchWrapper({
    fetchKey: FETCH_INSIGHTS_TABLE_DATA_KEY,
    defaultErrorMessage:
      'Unable to get financial insights data for the breakdown.',
    fetchFunction: async () => {
      const res = await axios.get<InsightsRowAPIResponse[]>(
        '/finances/api/v1/financial_insights/uitable',
        { params: { type, startDate, endDate } }
      )
      return res.data
    },
  })

export const FETCH_INSIGHTS_CATEGORY_TRANSACTIONS_KEY =
  'FETCH_INSIGHTS_CATEGORY_TRANSACTIONS_KEY'
export const fetchInsightsCategoryTransactions = (
  transactionCategoryId: number,
  startDate: string,
  endDate: string
) =>
  fetchWrapper({
    fetchKey: FETCH_INSIGHTS_TABLE_DATA_KEY,
    defaultErrorMessage:
      'Unable to get financial insights data for the breakdown.',
    fetchFunction: async () => {
      const res = await axios.get<InsightsTransactionsAPIResponse[]>(
        `/finances/api/v1/financial_insights/transaction_categories/${transactionCategoryId}/transactions`,
        { params: { startDate, endDate } }
      )
      return res.data
    },
  })

export const FETCH_INSIGHTS_UNCATEGORIZED_TRANSACTIONS_KEY =
  'FETCH_INSIGHTS_UNCATEGORIZED_TRANSACTIONS_KEY'
export const fetchInsightsUncategorizedTransactionsData = (endDate: string) =>
  fetchWrapper({
    fetchKey: FETCH_INSIGHTS_TABLE_DATA_KEY,
    defaultErrorMessage:
      'Unable to get financial insights data for the uncategorized transactions',
    fetchFunction: async () => {
      const res = await axios.get<UncategorizedTransactionResponse>(
        '/finances/api/v1/financial_insights/uncategorized_transactions',
        { params: { endDate } }
      )
      return res.data
    },
  })
