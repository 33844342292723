import { useEffect, useState } from 'react'

import { fetchAllCompanyForms } from '../payrollActions'
import GustoFormsTable from './GustoFormsTable'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { Gusto_GustoForm } from '../generated_gusto_types'

const CompanyDocumentsTab = () => {
  const dispatch = useAppDispatch()
  const [forms, setForms] = useState<Gusto_GustoForm[]>([])

  useEffect(() => {
    const fetch = async () => {
      const res = await fetchAllCompanyForms()(dispatch)
      if (res) {
        setForms(res)
      }
    }

    fetch()
  }, [dispatch])

  return <GustoFormsTable forms={forms} />
}

export default CompanyDocumentsTab
