import { History } from '../features/Admin/Histories/histories.slice'
import { isEqual, omitBy, uniq, get } from 'lodash'

// Determine which History properties have changed by comparing old_version with new_version
// Returns [{ key:string, previousValue: string|JSON, currentValue: string|JSON }]
export const getHistoryDiffs = ({
  record,
  excludedProps,
}: {
  record: History
  excludedProps: string[]
}) => {
  const oldVersionDiffs = omitBy(record.oldVersion, (v, k) =>
    isEqual(get(record.newVersion, k), v)
  )
  const newVersionDiffs = omitBy(record.newVersion, (v, k) =>
    isEqual(get(record.oldVersion, k), v)
  )

  return uniq([
    ...Object.keys(oldVersionDiffs),
    ...Object.keys(newVersionDiffs),
  ])
    .filter((k) => !excludedProps.includes(k))
    .map((k) => ({
      key: k as string,
      previousValue: get(record.oldVersion, k),
      currentValue: get(record.newVersion, k),
    }))
}
