import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { uniq } from 'lodash'

import { Dispatch, GetState, ReduxState } from '../../utils/typeHelpers'
import { selectEnabledAdminQueriedTransactionIds } from '../../features/Transactions/transactions.selectors'

export interface SelectedTransactionsState {
  lastChecked: number | null
  selectedTransactions: number[]
}

const initialState: SelectedTransactionsState = {
  lastChecked: null,
  selectedTransactions: [],
}

const selectedTransactionsSlice = createSlice({
  name: 'selectedTransactions',
  initialState,
  reducers: {
    updateSelectedTransaction: (
      state,
      action: PayloadAction<{
        transactionId: number
        value: boolean
      }>
    ) => {
      const { value, transactionId } = action.payload

      if (value) {
        state.selectedTransactions = uniq([
          ...state.selectedTransactions,
          transactionId,
        ])
        state.lastChecked = transactionId
      } else {
        state.selectedTransactions = state.selectedTransactions.filter(
          (selectedId) => selectedId !== transactionId
        )

        // If the last checked was this transaction clear it
        if (state.lastChecked === transactionId) {
          state.lastChecked = null
        }
      }
    },
    setSelectedTransactions: (state, action: PayloadAction<number[]>) => {
      state.lastChecked = action.payload[action.payload.length - 1]
      state.selectedTransactions = action.payload
    },
    clearSelectedTransactions: () => initialState,
  },
})

export default selectedTransactionsSlice.reducer

export const {
  updateSelectedTransaction,
  clearSelectedTransactions,
  setSelectedTransactions,
} = selectedTransactionsSlice.actions

const getSelectedTransactionState = (state: ReduxState) =>
  state.admin.selectedTransactions

export const getSelectedTransactionIds = createSelector(
  getSelectedTransactionState,
  (selectedTransactionState) => selectedTransactionState.selectedTransactions
)

export const getSelectedByTransactionId = createSelector(
  getSelectedTransactionIds,
  (_: unknown, id: string | number | undefined) => id,
  (selectedTransactions, id) =>
    id ? selectedTransactions.includes(Number(id)) : false
)

export const shiftSelect =
  (endTransactionId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState()

    const enabledTransactionIds = selectEnabledAdminQueriedTransactionIds(state)

    const startTransactionId = getSelectedTransactionState(state).lastChecked
    const start = startTransactionId
      ? enabledTransactionIds.indexOf(startTransactionId)
      : -1
    const end = enabledTransactionIds.indexOf(endTransactionId)

    if (start === -1 || end === -1) {
      return
    }

    if (end > start) {
      dispatch(
        setSelectedTransactions(enabledTransactionIds.slice(start, end + 1))
      )
    } else if (start > end) {
      dispatch(
        setSelectedTransactions(enabledTransactionIds.slice(end, start + 1))
      )
    }
  }
