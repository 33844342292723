import { Grid, GridColumn, GridRow, Image } from 'semantic-ui-react'
import { Link, Text } from '../../../../components/BaseComponents'
import { Colors } from '../../../../styles/theme'
import { GEP_PER_PERSON_PRICE, GEP_SUB_PRICE } from '../../helpers'
import { useAnalyticsView } from '../../../Amplitude'
import { useEffect } from 'react'

const OtherProviderDetails = () => {
  const pageView = useAnalyticsView()
  useEffect(() => {
    pageView('payroll upsell confirmation')
  }, [pageView])

  const whatToExpect = [
    {
      title: 'Select "Enroll in Payroll"',
      description:
        'We’ll add the "Enroll in Payroll" button right here to your dashboard.',
    },
    {
      title: 'Complete your onboarding',
      description: 'Set aside 15-30 minutes for onboarding.',
    },
    {
      title: 'Familiarize yourself',
      description:
        'Check out the walkthrough and guide detailing all of the information you’ll need to provide.',
      links: [
        {
          url: 'https://support.gusto.com/article/100758300100000/Onboard-to-Gusto-overview',
          label: 'Detailed onboarding guide',
        },
      ],
    },
  ]

  return (
    <Grid>
      <GridRow>
        <GridColumn computer={4} tablet={2} />
        <GridColumn computer={7} tablet={10} mobile={16}>
          <Image
            src={
              'https://heard-images.s3.amazonaws.com/assets/magnifying-glass.svg'
            }
            style={{ marginBottom: 24 }}
          />
          <Text as={'h1'} style={{ marginBottom: 42 }}>
            We&apos;re reviewing your payroll needs
          </Text>
          <Text as={'h2'} style={{ marginBottom: 28 }}>
            What to expect next:
          </Text>
          <Grid>
            {whatToExpect.map((item, key) => (
              <GridRow key={item.title}>
                <GridColumn width={2}>
                  <div
                    style={{
                      backgroundColor: Colors.stone,
                      borderRadius: '50%',
                      width: 28,
                      height: 28,
                      textAlign: 'center',
                      marginTop: -4,
                    }}
                  >
                    <Text as={'h3'} style={{ lineHeight: '28px' }}>
                      {key + 1}
                    </Text>
                  </div>
                </GridColumn>
                <GridColumn width={14}>
                  <Text as={'h3'} style={{ marginBottom: 8 }}>
                    {item.title}
                  </Text>
                  <Text as={'bodySm'}>{item.description}</Text>
                  {item.links && (
                    <div style={{ marginTop: 12 }}>
                      {item.links.map((link) => (
                        <Text style={{ marginBottom: 8 }} key={link.url}>
                          <Link href={link.url} newPage key={link.url}>
                            {link.label}
                          </Link>
                        </Text>
                      ))}
                    </div>
                  )}
                </GridColumn>
              </GridRow>
            ))}
            <GridRow>
              <GridColumn width={2} />
              <GridColumn width={14}>
                <Text color={'darkGray'} as={'bodySm'}>
                  The payroll feature is a base cost of ${GEP_SUB_PRICE}/month +
                  ${GEP_PER_PERSON_PRICE}
                  /individual per month.
                </Text>
              </GridColumn>
            </GridRow>
          </Grid>
        </GridColumn>
      </GridRow>
    </Grid>
  )
}

export default OtherProviderDetails
