import { Card, Text } from '../components/BaseComponents'

export const DefaultCard = () => (
  <Card>
    <Text>Body</Text>
  </Card>
)

export const SectionCard = () => (
  <Card type="section">
    <Text>Section</Text>
  </Card>
)

export const SubSectionCard = () => (
  <Card type="subsection">
    <Text>Subsection</Text>
  </Card>
)

export const DisabledCard = () => (
  <Card variant="disabled">
    <Text>Disabled</Text>
  </Card>
)

export const EmphasisCard = () => (
  <Card type="section" variant="emphasis">
    <Text>Emphasis</Text>
  </Card>
)

export const BlushCard = () => (
  <Card backgroundColor="blush">
    <Text>Blush</Text>
  </Card>
)

export const FullWidthCard = () => (
  <Card fullWidth>
    <Text>Full Width</Text>
  </Card>
)

export const CustomPaddingCard = () => (
  <Card padding={8}>
    <Text>Custom Padding 8px</Text>
  </Card>
)
