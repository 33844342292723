import { useParams } from 'react-router-dom'
import { Grid, List } from 'semantic-ui-react'
import moment from 'moment'
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Accordion,
  Card,
  GridRowColumn,
  Icon,
  Text,
  Link,
} from '../../../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../../../utils/dateHelpers'
import { UserTaxCalculation } from '../../../../Admin/UserTaxCalculations/userTaxCalculation.slice'
import { centsToDollars } from '../../../../../utils/currencyHelpers'
import CurrencyFormatLabel from '../../../../../components/shared/CurrencyFormatLabel'
import { FILING_STATUSES, TAX_ENTITY_TYPES } from '../../../taxConstants'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectTaxCalculationByTaxQuarter } from '../../../../../selectors/userTaxCalculation.selectors'

const taxCalculationQtrDisplayDate = (calc: UserTaxCalculation) => {
  const quarter = calc.calendarQuarter
  const year = calc.calendarYear
  switch (quarter) {
    case '1':
      return `January - February ${year}`
    case '2':
      return `January - April ${year}`
    case '3':
      return `January - July ${year}`
    case '4':
      return `January - November ${year}`
    default:
      return ''
  }
}

const UpdateProfileMessage = () => (
  <Text as="bodySm" color="darkGray" style={{ marginTop: 8 }}>
    If these numbers don&apos;t look accurate, please reach out via{' '}
    <Link trackMessage="clicked qte reachout" to="/conversations">
      Conversations
    </Link>
    .
  </Text>
)

const NetProfitCard = ({
  taxCalc,
}: {
  taxCalc?: UserTaxCalculation | null
}) => {
  const netIncomeForPeriod =
    (taxCalc?.periodIncomeInCents || 0) - (taxCalc?.periodExpensesInCents || 0)
  const netProfit = centsToDollars(netIncomeForPeriod)
  const netProfitText = taxCalc ? (
    <>
      {' '}
      of <CurrencyFormatLabel value={netProfit} /> from{' '}
      {taxCalculationQtrDisplayDate(taxCalc)}{' '}
    </>
  ) : null
  return (
    <Card type="section">
      <Grid stackable doubling>
        <Grid.Row>
          <Grid.Column computer={2} tablet={3}>
            <Icon icon={light('circle-dollar')} color="green" size="4x" />
          </Grid.Column>
          <Grid.Column computer={14} tablet={13}>
            <Grid>
              <GridRowColumn>
                <Text as="h2">Net Profit</Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text>
                  Your <Link to="/reports/yearly"> net profit </Link>
                  {netProfitText} provides the <b>base number</b> for your
                  estimate.
                </Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text>
                  We predict your annual net profit from this number in order to
                  calculate your estimate.
                </Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text as="bodySm" color="darkGray">
                  Uncategorized{' '}
                  <Link to="/transactions/review"> transactions </Link>
                  and missing{' '}
                  <Link to="/practice/documents">
                    monthly bank statements{' '}
                  </Link>{' '}
                  can affect the accuracy of your net profit.
                </Text>
              </GridRowColumn>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

const AdditionalTaxableIncomeCard = ({
  taxCalc,
}: {
  taxCalc?: UserTaxCalculation | null
}) => {
  const isScorp = taxCalc?.taxEntityType === TAX_ENTITY_TYPES.form_1120_s

  return (
    <Card type="section">
      <Grid stackable doubling>
        <Grid.Row>
          <Grid.Column computer={2} tablet={3}>
            <Icon icon={light('money-bills')} color="green" size="4x" />
          </Grid.Column>
          <Grid.Column computer={14} tablet={13}>
            <Grid>
              <GridRowColumn>
                <Text as="h2">Personal taxable income</Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text>
                  Your estimate is <b>increased</b> by any taxable income
                  {isScorp ? ', both from and outside ' : ' from outside '}
                  your private practice.
                </Text>
              </GridRowColumn>
              <GridRowColumn short>
                {taxCalc ? (
                  <List bulleted>
                    {isScorp && (
                      <List.Item>
                        <Text>
                          <b>
                            <CurrencyFormatLabel
                              value={centsToDollars(
                                taxCalc.scorpPracticeWIncomeInCents
                              )}
                            />
                          </b>{' '}
                          of private practice YTD W-2 income
                        </Text>
                      </List.Item>
                    )}
                    <List.Item>
                      <Text>
                        <b>
                          <CurrencyFormatLabel
                            value={centsToDollars(taxCalc.wIncomeInCents)}
                          />
                        </b>{' '}
                        of {isScorp && 'other '}YTD W-2 income
                      </Text>
                    </List.Item>
                    <List.Item>
                      <Text>
                        <b>
                          <CurrencyFormatLabel
                            value={centsToDollars(
                              taxCalc.otherIndividualIncomeInCents
                            )}
                          />
                        </b>{' '}
                        of YTD 1099 income
                      </Text>
                    </List.Item>
                    {taxCalc.filingStatus ===
                      FILING_STATUSES.married_filing_jointly && (
                      <>
                        <List.Item>
                          <Text>
                            <b>
                              <CurrencyFormatLabel
                                value={centsToDollars(
                                  taxCalc.spouseWIncomeInCents
                                )}
                              />
                            </b>{' '}
                            of your spouse&apos;s YTD W-2 income
                          </Text>
                        </List.Item>
                        <List.Item>
                          <Text>
                            <b>
                              <CurrencyFormatLabel
                                value={centsToDollars(
                                  taxCalc.spouseIndividualIncomeInCents
                                )}
                              />
                            </b>{' '}
                            of your spouse&apos;s YTD 1099 income
                          </Text>
                        </List.Item>
                      </>
                    )}
                  </List>
                ) : null}
              </GridRowColumn>
              <GridRowColumn short>
                <Text>
                  We predict your annual W-2 income from the number(s) above in
                  order to calculate your estimate.
                </Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Accordion
                  title="What if I have income from rental property or investments?"
                  variant="text"
                  content={
                    <Text>
                      The Heard Tax Team doesn&apos;t include either in your
                      quarterly tax calculation, but we suggest putting money
                      aside for annual taxes. The same goes for capital gains,
                      pension distributions and other income that isn&apos;t W-2
                      or 1099.
                    </Text>
                  }
                />
              </GridRowColumn>
              <UpdateProfileMessage />
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

const WithholdingsCard = ({
  taxCalc,
}: {
  taxCalc?: UserTaxCalculation | null
}) => (
  <Card type="section">
    <Grid stackable doubling>
      <Grid.Row>
        <Grid.Column computer={2} tablet={3}>
          <Icon icon={light('landmark')} color="green" size="4x" />
        </Grid.Column>
        <Grid.Column computer={14} tablet={13}>
          <Grid>
            <GridRowColumn>
              <Text as="h2">Withholdings</Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Text>
                Your estimate is <b>reduced</b> by any withholdings. If
                you&apos;re an S corporation owner, this includes W-2
                withholdings from being an employee of your private practice.
              </Text>
            </GridRowColumn>
            <GridRowColumn short>
              {taxCalc ? (
                <List bulleted>
                  <List.Item>
                    <Text>
                      <b>
                        <CurrencyFormatLabel
                          value={centsToDollars(
                            taxCalc.federalWithholdingInCents
                          )}
                        />
                      </b>{' '}
                      of YTD federal withholdings
                    </Text>
                  </List.Item>
                  <List.Item>
                    <Text>
                      <b>
                        <CurrencyFormatLabel
                          value={centsToDollars(
                            taxCalc.stateWithholdingInCents
                          )}
                        />
                      </b>{' '}
                      of YTD state withholdings
                    </Text>
                  </List.Item>
                  {taxCalc.filingStatus ===
                    FILING_STATUSES.married_filing_jointly && (
                    <>
                      <List.Item>
                        <Text>
                          <b>
                            <CurrencyFormatLabel
                              value={centsToDollars(
                                taxCalc.spouseFederalWithholdingInCents
                              )}
                            />
                          </b>{' '}
                          of your spouse&apos;s YTD federal withholdings
                        </Text>
                      </List.Item>
                      <List.Item>
                        <Text>
                          <b>
                            <CurrencyFormatLabel
                              value={centsToDollars(
                                taxCalc.spouseStateWithholdingInCents
                              )}
                            />
                          </b>{' '}
                          of your spouse&apos;s YTD state withholdings
                        </Text>
                      </List.Item>
                    </>
                  )}
                </List>
              ) : null}
              <UpdateProfileMessage />
            </GridRowColumn>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Card>
)

const PreviousQuarterlyPaymentsCard = () => (
  <Card type="section">
    <Grid stackable doubling>
      <Grid.Row>
        <Grid.Column computer={2} tablet={3}>
          <Icon icon={light('receipt')} color="green" size="4x" />
        </Grid.Column>
        <Grid.Column computer={14} tablet={13}>
          <Grid>
            <GridRowColumn>
              <Text as="h2">Previous Quarterly Payments</Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Text>
                Tax payments made in Q1-Q3 are used to calculate an accurate Q4
                estimate.{' '}
              </Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Text as="bodySm" color="darkGray">
                Make sure all tax payments throughout the year are recorded in
                your <Link to="/taxes/quarterly">Payment History</Link>.
              </Text>
            </GridRowColumn>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Card>
)

const DefaultBreakdown = () => {
  const { taxQuarter } = useParams()
  const taxCalc = useReselector(selectTaxCalculationByTaxQuarter, taxQuarter)

  return (
    <Grid>
      <GridRowColumn>
        <Link to="/taxes/quarterly">
          <Icon icon={regular('arrow-left')} style={{ marginRight: 8 }} />
          Back to Quarterly Taxes
        </Link>
      </GridRowColumn>
      <GridRowColumn>
        <Text as="h1">How are your quarterly tax estimates calculated?</Text>
      </GridRowColumn>
      <GridRowColumn>
        <Text>
          We predict your total {taxCalc?.calendarYear} tax liability based upon
          your financial results so far, and then adjust for this quarter&apos;s
          payment.
        </Text>
        {taxCalc && (
          <Text style={{ marginTop: 8 }}>
            The dollar values you see below reflect the values we used to
            calculate this estimate on{' '}
            {moment(taxCalc?.calculatedAt).format(DATE_FORMATS.DISPLAY_SHORT)}.
          </Text>
        )}
      </GridRowColumn>
      <GridRowColumn>
        <NetProfitCard taxCalc={taxCalc} />
      </GridRowColumn>
      <GridRowColumn>
        <AdditionalTaxableIncomeCard taxCalc={taxCalc} />
      </GridRowColumn>
      <GridRowColumn>
        <WithholdingsCard taxCalc={taxCalc} />
      </GridRowColumn>
      <GridRowColumn>
        <PreviousQuarterlyPaymentsCard />
      </GridRowColumn>
    </Grid>
  )
}

export default DefaultBreakdown
