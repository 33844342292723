import axios from 'axios'
import { Moment } from 'moment'

import { fetchWrapper } from '../reducers/fetch'
import {
  JournalEntryLine,
  receiveJournalEntryLines,
} from '../reducers/admin/journalEntryLineReducer'
import { keyBy } from 'lodash'

// Admin Breakdowns

export interface AdminExpenses {
  expenses: Array<{
    transactionCategoryId: number
    sum: string
  }>
  sum: string | number
}
export const fetchAdminExpensesBreakdown = ({
  userId,
  startDate,
  endDate,
}: {
  userId: number | string
  startDate?: Moment
  endDate?: Moment
}) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error fetching the expenses breakdown.',
    defaultValue: {
      expenses: [],
      sum: 0,
    },
    fetchFunction: () =>
      axios
        .get<AdminExpenses>(
          `/finances/api/v2/admin/reports/journal_entry_expenses_breakdown/${userId}`,
          {
            params: {
              startDate: startDate?.format('YYYY-MM-DD'),
              endDate: endDate?.format('YYYY-MM-DD'),
              year: !startDate && !endDate && 'all',
            },
          }
        )
        .then((json) => {
          return json.data
        }),
  })

export interface AdminProfits {
  profits: Array<{
    transactionCategoryId: number
    sum: string
  }>
  sum: string | number
}

export const fetchAdminProfitsBreakdown =
  ({
    userId,
    startDate,
    endDate,
  }: {
    userId: number | string
    startDate?: Moment
    endDate?: Moment
  }) =>
  () =>
    axios
      .get<AdminProfits>(
        `/finances/api/v2/admin/reports/journal_entry_profits_breakdown/${userId}`,
        {
          params: {
            startDate: startDate?.format('YYYY-MM-DD'),
            endDate: endDate?.format('YYYY-MM-DD'),
            year: !startDate && !endDate && 'all',
          },
        }
      )
      .then((json) => {
        return json.data
      })
      .catch(() => ({
        profits: [],
        sum: 0,
      }))

export const fetchAdminUncategorizedBreakdown =
  ({
    userId,
    startDate,
    endDate,
  }: {
    userId: number
    startDate?: Moment
    endDate?: Moment
  }) =>
  () =>
    axios
      .get<{ sum: number; transactionCategoryId: number }>(
        `/finances/api/v2/admin/reports/journal_entry_uncategorized_breakdown/${userId}`,
        {
          params: {
            startDate: startDate?.format('YYYY-MM-DD'),
            endDate: endDate?.format('YYYY-MM-DD'),
            year: !startDate && !endDate && 'all',
          },
        }
      )
      .then((json) => json.data)
      .catch(() => ({
        sum: 0,
        transactionCategoryId: null,
      }))

export const fetchAdminOtherBreakdown =
  ({
    userId,
    startDate,
    endDate,
  }: {
    userId: number
    startDate?: Moment
    endDate?: Moment
  }) =>
  () =>
    axios
      .get<{
        otherExpenses: Array<{
          transactionCategoryId: number
          sum: string
        }>
        sum: string | number
      }>(
        `/finances/api/v2/admin/reports/journal_entry_other_breakdown/${userId}`,
        {
          params: {
            startDate: startDate?.format('YYYY-MM-DD'),
            endDate: endDate?.format('YYYY-MM-DD'),
            year: !startDate && !endDate && 'all',
          },
        }
      )
      .then((json) => {
        return json.data
      })
      .catch(() => ({
        otherExpenses: [],
        sum: 0,
      }))

export interface AdminProfitLossItem {
  name: string
  account_type: string
  sum: string
}

export const fetchAdminProfitAndLoss =
  ({
    userId,
    startDate,
    endDate,
  }: {
    userId: string | number
    startDate?: Moment
    endDate?: Moment
  }) =>
  () =>
    axios
      .get<{
        profits: AdminProfitLossItem[]
        profitSum: string | number // Api returns 0 instead of "0"
        expenses: AdminProfitLossItem[]
        expensesSum: string | number
        other: AdminProfitLossItem[]
        otherSum: string | number
        uncategorizedSum: string | number
      }>(
        `/finances/api/v2/admin/reports/journal_entry_profit_and_loss/${userId}`,
        {
          params: {
            startDate: startDate?.format('YYYY-MM-DD'),
            endDate: endDate?.format('YYYY-MM-DD'),
            year: !startDate && !endDate && 'all',
          },
        }
      )
      .then((json) => json.data)
      .catch(() => ({
        profits: [],
        profitSum: 0,
        expenses: [],
        expensesSum: 0,
        other: [],
        otherSum: 0,
        uncategorizedSum: 0,
      }))

export const FETCH_JOURNAL_ENTRY_LINES_KEY = 'FETCH_JOURNAL_ENTRY_LINES_KEY'
export const fetchAdminJournalEntryLines = ({
  userId,
  startDate,
  endDate,
  transactionCategoryId,
  financialAccountId,
}: {
  userId: number
  startDate: Moment
  endDate: Moment
  transactionCategoryId?: number
  financialAccountId?: number
}) =>
  fetchWrapper({
    defaultErrorMessage: 'Error fetching journal entry lines',
    fetchKey: FETCH_JOURNAL_ENTRY_LINES_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<JournalEntryLine[]>(
        `/finances/api/v2/admin/reports/journal_entry_lines/${userId}`,
        {
          params: {
            startDate: startDate?.format('YYYY-MM-DD'),
            endDate: endDate?.format('YYYY-MM-DD'),
            transactionCategoryId,
            financialAccountId,
          },
        }
      )
      dispatch(receiveJournalEntryLines(keyBy(json.data, 'id')))
      return json.data
    },
  })
