import { ReactNode } from 'react'
import { Breadcrumb } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { getUserNameById } from '../../../selectors/user.selectors'
import { useReselector } from '../../../utils/sharedHooks'

const UserBreadcrumbs = ({
  userId,
  pageName,
  children,
}: {
  userId: number | string | undefined
  pageName?: string
  children?: ReactNode
}) => {
  const userName = useReselector(getUserNameById, userId)

  if (!userName) {
    return null
  }

  return (
    <Breadcrumb size="small">
      <Breadcrumb.Section>
        {' '}
        <Link to="/">Dashboard</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right chevron" />
      <Breadcrumb.Section>
        <Link to="/admin/finances/list-users">Users</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right chevron" />
      {/*Display link only if there are other tabs*/}
      <Breadcrumb.Section>
        {pageName || children ? (
          <Link to={`/admin/finances/records/${userId}`}>{userName}</Link>
        ) : (
          userName
        )}
      </Breadcrumb.Section>
      {pageName && (
        <>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active>{pageName}</Breadcrumb.Section>
        </>
      )}
      {children}
    </Breadcrumb>
  )
}

export default UserBreadcrumbs
