import { createSelector } from 'reselect'
import { sortBy, isNil, isNull } from 'lodash'
import moment from 'moment'

import { ReduxState } from '../../utils/typeHelpers'
import { addCurrencyArray, centsToDollars } from '../../utils/currencyHelpers'
import {
  getCurrentUser,
  getFinancialProfile,
  selectParsedAddress,
} from '../../selectors/user.selectors'
import { FILING_STATUSES, TAX_ENTITY_TYPES } from '../Taxes/taxConstants'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { PayrollSetup, PayrollState } from '../../reducers/auth/userReducer'
import { Gusto_EmployeeFederalTaxes } from './generated_gusto_types'

export const selectPayrollProfile = (state: ReduxState) => state.payrollProfile
export const selectEmployees = (state: ReduxState) => state.employees
export const selectEmployeeJobs = (state: ReduxState) => state.employeeJobs
export const selectContractors = (state: ReduxState) => state.contractors
export const selectPayrollCompany = (state: ReduxState) => state.payrollCompany
export const selectPayrollCompanyLocations = (state: ReduxState) =>
  state.payrollCompanyLocation
export const selectPayrolls = (state: ReduxState) => state.payrolls
export const selectPaySchedule = (state: ReduxState) => state.paySchedule

export const selectIsPayrollActive = createSelector(
  selectPayrollProfile,
  (payrollProfile) => Boolean(payrollProfile?.isActive)
)

export const selectCompanyId = createSelector(
  selectPayrollProfile,
  (payrollProfile) => payrollProfile?.gustoCompanyUuid
)

export const selectEmployeeByUuid = createSelector(
  selectEmployees,
  (_: unknown, uuid?: string) => uuid,
  (employees, uuid) => (uuid && employees[uuid] ? employees[uuid] : null)
)

export const selectActiveEmployees = createSelector(
  selectEmployees,
  (employees) =>
    Object.values(employees).filter((employee) => !employee.terminated)
)

export const selectInActiveEmployees = createSelector(
  selectEmployees,
  (employees) =>
    Object.values(employees).filter((employee) => employee.terminated)
)

export const selectActiveOnboardedEmployees = createSelector(
  selectActiveEmployees,
  (activeEmployees) => activeEmployees.filter((emp) => emp.onboarded)
)

export const selectActiveContractors = createSelector(
  selectContractors,
  (contractors) =>
    Object.values(contractors).filter((contractor) => contractor.is_active)
)

export const selectInactiveContractors = createSelector(
  selectContractors,
  (contractors) =>
    Object.values(contractors).filter((contractor) => !contractor.is_active)
)

export const selectNumberOfTeamMembers = createSelector(
  selectActiveEmployees,
  selectActiveContractors,
  (employees, contractors) => employees.length + contractors.length
)

export const selectNetPayroll = createSelector(
  selectPayrolls,
  (_: unknown, year?: number) => year,
  (payrollObject, year) => {
    let payrolls = Object.values(payrollObject)

    if (year) {
      payrolls = payrolls.filter(
        ({ processed_date }) =>
          processed_date && moment(processed_date).year() === year
      )
    }

    return {
      netPaidToEmployees: addCurrencyArray(
        payrolls.map((payroll) => payroll.totals?.net_pay)
      ),
      netPayrollTaxes: addCurrencyArray(
        payrolls.map((payroll) => payroll.totals?.tax_debit)
      ),
      netDebit: addCurrencyArray(
        payrolls.map((payroll) => payroll.totals?.company_debit)
      ),
    }
  }
)

export const selectJobByEmployeeUuid = createSelector(
  selectEmployeeByUuid,
  selectEmployeeJobs,
  (employee, jobs) => {
    if (!employee || !employee.jobs.length) {
      return null
    }

    // Return the first job
    return jobs[employee.jobs[0]] ? jobs[employee.jobs[0]] : null
  }
)

export const selectCompensationByEmployeeUuid = createSelector(
  selectJobByEmployeeUuid,
  (job) =>
    job?.compensations.find(
      (comp) => comp.uuid === job.current_compensation_uuid
    )
)

export const selectContractorByUuid = createSelector(
  selectContractors,
  (_: unknown, uuid: string | undefined) => uuid,
  (contractors, uuid) => (uuid && contractors[uuid] ? contractors[uuid] : null)
)

export const selectTeamMemberByUuid = createSelector(
  selectEmployeeByUuid,
  selectContractorByUuid,
  (_: unknown, __: unknown, isEmployee: boolean) => isEmployee,
  (employee, contractor, isEmployee) => (isEmployee ? employee : contractor)
)

export const selectPayrollCompanyLocationByUuid = createSelector(
  selectPayrollCompanyLocations,
  (_: unknown, uuid: string | undefined) => uuid,
  (locations, uuid) => (uuid && locations[uuid] ? locations[uuid] : null)
)

export const selectPayrollByUuid = createSelector(
  selectPayrolls,
  (_: unknown, uuid: string | undefined) => uuid,
  (payrolls, uuid) => (uuid && payrolls[uuid] ? payrolls[uuid] : null)
)

const selectSortedPayrolls = createSelector(selectPayrolls, (payrolls) =>
  sortBy(Object.values(payrolls), (payroll) => payroll.pay_period.start_date)
)

const selectPastUnprocessedPayrolls = createSelector(
  selectSortedPayrolls,
  (payrolls) => {
    const today = moment().startOf('day')

    return payrolls.filter(
      ({
        pay_period: { start_date },
        processed,
        off_cycle,
        payroll_deadline,
      }) =>
        moment(start_date, DATE_FORMATS.GUSTO_SUBMIT).isSameOrBefore(today) &&
        moment(payroll_deadline, DATE_FORMATS.GUSTO_SUBMIT).isSameOrAfter(
          today
        ) &&
        !processed &&
        !off_cycle
    )
  }
)

export const selectUpcomingPayroll = createSelector(
  selectSortedPayrolls,
  selectPastUnprocessedPayrolls,
  (allSortedPayrolls, pastUnprocessedPayrolls) => {
    const today = moment().startOf('day')

    // If there is a payroll in the current period that hasn't been processed return that
    const currentPayroll =
      pastUnprocessedPayrolls[pastUnprocessedPayrolls.length - 1]

    // Return next payroll.  Page will handle display if it's processed or not
    return (
      currentPayroll ||
      allSortedPayrolls.find(
        ({ pay_period: { start_date }, off_cycle }) =>
          moment(start_date, DATE_FORMATS.GUSTO_SUBMIT).isAfter(today) &&
          !off_cycle
      )
    )
  }
)

export const selectPreviousUnprocessedPayrollOptions = createSelector(
  selectPastUnprocessedPayrolls,
  (payrolls) =>
    payrolls.map(({ payroll_uuid, pay_period: { start_date, end_date } }) => ({
      text: `${moment(start_date, DATE_FORMATS.GUSTO_SUBMIT).format(
        'MMM DD'
      )} - ${moment(end_date, DATE_FORMATS.GUSTO_SUBMIT).format(
        'MMM DD, YYYY'
      )}`,
      value: payroll_uuid,
    }))
)

export const selectPayrollsForEmployee = createSelector(
  selectPayrolls,
  (_: unknown, uuid: string) => uuid,
  (payrolls, uuid) =>
    Object.values(payrolls).filter((payroll) =>
      Boolean(
        payroll.employee_compensations?.find(
          (comp) => comp.employee_uuid === uuid && !comp.excluded
        )
      )
    )
)

// For enrollment flow.  There should only ever be one employee so use that one
export const selectFirstEmployee = createSelector(
  selectEmployees,
  (employees) => {
    const empArr = Object.values(employees)
    return empArr.length ? empArr[0] : undefined
  }
)

// For enrollment flow.  There should only ever be one employee job so use that one
export const selectFirstEmployeeJob = createSelector(
  selectEmployeeJobs,
  (jobs) => {
    const jobArr = Object.values(jobs)
    return jobArr.length ? jobArr[0] : undefined
  }
)

export const selectContractorList = createSelector(
  selectContractors,
  (contractors) => Object.values(contractors)
)

export const selectPayrollCanBeCanceled = createSelector(
  selectPayrollByUuid,
  (payroll) =>
    Boolean(payroll?.payroll_uuid) &&
    Boolean(payroll?.processed) &&
    moment().isBefore(
      moment(payroll?.payroll_deadline, DATE_FORMATS.GUSTO_SUBMIT)
        .hour(15)
        .minutes(30)
    )
)

export const selectFilingLocation = createSelector(
  selectPayrollCompanyLocations,
  (locations) => Object.values(locations).find((l) => l.filing_address)
)

export const selectMailingLocation = createSelector(
  selectPayrollCompanyLocations,
  (locations) => Object.values(locations).find((l) => l.mailing_address)
)

export const selectOtherLocations = createSelector(
  selectPayrollCompanyLocations,
  (locations) =>
    Object.values(locations).filter(
      (l) => !l.filing_address && !l.mailing_address
    )
)

export const selectFilingLocationOrParsedAddress = createSelector(
  selectFilingLocation,
  getCurrentUser,
  selectParsedAddress,
  (filingLocation, user, { parsedAddress }) =>
    filingLocation || {
      uuid: undefined,
      street_1: parsedAddress.addressLine1,
      street_2: parsedAddress.addressLine2,
      city: parsedAddress.placeName,
      state: parsedAddress.stateAbbreviation,
      zip: parsedAddress.zipCode,
      phone_number: user?.phoneNumber || undefined,
    }
)

const heardToGustoFilingStatus = (status?: FILING_STATUSES | null) => {
  if (
    status === 'married_filing_jointly' ||
    status === 'married_filing_separately'
  ) {
    return 'Married'
  } else if (status === 'head_of_household') {
    return 'Head of Household'
  }
  return 'Single'
}

const toStringOrUndefined = (value?: number | null) =>
  isNil(value) ? undefined : value.toString()
const toSingleDigit = (val?: string | null) =>
  val ? Number(val).toString() : undefined

export const selectEnrollFederalFormDefaults = createSelector(
  getFinancialProfile,
  (_: unknown, federalTaxes: Gusto_EmployeeFederalTaxes) => federalTaxes,
  (profile, taxes) => ({
    filing_status:
      taxes?.filing_status || heardToGustoFilingStatus(profile?.filingStatus),
    extra_withholding:
      taxes?.extra_withholding ||
      toStringOrUndefined(centsToDollars(profile?.federalWithholdingInCents)),
    two_jobs: taxes?.two_jobs ? 'Yes' : 'No',
    dependents_amount:
      toSingleDigit(taxes?.dependents_amount) ||
      toStringOrUndefined(profile?.numberOfDependents),
    other_income: taxes?.other_income,
    deductions:
      taxes?.deductions ||
      toStringOrUndefined(centsToDollars(profile?.deductionInCents)),
  })
)

/* 
  These rules are defined in
  https://linear.app/heard/issue/GROW-339/update-logic-for-displaying-payroll-upsell-card-left-nav-tab-welcome
  and https://www.notion.so/heard/QA-Payroll-Upsell-6699a3225d38470baac19e71baf08b64?pvs=4#11d813ca8a8944ff9745ee7685e25585
*/
export const selectPayrollState = createSelector(
  getCurrentUser,
  selectIsPayrollActive,
  (currentUser, isPayrollActive) => {
    if (!currentUser) {
      return null
    }
    const { financialProfile } = currentUser
    if (
      (financialProfile?.taxEntityType === TAX_ENTITY_TYPES.form_1120_s ||
        financialProfile?.practiceType === 'group') &&
      isNull(currentUser?.interestedInGepAt) &&
      isNull(currentUser?.payrollEnabledAt) &&
      (currentUser?.payrollSetup === PayrollSetup.unknown ||
        currentUser.payrollSetup === PayrollSetup.none) &&
      !isPayrollActive
    ) {
      return PayrollState.payroll_eligible
    }

    if (
      (financialProfile?.taxEntityType === TAX_ENTITY_TYPES.form_1120_s ||
        financialProfile?.practiceType === 'group') &&
      !isNull(currentUser.interestedInGepAt) &&
      isNull(currentUser.payrollEnabledAt) &&
      (currentUser.payrollSetup === PayrollSetup.gusto ||
        currentUser.payrollSetup === PayrollSetup.needsPayroll) &&
      !isPayrollActive
    ) {
      return PayrollState.payroll_interested
    }

    return null
  }
)

export const selectShouldShowPayrollUpsellCard = createSelector(
  selectPayrollState,
  (payrollState) => payrollState === PayrollState.payroll_eligible
)

export const selectShouldShowPayrollSideNavLink = createSelector(
  selectPayrollState,
  (payrollState) =>
    payrollState === PayrollState.payroll_eligible ||
    payrollState === PayrollState.payroll_interested
)

export const selectShouldShowPayrollWelcomePage = createSelector(
  selectPayrollState,
  (payrollState) => payrollState === PayrollState.payroll_eligible
)

export const selectShouldShowPayrollConfirmationPage = createSelector(
  selectPayrollState,
  (payrollState) => payrollState === PayrollState.payroll_interested
)
