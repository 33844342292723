import moment from 'moment'
import { FinancialProfile } from '../../../reducers/auth/userReducer'
import {
  QuarterlyTaxEstimateDetail,
  fetchAllQuarterlyTaxEstimateDetailsIfNeeded,
} from '../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import {
  databaseColumns as KEYS,
  FILING_STATUSES,
  NO_STATE_QUARTERLY_TAX_ESTIMATES,
} from '../taxConstants'
import currency from 'currency.js'
import { isNil } from 'lodash'
import {
  UserTaxEstimate,
  fetchUserTaxEstimatesIfNeeded,
} from './userTaxEstimates.slice'
import { FederalIncomeTaxEstimateDetails } from '../../Admin/Taxes/IncomeTaxDetails/FederalIncomeTaxDetails/federalIncomeTaxDetailsActions'
import { UserActionItemActionItemIdentifiers } from '../../Dashboard/UserActionItems/userActionItems.slice'
import { Attributes, useAnalyticsTrack } from '../../Amplitude'
import { markUserActionItemCompleteIfExists } from '../../Dashboard/UserActionItems/service'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useEffect } from 'react'
import { useReselector } from '../../../utils/sharedHooks'
import { selectActiveQuarterlyTaxEstimateDetails } from '../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { selectActionableQTEChecklistItemsComplete } from './userTaxEstimates.selector'

/*
  Removes the timestamp component of a date string.
  If null is provided, return today's date

  Example input:  2022-03-09T22:31:11.437Z
  Example output: 2022-03-09T00:00:00.000Z
*/
export function dateWithoutTimestamp(date: string | null = null) {
  return date ? moment.utc(date) : moment.utc()
}

/**
 * Returns a standard deduction based upon the filing status rules
 * married_filing_separately = 12,950
 *    married_filing_jointly = 25,900
 *         head_of_household = 19,400
 *          qualifying_widow = 25,900
 *                    single = 12,950
 */
export function calculateStandardDeduction(
  filingStatus: string,
  currentFederalIncomeTaxDetails: FederalIncomeTaxEstimateDetails | undefined
) {
  if (currentFederalIncomeTaxDetails === undefined) {
    return null
  }

  if (filingStatus === FILING_STATUSES.married_filing_separately) {
    return currentFederalIncomeTaxDetails.standardDeduction
      .marriedFilingSeparately
  }

  if (filingStatus === FILING_STATUSES.married_filing_jointly) {
    return currentFederalIncomeTaxDetails.standardDeduction.marriedFilingJointly
  }

  if (filingStatus === FILING_STATUSES.head_of_household) {
    return currentFederalIncomeTaxDetails.standardDeduction.headOfHousehold
  }

  if (filingStatus === FILING_STATUSES.qualifying_widow) {
    return currentFederalIncomeTaxDetails.standardDeduction.qualifyingWidow
  }

  if (filingStatus === FILING_STATUSES.single) {
    return currentFederalIncomeTaxDetails.standardDeduction.single
  }

  return 0
}

/* 
  Checks each form field to ensure values are present. For % income per state, we
  ensure that all values sum to 100%.
*/
export function validateTaxProfileForm(state: Partial<FinancialProfile>) {
  const erroneousFields: { [key: string]: string } = {}

  const errorFieldRequired = 'This field is required'
  const errorSumIncomplete =
    'The % of income made in each state does not sum to 100%'
  const errorNaN = 'The % of income made is not a number'

  const setError = ({ key, message }: { key: string; message: string }) => {
    if (!erroneousFields[key]) {
      erroneousFields[key] = message
    }
  }

  if (!state.filingStatus) {
    setError({ key: KEYS.filingStatus, message: errorFieldRequired })
  }

  if (
    state.filingStatus !== FILING_STATUSES.single &&
    !state.numberOfDependents?.toString()
  ) {
    setError({ key: KEYS.numberOfDependents, message: errorFieldRequired })
  }

  if (isNil(state.deductionInCents)) {
    setError({ key: KEYS.deductionInCents, message: errorFieldRequired })
  }

  if (state.filingStatus === FILING_STATUSES.married_filing_jointly) {
    if (!state.spouseFirstName) {
      setError({ key: KEYS.spouseFirstName, message: errorFieldRequired })
    }

    if (!state.spouseLastName) {
      setError({ key: KEYS.spouseLastName, message: errorFieldRequired })
    }
  }

  if (state.relocatedThisQuarter === null) {
    setError({ key: KEYS.relocatedThisQuarter, message: errorFieldRequired })
  } else if (state.relocatedThisQuarter) {
    if (!state.relocatedPreviousState) {
      setError({
        key: KEYS.relocatedPreviousState,
        message: errorFieldRequired,
      })
    }

    if (!state.relocatedPreviousStateAt) {
      setError({
        key: KEYS.relocatedPreviousStateAt,
        message: errorFieldRequired,
      })
    }
  }

  if (!state.homeState) {
    setError({ key: KEYS.homeState, message: errorFieldRequired })
  }

  if (state.sawMultistateClientsThisQuarter === null) {
    setError({
      key: KEYS.sawMultistateClientsThisQuarter,
      message: errorFieldRequired,
    })
  } else if (state.sawMultistateClientsThisQuarter) {
    if (!state.percentageIncomePerState) {
      setError({
        key: KEYS.percentageIncomePerState,
        message: errorFieldRequired,
      })
    }

    let percentageSum = 0
    state.percentageIncomePerState?.forEach((row) => {
      if (!row.state || !row.value) {
        setError({
          key: KEYS.percentageIncomePerState,
          message: errorFieldRequired,
        })
      }

      if (row.value) {
        const rowValue = parseFloat(row.value)

        if (isNaN(rowValue)) {
          setError({ key: KEYS.percentageIncomePerState, message: errorNaN })
        } else {
          percentageSum += rowValue
        }
      }
    })

    if (percentageSum !== 100) {
      setError({
        key: KEYS.percentageIncomePerState,
        message: errorSumIncomplete,
      })
    }
  }
  if (!state.taxEntityType) {
    setError({ key: KEYS.taxEntityType, message: errorFieldRequired })
  }

  return erroneousFields
}

/* 
  The maximum (realistic) withholding amount that a user should enter is based upon their W2 income.
  This function calculates the max withholding amount using the formula below

  MAX_WITHHOLDING = ((w2 * 0.20) / 4) * q, where

  q = 1, 2, 3, or 4
  w2 = W2 income
*/
const maxWithholdingForQuarter = (
  w2IncomeInCents: number,
  quarterDetails?: QuarterlyTaxEstimateDetail
) => {
  const PERCENTAGE_OF_W2_INCOME_IN_DECIMAL = 0.2

  // Convert the Number into a currency object
  const w2CurrencyCents = currency(w2IncomeInCents)

  // Convert the specific quarter from string to number
  const quarterNumber = Number(quarterDetails?.taxQuarter || 4)

  const resultCurrency = w2CurrencyCents
    .multiply(PERCENTAGE_OF_W2_INCOME_IN_DECIMAL)
    .divide(4)
    .multiply(quarterNumber)

  return resultCurrency.value
}

export const inspectTaxProfileForWarnings = (
  state: Partial<FinancialProfile>,
  activeQuarterDetails?: QuarterlyTaxEstimateDetail
) => {
  const warnedFields: { [key: string]: string } = {}

  const DEFAULT_HIGH_MESSAGE = 'This value seems a little high 👇'
  const W_INCOME_UPPER_BOUND_CENTS = 10000000 // $100k
  const NUMBER_DEPENDENTS_UPPER_BOUND = 11

  const setWarning = ({ key, message }: { key: string; message: string }) => {
    if (!warnedFields[key]) {
      warnedFields[key] = message
    }
  }

  // Values are currently input into the form as whole dollar amounts.
  // They are stored in state as cent amounts. Below we're instantiated the currency
  // object using cent amounts.
  // Calling .value returns cents amount, whereas calling .intValue would return cents * 100 (which is not useful here)
  const w2CurrencyCents = currency(state.wIncomeInCents || 0)
  const spouseW2CurrencyCents = currency(state.spouseWIncomeInCents || 0)
  const federalWithholdingCurrencyCents = currency(
    state.federalWithholdingInCents || 0
  )
  const stateWithholdingCurrencyCents = currency(
    state.stateWithholdingInCents || 0
  )
  const spouseFederalWithholdingCurrencyCents = currency(
    state.spouseFederalWithholdingInCents || 0
  )
  const spouseStateWithholdingCurrencyCents = currency(
    state.spouseStateWithholdingInCents || 0
  )

  const INDIVIDUAL_WITHHOLDING_UPPER_BOUND_CENTS = maxWithholdingForQuarter(
    w2CurrencyCents.value,
    activeQuarterDetails
  )
  const SPOUSE_WITHHOLDING_UPPER_BOUND_CENTS = maxWithholdingForQuarter(
    spouseW2CurrencyCents.value,
    activeQuarterDetails
  )

  if (w2CurrencyCents.value > W_INCOME_UPPER_BOUND_CENTS) {
    setWarning({ key: KEYS.wIncomeInCents, message: DEFAULT_HIGH_MESSAGE })
  }

  if (spouseW2CurrencyCents.value > W_INCOME_UPPER_BOUND_CENTS) {
    setWarning({
      key: KEYS.spouseWIncomeInCents,
      message: DEFAULT_HIGH_MESSAGE,
    })
  }

  if (
    federalWithholdingCurrencyCents.value >
    INDIVIDUAL_WITHHOLDING_UPPER_BOUND_CENTS
  ) {
    setWarning({
      key: KEYS.federalWithholdingInCents,
      message: DEFAULT_HIGH_MESSAGE,
    })
  }

  if (
    stateWithholdingCurrencyCents.value >
    INDIVIDUAL_WITHHOLDING_UPPER_BOUND_CENTS
  ) {
    setWarning({
      key: KEYS.stateWithholdingInCents,
      message: DEFAULT_HIGH_MESSAGE,
    })
  }

  if (
    spouseFederalWithholdingCurrencyCents.value >
    SPOUSE_WITHHOLDING_UPPER_BOUND_CENTS
  ) {
    setWarning({
      key: KEYS.spouseFederalWithholdingInCents,
      message: DEFAULT_HIGH_MESSAGE,
    })
  }

  if (
    spouseStateWithholdingCurrencyCents.value >
    SPOUSE_WITHHOLDING_UPPER_BOUND_CENTS
  ) {
    setWarning({
      key: KEYS.spouseStateWithholdingInCents,
      message: DEFAULT_HIGH_MESSAGE,
    })
  }

  if (Number(state.numberOfDependents || 0) > NUMBER_DEPENDENTS_UPPER_BOUND) {
    setWarning({ key: KEYS.numberOfDependents, message: DEFAULT_HIGH_MESSAGE })
  }

  return warnedFields
}

// Return true if a numerical string is not zero
export const shouldShowPaystub = (input?: number | null) => Boolean(input)

// Prevents manual typing of dates for our calendar input component
export function dateFormattedCorrectly(dateString: string) {
  return /^\d{2}-\d{2}-\d{4}$/.test(dateString)
}

export function isValidDate(dateString: string) {
  const date = moment(dateString)
  return dateFormattedCorrectly(dateString) && date.isValid()
}

// Represents the possible database values of user_tax_estimate.status
export const EstimateDBStatus = {
  future: 'future',
  past: 'past',
  active: 'active',
}

export enum EstimateStatus {
  awaitingEstimate,
  paid,
  upcoming,
  noIncomeTax,
  joinedTooLate,
  estimateIsZero,
  unpaidWithoutResponse,
  unpaidWithResponse,
  readyForPayment,
}

// Determines status for an estimate. The order of "returns" matters here
export const statusForSingleEstimate = (
  estimate: UserTaxEstimate | null,
  userJoinedToLate?: boolean
) => {
  if (!estimate) {
    return EstimateStatus.awaitingEstimate
  }

  const isStateEstimate = estimate.type !== 'federal'
  const paymentExpectedByHeard = !estimate.noTaxPaymentNeeded
  const userIndicatedNoPaymentThisQuarter = estimate.quarterPaid === false

  // estimate was paid
  if (estimate.paidAt) {
    return EstimateStatus.paid
  }

  // db status === 'future', it's an upcoming estimate
  if (estimate.status === EstimateDBStatus.future) {
    return EstimateStatus.upcoming
  }

  // it's a state estimate AND the state doesn't charge income tax
  if (
    estimate.filingState &&
    isStateEstimate &&
    NO_STATE_QUARTERLY_TAX_ESTIMATES.includes(estimate.filingState)
  ) {
    return EstimateStatus.noIncomeTax
  }

  if (
    estimate.status === EstimateDBStatus.active &&
    userJoinedToLate &&
    !estimate.safeHarborType
  ) {
    return EstimateStatus.joinedTooLate
  }

  // db status === 'active' AND payment is expected AND the estimate's value hasn't been set yet AND user hasn't indicated, we are awaiting estimate
  if (
    estimate.status === EstimateDBStatus.active &&
    isNil(estimate.estimateInCents) &&
    paymentExpectedByHeard &&
    !userIndicatedNoPaymentThisQuarter
  ) {
    return EstimateStatus.awaitingEstimate
  }

  // estimate === 0 OR !paymentExpectedByHeard
  if (estimate.estimateInCents === 0 || !paymentExpectedByHeard) {
    return EstimateStatus.estimateIsZero
  }

  // db status === 'past' AND payment has not been made AND customer hasn't indicated no-payment
  if (
    estimate.status === EstimateDBStatus.past &&
    isNil(estimate.paidAt) &&
    !userIndicatedNoPaymentThisQuarter
  ) {
    return EstimateStatus.unpaidWithoutResponse
  }

  // the user indicated they have not paid for this quarter
  if (userIndicatedNoPaymentThisQuarter) {
    return EstimateStatus.unpaidWithResponse
  }

  // db status === 'active' AND estimate is given AND doesn't equal 0 AND not paid
  if (
    estimate.status === EstimateDBStatus.active &&
    estimate.estimateInCents &&
    isNil(estimate.paidAt)
  ) {
    return EstimateStatus.readyForPayment
  }

  // Default
  return EstimateStatus.awaitingEstimate
}

export const statusForMultipleEstimates = (
  estimates: Array<UserTaxEstimate | null>
) => {
  const results = estimates.map((estimate) => statusForSingleEstimate(estimate))

  // If we have at least 1 awaiting_estimate, return that result for the group
  if (
    results.includes(EstimateStatus.awaitingEstimate) ||
    estimates.includes(null)
  ) {
    return EstimateStatus.awaitingEstimate
  }

  // Next, if we have at least 1 ready_for_payment, return that result for the group
  if (results.includes(EstimateStatus.readyForPayment)) {
    return EstimateStatus.readyForPayment
  }

  // Since the remaining 3 types require no action on part of the user, return paid
  return EstimateStatus.paid
}

export const updateQTEActionItemsIfNeeded = async ({
  currentQuarterDetails,
  completedChecklist,
  track,
}: {
  currentQuarterDetails?: QuarterlyTaxEstimateDetail
  completedChecklist: boolean
  track: (event: string, properties: Attributes) => void
}) => {
  if (!currentQuarterDetails) {
    return
  }

  if (completedChecklist) {
    await markUserActionItemCompleteIfExists(
      UserActionItemActionItemIdentifiers.completeQTEChecklist({
        taxQuarter: currentQuarterDetails.taxQuarter,
        taxYear: currentQuarterDetails.taxYear,
      }),
      (event, properties) => track(event, properties)
    )
  }
}

export const useCompleteQTEActionItemIfNeeded = () => {
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()

  useEffect(() => {
    dispatch(fetchAllQuarterlyTaxEstimateDetailsIfNeeded())
    dispatch(fetchUserTaxEstimatesIfNeeded())
  }, [dispatch])

  const currentQuarterDetails = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )
  const completedActionableChecklist = useReselector(
    selectActionableQTEChecklistItemsComplete,
    currentQuarterDetails?.taxYear
  )

  useEffect(() => {
    if (completedActionableChecklist && currentQuarterDetails) {
      markUserActionItemCompleteIfExists(
        UserActionItemActionItemIdentifiers.completeQTEChecklist({
          taxQuarter: currentQuarterDetails.taxQuarter,
          taxYear: currentQuarterDetails.taxYear,
        }),
        track
      )
    }
  }, [currentQuarterDetails, completedActionableChecklist, track])
}
