import { getTransactionCategorySelector } from '../../Reports/reports.selectors'
import { useReselector } from '../../../utils/sharedHooks'
import { TransactionRule } from '../../../reducers/admin/transactionRulesReducer'

const SimilarRuleItem = ({ rule }: { rule: TransactionRule }) => {
  const selectedCategory = useReselector(
    getTransactionCategorySelector,
    rule.transactionCategoryId
  )

  const applicableUsers = rule.userId ? `User: ${rule.userId}` : 'All Users'

  return (
    <li>
      <p style={{ fontSize: '16px' }}>
        <b>Rule #{rule.id}:</b> Categorizing <b>{rule.ruleText}</b> as{' '}
        <b>{selectedCategory?.name || 'Awaiting Categorization'}</b>, applicable
        to <b>{applicableUsers}</b>
      </p>
    </li>
  )
}

export default SimilarRuleItem
