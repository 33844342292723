export const PLAID_ERRORS: {
  [error: string]: string
} = {
  INVALID_INPUT:
    "It looks like we've encountered an issue with this bank connection! Please refresh the page and try connecting again.",
  ITEM_CONCURRENTLY_DELETED:
    "The bank connection you requested cannot be found because it doesn't exist or has been removed. You may add a new connection to this bank if you'd like.",
  ITEM_NOT_FOUND:
    "The bank connection you requested cannot be found because it doesn't exist or has been removed. You may add a new connection to this bank if you'd like.",
  INTERNAL_SERVER_ERROR:
    'Plaid is having some issues right now. Please refresh the page and try connecting again in a few minutes.',
  INVALID_CREDENTIALS:
    'If you are having trouble connecting your bank to Plaid due to invalid credentials, please login to your bank and check if you need to enable access for third-party apps in your settings. Then retry connecting with Plaid.',
  ID_NOT_FOUND: 'There was an issue adding your new bank connection.',
}
