import { useCallback, useState } from 'react'
import { Button, Modal, Header, Dropdown, Form } from 'semantic-ui-react'

import {
  BULK_UPDATE_USER_TRANSACTIONS_KEY,
  BulkUpdatePayload,
  bulkUpdateUserTransactions,
} from '../../actions/adminActions'
import { TRANSACTION_TYPE_OPTIONS } from '../../constants/transactionConstants'
import { clearSelectedTransactions } from '../../reducers/admin/selectedTransactionsReducer'
import { getTransactionCategoryOptions } from '../../features/Reports/reports.selectors'
import { useReselector } from '../../utils/sharedHooks'
import { getFetchError } from '../../reducers/fetch'
import { Alert } from '../BaseComponents'
import { Transaction } from '../../reducers/admin/allTransactions.slice'
import { useAppDispatch } from '../../utils/typeHelpers'
import { TransactionRuleCategoryType } from '../../reducers/admin/transactionRulesReducer'

const BulkUpdateTransactionModal = ({
  open,
  onClose,
  onApply,
  selectedTransactionKeys,
}: {
  open: boolean
  onClose: () => void
  onApply: (updatedTransactions?: Transaction[]) => void
  selectedTransactionKeys: number[]
}) => {
  const dispatch = useAppDispatch()
  const [transactionType, setTransactionType] =
    useState<TransactionRuleCategoryType>(TransactionRuleCategoryType.business)
  const [transactionCategoryId, setTransactionCategoryId] = useState<number>()

  const categoryOptions = useReselector(getTransactionCategoryOptions)
  const error = useReselector(getFetchError, BULK_UPDATE_USER_TRANSACTIONS_KEY)

  const updateTransactions = useCallback(
    async (confirm: boolean) => {
      const data: BulkUpdatePayload = {
        transactionIds: selectedTransactionKeys,
        transactionCategoryId,
        type: transactionType,
        excludedAt: transactionType === 'business' ? null : Date.now(),
      }

      if (confirm) {
        data.confirmedAt = Date.now()
        data.requestedClarificationAt = null
        data.notifiedRequestedClarificationAt = null
      }

      const res = await bulkUpdateUserTransactions(data)(dispatch)
      if (res) {
        dispatch(clearSelectedTransactions())
        onClose()
        onApply(res)
      }
    },
    [
      onClose,
      onApply,
      dispatch,
      selectedTransactionKeys,
      transactionCategoryId,
      transactionType,
    ]
  )

  return (
    <Modal
      size="small"
      dimmer="inverted"
      className="bulkUpdateTransactionModal"
      open={open}
      onClose={onClose}
    >
      <Modal.Content>
        <Header as="h4">Update Transactions</Header>
        {error && <Alert type="error">{error.message}</Alert>}

        <Header as="h6">
          {selectedTransactionKeys.length} transactions selected
        </Header>

        <Form>
          <Form.Field required>
            <label>Transaction Type</label>
            <Dropdown
              placeholder="Transaction Type"
              value={transactionType}
              options={TRANSACTION_TYPE_OPTIONS}
              onChange={(_, { value }) =>
                (value === TransactionRuleCategoryType.business ||
                  value === TransactionRuleCategoryType.personal) &&
                setTransactionType(value)
              }
              selection
              required
            />
          </Form.Field>
          <Form.Field required>
            <label>Transaction Category</label>
            <Dropdown
              placeholder="Update Category"
              value={transactionCategoryId}
              options={categoryOptions}
              onChange={(_, { value }) =>
                typeof value === 'number' && setTransactionCategoryId(value)
              }
              search
              selection
              required
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => updateTransactions(false)}>Apply</Button>
        <Button positive onClick={() => updateTransactions(true)}>
          Apply and Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default BulkUpdateTransactionModal
