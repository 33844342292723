import React, { ReactNode } from 'react'
import { Icon, Popup, SemanticICONS } from 'semantic-ui-react'
import { StrictPopupProps } from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup'

interface Props {
  popupText?: string
  popupContent: string | ReactNode
  iconName?: SemanticICONS
  trigger?: React.ReactNode
  position?: StrictPopupProps['position']
  wide?: boolean
}

// todo components using this should use shared popup component instead
const OnboardingPopup: React.FC<Props> = ({
  popupText,
  popupContent,
  iconName = 'info circle',
  position = 'bottom center',
  wide,
  trigger = (
    <p
      style={{
        color: '#7A9FA0',
        fontSize: '12px',
        cursor: 'pointer',
      }}
    >
      <Icon name={iconName} /> {popupText}
    </p>
  ),
}: Props) => {
  return (
    <Popup
      className="onboardingPopup"
      trigger={trigger}
      basic
      inverted
      wide={wide}
      content={popupContent}
      position={position}
    />
  )
}

export default OnboardingPopup
