import { Link } from 'react-router-dom'
import { Header, Menu, Button } from 'semantic-ui-react'

import './UserTransactionsPanels.scss'

const UserTransactionsHeader = ({
  headerDescription,
  backlink,
}: {
  headerDescription: string
  backlink: string
}) => (
  <Menu secondary className="userTransactionsHeader" stackable>
    <Menu.Item>
      <Link to={backlink}>
        <Button circular icon="arrow left" />
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Header as="h3">{headerDescription}</Header>
    </Menu.Item>
  </Menu>
)

export default UserTransactionsHeader
