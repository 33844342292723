import { Button } from '../components/BaseComponents'
import Popup from '../components/BaseComponents/Popup'

export const DefaultOpenedPopup = () => (
  <Popup
    content="I am a default opened popup"
    trigger={<Button>Default Popup</Button>}
    open
  />
)

export const TopCenterPopup = () => (
  <Popup
    content="I am a top center popup"
    position="top center"
    trigger={<Button>Top Center</Button>}
  />
)

export const TopRightPopup = () => (
  <Popup
    content="I am a top right popup"
    position="top right"
    trigger={<Button>Top Right</Button>}
  />
)

export const LeftCenterPopup = () => (
  <Popup
    content="I am a left center popup"
    position="left center"
    trigger={<Button>Left Center</Button>}
  />
)

export const RightCenterPopup = () => (
  <Popup
    content="I am a right center popup"
    position="right center"
    trigger={<Button>Right Center</Button>}
  />
)

export const BottomLeftPopup = () => (
  <Popup
    content="I am a bottom left popup"
    position="bottom left"
    trigger={<Button>Bottom Left</Button>}
  />
)

export const BottomCenterPopup = () => (
  <Popup
    content="I am a bottom center popup"
    position="bottom center"
    trigger={<Button>Bottom Center</Button>}
  />
)

export const BottomRightPopup = () => (
  <Popup
    content="I am a bottom right popup"
    position="bottom right"
    trigger={<Button>Bottom Right</Button>}
  />
)
