import { useCallback, useContext, useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Button, Card, Icon, Text } from '../../../components/BaseComponents'
import { useHeardPlaid } from '../../../components/shared/plaid/helpers'
import {
  ConnectionFailureModal,
  SharingPermissionsModal,
} from './ConnectBankModals'
import { selectActivePlaidFinancialAccounts } from '../../../selectors/financeSelectors'
import { FinancialAccount } from '../../../reducers/finances/financialAccountsReducer'
import {
  deleteFinancialAccount,
  fetchFinancialAccountsIfNeeded,
} from '../../../actions/financialAccountActions'
import { PlaidLinkContext } from '../../../components/Finances/Accounts/PlaidLinkContext'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useReselector } from '../../../utils/sharedHooks'

const ConnectedBank = ({ account }: { account: FinancialAccount }) => {
  const dispatch = useAppDispatch()

  const deleteAccount = useCallback(
    () => dispatch(deleteFinancialAccount(account.id)),
    [account.id, dispatch]
  )

  return (
    <Grid.Row>
      <Grid.Column width={2} />
      <Grid.Column width={13}>
        <Card backgroundColor="natural">
          <Grid>
            <Grid.Row>
              <Grid.Column width={15}>
                <Text as="h3">{account.plaidInstitutionName}</Text>
                <br />
                <Text>
                  {account.name}: {account.mask}
                </Text>
              </Grid.Column>
              <Grid.Column width={1} verticalAlign="middle">
                <Icon
                  icon={light('trash-can')}
                  onClick={deleteAccount}
                  style={{ cursor: 'pointer' }}
                  color="mediumGray"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card>
      </Grid.Column>
    </Grid.Row>
  )
}

const ConnectedBanks = () => {
  const dispatch = useAppDispatch()
  const accounts = useReselector(selectActivePlaidFinancialAccounts)
  const [troubleConnectOpen, setTroubleConnectOpen] = useState(false)
  const [connectFailureModalOpen, setConnectFailureModalOpen] = useState(false)
  const [sharingPermModalOpen, setSharingPermModalOpen] = useState(false)
  const { plaidError, clearPlaidError } = useContext(PlaidLinkContext)

  useEffect(() => {
    dispatch(fetchFinancialAccountsIfNeeded(true))
  }, [dispatch])

  useEffect(() => {
    if (plaidError) {
      if (plaidError?.errorCode === 'INVALID_CREDENTIALS') {
        setSharingPermModalOpen(true)
      } else {
        setConnectFailureModalOpen(true)
      }
    }
  }, [plaidError])

  const { open, ready } = useHeardPlaid()

  if (!Object.keys(accounts).length)
    return (
      <>
        <Grid.Row>
          <Grid.Column width={5} />
          <Grid.Column width={6}>
            <Button fullWidth disabled={!ready} onClick={() => open()}>
              <Icon icon={light('link')} style={{ marginRight: 10 }} />
              Connect Business Accounts
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="short">
          <Grid.Column width={5} />
          <Grid.Column width={6}>
            <Button
              fullWidth
              variant="link"
              onClick={() => setTroubleConnectOpen(true)}
            >
              I can&apos;t find my bank in Plaid
            </Button>
          </Grid.Column>
        </Grid.Row>
        {troubleConnectOpen && (
          <Grid.Row>
            <Grid.Column width={3} />
            <Grid.Column width={10}>
              <Card backgroundColor="natural">
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Text as="bodyLg">
                        Plaid doesn&apos;t work with some smaller banks or
                        credit unions. You&apos;ll need to use or create a
                        business account with a supported bank.
                      </Text>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Text as="bodyLg">
                        You may skip this step for now and connect an account
                        after sign-up.
                      </Text>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card>
            </Grid.Column>
          </Grid.Row>
        )}
        {connectFailureModalOpen && (
          <ConnectionFailureModal
            closeModal={() => setConnectFailureModalOpen(false)}
          />
        )}
        {sharingPermModalOpen && (
          <SharingPermissionsModal
            closeModal={() => setSharingPermModalOpen(false)}
          />
        )}
      </>
    )

  return (
    <>
      {Object.values(accounts).map((account) => (
        <ConnectedBank key={`account-row-${account.id}`} account={account} />
      ))}
      <Grid.Row>
        <Grid.Column width={2} />
        <Grid.Column width={14}>
          <Button variant="link" onClick={() => open()}>
            <Icon icon={regular('plus')} style={{ marginRight: 10 }} />
            Connect another account
          </Button>
        </Grid.Column>
      </Grid.Row>
      {connectFailureModalOpen && (
        <ConnectionFailureModal
          closeModal={() => {
            setConnectFailureModalOpen(false)
            clearPlaidError()
          }}
          hasAccount
        />
      )}
      {sharingPermModalOpen && (
        <SharingPermissionsModal
          closeModal={() => {
            setSharingPermModalOpen(false)
            clearPlaidError()
          }}
          hasAccount
        />
      )}
    </>
  )
}

export default ConnectedBanks
