import axios from 'axios'
import { fetchWrapper } from '../../reducers/fetch'

export interface WebinarEvent {
  description: string
  title: string
  url: string
  end: {
    dateTime: string
    timeZone: string
  }
  start: {
    dateTime: string
    timeZone: string
  }
}

export const FETCH_WEBINAR_KEY = 'FETCH_WEBINAR_KEY'
export const fetchWebinarEvents = () =>
  fetchWrapper({
    fetchKey: FETCH_WEBINAR_KEY,
    defaultErrorMessage: 'There was an error fetching webinars',
    fetchFunction: async () => {
      const response = await axios.get<WebinarEvent[]>(
        '/finances/api/v1/resources/webinars'
      )
      return response.data
    },
  })
