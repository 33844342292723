import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AdminPlaidItem } from '../finances/plaidItemReducer'
import { FinancialAccount } from '../finances/financialAccountsReducer'

export interface AdminPlaidItemState {
  [key: number]: { [key: number]: AdminPlaidItem | undefined }
}

const initialState: AdminPlaidItemState = {}

const plaidItemSlice = createSlice({
  name: 'plaidItems',
  initialState,
  reducers: {
    receiveUserPlaidItems: (
      state,
      action: PayloadAction<{
        plaidItems: {
          [p: string]: AdminPlaidItem
        }
        userId: number
      }>
    ) => {
      state[action.payload.userId] = action.payload.plaidItems
    },
    receiveUserPlaidItem: (
      state,
      action: PayloadAction<{
        userId: number
        plaidItem: AdminPlaidItem
      }>
    ) => {
      state[action.payload.userId][action.payload.plaidItem.id] =
        action.payload.plaidItem
    },
    receiveAdminDeleteUserPlaidItem: (
      state,
      action: PayloadAction<{
        userId: number
        deletedId: number
      }>
    ) => {
      delete state[action.payload.userId][action.payload.deletedId]
    },
    receiveUpdateUserPlaidItemFinancialAccount: (
      state,
      action: PayloadAction<{
        userId: number
        account: FinancialAccount
      }>
    ) => {
      const { userId, account } = action.payload
      if (!account.plaidItemId) return
      const plaidItem = state[userId][account.plaidItemId]
      if (!plaidItem) return
      const accountIndex = plaidItem.accounts.findIndex(
        ({ id }) => id === account.id
      )
      if (accountIndex === -1) return
      plaidItem.accounts[accountIndex] = action.payload.account
      state[userId][account.plaidItemId] = plaidItem
    },
  },
})

export default plaidItemSlice.reducer

export const {
  receiveUserPlaidItems,
  receiveUserPlaidItem,
  receiveAdminDeleteUserPlaidItem,
  receiveUpdateUserPlaidItemFinancialAccount,
} = plaidItemSlice.actions
