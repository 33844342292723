import { Fragment, ReactNode, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Button,
  Icon,
  Link,
  Popup,
  Text,
} from '../../components/BaseComponents'
import { UploadDocumentType } from '../../constants/businessConstants'
import FileUploadModal from '../../components/FileUpload/FileUploadModal'
import { useReselector } from '../../utils/sharedHooks'
import { selectUserDocumentCategoryByInternalName } from '../Admin/UserDocumentCategories/userDocumentCategories.selectors'
import { getUserDocumentsByCategoryInternalName } from '../UserDocuments/userDocuments.selector'
import { isNil } from 'lodash'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'

const PracticeProfileEntry = ({
  label,
  value,
  popupContent,
  documentCat,
  documentType = UploadDocumentType.TAX,
  taxYear,
}: {
  label: string
  value?: string | number | undefined | null
  popupContent?: ReactNode
  documentCat?: string
  documentType?: UploadDocumentType
  taxYear?: string
}) => {
  const [modelOpen, setModalOpen] = useState(false)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const taxDocCat = useReselector(
    selectUserDocumentCategoryByInternalName,
    documentCat
  )

  const userDocs = useReselector(
    getUserDocumentsByCategoryInternalName,
    documentCat
  )

  return (
    <Grid.Row verticalAlign="middle">
      <Grid.Column computer={6} tablet={7} mobile={16}>
        <Text
          as="bodyLg"
          color="darkGray"
          textAlign={isMobile ? 'left' : 'right'}
        >
          {label}
          {popupContent && <Popup content={popupContent} />}
        </Text>
      </Grid.Column>
      <Grid.Column computer={10} tablet={9} mobile={16}>
        {!isNil(value) && <Text as="bodyLg">{value}</Text>}
        {userDocs.map((doc) => (
          <Fragment key={doc.id}>
            {doc?.signedUrl ? (
              <Link href={doc.signedUrl}>{doc?.fileDescription}</Link>
            ) : (
              <Text>{doc?.fileDescription}</Text>
            )}
          </Fragment>
        ))}
        {taxDocCat && !userDocs.length && (
          <Button variant="secondary" onClick={() => setModalOpen(true)}>
            <Icon
              icon={regular('upload')}
              size="1x"
              style={{ marginRight: 10 }}
            />
            Upload
          </Button>
        )}
      </Grid.Column>

      <FileUploadModal
        open={modelOpen}
        close={() => setModalOpen(false)}
        userFacing
        documentType={documentType}
        categoryId={taxDocCat?.id}
        year={taxYear}
      />
    </Grid.Row>
  )
}

export default PracticeProfileEntry
