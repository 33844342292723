import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Gusto_Company } from './generated_gusto_types'

const initialState: Gusto_Company | null = null

const PayrollCompanySlice = createSlice({
  name: 'payrollCompany',
  initialState: initialState as Gusto_Company | null,
  reducers: {
    setPayrollCompany: (_, action: PayloadAction<Gusto_Company | null>) =>
      action.payload,
  },
})

export const { setPayrollCompany } = PayrollCompanySlice.actions

export default PayrollCompanySlice.reducer
