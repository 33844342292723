import moment from 'moment'

// BL
import { FinancialAccountReconciliationWithTransactions } from '../../../reducers/admin/allFinancialAccountReconciliationsReducer'
import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import { TransactionCollection } from './shared'
import { formatCurrencyFromCents } from '../../../utils/currencyHelpers'
import { DATE_FORMATS } from '../../../utils/dateHelpers'

/**
 * Combines two sets of transactions into one.
 * Reco.transactions wins in the event of conflicting ids
 *
 * Returns a sorted array (date DESC) of TransactionModel[]
 */
export function generateTableDatasource(
  reconciliation: FinancialAccountReconciliationWithTransactions,
  transactions: Transaction[]
) {
  const result: TransactionCollection = {}

  function populate(t: Transaction, matched: boolean) {
    const formattedDollars = formatCurrencyFromCents(t.amountInCents)
    result[t.id] = {
      id: t.id,
      reconciliationId: reconciliation.id,
      date: t.date,
      description: t.description,
      manualUpload: Boolean(t.manuallyCreated),
      debit: t.amountInCents < 0 ? formattedDollars : null,
      credit: t.amountInCents >= 0 ? formattedDollars : null,
      matched,
    }
  }

  reconciliation.transactions?.forEach((t: Transaction) => populate(t, true))

  transactions.forEach((t: Transaction) => {
    if (!result[t.id]) {
      populate(t, false)
    }
  })

  const datasource = Object.values(result)
  datasource.sort((a, b) => (new Date(a.date) < new Date(b.date) ? 1 : -1))

  return datasource
}

export function toUTCDateString({
  value,
  format = DATE_FORMATS.INPUT,
}: {
  value: Date | string
  format?: string
}) {
  return formatUTCDateString({
    value: moment(value).utc(),
    format,
  })
}

export function yesterdayUTCDateString({
  format = DATE_FORMATS.INPUT,
}: { format?: string } = {}) {
  return formatUTCDateString({
    value: moment(new Date()).utc().subtract(1, 'd'),
    format,
  })
}

export function tomorrowUTCDateString({
  value,
  format = DATE_FORMATS.INPUT,
}: {
  value: string
  format?: string
}) {
  return formatUTCDateString({
    value: moment(value).utc().add(1, 'd'),
    format,
  })
}

export function formatUTCDateString({
  value,
  format = DATE_FORMATS.INPUT,
}: {
  value: moment.Moment | string
  format?: string
}) {
  if (typeof value === 'string' || value instanceof String) {
    value = moment.utc(value)
  }

  return value.format(format)
}
