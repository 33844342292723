import { Checkbox, Icon, Popup } from 'semantic-ui-react'
import { getLockedTransactionDescription } from '../../../features/Transactions/copyConstants'
import { useCallback, useEffect, useState } from 'react'
import {
  getSelectedByTransactionId,
  shiftSelect,
  updateSelectedTransaction,
} from '../../../reducers/admin/selectedTransactionsReducer'
import { useReselector } from '../../../utils/sharedHooks'
import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import { isTransactionLocked } from '../../../features/Transactions/helpers'
import { UserWithAdminInfo } from '../../../reducers/admin/v2/allUsersReducerV2'
import { useAppDispatch } from '../../../utils/typeHelpers'

const RowSelector = ({
  row,
  user,
}: {
  row: Transaction
  user: UserWithAdminInfo
}) => {
  const dispatch = useAppDispatch()
  const selected = useReselector(getSelectedByTransactionId, row.id)
  const [checked, setChecked] = useState(selected || false)

  const locked = isTransactionLocked({
    date: row.date,
    userLockedYear: user.booksLockedForUserYear,
    adminLockedYear: user.booksLockedForAdminYear,
    isAdmin: true,
  })

  const handleCheckboxSelect = useCallback(
    (shiftKey: boolean) => {
      const newCheckedValue = !checked

      setChecked(newCheckedValue)
      if (shiftKey) {
        dispatch(shiftSelect(row.id))
      }
      dispatch(
        updateSelectedTransaction({
          transactionId: row.id,
          value: newCheckedValue,
        })
      )
    },
    [checked, dispatch, row.id]
  )

  useEffect(() => {
    setChecked(selected)
  }, [selected])

  return (
    <div>
      {locked ? (
        <Popup
          content={
            getLockedTransactionDescription().adminTransactionRowLockIconTooltip
          }
          trigger={
            <Icon
              name="lock"
              size="small"
              style={{ pointerEvents: 'auto', color: 'rgba(0,0,0,1)' }}
            />
          }
        />
      ) : (
        <Checkbox
          checked={checked}
          onClick={(_, { shiftKey }) => handleCheckboxSelect(shiftKey)}
        />
      )}
    </div>
  )
}

export default RowSelector
