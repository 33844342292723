import { Image } from 'semantic-ui-react'
import styled from 'styled-components'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { useSidebar } from '../../utils/sharedHooks'
import { Icon } from '../BaseComponents'

export const MobileMenu = styled(({ className }: { className?: string }) => {
  const { toggleSidebar, open } = useSidebar()

  return (
    <div
      className={`${className} ${open ? 'open' : 'closed'}`}
      onClick={() => toggleSidebar()}
      onKeyDown={() => toggleSidebar()}
      role="presentation"
    >
      <Icon icon={regular('bars')} color={open ? 'white' : 'green'} />
      <Image
        src="https://heard-images.s3.amazonaws.com/assets/heard-h-logo.svg"
        className="heard-h"
      />
    </div>
  )
})({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',

  '&.open': {
    filter: 'brightness(0) invert(1)',
  },
  '&.closed': {
    height: 50,
    paddingLeft: 18.5,
    paddingTop: 22.25,
  },
  '.heard-h': { width: 12.83, height: 15, marginLeft: 10.5 },
})

export default MobileMenu
