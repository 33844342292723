import { useEffect, useState } from 'react'
import { Embed } from 'semantic-ui-react'

import {
  Button,
  GridRowColumn,
  Modal,
} from '../../../components/BaseComponents'
import { postCreateGustoFlow, WORKERS_COMP_FLOW } from '../payrollActions'
import { useAnalyticsTrack, useAnalyticsView } from '../../Amplitude'
import { useAppDispatch } from '../../../utils/typeHelpers'

const WorkersCompPanel = ({ buttonText }: { buttonText: string }) => {
  const dispatch = useAppDispatch()
  const [link, setLink] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const pageView = useAnalyticsView()
  const track = useAnalyticsTrack()
  const screenName = 'payroll enroll wc'
  useEffect(() => {
    pageView(screenName)
  }, [pageView])

  useEffect(() => {
    const fetch = async () => {
      const res = await postCreateGustoFlow(WORKERS_COMP_FLOW)(dispatch)

      if (res) {
        setLink(res.url)
      }
    }

    fetch()
  }, [dispatch])

  const trackEnrollment = () => {
    track('payroll enroll wc clicked')
  }

  if (!link) {
    return null
  }

  return (
    <GridRowColumn>
      <Button
        onClick={() => {
          setIsOpen(true)
          trackEnrollment()
        }}
      >
        {buttonText || 'Get Started'}
      </Button>
      <Modal
        open={isOpen}
        size="fullscreen"
        closeIcon
        onClose={() => setIsOpen(false)}
      >
        <Modal.Content>
          <Embed active url={link} iframe={{ scrolling: true }} />
        </Modal.Content>
      </Modal>
    </GridRowColumn>
  )
}

export default WorkersCompPanel
