import { useEffect, useMemo } from 'react'
import { sortBy } from 'lodash'

import { useAppDispatch } from '../../../../utils/typeHelpers'
import { selectEmployees, selectPayrollByUuid } from '../../payroll.selectors'
import { fetchEmployees, fetchSinglePayroll } from '../../payrollActions'
import { generatePayrollDetails } from '../../helpers'
import { formatCurrency } from '../../../../utils/currencyHelpers'
import {
  Table,
  Text,
  Accordion,
  GridRowColumn,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'

const PayrollDetails = ({
  uuid,
  title = 'Payroll Details',
}: {
  uuid: string
  title?: string
}) => {
  const dispatch = useAppDispatch()
  const upcomingPayroll = useReselector(selectPayrollByUuid, uuid)

  const employees = useReselector(selectEmployees)

  useEffect(() => {
    dispatch(
      fetchSinglePayroll(uuid, {
        include: ['benefits', 'deductions', 'taxes'],
      })
    )
    dispatch(fetchEmployees())
  }, [dispatch, uuid])

  const details = useMemo(
    () => generatePayrollDetails(upcomingPayroll, employees),
    [employees, upcomingPayroll]
  )

  if (!upcomingPayroll || !details) {
    return null
  }

  const {
    totals: {
      employeeTaxes,
      companyTaxes,
      employeeNetPay,
      reimbursements,
      garnishments,
      grossPay,
      deductions,
      employeeBenefits,
      companyNetPay,
      companyBenefits,
      allTaxes,
    },
  } = details

  return (
    <>
      <GridRowColumn>
        <Text as="h2">{title}</Text>
      </GridRowColumn>
      <GridRowColumn>
        <Accordion
          title="What gets taxed and debited"
          content={
            <>
              <Table basic="very">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Tax Description</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      By Your Employees
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      By Your Company
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {Object.values(details.taxes || {}).map(
                    ({ name, employeeAmount, companyAmount }) => (
                      <Table.Row key={name}>
                        <Table.Cell>{name}</Table.Cell>
                        <Table.Cell textAlign="right">
                          {employeeAmount
                            ? formatCurrency(employeeAmount)
                            : 'N/A'}
                        </Table.Cell>
                        <Table.Cell textAlign="right">
                          {companyAmount
                            ? formatCurrency(companyAmount)
                            : 'N/A'}
                        </Table.Cell>
                      </Table.Row>
                    )
                  )}
                  <Table.Row>
                    <Table.Cell color="green">
                      <b>Totals</b>
                    </Table.Cell>
                    <Table.Cell textAlign="right" color="green">
                      {formatCurrency(employeeTaxes)}
                    </Table.Cell>
                    <Table.Cell textAlign="right" color="green">
                      <b>{formatCurrency(companyTaxes)}</b>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Table basic="very">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Debited by Gusto</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Total</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Direct Deposits</Table.Cell>
                    <Table.Cell textAlign="right">
                      {formatCurrency(employeeNetPay)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Reimbursements</Table.Cell>
                    <Table.Cell textAlign="right">
                      {formatCurrency(reimbursements)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Garnishments</Table.Cell>
                    <Table.Cell textAlign="right">
                      {formatCurrency(garnishments)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell color="green">
                      <b>Taxes (Employees and Employers)</b>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      {formatCurrency(allTaxes)}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </>
          }
        />
      </GridRowColumn>
      <GridRowColumn short>
        <Accordion
          title="What your employees worked and take home"
          content={
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    Employees ({upcomingPayroll.employee_compensations?.length})
                  </Table.HeaderCell>
                  <Table.HeaderCell>Payment Type</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">
                    Gross Pay
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">
                    Deductions
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">
                    Reimbursements
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">
                    Employee Taxes
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">
                    Employee Benefits
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Payment</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {sortBy(Object.values(details.employees), 'name').map(
                  ({
                    uuid,
                    name,
                    paymentType,
                    grossPay,
                    deductions,
                    reimbursements,
                    employeeTaxes,
                    employeeBenefits,
                    employeeNetPay,
                  }) => (
                    <Table.Row key={`takeHome${uuid}`}>
                      <Table.Cell>{name}</Table.Cell>
                      <Table.Cell>{paymentType}</Table.Cell>
                      <Table.Cell textAlign="right">
                        {formatCurrency(grossPay)}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {formatCurrency(deductions)}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {formatCurrency(reimbursements)}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {formatCurrency(employeeTaxes)}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {formatCurrency(employeeBenefits)}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {formatCurrency(employeeNetPay)}
                      </Table.Cell>
                    </Table.Row>
                  )
                )}
                <Table.Row>
                  <Table.Cell color="green">
                    <b>Totals</b>
                  </Table.Cell>
                  <Table.Cell />
                  <Table.Cell textAlign="right">
                    {formatCurrency(grossPay)}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {formatCurrency(deductions)}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {formatCurrency(reimbursements)}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {formatCurrency(employeeTaxes)}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {formatCurrency(employeeBenefits)}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {formatCurrency(employeeNetPay)}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          }
        />
      </GridRowColumn>

      <GridRowColumn short>
        <Accordion
          title="What your company pays"
          content={
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    Employees ({upcomingPayroll.employee_compensations?.length})
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">
                    Gross Pay
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">
                    Reimbursements
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">
                    Company Taxes
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">
                    Company Benefits
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">
                    Subtotal
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {sortBy(Object.values(details.employees), 'name').map(
                  ({
                    uuid,
                    name,
                    grossPay,
                    reimbursements,
                    companyTaxes,
                    companyBenefits,
                    companyNetPay,
                  }) => (
                    <Table.Row key={`takeHome${uuid}`}>
                      <Table.Cell>{name}</Table.Cell>
                      <Table.Cell textAlign="right">
                        {formatCurrency(grossPay)}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {formatCurrency(reimbursements)}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {formatCurrency(companyTaxes)}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {formatCurrency(companyBenefits)}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {formatCurrency(companyNetPay)}
                      </Table.Cell>
                    </Table.Row>
                  )
                )}
                <Table.Row>
                  <Table.Cell color="green">
                    <b>Totals</b>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {formatCurrency(grossPay)}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {formatCurrency(reimbursements)}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {formatCurrency(companyTaxes)}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {formatCurrency(companyBenefits)}
                  </Table.Cell>
                  <Table.Cell textAlign="right" color="green">
                    <b>{formatCurrency(companyNetPay)}</b>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          }
        />
      </GridRowColumn>
    </>
  )
}

export default PayrollDetails
