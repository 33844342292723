import { useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { DateTime } from 'luxon'

import { selectActiveReportByReportId } from '../../../selectors/reportSelectors'
import { useReselector } from '../../../utils/sharedHooks'
import { DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import { fetchBookkeepingReports } from '../../../actions/bookkeepingReportActions'
import { useAppDispatch } from '../../../utils/typeHelpers'

const BookkeepingReportReroute = () => {
  const dispatch = useAppDispatch()
  const { bookkeepingReportId } = useParams()

  const activeReport = useReselector(
    selectActiveReportByReportId,
    bookkeepingReportId
  )

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchBookkeepingReports())
    }
    fetch()
  }, [dispatch])

  if (activeReport) {
    return (
      <Navigate
        to={`/reports/bookkeeping/${DateTime.fromFormat(activeReport.date, DATE_FORMATS_LUXON.YEAR_MONTH).toFormat('MM')}/${DateTime.fromFormat(activeReport.date, DATE_FORMATS_LUXON.YEAR_MONTH).toFormat('yyyy')}/overview`}
        replace
      />
    )
  }

  return null
}

export default BookkeepingReportReroute
