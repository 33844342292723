import { ReactNode, useCallback, useMemo } from 'react'
import { Grid } from 'semantic-ui-react'
import { groupBy, orderBy } from 'lodash'
import moment from 'moment'

import { Text } from '../../../../components/BaseComponents'
import {
  updateUserActionItem,
  UserActionItem,
  UserActionItemsGroupOption,
} from '../userActionItems.slice'
import UserActionItemListItem from './UserActionItemListItem'
import UserActionItemEmptyState from './UserActionItemEmptyState'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { useAnalyticsTrack } from '../../../Amplitude'
import { useAppDispatch } from '../../../../utils/typeHelpers'

export enum ACTION_ITEM_TYPE {
  ACTIVE = 'active',
  UPCOMING = 'upcoming',
  COMPLETED = 'completed',
}

interface Props {
  userActionItems: UserActionItem[]
  tabPaneType: ACTION_ITEM_TYPE
  groupOption: UserActionItemsGroupOption
  hideCalendar?: boolean
}

const getHeaderStyle = (tabPaneType: ACTION_ITEM_TYPE, i: number) => ({
  paddingTop:
    tabPaneType === ACTION_ITEM_TYPE.ACTIVE && i === 0
      ? 16
      : tabPaneType === ACTION_ITEM_TYPE.ACTIVE && i > 0
        ? 36
        : 10,
})

const UserActionItemTabPane = ({
  tabPaneType,
  userActionItems,
  groupOption,
}: Props) => {
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()

  const markUserActionComplete = useCallback(
    (id: number, complete: boolean, description?: string) => {
      dispatch(
        updateUserActionItem(id, {
          id,
          completedAt: complete ? moment().toJSON() : null,
        })
      )
      if (complete) {
        track('completed todo', {
          completed_source: 'marked as completed',
          todo_description: description || '',
        })
      }
    },
    [dispatch, track]
  )

  const markUserActionNotApplicable = useCallback(
    (id: number, complete: boolean, description?: string) => {
      dispatch(
        updateUserActionItem(id, {
          id,
          completedAt: complete ? moment().toJSON() : null,
          markedNotApplicableAt: complete ? moment().toJSON() : null,
        })
      )
      if (complete) {
        track('marked todo not applicable', {
          completed_source: 'marked as completed',
          todo_description: description || '',
        })
      }
    },
    [dispatch, track]
  )

  const dropdownOptions = useMemo(() => {
    if (tabPaneType === ACTION_ITEM_TYPE.ACTIVE) {
      return {
        completable: [
          {
            key: 'mark-complete',
            text: 'Mark as complete',
            onClick: (id: number, description?: string) =>
              markUserActionComplete(id, true, description),
          },
        ],
        notApplicable: [
          {
            key: 'mark-complete',
            text: 'Mark as complete',
            onClick: (id: number, description?: string) =>
              markUserActionComplete(id, true, description),
          },
          {
            key: 'mark-not-applicable',
            text: 'Not applicable',
            onClick: (id: number, description?: string) =>
              markUserActionNotApplicable(id, true, description),
          },
        ],
      }
    }
    if (tabPaneType === ACTION_ITEM_TYPE.COMPLETED) {
      return {
        completable: [
          {
            key: 'mark-incomplete',
            text: 'Mark as incomplete',
            onClick: (id: number) => markUserActionComplete(id, false),
          },
        ],
        notApplicable: [
          {
            key: 'mark-incomplete',
            text: 'Mark as incomplete',
            onClick: (id: number) => markUserActionComplete(id, false),
          },
          {
            key: 'mark-applicable',
            text: 'Mark applicable',
            onClick: (id: number) => markUserActionNotApplicable(id, false),
          },
        ],
      }
    }
    return { completable: [], notApplicable: [] }
  }, [tabPaneType, markUserActionComplete, markUserActionNotApplicable])

  // Sorts 'active' and 'upcoming' tabs by endsAt asc
  // Sorts 'completed' tab by completedAt desc
  const sortedActionItems = useMemo(
    () =>
      tabPaneType === ACTION_ITEM_TYPE.COMPLETED
        ? orderBy(
            userActionItems,
            (i) => (i.completedAt ? new Date(i.completedAt) : new Date()),
            'desc'
          )
        : orderBy(
            userActionItems,
            (i) =>
              i.actionItem.endsAt ? new Date(i.actionItem.endsAt) : new Date(),
            'asc'
          ),
    [tabPaneType, userActionItems]
  )

  const renderGroupedActionItems = useCallback(() => {
    // 1. Sort userActionItems
    // 2. Group userActionItems
    // 3. Conditionally append items with null endsAt (active and upcoming tabs only)
    const els: ReactNode[] = []

    if (groupOption === 'date-asc-groupedby-categoryName') {
      // 1
      const groupedByCategory = groupBy(
        sortedActionItems,
        (i) => i.actionItem.actionItemCategory?.title
      )
      let headerName = ''

      Object.keys(groupedByCategory).forEach((categoryName, i) => {
        const userActionItems = groupedByCategory[categoryName]
        if (headerName !== categoryName) {
          headerName = categoryName
          els.push(
            <Text
              style={getHeaderStyle(tabPaneType, i)}
              as="eyebrow"
              key={headerName}
            >
              {headerName}
            </Text>
          )
        }

        userActionItems?.forEach((userActionItem) => {
          els.push(
            <UserActionItemListItem
              dropdownOptions={
                userActionItem.actionItem.canMarkNotApplicable
                  ? dropdownOptions.notApplicable
                  : dropdownOptions.completable
              }
              key={`userActionItem-todo-${userActionItem.id}`}
              userActionItem={userActionItem}
              selectedTabPane={tabPaneType}
            />
          )
        })
      })

      // 2 – Note, we do not need to filter-out null values when grouping by category
      // Because all user action items will have a category
    } else {
      // Filter non-null from null action items
      const dateItems =
        userActionItems?.filter((i) =>
          tabPaneType === ACTION_ITEM_TYPE.COMPLETED
            ? i.completedAt
            : i.actionItem.endsAt
        ) || []
      const nullDateItems =
        userActionItems?.filter((i) =>
          tabPaneType === ACTION_ITEM_TYPE.COMPLETED
            ? !i.completedAt
            : !i.actionItem.endsAt
        ) || []

      // 3
      const groupedByDate = groupBy(dateItems, (i) =>
        moment
          .utc(
            tabPaneType === ACTION_ITEM_TYPE.COMPLETED
              ? i.completedAt
              : i.actionItem.endsAt
          )
          .format(DATE_FORMATS.MONTH_YEAR)
      )
      let headerName = ''
      let i = 0
      for (const monthName of Object.keys(groupedByDate)) {
        const userActionItems = groupedByDate[monthName]
        if (headerName !== monthName) {
          headerName = monthName
          els.push(
            <Text
              style={getHeaderStyle(tabPaneType, i)}
              as="eyebrow"
              key={headerName}
            >
              {headerName}
            </Text>
          )
        }

        userActionItems?.forEach((userActionItem) => {
          els.push(
            <UserActionItemListItem
              dropdownOptions={
                userActionItem.actionItem.canMarkNotApplicable
                  ? dropdownOptions.notApplicable
                  : dropdownOptions.completable
              }
              key={`userActionItem-todo-${userActionItem.id}`}
              userActionItem={userActionItem}
              selectedTabPane={tabPaneType}
            />
          )
        })
        i++
      }

      //4 - Now we can append null items to the end of the list
      headerName = ''
      nullDateItems.forEach((item, i) => {
        if (!headerName) {
          headerName = 'No Due Date'
          els.push(
            <Text
              style={getHeaderStyle(tabPaneType, i)}
              as="eyebrow"
              key={headerName}
            >
              {headerName}
            </Text>
          )
        }

        userActionItems?.forEach((userActionItem) => {
          els.push(
            <UserActionItemListItem
              dropdownOptions={
                userActionItem.actionItem.canMarkNotApplicable
                  ? dropdownOptions.notApplicable
                  : dropdownOptions.completable
              }
              key={`userActionItem-todo-${userActionItem.id}`}
              userActionItem={userActionItem}
              selectedTabPane={tabPaneType}
            />
          )
        })
      })
    }

    return els
  }, [
    tabPaneType,
    dropdownOptions,
    groupOption,
    sortedActionItems,
    userActionItems,
  ])

  if (!userActionItems.length) {
    return <UserActionItemEmptyState tabPaneType={tabPaneType} />
  }

  return (
    <Grid>
      {tabPaneType === ACTION_ITEM_TYPE.UPCOMING && (
        <Grid.Row style={{ marginTop: 10 }}>
          <Grid.Column>
            <Text>
              Here are your upcoming tasks for the next{' '}
              <Text style={{ display: 'inline' }} as="h3">
                6 months.
              </Text>
            </Text>
          </Grid.Column>
        </Grid.Row>
      )}
      {tabPaneType === ACTION_ITEM_TYPE.COMPLETED && (
        <Grid.Row style={{ marginTop: 10 }}>
          <Grid.Column>
            <Text>
              Here are your completed tasks for the past{' '}
              <Text style={{ display: 'inline' }} as="h3">
                60 days.
              </Text>
            </Text>
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column>{renderGroupedActionItems()}</Grid.Column>
      </Grid.Row>

      {/*disabled while we add new calendar https://linear.app/heard/issue/AT-1346/add-dates-to-calendar-option-on-the-heard-home-dashboard-prompts*/}
      {/*{tabPaneType !== ACTION_ITEM_TYPE.COMPLETED && !hideCalendar && (*/}
      {/*  <Grid.Row>*/}
      {/*    <Grid.Column>*/}
      {/*      <Button*/}
      {/*        variant="link"*/}
      {/*        onClick={() => setAddToCalendarModalOpen(true)}*/}
      {/*        style={{ paddingTop: 8 }}*/}
      {/*      >*/}
      {/*        <Icon*/}
      {/*          icon={regular('calendar-alt')}*/}
      {/*          style={{ paddingRight: 10 }}*/}
      {/*        />*/}
      {/*        Add dates to Calendar*/}
      {/*      </Button>*/}

      {/*      <AddToCalendarModal*/}
      {/*        open={addToCalendarModalOpen}*/}
      {/*        onClose={() => setAddToCalendarModalOpen(false)}*/}
      {/*      />*/}
      {/*    </Grid.Column>*/}
      {/*  </Grid.Row>*/}
      {/*)}*/}
    </Grid>
  )
}

export default UserActionItemTabPane
