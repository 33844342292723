import { useCallback, useState } from 'react'
import { Grid, Message } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Button,
  FormikInput,
  GridRowColumn,
  IconButton,
  makeReqEmailSchema,
  makeReqStringSchema,
  Text,
} from '../../components/BaseComponents'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'
import {
  INVITE_KNOWN_RECIPIENT_KEY,
  inviteKnownRecipient,
  KnownRecipientResponse,
} from './referrals.slice'
import { useReselector } from '../../utils/sharedHooks'
import { getFetchError, getIsFetching } from '../../reducers/fetch'
import { useAnalyticsTrack } from '../Amplitude'
import { useAppDispatch } from '../../utils/typeHelpers'

const createErroneousMessage = (recipients: KnownRecipientResponse[]) => {
  const alreadyExistingUsers = recipients.filter(
    (r) => r.errorCode === 'rf_user_already_exists'
  )
  const errors = recipients.filter(
    (r) =>
      r.errorCode === 'heard_db_error' ||
      r.errorCode === 'sendgrid_error' ||
      r.errorCode === 'rf_user_creation_error'
  )

  const verb = alreadyExistingUsers.length > 1 ? 'have' : 'has'

  let details =
    alreadyExistingUsers.length > 0
      ? `${alreadyExistingUsers
          .map((u) => u.email)
          .join(', ')} ${verb} already been invited.`
      : ''

  if (errors.length > 0) {
    details += '\nThere was an error processing this request. Please try again'
  }

  return details
}

const ReferralEmailForm = () => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const dispatch = useAppDispatch()

  const loading = useReselector(getIsFetching, INVITE_KNOWN_RECIPIENT_KEY)
  const error = useReselector(getFetchError, INVITE_KNOWN_RECIPIENT_KEY)
  const track = useAnalyticsTrack()
  const [postResponse, setPostResponse] = useState<{
    successful: KnownRecipientResponse[]
    erroneous: KnownRecipientResponse[]
  }>()

  const formik = useFormik({
    initialValues: {
      referrals: [{ firstName: '', lastName: '', email: '' }],
    },
    validateOnMount: true,
    onSubmit: async (values) => {
      const { referrals } = values
      const res = await inviteKnownRecipient(referrals)(dispatch)

      if (res) {
        setPostResponse(res)
        track('clicked referral send email button')
        // Remove successful results from form
        for (const s of res.successful) {
          const index = referrals.findIndex((r) => r.email === s.email)
          handleRemove(index)
        }
      }
    },
  })

  const { isValid, submitForm, values, setFieldValue, validateForm } = formik

  const handleRemove = useCallback(
    (idx: number) => {
      const newReferrals = [...values.referrals]
      newReferrals.splice(idx, 1)
      setFieldValue('referrals', newReferrals).then(() => {
        // This should be automatic but doesn't seem to work for some reason
        validateForm()
      })
    },
    [setFieldValue, values.referrals, validateForm]
  )

  return (
    <Grid>
      <GridRowColumn>
        <Text as="h3">Invite by email</Text>
      </GridRowColumn>
      <FormikProvider value={formik}>
        {values.referrals.map((val, index) => (
          <Grid.Row key={`referral-${index}`} className="short">
            <Grid.Column
              computer={5}
              tablet={5}
              mobile={14}
              style={{ marginBottom: isMobile ? 10 : 0 }}
            >
              <FormikInput
                disabled={loading}
                name={`referrals[${index}].firstName`}
                required
                placeholder="Friend's first name"
                schema={makeReqStringSchema()}
                fullWidth
              />
            </Grid.Column>
            <Grid.Column
              computer={5}
              tablet={5}
              mobile={14}
              style={{ marginBottom: isMobile ? 10 : 0 }}
            >
              <FormikInput
                disabled={loading}
                name={`referrals[${index}].lastName`}
                required
                placeholder="Friend's last name"
                schema={makeReqStringSchema()}
                fullWidth
              />
            </Grid.Column>
            <Grid.Column computer={5} tablet={5} mobile={14}>
              <FormikInput
                disabled={loading}
                name={`referrals[${index}].email`}
                required
                placeholder="Friend's email"
                schema={makeReqEmailSchema()}
                fullWidth
                style={{ marginBottom: isMobile ? 10 : 0 }}
              />
            </Grid.Column>
            {values.referrals.length > 1 && (
              <Grid.Column width={1} style={{ marginTop: 20 }}>
                <IconButton
                  icon={regular('trash-can')}
                  onClick={() => handleRemove(index)}
                />
              </Grid.Column>
            )}
          </Grid.Row>
        ))}
      </FormikProvider>
      <Grid.Row className="short" verticalAlign="middle">
        <Grid.Column
          computer={12}
          tablet={12}
          mobile={16}
          style={{ display: 'flex' }}
        >
          <Button
            variant="link"
            onClick={() => {
              setFieldValue('referrals', [
                ...values.referrals,
                { firstName: '', lastName: '', email: '' },
              ]).then(() => {
                validateForm()
              })
            }}
            style={{
              marginBottom: isMobile ? 10 : 0,
              alignSelf: isMobile ? 'center' : 'flex-start',
            }}
          >
            + Add another
          </Button>
        </Grid.Column>
        <Grid.Column computer={4} tablet={4} mobile={16}>
          <Button
            disabled={!isValid || loading}
            loading={loading}
            onClick={submitForm}
            fullWidth
          >
            Send email
          </Button>
        </Grid.Column>
      </Grid.Row>
      {error && (
        <GridRowColumn>
          <Message error content="There was an error, please try again." />
        </GridRowColumn>
      )}
      {!error &&
        !loading &&
        postResponse &&
        postResponse.successful.length > 0 && (
          <Message
            style={{ width: '100%' }}
            success
            header="Success"
            content={`${postResponse.successful
              .map((s) => s.email)
              .join(', ')} ${
              postResponse.successful.length > 1 ? 'have' : 'has'
            } been invited!`}
          />
        )}
      {!error &&
        !loading &&
        postResponse &&
        postResponse.erroneous.length > 0 && (
          <Message
            style={{ width: '100%' }}
            warning
            header="Hmm could you please review"
            content={createErroneousMessage(postResponse.erroneous)}
          />
        )}
    </Grid>
  )
}

export default ReferralEmailForm
