import moment from 'moment'
import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Button, Checkbox, Divider, Form, Label, List } from 'semantic-ui-react'

import { adminFetchSingleUser } from '../../../actions/admin/adminAllUsersActions'
import CurrencyFormatLabel from '../../../components/shared/CurrencyFormatLabel'
import {
  getFinancialProfileByUserId,
  getUserNameById,
} from '../../../selectors/user.selectors'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { AnnualTaxFilingExtensionPayment } from '../../Taxes/AnnualTaxes/annualTaxFilingForms.slice'
import { ANNUAL_TAX_FILING_FORM_TYPES } from '../../Taxes/AnnualTaxes/Questionnaires/constants'
import { DatePicker } from '../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useReselector } from '../../../utils/sharedHooks'

interface Props {
  isEditing: boolean
  paymentInfo?: AnnualTaxFilingExtensionPayment[] | null
  isFormExtended?: boolean
  filingFormType: string
  filingFormId: number
  userId: number
  updateExtensionInfo?: (_params: {
    extensionPayments: AnnualTaxFilingExtensionPayment[]
    filingFormId: number
    isExtended?: boolean
  }) => Promise<void>
  closeModal: () => void
}

const ExtensionPaymentsForm: React.FC<Props> = ({
  updateExtensionInfo,
  isEditing,
  paymentInfo,
  filingFormId,
  userId,
  isFormExtended,
  filingFormType,
  closeModal,
}) => {
  const dispatch = useAppDispatch()
  const [isSaving, setIsSaving] = useState(false)
  const [isExtended, setIsExtended] = useState<boolean | undefined>(
    isFormExtended
  )
  const [federalPayment, setFederalPayment] = useState<
    AnnualTaxFilingExtensionPayment | undefined
  >()
  const [statePayments, setStatePayments] = useState<
    AnnualTaxFilingExtensionPayment[] | undefined
  >()

  useEffect(() => {
    function performAsync() {
      dispatch(adminFetchSingleUser(userId))
    }

    setFederalPayment(paymentInfo?.find((p) => p.entityType === 'federal'))
    setStatePayments(paymentInfo?.filter((p) => p.entityType === 'state'))

    performAsync()
  }, [dispatch, userId, setFederalPayment, setStatePayments, paymentInfo])

  const updateFederalPaymentValue = (value: string) => {
    setFederalPayment((prev) => ({
      ...prev,
      entityType: 'federal',
      entityName: 'federal',
      value,
    }))
  }

  const updateFederalPaymentDate = (date: string) => {
    setFederalPayment((prev) => ({
      ...prev,
      entityType: 'federal',
      entityName: 'federal',
      paidAt: date,
    }))
  }

  const resetState = () => {
    setIsSaving(false)
    setFederalPayment(undefined)
    setStatePayments(undefined)
    setIsExtended(undefined)
  }

  const onSaveClicked = async () => {
    let combinedInfo = federalPayment ? [federalPayment] : []

    if (statePayments) {
      combinedInfo = [...combinedInfo, ...statePayments]
    }

    setIsSaving(true)

    if (updateExtensionInfo) {
      await updateExtensionInfo({
        extensionPayments: combinedInfo,
        filingFormId,
        isExtended,
      })
    }

    resetState()
  }

  const updateStatePaymentValue = ({
    stateAbbr,
    value,
  }: {
    stateAbbr: string
    value: string
  }) => {
    let statePayment = statePayments?.find(
      (p) => p.entityType === 'state' && p.entityName === stateAbbr
    )

    if (!statePayment) {
      statePayment = {
        entityName: stateAbbr,
        entityType: 'state',
      }
    }

    statePayment.value = value

    const statePaymentsClone = statePayments ? [...statePayments] : []
    const index = statePaymentsClone?.indexOf(statePayment)

    if (index === -1) {
      statePaymentsClone.push(statePayment)
    } else {
      statePaymentsClone[index] = statePayment
    }

    setStatePayments(statePaymentsClone)
  }

  const updateStatePaymentDate = ({
    stateAbbr,
    date,
  }: {
    stateAbbr: string
    date: string
  }) => {
    let statePayment = statePayments?.find(
      (p) => p.entityType === 'state' && p.entityName === stateAbbr
    )

    if (!statePayment) {
      statePayment = {
        entityName: stateAbbr,
        entityType: 'state',
      }
    }

    statePayment.paidAt = date

    const statePaymentsClone = statePayments ? [...statePayments] : []
    const index = statePaymentsClone?.indexOf(statePayment)

    if (index === -1) {
      statePaymentsClone.push(statePayment)
    } else {
      statePaymentsClone[index] = statePayment
    }

    setStatePayments(statePaymentsClone)
  }

  const isForm1120_s =
    filingFormType === ANNUAL_TAX_FILING_FORM_TYPES.form_1120_s
  const today = new Date()

  const editableForm = () => (
    <Form>
      <Checkbox
        checked={isExtended}
        toggle
        label={`Is ${userFullName}'s ${filingFormType} extended?`}
        onChange={() => setIsExtended(!isExtended)}
      />
      <Divider />
      <Form.Group>
        <Form.Field>
          <label>Federal Amount Paid</label>
          <CurrencyInput
            disabled={!isExtended || isForm1120_s}
            allowNegativeValue={false}
            prefix="$"
            placeholder="Leave empty if N/A"
            decimalScale={2}
            value={federalPayment?.value}
            onValueChange={(value: string | undefined) =>
              updateFederalPaymentValue(value || '0')
            }
          />
        </Form.Field>
        <Form.Field>
          <DatePicker
            placeholder="Choose a date"
            label="Federal Date Paid"
            value={federalPayment?.paidAt || ''}
            disabled={!isExtended || isForm1120_s}
            maxDate={today}
            onChange={updateFederalPaymentDate}
          />
        </Form.Field>
      </Form.Group>

      {financialProfile?.filingStates?.map((stateAbbr) => (
        <Form.Group key={`${stateAbbr}-details`}>
          <Form.Field>
            <label>{`${stateAbbr} Amount Paid`}</label>
            <CurrencyInput
              disabled={!isExtended}
              allowNegativeValue={false}
              prefix="$"
              placeholder="Leave empty if N/A"
              decimalScale={2}
              value={
                statePayments?.find((s) => s.entityName === stateAbbr)?.value
              }
              onValueChange={(value: string | undefined) =>
                updateStatePaymentValue({ stateAbbr, value: value || '0' })
              }
            />
          </Form.Field>
          <Form.Field>
            <DatePicker
              placeholder="Choose a date"
              label={`${stateAbbr} Date Paid`}
              value={
                statePayments?.find((s) => s.entityName === stateAbbr)
                  ?.paidAt || ''
              }
              dateFormat={DATE_FORMATS.DISPLAY_SIMPLE}
              disabled={!isExtended}
              maxDate={today}
              onChange={(value) => {
                updateStatePaymentDate({ stateAbbr, date: value })
              }}
            />
          </Form.Field>
        </Form.Group>
      ))}
      <Button disabled={isSaving} onClick={closeModal} content="Cancel" />
      <Button
        disabled={isSaving}
        loading={isSaving}
        onClick={onSaveClicked}
        floated="right"
        primary
        content="Save"
      />
    </Form>
  )

  const readOnlyForm = () => (
    <List style={{ width: '250px' }}>
      <Label>Federal Payment</Label>
      <List.Item>
        <List.Content floated="right">
          {isForm1120_s ? (
            'N/A'
          ) : federalPayment?.value ? (
            <CurrencyFormatLabel value={federalPayment?.value} />
          ) : (
            'N/A'
          )}
          {!isForm1120_s && federalPayment?.paidAt
            ? ` on ${moment(federalPayment.paidAt).format(
                DATE_FORMATS.DISPLAY_SHORT
              )}`
            : ' on N/A'}
        </List.Content>
      </List.Item>

      <Label style={{ marginTop: '12px' }}>
        {financialProfile?.filingStates?.length === 1
          ? 'State Payment'
          : 'State Payments'}
      </Label>

      {financialProfile?.filingStates?.map((stateAbbr) => {
        const statePayment = statePayments?.find(
          (p) => p.entityName === stateAbbr
        )
        return (
          <List.Item key={`${stateAbbr}-payment-amount`}>
            <List.Content floated="right">
              {statePayment?.value ? (
                <CurrencyFormatLabel value={statePayment?.value} />
              ) : (
                'N/A'
              )}
              {statePayment?.paidAt
                ? ` on ${moment(statePayment.paidAt).format(
                    DATE_FORMATS.DISPLAY_SHORT
                  )}`
                : ' on N/A'}
            </List.Content>
            <List.Header>{stateAbbr}</List.Header>
          </List.Item>
        )
      })}
    </List>
  )

  const financialProfile = useReselector(getFinancialProfileByUserId, userId)
  const userFullName = useReselector(getUserNameById, userId)

  const el = isEditing ? editableForm() : readOnlyForm()

  return el
}

export default ExtensionPaymentsForm
