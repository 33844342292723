import OtpInput from 'react-otp-input'
import { Colors, Fonts } from '../../styles/theme'

const inputStyle = {
  width: 50,
  border: `1px solid ${Colors.gray}`,
  borderRadius: 8,
  color: Colors.black,
  padding: '12px 16px',
  margin: '10px 15px',
  ...Fonts.bodyMd,
}
const containerStyle = {
  justifyContent: 'center',
  padding: '30px 0 20px 0',
}
const OneTimePasscodeInput = ({
  otp,
  handleOTPChange,
}: {
  otp: string
  handleOTPChange: (val: string) => void
}) => (
  <OtpInput
    value={otp}
    onChange={handleOTPChange}
    numInputs={6}
    shouldAutoFocus
    inputStyle={inputStyle}
    containerStyle={containerStyle}
    renderInput={(props) => <input {...props} />}
  />
)

export default OneTimePasscodeInput
