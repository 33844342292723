import React, { useState } from 'react'
import { Modal, Header, Button, Form, Message } from 'semantic-ui-react'

import {
  CREATE_USER_DOCUMENT_CATEGORIES_KEY,
  createUserDocumentCategories,
  UPDATE_USER_DOCUMENT_CATEGORIES_KEY,
  updateUserDocumentCategories,
  UserDocumentCategory,
} from './userDocumentCategories.slice'
import { USER_DOCUMENT_TYPES_OPTIONS } from '../../../constants/businessConstants'
import { BUSINESS_ENTITY_OPTIONS } from '../../../constants/onboardingConstants'
import { Checkbox, Dropdown, Popup } from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { selectErrorsForKeys } from '../../../reducers/fetch'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface UserDocumentCategoryModalProps {
  open: boolean
  close: () => void
  userDocumentCategory?: UserDocumentCategory
}

const UserDocumentCategoryModal: React.FC<UserDocumentCategoryModalProps> = ({
  open,
  close,
  userDocumentCategory,
}) => {
  const dispatch = useAppDispatch()
  const [description, setDescription] = useState(
    userDocumentCategory?.description || ''
  )
  const [internalName, setInternalName] = useState(
    userDocumentCategory?.internalName || ''
  )
  const [title, setTitle] = useState(userDocumentCategory?.title || '')
  const [type, setType] = useState(userDocumentCategory?.type || null)
  const [isAnnual, setIsAnnual] = useState(
    Boolean(userDocumentCategory?.isAnnual)
  )
  const [isInternal, setIsInternal] = useState(
    Boolean(userDocumentCategory?.isInternal)
  )
  const [archivedAt, setArchivedAt] = useState(userDocumentCategory?.archivedAt)
  const [requiredFor, setRequiredFor] = useState(
    userDocumentCategory?.requiredFor || []
  )
  const errors = useReselector(selectErrorsForKeys, [
    UPDATE_USER_DOCUMENT_CATEGORIES_KEY,
    CREATE_USER_DOCUMENT_CATEGORIES_KEY,
  ])

  const resetFields = () => {
    setDescription('')
    setInternalName('')
    setTitle('')
    setType(null)
    setRequiredFor([])
  }

  const createCategory = async () => {
    const data = {
      description,
      internalName,
      title,
      type,
      isAnnual,
      isInternal,
      archivedAt: archivedAt ?? null,
      requiredFor,
    }

    let res
    if (userDocumentCategory) {
      res = await updateUserDocumentCategories(
        userDocumentCategory.id,
        data
      )(dispatch)
    } else {
      res = await createUserDocumentCategories(data)(dispatch)
    }

    if (res) {
      resetFields()
      close()
    }
  }

  const createOrUpdateText = userDocumentCategory ? 'Update' : 'Create'
  return (
    <Modal
      size={'small'}
      className="userDocumentCategoryCreationModal"
      open={open}
      onClose={close}
    >
      <Modal.Content>
        <Header as="h2">{createOrUpdateText} a User Document Category</Header>
        <p>
          {createOrUpdateText} a document category / document tag for users.
        </p>
        {errors.length > 0 && (
          <span>
            {errors.map((error) => (
              <Message key={error.message} error content={error.message} />
            ))}
          </span>
        )}
        <Form>
          <Form.Field required>
            <Form.Input
              placeholder="Internal Name"
              label="Enter internal name"
              name="internalName"
              value={internalName}
              onChange={(e, target) => setInternalName(target.value as string)}
              fluid
              required
            />
          </Form.Field>
          <Form.Field required>
            <Form.Input
              placeholder="Title"
              label="Enter User Facing Title for this action"
              name="title"
              value={title}
              onChange={(e, target) => setTitle(target.value as string)}
              fluid
              required
            />
          </Form.Field>
          <Form.Field required>
            <Form.Input
              placeholder="Description"
              label="Enter User Facing Description for this action"
              name="description"
              value={description}
              onChange={(e, target) => setDescription(target.value as string)}
              fluid
              required
            />
          </Form.Field>

          <Form.Field required>
            <Dropdown
              fullWidth
              label="Select Document Type"
              placeholder="Select a type of document"
              value={type || ''}
              onChange={setType}
              options={USER_DOCUMENT_TYPES_OPTIONS}
              required
            />
          </Form.Field>

          <Form.Field>
            <Checkbox
              label="Is this an annual document?"
              checked={isAnnual}
              onChange={setIsAnnual}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Is this an internal document?"
              checked={isInternal}
              onChange={setIsInternal}
            />
            <Popup content="Internal documents are documents that are generated by Heard. Only admins can manually upload documents with an internal document category." />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Is this category archived?"
              checked={Boolean(archivedAt)}
              onChange={(value) =>
                setArchivedAt(value ? new Date().toISOString() : null)
              }
            />
            <Popup content="Check this box if this category is no longer in use. New documents cannot be uploaded with this category, and existing documents cannot be changed to this category (for both admins and users)." />
          </Form.Field>

          <Form.Field required>
            <Dropdown
              label={
                <span className="flexbox">
                  <Form.Field>
                    <label>Select all entities this is required for</label>
                    <i>
                      Note, please only use this if it is 100% required for ALL
                      users with that entity. You can still tag documents with
                      this tag if this is empty
                    </i>
                  </Form.Field>
                </span>
              }
              placeholder="Select all that apply"
              value={requiredFor}
              onChange={setRequiredFor}
              options={BUSINESS_ENTITY_OPTIONS}
              fullWidth
              required
              multiple
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => close()}>
          Cancel
        </Button>
        <Button primary onClick={() => createCategory()}>
          {createOrUpdateText}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default UserDocumentCategoryModal
