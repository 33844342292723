import { Grid, Image, Sticky } from 'semantic-ui-react'

import {
  ProgressBar,
  Button,
  Alert,
  Text,
} from '../../components/BaseComponents'
import { SIGNUP_STEPS, SIGNUP_STEPS_2024 } from './helpers'
import { logoutUser } from '../../actions/authActions'
import { useNavigate } from 'react-router-dom'
import { useAnalyticsReset } from '../Amplitude'
import { useCallback, useState } from 'react'
import { Colors } from '../../styles/theme'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'
import {
  getFinancialProfile,
  selectIsReactivatedUser,
} from '../../selectors/user.selectors'
import { useReselector } from '../../utils/sharedHooks'
import { useAppDispatch } from '../../utils/typeHelpers'
import { selectCanOptIntoPreviousTaxYear } from '../Admin/AnnualTaxDetails/annualTaxDetails.selector'

const DesktopHeader = ({
  loggedIn,
  handleLogoutClick,
  currentStep,
  reactivating,
  hideProgress,
}: {
  loggedIn: boolean
  handleLogoutClick: () => void
  currentStep: number
  reactivating: boolean
  hideProgress?: boolean
}) => {
  const fp = useReselector(getFinancialProfile)
  const canOptIntoPreviousYearTaxes = useReselector(
    selectCanOptIntoPreviousTaxYear,
    fp?.taxEntityType
  )

  return (
    <>
      <Grid.Row style={{ paddingBottom: 0 }}>
        <Grid.Column width={14}>
          <Image
            src="https://heard-images.s3.us-west-1.amazonaws.com/heard_logo_green_2x.png"
            alt="heard logo"
            style={{ height: 20 }}
          />
        </Grid.Column>
        {loggedIn && (
          <Grid.Column width={2} floated="right">
            <Button variant="link" onClick={() => handleLogoutClick()}>
              Logout
            </Button>
          </Grid.Column>
        )}
      </Grid.Row>
      {!hideProgress && (
        <Grid.Row className="short">
          <Grid.Column>
            {canOptIntoPreviousYearTaxes ? (
              <ProgressBar
                steps={SIGNUP_STEPS_2024}
                currentStep={currentStep}
              />
            ) : (
              <ProgressBar steps={SIGNUP_STEPS} currentStep={currentStep} />
            )}
          </Grid.Column>
        </Grid.Row>
      )}
      {reactivating && (
        <Grid.Row className="short">
          <Grid.Column>
            <Alert type="info">
              <Text as="bodyLg">Welcome back to Heard!</Text>
              <Text as="bodyLg">
                To reactivate your account, please proceed through the sign up
                flow.
              </Text>
            </Alert>
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  )
}

const rootElement = document.getElementById('root')
const mobileStuckStyle = {
  backgroundColor: Colors.white,
  boxShadow: 'rgba(0, 0, 0, 0.15) 0px 4px 8px',
  paddingRight: '1rem',
  paddingLeft: '1rem',
  margin: '-1rem',
  width: '100%',
}

const MobileHeader = ({
  loggedIn,
  handleLogoutClick,
  currentStep,
  reactivating,
  hideProgress,
}: {
  loggedIn: boolean
  handleLogoutClick: () => void
  currentStep: number
  reactivating: boolean
  hideProgress?: boolean
}) => {
  const [isStuck, setIsStuck] = useState(false)
  const fp = useReselector(getFinancialProfile)
  const canOptIntoPreviousYearTaxes = useReselector(
    selectCanOptIntoPreviousTaxYear,
    fp?.taxEntityType
  )

  return (
    <>
      <Grid.Row style={{ paddingBottom: 0 }}>
        <Grid.Column width={14}>
          <Image
            src="https://heard-images.s3.us-west-1.amazonaws.com/heard_logo_green_2x.png"
            alt="heard logo"
            style={{ height: 20, float: 'left' }}
          />
          {loggedIn && (
            <Button
              style={{ float: 'right' }}
              variant="link"
              onClick={() => handleLogoutClick()}
            >
              Logout
            </Button>
          )}
        </Grid.Column>
      </Grid.Row>
      {!hideProgress && (
        <Grid.Row className="short">
          <Grid.Column style={isStuck ? { padding: 0 } : undefined}>
            <Sticky
              offset={14}
              context={rootElement}
              onStick={() => setIsStuck(true)}
              onUnstick={() => setIsStuck(false)}
              styleElement={isStuck ? mobileStuckStyle : undefined}
            >
              {canOptIntoPreviousYearTaxes ? (
                <ProgressBar
                  steps={SIGNUP_STEPS_2024}
                  currentStep={currentStep}
                />
              ) : (
                <ProgressBar steps={SIGNUP_STEPS} currentStep={currentStep} />
              )}
            </Sticky>
          </Grid.Column>
        </Grid.Row>
      )}
      {reactivating && (
        <Grid.Row className="short">
          <Grid.Column>
            <Alert type="info">
              <Text as="bodyLg">Welcome back to Heard!</Text>
              <Text as="bodyLg">
                To reactivate your account, please proceed through the sign up
                flow.
              </Text>
            </Alert>
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  )
}

const SignupHeader = ({
  currentStep,
  hideProgress,
}: {
  currentStep?: number
  hideProgress?: boolean
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const resetAnalytics = useAnalyticsReset()
  const reactivatingUser = useReselector(selectIsReactivatedUser)
  /* 
    step is 0 if on account creation page
  */
  const loggedIn = currentStep !== 0

  const handleLogoutClick = useCallback(async () => {
    resetAnalytics()
    await logoutUser()(dispatch)
    localStorage.removeItem('targetLink')
    navigate('/login')
  }, [resetAnalytics, navigate, dispatch])

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  return isMobile ? (
    <MobileHeader
      currentStep={currentStep || 0}
      loggedIn={loggedIn}
      handleLogoutClick={handleLogoutClick}
      reactivating={reactivatingUser}
      hideProgress={hideProgress}
    />
  ) : (
    <DesktopHeader
      loggedIn={loggedIn}
      handleLogoutClick={handleLogoutClick}
      currentStep={currentStep || 0}
      reactivating={reactivatingUser}
      hideProgress={hideProgress}
    />
  )
}

export default SignupHeader
