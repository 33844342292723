import { combineReducers } from 'redux'
import userReducer from './userReducer'
import securityReducer from './securityReducer'
import rolledOutInstitutionsReducer from './rolledOutInstitutionsReducer'

export default combineReducers({
  user: userReducer,
  security: securityReducer,
  rolledOutInstitutions: rolledOutInstitutionsReducer,
})
