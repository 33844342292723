import { useEffect, useState } from 'react'
import { Modal, Button, Loader, Table } from 'semantic-ui-react'
import { createTransactionRule } from '../../../actions/admin/transactionRuleActions'
import { listSmartRules, RuleSuggestion } from './actions'
import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import {
  TransactionRuleCategoryType,
  TransactionRuleType,
  TransactionRuleUse,
} from '../../../reducers/admin/transactionRulesReducer'
import { getAllTransactionCategories } from '../../Reports/reports.selectors'
import { useReselector } from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface Props {
  open: boolean
  close: () => void
  user: UserWithAdminInfo
}

const SmartRuleCreationModal = ({ open, close, user }: Props) => {
  const [loading, setLoading] = useState(false)
  const [showTable, setShowTable] = useState(false)
  const [ruleSuggestions, setRuleSuggestions] = useState<RuleSuggestion[]>([])
  const [createdRules, setCreatedRules] = useState(false)
  const [errorCreatingRule, setErrorCreatingRule] = useState(false)

  const dispatch = useAppDispatch()

  const transactionCategories = useReselector(getAllTransactionCategories)

  const runSmartRuleCreation = async (userId: number) => {
    setLoading(true)
    const response = await listSmartRules(userId)
    if (response) {
      setRuleSuggestions(response)
    }
    setLoading(false)
    setShowTable(true)
  }

  useEffect(() => {
    if (open) {
      runSmartRuleCreation(user.id)
    }
  }, [open, user.id])

  const handleRemovePotentialRule = (index: number) => {
    setRuleSuggestions((prev) => prev.filter((rule, i) => i !== index))
  }

  const createRule = (rule: {
    userId: number | undefined
    ruleUse: TransactionRuleUse
    ruleText: string
    ruleType: TransactionRuleType
    amountInCents: string | undefined
    transactionCategoryType: TransactionRuleCategoryType | undefined | null
    transactionCategoryId: number | undefined | null
  }) => {
    return createTransactionRule(rule)(dispatch)
  }

  const createRules = async (rules: RuleSuggestion[]) => {
    const results = []
    for (const rule of rules) {
      results.push({
        userId: user.id,
        ruleUse: TransactionRuleUse.CATEGORIZATION,
        ruleText: rule.ruleText,
        ruleType: TransactionRuleType.CONTAINS_WORD,
        transactionCategoryType: rule.type,
        transactionCategoryId: rule.transactionCategoryId,
        amountInCents: '0',
      })
    }
    const response = await Promise.all(results.map((rule) => createRule(rule)))

    if (response.every(Boolean)) {
      setCreatedRules(true)
    } else {
      setErrorCreatingRule(true)
    }
    setShowTable(false)
  }

  const renderPotentialRulesTable = () => {
    return (
      <Modal.Content>
        {ruleSuggestions.length === 0 && <p>No suggested rules</p>}
        {ruleSuggestions.length !== 0 && (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Rule Text</Table.HeaderCell>
                <Table.HeaderCell>Rule Type</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Category</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {ruleSuggestions.map((rule, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>{rule.ruleText}</Table.Cell>
                    <Table.Cell>{rule.ruleType}</Table.Cell>
                    <Table.Cell>{rule.type}</Table.Cell>
                    <Table.Cell>
                      {!rule.transactionCategoryId && 'n/a'}
                      {rule.transactionCategoryId &&
                        `${rule.transactionCategoryId}: ${
                          transactionCategories[rule.transactionCategoryId]
                            ?.name
                        }`}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        basic
                        primary
                        onClick={() => handleRemovePotentialRule(i)}
                      >
                        remove
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        )}
        <Modal.Actions
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button basic primary onClick={() => close()}>
            Cancel
          </Button>
          {ruleSuggestions.length !== 0 && (
            <Button
              basic
              primary
              disabled={loading}
              onClick={() => createRules(ruleSuggestions)}
            >
              Create rules
            </Button>
          )}
        </Modal.Actions>
      </Modal.Content>
    )
  }

  const renderSuccessfulMessage = () => (
    <>
      <Modal.Header as="h4">Rules successfully created!</Modal.Header>

      <Modal.Actions
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button basic primary onClick={() => close()}>
          Close
        </Button>
      </Modal.Actions>
    </>
  )

  const renderErrorMessage = () => (
    <>
      <Modal.Header as="h4">
        There was an error creating transaction rules.
      </Modal.Header>
      <Modal.Actions
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button basic primary onClick={() => close()}>
          Close
        </Button>
      </Modal.Actions>
    </>
  )

  return loading ? (
    <Loader />
  ) : (
    <Modal
      className="smartRuleCreationModal"
      size="small"
      dimmer="inverted"
      open={open}
      onClose={close}
    >
      <Modal.Header as="h4">
        Smart Rule Creation for {user.firstName} {user.lastName}
      </Modal.Header>
      {showTable && renderPotentialRulesTable()}
      {createdRules && !errorCreatingRule && renderSuccessfulMessage()}
      {errorCreatingRule && !createdRules && renderErrorMessage()}
    </Modal>
  )
}

export default SmartRuleCreationModal
