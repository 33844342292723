import { useState } from 'react'
import TextArea from '../components/BaseComponents/TextArea'

export const DefaultTextArea = () => {
  const [value, setValue] = useState('')

  return (
    <TextArea
      onChange={setValue}
      placeholder="Type something..."
      value={value}
    />
  )
}

export const LabeledTextArea = () => {
  const [value, setValue] = useState('')

  return (
    <TextArea
      label="Label"
      onChange={setValue}
      placeholder="Type something..."
      value={value}
    />
  )
}

export const DescriptiveTextArea = () => {
  const [value, setValue] = useState('')

  return (
    <TextArea
      description="A description here"
      label="Label"
      onChange={setValue}
      placeholder="Type something..."
      value={value}
    />
  )
}

export const ErrorTextArea = () => {
  const [value, setValue] = useState('')

  return (
    <TextArea
      description="A description here"
      error="There was a problem"
      label="Label"
      onChange={setValue}
      placeholder="Type something..."
      value={value}
    />
  )
}

export const DisabledTextArea = () => {
  const [value, setValue] = useState('')

  return (
    <TextArea
      description="A description here"
      disabled
      label="Label"
      onChange={setValue}
      placeholder="Type something..."
      value={value}
    />
  )
}

export const IconTextArea = () => {
  const [value, setValue] = useState('')

  return (
    <TextArea
      icon="calendar outline"
      onChange={setValue}
      placeholder="Type something..."
      value={value}
    />
  )
}
