import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Divider } from 'semantic-ui-react'
import moment from 'moment'
import { FormikProvider, useFormik } from 'formik'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { POST_CREATE_EMPLOYEE_KEY, postCreateEmployee } from '../payrollActions'
import { GEP_PER_PERSON_PRICE, getMinDateOfBirth } from '../helpers'
import PayrollError from '../PayrollError'
import {
  makeDateSchema,
  makeReqStringSchema,
  Card,
  Modal,
  Button,
  FormikInput,
  Text,
  Link,
  FormikDateInput,
  Alert,
  GridRowColumn,
  getFieldName,
  Icon,
  makeReqEmailSchema,
} from '../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useAppDispatch } from '../../../utils/typeHelpers'

const VAL_END = 'for this employee'

const CreateEmployee = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [modalOpen, setModalOpen] = useState(false)

  const maxDateOfBirth = getMinDateOfBirth()

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      dob: '',
      email: '',
    },
    // This is a little unusual but the modal does the submission portion.  It won't open unless validation succeeds
    onSubmit: () => setModalOpen(true),
  })

  const onSave = useCallback(async () => {
    const employee = await postCreateEmployee({
      first_name: formik.values.firstName,
      last_name: formik.values.lastName,
      date_of_birth: moment(formik.values.dob).format(
        DATE_FORMATS.GUSTO_SUBMIT
      ),
      email: formik.values.email,
      self_onboarding: true,
    })(dispatch)

    if (employee) {
      navigate(`/payroll/create_employee/job/${employee.uuid}`)
    }
  }, [
    dispatch,
    formik.values.dob,
    formik.values.email,
    formik.values.firstName,
    formik.values.lastName,
    navigate,
  ])

  return (
    <Card>
      <FormikProvider value={formik}>
        <Grid>
          <GridRowColumn>
            <Text as="h1">Add a new team member</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Alert>
              Please enter basic information about your new team member. They
              will receive an email with instructions to complete their
              onboarding.
            </Alert>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="h2">Enter Employee Information</Text>
          </GridRowColumn>
          <Divider />
          <GridRowColumn>
            <Text>
              Prior to an employee’s first day of work, employers are legally
              required to verify their eligibility to work using{' '}
              <Link
                href="https://www.uscis.gov/sites/default/files/document/forms/i-9-paper-version.pdf"
                newPage
              >
                Form I-9
              </Link>
              . For more information, click{' '}
              <Link href="https://www.uscis.gov/i-9" newPage>
                here.
              </Link>
            </Text>
          </GridRowColumn>
          <Grid.Row>
            <Grid.Column computer={4} tablet={6} mobile={7}>
              <FormikInput
                name={getFieldName<typeof formik.values>('firstName')}
                label="First name"
                description="Their official, legal name."
                required
                schema={makeReqStringSchema({
                  field: 'first name',
                  end: VAL_END,
                })}
              />
            </Grid.Column>
            <Grid.Column computer={4} tablet={6} mobile={7}>
              <FormikInput
                name={getFieldName<typeof formik.values>('lastName')}
                label="Last name"
                description="Their official, legal name."
                required
                schema={makeReqStringSchema({
                  field: 'last name',
                  end: VAL_END,
                })}
              />
            </Grid.Column>
          </Grid.Row>
          <GridRowColumn width={7}>
            <FormikDateInput
              name={getFieldName<typeof formik.values>('dob')}
              maxDate={maxDateOfBirth.toDate()}
              label="Date of Birth"
              required
              schema={makeDateSchema({
                field: 'date of birth',
                end: VAL_END,
                maxDate: maxDateOfBirth.format(DATE_FORMATS.INPUT),
              })}
            />
          </GridRowColumn>
          <GridRowColumn width={7}>
            <FormikInput
              name={getFieldName<typeof formik.values>('email')}
              type="email"
              label="Email"
              required
              fullWidth
              schema={makeReqEmailSchema({ field: 'email', end: VAL_END })}
            />
          </GridRowColumn>
          <GridRowColumn short>
            <Text>
              This employee will be invited to enter their own details in Gusto.
            </Text>
          </GridRowColumn>
          <Grid.Row />
          <Grid.Row>
            <Grid.Column width={3}>
              <Button
                fullWidth
                variant="secondary"
                onClick={() => navigate('/payroll/employees')}
              >
                Cancel
              </Button>
            </Grid.Column>
            <Grid.Column width={9} />
            <Grid.Column width={4}>
              <Button onClick={formik.submitForm} fullWidth>
                Add Employee
                <Icon icon={regular('arrow-right')} style={{ marginLeft: 8 }} />
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row />
        </Grid>
      </FormikProvider>
      <Modal
        open={modalOpen}
        size="tiny"
        closeIcon
        onClose={() => setModalOpen(false)}
      >
        <Modal.Header>
          Confirm New Employee: {formik.values.firstName}{' '}
          {formik.values.lastName}
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <GridRowColumn short>
              <Text>
                You are about to add a new member to your team! Just confirming
                a couple of details.
              </Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Text>
                Once confirmed, your new employee will be asked to fill out a
                form to complete the setup process.
              </Text>
            </GridRowColumn>
            <PayrollError fetchKey={POST_CREATE_EMPLOYEE_KEY} />
            <GridRowColumn>
              <Text>Full Legal Name</Text>
              <Text>
                {formik.values.firstName} {formik.values.lastName}
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <div className="price-increase-wrapper">
                <Text color="green" style={{ marginBottom: 3 }}>
                  <b>Price increase</b>
                </Text>
                <Text color="green">
                  Your Heard subscription will increase by{' '}
                  <b>${GEP_PER_PERSON_PRICE}/month.</b>
                </Text>
              </div>
            </GridRowColumn>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setModalOpen(false)} variant="secondary">
            Cancel
          </Button>
          <Button onClick={onSave}>Add Employee</Button>
        </Modal.Actions>
      </Modal>
    </Card>
  )
}

export default CreateEmployee
