import { Grid } from 'semantic-ui-react'
import {
  Alert,
  Button,
  Checkbox,
  GridRowColumn,
  Modal,
  Text,
} from '../../../../components/BaseComponents'
import {
  SUBMIT_TAX_CHECKLIST_KEY,
  submitTaxChecklist,
} from './taxChecklistQuestion.actions'
import {
  getFetchError,
  getIsFetching,
  invalidateFetch,
} from '../../../../reducers/fetch'
import { useReselector } from '../../../../utils/sharedHooks'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { selectAnnualTaxFilingFormById } from '../annualTaxFilingForms.selector'
import { TAX_ENTITY_TYPES } from '../../taxConstants'
import { markUserActionItemCompleteIfExists } from '../../../Dashboard/UserActionItems/service'
import { UserActionItemActionItemIdentifiers } from '../../../Dashboard/UserActionItems/userActionItems.slice'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { track } from '@amplitude/analytics-browser'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const ApproveAndSubmitModal = ({
  formId,
  open,
  close,
}: {
  formId: number
  open: boolean
  close: () => void
}) => {
  const isSubmitting = useReselector(getIsFetching, SUBMIT_TAX_CHECKLIST_KEY)
  const error = useReselector(getFetchError, SUBMIT_TAX_CHECKLIST_KEY)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const filingForm = useReselector(selectAnnualTaxFilingFormById, formId)
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const [confirmedReview, setConfirmedReview] = useState(false)
  const submit = useCallback(async () => {
    const submitted = await submitTaxChecklist(formId)(dispatch)
    if (filingForm?.formType?.name === TAX_ENTITY_TYPES.form_1040) {
      await markUserActionItemCompleteIfExists(
        UserActionItemActionItemIdentifiers.submitTaxChecklist1040({
          year: taxYear,
        }),
        (event, properties) => track(event, properties)
      )
    } else {
      await markUserActionItemCompleteIfExists(
        UserActionItemActionItemIdentifiers.submitTaxChecklist1120s({
          year: taxYear,
        }),
        (event, properties) => track(event, properties)
      )
    }
    if (submitted) {
      close()
      navigate('/taxes/annual')
    }
  }, [dispatch, formId, close, navigate, filingForm, taxYear])

  const closeAndReset = () => {
    dispatch(invalidateFetch(SUBMIT_TAX_CHECKLIST_KEY))
    setConfirmedReview(false)
    close()
  }

  return (
    <Modal size="tiny" open={open} onClose={closeAndReset} closeIcon>
      <Modal.Header>Approve and Submit</Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox onClick={() => setConfirmedReview(!confirmedReview)} />
            </Grid.Column>
            <Grid.Column width={15}>
              <Text>
                I have reviewed all of my books and answers for accuracy, and I
                am ready to send all documents and information to my tax
                preparer. I understand that I cannot edit the Tax Checklist
                after submission.
              </Text>
            </Grid.Column>
          </Grid.Row>
          {error && (
            <GridRowColumn>
              <Alert type="error">
                {error.message ?? 'Something went wrong!'}
              </Alert>
            </GridRowColumn>
          )}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="actionLink"
          onClick={closeAndReset}
          style={{ marginRight: 32 }}
        >
          Cancel
        </Button>
        <Button
          onClick={submit}
          disabled={isSubmitting || !confirmedReview}
          loading={isSubmitting}
        >
          Approve
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ApproveAndSubmitModal
