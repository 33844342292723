import React, { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'
import { TaxCalculationViewModel } from '../userTaxCalculation.slice'
import '../style.scss'
import { fetchAllQuarterlyTaxEstimateDetailsIfNeeded } from '../../QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { selectActiveQuarterlyTaxEstimateDetails } from '../../QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { useReselector } from '../../../../utils/sharedHooks'

interface Props {
  onDateChange: (_params: {
    selectedQuarter: string
    selectedYear: string
  }) => void
  viewModel: TaxCalculationViewModel
}

// Inspects a viewModel and renders calendarYear and calendarQuarter buttons
// When a button is clicked, fire the parent's callback
const CalculationDateToolbar: React.FC<Props> = ({
  onDateChange,
  viewModel,
}) => {
  const calendarYears = Object.keys(viewModel)
  const [selectedYear, setSelectedYear] = useState('')
  const [selectedQuarter, setSelectedQuarter] = useState('')
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchAllQuarterlyTaxEstimateDetailsIfNeeded())
  }, [dispatch])

  const activeQuarterlyTaxEstimateDetails = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )

  const calendarQuarters = selectedYear
    ? Object.keys(viewModel[selectedYear])
    : null

  // Set default values to current year and current quarter
  useEffect(() => {
    // if a year hasn't been selected, we want to default to show QTEs for the current tax year
    if (
      !selectedYear &&
      // ensure that we have QTE details we can use to determine the current tax year
      activeQuarterlyTaxEstimateDetails &&
      // ensure that we have tax calculations for the current year
      calendarYears.includes(activeQuarterlyTaxEstimateDetails.taxYear)
    ) {
      setSelectedYear(activeQuarterlyTaxEstimateDetails.taxYear)
    }

    // if a quarter has not been selected set defaults
    if (!selectedQuarter && calendarQuarters && calendarQuarters.length > 0) {
      // if the tax year was not chosen by the user, default to the current quarter
      if (
        activeQuarterlyTaxEstimateDetails &&
        selectedYear === activeQuarterlyTaxEstimateDetails.taxYear
      ) {
        setSelectedQuarter(activeQuarterlyTaxEstimateDetails.taxQuarter)
        // if the user chooses to view a different year, default to the first quarter of that year
      } else {
        setSelectedQuarter(calendarQuarters[0])
      }
    }
  }, [
    calendarQuarters,
    calendarYears,
    setSelectedYear,
    activeQuarterlyTaxEstimateDetails,
    viewModel,
    selectedQuarter,
    selectedYear,
  ])

  useEffect(() => {
    if (selectedQuarter && selectedYear) {
      onDateChange({ selectedQuarter, selectedYear })
    }
  }, [selectedQuarter, selectedYear, onDateChange])

  return (
    <>
      {calendarYears.length === 0 && <p>No Calendar Years to display</p>}
      {calendarYears.length > 0 && (
        <div>
          <Button.Group>
            {calendarYears.map((y) => (
              <Button
                active={selectedYear === y}
                content={`${y} Calendar Year`}
                key={`button-${y}`}
                onClick={() => {
                  setSelectedQuarter('')
                  setSelectedYear(y)
                }}
              />
            ))}
          </Button.Group>
        </div>
      )}
      {calendarQuarters && calendarQuarters.length > 0 && (
        <div>
          <Button.Group>
            {calendarQuarters.map((q) => (
              <Button
                active={selectedQuarter === q}
                content={q}
                key={`button-${q}`}
                onClick={() => setSelectedQuarter(q)}
              />
            ))}
          </Button.Group>
        </div>
      )}
    </>
  )
}

export default CalculationDateToolbar
