import { useEffect, useMemo, useState } from 'react'
import { Icon } from 'semantic-ui-react'
import { sortBy } from 'lodash'
import moment from 'moment'

import '../runPayroll.scss'

import { fetchContractorPayments } from '../../payrollActions'
import {
  selectContractorByUuid,
  selectContractors,
} from '../../payroll.selectors'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { getContractorName } from '../../helpers'
import { formatCurrency } from '../../../../utils/currencyHelpers'
import { Table } from '../../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  Gusto_ContractorPayment,
  Gusto_ContractorPaymentListResponse,
} from '../../generated_gusto_types'

const LastContractorPayments = ({
  contractorUuid,
}: {
  contractorUuid?: string
}) => {
  const dispatch = useAppDispatch()
  const [paymentsByContractor, setPaymentsByContractor] = useState<
    Gusto_ContractorPaymentListResponse['contractor_payments']
  >([])
  const contractors = useReselector(selectContractors)
  const contractor = useReselector(selectContractorByUuid, contractorUuid)

  const payments = useMemo(() => {
    if (!contractorUuid) {
      const combinedPayments = paymentsByContractor.reduce<
        Gusto_ContractorPayment[]
      >((oldVal, val) => [...oldVal, ...val.payments], [])

      return sortBy(combinedPayments, 'date').slice(0, 5)
    } else {
      const payments =
        paymentsByContractor.find(
          (val) => val.contractor_uuid === contractorUuid
        )?.payments || []

      return sortBy(payments, 'date').slice(0, 5)
    }
  }, [contractorUuid, paymentsByContractor])

  useEffect(() => {
    const fetch = async () => {
      // Add start and end date here to get back all of the contractor payments
      const res = await fetchContractorPayments({
        start_date: '2020-01-01',
        end_date: moment().add(1, 'month').format(DATE_FORMATS.GUSTO_SUBMIT),
        contractor_uuid: contractorUuid,
      })(dispatch)

      if (res) {
        setPaymentsByContractor(res.contractor_payments)
      }
    }

    fetch()
  }, [contractorUuid, dispatch])

  if (!payments.length) {
    return null
  }

  const title =
    contractorUuid && contractor
      ? `Your recent payments to ${getContractorName(contractor)}`
      : `Your last ${payments.length} contractor payment${
          payments.length > 1 ? 's' : ''
        }`

  return (
    <Table className="last-payments-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell color="green">
            <Icon name="calendar outline" />
            <b>{title}</b>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {payments.map((payment) => {
          const contractor = contractors[payment.contractor_uuid]

          return (
            <Table.Row key={payment.uuid}>
              <Table.Cell>
                <b className="green">{formatCurrency(payment.wage_total)}</b> to{' '}
                <b className="green">{getContractorName(contractor)}</b> on{' '}
                <b className="green">
                  {moment(payment.date).format(DATE_FORMATS.DISPLAY_SHORT)}
                </b>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default LastContractorPayments
