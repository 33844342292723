import { useEffect, Fragment } from 'react'
import { Grid, Divider } from 'semantic-ui-react'
import { GridRowColumn, Text } from '../../../components/BaseComponents'
import { JournalEntryTransactionRow } from './JournalEntryTransactionRow'
import { fetchUserFinancialAccounts } from '../../../actions/admin/adminFinancialAccountActions'
import { fetchTransactionCategoriesIfNeeded } from '../../Reports/reports.slice'
import { JournalEntryTransaction, JournalEntry } from '../types'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface JournalEntryTransactionsProps {
  userId: number
  transactions: JournalEntryTransaction[]
  entriesWithoutTransactions: JournalEntry[]
  dateRange: [string, string]
}

export const JournalEntryTransactions = ({
  userId,
  transactions,
  entriesWithoutTransactions,
  dateRange,
}: JournalEntryTransactionsProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchUserFinancialAccounts(userId))
    dispatch(fetchTransactionCategoriesIfNeeded())
  }, [dispatch, userId])

  if (transactions.length === 0 && entriesWithoutTransactions.length === 0) {
    return (
      <>
        <Text> No journal entries match these filters. </Text>
        <br />
        <Divider />
      </>
    )
  }

  return (
    <Grid style={{ marginTop: 16 }}>
      {transactions.length > 0 && (
        <>
          {transactions.map((jeTransaction) => (
            <Fragment key={jeTransaction.id}>
              <JournalEntryTransactionRow
                userId={userId}
                transaction={jeTransaction}
                dateRange={dateRange}
              />
              <GridRowColumn short>
                <Divider />
              </GridRowColumn>
            </Fragment>
          ))}
        </>
      )}
      {entriesWithoutTransactions.length > 0 && (
        <>
          <JournalEntryTransactionRow
            userId={userId}
            transaction={{
              journalEntries: entriesWithoutTransactions,
              type: 'without_transaction',
            }}
            dateRange={dateRange}
          />
          <GridRowColumn short>
            <Divider />
          </GridRowColumn>
        </>
      )}
    </Grid>
  )
}
