import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Grid, Divider } from 'semantic-ui-react'
import { useFormik, FormikProvider } from 'formik'
import * as yup from 'yup'

import {
  postPayrollProfile,
  FETCH_PAYROLL_PROFILE_KEY,
  fetchPayrollProfileIfNeeded,
  POST_PAYROLL_PROFILE_KEY,
} from '../payrollActions'
import { selectPayrollProfile } from '../payroll.selectors'
import { getCurrentUser } from '../../../selectors/user.selectors'
import {
  getIsFetching,
  getIsFetchingOrNotStarted,
} from '../../../reducers/fetch'
import PayrollSetupList from './PayrollSetupList'
import PrefilledNoticeContainer from './PrefilledNoticeContainer'
import { GEP_ENROLL_PATHS } from '../helpers'
import PayrollError from '../PayrollError'
import {
  Button,
  Card,
  FormikInput,
  GridRowColumn,
  Link,
  makeReqStringSchema,
  Text,
  Loader,
  makeReqPhoneNumberSchema,
  parseNationalPhoneNumber,
  makeReqEmailSchema,
} from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'

const validationSchema = yup.object({
  first_name: makeReqStringSchema({ field: 'first name' }),
  last_name: makeReqStringSchema({ field: 'last name' }),
  phone: makeReqPhoneNumberSchema(),
  email: makeReqEmailSchema({ field: 'email' }),
  company_name: makeReqStringSchema({ field: 'business name' }),
})

const CompanyForm = ({ goToNextStep }: { goToNextStep: () => void }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = useReselector(getCurrentUser)
  const isPosting = useReselector(getIsFetching, POST_PAYROLL_PROFILE_KEY)

  const formik = useFormik({
    initialValues: {
      first_name: user?.firstName || '',
      last_name: user?.lastName || '',
      email: user?.email || '',
      phone: user?.phoneNumber || '',
      company_name: user?.financialProfile?.businessName || '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const res = await postPayrollProfile({
        user: {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          phone: parseNationalPhoneNumber(values.phone) || null,
        },
        company: { name: values.company_name },
      })(dispatch)

      if (res) {
        goToNextStep()
      }
    },
  })

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn>
          <Text as="h2">Company Setup</Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text as="bodyLg">Please confirm the following details.</Text>
        </GridRowColumn>
        <Divider />
        <Grid.Row>
          <Grid.Column width={8}>
            <FormikInput
              name="first_name"
              label="First name"
              description="Your legal name."
              required
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <FormikInput
              name="last_name"
              label="Last Name"
              description="Your legal name."
              required
            />
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn width={10}>
          <FormikInput name="email" label="Email Address" required fullWidth />
        </GridRowColumn>
        <GridRowColumn width={10}>
          <FormikInput
            name="phone"
            label="Phone"
            required
            fullWidth
            componentType="phone"
          />
        </GridRowColumn>
        <GridRowColumn>
          <FormikInput
            name="company_name"
            label="Company Name"
            description={
              <>
                Make sure this is your legal name, not your DBA.{' '}
                <Link
                  href="https://support.joinheard.com/hc/en-us/articles/7257775812887"
                  newPage
                  style={{ display: 'inline' }}
                >
                  <b>
                    <u>Where can I find this?</u>
                  </b>
                </Link>
              </>
            }
            required
            fullWidth
          />
        </GridRowColumn>
        <Grid.Row />
        <Grid.Row />
        <Grid.Row>
          <Grid.Column width={6}>
            <Button
              variant="secondary"
              onClick={() => navigate(GEP_ENROLL_PATHS.overview)}
              fullWidth
            >
              Cancel Enrollment
            </Button>
          </Grid.Column>
          <Grid.Column width={4} />
          <Grid.Column width={6}>
            <Button onClick={formik.submitForm} fullWidth loading={isPosting}>
              Save & Continue
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </FormikProvider>
  )
}

const PayrollCompanySetup = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const payrollProfile = useReselector(selectPayrollProfile)
  const isLoading = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_PAYROLL_PROFILE_KEY
  )

  const goToNextStep = useCallback(() => {
    navigate(GEP_ENROLL_PATHS.federal)
  }, [navigate])

  useEffect(() => {
    dispatch(fetchPayrollProfileIfNeeded())
  }, [dispatch])

  // This step cannot be redone or edited so make sure this form is only submitted once
  return (
    <Container>
      <Card>
        <Grid>
          <GridRowColumn>
            <Text as="h1">Setting Up Payroll</Text>
          </GridRowColumn>
          <Divider />
          <PrefilledNoticeContainer />
          <GridRowColumn>
            <Loader fetchKey={FETCH_PAYROLL_PROFILE_KEY} />
          </GridRowColumn>
          <PayrollError fetchKey={POST_PAYROLL_PROFILE_KEY} />
          {!isLoading && (
            <Grid.Row>
              {payrollProfile ? (
                <Grid.Column width={9}>
                  <Grid>
                    <GridRowColumn>
                      <Text as="h3">Company Created</Text>
                    </GridRowColumn>
                    <GridRowColumn>
                      <Card backgroundColor="lightGreen">
                        <Text as="bodyLg">
                          Company details successfully added. You may continue.
                        </Text>
                      </Card>
                    </GridRowColumn>
                    <GridRowColumn spacer={10}>
                      <Button fullWidth onClick={goToNextStep}>
                        Continue
                      </Button>
                    </GridRowColumn>
                  </Grid>
                </Grid.Column>
              ) : (
                <Grid.Column width={9}>
                  <CompanyForm goToNextStep={goToNextStep} />
                </Grid.Column>
              )}
              <Grid.Column width={7}>
                <PayrollSetupList />
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row />
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollCompanySetup
