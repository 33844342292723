import { Divider, Grid } from 'semantic-ui-react'
import { Button, GridRowColumn } from '../../components/BaseComponents'
import { useSearchParams } from 'react-router-dom'
import { CANCELLATION_FLOW_STEP } from './helpers'

const UserCancellationFlowFooter = ({
  stayLabel,
  continueLabel,
  disabled,
  isLoading,
  goToNextStep,
}: {
  stayLabel: string
  continueLabel?: string
  disabled?: boolean
  isLoading?: boolean
  goToNextStep: () => void
}) => {
  const [_, setSearchParams] = useSearchParams()

  return (
    <>
      <Grid.Row />
      <Divider />
      <Grid.Row />
      <GridRowColumn>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => goToNextStep()}
            variant="actionLink"
            style={{ paddingRight: '40px' }}
            disabled={disabled}
            loading={isLoading}
          >
            {continueLabel ?? 'Continue to Cancel'}
          </Button>
          <Button
            onClick={() =>
              setSearchParams({
                step: CANCELLATION_FLOW_STEP.cancellationPrevented,
              })
            }
          >
            {stayLabel}
          </Button>
        </div>
      </GridRowColumn>
    </>
  )
}

export default UserCancellationFlowFooter
