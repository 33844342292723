import { partition, sortBy } from 'lodash'
import { createSelector } from 'reselect'

import { ReduxState } from '../utils/typeHelpers'
import { getUserById } from './user.selectors'
import { BookkeepingReport } from '../reducers/finances/bookkeepingReportsReducer'
import { DateTime } from 'luxon'
import { selectActiveQuarterlyTaxEstimateDetails } from '../features/Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { DATE_FORMATS_LUXON } from '../utils/dateHelpers'

export const selectBookkeepingReports = (state: ReduxState) =>
  state.bookkeepingReports

export const selectBookkeepingReportList = createSelector(
  selectBookkeepingReports,
  (bookkeepingReports) => Object.values(bookkeepingReports)
)

export const selectBookkeepingReportsById = createSelector(
  selectBookkeepingReports,
  (_: unknown, id: number | string) => id,
  (byId, id) => (id ? byId[id] : undefined)
)

export const selectBookkeepingReportStatus = createSelector(
  selectBookkeepingReportsById,
  (report) => report?.statuses[0]?.status
)

export const selectBookkeepingReportsByUserId = createSelector(
  selectBookkeepingReportList,
  getUserById,
  (reports, user) =>
    user
      ? sortBy(
          reports.filter(
            (report) => report.financialProfileId === user.financialProfile?.id
          ),
          'date'
        )
      : ([] as BookkeepingReport[])
)

// Sorts configurable. defaults to most recent first
// null sorts to bottom when desc, top when asc
export const selectBooksSortedByReconciledDate = createSelector(
  selectBookkeepingReportList,
  (_: unknown, args: { desc?: boolean } = {}) => args,
  (reports, { desc = true }) =>
    reports.sort((a, b) => {
      if (!a.reconciledAt) {
        return desc ? 1 : -1
      }
      if (!b.reconciledAt) {
        return desc ? -1 : 1
      }
      return desc
        ? DateTime.fromISO(b.reconciledAt)
            .diff(DateTime.fromISO(a.reconciledAt))
            .toMillis()
        : DateTime.fromISO(a.reconciledAt)
            .diff(DateTime.fromISO(b.reconciledAt))
            .toMillis()
    })
)

export const selectForQTEAllBooksReconciledForRequiredMonths = createSelector(
  [selectBooksSortedByReconciledDate, selectActiveQuarterlyTaxEstimateDetails],
  (reports, activeDetails) => {
    if (!activeDetails || !reports) {
      return {
        completedBooks: [],
        allRequiredBooksAreReconciled: false,
        mostRecentBookkeepingReportReconciled: null,
      }
    }
    const reportsWithFormattedDates = reports.map((report) => ({
      ...report,
      date: DateTime.fromFormat(report.date, DATE_FORMATS_LUXON.YEAR_MONTH),
    }))
    const reportsFromThisYear = reportsWithFormattedDates.filter(
      (report) => report.date.year === Number(activeDetails.taxYear)
    )
    const { bookkeepingPeriodTotalMonths } = activeDetails
    const bookkeepingReports = partition(
      reportsFromThisYear,
      (report) =>
        report.reconciledAt && report.date.month <= bookkeepingPeriodTotalMonths
    )
    const reconciledBooks = bookkeepingReports[0]
    const unreconciledBooks = bookkeepingReports[1]
    return {
      unreconciledBooks,
      allRequiredBooksAreReconciled:
        reconciledBooks.length >= bookkeepingPeriodTotalMonths,
      mostRecentBookkeepingReportReconciled: reconciledBooks[0],
    }
  }
)
