import { useEffect, useMemo } from 'react'
import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'

import {
  Modal,
  Button,
  Text,
  FormikDropdown,
  Alert,
} from '../../BaseComponents'
import {
  ADMIN_CREATE_USER_ACTION_ITEM_KEY,
  adminCreateUserActionItem,
} from '../../../features/Dashboard/UserActionItems/userActionItems.slice'
import { useReselector } from '../../../utils/sharedHooks'
import { getFetchError } from '../../../reducers/fetch'
import {
  fetchAllTodos,
  selectTodos,
} from '../../../features/Admin/ActionItems/allActionItems.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface UserTodoCreationModalProps {
  open: boolean
  close: () => void
  userId: number
}

const UserTodoCreationModal = ({
  open,
  close,
  userId,
}: UserTodoCreationModalProps) => {
  const dispatch = useAppDispatch()
  const error = useReselector(getFetchError, ADMIN_CREATE_USER_ACTION_ITEM_KEY)

  const allTodos = useReselector(selectTodos)

  const todoOptions = useMemo(
    () =>
      allTodos.map((todo) => ({
        text: todo.description,
        value: todo.id,
      })),
    [allTodos]
  )

  useEffect(() => {
    fetchAllTodos()(dispatch)
  }, [dispatch])

  const validationSchema = yup.object({
    actionItemId: yup.number().required('You must select a todo.'),
  })

  const formik = useFormik({
    initialValues: {
      actionItemId: 0,
    },
    validationSchema,
    onSubmit: async (actionItemId: { actionItemId: number }) => {
      const res = await adminCreateUserActionItem(
        userId,
        actionItemId
      )(dispatch)
      if (res) {
        close()
      }
    },
  })

  return (
    <Modal
      size="tiny"
      className="userActionItemsCreationModal"
      open={open}
      onClose={close}
    >
      <Modal.Content>
        <Text as="h2">Assign a todo for User to complete</Text>
        <Text>
          This allows you to add a todo for the user to complete. This will show
          up on a user&#39;s Dashboard.
        </Text>
        {error && <Alert type="error">{error.message}</Alert>}
        <FormikProvider value={formik}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <FormikDropdown
                  label="Select Todo"
                  placeholder="Select Option"
                  name="actionItemId"
                  options={todoOptions}
                  fullWidth
                  required
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={6}>
                <Button variant="secondary" onClick={close} fullWidth>
                  Cancel
                </Button>
              </Grid.Column>
              <Grid.Column width={4} />
              <Grid.Column width={6}>
                <Button
                  onClick={() => formik.handleSubmit()}
                  type="submit"
                  fullWidth
                >
                  Create
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </FormikProvider>
      </Modal.Content>
    </Modal>
  )
}

export default UserTodoCreationModal
