import { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import { formatPhoneNumber } from 'react-phone-number-input'

import Button from '../../components/BaseComponents/Button'
import { Card, GridRowColumn, Text } from '../../components/BaseComponents'
import { useReselector } from '../../utils/sharedHooks'
import { ResponsiveHeader } from '../../components/Finances/Accounts/ResponsiveHeader'
import { SmsConsentModal } from './SmsConsentModal'
import {
  SmsConsentSource,
  SmsConsentStatusOption,
  fetchSmsConsentStatus,
  getSmsConsentStatus,
} from './smsConsentReducer'
import { useAppDispatch } from '../../utils/typeHelpers'
import { DATE_FORMATS_LUXON } from '../../utils/dateHelpers'
import EditSmsConsentModal from './EditSmsConsentModal'

const SmsConsent = () => {
  const dispatch = useAppDispatch()
  const [newConsentModalOpen, setNewConsentModalOpen] = useState(false)
  const [editConsentModalOpen, setEditConsentModalOpen] = useState(false)
  const smsConsentStatus = useReselector(getSmsConsentStatus)

  useEffect(() => {
    dispatch(fetchSmsConsentStatus())
  }, [dispatch])

  if (
    smsConsentStatus &&
    smsConsentStatus.status === SmsConsentStatusOption.OPT_IN
  ) {
    return (
      <>
        <ResponsiveHeader content="SMS Consent" />
        <Card type="subsection" backgroundColor="stone40">
          <Grid>
            <Grid.Row className="short">
              <Grid.Column width={14}>
                <Text as="h3">Permissions</Text>
                <Text style={{ marginTop: 10 }}>
                  You opted into receiving notifications on{' '}
                  {DateTime.fromISO(smsConsentStatus.createdAt).toFormat(
                    DATE_FORMATS_LUXON.DISPLAY_LONG
                  )}
                  .
                </Text>
              </Grid.Column>
              <Grid.Column>
                <Button
                  onClick={() => setEditConsentModalOpen(true)}
                  variant="secondaryLink"
                  floated="right"
                >
                  Edit
                </Button>
              </Grid.Column>
            </Grid.Row>
            <GridRowColumn short>
              <Text as="h3">Your Mobile Number</Text>
              <Text as="h3" style={{ marginTop: 10 }}>
                {smsConsentStatus.mobilePhoneNumber &&
                  formatPhoneNumber(smsConsentStatus.mobilePhoneNumber)}
              </Text>
            </GridRowColumn>
            <EditSmsConsentModal
              open={editConsentModalOpen}
              onClose={() => setEditConsentModalOpen(false)}
            />
          </Grid>
        </Card>
      </>
    )
  }

  return (
    <Grid>
      <GridRowColumn>
        <ResponsiveHeader content="SMS Consent" />
      </GridRowColumn>
      <GridRowColumn>
        <Text>
          Get important updates, reminders, and notifications regarding your
          practice&apos;s finances via SMS, like quarterly payment reminders.
        </Text>
      </GridRowColumn>
      <GridRowColumn short>
        <Button onClick={() => setNewConsentModalOpen(true)}>
          Get Reminders
        </Button>
      </GridRowColumn>
      <SmsConsentModal
        open={newConsentModalOpen}
        onClose={() => {
          setNewConsentModalOpen(false)
        }}
        source={SmsConsentSource.PRACTICE_PROFILE}
      />
    </Grid>
  )
}

export default SmsConsent
