import { TransactionRuleCategoryType } from '../reducers/admin/transactionRulesReducer'

export const TRANSACTION_TYPE_OPTIONS = [
  { value: TransactionRuleCategoryType.business, text: 'Business' },
  { value: TransactionRuleCategoryType.personal, text: 'Personal' },
]

// This is used to get around certain cases where all the transactions are required.  Eventually anything using this
// should be refactored
export const MAX_TRANSACTION_LIMIT = '10000'
