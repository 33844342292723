// BL
import { UserDocument } from '../../userDocuments.slice'

// UI
import { Table } from 'semantic-ui-react'
import UserDocumentActionButton from '../Buttons/UserDocumentActionButton'
import UploadRequiredDocumentButton from '../Buttons/UploadRequiredDocumentButton'

interface DocumentActionCellProps {
  document: UserDocument
}

const DocumentActionCell = ({ document }: DocumentActionCellProps) => (
  <Table.Cell
    style={{ overflow: 'visible', display: 'flex', justifyContent: 'center' }}
  >
    {/* Required, missing documents have their id set to -1 */}
    {document.id === -1 && <UploadRequiredDocumentButton document={document} />}
    {document.id !== -1 && <UserDocumentActionButton document={document} />}
  </Table.Cell>
)

export default DocumentActionCell
