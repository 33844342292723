import { Image, List } from 'semantic-ui-react'
import { Text } from '../../../components/BaseComponents'
import { CSSProperties, FC } from 'react'
import { Fonts } from '../../../styles/theme'

interface Props {
  imageSrc: string
  title: string
  subtitle: string
  comment: string
  className?: string
  style?: CSSProperties
}

const TestimonialListItem: FC<Props> = ({
  imageSrc,
  title,
  subtitle,
  comment,
  className,
  style,
}) => {
  return (
    <>
      <List.Item className={className} style={style}>
        <Image style={{ width: 48, height: 48 }} avatar src={imageSrc} />
        <List.Content>
          <List.Header>
            <Text as="h3">{title}</Text>
          </List.Header>
          <List.Description>
            <Text as="bodySm">{subtitle}</Text>
          </List.Description>
        </List.Content>
      </List.Item>
      <List.Item style={{ marginTop: 10 }}>
        <List.Content>
          <Text
            style={{ fontSize: Fonts.buttonSm.fontSize }}
            textAlign="center"
            color="darkGray"
          >
            “{comment}”
          </Text>
        </List.Content>
      </List.Item>
    </>
  )
}

export default TestimonialListItem
