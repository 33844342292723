import { UserWithAdminInfo } from '../../../reducers/admin/v2/allUsersReducerV2'
import { useCallback, useState } from 'react'
import { adminDeleteUser } from '../../../actions/admin/adminAllUsersActions'
import { Button, Form, Icon, Input, Modal } from 'semantic-ui-react'
import { UnstyledDropdown } from '../../BaseComponents/Dropdown'
import { SearchType } from '../../../actions/admin/v2/adminUsersActions'
import { useAppDispatch } from '../../../utils/typeHelpers'

export const ConfirmUserDeletion = ({
  user,
}: {
  user: Pick<UserWithAdminInfo, 'id' | 'firstName' | 'lastName' | 'email'>
}) => {
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)
  const [confirmUser, setConfirmUser] = useState<string>()
  const [deleting, setDeleting] = useState(false)

  const verifyDeletion = () => {
    return confirmUser === `${user.firstName} ${user.lastName}`
  }

  const validDelete = verifyDeletion()

  const handleDeleteUser = useCallback(async () => {
    setDeleting(true)
    const result = await adminDeleteUser(user.id)(dispatch)
    if (result) {
      setOpen(false)
      setDeleting(false)
    }
  }, [dispatch, user.id])

  return (
    <>
      <Button negative onClick={() => setOpen(true)} floated="right">
        Delete User
      </Button>
      <Modal size="small" open={open}>
        <Modal.Header>
          <Icon name="warning sign" color="yellow" />
          Are you sure you wanted to permanently delete: {user.firstName}{' '}
          {user.lastName}?
        </Modal.Header>
        <Modal.Content>
          You are permanently deleting:
          <ul>
            <li>UID {user.id}</li>
            <li>
              {user.firstName} {user.lastName}
            </li>
            <li>{user.email}</li>
          </ul>
          <Form>
            <Form.Field>
              <Form.Input
                label="To confirm deletion, type the user's full name"
                placeholder={`${user.firstName} ${user.lastName}`}
                onChange={(e, target) => setConfirmUser(target.value)}
                fluid
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            negative
            disabled={!validDelete || deleting}
            onClick={() => handleDeleteUser()}
            loading={deleting}
          >
            Delete this user
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export const FilterByBookkeeperIdDropdown = ({
  bookkeeperOptions,
  setBookkeeperId,
  bookkeeperId,
  resetPaginationAndActivePage,
}: {
  bookkeeperOptions: { key: number; text: string; value: number }[]
  setBookkeeperId: (bookkeeperId?: number) => void
  bookkeeperId?: number
  resetPaginationAndActivePage: () => void
}) => (
  <div style={{ width: '40%' }}>
    <h4>Filter By Bookkeeper</h4>
    <UnstyledDropdown
      onChange={(data) => {
        setBookkeeperId(data)
        resetPaginationAndActivePage()
      }}
      value={bookkeeperId ?? undefined}
      clearable
      search
      options={bookkeeperOptions}
      placeholder="Filter by Bookkeeper"
      selectOnBlur={false}
      fluid
    />
  </div>
)

export const SearchInput = ({
  searchType,
  setSearchType,
  onSearchSubmit,
}: {
  searchType: SearchType
  setSearchType: (searchType: SearchType) => void
  onSearchSubmit: (search: string) => void
}) => {
  const options = [
    { key: SearchType.ById, text: 'By user id', value: SearchType.ById },
    {
      key: SearchType.BySearchString,
      text: 'By name or email',
      value: SearchType.BySearchString,
    },
  ]

  const [searchString, setSearchString] = useState<string>('')

  return (
    <div style={{ width: '40%' }}>
      <h4>Search</h4>
      <Input
        type="text"
        placeholder="Search..."
        action
        fluid
        onChange={(e) => {
          setSearchString(e.target.value)
        }}
        onKeyPress={(e: { key: string }) => {
          if (e.key === 'Enter') {
            onSearchSubmit(searchString)
          }
        }}
      >
        <input />
        <UnstyledDropdown
          compact
          options={options}
          defaultValue={searchType}
          value={searchType}
          onChange={(data) => {
            setSearchType(data)
          }}
        />
        <Button
          type="submit"
          onClick={() => {
            onSearchSubmit(searchString)
          }}
        >
          Search
        </Button>
      </Input>
    </div>
  )
}
