import { useCallback, useEffect, useState } from 'react'
import Moment from 'react-moment'
import { Divider, Grid } from 'semantic-ui-react'
import {
  PieChart,
  Pie,
  Cell,
  Sector,
  ResponsiveContainer,
  Text as RechartsText,
} from 'recharts'
import { PieSectorDataItem } from 'recharts/types/polar/Pie'

import { Card, Dropdown, Link, Table, Text } from '../../BaseComponents'
import CurrencyFormatLabel from '../../shared/CurrencyFormatLabel'
import { Expense, fetchExpensesBreakdown } from '../../../actions/reportActions'
import { BRIGHT_COLORS } from '../../../constants/colorConstants'
import { YEAR_OPTIONS } from '../../../constants/businessConstants'
import {
  getAllTransactionCategories,
  getTransactionCategorySelector,
} from '../../../features/Reports/reports.selectors'
import { Fonts } from '../../../styles/theme'
import { useReselector } from '../../../utils/sharedHooks'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { selectTransactionsByCategoryAndYear } from '../../../features/Transactions/transactions.selectors'
import { useAppDispatch } from '../../../utils/typeHelpers'

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: {
  cx: number
  cy: number
  midAngle: number
  innerRadius: number
  outerRadius: number
  percent: number
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <RechartsText
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
      style={Fonts.bodyMd}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </RechartsText>
  )
}

const renderActiveShape = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
  name,
}: PieSectorDataItem) => (
  <g>
    <RechartsText
      x={cx}
      y={cy}
      dy={8}
      width={100}
      textAnchor="middle"
      verticalAnchor="middle"
      fill={fill}
      style={Fonts.bodyMd}
    >
      {name}
    </RechartsText>
    <Sector
      cx={cx}
      cy={cy}
      innerRadius={innerRadius}
      outerRadius={outerRadius}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
    <Sector
      cx={cx}
      cy={cy}
      startAngle={startAngle}
      endAngle={endAngle}
      innerRadius={outerRadius ? outerRadius + 6 : undefined}
      outerRadius={outerRadius ? outerRadius + 10 : undefined}
      fill={fill}
    />
  </g>
)

type ActivePayload = {
  percent: number
  value: number
}

const ExpensesBreakdownCard = ({
  activePayload,
  activeTransactionCategoryId,
  year,
}: {
  activePayload: ActivePayload | null
  activeTransactionCategoryId: number | null
  year: string
}) => {
  const selectedTransactions = useReselector(
    selectTransactionsByCategoryAndYear,
    activeTransactionCategoryId,
    year
  )
  const selectedCategory = useReselector(
    getTransactionCategorySelector,
    activeTransactionCategoryId
  )

  if (activeTransactionCategoryId) {
    return (
      <Card fullWidth type="section">
        <Text as="h2">Expense Type: {selectedCategory?.name}</Text>
        <Divider />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Text>
              <CurrencyFormatLabel value={activePayload?.value} />
            </Text>
            <Text as="bodyMd" style={{ marginTop: 8 }}>
              Total Dollar Spend
            </Text>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Text as="h1" color="red">
              <b>{Math.trunc((activePayload?.percent ?? 0) * 100)}%</b>
            </Text>
            <Text as="bodyMd" style={{ marginTop: 8 }}>
              Percentage of Expenses
            </Text>
          </div>
        </div>
        <br />
        <Text as="h3">Included Transactions </Text>
        <Table compact basic className="userTransactionsTable">
          <Table.Body>
            {selectedTransactions?.slice(0, 5).map((transaction) => (
              <Table.Row key={transaction.id}>
                <Table.Cell>
                  <Moment format={DATE_FORMATS.DISPLAY_SHORT} utc>
                    {transaction.date}
                  </Moment>
                </Table.Cell>
                <Table.Cell>{transaction.description}</Table.Cell>
                <Table.Cell style={{ whiteSpace: 'nowrap' }}>
                  <CurrencyFormatLabel
                    value={transaction.amountInCents / 100}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
            <Table.Row>
              <Table.Cell colSpan="2">
                <Text as="bodyMd" color="forest">
                  Viewing {selectedTransactions?.slice(0, 5).length} out of{' '}
                  {selectedTransactions?.length} transactions
                </Text>
              </Table.Cell>
              <Table.Cell textAlign="right">
                <Link
                  to={`/transactions/expenses/${activeTransactionCategoryId}?year=${year}`}
                >
                  View All
                </Link>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Card>
    )
  } else {
    return (
      <Card fullWidth type="section">
        <Text as="h3">Expense Type: None Selected</Text>
        <Divider />
        <Text as="bodyMd">
          To view a breakdown of a specific expense type, select a category from
          the pie chart to your left.
        </Text>
      </Card>
    )
  }
}

type FormattedExpense = {
  name: string
  value: number
  transactionCategoryId: number
}

const ExpensesPieChartRow = () => {
  const dispatch = useAppDispatch()

  const [data, setData] = useState<FormattedExpense[]>([])
  const [activeIndex, setActiveIndex] = useState<number>()
  const [activePayload, setActivePayload] = useState<ActivePayload | null>(null)
  const [activeTransactionCategoryId, setActiveTransactionCategoryId] =
    useState<number | null>(null)
  const [year, setYear] = useState('all')

  const categories = useReselector(getAllTransactionCategories)

  useEffect(() => {
    // Reset category when year changes
    setActiveTransactionCategoryId(null)
  }, [year])

  const formatData = useCallback(
    (results: Expense[]) => {
      const formattedResult: FormattedExpense[] = []
      results.forEach(({ sum, transactionCategoryId }) => {
        /* 
        We only want to format and show the data in the pie charge if the expense is less than 0 
        this is because there may be causes where the expenses total is actually positive (so a refund),
        in which case, does not make sense to show visually here 
      */
        const sumValue = Number(sum)
        if (sumValue < 0) {
          formattedResult.push({
            name: categories[transactionCategoryId]?.name,
            value: Math.abs(sumValue),
            transactionCategoryId,
          })
        }
      })

      return formattedResult
    },
    [categories]
  )

  useEffect(() => {
    fetchExpensesBreakdown({ year })(dispatch).then(({ expenses }) =>
      setData(formatData(expenses))
    )
  }, [year, dispatch, formatData])

  // The `data` param from recharts is a combination of various SVG properties (cx, cy, fill,
  // angle etc.) in addition to what we provide as data/payload for the values of the pie chart, so
  // this type for `data` isn't exactly the entire object type but a stripped down version for our
  // own purposes.
  //
  // If more properties are needed from the `data` param, suggestion would be to inspect the `data`
  // param (i.e. console.log and view in browser) to see what properties are supplied by recharts
  // on a MouseDown event and add to the `data` type accordingly.
  const onMouseDown = (
    data: {
      payload: { value: number }
      percent: number
      transactionCategoryId: number
    },
    index: number
  ) => {
    const { payload, percent, transactionCategoryId } = data
    const activePayload = {
      ...payload,
      percent,
    }

    setActiveIndex(index)
    setActiveTransactionCategoryId(transactionCategoryId)
    setActivePayload(activePayload)
  }

  return (
    <Grid.Row>
      <Grid.Column style={{ display: 'flex' }} width={16}>
        <Text as="h3" style={{ margin: 'auto 16px auto 0' }}>
          Breakdown for:
        </Text>
        <Dropdown
          color="forest"
          fontSize={24}
          options={YEAR_OPTIONS}
          onChange={setYear}
          value={year}
          variant="text"
        />
      </Grid.Column>
      <Grid.Column width={7}>
        <ResponsiveContainer width="99%" height={400}>
          <PieChart>
            <Pie
              data={data}
              cx={200}
              cy={200}
              isAnimationActive
              labelLine={false}
              label={renderCustomizedLabel}
              innerRadius={80}
              outerRadius={140}
              activeShape={renderActiveShape}
              activeIndex={activeIndex}
              fill="#8884d8"
              dataKey="value"
              onMouseDown={onMouseDown}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    BRIGHT_COLORS[
                      entry.transactionCategoryId % BRIGHT_COLORS.length
                    ]
                  }
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Grid.Column>
      <Grid.Column width={9}>
        <ExpensesBreakdownCard
          activePayload={activePayload}
          activeTransactionCategoryId={activeTransactionCategoryId}
          year={year}
        />
      </Grid.Column>
    </Grid.Row>
  )
}

export default ExpensesPieChartRow
