import axios from 'axios'
import { createSelector } from 'reselect'
import { keyBy } from 'lodash'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { fetchIfNeededWrapper, fetchWrapper } from '../../../reducers/fetch'
import { ReduxState } from '../../../utils/typeHelpers'
import { selectFilingFormsForYear } from './annualTaxFilingForms.selector'
import { TaxfyleJobStatus, TaxfyleJobStep } from './annualTaxFilingForms.slice'

export interface AnnualTaxOutcome {
  id: number
  isFederal: boolean
  state: string | null
  amountInCents: number | null
  isRefund: boolean | null
  filingFormId: number
  receiptDocumentId: number | null
  createdAt: string
  updatedAt: string
}

export interface AnnualTaxOutcomeState {
  [key: string]: AnnualTaxOutcome
}

const annualTaxOutcomeSlice = createSlice({
  name: 'annualTaxOutcome',
  initialState: {} as AnnualTaxOutcomeState,
  reducers: {
    receiveAnnualTaxOutcomes: (
      state,
      action: PayloadAction<AnnualTaxOutcomeState>
    ) => ({ ...state, ...action.payload }),
    receiveAnnualTaxSingleOutcome: (
      state,
      action: PayloadAction<AnnualTaxOutcome>
    ) => {
      state[action.payload.id] = action.payload
    },
  },
})

export default annualTaxOutcomeSlice.reducer

export const { receiveAnnualTaxSingleOutcome, receiveAnnualTaxOutcomes } =
  annualTaxOutcomeSlice.actions

export const FETCH_ANNUAL_TAX_OUTCOMES_KEY = 'FETCH_ANNUAL_TAX_OUTCOMES_KEY'
export const fetchAnnualTaxOutcomesIfNeeded = (filingFormId?: number) =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_ANNUAL_TAX_OUTCOMES_KEY,
    fetchFunction: async (dispatch) => {
      if (!filingFormId) {
        return undefined
      }

      const json = await axios.get<AnnualTaxOutcome[]>(
        `/finances/api/v1/annual_tax_outcomes/${filingFormId}`
      )

      dispatch(receiveAnnualTaxOutcomes(keyBy(json.data, 'id')))

      return json.data
    },
  })

export const UPDATE_ANNUAL_TAX_OUTCOME_KEY = 'UPDATE_ANNUAL_TAX_OUTCOME_KEY'
export const updateAnnualTaxOutcome = (
  outcomeId: number,
  payload: {
    isRefund: boolean
    receiptDocumentId: number | null
    amountInCents: number
  }
) =>
  fetchWrapper({
    fetchKey: UPDATE_ANNUAL_TAX_OUTCOME_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.put<AnnualTaxOutcome>(
        `/finances/api/v1/annual_tax_outcomes/${outcomeId}`,
        payload
      )

      dispatch(receiveAnnualTaxSingleOutcome(json.data))

      return json.data
    },
  })

const selectAnnualTaxOutcomes = (state: ReduxState) => state.annualTaxOutcomes

const selectAnnualTaxOutcomesList = createSelector(
  selectAnnualTaxOutcomes,
  (outcomes) => Object.values(outcomes)
)

export const selectAnnualTaxOutcomesByFormId = createSelector(
  selectAnnualTaxOutcomesList,
  (_: unknown, formId: number | undefined) => formId,
  (outcomes, formId) =>
    outcomes.filter((outcome) => outcome.filingFormId === formId)
)

export const selectTaxPaymentLoggedForYearAndFormId = createSelector(
  selectAnnualTaxOutcomesList,
  selectFilingFormsForYear,
  (_: unknown, _year: string, formId: number | string) => formId,
  (outcomes, filingForms, formId) => {
    const form = filingForms.find((form) => form.id === Number(formId))

    // Outcomes won't show up until the tax fyle job is returned
    if (
      (form?.taxfyleJob?.jobStatus !== TaxfyleJobStatus.completed &&
        form?.taxfyleJob?.jobStep !== TaxfyleJobStep.returnFiled) ||
      outcomes.length === 0
    ) {
      return false
    }

    // Is refund is required to be filled out with an outcome.  Use that to check if it's completed
    return !outcomes.some(
      (outcome) => outcome.filingFormId === form.id && outcome.isRefund === null
    )
  }
)
