import Pill from '../components/BaseComponents/Pill'

export const DefaultPill = () => (
  <div style={{ padding: 4, background: 'white' }}>
    <Pill>Default</Pill>
  </div>
)

export const LightPill = () => (
  <div style={{ padding: 4, background: 'white' }}>
    <Pill variant="light">Default</Pill>
  </div>
)

export const GreenPill = () => (
  <div style={{ padding: 4, background: 'white' }}>
    <Pill color="green">Green</Pill>
  </div>
)

export const GreenLightPill = () => (
  <div style={{ padding: 4, background: 'white' }}>
    <Pill color="green" variant="light">
      Green Light
    </Pill>
  </div>
)

export const MagentaPill = () => (
  <div style={{ padding: 4, background: 'white' }}>
    <Pill color="magenta">Magenta</Pill>
  </div>
)

export const MagentaLightPill = () => (
  <div style={{ padding: 4, background: 'white' }}>
    <Pill color="magenta" variant="light">
      Magenta Light
    </Pill>
  </div>
)

export const NavyPill = () => (
  <div style={{ padding: 4, background: 'white' }}>
    <Pill color="navy">Navy</Pill>
  </div>
)

export const NavyLightPill = () => (
  <div style={{ padding: 4, background: 'white' }}>
    <Pill color="navy" variant="light">
      Navy Light
    </Pill>
  </div>
)

export const YellowPill = () => (
  <div style={{ padding: 4, background: 'white' }}>
    <Pill color="yellow">Yellow</Pill>
  </div>
)

export const YellowLightPill = () => (
  <div style={{ padding: 4, background: 'white' }}>
    <Pill color="yellow" variant="light">
      Yellow Light
    </Pill>
  </div>
)
