import { useEffect } from 'react'
import { Grid } from 'semantic-ui-react'

import ExpensesPieChartRow from './ExpensesPieChartRow'
import ExpensesTransactionTable from './ExpensesTransactionTable'
import { Card, Text } from '../../BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { getIsFetching } from '../../../reducers/fetch'
import { fetchTransactionCategoriesIfNeeded } from '../../../features/Reports/reports.slice'
import {
  fetchUserTransactions,
  USER_TRANSACTION_KEY,
} from '../../../features/Transactions/transactions.slice'
import { useAnalyticsView } from '../../../features/Amplitude'
import PageHeader from '../../shared/PageHeader'
import { useAppDispatch } from '../../../utils/typeHelpers'

const ExpensesPanel = () => {
  const dispatch = useAppDispatch()
  const isLoading = useReselector(getIsFetching, USER_TRANSACTION_KEY)

  const pageView = useAnalyticsView()

  useEffect(() => {
    pageView('expense breakdown')
  }, [pageView])

  useEffect(() => {
    dispatch(fetchTransactionCategoriesIfNeeded())
    dispatch(fetchUserTransactions())
  }, [dispatch])

  const renderEmptyState = () => {
    return (
      <Grid doubling stackable>
        <Grid.Row>
          <Grid.Column width={16}>
            <Text as="h3">Your Expenses Breakdown is being built!</Text>
            <br />
            <Text style={{ marginBottom: 4 }}>
              This breakdown will give you an insight into where your spending
              lies.
            </Text>
            <Text>
              Once your bookkeeper finishes categorizing your expenses, your
              breakdown will be ready. We will notify you when it is ready to
              view!
            </Text>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  const renderReports = () => {
    return (
      <div className="expensesReports">
        <Grid stackable doubling>
          <ExpensesPieChartRow />
        </Grid>

        <Grid stackable doubling>
          <Text as="eyebrow" style={{ fontSize: 14 }}>
            Latest Categorized Expenses
          </Text>
          <ExpensesTransactionTable />
        </Grid>
      </div>
    )
  }

  const renderPanel = () => {
    if (isLoading) {
      return renderEmptyState()
    } else {
      return renderReports()
    }
  }

  return (
    <>
      <PageHeader header="Your Expenses Breakdown" />
      <Card type="subsection" backgroundColor="stone40">
        {renderPanel()}
      </Card>
    </>
  )
}

export default ExpensesPanel
