import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import {
  Alert,
  Card,
  getFieldName,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { getFinancialProfile } from '../../../../selectors/user.selectors'
import {
  UPDATE_FINANCIAL_PROFILE_KEY,
  updateFinancialProfile,
} from '../../../../actions/financialProfileActions'
import { TAX_PROFILE_STEP, useTaxProfileSteps } from '../FormFlow/helpers'

import TaxProfileFormFooter from '../FormFlow/TaxProfileFormFooter'
import { getFetchError } from '../../../../reducers/fetch'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import FilingStatusSubForm from '../FormFlow/FilingStatusSubForm'
import { selectActiveQuarterlyTaxEstimateDetails } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'

const RecalculationAboutYouForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const fp = useReselector(getFinancialProfile)
  const activeQuarterDetails = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )

  const currentQuarter = activeQuarterDetails?.taxQuarter
  const currentYear = activeQuarterDetails?.taxYear
  const error = useReselector(
    getFetchError,
    UPDATE_FINANCIAL_PROFILE_KEY(fp?.id)
  )
  const { goToNextStep } = useTaxProfileSteps(TAX_PROFILE_STEP.aboutYou)

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      filingStatusSame: undefined as boolean | undefined,
      filingStatus: fp?.filingStatus,
    },
    onSubmit: async ({ filingStatusSame, filingStatus }) => {
      const res = await dispatch(
        updateFinancialProfile(fp?.id, {
          ...((filingStatusSame === false || !fp?.filingStatus) && {
            filingStatus,
          }),
          // Clear spouse fields if not filing jointly
          ...(filingStatusSame === false &&
            filingStatus !== 'married_filing_jointly' && {
              spouseWIncomeInCents: null,
              spouseFederalWithholdingInCents: null,
              spouseStateWithholdingInCents: null,
              spouseIndividualIncomeInCents: null,
            }),
        })
      )

      if (res) {
        goToNextStep(res)
      }
    },
  })

  const { values, setFieldValue, submitForm, isValid, isSubmitting } = formik

  // If filingStatusSame toggled to true reset the filingStatus change
  useEffect(() => {
    if (values.filingStatusSame) {
      setFieldValue(
        getFieldName<typeof values>('filingStatus'),
        fp?.filingStatus
      )
    }
  }, [fp?.filingStatus, setFieldValue, values.filingStatusSame])

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn>
          <Text textAlign="center" as="display2">
            Adjust your tax information
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text textAlign="center" as="bodyLg">
            In order to receive a new federal tax estimate, please make changes
            to the details that seem off.
          </Text>
        </GridRowColumn>

        {error && (
          <GridRowColumn>
            <Alert type="error">{error.message}</Alert>
          </GridRowColumn>
        )}

        <GridRowColumn>
          <Card backgroundColor="stone40">
            <Grid>
              <FilingStatusSubForm fp={fp} />
            </Grid>
          </Card>
        </GridRowColumn>

        <TaxProfileFormFooter
          submitOrContinue={submitForm}
          goToPreviousStep={() =>
            navigate(
              `/taxes/quarterly/${currentYear}-${currentQuarter}/estimate-breakdown`
            )
          }
          continueDisabled={!isValid || isSubmitting}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default RecalculationAboutYouForm
