import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CalendlyLink } from '../calendlyLink.slice'
import { NormalizedSchema } from 'normalizr'

export type UserCallStateOfEvent =
  | 'completed'
  | 'missed'
  | 'skipped'
  | 'upcoming'
  | 'unscheduled'
  | 'not_required'
  | null

export type UserCallEventType = 'onboarding' | 'other' | 'checkins'

export interface UserCallEvent {
  id: number
  userId: number
  callName: string
  location: string
  startTime: string | null
  endTime: string | null
  calendlyInviteeUuid: string
  rescheduleUuid: string | null
  calendlyEventId: string
  calendlyEvent: string
  canceledAt: string
  createdAt: string
  updatedAt: string
  adminCalendlyLinkId?: number
  skippedAt: string | null
  missedOn: string | null
  year: string | null
  quarter: '1' | '2' | '3' | '4' | null
  eventType: UserCallEventType | null
  eventState: UserCallStateOfEvent
  adminCalendlyLinks: number
}

export interface UserCallEventEntities {
  userCallEvents?: { [key: number]: UserCallEvent }
  adminCalendlyLink?: { [key: number]: CalendlyLink }
}
export type NormalizedUserCallEvent = NormalizedSchema<
  UserCallEventEntities,
  number[] | number
>

export interface UserCallEventState {
  [key: number]: UserCallEvent
}

const initialState: UserCallEventState = {}

const userCallEventsSlice = createSlice({
  name: 'userCallEvents',
  initialState,
  reducers: {
    receiveUserCallEvents: (
      state,
      action: PayloadAction<NormalizedUserCallEvent>
    ) => ({ ...state, ...action.payload.entities.userCallEvents }),
  },
})

export default userCallEventsSlice.reducer

export const { receiveUserCallEvents } = userCallEventsSlice.actions
