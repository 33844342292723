import React from 'react'
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'

import { ReduxState } from '../../utils/typeHelpers'

interface ParentProps {
  component: React.ElementType
  customAuth?: () => boolean
}
type StateProps = ReturnType<typeof mapStateToProps>

type Props = StateProps & ParentProps

const ExternalAdminRoute = ({
  component: RouteComponent,
  auth,
  customAuth = () => true,
  ...rest
}: Props) => {
  // Tax Preparers are external admin user; this checks for External Admins

  if (auth.isAuthenticated && auth.isAdmin && customAuth()) {
    return <RouteComponent {...rest} />
  } else {
    return <Navigate to="/" />
  }
}

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth.security,
})

export default connect<StateProps, null, ParentProps, ReduxState>(
  mapStateToProps
)(ExternalAdminRoute)
