import { ReduxState } from '../../utils/typeHelpers'
import { FinancialAdvisoryPilotStatus } from './financialAdvisory.slice'
import { AdvisoryCallEventType } from './userFinancialAdvisoryCallEvents.slice'
import { orderBy } from 'lodash'

export const selectFinancialAdvisoryProfile = (state: ReduxState) =>
  state.financialAdvisoryProfile

export const hasAdvisoryEnabled = (state: ReduxState) => {
  return Boolean(state.financialAdvisoryProfile)
}

export const advisory = (state: ReduxState) => {
  return Boolean(state.financialAdvisoryProfile)
}

export const getAllUserFinancialAdvisoryCallEvents = (state: ReduxState) =>
  state.userFinancialAdvisoryCallEvents

export const getIntroUserFinancialAdvisoryCallEvents = (state: ReduxState) => {
  const events = state.userFinancialAdvisoryCallEvents
  const orderedEvents = orderBy(events, 'createdAt', 'desc')
  return Object.values(orderedEvents).filter(
    (event) => event.eventType === AdvisoryCallEventType.intro
  )[0]
}

export const getAdvisoryCallPricing = (state: ReduxState) => {
  switch (state.financialAdvisoryProfile?.pilotStatus) {
    case FinancialAdvisoryPilotStatus.design_partner:
      return 0
    case FinancialAdvisoryPilotStatus.beta_150:
      return 150
    case FinancialAdvisoryPilotStatus.beta_200:
      return 200
    default:
      return 150
  }
}
