import { FC, ReactNode, useCallback, useMemo, useState } from 'react'
import { Menu, StrictTabProps, Tab as SemanticTab } from 'semantic-ui-react'
import styled from 'styled-components'
import { Colors, Fonts } from '../../styles/theme'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'

type Panes =
  | StrictTabProps['panes']
  | {
      menuItem?: string
      ornament?: ReactNode
      render?: () => ReactNode
    }[]

interface Props extends Omit<StrictTabProps, 'panes' | 'onTabChange'> {
  className?: string
  container?: ({
    children,
    className,
  }: {
    children?: ReactNode
    className?: string
  }) => JSX.Element | ReactNode
  footer?: ReactNode
  header?: ReactNode
  onTabClick?: (index: number) => void
  panes?: Panes
}

interface TabComponent extends FC<Props> {
  Pane: typeof SemanticTab.Pane
}

const DivContainer = ({
  children,
  className,
}: {
  children?: ReactNode
  className?: string
}) => <div className={className}>{children}</div>

const Tab: TabComponent = styled(
  ({
    activeIndex,
    className,
    container: ContainerComponent = DivContainer,
    defaultActiveIndex,
    menu,
    footer,
    header,
    onTabClick,
    panes,
    ...rest
  }: Props) => {
    const [index, setIndex] = useState(Number(defaultActiveIndex ?? 0))

    const handleTabClick = useCallback(
      (tabIndex: number) => {
        onTabClick?.(tabIndex)
        setIndex(tabIndex)
      },
      [onTabClick, setIndex]
    )
    const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

    const formattedMenuItems =
      useMemo(() => {
        let style = {}
        if (isMobile) {
          style = {
            width: `${100 / (panes?.length || 1)}%`,
            justifyContent: 'center',
          }
        }
        return panes?.map((pane, paneIndex) =>
          typeof pane.menuItem === 'string' ? (
            <Menu.Item
              active={index === paneIndex}
              as="a"
              key={`${className}-${pane.menuItem}`}
              onClick={() => handleTabClick(paneIndex)}
              style={style}
            >
              {pane.menuItem}&nbsp;&nbsp;
              {'ornament' in pane && pane.ornament}
            </Menu.Item>
          ) : (
            pane.menuItem
          )
        )
      }, [isMobile, panes, index, className, handleTabClick]) ?? []

    return (
      <div className={className}>
        <Menu
          {...menu}
          activeIndex={activeIndex ?? index}
          pointing
          secondary
          style={{ marginBottom: 32, ...menu?.style }}
        >
          {formattedMenuItems}
        </Menu>
        <ContainerComponent>
          {header && (
            <>
              {header}
              <br />
            </>
          )}
          <SemanticTab
            activeIndex={activeIndex ?? index}
            menu={{ style: { display: 'none' } }}
            panes={panes ?? []}
            {...rest}
          />
          {footer && (
            <>
              <br />
              {footer}
            </>
          )}
        </ContainerComponent>
      </div>
    )
  }
)(() => ({
  '&&&&': {
    '.menu a.item': {
      borderColor: Colors.lightGray,
      color: Colors.black,
      ...Fonts.bodyMd,

      '&.active, &:hover': {
        borderColor: `${Colors.green} !important`,
        color: `${Colors.green} !important`,
      },

      '&.active': {
        fontWeight: 500,
      },
    },

    '.ui.segment.tab': {
      padding: 0,
    },
  },
}))

Tab.Pane = SemanticTab.Pane

export default Tab
