import { Grid } from 'semantic-ui-react'
import {
  Accordion,
  GridRowColumn,
  Text,
} from '../../../components/BaseComponents'
import { OtherFeature } from '../SetupComplete'
import AhaMomentsHeaderFooter from './AhaMomentsHeaderFooter'
import { makeGridConfig } from '../../../components/BaseComponents/Grid'

const AhaMomentsBaseComponent = ({
  title,
  subtitle,
  description,
  features,
  media,
  learnMoreItems,
  backButtonText,
  continueButtonText,
  handleContinue,
  handleBack,
  continueDisabled,
}: {
  title: string
  subtitle: string
  description: React.ReactNode
  features: Array<{
    imgSrc: string
    title: string
    description: string
  }>
  media: React.ReactNode
  learnMoreItems: Array<{ title: string; content: React.ReactNode }>
  backButtonText: string
  continueButtonText: string
  handleContinue: () => void
  handleBack: () => void
  continueDisabled?: boolean
}) => (
  <AhaMomentsHeaderFooter
    backButtonText={backButtonText}
    continueButtonText={continueButtonText}
    handleContinue={handleContinue}
    handleBack={handleBack}
    continueDisabled={continueDisabled}
  >
    <Grid
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 800,
        padding: 0,
        margin: '0 auto',
      }}
    >
      <GridRowColumn
        style={{ padding: '16px 0' }}
        {...makeGridConfig([14, 16], true)}
      >
        <GridRowColumn style={{ marginBottom: 45 }}>
          <Text as="display2">{title}</Text>
        </GridRowColumn>
        <GridRowColumn style={{ margin: '16px 0' }}>{media}</GridRowColumn>
        <GridRowColumn style={{ marginBottom: 24 }}>
          <Text as="display3">{subtitle}</Text>
        </GridRowColumn>
        <GridRowColumn style={{ marginBottom: 32 }}>
          <Text>{description}</Text>
        </GridRowColumn>
        <GridRowColumn style={{ gap: 24 }}>
          {features.map((feature, index) => (
            <OtherFeature
              key={index}
              imgSrc={feature.imgSrc}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </GridRowColumn>
        <GridRowColumn style={{ margin: '32px 0' }}>
          <Text as="display3">Learn more</Text>
          {learnMoreItems.map((item, index) => (
            <Accordion
              key={index}
              title={item.title}
              content={item.content}
              variant="text"
              style={{ marginTop: 16, marginBottom: 16 }}
            />
          ))}
        </GridRowColumn>
      </GridRowColumn>
    </Grid>
  </AhaMomentsHeaderFooter>
)

export default AhaMomentsBaseComponent
