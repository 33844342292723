import { Fragment, useState, useRef, useEffect } from 'react'
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownItemProps,
} from 'semantic-ui-react'
import { Checkbox } from '../BaseComponents'
import {
  LabelDescription,
  LabelDescriptionProps,
} from '../BaseComponents/Input'
import { Colors, Fonts } from '../../styles/theme'
import styled from 'styled-components'
import {
  GroupedDropdownValue,
  ChildDropdownOption,
  ParentDropdownOption,
  useGroupedDropdownLabel,
} from './groupedDropdownHelpers'

export interface GroupedDropdownProps<T extends GroupedDropdownValue> {
  options?: ParentDropdownOption<T>[]
  value?: T[]
  onChange?: (value: T[]) => void
  placeholder?: string
  disabled?: boolean
  error?: string
  className?: string
  label?: string
  description?: string
  required?: boolean
  afterLabel?: React.ReactNode
  fullWidth?: boolean
  height?: string | number
  width?: string | number
  color?: keyof typeof Colors
  highlightColor?: keyof typeof Colors
  fontSize?: string | number
  fontWeight?: number
  allowOneParent?: boolean
}

export const UnstyledGroupedDropdown = <T extends GroupedDropdownValue>({
  options = [],
  value = [],
  onChange,
  placeholder,
  disabled = false,
  error,
  className,
  label,
  description,
  required,
  afterLabel,
  fullWidth,
  allowOneParent = false,
}: GroupedDropdownProps<T> & LabelDescriptionProps) => {
  const [open, setOpen] = useState(false)

  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // close dropdown menu when clicking outside of the dropdown
    // Dropdown onBlur would still close when clicking a menu option
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(false)
      }
    }

    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside)

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const { childOptionMap, text } = useGroupedDropdownLabel(value, options)

  const handleParentClick = (
    parentChecked: boolean,
    childOptions: ChildDropdownOption<T>[]
  ) => {
    if (parentChecked) {
      // if checked remove all child options from value if they exist
      const updatedValue = value.filter(
        (val) => !childOptions.some((opt) => opt.value === val)
      )
      onChange?.(updatedValue)
    } else {
      // if not checked, add all child options to value if they are not added
      const childValues = childOptions.map((opt) => opt.value)
      let updatedValue = [...value]
      if (allowOneParent) {
        updatedValue = [...childValues]
      } else {
        updatedValue = [
          ...value,
          ...childValues.filter((val) => !value.includes(val as T)),
        ]
      }
      onChange?.(updatedValue)
    }
  }

  const handleItemClick = (
    _: unknown,
    { value: newValue }: DropdownItemProps
  ) => {
    // onClick is needed here because onChange isn't called within the DropdownMenu component
    const multipleValue = Array.isArray(value) ? [...value] : []
    const selectedIndex = multipleValue.indexOf(newValue as T)
    if (selectedIndex !== -1) {
      multipleValue.splice(selectedIndex, 1)
      onChange?.(multipleValue)
    } else {
      let updatedValue = [...multipleValue]
      if (allowOneParent) {
        const clickedChildOption = childOptionMap[newValue as T]
        const parentValue = clickedChildOption?.parent?.value
        updatedValue = multipleValue.filter((val) => {
          const parentOfVal = childOptionMap[val]?.parent?.value
          return parentOfVal === parentValue
        })
      }
      updatedValue.push(newValue as T)
      onChange?.(updatedValue)
    }
  }

  return (
    <div className={className} ref={dropdownRef}>
      <LabelDescription
        label={label}
        description={description}
        required={required}
        afterLabel={afterLabel}
      />
      <Dropdown
        open={open}
        onFocus={() => setOpen(true)}
        value={value}
        text={text}
        placeholder={placeholder}
        disabled={disabled}
        error={Boolean(error)}
        fluid={fullWidth}
        multiple
        selection
        clearable
        onChange={(_, { value: newValue }) => {
          // @ts-expect-error semantic UI typing issue
          onChange?.(newValue)
        }}
      >
        <DropdownMenu>
          {options.map((option) => {
            const elements = []
            const parentChecked = option.options.every((childOption) =>
              value.includes(childOption.value)
            )
            // render parent option
            elements.push(
              <DropdownItem
                value={option.value}
                onClick={() => handleParentClick(parentChecked, option.options)}
                key={option.value}
              >
                <Checkbox
                  checked={parentChecked}
                  variant="default"
                  style={{ marginBottom: 3 }}
                />
                {option.text} (all)
              </DropdownItem>
            )
            // render all child options under parent option with a left margin
            option.options.forEach((subOption) => {
              const childChecked = value.includes(subOption.value)
              elements.push(
                <DropdownItem
                  value={subOption.value}
                  onClick={handleItemClick}
                  key={subOption.value}
                  style={{
                    display: 'flex',
                    gap: 2,
                    marginLeft: 24,
                  }}
                >
                  <Checkbox
                    checked={childChecked}
                    variant="default"
                    style={{
                      marginBottom: 3,
                    }}
                  />
                  {subOption.text}
                </DropdownItem>
              )
            })
            return (
              <Fragment key={option.value?.toString()}>{elements}</Fragment>
            )
          })}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

const { black, gray, lightRed, mediumGray, red, white } = Colors

export const GroupedDropdown = styled(UnstyledGroupedDropdown)(
  ({
    highlightColor,
    disabled,
    error,
    fontSize,
    fontWeight,
    height,
    width,
  }) => {
    return {
      '&&&&': {
        'div[highlightcolor]': {
          'div.text': {
            color: highlightColor
              ? `${Colors[highlightColor]} !important`
              : undefined,
          },
        },

        '.ui.dropdown': {
          alignItems: 'center',
          backgroundColor: `${error ? lightRed : white} !important`,
          border: disabled
            ? 'none'
            : `1px solid ${error ? red : gray} !important`,
          borderRadius: 8,
          display: 'flex',
          justifyContent: 'start',
          padding: '12px 16px',
          height: height || 42,
          width: width || undefined,

          '&.multiple > div.text.default': {
            margin: 0,
          },

          'div.text, div.item': {
            ...Fonts.bodyMd,

            '&.default': {
              color: error ? red : mediumGray,
            },

            '&:not(.default)': {
              color: error ? red : black,
            },
          },

          ...(fontSize && {
            'div.text': {
              fontSize,
              fontWeight: `${fontWeight} !important`,
            },
          }),

          'i.icon': {
            right: 10,
            fontSize: '1.1em',
          },

          '.menu': {
            border: `1px solid ${error ? red : gray} !important`,
            borderRadius: '0 0 8px 8px',
            borderTop: 'none !important',
            // Semantic UI max height for large screens - we want tablet/mobile to not be capped at small size
            maxHeight: '16.02857143rem',
          },

          '.item > .ui.label': {
            // Hides the label that sits before option values
            display: 'none',
          },

          '.ui.label.highlight': {
            '& + .text': {
              color: highlightColor ? Colors[highlightColor] : undefined,
            },
          },
          '.search': { height: '100%' },
        },
      },
    }
  }
) as typeof UnstyledGroupedDropdown
