/* 
  Used to view User Specific Annual Tax Filing Data
*/
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Grid, Header } from 'semantic-ui-react'

import { fetchAllUsersIfNeeded } from '../../../actions/admin/adminAllUsersActions'
import { fetchAnnualTaxFilingStatusTypesIfNeeded } from '../../Taxes/AnnualTaxes/annualTaxFilingStatusType.slice'
import { fetchAnnualTaxFormTypesIfNeeded } from '../../Taxes/AnnualTaxes/annualTaxFormType.slice'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../AnnualTaxDetails/annualTaxDetails.slice'
import { fetchUserDocumentCategoriesIfNeeded } from '../UserDocumentCategories/userDocumentCategories.slice'
import {
  AnnualTaxFilingNonNormalized,
  fetchAdminAnnualTaxFilingById,
} from './adminAnnualTaxFilings.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'

const AnnualTaxFilingUserPanel = () => {
  const dispatch = useAppDispatch()
  const { filingId } = useParams<{ filingId: string }>()
  const [filing, setFiling] = useState<
    AnnualTaxFilingNonNormalized | undefined
  >()
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchAllUsersIfNeeded())
      await dispatch(fetchAnnualTaxFilingStatusTypesIfNeeded())
      await dispatch(fetchUserDocumentCategoriesIfNeeded())
      await dispatch(fetchAnnualTaxFormTypesIfNeeded())
      await dispatch(fetchAllAnnualTaxDetailsIfNeeded())
      const response = await fetchAdminAnnualTaxFilingById(filingId)(dispatch)

      setFiling(response?.filing)
    }

    fetchData()
  }, [dispatch, filingId])
  return (
    <Container id="adminPanel" className="backdrop">
      <Header style={{ marginBottom: '1.5em' }} as="h3">
        Annual Tax Filing: {filingId}
      </Header>
      <Container>
        <Grid>
          <Grid.Column width={8}>
            <Grid.Row>
              <Header as="h4">Quick Information:</Header>
              <p>
                <b>Tax Needs:</b>
                <ul>
                  {filing?.annualTaxFormNeeds?.map((formNeed) => {
                    return (
                      <li key={formNeed}>
                        <p>{formNeed}</p>
                      </li>
                    )
                  })}
                </ul>
              </p>
              <p>
                <b>Need 1099-NEC Filing: </b>
                {filing?.needContractorFiling}
              </p>
              <p>Expedited: {filing?.expedited?.toString()}</p>
              <p>Expedited Due Date: {filing?.expeditedDueDate}</p>
              <p>Expedited Reason: {filing?.expeditedReason}</p>
              <p>Additional Info Reason: {filing?.additionalTaxInformation}</p>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid.Row>
              <Header as="h4">Heard Milestones</Header>
              <p>
                Tax Season Kickoff Completed On:{' '}
                {filing?.pretaxSurveySubmittedAt
                  ? filing?.pretaxSurveySubmittedAt
                  : 'Not Completed'}
              </p>
              <p>
                EOY Completed On:{' '}
                {filing?.endOfYearReviewCompletedAt
                  ? filing?.endOfYearReviewCompletedAt
                  : 'Not Completed'}
              </p>
              <p>Needs 1099-NEC: {filing?.needContractorFiling}</p>
              <p>Overall Annual Tax Filing Status: </p>
              <Header as="h4">Tax Forms Status</Header>
              {filing?.annualTaxFilingForms.map((form) => {
                return (
                  <div key={form.id}>
                    <p key={form.id}>{form.id}</p>
                    <p>Tax Form Status:</p>
                  </div>
                )
              })}
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </Container>
    </Container>
  )
}

export default AnnualTaxFilingUserPanel
