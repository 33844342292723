import moment from 'moment'
import { useEffect, useState } from 'react'
import { Grid, Icon, Loader } from 'semantic-ui-react'

import {
  Button,
  Card,
  GridRowColumn,
  Modal,
  Text,
} from '../../../components/BaseComponents'
import {
  getFetchError,
  getIsFetchingOrNotStarted,
} from '../../../reducers/fetch'
import Tooltip from '../../Taxes/QuarterlyTaxEstimates/components/Tooltip'
import ImportantDateList from './components/ImportantDateList'
import { getUserImportantDatesBetween } from './userImportantDates.selectors'
import {
  fetchUserImportantDatesIfNeeded,
  FETCH_USER_IMPORTANT_DATES_KEY,
} from './userImportantDates.slice'
import { useReselector } from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'

const NUMBER_ITEMS_SHOWN_IN_CARD = 3
const NUMBER_UPCOMING_MONTHS_IN_MODAL = 12

const MIN_LOCAL_DATE_ONLY = moment()
const MAX_LOCAL_DATE_ONLY = moment()
  .add(NUMBER_UPCOMING_MONTHS_IN_MODAL, 'M')
  .endOf('d')

const UserImportantDatesCard = () => {
  const dispatch = useAppDispatch()
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    dispatch(fetchUserImportantDatesIfNeeded())
  }, [dispatch])

  const upcomingUserImportantDates = useReselector(
    getUserImportantDatesBetween,
    {
      startDateParam: MIN_LOCAL_DATE_ONLY,
      endDateParam: MAX_LOCAL_DATE_ONLY,
      count: NUMBER_ITEMS_SHOWN_IN_CARD,
    }
  )

  const allUserImportantDates = useReselector(getUserImportantDatesBetween, {
    startDateParam: MIN_LOCAL_DATE_ONLY,
    endDateParam: MAX_LOCAL_DATE_ONLY,
    count: null,
  })

  const isFetchingOrNotStarted = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_USER_IMPORTANT_DATES_KEY
  )

  const fetchError = useReselector(
    getFetchError,
    FETCH_USER_IMPORTANT_DATES_KEY
  )

  return (
    <Card
      style={{ marginBottom: 24 }}
      type="subsection"
      backgroundColor="stone40"
    >
      <Grid>
        <Grid.Row>
          <Grid.Column width={14}>
            <Text as="h2">Important Dates</Text>
          </Grid.Column>
          <Grid.Column floated="right" width={2} only="computer">
            <Tooltip
              popup={{
                body: 'State-specific deadlines are not currently included.',
              }}
              labelComponent={<Icon name="question circle outline" />}
            />
          </Grid.Column>
        </Grid.Row>

        {fetchError && (
          <GridRowColumn>
            <Text>
              There was an error loading this card. Please refresh the page to
              try again.
            </Text>
          </GridRowColumn>
        )}
        {isFetchingOrNotStarted && <Loader active size="small" />}
        {!isFetchingOrNotStarted && !fetchError && (
          <ImportantDateList
            style={{ marginTop: 0 }}
            groupItemsByMonth={false}
            indicateUnreadItems
            userImportantDates={upcomingUserImportantDates}
          />
        )}
        <Grid.Row>
          <Grid.Column>
            {allUserImportantDates.length === 0 ? (
              <Text>No upcoming important dates yet!</Text>
            ) : (
              <Button
                variant="secondaryLink"
                onClick={() => setModalOpen(true)}
                fullWidth={false}
              >
                View All
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Modal
        closeIcon
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        size="tiny"
      >
        <Modal.Header>Upcoming Dates</Modal.Header>
        <Modal.Content scrolling>
          <Text>
            Below are important dates for the next twelve months. State-specific
            deadlines aren&apos;t currently available.
          </Text>
          <ImportantDateList
            userImportantDates={allUserImportantDates}
            indicateUnreadItems={false}
            groupItemsByMonth
          />
        </Modal.Content>
      </Modal>
    </Card>
  )
}

export default UserImportantDatesCard
