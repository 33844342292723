import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Gusto_Contractor } from './generated_gusto_types'

export interface ContractorState {
  [key: string]: Gusto_Contractor
}

const contractorSlice = createSlice({
  name: 'contractor',
  initialState: {} as ContractorState,
  reducers: {
    setMultipleContractors: (
      state,
      action: PayloadAction<{ [key: number]: Gusto_Contractor }>
    ) => ({
      ...state,
      ...action.payload,
    }),
    setSingleContractor: (state, action: PayloadAction<Gusto_Contractor>) => {
      state[action.payload.uuid] = action.payload
    },
    removeContractor: (state, action: PayloadAction<string>) => {
      delete state[action.payload]
    },
  },
})

export const { setMultipleContractors, setSingleContractor, removeContractor } =
  contractorSlice.actions

export default contractorSlice.reducer
