import { useState } from 'react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  GridRowColumn,
  IconButton,
  Link,
  Text,
} from '../../../../../components/BaseComponents'
import { useReselector } from '../../../../../utils/sharedHooks'
import { getUserDocumentById } from '../../../../UserDocuments/userDocuments.selector'
import DeleteDocumentModal from './DeleteDocumentModal'

const UploadedDocument = ({
  id,
  readOnly,
}: {
  id: number
  readOnly?: boolean
}) => {
  const userDoc = useReselector(getUserDocumentById, id)
  const [openModal, setOpenModal] = useState(false)
  return (
    <GridRowColumn
      key={userDoc?.id}
      short
      columnStyle={{
        display: 'flex',
        flexDirection: 'row',
        verticalAlign: 'middle',
        justifyContent: 'end',
        gap: 6,
        alignItems: 'center',
      }}
    >
      {!userDoc?.signedUrl && <Text>{userDoc?.fileDescription}</Text>}
      {userDoc?.signedUrl && (
        <Link href={userDoc.signedUrl}>{userDoc?.fileDescription}</Link>
      )}
      {!readOnly && (
        <IconButton
          onClick={() => setOpenModal(true)}
          icon={regular('trash-can')}
        />
      )}
      <DeleteDocumentModal
        open={openModal}
        close={() => setOpenModal(false)}
        documentId={id}
      />
    </GridRowColumn>
  )
}

export default UploadedDocument
