import { CSSProperties } from 'react'
import { Grid } from 'semantic-ui-react'
import { StrictGridRowProps } from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow'

type SemanticWIDTHSNUMBER =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16

interface Props extends StrictGridRowProps {
  width?: SemanticWIDTHSNUMBER
  computer?: SemanticWIDTHSNUMBER
  tablet?: SemanticWIDTHSNUMBER
  mobile?: SemanticWIDTHSNUMBER
  spacer?: SemanticWIDTHSNUMBER
  computerSpacer?: SemanticWIDTHSNUMBER
  tabletSpacer?: SemanticWIDTHSNUMBER
  mobileSpacer?: SemanticWIDTHSNUMBER
  short?: boolean
  style?: CSSProperties
  columnStyle?: CSSProperties
  centerContent?: boolean
  columnClass?: string
}

// Spacer should be half of the remaining column spacing
// so if a column is size 10, there are a remaining 6 columns and the spacer should be 3 so it's equidistant from either side
const getSpacer = (width: SemanticWIDTHSNUMBER) =>
  Math.floor((16 - width) / 2) as SemanticWIDTHSNUMBER

// Usage `<GridRowColumn {...makeGridConfig([10, 12, 16], true)}`
// If 2 configs sent in the first will be used by the tablet and mobile will use the 2nd
// The second prop will center the content in every screen size
export const makeGridConfig = (
  config: [SemanticWIDTHSNUMBER, SemanticWIDTHSNUMBER, SemanticWIDTHSNUMBER?],
  centered?: boolean
) => {
  // If 3rd config not sent in tablet will use 1st config
  const tabletConfig = config[2] ? config[1] : config[0]
  // If 3rd config not sent in mobile will use 2nd config
  const mobileConfig = config[2] ? config[2] : config[1]

  return {
    computer: config[0],
    tablet: tabletConfig,
    mobile: mobileConfig,
    ...(centered && {
      computerSpacer: getSpacer(config[0]),
      tabletSpacer: getSpacer(tabletConfig),
      mobileSpacer: getSpacer(mobileConfig),
    }),
  }
}

export const GridRowColumn = ({
  children,
  width,
  spacer,
  computerSpacer,
  tabletSpacer,
  mobileSpacer,
  short,
  columnStyle,
  centerContent = false,
  columnClass,
  computer,
  tablet,
  mobile,
  ...rest
}: Props) => (
  <Grid.Row className={short ? 'short' : undefined} {...rest}>
    {Boolean(spacer) && <Grid.Column width={spacer} />}
    {Boolean(computerSpacer) && (
      <Grid.Column width={computerSpacer} only="computer" />
    )}
    {Boolean(tabletSpacer) && (
      <Grid.Column width={tabletSpacer} only="tablet" />
    )}
    {Boolean(mobileSpacer) && (
      <Grid.Column width={mobileSpacer} only="mobile" />
    )}

    <Grid.Column
      width={
        width ||
        (spacer
          ? // Casted with assumption that valid spacer sent in (TS would catch)
            ((16 - spacer) as SemanticWIDTHSNUMBER)
          : undefined)
      }
      computer={computer}
      tablet={tablet}
      mobile={mobile}
      style={{
        ...columnStyle,
        ...(centerContent && { display: 'flex', justifyContent: 'center' }),
      }}
      className={columnClass}
    >
      {children}
    </Grid.Column>
  </Grid.Row>
)

// GridColumnSpacer will add a vertical space for overflowing Grid rows, a common case in Heard designs
// Usage:
//  <Grid.Row>
//     <Grid.Column computer={8} tablet={8} mobile={16}>Content</Grid.Column>
//     <GridColumnSpacer mobile />
//     <Grid.Column computer={8} tablet={8} mobile={16}>Content</Grid.Column>
//  </Grid.Row>
// When the 2 16 width columns overflow there normally is no space but now there will be a vertical gap, just for mobile
export const GridColumnSpacer = ({
  height = 10,
  computer,
  tablet,
  mobile,
}: {
  height?: number
  computer?: boolean
  tablet?: boolean
  mobile?: boolean
}) => (
  <Grid.Column
    only={[computer && 'computer', tablet && 'tablet', mobile && 'mobile']
      .filter((val) => val)
      .join(' ')}
    width={16}
    style={{ height }}
  />
)
