import axios from 'axios'

import { fetchWrapper } from '../../../reducers/fetch'

export const POST_SEND_GRID_EMAIL_KEY = 'POST_SEND_GRID_EMAIL_KEY'
export const postSendGridEmail = ({
  templateId,
  templateVersion,
  ...rest
}: {
  userList: number[]
  templateId: string
  templateVersion?: string
  applyStrictUserCriteria?: boolean
  categories?: string[]
}) =>
  fetchWrapper({
    fetchKey: POST_SEND_GRID_EMAIL_KEY,
    fetchFunction: async () => {
      // Version is appended to template id by `.`
      const sendgridTemplateId = `${templateId}${templateVersion ? `.${templateVersion}` : ''}`

      const res = await axios.post<
        Array<{ id: number; firstName: string; email: string }>
      >('/finances/api/v2/admin/messaging', {
        ...rest,
        sendgridTemplateId,
        // True must be sent for the emails to send
        enableSending: true,
      })

      return res.data
    },
  })
