import { useCallback, useEffect } from 'react'
import {
  useMemoizedValue,
  useReselector,
} from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  FETCH_ALL_EOY_REVIEW_STEPS_KEY,
  fetchAllEoyReviewStepsIfNeeded,
} from './allEoyReviewSteps.slice'
import {
  DELETE_USER_EOY_REVIEW_PROGRESS_KEY,
  FETCH_USER_EOY_REVIEW_PROGRESS_KEY,
  UPSERT_USER_EOY_REVIEW_PROGRESS_KEY,
  deleteUserEoyReviewProgress,
  fetchUserEoyReviewProgress,
  upsertUserEoyReviewProgress,
} from './userEndOfYearReviewProgress.slice'
import { useParams } from 'react-router-dom'
import { selectAnnualTaxFilingFormById } from '../../../annualTaxFilingForms.selector'
import { SubStepIdentifiers } from './stepProgress.helpers'
import { selectEoyReviewStepsInList } from './endOfYearReviewSteps.selector'
import { selectUserEoyReviewProgressForChecklistStep } from './userEndOfYearReviewProgress.selector'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { logSentryError } from '../../../../../../utils/sentryHelpers'
import {
  AnnualTaxFilingFormQuestionnaireStatus,
  updateAnnualTaxFilingForms,
} from '../../../annualTaxFilingForms.slice'
import { selectTqIsReadOnly } from '../../../annualTaxFilings.selector'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'

export interface StepUpdates {
  createdSteps?: SubStepIdentifiers[]
  deletedSteps?: SubStepIdentifiers[]
  completedSteps?: SubStepIdentifiers[]
  incompleteSteps?: SubStepIdentifiers[]
  createAndCompleteSteps?: SubStepIdentifiers[]
}

interface Props {
  steps: SubStepIdentifiers[]
}
const useProgressSteps = ({ steps }: Props = { steps: [] }) => {
  const initialSteps = useMemoizedValue(steps)
  const dispatch = useAppDispatch()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()
  const readOnly = useReselector(selectTqIsReadOnly, formId)
  const filingForm = useReselector(selectAnnualTaxFilingFormById, formId)
  const eoySteps = useReselector(selectEoyReviewStepsInList, initialSteps)
  const userProgresses = useReselector(
    selectUserEoyReviewProgressForChecklistStep,
    initialSteps
  )
  const fetchKeys = [
    FETCH_USER_EOY_REVIEW_PROGRESS_KEY,
    FETCH_ALL_EOY_REVIEW_STEPS_KEY,
  ]
  const updateKeys = [
    DELETE_USER_EOY_REVIEW_PROGRESS_KEY,
    UPSERT_USER_EOY_REVIEW_PROGRESS_KEY,
  ]
  const allKeys = [...fetchKeys, ...updateKeys]
  const isFetching = useReselector(selectIsFetchingForKeys, fetchKeys)
  const isUpdating = useReselector(selectIsFetchingForKeys, updateKeys)

  const getUserProgressByStepIdentifier = useCallback(
    (stepIdentifier: SubStepIdentifiers) => {
      const step = eoySteps.find(
        (eoyStep) => eoyStep.identifier === stepIdentifier
      )
      const userProgress = userProgresses.find(
        (progress) => progress.endOfYearReviewStepId === step?.id
      )
      if (!userProgress?.id) {
        logSentryError(`User progress not found for step ${stepIdentifier}`)
      }
      return userProgress
    },
    [eoySteps, userProgresses]
  )

  const updateProgressData = useCallback(
    async ({
      completedSteps = [],
      incompleteSteps = [],
      createdSteps = [],
      deletedSteps = [],
      createAndCompleteSteps = [],
    }: StepUpdates) => {
      if (readOnly) {
        return true
      }
      const stepsToCreate = createdSteps.flatMap((stepIdentifier) => {
        const userProgress = getUserProgressByStepIdentifier(stepIdentifier)
        const step = eoySteps.find(
          (eoyStep) => eoyStep.identifier === stepIdentifier
        )
        if (!userProgress && filingForm && step) {
          return [
            {
              annualTaxFilingId: filingForm.annualTaxFilingId,
              endOfYearReviewStepId: step.id,
            },
          ]
        }
        return []
      })

      const stepsToCreateAndComplete = createAndCompleteSteps.flatMap(
        (stepIdentifier) => {
          const userProgress = getUserProgressByStepIdentifier(stepIdentifier)
          const step = eoySteps.find(
            (eoyStep) => eoyStep.identifier === stepIdentifier
          )
          if (!userProgress && filingForm && step) {
            return [
              {
                annualTaxFilingId: filingForm.annualTaxFilingId,
                endOfYearReviewStepId: step.id,
                completedAt: new Date(),
              },
            ]
          }
          return []
        }
      )

      const stepsToComplete = completedSteps.flatMap((stepIdentifier) => {
        const userProgress = getUserProgressByStepIdentifier(stepIdentifier)
        if (userProgress?.id && !userProgress.completedAt) {
          return [
            {
              ...userProgress,
              completedAt: new Date(),
            },
          ]
        }
        return []
      })

      const stepsToMarkIncomplete = incompleteSteps.flatMap(
        (stepIdentifier) => {
          const userProgress = getUserProgressByStepIdentifier(stepIdentifier)
          if (userProgress?.id && userProgress.completedAt) {
            return [
              {
                ...userProgress,
                completedAt: null,
              },
            ]
          }
          return []
        }
      )
      const stepsToUpsert = [
        ...stepsToCreate,
        ...stepsToCreateAndComplete,
        ...stepsToComplete,
        ...stepsToMarkIncomplete,
      ]
      const upsertResults =
        await upsertUserEoyReviewProgress(stepsToUpsert)(dispatch)

      const deletedResults = await Promise.allSettled(
        deletedSteps.flatMap((stepIdentifier) => {
          const userProgress = getUserProgressByStepIdentifier(stepIdentifier)
          if (userProgress?.id) {
            return deleteUserEoyReviewProgress(userProgress.id)(dispatch)
          }
          return []
        })
      )
      const updateSuccess =
        Boolean(upsertResults) &&
        deletedResults.every((result) => result.status === 'fulfilled')

      if (
        updateSuccess &&
        filingForm?.questionnaireResponseStatus ===
          AnnualTaxFilingFormQuestionnaireStatus.notStarted
      ) {
        updateAnnualTaxFilingForms(Number(formId), {
          questionnaireResponseStatus:
            AnnualTaxFilingFormQuestionnaireStatus.started,
          startedAt: new Date().toISOString(),
        })(dispatch)
      }
      return updateSuccess
    },
    [
      dispatch,
      getUserProgressByStepIdentifier,
      eoySteps,
      filingForm,
      formId,
      readOnly,
    ]
  )

  useEffect(() => {
    if (!isFetching && !isUpdating && filingForm?.annualTaxFilingId) {
      const stepsToCreate: {
        annualTaxFilingId: number
        endOfYearReviewStepId: number
      }[] = []
      eoySteps.forEach((step) => {
        const userProgress = userProgresses.find(
          (progress) => progress.endOfYearReviewStepId === step.id
        )
        if (!userProgress && step) {
          stepsToCreate.push({
            annualTaxFilingId: filingForm.annualTaxFilingId,
            endOfYearReviewStepId: step.id,
          })
        }
      })
      if (stepsToCreate.length > 0 && !readOnly) {
        upsertUserEoyReviewProgress(stepsToCreate)(dispatch)
      }
    }
  }, [
    dispatch,
    eoySteps,
    filingForm?.annualTaxFilingId,
    isFetching,
    isUpdating,
    userProgresses,
    readOnly,
  ])

  useEffect(() => {
    dispatch(fetchUserEoyReviewProgress(taxYear))
    dispatch(fetchAllEoyReviewStepsIfNeeded())
  }, [dispatch, taxYear])

  return {
    getUserProgressByStepIdentifier,
    updateProgressData,
    isFetching,
    fetchKeys: allKeys,
    userProgresses,
  }
}

export default useProgressSteps
