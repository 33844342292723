import { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { Container, Form, Grid, Image } from 'semantic-ui-react'

import {
  SEND_RESET_PASSWORD_EMAIL_KEY,
  sendResetPasswordEmail,
} from '../../actions/authActions'
import { Dispatch, ReduxState } from '../../utils/typeHelpers'
import {
  Text,
  Alert,
  Input,
  Button,
  Link,
  Card,
  GridRowColumn,
} from '../BaseComponents'
import { getFetchError } from '../../reducers/fetch'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps

interface State {
  email: string
}

const transformErrorMessage = (message: string) =>
  message === 'email must be unique'
    ? 'An account already exists with this email.'
    : message

class ResetPassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      email: '',
    }
  }

  renderPasswordResetForm() {
    const {
      security: { resetPasswordEmailSent },
      sendResetPasswordEmail,
      error,
    } = this.props

    if (resetPasswordEmailSent) {
      return (
        <Container
          fluid
          className="finances reset"
          style={{ paddingTop: '3em', width: '100%' }}
        >
          <Text as="h2">Please check your email!</Text>
          <Text as="bodyMd">
            We&#39;ve sent over a link that you will use to reset your password.
          </Text>
        </Container>
      )
    } else {
      return (
        <Container
          fluid
          className="finances reset"
          style={{ paddingTop: '3em', width: '100%' }}
        >
          <Grid textAlign="center">
            <GridRowColumn centerContent>
              <Image
                src="https://heard-images.s3.us-west-1.amazonaws.com/heard_logo_green_2x.png"
                alt="heard logo"
                style={{ height: 20 }}
              />
            </GridRowColumn>
            <GridRowColumn textAlign="center">
              <Text as="h2">Reset Your Password</Text>
            </GridRowColumn>
            <Grid.Row>
              <Grid.Column computer={6} mobile={15} tablet={12}>
                <Card>
                  <Form
                    onSubmit={(event) => {
                      event.preventDefault()
                      sendResetPasswordEmail(this.state.email.toLowerCase())
                    }}
                    error
                  >
                    <Grid>
                      {error && (
                        <GridRowColumn>
                          <div>
                            <Alert type="error">
                              {transformErrorMessage(error.message)}
                            </Alert>
                            <br />
                          </div>
                        </GridRowColumn>
                      )}
                      <GridRowColumn>
                        <Input
                          placeholder="Email"
                          label="Email"
                          type="email"
                          value={this.state.email}
                          onChange={(email) => this.setState({ email })}
                          required
                          fullWidth
                        />
                      </GridRowColumn>
                      <br />
                      <GridRowColumn>
                        <Button
                          id="btn-auth-reset-password-email"
                          fullWidth
                          type="submit"
                          disabled={!this.state.email}
                        >
                          Send Reset Email
                        </Button>
                      </GridRowColumn>
                    </Grid>
                  </Form>
                </Card>
                <Link to="/login">Return to login</Link>
                <Link to="/auth/reset" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      )
    }
  }

  render() {
    if (this.props.security.isAuthenticated) {
      return <Navigate to="/dashboard" />
    }

    return this.renderPasswordResetForm()
  }
}

const mapStateToProps = (state: ReduxState) => ({
  security: state.auth.security,
  error: getFetchError(state, SEND_RESET_PASSWORD_EMAIL_KEY),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  sendResetPasswordEmail: (email: string) =>
    dispatch(sendResetPasswordEmail({ email })),
})

export default connect<
  StateProps,
  DispatchProps,
  Record<string, unknown>,
  ReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword)
