import { useCallback, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useReselector } from '../utils/sharedHooks'
import { getAllTransactionCategories } from '../features/Reports/reports.selectors'
import { getAllTransactionFilters } from '../features/Transactions/transactions.selectors'
import { transactionFilterToQueryUrlService } from '../services/transactionFilterToQueryUrlService'
import { MAX_TRANSACTION_LIMIT } from '../constants/transactionConstants'
import { fetchFilteredTransactions } from '../actions/adminActions'
import { parse } from 'json2csv'
import {
  Transaction,
  TransactionOrSplit,
} from '../reducers/admin/allTransactions.slice'
import { centsToDollars } from '../utils/currencyHelpers'
import { parseErrorFromCatch } from '../utils/errorHelpers'
import { Button, Grid, Icon, Message } from 'semantic-ui-react'
import {
  CursorPaginatedResponse,
  PrismaCursorPagination,
} from '../utils/fetchHelpers'
import { useAppDispatch } from '../utils/typeHelpers'

export const filterByPersonalOrBusiness = (
  transactions: Transaction[],
  transactionType: 'personal' | 'business'
) => {
  const filteredTransactions: TransactionOrSplit[] = []

  for (const transaction of transactions) {
    if (!transaction.type || transaction.type === transactionType) {
      filteredTransactions.push(transaction)
    }

    if (transaction.splitTransactions) {
      for (const splitTransaction of transaction.splitTransactions) {
        if (
          !splitTransaction.type ||
          splitTransaction.type === transactionType
        ) {
          filteredTransactions.push(splitTransaction)
        }
      }
    }
  }

  return filteredTransactions
}

// This is a hack to get around cases where a value may have a comma.  Basically it replaces commas with a low quotation mark to get around csv delimiting errors
export const sanitizeField = (value: string | undefined) =>
  value?.replaceAll(',', '‚')

const AdminExportTransactionCsv = ({
  fetchData = undefined,
}: {
  fetchData?: (
    pagination: PrismaCursorPagination
  ) => Promise<CursorPaginatedResponse<Transaction>>
}) => {
  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [csvData, setCsvData] = useState('')
  const transactionCategories = useReselector(getAllTransactionCategories)

  const transactionFilters = useReselector(getAllTransactionFilters)
  const dispatch = useAppDispatch()
  // Workaround for async https://github.com/react-csv/react-csv/issues/189
  useEffect(() => {
    if (csvData !== '' && csvLink?.current?.link && !isLoading) {
      csvLink?.current?.link.click()
    }
  }, [csvData, isLoading])

  const transactionFetcher = useCallback(async () => {
    if (fetchData) {
      // Fetch all transactions in 500 transaction chunks
      const transactions: Transaction[] = []
      let pagination: PrismaCursorPagination = { take: 500 }
      do {
        const result = await fetchData(pagination)
        if (result.data) {
          transactions.push(...result.data)
        }
        pagination = {
          ...pagination,
          cursor: result.pagination?.nextCursor,
        }
      } while (
        pagination.cursor &&
        transactions.length < Number(MAX_TRANSACTION_LIMIT)
      )

      return transactions
    }

    if (!transactionFilters.userId) {
      return []
    }

    const query = transactionFilterToQueryUrlService({
      ...transactionFilters,
      userId: transactionFilters.userId,
      limit: MAX_TRANSACTION_LIMIT,
    })
    const result = await fetchFilteredTransactions(query)(dispatch)
    return filterByPersonalOrBusiness(
      result?.transactions ?? [],
      transactionFilters.transactionsType === 'excluded'
        ? 'personal'
        : 'business'
    )
  }, [fetchData, transactionFilters, dispatch])

  const fetchCsvData = useCallback(async () => {
    if (!transactionFilters.userId) {
      return
    }
    setIsLoading(true)
    try {
      const transactions = await transactionFetcher()
      if (transactions?.length) {
        setCsvData(
          parse(transactions, {
            // Map the transaction object to the expected output for CSV
            transforms: [
              (transaction: Transaction) => {
                const category =
                  (transaction.transactionCategoryId &&
                    transactionCategories[transaction.transactionCategoryId]) ||
                  null

                return {
                  ID: transaction.id,
                  'Financial Account Id': transaction.financialAccountId,
                  Date: transaction.date,
                  'Expense Type': transaction.type,
                  'Category Type': sanitizeField(category?.accountType),
                  'Category Name': sanitizeField(category?.name),
                  Description: sanitizeField(transaction.description),
                  'Amount in $': centsToDollars(
                    Number(transaction.amountInCents)
                  ),
                }
              },
            ],
            quote: '',
            fields: [
              'ID',
              'Financial Account Id',
              'Date',
              'Expense Type',
              'Category Type',
              'Category Name',
              'Description',
              'Amount in $',
            ],
          })
        )
      } else {
        setError('No transactions found')
      }
    } catch (e) {
      setError(`Something went wrong: ${parseErrorFromCatch(e)}`)
    }

    setIsLoading(false)
  }, [transactionCategories, transactionFilters, transactionFetcher])

  return (
    <Grid stackable doubling className="noPadding">
      <Grid.Row>
        <Grid.Column width={4} floated="right">
          <Button
            float="right"
            loading={isLoading}
            onClick={fetchCsvData}
            fluid
          >
            <Icon name="download" />
            Download All Transactions
          </Button>
          <CSVLink
            data={csvData}
            filename="data.csv"
            style={{ display: 'none' }}
            ref={csvLink}
            target="_blank"
          >
            Download me
          </CSVLink>
          {error && <Message error>{error}</Message>}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default AdminExportTransactionCsv
