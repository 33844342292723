import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

import { fetchIfNeededWrapper, fetchWrapper } from '../../reducers/fetch'
import { keyBy } from 'lodash'

export enum AdvisoryCallEventType {
  intro = 'intro',
  advisory_session = 'advisory_session',
}

export interface UserFinancialAdvisoryCallEvent {
  id: number
  userId?: number
  createdAt: string
  updatedAt: string
  deletedAt?: string | null
  startTime: string
  endTime: string
  rescheduledAt?: string | null
  transcriptUserDocumentId?: number | null
  recordingLink?: string | null
  eventStatus: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rawCalendlySchedulingResponses: any | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rawCalendlyEventBlob: any | null
  eventUri: string | null
  eventType: AdvisoryCallEventType
}

export interface UserFinancialAdvisoryCallEventState {
  [key: string]: UserFinancialAdvisoryCallEvent
}

const userFinancialAdvisoryCallEventSlice = createSlice({
  name: 'userFinancialAdvisoryCallEvents',
  initialState: {} as UserFinancialAdvisoryCallEventState,
  reducers: {
    receiveAllUserFinancialAdvisoryCallEvents: (
      state,
      action: PayloadAction<UserFinancialAdvisoryCallEventState>
    ) => ({
      ...state,
      ...action.payload,
    }),
    receiveSingleUserFinancialAdvisoryCallEvent: (
      state,
      action: PayloadAction<UserFinancialAdvisoryCallEvent>
    ) => {
      state[action.payload.id] = action.payload
    },
  },
})

export default userFinancialAdvisoryCallEventSlice.reducer

const {
  receiveAllUserFinancialAdvisoryCallEvents,
  receiveSingleUserFinancialAdvisoryCallEvent,
} = userFinancialAdvisoryCallEventSlice.actions

export const FETCH_USER_FINANCIAL_ADVISORY_CALL_EVENTS_KEY =
  'FETCH_USER_FINANCIAL_ADVISORY_CALL_EVENTS_KEY'

export const fetchUserFinancialAdvisoryCallEventsIfNeeded = (
  alwaysFetch = false
) =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_USER_FINANCIAL_ADVISORY_CALL_EVENTS_KEY,
    defaultErrorMessage:
      'There was an error fetching user financial advisory call events.',
    alwaysFetch,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<UserFinancialAdvisoryCallEvent[]>(
        '/finances/api/v1/user_financial_advisory_call_events'
      )

      dispatch(
        receiveAllUserFinancialAdvisoryCallEvents(keyBy(json.data, 'id'))
      )
      return json.data
    },
  })

export const createUserFinancialAdvisoryCallEvent = (
  data: Partial<UserFinancialAdvisoryCallEvent>
) =>
  fetchWrapper({
    defaultErrorMessage:
      'There was an error creating the user financial advisory call event.',
    fetchFunction: async (dispatch) => {
      const json = await axios.post<UserFinancialAdvisoryCallEvent>(
        '/finances/api/v1/user_financial_advisory_call_events',
        data
      )
      dispatch(receiveSingleUserFinancialAdvisoryCallEvent(json.data))
      return json.data
    },
  })

export const updateUserFinancialAdvisoryCallEvent = (
  id: string,
  data: UserFinancialAdvisoryCallEvent
) =>
  fetchWrapper({
    defaultErrorMessage:
      'There was an error updating the user financial advisory call event.',
    fetchFunction: (dispatch) =>
      axios
        .put<UserFinancialAdvisoryCallEvent>(`/${id}`, data)
        .then((response) => {
          dispatch(receiveSingleUserFinancialAdvisoryCallEvent(response.data))
          return response.data
        }),
  })

export const deleteUserFinancialAdvisoryCallEvent = (id: string) =>
  fetchWrapper({
    defaultErrorMessage:
      'There was an error deleting the user financial advisory call event.',
    fetchFunction: (dispatch) =>
      axios.delete(`$/${id}`).then(() => {
        dispatch(
          receiveSingleUserFinancialAdvisoryCallEvent(
            {} as UserFinancialAdvisoryCallEvent
          )
        )
        return id
      }),
  })

export const fetchBetaStatus = () =>
  fetchWrapper({
    defaultErrorMessage:
      'There was an error deleting the user financial advisory call event.',
    fetchFunction: async () => {
      const json = await axios.get(
        '/finances/api/v1/user_financial_advisory_call_events/beta_status'
      )
      return json.data
    },
  })
