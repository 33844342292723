import { useState, useEffect } from 'react'
import { useAppDispatch } from '../../../utils/typeHelpers'

import {
  Container,
  Divider,
  Header,
  Table,
  Button,
  Modal,
  Form,
  Message,
  Grid,
} from 'semantic-ui-react'
import CurrencyInput from 'react-currency-input-field'
import Moment from 'react-moment'
import CurrencyFormatLabel from '../../../components/shared/CurrencyFormatLabel'
import moment from 'moment-timezone'
import { adminGroupUserTaxEstimates } from '../../Taxes/QuarterlyTaxEstimates/userTaxEstimates.selector'

import {
  adminUpdateUserTaxEstimate,
  fetchSingleUserTaxEstimates,
  notifyUserTaxEstimates,
  UserTaxEstimate,
} from '../../Taxes/QuarterlyTaxEstimates/userTaxEstimates.slice'
import { getFinancialProfileByUserId } from '../../../selectors/user.selectors'
import { centsToDollars, dollarsToCents } from '../../../utils/currencyHelpers'
import { DatePicker } from '../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useReselector } from '../../../utils/sharedHooks'

/*
  Handles ALL UserTaxEstimates by quarter
*/
interface UserTaxEstimateUpdateModalProps {
  open: boolean
  close: () => void
  estimates: UserTaxEstimate[]
  taxQuarter: string
  userId: number
  homeState?: string | null
}
const UserTaxEstimateUpdateModal = ({
  open,
  close,
  estimates,
  taxQuarter,
  userId,
  homeState,
}: UserTaxEstimateUpdateModalProps) => {
  const dispatch = useAppDispatch()
  const [newEstimates, setNewEstimates] = useState(estimates || [])
  const [loading, setLoading] = useState(false)
  const [notifying, setNotifying] = useState(false)
  const [updated, setUpdated] = useState(false)
  const [notified, setNotified] = useState(false)

  const updateTaxEstimates = async () => {
    setLoading(true)
    for (const key of Object.keys(newEstimates)) {
      const estimate: UserTaxEstimate = newEstimates[Number(key)]
      await adminUpdateUserTaxEstimate(estimate.id, estimate)(dispatch)
    }
    setLoading(false)
    setUpdated(true)
  }

  const updateEstimates = (idx: number, floatValue: string) => {
    const newValue = [...newEstimates]
    const newEstimatesValue = { ...newEstimates[idx] }
    newEstimatesValue.estimateInCents = dollarsToCents(floatValue)
    newValue[idx] = newEstimatesValue
    setNewEstimates(newValue)
  }

  const updateMinimumPayment = (idx: number, floatValue: string) => {
    const newValue = [...newEstimates]
    const newEstimatesValue = { ...newEstimates[idx] }
    newEstimatesValue.minimumPaymentInCents = dollarsToCents(floatValue)
    newValue[idx] = newEstimatesValue
    setNewEstimates(newValue)
  }

  const updateAmountPaid = (idx: number, floatValue: string) => {
    const newValue = [...newEstimates]

    const newEstimatesValue = { ...newEstimates[idx] }
    newEstimatesValue.amountPaidInCents = dollarsToCents(floatValue)
    newValue[idx] = newEstimatesValue
    setNewEstimates(newValue)
  }

  const updatePaidOnDate = (idx: number, value: string) => {
    const newValue = [...newEstimates]
    const newEstimatesValue = { ...newEstimates[idx] }
    newEstimatesValue.paidAt = value ? value : null
    newEstimatesValue.quarterPaid = value ? true : null
    newValue[idx] = newEstimatesValue
    setNewEstimates(newValue)
  }

  const updateEstimatesNoPayment = (
    idx: number,
    noTaxPaymentNeeded: boolean
  ) => {
    const newValue = [...newEstimates]
    const newObj = {
      ...newValue[idx],
    }
    newObj.noTaxPaymentNeeded = noTaxPaymentNeeded
    newObj.estimateInCents = 0
    newValue[idx] = newObj
    setNewEstimates(newValue)
  }

  const notifyUser = async () => {
    setNotifying(true)
    const res = await notifyUserTaxEstimates(userId, taxQuarter)(dispatch)
    setNotifying(false)
    setNotified(Boolean(res?.status))
  }

  /*
    Should be disabled if admin has clicked "notify" already
  */
  const checkNotifyDisabled = () => {
    let errorCount = 0

    for (const estimate of newEstimates) {
      /* check if we have estimate for their federal taxes */
      if (
        estimate.type === 'federal' &&
        !estimate.estimateInCents &&
        !estimate.noTaxPaymentNeeded
      ) {
        errorCount += 1
      } else if (estimate.type === 'state') {
        /* for state estimates, check if we have an estimate for their home state*/
        if (
          !estimate.noTaxPaymentNeeded &&
          estimate.filingState !== homeState
        ) {
          errorCount += 1
        }
      }
    }

    return errorCount > 0
  }

  return (
    <Modal
      size={'small'}
      dimmer={'inverted'}
      className="userTaxEstimateUpdateModal"
      open={open}
      onClose={close}
    >
      <Modal.Content>
        <Header as="h3">
          Update Quarterly Tax Estimates for: Q
          {moment(taxQuarter, 'YYYY-Q').format(DATE_FORMATS.QUARTER)}
        </Header>
        {updated && <Message positive>Estimates updated!</Message>}
        {notified && <Message positive>{notified}</Message>}
        <Message error>
          To Update Tax estimates:
          <p>1. Update all relevant tax estimates (state and federal)</p>
          <p>
            2. Click &#34;Notify&#34; — this will notify user that the estimate
            is ready
          </p>
          <br />
          <b>
            WARNING — Please ensure estimates are complete before notifying
            user.
          </b>
        </Message>
        <Grid doubling stackable padded celled="internally">
          {estimates.map((estimate, idx) => {
            return (
              <Grid.Row key={estimate.id}>
                <Grid.Column width={6}>
                  <Header as="h3" className="capitalize">
                    {estimate.type}
                    {estimate.type === 'state' && (
                      <span> — {estimate.filingState}</span>
                    )}
                  </Header>
                  <p>
                    Notified On:{' '}
                    {estimate.notifiedAt ? (
                      <Moment format={DATE_FORMATS.DISPLAY_SHORT}>
                        {estimate.notifiedAt}
                      </Moment>
                    ) : (
                      'N/A'
                    )}
                  </p>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Form>
                    <Form.Checkbox
                      label="Mark No Tax Payment Needed (Tax Estimate is $0)"
                      checked={newEstimates[idx].noTaxPaymentNeeded || false}
                      onChange={() =>
                        updateEstimatesNoPayment(
                          idx,
                          !newEstimates[idx].noTaxPaymentNeeded
                        )
                      }
                    />
                    {!newEstimates[idx].noTaxPaymentNeeded && (
                      <>
                        <b>
                          <label>Input Quarterly Tax Estimate:</label>
                        </b>
                        <CurrencyInput
                          prefix="$"
                          allowNegativeValue={false}
                          defaultValue={
                            centsToDollars(newEstimates[idx].estimateInCents) ||
                            '0'
                          }
                          onValueChange={(
                            _: unknown,
                            __: unknown,
                            values?: { float: number | null }
                          ) =>
                            updateEstimates(
                              idx,
                              values?.float?.toString() || '0'
                            )
                          }
                        />
                      </>
                    )}
                    <>
                      <b>
                        <label>Input Minimum Tax Payment:</label>
                      </b>
                      <CurrencyInput
                        prefix="$"
                        allowNegativeValue={false}
                        defaultValue={
                          centsToDollars(
                            newEstimates[idx].minimumPaymentInCents
                          ) || '0'
                        }
                        onValueChange={(
                          _: unknown,
                          __: unknown,
                          values?: { float: number | null }
                        ) =>
                          updateMinimumPayment(
                            idx,
                            values?.float?.toString() || '0'
                          )
                        }
                      />
                    </>
                    <>
                      <b>
                        <label>Quarterly Tax Estimate Paid:</label>
                      </b>
                      <CurrencyInput
                        prefix="$"
                        allowNegativeValue={false}
                        defaultValue={centsToDollars(
                          newEstimates[idx].amountPaidInCents
                        )}
                        onValueChange={(
                          _: unknown,
                          __: unknown,
                          values?: { float: number | null }
                        ) =>
                          updateAmountPaid(
                            idx,
                            values?.float?.toString() || '0'
                          )
                        }
                      />
                    </>
                    <b>
                      <label>Quarterly Tax Estimate Paid At:</label>
                    </b>
                    <DatePicker
                      value={newEstimates[idx].paidAt || ''}
                      placeholder="Quarterly Tax Estimate Paid At:"
                      format={DATE_FORMATS.INPUT}
                      maxDate={new Date()}
                      onChange={(value) => updatePaidOnDate(idx, value)}
                      fullWidth
                    />
                  </Form>
                </Grid.Column>
              </Grid.Row>
            )
          })}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => close()}>
          Close
        </Button>
        <Button
          secondary
          loading={notifying}
          disabled={checkNotifyDisabled()}
          onClick={() => {
            notifyUser()
          }}
        >
          Notify User Estimates Ready
        </Button>
        <Button
          primary
          loading={loading}
          onClick={() => {
            updateTaxEstimates()
          }}
        >
          Update Estimates
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const TaxEstimateValueCellGroup = ({
  estimate,
}: {
  estimate: UserTaxEstimate
}) => {
  if (estimate.noTaxPaymentNeeded) {
    /* If no tax payment needed, do not show to user*/
    return (
      <Table.Cell colSpan="2">No payment needed — Tax estimate of 0</Table.Cell>
    )
  } else {
    return (
      <>
        <Table.Cell>
          {!estimate.estimateInCents ? (
            'No Estimate Yet'
          ) : (
            <CurrencyFormatLabel
              value={centsToDollars(estimate.estimateInCents)}
            />
          )}
        </Table.Cell>
        <Table.Cell>
          {!estimate.minimumPaymentInCents ? (
            'N/A'
          ) : (
            <CurrencyFormatLabel
              value={centsToDollars(estimate.minimumPaymentInCents)}
            />
          )}
        </Table.Cell>

        <Table.Cell>
          {!estimate.amountPaidInCents ? (
            'N/A'
          ) : (
            <CurrencyFormatLabel
              value={centsToDollars(estimate.amountPaidInCents)}
            />
          )}
        </Table.Cell>
      </>
    )
  }
}

const TaxEstimateRow = ({
  estimate,
  showEstimate,
}: {
  estimate: UserTaxEstimate
  showEstimate: boolean
}) => {
  return (
    <Table.Row
      key={estimate.id}
      error={!estimate.paidAt && showEstimate && !estimate.noTaxPaymentNeeded}
    >
      <Table.Cell>{estimate.id}</Table.Cell>
      <Table.Cell>{estimate.type}</Table.Cell>
      <Table.Cell>{estimate.filingState || 'N/A'}</Table.Cell>
      <TaxEstimateValueCellGroup estimate={estimate} />

      <Table.Cell error={!estimate.paidAt}>
        {estimate.paidAt ? (
          <Moment format={DATE_FORMATS.DISPLAY_SHORT}>{estimate.paidAt}</Moment>
        ) : (
          'Unpaid'
        )}
      </Table.Cell>
      <Table.Cell>
        {estimate.notifiedAt ? (
          <Moment format={DATE_FORMATS.DISPLAY_SHORT}>
            {estimate.notifiedAt}
          </Moment>
        ) : (
          'N/A'
        )}
      </Table.Cell>
      <Table.Cell>{estimate.status}</Table.Cell>
    </Table.Row>
  )
}

/*
  Used to denote tax estimates by Quarter
*/
const TaxEstimateQuarter = ({
  estimates,
  firstEstimate,
  userId,
  homeState,
}: {
  estimates: UserTaxEstimate[]
  firstEstimate: UserTaxEstimate
  userId: number
  homeState?: string | null
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  return (
    <>
      <Table.Row error={!firstEstimate.paidAt}>
        <Table.Cell rowSpan={estimates.length}>
          Q{' '}
          {moment(firstEstimate.taxQuarter, 'YYYY-Q').format(
            DATE_FORMATS.QUARTER
          )}
        </Table.Cell>
        <Table.Cell>{firstEstimate.id}</Table.Cell>
        <Table.Cell>{firstEstimate.type}</Table.Cell>
        <Table.Cell>{firstEstimate.filingState || 'N/A'}</Table.Cell>
        <Table.Cell>
          {!firstEstimate.estimateInCents ? (
            'No Estimate Yet'
          ) : (
            <CurrencyFormatLabel
              value={centsToDollars(firstEstimate.estimateInCents)}
            />
          )}
        </Table.Cell>
        <Table.Cell>
          {!firstEstimate.minimumPaymentInCents ? (
            'N/A'
          ) : (
            <CurrencyFormatLabel
              value={centsToDollars(firstEstimate.minimumPaymentInCents)}
            />
          )}
        </Table.Cell>
        <Table.Cell>
          {!firstEstimate.amountPaidInCents ? (
            'N/A'
          ) : (
            <CurrencyFormatLabel
              value={centsToDollars(firstEstimate.amountPaidInCents)}
            />
          )}
        </Table.Cell>
        <Table.Cell error={!firstEstimate.paidAt}>
          {firstEstimate.paidAt ? (
            <Moment format={DATE_FORMATS.DISPLAY_SHORT}>
              {firstEstimate.paidAt}
            </Moment>
          ) : (
            'Unpaid'
          )}
        </Table.Cell>
        <Table.Cell>
          {firstEstimate.notifiedAt ? (
            <Moment format={DATE_FORMATS.DISPLAY_SHORT}>
              {firstEstimate.notifiedAt}
            </Moment>
          ) : (
            'N/A'
          )}
        </Table.Cell>
        <Table.Cell>{firstEstimate.status}</Table.Cell>
        <Table.Cell rowSpan={estimates.length}>
          <Button className="link" onClick={() => setModalOpen(true)}>
            Update
          </Button>
        </Table.Cell>
        {modalOpen && (
          <UserTaxEstimateUpdateModal
            userId={userId}
            open={modalOpen}
            close={() => setModalOpen(false)}
            estimates={estimates}
            taxQuarter={firstEstimate.taxQuarter}
            homeState={homeState}
          />
        )}
      </Table.Row>
      {estimates.slice(1).map((estimate) => {
        const showEstimate: boolean =
          estimate.filingState === homeState || !homeState

        return (
          <TaxEstimateRow
            estimate={estimate}
            key={estimate.id}
            showEstimate={showEstimate}
          />
        )
      })}
    </>
  )
}

interface TaxTabPanelProps {
  userId: number
}
const TaxTabPanel = ({ userId }: TaxTabPanelProps) => {
  const dispatch = useAppDispatch()
  const userTaxEstimates = useReselector(adminGroupUserTaxEstimates, userId)
  const financialProfile = useReselector(getFinancialProfileByUserId, userId)

  useEffect(() => {
    fetchSingleUserTaxEstimates(userId)(dispatch)
  }, [userId, dispatch])

  const renderEstimates = () => {
    if (Object.keys(userTaxEstimates).length === 0) {
      return (
        <div>
          <Header as="h6">No Quarterly Tax estimates</Header>
        </div>
      )
    }

    return (
      <Table striped compact="very" structured celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Tax Quarter</Table.HeaderCell>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Filing State</Table.HeaderCell>
            <Table.HeaderCell>Estimate</Table.HeaderCell>
            <Table.HeaderCell>Minimum Estimate</Table.HeaderCell>
            <Table.HeaderCell>Amount Paid</Table.HeaderCell>
            <Table.HeaderCell>Paid At</Table.HeaderCell>
            <Table.HeaderCell>Notified At</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {financialProfile &&
            Object.keys(userTaxEstimates).map((taxQuarter) => {
              const estimates: UserTaxEstimate[] = userTaxEstimates[taxQuarter]
              const firstEstimate: UserTaxEstimate = estimates[0]
              return (
                <TaxEstimateQuarter
                  key={taxQuarter}
                  userId={userId}
                  estimates={estimates}
                  firstEstimate={firstEstimate}
                  homeState={financialProfile.homeState}
                />
              )
            })}
        </Table.Body>
      </Table>
    )
  }

  return (
    <Container fluid className="userTaxEstimatesCard">
      <Header as="h4">Quarterly Tax Estimates</Header>
      {renderEstimates()}
      <Divider />
    </Container>
  )
}

export default TaxTabPanel
