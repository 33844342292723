import { CSSProperties } from 'react'
import { Table, Header, Image } from 'semantic-ui-react'

interface OwnProps {
  imageUrl: string
  subtitle: string
  style: CSSProperties
  title: string
}

type Props = OwnProps

const ProfileCell = (props: Props) => (
  <Table.Cell style={{ ...props.style }}>
    <Header as="h6" image>
      <Image src={props.imageUrl} rounded size="mini" />
      <Header.Content>
        {props.title}
        <Header.Subheader>{props.subtitle}</Header.Subheader>
      </Header.Content>
    </Header>
  </Table.Cell>
)

export default ProfileCell
