import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  PlaidLinkError,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkStableEvent,
  usePlaidLink,
} from 'react-plaid-link'

import {
  createPlaidLinkEvent,
  updatePlaidItem,
  updateAccounts,
} from '../../../actions/financialAccountActions'
import {
  fetchAccessToken,
  fetchLinkToken,
  fetchUpdateLinkToken,
} from '../../../actions/plaid/authActions'
import { logSentryMessage } from '../../../utils/sentryHelpers'
import { FinancialAccount } from '../../../reducers/finances/financialAccountsReducer'
import { FinancialAccountWithAdminInfo } from '../../../reducers/admin/financialAccountsReducer'
import { markUserActionItemCompleteIfExists } from '../../../features/Dashboard/UserActionItems/service'
import {
  fetchUserActionItemsIfNeeded,
  updateUserActionItem,
  UserActionItemActionItemIdentifiers,
} from '../../../features/Dashboard/UserActionItems/userActionItems.slice'
import { OnboardingTaskIdents } from '../../../features/Onboarding/config'
import { fetchPlaidItems } from '../../../actions/plaidItemActions'
import {
  PlaidLinkContext,
  PlaidPostAuthModalType,
} from '../../Finances/Accounts/PlaidLinkContext'
import { keyBy } from 'lodash'
import { useAnalyticsTrack } from '../../../features/Amplitude'
import { PLAID_ERRORS } from './copyConstants'
import { ConnectBusinessBankAccountStatus } from '../../../features/Dashboard/UserActionItems/userActionItemStatuses'
import { getUserActionItemByActionItemIdentifier } from '../../../features/Dashboard/UserActionItems/userActionItems.selectors'
import { useReselector } from '../../../utils/sharedHooks'
import { fetchPlaidStatementInstitutionsIfNeeded } from '../../../actions/plaidStatementInstitutionActions'
import { selectActivePlaidStatementInstitutions } from '../../../selectors/financeSelectors'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useCompleteOnboardingStepIfNeeded } from '../../../features/Onboarding/UserOnboardingSteps/onboarding.hooks'
import { GettingStartedOnboardingStep } from '../../../features/Onboarding/UserOnboardingSteps/onboarding.reducer'

export const useHeardPlaid = ({
  account = null,
  // Frontend navigation after success/failure
  redirectLink,
  // Navigation link tied to plaid token
  redirectUri,
  // Used for oauth configuration.  Requires oauth_state_id query param in url
  receivedRedirectUri,
  linkToken,
  disconnected,
}: {
  account?: FinancialAccount | FinancialAccountWithAdminInfo | null
  redirectLink?: string
  redirectUri?: string
  receivedRedirectUri?: string
  linkToken?: string
  disconnected?: boolean
} = {}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const track = useAnalyticsTrack()

  const [token, setToken] = useState(linkToken || '')
  const [initialStatementPermissions] = useState(account?.statementPermissions)

  const { setDuplicateInstitution, setPlaidError, setPostAuthModal } =
    useContext(PlaidLinkContext)

  useEffect(() => {
    dispatch(fetchUserActionItemsIfNeeded())
    dispatch(fetchPlaidStatementInstitutionsIfNeeded())
  }, [dispatch])

  const actionItem = useReselector(
    getUserActionItemByActionItemIdentifier,
    OnboardingTaskIdents.CONNECT_A_BUSINESS_BANK_ACCOUNT
  )

  const plaidStatementInstitutions = useReselector(
    selectActivePlaidStatementInstitutions
  )

  const connectBusinessBankAccountOnboardingStep =
    useCompleteOnboardingStepIfNeeded(
      GettingStartedOnboardingStep.connectBusinessBankAccount
    )

  const asyncFetchLinkToken = useCallback(async () => {
    let res
    // If account is passed, it means we want to pull an update Link token
    if (account?.id) {
      res = await fetchUpdateLinkToken(
        redirectUri,
        account?.plaidItemId,
        disconnected
      )(dispatch)
    } else {
      res = await fetchLinkToken(redirectUri)(dispatch)
    }
    if (res) {
      localStorage.setItem('link_token', res.link_token)
      setToken(res.link_token)
    }
  }, [account?.id, account?.plaidItemId, redirectUri, dispatch, disconnected])

  /**
   * Determines if the PostPlaidAuthModal should be shown and
   * what content it should display
   */
  const updatePostAuthModalState = useCallback(
    (institutionId?: string, institutionName?: string) => {
      let postAuthModalType: PlaidPostAuthModalType | null = null
      // connections (even if they have already onboarded)
      if (!account?.id) {
        const isPlaidStatementInstitution = plaidStatementInstitutions.find(
          (psi) => psi.institutionId === institutionId
        )
        if (
          isPlaidStatementInstitution &&
          initialStatementPermissions !== 'plaid_statement'
        ) {
          if (account?.bankAccessEnabledAt) {
            postAuthModalType = PlaidPostAuthModalType.UPGRADE_FROM_LBA
          } else {
            postAuthModalType =
              PlaidPostAuthModalType.UPGRADE_FROM_MANUAL_STATEMENTS
          }
        }
      }

      if (postAuthModalType && institutionId && institutionName) {
        setPostAuthModal?.({
          institutionId,
          institutionName,
          type: postAuthModalType,
        })
      } else {
        setPostAuthModal?.(null)
      }
    },
    [
      account?.id,
      account?.bankAccessEnabledAt,
      initialStatementPermissions,
      plaidStatementInstitutions,
      setPostAuthModal,
    ]
  )

  const onSuccess = useCallback(
    async (token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      const res = await fetchPlaidItems()(dispatch)
      if (!res) {
        return setPlaidError?.({
          message:
            'There was a problem with Plaid connecting to our server. Please wait a few minutes before refreshing the page and trying again.',
          errorCode: '',
        })
      }
      const plaidInstitutions = keyBy(res, 'institutionId')
      const institutionId = metadata.institution?.institution_id
      const institutionName = metadata.institution?.name

      if (account?.id) {
        if (!disconnected) {
          await dispatch(
            updateAccounts(account?.plaidItemId, {
              plaidAccounts: metadata.accounts,
            })
          )
        }
        const data = {
          needsReconnection: false,
          connectedOn: new Date(),
          disconnectedOn: null,
          errorObject: null,
          institutionId,
        }
        await dispatch(updatePlaidItem(account?.plaidItemId, data))
        await markUserActionItemCompleteIfExists(
          UserActionItemActionItemIdentifiers.reconnectBankAccount,
          (event, properties) => track(event, properties)
        )
      } else {
        if (!institutionId) {
          return setPlaidError?.({
            message: PLAID_ERRORS.ID_NOT_FOUND,
            errorCode: 'ID_NOT_FOUND',
          })
        }
        if (plaidInstitutions[institutionId]) {
          setDuplicateInstitution?.(institutionId)
          asyncFetchLinkToken()
          return null
        }
        dispatch(fetchAccessToken(token, metadata))
      }

      const { link_session_id: linkSessionId, accounts } = metadata
      dispatch(
        createPlaidLinkEvent({
          institutionId,
          accounts,
          linkSessionId,
          eventType: 'success',
        })
      )
      await markUserActionItemCompleteIfExists(
        OnboardingTaskIdents.CONNECT_A_BUSINESS_BANK_ACCOUNT,
        (event, properties) => track(event, properties)
      )
      await connectBusinessBankAccountOnboardingStep.markComplete({
        shouldRefetchAllOnboardingSteps: true,
      })

      if (actionItem)
        await updateUserActionItem(actionItem.id, {
          id: actionItem.id,
          status: ConnectBusinessBankAccountStatus.connected,
        })(dispatch)

      setDuplicateInstitution?.('')
      asyncFetchLinkToken()
      if (redirectLink) {
        navigate(redirectLink)
      }
      updatePostAuthModalState(institutionId, institutionName)
      return null
    },
    [
      dispatch,
      account?.id,
      account?.plaidItemId,
      connectBusinessBankAccountOnboardingStep,
      actionItem,
      setDuplicateInstitution,
      asyncFetchLinkToken,
      redirectLink,
      updatePostAuthModalState,
      setPlaidError,
      disconnected,
      track,
      navigate,
    ]
  )

  const onExit = useCallback(
    (err: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
      if (err) {
        const institutionId = metadata.institution?.institution_id
        const { link_session_id: linkSessionId, status: exitStatus } = metadata

        const { error_code: errorCode, error_type: errorType } = err
        const data = {
          institutionId,
          linkSessionId,
          errorCode,
          errorType,
          exitStatus,
          eventType: 'exit',
        }
        dispatch(createPlaidLinkEvent(data))
        logSentryMessage(`Plaid Error: ${JSON.stringify(err)}`)
        if (PLAID_ERRORS[errorType]) {
          setPlaidError?.({
            message: PLAID_ERRORS[errorType],
            errorCode,
          })
        } else if (PLAID_ERRORS[errorCode]) {
          setPlaidError?.({
            message: PLAID_ERRORS[errorCode],
            errorCode,
          })
        }
      }

      asyncFetchLinkToken()
      setDuplicateInstitution?.('')

      if (redirectLink) {
        navigate(redirectLink)
      }
    },
    [
      dispatch,
      redirectLink,
      navigate,
      asyncFetchLinkToken,
      setDuplicateInstitution,
      setPlaidError,
    ]
  )

  const onEvent = useCallback(
    (
      eventName: PlaidLinkStableEvent | string,
      metadata: PlaidLinkOnEventMetadata
    ) => {
      if (eventName === 'ERROR' && metadata.error_code !== null) {
        const {
          link_session_id: linkSessionId,
          institution_id: institutionId,
          error_code: errorCode,
          error_type: errorType,
          exit_status: exitStatus,
        } = metadata
        const data = {
          institutionId,
          linkSessionId,
          errorCode,
          errorType,
          exitStatus,
          eventType: 'error',
        }
        dispatch(createPlaidLinkEvent(data))
      }
      const { view_name, institution_name } = metadata
      const fi_name = institution_name || ''
      if (eventName === 'TRANSITION_VIEW') {
        if (view_name === 'SELECT_INSTITUTION') {
          track('clicked plaid continue to select FI')
        } else if (view_name === 'SELECT_ACCOUNT') {
          track('selected plaid accounts connected', {
            fi_name,
          })
        } else if (view_name === 'ERROR') {
          track('viewed plaid error screen', {
            fi_name,
          })
        }
      } else if (eventName === 'OPEN_OAUTH') {
        track('clicked plaid continue to login', {
          fi_name,
        })
      } else if (eventName === 'HANDOFF') {
        track('submitted successful plaid connection', {
          fi_name,
        })
        track('completed onboarding item connect financial accounts', {
          status: 'done',
        })
      } else if (eventName === 'EXIT') {
        track('clicked plaid exit flow', {
          fi_name,
        })
      }
    },
    [dispatch, track]
  )

  const { open, ready, error } = usePlaidLink({
    token,
    onSuccess,
    onExit,
    onEvent,
    receivedRedirectUri,
  })

  useEffect(() => {
    // if link token is sent in don't fetch it
    if (!linkToken) {
      asyncFetchLinkToken()
    }
  }, [asyncFetchLinkToken, linkToken])

  return { open, ready, error }
}
