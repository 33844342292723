import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
  LegendProps,
} from 'recharts'
import { Text } from '../../BaseComponents'
import {
  centsToDollars,
  formatCurrencyFromCents,
} from '../../../utils/currencyHelpers'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import {
  CATEGORY_FILTERS,
  ChartCategories,
  INSIGHTS_TYPE,
  getBarColor,
  insightsCopyConstants,
} from './utils'
import { Colors } from '../../../styles/theme'
import { includes } from 'lodash'
import { categoryColorMap } from './categoryColorMap'

const formatLabel = (value: string, categories?: ChartCategories): string => {
  return categories?.[value]?.category_name || value
}

const getTooltipTopLabel = (type: INSIGHTS_TYPE, filter?: string) => {
  if (type === INSIGHTS_TYPE.PROFIT) return ''
  if (!filter || filter === CATEGORY_FILTERS.ALL) {
    return `Top 3 ${insightsCopyConstants[type].label}`
  } else if (filter === CATEGORY_FILTERS.TOP) {
    return `Top ${insightsCopyConstants[type].label}`
  } else if (filter === CATEGORY_FILTERS.DEDUCTIBLE) {
    return 'Top Deductible Expenses'
  }
  return ''
}

const CustomTooltip = ({
  active,
  payload,
  filter,
  categories,
  type,
}: TooltipProps<number, string> & {
  filter?: string
  categories?: ChartCategories
  type: INSIGHTS_TYPE
}) => {
  if (active && payload?.length === 0) {
    return (
      <div
        style={{
          backgroundColor: Colors.white,
          padding: 12,
          borderRadius: 4,
          textAlign: 'center',
          visibility: 'visible',
          maxWidth: 140,
          boxShadow: '1px 2px 20px 1px #00000040',
        }}
      >
        <Text>{insightsCopyConstants[type].emptyChartMonth}</Text>
      </div>
    )
  } else if (active && payload?.length) {
    const totalAmount = payload.reduce(
      (sum, entry) => sum + (entry.value as number),
      0
    )

    const sortedPayload = payload
      .slice()
      .sort((a, b) => (b.value || 0) - (a.value || 0))

    const dateString = `${payload[0]?.payload?.month} ${payload[0]?.payload?.year}`

    const renderHeaders = includes(Object.values(CATEGORY_FILTERS), filter)

    return (
      <div
        style={{
          backgroundColor: Colors.white,
          padding: 12,
          borderRadius: 4,
          textAlign: 'center',
          boxShadow: '1px 2px 20px 1px #00000040',
        }}
      >
        <Text as="h3" style={{ marginBottom: 12 }}>
          {dateString}
        </Text>
        {renderHeaders && (
          <>
            <Text as="h3" style={{ marginBottom: 12 }}>
              {formatCurrencyFromCents(totalAmount)}
            </Text>
            <Text as="bodySm">
              <b>{getTooltipTopLabel(type, filter)}</b>
            </Text>
          </>
        )}
        {type === INSIGHTS_TYPE.PROFIT && (
          <>
            <Text as="bodySm">
              Income:{' '}
              {formatCurrencyFromCents(payload[0]?.payload?.total_income)}
            </Text>
            <Text as="bodySm">
              Expenses:{' '}
              {formatCurrencyFromCents(
                Math.abs(payload[0]?.payload?.total_expenses)
              )}
            </Text>
          </>
        )}
        {type !== INSIGHTS_TYPE.PROFIT &&
          sortedPayload
            .slice(0, 3)
            .map((entry) => (
              <Text key={entry.name} as="bodySm">{`${formatLabel(
                entry.name || '',
                categories
              )}: ${formatCurrencyFromCents(entry.value)}`}</Text>
            ))}
      </div>
    )
  }

  return null
}

const amountFormatter = (num: number) => {
  return `$${centsToDollars(num).toLocaleString()}`
}

const CustomLegend = ({
  payload,
  categories,
}: LegendProps & { categories: ChartCategories }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
      {payload?.map((entry) => (
        <div
          key={`legend-${entry.value}`}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: 8,
            gap: 8,
          }}
        >
          <div
            style={{
              width: 12,
              height: 12,
              backgroundColor: entry.color,
              borderRadius: 2,
            }}
          />
          <span style={{ color: Colors.black }}>
            {formatLabel(entry.value, categories)}
          </span>
        </div>
      ))}
    </div>
  )
}

export const InsightsChart = ({
  data,
  categories,
  filter,
  isChartDataEmpty,
  filteredCategories,
  type,
}: {
  data: {
    [key: string]: string | number | undefined
  }[]
  categories: ChartCategories
  filter?: string
  isChartDataEmpty: boolean
  filteredCategories: ChartCategories
  type: INSIGHTS_TYPE
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  return (
    <div style={{ position: 'relative' }}>
      {isChartDataEmpty && (
        <Text
          as="h3"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            color: 'grey',
          }}
        >
          {insightsCopyConstants[type].emptyChart}
        </Text>
      )}
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data} margin={{ left: isMobile ? 4 : 24 }}>
          <CartesianGrid horizontal vertical={false} />
          <XAxis dataKey="month" />
          <YAxis
            tickFormatter={amountFormatter}
            dataKey={type === INSIGHTS_TYPE.PROFIT ? 'profit' : undefined}
          />
          <Tooltip
            cursor={{ fill: Colors.stone40 }}
            content={
              <CustomTooltip
                filter={filter}
                categories={categories}
                type={type}
              />
            }
          />
          <Legend
            formatter={(value, _entry) => formatLabel(value, categories)}
            layout={isMobile ? 'horizontal' : 'vertical'}
            align={isMobile ? undefined : 'right'}
            verticalAlign={isMobile ? undefined : 'middle'}
            content={<CustomLegend categories={categories} />}
            wrapperStyle={{
              paddingLeft: 20,
              height: isMobile ? '20%' : '80%',
              overflow: 'auto',
              lineHeight: 1.7,
              boxShadow: '0px -20px 20px -20px rgba(0,0,0,0.15) inset',
            }}
          />
          {Object.entries(filteredCategories).map(([key, category]) => (
            <Bar
              key={category.id}
              dataKey={key}
              stackId="a"
              fill={categoryColorMap[key] || getBarColor(category.id)}
              barSize={isMobile ? 16 : 24}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
