import { useState, useCallback, useMemo, useEffect } from 'react'
import { Image, Grid } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import {
  Button,
  GridRowColumn,
  Link,
  Modal,
  Text,
  Radio,
} from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import { useReselector } from '../../../utils/sharedHooks'
import {
  getCurrentUser,
  selectSCorpRequiredIncome,
} from '../../../selectors/user.selectors'
import { useAnalyticsTrack } from '../../Amplitude'
import { ScorpCardAndModalButtonType } from './SCorpUpsellCard'
import { postSCorpElectionStatus } from './sCorpActions'
import { RequestSource, SCorpSetUpStatus } from './utils'
import { isUndefined } from 'lodash'
import { useAppDispatch } from '../../../utils/typeHelpers'

export enum SCorpModalType {
  INTERESTED = 'interested',
  PENDING = 'pending',
  ELIGIBLE = 'eligible',
  DISQUALIFIED_AUTOMATICALLY = 'disqualified_automatically',
}

const hideCloseTypes = [
  SCorpModalType.PENDING,
  SCorpModalType.DISQUALIFIED_AUTOMATICALLY,
]

const InterestedModalContent = ({
  handleInterested,
  title,
  bodyText,
  notEligible,
  learnMoreLink,
  image = 'https://heard-images.s3.amazonaws.com/assets/shiny-coins.svg',
  onClose,
  hideCard,
  trackUpsellModalButtonClick,
}: {
  title: string
  bodyText: string
  handleInterested: (status: SCorpSetUpStatus) => void
  taxSavings?: string
  notEligible?: boolean
  learnMoreLink?: string
  image?: string
  onClose?: () => void
  hideCard?: () => void
  trackUpsellModalButtonClick?: (button: ScorpCardAndModalButtonType) => void
}) => {
  const handleInterestedClick = () => {
    if (notEligible) {
      trackUpsellModalButtonClick?.(ScorpCardAndModalButtonType.GOT_IT)
      hideCard?.()
      onClose?.()
    } else {
      handleInterested(SCorpSetUpStatus.request_submitted)
    }
  }

  const handleNotInterestedClick = () => {
    handleInterested(SCorpSetUpStatus.not_interested)
  }

  return (
    <>
      <Modal.Header>
        <Text as="h2">{title}</Text>
      </Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Image src={image} />
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              {bodyText}{' '}
              <Link href={learnMoreLink} newPage>
                Learn more
              </Link>
            </Text>
          </GridRowColumn>
          {!notEligible && (
            <GridRowColumn short>
              <Text>Are you interested?</Text>
            </GridRowColumn>
          )}
          {!notEligible && (
            <GridRowColumn short>
              <Text color="darkGray">
                Note: Electing to become an S Corporation requires you to switch
                to a new plan ($299/month). See full pricing information for the
                S Corporation plan{' '}
                <Link href="https://www.joinheard.com/pricing" newPage>
                  here
                </Link>
                .
              </Text>
            </GridRowColumn>
          )}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <>
          {!notEligible && (
            <Button
              variant="link"
              onClick={handleNotInterestedClick}
              style={{ color: Colors.black, marginRight: 24 }}
            >
              Not now
            </Button>
          )}
          <Button onClick={handleInterestedClick}>
            {notEligible ? 'Got it' : "I'm interested"}
          </Button>
        </>
      </Modal.Actions>
    </>
  )
}

const PendingModalContent = ({
  onClose,
  hideCard,
}: {
  onClose: () => void
  hideCard: () => void
}) => {
  const handleDone = () => {
    hideCard()
    onClose()
  }

  return (
    <>
      <Modal.Header>
        <Text as="h2"> Got it! We Will Get Back To You </Text>
      </Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Image src="https://heard-images.s3.amazonaws.com/assets/coins-cloud.svg" />
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              We&apos;ve confirmed your interest in becoming an S corporation.
              You will hear back from the Heard team via Conversations.
            </Text>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleDone} variant="secondary">
          Done
        </Button>
      </Modal.Actions>
    </>
  )
}

const CurrentYearModalContent = ({
  onClose,
  setType,
  handleInterested,
  trackUpsellModalButtonClick,
}: {
  onClose: () => void
  setType: (type: SCorpModalType) => void
  handleInterested: (status: SCorpSetUpStatus) => void
  trackUpsellModalButtonClick: (button: ScorpCardAndModalButtonType) => void
}) => {
  const requiredIncome = useReselector(selectSCorpRequiredIncome)

  const [earnMore, setEarnMore] = useState<boolean>()

  const formattedIncome = `$${requiredIncome},000`

  const currentYear = DateTime.now().year

  const handleNextClick = () => {
    trackUpsellModalButtonClick(ScorpCardAndModalButtonType.NEXT)
    if (earnMore) {
      setType(SCorpModalType.ELIGIBLE)
    } else if (earnMore === false) {
      handleInterested(SCorpSetUpStatus.disqualified_automatically)
    }
  }

  const handleCancel = () => {
    trackUpsellModalButtonClick(ScorpCardAndModalButtonType.CANCEL)
    onClose()
  }

  return (
    <>
      <Modal.Header>
        <Text as="h2">Tell us a bit about your practice</Text>
      </Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Image src="https://heard-images.s3.amazonaws.com/assets/office.svg" />
          </GridRowColumn>
          <GridRowColumn>
            <Text as="h3">
              Do you expect to earn more than {formattedIncome} in profit in{' '}
              {currentYear} too?
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Radio
              label={`Yes, I expect to earn more than ${formattedIncome} in profit in ${currentYear}.`}
              checked={earnMore}
              onChange={() => setEarnMore(true)}
            />
          </GridRowColumn>
          <GridRowColumn short>
            <Radio
              label={`No, I will probably earn less than ${formattedIncome} in profit in ${currentYear}.`}
              checked={earnMore === false}
              onChange={() => setEarnMore(false)}
            />
          </GridRowColumn>
          <GridRowColumn>
            <Link
              href="https://www.joinheard.com/articles/when-should-my-therapy-practice-become-an-s-corp"
              newPage
            >
              <Text as="bodySm" style={{ fontWeight: 500 }}>
                When Should My Therapy Practice Become an S Corporation?
              </Text>
            </Link>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <>
          <Button
            variant="link"
            style={{ color: Colors.black, marginRight: 24 }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button onClick={handleNextClick} disabled={isUndefined(earnMore)}>
            Next
          </Button>
        </>
      </Modal.Actions>
    </>
  )
}

export const SCorpInterestedModal = ({
  open,
  onClose,
  isPreCurrentYear = true,
  taxSavings,
  hideCard,
}: {
  open: boolean
  onClose: () => void
  modalType?: SCorpModalType
  isPreCurrentYear?: boolean
  taxSavings?: string
  hideCard: () => void
}) => {
  const dispatch = useAppDispatch()

  const requiredIncome = useReselector(selectSCorpRequiredIncome)
  const currentUser = useReselector(getCurrentUser)

  const [type, setType] = useState(SCorpModalType.INTERESTED)
  const track = useAnalyticsTrack()

  const currentYear = useMemo(() => {
    const currentDate = DateTime.now()
    return currentDate.year
  }, [])

  const trackUpsellModalButtonClick = useCallback(
    (button: ScorpCardAndModalButtonType) => {
      track('clicked s corp upsell modal button', { button })
    },
    [track]
  )

  useEffect(() => {
    if (open) {
      track('viewed s corp upsell modal')
    }
  }, [track, open])

  useEffect(() => {
    if (!open) {
      setType(SCorpModalType.INTERESTED)
    }
  }, [open])

  const handleInterested = useCallback(
    async (status: SCorpSetUpStatus) => {
      if (!currentUser?.id) return
      await postSCorpElectionStatus({
        userId: currentUser.id,
        status,
        initialRequestSource: RequestSource.upsell_card,
      })(dispatch)
      if (status === SCorpSetUpStatus.request_submitted) {
        setType(SCorpModalType.PENDING)
        trackUpsellModalButtonClick(ScorpCardAndModalButtonType.INTERESTED)
      } else if (status === SCorpSetUpStatus.not_interested) {
        trackUpsellModalButtonClick(ScorpCardAndModalButtonType.NOT_NOW)
        hideCard()
        onClose()
      } else if (status === SCorpSetUpStatus.disqualified_automatically) {
        setType(SCorpModalType.DISQUALIFIED_AUTOMATICALLY)
      }
    },
    [onClose, dispatch, hideCard, trackUpsellModalButtonClick, currentUser?.id]
  )

  const renderModalContent = useMemo(() => {
    if (type === SCorpModalType.INTERESTED && isPreCurrentYear)
      return (
        <InterestedModalContent
          title={`Save ${taxSavings} in taxes by becoming an S Corporation`}
          bodyText={`You can save ${taxSavings} for the ${currentYear} tax year if you
          elect to become an S Corporation by March 15, ${currentYear}. See
          if you are a good fit!`}
          handleInterested={handleInterested}
          taxSavings={taxSavings}
          learnMoreLink="https://www.joinheard.com/articles/the-complete-guide-to-s-corporations-for-therapists"
        />
      )
    if (type === SCorpModalType.INTERESTED && !isPreCurrentYear)
      return (
        <CurrentYearModalContent
          setType={setType}
          onClose={onClose}
          handleInterested={handleInterested}
          trackUpsellModalButtonClick={trackUpsellModalButtonClick}
        />
      )
    if (type === SCorpModalType.ELIGIBLE)
      return (
        <InterestedModalContent
          title="You're a great fit to become an S Corporation!"
          bodyText={`You may be able to save on taxes for the ${currentYear} tax year if you elect to become an S Corporation by March 15, ${currentYear}.`}
          handleInterested={handleInterested}
          learnMoreLink="https://www.joinheard.com/articles/the-complete-guide-to-s-corporations-for-therapists"
        />
      )

    if (type === SCorpModalType.DISQUALIFIED_AUTOMATICALLY)
      return (
        <InterestedModalContent
          title="Not a Good fit for S Corporation"
          bodyText={`
            We don't believe it would be beneficial for you to become an S Corporation in
            ${currentYear} considering your profit targets. As an S Corporation you need to be making at 
            least $${requiredIncome},000 in profit to pay yourself a salary and pay off expenses.
            If you have any questions, let us know via Conversations.
           `}
          handleInterested={handleInterested}
          learnMoreLink="https://www.joinheard.com/articles/the-complete-guide-to-s-corporations-for-therapists#when-it-makes-sense-to-form-an-s-corporation-for-your-therapy-practice"
          notEligible
          image="https://heard-images.s3.amazonaws.com/assets/x_checklist.svg"
          onClose={onClose}
          hideCard={hideCard}
          trackUpsellModalButtonClick={trackUpsellModalButtonClick}
        />
      )
    if (type === SCorpModalType.PENDING)
      return <PendingModalContent onClose={onClose} hideCard={hideCard} />
    return null
  }, [
    type,
    onClose,
    isPreCurrentYear,
    taxSavings,
    handleInterested,
    hideCard,
    currentYear,
    requiredIncome,
    trackUpsellModalButtonClick,
  ])

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeIcon={!hideCloseTypes.includes(type)}
      closeOnDimmerClick={!hideCloseTypes.includes(type)}
      style={{ maxWidth: 660 }}
    >
      {renderModalContent}
    </Modal>
  )
}
