import { ReactNode } from 'react'
import { Grid } from 'semantic-ui-react'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'

import {
  GridRowColumn,
  Text,
  Icon,
  Card,
  CircularWrapper,
} from '../../components/BaseComponents'

export const SeparateStep = ({
  stepNumber,
  title,
  content,
  icon,
}: {
  stepNumber: string
  title: string
  content: ReactNode
  icon: IconDefinition
}) => {
  return (
    <GridRowColumn>
      <Card backgroundColor="stone40">
        <Grid>
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={2}>
              <CircularWrapper height={48} wrapperColor="moss">
                <Text as="bodyLg">{stepNumber}</Text>
              </CircularWrapper>
            </Grid.Column>
            <Grid.Column width={13}>
              <Text as="h2">{title}</Text>
              {content}
            </Grid.Column>
            <Grid.Column width={1}>
              <Icon icon={icon} color="gray" size="2x" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </GridRowColumn>
  )
}
