import { createRef, useCallback, useMemo, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { times } from 'lodash'

import { filterNulls, useAppDispatch } from '../../../utils/typeHelpers'
import { useFetchResponse } from '../../../utils/sharedHooks'
import {
  fetchEmployeeStateTaxes,
  PUT_UPDATE_EMPLOYEE_STATE_TAXES_KEY,
  setupFinishEmployeeSetup,
} from '../payrollActions'
import {
  StateTaxSection,
  StateTaxSectionHandle,
} from '../Enroll/PayrollEmployeeState'
import { Button, Modal } from '../../../components/BaseComponents'
import PayrollError from '../PayrollError'

const OnboardEmployeeModal = ({
  employeeUuid,
  open,
  onClose,
}: {
  employeeUuid: string
  open: boolean
  onClose: () => void
}) => {
  const dispatch = useAppDispatch()
  const [submitting, setSubmitting] = useState(false)

  const stateTaxes = useFetchResponse(fetchEmployeeStateTaxes, employeeUuid)

  const stateSectionRefs = useMemo(
    () =>
      times(stateTaxes?.length || 0).map(() =>
        createRef<StateTaxSectionHandle>()
      ),
    [stateTaxes?.length]
  )

  const onboardEmployee = useCallback(async () => {
    setSubmitting(true)

    const statePayload = {
      states: filterNulls(
        stateSectionRefs.map((section) => section.current?.getQuestionAnswers())
      ),
    }

    const res = await dispatch(
      setupFinishEmployeeSetup(employeeUuid, statePayload)
    )

    setSubmitting(false)

    if (res) {
      onClose()
    }
  }, [dispatch, employeeUuid, onClose, stateSectionRefs])

  return (
    <Modal open={open} size="tiny" closeIcon onClose={onClose}>
      <Modal.Header>Onboard Employee</Modal.Header>
      <Modal.Content>
        <Grid>
          <PayrollError fetchKey={PUT_UPDATE_EMPLOYEE_STATE_TAXES_KEY} />
          {stateTaxes?.map((taxesForSingleState, index) => {
            if (!stateSectionRefs[index]) {
              return null
            }
            return (
              <StateTaxSection
                key={taxesForSingleState.state}
                ref={stateSectionRefs[index]}
                taxesForSingleState={taxesForSingleState}
              />
            )
          })}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={onboardEmployee}
          disabled={submitting}
          loading={submitting}
        >
          Onboard Employee
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default OnboardEmployeeModal
