import { useEffect, useState } from 'react'
import { Confirm, Container, Grid, Icon } from 'semantic-ui-react'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { uniq } from 'lodash'

import { adminDestroyUserDocument } from '../../../actions/admin/userDocumentsActions'
import { downloadZipDocuments } from './helpers'
import FileUploadModal from '../../../components/FileUpload/FileUploadModal'
import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import AdminDocumentTableWrapper from './AdminDocumentTableWrapper'
import { Button } from '../../../components/BaseComponents'

import '../../UserDocuments/style.scss'
import { useReselector } from '../../../utils/sharedHooks'
import { selectUserDocumentsByType } from '../../UserDocuments/userDocuments.selector'
import { UploadDocumentType } from '../../../constants/businessConstants'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { fetchTaxUserDocumentsIfNeededForUser } from '../../Taxes/AnnualTaxes/taxUserDocuments.slice'

interface Props {
  user: UserWithAdminInfo
  documentType: UploadDocumentType
}

const DocumentsTable = ({ user, documentType }: Props) => {
  const [uploadDocumentModalOpen, setUploadDocumentModalOpen] = useState(false)
  const [selectedDocs, setSelectedDocs] = useState<number[]>([])
  const [downloadingDocuments, setDownloadingDocuments] = useState(false)
  const [deletingDocuments, setDeletingDocuments] = useState(false)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const dispatch = useAppDispatch()
  const userDocuments = useReselector(
    selectUserDocumentsByType,
    user.id,
    documentType
  )

  /* If user  changes, clear selected*/
  useEffect(() => setSelectedDocs([]), [user.id])
  useEffect(() => {
    if (documentType === UploadDocumentType.TAX) {
      dispatch(fetchTaxUserDocumentsIfNeededForUser(user.id))
    }
  }, [user.id, dispatch, documentType])

  const downloadDocuments = async () => {
    setDownloadingDocuments(true)
    // Fetch ZIP from back end, based on documents
    const zipName = `${user.firstName}-${user.lastName}-${moment().format(
      'MM-DD-YY'
    )}.zip`
    const result = await downloadZipDocuments(selectedDocs)
    if (result) {
      // Generate Blob of the returned Zip
      const blob = new Blob([result.data], { type: 'application/zip' })
      // Initiate a save
      saveAs(blob, zipName)
      setDownloadingDocuments(false)
    }
  }

  const deleteDocuments = async () => {
    setConfirmDeleteOpen(false)
    setDeletingDocuments(true)
    await Promise.all(
      selectedDocs.map((id) => adminDestroyUserDocument(id)(dispatch))
    )
    setDeletingDocuments(false)
    setSelectedDocs([])
  }

  const onCheck = (docId: number, checked: boolean) =>
    setSelectedDocs((docs) => {
      return checked
        ? uniq([...docs, docId])
        : docs.filter((id) => id !== docId)
    })

  return (
    <Container>
      {documentType !== UploadDocumentType.RECEIPT && (
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <Button onClick={() => setUploadDocumentModalOpen(true)}>
                <Icon name="upload" />
                {' Upload File'}
              </Button>
            </Grid.Column>
            <Grid.Column width={4} />
            <Grid.Column width={3}>
              <Button
                onClick={() => setConfirmDeleteOpen(true)}
                loading={deletingDocuments}
                disabled={!selectedDocs.length}
              >
                <Icon name="trash" />
                Delete Documents
              </Button>
            </Grid.Column>
            <Grid.Column width={5}>
              <Button
                onClick={downloadDocuments}
                loading={downloadingDocuments}
                disabled={!selectedDocs.length}
              >
                <Icon name="download" />
                Download Selected Files in Zip
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      <FileUploadModal
        open={uploadDocumentModalOpen}
        close={() => setUploadDocumentModalOpen(false)}
        user={user}
        userFacing={false}
        documentType={documentType}
      />
      <AdminDocumentTableWrapper
        documentType={documentType}
        documents={Object.values(userDocuments)}
        onCheck={onCheck}
      />
      <Confirm
        size="tiny"
        open={confirmDeleteOpen}
        cancelButton="Cancel"
        confirmButton="Delete documents"
        content="Are you sure you want to delete these documents"
        onCancel={() => setConfirmDeleteOpen(false)}
        onConfirm={deleteDocuments}
      />
    </Container>
  )
}

export default DocumentsTable
