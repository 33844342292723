import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PlaidStatementInstitution {
  id: number
  institutionId: string
  institutionName: string
  supportEndedAt: string | null
  createdAt: string
  updatedAt: string
}

export interface PlaidStatementInstitutionState {
  [key: number]: PlaidStatementInstitution
}

const plaidStatementInstitutionsSlice = createSlice({
  name: 'plaidStatementInstitutions',
  initialState: {} as PlaidStatementInstitutionState,
  reducers: {
    receivePlaidStatementInstitutions: (
      _,
      action: PayloadAction<{ [key: number]: PlaidStatementInstitution }>
    ) => ({
      ...action.payload,
    }),
  },
})

export default plaidStatementInstitutionsSlice.reducer

export const { receivePlaidStatementInstitutions } =
  plaidStatementInstitutionsSlice.actions
