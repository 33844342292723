import { FC, useEffect, useMemo, useState } from 'react'
import { Grid, Icon } from 'semantic-ui-react'
import moment from 'moment'
import { isNil } from 'lodash'
import { FormikProvider, useFormik } from 'formik'
import { number as yupNumber, object, string as yupString } from 'yup'

import {
  Button,
  FormikDateInput,
  FormikInput,
  FormikRadioToggleButton,
  getFieldName,
  GridRowColumn,
  makeDateSchema,
  Modal,
  Text,
  Link,
} from '../../../../components/BaseComponents'
import {
  updateUserTaxEstimates,
  UserTaxEstimate,
} from '../userTaxEstimates.slice'
import { shortNames } from '../../../../constants/statesShortNamesConstants'
import {
  centsToDollars,
  dollarsToCents,
  formatCurrency,
} from '../../../../utils/currencyHelpers'
import FileUploadModal from '../../../../components/FileUpload/FileUploadModal'
import { markUserActionItemCompleteIfExists } from '../../../Dashboard/UserActionItems/service'
import { UserActionItemActionItemIdentifiers } from '../../../Dashboard/UserActionItems/userActionItems.slice'
import { FontWeight } from '../../../../styles/theme'
import { useReselector } from '../../../../utils/sharedHooks'
import { getUserDocumentById } from '../../../UserDocuments/userDocuments.selector'
import { selectUserDocumentCategoryByInternalName } from '../../../Admin/UserDocumentCategories/userDocumentCategories.selectors'
import { fetchUserDocumentCategoriesIfNeeded } from '../../../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { fetchUserDocuments } from '../../../UserDocuments/userDocuments.slice'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { UploadDocumentType } from '../../../../constants/businessConstants'
import { useAnalyticsTrack } from '../../../Amplitude'
import { splitTaxYearQuarter } from '../helpers'
import { UserDocumentCategoryIdentifier } from '../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { useAppDispatch } from '../../../../utils/typeHelpers'

interface Props {
  open: boolean
  close: () => void
  estimate: UserTaxEstimate
}

const renderTitle = (estimate: UserTaxEstimate) => {
  const isFederal = estimate.type === 'federal'

  const { quarter, year } = splitTaxYearQuarter(estimate.taxQuarter)

  const estimateTypes = isFederal
    ? 'Federal'
    : estimate.filingState
      ? `${shortNames[estimate.filingState]}`
      : '[State not provided]'

  return `Update Tax Payment - ${estimateTypes}, Q${quarter} ${year}`
}

const labelStyle = { fontWeight: FontWeight.MEDIUM }

const validationSchema = object({
  paymentAmountInDollars: yupNumber()
    .min(0.0)
    .when('paymentSubmitted', ([paymentSubmitted]) =>
      paymentSubmitted === 'yes'
        ? yupNumber().min(0.0).required()
        : yupNumber().notRequired()
    ),
  paymentDate: yupString().when(
    'paymentSubmitted',
    ([paymentSubmitted], schema) =>
      paymentSubmitted === 'yes'
        ? makeDateSchema({ field: 'payment date' })
        : schema
  ),
  // value is manually set via formik's api
  paymentSubmitted: yupString().required(),
})

// Form value state is managed by both Formik and React.useState
const UpdateQuarterlyTaxEstimateModal: FC<Props> = ({
  close,
  estimate,
  open,
}) => {
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()

  const { quarter, year } = splitTaxYearQuarter(estimate.taxQuarter)
  const [fileUploadOpen, setFileUploadOpen] = useState(false)
  const document = useReselector(getUserDocumentById, estimate.userDocumentId)
  const categoryIdentifier = useMemo(() => {
    switch (quarter) {
      case '1':
        return UserDocumentCategoryIdentifier.q1Payment
      case '2':
        return UserDocumentCategoryIdentifier.q2Payment
      case '3':
        return UserDocumentCategoryIdentifier.q3Payment
      case '4':
        return UserDocumentCategoryIdentifier.q4Payment
      default:
        return UserDocumentCategoryIdentifier.qtePayment
    }
  }, [quarter])
  const category = useReselector(
    selectUserDocumentCategoryByInternalName,
    categoryIdentifier
  )
  const [uploadedFile, setUploadedFile] = useState<{
    title: string
    url: string
    id: number | undefined
  }>({
    title: document?.fileDescription || '',
    url: document?.signedUrl || '',
    id: document?.id,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(fetchUserDocuments())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
  }, [dispatch])

  useEffect(() => {
    if (document?.id) {
      setUploadedFile({
        title: document.fileDescription || '',
        url: document.signedUrl || '',
        id: document.id,
      })
    }
  }, [document?.fileDescription, document?.id, document?.signedUrl])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      paymentSubmitted: isNil(estimate.quarterPaid)
        ? undefined
        : estimate.quarterPaid
          ? 'yes'
          : 'no',
      paymentAmountInDollars: estimate.amountPaidInCents
        ? centsToDollars(estimate.amountPaidInCents)
        : '',
      paymentDate: estimate.paidAt
        ? moment(estimate.paidAt).format(DATE_FORMATS.INPUT)
        : '',
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!quarter || !year) {
        return
      }

      setLoading(true)
      let quarterPaid
      let paidAt
      let amountPaidInCents

      if (values.paymentSubmitted === 'no') {
        // Reset paidAt and amount in case they previously had values
        quarterPaid = false
        paidAt = null
        amountPaidInCents = null
      } else {
        quarterPaid = true
        paidAt = moment(values.paymentDate, DATE_FORMATS.INPUT).valueOf()
        amountPaidInCents = dollarsToCents(values.paymentAmountInDollars)
      }

      await updateUserTaxEstimates(estimate.id, {
        quarterPaid,
        paidAt,
        amountPaidInCents,
        userDocumentId: uploadedFile.id,
      })(dispatch)

      await markUserActionItemCompleteIfExists(
        UserActionItemActionItemIdentifiers.submitQTEPayment({
          taxQuarter: quarter,
          taxYear: year,
        }),
        (event, properties) => track(event, properties)
      )

      track('updated tax estimate payment', {
        year,
        quarter,
        quarterPaid,
        paidAt: moment(values.paymentDate, DATE_FORMATS.INPUT).toString(),
        amountPaidInCents,
        uploadedFileId: uploadedFile.id || null,
      })

      setLoading(false)
      close()
    },
  })

  const paymentSubmitted = isNil(formik.values.paymentSubmitted)
    ? undefined
    : formik.values.paymentSubmitted

  return (
    <Modal open={open} size="tiny" onClose={close} closeIcon>
      <Modal.Header>{renderTitle(estimate)}</Modal.Header>
      <Modal.Content>
        <FormikProvider value={formik}>
          <Grid>
            {estimate.status !== 'active' && estimate.estimateInCents && (
              <GridRowColumn>
                <Text style={labelStyle}>Estimated amount to be paid</Text>
                <Text as="h1">
                  {formatCurrency(centsToDollars(estimate.estimateInCents))}
                </Text>
              </GridRowColumn>
            )}
            {estimate.status === 'active' && (
              <GridRowColumn>
                <Text style={labelStyle}>Estimated amount to be paid</Text>
                <Text as="h1">
                  {estimate.estimateInCents !== null
                    ? formatCurrency(centsToDollars(estimate.estimateInCents))
                    : 'Awaiting Estimate'}
                </Text>
              </GridRowColumn>
            )}
            <GridRowColumn>
              <Text style={labelStyle}>
                Have you submitted your estimated quarterly tax payment?
              </Text>
              <div />
              <div />
              <FormikRadioToggleButton
                style={{ width: '92px', marginRight: '8px' }}
                inline
                name="paymentSubmitted"
                value="yes"
              >
                Yes
              </FormikRadioToggleButton>
              <FormikRadioToggleButton
                style={{ width: '92px' }}
                inline
                name="paymentSubmitted"
                value="no"
              >
                No
              </FormikRadioToggleButton>
            </GridRowColumn>
            {!isNil(paymentSubmitted) &&
              paymentSubmitted === 'no' &&
              quarter === '4' && (
                <Text as="bodySm" color="darkGray">
                  Not paying estimated taxes may trigger underpayment penalties
                </Text>
              )}
            {!isNil(paymentSubmitted) && paymentSubmitted === 'yes' && (
              <>
                <Grid.Row columns="equal">
                  <Grid.Column>
                    <FormikInput
                      required
                      name={getFieldName<typeof formik.values>(
                        'paymentAmountInDollars'
                      )}
                      label="How much did you pay?"
                      componentType="currency"
                      allowNegativeValue={false}
                      placeholder="$0.00"
                      fullWidth
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormikDateInput
                      required
                      name={getFieldName<typeof formik.values>('paymentDate')}
                      label="When did you pay?"
                      maxDate={new Date()}
                      placeholder="Enter date"
                      fullWidth
                    />
                  </Grid.Column>
                </Grid.Row>
                <GridRowColumn>
                  <Text style={labelStyle}>Attach Receipt</Text>
                  <Text as="bodySm">
                    Please attach the receipt for your payment. This helps us
                    with your bookkeeping.
                  </Text>
                  {uploadedFile?.title && (
                    <div style={{ marginTop: 16 }}>
                      <Link href={uploadedFile.url} newPage>
                        {uploadedFile.title}
                      </Link>
                      <Text as="bodySm">
                        <i>Can be found in your &quot;Tax&quot; Documents</i>
                      </Text>
                    </div>
                  )}
                  <Button
                    onClick={() => setFileUploadOpen(true)}
                    size="medium"
                    variant="secondary"
                    style={{ marginTop: 14 }}
                  >
                    <Icon name="add" />
                    Upload Receipt
                  </Button>
                </GridRowColumn>
                {fileUploadOpen && (
                  <FileUploadModal
                    open={fileUploadOpen}
                    categoryId={category?.id}
                    close={() => setFileUploadOpen(false)}
                    documentType={UploadDocumentType.TAX}
                    userFacing
                    setUploadedFile={setUploadedFile}
                    year={year}
                  />
                )}
              </>
            )}
          </Grid>
        </FormikProvider>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={close} style={{ width: '121px' }} variant="actionLink">
          Cancel
        </Button>
        <Button
          disabled={isNil(paymentSubmitted) || loading}
          loading={loading}
          onClick={formik.submitForm}
        >
          Update
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default UpdateQuarterlyTaxEstimateModal
