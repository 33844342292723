import { useState, useCallback, useMemo } from 'react'
import { TransactionOrSplit } from '../../../reducers/admin/allTransactions.slice'
import { Input } from '../../../components/BaseComponents'
import { updateUserTransaction } from '../transactions.slice'
import { debounce } from 'lodash'
import {
  dollarsToCents,
  formatCurrencyFromCents,
} from '../../../utils/currencyHelpers'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface TransactionAmountInputProps {
  transaction: TransactionOrSplit
}

export const TransactionAmountInput = ({
  transaction,
}: TransactionAmountInputProps) => {
  const [value, setValue] = useState(transaction.amountInCents)
  const dispatch = useAppDispatch()

  const debouncedUpdateTransaction = useMemo(
    () =>
      debounce((newValue: number | undefined) => {
        if (transaction.amountInCents === (newValue || 0)) return

        updateUserTransaction(transaction.id, { amountInCents: newValue })(
          dispatch
        )
      }, 1000),
    [transaction.amountInCents, transaction.id, dispatch]
  )

  const handleChange = useCallback(
    (newValue: number | undefined) => {
      setValue(newValue ? newValue : 0)
      debouncedUpdateTransaction(newValue)
    },
    [debouncedUpdateTransaction]
  )

  return (
    <Input
      value={formatCurrencyFromCents(value) ?? ''}
      componentType="number"
      onChange={(value) => handleChange(dollarsToCents(value))}
      fullWidth
    />
  )
}
