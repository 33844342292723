import { useCallback, useEffect, useMemo, useState } from 'react'

import { generateMonthlyStatementOptions } from '../../../constants/businessConstants'
import { documentCategoryOptions } from './helpers'
import { UserDocument } from '../../UserDocuments/userDocuments.slice'
import { getUserDocumentCategoriesById } from '../UserDocumentCategories/userDocumentCategories.selectors'
import { getUserByFinancialProfileId } from '../../../selectors/user.selectors'
import {
  adminUpdateUserDocument,
  adminUpdateStatement,
} from '../../../actions/admin/userDocumentsActions'
import {
  Dropdown,
  Modal,
  Button,
  GridRowColumn,
  DatePicker,
} from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../OpenFeature'
import { Grid } from 'semantic-ui-react'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { selectTaxUserDocumentForUserDocumentId } from '../../Taxes/AnnualTaxes/taxUserDocuments.selector'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface Props {
  modalOpen: boolean
  setModalOpen: (open: boolean) => void
  document: UserDocument
  fileType: string
}

const MoveDocumentModal = ({
  modalOpen,
  setModalOpen,
  document,
  fileType,
}: Props) => {
  const dispatch = useAppDispatch()
  const taxUserDocument = useReselector(
    selectTaxUserDocumentForUserDocumentId,
    document.id
  )
  const [year, setYear] = useState<string | null | undefined>(
    taxUserDocument?.taxYear
  )

  useEffect(() => {
    setYear(taxUserDocument?.taxYear)
  }, [taxUserDocument?.taxYear])

  const user = useReselector(
    getUserByFinancialProfileId,
    document.financialProfileId
  )

  const releaseStatementUpload = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.multiStatementUpload,
    false
  )

  const documentCategories = useReselector(getUserDocumentCategoriesById)
  const [newFileType, setNewFileType] = useState(fileType)
  const [documentCategory, setDocumentCategory] = useState<number>()
  const [statementMonth, setStatementMonth] = useState<string>()
  const [error, setError] = useState(false)
  const filteredDocumentCategories = useMemo(
    () => documentCategoryOptions(documentCategories, newFileType),
    [documentCategories, newFileType]
  )

  const fileTypeOptions = [
    { value: 'statement', text: 'statement' },
    { value: 'tax', text: 'tax' },
    { value: 'incorporation', text: 'incorporation' },
    { value: 'other', text: 'other' },
  ]

  const handleMove = useCallback(async () => {
    if (!user?.id) {
      return
    }

    const data: Partial<UserDocument> = { documentType: newFileType }

    if (documentCategory) {
      data.userDocumentCategoryId = documentCategory
    }
    if (statementMonth) {
      data.statementMonth = statementMonth
    }
    if (document.needsReview) {
      data.needsReview = false
    }

    if (statementMonth && releaseStatementUpload) {
      data.statement = {
        statementMonth,
      }
    }

    let response

    if (releaseStatementUpload && newFileType === 'statement') {
      response = await adminUpdateStatement(document.id, data)(dispatch)
    } else {
      response = await adminUpdateUserDocument(document.id, {
        ...data,
        year: typeof year === 'string' && year.length === 0 ? null : year,
      })(dispatch)
    }

    if (response) {
      setModalOpen(false)
    } else {
      setError(true)
    }
  }, [
    dispatch,
    document.id,
    document.needsReview,
    documentCategory,
    newFileType,
    setModalOpen,
    statementMonth,
    user?.id,
    releaseStatementUpload,
    year,
  ])

  return (
    <Modal
      size="small"
      dimmer="inverted"
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      closeIcon
    >
      {!error && (
        <>
          <Modal.Header>Move {document.fileDescription}</Modal.Header>
          <Modal.Content>
            <Grid>
              <GridRowColumn>
                <Dropdown
                  required
                  label="Move File To:"
                  placeholder="Select File Type"
                  value={newFileType}
                  options={fileTypeOptions}
                  onChange={setNewFileType}
                />
              </GridRowColumn>

              {newFileType === 'statement' && (
                <GridRowColumn>
                  <Dropdown
                    required
                    label="Select Month This Statement Belongs To"
                    placeholder="Select Statement Month"
                    value={statementMonth}
                    options={generateMonthlyStatementOptions()}
                    onChange={setStatementMonth}
                  />
                </GridRowColumn>
              )}
              {newFileType !== 'statement' && (
                <GridRowColumn>
                  <Dropdown
                    label="Select Document Category If Relevant"
                    placeholder="Select Document Category"
                    value={documentCategory}
                    options={filteredDocumentCategories}
                    onChange={setDocumentCategory}
                  />
                </GridRowColumn>
              )}
              {newFileType === 'tax' &&
                (!documentCategory ||
                  documentCategories[documentCategory]?.isAnnual) && (
                  <GridRowColumn>
                    <DatePicker
                      placeholder="Select year"
                      label="Tax Year"
                      value={year ?? ''}
                      maxDate={new Date()}
                      onChange={setYear}
                      dateFormat={DATE_FORMATS.YEAR}
                      showYearPicker
                      clearable
                    />
                  </GridRowColumn>
                )}
            </Grid>
          </Modal.Content>

          <Modal.Actions
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button variant="secondary" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>

            <Button
              disabled={newFileType === 'statement' && !statementMonth}
              onClick={handleMove}
            >
              Move File
            </Button>
          </Modal.Actions>
        </>
      )}
      {error && (
        <>
          <Modal.Header>
            There was an error moving {document.fileDescription}.
          </Modal.Header>
          <Modal.Actions
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button
              variant="secondary"
              onClick={() => {
                setModalOpen(false)
                setError(false)
              }}
            >
              Close
            </Button>
          </Modal.Actions>
        </>
      )}
    </Modal>
  )
}

export default MoveDocumentModal
