import { Component, createRef, RefObject } from 'react'
import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import currency from 'currency.js'

import { selectEmployeeByUuid } from '../../payroll.selectors'
import FixedCell from './FixedCell'
import {
  makeNumberSchema,
  Modal,
  Button,
  FormikInput,
  Text,
  GridRowColumn,
  getFieldName,
} from '../../../../components/BaseComponents'
import { AddButton, EditButton } from './PayrollInputButtons'
import { PayrollComps } from '../../helpers'
import { useReselector } from '../../../../utils/sharedHooks'
import { Gusto_EmployeeFixedCompensation } from '../../generated_gusto_types'

const validationSchema = yup.object({
  cashTips: makeNumberSchema({ field: 'cash tips', allowedDecimals: 2 }),
  paycheckTips: makeNumberSchema({
    field: 'paycheck tips',
    allowedDecimals: 2,
  }),
  minimumWageAdjustment: makeNumberSchema({
    field: 'minimum wage adjustment',
    allowedDecimals: 2,
  }),
  commission: makeNumberSchema({ field: 'commission', allowedDecimals: 2 }),
})

const AdditionalEarningsModal = ({
  onSave,
  close,
  employeeUuid,
  cashTips,
  paycheckTips,
  minimumWageAdjustment,
  commission,
}: {
  onSave: (_: {
    cashTips: string
    paycheckTips: string
    minimumWageAdjustment: string
    commission: string
  }) => void
  close: () => void
  employeeUuid: string
  cashTips: string
  paycheckTips: string
  minimumWageAdjustment: string
  commission: string
}) => {
  const employee = useReselector(selectEmployeeByUuid, employeeUuid)

  const formik = useFormik({
    initialValues: {
      cashTips,
      paycheckTips,
      minimumWageAdjustment,
      commission,
    },
    validationSchema,
    onSubmit: (values) => onSave(values),
  })

  return (
    <Modal open size="tiny" onClose={close} closeIcon>
      <Modal.Header>Add Other Earnings</Modal.Header>
      <Modal.Content>
        <FormikProvider value={formik}>
          <Grid>
            <GridRowColumn>
              <Text>
                {employee?.first_name} {employee?.last_name} will be paid these
                earnings, in addition to their regular wages.
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <FormikInput
                name={getFieldName<typeof formik.values>('commission')}
                componentType="currency"
                label="Commission"
                description="The amount of commission pay the employee received for this pay period."
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikInput
                name={getFieldName<typeof formik.values>('paycheckTips')}
                componentType="currency"
                label="Paycheck Tips"
                description="Paycheck tips (service charges) to be paid to the employee this pay period. This amount will be added to the employee's gross pay."
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikInput
                name={getFieldName<typeof formik.values>(
                  'minimumWageAdjustment'
                )}
                componentType="currency"
                label="Minimum Wage Adjustment"
                description="This amount will be added to the employee's gross pay."
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikInput
                name={getFieldName<typeof formik.values>('cashTips')}
                componentType="currency"
                label="Cash Tips"
                description="This amount will be added to the employee's gross pay."
              />
            </GridRowColumn>
            <GridRowColumn>
              <Button onClick={formik.submitForm} fullWidth>
                Save
              </Button>
            </GridRowColumn>
            <GridRowColumn>
              <Button onClick={close} variant="secondary" fullWidth>
                Cancel
              </Button>
            </GridRowColumn>
          </Grid>
        </FormikProvider>
      </Modal.Content>
    </Modal>
  )
}

interface Props {
  fixedComps: Gusto_EmployeeFixedCompensation[]
  employeeUuid: string
  isOffCycle: boolean
}

interface State {
  modalShown: boolean
  cashTips: string
  paycheckTips: string
  minimumWageAdjustment: string
  commission: string
  jobUuid?: string
}

class AdditionalEarnings extends Component<Props, State> {
  bonusRef: RefObject<FixedCell>
  correctionRef: RefObject<FixedCell>

  constructor(props: Props) {
    super(props)

    this.state = {
      modalShown: false,
      cashTips:
        props.fixedComps.find((val) => val.name === PayrollComps.cashTips)
          ?.amount || '0.00',
      paycheckTips:
        props.fixedComps.find((val) => val.name === PayrollComps.paycheckTips)
          ?.amount || '0.00',
      minimumWageAdjustment:
        props.fixedComps.find(
          (val) => val.name === PayrollComps.minimumWageAdjustment
        )?.amount || '0.00',
      commission:
        props.fixedComps.find((val) => val.name === PayrollComps.commission)
          ?.amount || '0.00',
      jobUuid: props.fixedComps[0]?.job_uuid,
    }
    this.bonusRef = createRef<FixedCell>()
    this.correctionRef = createRef<FixedCell>()
  }

  calculate() {
    const {
      cashTips,
      paycheckTips,
      minimumWageAdjustment,
      commission,
      jobUuid: job_uuid,
    } = this.state
    return [
      {
        job_uuid,
        name: PayrollComps.bonus,
        amount: this.bonusRef.current?.calculate().amount || '0',
      },
      {
        job_uuid,
        name: PayrollComps.cashTips,
        amount: cashTips || '0',
      },
      {
        job_uuid,
        name: PayrollComps.commission,
        amount: commission || '0',
      },
      {
        job_uuid,
        name: PayrollComps.correctionPayment,
        amount: this.correctionRef.current?.calculate().amount || '0',
      },
      {
        job_uuid,
        name: PayrollComps.minimumWageAdjustment,
        amount: minimumWageAdjustment || '0',
      },
      {
        job_uuid,
        name: PayrollComps.paycheckTips,
        amount: paycheckTips || '0',
      },
    ]
  }

  saveModal = (payload: {
    cashTips: string
    paycheckTips: string
    minimumWageAdjustment: string
    commission: string
  }) => {
    const { employeeUuid } = this.props

    this.setState({ ...payload, modalShown: false }, () =>
      document.dispatchEvent(new CustomEvent(`inputChange${employeeUuid}`))
    )
  }

  render() {
    const {
      modalShown,
      commission,
      minimumWageAdjustment,
      cashTips,
      paycheckTips,
    } = this.state
    const { employeeUuid, fixedComps, isOffCycle } = this.props

    const otherTotal = currency(Number(commission))
      .add(Number(minimumWageAdjustment))
      .add(Number(cashTips))
      .add(Number(paycheckTips))

    const bonusComp = fixedComps.find(
      (val) => val.name === PayrollComps.bonus
    ) || {
      name: PayrollComps.bonus,
      amount: '0.00',
      job_uuid: this.state.jobUuid,
    }

    const correctionComp = fixedComps.find(
      (val) => val.name === PayrollComps.correctionPayment
    ) || {
      name: PayrollComps.correctionPayment,
      amount: '0.00',
      job_uuid: this.state.jobUuid,
    }

    return (
      <>
        <FixedCell
          ref={this.bonusRef}
          employeeUuid={employeeUuid}
          fixedComp={bonusComp}
        />
        {/*correction comp should only display for off cycle payrolls.  This is meant to match Gusto's implementation*/}
        {isOffCycle && (
          <FixedCell
            ref={this.correctionRef}
            employeeUuid={employeeUuid}
            fixedComp={correctionComp}
            name="Gross Earnings"
          />
        )}
        {otherTotal ? (
          <EditButton
            onClick={() => this.setState({ modalShown: true })}
            text={`$${otherTotal} Additional Pay`}
            style={{ marginTop: 5 }}
          />
        ) : (
          <AddButton
            onClick={() => this.setState({ modalShown: true })}
            text="Other Earnings"
          />
        )}
        {modalShown && (
          <AdditionalEarningsModal
            employeeUuid={employeeUuid}
            close={() => this.setState({ modalShown: false })}
            onSave={this.saveModal}
            cashTips={cashTips}
            commission={commission}
            paycheckTips={paycheckTips}
            minimumWageAdjustment={minimumWageAdjustment}
          />
        )}
      </>
    )
  }
}

export default AdditionalEarnings
