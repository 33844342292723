import { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid } from 'semantic-ui-react'

import { useReselector } from '../../../../utils/sharedHooks'
import {
  getAnnualTaxFilingForYearSelector,
  selectExpectedAnnualTaxFormNeeds,
} from '../annualTaxFilings.selector'
import {
  Accordion,
  Button,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import {
  TaxSeasonKickoffStepProps,
  TAX_SURVEY_CONTENT_SCREENS,
} from './AnnualTaxSurvey'
import FormFlowFooter from '../../../../components/FormFlow/FormFlowFooter'
import { TaxFormType } from '../Questionnaires/constants'
import { get1040Accordion, get1120SAccordion } from '../../helpers'
import { selectTaxDetailsByYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import SurveyTaxFormHelpModal from './SurveyTaxFormHelpModal'
import { useAnalyticsView } from '../../../Amplitude'
import { ConfirmAnnualTaxFiling } from '../annualTaxFilings.slice'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'

const TaxFormPanel = ({
  year,
  goToNextStep,
  goBack,
}: TaxSeasonKickoffStepProps) => {
  const taxFiling = useReselector(getAnnualTaxFilingForYearSelector, year)
  const needs = useReselector(selectExpectedAnnualTaxFormNeeds, year)
  const taxDetails = useReselector(selectTaxDetailsByYear, year)
  const [modalOpen, setModalOpen] = useState(false)

  const [confirmForm, setConfirmForm] = useState(taxFiling?.confirmedFormNeeds)

  const pageView = useAnalyticsView()
  const screenName = 'TSK tax form confirmation'
  useEffect(() => {
    pageView(screenName)
  }, [pageView])

  useEffect(() => {
    if (
      confirmForm &&
      [ConfirmAnnualTaxFiling.no, ConfirmAnnualTaxFiling.unsure].includes(
        confirmForm
      )
    ) {
      setModalOpen(true)
    }
  }, [confirmForm])

  const is1120s = needs?.includes(TaxFormType.form1120s)

  const infoAccordion = is1120s
    ? get1120SAccordion(taxDetails)
    : get1040Accordion()

  const subtitle = useMemo(() => {
    if (is1120s) {
      return (
        <Text as="bodyLg">
          Based on your profile, you’re filing a{' '}
          <b>business income tax return (Form 1120-S)</b>. This means that
          you’ve set up a separate S corp to report income.
        </Text>
      )
    } else if (needs?.length === 1 && needs?.includes(TaxFormType.form1040)) {
      return (
        <Text as="bodyLg">
          Based on your profile, you’re filing a{' '}
          <b>personal income tax return (Form 1040)</b> this tax season.
        </Text>
      )
    } else {
      // users who don't fall in above cases should not hit this page
      return null
    }
  }, [is1120s, needs])

  const saveAndContinue = useCallback(() => {
    goToNextStep(
      {
        annualTaxFormNeeds: needs,
        confirmedFormNeeds: ConfirmAnnualTaxFiling.yes,
      },
      needs?.includes(TaxFormType.form1120s)
        ? TAX_SURVEY_CONTENT_SCREENS.additionalForms
        : TAX_SURVEY_CONTENT_SCREENS.contractorNec
    )
  }, [goToNextStep, needs])

  const justContinue = useCallback(() => {
    goToNextStep(
      null,
      needs?.includes(TaxFormType.form1120s)
        ? TAX_SURVEY_CONTENT_SCREENS.additionalForms
        : TAX_SURVEY_CONTENT_SCREENS.contractorNec
    )
  }, [goToNextStep, needs])

  return (
    <Grid>
      <GridRowColumn>
        <Text as="display2" textAlign="center">
          Your Tax Form
        </Text>
      </GridRowColumn>

      <GridRowColumn {...makeGridConfig([10, 12], true)}>
        <Text as="bodyLg">{subtitle}</Text>
      </GridRowColumn>
      <Grid.Row className="short" />

      <GridRowColumn {...makeGridConfig([10, 14], true)}>
        <Text as="h3">Is this correct?</Text>
      </GridRowColumn>

      <GridRowColumn {...makeGridConfig([6, 14], true)} short>
        <Button
          variant={
            confirmForm === ConfirmAnnualTaxFiling.yes ? 'primary' : 'secondary'
          }
          onClick={() => {
            setConfirmForm(ConfirmAnnualTaxFiling.yes)
            saveAndContinue()
          }}
          fullWidth
        >
          Yes
        </Button>
      </GridRowColumn>

      <GridRowColumn {...makeGridConfig([6, 14], true)} short>
        <Button
          variant={
            confirmForm === ConfirmAnnualTaxFiling.no ? 'primary' : 'secondary'
          }
          onClick={() => setConfirmForm(ConfirmAnnualTaxFiling.no)}
          fullWidth
        >
          No
        </Button>
      </GridRowColumn>

      <GridRowColumn {...makeGridConfig([6, 14], true)} short>
        <Button
          variant={
            confirmForm === ConfirmAnnualTaxFiling.unsure
              ? 'primary'
              : 'secondary'
          }
          onClick={() => setConfirmForm(ConfirmAnnualTaxFiling.unsure)}
          fullWidth
        >
          I&apos;m not sure
        </Button>
      </GridRowColumn>

      <GridRowColumn {...makeGridConfig([10, 14], true)}>
        <Accordion
          title={infoAccordion.accordionTitle}
          content={infoAccordion.accordionContent}
          trackOnOpenClick={{
            screen: screenName,
            title: infoAccordion.accordionTitle,
          }}
          variant="default"
        />

        {!is1120s && (
          <>
            <Accordion
              title="I’m married filing jointly. Do you need my spouse’s information too?"
              content="Since you and your spouse are considered a single tax filer, we’ll ask for your spouse’s income and identification information during the Tax Questionnaire and tax preparation."
              variant="default"
            />
            <Accordion
              title="I’m married filing separately. Will you file my spouse’s tax return too?"
              content="We currently don’t support separate spouse returns."
              variant="default"
            />
          </>
        )}
      </GridRowColumn>
      <FormFlowFooter
        onBack={() => goBack(null)}
        onForward={justContinue}
        continueDisabled={confirmForm === undefined}
      />
      <SurveyTaxFormHelpModal
        open={modalOpen}
        close={() => {
          setModalOpen(false)
          setConfirmForm(undefined)
        }}
        year={year}
        confirmedFormNeeds={confirmForm}
      />
    </Grid>
  )
}

export default TaxFormPanel
