import { useEffect } from 'react'

import CashflowBarGraph from './CashflowBarGraph'
import CashflowReport from './CashflowReport'
import { useAnalyticsView } from '../../../features/Amplitude'
import PageHeader from '../../shared/PageHeader'

const YearlyReportsPanel = () => {
  const pageView = useAnalyticsView()
  useEffect(() => {
    pageView('allocation bookkeeping report')
  }, [pageView])

  return (
    <>
      <PageHeader header="Annual Reports" />
      <br />
      <CashflowBarGraph />
      <CashflowReport />
    </>
  )
}
export default YearlyReportsPanel
