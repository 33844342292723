import currency from 'currency.js'
import { createSelector } from 'reselect'
import {
  prodCatchupBkPriceId,
  testCatchupBkPriceId,
  CATCHUP_BOOKKEEPING_PRICE_PER_MONTH,
} from '../constants/pricingConstants'
import { getFinancialProfile } from './user.selectors'
import { isoToUTCDateTime } from '../utils/dateHelpers'
import { CatchUpBKMonths } from '../features/CatchUpBookkeepingStatus/catchUpBookkeepingStatus.slice'

export const selectApproximatePracticeStartDate = createSelector(
  getFinancialProfile,
  (fp) => {
    if (!fp?.approximatePracticeStartDate) {
      return null
    }
    const dateObject = isoToUTCDateTime(fp?.approximatePracticeStartDate)
    if (!dateObject.isValid) {
      return null
    }
    return dateObject
  }
)

export const selectCatchupBookkeepingFees = createSelector(
  (_: unknown, monthsToCharge?: CatchUpBKMonths) => monthsToCharge,
  (monthsToCharge) => {
    const isProd = process.env.VITE_IS_PROD === 'true'
    return {
      priceId: isProd ? prodCatchupBkPriceId : testCatchupBkPriceId,
      costPerMonth: CATCHUP_BOOKKEEPING_PRICE_PER_MONTH,
      monthCount: monthsToCharge?.totalMonths ?? 0,
      amount: currency(CATCHUP_BOOKKEEPING_PRICE_PER_MONTH).multiply(
        monthsToCharge?.totalMonths ?? 0
      ),
    }
  }
)
