// UI
import { Icon } from 'semantic-ui-react'
import { Button } from '../../../../components/BaseComponents'

const UploadDocumentButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    icon
    labelPosition="left"
    onClick={onClick}
    style={{ float: 'right' }}
  >
    Upload Documents
    <Icon name="cloud upload" />
  </Button>
)

export default UploadDocumentButton
