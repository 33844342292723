import { useEffect, useState } from 'react'
import { Container, Grid, Image } from 'semantic-ui-react'

import { fetchPayrollAuthLink } from '../payrollActions'
import {
  Checkbox,
  Link,
  Text,
  Button,
  Card,
  GridRowColumn,
} from '../../../components/BaseComponents'
import { GEPPriceCard } from '../Enroll'
import { useAnalyticsView } from '../../Amplitude'
import { useAppDispatch } from '../../../utils/typeHelpers'

const MigratePayroll = () => {
  const dispatch = useAppDispatch()
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [authLink, setAuthLink] = useState('')
  const pageView = useAnalyticsView()

  useEffect(() => {
    const fetchLink = async () => {
      const res = await fetchPayrollAuthLink()(dispatch)
      if (res) {
        setAuthLink(res.authUrl)
      }
    }

    fetchLink()
    pageView('payroll set up')
  }, [dispatch, pageView])

  return (
    <Container>
      <Card>
        <Grid>
          <Grid.Row />
          <Grid.Row />
          <GridRowColumn centerContent>
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/heard_gusto.png"
              style={{ width: 86 }}
            />
          </GridRowColumn>
          <GridRowColumn spacer={2} width={12}>
            <Text as="display" textAlign="center">
              Set up your Gusto Payroll in
              <br />
              Heard
            </Text>
          </GridRowColumn>
          <GridRowColumn spacer={2} width={12}>
            <Text as="bodyLg" textAlign="center">
              We are excited to launch Gusto x Heard. You can now set up all
              your Gusto Payroll information in the Heard platform!
            </Text>
          </GridRowColumn>
          <GridRowColumn spacer={4} width={8}>
            <GEPPriceCard footerText="(This total will be added to the price of your Heard subscription. You will no longer be charged on Gusto)." />
          </GridRowColumn>
          <Grid.Row />
          <GridRowColumn spacer={4}>
            <Checkbox
              checked={acceptTerms}
              onChange={(checked) => setAcceptTerms(checked)}
              style={{ paddingTop: 2 }}
            />
            <Text style={{ marginLeft: 10, display: 'inline' }}>
              I have viewed and accepted the{' '}
              <Link href="https://flows.gusto.com/terms" newPage>
                Embedded Payroll Terms of Service
              </Link>
            </Text>
          </GridRowColumn>
          {authLink && (
            <GridRowColumn spacer={6} width={4}>
              <Button
                onClick={() => window.open(authLink, '_self')}
                disabled={!acceptTerms}
                fullWidth
              >
                Set Up Payroll in Heard
              </Button>
            </GridRowColumn>
          )}
          <Grid.Row />
          <Grid.Row />
        </Grid>
      </Card>
    </Container>
  )
}

export default MigratePayroll
