import axios from 'axios'
import { fetchWrapper } from '../../reducers/fetch'
import { setUserFocusAreas, UserFocusAreas } from './userFocusArea.slice'

export const fetchUserFocusAreas = (userId: number) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const res = await axios.get<UserFocusAreas[]>(
        `/finances/api/v1/user_focus_areas/${userId}`
      )
      dispatch(setUserFocusAreas(res.data))
      return res.data
    },
  })

export const createUserFocusAreas = (payload: Omit<UserFocusAreas, 'id'>) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const res = await axios.post<UserFocusAreas>(
        '/finances/api/v1/user_focus_areas',
        payload
      )
      dispatch(setUserFocusAreas(res.data))
      return res.data
    },
  })

export const updateUserFocusAreas = (
  userId: number,
  payload: Omit<UserFocusAreas, 'id'>
) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const res = await axios.put<UserFocusAreas>(
        `/finances/api/v1/user_focus_areas/${userId}`,
        payload
      )
      dispatch(setUserFocusAreas(res.data))
      return res.data
    },
  })
