import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { ReactNode } from 'react'

interface FlyoutState {
  open: boolean
  flyoutContent: ReactNode | null
  direction: 'right' | 'left' | 'top' | 'bottom'
  title?: string | null
  titleIcon?: IconDefinition | null
  footer: ReactNode | null
  preventClose?: (() => boolean) | null
}

const initialState: FlyoutState = {
  open: false,
  flyoutContent: null,
  title: null,
  direction: 'right',
  titleIcon: null,
  footer: null,
  preventClose: null,
}

const flyoutSlice = createSlice({
  name: 'flyout',
  initialState,
  reducers: {
    openFlyout: (
      state,
      {
        payload,
      }: PayloadAction<{
        content: ReactNode
        title?: string
        direction?: 'right' | 'left' | 'top' | 'bottom'
        titleIcon?: IconDefinition
        footer?: ReactNode
        preventClose?: (() => boolean) | null
      }>
    ) => {
      state.open = true
      state.flyoutContent = payload.content
      state.direction = payload.direction || 'right'
      state.title = payload.title
      state.titleIcon = payload.titleIcon
      state.footer = payload.footer
      state.preventClose = payload.preventClose || null
    },
    closeFlyout: (state) => {
      state.open = false
      state.flyoutContent = null
      state.title = null
      state.titleIcon = null
      state.footer = null
    },
  },
})

export const { openFlyout: setFlyoutContent, closeFlyout: clearFlyoutContent } =
  flyoutSlice.actions

export default flyoutSlice.reducer
