import { Container, Header, Tab } from 'semantic-ui-react'

import DocumentsTable from './DocumentsTable'
import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import { UploadDocumentType } from '../../../constants/businessConstants'

interface Props {
  user: UserWithAdminInfo
}

const Documents = ({ user }: Props) => (
  <Container>
    <Header as="h2">Documents</Header>
    <Tab
      menu={{ secondary: true, pointing: true }}
      panes={[
        {
          menuItem: 'Bank Statements',
          render: () => (
            <DocumentsTable
              user={user}
              documentType={UploadDocumentType.STATEMENT}
            />
          ),
        },
        {
          menuItem: 'Bookkeeping',
          render: () => (
            <DocumentsTable
              user={user}
              documentType={UploadDocumentType.BOOKKEEPING}
            />
          ),
        },
        {
          menuItem: 'Tax',
          render: () => (
            <DocumentsTable user={user} documentType={UploadDocumentType.TAX} />
          ),
        },
        {
          menuItem: 'Incorporation',
          render: () => (
            <DocumentsTable
              user={user}
              documentType={UploadDocumentType.INCORPORATION}
            />
          ),
        },
        {
          menuItem: 'Receipts',
          render: () => (
            <DocumentsTable
              user={user}
              documentType={UploadDocumentType.RECEIPT}
            />
          ),
        },
        {
          menuItem: 'Other',
          render: () => (
            <DocumentsTable
              user={user}
              documentType={UploadDocumentType.OTHER}
            />
          ),
        },
      ]}
    />
  </Container>
)

export default Documents
