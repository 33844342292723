import { useParams } from 'react-router-dom'
import { Divider, Grid, Image } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Card,
  FormikInput,
  FormikLabelError,
  FormikRadioToggleButton,
  getFieldName,
  GridRowColumn,
  Icon,
  Link,
  makeNumberSchema,
  Text,
  Button,
} from '../../../../../../components/BaseComponents'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  centsToDollars,
  dollarsToCents,
} from '../../../../../../utils/currencyHelpers'
import {
  DEDUCTIONS_AND_CREDITS_SCREENS,
  DeductionsAndCreditsStepProps,
} from '.'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../../taxChecklist.selectors'
import { TaxListQuestionId } from '../../service'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import currency from 'currency.js'
import {
  DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  deleteTaxQuestionnaireResponses,
} from '../../taxChecklistQuestion.actions'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { logSentryError } from '../../../../../../utils/sentryHelpers'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'

export const homeOfficeStandardStep2QuestionIds = [
  TaxListQuestionId.home_office_total_standard_amount_in_cents,
  TaxListQuestionId.home_office_indirect_insurance_amount_in_cents,
  TaxListQuestionId.home_office_indirect_rent_amount_in_cents,
  TaxListQuestionId.home_office_indirect_maintenance_amount_in_cents,
  TaxListQuestionId.home_office_indirect_electricity_amount_in_cents,
  TaxListQuestionId.home_office_indirect_water_sewage_amount_in_cents,
  TaxListQuestionId.home_office_indirect_internet_amount_in_cents,
  TaxListQuestionId.home_office_indirect_other_expense_amount_in_cents,
  //they will need to answer whether they had direct expenses, even if they didn't have any
  TaxListQuestionId.home_office_direct_expenses,
  TaxListQuestionId.home_office_direct_insurance_amount_in_cents,
  TaxListQuestionId.home_office_direct_rent_amount_in_cents,
  TaxListQuestionId.home_office_direct_maintenance_amount_in_cents,
  TaxListQuestionId.home_office_direct_electricity_amount_in_cents,
  TaxListQuestionId.home_office_direct_water_sewage_amount_in_cents,
  TaxListQuestionId.home_office_direct_internet_amount_in_cents,
  TaxListQuestionId.home_office_direct_other_expense_amount_in_cents,
]

const HomeOfficeDeductionsStandardStep2Panel = ({
  goBack,
  goToNextStep,
}: DeductionsAndCreditsStepProps) => {
  const dispatch = useAppDispatch()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()

  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
  ])

  const questionTotalStandardAmount = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_office_total_standard_amount_in_cents,
    taxYear
  )
  const responseTotalStandardAmount = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_total_standard_amount_in_cents,
    Number(formId)
  )

  const questionInsuranceIndirect = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_office_indirect_insurance_amount_in_cents,
    taxYear
  )
  const responseInsuranceIndirect = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_indirect_insurance_amount_in_cents,
    Number(formId)
  )

  const questionRentIndirect = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_office_indirect_rent_amount_in_cents,
    taxYear
  )
  const responseRentIndirect = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_indirect_rent_amount_in_cents,
    Number(formId)
  )

  const questionMaintenanceIndirect = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_office_indirect_maintenance_amount_in_cents,
    taxYear
  )
  const responseMaintenanceIndirect = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_indirect_maintenance_amount_in_cents,
    Number(formId)
  )

  const questionElectricityIndirect = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_office_indirect_electricity_amount_in_cents,
    taxYear
  )
  const responseElectricityIndirect = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_indirect_electricity_amount_in_cents,
    Number(formId)
  )

  const questionWaterSewageIndirect = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_office_indirect_water_sewage_amount_in_cents,
    taxYear
  )
  const responseWaterSewageIndirect = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_indirect_water_sewage_amount_in_cents,
    Number(formId)
  )

  const questionInternetIndirect = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_office_indirect_internet_amount_in_cents,
    taxYear
  )
  const responseInternetIndirect = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_indirect_internet_amount_in_cents,
    Number(formId)
  )

  const questionOtherExpensesIndirect = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_office_indirect_other_expense_amount_in_cents,
    taxYear
  )
  const responseOtherExpensesIndirect = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_indirect_other_expense_amount_in_cents,
    Number(formId)
  )

  const questionDirectExpenses = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_office_direct_expenses,
    taxYear
  )
  const responseDirectExpenses = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_direct_expenses,
    Number(formId)
  )

  const questionInsuranceDirect = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_office_direct_insurance_amount_in_cents,
    taxYear
  )
  const responseInsuranceDirect = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_direct_insurance_amount_in_cents,
    Number(formId)
  )

  const questionRentDirect = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_office_direct_rent_amount_in_cents,
    taxYear
  )
  const responseRentDirect = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_direct_rent_amount_in_cents,
    Number(formId)
  )

  const questionMaintenanceDirect = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_office_direct_maintenance_amount_in_cents,
    taxYear
  )
  const responseMaintenanceDirect = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_direct_maintenance_amount_in_cents,
    Number(formId)
  )

  const questionElectricityDirect = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_office_direct_electricity_amount_in_cents,
    taxYear
  )
  const responseElectricityDirect = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_direct_electricity_amount_in_cents,
    Number(formId)
  )

  const questionWaterSewageDirect = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_office_direct_water_sewage_amount_in_cents,
    taxYear
  )
  const responseWaterSewageDirect = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_direct_water_sewage_amount_in_cents,
    Number(formId)
  )

  const questionInternetDirect = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_office_direct_internet_amount_in_cents,
    taxYear
  )
  const responseInternetDirect = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_direct_internet_amount_in_cents,
    Number(formId)
  )

  const questionOtherExpensesDirect = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_office_direct_other_expense_amount_in_cents,
    taxYear
  )
  const responseOtherExpensesDirect = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_direct_other_expense_amount_in_cents,
    Number(formId)
  )

  const formik = useFormik({
    initialValues: {
      totalStandardAmountInDollars:
        typeof responseTotalStandardAmount?.[0]?.value === 'number'
          ? centsToDollars(responseTotalStandardAmount[0].value)
          : undefined,
      indirectInsuranceInDollars:
        typeof responseInsuranceIndirect?.[0]?.value === 'number'
          ? centsToDollars(responseInsuranceIndirect[0].value)
          : undefined,
      indirectRentInDollars:
        typeof responseRentIndirect?.[0]?.value === 'number'
          ? centsToDollars(responseRentIndirect[0].value)
          : undefined,
      indirectRepairsAndMaintenanceInDollars:
        typeof responseMaintenanceIndirect?.[0]?.value === 'number'
          ? centsToDollars(responseMaintenanceIndirect[0].value)
          : undefined,
      indirectElectricityInDollars:
        typeof responseElectricityIndirect?.[0]?.value === 'number'
          ? centsToDollars(responseElectricityIndirect[0].value)
          : undefined,
      indirectWaterSewageInDollars:
        typeof responseWaterSewageIndirect?.[0]?.value === 'number'
          ? centsToDollars(responseWaterSewageIndirect[0].value)
          : undefined,
      indirectInternetInDollars:
        typeof responseInternetIndirect?.[0]?.value === 'number'
          ? centsToDollars(responseInternetIndirect[0].value)
          : undefined,
      indirectOtherExpensesInDollars:
        typeof responseOtherExpensesIndirect?.[0]?.value === 'number'
          ? centsToDollars(responseOtherExpensesIndirect[0].value)
          : undefined,
      haveDirectExpensesRelatedToHomeOffice: responseDirectExpenses?.[0]?.value,
      directInsuranceInDollars:
        typeof responseInsuranceDirect?.[0]?.value === 'number'
          ? centsToDollars(responseInsuranceDirect[0].value)
          : undefined,
      directRentInDollars:
        typeof responseRentDirect?.[0]?.value === 'number'
          ? centsToDollars(responseRentDirect[0].value)
          : undefined,
      directRepairsAndMaintenanceInDollars:
        typeof responseMaintenanceDirect?.[0]?.value === 'number'
          ? centsToDollars(responseMaintenanceDirect[0].value)
          : undefined,
      directElectricityInDollars:
        typeof responseElectricityDirect?.[0]?.value === 'number'
          ? centsToDollars(responseElectricityDirect[0].value)
          : undefined,
      directWaterSewageInDollars:
        typeof responseWaterSewageDirect?.[0]?.value === 'number'
          ? centsToDollars(responseWaterSewageDirect[0].value)
          : undefined,
      directInternetInDollars:
        typeof responseInternetDirect?.[0]?.value === 'number'
          ? centsToDollars(responseInternetDirect[0].value)
          : undefined,
      directOtherExpensesInDollars:
        typeof responseOtherExpensesDirect?.[0]?.value === 'number'
          ? centsToDollars(responseOtherExpensesDirect[0].value)
          : undefined,
    },
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        const responseData: Partial<TaxChecklistResponse>[] = []
        if (
          values.totalStandardAmountInDollars &&
          dollarsToCents(currency(values.totalStandardAmountInDollars)) !==
            responseTotalStandardAmount?.[0]?.value
        ) {
          responseData.push({
            id: responseTotalStandardAmount?.[0]?.id,
            value: dollarsToCents(
              currency(values.totalStandardAmountInDollars)
            ),
            annualTaxFilingFormId: Number(formId),
            questionId:
              TaxListQuestionId.home_office_total_standard_amount_in_cents,
          })
        }
        if (
          values.indirectInsuranceInDollars &&
          dollarsToCents(currency(values.indirectInsuranceInDollars)) !==
            responseInsuranceIndirect?.[0]?.value
        ) {
          responseData.push({
            id: responseInsuranceIndirect?.[0]?.id,
            value: dollarsToCents(currency(values.indirectInsuranceInDollars)),
            annualTaxFilingFormId: Number(formId),
            questionId:
              TaxListQuestionId.home_office_indirect_insurance_amount_in_cents,
          })
        }
        if (
          values.indirectRentInDollars &&
          dollarsToCents(currency(values.indirectRentInDollars)) !==
            responseRentIndirect?.[0]?.value
        ) {
          responseData.push({
            id: responseRentIndirect?.[0]?.id,
            value: dollarsToCents(currency(values.indirectRentInDollars)),
            annualTaxFilingFormId: Number(formId),
            questionId:
              TaxListQuestionId.home_office_indirect_rent_amount_in_cents,
          })
        }
        if (
          values.indirectRepairsAndMaintenanceInDollars &&
          dollarsToCents(
            currency(values.indirectRepairsAndMaintenanceInDollars)
          ) !== responseMaintenanceIndirect?.[0]?.value
        ) {
          responseData.push({
            id: responseMaintenanceIndirect?.[0]?.id,
            value: dollarsToCents(
              currency(values.indirectRepairsAndMaintenanceInDollars)
            ),
            annualTaxFilingFormId: Number(formId),
            questionId:
              TaxListQuestionId.home_office_indirect_maintenance_amount_in_cents,
          })
        }
        if (
          values.indirectElectricityInDollars &&
          dollarsToCents(currency(values.indirectElectricityInDollars)) !==
            responseElectricityIndirect?.[0]?.value
        ) {
          responseData.push({
            id: responseElectricityIndirect?.[0]?.id,
            value: dollarsToCents(
              currency(values.indirectElectricityInDollars)
            ),
            annualTaxFilingFormId: Number(formId),
            questionId:
              TaxListQuestionId.home_office_indirect_electricity_amount_in_cents,
          })
        }
        if (
          values.indirectWaterSewageInDollars &&
          dollarsToCents(currency(values.indirectWaterSewageInDollars)) !==
            responseWaterSewageIndirect?.[0]?.value
        ) {
          responseData.push({
            id: responseWaterSewageIndirect?.[0]?.id,
            value: dollarsToCents(
              currency(values.indirectWaterSewageInDollars)
            ),
            annualTaxFilingFormId: Number(formId),
            questionId:
              TaxListQuestionId.home_office_indirect_water_sewage_amount_in_cents,
          })
        }
        if (
          values.indirectInternetInDollars &&
          dollarsToCents(currency(values.indirectInternetInDollars)) !==
            responseInternetIndirect?.[0]?.value
        ) {
          responseData.push({
            id: responseInternetIndirect?.[0]?.id,
            value: dollarsToCents(currency(values.indirectInternetInDollars)),
            annualTaxFilingFormId: Number(formId),
            questionId:
              TaxListQuestionId.home_office_indirect_internet_amount_in_cents,
          })
        }
        if (
          values.indirectOtherExpensesInDollars &&
          dollarsToCents(currency(values.indirectOtherExpensesInDollars)) !==
            responseOtherExpensesIndirect?.[0]?.value
        ) {
          responseData.push({
            id: responseOtherExpensesIndirect?.[0]?.id,
            value: dollarsToCents(
              currency(values.indirectOtherExpensesInDollars)
            ),
            annualTaxFilingFormId: Number(formId),
            questionId:
              TaxListQuestionId.home_office_indirect_other_expense_amount_in_cents,
          })
        }
        if (
          values.haveDirectExpensesRelatedToHomeOffice !==
          responseDirectExpenses?.[0]?.value
        ) {
          responseData.push({
            id: responseDirectExpenses?.[0]?.id,
            value: values.haveDirectExpensesRelatedToHomeOffice,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.home_office_direct_expenses,
          })
        }
        if (
          values.haveDirectExpensesRelatedToHomeOffice &&
          values.directInsuranceInDollars &&
          dollarsToCents(currency(values.directInsuranceInDollars)) !==
            responseInsuranceDirect?.[0]?.value
        ) {
          responseData.push({
            id: responseInsuranceDirect?.[0]?.id,
            value: dollarsToCents(currency(values.directInsuranceInDollars)),
            annualTaxFilingFormId: Number(formId),
            questionId:
              TaxListQuestionId.home_office_direct_insurance_amount_in_cents,
          })
        }
        if (
          values.haveDirectExpensesRelatedToHomeOffice &&
          values.directRentInDollars &&
          dollarsToCents(currency(values.directRentInDollars)) !==
            responseRentDirect?.[0]?.value
        ) {
          responseData.push({
            id: responseRentDirect?.[0]?.id,
            value: dollarsToCents(currency(values.directRentInDollars)),
            annualTaxFilingFormId: Number(formId),
            questionId:
              TaxListQuestionId.home_office_direct_rent_amount_in_cents,
          })
        }
        if (
          values.haveDirectExpensesRelatedToHomeOffice &&
          values.directRepairsAndMaintenanceInDollars &&
          dollarsToCents(
            currency(values.directRepairsAndMaintenanceInDollars)
          ) !== responseMaintenanceDirect?.[0]?.value
        ) {
          responseData.push({
            id: responseMaintenanceDirect?.[0]?.id,
            value: dollarsToCents(
              currency(values.directRepairsAndMaintenanceInDollars)
            ),
            annualTaxFilingFormId: Number(formId),
            questionId:
              TaxListQuestionId.home_office_direct_maintenance_amount_in_cents,
          })
        }
        if (
          values.haveDirectExpensesRelatedToHomeOffice &&
          values.directElectricityInDollars &&
          dollarsToCents(currency(values.directElectricityInDollars)) !==
            responseElectricityDirect?.[0]?.value
        ) {
          responseData.push({
            id: responseElectricityDirect?.[0]?.id,
            value: dollarsToCents(currency(values.directElectricityInDollars)),
            annualTaxFilingFormId: Number(formId),
            questionId:
              TaxListQuestionId.home_office_direct_electricity_amount_in_cents,
          })
        }
        if (
          values.haveDirectExpensesRelatedToHomeOffice &&
          values.directWaterSewageInDollars &&
          dollarsToCents(currency(values.directWaterSewageInDollars)) !==
            responseWaterSewageDirect?.[0]?.value
        ) {
          responseData.push({
            id: responseWaterSewageDirect?.[0]?.id,
            value: dollarsToCents(currency(values.directWaterSewageInDollars)),
            annualTaxFilingFormId: Number(formId),
            questionId:
              TaxListQuestionId.home_office_direct_water_sewage_amount_in_cents,
          })
        }
        if (
          values.haveDirectExpensesRelatedToHomeOffice &&
          values.directInternetInDollars &&
          dollarsToCents(currency(values.directInternetInDollars)) !==
            responseInternetDirect?.[0]?.value
        ) {
          responseData.push({
            id: responseInternetDirect?.[0]?.id,
            value: dollarsToCents(currency(values.directInternetInDollars)),
            annualTaxFilingFormId: Number(formId),
            questionId:
              TaxListQuestionId.home_office_direct_internet_amount_in_cents,
          })
        }
        if (
          values.haveDirectExpensesRelatedToHomeOffice &&
          values.directOtherExpensesInDollars &&
          dollarsToCents(currency(values.directOtherExpensesInDollars)) !==
            responseOtherExpensesDirect?.[0]?.value
        ) {
          responseData.push({
            id: responseOtherExpensesDirect?.[0]?.id,
            value: dollarsToCents(
              currency(values.directOtherExpensesInDollars)
            ),
            annualTaxFilingFormId: Number(formId),
            questionId:
              TaxListQuestionId.home_office_direct_other_expense_amount_in_cents,
          })
        }

        let deletionSucceeded = true
        //if there are no direct expenses, delete the direct expenses responses
        if (values.haveDirectExpensesRelatedToHomeOffice === false) {
          const idsToDelete: number[] = []
          if (responseInsuranceDirect.length) {
            idsToDelete.push(responseInsuranceDirect[0].id)
          }
          if (responseRentDirect.length) {
            idsToDelete.push(responseRentDirect[0].id)
          }
          if (responseMaintenanceDirect.length) {
            idsToDelete.push(responseMaintenanceDirect[0].id)
          }
          if (responseElectricityDirect.length) {
            idsToDelete.push(responseElectricityDirect[0].id)
          }
          if (responseWaterSewageDirect.length) {
            idsToDelete.push(responseWaterSewageDirect[0].id)
          }
          if (responseInternetDirect.length) {
            idsToDelete.push(responseInternetDirect[0].id)
          }
          if (responseOtherExpensesDirect.length) {
            idsToDelete.push(responseOtherExpensesDirect[0].id)
          }
          if (idsToDelete.length) {
            deletionSucceeded = Boolean(
              await dispatch(deleteTaxQuestionnaireResponses(idsToDelete))
            )
          }
        }
        if (deletionSucceeded) {
          await goToNextStep(
            responseData,
            DEDUCTIONS_AND_CREDITS_SCREENS.homeOffice
          )
        }
      } catch (err) {
        logSentryError(err)
      }
    },
  })

  const { values, submitForm, isValid, validateForm, isSubmitting } = formik

  return (
    <Grid>
      <Grid.Row />
      <Grid.Row />
      <GridRowColumn>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/big-house.svg"
          style={{ margin: 'auto', width: 120 }}
        />
      </GridRowColumn>
      <GridRowColumn>
        <Text as="display2" textAlign="center">
          Step 2: Standard Method
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([8, 14], true)}>
        <Text as="bodyMd">
          Next, we need the yearly totals of your indirect expenses.
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([8, 14], true)}>
        <Link
          href="https://support.joinheard.com/hc/en-us/articles/10270233571607-Home-Office-Deductions-FAQs"
          newPage
        >
          Home Office Deduction FAQs
        </Link>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([8, 14], true)}>
        <Card backgroundColor="stone40" centered>
          <Grid>
            <GridRowColumn>
              <Text as="h1" textAlign="left">
                Calculate Deduction
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text as="bodyLg" textAlign="left">
                Download the template to calculate your estimated home office
                deduction and fill in the boxes below.
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Button
                variant="secondary"
                icon={regular('file-arrow-down')}
                onClick={() =>
                  window.open(
                    'https://docs.google.com/spreadsheets/d/1jxQ8gmkIntxjbYeUD8c7bBUGA6_PL0UigU_avntoqkM/',
                    '_blank',
                    'noreferrer'
                  )
                }
              >
                <Icon
                  icon={regular('file-arrow-down')}
                  style={{ marginRight: 8 }}
                />
                Download Template
              </Button>
            </GridRowColumn>
            <GridRowColumn textAlign="left">
              <FormikProvider value={formik}>
                <FormikInput
                  description="Enter the Total Home Office Deduction amount found in the Home Office Deduction Template"
                  label={questionTotalStandardAmount?.question?.text}
                  name={getFieldName<typeof values>(
                    'totalStandardAmountInDollars'
                  )}
                  placeholder="$0"
                  componentType="currency"
                  schema={makeNumberSchema({
                    field: 'total deduction amount',
                    allowedDecimals: 2,
                  })}
                  fullWidth
                />
              </FormikProvider>
            </GridRowColumn>
          </Grid>
        </Card>
      </GridRowColumn>

      <FormikProvider value={formik}>
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <Card backgroundColor="stone40">
            <Grid>
              <GridRowColumn>
                <Text as="h1">{taxYear} Indirect Expenses</Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text as="bodyMd">
                  These are the total costs of owning/renting the home as a
                  whole. These expenses will be allocated based on the square
                  footage used exclusively for business.
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text as="bodyMd">
                  Use the Home Office Deduction Template to populate the numbers
                  below.
                </Text>
              </GridRowColumn>
              <Divider />
              <Grid.Row>
                <Grid.Column width={8}>
                  <FormikInput
                    name={getFieldName<typeof values>(
                      'indirectInsuranceInDollars'
                    )}
                    label={questionInsuranceIndirect?.question?.text}
                    placeholder="Enter total $ amount"
                    componentType="currency"
                    schema={makeNumberSchema({
                      field: 'insurance amount',
                      allowedDecimals: 2,
                    })}
                    fullWidth
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <FormikInput
                    name={getFieldName<typeof values>('indirectRentInDollars')}
                    label={questionRentIndirect?.question?.text}
                    placeholder="Enter total $ amount"
                    componentType="currency"
                    schema={makeNumberSchema({
                      field: 'rent amount',
                      allowedDecimals: 2,
                    })}
                    fullWidth
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <FormikInput
                    name={getFieldName<typeof values>(
                      'indirectRepairsAndMaintenanceInDollars'
                    )}
                    label={questionMaintenanceIndirect?.question?.text}
                    placeholder={'Enter total $ amount'}
                    componentType="currency"
                    schema={makeNumberSchema({
                      field: 'repairs and maintenance amount',
                      allowedDecimals: 2,
                    })}
                    fullWidth
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <FormikInput
                    name={getFieldName<typeof values>(
                      'indirectElectricityInDollars'
                    )}
                    label={questionElectricityIndirect?.question?.text}
                    placeholder={'Enter total $ amount'}
                    componentType="currency"
                    schema={makeNumberSchema({
                      field: 'electricity amount',
                      allowedDecimals: 2,
                    })}
                    fullWidth
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <FormikInput
                    name={getFieldName<typeof values>(
                      'indirectWaterSewageInDollars'
                    )}
                    label={questionWaterSewageIndirect?.question?.text}
                    placeholder="Enter total $ amount"
                    componentType="currency"
                    schema={makeNumberSchema({
                      field: 'water/sewage amount',
                      allowedDecimals: 2,
                    })}
                    fullWidth
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <FormikInput
                    name={getFieldName<typeof values>(
                      'indirectInternetInDollars'
                    )}
                    label={questionInternetIndirect?.question?.text}
                    placeholder="Enter total $ amount"
                    componentType="currency"
                    schema={makeNumberSchema({
                      field: 'internet amount',
                      allowedDecimals: 2,
                    })}
                    fullWidth
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <FormikInput
                    name={getFieldName<typeof values>(
                      'indirectOtherExpensesInDollars'
                    )}
                    label={questionOtherExpensesIndirect?.question?.text}
                    placeholder="Enter total $ amount"
                    componentType="currency"
                    schema={makeNumberSchema({
                      field: 'other expenses amount',
                      allowedDecimals: 2,
                    })}
                    fullWidth
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <Card backgroundColor="stone40">
            <Grid>
              <GridRowColumn>
                <Text as="h1">{taxYear} Direct Expenses</Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text as="bodyMd">
                  These are costs that deal specifically with your home office.
                </Text>
              </GridRowColumn>
              <Divider />
              <GridRowColumn>
                <FormikLabelError
                  name={getFieldName<typeof values>(
                    'haveDirectExpensesRelatedToHomeOffice'
                  )}
                  label={questionDirectExpenses?.question?.text}
                />
              </GridRowColumn>
              <Grid.Row className="short" style={{ justifyContent: 'center' }}>
                <Grid.Column width={8}>
                  <FormikRadioToggleButton
                    name={getFieldName<typeof values>(
                      'haveDirectExpensesRelatedToHomeOffice'
                    )}
                    value
                    fullWidth
                  >
                    Yes
                  </FormikRadioToggleButton>
                </Grid.Column>
                <Grid.Column width={8}>
                  <FormikRadioToggleButton
                    name={getFieldName<typeof values>(
                      'haveDirectExpensesRelatedToHomeOffice'
                    )}
                    value={false}
                    onClick={validateForm}
                    fullWidth
                  >
                    No
                  </FormikRadioToggleButton>
                </Grid.Column>
              </Grid.Row>
              {values.haveDirectExpensesRelatedToHomeOffice && (
                <>
                  <GridRowColumn>
                    <Text as="bodyMd">
                      Use the Home Office Deduction Template to populate the
                      numbers below.
                    </Text>
                  </GridRowColumn>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <FormikInput
                        name={getFieldName<typeof values>(
                          'directInsuranceInDollars'
                        )}
                        label={questionInsuranceDirect?.question?.text}
                        placeholder="Enter total $ amount"
                        componentType="currency"
                        schema={makeNumberSchema({
                          field: 'insurance amount',
                          allowedDecimals: 2,
                        })}
                        fullWidth
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <FormikInput
                        name={getFieldName<typeof values>(
                          'directRentInDollars'
                        )}
                        label={questionRentDirect?.question?.text}
                        placeholder="Enter total $ amount"
                        componentType="currency"
                        schema={makeNumberSchema({
                          field: 'rent amount',
                          allowedDecimals: 2,
                        })}
                        fullWidth
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <FormikInput
                        name={getFieldName<typeof values>(
                          'directRepairsAndMaintenanceInDollars'
                        )}
                        label={questionMaintenanceDirect?.question?.text}
                        placeholder="Enter total $ amount"
                        componentType="currency"
                        schema={makeNumberSchema({
                          field: 'repairs and maintenance amount',
                          allowedDecimals: 2,
                        })}
                        fullWidth
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <FormikInput
                        name={getFieldName<typeof values>(
                          'directElectricityInDollars'
                        )}
                        label={questionElectricityDirect?.question?.text}
                        placeholder="Enter total $ amount"
                        componentType="currency"
                        schema={makeNumberSchema({
                          field: 'electricity amount',
                          allowedDecimals: 2,
                        })}
                        fullWidth
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <FormikInput
                        name={getFieldName<typeof values>(
                          'directWaterSewageInDollars'
                        )}
                        label={questionWaterSewageDirect?.question?.text}
                        placeholder="Enter total $ amount"
                        componentType="currency"
                        schema={makeNumberSchema({
                          field: 'water/sewage amount',
                          allowedDecimals: 2,
                        })}
                        fullWidth
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <FormikInput
                        name={getFieldName<typeof values>(
                          'directInternetInDollars'
                        )}
                        label={questionInternetDirect?.question?.text}
                        placeholder="Enter total $ amount"
                        componentType="currency"
                        schema={makeNumberSchema({
                          field: 'internet amount',
                          allowedDecimals: 2,
                        })}
                        fullWidth
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <FormikInput
                        name={getFieldName<typeof values>(
                          'directOtherExpensesInDollars'
                        )}
                        label={questionOtherExpensesDirect?.question?.text}
                        placeholder="Enter total $ amount"
                        componentType="currency"
                        schema={makeNumberSchema({
                          field: 'other expenses amount',
                          allowedDecimals: 2,
                        })}
                        fullWidth
                      />
                    </Grid.Column>
                  </Grid.Row>
                </>
              )}
            </Grid>
          </Card>
        </GridRowColumn>
      </FormikProvider>
      <FormFlowFooter
        onBack={() =>
          goBack(DEDUCTIONS_AND_CREDITS_SCREENS.homeOfficeStandardStep1)
        }
        onForward={submitForm}
        continueDisabled={!isValid || isSubmitting}
        loading={isUpdating || isSubmitting}
      />
    </Grid>
  )
}

export default HomeOfficeDeductionsStandardStep2Panel
