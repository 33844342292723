import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Divider, Grid, List } from 'semantic-ui-react'
import { useFormik, FormikProvider } from 'formik'
import { isArray, isEqual } from 'lodash'
import currency from 'currency.js'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  GridRowColumn,
  getFieldName,
  Text,
  FormikDropdown,
  FormikLabelError,
  FormikRadioToggleButton,
  makeReqBoolSchema,
  makeStringArraySchema,
  Popup,
  Button,
  FormikInput,
  makeNumberSchema,
  FormikCheckboxMulti,
  Icon,
  makeReqStringSchema,
} from '../../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { TaxListOptionId, TaxListQuestionId } from '../../service'
import {
  selectIsMarriedFilingJointlyOrQualifyingWidow,
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../../taxChecklist.selectors'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import {
  DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  deleteTaxQuestionnaireResponses,
} from '../../taxChecklistQuestion.actions'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { Form1040DetailsProps } from '.'
import DocumentAddedAlert from '../../Shared/SpecialtyAlerts'
import {
  centsToDollars,
  dollarsToCents,
} from '../../../../../../utils/currencyHelpers'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'

export const healthcareInfoQuestionIds = [
  TaxListQuestionId.healthcare_coverage_type,
  TaxListQuestionId.healthcare_coverage_explanation,
  TaxListQuestionId.healthcare_coverage_type_spouse,
  TaxListQuestionId.healthcare_had_account,
  TaxListQuestionId.healthcare_account_types,
  TaxListQuestionId.healthcare_all_qualified_expenses,
  TaxListQuestionId.healthcare_distributions_amount_in_cents,
]

const PopupContent = () => {
  return (
    <Popup
      content={
        <Grid>
          <GridRowColumn>
            <Text as="h3">Marketplace tax forms</Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Grid>
              <GridRowColumn>
                <Text>
                  If you bought health insurance from the marketplace,
                  you&apos;ll need to upload these:
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <List bulleted>
                  <List.Item>Form 1095-A</List.Item>
                  <List.Item>Form 8962</List.Item>
                </List>
              </GridRowColumn>
              <GridRowColumn>
                <Text>
                  Some states also require a state-specific form. For example:
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <List bulleted>
                  <List.Item>CA has Form 3895</List.Item>
                  <List.Item>MA has Form 1099-HC</List.Item>
                </List>
              </GridRowColumn>
            </Grid>
          </GridRowColumn>
        </Grid>
      }
      trigger={<Button variant="tooltipLink">Learn more</Button>}
    />
  )
}

const HealthcareInfoPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: Form1040DetailsProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()
  const dispatch = useAppDispatch()
  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
  ])

  const questionCoverageType = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.healthcare_coverage_type,
    taxYear
  )
  const responseCoverageType = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.healthcare_coverage_type,
    Number(formId)
  )
  const questionCoverageExplanation = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.healthcare_coverage_explanation,
    taxYear
  )
  const responseCoverageExplanation = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.healthcare_coverage_explanation,
    Number(formId)
  )
  const questionCoverageTypeSpouse = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.healthcare_coverage_type_spouse,
    taxYear
  )
  const responseCoverageTypeSpouse = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.healthcare_coverage_type_spouse,
    Number(formId)
  )
  const questionHadAccount = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.healthcare_had_account,
    taxYear
  )
  const responseHadAccount = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.healthcare_had_account,
    Number(formId)
  )
  const questionAccountTypes = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.healthcare_account_types,
    taxYear
  )
  const responseAccountTypes = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.healthcare_account_types,
    Number(formId)
  )
  const questionAllQualifiedExpenses = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.healthcare_all_qualified_expenses,
    taxYear
  )
  const responseAllQualifiedExpenses = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.healthcare_all_qualified_expenses,
    Number(formId)
  )
  const questionDistributionsAmountInCents = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.healthcare_distributions_amount_in_cents,
    taxYear
  )
  const responseDistributionsAmountInCents = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.healthcare_distributions_amount_in_cents,
    Number(formId)
  )

  const showSpouseQuestion = useReselector(
    selectIsMarriedFilingJointlyOrQualifyingWidow,
    TaxListQuestionId.filing_status,
    taxYear
  )

  const formik = useFormik({
    initialValues: {
      coverageType: responseCoverageType?.[0]?.value,
      coverageExplanation: responseCoverageExplanation?.[0]?.value,
      coverageTypeSpouse: responseCoverageTypeSpouse?.[0]?.value,
      hadAccount: responseHadAccount?.[0]?.value,
      accountTypes: responseAccountTypes?.[0]?.value,
      allQualifiedExpenses: responseAllQualifiedExpenses?.[0]?.value,
      distributionAmount:
        typeof responseDistributionsAmountInCents?.[0]?.value === 'number'
          ? centsToDollars(responseDistributionsAmountInCents[0].value)
          : undefined,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      if (!isEqual(values.coverageType, responseCoverageType?.[0]?.value)) {
        responseData.push({
          id: responseCoverageType?.[0]?.id,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.healthcare_coverage_type,
          value: values.coverageType,
        })
      }
      if (
        isArray(values.coverageType) &&
        values.coverageType.includes(TaxListOptionId.health_insurance_other) &&
        values.coverageExplanation !== responseCoverageExplanation?.[0]?.value
      ) {
        responseData.push({
          id: responseCoverageExplanation?.[0]?.id,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.healthcare_coverage_explanation,
          value: values.coverageExplanation,
        })
      }
      if (
        showSpouseQuestion &&
        !isEqual(
          values.coverageTypeSpouse,
          responseCoverageTypeSpouse?.[0]?.value
        )
      ) {
        responseData.push({
          id: responseCoverageTypeSpouse?.[0]?.id,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.healthcare_coverage_type_spouse,
          value: values.coverageTypeSpouse,
        })
      }
      if (values.hadAccount !== responseHadAccount?.[0]?.value) {
        responseData.push({
          id: responseHadAccount?.[0]?.id,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.healthcare_had_account,
          value: values.hadAccount,
        })
      }
      if (
        values.hadAccount &&
        values.accountTypes !== responseAccountTypes?.[0]?.value
      ) {
        responseData.push({
          id: responseAccountTypes?.[0]?.id,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.healthcare_account_types,
          value: values.accountTypes,
        })
      }
      if (
        values.hadAccount &&
        values.allQualifiedExpenses !== responseAllQualifiedExpenses?.[0]?.value
      ) {
        responseData.push({
          id: responseAllQualifiedExpenses?.[0]?.id,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.healthcare_all_qualified_expenses,
          value: values.allQualifiedExpenses,
        })
      }
      if (
        values.hadAccount &&
        values.distributionAmount &&
        dollarsToCents(currency(values.distributionAmount)) !==
          responseDistributionsAmountInCents?.[0]?.value
      ) {
        responseData.push({
          id: responseDistributionsAmountInCents?.[0]?.id,
          annualTaxFilingFormId: Number(formId),
          questionId:
            TaxListQuestionId.healthcare_distributions_amount_in_cents,
          value: dollarsToCents(currency(values.distributionAmount)),
        })
      }
      let deleteSuccess = true
      const idsToDelete: number[] = []

      if (
        values.coverageType !== TaxListOptionId.health_insurance_other &&
        responseCoverageExplanation?.[0]?.id
      ) {
        idsToDelete.push(responseCoverageExplanation?.[0]?.id)
      }

      if (
        (values.allQualifiedExpenses === true || values.hadAccount === false) &&
        responseDistributionsAmountInCents?.[0]?.id
      ) {
        idsToDelete.push(responseDistributionsAmountInCents?.[0]?.id)
      }

      if (values.hadAccount === false) {
        if (responseAccountTypes?.[0]?.id) {
          idsToDelete.push(responseAccountTypes?.[0]?.id)
        }
        if (responseAllQualifiedExpenses?.[0]?.id) {
          idsToDelete.push(responseAllQualifiedExpenses?.[0]?.id)
        }
      }

      if (idsToDelete.length > 0) {
        deleteSuccess = Boolean(
          await dispatch(deleteTaxQuestionnaireResponses(idsToDelete))
        )
      }
      if (deleteSuccess) {
        await goToNextStep(responseData, nextScreen ?? null, {
          completedSteps: [
            SubStepIdentifiers.updateBusinessAndHealthcareInformation,
          ],
        })
      }
    },
  })

  const { values, isValid, isSubmitting, submitForm, validateForm } = formik

  const getCoverageDocumentText = useCallback((coverageType: string) => {
    switch (coverageType) {
      case TaxListOptionId.health_insurance_employer:
        return 'Form 1095-C added to checklist'
      case TaxListOptionId.health_insurance_other:
        return 'Form 1095-B added to checklist'
      case TaxListOptionId.health_insurance_marketplace:
        return 'Form 1095-A added to checklist'
      default:
        return 'Please note that you may be penalized on either the federal or state return.'
    }
  }, [])

  return (
    <FormikProvider value={formik}>
      <Container style={{ marginTop: 56 }} text textAlign="left">
        <Grid>
          <GridRowColumn>
            <Text as="display2" textAlign="center">
              Update Healthcare Information
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="bodyLg">
              Please update the following information for {taxYear}. These
              answers will appear on your tax return.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <DocumentAddedAlert>
              When you see the “add file” icon, we&apos;ve added a form
              requirement to your Upload Documents checklist. This list is at
              the end of the questionnaire, which is when you&apos;ll upload
              your documents.
            </DocumentAddedAlert>
          </GridRowColumn>
          <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
            <FormikDropdown
              name={getFieldName<typeof values>('coverageType')}
              label={questionCoverageType.question?.text}
              options={questionCoverageType?.options?.map((o) => ({
                text: o.text,
                value: o.id,
                key: o.id,
              }))}
              placeholder="Select"
              schema={makeStringArraySchema()}
              fullWidth
              multiple
            />
          </GridRowColumn>
          {isArray(values.coverageType) &&
            values.coverageType.map((coverageType) => {
              if (coverageType === TaxListOptionId.health_insurance_medicare) {
                return null
              }
              return (
                <GridRowColumn
                  key={`user-${coverageType}`}
                  width={12}
                  style={{ justifyContent: 'center' }}
                >
                  <DocumentAddedAlert>
                    {getCoverageDocumentText(coverageType)}
                    {coverageType ===
                      TaxListOptionId.health_insurance_marketplace && (
                      <PopupContent />
                    )}
                  </DocumentAddedAlert>
                </GridRowColumn>
              )
            })}
          {isArray(values.coverageType) &&
            values.coverageType.includes(
              TaxListOptionId.health_insurance_other
            ) && (
              <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
                <FormikInput
                  name={getFieldName<typeof values>('coverageExplanation')}
                  label={questionCoverageExplanation.question?.text}
                  placeholder="Enter explanation"
                  schema={makeReqStringSchema()}
                  fullWidth
                />
              </GridRowColumn>
            )}
          {showSpouseQuestion && (
            <>
              <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
                <FormikDropdown
                  name={getFieldName<typeof values>('coverageTypeSpouse')}
                  label={questionCoverageTypeSpouse.question?.text}
                  options={questionCoverageTypeSpouse?.options?.map((o) => ({
                    text: o.text,
                    value: o.id,
                    key: o.id,
                  }))}
                  placeholder="Select"
                  schema={makeStringArraySchema()}
                  fullWidth
                  multiple
                />
              </GridRowColumn>
              {isArray(values.coverageTypeSpouse) &&
                values.coverageTypeSpouse.map((coverageType) => {
                  if (
                    coverageType === TaxListOptionId.health_insurance_medicare
                  ) {
                    return null
                  }
                  return (
                    <GridRowColumn
                      key={`spouse-${coverageType}`}
                      width={12}
                      style={{ justifyContent: 'center' }}
                    >
                      <DocumentAddedAlert>
                        {getCoverageDocumentText(coverageType)}
                        {coverageType ===
                          TaxListOptionId.health_insurance_marketplace && (
                          <PopupContent />
                        )}
                      </DocumentAddedAlert>
                    </GridRowColumn>
                  )
                })}
            </>
          )}
          <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
            <Divider />
          </GridRowColumn>
          <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
            <FormikLabelError
              name={getFieldName<typeof values>('hadAccount')}
              label={questionHadAccount.question?.text}
              schema={makeReqBoolSchema()}
            />
          </GridRowColumn>
          <Grid.Row className="short" style={{ justifyContent: 'center' }}>
            <Grid.Column width={6} style={{ paddingRight: 0 }}>
              <FormikRadioToggleButton
                fullWidth
                name={getFieldName<typeof values>('hadAccount')}
                value
                onClick={validateForm}
              >
                Yes
              </FormikRadioToggleButton>
            </Grid.Column>
            <Grid.Column width={6}>
              <FormikRadioToggleButton
                fullWidth
                name={getFieldName<typeof values>('hadAccount')}
                value={false}
                onClick={validateForm}
              >
                No
              </FormikRadioToggleButton>
            </Grid.Column>
          </Grid.Row>
          {values.hadAccount && (
            <>
              <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
                <FormikLabelError
                  name={getFieldName<typeof values>('accountTypes')}
                  label={questionAccountTypes.question?.text}
                  schema={makeStringArraySchema({
                    required: Boolean(values.hadAccount),
                  })}
                />
              </GridRowColumn>
              {questionAccountTypes.options.map((o) => (
                <GridRowColumn
                  key={o.id}
                  short
                  width={12}
                  style={{ justifyContent: 'center' }}
                >
                  <FormikCheckboxMulti
                    schema={makeStringArraySchema({
                      required: Boolean(values.hadAccount),
                    })}
                    name={getFieldName<typeof values>('accountTypes')}
                    value={o.id}
                    label={<Text as="bodyLg">{o.text}</Text>}
                  />
                </GridRowColumn>
              ))}
              {Array.isArray(values.accountTypes) &&
                values.accountTypes.length > 0 && (
                  <GridRowColumn
                    width={12}
                    style={{ justifyContent: 'center' }}
                  >
                    <DocumentAddedAlert>
                      Form 1099-SA added to checklist
                    </DocumentAddedAlert>
                  </GridRowColumn>
                )}
              <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
                <FormikLabelError
                  name={getFieldName<typeof values>('allQualifiedExpenses')}
                  label={questionAllQualifiedExpenses.question?.text}
                  schema={makeReqBoolSchema()}
                  afterLabel={
                    <Popup
                      content={
                        <Grid>
                          <GridRowColumn>
                            <Text as="h3">Qualified expenses</Text>
                          </GridRowColumn>
                          <GridRowColumn short>
                            <Text>
                              Qualified medical expenses are expenses that
                              generally would qualify for the medical and dental
                              expenses deduction. Examples include unreimbursed
                              expenses for doctors, dentists, and hospitals.
                              Wellness-related expenses (exercises classes,
                              massages) do not count.
                            </Text>
                          </GridRowColumn>
                        </Grid>
                      }
                      trigger={
                        <Icon
                          size="1x"
                          style={{ marginLeft: 4 }}
                          icon={regular('question-circle')}
                          color="mediumGray"
                        />
                      }
                    />
                  }
                />
              </GridRowColumn>
              <Grid.Row className="short" style={{ justifyContent: 'center' }}>
                <Grid.Column width={6} style={{ paddingRight: 0 }}>
                  <FormikRadioToggleButton
                    fullWidth
                    name={getFieldName<typeof values>('allQualifiedExpenses')}
                    value
                    onClick={validateForm}
                  >
                    Yes
                  </FormikRadioToggleButton>
                </Grid.Column>
                <Grid.Column width={6}>
                  <FormikRadioToggleButton
                    fullWidth
                    name={getFieldName<typeof values>('allQualifiedExpenses')}
                    value={false}
                    onClick={validateForm}
                  >
                    No
                  </FormikRadioToggleButton>
                </Grid.Column>
              </Grid.Row>
              {values.allQualifiedExpenses === false && (
                <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
                  <FormikInput
                    componentType="currency"
                    name={getFieldName<typeof values>('distributionAmount')}
                    label={questionDistributionsAmountInCents.question?.text}
                    schema={makeNumberSchema({
                      allowedDecimals: 2,
                      required: values.allQualifiedExpenses === false,
                    })}
                    fullWidth
                    placeholder="$0.00"
                  />
                </GridRowColumn>
              )}
            </>
          )}
          <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
            <Divider />
          </GridRowColumn>
        </Grid>
      </Container>
      <Grid>
        <FormFlowFooter
          loading={isUpdating || isSubmitting}
          continueDisabled={!isValid || isUpdating || isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
        />
      </Grid>
    </FormikProvider>
  )
}

export default HealthcareInfoPanel
