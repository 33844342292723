import { useEffect, useState, useCallback } from 'react'
import {
  Container,
  Header,
  Table,
  Button,
  Message,
  Form,
  Modal,
  Grid,
  Confirm,
  Menu,
} from 'semantic-ui-react'
import Moment from 'react-moment'

import CurrencyFormatLabel from '../../shared/CurrencyFormatLabel'
import {
  fetchAllTransactionRulesIfNeeded,
  createTransactionRule,
  deleteTransactionRule,
  CREATE_TRANSACTION_RULE_KEY,
  DELETE_TRANSACTION_RULE_KEY,
} from '../../../actions/admin/transactionRuleActions'
import { fetchAllUsersIfNeeded } from '../../../actions/admin/adminAllUsersActions'
import { TRANSACTION_TYPE_OPTIONS } from '../../../constants/transactionConstants'
import {
  RULE_USE_OPTIONS,
  RULE_TYPE_OPTIONS,
} from '../../../constants/transactionRuleConstants'
import './Autocategorization.scss'
import { centsToDollars, dollarsToCents } from '../../../utils/currencyHelpers'
import { getTransactionCategorySelector } from '../../../features/Reports/reports.selectors'
import TransactionCategoryDropdown from '../../shared/TransactionCategoryDropdown'
import { Alert, Input } from '../../BaseComponents'
import {
  TransactionRule,
  TransactionRuleCategoryType,
  TransactionRuleType,
  TransactionRuleUse,
} from '../../../reducers/admin/transactionRulesReducer'
import { useReselector } from '../../../utils/sharedHooks'
import { getAllUsersById } from '../../../selectors/user.selectors'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import {
  selectSimilarRules,
  selectTransactionRulesByType,
} from '../../../features/Transactions/transactions.selectors'
import { getFetchError } from '../../../reducers/fetch'
import { useAppDispatch } from '../../../utils/typeHelpers'

const SimilarRuleItem = ({ rule }: { rule: TransactionRule }) => {
  const selectedCategory = useReselector(
    getTransactionCategorySelector,
    rule.transactionCategoryId
  )
  const applicableUsers = rule.userId ? `User: ${rule.userId}` : 'All Users'

  return (
    <li>
      <p style={{ fontSize: '16px' }}>
        <b>Rule #{rule.id}:</b> Categorizing <b>{rule.ruleText}</b> as{' '}
        <b>{selectedCategory?.name || 'Awaiting Categorization'}</b>, applicable
        to <b>{applicableUsers}</b>
      </p>
    </li>
  )
}
const TransactionRuleCreationModal = ({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) => {
  const dispatch = useAppDispatch()
  const [checkRule, setCheckRule] = useState(false)
  const [ruleUse, setRuleUse] = useState<TransactionRuleUse>()
  const [ruleText, setRuleText] = useState('')
  const [ruleType, setRuleType] = useState<TransactionRuleType>()
  const [amountInCents, setAmountInCents] = useState<string>()
  const [transactionCategoryType, setTransactionCategoryType] =
    useState<TransactionRuleCategoryType>()
  const [transactionCategoryId, setTransactionCategoryId] = useState<number>()
  const [userId, setUserId] = useState<number>()
  const similarRules = useReselector(selectSimilarRules, ruleUse, ruleText)
  const error = useReselector(getFetchError, CREATE_TRANSACTION_RULE_KEY)
  const allUsers = useReselector(getAllUsersById)

  const USER_OPTIONS = Object.values(allUsers).map(
    ({ id, firstName, lastName }) => ({
      value: id,
      text: `${id}: ${firstName} ${lastName}`,
    })
  )

  const submit = async () => {
    if (!ruleUse || !ruleType) {
      return
    }

    const result = await createTransactionRule({
      ruleUse,
      ruleText,
      ruleType,
      amountInCents,
      transactionCategoryType,
      transactionCategoryId,
      userId,
    })(dispatch)
    if (result) {
      close()
    }
  }

  const selectedCategory = useReselector(
    getTransactionCategorySelector,
    transactionCategoryId
  )

  const validateRule = () => {
    const ruleFilled = ruleText && ruleType

    if (ruleUse === TransactionRuleUse.CLARIFICATION) {
      return ruleFilled
    } else {
      const categoryFilled =
        transactionCategoryType === 'business'
          ? transactionCategoryType && transactionCategoryId
          : transactionCategoryType

      return ruleFilled && categoryFilled
    }
  }

  const validateAndCheckRule = () => {
    if (validateRule()) {
      setCheckRule(true)
    } else {
      setCheckRule(false)
    }
  }

  const checkRuleContent = () => (
    <>
      <Modal.Content>
        <Header as="h3">Checking New Transaction Rule</Header>
        <p>
          Currently checking for similar rules that might conflict with this
          one. Remember, rules are symbol sensitive, and there should only be
          one rule per match word.
        </p>
        <div className="background">
          <Header as="h6">Your New Rule:</Header>
          {ruleUse === TransactionRuleUse.CATEGORIZATION && (
            <p>
              Categorizing transactions that match <b>{ruleText}</b> with rule
              type <b>{ruleType}</b>
              <br />
              as <b>{selectedCategory?.name || 'Awaiting Categorization'}</b>,
              applicable to <b>{userId ? `User ${userId}` : 'All Users'}</b>
            </p>
          )}
          {ruleUse === TransactionRuleUse.CLARIFICATION && (
            <p>
              Mark transactions that match <b>{ruleText}</b> as &#39;Needs
              Clarification&#39;
            </p>
          )}
        </div>
        <br />
        <div className="background">
          {similarRules.length === 0 && (
            <Header as="h6">Rule looks good.</Header>
          )}
          {similarRules.length > 0 && (
            <div>
              <Header as="h6">Similar rules:</Header>
              <ul>
                {similarRules.map((rule) => (
                  <SimilarRuleItem key={rule.id} rule={rule} />
                ))}
              </ul>
              <p>
                Are you sure that the new rule you are creating is <b>unique</b>{' '}
                and <b>necessary</b>?
              </p>
            </div>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={close}>Cancel</Button>
        <Button primary onClick={submit}>
          Create Rule
        </Button>
      </Modal.Actions>
    </>
  )

  const toggleFormByContent = () => {
    if (ruleUse === TransactionRuleUse.CATEGORIZATION) {
      return (
        <>
          <Grid.Column width={8}>
            <Header as="h6">For the following rule: </Header>
            <div className="background">
              <Form.Field required>
                <Form.Input
                  label="Rule Text — Text that we want to match"
                  placeholder="Enter Exact Text e.g. 'PsychologyToday' "
                  name="ruleText"
                  value={ruleText}
                  onChange={(name, target) => setRuleText(target.value)}
                  required
                  fluid
                />
              </Form.Field>
              <Form.Field required>
                <Form.Dropdown
                  label="Rule Type — how we want to match"
                  placeholder="Enter Text"
                  name="ruleType"
                  value={ruleType}
                  options={RULE_TYPE_OPTIONS}
                  selectOnBlur={false}
                  onChange={(name, target) =>
                    setRuleType(target.value as TransactionRuleType)
                  }
                  required
                  selection
                  fluid
                />
              </Form.Field>
            </div>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as="h6">Categorize transactions that match as: </Header>
            <div className="background">
              <Form.Field required>
                <Form.Dropdown
                  label="Transaction Type"
                  placeholder="Select Transaction type"
                  name="transactionCategoryType"
                  value={transactionCategoryType}
                  options={TRANSACTION_TYPE_OPTIONS}
                  selectOnBlur={false}
                  onChange={(name, target) =>
                    setTransactionCategoryType(
                      target.value as TransactionRuleCategoryType
                    )
                  }
                  selection
                  fluid
                  required
                />
              </Form.Field>
              <Form.Field required>
                <TransactionCategoryDropdown
                  optionStyle="combineIdAndValue"
                  onChange={(value) => setTransactionCategoryId(value)}
                  value={transactionCategoryId}
                  disabled={false}
                  required={transactionCategoryType === 'business'}
                  fluid
                />
              </Form.Field>
              <Form.Field required>
                <Form.Dropdown
                  label="Applies to: (for all users, leave empty)"
                  placeholder="Select user or leave empty for all users"
                  name="userId"
                  value={userId}
                  options={USER_OPTIONS}
                  selectOnBlur={false}
                  onChange={(name, { value }) => {
                    if (typeof value === 'number') {
                      setUserId(value)
                    } else {
                      // Set as undefined on clear
                      setUserId(undefined)
                    }
                  }}
                  selection
                  clearable
                  search
                  fluid
                />
              </Form.Field>
              {/*
                If user id is set (aka a user rule), we allow the ability to set amounts.
              */}
              {userId && (
                <Form.Field>
                  <Input
                    componentType="currency"
                    label="Amount to match"
                    name="amountInCents"
                    value={centsToDollars(amountInCents).toString()}
                    onChange={(value) =>
                      setAmountInCents(dollarsToCents(value).toString())
                    }
                    required
                    fullWidth
                  />
                  <i>Remember to use negative sign for expenses </i>
                </Form.Field>
              )}
            </div>
          </Grid.Column>
        </>
      )
    } else if (ruleUse === TransactionRuleUse.CLARIFICATION) {
      return (
        <Grid.Column width={16}>
          <Header as="h6">
            For transactions with the following text and match, set as
            &#34;Needs Clarification&#34;
          </Header>
          <div className="background">
            <Form.Field required>
              <Form.Input
                label="Rule Text — Text that we want to match"
                placeholder="Enter Exact Text e.g. 'PsychologyToday' "
                name="ruleText"
                value={ruleText}
                onChange={(name, target) => setRuleText(target.value)}
                required
                fluid
              />
            </Form.Field>
            <Form.Field required>
              <Form.Dropdown
                label="Rule Type — how we want to match"
                placeholder="Enter Text"
                name="ruleType"
                value={ruleType}
                options={RULE_TYPE_OPTIONS}
                selectOnBlur={false}
                onChange={(name, target) =>
                  setRuleType(target.value as TransactionRuleType)
                }
                required
                selection
                fluid
              />
            </Form.Field>
          </div>
        </Grid.Column>
      )
    } else {
      return null
    }
  }
  const formContent = () => {
    return (
      <>
        <Modal.Content>
          <Header as="h3">Create a new Transaction Rule</Header>
          {error && <Alert type="error">{error.message}</Alert>}
          <Message color="olive">
            <p>
              <b>Rule Use:</b> This can either be &#39;Clarification&#39; which
              will mark transactions as &#39;Needs Clarification&#39;, or
              &#39;Categorization&#39; which categorizations the transaction.
            </p>
            <p>
              <b>Rule Text:</b> This denotes the String that the bot will use
              for matching. This is symbol sensitive. This means that any spaces
              or commas will also matter.
            </p>
            <p>
              <b>Rule Type:</b> This denotes how the bot will use the Rule Text.{' '}
              <br />
              &#39;EXACT_MATCH&#39; means that the bot will find transactions
              whose description matches the Rule Text <i>exactly.</i>
              <br />
              &#39;CONTAINS_WORD&#39; means that the bot will find transactions
              whose description <i>contains</i> the Rule Text, in a full word.
              i.e. &#34;cat&#34; will match with &#34;cat&#34; but not with
              &#34;category&#34;
            </p>

            <p>
              <b>Category Type:</b> This is what we want to mark the transaction
              type as (business or personal). <br />
            </p>
            <p>
              <b>Category Id:</b> This is what we want to mark the transaction
              category id as. <br />
            </p>

            <p>
              <b>
                Any rule changes or additions will only be applied going
                forward. This means that if you were to update a rule, the
                updated rule will only apply to new transactions.
              </b>
            </p>
          </Message>
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <div className="background">
                    <Form.Field required>
                      <Form.Dropdown
                        label="Rule Use"
                        placeholder="Select Rule Use"
                        name="ruleUse"
                        value={ruleUse}
                        options={RULE_USE_OPTIONS}
                        selectOnBlur={false}
                        onChange={(name, target) =>
                          setRuleUse(target.value as TransactionRuleUse)
                        }
                        required
                        selection
                        fluid
                      />
                    </Form.Field>
                  </div>
                </Grid.Column>
              </Grid.Row>

              {toggleFormByContent()}
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={close}>Cancel</Button>
          <Button primary onClick={validateAndCheckRule}>
            Check Rule
          </Button>
        </Modal.Actions>
      </>
    )
  }
  return (
    <Modal
      size={'large'}
      dimmer={'inverted'}
      className="transactionRuleCreationModal"
      open={open}
      onClose={close}
    >
      {!checkRule && formContent()}
      {checkRule && checkRuleContent()}
    </Modal>
  )
}

const TransactionRuleDeletionConfirm = ({
  open,
  close,
  rule,
}: {
  open: boolean
  close: () => void
  rule: TransactionRule
}) => {
  const dispatch = useAppDispatch()
  const error = useReselector(
    getFetchError,
    DELETE_TRANSACTION_RULE_KEY(rule.id)
  )

  const deleteRule = async () => {
    const result = await deleteTransactionRule(rule.id)(dispatch)
    if (result) {
      close()
    }
  }

  const renderContent = () => {
    return (
      <Modal.Content>
        <Header as="h6">Rule You are Removing</Header>
        {error && <Alert type="error">{error.message}</Alert>}
        <ul>
          <SimilarRuleItem rule={rule} />
        </ul>
      </Modal.Content>
    )
  }

  return (
    <Confirm
      open={open}
      header={`⚠️ Warning: You are deleting Rule #${rule.id} ⚠️ `}
      content={renderContent()}
      cancelButton="Cancel"
      confirmButton={
        <Button negative primary>
          Delete Rule
        </Button>
      }
      onCancel={close}
      onConfirm={deleteRule}
    />
  )
}
const TransactionRulesRow = ({ rule }: { rule: TransactionRule }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const selectedCategory = useReselector(
    getTransactionCategorySelector,
    rule.transactionCategoryId
  )
  return (
    <Table.Row>
      <Table.Cell>{rule.id}</Table.Cell>
      <Table.Cell>{rule.ruleUse}</Table.Cell>
      <Table.Cell>{rule.ruleText}</Table.Cell>
      <Table.Cell>{rule.ruleType}</Table.Cell>
      <Table.Cell>{rule.transactionCategoryType}</Table.Cell>
      <Table.Cell>
        {selectedCategory
          ? `${selectedCategory.id}: ${selectedCategory?.name}`
          : 'No Category'}
      </Table.Cell>
      <Table.Cell>{rule.userId || 'All Users'}</Table.Cell>
      <Table.Cell>
        {rule.amountInCents && (
          <CurrencyFormatLabel value={centsToDollars(rule.amountInCents)} />
        )}
        {!rule.amountInCents && 'N/A'}
      </Table.Cell>
      <Table.Cell>
        {rule.archivedAt && (
          <Moment format={DATE_FORMATS.DISPLAY_SHORT}>{rule.archivedAt}</Moment>
        )}
        {!rule.archivedAt && 'Active'}
      </Table.Cell>

      <Table.Cell>
        <Button className="link" onClick={() => setModalOpen(true)}>
          Delete Rule
        </Button>
        {modalOpen && (
          <TransactionRuleDeletionConfirm
            open={modalOpen}
            close={() => setModalOpen(false)}
            rule={rule}
          />
        )}
      </Table.Cell>
    </Table.Row>
  )
}

const TransactionRuleTable = ({ active }: { active: string }) => {
  const transactionRules = useReselector(selectTransactionRulesByType, active)
  return (
    <Table striped sortable compact className="transactionRulesTable">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Id</Table.HeaderCell>
          <Table.HeaderCell>Rule Use</Table.HeaderCell>
          <Table.HeaderCell>Rule Text</Table.HeaderCell>
          <Table.HeaderCell>Rule Type</Table.HeaderCell>
          <Table.HeaderCell>Category Type</Table.HeaderCell>
          <Table.HeaderCell>Category Id</Table.HeaderCell>
          <Table.HeaderCell>Applies to:</Table.HeaderCell>
          <Table.HeaderCell>For amount:</Table.HeaderCell>
          <Table.HeaderCell>Archived At</Table.HeaderCell>
          <Table.HeaderCell>Action</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Object.keys(transactionRules).map((rule) => (
          <TransactionRulesRow
            rule={transactionRules[Number(rule)]}
            key={rule}
          />
        ))}
      </Table.Body>
    </Table>
  )
}
const TransactionRulesPanel = () => {
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)
  const [activeTab, setActiveTab] = useState('active')
  useEffect(() => {
    dispatch(fetchAllTransactionRulesIfNeeded())
    dispatch(fetchAllUsersIfNeeded())
  }, [dispatch])
  const onTabChange = useCallback(
    (tabName: string) => {
      setActiveTab(tabName)
    },
    [setActiveTab]
  )
  return (
    <Container id="autocategorizationPanel">
      <Header as="h3">Autocategorization Transaction Rules</Header>

      <Message>
        <Message.Header>
          Welcome to the Autocategorization Rules page!
        </Message.Header>
        This is an <b>critical</b> piece of how we process our transactions.
        Please don&#39;t use this tool until you have been trained by Victoria.
      </Message>
      <Message color="olive">
        <Message.Header>How Auto Categorization Works:</Message.Header>
        <p>
          Our bot uses Transaction Rules to determine how to match certain
          transactions.{' '}
        </p>
        <p>
          <b>Rule Use:</b> This can either be &#39;Clarification&#39; which will
          mark transactions as &#39;Needs Clarification&#39;, or
          &#39;Categorization&#39; which categorizations the transaction.
        </p>
        <p>
          <b>Rule Text:</b> This denotes the String that the bot will use for
          matching. This is symbol sensitive. This means that any spaces or
          commas will also matter.
        </p>
        <p>
          <b>Rule Type:</b> This denotes how the bot will use the Rule Text.{' '}
          <br />
          &#39;EXACT_MATCH&#39; means that the bot will find transactions whose
          description matches the Rule Text <i>exactly.</i>
          <br />
          &#39;CONTAINS_WORD&#39; means that the bot will find transactions
          whose description <i>contains</i> the Rule Text, in a full word. i.e.
          &#34;cat&#34; will match with &#34;cat&#34; but not with
          &#34;category&#34;
        </p>

        <p>
          <b>Category Type:</b> This is what we want to mark the transaction
          type as (business or personal). <br />
        </p>
        <p>
          <b>Category Id:</b> This is what we want to mark the transaction
          category id as. <br />
        </p>

        <p>
          <b>
            Any rule changes or additions will only be applied going forward.
            This means that if you were to update a rule, the updated rule will
            only apply to new transactions.
          </b>
        </p>
      </Message>
      <div>
        <Button primary size="tiny" onClick={() => setOpen(true)}>
          Create New Transaction Rule
        </Button>
        {open && (
          <TransactionRuleCreationModal
            close={() => setOpen(false)}
            open={open}
          />
        )}
      </div>
      <br />
      <div>
        <Menu id="transactionRulesTables" pointing secondary>
          <Menu.Item
            name={'Active'}
            active={activeTab === 'active'}
            onClick={() => onTabChange('active')}
          />
          <Menu.Item
            name={'All'}
            active={activeTab === 'all'}
            onClick={() => onTabChange('all')}
          />
        </Menu>

        <Header as="h5">All Rules</Header>
        <TransactionRuleTable active={activeTab} />
      </div>
    </Container>
  )
}

export default TransactionRulesPanel
