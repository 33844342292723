import { FC, useState } from 'react'
import { Accordion, AccordionTitleProps, Grid } from 'semantic-ui-react'
import { Card, Text, Link, Icon } from '../../../../components/BaseComponents'
import { Colors } from '../../../../styles/theme'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const headerStyle = {
  display: 'inline-block',
}

const QTEFAQs: FC = () => {
  const [accordionValue, setAccordionValue] = useState<number | null>(0)

  const handleAccordionClick = (_e: unknown, data: AccordionTitleProps) => {
    const { taxquarter } = data
    if (taxquarter === accordionValue) {
      setAccordionValue(null)
    } else {
      setAccordionValue(taxquarter)
    }
  }

  return (
    <Card backgroundColor="stone40" type="body">
      <Grid>
        <Grid.Row verticalAlign="middle">
          <Grid.Column>
            <Accordion>
              <Accordion.Title
                style={{ padding: '0' }}
                taxquarter={0}
                active={accordionValue === 0}
                onClick={handleAccordionClick}
              >
                <Text style={headerStyle} as="h2">
                  Common FAQs
                </Text>
                <Icon
                  size="1x"
                  style={{ float: 'right' }}
                  icon={accordionValue === 0 ? solid('minus') : solid('plus')}
                />
              </Accordion.Title>
              <Accordion.Content
                style={{ marginTop: '24px' }}
                active={accordionValue === 0}
              >
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Link
                        href="https://support.joinheard.com/hc/en-us/articles/4416208037655-What-are-quarterly-tax-estimates-"
                        newPage
                      >
                        What are Quarterly Tax Estimates?
                      </Link>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Link
                        href="https://support.joinheard.com/hc/en-us/articles/4416208116247-How-does-Heard-calculate-my-quarterly-estimated-taxes-"
                        newPage
                      >
                        How are my estimates calculated?
                      </Link>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Link
                        href="https://support.joinheard.com/hc/en-us/articles/4416196188439-What-do-you-mean-by-estimate-Can-I-pay-more-or-less-than-the-estimate-you-gave-me-"
                        newPage
                      >
                        Can I pay more or less than the amount you gave me?
                      </Link>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Link
                        href="https://support.joinheard.com/hc/en-us/articles/4416196562967-What-if-I-didn-t-pay-my-quarterly-estimates-for-prior-quarters-this-year-"
                        newPage
                      >
                        What if I missed one or more quarterly tax payments this
                        year?
                      </Link>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Link
                        href="https://support.joinheard.com/hc/en-us/articles/4416233114647-Should-I-pay-quarterly-taxes-from-my-personal-or-business-bank-account-"
                        newPage
                      >
                        Do I pay taxes out of my personal or business bank
                        account?
                      </Link>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Link
                        style={{ color: Colors.forest }}
                        href="https://support.joinheard.com/hc/en-us/sections/4416200689303-Quarterly-Taxes"
                        newPage
                        as="secondaryLink"
                      >
                        View all FAQs
                      </Link>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

export default QTEFAQs
