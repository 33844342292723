import { useNavigate } from 'react-router-dom'
import { Button, Card, Text } from '../BaseComponents'

const CreateAllocationCard = () => {
  const navigate = useNavigate()

  return (
    <Card type="subsection" backgroundColor="stone40">
      <Text as="h2">Set up your Allocation Guide</Text>
      <br />
      <Text as="h3">
        <b>Want more guidance on how to allocate your earnings?</b>
      </Text>
      <br />
      <Text>
        Set up your Allocation Guide to determine how much to set aside for
        taxes, retirement, paychecks and more. We&apos;ll send over a
        &#34;paystub&#34; at the end of every month with suggested amounts based
        on your previous months&apos; earnings.
      </Text>
      <br />
      <Button
        variant="primary"
        style={{ maxWidth: 200 }}
        onClick={() => navigate('/allocation-guides/new')}
      >
        Start
      </Button>
    </Card>
  )
}

export default CreateAllocationCard
