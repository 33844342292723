import { Grid, Image } from 'semantic-ui-react'
import {
  Card,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import AhaMomentsHeaderFooter from '../AhaMomentsHeaderFooter'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'

const TransactionsClarifiedSuccess = ({
  handleContinue,
  handleBack,
}: {
  handleContinue: () => void
  handleBack: () => void
}) => {
  return (
    <AhaMomentsHeaderFooter
      backButtonText={'Back'}
      continueButtonText={'Complete'}
      handleContinue={handleContinue}
      handleBack={handleBack}
    >
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 0,
          margin: '0 auto',
        }}
      >
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <GridRowColumn style={{ marginBottom: 45 }}>
            <Text as="display2">Learn How Bookkeeping Works</Text>
          </GridRowColumn>
          <Card backgroundColor="lightGreen">
            <Grid>
              <GridRowColumn
                width={4}
                verticalAlign="middle"
                style={{ marginRight: 10 }}
              >
                <Image src="https://heard-images.s3.amazonaws.com/assets/check.svg" />
              </GridRowColumn>
              <GridRowColumn>
                <Text as="display3">
                  Nice work! You know how to identify personal transactions
                </Text>
                <br />

                <Text>
                  Once your books are complete, you will be able to see full
                  reports and financial insights on your therapy business.
                  We&apos;ll let you know if you need to clarify anything else.
                </Text>
              </GridRowColumn>
            </Grid>
          </Card>
        </GridRowColumn>
      </Grid>
    </AhaMomentsHeaderFooter>
  )
}

export default TransactionsClarifiedSuccess
