import { NumericFormat } from 'react-number-format'

const CurrencyFormatLabel = ({ value }: { value?: string | number | null }) => {
  return (
    <NumericFormat
      prefix="$"
      displayType={'text'}
      thousandSeparator
      value={value}
      decimalScale={2}
      fixedDecimalScale
    />
  )
}

export default CurrencyFormatLabel
