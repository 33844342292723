import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from 'react'
import { UnknownAction, createAction, createReducer } from '@reduxjs/toolkit'

// BL
import { UserDocument } from './userDocuments.slice'
import { DescriptionType } from './constants'

interface InitialState {
  activeUploadModal: string | null
  documentToDelete: UserDocument | null
  userDocumentCategoryId?: number | null
  descriptionType?: DescriptionType | null
}

const initialState: InitialState = {
  activeUploadModal: null,
  documentToDelete: null,
  userDocumentCategoryId: null,
  descriptionType: null,
}

export const closeUploadModal = createAction(
  'user-documents/clear-active-upload-modal'
)
export const closeDeleteConfirm = createAction(
  'user-documents/clear-document-to-delete'
)
export const openUploadModal = createAction(
  'user-documents/set-active-upload-modal',
  (
    text: string,
    userDocumentCategoryId?: number | null,
    descriptionType?: DescriptionType | null
  ) => ({
    payload: { type: text, userDocumentCategoryId, descriptionType },
  })
)
export const openDeleteConfirm = createAction(
  'user-documents/set-document-to-delete',
  (document: UserDocument) => ({
    payload: {
      document,
    },
  })
)

const featureStateReducer = createReducer(initialState, (builder) => {
  builder.addCase(closeUploadModal, (state) => ({
    ...state,
    activeUploadModal: null,
  }))

  builder.addCase(closeDeleteConfirm, (state) => ({
    ...state,
    documentToDelete: null,
  }))

  builder.addCase(openUploadModal, (state, action) => ({
    ...state,
    activeUploadModal: action.payload.type,
    userDocumentCategoryId: action.payload.userDocumentCategoryId,
    descriptionType: action.payload.descriptionType,
  }))

  builder.addCase(openDeleteConfirm, (state, action) => ({
    ...state,
    documentToDelete: action.payload.document,
  }))
})

const FeatureState = createContext<InitialState>(initialState)
const FeatureDispatch = createContext<Dispatch<UnknownAction> | null>(null)

// Hooks so we can access and modify state anywhere within this feature
export const useFeatureState = () => useContext(FeatureState)
export const useFeatureDispatch = () => useContext(FeatureDispatch)

export const FeatureStateProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(featureStateReducer, initialState)

  return (
    <FeatureState.Provider value={state}>
      <FeatureDispatch.Provider value={dispatch}>
        {children}
      </FeatureDispatch.Provider>
    </FeatureState.Provider>
  )
}
