import { createRef, useState, useEffect, useCallback, useMemo } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Container, Ref, Sticky } from 'semantic-ui-react'
import moment from 'moment'

// BL
import {
  fetchSingleAccountReconciliationIfNeeded,
  getFetchSingleAccountRecKey,
  upsertAccountReconciliation,
} from '../../../../actions/admin/accountReconciliationActions'
import {
  fetchFilteredTransactions,
  adminDeleteTransaction,
  bulkUpdateUserTransactions,
  FETCH_FILTERED_TRANSACTIONS_KEY,
} from '../../../../actions/adminActions'
import {
  FETCH_FINANCIAL_ACCOUNT_TRANSACTION_SUM_KEY,
  fetchFinancialAccountTransactionSum,
  FinancialAccountSumResponse,
} from '../../../../actions/admin/adminFinancialAccountActions'
import {
  FETCH_TRANSACTION_CATEGORIES_KEY,
  fetchTransactionCategoriesIfNeeded,
} from '../../../../features/Reports/reports.slice'
import { TransactionRowModel } from '../shared'
import { generateTableDatasource } from '../service'

// UI
import '../style.scss'
import PanelHeader from '../Components/PanelHeader'
import PanelToolbar from '../Components/PanelToolbar'
import PanelTable from '../Components/PanelTable'
import TransactionModal from '../Components/PanelTransactionModal'
import { getReconciliationById } from '../../../../selectors/reconciliations.selectors'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectErrorsForKeys,
  selectIsFetchingForKeys,
} from '../../../../reducers/fetch'
import { clearQueriedTransactions } from '../../../../reducers/admin/allTransactions.slice'
import { selectQueriedTransactions } from '../../../../features/Transactions/transactions.selectors'
import { AccountTypes } from '../../../../reducers/finances/financialAccountsReducer'
import { centsToCurrency } from '../../../../utils/currencyHelpers'
import { ENABLE_ADMIN, useAnalyticsTrack } from '../../../../features/Amplitude'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const ReconciliationPanel = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [qsParams] = useSearchParams()
  const track = useAnalyticsTrack(ENABLE_ADMIN)
  const contextRef = createRef<HTMLElement>()
  const [account, setAccount] = useState<FinancialAccountSumResponse | null>(
    null
  )
  const [addTransactionCnt, setAddTransactionCnt] = useState<number>(0)
  const [deleteTransactionCnt, setDeleteTransactionCnt] = useState<number>(0)

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { userId, reconciliationId = '0' } = useParams()
  const reconciliation = useReselector(getReconciliationById, reconciliationId)
  const fetchAccountRecKey = getFetchSingleAccountRecKey(reconciliationId)

  const loading = useReselector(selectIsFetchingForKeys, [
    FETCH_TRANSACTION_CATEGORIES_KEY,
    fetchAccountRecKey,
    FETCH_FILTERED_TRANSACTIONS_KEY,
    FETCH_FINANCIAL_ACCOUNT_TRANSACTION_SUM_KEY,
  ])

  const errors = useReselector(selectErrorsForKeys, [
    fetchAccountRecKey,
    FETCH_FILTERED_TRANSACTIONS_KEY,
  ])
  const transactions = useReselector(selectQueriedTransactions)

  const datasource = useMemo(
    () =>
      reconciliation
        ? generateTableDatasource(reconciliation, transactions)
        : [],
    [reconciliation, transactions]
  )
  const startDate = useMemo(
    () =>
      reconciliation?.startingBalanceDate &&
      moment.utc(reconciliation?.startingBalanceDate, 'YYYY-MM-DD'),
    [reconciliation?.startingBalanceDate]
  )
  const endDate = useMemo(
    () =>
      reconciliation?.endingBalanceDate &&
      moment.utc(reconciliation?.endingBalanceDate, 'YYYY-MM-DD'),
    [reconciliation?.endingBalanceDate]
  )

  const bookkeepingReportId = useMemo(
    () => qsParams.get('bookkeepingReportId'),
    [qsParams]
  )

  useEffect(() => {
    // Clear any prior queried transactions when loading this page
    dispatch(clearQueriedTransactions())
  }, [dispatch])

  // Initial fetches
  useEffect(() => {
    dispatch(fetchTransactionCategoriesIfNeeded())
    dispatch(fetchSingleAccountReconciliationIfNeeded(reconciliationId))
  }, [dispatch, reconciliationId])

  useEffect(() => {
    if (!startDate || !endDate || !userId) {
      return
    }
    dispatch(
      fetchFilteredTransactions({
        startDate,
        endDate,
        financialAccountId: reconciliation?.accountId,
        limit: 1000,
        includeExcluded: true,
        userId: Number(userId),
      })
    )
  }, [dispatch, endDate, reconciliation?.accountId, startDate, userId])

  // Fetch for transaction sum
  useEffect(() => {
    const fetch = async () => {
      if (!startDate || !endDate || !reconciliation?.accountId) {
        return
      }
      const accountResult = await fetchFinancialAccountTransactionSum(
        reconciliation?.accountId,
        startDate,
        endDate
      )(dispatch)

      if (accountResult?.inactive) {
        throw new Error('This account is now inactive and cannot be reconciled')
      }
      if (accountResult) {
        setAccount(accountResult)
      }
    }

    fetch()
  }, [reconciliation?.accountId, transactions, startDate, endDate, dispatch])

  const onCheckButtonClicked = useCallback(
    async (t: TransactionRowModel) => {
      const data = {
        transactionIds: [t.id],
        reconciliationId: t.matched ? null : t.reconciliationId,
      }
      await bulkUpdateUserTransactions(data)(dispatch)
    },
    [dispatch]
  )

  const onDeleteClicked = useCallback(
    async (t: TransactionRowModel) => {
      await adminDeleteTransaction(Number(t.id))(dispatch)
      setDeleteTransactionCnt((prev) => prev + 1)
    },
    [dispatch, setDeleteTransactionCnt]
  )

  const onAddTransaction = useCallback(() => {
    setAddTransactionCnt((prev) => prev + 1)
  }, [])

  const onCompleteClicked = useCallback(async () => {
    // Mark reconciliation complete
    if (!account) {
      throw new Error('Account does not exist')
    }

    if (!reconciliation?.id) {
      throw new Error('Reconciliation does not exist')
    }

    if (!bookkeepingReportId) {
      throw new Error('Bookkeeping report ID not provided')
    }

    await upsertAccountReconciliation(reconciliation.id, {
      accountId: account.id,
      endingBalanceDate: moment.utc(reconciliation.endingBalanceDate),
      endingBalanceInCents: reconciliation.endingBalanceInCents,
      startingBalanceDate: moment.utc(reconciliation.startingBalanceDate),
      startingBalanceInCents: reconciliation.startingBalanceInCents,
      status: 'complete',
      type: 'manual',
      bookkeepingReportId: parseInt(bookkeepingReportId),
    })(dispatch)

    await bulkUpdateUserTransactions({
      transactionIds: account.transactionSumIds,
      reconciliationId: reconciliation.id,
    })(dispatch)

    track('Reconciliation Break Fixed', {
      reconciliationId: reconciliation.id,
      accountId: account.id,
      startDate: reconciliation.startingBalanceDate,
      endDate: reconciliation.endingBalanceDate,
      addTransactionCnt,
      deleteTransactionCnt,
    })

    navigate(-1)
  }, [
    account,
    dispatch,
    reconciliation?.endingBalanceDate,
    reconciliation?.endingBalanceInCents,
    reconciliation?.id,
    reconciliation?.startingBalanceDate,
    reconciliation?.startingBalanceInCents,
    navigate,
    addTransactionCnt,
    deleteTransactionCnt,
    track,
    bookkeepingReportId,
  ])

  const transactionSumInCents = useMemo(() => {
    if (account?.type === AccountTypes.CREDIT) {
      return Math.abs(Number(account?.transactionsSumInCents || 0))
    }
    return Number(account?.transactionsSumInCents || 0)
  }, [account?.type, account?.transactionsSumInCents])

  const heardBalanceInCents = useMemo(
    () =>
      centsToCurrency(reconciliation?.startingBalanceInCents).add(
        centsToCurrency(transactionSumInCents)
      ).intValue,
    [reconciliation?.startingBalanceInCents, transactionSumInCents]
  )

  return (
    <>
      {Boolean(errors.length) && <div>{errors[0].message}</div>}
      {!errors.length && loading && <div>Loading</div>}

      {!errors.length && !loading && reconciliation && (
        <Ref innerRef={contextRef}>
          <Container id="FinancialAccountReconciliation">
            <Sticky context={contextRef}>
              <PanelToolbar
                account={account}
                onBackClicked={() => navigate(-1)}
                reconciliation={reconciliation}
              />
              <PanelHeader
                onCompleteClicked={onCompleteClicked}
                onNewTransactionClicked={() => setModalOpen((prev) => !prev)}
                heardBalanceInCents={heardBalanceInCents}
                statementBalanceInCents={reconciliation.endingBalanceInCents}
              />
            </Sticky>
            <PanelTable
              datasource={datasource}
              onDeleteClicked={onDeleteClicked}
              onRowChecked={onCheckButtonClicked}
            />

            <TransactionModal
              userId={Number(userId)}
              reconciliation={reconciliation}
              account={account}
              close={() => setModalOpen(false)}
              open={modalOpen}
              onAddTransaction={onAddTransaction}
            />
          </Container>
        </Ref>
      )}
    </>
  )
}

export default ReconciliationPanel
