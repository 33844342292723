import { useMemo } from 'react'
import { Container, Grid } from 'semantic-ui-react'

import CompanyDocumentsTab from './CompanyDocumentsTab'
import TeamMemberFormsTab from './TeamMemberFormsTab'
import {
  Alert,
  Card,
  Link,
  Tab,
  Text,
} from '../../../components/BaseComponents'

const PayrollDocuments = () => {
  const panes = useMemo(
    () => [
      {
        menuItem: 'Company Forms',
        render: () => (
          <Tab.Pane>
            <CompanyDocumentsTab />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Team Member Forms',
        render: () => (
          <Tab.Pane>
            <TeamMemberFormsTab />
          </Tab.Pane>
        ),
      },
    ],
    []
  )

  return (
    <Container>
      <Card>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Text as="h1">Payroll Documents</Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Alert title="This page only lists Documents related to Payroll.">
                Looking for other Heard Documents?{' '}
                <Link to="/practice/documents">
                  Head to the regular Documents Page
                </Link>
              </Alert>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Tab panes={panes} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollDocuments
