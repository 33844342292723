import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { JournalEntry } from '../../features/JournalEntries/types'

export interface JournalEntryState {
  [journalEntryId: number]: JournalEntry
}

const initialState: JournalEntryState = {}

const journalEntrySlice = createSlice({
  name: 'journalEntries',
  initialState,
  reducers: {
    receiveJournalEntry: (state, action: PayloadAction<JournalEntry>) => {
      state[action.payload.id] = action.payload
    },
    receiveJournalEntries: (
      state,
      action: PayloadAction<JournalEntryState>
    ) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    receiveAdminDeleteUserJournalEntry: (
      state,
      action: PayloadAction<number>
    ) => {
      delete state[action.payload]
    },
  },
})

export default journalEntrySlice.reducer

export const {
  receiveJournalEntry,
  receiveJournalEntries,
  receiveAdminDeleteUserJournalEntry,
} = journalEntrySlice.actions
