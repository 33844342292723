import { Grid } from 'semantic-ui-react'
import { Card, Text } from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import LearnWithHeardItem, {
  LearnWithHeardItemProps,
} from './LearnWithHeardItem'

const LearnWithHeardItemList: LearnWithHeardItemProps[] = [
  {
    key: 'bookkeeping',
    imageBackgroundColor: Colors.sunrise,
    imageSrc:
      'https://heard-images.s3.amazonaws.com/assets/books_lightShadow.png',
    imageAlt: 'books',
    title: 'Bookkeeping',
    description:
      'Learn why bookkeeping matters, how we help you save time, and what you need to do on a monthly basis.',
    navigateTo: '/onboarding-v2/learning/bookkeeping',
    timeItTakes: '5 minutes',
  },
]

const LearnWithHeard = () => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Text as="h1">Learn with Heard</Text>
          <Text as="bodyMd">Set your therapy practice up for success.</Text>
        </Grid.Column>
      </Grid.Row>
      <Card.Group itemsPerRow={LearnWithHeardItemList.length > 2 ? 3 : 2}>
        {LearnWithHeardItemList.map((item) => (
          <LearnWithHeardItem
            key={item.key}
            imageBackgroundColor={item.imageBackgroundColor}
            imageSrc={item.imageSrc}
            imageAlt={item.imageAlt}
            title={item.title}
            description={item.description}
            navigateTo={item.navigateTo}
            timeItTakes={item.timeItTakes}
          />
        ))}
      </Card.Group>
    </Grid>
  )
}

export default LearnWithHeard
