import { ReduxState } from '../../../utils/typeHelpers'
import { createSelector } from 'reselect'

export const getSCorpSetUpStatuses = (state: ReduxState) => state.sCorpStatus

export const selectSCorpSetUpStatusesForUser = createSelector(
  getSCorpSetUpStatuses,
  (_: unknown, userId: number | string | undefined) => userId,
  (sCorpStatus, userId) => (userId ? sCorpStatus[Number(userId)] : null)
)
