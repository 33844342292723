import { useState, useEffect, RefObject, forwardRef } from 'react'
import { Accordion, Grid, Icon } from 'semantic-ui-react'
import {
  Button,
  Table,
  Text,
  GridRowColumn,
  Card,
} from '../../../components/BaseComponents'
import CurrencyFormatLabel from '../../../components/shared/CurrencyFormatLabel'
import { Colors } from '../../../styles/theme'
import { centsToDollars } from '../../../utils/currencyHelpers'
import { JournalEntryType, GeneralLedgerLineItem } from '../types'
import {
  GeneralLedgerAccount,
  NUM_ITEMS_IN_COLLAPSED_STATE,
} from './GeneralLedger'
import { formatFinancialStatement } from '../service'
import {
  isoToUTCDateTime,
  DATE_FORMATS_LUXON,
} from '../../../utils/dateHelpers'
import { TransactionAccountTypes } from '../../Reports/reports.slice'

interface Props {
  account: GeneralLedgerAccount
  active: boolean
  index: number
  onRowClick: (index: number) => void
  forwardedRef: RefObject<HTMLDivElement> | null
}

const borderlessCellStyle = {
  border: 'none',
  padding: 5,
}

const singleBorderBottomStyle = {
  borderBottom: `1px solid ${Colors.black}`,
}

const doubleBorderBottomStyle = {
  borderBottom: '1px solid',
  borderBottomStyle: 'double' as const,
  borderBottomWidth: 'medium',
}

const GeneralLedgerRow = forwardRef(
  ({ account, active, index, onRowClick, forwardedRef }: Props, _ref) => {
    const [isLineItemExpanded, setIsLineItemExpanded] = useState(false)
    const [visibleLineItems, setVisibleLineItems] = useState<
      GeneralLedgerLineItem[]
    >([])

    useEffect(() => {
      if (!isLineItemExpanded) {
        setVisibleLineItems(
          account.lineItems.slice(0, NUM_ITEMS_IN_COLLAPSED_STATE)
        )
      } else {
        setVisibleLineItems(account.lineItems)
      }
    }, [isLineItemExpanded, account.lineItems])

    const toggleLineItemListState = () =>
      setIsLineItemExpanded((state) => !state)

    return (
      <div key={account.key} ref={forwardedRef} style={{ marginBottom: 16 }}>
        <Card
          type="subsection"
          backgroundColor={forwardedRef ? 'natural' : undefined}
        >
          <Accordion.Title
            onClick={(_e, data) =>
              onRowClick(data.index ? Number(data.index) : 0)
            }
            index={index}
            active={active}
          >
            <Grid stackable>
              <Grid.Row verticalAlign="middle">
                <Grid.Column width={6}>
                  <Text>{account.name}</Text>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Text>{account.accountType}</Text>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Text>
                    {formatFinancialStatement(account.financialStatement)}
                  </Text>
                </Grid.Column>
                <Grid.Column width={3} floated="right">
                  <Text as="h3" textAlign="right">
                    <CurrencyFormatLabel
                      value={centsToDollars(account.netTotalInCents)}
                    />
                  </Text>
                </Grid.Column>
                <Grid.Column width={1} floated="right">
                  <Text textAlign="right">
                    <Icon name={`${active ? 'caret down' : 'caret right'}`} />
                  </Text>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Title>
          <Accordion.Content active={active}>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Text color="blue">
                    Showing {visibleLineItems.length} of{' '}
                    {account.lineItems.length}
                  </Text>
                </Grid.Column>
                <Grid.Column textAlign="right">
                  {account.lineItems.length > NUM_ITEMS_IN_COLLAPSED_STATE && (
                    <Button variant="link" onClick={toggleLineItemListState}>
                      {isLineItemExpanded
                        ? 'View less journal entries'
                        : 'View all journal entries'}
                    </Button>
                  )}
                </Grid.Column>
              </Grid.Row>
              <GridRowColumn>
                <Table padded={false} compact="very">
                  <Table.Header>
                    <Table.HeaderCell style={borderlessCellStyle}>
                      Date
                    </Table.HeaderCell>
                    <Table.HeaderCell style={borderlessCellStyle}>
                      Id
                    </Table.HeaderCell>
                    <Table.HeaderCell style={borderlessCellStyle}>
                      Description
                    </Table.HeaderCell>
                    <Table.HeaderCell style={borderlessCellStyle}>
                      Debit
                    </Table.HeaderCell>
                    <Table.HeaderCell style={borderlessCellStyle}>
                      Credit
                    </Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>
                    {visibleLineItems.map(
                      ({
                        id,
                        journalEntryDate,
                        transactionDescription,
                        journalEntryDetails,
                        amountInCents,
                        entryType,
                      }) => (
                        <Table.Row key={`${id}`}>
                          <Table.Cell style={borderlessCellStyle}>
                            {journalEntryDate &&
                              isoToUTCDateTime(journalEntryDate).toFormat(
                                DATE_FORMATS_LUXON.DISPLAY_SHORT
                              )}
                          </Table.Cell>
                          <Table.Cell style={borderlessCellStyle}>
                            {id}
                          </Table.Cell>
                          <Table.Cell style={borderlessCellStyle}>
                            {transactionDescription || journalEntryDetails}
                          </Table.Cell>
                          <Table.Cell style={borderlessCellStyle}>
                            {entryType === JournalEntryType.DEBIT && (
                              <CurrencyFormatLabel
                                value={centsToDollars(amountInCents)}
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell style={borderlessCellStyle}>
                            {entryType === JournalEntryType.CREDIT && (
                              <CurrencyFormatLabel
                                value={centsToDollars(amountInCents)}
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      )
                    )}

                    {/* Balance brought down row */}
                    {!isLineItemExpanded &&
                      account.lineItems.length >
                        NUM_ITEMS_IN_COLLAPSED_STATE && (
                        <Table.Row>
                          <Table.Cell style={borderlessCellStyle} />
                          <Table.Cell style={borderlessCellStyle} />
                          <Table.Cell style={borderlessCellStyle}>
                            Sum of hidden transactions{' '}
                            <Button
                              variant="link"
                              onClick={toggleLineItemListState}
                            >
                              (View all)
                            </Button>
                          </Table.Cell>
                          <Table.Cell style={borderlessCellStyle}>
                            <CurrencyFormatLabel
                              value={centsToDollars(
                                account.balanceBroughtDownDetails.sumDebits
                              )}
                            />
                          </Table.Cell>
                          <Table.Cell style={borderlessCellStyle}>
                            <CurrencyFormatLabel
                              value={centsToDollars(
                                account.balanceBroughtDownDetails.sumCredits
                              )}
                            />
                          </Table.Cell>
                        </Table.Row>
                      )}

                    {/* Total debits and credits */}
                    <Table.Row>
                      <Table.Cell style={borderlessCellStyle} />
                      <Table.Cell style={borderlessCellStyle} />
                      <Table.Cell
                        style={{
                          ...borderlessCellStyle,
                          ...singleBorderBottomStyle,
                        }}
                      >
                        <Text as="h3">
                          <b>Total {account.name}</b>
                        </Text>
                      </Table.Cell>
                      <Table.Cell
                        style={{
                          ...borderlessCellStyle,
                          ...singleBorderBottomStyle,
                        }}
                      >
                        <Text as="h3">
                          <b>
                            <CurrencyFormatLabel
                              value={centsToDollars(account.totalDebitsInCents)}
                            />
                          </b>
                        </Text>
                      </Table.Cell>
                      <Table.Cell
                        style={{
                          ...borderlessCellStyle,
                          ...singleBorderBottomStyle,
                        }}
                      >
                        <Text as="h3">
                          <b>
                            <CurrencyFormatLabel
                              value={centsToDollars(
                                account.totalCreditsInCents
                              )}
                            />
                          </b>
                        </Text>
                      </Table.Cell>
                    </Table.Row>

                    {/* Net movement */}
                    <Table.Row>
                      <Table.Cell style={borderlessCellStyle} />
                      <Table.Cell style={borderlessCellStyle} />
                      <Table.Cell
                        style={{
                          ...borderlessCellStyle,
                          ...doubleBorderBottomStyle,
                        }}
                      >
                        <Text as="h3">
                          <b>Net Movement</b>
                        </Text>
                      </Table.Cell>
                      <Table.Cell
                        style={{
                          ...borderlessCellStyle,
                          ...doubleBorderBottomStyle,
                        }}
                      >
                        {((account.accountType !==
                          TransactionAccountTypes.assets &&
                          account.netTotalInCents < 0) ||
                          (account.accountType ===
                            TransactionAccountTypes.assets &&
                            account.netTotalInCents >= 0)) && (
                          <Text as="h3">
                            <b>
                              <CurrencyFormatLabel
                                value={centsToDollars(
                                  Math.abs(account.netTotalInCents)
                                )}
                              />
                            </b>
                          </Text>
                        )}
                      </Table.Cell>
                      <Table.Cell
                        style={{
                          ...borderlessCellStyle,
                          ...doubleBorderBottomStyle,
                        }}
                      >
                        {((account.accountType !==
                          TransactionAccountTypes.assets &&
                          account.netTotalInCents >= 0) ||
                          (account.accountType ===
                            TransactionAccountTypes.assets &&
                            account.netTotalInCents < 0)) && (
                          <Text as="h3">
                            <b>
                              <CurrencyFormatLabel
                                value={centsToDollars(
                                  Math.abs(account.netTotalInCents)
                                )}
                              />
                            </b>
                          </Text>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </GridRowColumn>
            </Grid>
          </Accordion.Content>
        </Card>
      </div>
    )
  }
)

export default GeneralLedgerRow
