import TermsOfServiceCopy from './TermsOfServiceCopy'
import { updateUser } from '../../actions/userActions'
import { Alert, Button, Modal, Text } from '../BaseComponents'
import { TosVersion } from '../../constants/businessConstants'
import { useAppDispatch } from '../../utils/typeHelpers'

interface Props {
  currentUserId: number
}

const TermsOfServiceModal = ({ currentUserId }: Props) => {
  const dispatch = useAppDispatch()

  const acceptNewTerms = async () => {
    const data = {
      tosVersion: TosVersion.tos_02_2022,
      tosAcceptedOn: Date.now(),
    }
    /* Update user — don't need to do anything after because the returned user will close the modal */
    await updateUser(currentUserId, data)(dispatch)
  }

  return (
    <Modal className="flexbox " open>
      <Modal.Content>
        <Text as="h1" color="forest">
          Changes to our Terms of Service
        </Text>
        <br />
        <Alert>
          We have made some changes to our Terms of Service. Please review and
          accept, thank you!
        </Alert>
        <br />
        <div style={{ height: '350px', overflowY: 'scroll' }}>
          <TermsOfServiceCopy />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={acceptNewTerms}>Accept New Terms</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default TermsOfServiceModal
