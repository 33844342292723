import { createSelector } from 'reselect'
import { DateTime } from 'luxon'
import moment from 'moment'
import { filter, groupBy, isEmpty, orderBy, pickBy } from 'lodash'

import { ReduxState } from '../../../utils/typeHelpers'
import { UserTaxEstimate, UserTaxEstimateState } from './userTaxEstimates.slice'
import { NO_STATE_QUARTERLY_TAX_ESTIMATES } from '../taxConstants'
import {
  getCurrentUser,
  selectMembershipStart,
  selectTaxProfileLastReviewed,
} from '../../../selectors/user.selectors'
import { selectCurrentAnnualTaxYear } from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  selectActiveQuarterlyTaxEstimateDetails,
  selectQuarterDetailsForQuarterBeforeActiveQuarter,
} from '../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { isoToUTCDateTime } from '../../../utils/dateHelpers'
import { QuarterlyTaxEstimateDetail } from '../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import { splitTaxYearQuarter } from './helpers'
import { statusForSingleEstimate } from './service'
import { selectForQTEAllBooksReconciledForRequiredMonths } from '../../../selectors/bookkeepingReportsSelectors'

const groupEstimates = (
  estimates: UserTaxEstimate[],
  homeState?: string | null
) => {
  const federalTaxEstimate = estimates
    ? estimates.filter(
        (estimate: UserTaxEstimate) => estimate.type === 'federal'
      )[0]
    : null
  const stateTaxEstimates = estimates
    ? estimates.filter(
        (estimate: UserTaxEstimate) =>
          estimate.type === 'state' && estimate.filingState !== homeState
      )
    : null
  const homeStateTaxEstimate = estimates
    ? estimates.filter(
        (estimate: UserTaxEstimate) =>
          estimate.type === 'state' && estimate.filingState === homeState
      )[0]
    : null
  return {
    federalTaxEstimate,
    stateTaxEstimates,
    homeStateTaxEstimate,
  }
}

export const getUserTaxEstimates = (state: ReduxState) => state.userTaxEstimates

export const selectUserTaxEstimateById = createSelector(
  getUserTaxEstimates,
  (_: unknown, id: number | string | undefined) => id,
  (taxEstimates, id) => (id ? taxEstimates[id] : null)
)

const getEstimatesForYear = (
  userTaxEstimates: UserTaxEstimateState,
  year: string | number | undefined
) =>
  year
    ? orderBy(
        filter(
          userTaxEstimates,
          (event) =>
            moment(event.taxQuarter, 'YYYY-Q').format('YYYY') ===
            year.toString()
        ),
        ['dueDate'],
        ['asc']
      )
    : []

export const getEstimatesByYear = createSelector(
  [getUserTaxEstimates, (_: unknown, year?: string | number) => year],
  getEstimatesForYear
)

export const selectEstimatesByQuarter = createSelector(
  getEstimatesByYear,
  (estimates) => groupBy(estimates, (e) => e.taxQuarter)
)

export const selectEstimatesByTaxQuarter = createSelector(
  getUserTaxEstimates,
  // taxQuarter should be sent in YYYY-Q format
  (_: unknown, taxQuarter: string | undefined) => taxQuarter,
  (estimates, taxQuarter) =>
    Object.values(estimates).filter((est) => est.taxQuarter === taxQuarter)
)

export const selectFederalEstimateByTaxQuarter = createSelector(
  selectEstimatesByTaxQuarter,
  (estimates) => estimates.find((estimate) => estimate.filingState === null)
)

export const selectActiveYearEstimates = createSelector(
  getUserTaxEstimates,
  selectActiveQuarterlyTaxEstimateDetails,
  (taxEstimates, activeDetails) =>
    getEstimatesForYear(taxEstimates, activeDetails?.taxYear)
)

export const selectPreviousQuarterTaxEstimatesForYear = createSelector(
  getEstimatesByYear,
  (_: unknown, __: unknown, quarter: number | string) => quarter,
  (estimates, quarter) =>
    estimates.filter(
      (estimate) =>
        // this is pulling out the quarter number and checking that it's less than the current quarter
        Number(moment(estimate.taxQuarter, 'YYYY-Q').format('Q')) <
        Number(quarter)
    )
)

export const selectOnboardingEstimates = createSelector(
  getCurrentUser,
  getUserTaxEstimates,
  selectCurrentAnnualTaxYear,
  (user, userTaxEstimates, year) => {
    if (!user) {
      return {}
    }
    const { createdAt } = user
    const taxQuarters = [
      { q: `${year}-1`, cutoff: `3/31/${year}` },
      { q: `${year}-2`, cutoff: `7/31/${year}` },
      { q: `${year}-3`, cutoff: `9/30/${year}` },
    ]
      .filter(({ cutoff }) => moment(createdAt).isAfter(cutoff))
      .map(({ q }) => q)

    return pickBy(userTaxEstimates, (event) =>
      taxQuarters.includes(event.taxQuarter)
    )
  }
)

export const checkEstimateIsUnpaid = (estimate: UserTaxEstimate) => {
  /* 
      Unpaid is true if:
        Paid At is not indicated
        AND no tax payment is not indicated
        AND user has not told us they have not paid
        AND estimate is not 0
        AND estimate is in the past (not active or future)
        AND state estimate is not a no income tax state
    */
  return (
    estimate.paidAt === null &&
    !estimate.noTaxPaymentNeeded &&
    estimate.estimateInCents !== 0 &&
    estimate.status === 'past' &&
    estimate.quarterPaid !== false &&
    (estimate.filingState
      ? !NO_STATE_QUARTERLY_TAX_ESTIMATES.includes(estimate.filingState)
      : true)
  )
}

export const getUnpaidTaxEstimatesByYear = createSelector(
  getEstimatesByYear,
  (estimates) => {
    return estimates.filter((estimate) => checkEstimateIsUnpaid(estimate))
  }
)

export const getPaidTaxEstimatesByYear = createSelector(
  getEstimatesByYear,
  (estimates) => {
    return estimates.filter((estimate) => !checkEstimateIsUnpaid(estimate))
  }
)

export const selectAreAllEstimatesPaidSoFar = createSelector(
  getEstimatesByYear,
  (estimates) => {
    if (isEmpty(estimates)) {
      return false
    }
    return estimates.every((estimate) => !checkEstimateIsUnpaid(estimate))
  }
)

export const getActiveTaxEstimateWithinActiveQuarter = createSelector(
  [getUserTaxEstimates, selectActiveQuarterlyTaxEstimateDetails],
  (userTaxEstimates, activeQuarterlyTaxEstimateDetails) => {
    return filter(userTaxEstimates, (taxEstimate: UserTaxEstimate) => {
      if (!activeQuarterlyTaxEstimateDetails || !taxEstimate.taxQuarter) {
        return false
      }
      const { quarter, year } = splitTaxYearQuarter(taxEstimate.taxQuarter)

      return (
        taxEstimate.status === 'active' &&
        quarter === activeQuarterlyTaxEstimateDetails.taxQuarter &&
        year === activeQuarterlyTaxEstimateDetails.taxYear
      )
    })
  }
)

export const getGroupedTaxEstimatesForQuarter = createSelector(
  [
    getUserTaxEstimates,
    (_: unknown, args: { taxQuarter: string; homeState: string | null }) =>
      args,
  ],
  (estimates, args) => {
    const { taxQuarter, homeState } = args
    const estimatesForQuarter = filter(
      estimates,
      (estimate: UserTaxEstimate) => estimate.taxQuarter === taxQuarter
    )

    return groupEstimates(estimatesForQuarter, homeState)
  }
)

export const getGroupedActiveTaxEstimatesWithinActiveQuarter = createSelector(
  [
    getActiveTaxEstimateWithinActiveQuarter,
    (_: unknown, homeState?: string | null) => homeState,
  ],
  (activeTaxEstimate, homeState) => {
    return groupEstimates(activeTaxEstimate, homeState)
  }
)

export const selectAreAnyEstimatesLastQuarterNotPaidOrPaidAfterIRSDeadline =
  createSelector(
    [getUserTaxEstimates, selectQuarterDetailsForQuarterBeforeActiveQuarter],
    (estimates, quarterDetails) => {
      if (!quarterDetails) {
        return false
      }
      const taxQuarter = `${quarterDetails.taxYear}-${quarterDetails.taxQuarter}`
      const estimatesForQuarter = filter(
        estimates,
        (estimate: UserTaxEstimate) => estimate.taxQuarter === taxQuarter
      )
      return estimatesForQuarter.some(
        (estimate) =>
          checkEstimateIsUnpaid(estimate) ||
          estimate.quarterPaid === false ||
          (estimate.paidAt &&
            DateTime.fromISO(estimate.paidAt) >
              DateTime.fromISO(quarterDetails.irsPaymentDueAt))
      )
    }
  )

/*ADMIN */
export const adminGetUserTaxEstimates = createSelector(
  [getUserTaxEstimates, (_: unknown, userId: string) => userId],
  (allUserTaxEstimates, userId) => {
    return filter(
      allUserTaxEstimates,
      (taxEstimate: UserTaxEstimate) =>
        Number(taxEstimate.userId) === Number(userId)
    )
  }
)

export const selectUserTaxEstimates = createSelector(
  [getUserTaxEstimates, (_: unknown, userId?: number) => userId],
  (allUserTaxEstimates, userId) =>
    filter(
      allUserTaxEstimates,
      (taxEstimate: UserTaxEstimate) => taxEstimate.userId === userId
    )
)

export const adminGroupUserTaxEstimates = createSelector(
  [selectUserTaxEstimates],
  (taxEstimates) => groupBy(taxEstimates, 'taxQuarter')
)

const isPastCutoff = (
  details: QuarterlyTaxEstimateDetail,
  membershipStart: string
) => {
  const cutoffDate = isoToUTCDateTime(details.newUserCutOffAt)
  // using utc + 10hrs to match what we're doing in the backend
  // the +10hrs is used because we don't store user's timezone
  // so we're using max possible timezone offset for US (Hawaii)
  const dayAfterCutoff = cutoffDate.plus({ days: 1, hours: 10 })
  const userCreatedAtAsUTC = isoToUTCDateTime(membershipStart)
  return userCreatedAtAsUTC >= dayAfterCutoff
}

// note: this uses the same cutoff calculation as what we're
// using in the backend to send checklist CTA emails
export const selectUserCreatedPastQteCutoff = createSelector(
  selectActiveQuarterlyTaxEstimateDetails,
  selectMembershipStart,
  (activeQTEDetails, membershipStart) =>
    Boolean(
      activeQTEDetails &&
        membershipStart &&
        isPastCutoff(activeQTEDetails, membershipStart)
    )
)

export const selectStatusForTaxEstimate = createSelector(
  selectUserTaxEstimateById,
  selectUserCreatedPastQteCutoff,
  (taxEstimate, userJoinedTooLate) =>
    statusForSingleEstimate(taxEstimate, userJoinedTooLate)
)

//This will check the status for a safe harbor estimate, so we don't want to return the joinedTooLate status
export const selectStatusForLateJoinerTaxEstimate = createSelector(
  selectUserTaxEstimateById,
  (taxEstimate) => statusForSingleEstimate(taxEstimate)
)

// Year expected in YYYY format
export const adminGetUserPaidTaxEstimatesForYear = createSelector(
  [adminGetUserTaxEstimates, (_: unknown, __: string, year: string) => year],
  (estimates, year) =>
    estimates.filter(
      (estimate) =>
        !checkEstimateIsUnpaid(estimate) && estimate.taxQuarter.includes(year)
    )
)

export const selectTaxProfileReviewedInRequiredRangeForQTE = createSelector(
  [selectTaxProfileLastReviewed, selectActiveQuarterlyTaxEstimateDetails],
  (lastReviewed, activeQuarterDetails) => {
    if (!lastReviewed || !activeQuarterDetails) {
      return false
    }
    return (
      isoToUTCDateTime(lastReviewed) >=
      isoToUTCDateTime(activeQuarterDetails.newUserCutOffAt).startOf('day')
    )
  }
)

// Defined in tech spec
// https://www.notion.so/heard/QTE-Withhold-Estimate-when-information-is-not-up-to-date-68d6ec1935d840f9b8677f01a58d91f6#d0a6246856f440edb38938d3bf3f7d8b
export const selectAllQTEChecklistItemsComplete = createSelector(
  [
    selectTaxProfileReviewedInRequiredRangeForQTE,
    selectAreAllEstimatesPaidSoFar,
    selectForQTEAllBooksReconciledForRequiredMonths,
  ],
  (taxProfileReviewed, allEstimatesPaid, allBooksClosed) => {
    const { allRequiredBooksAreReconciled } = allBooksClosed
    return (
      taxProfileReviewed && allEstimatesPaid && allRequiredBooksAreReconciled
    )
  }
)

// Return the parts of the checklist that users can action on (updating their tax profile and marking their previous estimates)
// Books reconciliation is outside of their control, so don't include that
export const selectActionableQTEChecklistItemsComplete = createSelector(
  [
    selectTaxProfileReviewedInRequiredRangeForQTE,
    selectAreAllEstimatesPaidSoFar,
  ],
  (taxProfileReviewed, allEstimatesPaid) => {
    return taxProfileReviewed && allEstimatesPaid
  }
)

export const selectAllQTEChecklistItemsCompletedOnDate = createSelector(
  [
    selectAllQTEChecklistItemsComplete,
    selectTaxProfileLastReviewed,
    selectForQTEAllBooksReconciledForRequiredMonths,
    getPaidTaxEstimatesByYear,
  ],
  (
    checklistComplete,
    taxProfileReviewedAt,
    allBooksReconciled,
    paidEstimatesForYear
  ) => {
    const { mostRecentBookkeepingReportReconciled } = allBooksReconciled
    const mostRecentBookkeepingReportReconciledAt =
      mostRecentBookkeepingReportReconciled?.reconciledAt
    if (
      !checklistComplete ||
      !mostRecentBookkeepingReportReconciledAt ||
      !taxProfileReviewedAt
    ) {
      return null
    }

    // filter to status past and get most recently updated estimate
    const mostRecentlyUpdatedEstimate = paidEstimatesForYear.reduce(
      (acc, estimate) => {
        if (estimate.status === 'past' && estimate.updatedAt) {
          return !acc ||
            DateTime.fromISO(estimate.updatedAt) >
              DateTime.fromISO(acc.updatedAt)
            ? estimate
            : acc
        }
        return acc
      },
      null as UserTaxEstimate | null
    )

    // find the most recent date from the three
    const dates = [
      taxProfileReviewedAt,
      mostRecentBookkeepingReportReconciledAt,
    ]

    if (mostRecentlyUpdatedEstimate) {
      dates.push(mostRecentlyUpdatedEstimate.updatedAt)
    }

    return DateTime.max(...dates.map((d) => DateTime.fromISO(d)))
  }
)

export const selectPreviousQuarterSafeHarborAvailable = createSelector(
  selectQuarterDetailsForQuarterBeforeActiveQuarter,
  getUserTaxEstimates,
  selectMembershipStart,
  (previousDetails, estimates, membershipStart) => {
    const previousFederalEstimate = Object.values(estimates).find(
      (e) =>
        e.taxQuarter ===
          `${previousDetails?.taxYear}-${previousDetails?.taxQuarter}` &&
        e.filingState === null
    )
    // don't show safe harbor if it's Q1 now or if they don't have an estimate/details for the previous quarter
    if (
      !previousDetails ||
      previousDetails?.taxQuarter === '4' ||
      !previousFederalEstimate
    ) {
      return false
    }

    return (
      membershipStart &&
      isoToUTCDateTime(membershipStart) >=
        isoToUTCDateTime(previousDetails.newUserCutOffAt)
    )
  }
)

export const selectNumberOfQuartersWithFederalEstimate = createSelector(
  getUserTaxEstimates,
  (estimates) => {
    const estimatesByQuarter = groupBy(estimates, (e) => e.taxQuarter)
    return Object.values(estimatesByQuarter).reduce((count, quarter) => {
      const federalEstimate = quarter.find((e) => e.type === 'federal')
      return federalEstimate?.estimateInCents ? count + 1 : count
    }, 0)
  }
)
