import { Dropdown, Text } from '../../../components/BaseComponents'
import {
  DropdownDivider,
  Dropdown as SemanticDropdown,
} from 'semantic-ui-react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useFlyout } from '../../../utils/sharedHooks'
import TransactionDetails from '../TransactionDetails'
import { TransactionOrSplit } from '../../../reducers/admin/allTransactions.slice'
import { useAnalyticsTrack } from '../../Amplitude'
import { useMemo, useState, useEffect, useRef } from 'react'
import TransactionDeleteModal from '../TransactionDeleteModal'
export const TransactionDetailsMenu = ({
  transaction,
  review,
  toggleFileModal,
  isManualTransaction,
}: {
  transaction: TransactionOrSplit
  review?: boolean
  toggleFileModal: () => void
  isManualTransaction: boolean
}) => {
  const { openFlyout } = useFlyout()
  const track = useAnalyticsTrack()
  const [open, setOpen] = useState(false)
  const [preventClose, setPreventClose] = useState(false)
  const content = useMemo(() => {
    return isManualTransaction
      ? {
          details: 'View/Edit Details',
          button: 'Delete Transaction',
        }
      : {
          details: 'View Details',
          button: 'Mark as Duplicate',
        }
  }, [isManualTransaction])

  const preventCloseRef = useRef(preventClose)

  useEffect(() => {
    preventCloseRef.current = preventClose
  }, [preventClose])

  const preventCloseAction = (value: boolean) => {
    setPreventClose(value)
  }

  const preventCloseGetter = () => {
    return preventCloseRef.current
  }

  const handleOpenTransactionDetails = (activeIndex: number) => {
    openFlyout({
      content: (
        <TransactionDetails
          transactionId={transaction.id}
          review={review}
          parentId={transaction.splitFrom ?? undefined}
          defaultActiveIndex={activeIndex}
          isManualTransaction={isManualTransaction}
          setPreventClose={preventCloseAction}
        />
      ),
      title: 'Transaction Details',
      direction: 'right',
      titleIcon: solid('coins'),
      preventClose: preventCloseGetter,
    })
    track('clicked transaction flyout button')
  }

  return (
    <>
      <Dropdown
        className="action-button"
        direction="left"
        icon="ellipsis horizontal"
        variant="text"
      >
        <SemanticDropdown.Menu>
          <SemanticDropdown.Item
            onClick={() => handleOpenTransactionDetails(0)}
          >
            <Text>{content.details}</Text>
          </SemanticDropdown.Item>
          <SemanticDropdown.Item onClick={toggleFileModal}>
            <Text>Upload Receipt</Text>
          </SemanticDropdown.Item>
          <SemanticDropdown.Item
            onClick={() => handleOpenTransactionDetails(1)}
          >
            <Text>Add Note</Text>
          </SemanticDropdown.Item>
          {!transaction.reconciliationId && (
            <>
              <DropdownDivider />
              <SemanticDropdown.Item onClick={() => setOpen(true)}>
                <Text color={isManualTransaction ? 'red' : 'black'}>
                  {content.button}
                </Text>
              </SemanticDropdown.Item>
            </>
          )}
        </SemanticDropdown.Menu>
      </Dropdown>
      <TransactionDeleteModal
        open={open}
        close={() => setOpen(false)}
        transactionId={transaction.id}
        isManualTransaction={isManualTransaction}
      />
    </>
  )
}

export default TransactionDetailsMenu
