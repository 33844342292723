import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { keyBy } from 'lodash'

import { fetchWrapper } from '../../../reducers/fetch'

/*
  The below is all the KEYWORDS we used on the DB to denote these statuses.
  Do not update unless you also update the DB
*/
export const FILING_STATUS_COMPLETE = 'complete'
export const FILING_STATUS_NOT_STARTED = 'not_started'
export const FILING_STATUS_SIGNED = 'signed'
export const FILING_STATUS_SIGNING_READY = 'ready_to_sign'

export interface AnnualTaxFilingStatusType {
  id: number
  name: string
  tooltip: null | string
  index: string
  createdAt: string
  updatedAt: string
}

export interface AnnualTaxFilingStatusTypeEntities {
  annualTaxFilingStatusTypes?: { [key: string]: AnnualTaxFilingStatusType }
}

export interface AnnualTaxFilingStatusTypeState {
  [key: string]: AnnualTaxFilingStatusType
}

const annualTaxFilingStatusTypesSlice = createSlice({
  name: 'annualTaxFilingStatusTypes',
  initialState: {} as AnnualTaxFilingStatusTypeState,
  reducers: {
    getAnnualTaxFilingStatusTypes: (
      state,
      action: PayloadAction<{ [key: string]: AnnualTaxFilingStatusType }>
    ) => ({
      ...state,
      ...action.payload,
    }),
  },
})

export default annualTaxFilingStatusTypesSlice.reducer

// Actions
const { getAnnualTaxFilingStatusTypes } =
  annualTaxFilingStatusTypesSlice.actions

const FETCH_ANNUAL_TAX_FILING_STATUS_TYPE_KEY =
  'FETCH_ANNUAL_TAX_FILING_STATUS_TYPE_KEY'
export const fetchAnnualTaxFilingStatusTypesIfNeeded = () =>
  fetchWrapper({
    fetchKey: FETCH_ANNUAL_TAX_FILING_STATUS_TYPE_KEY,
    defaultErrorMessage: 'Error fetching all annual tax filing status type',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<AnnualTaxFilingStatusType[]>(
        '/finances/api/v1/annual_tax_filings/status_types'
      )
      dispatch(getAnnualTaxFilingStatusTypes(keyBy(json.data, 'id')))
      return json.data
    },
  })
