import { ReactNode } from 'react'
import { Colors } from '../../../styles/theme'

export enum DropdownType {
  dropdown,
}

export const InsightsDividedCard = ({
  columns,
  type,
}: {
  columns: Array<{
    content: ReactNode
    isLastColumn?: boolean
  }>
  type?: DropdownType
}) => (
  <div style={{ display: 'flex' }}>
    {columns.map((config, index) => (
      <div
        style={{
          backgroundColor: Colors.stone40,
          padding: type === DropdownType.dropdown ? 0 : 12,
          borderLeft:
            index > 0 && !config.isLastColumn ? 'solid black 1px' : undefined,
          marginLeft: config.isLastColumn ? 40 : 0,
        }}
        key={index} // skipcq: JS-0437 - Ignoring deepsource since there's no candidate identifier to access
      >
        {config.content}
      </div>
    ))}
  </div>
)
