import { Icon, IconButton } from '../components/BaseComponents'
import {
  light,
  regular,
  solid,
} from '@fortawesome/fontawesome-svg-core/import.macro'

export const LargeRegularIcon = () => (
  <Icon icon={regular('cat-space')} size="2x" color="green" />
)

export const SmallLightIcon = () => <Icon icon={light('meteor')} color="blue" />

export const IconButtonExample = () => (
  <IconButton
    icon={solid('narwhal')}
    color="forest"
    onClick={() => {}}
    size="2x"
  />
)
