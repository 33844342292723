// BL
import { UserDocument } from '../../userDocuments.slice'
import { toLocalFullDate } from '../../service'

// UI
import { Table } from 'semantic-ui-react'

interface DocumentDateUploadedCellProps {
  document: UserDocument
}

const DocumentDateUploadedCell = ({
  document,
}: DocumentDateUploadedCellProps) => (
  <Table.Cell>
    {/* Required, missing documents have their id set to -1 */}
    {document.id !== -1 && toLocalFullDate(document.createdAt)}
  </Table.Cell>
)

export default DocumentDateUploadedCell
