import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Grid } from 'semantic-ui-react'
import { TransactionOrSplit } from '../../../reducers/admin/allTransactions.slice'
import {
  Card,
  Text,
  Label,
  IconButton,
  Button,
} from '../../../components/BaseComponents'
import { JournalEntryLinesTable } from './JournalEntryTransactionRow'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../utils/dateHelpers'
import { useReselector, useToggle } from '../../../utils/sharedHooks'
import { EditJournalEntryModal } from './EditJournalEntryModal'
import { DeleteJournalEntryModal } from './DeleteJournalEntryModal'
import {
  selectJournalEntryTransactionById,
  selectJournalEntrySplitTransactionById,
} from '../../../selectors/journalEntrySelectors'
import { JournalEntry } from '../types'
import {
  JournalEntryUpdateType,
  MaybeJournalEntry,
} from '../../../components/Admin/PaginatedTransactionTable/RowDrawer'

export const TransactionJournalEntriesCard = ({
  journalEntry,
  onUpdate = () => undefined,
}: {
  journalEntry: JournalEntry
  onUpdate?: (
    journalEntry: MaybeJournalEntry,
    type: JournalEntryUpdateType
  ) => void
}) => {
  const [isEditing, toggleEdit] = useToggle()
  const [isDeleting, toggleDelete] = useToggle()
  const { journalEntryDate, details, automated, userId, journalEntryLines } =
    journalEntry
  return (
    <Card type="subsection" backgroundColor="stone40">
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <Text as="h3" style={{ marginBottom: 16 }}>
              {isoToUTCDateTime(journalEntryDate).toFormat(
                DATE_FORMATS_LUXON.INPUT
              )}
            </Text>
            <Text color="darkGray" style={{ marginBottom: 16 }}>
              <i>{details}</i>
            </Text>
            {automated && (
              <Label icon={regular('bolt')} style={{ marginBottom: 16 }}>
                Auto
              </Label>
            )}
            {!automated && (
              <Label
                icon={regular('hand')}
                color="dark"
                style={{ marginBottom: 16 }}
              >
                Manual
              </Label>
            )}
            <Grid>
              <Grid.Row>
                <Grid.Column width={3}>
                  <IconButton
                    icon={regular('edit')}
                    color="darkGray"
                    onClick={toggleEdit}
                    style={{ marginTop: 16 }}
                  />
                </Grid.Column>
                {!automated && (
                  <Grid.Column width={3}>
                    <IconButton
                      icon={regular('trash-alt')}
                      onClick={toggleDelete}
                      color="darkGray"
                    />
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width={12}>
            <JournalEntryLinesTable
              journalEntryLines={journalEntryLines}
              userId={userId}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {isEditing && (
        <EditJournalEntryModal
          open={isEditing}
          onClose={toggleEdit}
          onUpdate={onUpdate}
          journalEntry={journalEntry}
        />
      )}
      {isDeleting && (
        <DeleteJournalEntryModal
          open={isDeleting}
          onClose={toggleDelete}
          journalEntry={journalEntry}
          onUpdate={onUpdate}
          userId={userId}
        />
      )}
    </Card>
  )
}

const TransactionJournalEntriesPanel = ({
  transaction,
  ignoreRedux = false,
  onUpdate = () => undefined,
}: {
  transaction: TransactionOrSplit
  ignoreRedux?: boolean
  onUpdate?: (
    journalEntry: MaybeJournalEntry,
    type: JournalEntryUpdateType
  ) => void
}) => {
  const [isCreating, toggleCreate] = useToggle()
  const journalEntryTransaction = useReselector(
    selectJournalEntryTransactionById,
    transaction.id
  )

  const splitJournalEntryTransaction = useReselector(
    selectJournalEntrySplitTransactionById,
    transaction.id,
    transaction.splitFrom
  )

  const foundTransaction = transaction.splitFrom
    ? splitJournalEntryTransaction
    : journalEntryTransaction

  const journalEntries =
    (ignoreRedux
      ? transaction.journalEntries
      : foundTransaction?.journalEntries) ?? []

  if (!foundTransaction && !ignoreRedux) return null

  return (
    <div style={{ padding: 16 }}>
      <Grid>
        <Grid.Row>
          <Grid.Column width={2} />
          <Grid.Column width={2} floated="right">
            <Button
              variant="secondaryWhite"
              onClick={toggleCreate}
              size="small"
              style={{ marginTop: 12 }}
              fullWidth
            >
              Create Journal Entry
            </Button>
          </Grid.Column>
          <Grid.Column width={12}>
            {journalEntries.map((journalEntry) => (
              <TransactionJournalEntriesCard
                journalEntry={journalEntry}
                key={journalEntry.id}
                onUpdate={onUpdate}
              />
            ))}
          </Grid.Column>
        </Grid.Row>
        {isCreating && (
          <EditJournalEntryModal
            open={isCreating}
            onClose={toggleCreate}
            onUpdate={onUpdate}
            transactionId={transaction.id.toString()}
          />
        )}
      </Grid>
    </div>
  )
}

export default TransactionJournalEntriesPanel
