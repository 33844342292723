import { createSelector } from 'reselect'
import { ReduxState } from '../../../utils/typeHelpers'
import { orderBy } from 'lodash'
import { TransactionChangeLogsState } from './transactionChangeLog.slice'

const getTransactionChangeLogs = (state: ReduxState) =>
  state.admin.allTransactionChangeLogs

export const getTransactionChangeLogsByTransactionId = createSelector(
  [
    getTransactionChangeLogs,
    (_: unknown, transactionId: number) => transactionId,
  ],
  (transactionChangeLogs: TransactionChangeLogsState, transactionId) => {
    if (!transactionChangeLogs || transactionChangeLogs.allIds.length === 0) {
      return null
    }

    const result = Object.values(transactionChangeLogs.byId).filter(
      (log) => log.transactionId === transactionId
    )

    return orderBy(result, ['createdAt'], ['desc'])
  }
)
