import moment from 'moment'

// https://stackoverflow.com/a/64966935
export function csvRowToArray(row: string, delimiter = ',', quoteChar = '"') {
  const array = []
  let nStart = 0,
    nEnd = 0,
    bQuotedValue
  while (nStart <= row.length) {
    bQuotedValue = row.charAt(nStart) === quoteChar
    if (bQuotedValue) {
      nStart++
      nEnd = row.indexOf(quoteChar + delimiter, nStart)
    } else {
      nEnd = row.indexOf(delimiter, nStart)
    }
    if (nEnd < 0) nEnd = row.length
    const el = row.substring(nStart, nEnd)

    if (el) {
      array.push(el)
    }
    nStart = nEnd + delimiter.length + (bQuotedValue ? 1 : 0)
  }
  return array
}

export const getSpreadsheetRowErrors = (rows: string[][]) => {
  const errors = []

  // 1
  if (rows.length < 2) {
    errors.push(
      'The spreadsheet must have at least 2 rows - 1 row of column titles and at least 1 row of transaction data'
    )
  } else {
    let columnCountIsValid = true

    // 2
    rows.forEach((r, i) => {
      if (r.length !== 3) {
        columnCountIsValid = false
        errors.push(`Row ${i + 1} must have three non-empty cells`)
      } else if (i !== 0) {
        // Skip header row
        if (!moment(r[0]).isValid()) {
          errors.push(`Row ${i + 1} has an invalid date`)
        }
        if (Number.isNaN(parseFloat(r[2].replace(/,/g, '')))) {
          errors.push(`Row ${i + 1} has an invalid amount`)
        }
      }
    })

    if (columnCountIsValid) {
      // 3
      // At this point, we know 2+ rows exist, and each row contains exactly 3 columns
      if (rows[0][0].toLowerCase().trim() !== 'date') {
        errors.push('The first column must be titled "date"')
      }

      if (rows[0][1].toLowerCase().trim() !== 'description') {
        errors.push('The second column must be titled "description"')
      }

      if (rows[0][2].toLowerCase().trim() !== 'amount') {
        errors.push('The third column must be titled "amount"')
      }
    }
  }

  return errors
}

export const processCsv = (csv: string) => {
  const allTextLines = csv.split(/\r\n|\n/)
  const rows: Array<Array<string>> = []

  // Nested loop through each row's cell. Entirely empty rows not retained
  allTextLines.forEach((l) => {
    const data = csvRowToArray(l, ',', '"')
    const cells: Array<string> = []
    data?.forEach((d) => {
      cells.push(d)
    })

    let emptyCellCount = 0
    cells.forEach((c) => {
      if (c.trim() === '') {
        emptyCellCount += 1
      }
    })

    // Add the row if the ENTIRE row is not empty
    if (emptyCellCount !== cells.length) {
      rows.push(cells)
    }
  })

  return rows
}
