import { isNil } from 'lodash'
import moment from 'moment'
import { CSSProperties, useMemo } from 'react'
import { Divider, List, Message } from 'semantic-ui-react'
import CurrencyFormatLabel from '../../../../components/shared/CurrencyFormatLabel'
import {
  centsToDollars,
  dollarsToCurrency,
  formatCurrencyFromCents,
} from '../../../../utils/currencyHelpers'
import { UserTaxEstimate } from '../../../Taxes/QuarterlyTaxEstimates/userTaxEstimates.slice'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectShouldDeductSelfEmploymentTax,
  selectUserTaxCalculationById,
} from '../../../../selectors/userTaxCalculation.selectors'
import { selectActiveQuarterlyTaxEstimateDetails } from '../../QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { splitTaxYearQuarter } from '../../../Taxes/QuarterlyTaxEstimates/helpers'

const AdjustmentsPopup = ({
  taxCalculationId,
  isFederal,
  style,
}: {
  isFederal: boolean
  taxCalculationId: number
  style?: CSSProperties
}) => {
  const calc = useReselector(selectUserTaxCalculationById, taxCalculationId)
  const activeQuarterlyDetail = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )

  const shouldDeductSETax = useReselector(
    selectShouldDeductSelfEmploymentTax,
    taxCalculationId
  )

  const stateAnnualPaymentsDeductedCurrency = useMemo(() => {
    let incomeTax = dollarsToCurrency(
      calc?.taxCalculationOutput?.data.stateIncomeTax
    )

    calc?.paidEstimatesAtQ4Calculation
      ?.filter((e) => e.type === 'state')
      .forEach((paidStateEstimate) => {
        if (paidStateEstimate.amountPaidInCents) {
          incomeTax = incomeTax.subtract(
            centsToDollars(paidStateEstimate.amountPaidInCents)
          )
        }
      })

    return incomeTax
  }, [
    calc?.taxCalculationOutput?.data.stateIncomeTax,
    calc?.paidEstimatesAtQ4Calculation,
  ])

  if (!calc) {
    return null
  }

  const {
    taxCalculationOutput,
    stateQuarterlyEstimates,
    paidEstimatesAtQ4Calculation,
    calendarQuarter,
    federalQuarterlyEstimateInCents,
    calculatedByUserId,
  } = calc

  if (
    !taxCalculationOutput ||
    !stateQuarterlyEstimates ||
    stateQuarterlyEstimates.length === 0
  ) {
    return <div>Row has no abound response yet</div>
  }

  const NoQTEPaymentsMade = () => (
    <List.Item style={{ fontSize: '90%' }}>No payments made</List.Item>
  )

  const federalAnnual = (
    <CurrencyFormatLabel
      value={taxCalculationOutput.data.federalTaxOutstanding}
    />
  )
  const stateAnnual = (
    <CurrencyFormatLabel value={taxCalculationOutput.data.stateIncomeTax} />
  )
  const seTax = (
    <CurrencyFormatLabel value={taxCalculationOutput.data.selfEmploymentTax} />
  )

  const seTaxDifference = (
    <CurrencyFormatLabel
      value={
        dollarsToCurrency(
          taxCalculationOutput.data.federalTaxOutstanding
        ).subtract(taxCalculationOutput.data.selfEmploymentTax).value
      }
    />
  )
  const seTaxDifferenceQuarterly = (
    <CurrencyFormatLabel
      value={
        dollarsToCurrency(taxCalculationOutput.data.federalTaxOutstanding)
          .subtract(taxCalculationOutput.data.selfEmploymentTax)
          .divide(4).value
      }
    />
  )
  const federalQuartered = (
    <CurrencyFormatLabel
      value={
        dollarsToCurrency(
          taxCalculationOutput.data.federalTaxOutstanding
        ).divide(4).value
      }
    />
  )
  const stateQuarterly = (
    <CurrencyFormatLabel
      value={
        dollarsToCurrency(taxCalculationOutput.data.stateIncomeTax).divide(4)
          .value
      }
    />
  )

  let totalFederalPaymentsCentsCurrency = dollarsToCurrency(0)
  paidEstimatesAtQ4Calculation
    ?.filter((e) => e.type === 'federal')
    .forEach((paidFederalEstimate) => {
      if (paidFederalEstimate.amountPaidInCents) {
        totalFederalPaymentsCentsCurrency =
          totalFederalPaymentsCentsCurrency.add(
            centsToDollars(paidFederalEstimate.amountPaidInCents)
          )
      }
    })

  const federalAnnualSETaxPaymentsAdjusted = (
    <CurrencyFormatLabel
      value={
        dollarsToCurrency(taxCalculationOutput.data.federalTaxOutstanding)
          .subtract(taxCalculationOutput.data.selfEmploymentTax)
          .subtract(totalFederalPaymentsCentsCurrency.value).value
      }
    />
  )

  const federalQuarteredPaymentAdjusted = (
    <CurrencyFormatLabel
      value={
        dollarsToCurrency(
          taxCalculationOutput.data.federalTaxOutstanding
        ).subtract(totalFederalPaymentsCentsCurrency).value
      }
    />
  )

  const stateAnnualPaymentsDeducted = (
    <CurrencyFormatLabel value={stateAnnualPaymentsDeductedCurrency.value} />
  )

  const renderPaidEstimateAmount = (e: UserTaxEstimate) => {
    const { quarter } = splitTaxYearQuarter(e.taxQuarter)

    return quarter === '4' && isNil(e.amountPaidInCents) ? null : (
      <List.Item style={{ textAlign: 'right', fontSize: '90%' }}>
        —{formatCurrencyFromCents(e.amountPaidInCents)}
      </List.Item>
    )
  }

  const renderPaidEstimateTaxQuarter = (e: UserTaxEstimate) => {
    const { quarter, year } = splitTaxYearQuarter(e.taxQuarter)

    return quarter === '4' && isNil(e.amountPaidInCents) ? null : (
      <List.Item style={{ textAlign: 'right', fontSize: '90%' }}>
        Q{`${quarter} ${year}`}
      </List.Item>
    )
  }

  const paidFederalEstimatesForYear = paidEstimatesAtQ4Calculation?.filter(
    (e) => e.type === 'federal'
  )
  const paidStateEstimatesForYear = paidEstimatesAtQ4Calculation?.filter(
    (e) => e.type === 'state'
  )

  return (
    <List style={style}>
      <List.Item>
        <List.Content verticalAlign="middle" floated="right">
          {isFederal ? federalAnnual : stateAnnual}
        </List.Content>
        <List.Header style={{ fontWeight: 'normal' }} verticalAlign="middle">
          {isFederal
            ? 'Federal Annual'
            : `${stateQuarterlyEstimates[0].state} Annual`}
        </List.Header>
      </List.Item>
      {isFederal && shouldDeductSETax && (
        <>
          <List.Item style={{ fontStyle: 'italic' }}>
            <List.Content verticalAlign="middle" floated="right">
              —{seTax}
            </List.Content>
            <List.Header
              style={{ fontWeight: 'normal' }}
              verticalAlign="middle"
            >
              <div>Subtract SE Tax</div>
            </List.Header>
          </List.Item>
          <Divider style={{ margin: '3px' }} />
          <List.Item>
            <List.Content floated="right">{seTaxDifference}</List.Content>
          </List.Item>
        </>
      )}
      {calendarQuarter !== '4' && (
        <>
          <List.Item style={{ fontStyle: 'italic' }}>
            <List.Content verticalAlign="middle" floated="right">
              ÷ 4
            </List.Content>
            <List.Header
              style={{ fontWeight: 'normal' }}
              verticalAlign="middle"
            >
              Convert to Quarterly
            </List.Header>
          </List.Item>
          <Divider />
          <List.Item>
            <List.Content floated="right">
              {isFederal && shouldDeductSETax
                ? seTaxDifferenceQuarterly
                : isFederal && !shouldDeductSETax
                  ? federalQuartered
                  : stateQuarterly}
            </List.Content>
          </List.Item>
        </>
      )}
      {calendarQuarter === '4' && (
        <>
          <List.Item style={{ fontStyle: 'italic' }}>
            <List.Header
              style={{ fontWeight: 'normal' }}
              verticalAlign="middle"
            >
              Subtract estimates marked paid (
              <span style={{ fontSize: '90%', fontWeight: 'bold' }}>
                as of{' '}
                {moment
                  .utc(activeQuarterlyDetail?.calculationStartsAt)
                  .format(DATE_FORMATS.DISPLAY_SHORT)}
                )
              </span>
            </List.Header>
            <List.Content floated="left">
              {isFederal &&
                (!paidFederalEstimatesForYear ||
                  paidFederalEstimatesForYear?.length === 0) && (
                  <NoQTEPaymentsMade />
                )}
              {!isFederal &&
                (!paidStateEstimatesForYear ||
                  paidStateEstimatesForYear?.length === 0) && (
                  <NoQTEPaymentsMade />
                )}
              {isFederal
                ? paidFederalEstimatesForYear?.map(renderPaidEstimateTaxQuarter)
                : paidStateEstimatesForYear?.map(renderPaidEstimateTaxQuarter)}
            </List.Content>
            <List.Content verticalAlign="middle" floated="right">
              {isFederal
                ? paidFederalEstimatesForYear?.map(renderPaidEstimateAmount)
                : paidStateEstimatesForYear?.map(renderPaidEstimateAmount)}
            </List.Content>
          </List.Item>
          <Divider />
          <List.Item>
            <List.Content floated="right">
              {isFederal &&
                shouldDeductSETax &&
                federalAnnualSETaxPaymentsAdjusted}
              {isFederal &&
                !shouldDeductSETax &&
                federalQuarteredPaymentAdjusted}

              {!isFederal && stateAnnualPaymentsDeducted}
            </List.Content>
          </List.Item>
        </>
      )}
      <Divider />
      <List.Item>
        <List.Content
          style={{ fontWeight: 'bold' }}
          verticalAlign="middle"
          floated="right"
        >
          <CurrencyFormatLabel
            value={
              isFederal
                ? centsToDollars(federalQuarterlyEstimateInCents)
                : stateQuarterlyEstimates[0].value
            }
          />
        </List.Content>
        <List.Header verticalAlign="middle">
          {isFederal ? 'Federal' : 'State'} Quarterly Adjusted Estimate
        </List.Header>
        {calculatedByUserId && (
          <Message
            size="tiny"
            color="teal"
            content="The final estimates have been overwritten. The above math may no longer be accurate."
          />
        )}
      </List.Item>
    </List>
  )
}

export default AdjustmentsPopup
