import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { useState } from 'react'

import {
  FormikCheckbox,
  FormikDateInput,
  FormikDropdown,
  FormikInput,
  FormikLocationSearchInput,
  getFieldName,
  makeDateSchema,
  Button,
  Text,
  FormikRadioButton,
} from '../components/BaseComponents'

const validationSchema = yup.object({
  textInput: yup.string().required('Text input is required'),
  dropdownValue: yup.string().required('Dropdown value is required'),
  checked: yup.boolean().isTrue(),
  date: makeDateSchema({ field: 'date' }),
  location: yup.string().required('Radio selection is required'),
})

const SampleForm = () => {
  const [saved, setSaved] = useState(false)

  const formik = useFormik({
    initialValues: {
      textInput: '',
      dropdownValue: '',
      checked: false,
      date: '',
      radioSelection: '',
      location: '',
    },
    validationSchema,
    onSubmit: () => setSaved(true),
  })

  return (
    <FormikProvider value={formik}>
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <Text as="h1">Sample Form</Text>
          </Grid.Column>
          {saved && (
            <Grid.Column width={4}>
              <Text as="h1" color="lightRed">
                Saved!
              </Text>
            </Grid.Column>
          )}
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <FormikInput
              name={getFieldName<typeof formik.values>('textInput')}
              label="Text Input"
              leftIcon="dollar"
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <FormikDropdown
              name={getFieldName<typeof formik.values>('dropdownValue')}
              label="Text Input"
              optionValues={['1', '2', '3', '4']}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <FormikCheckbox
              name={getFieldName<typeof formik.values>('checked')}
              label="Checkbox"
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={4}>
            <FormikDateInput
              name={getFieldName<typeof formik.values>('date')}
              label="Date"
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={4}>
            <Text>Radio Selection</Text>
          </Grid.Column>
          <Grid.Column width={2}>
            <FormikRadioButton
              name={getFieldName<typeof formik.values>('radioSelection')}
              value="option1"
              label="Option 1"
            />
          </Grid.Column>
          <Grid.Column width={2}>
            <FormikRadioButton
              name={getFieldName<typeof formik.values>('radioSelection')}
              value="option2"
              label="Option 2"
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <FormikLocationSearchInput
              label="Location"
              name={getFieldName<typeof formik.values>('location')}
              fullWidth
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={3}>
            <Button fullWidth onClick={formik.submitForm}>
              Submit
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </FormikProvider>
  )
}

export default SampleForm
