import { useMemo } from 'react'
import { Grid } from 'semantic-ui-react'
import { cloneDeep } from 'lodash'

import {
  Button,
  Dropdown,
  GridRowColumn,
  Input,
  Label,
} from '../../../BaseComponents'
import { PercentageIncomePerState } from '../../../../reducers/auth/userReducer'
import { UpdateStateType } from './TaxProfileForm'
import {
  databaseColumns as KEYS,
  generateUSAStateOptions,
  getUnusedOptions,
} from '../../../../features/Taxes/taxConstants'

interface RowProps {
  data: PercentageIncomePerState
  required: boolean
  onDeleteClicked: () => void
  onUSAStateChange: (_usaState: string) => void
  onPercentageChange: (_percentage: string) => void
  usaStateOptions: Array<{
    value: string
    text: string
  }>
  disabled: boolean
}

const Row = ({
  data,
  required,
  onDeleteClicked,
  onUSAStateChange,
  onPercentageChange,
  usaStateOptions,
  disabled,
}: RowProps) => (
  <Grid>
    <Grid.Row>
      <Grid.Column width={7}>
        <Dropdown
          label="States your clients live in"
          options={usaStateOptions}
          search
          disabled={disabled}
          value={data?.state}
          placeholder="State your client(s) live in"
          onChange={onUSAStateChange}
          fullWidth
        />
      </Grid.Column>
      <Grid.Column width={7}>
        <Input
          label="% of income made"
          componentType="currency"
          required
          allowDecimals={false}
          maxLength={3}
          disabled={disabled}
          prefix={undefined}
          suffix="%"
          allowNegativeValue={false}
          value={data?.value || ''}
          placeholder={
            data?.state
              ? `% of income made in ${data.state}`
              : 'Please enter the % income made'
          }
          onChange={onPercentageChange}
          fullWidth
        />
      </Grid.Column>
      <Grid.Column width={2}>
        <Button
          variant="secondary"
          style={{ display: required ? 'none' : 'block', marginTop: 30 }}
          icon="trash alternate outline"
          size="medium"
          onClick={onDeleteClicked}
        />
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

interface Props {
  rowData: PercentageIncomePerState[]
  updateState: (_props: UpdateStateType) => void
  error?: string
  disabled: boolean
}

const IncomeByState = ({ rowData, updateState, error, disabled }: Props) => {
  const usaStateOptions = useMemo(() => {
    const used = rowData?.map((data) => data.state || '') || []
    const all = generateUSAStateOptions()

    return getUnusedOptions({ used, all })
  }, [rowData])

  const createNewRow = () => {
    const clone = [...rowData]
    clone.push({})
    updateState({ key: KEYS.percentageIncomePerState, value: clone })
  }

  const deleteRow = (index: number) => {
    const clone = [...rowData]
    clone.splice(index, 1)
    updateState({ key: KEYS.percentageIncomePerState, value: clone })
  }

  const updateUSAState = (index: number, usaState: string) => {
    const clone = cloneDeep(rowData)
    clone[index].state = usaState
    updateState({ key: KEYS.percentageIncomePerState, value: clone })
  }

  const updatePercentageValue = (index: number, percentage: string) => {
    const clone = cloneDeep(rowData)
    clone[index].value = percentage
    updateState({ key: KEYS.percentageIncomePerState, value: clone })
  }

  return (
    <Grid>
      <GridRowColumn>
        {rowData.map((row, i) => (
          <Row
            data={row}
            disabled={disabled}
            key={`row-${row.state}`}
            required={i === 0}
            onDeleteClicked={() => deleteRow(i)}
            onUSAStateChange={(usaState) => updateUSAState(i, usaState)}
            onPercentageChange={(percentage) =>
              updatePercentageValue(i, percentage)
            }
            usaStateOptions={usaStateOptions}
          />
        ))}
        {error && <Label color="red">{error}</Label>}
      </GridRowColumn>
      <GridRowColumn>
        <Button
          content="+ Add another state"
          disabled={disabled}
          onClick={() => createNewRow()}
        />
      </GridRowColumn>
    </Grid>
  )
}

export default IncomeByState
