/*
  Summarizes all the allocation guides to date
*/
import { Fragment, useEffect } from 'react'

import CurrencyFormatLabel from '../shared/CurrencyFormatLabel'
import { fetchAllocationGuidesSummary } from '../../actions/allocationGuideActions'
import { formatCurrencyFromCents } from '../../utils/currencyHelpers'
import { useAppDispatch } from '../../utils/typeHelpers'
import { AllocationGuideSummary as Summary } from '../../reducers/finances/allocationGuidesReducer'
import { Alert, Card, Text } from '../BaseComponents'
import { useReselector } from '../../utils/sharedHooks'

const AllocationGuideSummary = ({ summaries }: { summaries?: Summary[] }) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!summaries) {
      fetchAllocationGuidesSummary()(dispatch)
    }
  }, [dispatch, summaries])

  let allocationGuideSummaries = useReselector(
    (state) => state.allocationGuides.summaries
  )

  if (summaries) {
    allocationGuideSummaries = summaries
  }

  const content = () => {
    if (allocationGuideSummaries.length === 0) {
      return (
        <Alert type="warn">
          No allocation guides have been processed yet! The next guide will be
          sent to you on the 15th.
        </Alert>
      )
    }

    return allocationGuideSummaries.map(
      ({ allocationRuleName, totalAmount }, idx) => (
        <Fragment key={`AllocationGuideSummary_${allocationRuleName}_${idx}`}>
          <br />
          <Text as="bodyLg">
            <b>{allocationRuleName}</b>
          </Text>
          <Text>
            <CurrencyFormatLabel value={formatCurrencyFromCents(totalAmount)} />
          </Text>
        </Fragment>
      )
    )
  }

  return (
    <Card type="subsection" backgroundColor="stone40">
      <Text as="h2" style={{ marginBottom: 4 }}>
        Year to Date Statistics*
      </Text>
      <Text as="bodyMd">
        <i>*Since enabling Heard Allocation Guide </i>
      </Text>
      {content()}
    </Card>
  )
}

export default AllocationGuideSummary
