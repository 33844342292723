import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { keyBy } from 'lodash'

import { fetchWrapper } from '../../../reducers/fetch'

export interface Tooltip {
  id: number
  internalName: string
  title: null | string
  body: string
  link: null | string

  // These won't come back on the subqueries
  createdAt?: null | string
  updatedAt?: null | string
}

interface AllToolTipsState {
  [key: number]: Tooltip
}

const allTooltipsSlice = createSlice({
  name: 'allTooltips',
  initialState: {} as AllToolTipsState,
  reducers: {
    setTooltips: (
      state,
      action: PayloadAction<{ [key: number]: Tooltip }>
    ) => ({ ...state, ...action.payload }),
  },
})

export const { setTooltips } = allTooltipsSlice.actions

export default allTooltipsSlice.reducer

const FETCH_TOOLTIPS_KEY = 'FETCH_TOOLTIPS_KEY'
export const fetchAllTooltips = () =>
  fetchWrapper({
    fetchKey: FETCH_TOOLTIPS_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<Tooltip[]>(
        '/finances/api/v1/admin/action_item/tooltips'
      )
      dispatch(setTooltips(keyBy(json.data, 'id')))
      return json.data
    },
  })
