import { Grid, Message } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { isNil } from 'lodash'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  createQAUser,
  CreateQAUserFormData,
  CREATE_QA_USER_KEY,
} from '../qaUsers.slice'
import {
  Button,
  FormikCheckbox,
  FormikDropdown,
  FormikInput,
  getFieldName,
  GridRowColumn,
  Link,
  makeReqStringSchema,
  Modal,
  Text,
  BorderedIcon,
  makeReqEmailSchema,
} from '../../../../components/BaseComponents'
import { Pathway } from '../qaFeatures.slice'
import { useReselector } from '../../../../utils/sharedHooks'
import { getFetchError, getIsFetching } from '../../../../reducers/fetch'
import { Colors } from '../../../../styles/theme'
import { TaxEntityTypeOptions, YesNoOptions } from '../../../Taxes/taxConstants'
import { useAppDispatch } from '../../../../utils/typeHelpers'

// https://www.arungudelli.com/tutorial/javascript/how-to-create-uuid-guid-in-javascript-with-examples
const createUUID = () =>
  'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
    const random = (Math.random() * 16) | 0,
      result = char === 'x' ? random : (random & 0x3) | 0x8
    return result.toString(16)
  })

const validationSchema = yup.object({
  pathwayIdentifier: makeReqStringSchema({ field: 'pathway' }),
  createNames: yup.boolean(),
  createEmail: yup.boolean(),
  firstName: yup
    .string()
    .when('createNames', ([createNames], schema) =>
      !createNames ? makeReqStringSchema({ field: 'firstName' }) : schema
    ),
  lastName: yup
    .string()
    .when('createNames', ([createNames], schema) =>
      !createNames ? makeReqStringSchema({ field: 'lastName' }) : schema
    ),
  activeState: makeReqStringSchema({ field: 'activeState' }),
  email: yup
    .string()
    .email()
    .when('createEmail', ([createEmail], schema) =>
      !createEmail ? makeReqEmailSchema({ field: 'email' }) : schema
    ),
  taxEntityType: yup.string().optional(),
  showOnboardingPage: yup.bool().optional(),
})

const NewUserModal = ({
  open,
  onClose,
  pathwayIdent,
  pathways,
}: {
  open: boolean
  onClose: () => void
  pathwayIdent: string
  pathways: Pathway[]
}) => {
  const dispatch = useAppDispatch()
  const loading = useReselector(getIsFetching, CREATE_QA_USER_KEY)
  const error = useReselector(getFetchError, CREATE_QA_USER_KEY)

  const formik = useFormik({
    initialValues: {
      createEmail: true,
      pathwayIdentifier: pathwayIdent || '',
      firstName: '',
      lastName: '',
      activeState: 'active',
      email: '',
      taxEntityType: undefined,
      createNames: true,
      showOnboardingPage: false,
    },
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (params: CreateQAUserFormData) => {
      if (formik.values.createNames) {
        params.firstName = undefined
        params.lastName = undefined
      }

      // If we're automatically creating an email address, we want undefined sent to the server
      if (formik.values.createEmail) {
        params.email = undefined
      } else {
        // Otherwise subaddress the email by adding a UUID
        const parts = formik.values.email?.split('@')
        if (parts && parts.length === 2) {
          const uuid = createUUID()
          params.email = `${parts[0]}+${uuid}@${parts[1]}`
        }
      }

      const user = await createQAUser(params)(dispatch)

      if (user) {
        onClose()
      }
    },
  })

  const pathwayOptions = pathways.map((pathway, index) => ({
    key: index,
    text: `${pathway.name} (${pathway.adminRoleName || 'Customer'})`,
    content: (
      <>
        <Text as="h3">
          {pathway.name} ({pathway.adminRoleName || 'Customer'})
        </Text>
        <Text color="darkGray">{pathway.description}</Text>
      </>
    ),
    value: pathway.identifier,
  }))

  const selectedPathway = pathways.find(
    (p) => p.identifier === formik.values.pathwayIdentifier
  )

  // If the selected pathway does not have an admin role specified, assume it is intended for customers
  const isCustomerPathway = isNil(selectedPathway?.adminRoleName)

  return (
    <FormikProvider value={formik}>
      <Modal open={open} closeIcon onClose={onClose} size="tiny">
        <Modal.Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <BorderedIcon
              icon={regular('user-astronaut')}
              wrapperColor="moss"
              color="forest"
              height={40}
            />
            <Text as="h2" style={{ marginLeft: 5 }}>
              New QA User
            </Text>
          </div>
        </Modal.Header>
        <Modal.Content scrolling>
          <Grid>
            {/* Pathway */}
            <GridRowColumn>
              <FormikDropdown
                label="Choose a pathway"
                required
                name={getFieldName<typeof formik.values>('pathwayIdentifier')}
                options={pathwayOptions}
                fullWidth
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikCheckbox
                toggle
                style={{ verticalAlign: 'middle' }}
                label={
                  <Text as="bodyLg">
                    Create random first &amp; last names for me
                  </Text>
                }
                name={getFieldName<typeof formik.values>('createNames')}
              />
            </GridRowColumn>
            {!formik.values.createNames && (
              <Grid.Row>
                <Grid.Column width={8}>
                  <FormikInput
                    label="First Name"
                    required
                    name={getFieldName<typeof formik.values>('firstName')}
                    placeholder="Add a first name"
                    fullWidth
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <FormikInput
                    label="Last Name"
                    required
                    name={getFieldName<typeof formik.values>('lastName')}
                    placeholder="Add a last name"
                    fullWidth
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            <GridRowColumn>
              <FormikCheckbox
                toggle
                label={
                  <Text as="bodyLg">Create a random email address for me</Text>
                }
                name={getFieldName<typeof formik.values>('createEmail')}
              />
            </GridRowColumn>
            {!formik.values.createEmail && (
              <GridRowColumn>
                <Text as="h3">
                  Email Address<span style={{ color: Colors.red }}>*</span>
                </Text>
                <Text style={{ padding: '8px 0' }} as="bodySm">
                  A valid address is required to receive emails. Note - A unique
                  string will be appended, e.g.
                  {
                    <Link
                      style={{ paddingLeft: 3, fontSize: '1em' }}
                      href="mailto:you+491ae9ee3@joinheard.com"
                    >
                      you+491ae9ee3@joinheard.com
                    </Link>
                  }
                </Text>
                <FormikInput
                  fullWidth
                  name={getFieldName<typeof formik.values>('email')}
                />
              </GridRowColumn>
            )}
            {isCustomerPathway && (
              <>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <FormikDropdown
                      label="User's state"
                      required
                      name={getFieldName<typeof formik.values>('activeState')}
                      options={[
                        { key: 'active', text: 'Active', value: 'active' },
                        {
                          key: 'onboarding',
                          text: 'Signup',
                          value: 'onboarding',
                        },
                      ]}
                      fullWidth
                    />
                  </Grid.Column>
                  {isCustomerPathway &&
                    formik.values.activeState === 'active' && (
                      <Grid.Column width={8}>
                        <FormikDropdown
                          required
                          label="Show Onboarding?"
                          name={getFieldName<typeof formik.values>(
                            'showOnboardingPage'
                          )}
                          options={YesNoOptions}
                          fullWidth
                        />
                      </Grid.Column>
                    )}
                </Grid.Row>
                <GridRowColumn>
                  <Text as="h3">Tax Entity Type (optional)</Text>
                  <Text style={{ padding: '8px 0' }} as="bodySm">
                    Might be overwritten if this pathway requires a specific tax
                    entity type
                  </Text>
                  <FormikDropdown
                    name={getFieldName<typeof formik.values>('taxEntityType')}
                    options={TaxEntityTypeOptions}
                    fullWidth
                  />
                </GridRowColumn>
              </>
            )}
          </Grid>
          {error && (
            <Message
              error
              header="Something went wrong"
              content="There was an error saving the user. Please try again"
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            variant="actionLink"
            style={{ marginRight: 30 }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            onClick={formik.submitForm}
            disabled={!formik.isValid || loading}
            loading={loading}
          >
            Create
          </Button>
        </Modal.Actions>
      </Modal>
    </FormikProvider>
  )
}

export default NewUserModal
