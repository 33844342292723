import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface FinancialInsight {
  id: number
  title: string
  description: string
  active: boolean
  month: string
  userId: number
  createdAt: string
}

export interface FinancialInsightsState {
  [key: number]: FinancialInsight
}

const financialInsightsSlice = createSlice({
  name: 'financialInsights',
  initialState: {} as FinancialInsightsState,
  reducers: {
    receiveFinancialInsights: (
      state,
      action: PayloadAction<FinancialInsightsState>
    ) => ({
      ...state,
      ...action.payload,
    }),
    receiveSingleFinancialInsight: (
      state,
      action: PayloadAction<FinancialInsight>
    ) => {
      state[action.payload.id] = action.payload
    },
  },
})

export default financialInsightsSlice.reducer

export const { receiveFinancialInsights, receiveSingleFinancialInsight } =
  financialInsightsSlice.actions
