import { useCallback, useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { FederalIncomeTaxDetailsModal } from './components/FederalIncomeTaxDetailsModal'
import {
  Table,
  Text,
  Button,
  Card,
  GridRowColumn,
  Popup,
} from '../../../../../components/BaseComponents'
import {
  FederalIncomeTaxEstimateDetails,
  getFederalDetails,
} from './federalIncomeTaxDetailsActions'
import moment from 'moment'
import { DATE_FORMATS } from '../../../../../utils/dateHelpers'
import { useAppDispatch } from '../../../../../utils/typeHelpers'

export const FederalIncomeTaxDetailsPanel = () => {
  const dispatch = useAppDispatch()
  const [
    federalIncomeTaxDetailsModalOpen,
    setFederalIncomeTaxDetailsModalOpen,
  ] = useState(false)

  const [taxDetails, setTaxDetails] = useState<
    FederalIncomeTaxEstimateDetails[]
  >([])
  useEffect(() => {
    const fetch = async () => {
      const res = await getFederalDetails()(dispatch)
      if (res) {
        setTaxDetails(res)
      }
    }
    fetch()
  }, [dispatch])

  const onClose = useCallback(
    (newDetails?: FederalIncomeTaxEstimateDetails) => {
      setFederalIncomeTaxDetailsModalOpen(false)
      if (newDetails) {
        setTaxDetails((prevDetails) => [...prevDetails, newDetails])
      }
    },
    []
  )

  return (
    <Card>
      <Grid>
        <GridRowColumn>
          <Text as="h3">Federal Income Tax Details</Text>
        </GridRowColumn>
        <GridRowColumn>
          <Button onClick={() => setFederalIncomeTaxDetailsModalOpen(true)}>
            Add New Federal Income Tax Details
          </Button>
        </GridRowColumn>
        <GridRowColumn>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>id</Table.HeaderCell>
                <Table.HeaderCell>Effective From</Table.HeaderCell>
                <Table.HeaderCell>Current</Table.HeaderCell>
                <Table.HeaderCell>Standard Deduction</Table.HeaderCell>
                <Table.HeaderCell>
                  Dependent Deduction in Cents
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Deductible Self Employment Rate
                </Table.HeaderCell>
                <Table.HeaderCell>
                  QBI Deduction Income Limit in Cents
                </Table.HeaderCell>
                <Table.HeaderCell>QBI Deduction Rate</Table.HeaderCell>
                <Table.HeaderCell>
                  Self Employment Reduction Rate
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Social Security Tax Max Income in Cents
                </Table.HeaderCell>
                <Table.HeaderCell>Social Security Tax Rate</Table.HeaderCell>
                <Table.HeaderCell>Medicare Tax Rate</Table.HeaderCell>
                <Table.HeaderCell>Tax Rate Brackets</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {taxDetails?.map((detail) => (
              <Table.Row key={detail.id}>
                <Table.Cell>{detail.id}</Table.Cell>
                <Table.Cell>
                  {moment
                    .utc(detail.effectiveFrom)
                    .format(DATE_FORMATS.DISPLAY_SHORT)}
                </Table.Cell>
                <Table.Cell>{detail.current && 'true'}</Table.Cell>
                <Table.Cell>
                  {JSON.stringify(detail.standardDeduction, null, 2)}
                </Table.Cell>
                <Table.Cell>{detail.dependentDeductionInCents}</Table.Cell>
                <Table.Cell>{detail.deductibleSelfEmploymentRate}</Table.Cell>
                <Table.Cell>{detail.qbiDeductionIncomeLimitInCents}</Table.Cell>
                <Table.Cell>{detail.qbiDeductionRate}</Table.Cell>
                <Table.Cell>{detail.selfEmploymentReductionRate}</Table.Cell>
                <Table.Cell>
                  {detail.socialSecurityTaxMaxIncomeInCents}
                </Table.Cell>
                <Table.Cell>{detail.socialSecurityTaxRate}</Table.Cell>
                <Table.Cell>{detail.medicareTaxRate}</Table.Cell>
                <Table.Cell>
                  <Popup
                    content={JSON.stringify(detail.taxRateBrackets, null, 2)}
                    trigger="Hover to see"
                    position="bottom right"
                    wide="very"
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table>
        </GridRowColumn>
        <FederalIncomeTaxDetailsModal
          modalOpen={federalIncomeTaxDetailsModalOpen}
          onClose={onClose}
        />
      </Grid>
    </Card>
  )
}

export default FederalIncomeTaxDetailsPanel
