import { Divider, Grid } from 'semantic-ui-react'
import {
  Card,
  GridRowColumn,
  Text,
} from '../../../../../components/BaseComponents'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  getEstimatesByYear,
  getUnpaidTaxEstimatesByYear,
} from '../../../QuarterlyTaxEstimates/userTaxEstimates.selector'
import { groupBy, isEmpty } from 'lodash'
import { useMemo } from 'react'
import { Form1040DetailsProps } from '../../TaxChecklist/Form1040/Details'
import EstimateCard from '../../TaxChecklist/Form1040/AddMissingQTEPayments/EstimateCard'
import moment from 'moment'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import { makeGridConfig } from '../../../../../components/BaseComponents/Grid'
import { TaxListQuestionId } from '../../TaxChecklist/service'
import { usePersonalTaxEstimateQuestions } from '../SharedHooks'

export const estimatedTaxQuestionIds = [
  TaxListQuestionId.household_income_in_cents,
  TaxListQuestionId.household_income_withholdings_in_cents,
  TaxListQuestionId.previous_year_personal_tax_return,
]

export const EstimateTaxes = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
  taxYear,
}: Form1040DetailsProps) => {
  const {
    personalTaxEstimateContent,
    personalFormik,
    isPrevYear1040Uploaded,
    govIds,
    spouseGovIds,
    editPersonalIncome,
    needsSpouseInfo,
  } = usePersonalTaxEstimateQuestions({
    goBack,
    goToNextStep,
    previousScreen,
    nextScreen,
    taxYear,
  })
  const { isValid, isSubmitting } = personalFormik

  const continueSurvey = useAsyncCallback(() => {
    if (personalFormik.isValid) {
      return goToNextStep(null, nextScreen ?? null)
    }
    return Promise.resolve(false)
  })
  return (
    <Grid>
      <GridRowColumn {...makeGridConfig([12, 14], true)}>
        <Text as="display2" textAlign="center">
          Estimate Taxes
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([8, 14], true)}>
        <Text>
          Please look over the following and update anything that has changed.
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([8, 14], true)}>
        {personalTaxEstimateContent}
      </GridRowColumn>
      <FormFlowFooter
        continueDisabled={
          !isValid ||
          isSubmitting ||
          !isPrevYear1040Uploaded ||
          continueSurvey.loading ||
          editPersonalIncome ||
          isEmpty(govIds) ||
          (needsSpouseInfo && isEmpty(spouseGovIds))
        }
        loading={continueSurvey.loading}
        onForward={continueSurvey.callback}
        onBack={() => goBack(previousScreen ?? null)}
      />
    </Grid>
  )
}

export const EstimateTaxesQuarterPayments = ({
  goBack,
  previousScreen,
  goToNextStep,
  nextScreen,
}: Form1040DetailsProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const estimates = useReselector(getEstimatesByYear, taxYear)
  const groupedEstimates = useMemo(
    () => Object.entries(groupBy(estimates, (estimate) => estimate.taxQuarter)),
    [estimates]
  )
  const unpaidEstimates = useReselector(getUnpaidTaxEstimatesByYear, taxYear)
  return (
    <Grid>
      <GridRowColumn {...makeGridConfig([10, 14], true)}>
        <Text as="display2" textAlign="center">
          Quarterly Tax Payments
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([10, 14], true)}>
        <Text>
          Your tax preparer will need the following in order to provide an
          estimated tax payment amount for {taxYear}.
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([10, 14], true)}>
        <Card backgroundColor="stone40">
          <Grid>
            <GridRowColumn>
              <Text as="h2">Quarterly Tax Payments</Text>
              <Divider />
            </GridRowColumn>
            {groupedEstimates.map(([key, estimates]) => (
              <GridRowColumn key={key} centerContent>
                <div style={{ width: '100%' }}>
                  <Text as="h2">
                    {moment(key, 'YYYY-Q').format('[Q]Q YYYY')}
                  </Text>
                  {estimates.map((estimate) => (
                    <EstimateCard key={estimate.id} estimate={estimate} />
                  ))}
                </div>
              </GridRowColumn>
            ))}
          </Grid>
        </Card>
      </GridRowColumn>
      <FormFlowFooter
        continueDisabled={unpaidEstimates.length > 0}
        onForward={() => goToNextStep(null, nextScreen ?? null)}
        onBack={() => goBack(previousScreen ?? null)}
      />
    </Grid>
  )
}

export default EstimateTaxes
