import { useState } from 'react'

//BL
import { ManualTransactionChildProps } from './ManualTransactionActions'
import EditTransactionForm, {
  EditTransactionFormFields,
} from './EditTransactionForm'

//UI
import { Button, Modal } from 'semantic-ui-react'
import { parseErrorFromCatch } from '../../../utils/errorHelpers'
import { bulkUpdateUserTransactions } from '../../../actions/adminActions'
import { useAppDispatch } from '../../../utils/typeHelpers'

export const useEditManualTransaction = ({
  transaction,
  onChildBeginLoading,
  onChildEndLoading,
  disabled,
  onUpdated = () => undefined,
}: ManualTransactionChildProps) => {
  const [open, setOpen] = useState(false)
  const dispatch = useAppDispatch()

  const closeModal = () => setOpen(false)

  const onFormSubmit = async (data: EditTransactionFormFields) => {
    onChildBeginLoading()
    await performSave(data)
    onChildEndLoading()
  }

  function handleError(err: string) {
    alert(err)
  }

  const performSave = async ({
    id,
    amountInCents,
    description,
    date,
  }: EditTransactionFormFields) => {
    try {
      const data = {
        transactionIds: [id],
        amountInCents,
        description,
        date,
      }
      await bulkUpdateUserTransactions(data)(dispatch)
      onUpdated()
      closeModal()
    } catch (err) {
      handleError(parseErrorFromCatch(err))
    }
  }

  const renderModal = () => (
    <Modal closeIcon open={open} onClose={closeModal} size="small">
      <Modal.Header>
        Edit manually created transaction {transaction.description}
      </Modal.Header>
      <Modal.Content>
        <EditTransactionForm
          loading={disabled}
          onSubmit={onFormSubmit}
          transaction={transaction}
        />
      </Modal.Content>
    </Modal>
  )

  return {
    openModal: () => setOpen(true),
    renderModal,
  }
}

const EditTransactionModule = (props: ManualTransactionChildProps) => {
  const { openModal, renderModal } = useEditManualTransaction(props)

  return (
    <>
      <Button
        disabled={props.disabled}
        onClick={openModal}
        title="Edit manually created transaction"
        basic
        icon="edit"
      />
      {renderModal()}
    </>
  )
}

export default EditTransactionModule
