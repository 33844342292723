import { ProgressBar, ProgressBarNoTitles } from '../components/BaseComponents'

export const ProgressBarExample = () => (
  <ProgressBar
    steps={['Step One', 'Step Two', 'Step Three', 'Step Four']}
    currentStep={2}
  />
)

export const ProgressBarNoTitlesExample = () => (
  <ProgressBarNoTitles totalSteps={4} currentStep={2} />
)
