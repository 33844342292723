import { Component, createRef, RefObject } from 'react'
import { connect } from 'react-redux'
import currency from 'currency.js'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { filterNulls, ReduxState } from '../../../../utils/typeHelpers'
import {
  calculateHourlyRate,
  getShortPayUnit,
  PayrollComps,
  sortHourComps,
} from '../../helpers'
import {
  selectEmployeeByUuid,
  selectJobByEmployeeUuid,
  selectPayrollByUuid,
  selectPaySchedule,
} from '../../payroll.selectors'
import HourlyCell from './HourlyCell'
import FixedCell from './FixedCell'
import AdditionalEarnings from './AdditionalEarnings'
import { formatCurrency } from '../../../../utils/currencyHelpers'
import { FlsaStatus } from '../../employeeJob.slice'
import {
  Popup,
  Table,
  Text,
  Button,
  Icon,
} from '../../../../components/BaseComponents'
import { AddButton } from './PayrollInputButtons'
import {
  Gusto_EmployeePayrollCompensation,
  Gusto_UpdatePayroll,
} from '../../generated_gusto_types'

type StateProps = ReturnType<typeof mapStateToProps>
interface ParentProps {
  comp: Gusto_EmployeePayrollCompensation
  payrollUuid: string
}
type Props = StateProps & ParentProps

interface State {
  gross: number
  excluded: boolean
}

class PayrollInputRow extends Component<Props, State> {
  hourlyRefs: RefObject<HourlyCell>[]
  remiRef: RefObject<FixedCell>
  additionalEarnRef: RefObject<AdditionalEarnings>

  constructor(props: Props) {
    super(props)
    this.hourlyRefs = props.comp.hourly_compensations.map(() =>
      createRef<HourlyCell>()
    )
    this.remiRef = createRef<FixedCell>()
    this.additionalEarnRef = createRef<AdditionalEarnings>()
    this.state = {
      gross: 0,
      excluded: props.comp.excluded,
    }
  }

  // Job details can come in late and gross must be recalculated when this happens
  componentDidUpdate(prevProps: Props) {
    if (
      this.props.job?.compensations[0]?.rate !==
      prevProps.job?.compensations[0]?.rate
    ) {
      this.calculateGross()
    }
  }

  componentDidMount() {
    document.addEventListener(
      `inputChange${this.props.comp.employee_uuid}`,
      this.calculateGross
    )
    this.calculateGross()
  }

  componentWillUnmount() {
    document.removeEventListener(
      `inputChange${this.props.comp.employee_uuid}`,
      this.calculateGross
    )
  }

  calculate = (): Gusto_UpdatePayroll['employee_compensations'][number] => {
    const {
      comp: { employee_uuid, paid_time_off, version },
    } = this.props
    const { excluded } = this.state

    // Gather the compensations via the refs
    const hourly_compensations = filterNulls(
      this.hourlyRefs.map((ref) => ref.current?.calculate())
    )

    const fixed_compensations = filterNulls([
      ...(this.additionalEarnRef.current?.calculate() || []),
      this.remiRef.current?.calculate(),
    ])

    return {
      version,
      employee_uuid,
      payment_method: 'Direct Deposit',
      paid_time_off,
      fixed_compensations,
      hourly_compensations,
      excluded,
      memo: null,
    }
  }

  calculateGross = () => {
    const { job, paySchedule } = this.props
    const totals = this.calculate()
    const rate = job?.compensations[0]?.rate

    if (!rate || !paySchedule) {
      return
    }

    let gross = currency(0)

    for (const fixed of totals.hourly_compensations) {
      gross = gross.add(
        // This multiplication should NOT use currency calculations to match Gusto's implementation
        currency(
          calculateHourlyRate(job, paySchedule.frequency) *
            Number(fixed.hours) *
            fixed.compensation_multiplier
        )
      )
    }

    for (const fixed of totals.fixed_compensations) {
      gross = gross.add(fixed.amount)
    }

    this.setState({ gross: gross.value })
  }

  renderInputs() {
    const { comp, job, employee, payroll } = this.props
    const isOffCycle = Boolean(payroll?.off_cycle)

    if (!employee) {
      return null
    }

    if (this.state.excluded) {
      return (
        <Table.Cell colSpan={3}>
          <div className="skip-payroll">
            <Text>
              You&apos;ve chosen to skip this payroll cycle for this employee.
            </Text>
            <AddButton
              onClick={() => this.setState({ excluded: false })}
              text="Re-add them here"
            />
          </div>
        </Table.Cell>
      )
    }

    // Reimbursement is split out into a different column than other fixed compensations
    const reim = comp.fixed_compensations.find(
      (fixedComp) => fixedComp.name === PayrollComps.reimbursement
    ) || {
      name: PayrollComps.reimbursement,
      amount: '0.00',
      job_uuid: this.props.job?.uuid,
    }

    const isSalary = job?.compensations[0]?.flsa_status !== FlsaStatus.Nonexempt
    // Hourly comps cannot be edited for salaried employees in off cycle payrolls
    const showHourly = !isSalary || !isOffCycle

    return (
      <>
        <Table.Cell>
          {sortHourComps(comp.hourly_compensations).map((hourlyComp, index) => (
            <HourlyCell
              key={`${comp.employee_uuid}${hourlyComp.name}${hourlyComp.job_uuid}`}
              employeeUuid={comp.employee_uuid}
              hourlyComp={hourlyComp}
              ref={this.hourlyRefs[index]}
              shown={showHourly}
            />
          ))}
          {isSalary && <Text>Salaried Employee</Text>}
        </Table.Cell>
        <Table.Cell>
          <AdditionalEarnings
            employeeUuid={employee.uuid}
            fixedComps={comp.fixed_compensations}
            ref={this.additionalEarnRef}
            isOffCycle={isOffCycle}
          />
        </Table.Cell>
        <Table.Cell>
          <b>{formatCurrency(this.state.gross)}</b>
          <br />
          <FixedCell
            key={`${comp.employee_uuid}${reim.name}${reim.job_uuid}`}
            employeeUuid={comp.employee_uuid}
            fixedComp={reim}
            ref={this.remiRef}
          />
        </Table.Cell>
      </>
    )
  }

  render() {
    const { employee, job } = this.props
    const { excluded } = this.state

    if (!employee || !job) {
      return null
    }

    return (
      <Table.Row>
        <Table.Cell style={{ paddingLeft: 10 }}>
          <b>
            {employee.first_name} {employee.last_name}
          </b>
          <br />
          {formatCurrency(job.rate)}/
          {job?.payment_unit && getShortPayUnit(job.payment_unit)}
        </Table.Cell>
        {this.renderInputs()}
        <Table.Cell>
          {!excluded && (
            <Popup
              pinned
              hoverable
              flowing
              style={{ padding: 0 }}
              basic
              content={
                <Button
                  onClick={() => this.setState({ excluded: true })}
                  style={{ margin: 0 }}
                  variant="secondary"
                >
                  Skip Payroll
                </Button>
              }
              trigger={
                <Icon icon={regular('ellipsis-v')} className="action-icon" />
              }
              position="bottom right"
            />
          )}
        </Table.Cell>
      </Table.Row>
    )
  }
}

const mapStateToProps = (state: ReduxState, props: ParentProps) => ({
  employee: selectEmployeeByUuid(state, props.comp.employee_uuid),
  job: selectJobByEmployeeUuid(state, props.comp.employee_uuid),
  paySchedule: selectPaySchedule(state),
  payroll: selectPayrollByUuid(state, props.payrollUuid),
})

export const ConnectedPayrollInputRow = connect<
  StateProps,
  null,
  ParentProps,
  ReduxState
>(mapStateToProps, null, null, { forwardRef: true })(PayrollInputRow)

export default PayrollInputRow
