import { useCallback, useEffect } from 'react'
import { Grid } from 'semantic-ui-react'

import { useReselector } from '../../../../utils/sharedHooks'
import { getAnnualTaxFilingForYearSelector } from '../annualTaxFilings.selector'
import {
  Accordion,
  Button,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import {
  TaxSeasonKickoffStepProps,
  TAX_SURVEY_CONTENT_SCREENS,
} from './AnnualTaxSurvey'
import FormFlowFooter from '../../../../components/FormFlow/FormFlowFooter'
import { needContractorFilingOption } from '../annualTaxFilings.slice'
import { useAnalyticsView } from '../../../Amplitude'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'

const ContractorNecPanel = ({
  year,
  goToNextStep,
  goBack,
}: TaxSeasonKickoffStepProps) => {
  const currentAnnualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    year
  )

  const screenName = 'TSK needs contractor 1099-NEC'
  const accordionTitle =
    'What is a 1099-NEC and why would I be required to file one?'

  const needNec = currentAnnualTaxFiling?.needContractorFiling
  const pageView = useAnalyticsView()
  useEffect(() => {
    pageView(screenName)
  }, [pageView])

  const saveAndContinue = useCallback(
    (needContractorFiling: needContractorFilingOption) => {
      goToNextStep(
        { needContractorFiling },
        TAX_SURVEY_CONTENT_SCREENS.importantDates
      )
    },
    [goToNextStep]
  )

  const justContinue = useCallback(() => {
    goToNextStep(null, TAX_SURVEY_CONTENT_SCREENS.importantDates)
  }, [goToNextStep])

  return (
    <Grid>
      <GridRowColumn {...makeGridConfig([10, 12], true)}>
        <Text as="display2" textAlign="center">
          Contractor Tax Payroll Form (1099-NEC)
        </Text>
      </GridRowColumn>

      <GridRowColumn {...makeGridConfig([10, 12], true)}>
        <Text as="bodyLg">
          If you’ve paid a contractor{' '}
          <b>at least $600 with cash, checks or bank transfers</b> in {year},
          Heard will file a 1099-NEC on your behalf.
        </Text>
      </GridRowColumn>
      <Grid.Row />

      <GridRowColumn {...makeGridConfig([6, 14], true)}>
        <Text as="h3">Do you need Heard to file a 1099-NEC for you?</Text>
      </GridRowColumn>

      <GridRowColumn {...makeGridConfig([6, 14], true)} short>
        <Button
          variant={
            needNec === needContractorFilingOption.yes ? 'primary' : 'secondary'
          }
          onClick={() => saveAndContinue(needContractorFilingOption.yes)}
          fullWidth
        >
          Yes
        </Button>
      </GridRowColumn>

      <GridRowColumn {...makeGridConfig([6, 14], true)} short>
        <Button
          variant={
            needNec === needContractorFilingOption.no ? 'primary' : 'secondary'
          }
          onClick={() => saveAndContinue(needContractorFilingOption.no)}
          fullWidth
        >
          No
        </Button>
      </GridRowColumn>

      <GridRowColumn {...makeGridConfig([6, 14], true)} short>
        <Button
          variant={
            needNec === needContractorFilingOption.unsure
              ? 'primary'
              : 'secondary'
          }
          onClick={() => saveAndContinue(needContractorFilingOption.unsure)}
          fullWidth
        >
          I&apos;m not sure
        </Button>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([6, 14], true)}>
        <Text as="h3">
          (It’s okay if you don’t know yet. We’ll check back with you later).
        </Text>
      </GridRowColumn>

      <Grid.Row />

      <GridRowColumn {...makeGridConfig([10, 14], true)}>
        <Accordion
          title={accordionTitle}
          content={
            <>
              <Text>
                If you’ve paid a contractor at least $600 with cash, checks or
                bank transfers in {year}, you’ll need to file a 1099-NEC so your
                contractor can use it to report their earnings to the IRS.
              </Text>
              <br />
              <Text>
                If you paid a contractor through a payment or credit card
                processor (i.e. Square, Stripe, Visa, Mastercard), the processor
                will file the 1099 for you.
              </Text>
              <br />
              <Text>
                If you paid a contractor through a payroll provider (i.e.
                Gusto), the provider will file the 1099 for you.
              </Text>
            </>
          }
          trackOnOpenClick={{
            screen: screenName,
            title: accordionTitle,
          }}
          variant="default"
        />
      </GridRowColumn>

      <FormFlowFooter
        onBack={() => goBack(TAX_SURVEY_CONTENT_SCREENS.formSelection)}
        onForward={justContinue}
        continueDisabled={needNec === undefined}
      />
    </Grid>
  )
}

export default ContractorNecPanel
