import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { UserTaxCalculation } from '../../Admin/UserTaxCalculations/userTaxCalculation.slice'
import {
  Alert,
  GridRowColumn,
  Icon,
  Link,
} from '../../../components/BaseComponents'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../OpenFeature'

export const getIsTaxCalcIncomeHigh = (taxCalc?: UserTaxCalculation | null) =>
  Boolean(
    taxCalc?.annualizedTotalWIncomeInCents &&
      taxCalc?.annualizedTotalWIncomeInCents > 40000000
  )

const HighEstimatesAlert = ({
  title,
  taxCalc,
  unsetMobileMargin,
}: {
  title: string
  taxCalc?: UserTaxCalculation | null
  unsetMobileMargin?: number
}) => {
  const isIncomeHigh = getIsTaxCalcIncomeHigh(taxCalc)

  const shouldEnableQ1QteFollowups = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.q1QteFollowups,
    false
  )

  if (!isIncomeHigh || !shouldEnableQ1QteFollowups) {
    return null
  }

  // todo this text doesn't work with q4
  return (
    <GridRowColumn>
      <Alert
        unsetMobileMargin={unsetMobileMargin}
        title={title}
        type="warn"
        customIcon={<Icon icon={regular('stars')} />}
      >
        If you’ve earned income outside of your practice, double-check that it’s
        entered as year-to-date (this is how much you’ve earned so far this
        year).{' '}
        <Link to="/taxes/quarterly/recalculate" as="secondaryLink">
          Make Adjustments
        </Link>
      </Alert>
    </GridRowColumn>
  )
}

export default HighEstimatesAlert
