import { useMemo, useState } from 'react'
import { Radio, Grid, Modal, Loader } from 'semantic-ui-react'
import { UserWithAdminInfo } from '../../../reducers/admin/v2/allUsersReducerV2'
import { useReselector } from '../../../utils/sharedHooks'
import { GridRowColumn, Button, Text, Input, Alert } from '../../BaseComponents'
import {
  getIsAdministrator,
  selectIsBookkeeper,
  selectIsAccountingOpsManager,
} from '../../../selectors/user.selectors'
import { useAppDispatch } from '../../../utils/typeHelpers'
import {
  adminUpdateUserCatchUpBookkeepingStatus,
  CatchUpBookkeepingStatus,
  CatchUpBookkeepingStatusType,
} from '../../../features/CatchUpBookkeepingStatus/catchUpBookkeepingStatus.slice'

const CatchUpBookkeepingToggleConfirmationModal = ({
  user,
  open,
  close,
  catchupBKIsComplete,
  catchupBookkeepingStatusUpdateCallback,
}: {
  open: boolean
  close: () => void
  user: Pick<UserWithAdminInfo, 'id' | 'firstName' | 'lastName'>
  catchupBKIsComplete: boolean
  catchupBookkeepingStatusUpdateCallback?: (
    data: CatchUpBookkeepingStatus
  ) => void
}) => {
  const dispatch = useAppDispatch()
  const [errorMessage, setErrorMessage] = useState('')
  const [confirmValue, setConfirmValue] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const lockedConfirmed = confirmValue === `${user.firstName} ${user.lastName}`
  const isAccountingOpsManager = useReselector(selectIsAccountingOpsManager)
  const submitButtonContent = useMemo(() => {
    if (!submitting) {
      return `Mark Books ${catchupBKIsComplete ? 'Incomplete' : 'Complete'}`
    } else {
      return <Loader active inline size="small" />
    }
  }, [submitting, catchupBKIsComplete])

  const modalContentAndActions = useMemo(() => {
    const submitCatchupBookkeepingStatusUpdate = async () => {
      const valueToSet = catchupBKIsComplete
        ? CatchUpBookkeepingStatusType.incomplete
        : CatchUpBookkeepingStatusType.complete
      setSubmitting(true)
      const result = await adminUpdateUserCatchUpBookkeepingStatus(
        user.id,
        valueToSet
      )(dispatch)
      if (result) {
        close()
        if (catchupBookkeepingStatusUpdateCallback) {
          catchupBookkeepingStatusUpdateCallback(result)
        }
      } else {
        setErrorMessage('Cannot update catch-up bookkeeping status for user')
      }
      setSubmitting(false)
    }

    // Display different content based on user role
    if (isAccountingOpsManager) {
      return (
        <>
          <Modal.Content>
            <Grid>
              <GridRowColumn>
                <Text as="h3">
                  You are currently setting the catch-up bookkeeping status to{' '}
                  <b>{catchupBKIsComplete ? 'incomplete' : 'complete'} </b>for
                  this user
                </Text>
                {/* Add consequence of change as part of CORE-363*/}
              </GridRowColumn>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <div className="flexbox">
              <Button variant="secondary" onClick={close}>
                Cancel
              </Button>
              <Button
                disabled={submitting}
                onClick={() => submitCatchupBookkeepingStatusUpdate()}
              >
                {submitButtonContent}
              </Button>
            </div>
          </Modal.Actions>
        </>
      )
    }
    return (
      <>
        <Modal.Content>
          {errorMessage && <Alert type="error">Error: {errorMessage}</Alert>}
          {catchupBKIsComplete ? (
            <Grid>
              <GridRowColumn>
                <Text as="h3">
                  Warning: This User&apos;s catch-up bookkeeping is marked
                  complete and locked. You cannot unlock it automatically.
                </Text>
                <br />
                <Text>
                  Please contact Accounting Ops if you need to set this
                  user&apos;s catch-up bookkeeping as incomplete.
                </Text>
              </GridRowColumn>
            </Grid>
          ) : (
            <Grid>
              <GridRowColumn>
                <Text as="h3">
                  Warning: You are currently marking catch-up bookkeeping as{' '}
                  <b>complete</b> for this user
                </Text>
                <br />
                {/* Add consequence of change as part of CORE-363*/}
                <Text>
                  Please ensure the User&#39;s books are truly complete before
                  toggling this. Once this is toggled and locked you will need
                  to contact Accounting Ops to get it unlocked.
                </Text>
              </GridRowColumn>
              <br />
              <GridRowColumn>
                <Input
                  description="This is case sensitive"
                  label="Type User's Name to Confirm Lock on This User's Books"
                  onChange={(value) => setConfirmValue(value)}
                  placeholder={`${user.firstName} ${user.lastName}`}
                  value={confirmValue}
                />
              </GridRowColumn>
            </Grid>
          )}
        </Modal.Content>
        <Modal.Actions>
          <div className="flexbox">
            <Button variant="secondary" onClick={close}>
              Cancel
            </Button>
            <Button
              disabled={!lockedConfirmed || catchupBKIsComplete || submitting}
              onClick={() => submitCatchupBookkeepingStatusUpdate()}
            >
              {submitButtonContent}
            </Button>
          </div>
        </Modal.Actions>
      </>
    )
  }, [
    catchupBKIsComplete,
    catchupBookkeepingStatusUpdateCallback,
    close,
    confirmValue,
    dispatch,
    errorMessage,
    isAccountingOpsManager,
    lockedConfirmed,
    submitButtonContent,
    submitting,
    user.firstName,
    user.id,
    user.lastName,
  ])

  return (
    <Modal size="small" open={open} onClose={close} closeIcon>
      <Modal.Header>
        Marking Catch-up Bookkeeping Status as{' '}
        {catchupBKIsComplete ? 'Incomplete' : 'Complete'} for {user.id}:{' '}
        {user.firstName} {user.lastName}
      </Modal.Header>
      {modalContentAndActions}
    </Modal>
  )
}

const CatchUpBookkeepingToggle = ({
  user,
  toggleUpdateCallback,
}: {
  user: UserWithAdminInfo
  toggleUpdateCallback?: (data: UserWithAdminInfo) => void
}) => {
  const isAdministrator = useReselector(getIsAdministrator)
  const isBookkeeper = useReselector(selectIsBookkeeper)
  const isAccountingOpsManager = useReselector(selectIsAccountingOpsManager)
  const catchUpBookkeepingIsComplete =
    user.catchUpBookkeepingStatus?.status ===
    CatchUpBookkeepingStatusType.complete
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false)

  const toggleCallback = (data: CatchUpBookkeepingStatus) => {
    if (toggleUpdateCallback) {
      // update the catchUpBookkeepingStatus on the user object
      const updatedUser = { ...user, catchUpBookkeepingStatus: data }
      toggleUpdateCallback(updatedUser)
    }
  }

  const toggleDisabled = useMemo(() => {
    return !(isBookkeeper || isAdministrator || isAccountingOpsManager)
  }, [isAccountingOpsManager, isBookkeeper, isAdministrator])

  return (
    <div style={{ position: 'relative' }}>
      <Radio
        toggle
        checked={catchUpBookkeepingIsComplete}
        onChange={() => setConfirmationModalIsOpen(true)}
        disabled={toggleDisabled}
      />
      {confirmationModalIsOpen && (
        <CatchUpBookkeepingToggleConfirmationModal
          user={user}
          open={confirmationModalIsOpen}
          close={() => setConfirmationModalIsOpen(false)}
          catchupBookkeepingStatusUpdateCallback={toggleCallback}
          catchupBKIsComplete={catchUpBookkeepingIsComplete}
        />
      )}
    </div>
  )
}

export default CatchUpBookkeepingToggle
