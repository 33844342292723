import { useNavigate } from 'react-router-dom'
import { formatPhoneNumber } from 'react-phone-number-input'
import { Grid } from 'semantic-ui-react'
import moment from 'moment'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Accordion, IconButton, Text } from '../../components/BaseComponents'
import PracticeProfileEntry from './PracticeProfileEntry'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { useReselector } from '../../utils/sharedHooks'
import { getCurrentUser } from '../../selectors/user.selectors'

const PersonalInformationSection = () => {
  const navigate = useNavigate()
  const user = useReselector(getCurrentUser)

  return (
    <Accordion
      initialOpen
      title={
        <>
          <Text as="h2">Personal Information</Text>
          <IconButton
            icon={regular('pen-to-square')}
            style={{ position: 'absolute', right: 55, zIndex: 1000 }}
            onClick={() => navigate('/practice/profile/editPersonal')}
          />
        </>
      }
      content={
        <Grid>
          <PracticeProfileEntry label="First Name" value={user?.firstName} />
          <PracticeProfileEntry label="Last Name" value={user?.lastName} />
          <PracticeProfileEntry
            label="Date of Birth"
            value={
              user?.dateOfBirth &&
              moment.utc(user?.dateOfBirth).format(DATE_FORMATS.DISPLAY_LONG)
            }
          />
          <PracticeProfileEntry
            label="Personal Email"
            value={user?.personalEmail}
          />
          <PracticeProfileEntry
            label="Personal Phone"
            value={
              user?.personalPhone && formatPhoneNumber(user?.personalPhone)
            }
          />
          <PracticeProfileEntry
            label="Personal Address"
            value={user?.homeAddress}
          />
        </Grid>
      }
    />
  )
}

export default PersonalInformationSection
