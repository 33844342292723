import { ReactNode, useMemo } from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import {
  Button,
  Card,
  GridRowColumn,
  Icon,
  Loader,
  Text,
} from '../../../../components/BaseComponents'
import { Colors } from '../../../../styles/theme'
import { DateTime } from 'luxon'
import { DATE_FORMATS_LUXON } from '../../../../utils/dateHelpers'
import { useNavigate } from 'react-router-dom'

const PromptIcon = styled(
  ({
    className,
    icon,
    isComplete,
  }: {
    className?: string
    icon: IconDefinition
    background?: string
    isComplete: boolean
  }) => (
    <div className={className}>
      <Icon
        color="white"
        icon={isComplete ? regular('check') : icon}
        style={{ height: 16, width: 24 }}
      />
    </div>
  )
)(({ isComplete, background }) => ({
  alignItems: 'center',
  backgroundColor: background || (isComplete ? Colors.green : Colors.black),
  borderRadius: 4,
  display: 'inline-flex',
  height: 24,
  justifyContent: 'center',
  marginRight: 8,
  width: 24,
}))

export enum PromptCardStatus {
  COMING_SOON = 'coming-soon',
  ACTION_REQUIRED = 'action-required',
  IN_PROGRESS = 'in-progress',
  DONE = 'done',
}

export const TaxPromptCardButton = ({
  status,
  actionLink,
}: {
  status: PromptCardStatus
  actionLink: string
}) => {
  const navigate = useNavigate()
  const buttonText = useMemo(() => {
    switch (status) {
      case PromptCardStatus.DONE:
        return 'Edit Answers'
      case PromptCardStatus.IN_PROGRESS:
        return 'Continue'
      case PromptCardStatus.ACTION_REQUIRED:
        return 'Get Started'
      default:
      case PromptCardStatus.COMING_SOON:
        return 'Coming Soon'
    }
  }, [status])

  return (
    <GridRowColumn centerContent>
      <Button
        disabled={status === PromptCardStatus.COMING_SOON}
        onClick={() => navigate(actionLink)}
        variant={status === PromptCardStatus.DONE ? 'secondary' : 'primary'}
      >
        {buttonText}
      </Button>
    </GridRowColumn>
  )
}

export const TaxPromptCardInfo = ({
  timeToComplete,
  docInfo,
  dueDate,
  startDate,
  extraListItem,
}: {
  timeToComplete: string
  docInfo: string
  dueDate?: DateTime | null
  startDate?: DateTime | null
  extraListItem?: ReactNode
}) => (
  <GridRowColumn centerContent spacer={3} width={10}>
    <Card type="subsection" backgroundColor="stone">
      <Text as="bodySm">
        <Icon icon={regular('timer')} style={{ marginRight: 15 }} size="1x" />
        This should take about {timeToComplete}
      </Text>
      <Text as="bodySm">
        <Icon icon={regular('files')} style={{ marginRight: 15 }} size="1x" />
        {docInfo}
      </Text>
      {dueDate && (
        <Text as="bodySm">
          <Icon
            icon={regular('flag-checkered')}
            style={{ marginRight: 15 }}
            size="1x"
          />
          Due {dueDate.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}
        </Text>
      )}
      {startDate && (
        <Text as="bodySm">
          <Icon
            icon={regular('hourglass-clock')}
            style={{ marginRight: 15 }}
            size="1x"
          />
          Available {startDate.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}
        </Text>
      )}
      {extraListItem}
    </Card>
  </GridRowColumn>
)

type Props = {
  body: { [key in PromptCardStatus]: ReactNode }
  icon: IconDefinition
  status: PromptCardStatus
  title: string
  loading?: boolean
}

const TaxCenterPromptCard = ({ body, icon, status, title, loading }: Props) => (
  <Card
    backgroundColor={
      [PromptCardStatus.COMING_SOON, PromptCardStatus.DONE].includes(status)
        ? 'natural'
        : 'stone40'
    }
    type="subsection"
  >
    <Loader loading={loading} />
    <Grid>
      <GridRowColumn
        columnStyle={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <PromptIcon icon={icon} isComplete={status === PromptCardStatus.DONE} />
        <Text as="h2">{title}</Text>
      </GridRowColumn>
      <GridRowColumn>{body[status]}</GridRowColumn>
    </Grid>
  </Card>
)

export default TaxCenterPromptCard
