import { useCallback, useState } from 'react'
import { saveAs } from 'file-saver'

import {
  downloadPayrollReport,
  fetchPayrollForUser,
} from '../../../features/Payroll/payrollActions'
import { useFetchResponse, useReselector } from '../../../utils/sharedHooks'
import { selectBookkeepingReportsById } from '../../../selectors/bookkeepingReportsSelectors'
import { getUserById } from '../../../selectors/user.selectors'
import { Button, Menu } from 'semantic-ui-react'
import { useAppDispatch } from '../../../utils/typeHelpers'

const DownloadPayrollReportMenuItem = ({
  userId,
  reportId,
}: {
  userId: string
  reportId: string
}) => {
  const dispatch = useAppDispatch()
  const report = useReselector(selectBookkeepingReportsById, reportId)
  const user = useReselector(getUserById, userId)
  const [loading, setLoading] = useState(false)
  const payrollProfile = useFetchResponse(fetchPayrollForUser, userId)

  const download = useCallback(async () => {
    setLoading(true)
    const res = await downloadPayrollReport(reportId)(dispatch)
    if (res) {
      const blob = new Blob([res.data], { type: 'text/csv' })
      saveAs(
        blob,
        `payroll_report_${user?.firstName}_${user?.lastName}_${report?.date}.csv`
      )
    }

    setLoading(false)
  }, [dispatch, report?.date, reportId, user?.firstName, user?.lastName])

  if (!payrollProfile) {
    return null
  }

  return (
    <Menu.Item>
      <Button onClick={download} loading={loading}>
        Download payroll report
      </Button>
    </Menu.Item>
  )
}

export default DownloadPayrollReportMenuItem
