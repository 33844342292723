import {
  Button,
  Text,
  Card,
  GridRowColumn,
  Link,
} from '../../../../components/BaseComponents'
import { Image, Grid } from 'semantic-ui-react'

import NumberIconList from '../../../../components/shared/NumberIconList'
import { useNavigate } from 'react-router-dom'
import { Colors } from '../../../../styles/theme'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { ReactNode, useEffect, useMemo } from 'react'
import { selectSubmitted1099sAfterDeadline } from '../annualTaxFilings.selector'
import { fetchAboundTaxDocumentsForPayer } from '../aboundAnnualTaxFilings.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const DonePanel = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const submittedLate = useReselector(
    selectSubmitted1099sAfterDeadline,
    taxYear
  )
  const nextStepsList = useMemo(() => {
    const steps: ReactNode[] = [
      'We’ll file the 1099-NECs and send an email upon submission. If there’s an issue with a contractor’s information, we’ll email you ASAP.',
      'Once the 1099-NECs are successfully filed, we’ll notify you and you can download them in Documents.',
      'You’ll send the 1099-NECs to your contractors.',
    ]
    if (submittedLate) {
      steps.push(
        <>
          After your 1099-NECs are filed, the IRS will mail you a notice for the
          late-filing penalty fees. You should pay them as soon as you can to
          avoid paying interest.{' '}
          <Link
            href="https://www.irs.gov/payments/information-return-penalties"
            newPage
          >
            Learn more.
          </Link>
        </>
      )
    }
    return steps
  }, [submittedLate])

  useEffect(() => {
    fetchAboundTaxDocumentsForPayer()(dispatch)
  }, [dispatch])
  return (
    <Card>
      <Grid centered>
        <GridRowColumn width={8}>
          <Grid centered>
            <GridRowColumn>
              <Image
                src="https://heard-images.s3.us-west-1.amazonaws.com/assets/taxes.svg"
                centered
                style={{
                  height: 171,
                  backgroundColor: Colors.blush,
                  borderRadius: '50%',
                  marginBottom: 16,
                }}
              />
              <Text style={{ marginBottom: 32 }} textAlign="center" as="h1">
                You&apos;re Done!
              </Text>
              <Text textAlign="center">
                Your contractors&apos; information have been submitted. Next up:
              </Text>
            </GridRowColumn>
            <NumberIconList width={12} list={nextStepsList} />
            <Grid.Row>
              <Grid.Column width={8}>
                <Button
                  fullWidth
                  variant="secondary"
                  onClick={() => navigate('/taxes/annual')}
                >
                  Return to Tax Center
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </GridRowColumn>
      </Grid>
    </Card>
  )
}

export default DonePanel
