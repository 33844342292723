import { Divider, Loader } from 'semantic-ui-react'
import { Text } from '../../components/BaseComponents'
import { useEffect, useState } from 'react'
import { selectFinancialAdvisoryProfile } from './financialAdvisory.selectors'
import {
  fetchFinancialAdvisoryProfileIfNeeded,
  FinancialAdvisoryProfilesStatus,
} from './financialAdvisory.slice'
import {
  fetchSubscriptions,
  selectHeardProductPlanInterval,
} from '../../reducers/subscription.slice'
import { useReselector } from '../../utils/sharedHooks'
import { useAnalyticsView } from '../Amplitude'
import PageHeader from '../../components/shared/PageHeader'
import { fetchCurrentUserDetailsIfNeeded } from '../../actions/userActions'
import AdvisoryHomepageWrapper from './AdvisoryHomepageWrapper'
import IntroCallCTAPanel from './panels/IntroCallCTAPanel'
import NotInterestedPanel from './panels/NotInterestedPanel'
import { useAppDispatch } from '../../utils/typeHelpers'

const FinancialAdvisoryOverview = () => {
  const dispatch = useAppDispatch()
  const pageView = useAnalyticsView()
  const [fetching, setFetching] = useState(false)
  const faProfile = useReselector(selectFinancialAdvisoryProfile)

  useEffect(() => {
    async function fetchData() {
      setFetching(true)
      await dispatch(fetchFinancialAdvisoryProfileIfNeeded())
      await dispatch(fetchCurrentUserDetailsIfNeeded())
      await dispatch(fetchSubscriptions())
      setFetching(false)
    }
    fetchData()
  }, [dispatch])

  useEffect(() => {
    pageView('advisory homepage')
  }, [pageView])

  // check subscription interval
  const primarySubscriptionInterval = useReselector(
    selectHeardProductPlanInterval
  )

  const renderExtraContent = () => {
    return <Text as="eyebrow">powered by Uprise</Text>
  }
  const renderAdvisoryHomepage = () => {
    // State 1: Check if User has an advisory Profile or if intro call is not scheduled
    // User does not have a Advisory Profile => Has not Enrolled or used Intro Call
    if (
      !faProfile ||
      faProfile.status === FinancialAdvisoryProfilesStatus.profile_generated ||
      faProfile.status === FinancialAdvisoryProfilesStatus.waitlisted
    ) {
      // Show CTA to schedule intro call
      return <IntroCallCTAPanel />
    }
    // User canceled their plan or indicated that they were not interested
    else if (
      faProfile.status === FinancialAdvisoryProfilesStatus.canceled ||
      faProfile.status === FinancialAdvisoryProfilesStatus.not_interested
    ) {
      // Show CTA to reactive and onboard to FA
      return <NotInterestedPanel faProfile={faProfile} />
    }

    // State 2: User has Advisory Profile, we will use the Profile Status to identify views to show
    else if (faProfile) {
      return <AdvisoryHomepageWrapper faProfile={faProfile} />

      // State 3: Check for Plan Interval: Advisory only included for yearly plans
      // We offer a CTA to convert to Annual
    } else if (
      primarySubscriptionInterval &&
      primarySubscriptionInterval !== 'year'
    ) {
      return <div>does not qualify</div>
    } else {
      // Weird state
      return <div />
    }
  }
  return (
    <>
      <PageHeader
        header="Financial Advisory"
        extraContent={renderExtraContent()}
      />
      <Divider />
      {fetching && <Loader loading={fetching} />}
      {!fetching && renderAdvisoryHomepage()}
    </>
  )
}

export default FinancialAdvisoryOverview
