import { Grid, Image } from 'semantic-ui-react'

import { GridRowColumn, Link, Text } from '../../components/BaseComponents'
import { AnnualTaxDetail } from '../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { DATE_FORMATS_LUXON, formatISOFromUTC } from '../../utils/dateHelpers'

export const get1040Accordion = () => ({
  accordionTitle: 'What is Form 1040?',
  accordionContent: (
    <Grid>
      <GridRowColumn>
        <Text>
          Form 1040 is used to report your taxes as a sole proprietor. Since
          there is no legal distinction between you, the individual, and your
          business, you report any income earned through a personal tax return.
        </Text>
      </GridRowColumn>
      <GridRowColumn>
        <Text as="eyebrow">This is what Form 1040 looks like:</Text>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/form1040.png"
          style={{ paddingTop: 8 }}
        />
      </GridRowColumn>
      <GridRowColumn>
        <Text>
          <Link
            href="https://www.irs.gov/forms-pubs/about-form-1040"
            newPage
            as="secondaryLink"
          >
            Learn more
          </Link>{' '}
          about Form 1040.
        </Text>
      </GridRowColumn>
      <GridRowColumn>
        <Text>
          <Link
            href="https://www.irs.gov/pub/irs-pdf/f1040.pdf"
            newPage
            as="secondaryLink"
          >
            Grab a copy
          </Link>{' '}
          of Form 1040.
        </Text>
      </GridRowColumn>
    </Grid>
  ),
})

export const get1120SAccordion = (taxDetails?: AnnualTaxDetail) => ({
  accordionTitle: 'What is Form 1120-S?',
  accordionContent: (
    <Grid>
      <GridRowColumn>
        <Text>
          An S corporation uses this form to report any income, gains, losses,
          deductions and credits for their business.{' '}
          <b>
            {formatISOFromUTC(
              taxDetails?.irsFormDueDates.form_1120_s.irs.dueDate,
              DATE_FORMATS_LUXON.DISPLAY_LONG
            )}
          </b>{' '}
          is the deadline to file your tax return.
        </Text>
      </GridRowColumn>
      <GridRowColumn>
        <Text as="eyebrow">This is what Form 1120-S looks like:</Text>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/form1120s.png"
          style={{ paddingTop: 8 }}
        />
      </GridRowColumn>
      <GridRowColumn>
        <Text>
          <Link
            href="https://www.irs.gov/forms-pubs/about-form-1120-s"
            newPage
            as="secondaryLink"
          >
            Learn more
          </Link>{' '}
          about Form 1120-S.
        </Text>
      </GridRowColumn>
      <GridRowColumn>
        <Text>
          <Link
            href="https://www.irs.gov/pub/irs-pdf/f1120s.pdf"
            newPage
            as="secondaryLink"
          >
            Grab a copy
          </Link>{' '}
          of Form 1120-S.
        </Text>
      </GridRowColumn>
    </Grid>
  ),
})
