import { useMemo } from 'react'
import { Divider, Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import moment from 'moment/moment'

import {
  Button,
  FormikDateInput,
  FormikDropdown,
  getFieldName,
  GridRowColumn,
  Link,
  makeDateSchema,
  makeReqStringSchema,
  Text,
} from '../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useReselector } from '../../../utils/sharedHooks'
import { getIsFetching } from '../../../reducers/fetch'
import { POST_PAY_SCHEDULE_KEY, postPaySchedule } from '../payrollActions'
import { generateFilterAnchorDate, generateFilterFirstDate } from '../helpers'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { Gusto_PayScheduleFrequency } from '../generated_gusto_types'

const FREQUENCY_OPTIONS = [
  { text: 'Every week', value: 'Every week' },
  { text: 'Every other week', value: 'Every other week' },
  {
    text: 'Twice per month: 15th and last day of the month',
    value: 'Twice per month',
  },
  { text: 'Monthly: last day of the month', value: 'Monthly' },
]

export const SchedulePreview = ({
  frequency,
  firstPayDate,
}: {
  frequency: string
  firstPayDate: string
}) => {
  const date = moment(firstPayDate)

  return (
    <Grid className="pay-schedule-preview">
      <GridRowColumn>
        <Text>YOUR SELECTED PAY SCHEDULE</Text>
      </GridRowColumn>
      <Divider />
      <Grid.Row>
        <Grid.Column width={8}>
          <Text>Pay Frequency</Text>
          <Text as="h2">{frequency}</Text>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Text>First Pay Date</Text>
          <Text as="h2">
            {date.isValid() ? date.format(DATE_FORMATS.DISPLAY_LONG) : ''}
          </Text>
        </Grid.Column>
        <Grid.Column width={8}>
          <Text>Deadline to Run Payroll</Text>
          <Text as="h2">
            {date.isValid()
              ? date.subtract(4, 'days').format(DATE_FORMATS.DISPLAY_LONG)
              : ''}
          </Text>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const validationSchema = yup.object({
  frequency: makeReqStringSchema({ field: 'frequency' }),
  firstPayDate: makeDateSchema({ field: 'first pay date' }),
  anchorEndDate: makeDateSchema({ field: 'anchor end of pay period' }),
})

const PayrollScheduleForm = ({
  onSuccess,
  onCancel,
  saveText,
}: {
  onSuccess: () => void
  onCancel: () => void
  saveText: string
}) => {
  const dispatch = useAppDispatch()
  const isPosting = useReselector(getIsFetching, POST_PAY_SCHEDULE_KEY)

  const formik = useFormik<{
    frequency: Gusto_PayScheduleFrequency
    firstPayDate: string
    anchorEndDate: string
  }>({
    initialValues: {
      frequency: 'Every other week',
      firstPayDate: '',
      anchorEndDate: '',
    },
    validationSchema,
    onSubmit: async ({ frequency, firstPayDate, anchorEndDate }) => {
      let day_1 = null

      if (frequency === 'Twice per month') {
        day_1 = 15
      } else if (frequency === 'Monthly') {
        day_1 = 31
      }

      const res = await postPaySchedule({
        frequency,
        anchor_pay_date: moment(firstPayDate, DATE_FORMATS.INPUT).format(
          DATE_FORMATS.GUSTO_SUBMIT
        ),
        anchor_end_of_pay_period: moment(
          anchorEndDate,
          DATE_FORMATS.INPUT
        ).format(DATE_FORMATS.GUSTO_SUBMIT),
        day_1,
        day_2: frequency === 'Twice per month' ? 31 : null,
      })(dispatch)

      if (res) {
        onSuccess()
      }
    },
  })

  const filterFirstDate = useMemo(
    () => generateFilterFirstDate(formik.values.frequency),
    [formik.values.frequency]
  )
  const filterAnchorDate = useMemo(
    () =>
      generateFilterAnchorDate(
        formik.values.firstPayDate,
        formik.values.frequency
      ),
    [formik.values.firstPayDate, formik.values.frequency]
  )

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn>
          <Text as="h2">Create a Pay Schedule</Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text>
            Pick the frequency at which you would like to run payroll.{' '}
            <Link
              href="https://support.joinheard.com/hc/en-us/articles/7257879526807"
              newPage
            >
              How to choose a pay schedule.
            </Link>
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text>
            Some states have laws around when you must pay your employees.
            Please choose a pay schedule that is legal for your employees.
          </Text>
        </GridRowColumn>
        <Divider />
        <GridRowColumn short width={10}>
          <FormikDropdown
            name={getFieldName<typeof formik.values>('frequency')}
            options={FREQUENCY_OPTIONS}
            label="Pay Frequency"
            description="We recommend paying yourself once per month, on the last day of each month"
            onChange={() => {
              formik.setFieldValue('firstPayDate', '')
              formik.setFieldValue('anchorEndDate', '')
            }}
            fullWidth
            required
          />
        </GridRowColumn>
        <GridRowColumn width={10}>
          <FormikDateInput
            name={getFieldName<typeof formik.values>('firstPayDate')}
            label="First Pay Date"
            description="When selecting your pay date, please account for the 4 days it will take to process payroll."
            fullWidth
            required
            filterDate={filterFirstDate}
            onChange={() => formik.setFieldValue('anchorEndDate', '')}
          />
        </GridRowColumn>
        <GridRowColumn width={10}>
          <FormikDateInput
            name={getFieldName<typeof formik.values>('anchorEndDate')}
            label="Anchor End Date"
            description="Last day of the first pay period. Can be the same as first paydate"
            fullWidth
            required
            filterDate={filterAnchorDate}
          />
        </GridRowColumn>
        <Grid.Row />
        <Grid.Row>
          <Grid.Column width={6}>
            <Button fullWidth variant="secondary" onClick={onCancel}>
              Back
            </Button>
          </Grid.Column>
          <Grid.Column width={4} />
          <Grid.Column width={6}>
            <Button onClick={formik.submitForm} fullWidth loading={isPosting}>
              {saveText}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </FormikProvider>
  )
}

export default PayrollScheduleForm
