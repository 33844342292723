import { useMemo } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import TaxCenterPromptCard, {
  PromptCardStatus,
  TaxPromptCardButton,
  TaxPromptCardInfo,
} from '../components/TaxCenterPromptCard'
import {
  GridRowColumn,
  Icon,
  Text,
} from '../../../../components/BaseComponents'
import { AnnualTaxFilingForm } from '../annualTaxFilingForms.slice'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  select1040TQStatusByYear,
  selectShowFileExtensionForYear,
} from '../annualTaxFilings.selector'
import { AnnualTaxFilingStepStatus } from '../constants'
import { selectTaxDetailsByYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { isoToUTCDateTime } from '../../../../utils/dateHelpers'
import {
  select1040FormForYear,
  selectIsTwoFormFiler,
} from '../annualTaxFilingForms.selector'
import { FilingStatusByForm } from '../Overview/DetailedFormStatusItem'

const TaxChecklistActionCard = ({
  year,
  status,
}: {
  year: string
  status: PromptCardStatus
}) => {
  const taxDetails = useReselector(selectTaxDetailsByYear, year)
  const filingForm = useReselector(select1040FormForYear, year)
  const isTwoFormFiler = useReselector(selectIsTwoFormFiler, year)
  const hasExtension = useReselector(selectShowFileExtensionForYear, year)

  const getDueDate = useMemo(() => {
    if (status !== PromptCardStatus.COMING_SOON && taxDetails) {
      if (hasExtension) {
        return isoToUTCDateTime(
          taxDetails.irsFormDueDates.form_1040.internal.extendedDueDate
        )
      }
      return isoToUTCDateTime(
        taxDetails.irsFormDueDates.form_1040.internal.dueDate
      )
    }
    return undefined
  }, [taxDetails, hasExtension, status])

  return (
    <Grid>
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/lifeEvents.svg"
          style={{ width: 363, height: 190 }}
        />
      </GridRowColumn>
      <GridRowColumn spacer={3} width={10}>
        <Text textAlign="center">
          {isTwoFormFiler
            ? 'You’ll share any life changes, enter your deductions and credits, and upload documents into the Tax Checklist.'
            : 'You’ll review your personal income and expenses, share any life changes, enter your deductions and credits, and upload documents into the Tax Checklist.'}
        </Text>
      </GridRowColumn>
      <TaxPromptCardInfo
        timeToComplete="1-2 hours"
        docInfo={`${year} tax documents you have on hand`}
        dueDate={getDueDate}
        startDate={
          !hasExtension &&
          status === PromptCardStatus.COMING_SOON &&
          taxDetails?.taxQuestionnaireDueDates?.form_1040.startAt
            ? isoToUTCDateTime(
                taxDetails?.taxQuestionnaireDueDates?.form_1040.startAt
              )
            : undefined
        }
        extraListItem={
          hasExtension && (
            <Text>
              <Text as="bodySm">
                <Icon
                  icon={regular('hourglass-clock')}
                  style={{ marginRight: 15 }}
                  size="1x"
                />
                Available when extension request is complete
              </Text>
            </Text>
          )
        }
      />
      <TaxPromptCardButton
        status={status}
        actionLink={`/taxes/annual/tax_checklist/${filingForm?.id}`}
      />
    </Grid>
  )
}

const TaxChecklistPromptCard1040 = ({
  filingForm,
}: {
  filingForm: AnnualTaxFilingForm
}) => {
  const { year } = filingForm
  const formStatus = useReselector(select1040TQStatusByYear, year)

  const status = useMemo(() => {
    switch (formStatus) {
      case AnnualTaxFilingStepStatus.inProgress:
        return PromptCardStatus.IN_PROGRESS
      case AnnualTaxFilingStepStatus.completeLocked:
        return PromptCardStatus.DONE
      case AnnualTaxFilingStepStatus.notStarted:
        return PromptCardStatus.ACTION_REQUIRED
      case AnnualTaxFilingStepStatus.upcoming:
      case AnnualTaxFilingStepStatus.comingSoon:
        return PromptCardStatus.COMING_SOON
      default:
        return formStatus satisfies never
    }
  }, [formStatus])

  if (![PromptCardStatus.DONE, PromptCardStatus.IN_PROGRESS].includes(status)) {
    return (
      <TaxCenterPromptCard
        body={{
          [PromptCardStatus.COMING_SOON]: (
            <TaxChecklistActionCard year={year} status={status} />
          ),
          [PromptCardStatus.IN_PROGRESS]: null,
          [PromptCardStatus.ACTION_REQUIRED]: (
            <TaxChecklistActionCard year={year} status={status} />
          ),

          [PromptCardStatus.DONE]: null,
        }}
        icon={regular('seal-question')}
        status={status}
        title="Personal Tax Checklist (Form 1040)"
      />
    )
  }
  return <FilingStatusByForm filingForm={filingForm} />
}

export default TaxChecklistPromptCard1040
