import moment from 'moment'
import { FC, useCallback, useMemo } from 'react'
import { Grid, List } from 'semantic-ui-react'
import { Label, Text } from '../../../../components/BaseComponents'
import { Colors, FontWeight } from '../../../../styles/theme'

import {
  updateUserImportantDate,
  UserImportantDate,
} from '../userImportantDates.slice'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { useAppDispatch } from '../../../../utils/typeHelpers'

interface Props {
  userImportantDate: UserImportantDate
  indicateUnreadItems: boolean
}

// TODO: Unsure where to reference colors and put styles
const listStyle = {
  padding: '1em',
  borderTop: '0',
  borderBottom: '1px solid rgba(34,36,38,0.15)',
}

const unreadStyle = {
  backgroundColor: Colors.natural,
  cursor: 'pointer',
}

const UserImportantDateItem: FC<Props> = ({
  indicateUnreadItems,
  userImportantDate,
}) => {
  const dispatch = useAppDispatch()

  const isUnread = !userImportantDate.readAt
  const endDateCalc = userImportantDate.endsAt
    ? userImportantDate.endsAt
    : userImportantDate.actionItem.endsAt
  const subtitleText = useMemo(() => {
    const endDate = moment.utc(endDateCalc).format(DATE_FORMATS.DISPLAY_SHORT)

    if (userImportantDate.actionItem.startsAt) {
      const startDate = moment
        .utc(userImportantDate.actionItem.startsAt)
        .format(DATE_FORMATS.DISPLAY_SHORT)

      return `${startDate} - ${endDate}`
    }

    return endDate
  }, [endDateCalc, userImportantDate.actionItem.startsAt])

  const markAsRead = useCallback(() => {
    if (indicateUnreadItems) {
      dispatch(
        updateUserImportantDate(userImportantDate.id, {
          id: userImportantDate.id,
          readAt: Date.now(),
        })
      )
    }
  }, [dispatch, userImportantDate.id, indicateUnreadItems])

  return (
    <List.Item
      onClick={markAsRead}
      style={
        isUnread && indicateUnreadItems
          ? { ...listStyle, ...unreadStyle }
          : listStyle
      }
    >
      <Grid>
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Grid.Column>
            <Text as="bodySm" style={{ fontWeight: FontWeight.MEDIUM }}>
              {userImportantDate.actionItem.description}
              {indicateUnreadItems && isUnread && (
                <Label
                  style={{ display: 'inline', backgroundColor: 'transparent' }}
                  size="mini"
                  color="green"
                >
                  NEW
                </Label>
              )}
            </Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0 }}>
          <Grid.Column>
            <Text color="oak" as="bodyXs">
              {subtitleText}
            </Text>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </List.Item>
  )
}

export default UserImportantDateItem
