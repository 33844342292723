import { ReactNode, useEffect, useMemo } from 'react'
import { Segment } from 'semantic-ui-react'
import styled from 'styled-components'

import { Colors } from '../../styles/theme'
import Sidebar from '../Navigation/Sidebar'
import '../BaseComponents/BaseComponents.scss'
import { useFlyout, useSidebar } from '../../utils/sharedHooks'
import BaseRouterBanners from './BaseRouterBanners'
import MobileMenu from '../Navigation/MobileMenu'
import {
  DeviceWidth,
  DeviceWidthCombo,
  useDeviceWidth,
  useIsDeviceWidth,
} from '../../utils/deviceWidthHelpers'
import { FlyoutContent } from '../BaseComponents/Flyout'
import { useRouteConfig } from '../../utils/routeHelpers'

export const LoggedInLayout = styled(
  ({ children, className }: { children: ReactNode; className?: string }) => {
    const deviceWidth = useDeviceWidth()
    const { open: sidebarOpen, toggleSidebar } = useSidebar()
    const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
    const isTablet = useIsDeviceWidth(DeviceWidth.tablet)
    const { open } = useFlyout()
    const currentRoute = useRouteConfig()

    const isWideDesktop = deviceWidth === DeviceWidth.wideDesktop
    const isDesktopWidth = useIsDeviceWidth(DeviceWidthCombo.desktop)

    const pusherWidth = useMemo(() => {
      if (!isDesktopWidth) {
        return 0
      }

      if (isWideDesktop && sidebarOpen) {
        return 275
      }

      return 60
    }, [isDesktopWidth, isWideDesktop, sidebarOpen])

    useEffect(() => {
      // If the size of the screen goes below the wide desktop threshold automatically close the sidebar
      if (!isWideDesktop) {
        toggleSidebar(false)
      }
    }, [isWideDesktop, toggleSidebar])

    return (
      <>
        <Sidebar.Pushable
          className={`${className} ${isMobile && 'mobile'} ${
            isTablet && 'tablet'
          }`}
          as={Segment}
        >
          <div
            style={{
              position: isWideDesktop ? 'inherit' : 'absolute',
            }}
          >
            <Sidebar />
          </div>

          {/*Clickable div that covers content on mobile if sidebar is open*/}
          {sidebarOpen && isMobile && (
            <div
              className="content-cover"
              onClick={() => toggleSidebar()}
              onKeyDown={() => toggleSidebar()}
              role="presentation"
            />
          )}
          <Sidebar.Pusher
            className="sidebar-pusher"
            style={{ width: `calc(100% - ${pusherWidth}px)` }}
            dimmed={open}
          >
            <Segment
              className="content-wrapper"
              style={{
                backgroundColor: currentRoute.config?.backgroundColor,
                ...currentRoute.config?.contentWrapperStyle,
              }}
            >
              <BaseRouterBanners />
              {!isDesktopWidth && <MobileMenu />}
              <div
                className="inner-content-wrapper"
                style={currentRoute.config?.innerContentWrapperStyle}
              >
                {children}
              </div>
            </Segment>

            {/* This is a trick we can do with flexbox where if we want to limit the height of a
              container but don't know the heights of some of its sibling components (AKA the
              tax notice banner and the topbar), we can put a 0px height div with a flex-grow
              of 0, flex-shrink of 1, and flex-basis of 0px to prevent the other elements with
              a higher flex-grow value from exceeding the limits of the container. */}
            <div style={{ flex: '0 1 0px' }} />
          </Sidebar.Pusher>
        </Sidebar.Pushable>
        <FlyoutContent />
      </>
    )
  }
)({
  '&&&': {
    border: 'none',
    borderRadius: 0,
    position: 'fixed',
    width: '100%',

    '.sidebar-pusher': {
      backgroundColor: Colors.stone,
      display: 'flex',
      flexDirection: 'column',
      float: 'right',
      height: '100%',
      transform: 'none',
      transition: 'all 0.2s',
    },

    '&.mobile': {
      '.inner-content-wrapper': {
        padding: '12px 0 40px',
        // Somehow this fixes an issue where mobile can have extra gap on the sides
        overflowX: 'clip',
      },
      // In order to not clutter this file with lots of mobile per-page scroll logic
      // we're ignoring the scrollbar gutter. This may cause some browsers in mobile
      // to push content right slightly on scrollbar render
      '.content-wrapper': {
        scrollbarGutter: 'unset',
        overflowX: 'hidden',
      },
    },

    '&.tablet': {
      '.inner-content-wrapper': {
        padding: '24px',
      },
    },

    '.content-wrapper': {
      backgroundColor: 'inherit',
      border: 'none',
      boxShadow: 'none',
      flex: '1 1 auto',
      margin: 0,
      padding: 0,
      overflowY: 'auto',
      scrollbarGutter: 'stable',
      borderRadius: 0,
    },

    '.inner-content-wrapper': {
      padding: '24px 20px 0 40px',
    },

    '.content-cover': {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 10,
    },
  },
})
