import { useEffect } from 'react'
import { Loader, Grid } from 'semantic-ui-react'
import styled from 'styled-components'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import {
  Modal,
  Button,
  Text,
  Card,
  Accordion,
  IconButton,
  GridRowColumn,
} from '../BaseComponents'
import { FEATURE_FLAG_KEYS } from '../../features/OpenFeature'
import { useHelloSignBAA } from './helloSignHelper'
import { useReselector, useToggle } from '../../utils/sharedHooks'
import { getUserActionItemByActionItemIdentifier } from '../../features/Dashboard/UserActionItems/userActionItems.selectors'
import { GuidedOnboardingTaskIdents } from '../../features/Onboarding/config'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { getCurrentUser } from '../../selectors/user.selectors'
import { useAnalyticsTrack } from '../../features/Amplitude'
import { markUserActionItemCompleteIfExists } from '../../features/Dashboard/UserActionItems/service'
import { SignBAAAgreementStatus } from '../../features/Dashboard/UserActionItems/userActionItemStatuses'
import { Colors } from '../../styles/theme'

const StyledLoader = styled(Loader)`
  &&&::before {
    border-color: ${Colors.black} !important;
    opacity: 0.15;
  }
`

export const SignBaaModal = () => {
  const [showModal, toggleModal] = useToggle()
  // use closed state to prevent modal from reopening after closing
  const [closed, toggleClose] = useToggle()
  const [loading, toggleLoading] = useToggle()
  const track = useAnalyticsTrack()

  const user = useReselector(getCurrentUser)
  const { baa, openSignatureModal, fetching, signUrlError } = useHelloSignBAA()

  const baaActionItem = useReselector(
    getUserActionItemByActionItemIdentifier,
    GuidedOnboardingTaskIdents.SIGN_BAA_AGREEMENT
  )

  const releaseBaa = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.addBaaToGuidedOnboarding,
    false
  )

  useEffect(() => {
    if (showModal) {
      track('viewed sign BAA modal')
    }
  }, [showModal, track])

  useEffect(() => {
    if (!baaActionItem?.id) return
    if (
      releaseBaa &&
      !baaActionItem?.completedAt &&
      !showModal &&
      !closed &&
      user?.onboardingItemsCompletedAt
    ) {
      toggleModal()
    }
  }, [
    baaActionItem?.id,
    baaActionItem?.completedAt,
    showModal,
    releaseBaa,
    toggleModal,
    closed,
    user?.onboardingItemsCompletedAt,
  ])

  const handleDownloadBaa = () => {
    if (baa) {
      window.open(baa.fileUrl || '', '_blank')
    }
  }

  const handleClose = async () => {
    track('completed sign BAA modal')
    toggleLoading()
    if (!baaActionItem?.completedAt) {
      await markUserActionItemCompleteIfExists(
        GuidedOnboardingTaskIdents.SIGN_BAA_AGREEMENT,
        track,
        SignBAAAgreementStatus.signed
      )
    }
    toggleLoading()
    toggleClose()
  }

  return (
    <Modal open={showModal && !closed} size="small">
      {!baaActionItem?.completedAt && !signUrlError && (
        <>
          <Modal.Header>Keep your clients&apos; data safe</Modal.Header>
          <Modal.Content>
            <Card
              type="subsection"
              backgroundColor="natural"
              style={{
                height: 240,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src="https://heard-images.s3.amazonaws.com/assets/checklist.svg"
                alt="checklist"
              />
            </Card>
            <br />
            <Text as="bodyLg">
              Heard cares about security and your personal data. To continue,
              we&apos;ll need you to sign a{' '}
              <b> Business Associate Agreement (BAA). </b>
            </Text>
            <br />
            <Text as="bodyLg">
              This is a contract that protects your business. It allows us to
              securely handle data that could include sensitive details, such as
              your clients&apos; identifying information.
            </Text>
            <br />
            <Accordion
              title="Why does Heard need this document?"
              content={
                <Text>
                  Your clients&apos; personal information may appear in your
                  transactions. We ask that you sign this agreement to ensure
                  that all of this Protected Health Information (PHI) is secure.
                </Text>
              }
              variant="text"
              style={{
                marginTop: 16,
                marginBottom: 16,
              }}
            />
            <Accordion
              title="What is a Business Associate Agreement?"
              content={
                <Text>
                  A business associate agreement establishes a legally-binding
                  relationship between HIPAA-covered entities and business
                  associates to ensure complete protection of Protected Health
                  Information (PHI).
                </Text>
              }
              variant="text"
            />
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={openSignatureModal} loading={fetching}>
              View and Sign
            </Button>
          </Modal.Actions>
        </>
      )}
      {baaActionItem?.completedAt && !signUrlError && (
        <>
          <Modal.Header>Thanks for signing your BAA</Modal.Header>
          <Modal.Content>
            <Grid>
              <GridRowColumn>
                <Card
                  type="subsection"
                  backgroundColor="lightGreen"
                  style={{
                    height: 240,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src="https://heard-images.s3.amazonaws.com/assets/checklist.svg"
                    alt="checklist"
                  />
                </Card>
              </GridRowColumn>
              <GridRowColumn>
                <Text as="bodyLg">
                  You can now download a copy of the BAA for your records.
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text>
                  Heard Business Associate Agreement
                  {typeof baa !== 'string' && baa?.fileUrl ? (
                    <IconButton
                      style={{ marginLeft: 16 }}
                      icon={regular('download')}
                      onClick={handleDownloadBaa}
                    />
                  ) : (
                    <StyledLoader active inline style={{ marginLeft: 16 }} />
                  )}
                </Text>
              </GridRowColumn>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={handleClose} variant="secondary" loading={loading}>
              Close
            </Button>
          </Modal.Actions>
        </>
      )}
      {signUrlError && (
        <>
          <Modal.Header>Please try again later</Modal.Header>
          <Modal.Content>
            <Text as="bodyLg">
              Due to an external error, we can&apos;t currently retrieve the BAA
              document. We&apos;ll ask you to sign it later.
            </Text>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={toggleClose} variant="secondary">
              Close
            </Button>
          </Modal.Actions>
        </>
      )}
    </Modal>
  )
}
