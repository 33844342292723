import { useEffect } from 'react'
import { Grid } from 'semantic-ui-react'
import { GridRowColumn, Text, Card } from '../../components/BaseComponents'
import { useAnalyticsView } from '../Amplitude'

export const OnboardingMobileRedirect = () => {
  const pageView = useAnalyticsView()

  useEffect(() => {
    pageView('onboarding mobile page')
  }, [pageView])

  return (
    <Grid style={{ padding: 24 }}>
      <GridRowColumn>
        <Text as="h2"> Get started with Heard </Text>
      </GridRowColumn>
      <GridRowColumn>
        <Card
          fullWidth
          backgroundColor="lightOrange"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src="https://heard-images.s3.amazonaws.com/assets/laptop-only.png"
            alt="laptop"
          />
        </Card>
      </GridRowColumn>
      <GridRowColumn>
        <Text as="h3">
          Setting up your Heard account isn&apos;t available on mobile (yet).
        </Text>
        <br />
        <Text>
          Please switch to a desktop or laptop computer to continue setting up
          your Heard account.
        </Text>
      </GridRowColumn>
    </Grid>
  )
}
