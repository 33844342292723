import { useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'

import {
  fetchEmployeeHomeAddresses,
  putUpdateEmployee,
} from '../payrollActions'
import { DetailsRow } from './JobPayTab'
import {
  selectEmployeeByUuid,
  selectJobByEmployeeUuid,
} from '../payroll.selectors'
import { gepAddressToText } from '../helpers'
import {
  Button,
  Modal,
  Text,
  FormikDateInput,
  FormikInput,
  makeDateSchema,
  makeReqStringSchema,
  makeReqEmailSchema,
} from '../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useFetchResponse, useReselector } from '../../../utils/sharedHooks'
import { EmployeeWithoutJob } from '../employee.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'

const END_VAL = 'for this employee'
const validationSchema = yup.object({
  firstName: makeReqStringSchema({ field: 'first name', end: END_VAL }),
  lastName: makeReqStringSchema({ field: 'last name', end: END_VAL }),
  dob: makeDateSchema({ field: 'date of birth', end: END_VAL }),
  email: makeReqEmailSchema({ field: 'email', end: END_VAL }),
})

const EditDetailsModal = ({
  employee,
  closeModal,
}: {
  employee: EmployeeWithoutJob
  closeModal: () => void
}) => {
  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      firstName: employee.first_name,
      lastName: employee.last_name,
      dob: moment(employee.date_of_birth, DATE_FORMATS.GUSTO_SUBMIT).format(
        DATE_FORMATS.INPUT
      ),
      email: employee.email || '',
    },
    validationSchema,
    onSubmit: async ({ firstName, lastName, email, dob }) => {
      const res = await putUpdateEmployee(employee.uuid, {
        version: employee.version,
        first_name: firstName,
        last_name: lastName,
        date_of_birth: moment(dob, DATE_FORMATS.INPUT).format(
          DATE_FORMATS.GUSTO_SUBMIT
        ),
        email,
      })(dispatch)

      if (res) {
        closeModal()
      }
    },
  })

  return (
    <Modal open size="tiny" onClose={closeModal} closeIcon>
      <Modal.Header>Update Details</Modal.Header>
      <Modal.Content>
        <FormikProvider value={formik}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <FormikInput name="firstName" label="First Name" required />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <FormikInput name="lastName" label="Last Name" required />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <FormikDateInput name="dob" label="Date of Birth" required />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <FormikInput
                  name="email"
                  type="email"
                  label="Email"
                  required
                  fullWidth
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button onClick={() => formik.handleSubmit()} fullWidth>
                  Confirm
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button onClick={closeModal} variant="secondary" fullWidth>
                  Cancel
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </FormikProvider>
      </Modal.Content>
    </Modal>
  )
}

const EmployeeDetailsPanel = ({ employeeUuid }: { employeeUuid: string }) => {
  const [modalOpen, setIsModalOpen] = useState(false)
  const employee = useReselector(selectEmployeeByUuid, employeeUuid)
  const job = useReselector(selectJobByEmployeeUuid, employeeUuid)
  const homeAddressList = useFetchResponse(
    fetchEmployeeHomeAddresses,
    employeeUuid
  )

  const currentHomeAddress = homeAddressList?.find((address) => address.active)

  if (!employee) {
    return null
  }

  const { first_name, last_name, date_of_birth, email, terminated } = employee

  return (
    <div className="gep-details-container">
      <Grid>
        <Grid.Row>
          <Grid.Column width={14}>
            <Text as="h2">Employee Details</Text>
          </Grid.Column>
          <Grid.Column width={2}>
            <Button onClick={() => setIsModalOpen(true)} variant="link">
              Edit
            </Button>
          </Grid.Column>
        </Grid.Row>
        <DetailsRow label="First Name" value={first_name} />
        <DetailsRow label="Last Name" value={last_name} />
        <DetailsRow
          label="Date of Birth"
          value={moment(date_of_birth).format(DATE_FORMATS.DISPLAY_LONG)}
        />
        <DetailsRow label="Personal Email" value={email} />
        <DetailsRow
          label="Employee Status"
          value={terminated ? 'Inactive' : 'Active'}
        />
        {job && (
          <DetailsRow
            label="Start Date"
            value={moment(job.hire_date).format(DATE_FORMATS.DISPLAY_LONG)}
          />
        )}
        {currentHomeAddress?.street_1 && (
          <DetailsRow
            label="Home address"
            value={gepAddressToText(currentHomeAddress)}
          />
        )}
      </Grid>
      {modalOpen && (
        <EditDetailsModal
          employee={employee}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
    </div>
  )
}

export default EmployeeDetailsPanel
