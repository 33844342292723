import { useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Container, Grid, Icon } from 'semantic-ui-react'

import { selectEmployeeByUuid } from '../payroll.selectors'
import { fetchSingleEmployee } from '../payrollActions'
import JobPayTab from './JobPayTab'
import EmployeeDocumentsTab from './EmployeeDocumentsTab'

import OnboardingCTA from './OnboardingCTA'
import {
  Text,
  Link,
  Tab,
  Label,
  Card,
  Loader,
  GridRowColumn,
} from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'

const PayrollEmployee = () => {
  const dispatch = useAppDispatch()
  const { uuid } = useParams()
  const [searchParams] = useSearchParams()
  const employee = useReselector(selectEmployeeByUuid, uuid)

  const [activeIndex, setActiveIndex] = useState(
    searchParams.get('tab') === 'documents' ? 1 : 0
  )

  useEffect(() => {
    if (uuid) {
      dispatch(fetchSingleEmployee(uuid))
    }
  }, [dispatch, uuid])

  const panes = useMemo(
    () =>
      uuid
        ? [
            {
              menuItem: 'Job and Pay',
              render: () => (
                <Tab.Pane attached={false}>
                  <JobPayTab uuid={uuid} />
                </Tab.Pane>
              ),
            },
            {
              menuItem: 'Documents',
              render: () => (
                <Tab.Pane attached={false}>
                  <EmployeeDocumentsTab uuid={uuid} />
                </Tab.Pane>
              ),
            },
          ]
        : [],
    [uuid]
  )

  return (
    <Container id="employee-details">
      <Card>
        <Loader loading={!employee} />
        {employee && (
          <Grid>
            <GridRowColumn>
              <Link to="/payroll/employees">
                <Icon name="arrow left" style={{ marginTop: -8 }} />
                Back to team
              </Link>
            </GridRowColumn>
            <Grid.Row />
            <GridRowColumn>
              <div style={{ display: 'flex' }}>
                <Text as="h1">
                  {employee.first_name} {employee.last_name}&nbsp;&nbsp;
                </Text>
                {!employee.onboarded && <Label color="green">Onboarding</Label>}
              </div>
            </GridRowColumn>
            <OnboardingCTA teamMemberUuid={employee.uuid} isEmployee />
            <GridRowColumn>
              <Tab
                panes={panes}
                activeIndex={activeIndex}
                defaultActiveIndex={activeIndex}
                onTabClick={(index) => setActiveIndex(index)}
              />
            </GridRowColumn>
          </Grid>
        )}
      </Card>
    </Container>
  )
}

export default PayrollEmployee
