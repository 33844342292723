import { useCallback, useEffect, useMemo, useState } from 'react'
import { Container } from 'semantic-ui-react'
import { useNavigate, useParams } from 'react-router-dom'

import '../../payroll.scss'
import '../runPayroll.scss'

import { selectPayrollByUuid } from '../../payroll.selectors'
import {
  fetchAllPaySchedules,
  fetchEmployees,
  fetchSinglePayroll,
  putCalculatePayroll,
  putPreparePayroll,
  putSubmitPayroll,
} from '../../payrollActions'
import PayrollOverview from './ProcessPayrollOverviewTab'
import { useReselector, useTimeoutRef } from '../../../../utils/sharedHooks'
import PayrollReviewAndSubmitTab from './PayrollReviewAndSubmitTab'
import PayrollConfirmationTab from './PayrollConfirmationTab'
import PayrollLoading from '../PayrollLoading'
import { useBankAccount } from '../../helpers'
import { Card, Text, Button } from '../../../../components/BaseComponents'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const ProcessPayroll = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const pollTimeoutRef = useTimeoutRef()

  const [currentTab, setCurrentTab] = useState(0)
  const [loading, setLoading] = useState(true)
  const { uuid } = useParams()
  const upcomingPayroll = useReselector(selectPayrollByUuid, uuid)
  const bankAccount = useBankAccount()

  // Initial Load
  useEffect(() => {
    const fetch = async () => {
      await Promise.all([
        uuid && dispatch(putPreparePayroll(uuid)),
        dispatch(fetchEmployees()),
        dispatch(fetchAllPaySchedules()),
      ])

      setLoading(false)
    }
    fetch()
  }, [dispatch, uuid])

  // Payroll calculation/processing is async so fetch endpoint is polled to see if it's complete
  const pollPayroll = useCallback(
    (checkForProcessed: boolean) => {
      pollTimeoutRef.current = setTimeout(async () => {
        if (!uuid) {
          return
        }

        const payroll = await fetchSinglePayroll(uuid, {
          include: ['benefits', 'deductions', 'taxes'],
        })(dispatch)

        // This is combined to share poll function.  `processed` is for payroll submission, `calculated_at` is for calculations
        if (checkForProcessed ? payroll?.processed : payroll?.calculated_at) {
          setLoading(false)
        } else {
          pollPayroll(checkForProcessed)
        }
      }, 1000)
    },
    [dispatch, uuid, pollTimeoutRef]
  )

  const calculatePayroll = useCallback(async () => {
    if (!uuid) {
      return
    }

    setLoading(true)
    setCurrentTab(1)
    const res = await putCalculatePayroll(uuid)(dispatch)

    if (res) {
      pollPayroll(false)
    } else {
      setCurrentTab(0)
      setLoading(false)
    }
  }, [dispatch, uuid, pollPayroll])

  // Extra logic is needed for going back because a payroll version is required for calculation but after calculation a
  // version doesn't come back. It needs to be refetched
  const goBackFromReview = useCallback(async () => {
    if (!uuid) {
      return
    }

    setLoading(true)
    setCurrentTab(0)
    await putPreparePayroll(uuid)(dispatch)
    setLoading(false)
  }, [dispatch, uuid])

  const submitPayroll = useCallback(async () => {
    if (!uuid) {
      return
    }

    setLoading(true)
    setCurrentTab(2)
    const res = await putSubmitPayroll(uuid)(dispatch)

    if (res) {
      pollPayroll(true)
    } else {
      setCurrentTab(1)
      setLoading(false)
    }
  }, [dispatch, uuid, pollPayroll])

  const content = useMemo(() => {
    if (currentTab === 0) {
      if (loading || !uuid) {
        return <PayrollLoading text="Fetching Payroll" />
      }
      return <PayrollOverview goForward={calculatePayroll} uuid={uuid} />
    } else if (currentTab === 1) {
      if (loading || !uuid) {
        return (
          <PayrollLoading text="Creating your payroll for your review..." />
        )
      }
      return (
        <PayrollReviewAndSubmitTab
          goForward={submitPayroll}
          goBack={goBackFromReview}
          uuid={uuid}
        />
      )
    }
    if (loading || !uuid) {
      return (
        <PayrollLoading
          text="We’re submitting your payroll..."
          subtext="Please do not close or refresh this page"
        />
      )
    }
    return <PayrollConfirmationTab uuid={uuid} bankAccount={bankAccount} />
  }, [
    bankAccount,
    calculatePayroll,
    currentTab,
    goBackFromReview,
    uuid,
    loading,
    submitPayroll,
  ])

  return (
    <Container id="run-payroll">
      <Card>
        {/*Error case handling when payroll is already processed*/}
        {Boolean(upcomingPayroll?.processed) && currentTab !== 2 && (
          <Button onClick={() => navigate('/payroll/run_payroll')}>
            Payroll processed. Go back
          </Button>
        )}
        {/*Error case handling when payroll cannot be found*/}
        {!loading && !upcomingPayroll && (
          <>
            <Text>Could not find upcoming payroll</Text>
            <Button onClick={() => navigate('/payroll/run_payroll')}>
              Go back to payroll
            </Button>
          </>
        )}
        {content}
      </Card>
    </Container>
  )
}

export default ProcessPayroll
