import { DateTime } from 'luxon'
import { DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'

export enum RequestSource {
  upsell_card = 'upsell_card',
  conversations = 'conversations',
}

export enum SCorpSetUpStatus {
  disqualified_automatically = 'disqualified_automatically',
  not_interested = 'not_interested',
  request_submitted = 'request_submitted',
  qualification_pending = 'qualification_pending',
  disqualified_manually = 'Disqualified manually',
  blocked_by_entity_ein = 'blocked_by_entity_ein',
  drafting_form_2553 = 'drafting_form_2553',
  blocked_by_signature = 'blocked_by_signature',
  form_2553_filed = 'form_2553_filed',
  cp261_received = 'cp261_received',
  withdrew_election = 'withdrew_election',
}

export type SCorpUpsellImportantDate = {
  label: string
  date: string
  time: string
  meridian: string
}

export const formatDateForUpsellImportantDates = (
  dateString: string,
  isInput?: boolean
) => {
  const date = DateTime.fromISO(dateString, { zone: 'America/Los_Angeles' })
  return date.toFormat(
    isInput ? DATE_FORMATS_LUXON.INPUT : DATE_FORMATS_LUXON.DISPLAY_SHORT
  )
}

export const formatTimeForUpsellImportantDates = (dateString: string) => {
  const date = DateTime.fromISO(dateString, { zone: 'America/Los_Angeles' })
  return date.toFormat('hh:mm')
}

export const formatRequestSourceLabel = (requestSource: RequestSource) => {
  switch (requestSource) {
    case RequestSource.upsell_card:
      return 'Upsell Card'
    case RequestSource.conversations:
      return 'Conversations'
    default:
      return requestSource satisfies never
  }
}

export const formatSetUpStatusLabelForSetUpStatus = (
  setupStatus: SCorpSetUpStatus
) => {
  switch (setupStatus) {
    case SCorpSetUpStatus.disqualified_automatically:
      return 'Disqualified Automatically'
    case SCorpSetUpStatus.not_interested:
      return 'Not Interested'
    case SCorpSetUpStatus.request_submitted:
      return 'Request Submitted'
    case SCorpSetUpStatus.qualification_pending:
      return 'Qualification Pending'
    case SCorpSetUpStatus.disqualified_manually:
      return 'Disqualified Manually'
    case SCorpSetUpStatus.blocked_by_entity_ein:
      return 'Blocked by Entity EIN'
    case SCorpSetUpStatus.drafting_form_2553:
      return 'Drafting Form 2553'
    case SCorpSetUpStatus.blocked_by_signature:
      return 'Blocked by Signature'
    case SCorpSetUpStatus.form_2553_filed:
      return 'Form 2553 Filed'
    case SCorpSetUpStatus.cp261_received:
      return 'CP261 Received'
    case SCorpSetUpStatus.withdrew_election:
      return 'Withdrew Election'
    default:
      return setupStatus satisfies never
  }
}
