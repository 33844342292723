import axios from 'axios'
import { keyBy } from 'lodash'

import {
  receiveDeleteTransactionRule,
  receiveSingleTransactionRule,
  receiveTransactionRules,
  TransactionRule,
  TransactionRuleCategoryType,
  TransactionRuleType,
  TransactionRuleUse,
} from '../../reducers/admin/transactionRulesReducer'
import { fetchIfNeededWrapper, fetchWrapper } from '../../reducers/fetch'

export const FETCH_ALL_TRANSACTION_RULES_KEY = 'FETCH_ALL_TRANSACTION_RULES_KEY'

export const fetchAllTransactionRulesIfNeeded = () =>
  fetchIfNeededWrapper({
    defaultErrorMessage: 'There was an error fetching all transaction rule.',
    fetchKey: FETCH_ALL_TRANSACTION_RULES_KEY,
    fetchFunction: (dispatch) =>
      axios
        .get<TransactionRule[]>('/finances/api/v1/admin/transaction_rules')
        .then((json) => {
          dispatch(receiveTransactionRules(keyBy(json.data, 'id')))
          return json.data
        }),
  })

export const CREATE_TRANSACTION_RULE_KEY = 'CREATE_TRANSACTION_RULE_KEY'
export const createTransactionRule = (data: {
  userId: number | undefined
  ruleUse: TransactionRuleUse
  ruleText: string
  ruleType: TransactionRuleType
  amountInCents: string | undefined
  transactionCategoryType: TransactionRuleCategoryType | undefined | null
  transactionCategoryId: number | undefined | null
}) =>
  fetchWrapper({
    fetchKey: CREATE_TRANSACTION_RULE_KEY,
    defaultErrorMessage: 'There was an error creating transaction rule.',
    fetchFunction: (dispatch) =>
      axios
        .post<TransactionRule>('/finances/api/v1/admin/transaction_rules', data)
        .then((json) => {
          dispatch(receiveSingleTransactionRule(json.data))
          return json.data
        }),
  })

export const DELETE_TRANSACTION_RULE_KEY = (id: number) =>
  `DELETE_TRANSACTION_RULE_KEY${id}`
export const deleteTransactionRule = (id: number) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error deleting the transaction rule.',
    defaultValue: false,
    fetchKey: DELETE_TRANSACTION_RULE_KEY(id),
    fetchFunction: (dispatch) =>
      axios
        .delete<{
          transactionRuleId: number
        }>(`/finances/api/v1/admin/transaction_rules/${id}`)
        .then((json) => {
          dispatch(receiveDeleteTransactionRule(json.data.transactionRuleId))
          return json.data
        }),
  })

export const fetchSingleTransactionRule = (id: number) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an receiving.',
    fetchFunction: (dispatch) =>
      axios.get(`/finances/api/v1/transaction_rules/${id}`).then((json) => {
        dispatch(receiveSingleTransactionRule(json.data))
        return json.data
      }),
  })
