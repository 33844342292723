import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { keyBy } from 'lodash'

import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import { fetchWrapper } from '../../../reducers/fetch'
import { TAX_ENTITY_TYPES_TYPE } from '../../Taxes/taxConstants'
import {
  AdminAnnualTaxFiling,
  updateSingleAnnualTaxFiling,
} from '../Taxes/adminAnnualTaxFilings.slice'

export interface QAUserDetails {
  pathway: {
    identifier: string
  }
  lastActiveAt: string
  lastResetAt: string
}

export interface QAUser extends Omit<UserWithAdminInfo, 'qaDetails'> {
  qaDetails: QAUserDetails
}

export interface QAUserState {
  [key: number]: QAUser
}

const initialState: QAUserState = {}

const QAUsersSlice = createSlice({
  name: 'qaFeature',
  initialState,
  reducers: {
    setQAUsers: (state, action: PayloadAction<{ [key: string]: QAUser }>) => ({
      ...state,
      ...action.payload,
    }),
    setSingleQAUser: (state, action: PayloadAction<QAUser>) => {
      state[action.payload.id] = action.payload
    },
    removeQAUser: (state, action: PayloadAction<number>) => {
      delete state[action.payload]
    },
    updateQAUser: (state, action: PayloadAction<QAUser>) => {
      state[action.payload.id] = action.payload
    },
  },
})

export default QAUsersSlice.reducer

export const { setQAUsers, setSingleQAUser, removeQAUser, updateQAUser } =
  QAUsersSlice.actions

export const FETCH_QA_USERS_KEY = 'FETCH_QA_USERS_KEY'
export const fetchQAUsers = () =>
  fetchWrapper({
    fetchKey: FETCH_QA_USERS_KEY,
    defaultErrorMessage: 'Error fetching all qa users',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<QAUser[]>('/finances/api/v1/admin/qa/users')
      dispatch(setQAUsers(keyBy(json.data, 'id')))

      return json.data
    },
  })

export const CREATE_QA_USER_KEY = 'CREATE_QA_USER_KEY'
export interface CreateQAUserFormData {
  pathwayIdentifier: string
  taxEntityType?: TAX_ENTITY_TYPES_TYPE
  activeState: 'active' | 'onboarding'
  createNames: boolean
  createEmail: boolean
  // Optional
  firstName?: string // Generated on the back-end if undefined
  lastName?: string // Generated on the back-end if undefined
  email?: string // Generated on the back-end if undefined
  showOnboardingPage?: boolean
}
export const createQAUser = (data: CreateQAUserFormData) =>
  fetchWrapper({
    fetchKey: CREATE_QA_USER_KEY,
    defaultErrorMessage: 'There was an error creating the QA user',
    fetchFunction: async (dispatch) => {
      const json = await axios.post<QAUser>(
        '/finances/api/v1/admin/qa/users',
        data
      )
      dispatch(setSingleQAUser(json.data))
      return json.data
    },
  })
export const DELETE_QA_USER_KEY = (userId: number) =>
  `DELETE_QA_USER_KEY_${userId}`
export const deleteQAUser = (userId: number) =>
  fetchWrapper({
    fetchKey: DELETE_QA_USER_KEY(userId),
    defaultErrorMessage: 'There was an error deleting the QA user',
    fetchFunction: async (dispatch) => {
      const json = await axios.delete<{ id: number }>(
        `/finances/api/v1/admin/qa/users/${userId}`
      )
      dispatch(removeQAUser(json.data.id))
      return true
    },
  })
export const RESET_QA_USER_KEY = (userId: number) =>
  `RESET_QA_USER_KEY_${userId}`
export const resetQAUser = (userId: number) =>
  fetchWrapper({
    fetchKey: RESET_QA_USER_KEY(userId),
    defaultErrorMessage: 'There was an error resetting the QA user',
    fetchFunction: async (dispatch) => {
      const json = await axios.put<QAUser>(
        `/finances/api/v1/admin/qa/users/auth/reset/${userId}`
      )
      dispatch(updateQAUser(json.data))
      return true
    },
  })

export const AUTOCOMPLETE_TQ_KEY = (filingFormId: number) =>
  `AUTOCOMPLETE_TQ_KEY_${filingFormId}`
export const autocompleteTq = (filingFormId: number) =>
  fetchWrapper({
    fetchKey: AUTOCOMPLETE_TQ_KEY(filingFormId),
    defaultErrorMessage:
      'There was an error auto-completing the TQ. Please try again.',
    fetchFunction: async (dispatch) => {
      const json = await axios.post<AdminAnnualTaxFiling>(
        `/finances/api/v1/admin/qa/annual_tax/filing_form/${filingFormId}/submit`
      )
      dispatch(updateSingleAnnualTaxFiling(json.data))
      return true
    },
  })
