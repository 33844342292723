import { useState } from 'react'
import { Grid, Container, Image } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import SignupHeader from '../SignupHeader'
import {
  Button,
  FormikInput,
  makeNumberSchema,
  Text,
  Link,
  makeReqEmailSchema,
  GridRowColumn,
  getFieldNames,
} from '../../../components/BaseComponents'
import { postWaitlistUser } from '../waitlistActions'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { useSignupPageUpdate } from '../../../services/onboardingService'
import { SIGNUP_PATHS } from '../helpers'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useReselector } from '../../../utils/sharedHooks'

const SignupWaitlist = () => {
  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false)
  const user = useReselector(getCurrentUser)

  useSignupPageUpdate(SIGNUP_PATHS.waitlist)

  const formik = useFormik({
    initialValues: {
      email: '',
      numberOfEmployees: '',
    },
    onSubmit: async ({ email, numberOfEmployees }) => {
      const res = await postWaitlistUser({
        email,
        numberOfEmployees: Number(numberOfEmployees),
      })(dispatch)

      if (res) {
        setSubmitted(true)
      }
    },
  })

  const { isValid, isSubmitting } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <Container style={{ paddingTop: 64 }}>
      <Grid>
        <SignupHeader hideProgress />
        {!submitted ? (
          <FormikProvider value={formik}>
            <GridRowColumn centerContent>
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/plant-monstera.svg"
                style={{ height: 300, width: 300 }}
              />
            </GridRowColumn>
            <GridRowColumn width={12} spacer={2}>
              <Text as="display2" textAlign="center">
                {user?.firstName || ''}, congratulations on building a
                flourishing private practice
              </Text>
            </GridRowColumn>
            <GridRowColumn short width={12} spacer={2}>
              <Text as="bodyLg" textAlign="center">
                Currently, we only serve private practices with up to 10
                employees. Join our waitlist for updates on when we can better
                help private practices like yours.
              </Text>
            </GridRowColumn>
            <GridRowColumn width={6} spacer={5}>
              <FormikInput
                label="Email address to receive updates"
                placeholder="jane.smith@privatepractice.com"
                required
                name={fieldNames.email}
                fullWidth
                schema={makeReqEmailSchema({ field: 'email' })}
              />
            </GridRowColumn>
            <GridRowColumn width={6} spacer={5}>
              <FormikInput
                label="Total number of employees"
                description="Include yourself as an employee"
                placeholder="Enter number of employees"
                required
                name={fieldNames.numberOfEmployees}
                fullWidth
                schema={makeNumberSchema({ field: 'number of employees' })}
              />
            </GridRowColumn>
            <Grid.Row />
            <GridRowColumn width={4} spacer={6}>
              <Button
                fullWidth
                onClick={formik.submitForm}
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
              >
                Join Waitlist
              </Button>
            </GridRowColumn>
          </FormikProvider>
        ) : (
          <>
            <GridRowColumn centerContent>
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/check.svg"
                style={{ height: 180, width: 180 }}
              />
            </GridRowColumn>
            <GridRowColumn>
              <Text as="h1" textAlign="center">
                Thanks for joining our waitlist.
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text textAlign="center">
                We’ll reach out if our services change. Good luck!
              </Text>
            </GridRowColumn>
          </>
        )}
        <GridRowColumn width={4} spacer={6}>
          <Link href="https://www.joinheard.com">
            <Button variant={submitted ? 'secondary' : 'link'} fullWidth>
              Return to Site
            </Button>
          </Link>
        </GridRowColumn>
      </Grid>
    </Container>
  )
}

export default SignupWaitlist
