import { useEffect, useMemo } from 'react'
import { Container, Divider, Tab } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'

import AdminUserCallsCard from './AdminUserCallsCard'
import UserBreadcrumbs from '../../../components/Admin/UserRecord/UserBreadcrumbs'
import { getOffsetYear } from '../helpers'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { adminFetchSingleUserIfNeeded } from '../../../actions/admin/adminAllUsersActions'
import { fetchAdminUserCallEventsIfNeeded } from '../../../actions/admin/userCallEventsActions'
import { getPastAllUserCallEventsIds } from '../../../selectors/userCallEventSelectors'
import { useReselector } from '../../../utils/sharedHooks'

const AdminUserCallsPanel = () => {
  const dispatch = useAppDispatch()
  const { userId } = useParams<{ userId: string }>()
  const pastYearCallEventIds = useReselector(
    getPastAllUserCallEventsIds,
    Number(userId)
  )

  useEffect(() => {
    dispatch(fetchAdminUserCallEventsIfNeeded(Number(userId)))
    dispatch(adminFetchSingleUserIfNeeded(Number(userId)))
  }, [dispatch, userId])

  const panes = useMemo(() => {
    const currentYear = getOffsetYear()

    const tabs = [
      {
        menuItem: `${currentYear} Checkins`,
        render: () => (
          <Tab.Pane>
            <AdminUserCallsCard userId={Number(userId)} showPastLink={false} />
          </Tab.Pane>
        ),
      },
    ]

    // Hide past tab if there are no years to display
    if (pastYearCallEventIds.length) {
      tabs.push({
        menuItem: 'Prior Year Checkins',
        render: () => (
          <Tab.Pane>
            <AdminUserCallsCard
              userId={Number(userId)}
              showPastEvents
              showPastLink={false}
            />
          </Tab.Pane>
        ),
      })
    }

    return tabs
  }, [pastYearCallEventIds.length, userId])

  return (
    <Container id="adminPanel">
      <UserBreadcrumbs userId={Number(userId)} pageName={'All User Calls'} />
      <Divider />
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </Container>
  )
}

export default AdminUserCallsPanel
