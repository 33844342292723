export enum BOOKKEEPING_STEPS {
  learnHowBookkeepingWorks = 'learn_how_bookkeeping_works',
  bookkeepingAtHeard = 'bookkeeping_at_heard',
  howToClarifyTransactions = 'how_to_clarify_transactions',
  clarifyTransactionsExample = 'clarify_transactions_example',
  transactionsClarifiedSuccess = 'transactions_clarified_success',
}

// order is important
export const ahaMomentBookkeepingStepsOrder = [
  BOOKKEEPING_STEPS.learnHowBookkeepingWorks,
  BOOKKEEPING_STEPS.bookkeepingAtHeard,
  BOOKKEEPING_STEPS.howToClarifyTransactions,
  BOOKKEEPING_STEPS.clarifyTransactionsExample,
  BOOKKEEPING_STEPS.transactionsClarifiedSuccess,
]
