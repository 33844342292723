import { useCallback, useEffect, useState } from 'react'
import {
  fetchCheckoutSession,
  PAYMENT_SUCCESS_SEARCH_PARAM,
} from '../../actions/settings/billingActions'
import {
  Alert,
  Button,
  Card,
  GridRowColumn,
  Link,
  Text,
} from '../../components/BaseComponents'
import { getCurrentUser } from '../../selectors/user.selectors'
import { useReselector } from '../../utils/sharedHooks'
import { useAppDispatch } from '../../utils/typeHelpers'
import { Grid } from 'semantic-ui-react'
import { useCatchupBookkeepingPrice } from '../Signup/ChoosePlan/hooks'
import {
  selectCatchUpBookkeepingIsUnpaid,
  selectCatchUpBookkeepingStatusForCurrentUser,
  selectTrialUserHasUnpaidCatchUpBookkeeping,
} from '../CatchUpBookkeepingStatus/catchUpBookkeepingStatus.selector'
import { fetchInvoicePaymentUrl } from '../../actions/paymentActions'
import { SIGNUP_PATHS } from '../Signup/helpers'
import { fetchUserCatchUpBookkeepingStatus } from '../CatchUpBookkeepingStatus/catchUpBookkeepingStatus.slice'
import { useNavigate } from 'react-router-dom'

const CatchUpBookkeepingUnpaid = () => {
  const dispatch = useAppDispatch()
  const isUnpaidTrialUser = useReselector(
    selectTrialUserHasUnpaidCatchUpBookkeeping
  )
  const catchUpBkStatus = useReselector(
    selectCatchUpBookkeepingStatusForCurrentUser
  )
  const user = useReselector(getCurrentUser)
  const catchupBookkeepingPrice = useCatchupBookkeepingPrice()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const catchUpBookkeepingIsUnpaid = useReselector(
    selectCatchUpBookkeepingIsUnpaid
  )
  const navigate = useNavigate()

  useEffect(() => {
    fetchUserCatchUpBookkeepingStatus()(dispatch)
  }, [dispatch])

  useEffect(() => {
    // if user has already paid, redirect to dashboard
    if (!catchUpBookkeepingIsUnpaid) {
      navigate('/dashboard')
    }
  }, [catchUpBookkeepingIsUnpaid, navigate])

  const getPaymentLink = useCallback(async () => {
    if (catchUpBkStatus?.invoiceId) {
      const url = await fetchInvoicePaymentUrl(catchUpBkStatus.invoiceId)(
        dispatch
      )
      return url ?? null
    }
    if (!user?.id || !catchupBookkeepingPrice) {
      return null
    }
    const res = await fetchCheckoutSession({
      userId: user.id,
      lineItems: [
        {
          priceId: catchupBookkeepingPrice.priceId,
          quantity: catchupBookkeepingPrice.monthCount,
        },
      ],
      success_url: {
        url: user.acquisitionSource
          ? `/dashboard?${PAYMENT_SUCCESS_SEARCH_PARAM}=true`
          : `${SIGNUP_PATHS.success}?${PAYMENT_SUCCESS_SEARCH_PARAM}=true`,
      },
      cancel_url: {
        url: `${location.pathname}${location.search}`,
      },
      mode: 'payment',
    })(dispatch)
    return res?.url ?? null
  }, [
    user?.id,
    catchupBookkeepingPrice,
    catchUpBkStatus?.invoiceId,
    dispatch,
    user?.acquisitionSource,
  ])

  const redirectToCheckout = useCallback(async () => {
    setLoading(true)
    const url = await getPaymentLink()
    if (url) {
      window.location.href = url
    } else {
      setError(
        'Could not retrieve payment link. Please refresh the page and try again. If the error persists, please contact support.'
      )
    }
    setLoading(false)
  }, [getPaymentLink])

  return (
    <Card
      variant="noShadow"
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: '81px 230px',
      }}
    >
      <Grid>
        <GridRowColumn>
          <Text as="h1" textAlign="center">
            Almost there: Pay Catch Up Bookkeeping Fee
          </Text>
        </GridRowColumn>
        {isUnpaidTrialUser && (
          <GridRowColumn>
            <Text>
              Thank you for trying Heard! We&apos;re excited to have you
              officially join our community of financially empowered therapists.
              During your trial, we began organizing your past financial records
              (this is known as catch up bookkeeping) to ensure accurate
              reporting and tax estimates. To re-gain full access to Heard, a
              one-time catch up bookkeeping fee is required. This covers the
              work done to bring your books up to date.
            </Text>
          </GridRowColumn>
        )}
        {!isUnpaidTrialUser && (
          <GridRowColumn>
            <Text>
              We&apos;re excited for you to start with Heard! Before onboarding,
              there&apos;s one last step: paying a one-time catch up bookkeeping
              fee. This ensures your financial data and tax estimates are
              accurate from day one.
            </Text>
          </GridRowColumn>
        )}
        <GridRowColumn
          columnStyle={{ display: 'flex', justifyContent: 'center' }}
        >
          <Button
            disabled={loading}
            loading={loading}
            onClick={redirectToCheckout}
          >
            {isUnpaidTrialUser ? 'Unlock my access' : 'Activate my account'}
          </Button>
        </GridRowColumn>
        {error && (
          <GridRowColumn>
            <Alert type="error">{error}</Alert>
          </GridRowColumn>
        )}
        <GridRowColumn>
          <Text>
            Questions? We&apos;re here to help:{' '}
            <Link as="secondaryLink" href="mailto:contact@joinheard.com">
              contact@joinheard.com
            </Link>
            .
          </Text>
        </GridRowColumn>
      </Grid>
    </Card>
  )
}

export default CatchUpBookkeepingUnpaid
