import { GridRowColumn } from '../../../../components/BaseComponents'
import TaxChecklistPromptCard1040 from '../TaxChecklist/TaxChecklistPromptCard1040'
import TaxChecklistPromptCard1120s from '../TaxChecklist/TaxChecklistPromptCard1120s'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectFilingFormsForYear } from '../annualTaxFilingForms.selector'
import {
  selectFileExtensionStatusForYear,
  selectShowFileExtensionForYear,
} from '../annualTaxFilings.selector'
import { AnnualTaxFilingStepStatus } from '../constants'
import { TAX_ENTITY_TYPES } from '../../taxConstants'

const FormPromptCards = ({ year }: { year: string }) => {
  const needsExtension = useReselector(selectShowFileExtensionForYear, year)
  const filingExtensionStatus = useReselector(
    selectFileExtensionStatusForYear,
    year
  )
  const currentAnnualTaxFilingForms = useReselector(
    selectFilingFormsForYear,
    year
  )

  if (
    needsExtension &&
    filingExtensionStatus !== AnnualTaxFilingStepStatus.completeLocked
  ) {
    return null
  }

  return Object.values(currentAnnualTaxFilingForms).map((filingForm) => (
    <GridRowColumn key={`TaxCenterPromptCard__${filingForm.id}`}>
      {filingForm.formType.name === TAX_ENTITY_TYPES.form_1040 ? (
        <TaxChecklistPromptCard1040 filingForm={filingForm} />
      ) : (
        <TaxChecklistPromptCard1120s filingForm={filingForm} />
      )}
    </GridRowColumn>
  ))
}

export default FormPromptCards
