import { CSSProperties, ReactNode, useMemo } from 'react'
import {
  Container as SemanticContainer,
  StrictContainerProps,
} from 'semantic-ui-react'

import Card from './Card'
import { Colors } from '../../styles/theme'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'

const Container = ({
  children,
  isFullWidthTablet,
  variant,
  ...rest
}: {
  children: ReactNode
  isFullWidthTablet?: boolean
  variant?: 'regular' | 'disabled' | 'emphasis' | 'raised' | 'noShadow'
  id?: string
} & StrictContainerProps) => {
  const isTablet = useIsDeviceWidth(DeviceWidth.tablet)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const containerStyle = useMemo(() => {
    const style: CSSProperties = {}
    if (isTablet && isFullWidthTablet) {
      style.width = '100%'
    }
    if (isMobile) {
      style.backgroundColor = Colors.white
      style.width = '100%'
    }
    return style
  }, [isTablet, isFullWidthTablet, isMobile])

  return (
    <SemanticContainer
      {...rest}
      style={containerStyle}
      className="card-container"
    >
      <Card
        backgroundColor="white"
        style={isMobile ? { padding: '32px 24px' } : {}}
        variant={variant}
      >
        {children}
      </Card>
    </SemanticContainer>
  )
}

export default Container
