import { useMemo, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { isValidPhoneNumber } from 'react-phone-number-input'

import {
  generateOTP,
  enable2FA,
  POST_GENERATE_OTP_KEY,
  POST_ENABLE_2FA_KEY,
} from '../../../actions/authActions'
import { updateUser } from '../../../actions/userActions'
import OneTimePasscodeInput from '../../shared/OneTimePasscodeInput'
import Button from '../../BaseComponents/Button'
import { Alert, GridRowColumn, Input, Modal, Text } from '../../BaseComponents'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { useReselector } from '../../../utils/sharedHooks'
import { selectErrorsForKeys } from '../../../reducers/fetch'
import { ResponsiveHeader } from './ResponsiveHeader'
import { useAppDispatch } from '../../../utils/typeHelpers'

const TwoFactorAuthenticationModal = ({
  close,
  open,
}: {
  close: () => void
  open: boolean
}) => {
  const dispatch = useAppDispatch()
  const user = useReselector(getCurrentUser)
  const [screen, setScreen] = useState('start')
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '')
  const [otp, setOtp] = useState('')
  const [resentCode, setResentCode] = useState(false)
  const errors = useReselector(selectErrorsForKeys, [
    POST_GENERATE_OTP_KEY,
    POST_ENABLE_2FA_KEY,
  ])

  const errorView = useMemo(
    () => (
      <>
        {errors.map((error) => (
          <GridRowColumn key={error.message}>
            <Alert type="error" title={error.message} />
            <br />
          </GridRowColumn>
        ))}
      </>
    ),
    [errors]
  )

  const isValid = () => phoneNumber && isValidPhoneNumber(phoneNumber)

  const generatePasscode = async () => {
    const result = await generateOTP({ phoneNumber })(dispatch)
    if (result) {
      setScreen('code')
    }
  }

  const validateAndEnable = async () => {
    const result = await enable2FA({
      passcode: otp,
      phoneNumber,
    })(dispatch)
    if (result) {
      setScreen('success')
    }
    setResentCode(false)
  }

  if (screen === 'code') {
    return (
      <Modal
        size="tiny"
        dimmer="inverted"
        className="twoFactorAuthenticationModal"
        open={open}
        onClose={() => {
          setScreen('start')
          close()
        }}
      >
        <Modal.Content>
          <Grid>
            <GridRowColumn>
              <Text as="h2">Secure your account with 2FA</Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text>
                We&#39;ve sent a 6 digit verification code to your number ending
                in: {phoneNumber.slice(-4)}. This may take a couple of minutes
                to arrive.
              </Text>
            </GridRowColumn>
            {errorView}
            <GridRowColumn>
              <OneTimePasscodeInput otp={otp} handleOTPChange={setOtp} />
            </GridRowColumn>
            <GridRowColumn>
              <Button
                variant="link"
                onClick={() => {
                  generatePasscode()
                  setResentCode(true)
                }}
              >
                Didn&#39;t receive a text? Resend code.
              </Button>
            </GridRowColumn>
            {resentCode && (
              <GridRowColumn>
                <Text>
                  <i>Code resent.</i>
                </Text>
              </GridRowColumn>
            )}
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            variant="secondary"
            onClick={() => {
              setScreen('start')
              close()
            }}
          >
            Close
          </Button>
          <Button disabled={!otp} onClick={() => validateAndEnable()}>
            Continue
          </Button>
        </Modal.Actions>
      </Modal>
    )
  } else if (screen === 'success') {
    return (
      <Modal
        size="tiny"
        dimmer="inverted"
        className="twoFactorAuthenticationModal"
        open={open}
        onClose={close}
      >
        <Modal.Content>
          <Grid>
            <GridRowColumn>
              <Text as="h2">Your account is secured!</Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text>
                We&#39;ve now enabled two-factor authentication for your
                account. When logging into Heard, you will now be required to
                enter a verification code.
              </Text>
            </GridRowColumn>
            {errorView}
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={close}>Close</Button>
        </Modal.Actions>
      </Modal>
    )
  }

  return (
    <Modal
      size="tiny"
      dimmer="inverted"
      className="twoFactorAuthenticationModal"
      open={open}
      onClose={close}
    >
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text as="h2">Secure your account with 2FA</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              Two-factor authentication is an extra layer of security. Once
              enabled, you&#39;ll be required to give two types of
              identification when you log into Heard.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>We currently only allow 2FA via text message.</Text>
          </GridRowColumn>
          {errorView}
          <GridRowColumn>
            <Input
              label="Enter your phone number:"
              componentType="phone"
              placeholder="(000) 000-0000"
              value={phoneNumber}
              onChange={setPhoneNumber}
            />
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              <i>
                You&#39;ll receive a 6-digit verification by text message. Text
                or data rates may apply.
              </i>
            </Text>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
        <Button disabled={!isValid()} onClick={generatePasscode}>
          Continue
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const TwoFactorAuthentication = () => {
  const dispatch = useAppDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const user = useReselector(getCurrentUser)

  const disable2FA = async () => {
    if (!user?.id) {
      return
    }

    const result = await updateUser(user.id, {
      twoFactorAuthEnabled: false,
    })(dispatch)
    if (result) {
      setConfirmOpen(false)
    }
  }

  return (
    <div>
      <Grid>
        <GridRowColumn>
          <ResponsiveHeader content="Two-factor Authentication" />
        </GridRowColumn>
        <GridRowColumn>
          <Text>
            Two-factor authentication (2FA) is an extra layer of security. Once
            enabled, you&#39;ll be required to give two types of identification
            when you log into Heard. We currently support 2FA via SMS only.
          </Text>
        </GridRowColumn>
        <GridRowColumn short>
          {user?.twoFactorAuthEnabled ? (
            <>
              <Text>
                <i>
                  Two-factor authentication is enabled with your phone number
                  ending in: {user.phoneNumber?.slice(-4)}
                </i>
              </Text>
              <Button variant="secondary" onClick={() => setConfirmOpen(true)}>
                Turn off
              </Button>
              <Modal
                size="tiny"
                dimmer="inverted"
                className="twoFactorAuthenticationModal"
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
              >
                <Modal.Content>
                  <Grid>
                    <GridRowColumn>
                      <Text as="h2">Turn off two-factor authentication?</Text>
                    </GridRowColumn>
                    <GridRowColumn>
                      <Text>
                        You&#39;ll need to reconfigure your two-factor
                        authentication if you decide to start using it again.
                      </Text>
                    </GridRowColumn>
                  </Grid>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    variant="secondary"
                    onClick={() => setConfirmOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button onClick={disable2FA}>Turn Off</Button>
                </Modal.Actions>
              </Modal>
            </>
          ) : (
            <Button onClick={() => setModalOpen(true)}>Set up</Button>
          )}
        </GridRowColumn>
      </Grid>

      <TwoFactorAuthenticationModal
        open={modalOpen}
        close={() => {
          setModalOpen(false)
        }}
      />
    </div>
  )
}

export default TwoFactorAuthentication
