import { useState, useEffect, useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid, Divider, Loader } from 'semantic-ui-react'
import { sumBy } from 'lodash'

import CurrencyFormatLabel from '../shared/CurrencyFormatLabel'
import AllocationRuleForm from './AllocationRuleForm'
import { Button, Card, Text, Alert } from '../BaseComponents'
import { fetchAllocationGuidesIfNeeded } from '../../actions/allocationGuideActions'
import {
  fetchAllocationRulesIfNeeded,
  editAllocationRuleset,
  FETCH_ALLOCATION_RULES_KEY,
} from '../../actions/allocationRuleActions'
import {
  ExpensesSummary,
  generateSummary,
} from '../../services/allocationGuideService'
import { getAllocationRuleArray } from '../../selectors/allocationGuideSelectors'
import { AllocationRule } from '../../reducers/finances/allocationRulesReducer'
import { Colors } from '../../styles/theme'
import { markUserActionItemCompleteIfExists } from '../../features/Dashboard/UserActionItems/service'
import {
  PostOnboardingTaskIdents,
  useIsOnboardingNav,
} from '../../features/Onboarding/config'
import { DEFAULT_ALLOCATION_RULES } from './AllocationConstants'
import { getIsFetching } from '../../reducers/fetch'
import { useReselector } from '../../utils/sharedHooks'
import { useAnalyticsTrack } from '../../features/Amplitude'
import PageHeader from '../shared/PageHeader'
import { useAppDispatch } from '../../utils/typeHelpers'

const flexStyles = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
}

const MonthlySummary = ({
  generatedSummaries,
  loading,
}: {
  generatedSummaries: ExpensesSummary | null
  loading: boolean
}) => {
  const avg = generatedSummaries?.average
  const summaries = generatedSummaries?.summaries

  if (loading) {
    return (
      <div className="centered">
        <Loader inline active />
      </div>
    )
  }
  if (avg?.noData) {
    return (
      <Card type="section">
        <Text as="h3" style={{ marginBottom: 4 }}>
          It looks like you&apos;re new here!
        </Text>
        <Text as="bodyMd">
          It looks like this is your first month with Heard! This means that we
          don&apos;t have any historical data (yet) to present here, but we
          encourage you to still set up your allocations. You can always revisit
          and make changes as we learn more about your business.
        </Text>
      </Card>
    )
  }

  /* This means this is a user with no data yet*/

  return (
    <>
      <Alert customIcon="👉">
        Quick note: the numbers you see below are based off your categorized
        transactions at the current time, and may not be reflective of all
        transactions from your accounts. We suggest using it purely as an
        estimate.
      </Alert>
      <br />
      <Card.Group itemsPerRow={4}>
        {summaries?.map((summary) => (
          <Card
            type="section"
            key={`summary-row-${summary.month}`}
            style={{ padding: 24 }}
          >
            <Card.Content textAlign="center">
              <Text as="h3">{summary.month}</Text>
              <br />
              {summary.noData && (
                <Text as="bodySm">
                  It looks like we have no data for this month!
                </Text>
              )}
              {!summary.noData && (
                <>
                  <Text as="bodySm" style={flexStyles}>
                    Income
                    <CurrencyFormatLabel value={summary.income} />
                  </Text>
                  <Text as="bodySm" style={flexStyles}>
                    Expenses
                    <CurrencyFormatLabel value={summary.expenses} />
                  </Text>
                  <Text as="bodySm" style={flexStyles}>
                    <b>Net Profit</b>
                    {/*  subtract here because we've ABS the values */}
                    <CurrencyFormatLabel
                      value={summary.income - summary.expenses}
                    />
                  </Text>
                </>
              )}
            </Card.Content>
          </Card>
        ))}
        <Card type="section" style={{ padding: 24 }}>
          <Card.Content>
            <Text as="h3">Average from past 3 months</Text>
            <br />
            <Text as="bodySm" style={flexStyles}>
              Average Income
              <CurrencyFormatLabel value={avg?.income} />
            </Text>
            <Text as="bodySm" style={flexStyles}>
              Average Expenses
              <CurrencyFormatLabel value={avg?.expenses} />
            </Text>
            <Text as="bodySm" style={flexStyles}>
              <b>Average Profit</b>
              <CurrencyFormatLabel
                value={(avg?.income ?? 0) - (avg?.expenses ?? 0)}
              />
            </Text>
          </Card.Content>
        </Card>
      </Card.Group>
    </>
  )
}

/*
  Form used to create OR update a user's allocation guide
*/
const AllocationUpdateForm = ({ edit }: { edit: boolean }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isOnboarding = useIsOnboardingNav()

  const existingAllocationRules = useReselector(getAllocationRuleArray)
  const isFetchingAllocationRules = useReselector(
    getIsFetching,
    FETCH_ALLOCATION_RULES_KEY
  )

  const [step, setStep] = useState(0)
  const [allocationRules, setAllocationRules] = useState<AllocationRule[]>(
    existingAllocationRules
  )
  const [percentageSum, setPercentageSum] = useState(0)
  const [generatingSummary, setGeneratingSummary] = useState(true)
  const [summaries, setSummaries] = useState<ExpensesSummary | null>(null)
  const [submitting, setSubmitting] = useState(false)
  const [submissionError, setSubmissionError] = useState(false)

  const handlePercentageSummation = useCallback(() => {
    setPercentageSum(
      sumBy(allocationRules, (rule) => Number(rule.percentage) || 0)
    )
  }, [allocationRules])

  useEffect(() => {
    if (existingAllocationRules.length) {
      setAllocationRules(existingAllocationRules)
    }
  }, [existingAllocationRules])

  useEffect(() => {
    async function fetchData() {
      const generated = await generateSummary()
      setSummaries(generated)
      setGeneratingSummary(false)
    }

    fetchData()
  }, [])

  useEffect(() => {
    handlePercentageSummation()
  }, [allocationRules, handlePercentageSummation])

  const track = useAnalyticsTrack()

  const submitAllocationRules = async () => {
    track('submitted allocation guide edit')
    setSubmitting(true)
    const successfulUpdate =
      await editAllocationRuleset(allocationRules)(dispatch)
    if (!successfulUpdate) {
      setSubmissionError(true)
    } else {
      markUserActionItemCompleteIfExists(
        PostOnboardingTaskIdents.SET_UP_HOW_YOUR_EARNINGS_ARE_ALLOCATED,
        (event, properties) => track(event, properties)
      )
      setStep(2)
    }
  }

  const renderProgress = () => {
    if (edit) {
      return (
        <Grid doubling stackable columns={2} className="compact">
          <Grid.Column>
            <Grid.Row>
              <div
                style={{
                  backgroundColor: Colors.green,
                  height: 2,
                  marginBottom: 4,
                }}
              />
              <Text as="h3" color="green">
                1. Edit Allocation
              </Text>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column>
            <Grid.Row>
              <div
                style={{
                  backgroundColor:
                    step !== 0 ? Colors.green : Colors.mediumGray,
                  height: 2,
                  marginBottom: 4,
                }}
              />
              <Text as="h3" {...(step !== 0 && { color: 'green' })}>
                2. Review &amp; Confirm Edit
              </Text>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      )
    }

    return (
      <Grid doubling stackable columns={2} className="compact">
        <Grid.Column>
          <Grid.Row>
            <div
              style={{
                backgroundColor: Colors.green,
                height: 2,
                marginBottom: 4,
              }}
            />
            <Text as="h3" color="green">
              1. Determine your monthly allocation
            </Text>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column>
          <Grid.Row>
            <div
              style={{
                backgroundColor: step !== 0 ? Colors.green : Colors.mediumGray,
                height: 2,
                marginBottom: 4,
              }}
            />
            <Text as="h3" {...(step !== 0 && { color: 'green' })}>
              2. Review &amp; Confirm
            </Text>
          </Grid.Row>
        </Grid.Column>
      </Grid>
    )
  }

  if (step === 2) {
    return (
      <>
        {renderProgress()}
        <br />
        <Card type="section">
          <Text as="h3">
            Congrats, you&#39;ve successfully set up your Allocation Guide!
            You&#39;re on your way to organizing your practice finances!
          </Text>
          <br />
          <Text as="bodyMd">
            Your next allocation guide will be sent to you{' '}
            <b>on the 15th of the next month</b>, after we&#39;ve gone through
            and complete your books. In this allocation guide, we will provide
            suggested amounts to allocate based on your previous months&apos;
            earnings and your allocation preferences.
          </Text>
          <br />
          <Button
            onClick={() => navigate(isOnboarding ? '/' : '/allocation-guides')}
          >
            Back to {isOnboarding ? 'Allocations' : 'Dashboard'}
          </Button>
        </Card>
      </>
    )
  } else if (step === 1) {
    return (
      <>
        {renderProgress()}
        <br />
        <Alert customIcon="💡">
          Review your allocation preferences below, then confirm to apply them
          to your account. You can adjust your allocations at any time based on
          your personal preferences.
        </Alert>

        <Card type="section">
          <Text as="h2">
            Here&#39;s how you decided to allocate your monthly earnings:
          </Text>
          <br />
          {submissionError && (
            <Alert style={{ margin: '16px 0 8px' }} type="error">
              We&#39;re having difficulties saving your allocation guide! Please
              try again.
            </Alert>
          )}
          <Grid doubling stackable padded>
            <Grid.Row>
              <Grid.Column width={10}>
                <Text as="h3">Allocation Type</Text>
              </Grid.Column>
              <Grid.Column width={6}>
                <Text as="h3">Percentage of Profit to Allocate</Text>
              </Grid.Column>
            </Grid.Row>
            {allocationRules.map((rule) => (
              <Grid.Row key={`review-rule-row-${rule.ruleName}`}>
                <Grid.Column width={10}>
                  <Text as="h3">{rule.ruleName}</Text>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Text as="h3">{rule.percentage}%</Text>
                </Grid.Column>
              </Grid.Row>
            ))}
            <Divider />
            <Grid.Row>
              <Grid.Column width={10} />
              <Grid.Column width={6}>
                <Text as="h3">Total % of Profit Allocated: </Text>
                <br />
                <Text as="h3">{percentageSum}%</Text>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card>
        <Divider />
        <div style={flexStyles}>
          <Button onClick={() => setStep(0)} variant="secondary">
            Back
          </Button>
          <Button
            disabled={submitting}
            onClick={() => submitAllocationRules()}
            loading={submitting}
            id="btn-allocation-guide-creation-confirm"
          >
            Confirm &amp; Set
          </Button>
        </div>
      </>
    )
  }
  return (
    <>
      {renderProgress()}
      <br />
      <Alert customIcon="💡">
        <b>
          Set up your Financial Allocation Guide to decide how much money to set
          aside for taxes, retirement, paychecks and more.{' '}
        </b>
        On the 15th of every month, we&apos;ll send over a &ldquo;paystub&rdquo;
        with suggested dollar amounts, based on your previous months&apos;
        earnings.
      </Alert>
      <br />
      <Text as="h3">First, review your last 3 months&apos; earnings</Text>
      <br />
      <MonthlySummary
        generatedSummaries={summaries}
        loading={generatingSummary}
      />
      <br />
      <Text as="h3">
        Next, determine your allocations for your monthly profit
      </Text>
      {isFetchingAllocationRules ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Loader centered inline active />
        </div>
      ) : (
        <AllocationRuleForm
          initialRules={
            existingAllocationRules.length !== 0
              ? existingAllocationRules
              : DEFAULT_ALLOCATION_RULES
          }
          percentageSum={percentageSum}
          progressForward={() => setStep(1)}
          updateRules={setAllocationRules}
        />
      )}
    </>
  )
}

const AllocationCreation = () => {
  const { state } = useParams<{ state: string }>()
  const dispatch = useAppDispatch()

  const edit = useMemo(() => state !== 'new', [state])

  useEffect(() => {
    dispatch(fetchAllocationGuidesIfNeeded())
    dispatch(fetchAllocationRulesIfNeeded())
  }, [dispatch])

  return (
    <div id="allocationCreationPanel">
      <PageHeader
        header={
          edit
            ? 'Edit your monthly allocation guide'
            : 'Set up your monthly allocation guide'
        }
      />
      <Card type="subsection" backgroundColor="stone40">
        <AllocationUpdateForm edit={edit} />
      </Card>
    </div>
  )
}

export default AllocationCreation
