import styled from 'styled-components'
import { Colors, Fonts } from '../../../styles/theme'

interface Props {
  $current?: boolean
  disabled?: boolean
  $hoverable?: boolean
}

const PaginationButton = styled.button<Props>(
  ({ $current = false, disabled = false, $hoverable = true }) => ({
    ...Fonts.buttonMd,

    // reset default button styling
    background: 'none',
    border: 'none',

    alignItems: 'center',
    borderRadius: 8,
    color: disabled ? Colors.mediumGray : Colors.black,
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    padding: '8px 16px',
    width: 40,

    ...($hoverable &&
      !disabled && {
        cursor: 'pointer',

        ...(!$current && {
          '&:hover': {
            background: Colors.stone,
          },
        }),
      }),

    ...($current &&
      (disabled
        ? {
            backgroundColor: Colors.lightGray,
          }
        : {
            backgroundColor: `${Colors.green} !important`,
            color: Colors.white,
          })),

    'i.icon': {
      alignItems: 'center',
      display: 'flex',
      fontSize: 16,
      justifyContent: 'center',
      margin: 0,
    },
  })
)

export default PaginationButton
