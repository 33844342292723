import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Image } from 'semantic-ui-react'

import {
  Button,
  GridRowColumn,
  Link,
  Text,
} from '../../../../components/BaseComponents'
import { updateFinancialProfile } from '../../../../actions/financialProfileActions'
import { useReselector } from '../../../../utils/sharedHooks'
import { getFinancialProfile } from '../../../../selectors/user.selectors'
import {
  dateWithoutTimestamp,
  useCompleteQTEActionItemIfNeeded,
} from '../../QuarterlyTaxEstimates/service'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const FinishTaxProfile = () => {
  const fp = useReselector(getFinancialProfile)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(
      updateFinancialProfile(fp?.id, {
        taxProfileLastReviewed: dateWithoutTimestamp().format(
          DATE_FORMATS.TIMESTAMP
        ),
      })
    )
  }, [dispatch, fp?.id])

  useCompleteQTEActionItemIfNeeded()

  return (
    <Grid>
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/deductions/clipboard.svg"
          style={{ height: 120, width: 120 }}
        />
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([12, 16, 16], true)}>
        <Text as="display" textAlign="center">
          Thanks for updating your tax information!
        </Text>
      </GridRowColumn>
      <GridRowColumn centerContent>
        <Button
          variant="secondary"
          onClick={() => navigate('/taxes/quarterly')}
        >
          Return to Quarterly Taxes
        </Button>
      </GridRowColumn>
      <GridRowColumn centerContent>
        <Link to="/practice/profile/editTax">Edit Tax Information</Link>
      </GridRowColumn>
      <Grid.Row />
    </Grid>
  )
}

export default FinishTaxProfile
