import { FormikProvider, useFormik } from 'formik'
import { Grid } from 'semantic-ui-react'

import {
  UPDATE_EMAIL_KEY,
  updateUserEmail,
  VERIFY_PASSWORD_KEY,
  verifyPassword,
} from '../../../actions/authActions'
import {
  Alert,
  Button,
  FormikInput,
  getFieldName,
  GridRowColumn,
  makeReqEmailSchema,
  makeReqStringSchema,
  Modal,
  Text,
} from '../../BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { selectErrorsForKeys } from '../../../reducers/fetch'
import { useAppDispatch } from '../../../utils/typeHelpers'

const ChangeEmailModal = ({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) => {
  const dispatch = useAppDispatch()
  const errors = useReselector(selectErrorsForKeys, [
    VERIFY_PASSWORD_KEY,
    UPDATE_EMAIL_KEY,
  ])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async ({ email, password }) => {
      const passwordResponse = await verifyPassword({ password })(dispatch)
      if (!passwordResponse) {
        return
      }

      const emailResponse = await updateUserEmail({ newEmail: email })(dispatch)
      if (emailResponse) {
        close()
      }
    },
  })

  return (
    <FormikProvider value={formik}>
      <Modal
        size="tiny"
        dimmer="inverted"
        open={open}
        closeIcon
        onClose={close}
      >
        <Modal.Header>Change Your Email Address</Modal.Header>
        <Modal.Content>
          <Grid>
            {Boolean(errors.length) && (
              <GridRowColumn>
                <Alert type="error">{errors[0].message}</Alert>
              </GridRowColumn>
            )}
            <GridRowColumn>
              <Text>
                To change your email address, enter your password and your new
                email address.
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <FormikInput
                name={getFieldName<typeof formik.values>('password')}
                label="Password"
                type="password"
                required
                fullWidth
                schema={makeReqStringSchema()}
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikInput
                name={getFieldName<typeof formik.values>('email')}
                label="New Email"
                type="email"
                required
                fullWidth
                schema={makeReqEmailSchema()}
              />
            </GridRowColumn>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button variant="secondary" onClick={close}>
            Cancel
          </Button>
          <Button onClick={formik.submitForm} disabled={!formik.isValid}>
            Update Email
          </Button>
        </Modal.Actions>
      </Modal>
    </FormikProvider>
  )
}

export default ChangeEmailModal
