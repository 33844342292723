import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button, Icon, Modal, Text } from '../../BaseComponents'
import { Colors } from '../../../styles/theme'
import { useEffect, useState } from 'react'
import {
  selectEligibleForPlaidStatements,
  selectPlaidItems,
  selectPlaidStatementInstitutions,
} from '../../../selectors/financeSelectors'
import { fetchPlaidItemsIfNeeded } from '../../../actions/plaidItemActions'
import { fetchPlaidStatementInstitutionsIfNeeded } from '../../../actions/plaidStatementInstitutionActions'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAnalyticsTrack } from '../../../features/Amplitude'
import { values } from 'lodash'
import { useReselector } from '../../../utils/sharedHooks'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../../features/OpenFeature'

export const PlaidStatementReloginModal = () => {
  const dispatch = useAppDispatch()
  const [showModal, setShowModal] = useState(false)
  const [fetching, setFetching] = useState(true)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const track = useAnalyticsTrack()
  const user = useReselector(getCurrentUser)
  const enableLowerFrictionStatements = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableLowerFrictionStatements,
    false
  )
  const hasEligibleInstitution = useReselector(
    selectEligibleForPlaidStatements,
    enableLowerFrictionStatements
  )
  const plaidItems = useReselector(selectPlaidItems)
  const plaidStatementInstitutions = useReselector(
    selectPlaidStatementInstitutions
  )

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchPlaidItemsIfNeeded())
      await dispatch(fetchPlaidStatementInstitutionsIfNeeded())
      setFetching(false)
    }

    fetch()
  }, [dispatch])

  useEffect(() => {
    const userHasSeen = localStorage.getItem('plaidStatementReloginModalShown')
    const plaidStatementSource = searchParams.get('psSrc')

    if (
      !fetching &&
      !userHasSeen &&
      !plaidStatementSource &&
      !showModal &&
      user?.onboardingItemsCompletedAt &&
      hasEligibleInstitution
    ) {
      localStorage.setItem('plaidStatementReloginModalShown', 'true')
      setShowModal(true)
    }
  }, [
    showModal,
    searchParams,
    fetching,
    hasEligibleInstitution,
    user?.onboardingItemsCompletedAt,
  ])

  const closeModal = () => {
    track('close plaid statement relogin modal')
    setShowModal(false)
  }

  return (
    <Modal closeIcon open={showModal} size="tiny" onClose={closeModal}>
      <Modal.Header>Tired of uploading statements?</Modal.Header>
      <Modal.Content>
        <div
          style={{
            backgroundColor: Colors.champagne,
            borderRadius: 8,
            padding: 24,
          }}
        >
          <img
            src="https://heard-images.s3.amazonaws.com/assets/statement_upload.svg"
            alt="banner"
            height={180}
            width={180}
            style={{ display: 'block', margin: '0 auto' }}
          />
        </div>

        <Text style={{ marginTop: 16 }}>
          Some of your financial institutions now{' '}
          <strong>support automatic statement uploads</strong>. After setting
          this up, you won&apos;t have to manually upload statements every month
          for those accounts!
        </Text>

        <Text as="h3" style={{ marginTop: 16, color: Colors.accentGreen }}>
          <Icon icon={solid('circle-check')} color="accentGreen" size="1x" />{' '}
          Supported financial institutions
        </Text>

        <ul style={{ paddingLeft: 30 }}>
          {values(plaidItems)
            .filter((pi) =>
              values(plaidStatementInstitutions).some(
                (psi) => psi.institutionId === pi.institutionId
              )
            )
            .map((pi) => (
              <li key={pi.id}>{pi.institutionName}</li>
            ))}
        </ul>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={closeModal}>
          Maybe Later
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setShowModal(false)
            navigate('/accounts?psSrc=relogin_modal#connected-institutions')
          }}
        >
          View in Settings
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
