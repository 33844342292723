import { chain } from 'lodash'
import { UserTaxEstimate } from './userTaxEstimates.slice'

// obtain the estimate's quarter 1,2,3,4 from string e.g. 2022-1
export const splitTaxYearQuarter = (yearTaxVal: string | undefined) => ({
  year: yearTaxVal ? yearTaxVal.split('-')[0] : undefined,
  quarter: yearTaxVal ? yearTaxVal.split('-')[1] : undefined,
})

export const formatTaxQuarter = (valueFromDatabase: string) => {
  const { quarter, year } = splitTaxYearQuarter(valueFromDatabase)

  if (quarter && year) {
    return `Q${quarter} ${year}`
  }

  return valueFromDatabase
}

const formatFilingState = (filingState: string) => `State - ${filingState}`

/*
  Sort the estimates by taxQuarter and filing state, then
  group them by taxQuarter, e.g. '2022-1': UserTaxEstimate[]
*/
export const sortAndGroupEstimatesByTaxQuarter = (
  estimates: UserTaxEstimate[]
) =>
  chain(estimates)
    .sortBy((e) => e.taxQuarter)
    .sortBy((e) => formatFilingState(e.filingState ?? ''))
    .groupBy((e) => e.taxQuarter)
    .value()
