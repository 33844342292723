import { useCallback, useRef } from 'react'
import { Grid } from 'semantic-ui-react'

import GustoAddress from '../GustoAddress'
import {
  POST_CREATE_COMPANY_LOCATION_KEY,
  postCreateCompanyLocation,
} from '../payrollActions'
import PayrollError from '../PayrollError'
import { Button, Modal } from '../../../components/BaseComponents'
import { useAppDispatch } from '../../../utils/typeHelpers'

const WorkAddressModal = ({ closeModal }: { closeModal: () => void }) => {
  const dispatch = useAppDispatch()
  const addressRef = useRef<GustoAddress>(null)

  const addAddress = useCallback(async () => {
    const address = addressRef.current?.getAddressWithPhone()
    if (!address) {
      return
    }

    const res = await postCreateCompanyLocation({
      ...address,
      filing_address: false,
      mailing_address: false,
    })(dispatch)

    if (res) {
      closeModal()
    }
  }, [closeModal, dispatch])

  return (
    <Modal open size="tiny" onClose={closeModal} closeIcon>
      <Modal.Header>Add New Location</Modal.Header>
      <Modal.Content>
        <Grid>
          <PayrollError fetchKey={POST_CREATE_COMPANY_LOCATION_KEY} />
          <Grid.Row>
            <Grid.Column>
              <GustoAddress ref={addressRef} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Button onClick={addAddress} fullWidth>
                Add New Location
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Button onClick={closeModal} variant="secondary" fullWidth>
                Cancel
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

export default WorkAddressModal
