import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { createInstance, Identify } from '@amplitude/analytics-browser'
import moment from 'moment'

import {
  getCurrentUser,
  getFinancialProfile,
  getIsRequiredStepsOnboarding,
  getUserIsAdmin,
  selectMembershipIsIn,
  selectPrimaryMembership,
} from '../../selectors/user.selectors'
import { removeAmplitudeTrackingParams } from '../../services/onboardingService'
import { useReselector } from '../../utils/sharedHooks'
import { urlSearchParamsToObject } from './utils'
import { fetchFinancialProfileIfNeeded } from '../../actions/financialProfileActions'
import { fetchUserFocusAreas } from '../Signup/userFocusAreaActions'
import {
  fetchSubscriptions,
  selectHeardProductSubscription,
} from '../../reducers/subscription.slice'
import { fetchUserActionItemsIfNeeded } from '../Dashboard/UserActionItems/userActionItems.slice'
import { getUserFocusAreas } from '../Signup/userFocusArea.selecter'
import { UserFocusAreas } from '../Signup/userFocusArea.slice'
import {
  BillingCycle,
  MEMBERSHIP_STATUS,
  MembershipScopes,
} from '../../reducers/auth/userReducer'
import { useAppDispatch } from '../../utils/typeHelpers'

export type Attributes = Record<
  string,
  string | boolean | number | string[] | MEMBERSHIP_STATUS | BillingCycle | null
>
export const UNSET_STRING_VALUE = '<unset>'

export interface CustomUserProps {
  userId: number
  admin: boolean
  email: string
  businessEntity: string | null
  taxEntity: string | null
  filingStatus: string | null
  practicePriorities: string[] | string | null
  privatePracticeStage: string | null
  yearPracticeStarted: string | null
  homeState: string | null
  onboarding: string | null
  userStatus: string | null
  userActiveState: string | null
  subscriptionPlan: string | null
  subscriptionType: string | null
  subscriptionStartDate: string | null
  membershipStatus: MEMBERSHIP_STATUS | null
  membershipStartDate: string | null
  membershipTrialStartDate: string | null
  membershipStripeSubscription: string | null
  membershipStripeCustomer: string | null
  membershipStripePlanDetails: string | null
  membershipStripeSubscriptionDetails: string | null
  membershipCancellationReason: string | null
  membershipCancellationComment: string | null
  membershipCancelledAt: string | null
  membershipUpdatedAt: string | null
}

export const userFocusAreaPayload = (userFocusArea: UserFocusAreas | null) => {
  if (!userFocusArea) {
    return UNSET_STRING_VALUE
  }

  return Object.entries(userFocusArea)
    .filter(([key, value]) => !['userId', 'id'].includes(key) && Boolean(value))
    .map(([key]) => key)
}

const defaultAttributes = (attributes?: Attributes) => ({
  page_domain: window.location.hostname,
  page_location: window.location.href,
  page_path: window.location.pathname,
  page_title: window.document.title,
  page_url: window.location.origin,
  search: urlSearchParamsToObject(new URLSearchParams(window.location.search)),
  ...attributes,
})

const useAmplitudeContext = () => {
  const dispatch = useAppDispatch()
  const userFocusArea = useReselector(getUserFocusAreas)
  const financialProfile = useReselector(getFinancialProfile)
  const user = useReselector(getCurrentUser)
  const isOnboarding = useReselector(getIsRequiredStepsOnboarding)
  const subscription = useReselector(selectHeardProductSubscription)
  const membership = useReselector(selectPrimaryMembership)
  const isActiveMember = useReselector(
    selectMembershipIsIn,
    MembershipScopes.allActiveIncludingUnpaid
  )
  const urlParams = new URLSearchParams(window.location.search)
  const event = urlParams.get('event')
  const source = urlParams.get('source')
  const deviceId = urlParams.get('device-id')

  const instance = useMemo(createInstance, [])
  const {
    init,
    identify: amplitudeIdentify,
    setDeviceId: amplitudeSetDeviceId,
    track: amplitudeTrack,
    logEvent,
  } = instance

  useEffect(() => {
    if (process.env.VITE_AMPLITUDE_API_KEY) {
      init(process.env.VITE_AMPLITUDE_API_KEY)
    }
  }, [init])

  const identify = useCallback(
    (customUserProps: Partial<CustomUserProps>) => {
      const identifyEvent = new Identify()
      for (const [key, value] of Object.entries(customUserProps)) {
        identifyEvent.set(key, value || UNSET_STRING_VALUE)
      }
      amplitudeIdentify(identifyEvent)
    },
    [amplitudeIdentify]
  )

  const view = useCallback(
    (name: string, attributes?: Attributes, customUserProps?: Attributes) =>
      // Adding a timeout to avoid a race condition between setting the user props and actually logging the event or page view
      // Custom props should be set first before the event is sent
      setTimeout(
        () =>
          logEvent(
            `viewed ${name} page`,
            defaultAttributes(attributes),
            customUserProps
          ),
        1000
      ),
    [logEvent]
  )

  const track = useCallback(
    (name: string, attributes?: Attributes, customUserProps?: Attributes) =>
      // Adding a timeout to avoid a race condition between setting the user props and actually logging the event or page view
      // Custom props should be set first before the event is sent
      setTimeout(
        () =>
          amplitudeTrack(name, defaultAttributes(attributes), customUserProps),
        1000
      ),
    [amplitudeTrack]
  )

  useEffect(() => {
    if (deviceId) {
      amplitudeSetDeviceId(deviceId)
    }
  }, [amplitudeSetDeviceId, deviceId])

  useEffect(() => {
    // Track event and source if provided via query params
    if (event && source) {
      track(event, { source })
    }

    removeAmplitudeTrackingParams()
  }, [event, source, track])

  useEffect(() => {
    if (!user?.id) {
      return
    }
    dispatch(fetchFinancialProfileIfNeeded())
    dispatch(fetchUserFocusAreas(user.id))
    dispatch(fetchSubscriptions())
    dispatch(fetchUserActionItemsIfNeeded({ forceFetch: true }))
  }, [dispatch, user?.id])

  useEffect(() => {
    const userIsActiveAmplitudeMapping = isActiveMember
      ? 'active'
      : (membership?.status ?? null)

    identify({
      userId: user?.id,
      admin: user?.admin,
      email: user?.email,
      businessEntity: financialProfile?.businessEntity,
      taxEntity: financialProfile?.taxEntityType,
      filingStatus: financialProfile?.filingStatus,
      practicePriorities: userFocusAreaPayload(userFocusArea),
      privatePracticeStage: financialProfile?.practiceType,
      yearPracticeStarted: financialProfile?.yearPracticeStarted,
      homeState: financialProfile?.homeState,
      onboarding: isOnboarding ? 'incomplete' : 'complete',
      userStatus: user?.deletedAt ? 'churned' : 'active',
      userActiveState: userIsActiveAmplitudeMapping,
      subscriptionPlan: subscription?.name,
      subscriptionType:
        subscription?.items &&
        Object.values(subscription.items).length > 0 &&
        Object.values(subscription.items)[0].interval === 'year'
          ? 'annual'
          : 'monthly',
      subscriptionStartDate: subscription?.startDate
        ? moment.unix(subscription.startDate).format()
        : null,
      membershipStatus: membership?.status,
      membershipStartDate: membership?.startDate,
      membershipTrialStartDate: membership?.trialStartDate,
      membershipStripeSubscription: membership?.stripeSubscription,
      membershipStripeCustomer: membership?.stripeCustomer,
      membershipStripePlanDetails: JSON.stringify(
        membership?.stripePlanDetails
      ),
      membershipStripeSubscriptionDetails: JSON.stringify(
        membership?.stripeSubscriptionDetails
      ),
      membershipCancellationReason: membership?.cancellationReason,
      membershipCancellationComment: membership?.cancellationComment,
      membershipCancelledAt: membership?.cancelledAt,
      membershipUpdatedAt: membership?.updatedAt,
    })
  }, [
    user?.id,
    user?.admin,
    user?.email,
    user?.deletedAt,
    financialProfile?.taxEntityType,
    financialProfile?.practiceType,
    financialProfile?.businessEntity,
    financialProfile?.homeState,
    financialProfile?.yearPracticeStarted,
    userFocusArea,
    subscription?.name,
    subscription?.startDate,
    subscription?.items,
    isOnboarding,
    identify,
    financialProfile?.filingStatus,
    membership,
    membership?.status,
    membership?.startDate,
    membership?.trialStartDate,
    membership?.stripeSubscription,
    membership?.stripeCustomer,
    membership?.stripePlanDetails,
    membership?.stripeSubscriptionDetails,
    membership?.cancellationReason,
    membership?.cancellationComment,
    membership?.cancelledAt,
    membership?.updatedAt,
    isActiveMember,
  ])

  return {
    reset: instance.reset,
    setUserId: instance.setUserId,
    track,
    view,
  }
}

const AnalyticsContext = createContext<ReturnType<
  typeof useAmplitudeContext
> | null>(null)

export const AnalyticsProvider = ({ children }: { children: ReactElement }) => {
  const amplitudeContext = useAmplitudeContext()

  return (
    <AnalyticsContext.Provider value={amplitudeContext}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export const ENABLE_ADMIN = true
/**
 * Returns a function to track an analytic event
 */
export const useAnalyticsTrack = (enableAdmin = false) => {
  const isAdmin = useReselector(getUserIsAdmin)
  const context = useContext(AnalyticsContext)
  return (isAdmin && !enableAdmin) || !context ? () => {} : context.track
}

/**
 * Returns a function to create page view
 */
export const useAnalyticsView = (enableAdmin = false) => {
  const isAdmin = useReselector(getUserIsAdmin)
  const context = useContext(AnalyticsContext)
  return (isAdmin && !enableAdmin) || !context ? () => {} : context.view
}

/**
 * Returns a function to set user id
 */
export const useAnalyticsSetUserId = () => {
  const context = useContext(AnalyticsContext)
  return context?.setUserId || (() => {})
}

export const useAnalyticsReset = () => {
  const context = useContext(AnalyticsContext)
  return context?.reset || (() => {})
}
