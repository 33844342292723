import { ReactNode, useEffect } from 'react'
import moment from 'moment'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { useReselector } from '../../utils/sharedHooks'
import { selectUserDocumentCategory } from '../../features/Admin/UserDocumentCategories/userDocumentCategories.selectors'
import { Text, Link, Accordion, Icon } from '../BaseComponents'
import { selectActivePlaidFinancialAccounts } from '../../selectors/financeSelectors'
import { fetchFinancialAccountsIfNeeded } from '../../actions/financialAccountActions'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { UploadDocumentType } from '../../constants/businessConstants'
import { BookkeepingDescription } from './BookkeepingDescription'
import { UserDocumentCategoryIdentifier } from '../../features/Admin/UserDocumentCategories/userDocumentCategory.constants'
import { useAppDispatch } from '../../utils/typeHelpers'

export const parseCategoryYear = (internalName?: string) => {
  const match = internalName?.match(/^\d{4}/g)
  return match ? match[0] : null
}

const stringToText = (text: string) => <Text as="bodyLg">{text}</Text>

const TaxReturnDescription = ({
  firstSentence,
  year,
}: {
  firstSentence: ReactNode
  year: string
}) => (
  <>
    <Text as="bodyLg">
      {firstSentence} Make sure this is last year’s return and to include the
      following:
    </Text>
    <ul>
      <li>
        <Text as="bodyLg">Federal {year} tax return</Text>
      </li>
      <li>
        <Text as="bodyLg"> State {year} tax return(s)</Text>
      </li>
    </ul>
    <Text as="bodyLg">
      These may either be in the same document or in multiple separate
      documents.
    </Text>
  </>
)

const FileUploadModalDescription = ({
  sentDescription,
  sentCategoryId,
  documentType,
  sentMonthYear,
  descriptionType,
  sentYear,
  forCatchUpBK = false,
  missingStatements = false,
}: {
  sentDescription: ReactNode
  sentCategoryId?: number | null
  documentType: UploadDocumentType
  sentMonthYear?: string
  descriptionType?: string | null
  sentYear?: string | null
  forCatchUpBK?: boolean
  missingStatements?: boolean
}) => {
  const dispatch = useAppDispatch()
  const sentCategory = useReselector(selectUserDocumentCategory, sentCategoryId)
  const activeFinancialAccounts = useReselector(
    selectActivePlaidFinancialAccounts
  )
  const categoryYear = sentYear ?? parseCategoryYear(sentCategory?.internalName)

  useEffect(() => {
    dispatch(fetchFinancialAccountsIfNeeded())
  }, [dispatch])

  // If a description is sent then use that
  if (sentDescription) {
    if (typeof sentDescription !== 'string') {
      return sentDescription
    }

    // Sent descriptions that are strings need to be formatted in Text
    return stringToText(sentDescription)
  }

  // If a month year is sent for statements show financial accounts
  if (sentMonthYear) {
    const sentMoment = moment(sentMonthYear, 'YYYY-MM')

    return (
      <>
        <Text as="bodyLg">
          Please include all statements from{' '}
          {sentMoment.format(DATE_FORMATS.MONTH_YEAR)} for all of your connected
          bank accounts:
        </Text>
        <br />
        <ul>
          {Object.values(activeFinancialAccounts).map(
            ({ id, plaidInstitutionName, name, mask }) => (
              <li key={`financialAccountRow-${id}`}>
                <Text as="bodyLg">
                  {plaidInstitutionName} {name} {mask}
                </Text>
              </li>
            )
          )}
        </ul>
      </>
    )
  }

  if (sentCategory?.internalName.endsWith('w3') && categoryYear) {
    return (
      <>
        <Text>
          This form summarizes your W-2 forms from {categoryYear}, including
          total employee wages and taxes withheld. Issued January 15,{' '}
          {parseInt(categoryYear) + 1}.
        </Text>
        <br />
        <Accordion
          title="Where do I find my W-3?"
          content={
            <Text>
              It can be found in your payroll provider account.{' '}
              <Link
                href="https://support.joinheard.com/hc/en-us/articles/19809547067543#form_w3"
                newPage
                as="secondaryLink"
              >
                View instructions
              </Link>
            </Text>
          }
          variant="text"
        />
        <br />
        <Link href="https://www.irs.gov/pub/irs-pdf/fw3.pdf" newPage>
          View example <Icon icon={regular('square-arrow-up-right')} />
        </Link>
      </>
    )
  }

  if (
    (sentCategory?.internalName.endsWith('1040') ||
      sentCategory?.internalName ===
        UserDocumentCategoryIdentifier.finalReturnForm1040) &&
    categoryYear
  ) {
    return (
      <TaxReturnDescription
        firstSentence={
          <>
            Your <b>personal</b> income tax returns.
          </>
        }
        year={categoryYear}
      />
    )
  }

  if (
    (sentCategory?.internalName.endsWith('1120s') ||
      sentCategory?.internalName ===
        UserDocumentCategoryIdentifier.finalReturnForm1120s) &&
    categoryYear
  ) {
    return (
      <TaxReturnDescription
        firstSentence={
          <>
            Your <b>business’s</b> federal tax return.
          </>
        }
        year={categoryYear}
      />
    )
  }

  if (documentType === UploadDocumentType.BOOKKEEPING) {
    return (
      <BookkeepingDescription
        category={sentCategory}
        descriptionType={descriptionType}
        year={categoryYear}
      />
    )
  }

  // If a sent category has a description use that
  if (sentCategory?.description) {
    return stringToText(sentCategory.description)
  }

  if (documentType === UploadDocumentType.STATEMENT && forCatchUpBK) {
    return stringToText(
      'We need these bank statements to complete your bookkeeping. Please upload them below.'
    )
  }
  if (documentType === UploadDocumentType.STATEMENT && missingStatements) {
    return (
      <Text as="bodyLg">
        Please upload the bank statements below so we can complete your
        bookkeeping.{' '}
        <Link
          href="https://support.joinheard.com/hc/en-us/articles/23569956149399-Bank-Statements-at-Heard"
          as="secondaryLink"
          newPage
        >
          Why?
        </Link>
      </Text>
    )
  }
  // Default descriptions
  if (documentType === UploadDocumentType.TAX) {
    return stringToText(
      'Upload tax-specific documents here, such as prior year tax returns.'
    )
  } else if (documentType === UploadDocumentType.INCORPORATION) {
    return stringToText(
      'Upload entity formation documents here, such as articles of incorporation, LLC approval, S corporation Form 2553, etc.'
    )
  } else if (documentType === UploadDocumentType.OTHER) {
    return stringToText(
      "Use this to upload any other documents you'd like to share with our team."
    )
  } else if (documentType === UploadDocumentType.RECEIPT) {
    return stringToText("Upload any other receipts you'd like to share here.")
  }

  return stringToText('Upload your monthly bank statements for bookkeeping.')
}

export default FileUploadModalDescription
