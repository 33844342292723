import axios from 'axios'
import { UserDocumentCategory } from '../UserDocumentCategories/userDocumentCategories.slice'
import { DropdownOption } from '../../../components/BaseComponents/Dropdown'

export const documentCategoryOptions = (
  documentCategories: { [key: string]: UserDocumentCategory },
  docType: string,
  isAdmin = false
) => {
  if (!documentCategories) {
    return [] as DropdownOption<number>[]
  }
  return Object.values(documentCategories)
    .filter(
      (cat) =>
        cat.type === docType &&
        ((isAdmin && !cat.archivedAt) || (!cat.archivedAt && !cat.isInternal))
    )
    .sort((a, b) => (a.title > b.title ? 1 : -1))
    .map((option: UserDocumentCategory) => ({
      value: option.id,
      text: option.title,
    }))
}

export const downloadZipDocuments = async (documentIds: number[]) => {
  try {
    const url = '/finances/api/v1/admin/user_documents/zip'
    const res = await axios.post(
      url,
      { documentIds },
      { responseType: 'arraybuffer' }
    )
    return res
  } catch (_) {
    return null
  }
}

export const compareArrays = <T>(arr1: T[], arr2: T[]) => {
  const set1 = new Set(arr1)
  const set2 = new Set(arr2)

  const newVals = arr2.filter((val) => !set1.has(val))
  const removedVals = arr1.filter((val) => !set2.has(val))

  return {
    newVals,
    removedVals,
  }
}
