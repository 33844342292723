import { useCallback, useState } from 'react'
import { Grid } from 'semantic-ui-react'

import { useReselector } from '../../../utils/sharedHooks'
import { getFetchError } from '../../../reducers/fetch'
import { ENABLE_PAYROLL_KEY, enablePayroll } from '../../Payroll/payrollActions'
import {
  Alert,
  Button,
  GridRowColumn,
  Modal,
  Toggle,
} from '../../../components/BaseComponents'
import { useAppDispatch } from '../../../utils/typeHelpers'

const EnrollPayrollModal = ({
  open,
  close,
  userId,
}: {
  open: boolean
  close: () => void
  userId: number
}) => {
  const dispatch = useAppDispatch()
  const [needsMigration, setNeedsMigration] = useState(false)
  const error = useReselector(getFetchError, ENABLE_PAYROLL_KEY)

  const enrollUserInPayroll = useCallback(async () => {
    await enablePayroll(userId, needsMigration)(dispatch)
    close()
  }, [close, dispatch, userId, needsMigration])

  return (
    <Modal
      size="small"
      className="enrollPayrollModal"
      open={open}
      onClose={close}
      closeIcon
    >
      <Modal.Header>Enroll User in Payroll</Modal.Header>
      <Modal.Content>
        <Grid>
          {error && (
            <GridRowColumn>
              <Alert type="error">Error: {error.message}</Alert>
            </GridRowColumn>
          )}
          <GridRowColumn>
            <Toggle
              label="Migrate User - Check this if the User already has an existing Gusto profile that
              needs to be migrated to Heard"
              checked={needsMigration}
              onChange={() => setNeedsMigration((prev) => !prev)}
            />
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={close}>
          Cancel
        </Button>
        <Button onClick={enrollUserInPayroll}>
          Enroll In Payroll for User
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default EnrollPayrollModal
