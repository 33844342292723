import { useState } from 'react'
import { Button, Table, Text } from '../../../../components/BaseComponents'
import { Pathway, SimulatedApiError } from '../qaFeatures.slice'
import NewUserModal from './NewUserModal'

const SimulatedError = ({ error }: { error: SimulatedApiError }) => (
  <li>{`${error.httpMethod} ${error.path}`}</li>
)

const PathwayTable = ({ pathways }: { pathways: Pathway[] }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [pathwayIdentifier, setPathwayIdentifier] = useState('')

  const onButtonClick = (identifier: string) => {
    setPathwayIdentifier(identifier)
    setModalOpen(true)
  }

  return (
    <>
      <Table>
        <Table.Header>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Role</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Header>
        <Table.Body>
          {pathways.map((pathway) => (
            <Table.Row key={`pathway-${pathway.identifier}`}>
              <Table.Cell>{pathway.name}</Table.Cell>
              <Table.Cell>{pathway.adminRoleName || 'Customer'}</Table.Cell>
              <Table.Cell style={{ whiteSpace: 'pre-wrap' }}>
                {pathway.description}
                {pathway.simulatedApiErrors &&
                  pathway.simulatedApiErrors.length > 0 && (
                    <div>
                      Simulated Api Errors:{' '}
                      <ul>
                        {' '}
                        {pathway.simulatedApiErrors.map((e) => (
                          <SimulatedError
                            key={`error-${e.httpMethod}${e.path}`}
                            error={e}
                          />
                        ))}
                      </ul>
                    </div>
                  )}
              </Table.Cell>
              <Table.Cell width={3}>
                <Button
                  onClick={() => onButtonClick(pathway.identifier)}
                  size="medium"
                  floated="right"
                >
                  Create User
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Cell colSpan="16">
            <Text as="bodySm">
              👉👉 Paths are manually coded by the pod&apos;s engineers. If you
              would like a specific path setup, please contact the relevant pod.
            </Text>
          </Table.Cell>
        </Table.Footer>
      </Table>
      <NewUserModal
        pathways={pathways}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        pathwayIdent={pathwayIdentifier}
      />
    </>
  )
}

export default PathwayTable
