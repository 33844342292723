import { useState } from 'react'
import Input from '../components/BaseComponents/Input'

export const DefaultInput = () => {
  const [value, setValue] = useState('')

  return (
    <Input onChange={setValue} placeholder="Type something..." value={value} />
  )
}

export const NumberInput = () => {
  const [value, setValue] = useState('')

  return (
    <Input
      onChange={setValue}
      placeholder="Type something..."
      value={value}
      type="number"
    />
  )
}

export const LabeledInput = () => {
  const [value, setValue] = useState('')

  return (
    <Input
      label="Label"
      onChange={setValue}
      placeholder="Type something..."
      value={value}
    />
  )
}

export const DescriptiveInput = () => {
  const [value, setValue] = useState('')

  return (
    <Input
      description="A description here"
      label="Label"
      onChange={setValue}
      placeholder="Type something..."
      value={value}
    />
  )
}

export const ClearableInput = () => {
  const [value, setValue] = useState('')

  return (
    <Input
      clearable
      description="I am clearable"
      label="Label"
      onChange={setValue}
      placeholder="Type something..."
      value={value}
    />
  )
}

export const CurrencyInputExample = () => {
  const [value, setValue] = useState('0')

  return (
    <Input
      componentType="currency"
      description="Please enter an amount"
      label="Currency Input"
      onChange={setValue}
      placeholder="value"
      value={value}
    />
  )
}

export const ErrorInput = () => {
  const [value, setValue] = useState('')

  return (
    <Input
      description="A description here"
      error="There was a problem"
      label="Label"
      onChange={setValue}
      placeholder="Type something..."
      value={value}
    />
  )
}

export const ClearableErrorInput = () => {
  const [value, setValue] = useState('')

  return (
    <Input
      clearable
      description="A description here"
      error="There was a problem"
      label="Label"
      onChange={setValue}
      placeholder="Type something..."
      value={value}
    />
  )
}

export const DisabledInput = () => {
  const [value, setValue] = useState('')

  return (
    <Input
      description="A description here"
      disabled
      label="Label"
      onChange={setValue}
      placeholder="Type something..."
      value={value}
    />
  )
}

export const LeftIconInput = () => {
  const [value, setValue] = useState('')

  return (
    <Input
      leftIcon="search"
      onChange={setValue}
      placeholder="Type something..."
      value={value}
    />
  )
}

export const LeftIconClickableInput = () => {
  const [value, setValue] = useState('')

  return (
    <Input
      leftIcon="calendar outline"
      onChange={setValue}
      onLeftIconClick={() => {}}
      placeholder="Type something..."
      value={value}
    />
  )
}

export const ClearableLeftIconInput = () => {
  const [value, setValue] = useState('')

  return (
    <Input
      clearable
      leftIcon="calendar outline"
      onChange={setValue}
      placeholder="Type something..."
      value={value}
    />
  )
}

export const RightIconInput = () => {
  const [value, setValue] = useState('')

  return (
    <Input
      rightIcon="calendar outline"
      onChange={setValue}
      placeholder="Type something..."
      value={value}
    />
  )
}

export const ClearableRightIconInput = () => {
  const [value, setValue] = useState('')

  return (
    <Input
      clearable
      rightIcon="calendar outline"
      onChange={setValue}
      placeholder="Type something..."
      value={value}
    />
  )
}

export const RequiredInput = () => {
  const [value, setValue] = useState('')

  return (
    <Input
      onChange={setValue}
      placeholder="..."
      value={value}
      required
      label="Name"
    />
  )
}

export const RightIconClickableInput = () => {
  const [value, setValue] = useState('')

  return (
    <Input
      rightIcon="calendar outline"
      onChange={setValue}
      onRightIconClick={() => {}}
      placeholder="Type something..."
      value={value}
    />
  )
}

export const ClearableErrorRightIconInput = () => {
  const [value, setValue] = useState('')

  return (
    <Input
      clearable
      error="There was a problem"
      rightIcon="calendar outline"
      onChange={setValue}
      placeholder="Type something..."
      value={value}
    />
  )
}

export const OnFocusInput = () => {
  const [value, setValue] = useState('')
  const [focusCount, setFocusCount] = useState(0)

  const onFocus = () => {
    setFocusCount((count) => count + 1)
  }

  return (
    <Input
      onChange={setValue}
      onFocus={onFocus}
      placeholder={`Click here... (${focusCount})`}
      value={value}
    />
  )
}

export const AmountInput = () => {
  const [value, setValue] = useState('0.00')

  return (
    <Input
      onChange={setValue}
      placeholder="Amount"
      value={value}
      label="Amount"
      componentType="number"
    />
  )
}

export const PhoneInputExample = () => {
  const [value, setValue] = useState('')

  return (
    <Input
      onChange={setValue}
      placeholder="(000) 000-0000"
      value={value}
      label="Phone number"
      componentType="phone"
    />
  )
}

export const PatternInput = () => {
  const [value, setValue] = useState('324-44')

  return (
    <Input
      onChange={setValue}
      value={value}
      label="Pattern"
      format="###-##"
      componentType="pattern"
      disabled
    />
  )
}
