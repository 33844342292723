export interface ReportByStatus {
  month: string
  status: string
  count: string
}

export interface ReportByMonth {
  month: string
  count: string
}

export interface BookkeepingReportStatistics {
  totalReportsByStatus: ReportByStatus[]
  totalReportsByMonth: ReportByMonth[]
}

export const getCountByStatus = (
  data: ReportByStatus[],
  month: string,
  status: string
) => {
  const countObj = data.find(
    (item) => item.month === month && item.status === status
  )
  return countObj?.count ?? '0'
}
