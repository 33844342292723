import { useNavigate } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { isNil } from 'lodash'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { useReselector } from '../../utils/sharedHooks'
import { getFinancialProfile } from '../../selectors/user.selectors'
import { Accordion, IconButton, Text } from '../../components/BaseComponents'
import PracticeProfileEntry from './PracticeProfileEntry'
import { formatCurrencyFromCents } from '../../utils/currencyHelpers'
import {
  FILING_STATUS_COPY,
  getTaxEntityName,
  TAX_ENTITY_TYPES,
} from '../Taxes/taxConstants'
import { UserDocumentCategoryIdentifier } from '../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { DateTime } from 'luxon'

const getCurrencyDisplay = (valInCents: number | null | undefined) =>
  isNil(valInCents) ? null : formatCurrencyFromCents(valInCents)

const TaxInformationSection = () => {
  const navigate = useNavigate()
  const fp = useReselector(getFinancialProfile)
  const taxYear = DateTime.now().year

  const displaySpouse = Boolean(
    fp?.filingStatus &&
      ['married_filing_jointly', 'married_filing_separately'].includes(
        fp?.filingStatus
      )
  )

  const isScorp = fp?.taxEntityType === TAX_ENTITY_TYPES.form_1120_s

  return (
    <Accordion
      initialOpen
      title={
        <>
          <div style={{ maxWidth: '70%' }}>
            <Text as="h2">{taxYear} Tax Information</Text>
            <Text as="bodySm" color="darkGray">
              Last Updated on{' '}
              {fp?.updatedAt
                ? `${DateTime.fromISO(fp.updatedAt).toLocaleString(DateTime.DATETIME_MED)}`
                : ''}
            </Text>
          </div>

          <IconButton
            icon={regular('pen-to-square')}
            style={{ position: 'absolute', right: 55, zIndex: 1000 }}
            onClick={() => navigate('/practice/profile/editTax')}
          />
        </>
      }
      content={
        <Grid>
          <PracticeProfileEntry
            label="Filing Status"
            value={
              fp?.filingStatus && FILING_STATUS_COPY[fp?.filingStatus]?.title
            }
          />
          <PracticeProfileEntry
            label="Tax Entity Type"
            value={getTaxEntityName(fp?.taxEntityType)}
          />
          <PracticeProfileEntry
            label="Deduction Type"
            value={fp?.itemizedDeduction ? 'Itemized' : 'Standard'}
          />
          <PracticeProfileEntry
            label="Dependents"
            value={fp?.numberOfDependents}
          />

          <Grid.Row />

          {isScorp && (
            <PracticeProfileEntry
              label="YTD W-2 Private Practice"
              value={getCurrencyDisplay(fp?.scorpPracticeWIncomeInCents)}
            />
          )}

          <PracticeProfileEntry
            label="YTD W-2 Income"
            value={getCurrencyDisplay(fp?.wIncomeInCents)}
          />
          <PracticeProfileEntry
            label="YTD Federal Withholding"
            value={getCurrencyDisplay(fp?.federalWithholdingInCents)}
          />
          <PracticeProfileEntry
            label="YTD State Withholding"
            value={getCurrencyDisplay(fp?.stateWithholdingInCents)}
          />
          <PracticeProfileEntry
            label="YTD 1099 Income"
            value={getCurrencyDisplay(fp?.otherIndividualIncomeInCents)}
          />

          <Grid.Row />

          {displaySpouse && (
            <>
              <PracticeProfileEntry
                label="Spouse Name"
                value={
                  fp?.spouseFirstName &&
                  `${fp?.spouseFirstName} ${fp?.spouseLastName}`
                }
              />

              <PracticeProfileEntry
                label="Spouse YTD W-2 Income"
                value={getCurrencyDisplay(fp?.spouseWIncomeInCents)}
              />
              <PracticeProfileEntry
                label="Spouse YTD Federal Withholding"
                value={getCurrencyDisplay(fp?.spouseFederalWithholdingInCents)}
              />
              <PracticeProfileEntry
                label="Spouse YTD State Withholding"
                value={getCurrencyDisplay(fp?.spouseStateWithholdingInCents)}
              />
              <PracticeProfileEntry
                label="Spouse YTD 1099"
                value={getCurrencyDisplay(fp?.spouseIndividualIncomeInCents)}
              />

              <Grid.Row />
            </>
          )}

          <PracticeProfileEntry
            label="Prior Year Tax Return"
            documentCat={UserDocumentCategoryIdentifier.finalReturn}
            taxYear={(taxYear - 1).toString()}
          />
        </Grid>
      }
    />
  )
}

export default TaxInformationSection
