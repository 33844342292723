import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { keyBy } from 'lodash'

import { fetchIfNeededWrapper, fetchWrapper } from '../../reducers/fetch'
import { ChartOfAccountCategory } from '../ChartOfAccounts/chartOfAccount.slice'
import { TransactionCategoryIdentifier } from '../../reducers/admin/allTransactions.slice'

export type TransactionAccountType =
  | 'Assets'
  | 'Liabilities'
  | 'Equity'
  | 'Income'
  | 'Expenses'
  | 'Transfer'
  | 'Other'

export enum TransactionAccountTypes {
  assets = 'Assets',
  liabilities = 'Liabilities',
  equity = 'Equity',
  income = 'Income',
  expenses = 'Expenses',
  transfer = 'Transfer',
  other = 'Other',
}

export interface TransactionCategory {
  accountType: TransactionAccountType
  createdAt: string
  id: number
  name: string
  updatedAt: string
  archivedAt?: string | null
  chartOfAccountCategories: ChartOfAccountCategory[]
  balanceSheetCategory: BalanceSheetCategory
  taxEntityTypesToExcludeProfit: string[] | null
  identifier: TransactionCategoryIdentifier
  isTaxCompliant: boolean
  isTaxDeductible: boolean
  financialStatement: TransactionCategoryFinancialStatement
}

export type TransactionCategoryFinancialStatement =
  | 'balance_sheet'
  | 'income_statement'

export enum TransactionCategoryFinancialStatements {
  balance_sheet = 'Balance Sheet',
  income_statement = 'Income Statement',
}

export type BalanceSheetCategory =
  | 'asset'
  | 'liability'
  | 'equity'
  | 'current_asset'
  | 'pp&e'
  | 'long_term_liability'
  | 'current_liability'
  | null

export enum BalanceSheetCategories {
  asset = 'Asset',
  liability = 'Liability',
  equity = 'Equity',
  current_asset = 'Current Asset',
  'pp&e' = 'PP&E',
  long_term_liability = 'Long Term Liability',
  current_liability = 'Current Liability',
}

export interface TransactionCategoryState {
  [key: string]: TransactionCategory
}

const transactionCategorySlice = createSlice({
  name: 'transactionCategory',
  initialState: {} as TransactionCategoryState,
  reducers: {
    receiveAllTransactionCategories: (
      state,
      action: PayloadAction<{
        [key: string]: TransactionCategory
      }>
    ) => ({
      ...state,
      ...action.payload,
    }),
    receiveSingleTransactionCategory: (
      state,
      action: PayloadAction<TransactionCategory>
    ) => {
      state[action.payload.id] = action.payload
    },
  },
})

export default transactionCategorySlice.reducer

const { receiveAllTransactionCategories, receiveSingleTransactionCategory } =
  transactionCategorySlice.actions

export const FETCH_TRANSACTION_CATEGORIES_KEY =
  'FETCH_TRANSACTION_CATEGORIES_KEY'
export const fetchTransactionCategoriesIfNeeded = (alwaysFetch = false) =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_TRANSACTION_CATEGORIES_KEY,
    defaultErrorMessage: 'There was an error fetching transaction categories.',
    alwaysFetch,
    fetchFunction: (dispatch) =>
      axios
        .get<TransactionCategory[]>('/finances/api/v1/transactions/categories')
        .then((json) => {
          dispatch(receiveAllTransactionCategories(keyBy(json.data, 'id')))
          return json.data
        }),
  })

export const createTransactionCategory = (data: {
  name: string
  accountType: string
}) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error creating transaction categories.',
    fetchFunction: (dispatch) =>
      axios
        .post<TransactionCategory>(
          '/finances/api/v1/admin/transactions/categories',
          data
        )
        .then((json) => {
          dispatch(receiveSingleTransactionCategory(json.data))
          return json.data
        }),
  })

export const updateTransactionCategory = (
  id: number,
  data: {
    name: string
    accountType: string
  }
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error updating transaction categories.',
    fetchFunction: (dispatch) =>
      axios
        .put<TransactionCategory>(
          `/finances/api/v1/admin/transactions/categories/${id}`,
          data
        )
        .then((json) => {
          dispatch(receiveSingleTransactionCategory(json.data))
          return json.data
        }),
  })
