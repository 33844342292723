import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import PlaidLink from '../../shared/plaid/PlaidLink'
import PlaidOauthLink from '../../shared/plaid/PlaidOauthLink'
import {
  FETCH_FINANCIAL_ACCOUNT_KEY,
  fetchFinancialAccountsIfNeeded,
} from '../../../actions/financialAccountActions'
import { Container, Grid, Loader, Divider } from 'semantic-ui-react'
import './Accounts.scss'
import { Button, Card, GridRowColumn, Text } from '../../BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { getIsFetchingOrNotStarted } from '../../../reducers/fetch'
import { selectFinancialAccounts } from '../../../selectors/financeSelectors'
import { useIsOnboardingNav } from '../../../features/Onboarding/config'
import { useAppDispatch } from '../../../utils/typeHelpers'

const NewAccount = () => {
  const dispatch = useAppDispatch()
  const loading = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_FINANCIAL_ACCOUNT_KEY
  )
  const financialAccounts = useReselector(selectFinancialAccounts)
  const location = useLocation()
  const isOnboarding = useIsOnboardingNav()
  const oauth = location.pathname === '/accounts/new/oauth'

  useEffect(() => {
    dispatch(fetchFinancialAccountsIfNeeded())
  }, [dispatch])

  return (
    <Container id="accounts" className="backdrop">
      <div style={{ display: 'flex', alignContent: 'center' }}>
        <Link to="/accounts">
          <Button
            variant="link"
            icon="arrow left"
            style={{ marginRight: '8px' }}
          />
        </Link>
        <Text as="h2" style={{ marginTop: '5px' }}>
          Add a new Financial Account
        </Text>
      </div>

      <Divider />
      {loading ? (
        <Grid doubling stackable>
          <Loader active inline="centered">
            Loading...
          </Loader>
        </Grid>
      ) : (
        <Grid doubling stackable>
          <GridRowColumn centered>
            <Card
              description={
                oauth ? (
                  <PlaidOauthLink
                    redirectUri="accounts"
                    redirectLink={
                      isOnboarding ? '/' : '/accounts#connected-institutions'
                    }
                  />
                ) : (
                  <PlaidLink
                    redirectUri="accounts"
                    redirectLink={
                      isOnboarding ? '/' : '/accounts#connected-institutions'
                    }
                  />
                )
              }
            />
          </GridRowColumn>
          <GridRowColumn>
            <Text>Connected Accounts</Text>
          </GridRowColumn>
          <Grid.Row>
            {Object.values(financialAccounts).length ? (
              Object.values(financialAccounts)
                .filter(
                  (account) =>
                    !['manual', 'user_manual_upload'].includes(
                      account.accountType
                    )
                )
                .map((account) => (
                  <Grid.Column width={4} textAlign="left" key={account.id}>
                    <Card
                      content={
                        <>
                          <Text>{account.plaidInstitutionName}</Text>
                          <Text>
                            {account.name}: {account.mask}
                          </Text>
                        </>
                      }
                    />
                  </Grid.Column>
                ))
            ) : (
              <Grid.Column
                width={10}
                className="accounts empty"
                textAlign="left"
              >
                <div className="accountCard white newAccount">
                  <Text as="bodyMd">No Connected Accounts</Text>
                </div>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      )}
    </Container>
  )
}

export default NewAccount
