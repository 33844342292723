import { memo } from 'react'
import { useLocation } from 'react-router-dom'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import './enroll.scss'

import { EMPLOYEE_ENROLL_PATHS, useOnboardingSteps } from '../helpers'
import { Text, Icon, CircularWrapper } from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'

const PayrollSetupList = ({ isIntro }: { isIntro?: boolean }) => {
  const steps = useOnboardingSteps()
  const location = useLocation()

  if (!steps.length) {
    return null
  }

  return (
    <div className={`payroll-setup-list ${isIntro ? 'intro' : ''}`}>
      {steps.map((step) => {
        const { key, paths, complete, text } = step

        const isActive = paths.includes(location.pathname)
        const borderColor = complete
          ? undefined
          : isActive
            ? Colors.black
            : Colors.gray

        return (
          <div key={key} className={`item ${isActive ? 'current' : ''}`}>
            <CircularWrapper
              height={24}
              wrapperColor={complete ? 'moss' : undefined}
              style={{ marginRight: 18, border: `1px solid ${borderColor}` }}
            >
              {complete && (
                <Icon icon={regular('check')} size="1x" color="forest" />
              )}
            </CircularWrapper>
            <div>
              <Text
                as={isActive ? 'h3' : 'bodyLg'}
                color={isActive ? 'green' : 'black'}
              >
                {text}
              </Text>
              {key === 'employee' && isActive && (
                <ul>
                  {[
                    'Set up personal details',
                    'Add job details',
                    'Add federal tax info',
                    'Add state tax info',
                    'Add payment info',
                  ].map((text, index) => {
                    const isSubItemActive =
                      index === EMPLOYEE_ENROLL_PATHS.indexOf(location.pathname)
                    return (
                      <li
                        key={text}
                        className={`item sub-item ${
                          isSubItemActive ? 'current' : ''
                        }`}
                      >
                        <Text color={isSubItemActive ? 'green' : 'black'}>
                          &bull; {text}
                        </Text>
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default memo(PayrollSetupList)
