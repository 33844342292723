import { Grid } from 'semantic-ui-react'
import {
  Accordion,
  Button,
  GridRowColumn,
  Link,
  Text,
} from '../../../../components/BaseComponents'

const AnnualTaxFaq = () => (
  <Accordion
    title={<Text as="h2">Common FAQs</Text>}
    content={
      <Grid>
        <GridRowColumn short>
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/4420597856535-What-are-annual-taxes-"
            newPage
          >
            When are my annual taxes due?
          </Link>
        </GridRowColumn>
        <GridRowColumn short>
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/4420597867287-Which-forms-do-I-need-to-file-"
            newPage
          >
            Which forms do I need to file?
          </Link>
        </GridRowColumn>
        <GridRowColumn short>
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/19233040314775"
            newPage
          >
            What tax documents should I look out for?
          </Link>
        </GridRowColumn>
        <GridRowColumn short>
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/4420597872023-What-do-I-need-to-know-about-tax-deductions-"
            newPage
          >
            What do I need to know about tax deductions?
          </Link>
        </GridRowColumn>
        <GridRowColumn short>
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/4420580970007-How-do-annual-taxes-work-with-Heard-"
            newPage
          >
            How do annual taxes work with Heard?
          </Link>
        </GridRowColumn>
        <GridRowColumn short>
          <Link
            href="https://support.joinheard.com/hc/en-us/sections/4416196135959-Annual-Taxes"
            newPage
          >
            <Button variant="link"> View All FAQs</Button>
          </Link>
        </GridRowColumn>
      </Grid>
    }
  />
)

export default AnnualTaxFaq
