import { Grid } from 'semantic-ui-react'
import { Card, Icon, Link, Text } from '../../../components/BaseComponents'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

const AboutPlaidRow = () => (
  <Grid.Row>
    <Grid.Column>
      <Card backgroundColor="forest">
        <Grid columns="equal" verticalAlign="middle">
          <Grid.Row>
            <Grid.Column>
              <Grid verticalAlign="middle">
                <Grid.Row>
                  <Grid.Column width={3}>
                    <Icon
                      icon={light('lock-keyhole')}
                      color="sunrise"
                      size="3x"
                    />
                  </Grid.Column>
                  <Grid.Column width={13}>
                    <Text color="white">
                      Plaid is a trusted service that securely pulls your
                      banking information into Heard.
                    </Text>
                    <Link
                      href="https://plaid.com/safety/"
                      newPage
                      color="white"
                      hoverColor="blush"
                      style={{ textDecoration: 'underline' }}
                    >
                      About connecting with Plaid
                    </Link>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid verticalAlign="middle">
                <Grid.Row>
                  <Grid.Column width={3}>
                    <Icon icon={light('bolt')} color="sunrise" size="3x" />
                  </Grid.Column>
                  <Grid.Column width={13}>
                    <Text color="white">
                      Plaid allows us to review your transactions, do
                      bookkeeping and complete all tax-related tasks.
                    </Text>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid verticalAlign="middle">
                <Grid.Row>
                  <Grid.Column width={3}>
                    <Icon
                      icon={light('shield-check')}
                      color="sunrise"
                      size="3x"
                    />
                  </Grid.Column>
                  <Grid.Column width={13}>
                    <Text color="white">
                      We only access your transaction history. We cannot move
                      money for you.
                    </Text>
                    <Link
                      href="https://support.joinheard.com/hc/en-us/articles/4410664221975-How-does-Heard-use-Plaid-"
                      newPage
                      color="white"
                      hoverColor="blush"
                      style={{ textDecoration: 'underline' }}
                    >
                      Learn what we can see
                    </Link>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </Grid.Column>
  </Grid.Row>
)

export default AboutPlaidRow
