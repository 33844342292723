import { useEffect } from 'react'
import PageHeader from '../../../components/shared/PageHeader'
import { useAnalyticsView } from '../../Amplitude'
import { UpriseIframe } from '../UpriseIframe'

const GoalsPanel = () => {
  const pageView = useAnalyticsView()
  useEffect(() => {
    pageView('advisory goals panel')
  }, [pageView])

  return (
    <>
      <PageHeader header="Financial Advisory" />
      <UpriseIframe page="goals" />
    </>
  )
}

export default GoalsPanel
