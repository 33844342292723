import { useEffect, useMemo, useState } from 'react'
import { Divider, Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Card,
  Dropdown,
  GridRowColumn,
  Icon,
  Text,
  Button,
  valsToDropdownOptions,
} from '../../../components/BaseComponents'
import EditImportantDatesModal, {
  getMeridianForDateTime,
} from './EditImportantDatesModal'
import {
  formatDateForUpsellImportantDates,
  formatTimeForUpsellImportantDates,
  SCorpSetUpStatus,
  RequestSource,
  formatRequestSourceLabel,
  formatSetUpStatusLabelForSetUpStatus,
} from '../../Dashboard/SCorpUpsell/utils'
import {
  fetchSCorpElectionStatus,
  postSCorpElectionStatus,
  putSCorpElectionStatus,
} from '../../Dashboard/SCorpUpsell/sCorpActions'
import { useReselector } from '../../../utils/sharedHooks'
import { selectSCorpSetUpStatusesForUser } from '../../Dashboard/SCorpUpsell/sCorpActions.selector'
import { LabelDescription } from '../../../components/BaseComponents/Input'
import { useAppDispatch } from '../../../utils/typeHelpers'

const makeAdditionalParamsForImportantDates = (status: SCorpSetUpStatus) => {
  switch (status) {
    case SCorpSetUpStatus.request_submitted:
      return {
        initialRequestSubmittedAt: new Date().toISOString(),
      }
    case SCorpSetUpStatus.form_2553_filed:
      return {
        form2553FiledAt: new Date().toISOString(),
      }
    case SCorpSetUpStatus.cp261_received:
      return {
        cp261UploadedAt: new Date().toISOString(),
      }
    case SCorpSetUpStatus.withdrew_election:
    case SCorpSetUpStatus.disqualified_automatically:
    case SCorpSetUpStatus.disqualified_manually:
    case SCorpSetUpStatus.blocked_by_entity_ein:
    case SCorpSetUpStatus.blocked_by_signature:
    case SCorpSetUpStatus.qualification_pending:
    case SCorpSetUpStatus.drafting_form_2553:
    case SCorpSetUpStatus.not_interested:
      return {}
    default:
      return status satisfies never
  }
}

const SCorpPanel = ({ userId }: { userId: number }) => {
  const dispatch = useAppDispatch()

  const [editDatesModalOpen, setEditDatesModalOpen] = useState(false)

  const electionStatus = useReselector(selectSCorpSetUpStatusesForUser, userId)

  useEffect(() => {
    dispatch(fetchSCorpElectionStatus(userId, true))
  }, [dispatch, userId])

  const formattedImportantDates = useMemo(() => {
    if (!electionStatus) {
      return []
    }
    return [
      {
        label: 'Request Submitted On:',
        date: electionStatus.initialRequestSubmittedAt
          ? formatDateForUpsellImportantDates(
              electionStatus.initialRequestSubmittedAt
            )
          : '-',
        time: electionStatus.initialRequestSubmittedAt
          ? formatTimeForUpsellImportantDates(
              electionStatus.initialRequestSubmittedAt
            )
          : '-',
        meridian: electionStatus.initialRequestSubmittedAt
          ? getMeridianForDateTime(electionStatus.initialRequestSubmittedAt)
          : '-',
      },
      {
        label: 'Form 2553 Filed On:',
        date: electionStatus.form2553FiledAt
          ? formatDateForUpsellImportantDates(electionStatus.form2553FiledAt)
          : '-',
        time: electionStatus.form2553FiledAt
          ? formatTimeForUpsellImportantDates(electionStatus.form2553FiledAt)
          : '-',
        meridian: electionStatus.form2553FiledAt
          ? getMeridianForDateTime(electionStatus.form2553FiledAt)
          : '-',
      },
      {
        label: 'CP261 Received On:',
        date: electionStatus.cp261UploadedAt
          ? formatDateForUpsellImportantDates(electionStatus.cp261UploadedAt)
          : '-',
        time: electionStatus.cp261UploadedAt
          ? formatTimeForUpsellImportantDates(electionStatus.cp261UploadedAt)
          : '-',
        meridian: electionStatus.cp261UploadedAt
          ? getMeridianForDateTime(electionStatus.cp261UploadedAt)
          : '-',
      },
    ]
  }, [electionStatus])

  const handleRequestSourceDropdownChange = async (source: RequestSource) => {
    if (!electionStatus) {
      await postSCorpElectionStatus({
        userId,
        status: SCorpSetUpStatus.request_submitted,
        initialRequestSource: source,
      })(dispatch)
    } else {
      await putSCorpElectionStatus({
        id: electionStatus.id,
        payload: {
          initialRequestSource: source,
        },
      })(dispatch)
    }
  }

  const handleSetUpStatusDropdownChange = async (status: SCorpSetUpStatus) => {
    if (!electionStatus) {
      return
    }

    await putSCorpElectionStatus({
      id: electionStatus.id,
      payload: {
        status,
        ...makeAdditionalParamsForImportantDates(status),
      },
    })(dispatch)
  }

  return (
    <Card fullWidth>
      <Grid>
        <GridRowColumn>
          <Text as="h2">S Corporation Election</Text>
        </GridRowColumn>
        <GridRowColumn style={{ paddingBottom: 5 }} width={8}>
          <LabelDescription label="Request Source" required />
          <Dropdown
            fullWidth
            options={valsToDropdownOptions(
              Object.values(RequestSource),
              formatRequestSourceLabel
            )}
            text={
              electionStatus?.initialRequestSource
                ? formatRequestSourceLabel(electionStatus.initialRequestSource)
                : 'No request made'
            }
            defaultValue={
              electionStatus?.initialRequestSource
                ? formatRequestSourceLabel(electionStatus.initialRequestSource)
                : 'No request made'
            }
            onChange={handleRequestSourceDropdownChange}
          />
        </GridRowColumn>
        {electionStatus && (
          <>
            <GridRowColumn style={{ paddingBottom: 5 }} width={8}>
              <LabelDescription label="Set Up Status" required />
              <Dropdown
                fullWidth
                options={valsToDropdownOptions(
                  Object.values(SCorpSetUpStatus),
                  formatSetUpStatusLabelForSetUpStatus
                )}
                text={formatSetUpStatusLabelForSetUpStatus(
                  electionStatus.status
                )}
                defaultValue={formatSetUpStatusLabelForSetUpStatus(
                  electionStatus.status
                )}
                onChange={handleSetUpStatusDropdownChange}
              />
            </GridRowColumn>

            <Grid.Row columns={2}>
              <Grid.Column width={14} verticalAlign="middle">
                <LabelDescription label="Important Dates" />
              </Grid.Column>
              <Grid.Column width={2}>
                <Button
                  variant="secondaryWhite"
                  onClick={() => {
                    setEditDatesModalOpen(true)
                  }}
                  size="small"
                  style={{ alignContent: 'center', alignItems: 'center' }}
                >
                  <Icon
                    icon={regular('pen-to-square')}
                    style={{ marginRight: 5 }}
                  />
                  Edit
                </Button>
              </Grid.Column>
            </Grid.Row>
            <GridRowColumn style={{ padding: 0 }}>
              <Divider />
            </GridRowColumn>
            {formattedImportantDates.map((date) => (
              <Grid.Row key={date.label} columns={3}>
                <Grid.Column width={10} verticalAlign="middle">
                  <Text>{date.label}</Text>
                </Grid.Column>
                <Grid.Column width={3} verticalAlign="middle">
                  <Text>{date.date}</Text>
                </Grid.Column>
                <Grid.Column width={3} verticalAlign="middle">
                  <Text>
                    {date.time} {date.meridian}
                  </Text>
                </Grid.Column>
              </Grid.Row>
            ))}
            <EditImportantDatesModal
              open={editDatesModalOpen}
              onClose={() => {
                setEditDatesModalOpen(false)
              }}
              electionStatus={electionStatus}
            />
          </>
        )}
      </Grid>
    </Card>
  )
}

export default SCorpPanel
