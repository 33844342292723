import React, { useCallback, useEffect } from 'react'
import {
  Grid,
  Table,
  Segment,
  Button,
  Icon,
  Loader,
  Popup,
} from 'semantic-ui-react'
import {
  fetchChangeLogsForSingleTransaction,
  FETCH_TRANSACTION_CHANGE_LOGS_KEY,
  TransactionChangeLog,
} from './transactionChangeLog.slice'
import { Dispatch, inOperator, ReduxState } from '../../../utils/typeHelpers'
import { connect } from 'react-redux'
import './style.scss'
import {
  getFetchError,
  getIsFetchingOrNotStarted,
} from '../../../reducers/fetch'
import { getTransactionChangeLogsByTransactionId } from './transactionChangeLog.selector'
import moment from 'moment'
import { useReselector } from '../../../utils/sharedHooks'

interface PassedProps {
  transactionId: number
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type StateProps = ReturnType<typeof mapStateToProps>
type Props = PassedProps & DispatchProps & StateProps

const gridStyle = {
  maxHeight: '240px',
  overflowY: 'auto',
  overflowX: 'hidden',
  margin: '0',
}

const DATE_FORMAT = 'MMM Do YYYY, hh:mm:ss a'

const TransactionChangeLogsPanel: React.FC<Props> = ({
  fetchChangeLogsForSingleTransaction,
  getFetchError,
  getIsFetchingOrNotStarted,
  transactionId,
}) => {
  const performAsync = useCallback(async () => {
    await fetchChangeLogsForSingleTransaction({ transactionId })
  }, [transactionId, fetchChangeLogsForSingleTransaction])

  useEffect(() => {
    performAsync()
  }, [performAsync])

  const transactionChangeLogs = useReselector(
    getTransactionChangeLogsByTransactionId,
    transactionId
  )

  const fetchError = getFetchError(transactionId)
  const isFetching = getIsFetchingOrNotStarted(transactionId)

  if (fetchError) {
    return (
      <Table.Cell
        style={{ position: 'relative' }}
        colSpan="12"
        textAlign="right"
      >
        {fetchError.message}. Please try again
      </Table.Cell>
    )
  } else {
    return (
      <TransactionChangeLogsGrid
        isFetching={isFetching}
        transactionChangeLogs={transactionChangeLogs}
      />
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchChangeLogsForSingleTransaction: ({
    transactionId,
  }: {
    transactionId: number
  }) => dispatch(fetchChangeLogsForSingleTransaction({ transactionId })),
})

const mapStateToProps = (state: ReduxState) => ({
  getIsFetchingOrNotStarted: (transactionId: number) =>
    getIsFetchingOrNotStarted(
      state,
      FETCH_TRANSACTION_CHANGE_LOGS_KEY + transactionId
    ),
  getFetchError: (transactionId: number) =>
    getFetchError(state, FETCH_TRANSACTION_CHANGE_LOGS_KEY + transactionId),
})

export default connect<StateProps, DispatchProps, PassedProps, ReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(TransactionChangeLogsPanel)

function getFormattedLogValues(value: unknown): string {
  if (value === null || typeof value === 'undefined') {
    return '--unset--'
  }

  if (typeof value === 'string') {
    const date = moment(value)

    if (date.isValid()) {
      return date.format(DATE_FORMAT)
    }

    return value.toString()
  }

  if (
    typeof value === 'boolean' ||
    typeof value === 'bigint' ||
    typeof value === 'number'
  ) {
    return value.toString()
  }

  // At this point, value is a custom json object
  if (value && typeof value === 'object') {
    // Render the name prop if applicable
    if (inOperator('name', value)) {
      return String(value.name)
    }

    // As a failsafe, render the value stringified
    return JSON.stringify(value)
  }

  return ''
}

const TransactionChangeLogsGrid = ({
  isFetching,
  transactionChangeLogs,
}: {
  isFetching: boolean
  transactionChangeLogs: TransactionChangeLog[] | null
}) => (
  <Table.Row className="expandable-transaction-row">
    <Table.Cell colSpan="12">
      <Grid style={gridStyle}>
        <Grid.Row stretched>
          {isFetching && (
            <Loader
              size="small"
              style={{ left: 'auto', right: '0', position: 'absolute' }}
              inline
              active={isFetching}
            />
          )}
          {transactionChangeLogs && transactionChangeLogs.length > 0 && (
            <>
              <Grid.Column width={3} />
              <Grid.Column width={3}>
                <Segment style={{ fontWeight: 'bold' }}>Author</Segment>
              </Grid.Column>
              <Grid.Column width={3}>
                <Segment style={{ fontWeight: 'bold' }}>Property</Segment>
              </Grid.Column>
              <Grid.Column width={4}>
                <Segment style={{ fontWeight: 'bold' }}>New Value</Segment>
              </Grid.Column>
              <Grid.Column width={3}>
                <Segment style={{ fontWeight: 'bold' }}>Old Value</Segment>
              </Grid.Column>
            </>
          )}
          {!isFetching &&
            (!transactionChangeLogs || transactionChangeLogs.length === 0) && (
              <Grid.Column
                style={{ position: 'relative' }}
                colSpan="12"
                textAlign="right"
              >
                This transaction does not have any changes yet
              </Grid.Column>
            )}
        </Grid.Row>

        {transactionChangeLogs?.map((log) => {
          const currentValue = getFormattedLogValues(log.currentValue)
          const previousValue = getFormattedLogValues(log.previousValue)
          const createdAt = moment(log.createdAt)

          // Null authors are assumed to the be auto-categorization bot
          const author =
            log.author !== null ? (
              `${log.author?.firstName} ${log.author?.lastName} (${log.authorId})`
            ) : (
              <div style={{ fontStyle: 'italic' }}>Auto Categorization Bot</div>
            )

          return (
            <Grid.Row key={`transaction-change-log-${log.id}`} stretched>
              <Grid.Column textAlign="right" width={3}>
                <Segment>
                  <Popup
                    content={createdAt.format(DATE_FORMAT)}
                    trigger={<div>{createdAt.fromNow()}</div>}
                    hoverable
                    position="right center"
                    basic
                    pinned
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column width={3}>
                <Segment>{author}</Segment>
              </Grid.Column>
              <Grid.Column width={3}>
                <Segment>{log.columnName}</Segment>
              </Grid.Column>
              <Grid.Column width={4}>
                <Segment>{currentValue}</Segment>
              </Grid.Column>
              <Grid.Column width={3}>
                <Segment>{previousValue}</Segment>
              </Grid.Column>
            </Grid.Row>
          )
        })}
      </Grid>
    </Table.Cell>
  </Table.Row>
)

export const TransactionChangeLogToggleButton = ({
  onClick,
}: {
  onClick: () => void
}) => (
  <Button basic icon onClick={onClick}>
    <Icon name="clock outline" />
  </Button>
)
