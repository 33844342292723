import { ReactNode, useEffect, useState } from 'react'
import { Loader } from 'semantic-ui-react'

import { useAppDispatch } from '../../../utils/typeHelpers'
import GettingStarted from './GettingStarted'
import LearnWithHeard from './LearnWithHeard'
import styled from 'styled-components'
import {
  useIsDeviceWidth,
  DeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { Colors } from '../../../styles/theme'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../OpenFeature'
import { fetchUserCatchUpBookkeepingStatus } from '../../CatchUpBookkeepingStatus/catchUpBookkeepingStatus.slice'
import { useReselector } from '../../../utils/sharedHooks'
import { selectCatchUpBookkeepingIsCompleteForUser } from '../../CatchUpBookkeepingStatus/catchUpBookkeepingStatus.selector'
import { OnboardingStepPhase } from '../../Onboarding/UserOnboardingSteps/onboarding.reducer'
import { selectHasUserCompletedOnboardingPhase } from '../../Onboarding/UserOnboardingSteps/onboarding.selectors'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { useNavigate } from 'react-router-dom'

const OnboardingDashboardWrapper = styled(
  ({ children, className }: { children: ReactNode; className?: string }) => {
    const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
    const isTablet = useIsDeviceWidth(DeviceWidth.tablet)
    const mobileOrTabletClass = `${isMobile && 'mobile'} ${isTablet && 'tablet'}`
    return (
      <div className={`${className} ${mobileOrTabletClass}`}>
        <div className={`getting-started ${mobileOrTabletClass}`}>
          <GettingStarted />
        </div>
        <div className={`additional-onboarding-content ${mobileOrTabletClass}`}>
          {children}
        </div>
      </div>
    )
  }
)({
  '&&&': {
    '.getting-started': {
      backgroundColor: Colors.moss,
      padding: '64px 40px 64px 40px',
      '&.tablet': {
        marginTop: '16px',
        padding: '64px 24px 64px 24px',
      },
      '&.mobile': {
        marginTop: '16px',
        padding: '32px 16px 32px 16px',
      },
    },
    '.additional-onboarding-content': {
      padding: '56px 40px',
      '&.tablet': {
        padding: '40px 24px',
      },
      '&.mobile': {
        padding: '40px 16px 24px 16px',
      },
    },
  },
})

const OnboardingDashboard = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const currentUser = useReselector(getCurrentUser)
  const shouldShowAhaMomentsMVP = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.ahaMomentsMVP,
    false
  )
  const catchUpBKComplete = useReselector(
    selectCatchUpBookkeepingIsCompleteForUser,
    currentUser?.id
  )
  const gettingStartedPhaseComplete = useReselector(
    selectHasUserCompletedOnboardingPhase,
    OnboardingStepPhase.gettingStarted
  )

  useEffect(() => {
    async function batchFetch() {
      await Promise.all([fetchUserCatchUpBookkeepingStatus()(dispatch)])
      setLoading(false)
    }
    batchFetch()
  }, [dispatch])

  useEffect(() => {
    if (catchUpBKComplete && gettingStartedPhaseComplete) {
      navigate('/dashboard')
    }
  }, [catchUpBKComplete, gettingStartedPhaseComplete, navigate])

  return loading ? (
    <Loader active />
  ) : (
    <OnboardingDashboardWrapper>
      {shouldShowAhaMomentsMVP && <LearnWithHeard />}
    </OnboardingDashboardWrapper>
  )
}

export default OnboardingDashboard
