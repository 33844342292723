import moment from 'moment'
import { CardProps } from 'semantic-ui-react'
import { Card, Label, Text } from '../../components/BaseComponents'
import { getFreeTrialEndsAt } from '../../selectors/user.selectors'
import { Colors } from '../../styles/theme'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { FREE_TRIAL_DAYS } from './helpers'
import { useReselector } from '../../utils/sharedHooks'

const labelStyle = {
  backgroundColor: Colors.sunrise,
  borderRadius: '100px !important',
  marginBottom: 12,
  color: Colors.forest,
  fontWeight: 'bold',
}

const FreeTrialBanner = (props: CardProps) => {
  const trialEndsAt = useReselector(getFreeTrialEndsAt)

  return (
    <Card {...props}>
      <Label style={labelStyle}>{FREE_TRIAL_DAYS}-DAY FREE TRIAL</Label>
      <Text as="bodySm">
        Your trial ends on{' '}
        {trialEndsAt
          ? moment(trialEndsAt).format(DATE_FORMATS.DISPLAY_SIMPLE)
          : ''}
      </Text>
    </Card>
  )
}

export default FreeTrialBanner
