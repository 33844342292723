import { Component, createRef, RefObject } from 'react'
import { FormikProps } from 'formik'

import { getShortCompName } from '../../helpers'
import { AmountInputForm } from './FixedCell'
import { Text } from '../../../../components/BaseComponents'
import { AddButton, EditButton } from './PayrollInputButtons'
import { Gusto_EmployeeHourlyCompensation } from '../../generated_gusto_types'

interface Props {
  hourlyComp: Gusto_EmployeeHourlyCompensation
  employeeUuid: string
  shown?: boolean
}

interface State {
  inputShown: boolean
}

interface FormValues {
  amount?: string
}

class HourlyCell extends Component<Props, State> {
  formRef: RefObject<FormikProps<FormValues>>
  constructor(props: Props) {
    super(props)
    this.state = { inputShown: false }

    this.formRef = createRef<FormikProps<FormValues>>()
  }

  calculate() {
    const { hourlyComp } = this.props
    return {
      ...hourlyComp,
      hours: this.formRef.current?.values.amount || hourlyComp.hours,
    }
  }

  render() {
    const { hourlyComp, employeeUuid, shown } = this.props
    const { inputShown } = this.state

    // Calculations require hours to come back BUT hours cannot be edited in certain cases.
    // This flag is sent in to prevent content rendering but allow the calculate function to still run
    if (shown === false) {
      return null
    }

    if (!inputShown) {
      // If there is time show edit pencil, otherwise show add button
      if (Number(hourlyComp.hours)) {
        return (
          <div className="hour-cell">
            <EditButton
              onClick={() => this.setState({ inputShown: true })}
              text={`${hourlyComp.hours} ${getShortCompName(hourlyComp.name)}`}
            />
          </div>
        )
      } else {
        return (
          <div className="hour-cell">
            <AddButton
              onClick={() => this.setState({ inputShown: true })}
              text={hourlyComp.name}
            />
          </div>
        )
      }
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 8,
        }}
      >
        <Text style={{ width: 30 }}>
          <b>{getShortCompName(hourlyComp.name)}</b>
        </Text>
        <AmountInputForm
          amount={hourlyComp.hours}
          ref={this.formRef}
          employeeUuid={employeeUuid}
          numSchema={{
            field: 'number of hours worked',
            article: 'the',
            allowedDecimals: 3,
          }}
        />
        <Text style={{ marginLeft: 5 }}>hrs</Text>
      </div>
    )
  }
}

export default HourlyCell
