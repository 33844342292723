import { ReactNode, useState, useCallback } from 'react'

/*
  Component structure
    - UploadButton x 1
    - UploadModal x 1
      - UploadLandingScreen x 1
      - UploadCSVScreen x 1
      - UploadReviewScreen x 1
      - UploadDuplicateScreen x 1
        - UploadDuplicateRow x N
*/

// UI
import './style.scss'
import Screens, { ScreenData } from './screens'
import { Button, Icon, Modal, Text } from '../../../components/BaseComponents'
import {
  Dropdown as SemanticDropdown,
  DropdownDivider,
} from 'semantic-ui-react'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { useFlyout } from '../../../utils/sharedHooks'
import AddTransaction, { TransactionTypes } from './AddTransaction'
import { useAnalyticsTrack } from '../../Amplitude'

export interface UploadSuccessPayload {
  id?: number
  date: string
  description: string
  amountInDollars: string
}

interface UploadButtonProps {
  onUploadComplete: (_args: {
    success: boolean
    data?: UploadSuccessPayload[]
  }) => void
  render?: (props: { onClick: () => void }) => ReactNode
}

const UploadButton = ({ render, onUploadComplete }: UploadButtonProps) => {
  const [open, setOpen] = useState(false)
  const defaultScreenIndex = 1
  const [screenIndex, setScreenIndex] = useState(defaultScreenIndex)
  const [screenData, setScreenData] = useState<ScreenData>({})
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const { closeFlyout, openFlyout } = useFlyout()
  const track = useAnalyticsTrack()

  const toggleAddTransactionsModal = () => {
    setOpen((prev) => !prev)
    setScreenIndex(defaultScreenIndex)
  }

  const toggleFlyout = (transactionType: string) => {
    const isIncomeTxn = transactionType === TransactionTypes.INCOME
    const flyoutIcon = isIncomeTxn ? solid('sack-dollar') : solid('credit-card')

    openFlyout({
      content: (
        <AddTransaction
          closeFlyout={closeFlyout}
          onUploadComplete={onUploadComplete}
          transactionType={transactionType}
        />
      ),
      title: `Add ${transactionType}`,
      direction: 'right',
      titleIcon: flyoutIcon,
    })
    track('clicked add transaction flyout button')
  }

  const goToScreenWithData = ({
    index,
    data,
  }: {
    index: number
    data?: ScreenData
  }) => {
    setScreenData(data || {})
    setScreenIndex(index)
  }

  const CurrentScreen = Screens[screenIndex]

  const close = useCallback(() => setOpen(false), [])

  return (
    <>
      {!render && (
        <SemanticDropdown
          trigger={
            <Button
              style={{ display: 'flex', gap: 10, marginBottom: 5 }}
              size="large"
            >
              <Icon name="plus" icon={regular('plus')} size="1x" />
              {isMobile ? 'Add' : 'Add Transaction'}
              <Icon name="down" icon={solid('caret-down')} size="1x" />
            </Button>
          }
          icon={null}
        >
          <SemanticDropdown.Menu
            direction="left"
            style={{ minWidth: '100%', left: 'unset', right: 0 }}
          >
            <SemanticDropdown.Item
              onClick={() => toggleFlyout(TransactionTypes.INCOME)}
            >
              <Text>Add Income</Text>
            </SemanticDropdown.Item>
            <SemanticDropdown.Item
              onClick={() => toggleFlyout(TransactionTypes.EXPENSE)}
            >
              <Text>Add Expense</Text>
            </SemanticDropdown.Item>
            <DropdownDivider />
            <SemanticDropdown.Item onClick={toggleAddTransactionsModal}>
              <Text>Add Multiple Transactions</Text>
            </SemanticDropdown.Item>
          </SemanticDropdown.Menu>
        </SemanticDropdown>
      )}
      {render?.({ onClick: toggleAddTransactionsModal })}
      <Modal
        id="upload-transactions-modal"
        closeIcon
        onClose={close}
        content={
          <CurrentScreen
            data={screenData}
            onUploadComplete={onUploadComplete}
            finishFlow={close}
            goToScreenWithData={goToScreenWithData}
          />
        }
        open={open}
      />
    </>
  )
}

export default UploadButton
