import { Image } from 'semantic-ui-react'
import styled from 'styled-components'
import parse, { Element } from 'html-react-parser'

import { Colors, Fonts } from '../../styles/theme'

// Takes in an html string (often returned by external apis) and converts it into dom with Heard styling
const ParsedHtml = styled(
  ({ children, className }: { children: string; className?: string }) => (
    <div className={className}>
      {parse(children, {
        // This code makes any image in parsed html clickable and opens it in a new tab.  Depending on wrapper these can
        // end up small and difficult to see
        replace: (domNode) => {
          if (domNode instanceof Element && domNode.name === 'img') {
            return (
              <Image
                style={{ height: '100%', width: '100%', cursor: 'pointer' }}
                src={domNode.attribs.src}
                onClick={() =>
                  window.open(domNode.attribs.src, '_blank', 'noreferrer')
                }
              />
            )
          }

          return undefined
        },
      })}
    </div>
  )
)({
  'ol, li, p': {
    ...Fonts['bodyMd'],
    color: Colors.charcoal,
  },
  a: {
    ...Fonts.link,
    '&:hover': {
      color: Colors.black,
    },
  },
  h1: {
    ...Fonts['h1'],
    color: Colors.black,
  },
  h2: {
    ...Fonts['h2'],
    color: Colors.black,
  },
  h3: {
    ...Fonts['h3'],
    color: Colors.black,
  },
  img: {
    width: '100%',
    height: '100%',
  },
})

export default ParsedHtml
