import { useCallback, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import {
  GridRowColumn,
  Alert,
  Loader,
} from '../../../../../../components/BaseComponents'
import {
  useSetScreen,
  useFormFlow,
} from '../../../../../../components/FormFlow/formFlow'
import PageHeader from '../../../../../../components/shared/PageHeader'
import {
  selectErrorsForKeys,
  selectIsFetchingForKeys,
  invalidateFetch,
} from '../../../../../../reducers/fetch'
// import { fetchSkyflowSsnTokensIfNeeded } from '../../../../../../reducers/skyflowSlice'
import { getCurrentUser } from '../../../../../../selectors/user.selectors'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { fetchAnnualTaxFilingFormsIfNeeded } from '../../../annualTaxFilingForms.slice'
import { fetchUserTaxQuestionnaire } from '../../taxChecklistQuestion.actions'
import ReviewIncome from './ReviewIncomePanel'
import ReviewExpenses from './ReviewExpensesPanel'
import { updateUserEoyReviewProgress } from '../ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { SubStepIdentifiers } from '../ReviewStepsandProgresses/stepProgress.helpers'
import useProgressSteps from '../ReviewStepsandProgresses/useProgressSteps'
import { TAX_ENTITY_TYPES } from '../../../../taxConstants'
import { selectAnnualTaxFilingFormById } from '../../../annualTaxFilingForms.selector'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'

export enum INCOME_AND_EXPENSES_SCREENS {
  reviewIncome = 'review-income',
  reviewExpenses = 'review-expenses',
}

export interface ReviewIncomeAndExpensesProps {
  goToNextStep: (
    progressId?: number,
    newScreen?: INCOME_AND_EXPENSES_SCREENS | null
  ) => Promise<void>
  goBack: (newScreen: INCOME_AND_EXPENSES_SCREENS | null) => void
  nextScreen?: INCOME_AND_EXPENSES_SCREENS | null
  previousScreen?: INCOME_AND_EXPENSES_SCREENS | null
}

const ReviewIncomeAndExpenses = () => {
  const dispatch = useAppDispatch()
  const { formId } = useParams()
  const { setScreen, currentScreen } = useSetScreen({
    backLink: `/taxes/annual/tax_checklist/${formId}`,
  })
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const user = useReselector(getCurrentUser)
  const { fetchKeys: progressFetchKeys } = useProgressSteps({
    steps: [
      SubStepIdentifiers.reviewIncomeInitial,
      SubStepIdentifiers.reviewExpensesInitial,
      SubStepIdentifiers.reviewIncomeFinal,
      SubStepIdentifiers.reviewExpensesFinal,
    ],
  })
  const allFetchKeys = useMemo(() => {
    return [...progressFetchKeys]
  }, [progressFetchKeys])

  const updateError = useReselector(selectErrorsForKeys, allFetchKeys)
  const isFetching = useReselector(selectIsFetchingForKeys, allFetchKeys)
  const filingForm = useReselector(selectAnnualTaxFilingFormById, formId)
  const is1040 = filingForm?.formType?.name === TAX_ENTITY_TYPES.form_1040

  const updateProgress = useCallback(
    async (
      progressId: number | undefined,
      newScreen: INCOME_AND_EXPENSES_SCREENS | null | undefined = null
    ) => {
      allFetchKeys.forEach((key) => dispatch(invalidateFetch(key)))
      const updated = Boolean(
        await dispatch(
          updateUserEoyReviewProgress(progressId ?? -1, {
            completedAt: new Date(),
          })
        )
      )
      if (updated) {
        setScreen(newScreen)
      }
    },
    [dispatch, setScreen, allFetchKeys]
  )

  const screenConfig = useMemo(() => {
    const props = { goToNextStep: updateProgress, goBack: setScreen }
    return [
      {
        component: () => <ReviewIncome {...props} />,
        screenName: INCOME_AND_EXPENSES_SCREENS.reviewIncome,
        step: 0,
      },
      {
        component: () => <ReviewExpenses {...props} />,
        screenName: INCOME_AND_EXPENSES_SCREENS.reviewExpenses,
        step: 1,
      },
    ]
  }, [updateProgress, setScreen])

  const { progressBar, content } = useFormFlow({
    steps: ['Review Income', 'Review Expenses'],
    screens: screenConfig,
  })

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    // if (user?.id) {
    //   dispatch(fetchSkyflowSsnTokensIfNeeded(user.id.toString()))
    // }
  }, [dispatch, user?.id])

  useEffect(() => {
    dispatch(fetchUserTaxQuestionnaire(taxYear))
  }, [taxYear, dispatch])

  useEffect(() => {
    if (!currentScreen) {
      setScreen(INCOME_AND_EXPENSES_SCREENS.reviewIncome, true)
    }
  }, [setScreen, currentScreen])

  return (
    <>
      <PageHeader
        header={`${taxYear} ${
          is1040 ? 'Personal' : 'Business'
        } Tax Checklist: Review Income and Expenses`}
      />
      <Grid>
        <Loader loading={isFetching} />
        {!isFetching && (
          <>
            <GridRowColumn>{progressBar}</GridRowColumn>
            {updateError.length > 0 && (
              <GridRowColumn>
                <Alert type="error">{updateError[0].message}</Alert>
              </GridRowColumn>
            )}
            <GridRowColumn>{content}</GridRowColumn>
          </>
        )}
      </Grid>
    </>
  )
}

export default ReviewIncomeAndExpenses
