import { Grid, Header, Image } from 'semantic-ui-react'
import { Card, Icon, Text } from '../../../components/BaseComponents'
import { NumberFormatWrapper } from '../../../components/shared/NumberIconList'
import { NumericFormat } from 'react-number-format'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

const SetupLandingCardItems = ({
  stepNumber,
  headerText,
  bodyText,
  timeEstimate,
  imageSrc,
  imageAlt,
}: {
  stepNumber: number
  headerText: string
  bodyText: string
  timeEstimate: string
  imageSrc: string
  imageAlt: string
}) => {
  return (
    <Card type="subsection" backgroundColor="stone40">
      <Grid columns={3}>
        <Grid.Column width={2}>
          <NumberFormatWrapper>
            <NumericFormat
              style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
              displayType={'text'}
              value={stepNumber}
            />
          </NumberFormatWrapper>
        </Grid.Column>
        <Grid.Column width={9}>
          <Header as="h3">{headerText}</Header>
          <Text as="bodyMd">{bodyText}</Text>
          <Text
            as="bodySm"
            style={{
              marginTop: '16px',
            }}
          >
            <Icon icon={regular('timer')} style={{ marginRight: 8 }} />
            {timeEstimate}
          </Text>
        </Grid.Column>
        <Grid.Column
          width={5}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Image
            src={imageSrc}
            alt={imageAlt}
            style={{
              width: 116,
              height: 80,
              marginTop: 16,
            }}
          />
        </Grid.Column>
      </Grid>
    </Card>
  )
}

export default SetupLandingCardItems
