import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { keyBy } from 'lodash'

import { fetchIfNeededWrapper, fetchWrapper } from '../../../reducers/fetch'
import { UploadDocumentType } from '../../../constants/businessConstants'

// these internal names are based off of the categories in prod and staging. if a new user document
// category is created and pertains to S-Corps, then this list may need to be updated.
export const SCorpUserDocumentCategoriesIdents = [
  /\d{4}_1120s/g,
  'form_2553',
  'cp261_notice',
  's_corp_documents', // prod
  's_corp_filing', // staging
]

export interface UserDocumentCategory {
  id: number
  internalName: string
  title: string
  description: string | null
  type: UploadDocumentType | null
  requiredFor: string[] | null
  createdAt: string
  updatedAt: string
  isAnnual: boolean
  isInternal: boolean
  archivedAt: string | null
  downloadFormUrl?: string | null
  viewExampleUrl?: string | null
}

export interface UserDocumentCategoryState {
  [key: string]: UserDocumentCategory
}

const userDocumentCategorySlice = createSlice({
  name: 'userDocumentCategories',
  initialState: {} as UserDocumentCategoryState,
  reducers: {
    receiveUserDocumentCategories: (
      state,
      action: PayloadAction<{
        [key: string]: UserDocumentCategory
      }>
    ) => ({ ...state, ...action.payload }),
    receiveSingleUserDocumentCategory: (
      state,
      action: PayloadAction<UserDocumentCategory>
    ) => {
      state[action.payload.id] = action.payload
    },
  },
})

export default userDocumentCategorySlice.reducer

// Actions
const { receiveUserDocumentCategories, receiveSingleUserDocumentCategory } =
  userDocumentCategorySlice.actions

export const FETCH_DOCUMENT_CATEGORIES_KEY = 'FETCH_DOCUMENT_CATEGORIES_KEY'

export const fetchUserDocumentCategoriesIfNeeded = () =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_DOCUMENT_CATEGORIES_KEY,
    defaultErrorMessage: 'Error fetching all user document categories',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<UserDocumentCategory[]>(
        '/finances/api/v1/user_document/categories'
      )
      dispatch(receiveUserDocumentCategories(keyBy(json.data, 'id')))
      return json.data
    },
  })

export const CREATE_USER_DOCUMENT_CATEGORIES_KEY =
  'CREATE_USER_DOCUMENT_CATEGORIES_KEY'
export const createUserDocumentCategories = (
  data: Omit<
    UserDocumentCategory,
    'id' | 'createdAt' | 'updatedAt' | 'isAnnual' | 'isInternal' | 'archivedAt'
  >
) =>
  fetchWrapper({
    defaultErrorMessage: 'Error creating user document category',
    defaultValue: false,
    fetchKey: CREATE_USER_DOCUMENT_CATEGORIES_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<UserDocumentCategory>(
        '/finances/api/v1/admin/user_document/categories',
        data
      )
      dispatch(receiveSingleUserDocumentCategory(json.data))
      return json.data
    },
  })

export const UPDATE_USER_DOCUMENT_CATEGORIES_KEY =
  'UPDATE_USER_DOCUMENT_CATEGORIES_KEY'
export const updateUserDocumentCategories = (
  id: number,
  data: Partial<UserDocumentCategory>
) =>
  fetchWrapper({
    defaultErrorMessage: 'Error updating user document',
    defaultValue: false,
    fetchKey: UPDATE_USER_DOCUMENT_CATEGORIES_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.put<UserDocumentCategory>(
        `/finances/api/v1/admin/user_document/categories/${id}`,
        data
      )
      dispatch(receiveSingleUserDocumentCategory(json.data))
      return json.data
    },
  })
