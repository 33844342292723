import { Icon } from '../../BaseComponents'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import PlaidLink from '../../shared/plaid/PlaidLink'
import {
  useIsDeviceWidth,
  DeviceWidth,
} from '../../../utils/deviceWidthHelpers'

export const NewInstitutionButton = () => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  return (
    <PlaidLink
      buttonVariant="secondary"
      connectButtonText={
        <>
          <Icon icon={regular('plus')} size="sm" style={{ marginRight: 8 }} />
          {isMobile ? 'New' : 'New Financial Institution'}
        </>
      }
    />
  )
}
