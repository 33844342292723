import { useEffect, useMemo, useState } from 'react'
import { Divider, Grid, Loader, Transition } from 'semantic-ui-react'
import moment from 'moment'
import currency from 'currency.js'

import CurrencyFormatLabel from '../../components/shared/CurrencyFormatLabel'
import { useAppDispatch } from '../../utils/typeHelpers'
import { centsToDollars } from '../../utils/currencyHelpers'
import {
  getProfitAndLossTransaction,
  getProfitAndLossTransactionsByCategoryId,
} from '../Transactions/transactions.selectors'
import { getTransactionCategoryNameSelector } from './reports.selectors'
import { usePrevious, useReselector } from '../../utils/sharedHooks'
import { fetchTransactionsByCategoryId } from '../../actions/admin/transactionActions'
import {
  Accordion,
  Button,
  Card,
  GridRowColumn,
  Text,
} from '../../components/BaseComponents'
import { Expense } from '../../actions/reportActions'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'
import { JournalEntryType } from '../JournalEntries/types'
import { fetchAdminJournalEntryLines } from '../../actions/reportActionsV2'
import {
  selectJournalEntrLineById,
  selectJournalEntryLineIdsByCategoryId,
} from '../../reducers/admin/journalEntryLineReducer'

const LineItemTransaction = ({
  transactionId,
  userIdForAdmin,
}: {
  transactionId: number
  userIdForAdmin: number | null
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const transaction = useReselector(
    getProfitAndLossTransaction,
    userIdForAdmin,
    transactionId
  )

  const title = useMemo(
    () => (
      <Text style={{ marginRight: '10px' }}>
        {moment.utc(transaction?.date).format(DATE_FORMATS.DISPLAY_SHORT)}
      </Text>
    ),
    [transaction?.date]
  )

  const amount = useMemo(
    () => (
      <Text textAlign="right">
        <CurrencyFormatLabel
          value={centsToDollars(transaction?.amountInCents)}
        />
      </Text>
    ),
    [transaction?.amountInCents]
  )

  const description = useMemo(
    () => (
      <Text style={{ width: '100%' }}>
        &nbsp;{transaction?.description || 'N/A'}
      </Text>
    ),
    [transaction?.description]
  )

  if (!transaction) {
    return null
  }

  if (isMobile) {
    return (
      <>
        <Grid.Row>
          <Grid.Column width={8}>{title}</Grid.Column>
          <Grid.Column width={8}>{amount}</Grid.Column>
        </Grid.Row>
        <GridRowColumn short>{description}</GridRowColumn>
      </>
    )
  }

  return (
    <Grid.Row className="short">
      <Grid.Column computer={3} tablet={4}>
        {title}
      </Grid.Column>
      <Grid.Column computer={11} tablet={8}>
        {description}
      </Grid.Column>
      <Grid.Column computer={2} tablet={4}>
        {amount}
      </Grid.Column>
    </Grid.Row>
  )
}

const LineItemJournalEntry = ({
  journalEntryId,
}: {
  journalEntryId: number
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const journalEntryLine = useReselector(
    selectJournalEntrLineById,
    journalEntryId
  )

  const title = useMemo(
    () => (
      <Text style={{ marginRight: '10px' }}>
        {moment
          .utc(journalEntryLine.journalEntry.journalEntryDate)
          .format(DATE_FORMATS.DISPLAY_SHORT)}
      </Text>
    ),
    [journalEntryLine.journalEntry.journalEntryDate]
  )

  const amount = useMemo(() => {
    const multiplier =
      journalEntryLine.entryType === JournalEntryType.DEBIT ? -1 : 1
    const value = centsToDollars(journalEntryLine.amountInCents) * multiplier
    return (
      <Text textAlign="right">
        <CurrencyFormatLabel value={value} />
      </Text>
    )
  }, [journalEntryLine.amountInCents, journalEntryLine.entryType])

  const description = useMemo(
    () => (
      <Text style={{ width: '100%' }}>
        &nbsp;{journalEntryLine.journalEntry.transaction?.description || ''}
      </Text>
    ),
    [journalEntryLine.journalEntry.transaction?.description]
  )

  const note = useMemo(
    () => (
      <Text style={{ width: '100%', color: 'GrayText' }}>
        {journalEntryLine.journalEntry.details || ''}
      </Text>
    ),
    [journalEntryLine.journalEntry.details]
  )

  if (!journalEntryLine) {
    return null
  }

  if (isMobile) {
    return (
      <>
        <Grid.Row>
          <Grid.Column width={8}>{title}</Grid.Column>
          <Grid.Column width={8}>{amount}</Grid.Column>
        </Grid.Row>
        <GridRowColumn short>{description}</GridRowColumn>
        <GridRowColumn short>{note}</GridRowColumn>
      </>
    )
  }

  return (
    <Grid.Row className="short">
      <Grid.Column computer={3} tablet={4}>
        {title}
      </Grid.Column>
      <Grid.Column computer={6} tablet={5}>
        {description}
      </Grid.Column>
      <Grid.Column computer={5} tablet={3}>
        {note}
      </Grid.Column>
      <Grid.Column computer={2} tablet={4}>
        {amount}
      </Grid.Column>
    </Grid.Row>
  )
}

const LineItemExpanded = ({
  item,
  startDate,
  endDate,
  open,
  userIdForAdmin,
  confirmed,
}: {
  item: { sum: number | string; transactionCategoryId: number | null }
  startDate: string | undefined
  endDate: string | undefined
  open: boolean
  userIdForAdmin: number | null
  confirmed?: boolean
}) => {
  const dispatch = useAppDispatch()
  const [viewAllTransactions, setViewAllTransactions] = useState(false)
  const [viewedTransactionIds, setViewedTransactionIds] = useState<number[]>([])
  const [viewedJournalEntryIds, setViewedJournalEntryIds] = useState<number[]>(
    []
  )
  const [hasBeenFetched, setHasBeenFetched] = useState(false)
  const previousStartDate = usePrevious(startDate)
  const previousEndDate = usePrevious(endDate)

  useEffect(() => {
    if (previousStartDate !== startDate || endDate !== previousEndDate) {
      setHasBeenFetched(false)
    }
  }, [startDate, endDate, previousStartDate, previousEndDate])

  const transactionsByCategory = useReselector(
    getProfitAndLossTransactionsByCategoryId,
    userIdForAdmin,
    item.transactionCategoryId
  )

  const journalEntriesByCategory = useReselector(
    selectJournalEntryLineIdsByCategoryId,
    startDate,
    endDate,
    item.transactionCategoryId
  )

  useEffect(() => {
    if (open && !hasBeenFetched) {
      fetchTransactionsByCategoryId({
        id: item.transactionCategoryId,
        limit: null,
        startDate: startDate ? moment(startDate, 'MM-DD-YYYY') : undefined,
        endDate: endDate ? moment(endDate, 'MM-DD-YYYY') : undefined,
        userId: userIdForAdmin,
      })(dispatch)
      fetchAdminJournalEntryLines({
        startDate:
          moment(startDate, 'MM-DD-YYYY') ?? moment('01-01-2023', 'MM-DD-YYYY'),
        endDate:
          moment(endDate, 'MM-DD-YYYY') ?? moment('12-31-2023', 'MM-DD-YYYY'),
        userId: Number(userIdForAdmin),
        transactionCategoryId: Number(item.transactionCategoryId),
      })(dispatch)
      setHasBeenFetched(true)
    }
  }, [
    item.transactionCategoryId,
    dispatch,
    endDate,
    startDate,
    open,
    hasBeenFetched,
    userIdForAdmin,
  ])

  const numOfTransactions = transactionsByCategory.transactionIds.length

  useEffect(() => {
    if (confirmed) {
      if (!journalEntriesByCategory.length) {
        return
      }
      if (viewAllTransactions) {
        setViewedJournalEntryIds(journalEntriesByCategory)
      } else {
        setViewedJournalEntryIds(journalEntriesByCategory.slice(0, 10))
      }
    } else {
      if (!transactionsByCategory.transactionIds.length) {
        return
      }
      if (viewAllTransactions) {
        setViewedTransactionIds(transactionsByCategory.transactionIds)
      } else {
        setViewedTransactionIds(
          transactionsByCategory.transactionIds.slice(0, 10)
        )
      }
    }
  }, [
    viewAllTransactions,
    transactionsByCategory.transactionIds,
    journalEntriesByCategory,
    numOfTransactions,
    confirmed,
  ])

  if (
    !open ||
    (!viewedTransactionIds.length && !viewedJournalEntryIds.length)
  ) {
    return null
  }

  if (confirmed) {
    return (
      <Grid>
        <GridRowColumn short>
          <Divider />
        </GridRowColumn>

        {viewedJournalEntryIds.map((id) => (
          <LineItemJournalEntry key={id} journalEntryId={id} />
        ))}

        <Grid.Row short>
          {Boolean(numOfTransactions) && (
            <Grid.Column width={8}>
              <Text color="darkGray">
                Showing {viewedJournalEntryIds.length} of&nbsp;
                {journalEntriesByCategory.length}
              </Text>
            </Grid.Column>
          )}
          {numOfTransactions > 10 && (
            <Grid.Column
              width={8}
              style={{ display: 'flex', justifyContent: 'end' }}
            >
              <Button
                variant="secondaryLink"
                onClick={() => setViewAllTransactions((prev) => !prev)}
              >
                {viewAllTransactions
                  ? 'View less journal entries'
                  : 'View all journal entries'}
              </Button>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    )
  }

  return (
    <Grid>
      <GridRowColumn short>
        <Divider />
      </GridRowColumn>

      {viewedTransactionIds.map((id) => (
        <LineItemTransaction
          key={id}
          transactionId={id}
          userIdForAdmin={userIdForAdmin}
        />
      ))}

      <Grid.Row short>
        {Boolean(numOfTransactions) && (
          <Grid.Column width={8}>
            <Text color="darkGray">
              Showing {viewedTransactionIds.length} of&nbsp;
              {transactionsByCategory.totalCount}
            </Text>
          </Grid.Column>
        )}
        {numOfTransactions > 10 && (
          <Grid.Column
            width={8}
            style={{ display: 'flex', justifyContent: 'end' }}
          >
            <Button
              variant="secondaryLink"
              onClick={() => setViewAllTransactions((prev) => !prev)}
            >
              {viewAllTransactions
                ? 'View less transactions'
                : 'View all transactions'}
            </Button>
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  )
}

const LineItem = ({
  item,
  startDate,
  endDate,
  userIdForAdmin,
  isProfit,
  confirmed,
}: {
  item: {
    percentage?: string
    sum: number | string
    transactionCategoryId: number | null
  }
  startDate: string | undefined
  endDate: string | undefined
  userIdForAdmin: number | null
  isProfit?: boolean
  confirmed?: boolean
}) => {
  const categoryName = useReselector(
    getTransactionCategoryNameSelector,
    item.transactionCategoryId
  )

  return (
    <GridRowColumn short>
      <Accordion
        title={
          <>
            <Text as="h3" style={{ padding: 5 }}>
              {categoryName}
            </Text>
            <Text
              as="bodyLg"
              style={{ flex: 1, textAlign: 'end', marginRight: 60 }}
            >
              <CurrencyFormatLabel value={item?.sum} />
            </Text>
          </>
        }
        content={(open) => (
          <Transition.Group animation={'drop'} duration={500}>
            <LineItemExpanded
              item={item}
              startDate={startDate}
              endDate={endDate}
              userIdForAdmin={userIdForAdmin}
              open={open}
              confirmed={confirmed}
            />
          </Transition.Group>
        )}
        backgroundColor={isProfit ? 'natural' : 'stone40'}
      />
    </GridRowColumn>
  )
}

const PNLSection = ({
  title,
  totalText = 'Total',
  sum,
  expenses,
  startDate,
  endDate,
  userIdForAdmin,
  isProfit,
  confirmed,
}: {
  title: string
  totalText?: string
  sum: number | string
  expenses: Array<Pick<Expense, 'transactionCategoryId' | 'sum'>>
  startDate: string | undefined
  endDate: string | undefined
  userIdForAdmin: number | null
  isProfit?: boolean
  confirmed?: boolean
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  return (
    <Grid doubling>
      <Grid.Row>
        <Grid.Column computer={8} tablet={8} mobile={16}>
          <Text as="h2">{title}</Text>
        </Grid.Column>
        <Grid.Column computer={8} tablet={8} mobile={16}>
          <Text as="h3" textAlign={isMobile ? 'left' : 'right'}>
            {totalText}:&nbsp;
            <b>
              <CurrencyFormatLabel value={sum} />
            </b>
          </Text>
        </Grid.Column>
      </Grid.Row>
      {expenses.map((item) => (
        <LineItem
          item={item}
          key={item.transactionCategoryId}
          startDate={startDate}
          endDate={endDate}
          userIdForAdmin={userIdForAdmin}
          isProfit={isProfit}
          confirmed={confirmed}
        />
      ))}
    </Grid>
  )
}

const ProfitAndLossReports = ({
  startDate,
  endDate,
  profitsObj,
  expensesObj,
  uncategorizedObj,
  otherObj,
  fetchingData,
  userIdForAdmin,
  confirmed,
}: {
  startDate: string | undefined
  endDate: string | undefined
  profitsObj:
    | {
        profits: Array<Pick<Expense, 'transactionCategoryId' | 'sum'>>
        sum: number | string
      }
    | null
    | undefined
  expensesObj:
    | {
        expenses: Array<Pick<Expense, 'transactionCategoryId' | 'sum'>>
        sum: number | string
      }
    | null
    | undefined
  uncategorizedObj:
    | {
        sum: number | string
        transactionCategoryId: number | null
      }
    | null
    | undefined
  otherObj:
    | {
        otherExpenses: Array<Pick<Expense, 'transactionCategoryId' | 'sum'>>
        sum: number | string
      }
    | null
    | undefined
  fetchingData: boolean
  userIdForAdmin: number | null
  confirmed?: boolean
}) => {
  const uncategorizedData = useMemo(() => {
    if (!uncategorizedObj?.sum) {
      return null
    }
    const { sum, transactionCategoryId } = uncategorizedObj
    return (
      <>
        <Grid.Row />
        <Divider />
        <Grid.Row />
        <GridRowColumn>
          <Text as="h2">Awaiting Categorization</Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text as="bodyLg">
            Don’t worry about these— your bookkeeper will categorize these
            transactions each month.
          </Text>
        </GridRowColumn>
        <LineItem
          item={{ sum, transactionCategoryId }}
          startDate={startDate}
          endDate={endDate}
          userIdForAdmin={userIdForAdmin}
        />
      </>
    )
  }, [uncategorizedObj, startDate, endDate, userIdForAdmin])

  const netIncome = currency(profitsObj?.sum || 0).add(
    expensesObj?.sum || 0
  ).value

  if (fetchingData) {
    return <Loader inline active />
  }

  return (
    <Grid>
      <GridRowColumn>
        <PNLSection
          title="Income"
          totalText="Total Income"
          sum={profitsObj?.sum || 0}
          expenses={profitsObj?.profits || []}
          startDate={startDate}
          endDate={endDate}
          userIdForAdmin={userIdForAdmin}
          isProfit
          confirmed={confirmed}
        />
      </GridRowColumn>
      <Grid.Row />
      <GridRowColumn>
        <PNLSection
          title="Expenses"
          totalText="Total Expenses"
          sum={expensesObj?.sum || 0}
          expenses={expensesObj?.expenses || []}
          startDate={startDate}
          endDate={endDate}
          userIdForAdmin={userIdForAdmin}
          confirmed={confirmed}
        />
      </GridRowColumn>
      <Grid.Row />
      <GridRowColumn>
        <Card backgroundColor="lightGreen" type="subsection">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Text>Net Profit:</Text>
            <Text>
              <b>
                <CurrencyFormatLabel value={netIncome} />
              </b>
            </Text>
          </div>
        </Card>
      </GridRowColumn>

      {uncategorizedData}
      <Grid.Row />
      <Divider />
      <Grid.Row />
      <GridRowColumn>
        <PNLSection
          title="Other"
          sum={otherObj?.sum || 0}
          expenses={otherObj?.otherExpenses || []}
          startDate={startDate}
          endDate={endDate}
          userIdForAdmin={userIdForAdmin}
          confirmed={confirmed}
        />
      </GridRowColumn>
      <Grid.Row />
    </Grid>
  )
}

export default ProfitAndLossReports
