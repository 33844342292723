import { CSSProperties, ReactNode } from 'react'
import { Divider, Grid, GridColumn, Image } from 'semantic-ui-react'
import {
  Card,
  ProgressBar,
  Text,
  GridRowColumn,
  Button,
  Icon,
} from '../../components/BaseComponents'
import Tooltip from '../Taxes/QuarterlyTaxEstimates/components/Tooltip'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

const SETUP_STEPS = [
  'Connect bank accounts',
  'Bank account information',
  'Upload monthly statements',
]

export const SetupCard = ({
  currentStep,
  children,
  continueDisabled = false,
  onBackClick,
  onContinueClick,
  onSkipClick,
  showSkipButton = true,
  isSkipForNow = false,
  width = '100%',
  style = {},
  completeButtonContent,
  continueDisabledMessage,
}: {
  currentStep: number
  children?: ReactNode
  continueDisabled?: boolean
  onBackClick?: () => void
  onContinueClick?: () => void
  onSkipClick?: () => void
  showSkipButton?: boolean
  isSkipForNow?: boolean
  width?: string | number
  style?: CSSProperties
  completeButtonContent?: string
  continueDisabledMessage?: string
}) => {
  const skipText = isSkipForNow ? 'Skip For Now' : 'Skip'

  return (
    <Card
      backgroundColor="stone40"
      type="section"
      style={{ width, padding: 0, ...style }}
    >
      <Grid>
        <Grid.Row style={{ padding: '48px 32px 16px 32px' }}>
          <Grid.Column width={2}>
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/books_shadow.svg"
              alt="books"
              width={80}
              height={80}
            />
          </Grid.Column>
          <GridColumn width={13}>
            <Grid>
              <Grid.Column width={13}>
                <Text as="h2">Get Started With Heard</Text>
              </Grid.Column>
              <Grid.Column width={3}>
                <Icon
                  style={{ display: 'inline', marginRight: 8 }}
                  color="green"
                  icon={light('check')}
                />
                <Text as="bodyXs" color="green" style={{ display: 'inline' }}>
                  Autosaved
                </Text>
              </Grid.Column>
            </Grid>
            <ProgressBar steps={SETUP_STEPS} currentStep={currentStep} />
          </GridColumn>
        </Grid.Row>
        <GridRowColumn short>
          <Divider style={{ margin: 0 }} />
        </GridRowColumn>
        <GridRowColumn short style={{ padding: '8px 32px 24px' }}>
          {children}
        </GridRowColumn>
        <GridRowColumn short>
          <Divider style={{ margin: 0 }} />
        </GridRowColumn>
        <Grid.Row
          className="short"
          style={{ padding: '8px 32px 32px' }}
          verticalAlign="middle"
        >
          <Grid.Column width={10}>
            {onBackClick && (
              <Button variant="link" onClick={onBackClick}>
                Back
              </Button>
            )}
          </Grid.Column>
          {showSkipButton && (
            <Grid.Column width={isSkipForNow ? 3 : 2}>
              {onSkipClick && (
                <Button variant="secondaryWhite" onClick={onSkipClick}>
                  {skipText}
                </Button>
              )}
            </Grid.Column>
          )}
          <Grid.Column width={2}>
            {onContinueClick &&
              (continueDisabled ? (
                <Tooltip
                  popup={{
                    body: continueDisabledMessage || 'Continue disabled',
                  }}
                  labelComponent={
                    <Button disabled size="large">
                      {completeButtonContent || 'Continue'}
                    </Button>
                  }
                />
              ) : (
                <Button onClick={onContinueClick} size="large">
                  {completeButtonContent || 'Continue'}
                </Button>
              ))}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}
