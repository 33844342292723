import { FinancialAccount } from '../../../reducers/finances/financialAccountsReducer'
import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import { Intro } from './intro'
import { AllDone } from './all-done'
import { DisconnectedAccounts } from './disconnected-accounts'

export interface IUserBookkeepingStatus {
  disconnectedBankAccounts: FinancialAccount[]
  missingBankStatements: {
    account_id: string
    months: string[]
  }[]
  unclarifiedTransactions: {
    bookkeeperClarification: Transaction[]
    uncatagorized: Transaction[]
  }
  otherExpenses: Transaction[]
  otherIncome: Transaction[]
  isSCorp: boolean
  lastEoyBookkeepingScreen: string
}

export interface IStep {
  name: string
  route: string
  component: unknown
  props: ((userBookkeepingStatus: IUserBookkeepingStatus) => unknown) | null
  isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => boolean
  isAlwaysComplete: boolean
}

export const STEPS = [
  {
    name: 'Landing',
    route: 'intro',
    component: Intro,
    props: null,
    isRequired: () => true,
    isAlwaysComplete: true,
  },
  {
    name: 'Disconnected Accounts',
    route: 'disconnected-accounts',
    component: DisconnectedAccounts,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return {
        financialAccounts: userBookkeepingStatus.disconnectedBankAccounts,
      }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      const { disconnectedBankAccounts } = userBookkeepingStatus
      return disconnectedBankAccounts.length > 0
    },
    isAlwaysComplete: false,
  },
  {
    name: 'Review Accounts',
    route: 'review-accounts',
    component: Intro,
    props: null,
    isRequired: () => true,
    isAlwaysComplete: false,
  },
  {
    name: 'Missing Bank Statements',
    route: 'missing-bank-statements',
    component: Intro,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return userBookkeepingStatus.missingBankStatements
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      const { missingBankStatements } = userBookkeepingStatus
      return missingBankStatements.length > 0
    },
    isAlwaysComplete: false,
  },
  {
    name: 'Unclarified Transactions',
    route: 'unclarified-transactions',
    component: Intro,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return userBookkeepingStatus.unclarifiedTransactions
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      const {
        unclarifiedTransactions: { bookkeeperClarification, uncatagorized },
      } = userBookkeepingStatus

      return bookkeeperClarification.length > 0 || uncatagorized.length > 0
    },
    isAlwaysComplete: false,
  },
  {
    name: 'Other Expenses',
    route: 'other-expenses',
    component: AllDone,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return userBookkeepingStatus.otherExpenses
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      const { otherExpenses } = userBookkeepingStatus
      return otherExpenses.length > 0
    },
    isAlwaysComplete: false,
  },
  {
    name: 'Other Income',
    route: 'other-income',
    component: Intro,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return userBookkeepingStatus.otherIncome
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      const { otherIncome } = userBookkeepingStatus
      return otherIncome.length > 0
    },
    isAlwaysComplete: false,
  },
  {
    name: 'Business Income in Personal Accounts',
    route: 'business-income-in-personal-accounts',
    component: Intro,
    props: null,
    isRequired: () => true,
    isAlwaysComplete: false,
  },
  {
    name: 'Business Expenses in Personal Accounts',
    route: 'business-expenses-in-personal-accounts',
    component: Intro,
    props: null,
    isRequired: () => true,
    isAlwaysComplete: false,
  },
  {
    name: "Owner's Investments",
    route: 'owners-investments',
    component: Intro,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return { isSCorp: userBookkeepingStatus.isSCorp }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return userBookkeepingStatus.isSCorp
    },
    isAlwaysComplete: false,
  },
  {
    name: "Owner's Distributions",
    route: 'owners-distributions',
    component: Intro,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return { isSCorp: userBookkeepingStatus.isSCorp }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return userBookkeepingStatus.isSCorp
    },
    isAlwaysComplete: false,
  },
  {
    name: 'Review Income',
    route: 'review-income',
    component: Intro,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return userBookkeepingStatus.otherIncome
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      const { otherIncome } = userBookkeepingStatus
      return otherIncome.length > 0
    },
    isAlwaysComplete: false,
  },
  {
    name: 'Review Expenses',
    route: 'review-expenses',
    component: Intro,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return userBookkeepingStatus.otherExpenses
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      const { otherExpenses } = userBookkeepingStatus
      return otherExpenses.length > 0
    },
    isAlwaysComplete: false,
  },
  {
    name: 'Final Review',
    route: 'final-review',
    component: Intro,
    props: null,
    isRequired: () => true,
    isAlwaysComplete: false,
  },
  {
    name: 'All Done',
    route: 'all-done',
    component: AllDone,
    props: null,
    isRequired: () => true,
    isAlwaysComplete: false,
  },
]
