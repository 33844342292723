import { ReactNode, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Grid, Divider } from 'semantic-ui-react'
import currency from 'currency.js'
import { times } from 'lodash'

import {
  GridRowColumn,
  Text,
  Modal,
  Button,
  Popup,
  Accordion,
} from '../../../../../components/BaseComponents'
import { Colors } from '../../../../../styles/theme'
import { UserTaxCalculation } from '../../../../Admin/UserTaxCalculations/userTaxCalculation.slice'
import {
  centsToCurrency,
  formatCurrency,
  formatCurrencyFromCents,
} from '../../../../../utils/currencyHelpers'
import { useReselector } from '../../../../../utils/sharedHooks'
import {
  selectTaxCalculationOutput,
  selectUserTaxCalculationById,
} from '../../../../../selectors/userTaxCalculation.selectors'
import {
  getUserIsAdmin,
  selectIsCurrentUserScorp,
  selectIsFilingJointly,
} from '../../../../../selectors/user.selectors'
import {
  AnnualGrossIncomeTooltip,
  AnnualizedPPNetProfitTooltip,
  AnnualizedW2IncomeTooltip,
  AnnualTaxLiabilityNetTooltip,
  AnnualTaxLiabilityTooltip,
  TaxableIncomeTooltip,
  TotalPreviousQTEPaymentsTooltip,
  YTD1099IncomeTooltip,
  YTDTaxLiabilityGrossTooltip,
  YTDTaxLiabilityNetTooltip,
} from './CalculationOutputTooltips'

const greenUnderline = {
  borderBottom: 'dotted',
  borderColor: Colors.green,
}

const CalculationRow = ({
  title,
  value,
  popupContent,
}: {
  title: string
  value: string
  popupContent?: ReactNode
}) => (
  <div style={{ display: 'flex', marginBottom: 10 }}>
    {popupContent ? (
      <Popup
        content={popupContent}
        trigger={<Text style={greenUnderline}>{title}</Text>}
      />
    ) : (
      <Text>{title}</Text>
    )}

    <div
      style={{
        borderBottomStyle: 'dotted',
        borderBottomColor: Colors.black,
        borderBottomWidth: 1,
        flex: 1,
        margin: '0 8px 8px',
      }}
    />
    <Text>{value}</Text>
  </div>
)

export const CalcBreakdownSection = ({
  calcId,
  isState,
}: {
  calcId: number
  isState?: boolean
}) => {
  const isAdmin = useReselector(getUserIsAdmin)
  // Right now this just affects tooltip content so don't worry about what admins see
  const isScorp = useReselector(selectIsCurrentUserScorp)
  // Just show spouse if this is admin viewing this user
  const showSpouse = useReselector(selectIsFilingJointly) || isAdmin

  const calculation = useReselector(selectUserTaxCalculationById, calcId)
  const calculationOutput = useReselector(selectTaxCalculationOutput, calcId)

  const {
    annualizedTotalWIncomeInCents,
    otherIndividualIncomeInCents,
    spouseIndividualIncomeInCents,
    federalWithholdingInCents,
    spouseFederalWithholdingInCents,
    stateWithholdingInCents,
    spouseStateWithholdingInCents,
    annualizedIncomeInCents,
    annualizedExpensesInCents,
    calendarQuarter,
    federalQuarterlyEstimateInCents,
    stateQuarterlyEstimates,
  } = calculation || {}

  const {
    annualGrossIncomeInCents,
    annualTaxableIncomeInCents,
    annualFederalIncomeTaxLiabilityInCents,
    annualStateTaxLiabilityInCents,
    annualSelfEmploymentTaxLiabilityInCents,
    allFederalQuarterPaymentsLogged,
    allStateQuarterPaymentsLogged,
    YTDFederalTaxPaymentsMadeInCents,
    YTDStateTaxPaymentsMadeInCents,
    YTDGrossFederalTaxLiabilityInCents,
    YTDGrossStateTaxLiabilityInCents,
    annualTotalFederalWithholdingInCents,
    annualTotalStateWithholdingInCents,
    annualFederalEstimateInCents,
    annualStateEstimateInCents,
    quarterFederalEstimateInCents,
    quarterStateEstimateInCents,
  } = calculationOutput?.data || {}

  const stateName = stateQuarterlyEstimates?.[0].state

  const prevQuarterText = useMemo(
    () =>
      calendarQuarter
        ? ` (${times(Number(calendarQuarter) - 1)
            .map((num) => `Q${num + 1}`)
            .join(', ')})`
        : '',
    [calendarQuarter]
  )

  // todo this needs to be updated to support Q4
  const ytdTaxLiability = useMemo(() => {
    if (!isState) {
      if (allFederalQuarterPaymentsLogged) {
        return currency(
          centsToCurrency(YTDGrossFederalTaxLiabilityInCents)
        ).subtract(centsToCurrency(annualTotalFederalWithholdingInCents))
      }

      return centsToCurrency(annualFederalEstimateInCents)
    }

    if (allStateQuarterPaymentsLogged) {
      return currency(
        centsToCurrency(YTDGrossStateTaxLiabilityInCents)
      ).subtract(centsToCurrency(annualTotalStateWithholdingInCents))
    }

    return centsToCurrency(annualStateEstimateInCents)
  }, [
    YTDGrossFederalTaxLiabilityInCents,
    YTDGrossStateTaxLiabilityInCents,
    allFederalQuarterPaymentsLogged,
    allStateQuarterPaymentsLogged,
    annualFederalEstimateInCents,
    annualStateEstimateInCents,
    annualTotalFederalWithholdingInCents,
    annualTotalStateWithholdingInCents,
    isState,
  ])

  const estType = isState ? `${stateName} State` : 'Federal'

  const allPaymentsProvided = isState
    ? allStateQuarterPaymentsLogged
    : allFederalQuarterPaymentsLogged

  return (
    <Grid>
      <GridRowColumn short>
        <Text as="eyebrow" color="darkGray">
          Taxable Income
        </Text>
      </GridRowColumn>
      <Grid.Row className="short">
        <Grid.Column width={12}>
          <Accordion
            title={
              <Popup
                content={<AnnualGrossIncomeTooltip />}
                trigger={
                  <Text color="black" style={greenUnderline}>
                    Annual Gross Income
                  </Text>
                }
              />
            }
            content={
              <>
                <Text style={{ marginBottom: 10 }}>
                  Every quarter we reforecast your quarterly tax estimate based
                  on your Profit & Loss Statement (PnL) and your income. Annual
                  Gross Income is the calculated sum of:
                </Text>
                <CalculationRow
                  title="Annualized Private Practice Net Profit"
                  value={formatCurrencyFromCents(
                    centsToCurrency(annualizedIncomeInCents).subtract(
                      centsToCurrency(annualizedExpensesInCents)
                    )
                  )}
                  popupContent={<AnnualizedPPNetProfitTooltip />}
                />
                <CalculationRow
                  title="Annualized Total W-2 Income"
                  value={formatCurrencyFromCents(annualizedTotalWIncomeInCents)}
                  popupContent={<AnnualizedW2IncomeTooltip isScorp={isScorp} />}
                />
                <CalculationRow
                  title="YTD 1099 Income"
                  value={formatCurrencyFromCents(otherIndividualIncomeInCents)}
                  popupContent={<YTD1099IncomeTooltip />}
                />
                {showSpouse && (
                  <CalculationRow
                    title="YTD Spouse 1099 Income"
                    value={formatCurrencyFromCents(
                      spouseIndividualIncomeInCents
                    )}
                  />
                )}
              </>
            }
            variant="text"
            iconColor="black"
            style={{ marginTop: 0 }}
          />
        </Grid.Column>
        <Grid.Column width={4} textAlign="right">
          <Text>{formatCurrencyFromCents(annualGrossIncomeInCents)}</Text>
        </Grid.Column>
      </Grid.Row>
      {!isState && (
        <Grid.Row>
          <Grid.Column width={12} style={{ display: 'flex' }}>
            <Popup
              content={<TaxableIncomeTooltip />}
              trigger={<Text style={greenUnderline}>Taxable Income</Text>}
            />
            <div style={{ flex: 1 }} />
          </Grid.Column>
          <Grid.Column width={4} textAlign="right">
            <Text>{formatCurrencyFromCents(annualTaxableIncomeInCents)}</Text>
          </Grid.Column>
        </Grid.Row>
      )}
      <GridRowColumn short>
        <Divider />
      </GridRowColumn>
      <Grid.Row style={{ backgroundColor: Colors.stone }}>
        <Grid.Column width={12} style={{ display: 'flex' }}>
          <Popup
            content={<AnnualTaxLiabilityTooltip isState={Boolean(isState)} />}
            trigger={
              <Text style={greenUnderline}>Annual Tax Liability (Gross)</Text>
            }
          />
          <div style={{ flex: 1 }} />
        </Grid.Column>
        <Grid.Column width={4} textAlign="right">
          <Text>
            {isState
              ? formatCurrencyFromCents(annualStateTaxLiabilityInCents)
              : formatCurrencyFromCents(
                  currency(
                    centsToCurrency(annualFederalIncomeTaxLiabilityInCents)
                  ).add(
                    centsToCurrency(annualSelfEmploymentTaxLiabilityInCents)
                  )
                )}
          </Text>
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn>
        <Divider />
      </GridRowColumn>
      <GridRowColumn short>
        <Text as="eyebrow" color="darkGray">
          {estType} Quarterly Tax Estimate Calculation
        </Text>
      </GridRowColumn>
      {allPaymentsProvided ? (
        <>
          <Grid.Row className="short">
            <Grid.Column width={12} style={{ display: 'flex' }}>
              <Popup
                content={<YTDTaxLiabilityGrossTooltip />}
                trigger={
                  <Text style={greenUnderline}>YTD Tax Liability (Gross)</Text>
                }
              />
              <div style={{ flex: 1 }} />
            </Grid.Column>
            <Grid.Column width={4} textAlign="right">
              <Text>
                {formatCurrencyFromCents(
                  isState
                    ? YTDGrossStateTaxLiabilityInCents
                    : YTDGrossFederalTaxLiabilityInCents
                )}
              </Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="short">
            <Grid.Column width={12}>
              <Accordion
                title={
                  <Popup
                    content={<YTDTaxLiabilityNetTooltip />}
                    trigger={
                      <Text color="black" style={greenUnderline}>
                        YTD Tax Liability (Net)
                      </Text>
                    }
                  />
                }
                content={
                  <>
                    <Text style={{ marginBottom: 10 }}>
                      We subtract your withholdings (and your spouse&apos;s, if
                      applicable) from your Gross YTD Tax Liability to get your
                      Net YTD Tax Liability.
                    </Text>

                    {isState ? (
                      <>
                        <CalculationRow
                          title="Your YTD State Withholdings"
                          value={formatCurrencyFromCents(
                            stateWithholdingInCents
                          )}
                        />
                        {showSpouse && (
                          <CalculationRow
                            title="Spouse YTD State Withholdings"
                            value={formatCurrencyFromCents(
                              spouseStateWithholdingInCents
                            )}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <CalculationRow
                          title="Your YTD Federal Withholdings"
                          value={formatCurrencyFromCents(
                            federalWithholdingInCents
                          )}
                        />
                        {showSpouse && (
                          <CalculationRow
                            title="Spouse YTD Federal Withholdings"
                            value={formatCurrencyFromCents(
                              spouseFederalWithholdingInCents
                            )}
                          />
                        )}
                      </>
                    )}
                  </>
                }
                variant="text"
                iconColor="black"
                style={{ marginTop: 0 }}
              />
            </Grid.Column>

            <Grid.Column width={4} textAlign="right">
              <Text>{formatCurrency(ytdTaxLiability)}</Text>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="short">
            <Grid.Column width={12} style={{ display: 'flex' }}>
              <Popup
                content={<TotalPreviousQTEPaymentsTooltip />}
                trigger={
                  <Text style={greenUnderline}>
                    Total previous quarterly tax estimate payments{' '}
                    {prevQuarterText}
                  </Text>
                }
              />
              <div style={{ flex: 1 }} />
            </Grid.Column>
            <Grid.Column width={4} textAlign="right">
              <Text>
                {formatCurrencyFromCents(
                  isState
                    ? YTDStateTaxPaymentsMadeInCents
                    : YTDFederalTaxPaymentsMadeInCents
                )}
              </Text>
            </Grid.Column>
          </Grid.Row>
        </>
      ) : (
        <Grid.Row className="short">
          <Grid.Column width={12} style={{ display: 'flex' }}>
            <Popup
              content={<AnnualTaxLiabilityNetTooltip />}
              trigger={
                <Text color="black" style={greenUnderline}>
                  Annual Tax Liability (Net)
                </Text>
              }
            />
            <div style={{ flex: 1 }} />
          </Grid.Column>

          <Grid.Column width={4} textAlign="right">
            <Text>{formatCurrency(ytdTaxLiability)}</Text>
          </Grid.Column>
        </Grid.Row>
      )}

      {isAdmin && (
        <Grid.Row>
          <Grid.Column width={12}>
            <Text>
              QTE Calc Est Output (this line not shared with customer)
            </Text>
            <Text as="bodySm" color="darkGray">
              <i>
                {allPaymentsProvided
                  ? 'Method: Smoothed (previous quarterly tax payments provided)'
                  : 'Method: Divide by 4 (one or more quarterly tax payments not provided)'}
              </i>
            </Text>
          </Grid.Column>
          <Grid.Column width={4} textAlign="right">
            <Text>
              {formatCurrencyFromCents(
                isState
                  ? quarterStateEstimateInCents
                  : quarterFederalEstimateInCents
              )}
            </Text>
          </Grid.Column>
        </Grid.Row>
      )}

      <GridRowColumn>
        <Divider />
      </GridRowColumn>
      <Grid.Row
        style={{
          backgroundColor: Colors.blush,
          paddingTop: 22,
          paddingBottom: 22,
        }}
      >
        <Grid.Column width={12}>
          <Text as="h3">{estType} Quarterly Tax Estimate</Text>
        </Grid.Column>
        <Grid.Column width={4} textAlign="right">
          <Text as="h3">
            {isState
              ? formatCurrency(stateQuarterlyEstimates?.[0].value)
              : formatCurrencyFromCents(federalQuarterlyEstimateInCents)}
          </Text>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export const CalcBreakdown = ({ calcId }: { calcId: number }) => {
  const calculation = useReselector(selectUserTaxCalculationById, calcId)
  const [searchParams] = useSearchParams()

  const section = searchParams.get('section')

  if (!calculation) {
    return null
  }

  return (
    <Grid>
      <GridRowColumn>
        <Accordion
          title={<Text as="h2">Federal estimate calculation</Text>}
          content={<CalcBreakdownSection calcId={calcId} />}
          initialOpen={section === 'federal'}
        />
      </GridRowColumn>
      <GridRowColumn>
        <Accordion
          title={
            <Text as="h2">
              {calculation.stateQuarterlyEstimates?.[0].state} State estimate
              calculation
            </Text>
          }
          content={<CalcBreakdownSection calcId={calcId} isState />}
          initialOpen={section === 'state'}
        />
      </GridRowColumn>
    </Grid>
  )
}

export const CalculationOutputsModal = ({
  calc,
}: {
  calc: UserTaxCalculation
}) => {
  const calculationOutput = useReselector(selectTaxCalculationOutput, calc.id)

  const [open, setOpen] = useState(false)

  if (!calculationOutput) {
    return null
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      closeOnEscape
      closeOnDimmerClick
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button size="medium" style={{ margin: '8px 0px' }}>
          View QTE Details
        </Button>
      }
      size="small"
      closeIcon
    >
      <Modal.Header>Tax Calculation Output</Modal.Header>
      <Modal.Content>
        <CalcBreakdown calcId={calc.id} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)} variant="secondary">
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
