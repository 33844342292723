import { List } from 'semantic-ui-react'
import { Text, Link, Accordion } from '../BaseComponents'
import { UserDocumentCategory } from '../../features/Admin/UserDocumentCategories/userDocumentCategories.slice'
import { parseCategoryYear } from './FileUploadModalDescription'
import {
  BookkeepingInternalNames,
  DescriptionType,
} from '../../features/UserDocuments/constants'

export const getBookkeepingTitle = (
  category: UserDocumentCategory,
  descriptionType?: DescriptionType | null
) => {
  const { internalName, title } = category
  const year = parseCategoryYear(internalName)

  if (
    internalName.includes(BookkeepingInternalNames.CONTRACTOR_PAYMENTS_REPORT)
  ) {
    return 'Contractor payments report'
  }
  if (internalName.includes(BookkeepingInternalNames.LARGE_ASSET_RECEIPTS)) {
    return 'Receipts for assets costing more than $2,500'
  }
  if (
    internalName.includes(BookkeepingInternalNames.PAYMENT_PROCESSOR_REPORTS) &&
    descriptionType === DescriptionType.MERCHANT
  ) {
    return 'Merchant fees report'
  }
  if (
    internalName.includes(BookkeepingInternalNames.PAYMENT_PROCESSOR_REPORTS) &&
    descriptionType === DescriptionType.INCOME &&
    year
  ) {
    return `December ${year} income report`
  }
  return title
}

export const BookkeepingDescription = ({
  category,
  descriptionType,
  year,
}: {
  category: UserDocumentCategory | null
  descriptionType?: string | null
  year?: string | null
}) => {
  if (
    category?.internalName.includes(
      BookkeepingInternalNames.LARGE_ASSET_RECEIPTS
    ) &&
    year
  ) {
    return (
      <>
        <Text>
          As an S corp, you need to provide receipts for{' '}
          <b>assets costing over $2,500</b> that were purchased in {year}.{' '}
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/19809547067543#fixed_assets"
            newPage
            as="secondaryLink"
          >
            How do I obtain these?
          </Link>
        </Text>
        <br />
        <Accordion
          title="How do I know if an asset requires a receipt"
          content="If you're not sure, upload it anyway. It's better to provide more information than less. The bookkeeping team will sort and tag the receipts for you."
          variant="text"
        />
        <br />
        <Accordion
          title="What if I already uploaded receipts in Transactions?"
          content="You don't need to upload receipts a second time. This is only required for assets that aren't recorded in Transactions. (For example: assets you may have purchased before you joined Heard)"
          variant="text"
        />
      </>
    )
  }

  if (
    category?.internalName.includes(
      BookkeepingInternalNames.CONTRACTOR_PAYMENTS_REPORT
    ) &&
    year
  ) {
    return (
      <>
        <Text>
          This form shows the total contractor payments made through a payroll
          provider in {year}.
        </Text>
        <br />
        <Accordion
          title="Where do I find my contractor payments report?"
          content={
            <Text>
              It can be found in your payroll provider account.{' '}
              <Link
                href="https://support.joinheard.com/hc/en-us/articles/19809547067543#contractor_pay"
                newPage
                as="secondaryLink"
              >
                View instructions
              </Link>
            </Text>
          }
          variant="text"
        />
      </>
    )
  }

  if (
    category?.internalName.includes(
      BookkeepingInternalNames.PAYMENT_PROCESSOR_REPORTS
    ) &&
    descriptionType === 'merchant' &&
    year
  ) {
    return (
      <>
        <Text>
          This form shows total merchant fees collected by your payment
          processor in {year}.
        </Text>
        <br />
        <Text as="h3"> Instructions: </Text>
        <List as="ul">
          <List.Item as="li">
            <Text>
              Upload a merchant fees report for each payment processor you use.
            </Text>
          </List.Item>
          <List.Item as="li">
            <Text>
              Include the name of the payment processor in the file name.
            </Text>
          </List.Item>
          <List.Item as="li">
            <Text>
              To comply with HIPAA, please{' '}
              <b>delete any client information first</b>.
            </Text>
          </List.Item>
        </List>
        <br />
        <Accordion
          title="Where do I find my merchant fees report?"
          content={
            <Text>
              It can be found in your payment processor account.{' '}
              <Link
                href="https://support.joinheard.com/hc/en-us/articles/19809547067543#merchant_fees"
                newPage
                as="secondaryLink"
              >
                View instructions
              </Link>
            </Text>
          }
          variant="text"
        />
      </>
    )
  }
  if (
    category?.internalName.includes(
      BookkeepingInternalNames.PAYMENT_PROCESSOR_REPORTS
    ) &&
    descriptionType === 'income' &&
    year
  ) {
    return (
      <>
        <Text>
          Reports any therapy income that was earned in{' '}
          <b>
            December {year}, but deposited in {parseInt(year) + 1}.
          </b>
        </Text>
        <br />
        <Text as="h3"> Instructions: </Text>
        <List as="ul">
          <List.Item as="li">
            <Text>
              Upload a report for <b>each payment processor or EHR</b> with bank
              deposits matching this description.
            </Text>
          </List.Item>
          <List.Item as="li">
            <Text>
              Include the name of the payment processor in the file name.
            </Text>
          </List.Item>
          <List.Item as="li">
            <Text>
              Reports <b>should not</b> be from your bank.
            </Text>
          </List.Item>
        </List>
        <br />
        <Accordion
          title="Where do I find my income report?"
          content={
            <Text>
              It can be found in your payment processor account.{' '}
              <Link
                href="https://support.joinheard.com/hc/en-us/articles/19809547067543#dec_income"
                newPage
                as="secondaryLink"
              >
                View instructions
              </Link>
            </Text>
          }
          variant="text"
        />
      </>
    )
  }
  return (
    <Text>
      {category?.description ||
        'Upload bookkeeping or year-end reconciliation documents here.'}
    </Text>
  )
}
