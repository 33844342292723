import moment from 'moment-timezone'
import { fetchExpensesSum, fetchIncomesSum } from '../actions/reportActions'
import { DATE_FORMATS } from '../utils/dateHelpers'

export type ExpensesSummary = {
  summaries: {
    month: string
    income: number
    expenses: number
    noData: boolean
  }[]
  average: {
    income: number
    expenses: number
    noData: boolean
  }
}

/*
  Generates past 3 months summary and average for Allocation Rule setting
*/
export const generateSummary = async (): Promise<ExpensesSummary> => {
  const pastMonth = moment().subtract(1, 'months')
  const secondLastMonth = moment().subtract(2, 'months')
  const thirdLastMonth = moment().subtract(3, 'months')
  const summaries = []

  /*
    These endpoints return the summation amountInCents, so we will need to convert to dollar
  */
  const thirdLastMonthExpenses = await fetchExpensesSum({
    month: thirdLastMonth,
  })()
  const thirdLastMonthExpensesSum = thirdLastMonthExpenses
    ? Math.abs(thirdLastMonthExpenses.sum)
    : 0

  const thirdLastMonthIncome = await fetchIncomesSum({
    month: thirdLastMonth,
  })()
  const thirdLastMonthIncomeSum = thirdLastMonthIncome
    ? Math.abs(thirdLastMonthIncome.sum)
    : 0
  summaries.push({
    month: thirdLastMonth.format(DATE_FORMATS.MONTH_YEAR),
    income: thirdLastMonthIncomeSum,
    expenses: thirdLastMonthExpensesSum,
    noData: thirdLastMonthIncomeSum === 0 && thirdLastMonthExpensesSum === 0,
  })

  const secondLastMonthExpenses = await fetchExpensesSum({
    month: secondLastMonth,
  })()
  const secondLastMonthExpensesSum = secondLastMonthExpenses
    ? Math.abs(secondLastMonthExpenses.sum)
    : 0
  const secondLastMonthIncome = await fetchIncomesSum({
    month: secondLastMonth,
  })()
  const secondLastMonthIncomeSum = secondLastMonthIncome
    ? Math.abs(secondLastMonthIncome.sum)
    : 0

  summaries.push({
    month: secondLastMonth.format(DATE_FORMATS.MONTH_YEAR),
    income: secondLastMonthIncomeSum || 0,
    expenses: secondLastMonthExpensesSum || 0,
    noData: secondLastMonthIncomeSum === 0 && secondLastMonthExpensesSum === 0,
  })

  const pastMonthExpenses = await fetchExpensesSum({ month: pastMonth })()
  const pastMonthExpensesSum = pastMonthExpenses
    ? Math.abs(pastMonthExpenses.sum)
    : 0
  const pastMonthIncome = await fetchIncomesSum({ month: pastMonth })()
  const pastMonthIncomeSum = pastMonthIncome ? Math.abs(pastMonthIncome.sum) : 0

  summaries.push({
    month: pastMonth.format(DATE_FORMATS.MONTH_YEAR),
    income: pastMonthIncomeSum || 0,
    expenses: pastMonthExpensesSum || 0,
    noData: pastMonthIncomeSum === 0 && pastMonthExpensesSum === 0,
  })

  const incomeAverage =
    (pastMonthIncomeSum + secondLastMonthIncomeSum + thirdLastMonthIncomeSum) /
    3
  const expensesAverage =
    (pastMonthExpensesSum +
      secondLastMonthExpensesSum +
      thirdLastMonthExpensesSum) /
    3

  return {
    summaries,
    average: {
      income: Math.abs(incomeAverage) || 0,
      expenses: Math.abs(expensesAverage) || 0,
      noData: incomeAverage === 0 && expensesAverage === 0,
    },
  }
}
