export type StandardDeduction = {
  single: number
  qualifyingWidow: number
  marriedFilingJointly: number
  marriedFilingSeparately: number
  headOfHousehold: number
}

export type TaxRateBracketInner = {
  minInCents: number
  maxInCents: number | null | undefined
  rate: number
}

export type TaxRateBrackets = {
  single: TaxRateBracketInner[]
  qualifyingWidow: TaxRateBracketInner[]
  marriedFilingJointly: TaxRateBracketInner[]
  marriedFilingSeparately: TaxRateBracketInner[]
  headOfHousehold: TaxRateBracketInner[]
}

export const hasCorrectFilingStatuses = (
  input: unknown
): input is hasFilingStatuses => {
  //Verify the format is a valid object
  if (!input || typeof input !== 'object') return false

  //JSON must include single, qualifyingWidow, marriedFilingJointly, marriedFilingSeparately, and headOfHousehold
  return (
    'single' in input &&
    'qualifyingWidow' in input &&
    'marriedFilingJointly' in input &&
    'marriedFilingSeparately' in input &&
    'headOfHousehold' in input &&
    Object.keys(input).length === 5
  )
}

export const isCorrectStandardDeductionFormat = (
  standardDeduction: unknown
): standardDeduction is StandardDeduction => {
  //Must be an object and contain the correct filing statuses
  //and all of the values must be positive numbers
  return (
    hasCorrectFilingStatuses(standardDeduction) &&
    typeof standardDeduction['single'] === 'number' &&
    Number(standardDeduction['single']) >= 0 &&
    typeof standardDeduction['qualifyingWidow'] === 'number' &&
    Number(standardDeduction['qualifyingWidow']) >= 0 &&
    typeof standardDeduction['marriedFilingJointly'] === 'number' &&
    Number(standardDeduction['marriedFilingJointly']) >= 0 &&
    typeof standardDeduction['marriedFilingSeparately'] === 'number' &&
    Number(standardDeduction['marriedFilingSeparately']) >= 0 &&
    typeof standardDeduction['headOfHousehold'] === 'number' &&
    Number(standardDeduction['headOfHousehold']) >= 0
  )
}

type hasFilingStatuses = {
  single: number | object[]
  qualifyingWidow: number | object[]
  marriedFilingJointly: number | object[]
  marriedFilingSeparately: number | object[]
  headOfHousehold: number | object[]
}
