import { FC } from 'react'
import styled from 'styled-components'
import { Modal as SemanticModal, StrictModalProps } from 'semantic-ui-react'

import { Colors, Fonts } from '../../styles/theme'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'

const ModalActions = styled(SemanticModal.Actions)({
  '&&&': {
    alignItems: 'center',
    backgroundColor: Colors.white,
    display: 'flex',
    justifyContent: 'right',
    padding: 24,
    width: '100%',
  },
})

const ModalHeader = styled(SemanticModal.Header)({
  '&&&&&': {
    borderBottom: 'none',
    padding: '32px 84px 16px 32px',
    ...Fonts.h2,
  },
})

const ModalDescription = styled(SemanticModal.Description)({
  '&&&': {
    padding: '0 32px',
    ...Fonts.bodyMd,
  },
})

const ModalContent = styled(SemanticModal.Content)({
  '&&&': {
    padding: '16px 32px 32px',
    ...Fonts.bodyMd,
  },
})

type Props = Omit<StrictModalProps, 'basic'> & { id?: string }

interface ModalComponent extends FC<Props> {
  Actions: typeof ModalActions
  Content: typeof ModalContent
  Description: typeof ModalDescription
  Dimmer: typeof SemanticModal.Dimmer
  Header: typeof ModalHeader
}

const Modal: ModalComponent = styled(
  ({ className, trigger, ...rest }: Props) => {
    const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
    return (
      <SemanticModal
        {...rest}
        className={`${className} ${isMobile && 'mobile'}`}
        trigger={trigger ? <span>{trigger}</span> : undefined}
      />
    )
  }
)({
  '&&&&': {
    borderRadius: 8,

    '&.mobile': {
      top: 0,
      bottom: 0,
      margin: 0,
      width: '100%',
      borderRadius: 0,
      paddingTop: 8,
      'i.close.icon': {
        top: '12px!important',
      },
      'div.scrollable': {
        maxHeight: 'calc(100vh - 200px)',
      },
    },

    'i.close.icon': {
      color: Colors.black,
      right: 16,
      top: '24px!important',
    },
  },
  'div.scrollable': {
    overflowY: 'auto',
    padding: 32,
    maxHeight: 400,
  },
})

Modal.Actions = ModalActions
Modal.Content = ModalContent
Modal.Description = ModalDescription
Modal.Dimmer = SemanticModal.Dimmer
Modal.Header = ModalHeader

export default Modal
