import { createSelector } from 'reselect'

import { ReduxState } from '../../../utils/typeHelpers'

const getTaxUserDocuments = (state: ReduxState) => state.taxUserDocuments

export const selectTaxUserDocuments = createSelector(
  getTaxUserDocuments,
  (documents) => Object.values(documents)
)

export const selectTaxUserDocumentForUserDocumentId = createSelector(
  selectTaxUserDocuments,
  (_: ReduxState, userDocumentId: number) => userDocumentId,
  (documents, userDocumentId) =>
    documents.find((doc) => doc.userDocumentId === userDocumentId)
)
