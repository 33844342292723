import { CSSProperties } from 'react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Button, Icon } from '../../../../components/BaseComponents'

export const AddButton = ({
  onClick,
  text,
  style,
}: {
  onClick: () => void
  text: string
  style?: CSSProperties
}) => (
  <Button onClick={onClick} variant="link" style={style}>
    <Icon icon={light('circle-plus')} style={{ marginRight: 8 }} />
    {text}
  </Button>
)

export const EditButton = ({
  onClick,
  text,
  style,
}: {
  onClick: () => void
  text: string
  style?: CSSProperties
}) => (
  <Button onClick={onClick} variant="link" style={style}>
    <Icon icon={light('pencil')} style={{ marginRight: 8 }} />
    {text}
  </Button>
)
