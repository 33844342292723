import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { keyBy } from 'lodash'

import { fetchIfNeededWrapper } from '../../reducers/fetch'

export interface ChartOfAccount {
  id: number
  name: string
  calendarYear: string
  archivedAt?: string
  createdAt: string
  updatedAt: string
  chartOfAccountCategories: ChartOfAccountCategory[]
}

export interface ChartOfAccountCategory {
  chartOfAccountId: number
  transactionCategoryId: number
  createdAt: string
  updatedAt: string
}

export interface NormalizedChartOfAccounts {
  chartOfAccounts: { [key: string]: ChartOfAccount }
  result: number[]
}

export interface ChartOfAccountsState {
  byId: { [key: string]: ChartOfAccount }
  allIds: string[]
}

const initialState: ChartOfAccountsState = {
  byId: {},
  allIds: [],
}

const chartOfAccountsSlice = createSlice({
  name: 'chartOfAccounts',
  initialState,
  reducers: {
    receiveChartOfAccounts: (
      state,
      action: PayloadAction<{ [key: string]: ChartOfAccount }>
    ) => {
      state.byId = {
        ...state.byId,
        ...action.payload,
      }

      state.allIds = [
        ...state.allIds,
        ...Object.values(action.payload).map((l) => l.id.toString()),
      ]
    },
  },
})

export default chartOfAccountsSlice.reducer

export const { receiveChartOfAccounts } = chartOfAccountsSlice.actions

export const FETCH_CHART_OF_ACCOUNTS_KEY = 'FETCH_CHART_OF_ACCOUNTS_KEY'
export const fetchChartOfAccountsIfNeeded = () =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_CHART_OF_ACCOUNTS_KEY,
    defaultErrorMessage: 'Error fetching chart of accounts',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<ChartOfAccount[]>(
        '/finances/api/v1/chart_of_accounts'
      )

      dispatch(receiveChartOfAccounts(keyBy(json.data, 'id')))

      return json.data
    },
  })
