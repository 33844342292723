import { parse } from 'json2csv'
import { orderBy } from 'lodash'
import moment from 'moment'
import { DateTime } from 'luxon'
import { FinancialAccountWithAdminInfo } from '../../reducers/admin/financialAccountsReducer'
import { centsToDollars } from '../../utils/currencyHelpers'
import { TransactionCategory } from '../Reports/reports.slice'
import {
  GeneralLedgerProps,
  GeneralLedgerViewModel,
} from './components/GeneralLedger'
import {
  JournalEntryLineItem,
  JournalEntryType,
  JournalEntry,
  NewJournalEntryLineItem,
  InitialJournalEntryFormValues,
  JournalEntryTransaction,
} from './types'
import {
  DATE_FORMATS,
  isoToUTCDateTime,
  DATE_FORMATS_LUXON,
} from '../../utils/dateHelpers'
import { GL_SORTS } from '../Reports/reportConstants'
import { FinancialType } from '../../reducers/finances/financialAccountsReducer'

const archivedCategoryCallout = (archivedAt: string) =>
  `- archived on ${moment
    .utc(archivedAt, DATE_FORMATS.TIMESTAMP)
    .format(DATE_FORMATS.DISPLAY_SHORT)}`

export const getAccountName = ({
  financialAccountsById,
  financialAccountId,
  transactionCategoriesById,
  transactionCategoryId,
}: {
  financialAccountsById: {
    [key: string]: FinancialAccountWithAdminInfo
  }
  financialAccountId: string
  transactionCategoriesById: {
    [key: string]: TransactionCategory
  }
  transactionCategoryId: string
}) => {
  if (financialAccountId && financialAccountsById[financialAccountId]) {
    const financialAccount = financialAccountsById[financialAccountId]
    if (
      financialAccount.accountType === FinancialType.MANUAL ||
      financialAccount.accountType === FinancialType.USER_MANUAL_UPLOAD
    ) {
      return financialAccount.name
    }
    return `${financialAccount.plaidInstitutionName} - ${financialAccount.name} ${financialAccount.mask}`
  } else if (
    transactionCategoryId &&
    transactionCategoriesById[transactionCategoryId]
  ) {
    const name = transactionCategoriesById[transactionCategoryId].name
    if (transactionCategoriesById[transactionCategoryId].archivedAt) {
      return `${name} ${archivedCategoryCallout(
        transactionCategoriesById[transactionCategoryId].archivedAt
      )}`
    }
    return name
  } else {
    return 'Account name unavailable'
  }
}

export const getAccountDetails = ({
  transactionCategoriesById,
  transactionCategoryId,
}: {
  transactionCategoriesById: {
    [key: string]: TransactionCategory
  }
  transactionCategoryId: string
}) => {
  const category = transactionCategoryId
    ? transactionCategoriesById[transactionCategoryId]
    : null
  if (
    transactionCategoryId &&
    transactionCategoriesById[transactionCategoryId]
  ) {
    return {
      accountType: category?.accountType,
      financialStatement: category?.financialStatement,
    }
  }
  return {
    accountType: null,
    financialStatement: null,
  }
}

export const createGeneralLedgerViewModel = ({
  generalLedgerItems,
  financialAccountsById,
  transactionCategoriesById,
  accountFilter,
  statementFilter,
  sortBy,
}: GeneralLedgerProps) => {
  const result: GeneralLedgerViewModel = {
    accounts: [],
  }
  generalLedgerItems.forEach((item) => {
    const name = getAccountName({
      financialAccountsById,
      financialAccountId: `${item.financialAccountId ?? ''}`,
      transactionCategoriesById,
      transactionCategoryId: `${item.transactionCategoryId ?? ''}`,
    })
    const { accountType, financialStatement } = getAccountDetails({
      transactionCategoriesById,
      transactionCategoryId: `${item.transactionCategoryId ?? ''}`,
    })
    const accountId = item.financialAccountId
      ? item.financialAccountId
      : item.transactionCategoryId
    const cacheKey = item.financialAccountId
      ? `financial-${accountId}`
      : `category-${accountId}`

    // Item will have either financial account id or transaction category id but this check is needed
    if (!accountId) {
      return
    }

    result.accounts.push({
      name,
      key: cacheKey,
      id: accountId,
      type: item.financialAccountId
        ? 'financialAccount'
        : 'transactionCategory',
      lineItems: item.journalEntryLines,
      balanceBroughtDownDetails: {
        sumDebits: item?.balanceBroughtDown?.sumDebits || 0,
        sumCredits: item?.balanceBroughtDown?.sumCredits || 0,
      },
      totalDebitsInCents: item.sumDebits,
      totalCreditsInCents: item.sumCredits,
      netTotalInCents: item.sum,
      accountType,
      financialStatement,
    })
  })

  if (accountFilter) {
    result.accounts = result.accounts.filter(
      (account) => account.accountType === accountFilter
    )
  }
  if (statementFilter) {
    result.accounts = result.accounts.filter(
      (account) => account.financialStatement === statementFilter
    )
  }

  if (sortBy === GL_SORTS.amount_asc) {
    result.accounts = orderBy(
      result.accounts,
      (account) => account.netTotalInCents,
      'asc'
    )
  } else if (sortBy === GL_SORTS.amount_desc) {
    result.accounts = orderBy(
      result.accounts,
      (account) => account.netTotalInCents,
      'desc'
    )
  } else {
    result.accounts = orderBy(result.accounts, 'name', 'asc')
  }

  return result
}

export type JournalEntryCsvLineItem = {
  transaction: {
    id?: number | string
    date?: string
    description?: string
  }
  journalEntry: {
    id: number | string
    createdAt: string
  }
  accountName: string
  financialAccountId: number | string
  transactionCategoryId: number | string
  debit: number | string
  credit: number | string
}

/**
 * Flatten and prepare a collection of {@link JournalEntryTransaction} data into
 * {@link JournalEntryCsvLineItem}s, with each element representing one row in the CSV, which
 * contains data pertaining to one journal entry.
 *
 * @returns A list of {@link JournalEntryCsvLineItem}s that will be used to parse into a CSV file.
 */
const transformJournalEntryTransactionsToCSV = ({
  financialAccounts,
  journalEntryTransactions,
  transactionCategories,
}: {
  financialAccounts:
    | {
        [key: number]: FinancialAccountWithAdminInfo | undefined
      }
    | undefined
  journalEntryTransactions: JournalEntryTransaction[]
  transactionCategories: {
    [key: string]: TransactionCategory
  }
}): JournalEntryCsvLineItem[] =>
  journalEntryTransactions.flatMap((journalEntryTransaction) => {
    return journalEntryTransaction.journalEntries.flatMap((journalEntry) =>
      journalEntry.journalEntryLines.map(
        ({
          id: journalEntryId,
          transactionCategoryId,
          financialAccountId,
          entryType,
          createdAt,
          amountInCents,
        }) => {
          const amountInDollars = centsToDollars(amountInCents)
          const financialAccount = financialAccountId
            ? financialAccounts?.[financialAccountId]
            : ''
          const accountName = transactionCategoryId
            ? (transactionCategories[transactionCategoryId]?.name ?? null)
            : financialAccount
              ? `${financialAccount.name} ${financialAccount.mask}`
              : ''

          return {
            transaction: {
              id: journalEntryTransaction?.id,
              date: journalEntryTransaction?.date,
              description: journalEntryTransaction?.description,
            },
            journalEntry: {
              id: journalEntryId || '',
              createdAt,
            },
            accountName,
            financialAccountId: financialAccountId || '',
            transactionCategoryId: transactionCategoryId || '',
            debit:
              entryType === JournalEntryType.DEBIT && amountInDollars
                ? amountInDollars
                : '',
            credit:
              entryType === JournalEntryType.CREDIT && amountInDollars
                ? amountInDollars
                : '',
          }
        }
      )
    )
  })

/**
 * Prepare {@link JournalEntryTransaction}s for CSV parsing.
 */
export const prepareJournalEntryTransactions = ({
  financialAccounts,
  journalEntryTransactions,
  sortFunction,
  transactionCategories,
}: {
  financialAccounts:
    | {
        [key: number]: FinancialAccountWithAdminInfo | undefined
      }
    | undefined
  journalEntryTransactions: JournalEntryTransaction[]
  sortFunction: (
    a: JournalEntryCsvLineItem,
    b: JournalEntryCsvLineItem
  ) => number
  transactionCategories: {
    [key: string]: TransactionCategory
  }
}): JournalEntryCsvLineItem[] => {
  const csvEntries = transformJournalEntryTransactionsToCSV({
    financialAccounts,
    journalEntryTransactions,
    transactionCategories,
  })

  return csvEntries.sort(sortFunction)
}

/**
 * Parse and transform journal entry data, in its {@link JournalEntryTransaction} form, into CSV
 * readable data.
 *
 * @param params Params required for parsing journal entry data and transforming it to CSV line
 * items for CSV download, including a collection of financial accounts, the journal entry
 * transactions, a sort function, and a collection of transaction categories.
 * @returns A parsed string containing a CSV representation of the journal entry data.
 */
export const parseJournalEntryDataToCsv = (params: {
  financialAccounts:
    | {
        [key: number]: FinancialAccountWithAdminInfo | undefined
      }
    | undefined
  journalEntryTransactions: JournalEntryTransaction[]
  sortFunction: (
    a: JournalEntryCsvLineItem,
    b: JournalEntryCsvLineItem
  ) => number
  transactionCategories: {
    [key: string]: TransactionCategory
  }
}) =>
  parse(
    [
      { downloadedAt: moment().toISOString() },
      ...prepareJournalEntryTransactions(params),
    ],
    {
      fields: [
        {
          label: 'Transaction ID',
          value: 'transaction.id',
        },
        {
          label: 'Transaction Date',
          value: 'transaction.date',
        },
        {
          label: 'Transaction Description',
          value: 'transaction.description',
        },
        {
          label: 'Journal ID',
          value: 'journalEntry.id',
        },
        {
          label: 'Journal Entry Created At',
          value: 'journalEntry.createdAt',
        },
        {
          label: 'Account Name',
          value: 'accountName',
        },
        {
          label: 'Financial Account ID',
          value: 'financialAccountId',
        },
        {
          label: 'Transaction Category ID',
          value: 'transactionCategoryId',
        },
        {
          label: 'Debit',
          value: 'debit',
        },
        {
          label: 'Credit',
          value: 'credit',
        },
        {
          label: 'Downloaded At',
          value: 'downloadedAt',
        },
      ],
      quote: '',
    }
  )

const financialStatementMap: {
  [identifier: string]: string
} = {
  income_statement: 'Income Statement (PnL)',
  balance_sheet: 'Balance Sheet',
}

export const formatFinancialStatement = (
  financialStatement: string | null | undefined
) => {
  if (financialStatement) {
    return financialStatementMap[financialStatement]
  }
  return ''
}

export const formatInitialJournalEntryLines = (
  journalEntryLines: JournalEntryLineItem[]
): NewJournalEntryLineItem[] => {
  return journalEntryLines.map((journalEntryLine) => {
    const {
      entryType,
      amountInCents,
      financialAccountId,
      transactionCategoryId,
    } = journalEntryLine
    const isDebit = entryType === 'debit'
    return {
      ...journalEntryLine,
      accountOrCategoryId: financialAccountId
        ? `${financialAccountId} fin`
        : `${transactionCategoryId} cat`,
      debitAmount: isDebit ? centsToDollars(amountInCents).toString() : '0.00',
      creditAmount: isDebit ? '0.00' : centsToDollars(amountInCents).toString(),
    }
  })
}

export const getInitialDateFilters = (
  endDate?: string | null
): [string, string] => {
  if (endDate) {
    const parsedEndDate = DateTime.fromFormat(endDate, DATE_FORMATS_LUXON.INPUT)
    if (parsedEndDate.isValid) {
      const firstDayOfYear = parsedEndDate
        .startOf('year')
        .toFormat(DATE_FORMATS_LUXON.INPUT)

      return [firstDayOfYear, endDate]
    }
  }
  const now = DateTime.now()
  const firstDayOfMonth = now
    .startOf('month')
    .toFormat(DATE_FORMATS_LUXON.INPUT)
  const lastDayOfMonth = now.endOf('month').toFormat(DATE_FORMATS_LUXON.INPUT)
  return [firstDayOfMonth, lastDayOfMonth]
}

export const COMMON_JOURNAL_ENTRY_TYPES = [
  'Bond Purchase',
  'Depreciation Expense',
  'Home office expense (S-Corp)',
  'Income Adjustment',
  'Land Purchase',
  'Payroll (S-Corp)',
  'Revenue Timing Adjustment',
  'Shareholder Loan',
]

export const getJournalEntryInitialValues = (
  journalEntry?: Partial<JournalEntry>,
  transactionId?: string
): InitialJournalEntryFormValues => {
  if (!journalEntry) {
    return {
      details: '',
      journalEntryDate: '',
      type: null,
      transactionId: transactionId ?? '',
      journalEntryLines: [
        { accountOrCategoryId: '', debitAmount: '0.00', creditAmount: '0.00' },
        { accountOrCategoryId: '', debitAmount: '0.00', creditAmount: '0.00' },
      ],
    }
  }

  const { journalEntryDate, journalEntryLines, details, automated } =
    journalEntry

  const initialValues: {
    journalEntryDate: string
    details?: string
    journalEntryLines: NewJournalEntryLineItem[]
    transactionId: null | string
    type: null | string
  } = {
    journalEntryDate: journalEntryDate
      ? isoToUTCDateTime(journalEntryDate).toFormat(DATE_FORMATS_LUXON.INPUT)
      : '',
    journalEntryLines: formatInitialJournalEntryLines(journalEntryLines || []),
    transactionId: null,
    type: null,
    details,
  }
  const type = COMMON_JOURNAL_ENTRY_TYPES.find((commonType) =>
    details?.startsWith(commonType)
  )
  if (type) {
    initialValues.details = details?.slice(type.length).trim()
    initialValues.type = type
  }
  if (!automated) {
    initialValues.transactionId = journalEntry.transactionId?.toString() ?? null
  }
  return initialValues
}
