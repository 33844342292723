import { useParams } from 'react-router-dom'
import { Divider, Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import {
  FormikInput,
  FormikLabelError,
  FormikRadioToggleButton,
  GridRowColumn,
  Text,
  getFieldName,
  makeNumberSchema,
  makeReqBoolSchema,
} from '../../../../../../components/BaseComponents'
import { useReselector } from '../../../../../../utils/sharedHooks'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../../taxChecklist.selectors'
import { TaxListQuestionId } from '../../service'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { IncomeAndLiabilitiesStepProps } from '.'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import DocumentAddedAlert from '../../Shared/SpecialtyAlerts'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { UPDATE_USER_EOY_REVIEW_PROGRESS_KEY } from '../../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { UPSERT_USER_TAX_QUESTIONNAIRE_KEY } from '../../taxChecklistQuestion.actions'

export const investmentsQuestionIds = [
  TaxListQuestionId.investments_bought_sold,
  TaxListQuestionId.number_of_brokerage_statements,
  TaxListQuestionId.received_dividends,
]

const InvestmentsPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: IncomeAndLiabilitiesStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()

  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    UPDATE_USER_EOY_REVIEW_PROGRESS_KEY,
  ])

  const questionInvestmentsBoughtSold = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.investments_bought_sold,
    taxYear
  )
  const responseInvestmentsBoughtSold = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.investments_bought_sold,
    Number(formId)
  )

  const questionNumberOfBrokerageStatements = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.number_of_brokerage_statements,
    taxYear
  )
  const responseNumberOfBrokerageStatements = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_of_brokerage_statements,
    Number(formId)
  )

  const questionReceivedDividends = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.received_dividends,
    taxYear
  )
  const responseReceivedDividends = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.received_dividends,
    Number(formId)
  )

  const formik = useFormik({
    initialValues: {
      investmentsBoughtSold: responseInvestmentsBoughtSold?.[0]?.value,
      receivedDividends: responseReceivedDividends?.[0]?.value,
      numberOfBrokerageStatements:
        responseNumberOfBrokerageStatements?.[0]?.value,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      if (
        values.investmentsBoughtSold !==
        responseInvestmentsBoughtSold?.[0]?.value
      ) {
        responseData.push({
          id: responseInvestmentsBoughtSold?.[0]?.id,
          value: values.investmentsBoughtSold,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.investments_bought_sold,
        })
      }
      if (
        values.numberOfBrokerageStatements !==
        Number(responseNumberOfBrokerageStatements?.[0]?.value)
      ) {
        responseData.push({
          id: responseNumberOfBrokerageStatements?.[0]?.id,
          value: Number(values.numberOfBrokerageStatements ?? 0),
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.number_of_brokerage_statements,
        })
      }
      if (values.receivedDividends !== responseReceivedDividends?.[0]?.value) {
        responseData.push({
          id: responseReceivedDividends?.[0]?.id,
          value: values.receivedDividends,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.received_dividends,
        })
      }

      goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.investments],
      })
    },
  })

  const { values, isValid, submitForm, isSubmitting } = formik
  const questionsGridConfig = makeGridConfig([8, 14], true)

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn>
          <Text as="display2" textAlign="center">
            Investments
          </Text>
        </GridRowColumn>
        <GridRowColumn {...questionsGridConfig}>
          <FormikLabelError
            name={getFieldName<typeof values>('investmentsBoughtSold')}
            label={questionInvestmentsBoughtSold.question?.text}
            schema={makeReqBoolSchema()}
          />
        </GridRowColumn>
        <Grid.Row className="short" style={{ justifyContent: 'center' }}>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName<typeof values>('investmentsBoughtSold')}
              value
            >
              Yes
            </FormikRadioToggleButton>
          </Grid.Column>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName<typeof values>('investmentsBoughtSold')}
              value={false}
            >
              No
            </FormikRadioToggleButton>
          </Grid.Column>
        </Grid.Row>
        {values.investmentsBoughtSold && (
          <GridRowColumn short {...questionsGridConfig}>
            <DocumentAddedAlert>
              1099-B form added to checklist
            </DocumentAddedAlert>
          </GridRowColumn>
        )}
        <GridRowColumn short {...questionsGridConfig}>
          <Divider />
        </GridRowColumn>
        <GridRowColumn {...questionsGridConfig}>
          <FormikInput
            name={getFieldName<typeof values>('numberOfBrokerageStatements')}
            label={questionNumberOfBrokerageStatements.question?.text}
            componentType="number"
            schema={makeNumberSchema({ allowedDecimals: 0 })}
            prefix=""
            placeholder="Enter number"
            decimalScale={0}
            fullWidth
          />
        </GridRowColumn>
        <GridRowColumn short {...questionsGridConfig}>
          <Divider />
        </GridRowColumn>
        <GridRowColumn {...questionsGridConfig}>
          <FormikLabelError
            name={getFieldName<typeof values>('receivedDividends')}
            label={questionReceivedDividends.question?.text}
            schema={makeReqBoolSchema()}
          />
        </GridRowColumn>
        <Grid.Row className="short" style={{ justifyContent: 'center' }}>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName<typeof values>('receivedDividends')}
              value
            >
              Yes
            </FormikRadioToggleButton>
          </Grid.Column>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName<typeof values>('receivedDividends')}
              value={false}
            >
              No
            </FormikRadioToggleButton>
          </Grid.Column>
        </Grid.Row>
        {values.receivedDividends && (
          <GridRowColumn short {...questionsGridConfig}>
            <DocumentAddedAlert>
              1099-DIV and 1099-INT forms added to checklist
            </DocumentAddedAlert>
          </GridRowColumn>
        )}
        <GridRowColumn short {...questionsGridConfig}>
          <Divider />
        </GridRowColumn>
        <GridRowColumn short {...questionsGridConfig} />
        <Grid.Row />
        <FormFlowFooter
          continueDisabled={!isValid || isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isUpdating || isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default InvestmentsPanel
