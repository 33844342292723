import ReactDatePicker from 'react-datepicker'
import { forwardRef, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import moment, { Moment } from 'moment'
import { Button } from '../../BaseComponents'
import { sortBookkeepingReportsByYear } from '../../../selectors/reportSelectors'
import { useReselector } from '../../../utils/sharedHooks'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import './Reports.scss'
import { DateTime } from 'luxon'

interface DateType {
  value?: string
  onClick?: () => void
}

export const MonthPicker = ({ activeMonth }: { activeMonth: Moment }) => {
  const sortedBookkeepingReports = useReselector(sortBookkeepingReportsByYear)
  const navigate = useNavigate()

  const startDate = useMemo(() => {
    const dateStr = moment.utc(activeMonth).format(DATE_FORMATS.MONTH_YEAR)
    return moment(dateStr, 'MMM YYYY', true).toDate()
  }, [activeMonth])

  const reportIdMap = useMemo(() => {
    if (!sortedBookkeepingReports) return null
    const reportMap: { [date: string]: string | number } = {}
    Object.keys(sortedBookkeepingReports).forEach((key) => {
      const reportsForYear = sortedBookkeepingReports[key]
      reportsForYear.forEach((report) => {
        reportMap[
          moment.utc(report.startDate).format(DATE_FORMATS.MONTH_YEAR)
        ] = report.id
      })
    })
    return reportMap
  }, [sortedBookkeepingReports])

  const reportMonths = useMemo(() => {
    if (!reportIdMap) return [] as Date[]
    return Object.keys(reportIdMap).map((dateStr) =>
      moment(dateStr, 'MMM YYYY', true).toDate()
    )
  }, [reportIdMap])

  const onChange = (date: Date | null) => {
    if (!date) {
      return
    }
    const month = DateTime.fromJSDate(date).toFormat('MM')
    const year = DateTime.fromJSDate(date).toFormat('yyyy')
    navigate(`/reports/bookkeeping/${month}/${year}/overview`)
  }

  const CustomInput = forwardRef(({ value, onClick }: DateType, _: unknown) => (
    <Button variant="secondary" onClick={onClick}>
      <Icon name="calendar alternate outline" />
      &nbsp;
      {value}
    </Button>
  ))

  return (
    <div className="monthPicker">
      <ReactDatePicker
        selected={startDate}
        onChange={onChange}
        dateFormat="MMM yyyy"
        showMonthYearPicker
        includeDates={reportMonths}
        customInput={<CustomInput />}
      />
    </div>
  )
}
