import axios from 'axios'
import { fetchWrapper } from '../../reducers/fetch'

interface UserReferralStatus {
  id: number
  status: ReferralStatus
  campaignName: string
  recipientCode: string
  senderCode: string
  createdAt: string
  updatedAt: string
}

export enum ReferralStatus {
  invited = 'invited',
  clicked_invitation_link = 'clicked_invitation_link',
  account_created = 'account_created',
  trial = 'trial',
  paid = 'paid',
  ineligible = 'ineligible',
  reward_due = 'reward_due',
  reward_processed = 'reward_processed',
}

export interface RecipientDetails {
  firstName?: string
  lastName?: string
  email?: string
  senderFirstName: string
  status: ReferralStatus
  responseCode: 'success' | 'details_not_found' | 'already_accepted_invitation'
}

export const fetchKeyForCode = (code?: string) => `FETCH_RECIPIENT_${code}`

export const fetchRecipientByCode = (code: string) =>
  fetchWrapper({
    fetchKey: fetchKeyForCode(code),
    defaultErrorMessage: 'There was an error fetching this recipient',
    fetchFunction: async () => {
      const response = await axios.get<RecipientDetails>(
        `/finances/referrals/recipients/${code}`
      )
      return response.data
    },
  })

interface PutPayload {
  recipientEmail: string
  status: ReferralStatus
  notifyIfNonexistent?: boolean
}

export const updateUserReferralStatusIfExists = ({
  recipientEmail,
  status,
  notifyIfNonexistent,
}: PutPayload) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error updating this recipients status',
    fetchFunction: async () => {
      const encodedEmail = encodeURIComponent(recipientEmail)
      const response = await axios.put<UserReferralStatus>(
        `/finances/referrals/recipients/${encodedEmail}`,
        {
          status,
          notifyIfNonexistent,
        }
      )
      return response.data
    },
  })

interface ReferralFactoryUser {
  id: number
  referrer_id: number
  campaign_id: number
  qualified: boolean | null
  qualified_at: string | null
  first_name: string
  email: string
  code: string
  reach: number | null
  referrals_count: number | null
  converted_referrals_count: number | null
  source: string
  date: string
  url: string
  sharing: {
    social: string
    url: string
  }[]
  campaign: {
    id: number
    name: string
    url: string
    code: string
    reach: number
    live: boolean
    status: string
    starts_at: string | null
    ends_at: string | null
  }
}

export interface KnownRecipientResponse {
  firstName: string
  lastName: string
  email: string
  rfUser?: ReferralFactoryUser
  signupLink?: string
  errorCode?:
    | 'rf_user_creation_error'
    | 'sendgrid_error'
    | 'heard_db_error'
    | 'rf_user_already_exists'
}

export const INVITE_KNOWN_RECIPIENT_KEY = 'INVITE_KNOWN_RECIPIENT_KEY'

export const inviteKnownRecipient = (
  recipients: {
    firstName: string
    lastName: string
    email: string
  }[]
) =>
  fetchWrapper({
    fetchKey: INVITE_KNOWN_RECIPIENT_KEY,
    defaultErrorMessage: 'There was an error inviting the known recipient(s)',
    fetchFunction: async () => {
      const response = await axios.post<{
        successful: KnownRecipientResponse[]
        erroneous: KnownRecipientResponse[]
      }>('/finances/api/v1/referrals/invite', { recipients })

      return response.data
    },
  })
