import styled from 'styled-components'
import { Editor, EditorProps } from 'react-draft-wysiwyg'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { Colors, Fonts } from '../../../styles/theme'

export interface StyledEditorDraftProps extends EditorProps {
  className?: string
}

const StyledDraftEditor = styled(
  ({ className, toolbarClassName, ...rest }: StyledEditorDraftProps) => (
    <Editor
      wrapperClassName={className}
      toolbarClassName={toolbarClassName || 'toolbar'}
      editorClassName="editor"
      placeholder="Type your message"
      toolbar={{
        options: ['inline', 'list', 'emoji', 'link'],
        inline: {
          inDropdown: false,
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
          options: ['bold', 'italic', 'underline'],
        },
        list: {
          inDropdown: false,
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
          options: ['unordered', 'ordered'],
        },
      }}
      {...rest}
    />
  )
)({
  '.toolbar': {
    borderRadius: 8,
    borderColor: Colors.lightGray,
    ...Fonts.bodyMd,
    '&.mobile': {
      display: 'none',
    },
    '.rdw-option-wrapper': {
      boxShadow: 'none',
      border: `1px solid ${Colors.white}`,

      '&:hover': {
        boxShadow: `0 0 1px ${Colors.lightGray}`,
        border: `1px solid ${Colors.lightGray}`,
      },
    },
    '.rdw-option-active': {
      backgroundColor: Colors.lightGray,
    },

    '.rdw-link-modal': {
      position: 'absolute',
      bottom: 50,
      left: 5,
      top: -250,
      display: 'flex',
      flexDirection: 'column',
      width: 235,
      height: 205,
      border: `1px solid ${Colors.lightGray}`,
      padding: 15,
      borderRadius: 2,
      zIndex: 100,
      background: Colors.white,
      boxShadow: `3px 3px 5px ${Colors.gray}`,
    },
  },
  '.editor': {
    padding: '0 24px',
    minHeight: 105,
    marginTop: 11,
    backgroundColor: `${Colors.white} !important`,
    borderRadius: 8,
    '.public-DraftEditorPlaceholder-inner': {
      color: Colors.mediumGray,
    },
    border: `1px solid ${Colors.lightGray}`,
    ...Fonts.bodyMd,
  },
})

export default StyledDraftEditor
