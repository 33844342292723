import { useState } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { noop } from 'lodash'

// BL
import { FinancialAccountReconciliationWithTransactions } from '../../../../reducers/admin/allFinancialAccountReconciliationsReducer'
import PanelTransactionForm, { FormFields } from './PanelTransactionForm'
import { formatUTCDateString, toUTCDateString } from '../service'
import { dollarsToCents } from '../../../../utils/currencyHelpers'

// UI
import { FinancialAccountSumResponse } from '../../../../actions/admin/adminFinancialAccountActions'
import { adminCreateTransaction } from '../../../../actions/adminActions'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { useAppDispatch } from '../../../../utils/typeHelpers'

interface Props {
  account: FinancialAccountSumResponse | null
  close: () => void
  open: boolean
  reconciliation: FinancialAccountReconciliationWithTransactions
  userId: number
  onAddTransaction: () => void
}

const PanelTransactionModal = ({
  account,
  close,
  open,
  reconciliation,
  userId,
  onAddTransaction,
}: Props) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [stepNum, setStepNum] = useState(0)

  const initialDate = toUTCDateString({
    value: reconciliation.startingBalanceDate,
    format: DATE_FORMATS.INPUT,
  })
  const [date, setDate] = useState<string>(initialDate)
  const [description, setDescription] = useState<string>('')
  const [amount, setAmount] = useState(0)
  const [categoryId, setCategoryId] = useState<number>(-1)

  const formFields: FormFields = {
    date: {
      get: date,
      set: setDate,
    },
    description: {
      get: description,
      set: setDescription,
    },
    amount: {
      get: amount,
      set: setAmount,
    },
    categoryId: {
      get: categoryId,
      set: setCategoryId,
    },
    account: {
      get: account,
      set: noop,
    },
  }

  function handleError(err: unknown) {
    alert(err)
    setLoading(false)
  }

  const onSaveClicked = async () => {
    if (formHasErrors()) {
      alert('All fields must be completed and the amount cannot equal 0.00')
      return
    }

    if (!account) {
      throw new Error('An account was not set')
    }

    const nowJSON = new Date().toJSON()

    try {
      setLoading(true)
      const data = {
        financialProfileId: account.financialProfileId,
        userId,
        date: formatUTCDateString({
          value: formFields.date.get,
          format: 'YYYY-MM-DD',
        }),
        description: formFields.description.get,
        amountInCents: dollarsToCents(formFields.amount.get),
        transactionCategoryId: formFields.categoryId.get,
        financialAccountId: reconciliation.accountId,
        reconciliationId: null,
        createdAt: nowJSON,
        updatedAt: nowJSON,
      }

      await dispatch(adminCreateTransaction(data))
      onAddTransaction()

      setLoading(false)
      onClose()
    } catch (err) {
      handleError(err)
    }
  }

  const formHasErrors = () =>
    !description || categoryId === -1 || !date || !amount

  const onClose = () => {
    close()
    reset()
  }

  function reset() {
    setStepNum(0)
    setLoading(false)
    setDescription('')
    setCategoryId(-1)
    setAmount(0)
    setDate(initialDate)
  }

  return (
    <Modal
      closeOnDimmerClick={false}
      closeOnEscape={false}
      onClose={onClose}
      open={open}
      size="tiny"
    >
      <Modal.Header>Create a new transaction</Modal.Header>
      <Modal.Content>
        {stepNum === 0 && (
          <>
            <p>
              Before adding a transaction, we should first attempt to locate it.
              Please contact Victoria to track it down.
            </p>
            <p>
              Adding a transaction should be our last resort, and doing so
              requires approval from either Victoria or Andrew.
            </p>
          </>
        )}
        {stepNum === 1 && (
          <PanelTransactionForm
            formFields={formFields}
            reconciliation={reconciliation}
            loading={loading}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button basic disabled={loading} onClick={onClose}>
          Cancel
        </Button>
        {stepNum === 0 && (
          <Button color="blue" disabled={loading} onClick={() => setStepNum(1)}>
            I have approval
          </Button>
        )}
        {stepNum === 1 && (
          <Button color="blue" disabled={loading} onClick={onSaveClicked}>
            Save
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  )
}

export default PanelTransactionModal
