import React, { useState } from 'react'
import {
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Popup,
} from 'semantic-ui-react'
import { parseErrorFromCatch } from '../../../utils/errorHelpers'

interface OwnProps {
  defaultValue: DropdownProps['defaultValue']
  disabled?: boolean
  entityId: number
  hoverMessage?: string
  onChange: (
    _entityId: number,
    _value: number
  ) => Promise<true | { error: Error } | undefined> | Promise<void>
  onError?: (error: string) => void
  options: DropdownItemProps[]
  placeholder?: string | null
}

type Props = OwnProps

const StatefulDropdown = (props: Props) => {
  const [requesting, setRequesting] = useState(false)
  const [error, setError] = useState(false)

  const handleError = (err: string) => {
    setError(true)
    setRequesting(false)
    props.onError?.(err)
  }

  const handleSuccess = () => {
    setError(false)
    setRequesting(false)
  }

  const onChange = (
    _e: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    setRequesting(true)

    try {
      const value = parseInt(String(data.value), 10)
      props.onChange(props.entityId, value)
      handleSuccess()
    } catch (err) {
      handleError(parseErrorFromCatch(err))
    }
  }

  const renderDropdown = () => (
    <Dropdown
      defaultValue={props.defaultValue}
      error={error}
      fluid
      disabled={props.disabled || requesting}
      loading={requesting}
      onChange={onChange}
      options={props.options}
      placeholder={props.placeholder || 'Choose'}
      selectOnBlur={false}
      selection
    />
  )

  return props.hoverMessage ? (
    <Popup
      content={props.hoverMessage}
      trigger={<span>{renderDropdown()}</span>}
    />
  ) : (
    renderDropdown()
  )
}

export default StatefulDropdown
