import { useContext, useEffect, useMemo, useState } from 'react'
import { Colors, Fonts } from '../../../styles/theme'
import { Modal, Text, Icon, Button, Link } from '../../BaseComponents'
import { fetchPlaidItemsIfNeeded } from '../../../actions/plaidItemActions'
import { selectPlaidItems } from '../../../selectors/financeSelectors'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { values } from 'lodash'
import {
  PostAuthModal,
  PlaidPostAuthModalType,
  PlaidLinkContext,
} from './PlaidLinkContext'
import { useAnalyticsTrack } from '../../../features/Amplitude'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useReselector } from '../../../utils/sharedHooks'

const StatementsBanner = () => (
  <div
    style={{
      backgroundColor: Colors.champagne,
      borderRadius: 8,
      padding: 24,
    }}
  >
    <img
      src="https://heard-images.s3.amazonaws.com/assets/statement_upload.svg"
      alt="banner"
      height={180}
      width={180}
      style={{ display: 'block', margin: '0 auto' }}
    />
  </div>
)

const UpgradeFromManualStatementsModal = ({
  postAuthModal,
  showModal,
  closeModal,
}: {
  showModal: boolean
  closeModal: () => void
  postAuthModal: PostAuthModal
}) => {
  const dispatch = useAppDispatch()
  const plaidItems = useReselector(selectPlaidItems)
  const [fetching, setFetching] = useState(true)

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchPlaidItemsIfNeeded())
      setFetching(false)
    }

    if (showModal) {
      fetch()
    }
  }, [dispatch, showModal])

  const manualStatementInstitution = useMemo(() => {
    return values(plaidItems).filter((plaidItem) =>
      plaidItem.accounts?.some(
        (account) =>
          account.statementPermissions === null && !account.bankAccessEnabledAt
      )
    )
  }, [plaidItems])

  if (fetching) return null

  return (
    <Modal closeIcon open={showModal} size="tiny" onClose={closeModal}>
      <Modal.Header>You have automatic statement uploads</Modal.Header>
      <Modal.Content>
        <StatementsBanner />

        <Text style={{ marginTop: 16 }}>
          Accounts at <strong>{postAuthModal.institutionName}</strong> support
          automatic statement uploads. This means you won&apos;t have to
          manually upload statements every month for accounts at this
          institution.
        </Text>

        {manualStatementInstitution.length > 0 && (
          <>
            <hr
              style={{
                border: 0,
                borderTop: `1px solid ${Colors.lightGray}`,
                marginTop: 16,
              }}
            />
            <Text as="h3" style={{ marginTop: 16, color: Colors.red }}>
              <Icon icon={solid('circle-xmark')} color="red" size="1x" /> Manual
              statement uploads still needed
            </Text>

            <Text
              style={{ ...Fonts.bodyXs, marginTop: 1, color: Colors.forest }}
            >
              These financial institutions don&apos;t support automatic
              statement uploads. You will still need to upload them manually to
              Documents.
            </Text>

            <ul style={{ paddingLeft: 20 }}>
              {manualStatementInstitution.map((plaidItem) => (
                <li key={plaidItem.id}>{plaidItem.institutionName}</li>
              ))}
            </ul>
          </>
        )}
        <div style={{ marginTop: 16 }}>
          <Link
            newPage
            href="https://support.joinheard.com/hc/en-us/articles/23569956149399/"
            as="secondaryLink"
          >
            Learn more about statement uploads &#8594;
          </Link>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const UpgradeFromLBAModal = ({
  postAuthModal,
  showModal,
  closeModal,
}: {
  showModal: boolean
  closeModal: () => void
  postAuthModal: PostAuthModal
}) => {
  return (
    <Modal closeIcon open={showModal} size="tiny" onClose={closeModal}>
      <Modal.Header>Your limited bank access has been updated</Modal.Header>
      <Modal.Content>
        <StatementsBanner />

        <Text style={{ marginTop: 16 }}>
          <strong>{postAuthModal.institutionName}</strong> uses{' '}
          <strong>automatic statement uploads</strong>, which is an updated
          method of sharing statements with Heard. This allows you to directly
          manage your permissions via Plaid.
        </Text>

        <Text style={{ marginTop: 16 }}>
          Statements from this institution will continue to be automatically
          uploaded.
        </Text>

        <div style={{ marginTop: 16 }}>
          <Link
            newPage
            href="https://support.joinheard.com/hc/en-us/articles/23569956149399/"
            as="secondaryLink"
          >
            Learn more about statement uploads &#8594;
          </Link>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export const PostPlaidAuthModal = () => {
  const { postAuthModal, setPostAuthModal } = useContext(PlaidLinkContext)
  const track = useAnalyticsTrack()

  useEffect(() => {
    if (postAuthModal?.type) {
      track('viewed post plaid auth modal', { ...postAuthModal })
    }
  }, [track, postAuthModal])

  const closeModal = () => {
    setPostAuthModal(null)
  }

  if (!postAuthModal) return null

  return (
    <>
      <UpgradeFromManualStatementsModal
        postAuthModal={postAuthModal}
        showModal={
          postAuthModal?.type ===
          PlaidPostAuthModalType.UPGRADE_FROM_MANUAL_STATEMENTS
        }
        closeModal={closeModal}
      />
      <UpgradeFromLBAModal
        postAuthModal={postAuthModal}
        showModal={
          postAuthModal?.type === PlaidPostAuthModalType.UPGRADE_FROM_LBA
        }
        closeModal={closeModal}
      />
    </>
  )
}
