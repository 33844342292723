import { useCallback, useState } from 'react'
import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import {
  Alert,
  Button,
  Icon,
  IconButton,
  Modal,
  Text,
} from '../../BaseComponents'
import {
  PUT_ADMIN_UPDATE_USER_TRANSACTION_KEY,
  adminUpdateUserTransaction,
} from '../../../actions/admin/transactionActions'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useReselector } from '../../../utils/sharedHooks'
import { getFetchError, getIsFetching } from '../../../reducers/fetch'

export const useClearDuplicateTransaction = ({
  transaction,
  onUpdated = () => undefined,
}: {
  transaction: Transaction
  onUpdated?: () => void
}) => {
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)

  const isUpdating = useReselector(
    getIsFetching,
    PUT_ADMIN_UPDATE_USER_TRANSACTION_KEY
  )
  const error = useReselector(
    getFetchError,
    PUT_ADMIN_UPDATE_USER_TRANSACTION_KEY
  )

  const clearDuplicateFlag = useCallback(async () => {
    await adminUpdateUserTransaction(transaction.id, {
      manuallyDedupedAt: null,
    })(dispatch)
    onUpdated()
    setOpen(false)
  }, [onUpdated, transaction, dispatch])

  const renderModal = () => (
    <Modal onClose={() => setOpen(false)} open={open} size="tiny" closeIcon>
      <Modal.Header>
        Are you sure you want to clear this duplicate flag?
      </Modal.Header>
      <Modal.Content>
        This transaction will no longer be marked as duplicate. This cannot be
        undone.
      </Modal.Content>
      {error && <Alert type="error">{error.message}</Alert>}
      <Modal.Actions>
        <Button
          onClick={() => setOpen(false)}
          variant="actionLink"
          style={{ marginRight: 36 }}
        >
          Cancel
        </Button>
        <Button onClick={() => clearDuplicateFlag()}>
          Clear Duplicate Flag
        </Button>
      </Modal.Actions>
    </Modal>
  )

  return {
    isUpdating,
    error,
    renderModal,
    openModal: () => setOpen(true),
  }
}

const MarkedDuplicateTransactionButton = ({
  transaction,
}: {
  transaction: Transaction
}) => {
  const { isUpdating, openModal, renderModal } = useClearDuplicateTransaction({
    transaction,
  })

  return (
    <Text
      color="orange"
      as="bodySm"
      style={{
        marginBottom: '1em',
      }}
    >
      <Icon icon={solid('flag')} style={{ marginRight: '0.5em' }} />
      <i>Marked duplicate</i>
      {!isUpdating && (
        <IconButton
          icon={regular('close')}
          onClick={() => openModal()}
          style={{ marginLeft: 10 }}
          color="green"
          size="sm"
        />
      )}
      {renderModal()}
    </Text>
  )
}

export default MarkedDuplicateTransactionButton
