import { FC } from 'react'
import { Grid } from 'semantic-ui-react'
import {
  Button,
  GridRowColumn,
  Modal,
  Text,
} from '../../../../../../components/BaseComponents'
import { Colors } from '../../../../../../styles/theme'

interface props {
  open: boolean
  close: () => void
  removeDependent: () => void
}

const RemoveDependentModal: FC<props> = ({ open, close, removeDependent }) => {
  return (
    <Modal size="tiny" open={open} onClose={close} closeIcon>
      <Modal.Header>
        Are you sure you want to remove this dependent?
      </Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text>Any information entered can&apos;t be recovered.</Text>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="actionLink"
          onClick={close}
          style={{ color: Colors.black, paddingRight: 32, paddingLeft: 32 }}
        >
          Cancel
        </Button>
        <Button
          variant="warning"
          onClick={() => {
            removeDependent()
            close()
          }}
        >
          Remove
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default RemoveDependentModal
