import { useReselector } from '../../../utils/sharedHooks'
import { Modal, Button, Text } from '../../../components/BaseComponents'
import { JournalEntry } from '../types'
import { JournalEntryCard } from './JournalEntryTransactionRow'
import {
  deleteJournalEntry,
  DELETE_JOURNAL_ENTRY_KEY,
} from '../../../actions/admin/journalEntryActions'
import { selectIsFetchingForKeys } from '../../../reducers/fetch'
import {
  JournalEntryUpdateType,
  MaybeJournalEntry,
} from '../../../components/Admin/PaginatedTransactionTable/RowDrawer'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface DeleteJournalEntraModalProps {
  onClose: () => void
  open: boolean
  journalEntry: JournalEntry
  userId: number
  onUpdate?: (
    journalEntry: MaybeJournalEntry,
    type: JournalEntryUpdateType
  ) => void
}

export const DeleteJournalEntryModal = ({
  onClose,
  open,
  journalEntry,
  userId,
  onUpdate = (_: MaybeJournalEntry, _2: JournalEntryUpdateType) => undefined,
}: DeleteJournalEntraModalProps) => {
  const dispatch = useAppDispatch()

  const loading = useReselector(selectIsFetchingForKeys, [
    DELETE_JOURNAL_ENTRY_KEY,
  ])

  const handleDelete = async () => {
    const res = await deleteJournalEntry(journalEntry.id)(dispatch)
    if (res) {
      onUpdate(res, JournalEntryUpdateType.DELETE)
      onClose()
    }
  }

  return (
    <Modal size="small" open={open} onClose={onClose} closeIcon>
      <Modal.Content style={{ padding: 32 }}>
        <Text as="h2" style={{ marginBottom: 32 }}>
          Confirm journal entry deletion
        </Text>
        <Text> Are you sure you want to delete this journal entry?</Text>
        <JournalEntryCard
          journalEntry={journalEntry}
          userId={userId}
          deleting
        />
        <Text style={{ fontWeight: 800 }}>It cannot be recovered.</Text>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="actionLink"
          onClick={onClose}
          style={{ marginRight: 24 }}
        >
          Cancel
        </Button>
        <Button variant="warning" onClick={handleDelete} loading={loading}>
          Delete Journal Entry
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
