import { useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { UserTaxEstimate } from '../../../../QuarterlyTaxEstimates/userTaxEstimates.slice'
import { checkEstimateIsUnpaid } from '../../../../QuarterlyTaxEstimates/userTaxEstimates.selector'
import {
  Button,
  Card,
  GridRowColumn,
  Icon,
  Text,
} from '../../../../../../components/BaseComponents'
import { Colors } from '../../../../../../styles/theme'
import { formatCurrencyFromCents } from '../../../../../../utils/currencyHelpers'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../../../../utils/dateHelpers'
import UpdateQuarterlyTaxEstimateModal from '../../../../QuarterlyTaxEstimates/components/UpdateQuarterlyTaxEstimateModal'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../../../../utils/deviceWidthHelpers'

const EditPaymentButton = ({
  setModalOpen,
}: {
  setModalOpen: (_val: boolean) => void
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  return (
    <GridRowColumn short>
      <Button
        variant="secondaryLink"
        floated={isMobile ? undefined : 'right'}
        onClick={() => setModalOpen(true)}
      >
        Edit Payment
      </Button>
    </GridRowColumn>
  )
}

export const EstimateCard = ({
  estimate,
  readOnly,
}: {
  estimate: UserTaxEstimate
  readOnly?: boolean
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const unpaid = checkEstimateIsUnpaid(estimate)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  return (
    <Card backgroundColor={unpaid ? 'stone' : 'lightGreen'}>
      <Grid>
        <Grid.Row>
          <Grid.Column
            computer={9}
            tablet={9}
            mobile={16}
            style={{ marginBottom: isMobile ? 30 : 0 }}
          >
            <Icon
              color="white"
              icon={regular('landmark')}
              style={{
                padding: 4,
                display: 'inline-block',
                backgroundColor: estimate.filingState
                  ? Colors.black
                  : Colors.green,
                borderRadius: 4,
                marginRight: 16,
                marginBottom: -3,
              }}
            />

            <Text style={{ display: 'inline-block' }} textAlign="left" as="h3">
              {estimate.filingState
                ? `State - ${estimate.filingState}`
                : 'Federal'}
            </Text>
          </Grid.Column>

          <Grid.Column computer={7} tablet={7} mobile={16}>
            <Grid>
              {unpaid ? (
                <GridRowColumn>
                  <Button
                    size="medium"
                    variant="secondary"
                    onClick={() => setModalOpen(true)}
                    fullWidth
                  >
                    Update Payment
                  </Button>
                </GridRowColumn>
              ) : estimate.quarterPaid ? (
                <>
                  <GridRowColumn>
                    <Text
                      color="accentGreen"
                      textAlign={isMobile ? 'left' : 'right'}
                      as="h1"
                    >
                      {formatCurrencyFromCents(estimate.amountPaidInCents)}
                    </Text>
                  </GridRowColumn>
                  <GridRowColumn textAlign={isMobile ? 'left' : 'right'} short>
                    <Text
                      color="forest"
                      style={{ display: 'inline-block' }}
                      as="bodySm"
                    >
                      paid{' '}
                      {
                        // we know paidAt will not be null due to the unpaid check above
                        estimate.paidAt &&
                          isoToUTCDateTime(estimate.paidAt).toFormat(
                            DATE_FORMATS_LUXON.DISPLAY_SHORT
                          )
                      }
                    </Text>
                    <Icon
                      icon={solid('check')}
                      style={{
                        marginLeft: 3,
                        height: 15,
                        display: 'inline-block',
                      }}
                      color="accentGreen"
                    />
                  </GridRowColumn>
                  {!readOnly && (
                    <EditPaymentButton setModalOpen={setModalOpen} />
                  )}
                </>
              ) : (
                <>
                  <GridRowColumn short>
                    <Text textAlign={isMobile ? 'left' : 'right'} as="bodySm">
                      You&apos;ve indicated that you didn&apos;t pay taxes this
                      quarter.
                    </Text>
                  </GridRowColumn>
                  {!readOnly && (
                    <EditPaymentButton setModalOpen={setModalOpen} />
                  )}
                </>
              )}
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <UpdateQuarterlyTaxEstimateModal
        open={modalOpen}
        close={() => setModalOpen(false)}
        estimate={estimate}
      />
    </Card>
  )
}

export default EstimateCard
