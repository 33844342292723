import { Grid, Image } from 'semantic-ui-react'
import {
  Button,
  Card,
  GridRowColumn,
  Text,
  Pill,
} from '../../../components/BaseComponents'
import { track } from '@amplitude/analytics-browser'
import { Colors } from '../../../styles/theme'
import { useNavigate } from 'react-router-dom'
import { useReselector } from '../../../utils/sharedHooks'
import { getCurrentUser } from '../../../selectors/user.selectors'

export const AdvisoryLaunchCard = () => {
  const navigate = useNavigate()
  const currentUser = useReselector(getCurrentUser)

  const handleLearnMoreButtonClick = () => {
    track('clicked financial advisory phase 2 dashboard card button', {
      user_id: currentUser?.id,
    })
    navigate('/advisory/overview')
  }

  return (
    <Card backgroundColor="green" className={'mobile-squared-corners'}>
      <Grid>
        <GridRowColumn>
          <Pill color="neutral">★ Annual Plan Benefit</Pill>
          <br />
          <Text as="h2" color={'white'}>
            Introducing: Financial Advisory{' '}
          </Text>
        </GridRowColumn>
        <GridRowColumn
          columnStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/financial_advice.svg"
            style={{ height: 150 }}
            alt="financial advisory"
          />
        </GridRowColumn>
        <GridRowColumn>
          <Text color="white" as="bodyMd">
            Managing your finances as a therapist can be challenging, but it
            doesn’t have to be. Our Financial Advisory experience provides you
            with expert, personalized support to help you achieve your financial
            goals with confidence.
          </Text>
          <br />
          <Text color="white" as="bodyMd">
            Ready to get started? Schedule your free 20 minute Introductory
            Advisory Call with a Certified Financial Planner (CFP®) to learn
            more.
          </Text>
          <br />

          <Button
            style={{ backgroundColor: Colors.white, color: Colors.black }}
            onClick={handleLearnMoreButtonClick}
          >
            Get Started with Advisory
          </Button>
        </GridRowColumn>
      </Grid>
    </Card>
  )
}
