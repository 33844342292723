import { useEffect, useMemo } from 'react'
import { isNil } from 'lodash'
import moment from 'moment'
import { Grid } from 'semantic-ui-react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Button,
  Link,
  Popup,
  Text,
  Icon,
} from '../../../../../components/BaseComponents'
import { formatCurrencyFromCents } from '../../../../../utils/currencyHelpers'
import { UserTaxEstimate } from '../../userTaxEstimates.slice'
import {
  selectIsSingleStateCalculationByTaxEstimateId,
  selectTaxCalculationByEstimateId,
} from '../../../../../selectors/userTaxCalculation.selectors'
import { useReselector } from '../../../../../utils/sharedHooks'
import { getUserDocumentById } from '../../../../UserDocuments/userDocuments.selector'
import { Fonts } from '../../../../../styles/theme'
import { fetchAllQuarterlyTaxEstimateDetailsIfNeeded } from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import { fetchUserDocuments } from '../../../../UserDocuments/userDocuments.slice'
import { DATE_FORMATS } from '../../../../../utils/dateHelpers'
import { useAppDispatch } from '../../../../../utils/typeHelpers'
import {
  selectStatusForTaxEstimate,
  selectUserTaxEstimateById,
} from '../../userTaxEstimates.selector'
import { EstimateStatus } from '../../service'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../../../utils/deviceWidthHelpers'
import { createLinkToTaxBreakdown } from './TaxEstimateCta'

const EstimatedPaymentSubtitle = ({
  estimate,
}: {
  estimate: UserTaxEstimate | null
}) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchAllQuarterlyTaxEstimateDetailsIfNeeded())
  }, [dispatch])

  const userTaxCalculation = useReselector(
    selectTaxCalculationByEstimateId,
    estimate?.id
  )
  const isSingleStateCalc = useReselector(
    selectIsSingleStateCalculationByTaxEstimateId,
    estimate?.id
  )

  const isFederal = estimate?.type === 'federal'

  const showPopup = isSingleStateCalc && userTaxCalculation?.calculatedByUserId

  return (
    <>
      <Popup
        disabled={!showPopup}
        trigger={
          <Text as="bodySm" color="forest">
            estimated payment{' '}
            {showPopup && <Icon icon={regular('question-circle')} />}
          </Text>
        }
        hoverable
        position="top right"
        content={
          <Text>
            Your final estimate has been manually adjusted by Heard staff.
          </Text>
        }
      />
      {isFederal && isSingleStateCalc && (
        <>
          <br />
          <Text as="bodySm" color="forest">
            If this looks off, double-check your entries{' '}
            <Link
              as="secondaryLink"
              to={createLinkToTaxBreakdown(estimate, {
                incomeExpanded: true,
              })}
              color="forest"
              style={{ fontSize: 14 }}
            >
              here.
            </Link>
          </Text>
        </>
      )}
    </>
  )
}

const RightColumn = ({
  estimateId,
  toggleOpen,
}: {
  estimateId?: number
  toggleOpen: boolean
}) => {
  const dispatch = useAppDispatch()
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const estimate = useReselector(selectUserTaxEstimateById, estimateId)
  const status = useReselector(selectStatusForTaxEstimate, estimateId)

  const document = useReselector(getUserDocumentById, estimate?.userDocumentId)

  useEffect(() => {
    dispatch(fetchUserDocuments())
  }, [dispatch])

  const subContent = useMemo(() => {
    switch (status) {
      case EstimateStatus.paid:
        return (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: isMobile ? 'start' : 'end',
                alignItems: 'center',
              }}
            >
              <Text as="bodySm">
                paid{' '}
                {moment(estimate?.paidAt).format(DATE_FORMATS.DISPLAY_LONG)}
              </Text>
              <Icon
                icon={solid('check')}
                style={{ marginLeft: 3, height: 15 }}
                color="green"
              />
            </div>

            {document?.signedUrl && (
              <Link
                newPage
                href={document.signedUrl}
                style={{
                  display: 'flex',
                  justifyContent: isMobile ? 'start' : 'end',
                  alignItems: 'center',
                }}
              >
                <Button variant="secondaryLink" style={Fonts.bodySm}>
                  receipt uploaded
                </Button>
                <Icon
                  icon={solid('check')}
                  style={{ marginLeft: 3, height: 15 }}
                  color="green"
                />
              </Link>
            )}
            {toggleOpen && (
              <>
                <br />
                <Text as="h3">
                  {formatCurrencyFromCents(estimate?.estimateInCents)}
                </Text>
                <EstimatedPaymentSubtitle estimate={estimate} />
              </>
            )}
            <Text color="green" as="h1">
              {!isNil(estimate?.amountPaidInCents)
                ? formatCurrencyFromCents(estimate.amountPaidInCents)
                : '$ ---'}
            </Text>
          </>
        )
      case EstimateStatus.upcoming:
      case EstimateStatus.awaitingEstimate:
      case EstimateStatus.unpaidWithResponse:
        return <Text as="bodySm">estimate</Text>
      case EstimateStatus.noIncomeTax:
        return <Text as="bodySm">No taxes owed.</Text>
      case EstimateStatus.estimateIsZero:
      case EstimateStatus.unpaidWithoutResponse:
      case EstimateStatus.readyForPayment:
        return <EstimatedPaymentSubtitle estimate={estimate} />

      case EstimateStatus.joinedTooLate:
        return null

      default:
        return status satisfies never
    }
  }, [document?.signedUrl, estimate, isMobile, status, toggleOpen])

  const estimateColor = useMemo(() => {
    switch (status) {
      case EstimateStatus.readyForPayment:
        return 'orange'
      case EstimateStatus.unpaidWithoutResponse:
        return 'red'
      case EstimateStatus.unpaidWithResponse:
        return 'green'
      case EstimateStatus.upcoming:
      case EstimateStatus.awaitingEstimate:
      case EstimateStatus.estimateIsZero:
        return 'black'

      // Estimate does not show for these
      case EstimateStatus.paid:
      case EstimateStatus.noIncomeTax:
      case EstimateStatus.joinedTooLate:
        return null

      default:
        return status satisfies never
    }
  }, [status])

  return (
    <Grid.Column
      computer={4}
      tablet={4}
      mobile={16}
      style={{ textAlign: isMobile ? 'left' : 'right' }}
    >
      {/* Dollar amount */}
      {estimateColor && (
        <Text color={estimateColor} as="h1">
          {!isNil(estimate?.estimateInCents)
            ? formatCurrencyFromCents(estimate.estimateInCents)
            : '$ ---'}
        </Text>
      )}

      {subContent}
    </Grid.Column>
  )
}

export default RightColumn
