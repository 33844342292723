import { useMemo, useEffect } from 'react'
import { Grid, Divider } from 'semantic-ui-react'
import { getUserFinancialAccounts } from '../../../selectors/financeSelectors'
import { useReselector } from '../../../utils/sharedHooks'
import { Card, GridRowColumn, Text } from '../../BaseComponents'
import { selectCurrentAnnualTaxYear } from '../../../features/Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { selectAdminAnnualTaxFilingsByYearForUser } from '../../../features/Admin/Taxes/adminAnnualTaxFilings.selector'
import { useAppDispatch, filterNulls } from '../../../utils/typeHelpers'
import { fetchAdminAnnualTaxFilings } from '../../../features/Admin/Taxes/adminAnnualTaxFilings.slice'
import { FinancialAccountWithAdminInfo } from '../../../reducers/admin/financialAccountsReducer'
import { sortBy } from 'lodash'

const questionMap: {
  [identifier: string]: {
    text: string
    order: number
  }
} = {
  isBusinessAccount: {
    text: 'Is this a business account?',
    order: 0,
  },
  hasPersonalTransactions: {
    text: 'Does it have any personal transactions?',
    order: 1,
  },
  hasBusinessTransactions: {
    text: 'Does it have any business transactions?',
    order: 2,
  },
  isAccountOpenForYear: {
    text: 'Has this account been open for the entire year?',
    order: 3,
  },
}

export const AccountAnswersCard = ({
  account,
  index,
}: {
  account: FinancialAccountWithAdminInfo
  index: number
}) => {
  const questionArray = useMemo(() => {
    const { onboardingAnswers } = account
    const unsortedList = Object.keys(onboardingAnswers).map((key) => ({
      text: questionMap[key].text,
      value: onboardingAnswers[key],
      order: questionMap[key].order,
      key,
    }))
    return sortBy(unsortedList, 'order')
  }, [account])

  return (
    <Card backgroundColor="stone40">
      <Grid>
        <GridRowColumn short>
          <Text as="eyebrow"> CONNECTED ACCOUNT {index + 1} </Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text>
            <b> {account.name} </b>
          </Text>
        </GridRowColumn>
        {questionArray.map((question) => {
          return (
            <GridRowColumn key={question.key} short>
              <Text as="h3"> {question.text} </Text>
              <Text> {question.value ? 'Yes' : 'No'} </Text>
            </GridRowColumn>
          )
        })}
      </Grid>
    </Card>
  )
}

export const OnboardingAnswers = ({ userId }: { userId: number }) => {
  const dispatch = useAppDispatch()
  const accounts = useReselector(getUserFinancialAccounts, userId)
  const taxYear = useReselector(selectCurrentAnnualTaxYear)

  const annualTaxFiling = useReselector(
    selectAdminAnnualTaxFilingsByYearForUser,
    taxYear,
    userId
  )

  useEffect(() => {
    fetchAdminAnnualTaxFilings({
      page: 1,
      limit: 1,
      year: taxYear,
      userIds: userId,
    })(dispatch)
  }, [dispatch, taxYear, userId])

  const accountList = useMemo(() => {
    if (!accounts) return []
    const accountArr = filterNulls(Object.values(accounts))
    return accountArr.filter((account) => account?.onboardingAnswers)
  }, [accounts])

  const needsAnnualTaxes = useMemo(() => {
    if (!annualTaxFiling) return 'No'
    const { optedOutAt, pretaxSurveySubmittedAt, isLateJoiner } =
      annualTaxFiling
    if (optedOutAt) return 'No'
    if (pretaxSurveySubmittedAt || isLateJoiner) return 'Yes'
    return 'No'
  }, [annualTaxFiling])

  return (
    <Card fullWidth>
      <Grid>
        <GridRowColumn>
          <Text as="h2"> Sign Up Questions </Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text as="h3" style={{ marginBottom: 6 }}>
            Would you like Heard to prepare your {taxYear} taxes?
          </Text>
          <Text as="bodyLg"> {needsAnnualTaxes} </Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Divider />
        </GridRowColumn>
        <GridRowColumn short>
          <Text as="h2">Onboarding Questions</Text>
        </GridRowColumn>
        <Grid.Row short columns={2}>
          {accountList.map((account, index) => (
            <Grid.Column key={account.id} style={{ marginBottom: 8 }}>
              <AccountAnswersCard account={account} index={index} />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Card>
  )
}
