import { Label } from 'semantic-ui-react'
import { selectBookkeepingReportStatus } from '../../../selectors/bookkeepingReportsSelectors'
import { useReselector } from '../../../utils/sharedHooks'

const BookkeepingStatusLabel = ({ reportId }: { reportId: string }) => {
  const status = useReselector(selectBookkeepingReportStatus, reportId)
  if (!status) {
    return null
  }

  return (
    {
      done: <Label color="green">Done</Label>,
      reconciled: <Label color="yellow">Reconciled</Label>,
      categorized: <Label color="orange">Bookkeeping Completed</Label>,
      in_progress: <Label color="orange">In progress</Label>,
      not_started: <Label color="red">Not Started</Label>,
      plaid_error: <Label color="red">Plaid Error</Label>,
      missing_info: <Label color="red">Missing Information</Label>,
    }[status] ?? <Label color="grey">{status}</Label>
  )
}

export default BookkeepingStatusLabel
