import { useMemo, Dispatch, SetStateAction } from 'react'
import { FormikProps } from 'formik'
import * as yup from 'yup'
import { Grid } from 'semantic-ui-react'
import { useReselector } from '../../../utils/sharedHooks'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { selectAdminOrUserFinancialAccountOptions } from '../../../selectors/financeSelectors'
import { selectTransactionCategoriesAsDropdownOptions } from '../../Reports/reports.selectors'
import {
  FormikDropdown,
  FormikInput,
  NOT_DISPLAY_ERR,
  Text,
  Button,
  Alert,
  GridRowColumn,
  IconButton,
  makeNumberSchema,
  Icon,
} from '../../../components/BaseComponents'
import { LabelDescription } from '../../../components/BaseComponents/Input'
import { InitialJournalEntryFormValues } from '../types'

interface JournalEntryLineInputsProps {
  formik: FormikProps<InitialJournalEntryFormValues>
  userId?: string
  totals: {
    debitTotal: string
    creditTotal: string
  }
  totalError: boolean
  setJournalEntryLinesToDelete: Dispatch<SetStateAction<number[]>>
}

export const JournalEntryLineInputs = ({
  formik,
  userId,
  totals,
  totalError,
  setJournalEntryLinesToDelete,
}: JournalEntryLineInputsProps) => {
  const financialAccountOptions = useReselector(
    selectAdminOrUserFinancialAccountOptions,
    userId
  )

  const categoryOptions = useReselector(
    selectTransactionCategoriesAsDropdownOptions
  )

  const lineItemDropdownOptions = useMemo(
    () => [
      ...financialAccountOptions.map((option) => ({
        value: `${option.value} fin`,
        text: option.text,
      })),
      ...categoryOptions.map((option) => ({
        value: `${option.value} cat`,
        text: option.text,
      })),
    ],
    [financialAccountOptions, categoryOptions]
  )

  // return error if a journal entry line has both a debit and a credit
  const hasLineError = useMemo(() => {
    return formik.values.journalEntryLines.some((line) => {
      const debitAmount = parseFloat(line.debitAmount)
      const creditAmount = parseFloat(line.creditAmount)
      return debitAmount !== 0 && creditAmount !== 0
    })
  }, [formik.values])

  const addJournalEntryLine = () => {
    formik.setValues((prevValues) => {
      const newLine = {
        debitAmount: '0.00',
        creditAmount: '0.00',
        accountOrCategoryId: null,
      }

      return {
        ...prevValues,
        journalEntryLines: [...prevValues.journalEntryLines, newLine],
      }
    })
  }

  const removeJournalEntryLine = (indexToRemove: number) => {
    formik.setValues((prevValues) => {
      const updatedLines = [...prevValues.journalEntryLines]
      const lineToDelete = updatedLines[indexToRemove]
      const id = lineToDelete.id
      if (id) {
        setJournalEntryLinesToDelete((prevValue) => [...prevValue, id])
      }
      updatedLines.splice(indexToRemove, 1)
      return {
        ...prevValues,
        journalEntryLines: updatedLines,
      }
    })
  }

  return (
    <>
      <Grid.Row>
        <Grid.Column width={7}>
          <LabelDescription label="Account" required />
        </Grid.Column>
        <Grid.Column width={4}>
          <LabelDescription label="Debit" required />
        </Grid.Column>
        <Grid.Column width={4}>
          <LabelDescription label="Credit" required />
        </Grid.Column>
      </Grid.Row>
      {formik.values.journalEntryLines.map((line, index) => {
        return (
          <Grid.Row
            key={`${line.id} ${index}`}
            className="short"
            verticalAlign="middle"
          >
            <Grid.Column width={7}>
              <FormikDropdown
                search
                name={`journalEntryLines.${index}.accountOrCategoryId`}
                options={lineItemDropdownOptions}
                fullWidth
                schema={yup.string().required('Field is required.')}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <FormikInput
                name={`journalEntryLines.${index}.debitAmount`}
                schema={makeNumberSchema({ allowedDecimals: 2 }).test(
                  'Test failed',
                  NOT_DISPLAY_ERR,
                  (debitAmount) => {
                    if (totals.debitTotal !== totals.creditTotal) return false
                    const debitNum = parseFloat(debitAmount || '0')
                    const creditNum = parseFloat(
                      formik.values.journalEntryLines[index].creditAmount
                    )
                    if (creditNum === 0 && debitNum === 0) return false
                    return !(debitNum !== 0 && creditNum !== 0)
                  }
                )}
                componentType="currency"
                fullWidth
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <FormikInput
                name={`journalEntryLines.${index}.creditAmount`}
                schema={makeNumberSchema({ allowedDecimals: 2 }).test(
                  'Test failed',
                  NOT_DISPLAY_ERR,
                  (creditAmount) => {
                    if (totals.debitTotal !== totals.creditTotal) return false
                    const creditNum = parseFloat(creditAmount || '0')
                    const debitNum = parseFloat(
                      formik.values.journalEntryLines[index].debitAmount
                    )
                    if (creditNum === 0 && debitNum === 0) return false
                    return !(debitNum !== 0 && creditNum !== 0)
                  }
                )}
                componentType="currency"
                fullWidth
              />
            </Grid.Column>
            {index > 1 && (
              <Grid.Column width={1} floated="right">
                <IconButton
                  icon={regular('trash-alt')}
                  onClick={() => removeJournalEntryLine(index)}
                />
              </Grid.Column>
            )}
          </Grid.Row>
        )
      })}
      {totals && (
        <Grid.Row className="short">
          <Grid.Column width={7}>
            <Button onClick={addJournalEntryLine} icon variant="link">
              <Icon
                icon={regular('plus')}
                style={{ marginRight: 8, marginBottom: 2 }}
              />
              Add line
            </Button>
          </Grid.Column>
          <Grid.Column width={4} textAlign="left">
            <Text
              style={{ overflowWrap: 'break-word', marginLeft: 16 }}
              color={totalError ? 'red' : 'darkGray'}
            >
              {totals.debitTotal}
            </Text>
          </Grid.Column>
          <Grid.Column width={4} textAlign="left">
            <Text
              style={{ overflowWrap: 'break-word', marginLeft: 16 }}
              color={totalError ? 'red' : 'darkGray'}
            >
              {totals.creditTotal}
            </Text>
          </Grid.Column>
        </Grid.Row>
      )}
      {hasLineError && (
        <GridRowColumn short>
          <Alert type="error">
            An amount can only be entered in one field per row.
          </Alert>
        </GridRowColumn>
      )}
    </>
  )
}
