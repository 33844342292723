import { Modal, Text } from '../../../components/BaseComponents'
import TodoAndImportantDateCreationForm from './TodoAndImportantDateCreationForm'
import { ActionItem } from './allActionItems.slice'

interface TodoAndImportantDateCreationModalProps {
  open: boolean
  close: () => void
  actionItem?: ActionItem
  isTodo?: boolean
}

const TodoAndImportantDateCreationModal = ({
  open,
  close,
  actionItem,
  isTodo = false,
}: TodoAndImportantDateCreationModalProps) => (
  <Modal
    size="tiny"
    className="TodoandImportantDateCreationModal"
    open={open}
    onClose={close}
  >
    <Modal.Content>
      <Text as="h2">
        {actionItem ? 'Update' : 'Create'} {isTodo ? 'Todo' : 'Important Date'}
      </Text>
      <p>
        {actionItem ? 'Update' : 'Create'}{' '}
        {isTodo ? 'a Todo' : 'an Important Date'} to be able to assign actions
        to user
      </p>
      <TodoAndImportantDateCreationForm
        actionItem={actionItem}
        isTodo={isTodo}
        close={close}
      />
    </Modal.Content>
  </Modal>
)

export default TodoAndImportantDateCreationModal
