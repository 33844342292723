import { Grid } from 'semantic-ui-react'
import { GridRowColumn, Text } from '../../../../../components/BaseComponents'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../utils/sharedHooks'
import { isEmpty } from 'lodash'
import { Form1040DetailsProps } from '../../TaxChecklist/Form1040/Details'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import { makeGridConfig } from '../../../../../components/BaseComponents/Grid'
import { TaxListQuestionId } from '../../TaxChecklist/service'
import { select1120sFormForYear } from '../../annualTaxFilingForms.selector'
import { selectIsFetchingForKeys } from '../../../../../reducers/fetch'
import { UPSERT_USER_TAX_QUESTIONNAIRE_KEY } from '../../TaxChecklist/taxChecklistQuestion.actions'
import {
  useBusinessIncome,
  usePersonalTaxEstimateQuestions,
} from '../SharedHooks'

export const estimatedTaxQuestionIds = [
  TaxListQuestionId.household_income_in_cents,
  TaxListQuestionId.previous_year_personal_tax_return,
  TaxListQuestionId.household_income_withholdings_in_cents,
  TaxListQuestionId.business_income_in_cents,
  TaxListQuestionId.business_calendar_type,
  TaxListQuestionId.previous_year_business_tax_return,
]

const EstimateTaxes = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
  taxYear,
}: Form1040DetailsProps) => {
  const filingForm1120s = useReselector(select1120sFormForYear, taxYear)
  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  ])

  const {
    personalTaxEstimateContent,
    personalFormik,
    isPrevYear1040Uploaded,
    govIds,
    spouseGovIds,
    needsSpouseInfo,
    editPersonalIncome,
  } = usePersonalTaxEstimateQuestions({
    goBack,
    goToNextStep,
    previousScreen,
    nextScreen,
    taxYear,
  })
  const {
    businessIncomeContent,
    businessIncomeFormik,
    isPrevYear1120sUploadedOrNotRequired,
    editBusinessIncome,
  } = useBusinessIncome({
    isUpdating,
    filingForm1120s,
    forCombinedFlow: true,
  })
  const { isValid, isSubmitting } = personalFormik

  const continueSurvey = useAsyncCallback(() => {
    if (personalFormik.isValid && businessIncomeFormik.isValid) {
      return goToNextStep(null, nextScreen ?? null)
    }
    return Promise.resolve(false)
  })

  return (
    <Grid>
      <GridRowColumn {...makeGridConfig([12, 14], true)}>
        <Text as="display2" textAlign="center">
          Estimate Taxes
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([8, 14], true)}>
        <Text>
          Your tax preparer will need the following in order to provide an
          estimated tax payment amount for {taxYear}.
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([8, 14], true)}>
        {businessIncomeContent}
      </GridRowColumn>
      <GridRowColumn
        style={{ marginTop: '-3rem' }}
        {...makeGridConfig([8, 14], true)}
      >
        {personalTaxEstimateContent}
      </GridRowColumn>
      <FormFlowFooter
        continueDisabled={
          !businessIncomeFormik.isValid ||
          businessIncomeFormik.isSubmitting ||
          !isPrevYear1120sUploadedOrNotRequired ||
          !isValid ||
          isSubmitting ||
          !isPrevYear1040Uploaded ||
          editBusinessIncome ||
          editPersonalIncome ||
          continueSurvey.loading ||
          isEmpty(govIds) ||
          (needsSpouseInfo && isEmpty(spouseGovIds))
        }
        loading={continueSurvey.loading}
        onForward={continueSurvey.callback}
        onBack={() => goBack(previousScreen ?? null)}
      />
    </Grid>
  )
}

export default EstimateTaxes
