import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useReselector } from '../../../utils/sharedHooks'
import { logSentryError } from '../../../utils/sentryHelpers'
import { selectPlaidItems } from '../../../selectors/financeSelectors'
import {
  StatementStatus,
  StatementStatusType,
  fetchFinancialAccountsIfNeeded,
  getAccountUploadStatuses,
  updateFinancialAccount,
} from '../../../actions/financialAccountActions'
import { SetupCard } from '../SetupCard'
import { OnboardingModal } from '../OnboardingModal'
import { AccountQuestions } from './AccountQuestions'
import { AccountQuestionsEndScreen } from './AccountQuestionsEndScreen'
import { FinancialAccount } from '../../../reducers/finances/financialAccountsReducer'
import { fetchPlaidItemsIfNeeded } from '../../../actions/plaidItemActions'
import { useAnalyticsTrack } from '../../Amplitude'
import { markUserActionItemCompleteIfExists } from '../../Dashboard/UserActionItems/service'
import { GuidedOnboardingTaskIdents } from '../config'
import { getUsersTransactions } from '../../Transactions/transactions.selectors'
import {
  USER_TRANSACTION_KEY,
  fetchUserTransactions,
} from '../../Transactions/transactions.slice'
import { getIsFetching } from '../../../reducers/fetch'
import { useAppDispatch } from '../../../utils/typeHelpers'

const OB_URL = '/onboarding-v2'
const ACCT_STAGE = `${OB_URL}?stage=account`
const CONNECT_STAGE = `${OB_URL}?stage=connect`
const STATEMENTS_STAGE = `${OB_URL}?stage=statements`

export const AccountsStage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const plaidInstitutions = useReselector(selectPlaidItems)
  const [statuses, setStatuses] = useState<StatementStatus[]>()
  const track = useAnalyticsTrack()

  const accounts = useMemo(() => {
    const institutions = Object.values(plaidInstitutions || {})
    if (!institutions.length) return []
    let institutionAccounts: FinancialAccount[] = []
    for (const institution of institutions) {
      const { accounts } = institution
      institutionAccounts = [...institutionAccounts, ...accounts]
    }

    institutionAccounts.sort((a, b) => a.id - b.id)
    return institutionAccounts
  }, [plaidInstitutions])

  const initialAccountIdx = Number(searchParams.get('accountIdx'))
  const [accountIdx, setAccountIdx] = useState(initialAccountIdx || 0)
  const [currentAccount, setCurrentAccount] = useState(accounts[accountIdx])
  const [showModal, setShowModal] = useState(false)
  const [step, setStep] = useState(searchParams.get('step'))
  const answers = currentAccount?.onboardingAnswers || {}
  const txn_per_minute = 60

  const transactions = useReselector(getUsersTransactions)
  const transactionsCount = Object.keys(transactions || {}).length
  const hoursSaved = Math.ceil(transactionsCount / txn_per_minute)

  const [isBusinessAccount, setIsBusinessAccount] = useState<
    boolean | undefined
  >(answers.isBusinessAccount)

  const [hasPersonalTransactions, setHasPersonalTransactions] = useState<
    boolean | undefined
  >(answers.hasPersonalTransactions)

  const [hasBusinessTransactions, setHasBusinessTransactions] = useState<
    boolean | undefined
  >(answers.hasBusinessTransactions)

  const [isAccountOpenForYear, setIsAccountOpenForYear] = useState<
    boolean | undefined
  >(answers.isAccountOpenForYear)

  const fetchingTransactions = useReselector(
    getIsFetching,
    USER_TRANSACTION_KEY
  )

  useEffect(() => {
    dispatch(fetchUserTransactions(true))
  }, [dispatch])

  useEffect(() => {
    setCurrentAccount(accounts[accountIdx])
    setIsBusinessAccount(currentAccount?.onboardingAnswers?.isBusinessAccount)
    setHasPersonalTransactions(
      currentAccount?.onboardingAnswers?.hasPersonalTransactions
    )
    setHasBusinessTransactions(
      currentAccount?.onboardingAnswers?.hasBusinessTransactions
    )
    setIsAccountOpenForYear(
      currentAccount?.onboardingAnswers?.isAccountOpenForYear
    )
  }, [accountIdx, accounts, currentAccount])

  useEffect(() => {
    setIsBusinessAccount(currentAccount?.onboardingAnswers?.isBusinessAccount)
    setHasPersonalTransactions(
      currentAccount?.onboardingAnswers?.hasPersonalTransactions
    )
    setHasBusinessTransactions(
      currentAccount?.onboardingAnswers?.hasBusinessTransactions
    )
    setIsAccountOpenForYear(
      currentAccount?.onboardingAnswers?.isAccountOpenForYear
    )
  }, [currentAccount])

  useEffect(() => {
    setAccountIdx(Number(searchParams.get('accountIdx')))
    setStep(searchParams.get('step'))
  }, [searchParams])

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchFinancialAccountsIfNeeded())
      await dispatch(fetchPlaidItemsIfNeeded())
      const statusesResponse = await dispatch(getAccountUploadStatuses())
      if (statusesResponse) {
        setStatuses(statusesResponse)
      }
    }
    fetch()
  }, [dispatch])

  const hasAllPlaidStatements = useMemo(() => {
    if (!statuses) return false
    return statuses.every(
      (status) => status.status === StatementStatusType.PLAID_STATEMENT
    )
  }, [statuses])

  const continueDisabled = useMemo(() => {
    if (step === 'complete') {
      return fetchingTransactions
    }
    if (step === 'questions') {
      return (
        isAccountOpenForYear === undefined ||
        isBusinessAccount === undefined ||
        (hasPersonalTransactions === undefined &&
          hasBusinessTransactions === undefined)
      )
    }
    return false
  }, [
    step,
    isAccountOpenForYear,
    isBusinessAccount,
    hasPersonalTransactions,
    hasBusinessTransactions,
    fetchingTransactions,
  ])

  const handleCompleteContinue = () => {
    track('completed payoff page', {
      account_count: accounts.length,
      transactions_count: transactionsCount,
      hours_saved_count: hoursSaved,
    })

    if (hasAllPlaidStatements) {
      navigate(`${STATEMENTS_STAGE}&step=plaidAccess`)
    } else {
      navigate(`${STATEMENTS_STAGE}&step=start`)
    }
  }

  const handleSave = async () => {
    try {
      await updateFinancialAccount(currentAccount.id, {
        onboardingAnswers: {
          isBusinessAccount,
          hasPersonalTransactions,
          hasBusinessTransactions,
          isAccountOpenForYear,
        },
      })(dispatch)

      await dispatch(fetchPlaidItemsIfNeeded(true))

      setIsBusinessAccount(currentAccount?.onboardingAnswers?.isBusinessAccount)
      setHasPersonalTransactions(
        currentAccount?.onboardingAnswers?.hasPersonalTransactions
      )
      setHasBusinessTransactions(
        currentAccount?.onboardingAnswers?.hasBusinessTransactions
      )
      setIsAccountOpenForYear(
        currentAccount?.onboardingAnswers?.isAccountOpenForYear
      )
    } catch (error) {
      logSentryError(error)
    }
  }

  const handleQuestionsContinue = async () => {
    const isLastAccount = accountIdx === accounts.length - 1

    track(
      `completed bank account information for account ${accounts[accountIdx].plaidInstitutionName} - ${accounts[accountIdx].name}`
    )
    await handleSave()

    if (!isLastAccount) {
      setAccountIdx(accountIdx + 1)
      navigate(`${ACCT_STAGE}&step=questions&accountIdx=${accountIdx + 1}`)
    }

    if (isLastAccount) {
      await markUserActionItemCompleteIfExists(
        GuidedOnboardingTaskIdents.FINANCIAL_ACCOUNT_QUESTIONS,
        track
      )
      track('completed bank account information page')
      navigate(`${ACCT_STAGE}&step=complete`)
    }
  }

  const handleContinue = () => {
    if (step === 'complete') {
      handleCompleteContinue()
      return
    }

    handleQuestionsContinue()
  }

  const handleBack = () => {
    handleSave()

    if (step === 'questions' && accountIdx === 0) {
      navigate(`${CONNECT_STAGE}&step=link`)
      return
    }

    if (accountIdx !== 0) {
      navigate(`${ACCT_STAGE}&step=questions&accountIdx=${accountIdx - 1}`)
      return
    }

    navigate(`${ACCT_STAGE}&step=questions&accountIdx=${accounts.length - 1}`)
  }

  return (
    <>
      <SetupCard
        currentStep={step === 'questions' ? 0 : 1}
        onBackClick={handleBack}
        onContinueClick={handleContinue}
        continueDisabled={continueDisabled}
      >
        {step === 'questions' ? (
          accounts.map((account, idx) => (
            <AccountQuestions
              key={account.id}
              account={account}
              institution={plaidInstitutions[account.plaidItemId || '']}
              display={idx === accountIdx}
              isBusinessAccount={isBusinessAccount}
              setIsBusinessAccount={setIsBusinessAccount}
              hasPersonalTransactions={hasPersonalTransactions}
              setHasPersonalTransactions={setHasPersonalTransactions}
              hasBusinessTransactions={hasBusinessTransactions}
              setHasBusinessTransactions={setHasBusinessTransactions}
              isAccountOpenForYear={isAccountOpenForYear}
              setIsAccountOpenForYear={setIsAccountOpenForYear}
            />
          ))
        ) : (
          <AccountQuestionsEndScreen
            accounts={accounts}
            transactionsCount={transactionsCount}
            hoursSaved={hoursSaved}
            isFetchingTransactions={fetchingTransactions}
          />
        )}
      </SetupCard>

      <OnboardingModal showModal={showModal} setShowModal={setShowModal} />
    </>
  )
}
