import { useState } from 'react'
import { Grid } from 'semantic-ui-react'
import moment from 'moment'
import { useFormik, FormikProvider } from 'formik'
import * as yup from 'yup'

import {
  PUT_UPDATE_CONTRACTOR_KEY,
  putUpdateContractor,
} from '../payrollActions'
import { getContractorName } from '../helpers'
import PayrollError from '../PayrollError'
import { formatCurrency } from '../../../utils/currencyHelpers'
import {
  Button,
  FormikDropdown,
  FormikInput,
  GridRowColumn,
  makeNumberSchema,
  makeReqStringSchema,
  Modal,
  Text,
} from '../../../components/BaseComponents'
import { DetailsRow } from '../Employee/JobPayTab'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import {
  Gusto_Contractor,
  Gusto_ContractorPayment,
} from '../generated_gusto_types'
import { useAppDispatch } from '../../../utils/typeHelpers'

const END_VAL = 'for this contractor'
const validationSchema = yup.object({
  first_name: yup
    .string()
    .nullable()
    .when('type', ([type], schema) =>
      type === 'Individual'
        ? makeReqStringSchema({ field: 'first name', end: END_VAL })
        : schema
    ),
  last_name: yup
    .string()
    .nullable()
    .when('type', ([type], schema) =>
      type === 'Individual'
        ? makeReqStringSchema({ field: 'last name', end: END_VAL })
        : schema
    ),
  business_name: yup
    .string()
    .nullable()
    .when('type', ([type], schema) =>
      type === 'Business'
        ? makeReqStringSchema({ field: 'business name', end: END_VAL })
        : schema
    ),
  ein: yup
    .string()
    .nullable()
    .when('type', ([type], schema) =>
      type === 'Business'
        ? makeNumberSchema({
            field: 'EIN',
            numDigits: 9,
            end: END_VAL,
            allowLeadingZero: true,
          })
        : schema
    ),
  hourly_rate: yup.string().when('wage_type', ([wage_type], schema) =>
    wage_type === 'Hourly'
      ? makeNumberSchema({
          field: 'hourly rate',
          end: END_VAL,
          allowedDecimals: 2,
        })
      : schema
  ),
})

const UpdateContractorModal = ({
  contractor,
  closeModal,
}: {
  contractor: Gusto_Contractor
  closeModal: () => void
}) => {
  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      first_name: contractor.first_name,
      last_name: contractor.last_name,
      business_name: contractor.business_name,
      ein: contractor.ein,
      hourly_rate: contractor.hourly_rate,
      wage_type: contractor.wage_type,
      // Not edited but used for validation
      type: contractor.type,
    },
    validationSchema,
    onSubmit: async (values) => {
      const res = await putUpdateContractor(contractor.uuid, {
        version: contractor.version,
        ...values,
      })(dispatch)

      if (res) {
        closeModal()
      }
    },
  })

  const isIndividual = contractor.type === 'Individual'

  return (
    <Modal open size="tiny" closeIcon onClose={closeModal}>
      <Modal.Header>Update Details</Modal.Header>
      <Modal.Content>
        <FormikProvider value={formik}>
          <Grid>
            <GridRowColumn>
              <Text>
                This information is used to report the contractor’s taxes to the
                government. Please make sure it is accurate.
              </Text>
            </GridRowColumn>
            <PayrollError fetchKey={PUT_UPDATE_CONTRACTOR_KEY} />
            {isIndividual && (
              <GridRowColumn>
                <FormikInput name="first_name" label="First name" required />
              </GridRowColumn>
            )}
            {isIndividual && (
              <GridRowColumn>
                <FormikInput name="last_name" required label="Last name" />
              </GridRowColumn>
            )}
            {!isIndividual && (
              <GridRowColumn>
                <FormikInput
                  name="business_name"
                  required
                  label="Business name"
                />
              </GridRowColumn>
            )}
            {!isIndividual && (
              <GridRowColumn>
                <FormikInput name="ein" required label="Ein" />
              </GridRowColumn>
            )}
            <GridRowColumn>
              <FormikDropdown
                optionValues={['Hourly', 'Fixed']}
                name="wage_type"
                required
                label="Wage Type"
              />
            </GridRowColumn>
            {formik.values.wage_type === 'Hourly' && (
              <GridRowColumn>
                <FormikInput
                  name="hourly_rate"
                  componentType="currency"
                  label="Hourly Rate"
                  required
                />
              </GridRowColumn>
            )}
            <GridRowColumn>
              <Button onClick={() => formik.handleSubmit()} fullWidth>
                Confirm
              </Button>
            </GridRowColumn>
            <GridRowColumn>
              <Button onClick={closeModal} variant="secondary" fullWidth>
                Cancel
              </Button>
            </GridRowColumn>
          </Grid>
        </FormikProvider>
      </Modal.Content>
    </Modal>
  )
}

const ContractorDetailsPanel = ({
  contractor,
}: {
  contractor: Gusto_Contractor
}) => {
  const [modalOpen, setIsModalOpen] = useState(false)

  return (
    <div className="gep-details-container">
      <Grid>
        <Grid.Row>
          <Grid.Column width={14}>
            <Text as="h2">Contractor Details</Text>
          </Grid.Column>
          <Grid.Column width={2}>
            <Button variant="link" onClick={() => setIsModalOpen(true)}>
              Edit
            </Button>
          </Grid.Column>
        </Grid.Row>
        <DetailsRow
          label="Full Name"
          value={getContractorName(contractor) || ''}
        />
        {contractor.type === 'Business' && (
          <DetailsRow label="Ein" value={contractor.ein || ''} />
        )}
        <DetailsRow label="Contractor Type" value={contractor.type} />
        <DetailsRow label="Wage Type" value={contractor.wage_type} />
        {contractor.wage_type === 'Hourly' && (
          <DetailsRow
            label="Hourly Rate"
            value={formatCurrency(contractor.hourly_rate)}
          />
        )}
        <DetailsRow label="Email" value={contractor.email} />
        <DetailsRow
          label="Contractor Status"
          value={contractor.is_active ? 'Active' : 'Inactive'}
        />
      </Grid>
      {modalOpen && (
        <UpdateContractorModal
          contractor={contractor}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
    </div>
  )
}

const ContractorPayments = ({
  payments,
  goToPayments,
}: {
  payments: Gusto_ContractorPayment[]
  goToPayments: () => void
}) => (
  <div className="gep-details-container">
    <Grid>
      <GridRowColumn>
        <Text as="h2">Payments</Text>
      </GridRowColumn>
      <GridRowColumn>
        {payments.slice(0, 5).map((payment) => (
          <Text key={payment.date}>
            {moment(payment.date).format(DATE_FORMATS.DISPLAY_LONG)} -{' '}
            {formatCurrency(payment.wage_total)}
          </Text>
        ))}
      </GridRowColumn>
      {Boolean(payments.length) && (
        <GridRowColumn width={6}>
          <Button fullWidth onClick={goToPayments}>
            View All
          </Button>
        </GridRowColumn>
      )}
    </Grid>
  </div>
)

const ContractorDetailsTab = ({
  contractor,
  payments,
  goToPayments,
}: {
  contractor: Gusto_Contractor
  payments: Gusto_ContractorPayment[]
  goToPayments: () => void
}) => (
  <Grid>
    <Grid.Row>
      <Grid.Column width={8}>
        <ContractorDetailsPanel contractor={contractor} />
      </Grid.Column>
      <Grid.Column width={8}>
        <ContractorPayments payments={payments} goToPayments={goToPayments} />
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

export default ContractorDetailsTab
