import moment from 'moment-timezone'
import { flatten, range, times } from 'lodash'

export enum UploadDocumentType {
  STATEMENT = 'statement',
  TAX = 'tax',
  INCORPORATION = 'incorporation',
  RECEIPT = 'receipt',
  OTHER = 'other',
  BOOKKEEPING = 'bookkeeping',
}

import { DATE_FORMATS } from '../utils/dateHelpers'

const CURRENT_YEAR = moment().year()
export const DEFAULT_YEAR = CURRENT_YEAR.toString()
export const DEFAULT_START_DATE = moment().year(CURRENT_YEAR).startOf('year')

export const DEFAULT_END_DATE = moment().year(CURRENT_YEAR).endOf('year')

const YEARS_TO_SHOW = range(2020, CURRENT_YEAR + 1)

export enum TosVersion {
  tos_02_2022 = 'tos_02_2022',
  tos_01_2024 = 'tos_01_2024',
}

export const YEAR_OPTIONS = [
  ...YEARS_TO_SHOW.map((year) => ({
    text: year.toString(),
    value: year.toString(),
  })),
  {
    text: 'All Time',
    value: 'all',
  },
]

export const QUARTER_PNL_OPTIONS = flatten(
  YEARS_TO_SHOW.reverse().map((year) =>
    range(1, 5).map((quarter) => ({
      value: `${year} Q${quarter}`,
      startDate: moment()
        .year(year)
        .quarter(quarter)
        .startOf('quarter')
        .format(DATE_FORMATS.INPUT),
      endDate: moment()
        .year(year)
        .quarter(quarter)
        .endOf('quarter')
        .format(DATE_FORMATS.INPUT),
    }))
  )
)

export const YEARLY_PNL_OPTIONS = YEARS_TO_SHOW.map((year) => ({
  value: year,
  startDate: moment().year(year).startOf('year').format(DATE_FORMATS.INPUT),
  endDate: moment().year(year).endOf('year').format(DATE_FORMATS.INPUT),
}))

export const SCHEDULE_CALL_URL =
  'https://calendly.com/heardaccounting/heard-client-check-in'
export const HEARD_PROFILE_IMAGE_URL =
  'https://pbs.twimg.com/profile_images/1361356151288340483/CL1qp8es_400x400.png'

export const generateMonthlyStatementOptions = () =>
  times(
    Math.ceil(
      moment
        .duration(
          moment().endOf('month').diff(moment().year(2019).startOf('year'))
        )
        .asMonths()
    )
  ).map((i) => ({
    text: moment().subtract(i, 'months').format('YYYY-MM'),
    value: moment().subtract(i, 'months').format('YYYY-MM'),
  }))

export const USER_DOCUMENT_TYPES_OPTIONS = [
  UploadDocumentType.TAX,
  UploadDocumentType.STATEMENT,
  UploadDocumentType.OTHER,
  UploadDocumentType.INCORPORATION,
  UploadDocumentType.RECEIPT,
  UploadDocumentType.BOOKKEEPING,
].map((value) => ({ text: value[0].toUpperCase() + value.slice(1), value }))

export const BOOKKEEPING_REPORT_STATUSES = {
  Done: {
    name: 'done',
  },
  Reconciled: {
    name: 'reconciled',
  },
  InProgress: {
    name: 'in_progress',
  },
  Categorized: {
    name: 'categorized',
  },
  NotStarted: {
    name: 'not_started',
  },
  MissingInfo: {
    name: 'missing_info',
  },
  PlaidError: {
    name: 'plaid_error',
  },
}

export const INSTITUTIONS_MAP = {
  WELLS_FARGO: 'ins_127991',
  CHASE: 'ins_56',
}

// List of institutions that are known to be error-prone
export const PROBLEMATIC_INSTITUTIONS = [
  'ins_14', // TD Bank
  'ins_127296', // Bluevine
  'ins_115674', // FirstBank (Colorado)
  'ins_110438', // GreenState Credit Union
  'ins_116225', // Webster Bank
  'ins_120013', // America First Credit Union
  'ins_113059', // Logix Credit Union
]

export const healthyFinancialInstitutionIds = [
  'ins_1',
  'ins_10',
  'ins_100635',
  'ins_100742',
  'ins_100864',
  'ins_100870',
  'ins_101551',
  'ins_101559',
  'ins_101560',
  'ins_101561',
  'ins_102552',
  'ins_103544',
  'ins_103548',
  'ins_105483',
  'ins_105496',
  'ins_105499',
  'ins_105846',
  'ins_106296',
  'ins_109532',
  'ins_109533',
  'ins_109535',
  'ins_109564',
  'ins_109578',
  'ins_109590',
  'ins_109643',
  'ins_109675',
  'ins_109682',
  'ins_109691',
  'ins_109701',
  'ins_109707',
  'ins_109763',
  'ins_109778',
  'ins_109781',
  'ins_109862',
  'ins_109867',
  'ins_109911',
  'ins_109913',
  'ins_109917',
  'ins_109930',
  'ins_109933',
  'ins_109971',
  'ins_110004',
  'ins_110019',
  'ins_110422',
  'ins_110427',
  'ins_110430',
  'ins_110476',
  'ins_110482',
  'ins_110501',
  'ins_110503',
  'ins_110549',
  'ins_110602',
  'ins_110690',
  'ins_110728',
  'ins_110835',
  'ins_111007',
  'ins_111013',
  'ins_111014',
  'ins_111037',
  'ins_111302',
  'ins_111607',
  'ins_111796',
  'ins_111902',
  'ins_113113',
  'ins_113598',
  'ins_113779',
  'ins_113790',
  'ins_113800',
  'ins_113801',
  'ins_113862',
  'ins_113976',
  'ins_114034',
  'ins_114047',
  'ins_114066',
  'ins_114149',
  'ins_114213',
  'ins_114454',
  'ins_114506',
  'ins_114664',
  'ins_114700',
  'ins_115291',
  'ins_115563',
  'ins_115581',
  'ins_115585',
  'ins_115599',
  'ins_115652',
  'ins_115674',
  'ins_115686',
  'ins_116196',
  'ins_116224',
  'ins_116243',
  'ins_116282',
  'ins_116294',
  'ins_116314',
  'ins_116354',
  'ins_116418',
  'ins_116497',
  'ins_116584',
  'ins_116594',
  'ins_116619',
  'ins_116627',
  'ins_116725',
  'ins_116754',
  'ins_116756',
  'ins_116758',
  'ins_116760',
  'ins_116794',
  'ins_116823',
  'ins_116954',
  'ins_117026',
  'ins_117048',
  'ins_117127',
  'ins_117193',
  'ins_117219',
  'ins_117228',
  'ins_117261',
  'ins_117701',
  'ins_118119',
  'ins_119270',
  'ins_119465',
  'ins_119491',
  'ins_119532',
  'ins_119658',
  'ins_120000',
  'ins_120003',
  'ins_120016',
  'ins_120020',
  'ins_120026',
  'ins_120033',
  'ins_120036',
  'ins_120050',
  'ins_120073',
  'ins_120078',
  'ins_120218',
  'ins_120227',
  'ins_120334',
  'ins_120406',
  'ins_121816',
  'ins_122133',
  'ins_123314',
  'ins_123432',
  'ins_124690',
  'ins_126237',
  'ins_126321',
  'ins_126729',
  'ins_127171',
  'ins_127291',
  'ins_127981',
  'ins_127989',
  'ins_127990',
  'ins_127991',
  'ins_128007',
  'ins_128822',
  'ins_128828',
  'ins_129525',
  'ins_129909',
  'ins_13',
  'ins_130973',
  'ins_131846',
  'ins_131856',
  'ins_131862',
  'ins_132102',
  'ins_132652',
  'ins_132701',
  'ins_132925',
  'ins_132941',
  'ins_132970',
  'ins_133215',
  'ins_133394',
  'ins_133396',
  'ins_133507',
  'ins_134593',
  'ins_135993',
  'ins_137007',
  'ins_14',
  'ins_25',
  'ins_26',
  'ins_27',
  'ins_29',
  'ins_3',
  'ins_32',
  'ins_4',
  'ins_56',
]
