import moment from 'moment'
import { useEffect } from 'react'
import { Container, Divider, Message } from 'semantic-ui-react'
import {
  Accordion,
  Link,
  Loader,
  Text,
} from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import { useReselector } from '../../../utils/sharedHooks'
import {
  fetchQAFeaturesIfNeeded,
  FETCH_QA_FEATURES_KEY,
  Pathway,
} from './qaFeatures.slice'
import { getFetchError } from '../../../reducers/fetch'
import { selectQAFeatures } from './qaFeatures.selectors'
import { selectQAUsersRecentlyActiveFirst } from './qaUsers.selectors'
import { fetchQAUsers, FETCH_QA_USERS_KEY, QAUser } from './qaUsers.slice'
import UserTable from './components/UserTable'
import PathwayTable from './components/PathwayTable'
import { useAppDispatch } from '../../../utils/typeHelpers'

const AccordionTitle = ({
  name,
  createdAt,
  description,
  linearCode,
}: {
  name: string
  createdAt: string
  description: string
  linearCode: string
}) => (
  <div>
    <Text as="h2">{name}</Text>
    <Text>
      <Link newPage href={`https://linear.app/heard/issue/${linearCode}`}>
        {linearCode}
      </Link>
      {` - ${description}`}
    </Text>
    <Text style={{ position: 'absolute', right: '3.5em', top: 20 }}>
      {moment(createdAt).fromNow()}
    </Text>
  </div>
)

const containerStyle = {
  backgroundColor: Colors.stone40,
  padding: 24,
}

const AccordionContent = ({
  pathways,
  users,
}: {
  pathways: Pathway[]
  users: QAUser[]
}) => (
  <>
    <Container style={containerStyle}>
      <Text style={{ display: 'block' }} as="h3">
        Available Paths - {pathways.length}
      </Text>
      <PathwayTable pathways={pathways} />
    </Container>
    <Container style={containerStyle}>
      <Text style={{ display: 'block' }} as="h3">
        QA Users - {users.length}
      </Text>
      <UserTable users={users} />
    </Container>
  </>
)

const QAPanel = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchQAFeaturesIfNeeded())
    dispatch(fetchQAUsers())
  }, [dispatch])

  const fetchFeaturesError = useReselector(getFetchError, FETCH_QA_FEATURES_KEY)
  const fetchUsersError = useReselector(getFetchError, FETCH_QA_FEATURES_KEY)
  const features = useReselector(selectQAFeatures)
  const users = useReselector(selectQAUsersRecentlyActiveFirst)

  return (
    <Container>
      <Text as="h2">Available features</Text>
      <Divider />
      <Loader loading fetchKey={FETCH_QA_FEATURES_KEY} />
      <Loader loading fetchKey={FETCH_QA_USERS_KEY} />
      {(fetchFeaturesError || fetchUsersError) && (
        <Message
          error
          header="Something went wrong"
          content="There was an error loading features or users. Please try refreshing the page"
        />
      )}
      {features?.map((feature) => (
        <Accordion
          key={`feature-${feature.linearCode}`}
          contentStyle={{ marginTop: '-1em' }}
          style={{
            backgroundColor: Colors.white,
            marginTop: 10,
            padding: '10px 0px',
          }}
          title={<AccordionTitle {...feature} />}
          content={
            <AccordionContent users={users} pathways={feature.pathways} />
          }
        />
      ))}
    </Container>
  )
}

export default QAPanel
