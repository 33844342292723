import { useEffect, useMemo } from 'react'
import { Container } from 'semantic-ui-react'

import { useReselector } from '../../../utils/sharedHooks'
import { getCurrentUser } from '../../../selectors/user.selectors'
import WelcomeToPayroll from './components/WelcomeToPayroll'
import { PayrollSetup } from '../../../reducers/auth/userReducer'
import OtherProviderDetails from './components/OtherProviderDetails'
import GustoProviderDetails from './components/GustoProviderDetails'
import { Card } from '../../../components/BaseComponents'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { fetchPayrollProfileIfNeeded } from '../payrollActions'
import {
  selectShouldShowPayrollConfirmationPage,
  selectShouldShowPayrollWelcomePage,
} from '../payroll.selectors'
import { useAppDispatch } from '../../../utils/typeHelpers'

const PayrollUpsell = () => {
  const currentUser = useReselector(getCurrentUser)
  const dispatch = useAppDispatch()
  const shouldShowPayrollWelcomePage = useReselector(
    selectShouldShowPayrollWelcomePage
  )
  const shouldShowPayrollConfirmationPage = useReselector(
    selectShouldShowPayrollConfirmationPage
  )

  useEffect(() => {
    dispatch(fetchPayrollProfileIfNeeded())
  }, [dispatch])

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const confirmationContent = useMemo(() => {
    if (!currentUser?.id) {
      return null
    }

    switch (currentUser.payrollSetup) {
      case PayrollSetup.other:
      case PayrollSetup.needsPayroll:
        return <OtherProviderDetails />
      case PayrollSetup.gusto:
        return <GustoProviderDetails />
      case PayrollSetup.heard:
      case PayrollSetup.unknown:
      case PayrollSetup.none:
      case undefined:
        return null
      default:
        return currentUser?.payrollSetup satisfies never
    }
  }, [currentUser?.id, currentUser?.payrollSetup])

  return (
    <Container>
      <Card
        style={{ ...(isMobile ? {} : { paddingTop: 70, paddingBottom: 70 }) }}
      >
        {shouldShowPayrollWelcomePage && <WelcomeToPayroll />}
        {shouldShowPayrollConfirmationPage && confirmationContent}
      </Card>
    </Container>
  )
}

export default PayrollUpsell
