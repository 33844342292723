import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Legend,
} from 'recharts'
import moment from 'moment'

import {
  fetchExpensesSum,
  fetchIncomesSum,
} from '../../../actions/reportActions'
import {
  DEFAULT_YEAR,
  YEAR_OPTIONS,
} from '../../../constants/businessConstants'
import { Card, Dropdown, Text } from '../../BaseComponents'
import { Colors, Fonts } from '../../../styles/theme'
import { usePrevious } from '../../../utils/sharedHooks'
import { Divider } from 'semantic-ui-react'
import CurrencyFormatLabel from '../../shared/CurrencyFormatLabel'
import {
  dollarsToCurrency,
  formatCurrency,
} from '../../../utils/currencyHelpers'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { useAnalyticsTrack } from '../../../features/Amplitude'

const labelFormatter = (value: string) => {
  return `$${Number(value).toFixed(2)}`
}

const generateMonths = () => {
  const earliest = moment('January 2020', DATE_FORMATS.MONTH_YEAR)
  const latest = moment()
  const interim = earliest.clone()
  const timeValues = []
  while (latest > interim) {
    timeValues.push({
      text: interim.format(DATE_FORMATS.MONTH_YEAR),
      value: interim.format(DATE_FORMATS.MONTH_YEAR),
    })
    interim.add(1, 'month')
  }
  return timeValues
}

const getInitialMonth = (currentMonth: string | undefined) =>
  currentMonth ? moment(currentMonth, 'MMMM YYYY') : moment().startOf('month')

interface Props {
  currentMonth?: string
  dropdownDisabled?: boolean
  monthView?: boolean
  narrow?: boolean
}

const CashflowBarGraph = ({
  currentMonth,
  dropdownDisabled,
  monthView,
  narrow,
}: Props) => {
  const prevCurrentMonth = usePrevious(currentMonth)

  const [month, setMonth] = useState(getInitialMonth(currentMonth))
  const [year, setYear] = useState(DEFAULT_YEAR)
  const [income, setIncome] = useState(0)
  const [expenses, setExpenses] = useState(0)

  const months = useMemo(generateMonths, [])

  useEffect(() => {
    const fetchData = async () => {
      const params = monthView ? { month } : { year }

      const expenses = await fetchExpensesSum(params)()
      setExpenses(expenses?.sum || 0)
      const income = await fetchIncomesSum(params)()
      setIncome(income?.sum || 0)
    }

    fetchData()
  }, [month, monthView, year])

  useEffect(() => {
    if (prevCurrentMonth !== currentMonth) {
      setMonth(getInitialMonth(currentMonth))
    }
  }, [prevCurrentMonth, currentMonth])

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const track = useAnalyticsTrack()

  const handleDropdownChange = useCallback(
    (value: string) => {
      const date = moment(value, DATE_FORMATS.MONTH_YEAR)
      if (monthView) {
        setMonth(date)
      } else {
        setYear(value)
      }
      track('clicked annual bk report year', {
        graph_name: 'Annual Cash Summary',
        report_year: value,
      })
    },
    [monthView, track]
  )

  const header = useMemo(
    () => (
      <div
        {...(!narrow &&
          !isMobile && {
            style: {
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            },
          })}
      >
        {isMobile && !dropdownDisabled && (
          <Dropdown
            options={monthView ? months : YEAR_OPTIONS}
            onChange={(value) => handleDropdownChange(value)}
            scrolling={monthView}
            value={monthView ? month.format(DATE_FORMATS.MONTH_YEAR) : year}
            variant="text"
          />
        )}
        <Text as="h2" style={{ marginBottom: 4 }}>
          {monthView ? 'Monthly' : 'Annual'} Cash Summary
        </Text>
        {!isMobile && !dropdownDisabled && (
          <Dropdown
            options={monthView ? months : YEAR_OPTIONS}
            onChange={(value) => handleDropdownChange(value)}
            scrolling={monthView}
            value={monthView ? month.format(DATE_FORMATS.MONTH_YEAR) : year}
            variant="text"
          />
        )}
      </div>
    ),
    [
      dropdownDisabled,
      narrow,
      month,
      monthView,
      months,
      year,
      isMobile,
      handleDropdownChange,
    ]
  )

  const profitValue = useMemo(
    () => formatCurrency(dollarsToCurrency(expenses).add(income)),
    [expenses, income]
  )

  return (
    <Card
      fullWidth
      className={`dropdownDisabled ${dropdownDisabled ? 'shadowed' : ''}`}
      type="subsection"
      backgroundColor="stone40"
    >
      {header}
      <Divider hidden />
      <Text>Profit</Text>
      <Text as="h1">
        <CurrencyFormatLabel value={profitValue} />
      </Text>
      <ResponsiveContainer width="100%" height={150}>
        <BarChart
          data={[
            {
              name: '',
              expenses: Math.abs(expenses) || 0,
              income: income || 0,
            },
          ]}
          margin={{ top: 0, right: 80, left: 0, bottom: 0 }}
          layout="vertical"
        >
          <XAxis type="number" hide />
          <YAxis tickLine={false} type="category" dataKey="name" hide />
          <Bar
            dataKey="income"
            fill={Colors.green}
            name="Income"
            label={{
              position: 'right',
              formatter: labelFormatter,
              // For whatever reason, the `fontFamily` property isn't being passed through
              // when we just assign `Fonts.bodySm` to `style` or even spread it, so we have
              // to explicitly define it in the definition here and below.
              style: { ...Fonts.bodySm, fontFamily: 'Inter' },
            }}
          />
          <Bar
            dataKey="expenses"
            fill={Colors.oak}
            name="Expenses"
            label={{
              position: 'right',
              formatter: labelFormatter,
              style: { ...Fonts.bodySm, fontFamily: 'Inter' },
            }}
          />
          <Legend align="left" iconType="square" layout="horizontal" />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  )
}

export default CashflowBarGraph
