import { List } from 'semantic-ui-react'
import { Fragment, useMemo } from 'react'
import { Accordion, Text } from '../BaseComponents'
import { MissingStatement } from '../../features/UserDocuments/userDocuments.slice'
import { isoToUTCDateTime, DATE_FORMATS_LUXON } from '../../utils/dateHelpers'

export const useViewMissingStatementsList = ({
  neededStatements,
}: {
  neededStatements?: MissingStatement[]
}) =>
  useMemo(
    () =>
      neededStatements?.map(
        ({ financial_institution_name, name, mask, statements }) => {
          const accountText = `${financial_institution_name} ${name}: ${mask}`
          return (
            <Fragment key={accountText}>
              <Text as="h3">{accountText}</Text>
              <List
                bulleted
                items={statements.map((statement) => (
                  <List.Item key={`${accountText} ${statement}`}>
                    <Text>
                      {isoToUTCDateTime(statement).toFormat(
                        DATE_FORMATS_LUXON.MONTH_YEAR_LONG
                      )}
                    </Text>
                  </List.Item>
                ))}
              />
            </Fragment>
          )
        }
      ),
    [neededStatements]
  )

const ViewMissingStatementsAccordion = ({
  neededStatements,
  title,
}: {
  neededStatements?: MissingStatement[]
  title: string
}) => {
  const formattedStatements = useViewMissingStatementsList({ neededStatements })
  if (!formattedStatements || formattedStatements.length === 0) {
    return null
  }
  return (
    <Accordion title={title} content={formattedStatements} variant="text" />
  )
}

export default ViewMissingStatementsAccordion
