import { CONTRACTOR_PAYROLL_STEPS } from '../../helpers'
import { ProgressBar, Text } from '../../../../components/BaseComponents'

const ContractorPayrollHeader = ({ currentTab }: { currentTab: number }) => (
  <>
    <Text as="h2">Pay Contractors</Text>
    <ProgressBar currentStep={currentTab} steps={CONTRACTOR_PAYROLL_STEPS} />
  </>
)

export default ContractorPayrollHeader
