import { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'

import { useAppDispatch } from '../../../utils/typeHelpers'
import { selectEmployeeByUuid } from '../payroll.selectors'
import { fetchAllEmployeeForms } from '../payrollActions'
import GustoFormsTable from '../PayrollDocuments/GustoFormsTable'
import { Text } from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { Gusto_GustoForm } from '../generated_gusto_types'

const EmployeeDocumentsTab = ({ uuid }: { uuid: string }) => {
  const dispatch = useAppDispatch()
  const employee = useReselector(selectEmployeeByUuid, uuid)

  const [forms, setForms] = useState<Gusto_GustoForm[]>([])

  useEffect(() => {
    const fetchForms = async () => {
      const res = await fetchAllEmployeeForms(uuid)(dispatch)
      if (res) {
        setForms(res)
      }
    }

    fetchForms()
  }, [dispatch, uuid])

  if (!employee) {
    return null
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Text as="h2">Employee Documents</Text>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="short">
        <Grid.Column>
          <Text>
            These are documents shared between you and{' '}
            <b>
              {employee.first_name} {employee.last_name}
            </b>
          </Text>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <GustoFormsTable forms={forms} employeeUuid={uuid} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default EmployeeDocumentsTab
