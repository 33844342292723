import {
  CSSProperties,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Dropdown, Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

// BL
import { UPLOAD_COPY } from '../../copyConstants'
import { UploadScreenProps } from './index'
import { getUserBooksLockedForUserYear } from '../../../../reducers/auth/user.selectors'

// UI
import UploadMessage from '../UploadMessage'
import LockedBooksMessage from '../../LockedBooksMessage'
import {
  Button,
  Text,
  Modal,
  Icon,
  GridRowColumn,
} from '../../../../components/BaseComponents'
import { SeparateStep } from '../../../Onboarding/SeparateTransactions'
import { getSpreadsheetRowErrors, processCsv } from './helpers'
import { useReselector } from '../../../../utils/sharedHooks'

export interface UploadCSVErrorModel {
  title: string
  body: Array<string>
}

export const EMPTY_TRANSACTION_CSV_DOWNLOAD =
  'https://docs.google.com/spreadsheets/d/1N-DJkEwMO-z_ucebhRKnZjnMOv2IEzu2TAfsn9nVmQI/export?format=csv'
export const EMPTY_TRANSACTION_CSV_COPY =
  'https://docs.google.com/spreadsheets/d/1N-DJkEwMO-z_ucebhRKnZjnMOv2IEzu2TAfsn9nVmQI/copy'

export const generateValidationErrorModel = ({
  errors,
}: {
  errors: string[]
}) => ({
  title: 'An error occurred when attempting to record your transactions!',
  body: errors,
})

export const ValidationErrorMessageContainer = ({
  error,
  style,
}: {
  error: UploadCSVErrorModel
  style?: CSSProperties
}) => (
  <div style={style}>
    <UploadMessage success={false} content={error.title} />
    <span>
      <ul style={{ width: '80%', margin: '1em auto', textAlign: 'left' }}>
        {error.body.map((e) => (
          <li key={`csv-upload-error-${e}`}>
            <Text>{e}</Text>
          </li>
        ))}
      </ul>
    </span>
  </div>
)

const UploadCSVPanel = ({ goToScreenWithData }: UploadScreenProps) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<UploadCSVErrorModel | null>(null)
  const [selectedFile, setSelectedFile] = useState<File>()

  const uploadFileRef = useRef<HTMLInputElement>(null)

  /**
   * Validate spreadsheet structure only on the client. Cell data not validated on this screen
   *
   * 1. Do 2+ rows exists?
   * 2. Does each row have exactly 3 columns?
   * 3. Does the first row have correct header names?
   */
  const validateSpreadsheetRows = (rows: string[][]) => {
    const errors = getSpreadsheetRowErrors(rows)

    if (errors.length > 0) {
      const model = generateValidationErrorModel({ errors })
      setError(model)
    } else {
      setError(null)
    }
    return errors.length === 0
  }

  useEffect(() => {
    // Extract data, validate it, navigate to review screen
    const processData = (csv: string) => {
      const rows = processCsv(csv)
      const valid = validateSpreadsheetRows(rows)

      if (valid) {
        // remove header row
        rows.splice(0, 1)

        const models = rows.map((r) => ({
          date: r[0],
          description: r[1],
          amountInDollars: r[2],
        }))

        goToScreenWithData({
          index: 2,
          data: { models, spreadsheetFile: selectedFile },
        })
      }

      setLoading(false)
    }

    const fileLoaded = (e: ProgressEvent<FileReader>) => {
      if (e.target) {
        const csv = e.target.result
        if (typeof csv === 'string') {
          processData(csv)
        }
      }
    }

    const fileError = (e: ProgressEvent<FileReader>) => {
      const errors = []

      if (e.target?.error?.name === 'NotReadableError') {
        errors.push('The file is not readable')
      } else {
        errors.push(`An error occurred. ${e.target?.error?.message || ''}`)
      }

      const model = generateValidationErrorModel({ errors })
      setError(model)
    }

    const getFileAsText = (file: File) => {
      const reader = new FileReader()
      // Read into memory UTF-8
      reader.readAsText(file)
      reader.onload = fileLoaded
      reader.onerror = fileError
    }
    if (selectedFile) {
      setLoading(true)
      getFileAsText(selectedFile)
    }
  }, [selectedFile, goToScreenWithData])

  const onGoogleDriveClick = (e: SyntheticEvent) => {
    e.preventDefault()
    window.open(EMPTY_TRANSACTION_CSV_COPY, '_blank')?.focus()
    return false
  }

  const onDownloadFileClick = (e: SyntheticEvent) => {
    e.preventDefault()
    window.open(EMPTY_TRANSACTION_CSV_DOWNLOAD, '_blank')?.focus()
    return false
  }

  return (
    <>
      <SeparateStep
        stepNumber="1"
        title="Download transaction spreadsheet"
        content={
          <Dropdown
            disabled={loading}
            pointing
            className="primary-pointing-button"
            text="Download Template"
            style={{ marginTop: 10 }}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                style={{ borderBottom: '1px solid #eaeaea' }}
                onClick={onDownloadFileClick}
                text="Download a file"
                description="To edit it on your computer"
              />
              <Dropdown.Item
                onClick={onGoogleDriveClick}
                text="Copy to Google Drive"
                description="To edit the file online"
              />
            </Dropdown.Menu>
          </Dropdown>
        }
        icon={regular('download')}
      />
      <SeparateStep
        stepNumber="2"
        title="Enter your transactions in the spreadsheet"
        content={
          <ul>
            <li>
              <Text>
                Fill in your transactions dating from January 2023 to the
                current date
              </Text>
            </li>
            <li>
              <Text>Use this format: date, description, dollar amount</Text>
            </li>
            <li>
              <Text>
                Mark <b>(+) for income/deposit</b> or <b>(-) for expense</b>{' '}
                next to the dollar amount
              </Text>
            </li>
            <li>
              <Text>
                Refer to the <b>Instructions</b> tab for more details{' '}
              </Text>
            </li>
            <li>
              <Text>
                Save this file as a <b>.csv</b>
              </Text>
            </li>
          </ul>
        }
        icon={regular('file-spreadsheet')}
      />
      <SeparateStep
        stepNumber="3"
        title="Upload the completed spreadsheet here"
        content={
          <>
            <Button
              style={{ marginTop: 10 }}
              disabled={loading}
              onClick={() => uploadFileRef.current?.click()}
            >
              <Icon icon={regular('upload')} style={{ marginRight: 6 }} />
              Upload Spreadsheet
            </Button>

            <input
              ref={uploadFileRef}
              accept=".csv"
              type="file"
              hidden
              onChange={(e) => {
                if (e.target.files) {
                  setSelectedFile(e.target.files[0])
                }
              }}
            />
            {error && (
              <ValidationErrorMessageContainer
                style={{ margin: '1em 1em 0 1em', width: '100%' }}
                error={error}
              />
            )}
          </>
        }
        icon={regular('cloud-arrow-up')}
      />
    </>
  )
}

export const UploadCSVModal = (props: UploadScreenProps) => {
  const booksLockedForUserYear = useReselector(getUserBooksLockedForUserYear)

  return (
    <>
      <Modal.Header>{UPLOAD_COPY.GENERIC_SCREEN_TITLE}</Modal.Header>

      <Modal.Content>
        <Grid>
          {booksLockedForUserYear && (
            <GridRowColumn>
              <LockedBooksMessage year={booksLockedForUserYear} />
            </GridRowColumn>
          )}
          <UploadCSVPanel {...props} />
        </Grid>
      </Modal.Content>
    </>
  )
}
