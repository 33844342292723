export const STATES = [
  { text: 'AL', value: 'AL' },
  { text: 'AK', value: 'AK' },
  { text: 'AZ', value: 'AZ' },
  { text: 'AR', value: 'AR' },
  { text: 'CA', value: 'CA' },
  { text: 'CO', value: 'CO' },
  { text: 'CT', value: 'CT' },
  { text: 'DE', value: 'DE' },
  { text: 'FL', value: 'FL' },
  { text: 'GA', value: 'GA' },
  { text: 'HI', value: 'HI' },
  { text: 'ID', value: 'ID' },
  { text: 'IL', value: 'IL' },
  { text: 'IN', value: 'IN' },
  { text: 'IA', value: 'IA' },
  { text: 'KS', value: 'KS' },
  { text: 'KY', value: 'KY' },
  { text: 'LA', value: 'LA' },
  { text: 'ME', value: 'ME' },
  { text: 'MD', value: 'MD' },
  { text: 'MA', value: 'MA' },
  { text: 'MI', value: 'MI' },
  { text: 'MN', value: 'MN' },
  { text: 'MS', value: 'MS' },
  { text: 'MO', value: 'MO' },
  { text: 'MT', value: 'MT' },
  { text: 'NE', value: 'NE' },
  { text: 'NV', value: 'NV' },
  { text: 'NH', value: 'NH' },
  { text: 'NJ', value: 'NJ' },
  { text: 'NM', value: 'NM' },
  { text: 'NY', value: 'NY' },
  { text: 'NC', value: 'NC' },
  { text: 'ND', value: 'ND' },
  { text: 'OH', value: 'OH' },
  { text: 'OK', value: 'OK' },
  { text: 'OR', value: 'OR' },
  { text: 'PA', value: 'PA' },
  { text: 'RI', value: 'RI' },
  { text: 'SC', value: 'SC' },
  { text: 'SD', value: 'SD' },
  { text: 'TN', value: 'TN' },
  { text: 'TX', value: 'TX' },
  { text: 'UT', value: 'UT' },
  { text: 'VT', value: 'VT' },
  { text: 'VA', value: 'VA' },
  { text: 'WA', value: 'WA' },
  { text: 'WV', value: 'WV' },
  { text: 'WI', value: 'WI' },
  { text: 'WY', value: 'WY' },
  { text: 'DC', value: 'DC' },
  { text: 'MH', value: 'MH' },
  { text: 'AA', value: 'AA' },
  { text: 'AE', value: 'AE' },
  { text: 'AP', value: 'AP' },
  { text: 'Unsure', value: 'Unsure' },
]

export const STATES_LONG = [
  { text: 'Alabama', value: 'AL' },
  { text: 'Alaska', value: 'AK' },
  { text: 'Arizona', value: 'AZ' },
  { text: 'Arkansas', value: 'AR' },
  { text: 'California', value: 'CA' },
  { text: 'Colorado', value: 'CO' },
  { text: 'Connecticut', value: 'CT' },
  { text: 'Delaware', value: 'DE' },
  { text: 'Florida', value: 'FL' },
  { text: 'Georgia', value: 'GA' },
  { text: 'Hawaii', value: 'HI' },
  { text: 'Idaho', value: 'ID' },
  { text: 'Illinois', value: 'IL' },
  { text: 'Indiana', value: 'IN' },
  { text: 'Iowa', value: 'IA' },
  { text: 'Kansas', value: 'KS' },
  { text: 'Kentucky', value: 'KY' },
  { text: 'Louisiana', value: 'LA' },
  { text: 'Maine', value: 'ME' },
  { text: 'Maryland', value: 'MD' },
  { text: 'Massachusetts', value: 'MA' },
  { text: 'Michigan', value: 'MI' },
  { text: 'Minnesota', value: 'MN' },
  { text: 'Mississippi', value: 'MS' },
  { text: 'Missouri', value: 'MO' },
  { text: 'Montana', value: 'MT' },
  { text: 'Nebraska', value: 'NE' },
  { text: 'Nevada', value: 'NV' },
  { text: 'New Hampshire', value: 'NH' },
  { text: 'New Jersey', value: 'NJ' },
  { text: 'New Mexico', value: 'NM' },
  { text: 'New York', value: 'NY' },
  { text: 'North Carolina', value: 'NC' },
  { text: 'North Dakota', value: 'ND' },
  { text: 'Ohio', value: 'OH' },
  { text: 'Oklahoma', value: 'OK' },
  { text: 'Oregon', value: 'OR' },
  { text: 'Pennsylvania', value: 'PA' },
  { text: 'Rhode Island', value: 'RI' },
  { text: 'South Carolina', value: 'SC' },
  { text: 'South Dakota', value: 'SD' },
  { text: 'Tennessee', value: 'TN' },
  { text: 'Texas', value: 'TX' },
  { text: 'Utah', value: 'UT' },
  { text: 'Vermont', value: 'VT' },
  { text: 'Virginia', value: 'VA' },
  { text: 'Washington', value: 'WA' },
  { text: 'West Virginia', value: 'WV' },
  { text: 'Wisconsin', value: 'WI' },
  { text: 'Wyoming', value: 'WY' },
  { text: 'District of Columbia', value: 'DC' },
  { text: 'Marshall Islands', value: 'MH' },
  { text: 'Armed Forces the Americas', value: 'AA' },
  { text: 'Armed Forces Europe', value: 'AE' },
  { text: 'Armed Forces Pacific', value: 'AP' },
  { text: 'Unsure', value: 'Unsure' },
]

export const stateAbbToState = (abbreviation: string) =>
  STATES_LONG.find((state) => state.value === abbreviation)?.text ?? ''
