import { SemanticWIDTHS } from 'semantic-ui-react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Icon, Table } from '../../../../components/BaseComponents'

export interface TableHeaderConfig {
  sortBy: string | null
  title: string
  width?: SemanticWIDTHS
}

interface UserDocumentsTableHeaderProps {
  columns: TableHeaderConfig[]
  type: 'Primary' | 'Secondary' | 'Tertiary'
  onClick?: () => void
  onSort?: (sortBy: string | null) => void
  isOpen?: boolean
  sortVal?: string | null
  sortAsc?: boolean
}

const SortIcon = ({
  type,
  isOpen,
  sortVal,
  sortAsc,
  index,
  sortBy,
}: {
  type: 'Primary' | 'Secondary' | 'Tertiary'
  isOpen?: boolean
  sortVal?: string | null
  sortAsc?: boolean
  index: number
  sortBy: string | null
}) => {
  let icon
  if (type === 'Tertiary') {
    if (sortBy) {
      if (sortBy === sortVal) {
        icon = sortAsc ? solid('sort-up') : solid('sort-down')
      } else {
        icon = solid('sort')
      }
    }
  } else if (!index) {
    icon = isOpen ? solid('caret-down') : solid('caret-right')
  }

  return (
    (icon && <Icon icon={icon} style={{ paddingLeft: 10 }} size={'sm'} />) ||
    null
  )
}

const UserDocumentsTableHeader = ({
  onSort = () => {},
  onClick = () => {},
  columns,
  type,
  isOpen,
  sortVal,
  sortAsc,
}: UserDocumentsTableHeaderProps) => {
  return (
    <Table.Header
      className={`user-documents-table-header ${type.toLowerCase()}`}
      onClick={onClick}
    >
      <Table.Row>
        {columns.map(({ title, sortBy, width }, i) => (
          <Table.HeaderCell
            key={`table-header-${i}`}
            onClick={() => onSort(sortBy)}
            width={width}
          >
            {title}
            <SortIcon
              type={type}
              sortBy={sortBy}
              sortVal={sortVal}
              sortAsc={sortAsc}
              isOpen={isOpen}
              index={i}
            />
          </Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
  )
}

export default UserDocumentsTableHeader
