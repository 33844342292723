import axios from 'axios'
import { keyBy } from 'lodash'

import { fetchIfNeededWrapper } from '../reducers/fetch'
import {
  BookkeepingReport,
  receiveAllBookkeepingReports,
} from '../reducers/finances/bookkeepingReportsReducer'

export const FETCH_BOOKKEEPING_REPORT_KEY = 'FETCH_BOOKKEEPING_REPORT_KEY'
export const fetchBookkeepingReports = () =>
  fetchIfNeededWrapper({
    defaultErrorMessage: 'There was an error fetching all bookkeeping reports.',
    fetchKey: FETCH_BOOKKEEPING_REPORT_KEY,
    fetchFunction: (dispatch) =>
      axios
        .get<BookkeepingReport[]>('/finances/api/v1/bookkeeping_reports')
        .then((json) => {
          dispatch(receiveAllBookkeepingReports(keyBy(json.data, 'id')))
          return json.data
        }),
  })
