import { Colors } from '../../../styles/theme'

const expenseCategoryColorMap: {
  [identifier: string]: string
} = {
  accounting_fees: Colors.blue,
  advertising_and_marketing: Colors.navy,
  auto_related_expenses: Colors.purple,
  bank_service_charges: Colors.red,
  business_fees_licenses_permits: Colors.magenta,
  charitable_donation: Colors.orange,
  clinical_supervision_fees: Colors.yellow,
  commissions_fees: '#FFB13C',
  communication_expenses: Colors.accentGreen,
  continuing_education: Colors.teal,
  credit_card_merchant_fees: '#5A7EBF',
  debt_repayment: '#787BCC',
  depreciation_expense: '#5FB998',
  dues_subscriptions: '#A46ABF',
  equipment_furniture: '#720E44',
  gifts: Colors.black,
  guaranteed_payments: '#DB80F2',
  insurance_auto: '#CC5084',
  insurance_dental: '#B8BADE',
  insurance_general: '#8C0C0C',
  insurance_health: '#CC690E',
  insurance_life: '#BFA484',
  insurance_professional_liability: '#D95E4E',
  insurance_property: '#D3A3B4',
  interest_expense: '#BF8F12',
  internet: '#70BF54',
  legal_fees: '#48BFBF',
  maintenance_repair: '#3D71CC',
  meals_entertainment: '#5C60CC',
  office_expenses: '#943EB2',
  other_expenses: '#E74B4B',
  parking_tolls: '#E7409A',
  payroll_contractors: '#FF7A00',
  payroll_employee_benefits: '#FFC93F',
  payroll_expenses_fees: '#56A63A',
  payroll_payroll_taxes: '#00A691',
  payroll_reimbursable_expenses: '#778DFF',
  payroll_salaries_wages: '#3F45D3',
  payroll_suta_expense: '#FF9D9D',
  payroll_workers_compensation: '#CDA6FF',
  postage_freight: '#712197',
  professional_development: '#D1240D',
  professional_fees: '#AE2B99',
  psychotherapy_fees: '#F26234',
  rent_and_lease: '#7EC622',
  software_services: '#0F6A44',
  supplies: '#0F6A44',
  taxes_city_local_taxes: '#98D4E7',
  taxes_federal_taxes: '#A4BC9E',
  taxes_franchise_taxes: '#17A4D1',
  taxes_state_taxes: '#91E59E',
  testing_reference_materials: '#3764D6',
  travel_expenses: '#9D41B4',
  uniforms_laundry: '#6C893E',
  utilities: '#1B9035',
}

const incomeCategoryColorMap: {
  [identifier: string]: string
} = {
  client_discounts_refunds: Colors.magenta,
  consulting_income: Colors.red,
  interest_income: '#FAB44B',
  other_income: '#72B859',
  retail_income: '#CDA6FF',
  speaking_income: '#90E3A7',
  supervision_income: '#58C1C1',
  teaching_income: '#FF9D9D',
  therapy_income: Colors.yellow,
  uncategorized_income: '#FF7A00',
}

export const categoryColorMap: {
  [identifier: string]: string
} = {
  profit: '#42BA85',
  ...expenseCategoryColorMap,
  ...incomeCategoryColorMap,
}
