import { useEffect } from 'react'
import { Form, Grid } from 'semantic-ui-react'
import {
  Alert,
  BinaryRadioGroup,
  Link,
  Text,
} from '../../../components/BaseComponents'
import { InstitutionLogo } from '../../../components/Finances/Accounts/InstitutionCard'
import { FinancialAccount } from '../../../reducers/finances/financialAccountsReducer'
import { PlaidItem } from '../../../reducers/finances/plaidItemReducer'
import { useAnalyticsView } from '../../Amplitude'
import './styles.scss'

export const AccountQuestions = ({
  account,
  institution,
  display,
  isBusinessAccount,
  setIsBusinessAccount,
  hasPersonalTransactions,
  setHasPersonalTransactions,
  hasBusinessTransactions,
  setHasBusinessTransactions,
  isAccountOpenForYear,
  setIsAccountOpenForYear,
}: {
  account: FinancialAccount
  institution?: PlaidItem | null
  display: boolean | undefined
  isBusinessAccount: boolean | undefined
  setIsBusinessAccount: (is: boolean | undefined) => void
  hasPersonalTransactions: boolean | undefined
  setHasPersonalTransactions: (has: boolean | undefined) => void
  hasBusinessTransactions: boolean | undefined
  setHasBusinessTransactions: (has: boolean | undefined) => void
  isAccountOpenForYear: boolean | undefined
  setIsAccountOpenForYear: (is: boolean | undefined) => void
}) => {
  const pageView = useAnalyticsView()

  const currentYear = new Date().getFullYear()

  useEffect(() => {
    pageView('bank account information')
  }, [pageView])

  const renderQ2 = isBusinessAccount !== undefined
  const renderQ3 =
    hasPersonalTransactions !== undefined ||
    hasBusinessTransactions !== undefined

  if (!display) {
    return null
  }

  const handleIsBusinessAccountChange = (value: boolean) => {
    setHasPersonalTransactions(undefined)
    setHasBusinessTransactions(undefined)
    setIsBusinessAccount(value)
  }

  const isQ2Answered =
    hasPersonalTransactions !== undefined ||
    hasBusinessTransactions !== undefined

  return (
    <>
      <Text as="h1">Tell us about this account</Text>
      <br />
      <Text>
        Your responses let us know if we need to provide additional support to
        set up the account.
      </Text>

      <div className="AccountQuestions__account-card">
        {institution && (
          <InstitutionLogo
            institution={institution}
            className="institution-logo"
          />
        )}
        <Text as="h3">
          {account.name} Account {account.mask}
        </Text>
      </div>
      <Grid>
        <Grid.Column width={10}>
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                <BinaryRadioGroup
                  label="Is this account used for your business?"
                  text="Heard only works with accounts used for your business. This means the account has transactions related to your practice."
                  name="isBusinessAccount"
                  onChange={handleIsBusinessAccountChange}
                  value={isBusinessAccount}
                />

                {isBusinessAccount === false && (
                  <Alert className="AccountQuestions__Alert">
                    <Text as="bodySm" className="AccountQuestions__Alert__Text">
                      A business account is required to make the most of Heard.
                      If you don’t have one already, you can sign up for one
                      later.
                    </Text>

                    <Link
                      newPage
                      href="https://support.joinheard.com/hc/en-us/articles/23407015266967/#open_business_account"
                    >
                      Learn more -&gt;
                    </Link>
                  </Alert>
                )}
              </Form.Field>
            </Form.Group>

            {renderQ2 && (
              <Form.Group
                widths="equal"
                style={{ paddingTop: 30, paddingBottom: 30 }}
              >
                <Form.Field>
                  {isBusinessAccount ? (
                    <BinaryRadioGroup
                      label="Does it have any personal transactions?"
                      text="A personal transaction is any transaction that isn’t for your business. Since Heard only handles business transactions, we need to know this so we can help you remove them later."
                      name="hasPersonalTransactions"
                      onChange={setHasPersonalTransactions}
                      value={hasPersonalTransactions}
                    />
                  ) : (
                    <BinaryRadioGroup
                      label="Does it have any business transactions?"
                      text="A business transaction is any purchase or income that is related to your practice, like software you use to run your business or income you make from clients."
                      name="hasBusinessTransactions"
                      onChange={setHasBusinessTransactions}
                      value={hasBusinessTransactions}
                    />
                  )}

                  {isQ2Answered &&
                    isBusinessAccount === false &&
                    hasBusinessTransactions && (
                      <Alert type="warn" className="AccountQuestions__Alert">
                        <Text
                          as="bodySm"
                          className="AccountQuestions__Alert__Text"
                        >
                          To maintain accurate bookkeeping, we ask that you
                          unlink this personal account, then manually add your
                          business transactions once you have access to the
                          Heard app. Please see the instructions below:
                        </Text>

                        <Link
                          newPage
                          href="https://support.joinheard.com/hc/en-us/articles/19671412059799-How-can-I-stop-sharing-a-financial-account-with-Heard"
                        >
                          1. Unlink your personal account from Heard -&gt;
                        </Link>

                        <Link
                          newPage
                          href="https://support.joinheard.com/hc/en-us/articles/23407015266967-Using-a-dedicated-business-bank-account-for-your-practice#business_transactions_personal_account"
                        >
                          2. Manually add business transactions within your
                          personal account -&gt;
                        </Link>
                      </Alert>
                    )}

                  {isQ2Answered &&
                    isBusinessAccount &&
                    hasPersonalTransactions && (
                      <Alert className="AccountQuestions__Alert">
                        <Text
                          as="bodySm"
                          className="AccountQuestions__Alert__Text"
                        >
                          Once you get access to the full Heard app, we’ll ask
                          you to mark these transactions as Personal. This
                          removes them from your business books.
                        </Text>

                        <Link
                          newPage
                          href="https://support.joinheard.com/hc/en-us/articles/23407015266967-Using-a-dedicated-business-bank-account-for-your-practice"
                        >
                          What are the risks of a commingled account? -&gt;
                        </Link>
                      </Alert>
                    )}
                </Form.Field>
              </Form.Group>
            )}

            {renderQ3 && (
              <Form.Group widths="equal">
                <Form.Field>
                  <BinaryRadioGroup
                    label={`Has this account been open for all of ${currentYear}?`}
                    text="We ask this to ensure all your transactions are accounted for during bookkeeping."
                    name="isAccountOpenForYear"
                    onChange={setIsAccountOpenForYear}
                    value={isAccountOpenForYear}
                  />
                </Form.Field>
              </Form.Group>
            )}
          </Form>
        </Grid.Column>
      </Grid>
    </>
  )
}
