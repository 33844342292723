import { useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Container, Grid, Icon, Loader } from 'semantic-ui-react'
import moment from 'moment'

import { selectContractorByUuid } from '../payroll.selectors'
import {
  fetchContractorPayments,
  fetchSingleContractor,
} from '../payrollActions'
import { getContractorName } from '../helpers'
import ContractorDetailsTab from './ContractorDetailsTab'
import ContractorPaymentsTab from './ContractorPaymentsTab'
import {
  Tab,
  Link,
  Text,
  Card,
  GridRowColumn,
} from '../../../components/BaseComponents'
import ContractorDocumentsTab from './ContractorDocumentsTab'
import { useReselector } from '../../../utils/sharedHooks'
import OnboardingCTA from '../Employee/OnboardingCTA'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { Gusto_ContractorPayment } from '../generated_gusto_types'

const PayrollContractor = () => {
  const dispatch = useAppDispatch()
  const { uuid } = useParams()
  const [searchParams] = useSearchParams()
  const contractor = useReselector(selectContractorByUuid, uuid)
  const [contractorPayments, setContractorPayments] = useState<
    Gusto_ContractorPayment[]
  >([])
  const [activeIndex, setActiveIndex] = useState(
    searchParams.get('tab') === 'documents' ? 2 : 0
  )

  const panes = useMemo(
    () => [
      {
        menuItem: 'Details',
        render: () => (
          <Tab.Pane attached={false}>
            {contractor && (
              <ContractorDetailsTab
                contractor={contractor}
                payments={contractorPayments}
                goToPayments={() => setActiveIndex(1)}
              />
            )}
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Payments',
        render: () => (
          <Tab.Pane attached={false}>
            {contractor && (
              <ContractorPaymentsTab
                contractor={contractor}
                contractorPayments={contractorPayments}
              />
            )}
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Documents',
        render: () =>
          uuid ? (
            <Tab.Pane attached={false}>
              <ContractorDocumentsTab uuid={uuid} />
            </Tab.Pane>
          ) : null,
      },
    ],
    [contractor, contractorPayments, uuid]
  )

  useEffect(() => {
    const fetchPayments = async () => {
      const res = await fetchContractorPayments({
        start_date: '2020-01-01',
        end_date: moment().add(1, 'month').format(DATE_FORMATS.GUSTO_SUBMIT),
        contractor_uuid: uuid,
      })(dispatch)
      if (res) {
        const overallPayments = res.contractor_payments.find(
          (payment) => payment.contractor_uuid === uuid
        )
        if (overallPayments) {
          setContractorPayments(overallPayments.payments)
        }
      }
    }

    if (!uuid) {
      return
    }

    dispatch(fetchSingleContractor(uuid))
    fetchPayments()
  }, [dispatch, uuid])

  return (
    <Container id="payroll-employees">
      <Card>
        {!contractor && <Loader />}
        {contractor && (
          <Grid>
            <GridRowColumn>
              <Link to="/payroll/employees">
                <Icon name="arrow left" style={{ marginTop: -8 }} />
                Back to team
              </Link>
            </GridRowColumn>
            <Grid.Row />
            <GridRowColumn>
              <Text as="h1">{getContractorName(contractor)}</Text>
            </GridRowColumn>
            <OnboardingCTA
              teamMemberUuid={contractor.uuid}
              isEmployee={false}
            />
            <GridRowColumn>
              <Tab
                panes={panes}
                activeIndex={activeIndex}
                onTabClick={(index) => setActiveIndex(index)}
                defaultActiveIndex={activeIndex}
              />
            </GridRowColumn>
          </Grid>
        )}
      </Card>
    </Container>
  )
}

export default PayrollContractor
