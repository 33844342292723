import { useMemo } from 'react'
import { Divider, Grid } from 'semantic-ui-react'

import { Button, GridRowColumn } from '../BaseComponents'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'

const FormFlowFooter = ({
  onBack,
  onForward,
  continueDisabled,
  isSubmit,
  hideContinue = false,
  loading,
}: {
  onBack: () => void
  onForward?: () => void
  continueDisabled?: boolean
  isSubmit?: boolean
  hideContinue?: boolean
  loading?: boolean
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const backButton = useMemo(
    () => (
      <Button fullWidth variant="secondary" onClick={onBack}>
        Back
      </Button>
    ),
    [onBack]
  )

  const continueButton = useMemo(
    () =>
      !hideContinue && (
        <Button
          fullWidth
          onClick={onForward}
          disabled={continueDisabled}
          loading={loading}
        >
          {isSubmit ? 'Submit' : 'Continue'}
        </Button>
      ),
    [continueDisabled, hideContinue, isSubmit, onForward, loading]
  )

  return (
    <>
      <GridRowColumn short>
        <Divider />
      </GridRowColumn>
      {isMobile ? (
        <>
          <GridRowColumn spacer={1} width={14}>
            {continueButton}
          </GridRowColumn>
          <GridRowColumn spacer={1} width={14} short>
            {backButton}
          </GridRowColumn>
        </>
      ) : (
        <Grid.Row>
          <Grid.Column computer={3} tablet={5} mobile={16}>
            {backButton}
          </Grid.Column>
          <Grid.Column computer={10} tablet={6} only="computer tablet" />
          <Grid.Column computer={3} tablet={5} mobile={16}>
            {continueButton}
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  )
}

export default FormFlowFooter
