import { useCallback, useEffect, useMemo, useState } from 'react'
import { Checkbox } from 'semantic-ui-react'

import {
  clearSelectedTransactions,
  getSelectedTransactionIds,
  setSelectedTransactions,
} from '../../../reducers/admin/selectedTransactionsReducer'
import { useReselector } from '../../../utils/sharedHooks'
import {
  selectAdminQueriedTransactionIds,
  selectEnabledAdminQueriedTransactionIds,
} from '../../../features/Transactions/transactions.selectors'
import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'

const TransactionHeaderCheckbox = ({
  transactions,
}: {
  transactions?: Transaction[]
}) => {
  const dispatch = useAppDispatch()
  const [allSelected, setAllSelected] = useState(false)
  const enabledReduxTransactionIds = useReselector(
    selectEnabledAdminQueriedTransactionIds
  )
  const enabledTransactionIds = useMemo(
    () => transactions?.filter((tx) => !tx.confirmedAt)?.map((tx) => tx.id),
    [transactions]
  )
  const queriedTransactions = useReselector(selectAdminQueriedTransactionIds)
  const selectedIds = useReselector(getSelectedTransactionIds)

  // If transactions changes, clear selected transactions
  useEffect(() => {
    setAllSelected(false)
    dispatch(clearSelectedTransactions())
  }, [dispatch, queriedTransactions, transactions])

  // If all selected transactions are unchecked, uncheck all selected
  useEffect(() => {
    if (!selectedIds.length) {
      setAllSelected(false)
    }
  }, [selectedIds.length])

  const handleCheckboxSelect = useCallback(() => {
    const newSelected = !allSelected
    setAllSelected(newSelected)
    if (newSelected) {
      if (enabledTransactionIds) {
        dispatch(setSelectedTransactions(enabledTransactionIds))
      } else {
        dispatch(setSelectedTransactions(enabledReduxTransactionIds))
      }
    } else {
      dispatch(clearSelectedTransactions())
    }
  }, [allSelected, dispatch, enabledTransactionIds, enabledReduxTransactionIds])

  return <Checkbox checked={allSelected} onClick={handleCheckboxSelect} />
}

export default TransactionHeaderCheckbox
