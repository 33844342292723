import { useCallback, useEffect, useMemo, useState } from 'react'
import { Dimmer, Grid, Loader } from 'semantic-ui-react'
import { orderBy, range, sortBy } from 'lodash'
import { DateTime } from 'luxon'

import {
  cancelContractorPayment,
  fetchContractorPayments,
  fetchContractors,
} from '../payrollActions'
import { selectContractorByUuid } from '../payroll.selectors'
import { getContractorName } from '../helpers'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { formatCurrency } from '../../../utils/currencyHelpers'
import {
  Modal,
  Text,
  Button,
  Table,
  Link,
  Dropdown,
  valsToDropdownOptions,
  GridRowColumn,
} from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import { DATE_FORMATS, DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import { useReselector } from '../../../utils/sharedHooks'
import { Gusto_ContractorPayment } from '../generated_gusto_types'

const CancelModal = ({
  payment,
  close,
  fetchPayments,
}: {
  payment: Gusto_ContractorPayment
  close: () => void
  fetchPayments: () => void
}) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const contractor = useReselector(
    selectContractorByUuid,
    payment.contractor_uuid
  )

  const cancelPayroll = useCallback(async () => {
    setLoading(true)
    // This is a very slow call (4 sec)
    const res = await cancelContractorPayment(payment.uuid)(dispatch)

    if (res) {
      // refetch payment (will remove cancelled payment)
      fetchPayments()
      close()
    }
    setLoading(false)
  }, [close, dispatch, payment.uuid, fetchPayments])

  return (
    <Modal open size="tiny" onClose={close} closeIcon>
      <Modal.Header>Cancel Contractor Payment</Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text>
              <b>
                Warning you are about to cancel {getContractorName(contractor)}
                &apos;s{' '}
                {DateTime.fromFormat(
                  payment.date,
                  DATE_FORMATS_LUXON.GUSTO_SUBMIT
                ).toFormat(DATE_FORMATS.DISPLAY_SHORT)}{' '}
                payment
              </b>
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Text>
              If you continue, the payment will not be sent to the contractor
              and the payment record will be removed.
            </Text>
          </GridRowColumn>
          {!loading && (
            <>
              <GridRowColumn>
                <Button
                  onClick={cancelPayroll}
                  fullWidth
                  style={{ backgroundColor: Colors.red }}
                >
                  Cancel Payment
                </Button>
              </GridRowColumn>
              <GridRowColumn>
                <Button variant="secondary" onClick={close} fullWidth>
                  Go Back
                </Button>
              </GridRowColumn>
            </>
          )}
          {loading && (
            <>
              <GridRowColumn>
                <Dimmer active inverted>
                  <Loader active />
                </Dimmer>
              </GridRowColumn>
              <Grid.Row />
            </>
          )}
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

const PaymentRow = ({
  payment,
  fetchPayments,
}: {
  payment: Gusto_ContractorPayment
  fetchPayments: () => void
}) => {
  const [showModal, setShowModal] = useState(false)

  const contractor = useReselector(
    selectContractorByUuid,
    payment.contractor_uuid
  )

  const paymentDate = DateTime.fromFormat(
    payment.date,
    DATE_FORMATS_LUXON.GUSTO_SUBMIT
  )

  return (
    <Table.Row key={payment.uuid}>
      <Table.Cell>
        <Text>{paymentDate.toFormat(DATE_FORMATS.DISPLAY_SHORT)}</Text>
        {paymentDate > DateTime.now() && (
          <Button onClick={() => setShowModal(true)} variant="link">
            Cancel Payroll
          </Button>
        )}
      </Table.Cell>
      <Table.Cell>{getContractorName(contractor)}</Table.Cell>
      <Table.Cell>
        {formatCurrency(
          Number(payment.wage_total) +
            Number(payment.bonus) +
            Number(payment.reimbursement)
        )}
      </Table.Cell>
      <Table.Cell>
        <Link to={`/payroll/contractor_history/${payment.uuid}`}>
          View Details
        </Link>
      </Table.Cell>
      {showModal && (
        <CancelModal
          payment={payment}
          close={() => setShowModal(false)}
          fetchPayments={fetchPayments}
        />
      )}
    </Table.Row>
  )
}

const ContractorPayrollHistoryTab = () => {
  const dispatch = useAppDispatch()

  const [payments, setPayments] = useState<Gusto_ContractorPayment[]>([])
  const [year, setYear] = useState(DateTime.now().year)

  const yearOptions = valsToDropdownOptions(
    range(2020, DateTime.now().year + 1).reverse()
  )

  const paymentsToShow = useMemo(
    () =>
      orderBy(
        Object.values(payments).filter(
          (payment) =>
            DateTime.fromFormat(payment.date, DATE_FORMATS_LUXON.GUSTO_SUBMIT)
              .year === year
        ),
        (payment) => payment.date,
        ['desc']
      ),
    [payments, year]
  )

  const fetchPayments = useCallback(async () => {
    // Add start and end date here to get back all of the contractor payments
    const res = await fetchContractorPayments({
      start_date: '2020-01-01',
      end_date: DateTime.now()
        .plus({ month: 1 })
        .toFormat(DATE_FORMATS.GUSTO_SUBMIT),
    })(dispatch)

    if (res) {
      const payments = res.contractor_payments.reduce<
        Gusto_ContractorPayment[]
      >((prev, payment) => {
        // Contractor uuid doesn't come back with payload so add it here
        return [
          ...prev,
          ...payment.payments.map((val) => ({
            ...val,
            contractor_uuid: payment.contractor_uuid,
          })),
        ]
      }, [])

      setPayments(sortBy(payments, 'date'))
    }
  }, [dispatch])

  useEffect(() => {
    fetchPayments()
    dispatch(fetchContractors())
  }, [dispatch, fetchPayments])

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={12}>
          <Text as="h3">
            <b>Contractor Payments</b>
          </Text>
        </Grid.Column>
        <Grid.Column width={4}>
          <Dropdown options={yearOptions} value={year} onChange={setYear} />
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn>
        <Text>
          Below is a list of payments you&apos;ve made to your contractors
        </Text>
      </GridRowColumn>
      <Grid.Row />
      <GridRowColumn>
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Pay Date</Table.HeaderCell>
              <Table.HeaderCell>Contractor Paid</Table.HeaderCell>
              <Table.HeaderCell>Total Amount</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {paymentsToShow.map((payment) => (
              <PaymentRow
                key={payment.uuid}
                payment={payment}
                fetchPayments={fetchPayments}
              />
            ))}
          </Table.Body>
        </Table>
      </GridRowColumn>
    </Grid>
  )
}

export default ContractorPayrollHistoryTab
