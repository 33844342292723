import { useCallback, useEffect, useState } from 'react'
import { Button, Card, Dropdown, Icon, Modal, Text } from '../../BaseComponents'
import {
  Header,
  Button as SemanticButton,
  Confirm,
  Card as SemanticCard,
  Label,
  Grid,
} from 'semantic-ui-react'
import moment from 'moment'

import { deleteFinancialAccount } from '../../../actions/financialAccountActions'
import {
  updateAdminUserFinancialAccounts,
  getDateOfLastTransaction,
  forcePullTransactions,
  forceNotifyUserReconnection,
  adminDeleteFinancialAccount,
  fetchUserFinancialAccounts,
  adminLinkFinancialAccount,
} from '../../../actions/admin/adminFinancialAccountActions'
import PlaidLink from '../../shared/plaid/PlaidLink'
import { FinancialAccount } from '../../../reducers/finances/financialAccountsReducer'
import { FinancialAccountWithAdminInfo } from '../../../reducers/admin/financialAccountsReducer'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useReselector } from '../../../utils/sharedHooks'
import { getPlaidOnlyFinancialAccountsByUserId } from '../../../selectors/financeSelectors'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useAppDispatch } from '../../../utils/typeHelpers'

const ConfirmExampleConfirm = ({
  isAdmin,
  account,
  userId,
}: {
  isAdmin: boolean
  account: FinancialAccount | FinancialAccountWithAdminInfo
  userId: number
}) => {
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)

  const removeAccount = useCallback(async () => {
    if (isAdmin) {
      await dispatch(adminDeleteFinancialAccount(userId, account.id))
    } else {
      await dispatch(deleteFinancialAccount(account.id))
    }
    setOpen(false)
  }, [account.id, dispatch, isAdmin, userId])

  return (
    <div>
      <Button
        variant="link"
        onClick={() => setOpen(true)}
        floated="right"
        icon="trash alternate outline"
      />

      <Modal
        size="tiny"
        dimmer="inverted"
        className="twoFactorAuthenticationModal"
        open={open}
        onClose={() => setOpen(false)}
        closeIcon
      >
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Text as="bodyMd">
                  Are you sure you want to remove ${account.name}: $
                  {account.mask} from your account? This will remove all
                  transactions associated with it.
                </Text>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button variant="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={removeAccount}>
            Remove
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

const LinkManualAccount = ({
  account,
  userId,
}: {
  account: FinancialAccount | FinancialAccountWithAdminInfo
  userId: number
}) => {
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)
  const [plaidAccountId, setPlaidAccountId] = useState<number>()

  const plaidAccounts = useReselector(
    getPlaidOnlyFinancialAccountsByUserId,
    userId
  )

  const plaidAccountOptions = plaidAccounts.map((account) => ({
    text: `${account.plaidInstitutionName} ${account.name}: ${account.mask}`,
    value: account.id,
  }))

  useEffect(() => {
    dispatch(fetchUserFinancialAccounts(userId))
  }, [dispatch, userId])

  const linkAccount = async () => {
    if (plaidAccountId) {
      const data = { userId, financialAccountId: account.id, plaidAccountId }
      await dispatch(adminLinkFinancialAccount(userId, account.id, data))
    }
    setOpen(false)
  }

  return (
    <div>
      <Button
        variant="link"
        onClick={() => setOpen(true)}
        floated="right"
        icon="linkify"
      />

      <Modal
        size="tiny"
        dimmer="inverted"
        className="twoFactorAuthenticationModal"
        open={open}
        onClose={() => setOpen(false)}
        closeIcon
      >
        <Modal.Content>
          <Dropdown
            fluid
            label="Plaid Connected Account"
            placeholder="Select Account"
            options={plaidAccountOptions}
            onChange={setPlaidAccountId}
            value={plaidAccountId}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button variant="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={linkAccount}>
            Link
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

const AccountCard = ({
  account,
  isAdmin,
  userId,
}: {
  account: FinancialAccount | FinancialAccountWithAdminInfo
  isAdmin?: boolean
  userId: number
}) => {
  const dispatch = useAppDispatch()
  const [submitting, setSubmitting] = useState(false)
  const [notifying, setNotifying] = useState(false)
  const [status, setStatus] = useState<string | null>(null)
  const [inactiveModalOpen, setInactiveModalOpen] = useState(false)
  const [dateOfLastTransaction, setDateOfLastTransaction] = useState<string>()

  useEffect(() => {
    const fetchData = async () => {
      const res = await getDateOfLastTransaction(account.id)(dispatch)
      if (res) {
        setDateOfLastTransaction(
          moment.utc(res.date).format(DATE_FORMATS.DISPLAY_SHORT)
        )
      }
    }

    fetchData()
  }, [account, dispatch])

  const pullTransactions = async () => {
    setSubmitting(true)
    const data = {
      accountId: account.id,
    }
    const res = await forcePullTransactions(data)(dispatch)
    if (!res) {
      setSubmitting(false)
      setStatus('Cannot force pull')
    }
    setSubmitting(false)
    setStatus('Force pulled')
  }

  const notifyUser = async () => {
    setNotifying(true)
    const data = {
      accountId: account.id,
    }

    const res = await forceNotifyUserReconnection(data)(dispatch)
    if (typeof res !== 'boolean') {
      setStatus(res.status)
    }

    setNotifying(false)
  }

  const updateInactive = async (inactive: boolean) => {
    const data = {
      inactive,
    }
    const res = await updateAdminUserFinancialAccounts(
      userId,
      account.id,
      data
    )(dispatch)

    if (!res) {
      setStatus('Cannot update as inactive')
    }
    setStatus('Set Inactive')
    setInactiveModalOpen(false)
  }

  const forcePullDisabled = () => {
    return (
      account.accountType !== 'plaid' ||
      account?.accounts?.needsReconnection ||
      account.inactive
    )
  }

  const notifyDisabled = () => {
    return (
      account.accountType !== 'plaid' ||
      !account?.accounts?.needsReconnection ||
      account.inactive
    )
  }

  const noRecentPlaidTransactions =
    moment(new Date()).diff(dateOfLastTransaction, 'days') > 7 &&
    account.accountType === 'plaid'

  let className = 'accountCard'

  if (account?.accounts?.needsReconnection) {
    className = `${className} needsReconnection`
  }

  if (isAdmin) {
    if (noRecentPlaidTransactions) {
      className = `${className} noRecentTransactions`
    }

    return (
      <SemanticCard className={className} fluid>
        <SemanticCard.Content>
          {isAdmin && account.accountType === 'manual' && (
            <LinkManualAccount account={account} userId={userId} />
          )}
          <ConfirmExampleConfirm
            account={account}
            isAdmin={isAdmin}
            userId={userId}
          />
          <Label
            color={account.accountType === 'plaid' ? 'green' : 'red'}
            horizontal
          >
            {account.accountType}
          </Label>

          {account.inactive && (
            <Label color="orange" horizontal>
              Inactive
            </Label>
          )}

          {/* Show if inactive and plaid connected since only plaid accounts can be reconnected */}
          {!account.inactive && account.accountType === 'plaid' && (
            <Label
              color={account?.accounts?.needsReconnection ? 'red' : 'green'}
              horizontal
            >
              {account?.accounts?.needsReconnection
                ? 'Needs Reconnect'
                : 'Connected'}
            </Label>
          )}

          {/* Only mark for Plaid accounts; ignore manual account */}
          {noRecentPlaidTransactions && (
            <Label horizontal color="red">
              No recent transactions
            </Label>
          )}

          <SemanticCard.Header>
            <Header as="h4" style={{ paddingLeft: 0 }}>
              {account.plaidInstitutionName}
            </Header>
          </SemanticCard.Header>
          <SemanticCard.Description>
            <p>
              <b>{account.name}:</b> {account.mask}
            </p>
            <p>
              {account.type}&nbsp;—&nbsp;{account.subtype}
            </p>
          </SemanticCard.Description>
        </SemanticCard.Content>

        {isAdmin && account.manualAccount && (
          <SemanticCard.Content>
            <b>Manual Account Linked: </b>{' '}
            <Icon icon={regular('check')} color="green" />
            <br />
            <b>Name: </b>
            {account.manualAccount.name}
          </SemanticCard.Content>
        )}

        {account?.accounts?.needsReconnection && (
          <SemanticCard.Content>
            <p>
              <b>Error: </b>
            </p>
            <p>Account needs reconnecting</p>
            <p>
              <b>Disconnected On: </b>
            </p>
            <p>
              {moment(account.accounts.disconnectedOn).format(
                DATE_FORMATS.DISPLAY_SHORT
              )}
            </p>
          </SemanticCard.Content>
        )}

        {noRecentPlaidTransactions && (
          <SemanticCard.Content>
            <p>
              <b>Error: </b>
            </p>
            <p>Over 7 days since last transaction</p>
            <p>
              <b>Date of Last Transaction On: </b>
            </p>
            <p>{dateOfLastTransaction}</p>
          </SemanticCard.Content>
        )}

        {account?.accounts?.errorObject && (
          <SemanticCard.Content>
            <p>
              <b>Error Type:</b> {account?.accounts?.errorObject.error_code}
            </p>
            <p>
              <b>Error Message:</b>{' '}
              {account?.accounts?.errorObject.error_message}
            </p>
          </SemanticCard.Content>
        )}

        <SemanticCard.Content>
          {account.inactive && (
            <SemanticButton basic fluid size="tiny" disabled>
              Inactive Account
            </SemanticButton>
          )}
          {!account.inactive && (
            <>
              <SemanticButton
                basic
                fluid
                size="tiny"
                onClick={() => setInactiveModalOpen(true)}
              >
                Mark Inactive
              </SemanticButton>
              <Confirm
                open={inactiveModalOpen}
                content="Are you sure you want to mark this account as inactive? This is a permanent change."
                onCancel={() => setInactiveModalOpen(false)}
                onConfirm={() => updateInactive(true)}
              />
            </>
          )}
          <SemanticButton
            basic
            disabled={forcePullDisabled()}
            fluid
            size="tiny"
            onClick={() => pullTransactions()}
            loading={submitting}
          >
            Force Pull
          </SemanticButton>

          <SemanticButton
            basic
            disabled={notifyDisabled()}
            fluid
            size="tiny"
            onClick={() => notifyUser()}
            loading={notifying}
          >
            Notify Reconnect
          </SemanticButton>
          {status && <i className="red">{status}</i>}
        </SemanticCard.Content>
      </SemanticCard>
    )
  } else {
    return (
      <Card
        content={
          <>
            {/* <RemoveAccountButton account={account} isAdmin={isAdmin} /> */}
            <Text as="bodyMd">{account.plaidInstitutionName}</Text>
            <Text as="bodySm">
              {account.name}: {account.mask}
            </Text>
            <br />
            {account.accounts?.needsReconnection && (
              <PlaidLink account={account} />
            )}
          </>
        }
      />
    )
  }
}

export default AccountCard
