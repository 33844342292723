import { useEffect, useMemo, useState } from 'react'
import { Confirm, Icon, Grid } from 'semantic-ui-react'
import moment from 'moment'

// BL
import { UPLOAD_COPY } from '../../copyConstants'
import { UploadScreenProps } from './index'
import { ManualTransactionModel } from '../../../../reducers/admin/allTransactions.slice'
import {
  validateMultipleManualTransactions,
  createMultipleManualTransactions,
} from '../../transactions.slice'
import { userUploadDocument } from '../../../../actions/awsActions'
import { parseErrorFromCatch } from '../../../../utils/errorHelpers'
import UploadBackButton from '../UploadBackButton'
import CurrencyFormatLabel from '../../../../components/shared/CurrencyFormatLabel'
import {
  ValidationErrorMessageContainer,
  UploadCSVErrorModel,
  generateValidationErrorModel,
} from './UploadCSVPanel'
import LockedBooksMessage from '../../LockedBooksMessage'
import { getCurrentUser } from '../../../../selectors/user.selectors'
import {
  Button,
  Input,
  Table,
  Modal,
  Text,
  DatePicker,
  GridRowColumn,
} from '../../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useReselector } from '../../../../utils/sharedHooks'

interface TableRowProps {
  transactionModel: ManualTransactionModel
  editingIndex: number | null
  index: number
  setEditingIndex: (index: number | null) => void
  updateModelAtIndex: (props: {
    index: number
    model: ManualTransactionModel
  }) => void
}

const TableRow = ({
  transactionModel,
  editingIndex,
  index,
  setEditingIndex,
  updateModelAtIndex,
}: TableRowProps) => {
  const [date, setDate] = useState(transactionModel.date)
  const [amountInDollars, setAmountInDollars] = useState(
    transactionModel.amountInDollars
  )
  const [description, setDescription] = useState(transactionModel.description)

  const onEditClick = () => setEditingIndex(index)
  const onEndEditingClick = () => {
    updateModelAtIndex({
      index,
      model: {
        date,
        description: description.trim(),
        amountInDollars,
      },
    })
    setEditingIndex(null)
  }

  const isEditing = editingIndex === index

  return (
    <Table.Row
      active={isEditing}
      disabled={editingIndex !== null && !isEditing}
    >
      <Table.Cell>{index + 1}</Table.Cell>

      {!isEditing && <Table.Cell>{date}</Table.Cell>}

      {isEditing && (
        <Table.Cell>
          <DatePicker
            value={date}
            maxDate={new Date()}
            onChange={setDate}
            name="transactionDate"
          />
        </Table.Cell>
      )}

      {!isEditing && <Table.Cell>{description}</Table.Cell>}

      {isEditing && (
        <Table.Cell>
          <Input
            placeholder="Enter a description"
            value={description}
            onChange={setDescription}
          />
        </Table.Cell>
      )}

      {!isEditing && (
        <Table.Cell textAlign="right">
          <CurrencyFormatLabel
            key={`currency-${index}`}
            value={amountInDollars}
          />
        </Table.Cell>
      )}

      {isEditing && (
        <Table.Cell textAlign="right">
          <Input
            onChange={setAmountInDollars}
            value={amountInDollars}
            placeholder="Amount"
            componentType="number"
          />
        </Table.Cell>
      )}

      <Table.Cell>
        {editingIndex === null && (
          <Icon
            name="pencil"
            onClick={onEditClick}
            style={{ cursor: 'pointer' }}
          />
        )}
        {isEditing && (
          <Icon
            name="check"
            onClick={onEndEditingClick}
            style={{ cursor: 'pointer' }}
          />
        )}
      </Table.Cell>
    </Table.Row>
  )
}

interface ModelStates {
  [i: number]: ManualTransactionModel
}

const UploadReviewPanel = ({
  goToScreenWithData,
  data,
  finishFlow,
  onUploadComplete,
}: UploadScreenProps) => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmInvoker, setConfirmInvoker] = useState('')
  const [editingIndex, setEditingIndex] = useState<number | null>(null)
  const [validationError, setValidationError] =
    useState<UploadCSVErrorModel | null>(null)
  const [modelStates, setModelStates] = useState<ModelStates>({})
  const [saving, setSaving] = useState(false)
  const [serverError, setServerError] = useState<string | null>(null)

  const dispatch = useAppDispatch()
  const currentUser = useReselector(getCurrentUser)

  const models = useMemo(() => data?.models || [], [data?.models])
  const { spreadsheetFile } = data || {}

  const titleText = UPLOAD_COPY.REVIEW_SCREEN_TITLE.replace(
    '{transaction_count}',
    models.length.toString()
  )

  useEffect(() => {
    const state: ModelStates = {}
    models.forEach((m, i) => {
      const candidateDate = moment(m.date)
      if (candidateDate.isValid()) {
        m.date = candidateDate.utc().format(DATE_FORMATS.INPUT)
      }
      m.description = m.description.trim()
      m.amountInDollars = m.amountInDollars.replace(/\$/, '')
      state[i] = m
    })

    setModelStates(state)
  }, [models])

  const openConfirm = (invoker: string) => {
    setConfirmInvoker(invoker)
    setConfirmOpen(true)
  }

  const updateModelAtIndex = ({
    index,
    model,
  }: {
    index: number
    model: ManualTransactionModel
  }) => {
    const stateClone = Object.values(modelStates).map((m) => {
      return { ...m }
    })
    model.amountInDollars = model.amountInDollars.replace(/\$/, '')
    model.description = model.description.trim()
    stateClone[index] = model
    setModelStates(stateClone)
  }

  const isEditing = editingIndex !== null

  const onContinueClick = async () => {
    setSaving(true)
    const modelList = Object.values(modelStates)

    try {
      const { errors, warnings } =
        await validateMultipleManualTransactions(modelList)

      if (errors.length) {
        const model = generateValidationErrorModel({ errors })
        setValidationError(model)
      } else {
        setValidationError(null)

        const { spreadsheetDuplicates, databaseDuplicates } = warnings

        // If there are warnings, separate valid transactions from warnings and navigate to the Duplicate screen
        if (
          Object.keys(spreadsheetDuplicates).length > 0 ||
          databaseDuplicates.length > 0
        ) {
          // Populate a set of valid transactions
          const validTransactions: Array<ManualTransactionModel> = []

          Object.values(modelStates).forEach((model) => {
            const key = JSON.stringify(model)
            let isValid = true

            model.date = moment(model.date).utc().format(DATE_FORMATS.INPUT)
            model.description = model.description.trim()

            const databaseMatch = databaseDuplicates.find((dup) => dup === key)

            // A transaction is not valid if it exists in database duplicates
            if (databaseMatch) {
              return
            }

            // A transaction is not valid if it exists in spreadsheet duplicates
            if (spreadsheetDuplicates[key]) {
              isValid = false
            }

            if (isValid) {
              validTransactions.push(model)
            }
          })

          // Navigate to the duplicate screen
          goToScreenWithData({
            index: 3,
            data: {
              validTransactions,
              databaseDuplicates,
              spreadsheetDuplicates,
              spreadsheetFile,
            },
          })
        } else {
          // No duplicate warnings, upload the file and save transactions to DB
          const modelList = Object.values(modelStates)
          const result =
            await createMultipleManualTransactions(modelList)(dispatch)
          if (currentUser && spreadsheetFile) {
            const docRes = await userUploadDocument({
              file: spreadsheetFile,
              user: currentUser,
              documentType: 'other',
            })(dispatch)
            if (docRes && 'error' in docRes) {
              throw new Error(docRes.error)
            }
          }

          if (result) {
            onUploadComplete?.({ success: true, data: modelList })
          } else {
            onUploadComplete?.({ success: false })
          }
          finishFlow()
        }
      }
      setServerError(null)
    } catch (err) {
      setServerError(parseErrorFromCatch(err))
    }
    setSaving(false)
  }

  return (
    <>
      <Grid>
        <GridRowColumn>
          <UploadBackButton
            disabled={saving}
            onClick={() => openConfirm('back')}
          />
        </GridRowColumn>
        <GridRowColumn>
          <Text as="h1" color="forest">
            {titleText}
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text as="bodyLg">{UPLOAD_COPY.REVIEW_SCREEN_SUBTITLE}</Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text as="bodyLg">{UPLOAD_COPY.SIGN_EXPLAINER}</Text>
        </GridRowColumn>

        {currentUser?.booksLockedForUserYear && (
          <GridRowColumn>
            <LockedBooksMessage year={currentUser.booksLockedForUserYear} />
          </GridRowColumn>
        )}
        <GridRowColumn>
          <Table basic="very">
            <Table.Header>
              <Table.Row style={{ border: 'none' }}>
                <Table.HeaderCell width={1} />
                <Table.HeaderCell width={4}>Date</Table.HeaderCell>
                <Table.HeaderCell width={6}>Description</Table.HeaderCell>
                <Table.HeaderCell width={5} textAlign="right">
                  Amount
                </Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {Object.values(modelStates).map((m, i) => (
                <TableRow
                  key={`csv-table-row-${m.id || m.date}-${i}`}
                  setEditingIndex={setEditingIndex}
                  transactionModel={m}
                  index={i}
                  editingIndex={editingIndex}
                  updateModelAtIndex={updateModelAtIndex}
                />
              ))}
            </Table.Body>
          </Table>
        </GridRowColumn>
        {!validationError && (
          <GridRowColumn textAlign="right">
            <Text as="bodyLg">Does everything look correct?</Text>
          </GridRowColumn>
        )}

        {validationError && (
          <GridRowColumn>
            <ValidationErrorMessageContainer error={validationError} />
          </GridRowColumn>
        )}

        {serverError && (
          <GridRowColumn>
            <Text className="server-error">{serverError}</Text>
          </GridRowColumn>
        )}

        <GridRowColumn spacer={12}>
          <Button
            loading={saving}
            onClick={onContinueClick}
            disabled={isEditing || saving}
            fullWidth
          >
            {!isEditing
              ? 'Continue'
              : 'Click the check icon to finish editing the row'}
          </Button>
        </GridRowColumn>
      </Grid>

      <Confirm
        id="csv-review-confirm"
        header={`Are you sure you want to ${
          confirmInvoker === 'back' ? 'go back' : 'close'
        }?`}
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => {
          if (confirmInvoker === 'back') {
            goToScreenWithData({ index: 1 })
            return
          }

          setConfirmOpen(false)
          finishFlow()
        }}
        content={`You will lose any uploaded data if you ${
          confirmInvoker === 'back' ? 'go back' : 'proceed with closing'
        }.`}
        size="tiny"
        confirmButton="Close"
        cancelButton="Cancel"
      />
    </>
  )
}

export const UploadReviewModal = (props: UploadScreenProps) => (
  <Modal.Content>
    <UploadReviewPanel {...props} />
  </Modal.Content>
)
