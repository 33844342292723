import { CSSProperties, FC } from 'react'
import {
  Table as SemanticTable,
  StrictTableBodyProps,
  StrictTableHeaderCellProps,
  StrictTableRowProps,
  StrictTableCellProps,
  StrictTableProps,
} from 'semantic-ui-react'
import styled from 'styled-components'
import { Colors, Fonts } from '../../styles/theme'

const cellBorder = `1px solid ${Colors.lightGray}`

const TableCell = styled(SemanticTable.Cell)<
  StrictTableCellProps & { color?: keyof typeof Colors; style?: CSSProperties }
>(({ color }) => ({
  '&&&&': {
    border: 'none',
    borderBottom: cellBorder,
    padding: 16,
    color: color ? Colors[color] : 'inherit',
    overflow: 'inherit',
    ...Fonts.bodyMd,
  },
}))

const TableHeader = ({
  className,
  ...rest
}: StrictTableBodyProps & { style?: CSSProperties; onClick?: () => void }) => (
  <SemanticTable.Header
    className={`table-header ${className ?? ''}`}
    {...rest}
  />
)

const TableHeaderCell = styled(
  ({
    className,
    ...rest
  }: StrictTableHeaderCellProps & {
    color?: keyof typeof Colors
    style?: CSSProperties
    onClick?: () => void
  }) => (
    <SemanticTable.HeaderCell
      className={`table-header-cell ${className ?? ''}`}
      {...rest}
    />
  )
)(({ color }) => ({
  '&&&&': {
    ...Fonts.eyebrow,
    background: 'transparent',
    border: 'none',
    borderBottom: cellBorder,
    padding: '16px 20px',
    ...(color && {
      color: Colors[color],
    }),
  },
}))

const TableRow = ({
  className,
  ...rest
}: StrictTableRowProps & { style?: CSSProperties; onClick?: () => void }) => (
  <SemanticTable.Row className={`table-row ${className ?? ''}`} {...rest} />
)

interface TableComponent
  extends FC<StrictTableProps & { style?: CSSProperties; id?: string }> {
  Body: typeof SemanticTable.Body
  Cell: typeof TableCell
  Footer: typeof SemanticTable.Footer
  Header: typeof TableHeader
  HeaderCell: typeof TableHeaderCell
  Row: typeof TableRow
}

const Table: TableComponent = styled(SemanticTable)({
  '&&&&': {
    background: 'transparent',
    border: 'none',
  },
})

Table.Body = SemanticTable.Body
Table.Cell = TableCell
Table.Header = TableHeader
Table.HeaderCell = TableHeaderCell
Table.Footer = SemanticTable.Footer
Table.Row = TableRow

export default Table
