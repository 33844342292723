import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FinancialAccount } from './financialAccountsReducer'

export interface PlaidItemState {
  [key: string]: PlaidItem
}

export interface PlaidItem {
  id: number
  userId: number
  institutionId: string
  institutionName: string
  needsReconnection: boolean
  disconnectedOn: string
  createdAt: string
  updatedAt: string
  logo?: string
  accounts: FinancialAccount[]
}

export interface AdminPlaidItem extends PlaidItem {
  itemId: string
}

const plaidItemSlice = createSlice({
  name: 'plaidItems',
  initialState: {} as PlaidItemState,
  reducers: {
    receivePlaidItems: (
      _state,
      action: PayloadAction<{ [key: string]: PlaidItem }>
    ) => ({
      ...action.payload,
    }),
    receiveCreatePlaidItem: (state, action: PayloadAction<PlaidItem>) => {
      if (!state[action.payload.id]) {
        return {
          ...state,
          [action.payload.id]: action.payload,
        }
      }
      return {
        ...state,
      }
    },
    receiveDeletePlaidItem: (state, action: PayloadAction<string>) => {
      delete state[action.payload]
    },
    receiveUpdatePlaidItem: (state, action: PayloadAction<PlaidItem>) => {
      state[action.payload.id] = action.payload
    },
    receiveUpdatePlaidItemFinancialAccount: (
      state,
      action: PayloadAction<FinancialAccount>
    ) => {
      const { plaidItemId, id } = action.payload
      if (!plaidItemId) return
      const plaidItem = state[plaidItemId]
      const accountIndex = plaidItem.accounts.findIndex(
        (account) => id === account.id
      )
      if (accountIndex === -1) return
      plaidItem.accounts[accountIndex] = action.payload
      state[plaidItemId] = plaidItem
    },
  },
})

export default plaidItemSlice.reducer

export const {
  receivePlaidItems,
  receiveCreatePlaidItem,
  receiveDeletePlaidItem,
  receiveUpdatePlaidItem,
  receiveUpdatePlaidItemFinancialAccount,
} = plaidItemSlice.actions
