import {
  Button,
  GridRowColumn,
  Link,
  Modal,
  Text,
} from '../../components/BaseComponents'
import { Grid, List } from 'semantic-ui-react'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'
import { Colors } from '../../styles/theme'

const PayrollComplianceModal = ({
  open,
  onClose,
  onSuccess,
}: {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  return (
    <Modal open={open} closeIcon onClose={onClose} size="small">
      <Modal.Header>Payroll Compliance Requirements</Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <div
              className={'scrollable'}
              style={{ backgroundColor: Colors.stone }}
            >
              <Text>
                As Heard is getting ready for the upcoming tax season,
                we&apos;re reaching out to all S corporations to verify that
                you&apos;re fulfilling all the necessary payroll
                responsibilities and meeting IRS and state regulations. For more
                information and to download the Payroll Audit Checklist,{' '}
                <Link
                  newPage
                  style={{ textDecoration: 'underline' }}
                  href={
                    'https://support.joinheard.com/hc/en-us/articles/19204189645719'
                  }
                >
                  refer to this article
                </Link>
                .
              </Text>
              <br />
              <Text as={'h3'}>Why do I need to set up payroll?</Text>
              <br />
              <Text>
                As an S corp, while you&apos;re the owner of the business,
                you&apos;re also an employee. Therefore, you&apos;re required to
                set up payroll and pay yourself a reasonable salary.
              </Text>
              <br />
              <Text as={'h3'}>
                How can I ensure that I comply with all the necessary
                requirements?
              </Text>
              <br />
              <Text>
                Please review our{' '}
                <Link
                  newPage
                  style={{ textDecoration: 'underline' }}
                  href={
                    'https://support.joinheard.com/hc/en-us/article_attachments/19203994461207'
                  }
                >
                  Payroll Audit Checklist
                </Link>{' '}
                for steps you’ll need to take to ensure you are compliant with
                federal and state requirements. By following these steps and
                providing the necessary information, you can ensure compliance
                with all required payroll regulations.
              </Text>
              <br />
              <Text>
                Here are the key compliance requirements: <br />
                <List ordered>
                  <List.Item>Run payroll within the calendar year</List.Item>
                  <List.Item>Set a reasonable salary</List.Item>
                  <List.Item>Provide health insurance</List.Item>
                  <List.Item>
                    Pay payroll taxes (both federal and state)
                    <List>
                      <List.Item>
                        Specify the amount of federal payroll taxes
                      </List.Item>
                      <List.Item>
                        Specify the amount of state payroll taxes
                      </List.Item>
                    </List>
                  </List.Item>
                  <List.Item>
                    Complete state registration
                    <List bulleted>
                      <List.Item>
                        If applicable, provide a list of the states where
                        registration has been completed
                      </List.Item>
                    </List>
                  </List.Item>
                </List>
              </Text>
              <br />
              <Text>
                By following these steps and providing the necessary
                information, you can ensure compliance with all required payroll
                regulations.
              </Text>
              <br />
              <Text as={'h3'}>
                What are the implications if I haven&apos;t run payroll?
              </Text>
              <br />
              <Text>
                Fortunately, there is still enough time to run payroll before
                the end of the calendar year. Between the time you elected your
                S corp status and now, it is possible you may receive notices
                about missing wage reports. In that case, it would simply mean
                working with the state and the IRS to submit $0 fillings since
                payroll was not run for those periods.
              </Text>
              <br />
              <Text>
                Going forward, ensure payroll is set up and you are running it
                so that the payroll provider can prepare and submit these
                filings on your behalf. It is important to take immediate action
                to prevent any fines, penalties, and interest charges from the
                IRS when you file your annual tax return.
              </Text>
              <br />
              <Text as={'h3'}>
                How do I set up payroll if I haven’t already?
              </Text>
              <br />
              <Text>
                If you haven’t set up payroll for your therapy practice,{' '}
                <Link
                  newPage
                  style={{ textDecoration: 'underline' }}
                  href={'/payroll/welcome'}
                >
                  click here
                </Link>{' '}
                to access Heard Payroll. Feel free to{' '}
                <Link
                  newPage
                  style={{ textDecoration: 'underline' }}
                  href={
                    'https://www.joinheard.com/articles/how-to-set-up-payroll-for-your-therapy-practice'
                  }
                >
                  refer to this article
                </Link>{' '}
                for more information on how to set up Payroll for your Therapy
                Practice.
              </Text>
              <br />
              <Text as={'h3'}>
                How can I ensure Heard has all the documents needed for tax
                season?
              </Text>
              <br />
              <Text>
                Please take a moment to ensure that the following compliance
                documents are uploaded to your Heard account. If you have not
                already done so, please upload these documents as soon as
                possible.
              </Text>
              <br />
              <List ordered>
                <List.Item>
                  CP261 Notice - S corporation approval letter from the IRS
                </List.Item>
                <List.Item>Form 2553 - S corporation election</List.Item>
                <List.Item>CP575 - EIN letter from the IRS</List.Item>
              </List>
              <Text>
                If you are unable to find any of the necessary documents, feel
                free to reach out to the IRS at 1-800-829-4933. When contacting
                them, please ensure that you have your Employer Identification
                Number (EIN) handy. You can expect to receive these documents by
                mail within a specific timeframe after making your request.
              </Text>
            </div>
          </GridRowColumn>
          <GridRowColumn
            style={{ ...(isMobile ? { paddingTop: 0 } : { padding: 0 }) }}
          >
            <Link
              newPage
              href={
                'https://support.joinheard.com/hc/en-us/article_attachments/19203994461207'
              }
            >
              Payroll Audit Checklist
            </Link>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          fullWidth={isMobile}
          onClick={onSuccess}
          style={{ ...(isMobile ? { marginLeft: 0 } : {}) }}
        >
          I understand
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default PayrollComplianceModal
