import axios from 'axios'
import { keyBy } from 'lodash'

import {
  PlaidItem,
  receivePlaidItems,
} from '../reducers/finances/plaidItemReducer'
import { fetchIfNeededWrapper, fetchWrapper } from '../reducers/fetch'

// getPlaidItems
export const FETCH_PLAID_ITEM_KEY = 'FETCH_PLAID_ITEM_KEY'
export const fetchPlaidItemsIfNeeded = (alwaysFetch = false) =>
  fetchIfNeededWrapper({
    defaultErrorMessage:
      'There was an error fetching your connected Plaid accounts.',
    alwaysFetch,
    fetchKey: FETCH_PLAID_ITEM_KEY,
    fetchFunction: (dispatch) =>
      axios.get<PlaidItem[]>('/finances/api/v1/plaid/items').then((json) => {
        dispatch(receivePlaidItems(keyBy(json.data, 'id')))
        return json.data
      }),
  })

export const fetchPlaidItems = () =>
  fetchWrapper({
    defaultErrorMessage:
      'There was an error fetching your connected Plaid accounts.',
    fetchFunction: () =>
      axios.get<PlaidItem[]>('/finances/api/v1/plaid/items').then((json) => {
        return json.data
      }),
  })
