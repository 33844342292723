import axios from 'axios'

import { logSentryError } from './sentryHelpers'

export const getUploadSignedUrl = async (
  fileName: string,
  fileType: string
) => {
  const response = await axios.get<{ signed_url: string }>(
    '/finances/api/v1/documents/get_signed_url',
    { params: { fileName, fileType } }
  )
  return response.data.signed_url
}

export const uploadFile = async (file: File, fileName: string) => {
  try {
    if (file.size <= 0) {
      return { error: 'File size must be larger than 0 bytes.' }
    }
    const url = await getUploadSignedUrl(fileName, file.type)
    return axios
      .put<string>(url, file, {
        headers: {},
        withCredentials: false,
        transformRequest: [
          (data: File, headers) => {
            if (headers) {
              delete headers.common
            }
            headers['x-amz-server-side-encryption'] = 'AES256'
            return data
          },
        ],
      })
      .then(() => null)
      .catch((err) => {
        logSentryError(err)
        return { error: 'Error uploading file.' }
      })
  } catch (err) {
    logSentryError(err)
    return { error: 'Error uploading file.' }
  }
}

// Opens a new tab with sent url
export const openFileInTab = (url: string) => {
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.target = '_blank'
  anchor.rel = 'noopener noreferrer'
  anchor.style.display = 'none'
  document.body.appendChild(anchor)
  anchor.click()
}
