import React, {
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from 'react'
import {
  Header,
  Form,
  Dropdown,
  Button,
  DropdownProps,
  TextArea,
  TextAreaProps,
} from 'semantic-ui-react'
import GridRowWrapper from './shared/GridRowWrapper'

import {
  getCustomersForAm,
  recipientsPreviewForSelectedAm,
  recipientFormOptions,
} from '../service'
import { UserWithAdminInfo } from '../../../../reducers/admin/allUsersReducer'

interface RecipientsRowProps {
  activeCustomers: UserWithAdminInfo[]
  editingIndex: number | null
  selectedAmId?: number
  selectedRecipientsUserIds: number[]
  setEditingIndex: Dispatch<SetStateAction<number | null>>
  setSelectedAmId: Dispatch<SetStateAction<number | undefined>>
  setSelectedRecipientsUserIds: Dispatch<SetStateAction<number[]>>
  disableEditing: boolean
}

const COMPONENT_INDEX = 0
const renderMenuItemContent = (content: string, subHeader: string) => {
  return <Header as="h5" content={content} subheader={subHeader} />
}

const RecipientsRow: React.FC<RecipientsRowProps> = ({
  activeCustomers,
  editingIndex,
  selectedAmId,
  selectedRecipientsUserIds,
  setEditingIndex,
  setSelectedAmId,
  setSelectedRecipientsUserIds,
  disableEditing,
}) => {
  const [dropdownValue, setDropdownValue] = useState<number | undefined>(
    selectedAmId
  )
  const [localRecipientUserIds, setLocalRecipientUserIds] = useState<number[]>(
    selectedRecipientsUserIds || []
  )
  const buttonText = selectedAmId ? 'Edit Recipients' : 'Add Recipients'
  const options = recipientFormOptions(activeCustomers, renderMenuItemContent)

  const resetForm = useCallback(() => {
    setEditingIndex(null)
    setDropdownValue(selectedAmId)
    setLocalRecipientUserIds(selectedRecipientsUserIds)
  }, [
    setEditingIndex,
    setDropdownValue,
    setLocalRecipientUserIds,
    selectedRecipientsUserIds,
    selectedAmId,
  ])

  const onDropdownChange = useCallback(
    (_e: unknown, data: DropdownProps) => {
      if (data.value) {
        setDropdownValue(Number(data.value))
      } else {
        setDropdownValue(undefined)
        setLocalRecipientUserIds([])
      }
    },
    [setDropdownValue, setLocalRecipientUserIds]
  )

  const onTextAreaChange = useCallback(
    (_: unknown, { value }: TextAreaProps) => {
      if (typeof value === 'string') {
        const userArrays = value
          .split(',')
          .map((value) => Number(value))
          .filter((val) => !isNaN(val))
        setLocalRecipientUserIds(userArrays)
        setDropdownValue(undefined)
      }
    },
    [setLocalRecipientUserIds, setDropdownValue]
  )

  const onSaveClicked = useCallback(() => {
    if (dropdownValue) {
      setSelectedAmId(dropdownValue)
      setEditingIndex(null)
    }
    if (localRecipientUserIds) {
      setSelectedRecipientsUserIds(localRecipientUserIds)
      setEditingIndex(null)
    }
  }, [
    dropdownValue,
    setSelectedAmId,
    setEditingIndex,
    localRecipientUserIds,
    setSelectedRecipientsUserIds,
  ])

  useEffect(() => {
    setDropdownValue(selectedAmId)
  }, [selectedAmId])

  let subtitle = 'Who are you sending this message to?'
  if (selectedAmId === -1) {
    subtitle = 'All active customers'
  } else if (selectedAmId) {
    const selectedAmsCustomers = getCustomersForAm({
      amId: selectedAmId,
      customers: activeCustomers,
    })
    const customerPreview = recipientsPreviewForSelectedAm({
      customers: selectedAmsCustomers,
    })
    subtitle = customerPreview
  }

  return (
    <GridRowWrapper
      buttonHidden={editingIndex === COMPONENT_INDEX}
      buttonText={buttonText}
      buttonDisabled={editingIndex !== null || disableEditing}
      subtitle={subtitle}
      title="To"
      checked={
        typeof selectedAmId !== 'undefined' || localRecipientUserIds.length > 0
      }
      buttonOnClick={() => setEditingIndex(COMPONENT_INDEX)}
    >
      {editingIndex === COMPONENT_INDEX && (
        <Form style={{ width: '300px' }}>
          <Form.Field>
            <label>Audience</label>
            <Dropdown
              selection
              selectOnBlur={false}
              placeholder="Choose an audience"
              options={options}
              value={dropdownValue}
              onChange={onDropdownChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Otherwise, insert common separated list of User Ids</label>
            <TextArea
              placeholder='Insert like "1,2,3". Please do not add quotations mark around the user ids. Ask Eng if confused'
              value={localRecipientUserIds.toString()}
              onChange={onTextAreaChange}
            />
          </Form.Field>
          <Button
            className="form-button"
            disabled={!dropdownValue && !localRecipientUserIds}
            onClick={onSaveClicked}
            primary
          >
            Save
          </Button>
          <Button onClick={resetForm} basic>
            Cancel
          </Button>
        </Form>
      )}
    </GridRowWrapper>
  )
}

export default RecipientsRow
