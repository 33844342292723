import axios from 'axios'
import { keyBy } from 'lodash'

import { fetchWrapper } from '../reducers/fetch'
import {
  FinancialInsight,
  receiveFinancialInsights,
  receiveSingleFinancialInsight,
} from '../reducers/finances/financialInsightsReducer'

export const fetchAdminUserFinancialInsights = (userId: number | string) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error fetching insights for user.',
    fetchFunction: (dispatch) =>
      axios
        .get<
          FinancialInsight[]
        >(`/finances/api/v1/admin/financial_insights/${userId}`)
        .then((json) => {
          dispatch(receiveFinancialInsights(keyBy(json.data, 'id')))
          return json.data
        }),
  })

export type CreateFinancialInsightPayload = Omit<
  FinancialInsight,
  'id' | 'createdAt'
>

export const CREATE_ADMIN_USER_FINANCIAL_INSIGHTS_KEY =
  'CREATE_ADMIN_USER_FINANCIAL_INSIGHTS_KEY'
export const createAdminUserFinancialInsights = (
  userId: number,
  data: CreateFinancialInsightPayload
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an creating this insight for user.',
    fetchKey: CREATE_ADMIN_USER_FINANCIAL_INSIGHTS_KEY,
    fetchFunction: (dispatch) =>
      axios
        .post<FinancialInsight>(
          `/finances/api/v1/admin/financial_insights/${userId}`,
          data
        )
        .then((json) => {
          dispatch(receiveSingleFinancialInsight(json.data))
          return json.data
        }),
  })

export const updateAdminUserFinancialInsights = (
  userId: number,
  insightId: number,
  data: Partial<FinancialInsight>
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an creating this insight for user.',
    fetchFunction: (dispatch) =>
      axios
        // note that this returns all financial insights
        .post<FinancialInsight[]>(
          `/finances/api/v1/admin/financial_insights/${userId}/${insightId}`,
          data
        )
        .then((json) => {
          dispatch(receiveFinancialInsights(json.data))
          return json.data
        }),
  })
