import { Grid, Loader } from 'semantic-ui-react'
import { fetchAllocationGuidesIfNeeded } from '../../actions/allocationGuideActions'
import { fetchAllocationRulesIfNeeded } from '../../actions/allocationRuleActions'
import { fetchFinancialAccountsIfNeeded } from '../../actions/financialAccountActions'
import AllocationGuidesTable from './AllocationGuidesTable'
import AllocationRulesCard from './AllocationRulesCard'
import AllocationGuideSummary from './AllocationGuideSummary'
import CreateAllocationCard from './CreateAllocationCard'
import CreateAllocationGuideCard from './CreateAllocationGuideCard'
import { useEffect, useState } from 'react'
import {
  getAllocationGuides,
  getAllocationRules,
} from '../../selectors/allocationGuideSelectors'
import { Card, GridRowColumn, Link, Text } from '../BaseComponents'
import { useReselector, useToggle } from '../../utils/sharedHooks'
import { selectFinancialAccounts } from '../../selectors/financeSelectors'
import { useAnalyticsView } from '../../features/Amplitude'
import PageHeader from '../shared/PageHeader'
import { BannerCard } from '../BaseComponents/BannerCard'
import { track } from '@amplitude/analytics-browser'
import { useAppDispatch } from '../../utils/typeHelpers'

const AllocationGuidePanel = () => {
  const dispatch = useAppDispatch()

  const allocationGuides = useReselector(getAllocationGuides)
  const allocationRules = useReselector(getAllocationRules)
  const financialAccounts = useReselector(selectFinancialAccounts)
  const [displayBanner, toggleBanner] = useToggle(true)

  const noRulesSet = Object.keys(allocationRules).length === 0
  const noFinancialAccounts = Object.keys(financialAccounts).length === 0

  const [loading, setLoading] = useState(true)

  const pageView = useAnalyticsView()

  useEffect(() => {
    pageView('allocation guide')
  }, [pageView])

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchAllocationGuidesIfNeeded())
      await dispatch(fetchAllocationRulesIfNeeded())
      await dispatch(fetchFinancialAccountsIfNeeded())
      setLoading(false)
    }
    fetch()
  }, [dispatch])

  const closeBanner = () => {
    toggleBanner()
    track('closed allocation guide banner')
  }
  const renderRightPanel = () => {
    // This means that the user has not set up any allocation rules
    if (loading) {
      return <Loader inline active />
    }

    /* If user has no financial accounts, we encourage them to set it up*/
    if (noFinancialAccounts) {
      return null
    } else if (noRulesSet) {
      return <CreateAllocationCard />
    } else {
      return (
        <>
          <AllocationGuideSummary />
          <CreateAllocationGuideCard />
        </>
      )
    }
  }

  /*
    If a user has no financial accounts, we encourage them to:
    1. set it up
    2. If they cannot set it up and their account is managed outside of Heard, to message the bookkeeper to help them complete
  */
  const renderAllocationBody = () => {
    if (noFinancialAccounts) {
      return (
        <Card type="section">
          <Text as="h3">
            It looks there are no financial accounts connected to Heard.
          </Text>
          <br />
          <Text as="bodyMd">
            Without connected accounts or insight into your business profits, we
            are unable to provide you with an allocation guide at this time.
          </Text>
          <br />
          <Text as="bodyMd">
            To get set up, head over to your{' '}
            <Link to={'/accounts#connected-institutions'}>Accounts page</Link>{' '}
            and connect your business bank account(s) and/or business credit
            card(s).
          </Text>
          <br />
          <Text as="h3">
            What if my bank accounts can&#39;t connect to Heard, and my
            accountant suggested an alternate solution?
          </Text>
          <br />
          <Text>
            <Link to="/conversations" size="small">
              Message the Heard Team
            </Link>{' '}
            and they will get you set up with your Allocation Guide!
          </Text>
        </Card>
      )
    }

    return (
      <>
        {!noRulesSet && <AllocationRulesCard />}
        <br />
        <Text as="h3">Past Allocations:</Text>
        <br />
        <AllocationGuidesTable allocationGuides={allocationGuides} />
      </>
    )
  }

  return (
    <div id="allocationGuidePanel">
      <PageHeader header="Allocation Guides" />
      <Grid doubling stackable>
        <GridRowColumn>
          {displayBanner && (
            <BannerCard
              headerText="What is an Allocation Guide?"
              imageUrl="https://heard-images.s3.us-west-1.amazonaws.com/assets/piggy_bank_allocation.svg"
              onClose={closeBanner}
              bodyContent={
                <div>
                  <Text as="bodyMd" color="forest">
                    Your Allocation Guide helps calculate how much money you
                    should set aside in order to stay on track with your saving
                    goals. Let us know how you&apos;d like to distribute your
                    business profit, and we&apos;ll provide you with estimates
                    each month on how much to allocate based on your profit.
                    <br />
                  </Text>
                  <br />
                  <Text as="bodyMd" color="forest">
                    Note: Unfortunately, Heard can’t move your money for you.
                    You will need to transfer your money manually.
                  </Text>{' '}
                </div>
              }
            />
          )}
        </GridRowColumn>
        {noRulesSet && <CreateAllocationCard />}
        {!noRulesSet && (
          <Grid.Row>
            <Grid.Column width={10}>
              <Card type="subsection" backgroundColor="stone40">
                {renderAllocationBody()}
              </Card>
            </Grid.Column>
            <Grid.Column width={6}>{renderRightPanel()}</Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </div>
  )
}

export default AllocationGuidePanel
