import { useState, useCallback, useMemo } from 'react'
import { TransactionOrSplit } from '../../../reducers/admin/allTransactions.slice'
import { Input } from '../../../components/BaseComponents'
import { updateUserTransaction } from '../transactions.slice'
import { debounce } from 'lodash'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface TransactionInputProps {
  transaction: TransactionOrSplit
  nickname?: boolean
}

export const TransactionInput = ({
  transaction,
  nickname = true,
}: TransactionInputProps) => {
  const [value, setValue] = useState(
    nickname ? transaction.nickname : transaction.description
  )
  const dispatch = useAppDispatch()

  const debouncedUpdateTransaction = useMemo(
    () =>
      debounce((newValue: string | undefined) => {
        let data = {}
        if (nickname) {
          if ((transaction.nickname || '') === (newValue || '')) return
          data = { nickname: newValue }
        } else {
          if ((transaction.description || '') === (newValue || '')) return
          data = { description: newValue }
        }

        updateUserTransaction(transaction.id, data)(dispatch)
      }, 1000),
    [
      nickname,
      transaction.nickname,
      transaction.description,
      transaction.id,
      dispatch,
    ]
  )

  const handleChange = useCallback(
    (newValue: string | undefined) => {
      setValue(newValue)
      debouncedUpdateTransaction(newValue)
    },
    [debouncedUpdateTransaction]
  )

  return (
    <Input
      value={value ?? ''}
      placeholder={`Enter a ${nickname ? 'nickname' : 'description'}`}
      onChange={handleChange}
      fullWidth
    />
  )
}
