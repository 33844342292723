import { convertToRaw, EditorState, Modifier, RichUtils } from 'draft-js'
import draftToHtml from 'draftjs-to-html'

import { RoleName } from '../../reducers/admin/allRolesReducer'

export const convertDraftToCommentBody = (editorState: EditorState) => {
  const currentContent = editorState.getCurrentContent()
  return {
    // `\u0001` removes line breaks
    body: currentContent.getPlainText('\u0001'),
    htmlBody: draftToHtml(convertToRaw(currentContent)),
  }
}

export const DEFAULT_HEARD_ADMIN_NAME = 'Heard Team'
export const DEFAULT_HEARD_ADMIN_ICON =
  'https://heard-images.s3.amazonaws.com/assets/user-icon.png'
export const DEFAULT_SUBJECT = '(No subject)'

export const getRoleNameText = (roleName?: RoleName) =>
  roleName === RoleName.AccountManager ? 'Accountant' : roleName || ''

// Draft js doesn't have a "clear" method so it requires `getResetEditorState` to reset the state.
// The initial instance should be created via `EditorState.createEmpty()` but if it's cleared reset with `getResetEditorState`
// Reason - it has listeners on it so if it's just replaced with a new editor state the listeners will throw errors
// This only seems to happen if you attempt to edit at the same time as the clear occurs

// https://github.com/jpuri/draftjs-utils/blob/master/js/block.js
const removeSelectedBlocksStyle = (editorState: EditorState) => {
  const newContentState = RichUtils.tryToRemoveBlockStyle(editorState)
  if (newContentState) {
    return EditorState.push(editorState, newContentState, 'change-block-type')
  }
  return editorState
}

// https://github.com/jpuri/draftjs-utils/blob/master/js/block.js
export const getResetEditorState = (editorState: EditorState) => {
  const blocks = editorState.getCurrentContent().getBlockMap().toList()
  const updatedSelection = editorState.getSelection().merge({
    anchorKey: blocks.first().get('key'),
    anchorOffset: 0,
    focusKey: blocks.last().get('key'),
    focusOffset: blocks.last().getLength(),
  })
  const newContentState = Modifier.removeRange(
    editorState.getCurrentContent(),
    updatedSelection,
    'forward'
  )

  const newState = EditorState.push(
    editorState,
    newContentState,
    'remove-range'
  )

  return removeSelectedBlocksStyle(newState)
}
