import { Label, List } from 'semantic-ui-react'
import { centsToDollars } from '../../../utils/currencyHelpers'

import CurrencyFormatLabel from '../../shared/CurrencyFormatLabel'
import moment from 'moment'
import {
  FILING_STATUSES,
  TAX_ENTITY_TYPES,
} from '../../../features/Taxes/taxConstants'
import { FinancialProfile } from '../../../reducers/auth/userReducer'
import { getUserById } from '../../../selectors/user.selectors'
import { useReselector } from '../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../features/Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { Link } from '../../BaseComponents'
import {
  getUserDocumentById,
  selectAdminTaxReturnDocumentsForTaxYear,
} from '../../../features/UserDocuments/userDocuments.selector'
import { DATE_FORMATS } from '../../../utils/dateHelpers'

export const TaxProfileContent = ({
  userId,
  profile,
}: {
  userId: number
  profile?: FinancialProfile
}) => {
  const mostRecentUpdateYear = profile?.updatedAt
    ? moment(profile?.updatedAt).year()
    : moment().year()
  const user = useReselector(getUserById, userId)
  const adminSpousePaystub = useReselector(
    getUserDocumentById,
    user?.financialProfile?.spousePaystubDocumentId
  )
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const previousTaxYear = Number(currentTaxYear) - 1

  const pytrDocuments = useReselector(
    selectAdminTaxReturnDocumentsForTaxYear,
    user?.financialProfile?.id ?? -1,
    previousTaxYear.toString()
  )

  const deductionText = profile?.itemizedDeduction ? 'Itemized' : 'Standard'
  return (
    <>
      <List relaxed="very" divided verticalAlign="middle">
        <Label>How they file</Label>
        <List.Item>
          <List.Content floated="right">{profile?.filingStatus}</List.Content>
          <List.Header>Filing Status</List.Header>
        </List.Item>
        {profile?.filingStatus !== FILING_STATUSES.single && (
          <List.Item>
            <List.Content floated="right">
              {profile?.numberOfDependents}
            </List.Content>
            <List.Header>No. of Dependents</List.Header>
          </List.Item>
        )}
        <List.Item>
          <List.Content floated="right">{profile?.taxEntityType}</List.Content>
          <List.Header>Tax Entity Type</List.Header>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            {profile?.wIncomeInCents !== null && (
              <CurrencyFormatLabel
                value={centsToDollars(profile?.wIncomeInCents)}
              />
            )}
          </List.Content>
          <List.Header>YTD W-2 Income</List.Header>
        </List.Item>
        {profile?.taxEntityType === TAX_ENTITY_TYPES.form_1120_s && (
          <List.Item>
            <List.Content floated="right">
              {profile?.scorpPracticeWIncomeInCents !== null && (
                <CurrencyFormatLabel
                  value={centsToDollars(profile?.scorpPracticeWIncomeInCents)}
                />
              )}
            </List.Content>
            <List.Header>YTD W-2 Income from Private Practice</List.Header>
          </List.Item>
        )}
        <List.Item>
          <List.Content floated="right">
            {profile?.otherIndividualIncomeInCents !== null && (
              <CurrencyFormatLabel
                value={centsToDollars(profile?.otherIndividualIncomeInCents)}
              />
            )}
          </List.Content>
          <List.Header>YTD 1099 Income</List.Header>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            {profile?.federalWithholdingInCents !== null && (
              <CurrencyFormatLabel
                value={centsToDollars(profile?.federalWithholdingInCents)}
              />
            )}
          </List.Content>
          <List.Header>
            YTD Federal Withholding ({mostRecentUpdateYear})
          </List.Header>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            {profile?.stateWithholdingInCents !== null && (
              <CurrencyFormatLabel
                value={centsToDollars(profile?.stateWithholdingInCents)}
              />
            )}
          </List.Content>
          <List.Header>
            YTD State Withholding ({mostRecentUpdateYear})
          </List.Header>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            {profile?.itemizedDeduction === null ? '' : deductionText}
          </List.Content>
          <List.Header>Deduction Type</List.Header>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            {profile?.deductionInCents !== null && (
              <CurrencyFormatLabel
                value={centsToDollars(profile?.deductionInCents)}
              />
            )}
          </List.Content>
          <List.Header>Deduction Amount</List.Header>
        </List.Item>
        {profile?.filingStatus === FILING_STATUSES.married_filing_jointly && (
          <>
            <List.Item>
              <List.Content floated="right">
                {profile?.spouseFirstName}
              </List.Content>
              <List.Header>Spouse&apos;s First Name</List.Header>
            </List.Item>
            <List.Item>
              <List.Content floated="right">
                {profile?.spouseLastName}
              </List.Content>
              <List.Header>Spouse&apos;s Last Name</List.Header>
            </List.Item>
            <List.Item>
              <List.Content floated="right">
                {profile?.spouseWIncomeInCents !== null && (
                  <CurrencyFormatLabel
                    value={centsToDollars(profile?.spouseWIncomeInCents)}
                  />
                )}
              </List.Content>
              <List.Header>Spouse&apos;s YTD W-2 Income</List.Header>
            </List.Item>
            <List.Item>
              <List.Content floated="right">
                {profile?.spouseIndividualIncomeInCents !== null && (
                  <CurrencyFormatLabel
                    value={centsToDollars(
                      profile?.spouseIndividualIncomeInCents
                    )}
                  />
                )}
              </List.Content>
              <List.Header>Spouse&apos;s YTD 1099 Income</List.Header>
            </List.Item>
            <List.Item>
              <List.Content floated="right">
                {profile?.spouseFederalWithholdingInCents !== null && (
                  <CurrencyFormatLabel
                    value={centsToDollars(
                      profile?.spouseFederalWithholdingInCents
                    )}
                  />
                )}
              </List.Content>
              <List.Header>
                Spouse&apos;s YTD Federal Withholding ({mostRecentUpdateYear})
              </List.Header>
            </List.Item>
            <List.Item>
              <List.Content floated="right">
                {profile?.spouseStateWithholdingInCents !== null && (
                  <CurrencyFormatLabel
                    value={centsToDollars(
                      profile?.spouseStateWithholdingInCents
                    )}
                  />
                )}
              </List.Content>
              <List.Header>
                Spouse&apos;s YTD State Withholding ({mostRecentUpdateYear})
              </List.Header>
            </List.Item>
            <List.Item>
              <List.Content floated="right">
                {adminSpousePaystub && (
                  <a
                    rel="noreferrer"
                    href={adminSpousePaystub.signedUrl || '#'}
                    target="_blank"
                  >
                    {adminSpousePaystub.fileDescription}
                  </a>
                )}
              </List.Content>
              <List.Header>Spouse&apos;s Paystub</List.Header>
            </List.Item>
          </>
        )}
      </List>
      <br />
      <List relaxed="very" divided verticalAlign="middle">
        <Label>Where they file</Label>
        <List.Item>
          <List.Content floated="right">
            {profile?.filingStates !== null
              ? profile?.filingStates?.join(', ')
              : 'no home state or %-income/state'}
          </List.Content>
          <List.Header>
            Filing States ({profile?.filingStates?.length})
          </List.Header>
          <i>(derived from home state and % income/state)</i>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            {profile?.relocatedThisQuarter !== null &&
              profile?.relocatedThisQuarter &&
              'Yes'}
            {profile?.relocatedThisQuarter !== null &&
              !profile?.relocatedThisQuarter &&
              'No'}
          </List.Content>
          <List.Header>Did move states this quarter?</List.Header>
        </List.Item>
        {profile?.relocatedPreviousState && (
          <>
            <List.Item>
              <List.Content floated="right">
                {profile?.relocatedPreviousState}
              </List.Content>
              <List.Header>State they moved away from</List.Header>
            </List.Item>
            <List.Item>
              <List.Content floated="right">
                {profile?.relocatedPreviousState &&
                  moment
                    .utc(profile?.relocatedPreviousStateAt)
                    .format(DATE_FORMATS.DISPLAY_SHORT)}
                {!profile?.relocatedPreviousState && ''}
              </List.Content>
              <List.Header>Date they moved</List.Header>
            </List.Item>
          </>
        )}
        <List.Item>
          <List.Content floated="right">{profile?.homeState}</List.Content>
          <List.Header>State of Residence</List.Header>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            {profile?.scorpRegistrationState}
          </List.Content>
          <List.Header>Business Registration State</List.Header>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            {profile?.sawMultistateClientsThisQuarter !== null &&
              profile?.sawMultistateClientsThisQuarter &&
              'Yes'}
            {profile?.sawMultistateClientsThisQuarter !== null &&
              !profile?.sawMultistateClientsThisQuarter &&
              'No'}
          </List.Content>
          <List.Header>Did see clients in other states?</List.Header>
        </List.Item>
        {profile?.sawMultistateClientsThisQuarter && (
          <List.Item>
            <List.Content floated="right">
              {profile?.percentageIncomePerState?.map((row) => (
                <div key={`state-income-${row.state}`}>
                  {row.state} | {row.value}%
                </div>
              ))}
            </List.Content>
            <List.Header>Percent Income per State</List.Header>
          </List.Item>
        )}
      </List>
      <List relaxed="very" divided verticalAlign="middle">
        <Label>Additional Resources</Label>
        <List.Item>
          <List.Content floated="right">
            {pytrDocuments.length === 0 && 'not uploaded'}
            {pytrDocuments.map((pytr) => (
              <Link key={pytr.id} href={pytr.signedUrl || '#'} newPage>
                {pytr.fileDescription}
              </Link>
            ))}
          </List.Content>
          <List.Header>Prior Year Tax Return ({previousTaxYear})</List.Header>
        </List.Item>
      </List>
    </>
  )
}
