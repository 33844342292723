import {
  Component,
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Divider, Grid } from 'semantic-ui-react'
import { times } from 'lodash'
import parse from 'html-react-parser'
import { DateTime } from 'luxon'

import PayrollSetupList from './PayrollSetupList'
import {
  fetchEmployees,
  fetchEmployeeStateTaxes,
  putUpdateEmployeeStateTaxes,
  PUT_UPDATE_EMPLOYEE_STATE_TAXES_KEY,
} from '../payrollActions'
import { filterNulls, useAppDispatch } from '../../../utils/typeHelpers'
import { GEP_ENROLL_PATHS } from '../helpers'
import PrefilledNoticeContainer from './PrefilledNoticeContainer'
import PayrollError from '../PayrollError'
import {
  Button,
  Card,
  DatePicker,
  Dropdown,
  GridRowColumn,
  Input,
  Text,
} from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { selectFirstEmployee } from '../payroll.selectors'
import { getIsFetching } from '../../../reducers/fetch'
import {
  Gusto_EmployeeStateTaxes,
  Gusto_EmployeeStateTaxesInput,
  Gusto_EmployeeStateTaxesQuestion,
} from '../generated_gusto_types'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { shortNames } from '../../../constants/statesShortNamesConstants'

// The employee state question api is a bit unusual
// 1. We fetch the questions `fetchEmployeeStateTaxes` (payload `EmployeeStateTaxes`).  This payload will be different per US state
// 2. We render the questions from that response within this component based on that payload
// 3. Once this page is submitted `getAnswer` is called on each question.  `StateTaxInput` will send the exact question
// payload it was given but with the answer included
// 4. Those are posted via `putUpdateEmployeeStateTaxes`

interface TaxProps {
  question: Gusto_EmployeeStateTaxesQuestion
}

interface TaxState {
  answer: string | boolean | number | undefined
}

class StateTaxInput extends Component<TaxProps, TaxState> {
  constructor(props: TaxProps) {
    super(props)

    this.state = {
      answer: this.props.question.answers[0]?.value,
    }
  }

  getAnswer() {
    if (this.state.answer === undefined) {
      return null
    }

    return {
      key: this.props.question.key,
      answers: [
        {
          value: this.state.answer,
          valid_from: '2010-01-01',
          valid_up_to: null,
        },
      ],
    }
  }

  renderFormInput() {
    const { question } = this.props

    const description = question.description
      ?.replaceAll('#{year}', DateTime.now().year.toString())
      ?.replaceAll('#{nextYear}', (DateTime.now().year + 1).toString())

    const formatType = question.input_question_format.type

    const inputProps = {
      fullWidth: true,
      label: question.label,
      description: description && parse(description),
    }

    if (formatType === 'Select') {
      return (
        <Dropdown
          options={question.input_question_format.options?.map((option) => ({
            text: option.label,
            value: option.value,
          }))}
          value={this.state.answer}
          onChange={(value) => this.setState({ answer: value })}
          {...inputProps}
        />
      )
    } else if (formatType === 'Date') {
      return (
        <DatePicker
          value={(this.state.answer || '').toString()}
          onChange={(value) => this.setState({ answer: value })}
          dateFormat={DATE_FORMATS.GUSTO_SUBMIT}
          {...inputProps}
        />
      )
    } else {
      let componentType

      if (formatType === 'Currency') {
        componentType = 'currency' as const
      }

      return (
        <Input
          value={(this.state.answer === undefined
            ? ''
            : this.state.answer
          ).toString()}
          onChange={(value) => this.setState({ answer: value })}
          componentType={componentType}
          {...inputProps}
        />
      )
    }
  }

  render() {
    return <GridRowColumn>{this.renderFormInput()}</GridRowColumn>
  }
}

export type StateTaxSectionHandle = {
  getQuestionAnswers: () => Gusto_EmployeeStateTaxesInput['states'][number]
}
export const StateTaxSection = forwardRef<
  StateTaxSectionHandle,
  { taxesForSingleState: Gusto_EmployeeStateTaxes }
>(({ taxesForSingleState }, ref) => {
  const questionRefs = useMemo(
    () =>
      times(taxesForSingleState.questions.length).map(() =>
        createRef<StateTaxInput>()
      ),
    [taxesForSingleState.questions.length]
  )

  const getQuestionAnswers = useCallback(
    () => ({
      state: taxesForSingleState.state,
      questions: filterNulls(
        questionRefs.map((ref) => ref.current?.getAnswer())
      ),
    }),
    [questionRefs, taxesForSingleState.state]
  )

  useImperativeHandle(ref, () => ({ getQuestionAnswers }))

  return (
    <>
      <GridRowColumn>
        <Text as="h3">
          {shortNames[taxesForSingleState.state] || taxesForSingleState.state}
        </Text>
      </GridRowColumn>
      {taxesForSingleState.questions.map((question, index) => {
        if (!questionRefs[index]) {
          return null
        }
        return (
          <GridRowColumn key={question.key}>
            <StateTaxInput question={question} ref={questionRefs[index]} />
          </GridRowColumn>
        )
      })}
    </>
  )
})

const StateTaxForm = ({
  stateTaxes,
}: {
  stateTaxes: Gusto_EmployeeStateTaxes[]
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isPosting = useReselector(
    getIsFetching,
    PUT_UPDATE_EMPLOYEE_STATE_TAXES_KEY
  )

  const employeeUuid = stateTaxes[0]?.employee_uuid

  const stateSectionRefs = useMemo(
    () =>
      times(stateTaxes.length).map(() => createRef<StateTaxSectionHandle>()),
    [stateTaxes.length]
  )

  const submit = useCallback(async () => {
    const payload = {
      states: filterNulls(
        stateSectionRefs.map((section) => section.current?.getQuestionAnswers())
      ),
    }

    const res = await putUpdateEmployeeStateTaxes(
      employeeUuid,
      payload
    )(dispatch)

    if (res) {
      navigate(GEP_ENROLL_PATHS.employeeBank)
    }
  }, [dispatch, navigate, stateSectionRefs, employeeUuid])

  return (
    <Grid>
      <GridRowColumn>
        <Text as="h2">Add yourself as an employee</Text>
      </GridRowColumn>
      <Divider />
      <GridRowColumn>
        <Text as="h2">Enter Employee State Tax Information</Text>
      </GridRowColumn>
      <PayrollError fetchKey={PUT_UPDATE_EMPLOYEE_STATE_TAXES_KEY} />

      {stateTaxes.map((taxesForSingleState, index) => {
        if (!stateSectionRefs[index]) {
          return null
        }
        return (
          <StateTaxSection
            key={taxesForSingleState.state}
            ref={stateSectionRefs[index]}
            taxesForSingleState={taxesForSingleState}
          />
        )
      })}
      <Grid.Row />
      <Grid.Row>
        <Grid.Column width={6}>
          <Button
            variant="secondary"
            fullWidth
            onClick={() => navigate(GEP_ENROLL_PATHS.employeeFederal)}
          >
            Back
          </Button>
        </Grid.Column>
        <Grid.Column width={4} />
        <Grid.Column width={6}>
          <Button fullWidth onClick={submit} loading={isPosting}>
            Save & Continue
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const PayrollEmployeeState = () => {
  const dispatch = useAppDispatch()
  const [stateTaxes, setStateTaxes] = useState<Gusto_EmployeeStateTaxes[]>()
  const firstEmployee = useReselector(selectFirstEmployee)

  useEffect(() => {
    dispatch(fetchEmployees())
  }, [dispatch])

  useEffect(() => {
    const fetch = async () => {
      if (!firstEmployee?.uuid) {
        return
      }

      const stateTaxRes = await fetchEmployeeStateTaxes(firstEmployee?.uuid)(
        dispatch
      )

      if (stateTaxRes?.length) {
        setStateTaxes(stateTaxRes)
      }
    }

    fetch()
  }, [dispatch, firstEmployee?.uuid])

  return (
    <Container>
      <Card>
        <Grid>
          <GridRowColumn>
            <Text as="h1">Setting Up Payroll</Text>
          </GridRowColumn>
          <Divider />
          <PrefilledNoticeContainer />
          <Grid.Row>
            <Grid.Column width={9}>
              {stateTaxes && <StateTaxForm stateTaxes={stateTaxes} />}
            </Grid.Column>
            <Grid.Column width={7}>
              <PayrollSetupList />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollEmployeeState
