import { createSelector } from 'reselect'
import axios from 'axios'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { keyBy, sortBy } from 'lodash'
import moment from 'moment'

import { fetchIfNeededWrapper, fetchWrapper } from './fetch'
import { ReduxState } from '../utils/typeHelpers'
import { centsToCurrency } from '../utils/currencyHelpers'
import { fetchStripePlans } from '../constants/pricingConstants'

export const SUBSCRIPTION_DEFAULT_KEY = 'default'

interface SubscriptionInvoice {
  id: string
  invoiceUrl: string
  amountPaid: number
  created: number
}

interface StripeDiscount {
  id: string
}

export interface SubscriptionState {
  [key: string]: {
    id: string
    name: string
    startDate: number
    currentBillingStartDate: number
    nextBillingDate: number
    productId: string
    items: {
      [key: string]: {
        id: string
        name: string
        amountInCents: number
        quantity: number
        interval: StripeInterval
      }
    }
    invoices: {
      [key: string]: SubscriptionInvoice
    }
    discounts: Array<string | StripeDiscount>
    status: string
  }
}

const initialState: SubscriptionState = {}

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    receiveSubscriptions: (
      state,
      action: PayloadAction<SubscriptionResponse>
    ) => {
      for (const sub of action.payload.subscriptions) {
        state[sub.product.name] = {
          id: sub.subscription.id,
          name: sub.product.name,
          startDate: sub.subscription.start_date,
          currentBillingStartDate: sub.subscription.current_period_start,
          nextBillingDate: sub.subscription.current_period_end,
          productId: sub.product.id,
          items: keyBy(
            sub.subscription.items.data.map((item) => ({
              id: item.id,
              amountInCents: item.plan.amount,
              quantity: item.quantity,
              name: item.plan.nickname || SUBSCRIPTION_DEFAULT_KEY,
              interval: item.plan.interval,
            })),
            (item) => item.name
          ),
          invoices: keyBy(
            sub.invoices.data.map((item) => ({
              id: item.id,
              invoiceUrl: item.hosted_invoice_url,
              amountPaid: item.amount_paid,
              created: item.created,
            })),
            (item) => item.id
          ),
          discounts: sub.subscription.discounts,
          status: sub.subscription.status,
        }
      }
    },
  },
})

export type StripeSubscription = {
  id: string
  items: {
    data: Array<{
      id: string
      plan: {
        id: string
        amount: number
        nickname: string | null
        interval: StripeInterval
      }
      quantity: number
    }>
  }
  plan: {
    product: string
  } | null
  discounts: Array<string | StripeDiscount>
  start_date: number
  current_period_end: number
  current_period_start: number
  status: string
}

export enum StripeInterval {
  month = 'month',
  year = 'year',
  week = 'week',
  day = 'day',
}

// A ton of data comes back with this so it is not fully typed
interface SubscriptionResponse {
  subscriptions: Array<{
    subscription: StripeSubscription
    product: {
      id: string
      name: string
    }
    invoices: {
      data: Array<{
        id: string
        hosted_invoice_url: string
        amount_paid: number
        created: number
      }>
    }
  }>
}

export default subscriptionSlice.reducer

const { receiveSubscriptions } = subscriptionSlice.actions

export const FETCH_SUBSCRIPTIONS_KEY = 'FETCH_SUBSCRIPTIONS_KEY'
export const fetchSubscriptions = () =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_SUBSCRIPTIONS_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<SubscriptionResponse>(
        '/finances/api/v1/stripe/subscriptions'
      )

      dispatch(receiveSubscriptions(json.data))
    },
  })

export interface InvoiceResponse {
  id: string
  hosted_invoice_url: string
  amount_paid: number
  created: number
}

export const fetchInvoices = (params: { starting_after_invoice_id?: string }) =>
  fetchWrapper({
    fetchFunction: async () => {
      const json = await axios.get<{
        data: InvoiceResponse[]
        has_more: boolean
      }>('/finances/api/v1/stripe/invoices', { params })

      return json.data
    },
  })

const HEARD_PRODUCT_BASE_PLAN_NAME = 'default'

const HEARD_PAYROLL_SUB_NAME = 'Heard Payroll'
const HEARD_PAYROLL_PER_PERSON_NAME = 'Per Seat Pricing'
const HEARD_PAYROLL_BASE_PLAN_NAME = 'Base Package Fee'

export const selectSubscription = (state: ReduxState) => state.subscription
export const selectAllInvoices = createSelector(selectSubscription, (subs) =>
  sortBy(
    Object.values(subs).flatMap((sub) => Object.values(sub.invoices)),
    'created'
  )
)

export const selectHeardProductSubscription = createSelector(
  selectSubscription,
  (subs) =>
    // Heard product subscriptions can have a lot of names so just search for the one that's not payroll
    {
      return Object.values(subs).find(
        (sub) => sub.name !== HEARD_PAYROLL_SUB_NAME
      )
    }
)

export const selectHeardPayrollSubscription = createSelector(
  selectSubscription,
  (subs) => (subs[HEARD_PAYROLL_SUB_NAME] ? subs[HEARD_PAYROLL_SUB_NAME] : null)
)
const selectGepPerPersonSub = createSelector(
  selectHeardPayrollSubscription,
  (sub) =>
    sub?.items[HEARD_PAYROLL_PER_PERSON_NAME]
      ? sub.items[HEARD_PAYROLL_PER_PERSON_NAME]
      : null
)

export const selectGepBasePrice = createSelector(
  selectHeardPayrollSubscription,
  (sub) =>
    sub?.items[HEARD_PAYROLL_BASE_PLAN_NAME]
      ? sub.items[HEARD_PAYROLL_BASE_PLAN_NAME].amountInCents
      : null
)

export const selectGepPerPersonPrice = createSelector(
  selectGepPerPersonSub,
  (sub) => sub?.amountInCents
)

export const selectHeardProductPlanPrice = createSelector(
  selectHeardProductSubscription,
  (sub) =>
    sub?.items[HEARD_PRODUCT_BASE_PLAN_NAME]
      ? sub.items[HEARD_PRODUCT_BASE_PLAN_NAME].amountInCents
      : null
)

export const selectHeardProductPlanInterval = createSelector(
  selectHeardProductSubscription,
  (sub) =>
    sub?.items[HEARD_PRODUCT_BASE_PLAN_NAME]
      ? sub.items[HEARD_PRODUCT_BASE_PLAN_NAME].interval
      : null
)

export const selectTotalSubscriptionPrice = createSelector(
  selectHeardProductPlanPrice,
  selectGepBasePrice,
  selectGepPerPersonSub,
  (productPrice, basePrice, perPersonSub) => {
    const productPerMonthPrice = centsToCurrency(productPrice).divide(12)
    const personPrice = centsToCurrency(perPersonSub?.amountInCents).multiply(
      perPersonSub?.quantity || 0
    )

    return productPerMonthPrice.add(centsToCurrency(basePrice)).add(personPrice)
      .intValue
  }
)

/**
 * This function computes the days until a customer's account is suspended. In Stripe, a
 * subscription is marked `past_due` if an auto-renewing subscription fails to charge the
 * specified payment method (for us it'll be the user's default payment method) when auto
 * renewing the subscription. As configured in our console, Stripe will automatically attempt
 * to renew the subscription and charge the payment method for 14 days, after which the
 * subscription will be marked as `unpaid`, and we will suspend the user's access to the core
 * features of Heard (minus messaging).
 */
export const selectDaysUntilSuspension = createSelector(
  selectSubscription,
  (subscription) => {
    const subscriptions = Object.values(subscription)

    // notify the caller if there is already an unpaid subscription
    if (subscriptions.find((sub) => sub.status === 'unpaid')) {
      return -1
    }

    // filter subscriptions by `past_due` status and sort by the start date of the current billing
    // period
    const pastDueSubscriptions = subscriptions
      .filter((sub) => sub.status === 'past_due')
      .sort(
        (sub1, sub2) =>
          sub1.currentBillingStartDate - sub2.currentBillingStartDate
      )

    // the first subscription item in the collection will be the earliest subscription that was
    // marked `past_due` and will eventually be the first subscription that will be marked `unpaid`
    const earliestPastDueSubscriptionTimestamp =
      pastDueSubscriptions[0]?.currentBillingStartDate ?? -1

    // subtract the number of days since that earliest subscription became `past_due` and subtract
    // that difference in days from 14 to get the number of days remaining before the account is
    // officially suspended
    return earliestPastDueSubscriptionTimestamp !== -1
      ? 14 -
          moment().diff(
            moment.unix(earliestPastDueSubscriptionTimestamp),
            'days'
          )
      : -1
  }
)

export const selectProductIds = createSelector(
  selectSubscription,
  (subscription) => Object.values(subscription).map((s) => s.productId)
)

const stripePlans = fetchStripePlans()

export const selectIsBasicPlan = createSelector(
  selectProductIds,
  (stripeProductIds) =>
    stripeProductIds.includes(stripePlans.basic_group.productId || '') ||
    stripeProductIds.includes(stripePlans.basic_solo.productId || '')
)

// For 2023, S corps who signed up before 10/23 (using an old Stripe product) have to pay an additional $450.
// That's why we are differentiating between old and new S corp Stripe products in this selector. We have to hardcode
// ids for 2023 b/c we are in the process of sunsetting old Stripe products. Once users have been migrated to the new
// plans (anticipation is early 2024), we can modify or remove this selector
export const getIsScorpNewSubscription = (stripeProductIds: string[]) =>
  stripeProductIds.some((id) =>
    [
      // staging
      'prod_Ol4unCLsUQkbl3',
      'prod_Ol4vGR4g4FjJSf',
      // prod
      'prod_Onizu0f68MwUej',
      'prod_OnizVpGMzi4ply',
    ].includes(id)
  )

export const selectIsNewScorpSubscription2023 = createSelector(
  selectProductIds,
  getIsScorpNewSubscription
)
