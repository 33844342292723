import { useState } from 'react'
import { Grid } from 'semantic-ui-react'
import moment from 'moment'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'

import { FlsaStatus, PaymentUnit } from '../employeeJob.slice'
import { DetailsRow } from './JobPayTab'
import {
  postCreateEmployeeJob,
  putUpdateEmployeeJob,
  putUpdateJobCompensation,
} from '../payrollActions'
import {
  selectEmployeeByUuid,
  selectJobByEmployeeUuid,
} from '../payroll.selectors'
import {
  flsaMapping,
  flsaDropdownOptions,
  useEmployeeWorkLocations,
} from '../helpers'
import { formatCurrency } from '../../../utils/currencyHelpers'
import {
  makeReqStringSchema,
  makeNumberSchema,
  Modal,
  Button,
  FormikDropdown,
  FormikInput,
  Text,
  GridRowColumn,
  getFieldNames,
} from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { Gusto_EmployeeJob } from '../generated_gusto_types'

const END_VAL = 'for this employee'

const EditJobModal = ({
  uuid,
  job,
  closeModal,
}: {
  uuid: string
  job: Gusto_EmployeeJob | null
  closeModal: () => void
}) => {
  const dispatch = useAppDispatch()
  const compensation = job?.compensations[0]

  const { locationOptions, defaultAddress, setWorkLocation } =
    useEmployeeWorkLocations(uuid)

  const formik = useFormik({
    initialValues: {
      title: job?.title || '',
      rate: compensation?.rate || '0',
      flsa_status: compensation?.flsa_status || FlsaStatus.Exempt,
      payment_unit: compensation?.payment_unit || PaymentUnit.Year,
      location_uuid: defaultAddress || '',
    },
    enableReinitialize: true,
    onSubmit: async ({
      title,
      rate,
      payment_unit,
      flsa_status,
      location_uuid,
    }) => {
      // Set work address
      const setWorkAddressSuccess = await setWorkLocation(location_uuid)

      if (!setWorkAddressSuccess) {
        return
      }

      let jobRes

      if (job?.uuid && job.version) {
        jobRes = await putUpdateEmployeeJob(job?.uuid, {
          version: job.version,
          hire_date: job?.hire_date,
          title,
        })(dispatch)
      } else {
        jobRes = await postCreateEmployeeJob(uuid, {
          hire_date: moment().format(DATE_FORMATS.GUSTO_SUBMIT),
          title,
        })(dispatch)
      }

      const currentComp = jobRes?.compensations.find(
        (comp) => comp.uuid === jobRes?.current_compensation_uuid
      )

      if (!jobRes || !currentComp) {
        return
      }

      const compRes = await putUpdateJobCompensation(currentComp.uuid, {
        rate,
        payment_unit,
        flsa_status,
        version: currentComp.version,
      })(dispatch)

      if (compRes) {
        closeModal()
      }
    },
  })

  const { submitForm, isSubmitting } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <Modal open size="tiny" onClose={closeModal} closeIcon>
      <Modal.Header>Update Details</Modal.Header>
      <Modal.Content>
        <FormikProvider value={formik}>
          <Grid>
            <GridRowColumn>
              <FormikDropdown
                name={fieldNames.flsa_status}
                options={flsaDropdownOptions}
                label="Employment Status"
                required
                schema={makeReqStringSchema({
                  field: 'employee type',
                  end: END_VAL,
                })}
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikInput
                name={fieldNames.title}
                label="Job Title"
                required
                schema={makeReqStringSchema({ field: 'title', end: END_VAL })}
              />
            </GridRowColumn>
            <GridRowColumn>
              <Text className="form-label required">Compensation</Text>
            </GridRowColumn>
            <Grid.Row className="short" verticalAlign="middle">
              <Grid.Column width={8}>
                <FormikInput
                  name={fieldNames.rate}
                  componentType="currency"
                  fullWidth
                  schema={makeNumberSchema({
                    field: 'rate',
                    allowedDecimals: 2,
                    end: END_VAL,
                  })}
                />
              </Grid.Column>
              <Grid.Column width={2}>
                <Text>Per</Text>
              </Grid.Column>
              <Grid.Column width={5}>
                <FormikDropdown
                  name={fieldNames.payment_unit}
                  optionValues={Object.values(PaymentUnit).filter(
                    (name) => name !== 'Paycheck'
                  )}
                  fullWidth
                  schema={makeReqStringSchema({
                    field: 'payment unit',
                    end: END_VAL,
                  })}
                />
              </Grid.Column>
            </Grid.Row>
            <GridRowColumn>
              <FormikDropdown
                name={fieldNames.location_uuid}
                options={locationOptions}
                label="Work Location"
                required
                fullWidth
                schema={yup.string().required('Work Location is required')}
              />
            </GridRowColumn>

            <GridRowColumn>
              <Button onClick={submitForm} fullWidth disabled={isSubmitting}>
                Confirm
              </Button>
            </GridRowColumn>
            <GridRowColumn>
              <Button onClick={closeModal} variant="secondary" fullWidth>
                Cancel
              </Button>
            </GridRowColumn>
          </Grid>
        </FormikProvider>
      </Modal.Content>
    </Modal>
  )
}

const EmployeeJobCompPanel = ({ employeeUuid }: { employeeUuid: string }) => {
  const [isOpen, setIsOpen] = useState(false)

  const employee = useReselector(selectEmployeeByUuid, employeeUuid)
  const job = useReselector(selectJobByEmployeeUuid, employeeUuid)

  if (!employee) {
    return null
  }

  const comp = job?.compensations[0]

  return (
    <div className="gep-details-container">
      <Grid>
        <Grid.Row>
          <Grid.Column width={14}>
            <Text as="h2">Compensation</Text>
          </Grid.Column>
          <Grid.Column width={2}>
            <Button onClick={() => setIsOpen(true)} variant="link">
              Edit
            </Button>
          </Grid.Column>
        </Grid.Row>
        <DetailsRow
          label="Employee Type"
          value={comp?.flsa_status ? flsaMapping[comp.flsa_status] : ''}
        />
        <DetailsRow label="Job Title" value={job?.title} />
        {comp && (
          <DetailsRow
            label="Wage"
            value={`${formatCurrency(comp.rate)} per ${comp.payment_unit}`}
          />
        )}
      </Grid>
      {isOpen && (
        <EditJobModal
          closeModal={() => setIsOpen(false)}
          uuid={employee.uuid}
          job={job}
        />
      )}
    </div>
  )
}

export default EmployeeJobCompPanel
