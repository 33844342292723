import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  uploadDocument,
  UploadUserDocumentPayload,
  userUploadDocument,
  UploadError,
} from '../../actions/awsActions'
import { User } from '../../reducers/auth/userReducer'
import { Alert, Button, Icon } from '../BaseComponents'
import { UserDocument } from '../../features/UserDocuments/userDocuments.slice'
import { TaxFilingUser } from '../../features/Taxes/AnnualTaxes/annualTaxFilings.slice'
import { getCurrentUser } from '../../selectors/user.selectors'
import { useReselector } from '../../utils/sharedHooks'
import { UploadedFile } from './FileUploadModal'
import { UploadDocumentType } from '../../constants/businessConstants'
import { parseErrorFromCatch } from '../../utils/errorHelpers'
import { useAppDispatch } from '../../utils/typeHelpers'

interface Props {
  buttonText: string
  user?: User | TaxFilingUser
  userFacing: boolean
  // Only support two types initially
  documentType: UploadDocumentType.OTHER | UploadDocumentType.RECEIPT
  setUploadedFile?: (_: UploadedFile) => Promise<void> | void
  title?: string
  taxFilingFormId?: number
  description?: ReactNode
  // Sometimes a file should be uploaded with extra props
  extraPayload?: Partial<UploadUserDocumentPayload>
}

const FileUploadButton = ({
  buttonText,
  userFacing,
  documentType,
  setUploadedFile,
  extraPayload,
  user: sentUser,
}: Props) => {
  const dispatch = useAppDispatch()
  const [filesToUpload, setFilesToUpload] = useState<File[]>([])
  const [error, setError] = useState('')
  const [uploading, setUploading] = useState(false)
  const currentUser = useReselector(getCurrentUser)
  const inputRef = useRef<HTMLInputElement>(null)

  const user = sentUser || currentUser

  const uploadFiles = useCallback(async () => {
    if (!user?.id) {
      return
    }

    const uploadFile = async (file: File) => {
      let docRes: UserDocument | UploadError | null = null

      const payload = {
        file,
        user: {
          id: user.id,
          uuid: user.uuid,
        },
        documentType,
        ...extraPayload,
      }
      if (userFacing) {
        const res = await userUploadDocument(payload)(dispatch)
        if (res && 'userDocument' in res) {
          docRes = res.userDocument
        } else if (res && 'error' in res) {
          docRes = res
        }
      } else {
        docRes = await uploadDocument(payload)(dispatch)
      }

      if (docRes && 'error' in docRes) {
        throw new Error(docRes.error)
      } else if (setUploadedFile && docRes && 'id' in docRes) {
        await setUploadedFile({
          title: docRes.fileDescription || '',
          url: docRes.signedUrl || '',
          id: docRes.id,
        })
      }
    }
    setError('')
    setUploading(true)
    try {
      await Promise.all(filesToUpload.map((file) => uploadFile(file)))
      setFilesToUpload([])
    } catch (err) {
      setError(parseErrorFromCatch(err))
    } finally {
      setUploading(false)
    }
  }, [
    user?.id,
    user?.uuid,
    documentType,
    userFacing,
    setUploadedFile,
    dispatch,
    filesToUpload,
    extraPayload,
  ])

  // When filesToUpload is set by the <input> below, we perform the upload
  useEffect(() => {
    if (!uploading && filesToUpload.length) {
      uploadFiles()
    }
  }, [filesToUpload, uploadFiles, uploading])

  return (
    <>
      <Button
        variant="secondary"
        disabled={uploading}
        loading={uploading}
        onClick={() => inputRef.current?.click()}
      >
        <Icon icon={regular('upload')} style={{ marginRight: 10 }} />
        {buttonText}
      </Button>
      <input
        type="file"
        multiple
        hidden
        onChange={(e) => {
          if (e.target.files) {
            setFilesToUpload(Array.from(e.target.files))
            e.target.value = ''
          }
        }}
        ref={inputRef}
      />
      {error && <Alert type="error" title={error} />}
    </>
  )
}

export default FileUploadButton
