import { Grid } from 'semantic-ui-react'
import {
  Accordion,
  Alert,
  Button,
  GridRowColumn,
  Modal,
  Text,
} from '../../components/BaseComponents'
import { useReselector } from '../../utils/sharedHooks'
import { getTransactionCategoryById } from '../Reports/reports.selectors'
import { useMemo } from 'react'
import { getFinancialProfile } from '../../selectors/user.selectors'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'
import { getRecategorizationExplanation } from './helpers'
import { useAnalyticsTrack } from '../Amplitude'

interface RecategorizationModalProps {
  oldCategoryId: number | null
  newCategoryId: number | null
  open: boolean
  close: () => void
  updateAction: () => void
}
export const RecategorizationModal = ({
  oldCategoryId,
  newCategoryId,
  open,
  close,
  updateAction,
}: RecategorizationModalProps) => {
  const oldCategory = useReselector(getTransactionCategoryById, oldCategoryId)
  const newCategory = useReselector(getTransactionCategoryById, newCategoryId)
  const financialProfile = useReselector(getFinancialProfile)
  const recategorizationExplainer = useMemo(() => {
    return getRecategorizationExplanation(
      oldCategory,
      newCategory,
      financialProfile?.taxEntityType
    )
  }, [oldCategory, newCategory, financialProfile?.taxEntityType])
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const track = useAnalyticsTrack()

  // This must be located below the recategorizationExplainer useMemo
  const shouldOpenModal = useMemo(() => {
    if (open) {
      if (recategorizationExplainer) {
        return true
      } else {
        updateAction()
        close()
        return false
      }
    }
    return false
  }, [open, recategorizationExplainer, close, updateAction])

  const recategorize = () => {
    updateAction()
    track('clicked recategorize on recat confirm')
    close()
  }
  return (
    <Modal
      open={shouldOpenModal}
      onClose={close}
      onOpen={() => track('viewed recat confirm modal')}
      closeIcon
      size="tiny"
    >
      <Modal.Header>
        {`Are you sure you want to recategorize this as ${
          newCategory?.name || 'Unselected'
        }?`}
      </Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text>
              This transaction must be categorized in a particular way to be
              tax-compliant.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Alert>{recategorizationExplainer?.reason}</Alert>
          </GridRowColumn>
          <GridRowColumn>
            <Accordion
              variant="text"
              title="Why is it important to use this category correctly?"
              content={recategorizationExplainer?.importance}
            />
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions style={isMobile ? { flexDirection: 'column' } : {}}>
        <Button
          variant="actionLink"
          onClick={recategorize}
          style={isMobile ? { order: 1 } : {}}
          fullWidth={isMobile}
        >
          Recategorize
        </Button>
        <Button variant="primary" onClick={close} fullWidth={isMobile}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default RecategorizationModal
