import { List } from 'semantic-ui-react'
import { Card, Icon, Text } from '../../components/BaseComponents'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import TestimonialListItem from './Testimonial/TestimonialListItem'

const Checkmark = () => (
  <Icon
    style={{ display: 'inline' }}
    color="green"
    size="2x"
    icon={light('check')}
  />
)

const HeardListItem = ({ contentText }: { contentText: string }) => (
  <List.Item style={{ padding: '12px 0' }}>
    <List.Icon verticalAlign="top">
      <Checkmark />
    </List.Icon>
    <List.Content>
      <Text>{contentText}</Text>
    </List.Content>
  </List.Item>
)

const WhatYouGetCard = ({ ...props }) => (
  <Card {...props} backgroundColor="stone40">
    <Text as="h2">What you get with Heard</Text>
    <List style={{ marginTop: 30 }}>
      <HeardListItem contentText="Tax pros who specialize in therapy practices" />
      <HeardListItem contentText="Annual personal and professional income tax filing, state and federal" />
      <HeardListItem contentText="Quarterly tax estimates" />
      <HeardListItem contentText="Monthly bookkeeping and financial reports" />
      <HeardListItem contentText="Anytime messaging support" />
      <TestimonialListItem
        title="Dr. MaryBeth Bailar"
        subtitle="PsyD"
        imageSrc="https://heard-images.s3.amazonaws.com/assets/assets/signup/therapist-profile-pic.png"
        comment="I am so thankful that Heard exists! I am especially grateful for
        the ease of communicating with my bookkeepers who are accessible and
        answer questions without any additional fees."
        style={{ margin: '40px 0 0px 15px' }}
      />
    </List>
  </Card>
)

export default WhatYouGetCard
