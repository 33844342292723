import { useCallback, useMemo } from 'react'
import { Container, Divider, Grid, List } from 'semantic-ui-react'
import { useFormik, FormikProvider } from 'formik'
import parse from 'html-react-parser'
import { useSearchParams } from 'react-router-dom'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Form1040DetailsProps } from '.'
import {
  GridRowColumn,
  getFieldName,
  Text,
  FormikRadioToggleButton,
  FormikLabelError,
  makeReqBoolSchema,
  makeReqStringSchema,
  FormikDropdown,
  Card,
  Accordion,
  Link,
  Icon,
} from '../../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { TaxListOptionId, TaxListQuestionId } from '../../service'
import {
  selectSpouseResponses,
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../../taxChecklist.selectors'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import {
  DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  deleteTaxQuestionnaireResponses,
} from '../../taxChecklistQuestion.actions'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { getCurrentUser } from '../../../../../../selectors/user.selectors'
import { FILING_STATUSES, isFilingStatusType } from '../../../../taxConstants'
import { logSentryError } from '../../../../../../utils/sentryHelpers'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import {
  REVIEW_QUERY_PARAM,
  useSetScreen,
} from '../../../../../../components/FormFlow/formFlow'
import { DropdownValue } from '../../../../../../components/BaseComponents/Dropdown'
import { select1040FormForYear } from '../../../annualTaxFilingForms.selector'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'

export const filingStatusQuestionIds = [
  TaxListQuestionId.filing_status,
  TaxListQuestionId.filing_status_changed,
]

const getFilingStatusCopy = (
  filingStatus: FILING_STATUSES | null,
  taxYear: string
) => {
  switch (filingStatus) {
    case FILING_STATUSES['single']: {
      return {
        title: 'Single',
        text: (
          <GridRowColumn short>
            <Text as="bodyLg">
              You may use this filing status if you are unmarried or legally
              separated/divorced.
            </Text>
          </GridRowColumn>
        ),
      }
    }
    case FILING_STATUSES['married_filing_jointly']: {
      return {
        title: 'Married Filing Jointly',
        text: (
          <GridRowColumn short>
            <Text as="bodyLg">
              You are married and plan to file a single return for you and your
              spouse. This will reflect income and deductions for both parties.
              Filing jointly typically results in the greatest tax deductions
              unless one of you has a large student loan debt or tax liability.
            </Text>
          </GridRowColumn>
        ),
      }
    }
    case FILING_STATUSES['married_filing_separately']: {
      return {
        title: 'Married Filing Separately',
        text: (
          <GridRowColumn short>
            <Text as="bodyLg">
              You are married and plan to file an annual tax return separate
              from that of your spouse. This will keep income and deductions
              between spouses separate. Filing separately when one spouse
              carries a large debt or tax liability typically results in a lower
              tax liability for both of you.
            </Text>
          </GridRowColumn>
        ),
      }
    }
    case FILING_STATUSES['head_of_household']: {
      return {
        title: 'Head of Household',
        text: (
          <>
            <GridRowColumn style={{ padding: 0 }}>
              <Text as="bodyLg">This means you:</Text>
            </GridRowColumn>
            <List bulleted style={{ marginTop: 0 }}>
              <List.Item>
                <Text as="bodyLg">
                  Were unmarried for the {taxYear} tax year
                </Text>
              </List.Item>
              <List.Item>
                <Text as="bodyLg">
                  Have a qualifying child or dependent living in your home for
                  more than half the year
                </Text>
              </List.Item>
              <List.Item>
                <Text as="bodyLg">
                  Were responsible for paying more than half of all household
                  expenses
                </Text>
              </List.Item>
            </List>
          </>
        ),
      }
    }
    case FILING_STATUSES['qualifying_widow']: {
      return {
        title: 'Qualifying Widow(er)',
        text: (
          <GridRowColumn short>
            <Text as="bodyLg">
              You may file as a Qualifying Widow(er) if your spouse has passed
              away, allowing you to use the tax rates as if you were filing
              jointly. You may use this status for up to two years following
              your spouse&apos;s passing.
            </Text>
          </GridRowColumn>
        ),
      }
    }
    default:
      return null
  }
}

const FilingStatusCard = ({
  filingStatus,
}: {
  filingStatus: FILING_STATUSES | null
}) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const copy = getFilingStatusCopy(filingStatus, taxYear)
  if (!copy) return null
  return (
    <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
      <Card type="subsection">
        <Grid>
          <GridRowColumn>
            <Text as="h2">{copy.title}</Text>
          </GridRowColumn>
          {copy.text}
        </Grid>
      </Card>
    </GridRowColumn>
  )
}

const FilingStatsFaq = () => {
  const supportLink =
    'https://support.joinheard.com/hc/en-us/articles/16123320231959-Should-my-spouse-and-I-file-our-taxes-together-or-separately'
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  return (
    <Accordion
      style={{ padding: 0 }}
      backgroundColor="white"
      title="Filing Status FAQs"
      content={
        <Grid>
          <GridRowColumn short>
            <Text as="bodyLg">Your filing status dictates the...</Text>
          </GridRowColumn>
          <List bulleted>
            <List.Item>
              <Text as="bodyLg">Calculation of income tax</Text>
            </List.Item>
            <List.Item>
              <Text as="bodyLg">Amount of standard deductions</Text>
            </List.Item>
            <List.Item>
              <Text as="bodyLg">
                Allowance or limitation of certain credits and deductions
              </Text>
            </List.Item>
          </List>
          <GridRowColumn>
            <Text as="bodyLg">
              For help,{' '}
              <Link newPage href={supportLink}>
                click here{' '}
                <Icon
                  style={{ marginLeft: 2 }}
                  size="1x"
                  icon={regular('arrow-up-right-from-square')}
                />
              </Link>{' '}
              to learn more about how to identify your filing status.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="bodyMd">
              *People can be eligible for more than one status. It&apos;s
              generally advised to use the status that results in the lowest tax
              payment.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="h3">Filing Statuses</Text>
          </GridRowColumn>
          {Object.values(FILING_STATUSES).map((fs) => {
            const copy = getFilingStatusCopy(fs, taxYear)
            return (
              <GridRowColumn key={fs} style={{ padding: 0 }}>
                <Accordion
                  style={{
                    marginTop: 0,
                    padding: 0,
                    borderTop: '1px solid #E0E0E0',
                  }}
                  backgroundColor="white"
                  title={copy?.title}
                  content={copy?.text}
                />
              </GridRowColumn>
            )
          })}
        </Grid>
      }
    />
  )
}

const FilingStatusPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
  taxYear,
}: Form1040DetailsProps) => {
  const dispatch = useAppDispatch()
  const form1040 = useReselector(select1040FormForYear, taxYear)
  const financialProfile = useReselector(getCurrentUser)?.financialProfile
  const [searchParams] = useSearchParams()
  const { currentScreen, setScreen } = useSetScreen()
  const reviewing = searchParams.get(REVIEW_QUERY_PARAM) === 'true'
  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
  ])

  const questionFilingStatus = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.filing_status,
    taxYear
  )
  const responseFilingStatus = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.filing_status,
    form1040?.id
  )
  const questionFilingStatusChanged = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.filing_status_changed,
    taxYear
  )
  const responseFilingStatusChanged = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.filing_status_changed,
    form1040?.id
  )
  const allSpouseResponses = useReselector(selectSpouseResponses)

  const formik = useFormik({
    initialValues: {
      filingStatus: responseFilingStatus?.[0]?.value,
      filingStatusChanged: responseFilingStatusChanged?.[0]?.value,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      let copyFpFilingStatus: string | null = null
      if (
        financialProfile?.filingStatus &&
        values.filingStatusChanged !== responseFilingStatusChanged?.[0]?.value
      ) {
        responseData.push({
          id: responseFilingStatusChanged?.[0]?.id,
          annualTaxFilingFormId: form1040?.id,
          questionId: TaxListQuestionId.filing_status_changed,
          value: values.filingStatusChanged,
        })
        if (values.filingStatusChanged === false) {
          copyFpFilingStatus = financialProfile?.filingStatus?.toUpperCase()
        }
      }
      if (
        copyFpFilingStatus ||
        values.filingStatus !== responseFilingStatus?.[0]?.value
      ) {
        responseData.push({
          id: responseFilingStatus?.[0]?.id,
          annualTaxFilingFormId: form1040?.id,
          questionId: TaxListQuestionId.filing_status,
          value: copyFpFilingStatus ?? values.filingStatus,
        })
      }
      let success = true
      const incompleteSteps: SubStepIdentifiers[] = []
      const needsSpouseInfo =
        values.filingStatus === TaxListOptionId.married_filing_jointly ||
        values.filingStatus === TaxListOptionId.qualifying_widow
      if (!needsSpouseInfo && allSpouseResponses?.length > 0) {
        success = Boolean(
          await deleteTaxQuestionnaireResponses(
            allSpouseResponses.map((r) => r.id)
          )(dispatch)
        )
      }
      if (needsSpouseInfo && allSpouseResponses.length === 0) {
        incompleteSteps.push(
          SubStepIdentifiers.updateBusinessAndHealthcareInformation,
          SubStepIdentifiers.spouseAndDependentInformation
        )
      }
      if (success) {
        await goToNextStep(responseData, nextScreen ?? null, {
          completedSteps: [SubStepIdentifiers.filingStatus],
          incompleteSteps,
        })
      }
    },
  })

  const { values, validateForm, isSubmitting, isValid, submitForm } = formik

  const typeSafeFilingStatus = useMemo(() => {
    if (typeof values.filingStatus !== 'string') return null
    const lowerCaseFilingStatus = values.filingStatus?.toLowerCase()
    if (!isFilingStatusType(lowerCaseFilingStatus)) {
      logSentryError('Invalid filing status type')
      return null
    }

    return (
      (values.filingStatusChanged === false && financialProfile?.filingStatus
        ? financialProfile.filingStatus
        : lowerCaseFilingStatus) ?? financialProfile?.filingStatus
    )
  }, [
    values.filingStatusChanged,
    financialProfile?.filingStatus,
    values.filingStatus,
  ])

  /**
   * If coming from the review screen, puts the user back into the normal
   * form flow since there are conditional questions on other screens
   */
  const returnToRegularFlow = useCallback(
    (changedVal: DropdownValue | DropdownValue[] | undefined) => {
      if (reviewing && changedVal === TaxListOptionId.married_filing_jointly) {
        setScreen(currentScreen, true)
      }
    },
    [reviewing, currentScreen, setScreen]
  )

  return (
    <FormikProvider value={formik}>
      <Container style={{ marginTop: 56 }} text textAlign="left">
        <Grid>
          <GridRowColumn>
            <Text as="display2" textAlign="center">
              Filing Status
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="bodyLg">
              Please update the following information for {taxYear}. These
              answers will appear on your tax return.
            </Text>
          </GridRowColumn>
          {financialProfile?.filingStatus && (
            <>
              <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
                <FormikLabelError
                  name={getFieldName<typeof values>('filingStatusChanged')}
                  label={parse(
                    questionFilingStatusChanged.question?.text ?? ''
                  )}
                  schema={makeReqBoolSchema()}
                />
              </GridRowColumn>
              <Grid.Row className="short" style={{ justifyContent: 'center' }}>
                <Grid.Column width={6} style={{ paddingRight: 0 }}>
                  <FormikRadioToggleButton
                    fullWidth
                    name={getFieldName<typeof values>('filingStatusChanged')}
                    value
                  >
                    Yes
                  </FormikRadioToggleButton>
                </Grid.Column>
                <Grid.Column width={6}>
                  <FormikRadioToggleButton
                    fullWidth
                    name={getFieldName<typeof values>('filingStatusChanged')}
                    value={false}
                    onClick={validateForm}
                  >
                    No
                  </FormikRadioToggleButton>
                </Grid.Column>
              </Grid.Row>
            </>
          )}
          {!financialProfile?.filingStatus && (
            <GridRowColumn>
              <Text as="bodyMd">
                Select your filing status. For help picking your status, check
                out the FAQs below.
              </Text>
            </GridRowColumn>
          )}
          {(!financialProfile?.filingStatus || values.filingStatusChanged) && (
            <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
              <FormikDropdown
                placeholder="Select filing status"
                name={getFieldName<typeof values>('filingStatus')}
                label={questionFilingStatus.question?.text}
                options={questionFilingStatus?.options?.map((o) => ({
                  text: o.text,
                  value: o.id,
                  key: o.id,
                }))}
                schema={makeReqStringSchema()}
                fullWidth
                onChange={returnToRegularFlow}
              />
            </GridRowColumn>
          )}
          <FilingStatusCard filingStatus={typeSafeFilingStatus} />
          <GridRowColumn style={{ paddingBottom: 0 }}>
            <Divider />
          </GridRowColumn>
          <FilingStatsFaq />
          <GridRowColumn short>
            <Divider />
          </GridRowColumn>
        </Grid>
      </Container>
      <Grid>
        <FormFlowFooter
          loading={isUpdating || isSubmitting}
          continueDisabled={!isValid || isUpdating || isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
        />
      </Grid>
    </FormikProvider>
  )
}

export default FilingStatusPanel
