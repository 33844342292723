import { Grid } from 'semantic-ui-react'

import { Link, Text } from '../../../components/BaseComponents'

const ContactSupport = () => (
  <Grid textAlign="center">
    <Grid.Row />
    <Grid.Row />
    <Grid.Row />
    <Grid.Row />
    <Grid.Row>
      <Grid.Column>
        <Text as="h1">Have questions about this payroll?</Text>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <Link to="/conversations">
          <Text as="bodyMd" color="green">
            Contact Support
          </Text>
        </Link>
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

export default ContactSupport
