import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Grid, Divider } from 'semantic-ui-react'

import PayrollSetupList from './PayrollSetupList'
import {
  FETCH_PAY_SCHEDULE_KEY,
  fetchAllPaySchedules,
  POST_PAY_SCHEDULE_KEY,
} from '../payrollActions'
import { GEP_ENROLL_PATHS } from '../helpers'
import PrefilledNoticeContainer from './PrefilledNoticeContainer'
import { getIsFetchingOrNotStarted } from '../../../reducers/fetch'
import PayrollError from '../PayrollError'
import { selectPaySchedule } from '../payroll.selectors'
import {
  Button,
  Card,
  GridRowColumn,
  Text,
} from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import PayrollScheduleForm, { SchedulePreview } from './PayrollScheduleForm'
import { useAppDispatch } from '../../../utils/typeHelpers'

const PayrollEnrollSchedule = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const paySchedule = useReselector(selectPaySchedule)
  const loading = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_PAY_SCHEDULE_KEY
  )

  useEffect(() => {
    dispatch(fetchAllPaySchedules())
  }, [dispatch])

  return (
    <Container>
      <Card>
        <Grid>
          <GridRowColumn>
            <Text as="h1">Setting Up Payroll</Text>
          </GridRowColumn>
          <Divider />
          <PrefilledNoticeContainer />
          <PayrollError fetchKey={POST_PAY_SCHEDULE_KEY} />
          <Grid.Row>
            <Grid.Column width={9}>
              {!paySchedule && !loading && (
                <PayrollScheduleForm
                  onSuccess={() => navigate(GEP_ENROLL_PATHS.flow)}
                  onCancel={() => navigate(GEP_ENROLL_PATHS.employeeBank)}
                  saveText="Save & Continue"
                />
              )}
              {paySchedule && (
                <Grid>
                  <GridRowColumn>
                    <SchedulePreview
                      firstPayDate={paySchedule.anchor_pay_date}
                      frequency={paySchedule.frequency}
                    />
                  </GridRowColumn>
                  <Grid.Row />
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <Button
                        variant="secondary"
                        fullWidth
                        onClick={() => navigate(GEP_ENROLL_PATHS.employeeBank)}
                      >
                        Back
                      </Button>
                    </Grid.Column>
                    <Grid.Column width={4} />
                    <Grid.Column width={6}>
                      <Button
                        fullWidth
                        onClick={() => navigate(GEP_ENROLL_PATHS.flow)}
                      >
                        Save & Continue
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </Grid.Column>
            <Grid.Column width={7}>
              <PayrollSetupList />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row />
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollEnrollSchedule
