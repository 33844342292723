import { Card, Link, Text } from '../../../../components/BaseComponents'
import { Grid, GridColumn, GridRow, Image, List } from 'semantic-ui-react'
import { GEP_PER_PERSON_PRICE, GEP_SUB_PRICE } from '../../helpers'
import { useAnalyticsView } from '../../../Amplitude'
import { useEffect } from 'react'

const GustoProviderDetails = () => {
  const pageView = useAnalyticsView()
  useEffect(() => {
    pageView('payroll upsell confirmation')
  }, [pageView])

  const nextUp = [
    {
      title: 'Select "Set Up Payroll"',
      description:
        'We’ll add the "Set Up Payroll" button right here to your dashboard.',
    },
    {
      title: 'Set up payroll',
      description: 'Take 2 minutes to accept Heard’s Terms and you’re set!',
    },
  ]
  return (
    <Grid>
      <GridRow>
        <GridColumn computer={4} tablet={2} />
        <GridColumn computer={8} tablet={11} mobile={16}>
          <Card
            type={'subsection'}
            backgroundColor={'natural'}
            style={{ marginBottom: 28 }}
          >
            <GridColumn>
              <Image
                src={
                  'https://heard-images.s3.amazonaws.com/assets/paper-plane.svg'
                }
              />
              <Text as={'h1'} style={{ marginTop: 24 }}>
                One more step:
              </Text>
              <Text as={'h2'} style={{ marginBottom: 32 }}>
                Add Heard Payroll as your admin
              </Text>
              <Text as={'bodySm'}>
                Add <b>payroll@joinheard.com</b> as a full admin on your Gusto
                account so we can review and assess for fit.
              </Text>
              <Text as={'bodySm'} style={{ marginTop: 24, marginBottom: 24 }}>
                <List bulleted>
                  <List.Item>First Name: Heard</List.Item>
                  <List.Item>Last Name: Payroll</List.Item>
                </List>
              </Text>
              <Text as={'bodySm'}>
                For more information, please read{' '}
                <Link
                  size="small"
                  href={
                    'https://support.gusto.com/article/114138050100000/Manage-administrator-permissions'
                  }
                  newPage
                  as="secondaryLink"
                >
                  Manage administrator permissions
                </Link>
                .
              </Text>
            </GridColumn>
          </Card>

          <Card type={'section'}>
            <Text as={'h1'} style={{ marginBottom: 12 }}>
              Next up
            </Text>
            <Text as={'bodyMd'} style={{ marginBottom: 28 }}>
              Here’s what you can expect if you’re eligible to enroll.
            </Text>
            <Grid>
              {nextUp.map((item, key) => (
                <GridRow key={item.title}>
                  <GridColumn width={2}>
                    <div
                      style={{
                        backgroundColor: '#F2F2EE',
                        borderRadius: '50%',
                        width: 28,
                        height: 28,
                        textAlign: 'center',
                        marginTop: -4,
                      }}
                    >
                      <Text as={'h3'} style={{ lineHeight: '28px' }}>
                        {key + 1}
                      </Text>
                    </div>
                  </GridColumn>
                  <GridColumn width={14}>
                    <Text as={'h3'} style={{ marginBottom: 8 }}>
                      {item.title}
                    </Text>
                    <Text as={'bodySm'}>{item.description}</Text>
                  </GridColumn>
                </GridRow>
              ))}
            </Grid>
            <Grid style={{ marginTop: 0 }}>
              <GridRow>
                <GridColumn width={2} />
                <GridColumn width={14}>
                  <Text color={'darkGray'} as={'bodySm'}>
                    The payroll feature is a base cost of ${GEP_SUB_PRICE}
                    /month + ${GEP_PER_PERSON_PRICE}/individual per month. You
                    would no longer be charged by Gusto and would only be
                    charged by Heard going forward.
                  </Text>
                </GridColumn>
              </GridRow>
            </Grid>
          </Card>
        </GridColumn>
      </GridRow>
    </Grid>
  )
}

export default GustoProviderDetails
