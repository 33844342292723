import { createSelector } from 'reselect'
import { orderBy } from 'lodash'
import moment from 'moment'

import { inOperator, ReduxState } from '../../../utils/typeHelpers'
import { History } from './histories.slice'
import { selectActiveQuarterlyTaxEstimateDetails } from '../QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'

const selectAllHistories = (state: ReduxState) => state.admin.allHistories

const getAllHistoriesSortedByCreationDesc = createSelector(
  selectAllHistories,
  (histories) =>
    orderBy(Object.values(histories), [(obj) => obj.createdAt], ['desc'])
)

// Returns all Histories whose modelName matches the argument.
// Accepts a filter function to return a subset of results, e.g. filter for specific userId on the models
export const getHistoriesForModelRecentFirstSelector = createSelector(
  [
    getAllHistoriesSortedByCreationDesc,
    (
      _: unknown,
      args: { modelName: string; filterFn: (_history: History) => boolean }
    ) => args,
  ],
  (histories: History[], args) =>
    histories.filter((h) => h.modelName === args.modelName && args.filterFn(h))
)

// todo this selector is written incorrectly - should not have selector inside of it
export const getFinancialProfileHistoriesSortedSelector = createSelector(
  [(state: ReduxState) => state, (_: unknown, userId: number) => userId],
  (state, userId) =>
    getHistoriesForModelRecentFirstSelector(state, {
      modelName: 'FinancialProfile',
      filterFn: (history: History) => {
        if (inOperator('userId', history.newVersion)) {
          return history.newVersion['userId'] === userId
        }

        return false
      },
    })
)

export const getAllFinancialProfileHistories = createSelector(
  [getAllHistoriesSortedByCreationDesc],
  (histories) => histories.filter((h) => h.modelName === 'FinancialProfile')
)

const getHistoriesForYear = (
  histories: History[],
  year: string | undefined
) => {
  const startOfYear = moment(year, 'YYYY').startOf('year')
  const endOfYear = moment(year, 'YYYY').endOf('year')

  return histories.filter((history) =>
    moment(history.createdAt).isBetween(startOfYear, endOfYear, undefined, '[]')
  )
}

export const getFinancialProfileHistoriesForYear = createSelector(
  [getAllFinancialProfileHistories, (_: unknown, year?: string) => year],
  getHistoriesForYear
)

export const selectFPHistoriesForCurrentYear = createSelector(
  getAllFinancialProfileHistories,
  selectActiveQuarterlyTaxEstimateDetails,
  (histories, details) => getHistoriesForYear(histories, details?.taxYear)
)
