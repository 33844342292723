import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { fetchPaymentMethodUpdateSession } from '../../actions/settings/billingActions'
import { Alert, Button, Icon, Text } from '../../components/BaseComponents'
import { useAppDispatch } from '../../utils/typeHelpers'

interface Props {
  daysUntilSuspension: number
}

const AccountSuspendedBanner = ({ daysUntilSuspension }: Props) => {
  const [loading, setLoading] = useState(false)

  const { pathname } = useLocation()
  const dispatch = useAppDispatch()

  const redirectToStripeSession = async () => {
    setLoading(true)
    const res = await fetchPaymentMethodUpdateSession(
      pathname,
      pathname
    )(dispatch)

    if (res) {
      window.location.href = res.url
    } else {
      setLoading(false)
    }
  }

  return (
    <Alert style={{ borderRadius: 0 }} type="announcement">
      Your last payment for Heard has failed. Please update it now or your
      account will be suspended in{' '}
      <b>{daysUntilSuspension === 0 ? '<1' : daysUntilSuspension} days</b>.
      &nbsp;
      <Button
        variant="link"
        disabled={loading}
        inline
        loading={loading}
        onClick={redirectToStripeSession}
      >
        Pay Now
        <Icon icon={regular('angles-right')} style={{ marginLeft: 4 }} />
      </Button>
      <br />
      <Text as="bodySm">
        <i>
          &#40;If you have recently updated your payment information, it may
          take a minute for these changes to reflect.&#41;
        </i>
      </Text>
    </Alert>
  )
}

export default AccountSuspendedBanner
