import {
  Modal,
  Text,
  Button,
  GridRowColumn,
  Alert,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../AnnualTaxDetails/annualTaxDetails.selector'
import { Grid } from 'semantic-ui-react'
import { selectAdminAnnualTaxFilingsByYearForUser } from '../../Taxes/adminAnnualTaxFilings.selector'
import {
  TaxfyleJob,
  TaxfyleJobStatus,
} from '../../../Taxes/AnnualTaxes/annualTaxFilingForms.slice'
import { AUTOCOMPLETE_TQ_KEY, autocompleteTq } from '../qaUsers.slice'
import { getFetchError, getIsFetching } from '../../../../reducers/fetch'
import { useState } from 'react'
import { AnnualTaxFilingFormWithDocument } from '../../Taxes/adminAnnualTaxFilings.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const ModalContent = ({
  filingForm,
}: {
  filingForm: AnnualTaxFilingFormWithDocument
}) => {
  const dispatch = useAppDispatch()
  const isUnsubmittedForm = (taxfyleJob: TaxfyleJob | null) =>
    !taxfyleJob || taxfyleJob.jobStatus === TaxfyleJobStatus.underConstruction
  const isUpdating = useReselector(
    getIsFetching,
    AUTOCOMPLETE_TQ_KEY(filingForm.id)
  )
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const error = useReselector(getFetchError, AUTOCOMPLETE_TQ_KEY(filingForm.id))

  const onSubmit = async (filingFormId: number) => {
    setSubmitSuccess(Boolean(await autocompleteTq(filingFormId)(dispatch)))
  }
  return (
    <>
      <Grid.Row>
        <Grid.Column width={8}>
          <Text as="h3">{filingForm.formType.displayName}</Text>
        </Grid.Column>
        <Grid.Column width={8} textAlign="right">
          {isUnsubmittedForm(filingForm.taxfyleJob) && (
            <Button
              disabled={isUpdating}
              loading={isUpdating}
              floated="right"
              variant="actionLink"
              onClick={() => onSubmit(filingForm.id)}
            >
              Submit
            </Button>
          )}
          {!isUnsubmittedForm(filingForm.taxfyleJob) && (
            <Text>Already Submitted</Text>
          )}
        </Grid.Column>
      </Grid.Row>
      {error && (
        <GridRowColumn>
          <Alert type="error">{error.message}</Alert>
        </GridRowColumn>
      )}
      {submitSuccess && (
        <GridRowColumn>
          <Alert>Success!</Alert>
        </GridRowColumn>
      )}
    </>
  )
}

const AutoCompleteTqModal = ({
  open,
  onClose,
  userId,
}: {
  open: boolean
  onClose: () => void
  userId: number
}) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const annualTaxFiling = useReselector(
    selectAdminAnnualTaxFilingsByYearForUser,
    taxYear,
    userId
  )

  return (
    <Modal size="tiny" open={open} onClose={onClose} closeIcon>
      <Modal.Header>
        <Text as="h2">Autocomplete Tax Questionnaire</Text>
      </Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text>
              Clicking submit will autocomplete the Tax Questionnaire and create
              and submit the Taxfyle job
            </Text>
          </GridRowColumn>
          {annualTaxFiling?.annualTaxFilingForms.map((form) => (
            <ModalContent key={form.id} filingForm={form} />
          ))}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default AutoCompleteTqModal
