import { MouseEventHandler, useEffect, useState, useCallback } from 'react'
import AllocationGuideAmountsTable from './AllocationGuideAmountsTable'
import Moment from 'react-moment'
import CurrencyFormatLabel from '../shared/CurrencyFormatLabel'
import { Grid, Breadcrumb, Loader, Divider } from 'semantic-ui-react'
import { Alert, Button, Card, Link, Modal, Text } from '../BaseComponents'
import {
  editAllocationGuide,
  fetchAllocationGuidesSummary,
  fetchAllocationGuidesIfNeeded,
} from '../../actions/allocationGuideActions'
import { getAllocationGuideById } from '../../selectors/allocationGuideSelectors'
import { formatCurrency, centsToDollars } from '../../utils/currencyHelpers'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../utils/typeHelpers'
import { markUserActionItemCompleteIfExists } from '../../features/Dashboard/UserActionItems/service'
import { UserActionItemActionItemIdentifiers } from '../../features/Dashboard/UserActionItems/userActionItems.slice'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { useAnalyticsTrack, useAnalyticsView } from '../../features/Amplitude'
import PageHeader from '../shared/PageHeader'
import { useReselector } from '../../utils/sharedHooks'

const flexStyles = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
}

const SingleAllocationGuidePanel = () => {
  const dispatch = useAppDispatch()
  const { allocationGuideId } = useParams<{
    allocationGuideId: string | undefined
  }>()

  const allocationGuide = useReselector(
    getAllocationGuideById,
    allocationGuideId
  )

  const pageView = useAnalyticsView()
  const track = useAnalyticsTrack()

  useEffect(() => {
    pageView('allocation guide report')
  }, [pageView])

  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)

  const markActionItemsComplete = useCallback(async () => {
    if (allocationGuide) {
      const dateParts = allocationGuide?.date.split('-')

      // Make remove leading 0 if exists
      const month =
        dateParts[1] && dateParts[1][0] === '0'
          ? dateParts[1].substring(1)
          : dateParts[1]

      await markUserActionItemCompleteIfExists(
        UserActionItemActionItemIdentifiers.reviewAllocationGuide({
          month,
          year: dateParts[0],
        }),
        (event, properties) => track(event, properties)
      )
    }
  }, [allocationGuide, track])

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchAllocationGuidesIfNeeded())
      await markActionItemsComplete()
      setLoading(false)
    }
    fetch()
  }, [dispatch, markActionItemsComplete])

  const handleUpdateAllocationGuide: MouseEventHandler = (e) => {
    e.preventDefault()
    if (allocationGuide) {
      dispatch(editAllocationGuide(allocationGuide?.id))
      dispatch(fetchAllocationGuidesSummary())
    }
    setOpen(false)
  }

  const renderBody = () => {
    if (loading) {
      return <Loader inline active />
    }

    const profits =
      centsToDollars(allocationGuide?.incomeAtCompilationInCents) +
      centsToDollars(allocationGuide?.expensesAtCompilationInCents)

    return (
      <Card type="subsection" backgroundColor="stone40">
        <div style={flexStyles}>
          <Text as="h2">
            <Moment format={DATE_FORMATS.MONTH_YEAR}>
              {allocationGuide?.date}
            </Moment>{' '}
            Allocation Guide
          </Text>
          <Text>
            Compiled on{' '}
            <Moment format={DATE_FORMATS.DISPLAY_LONG}>
              {allocationGuide?.compiledOn}
            </Moment>
            <Modal
              size={'small'}
              dimmer={'inverted'}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              open={open}
              trigger={
                <Button
                  inline
                  size="medium"
                  style={{ marginLeft: '1em' }}
                  variant="secondary"
                >
                  Update
                </Button>
              }
            >
              <Modal.Header>
                <Text as="h2">Confirm Allocation Guide Update</Text>
              </Modal.Header>
              <Modal.Content>
                <Text>
                  Please Confirm that you would like to make changes to the
                  selected allocation guide. Changes will be based on the
                  currently set percentages. Would you like to continue?
                </Text>
              </Modal.Content>
              <Modal.Actions>
                <Button variant="secondary" onClick={() => setOpen(false)}>
                  Cancel
                </Button>
                <Button onClick={handleUpdateAllocationGuide}>
                  Confirm Changes
                </Button>
              </Modal.Actions>
            </Modal>
          </Text>
        </div>

        {profits < 0 && (
          <Alert type="info">
            When your monthly profit is negative, all allocations will be set to
            $0.00
          </Alert>
        )}

        <Divider />
        <Grid padded doubling stackable>
          <Grid.Column width={9}>
            <AllocationGuideAmountsTable
              allocationGuide={allocationGuide}
              profits={profits}
            />
          </Grid.Column>

          <Grid.Column width={7}>
            <Card type="section">
              <Text as="h2" style={{ marginBottom: 4 }}>
                Profit and Loss*
              </Text>
              <Text>
                <i>*at time of allocation guide creation</i>
              </Text>
              <br />
              <div style={flexStyles}>
                <Text as="bodyLg">Income</Text>
                <Text as="bodyLg">
                  <CurrencyFormatLabel
                    value={formatCurrency(
                      centsToDollars(
                        allocationGuide?.incomeAtCompilationInCents
                      )
                    )}
                  />
                </Text>
              </div>
              <div style={flexStyles}>
                <Text as="bodyLg">Expenses</Text>
                <Text as="bodyLg">
                  <CurrencyFormatLabel
                    value={formatCurrency(
                      centsToDollars(
                        allocationGuide?.expensesAtCompilationInCents
                      )
                    )}
                  />
                </Text>
              </div>
              <div style={flexStyles}>
                <Text as="bodyLg">
                  <b>Profit</b>
                </Text>
                <Text as="bodyLg">
                  <b>
                    <CurrencyFormatLabel value={formatCurrency(profits)} />
                  </b>
                </Text>
              </div>
            </Card>
          </Grid.Column>
        </Grid>
      </Card>
    )
  }

  return (
    <>
      <PageHeader header="Allocation Guides" />
      <br />
      <Breadcrumb size="small">
        <Breadcrumb.Section>
          <Link to="/allocation-guides" size="small">
            All Allocations
          </Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>
          <Text>
            <Moment format={DATE_FORMATS.MONTH_YEAR}>
              {allocationGuide?.date}
            </Moment>
          </Text>
        </Breadcrumb.Section>
      </Breadcrumb>
      {allocationGuide && renderBody()}
    </>
  )
}

export default SingleAllocationGuidePanel
