import AhaMomentsBaseComponent from '../AhaMomentsBaseComponent'
import { Link, Text } from '../../../../components/BaseComponents'

const LearnHowBookkeepingWorks = ({
  handleContinue,
  handleBack,
}: {
  handleContinue: () => void
  handleBack: () => void
}) => {
  return (
    <AhaMomentsBaseComponent
      title="How bookkeeping works"
      subtitle="Why is bookkeeping important?"
      description="It all starts with bookkeeping. Your financial transactions power everything you do with Heard."
      media={
        <iframe
          src="https://www.loom.com/embed/dd741b9383394cba976c2c6430f34d50"
          allow="autoplay; fullscreen"
          allowFullScreen
          width="100%"
          height="300px"
          title="Why is bookkeeping important?"
          sandbox="allow-scripts allow-same-origin allow-popups"
        />
      }
      features={[
        {
          imgSrc:
            'https://heard-images.s3.amazonaws.com/assets/chart-tablet.png',
          title: 'Get insights on your income and expenses',
          description:
            'We use your books to provide detailed reports and insights that help you manage your therapy business.',
        },
        {
          imgSrc: 'https://heard-images.s3.amazonaws.com/assets/3coins.png',
          title: 'Save on taxes by finding deductions',
          description:
            'We find and keep track of all relevant deductions, allowing you to reduce your overall tax bill.',
        },
        {
          imgSrc: 'https://heard-images.s3.amazonaws.com/assets/check_list.svg',
          title: 'Stay compliant with the IRS',
          description:
            'We navigate the ins and outs of keeping your books in compliance with the IRS.',
        },
      ]}
      learnMoreItems={[
        {
          title: 'What is a transaction?',
          content: (
            <Text>
              A transaction is an exchange of goods, services, or money made via
              a sale, purchase, expense, revenue, investment, loan, and other
              financial actions.
            </Text>
          ),
        },
        {
          title: 'How does Heard help me find deductions?',
          content: (
            <Text>
              Some categories of transactions can be deducted from your taxable
              income, lowering your overall tax bill. Our bookkeeping team will
              take care of identifying these, using their expertise to determine
              if your transactions qualify. You don&apos;t have to do anything
              else.{' '}
              <Link
                href="https://support.joinheard.com/hc/en-us/articles/1500001922761-Am-I-eligible-for-tax-deductions"
                newPage
                style={{ textDecoration: 'underline' }}
              >
                Learn more
              </Link>
            </Text>
          ),
        },
      ]}
      backButtonText="Back"
      continueButtonText="Continue"
      handleContinue={handleContinue}
      handleBack={handleBack}
    />
  )
}

export default LearnHowBookkeepingWorks
