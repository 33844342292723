import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { addEmployeeJob, removeEmployeeJob } from './employeeJob.slice'
import { Gusto_Employee } from './generated_gusto_types'

export type EmployeeWithoutJob = Omit<Gusto_Employee, 'jobs'> & {
  jobs: string[]
}

export interface EmployeeState {
  // Jobs are pulled off in different redux
  [key: string]: EmployeeWithoutJob
}

const employeeSlice = createSlice({
  name: 'employee',
  initialState: {} as EmployeeState,
  reducers: {
    setSingleEmployee: (state, action: PayloadAction<EmployeeWithoutJob>) => {
      state[action.payload.uuid] = action.payload
    },
    setMultipleEmployees: (
      state,
      action: PayloadAction<{ [key: string]: EmployeeWithoutJob }>
    ) => ({ ...state, ...action.payload }),
    removeEmployee: (state, action: PayloadAction<string>) => {
      delete state[action.payload]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addEmployeeJob, (state, action) => {
      if (state[action.payload.employee_uuid]) {
        state[action.payload.employee_uuid].jobs.push(action.payload.uuid)
      }
    })

    builder.addCase(removeEmployeeJob, (state, action) => {
      if (state[action.payload.employeeUuid]) {
        state[action.payload.employeeUuid].jobs = state[
          action.payload.employeeUuid
        ].jobs.filter((uuid) => uuid !== action.payload.jobUuid)
      }
    })
  },
})

export const { setSingleEmployee, setMultipleEmployees, removeEmployee } =
  employeeSlice.actions

export default employeeSlice.reducer
