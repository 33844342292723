import React, { useCallback, useMemo, useState, ComponentClass } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal, Header, Message, Icon } from 'semantic-ui-react'
import moment from 'moment'
import { useCSVReader } from 'react-papaparse'

import { importTransactions } from '../../../actions/admin/transactionUploadActions'
import { getLockedTransactionDescription } from '../../../features/Transactions/copyConstants'
import LockedBooksMessage from '../../../features/Transactions/LockedBooksMessage'
import { User } from '../../../reducers/auth/userReducer'
import { Colors } from '../../../styles/theme'
import { dollarsToCents } from '../../../utils/currencyHelpers'
import { useReselector } from '../../../utils/sharedHooks'
import { Dropdown } from '../../BaseComponents'
import { getManualUploadAccountsByUserId } from '../../../selectors/financeSelectors'
import { useAppDispatch } from '../../../utils/typeHelpers'

const TransactionImportModal = ({
  user,
  open,
  close,
}: {
  user: User
  open: boolean
  close: () => void
}) => {
  const dispatch = useAppDispatch()
  const { CSVReader } = useCSVReader()
  const { booksLockedForAdminYear, booksLockedForUserYear, id: userId } = user

  const accounts = useReselector(getManualUploadAccountsByUserId, userId)

  const accountOptions = useMemo(
    () =>
      accounts.map((account) => ({
        text: `${account.plaidInstitutionName} — ${account.name}:${account.mask}`,
        value: account.id,
      })),
    [accounts]
  )

  const [step, setStep] = useState(1)
  const [accountId, setAccountId] = useState<number>()

  const manualAccountId = useMemo(() => {
    return accounts.find((account) => account?.id === accountId)?.manualAccount
      ?.id
  }, [accountId, accounts])

  const accountText = useMemo(
    () => accountOptions.find(({ value }) => value === accountId)?.text,
    [accountId, accountOptions]
  )

  const [errors, setErrors] = useState<string[]>([])
  const [transactionsToUpload, setTransactionsToUpload] = useState<string[][]>(
    []
  )
  const [importing, setImporting] = useState(false)

  const handleOnDrop = useCallback(
    ({ data }: { data: string[][] }) => {
      const newErrors: string[] = []
      const headers = data[0]
      const transactions = data.slice(1)
      if (headers[0] !== 'date') {
        newErrors.push('date needs to be the first column')
      }

      if (headers[1] !== 'description') {
        newErrors.push('description needs to be the second column')
      }

      if (headers[2] !== 'amount') {
        newErrors.push('amount needs to be the third column')
      }

      transactions.forEach((tx, i) => {
        if (tx.length !== 3) {
          newErrors.push(`Row ${i + 1} has incorrect amount of columns`)
        } else {
          const transactionMoment = moment(tx[0])
          if (!transactionMoment.isValid()) {
            newErrors.push(`Row ${i + 1} has invalid date`)
          } else if (
            transactionMoment.year().toString() === booksLockedForAdminYear
          ) {
            newErrors.push(
              getLockedTransactionDescription(booksLockedForAdminYear)
                .adminLockedBannerMessage
            )
          }

          if (!/^-?\d+\.?\d?\d?$/.test(tx[2])) {
            newErrors.push(`Row ${i + 1} has invalid amount`)
          }
        }
      })

      setErrors((errs) => [...errs, ...newErrors])
      if (!newErrors.length) {
        setTransactionsToUpload(
          transactions.map((tx) => [
            tx[0],
            tx[1],
            dollarsToCents(tx[2]).toString(),
          ])
        )
      }
    },
    [booksLockedForAdminYear]
  )

  const handleOnRemoveFile = useCallback(() => {
    setErrors([])
    setTransactionsToUpload([])
  }, [])

  const handleBulkImport = useCallback(async () => {
    if (!accountId || !transactionsToUpload.length) {
      return
    }
    setImporting(true)
    const data: {
      userId: number
      importedOn: number
      accountId: number
      dataArray: string[][]
      manualAccountId?: number | undefined
    } = {
      userId,
      importedOn: Date.now(),
      accountId,
      dataArray: transactionsToUpload,
    }
    if (manualAccountId) {
      data.manualAccountId = manualAccountId
    }

    const result = await importTransactions(userId, data)(dispatch)

    if (result) {
      setStep(3)
    }
    setImporting(false)
  }, [accountId, dispatch, transactionsToUpload, userId, manualAccountId])

  const errorMessages = useMemo(() => {
    if (errors.length > 0) {
      return (
        <Message error>
          <Message.Header>Fix the following errors:</Message.Header>
          {errors.map((error) => (
            <p key={error}>{error}</p>
          ))}
        </Message>
      )
    }
    return null
  }, [errors])

  const content = useMemo(() => {
    if (step === 1) {
      const lockedYear = booksLockedForAdminYear || booksLockedForUserYear
      return (
        <Modal.Content>
          <Header as="h5">Step 1 of 3</Header>
          <Header as="h4">Upload Transactions Manually</Header>
          {lockedYear && (
            <LockedBooksMessage
              className="noBorder"
              year={lockedYear}
              admin
              userOnlyLocked={
                Boolean(booksLockedForUserYear) && !booksLockedForAdminYear
              }
            />
          )}
          <Message warning>
            <b>Warning: You are uploading transactions manually.</b>
            <br />
            This means that the user is unable to connect this account via Plaid
            <br />
            <b>Please only use as a last resort.</b>
          </Message>
          {accounts.length === 0 && (
            <p>
              No manual account for this user. First create a Financial Account
              to upload transactions for, under the &#39;Financial Account&#39;
              card.
            </p>
          )}

          {accounts.length > 0 && (
            <Dropdown
              fullWidth
              placeholder="Select Manual Financial Account"
              value={accountId}
              onChange={setAccountId}
              required
              options={accountOptions}
              label="Select Financial Account to associate transactions: "
            />
          )}
        </Modal.Content>
      )
    } else if (step === 2) {
      return (
        <Modal.Content>
          <Header as="h5">Step 2 of 3</Header>
          <Header as="h4">
            Uploading Transactions for Account: {accountText}
          </Header>
          <Message warning>
            <b>
              Your CSV must have the following headers, with the exact same
              spelling and order. The CSV will be rejected otherwise:
            </b>
            <br />
            <b>date</b> — date of transaction
            <br />
            <b>description</b> — memo of transaction
            <br />
            <b>amount</b> — transaction amount in dollars. make sure you keep
            the negative numbers.
            <br />
          </Message>
          {errorMessages}
          <CSVReader<string>
            onUploadAccepted={handleOnDrop}
            onUploadRejected={() =>
              setErrors((errs) => [...errs, 'Upload rejected'])
            }
            noDrag
            addRemoveButton
          >
            {({
              getRootProps,
              acceptedFile,
              ProgressBar,
              getRemoveFileProps,
            }: {
              getRootProps: () => {
                onClick: (
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => void
              }
              acceptedFile?: { name: string; size: number }
              ProgressBar: ComponentClass
              getRemoveFileProps: () => {
                onClick: (
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => void
              }
            }) => (
              <>
                <div
                  style={{
                    border: '1px dotted #B3B3B3',
                    padding: 10,
                    borderRadius: 3,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {!acceptedFile && (
                    <Button {...getRootProps()} className="link">
                      Select File to Upload
                    </Button>
                  )}
                  {acceptedFile && (
                    <div
                      style={{
                        background: Colors.lightGray,
                        borderRadius: 5,
                        padding: 10,
                        display: 'flex',
                      }}
                    >
                      <p style={{ textAlign: 'center' }}>
                        {acceptedFile.size}B<br />
                        {acceptedFile.name}
                      </p>
                      <Button
                        onClick={(e) => {
                          getRemoveFileProps().onClick(e)
                          handleOnRemoveFile()
                        }}
                        style={{
                          backgroundColor: Colors.red,
                          color: Colors.white,
                          borderRadius: 10,
                          height: 20,
                          width: 20,
                          padding: 0,
                        }}
                      >
                        &#x2715;
                      </Button>
                    </div>
                  )}
                </div>
                <ProgressBar />
              </>
            )}
          </CSVReader>

          {transactionsToUpload.length > 0 && (
            <div>
              <p>
                You are manually uploading {transactionsToUpload.length}{' '}
                transactions.
              </p>
            </div>
          )}
        </Modal.Content>
      )
    } else if (step === 3) {
      return (
        <Modal.Content>
          <Header as="h5">Step 3 of 3</Header>
          <Header as="h4">
            Uploading Transactions for Account: {accountText}
          </Header>

          <Header as="h6" textAlign="center">
            <Icon name="check circle" color="green" size="large" />
            Success! You&#39;ve imported transactions.
          </Header>
          <p>
            Please view the newly imported transactions to double check for
            errors
          </p>
        </Modal.Content>
      )
    } else {
      return (
        <Modal.Content>
          <p>There is something awry! You&#39;re at an unknown step.</p>
          <p>Please refresh and try again</p>
        </Modal.Content>
      )
    }
  }, [
    CSVReader,
    accountId,
    accountOptions,
    accountText,
    accounts.length,
    errorMessages,
    handleOnDrop,
    handleOnRemoveFile,
    step,
    transactionsToUpload.length,
    booksLockedForAdminYear,
    booksLockedForUserYear,
  ])

  const actions = useMemo(() => {
    if (step === 1) {
      return (
        <Modal.Actions>
          <Button secondary onClick={close}>
            Close
          </Button>
          <Button primary disabled={!accountId} onClick={() => setStep(2)}>
            Next
          </Button>
        </Modal.Actions>
      )
    } else if (step === 2) {
      const disabled =
        transactionsToUpload.length === 0 || errors.length > 0 || !accountId
      return (
        <Modal.Actions>
          <Button secondary onClick={close}>
            Close
          </Button>
          <Button
            primary
            disabled={disabled}
            loading={importing}
            onClick={handleBulkImport}
          >
            Import Transactions
          </Button>
        </Modal.Actions>
      )
    } else if (step === 3) {
      return (
        <Modal.Actions>
          <Button secondary onClick={close}>
            Close
          </Button>

          <Link to={`/admin/finances/transactions/${userId}/imported`}>
            <Button primary>View Imported Transactions</Button>
          </Link>
        </Modal.Actions>
      )
    } else {
      return (
        <Modal.Actions>
          <Button secondary onClick={close}>
            Close
          </Button>
        </Modal.Actions>
      )
    }
  }, [
    accountId,
    close,
    errors.length,
    handleBulkImport,
    importing,
    step,
    transactionsToUpload.length,
    userId,
  ])

  return (
    <Modal
      size={'small'}
      dimmer={'inverted'}
      className="transactionImportModal"
      open={open}
      onClose={close}
    >
      {content}
      {actions}
    </Modal>
  )
}

export default TransactionImportModal
