import { Divider, Grid } from 'semantic-ui-react'
import { Card, GridRowColumn, Text } from '../../../components/BaseComponents'
import { InstitutionLogo } from '../../../components/Finances/Accounts/InstitutionCard'
import { PlaidItem } from '../../../reducers/finances/plaidItemReducer'

export const UnsupportedInstitutionCard = ({
  institution,
}: {
  institution: PlaidItem
}) => {
  return (
    <Card
      type="subsection"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '1rem',
      }}
    >
      <Grid
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          padding: 0,
        }}
      >
        <Grid.Row
          style={{
            flex: 'none',
            paddingBottom: '0.5rem',
            paddingLeft: '1rem',
          }}
        >
          <Grid.Column width={3} style={{ padding: 0 }}>
            <InstitutionLogo
              className="institutionLogo"
              institution={institution}
            />
          </Grid.Column>
          <Grid.Column
            width={13}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: 0,
            }}
          >
            <div
              className="nameLabel"
              style={{ flexGrow: 1, paddingLeft: '0.5rem' }}
            >
              <Text as="h3" style={{ textAlign: 'left', margin: 0 }}>
                {institution.institutionName}
              </Text>
            </div>
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn
          style={{
            flex: 'none',
            padding: 0,
          }}
        >
          <Divider />
        </GridRowColumn>
        <GridRowColumn style={{ flex: 1, padding: 0 }}>
          <ul style={{ margin: 0, paddingLeft: '1rem' }}>
            {institution.accounts.map((account) => (
              <li key={account.id}>{account.name}</li>
            ))}
          </ul>
        </GridRowColumn>
      </Grid>
    </Card>
  )
}
