import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { keyBy, uniq } from 'lodash'

import { fetchWrapper } from '../../../reducers/fetch'
import { User } from '../../../reducers/auth/userReducer'

export interface TransactionChangeLog {
  id: number
  authorId: number
  author: User
  transactionId: number
  columnName: string
  currentValue: string
  previousValue: string
  createdAt: number
  deletedAt: number
}

export interface TransactionChangeLogsState {
  byId: { [key: string]: TransactionChangeLog }
  allIds: string[]
}

const initialState: TransactionChangeLogsState = {
  byId: {},
  allIds: [],
}

const transactionChangeLogsSlice = createSlice({
  name: 'transactionChangeLogs',
  initialState,
  reducers: {
    receiveChangeLogsForSingleTransaction: (
      state,
      action: PayloadAction<{ [key: string]: TransactionChangeLog }>
    ) => {
      state.byId = { ...state.byId, ...action.payload }

      state.allIds = uniq([
        ...state.allIds,
        ...Object.values(action.payload).map((l) => l.id.toString()),
      ])
    },
  },
})

export default transactionChangeLogsSlice.reducer

export const { receiveChangeLogsForSingleTransaction } =
  transactionChangeLogsSlice.actions

export const FETCH_TRANSACTION_CHANGE_LOGS_KEY =
  'FETCH_TRANSACTION_CHANGE_LOGS_KEY_'
export const fetchChangeLogsForSingleTransaction = ({
  transactionId,
}: {
  transactionId: number
}) =>
  fetchWrapper({
    fetchKey: FETCH_TRANSACTION_CHANGE_LOGS_KEY + transactionId,
    defaultErrorMessage: `Error fetching transaction change logs for id: ${transactionId}`,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<TransactionChangeLog[]>(
        '/finances/api/v1/admin/transaction_change_logs',
        { params: { transactionId } }
      )

      dispatch(receiveChangeLogsForSingleTransaction(keyBy(json.data, 'id')))

      return json.data
    },
  })

export const FETCH_USER_TRANSACTION_CHANGE_LOG_KEY =
  'FETCH_USER_TRANSACTION_CHANGE_LOG_KEY_'
export const fetchChangeLogsForUserTransaction = ({
  transactionId,
}: {
  transactionId: number
}) =>
  fetchWrapper({
    fetchKey: FETCH_USER_TRANSACTION_CHANGE_LOG_KEY + transactionId,
    defaultErrorMessage: `Error fetching transaction change logs for id: ${transactionId}`,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<TransactionChangeLog[]>(
        '/finances/api/v1/transaction_change_logs',
        { params: { transactionId } }
      )

      dispatch(receiveChangeLogsForSingleTransaction(keyBy(json.data, 'id')))

      return json.data
    },
  })
