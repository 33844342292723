import {
  Component,
  createRef,
  forwardRef,
  RefObject,
  useImperativeHandle,
} from 'react'
import * as yup from 'yup'
import { FormikProvider, useFormik } from 'formik'
import { FormikProps } from 'formik/dist/types'
import { noop } from 'lodash'

import { GUSTO_STATE_OPTIONS } from '../../constants/statesShortNamesConstants'
import {
  FormikDropdown,
  FormikInput,
  FormikLocationSearchInput,
  getFieldName,
  makeReqPhoneNumberSchema,
  makeReqStringSchema,
  parseNationalPhoneNumber,
} from '../../components/BaseComponents'

const END_VAL = 'for this location'
const makeValidationSchema = (withPhone: boolean) =>
  yup.object({
    street_1: makeReqStringSchema({ field: 'street address', end: END_VAL }),
    street_2: yup.string().nullable(),
    city: makeReqStringSchema({ field: 'city', end: END_VAL }),
    state: makeReqStringSchema({ field: 'state', end: END_VAL }),
    zip: makeReqStringSchema({ field: 'ZIP code', end: END_VAL }),
    ...(withPhone
      ? {
          phone_number: makeReqPhoneNumberSchema(),
        }
      : {}),
  })

interface ValidAddress {
  street_1: string
  street_2: string | null
  city: string
  state: string
  zip: string
  phone_number: string
}

interface FormValues {
  street_1?: string | null
  street_2?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
  phone_number?: string | null
}

type Props = {
  version?: string | null
  hasPhoneNumber?: boolean
} & FormValues

const AddressForm = forwardRef((props: Props, ref) => {
  const formik = useFormik({
    initialValues: {
      street_1: props.street_1,
      street_2: props.street_2,
      city: props.city,
      state: props.state,
      zip: props.zip,
      ...(props.hasPhoneNumber !== false
        ? { phone_number: props.phone_number }
        : {}),
    },
    validationSchema: makeValidationSchema(props.hasPhoneNumber !== false),
    onSubmit: noop,
  })

  useImperativeHandle(ref, () => ({ ...formik }))

  return (
    <FormikProvider value={formik}>
      <FormikLocationSearchInput
        name={getFieldName<typeof formik.values>('street_1')}
        label="Street 1"
        required
        fullWidth
        streetAddressOnly
        street2Name={getFieldName<typeof formik.values>('street_2')}
      />
      <br />
      <FormikInput
        name={getFieldName<typeof formik.values>('street_2')}
        label="Street 2"
        fullWidth
      />
      <br />
      <FormikInput
        name={getFieldName<typeof formik.values>('city')}
        label="City"
        required
        fullWidth
      />
      <br />
      <FormikDropdown
        name={getFieldName<typeof formik.values>('state')}
        label="State"
        required
        optionValues={GUSTO_STATE_OPTIONS}
        fullWidth
      />
      <br />
      <FormikInput
        name={getFieldName<typeof formik.values>('zip')}
        label="Zip"
        required
      />
      {/*Some addresses don't have phone numbers (ie employee home)*/}
      {props.hasPhoneNumber !== false && (
        <>
          <br />
          <FormikInput
            name={getFieldName<typeof formik.values>('phone_number')}
            label="Phone"
            required
            fullWidth
            componentType="phone"
          />
        </>
      )}
    </FormikProvider>
  )
})

class GustoAddress extends Component<Props> {
  formRef: RefObject<FormikProps<FormValues>>

  constructor(props: Props) {
    super(props)
    this.formRef = createRef<FormikProps<FormValues>>()
  }

  getAddressWithPhone = (): ValidAddress | null => {
    // This validates form
    this.formRef.current?.submitForm()

    if (this.formRef.current?.isValid) {
      const { street_1, street_2, city, state, zip, phone_number } =
        this.formRef.current.values

      if (street_1 && city && state && zip && phone_number) {
        return {
          street_1,
          street_2: street_2 || null,
          city,
          state,
          zip,
          phone_number: parseNationalPhoneNumber(phone_number) || '',
        }
      }
    }

    return null
  }

  getAddressWithoutPhone = (): Omit<ValidAddress, 'phone_number'> | null => {
    // This validates form
    this.formRef.current?.submitForm()

    if (this.formRef.current?.isValid) {
      const { street_1, street_2, city, state, zip } =
        this.formRef.current.values

      if (street_1 && city && state && zip) {
        return {
          street_1,
          street_2: street_2 || null,
          city,
          state,
          zip,
        }
      }
    }

    return null
  }

  isValid = () => this.formRef.current?.isValid || false

  clear = () => this.formRef.current?.resetForm()

  render() {
    const {
      street_1,
      street_2,
      city,
      state,
      zip,
      phone_number,
      hasPhoneNumber,
    } = this.props

    return (
      <AddressForm
        ref={this.formRef}
        street_1={street_1}
        street_2={street_2}
        city={city}
        state={state}
        zip={zip}
        phone_number={phone_number}
        hasPhoneNumber={hasPhoneNumber}
      />
    )
  }
}

export default GustoAddress
