import { useState } from 'react'
import Pagination from '../components/BaseComponents/Pagination'

export const ShortPagination = () => {
  const [page, setPage] = useState(1)

  return <Pagination currentPage={page} onPageClick={setPage} pages={5} />
}

export const SevenPagination = () => {
  const [page, setPage] = useState(1)

  return <Pagination currentPage={page} onPageClick={setPage} pages={7} />
}

export const EightPagination = () => {
  const [page, setPage] = useState(1)

  return <Pagination currentPage={page} onPageClick={setPage} pages={8} />
}

export const LongPagination = () => {
  const [page, setPage] = useState(1)

  return <Pagination currentPage={page} onPageClick={setPage} pages={50} />
}

export const DisabledPagination = () => {
  const [page, setPage] = useState(3)

  return (
    <Pagination disabled currentPage={page} onPageClick={setPage} pages={7} />
  )
}
