import axios from 'axios'
import { keyBy } from 'lodash'
import { fetchIfNeededWrapper } from '../reducers/fetch'
import {
  PlaidStatementInstitution,
  receivePlaidStatementInstitutions,
} from '../reducers/finances/plaidStatementInstitutionsReducers'

export const FETCH_PLAID_STATEMENT_INSTITUTIONS_KEY =
  'FETCH_PLAID_STATEMENT_INSTITUTIONS_KEY'
export const fetchPlaidStatementInstitutionsIfNeeded = (alwaysFetch = false) =>
  fetchIfNeededWrapper({
    defaultErrorMessage: 'There was an error fetching your accounts.',
    alwaysFetch,
    fetchKey: FETCH_PLAID_STATEMENT_INSTITUTIONS_KEY,
    fetchFunction: (dispatch) =>
      axios
        .get<
          PlaidStatementInstitution[]
        >('/finances/api/v1/plaid/statement_institutions')
        .then((json) => {
          dispatch(receivePlaidStatementInstitutions(keyBy(json.data, 'id')))
          return json.data
        }),
  })
