import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Image } from 'semantic-ui-react'
import { debounce, uniq } from 'lodash'

import {
  Card,
  GridRowColumn,
  Text,
} from '../../../../../components/BaseComponents'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../utils/sharedHooks'
import UploadCard from './UploadCard'
import {
  FETCH_DOCUMENT_CATEGORIES_KEY,
  fetchUserDocumentCategoriesIfNeeded,
} from '../../../../Admin/UserDocumentCategories/userDocumentCategories.slice'
import {
  FETCH_USER_DOCUMENTS_KEY,
  fetchUserDocuments,
} from '../../../../UserDocuments/userDocuments.slice'
import { selectAnnualTaxFilingFormById } from '../../annualTaxFilingForms.selector'
import {
  FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
  fetchAnnualTaxFilingFormsIfNeeded,
} from '../../annualTaxFilingForms.slice'
import { fetchUserTaxQuestionnaire } from '../taxChecklistQuestion.actions'
import {
  selectAllRequiredDocsUploaded,
  selectDocumentProgressForForm,
  selectRequiredDocCatsForForm,
} from '../taxChecklist.selectors'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import { selectIsFetchingForKeys } from '../../../../../reducers/fetch'
import useProgressSteps from '../Shared/ReviewStepsandProgresses/useProgressSteps'
import {
  SubStepIdentifiers,
  UPLOAD_DOCUMENTS_1040,
  UPLOAD_DOCUMENTS_1120S,
} from '../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { TAX_ENTITY_TYPES } from '../../../taxConstants'
import { useNavigateWithLocation } from '../../../../../utils/routeHelpers'
import { updateUserEoyReviewProgress } from '../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { selectTqIsReadOnly } from '../../annualTaxFilings.selector'
import PageHeader from '../../../../../components/shared/PageHeader'
import {
  FETCH_TAX_USER_DOCUMENTS_KEY,
  fetchTaxUserDocumentsIfNeeded,
} from '../../taxUserDocuments.slice'
import { useAppDispatch } from '../../../../../utils/typeHelpers'

const UploadDocuments = () => {
  const { formId } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigateWithLocation()
  const form = useReselector(selectAnnualTaxFilingFormById, formId)
  const progress = useReselector(selectDocumentProgressForForm, formId)
  // This is separated out from redux state for more responsive UI
  const [skippedCategories, setSkippedCategories] = useState<string[]>()
  const readOnly = useReselector(selectTqIsReadOnly, formId)

  // Set the skipped categories once progress is loaded
  useEffect(() => {
    if (!skippedCategories && progress) {
      setSkippedCategories(
        progress?.responses?.[0]?.skippedDocumentCategories || []
      )
    }
  }, [progress, skippedCategories])

  const updateSkipped = useCallback((identifier: string, checked: boolean) => {
    setSkippedCategories((skippedCategories) =>
      checked
        ? uniq([...(skippedCategories || []), identifier])
        : (skippedCategories || []).filter((cat) => cat !== identifier)
    )
  }, [])

  const debounceUpdateProgress = useMemo(
    () =>
      debounce(
        (progressId: number, sentSkippedCategories: string[]) =>
          updateUserEoyReviewProgress(progressId, {
            responses: [{ skippedDocumentCategories: sentSkippedCategories }],
          })(dispatch),
        500
      ),
    [dispatch]
  )

  useEffect(() => {
    // Don't run if skipped categories hasn't been initialized yet
    if (skippedCategories && progress?.id) {
      debounceUpdateProgress(progress.id, skippedCategories)
    }
  }, [debounceUpdateProgress, progress?.id, skippedCategories])

  const is1040 = form?.formType?.name === TAX_ENTITY_TYPES.form_1040
  const formSteps = form
    ? is1040
      ? UPLOAD_DOCUMENTS_1040
      : UPLOAD_DOCUMENTS_1120S
    : []

  const { updateProgressData, fetchKeys: progressFetchKeys } = useProgressSteps(
    { steps: formSteps }
  )

  const isLoading = useReselector(selectIsFetchingForKeys, [
    FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
    FETCH_USER_DOCUMENTS_KEY,
    FETCH_TAX_USER_DOCUMENTS_KEY,
    FETCH_DOCUMENT_CATEGORIES_KEY,
    ...progressFetchKeys,
  ])

  const allRequiredDocsUploaded = useReselector(
    selectAllRequiredDocsUploaded,
    formId
  )

  useEffect(() => {
    if (form?.year) {
      dispatch(fetchUserTaxQuestionnaire(form.year))
    }
  }, [dispatch, form?.year])

  const { required, optional } = useReselector(
    selectRequiredDocCatsForForm,
    form?.id
  )

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchUserDocuments())
    dispatch(fetchTaxUserDocumentsIfNeeded())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
  }, [dispatch])

  const saveAndReturn = useAsyncCallback(async () => {
    const progressUpdatesSuccessful = await updateProgressData({
      completedSteps: [
        is1040
          ? SubStepIdentifiers.uploadDocuments1040
          : SubStepIdentifiers.uploadDocuments1120s,
      ],
    })

    if (progressUpdatesSuccessful) {
      navigate(`/taxes/annual/tax_checklist/${formId}`)
    }
  })

  return (
    <>
      {readOnly && (
        <PageHeader
          backControl={{
            link: `/taxes/annual/tax_checklist/${formId}`,
            text: `Back to ${form?.formType?.name === TAX_ENTITY_TYPES.form_1120_s ? 'Business' : 'Personal'} Tax Checklist`,
          }}
          header={''}
        />
      )}
      <Grid>
        <Grid.Row />
        <GridRowColumn centerContent>
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/paper-plane.svg"
            style={{ width: 128, height: 120 }}
          />
        </GridRowColumn>
        <GridRowColumn short>
          <Text as="h1" textAlign="center">
            Upload Forms
          </Text>
        </GridRowColumn>
        {!readOnly && (
          <GridRowColumn spacer={3} width={10}>
            <Text>
              Based on your answers in the questionnaire, we’ve identified the
              documents you need for your tax return.
            </Text>
          </GridRowColumn>
        )}
        <Grid.Row />
        <GridRowColumn>
          {/*wrapped by card to match spacing below*/}
          <Card
            backgroundColor="white"
            type="subsection"
            style={{ paddingBottom: 0 }}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Text as="eyebrow" color="darkGray">
                    document type
                  </Text>
                </Grid.Column>
                <Grid.Column width={9}>
                  <Text as="eyebrow" color="darkGray">
                    why you might have this
                  </Text>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Text as="eyebrow" color="darkGray">
                    upload forms
                  </Text>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card>
        </GridRowColumn>
        {required.map((category) => (
          <UploadCard
            readOnly={readOnly}
            key={category}
            docCategoryIdentifier={category}
            formId={Number(formId)}
            isRequired
            skipped={skippedCategories?.includes(category)}
            updateSkipped={updateSkipped}
          />
        ))}
        {optional.map((category) => (
          <UploadCard
            readOnly={readOnly}
            key={category}
            docCategoryIdentifier={category}
            formId={Number(formId)}
            skipped={skippedCategories?.includes(category)}
            updateSkipped={updateSkipped}
          />
        ))}
        <Grid.Row />
        <GridRowColumn textAlign="center">
          <Text as="h2">Other Documents</Text>
        </GridRowColumn>
        <UploadCard
          docCategoryIdentifier=""
          formId={Number(formId)}
          readOnly={readOnly}
          isOtherCat
        />
        {!readOnly && (
          <FormFlowFooter
            onBack={() => navigate(`/taxes/annual/tax_checklist/${formId}`)}
            continueDisabled={!allRequiredDocsUploaded || saveAndReturn.loading}
            onForward={saveAndReturn.callback}
            loading={isLoading || saveAndReturn.loading}
          />
        )}
      </Grid>
    </>
  )
}

export default UploadDocuments
