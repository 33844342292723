import { useEffect } from 'react'

import { useReselector } from '../../utils/sharedHooks'
import { GET_ZENDESK_SSO_KEY, getZendeskSso } from '../../actions/authActions'
import { getFetchError } from '../../reducers/fetch'
import { Loader, Alert } from '../../components/BaseComponents'
import { useZendeskSso } from './helpers'
import { useAppDispatch } from '../../utils/typeHelpers'

const ZendeskSso = () => {
  const dispatch = useAppDispatch()
  const error = useReselector(getFetchError, GET_ZENDESK_SSO_KEY)
  const { zendeskForm, submitForm } = useZendeskSso()

  useEffect(() => {
    const fetch = async () => {
      const res = await dispatch(getZendeskSso())

      if (res) {
        submitForm(res.data.jwt)
      }
    }

    fetch()
  }, [dispatch, submitForm])

  if (error) {
    return <Alert type="error" />
  }

  return (
    <>
      <Loader />
      {zendeskForm}
    </>
  )
}

export default ZendeskSso
