import { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Card,
  Dropdown,
  Text,
} from '../../../../components/BaseComponents'
import { Grid } from 'semantic-ui-react'
import CurrencyFormatLabel from '../../../../components/shared/CurrencyFormatLabel'
import { centsToDollars } from '../../../../utils/currencyHelpers'
import { Transaction } from '../../../../reducers/admin/allTransactions.slice'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../../utils/dateHelpers'
import { TransactionRuleCategoryType } from '../../../../reducers/admin/transactionRulesReducer'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  getAllTransactionCategories,
  selectCategoriesByType,
  selectNonExpenseAndIncomeCategories,
} from '../../../Reports/reports.selectors'
import SampleTransactionNotesModal from './SampleTransactionNotesModal'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { updateUserTransaction } from '../../../Transactions/transactions.slice'
import { DateTime } from 'luxon'

const SampleTransactionCard = ({
  transaction,
  displaySampleTransaction,
  updateSampleTransaction,
}: {
  transaction: Transaction
  displaySampleTransaction: boolean
  updateSampleTransaction: (
    transactionId: number,
    updates: Partial<Transaction>
  ) => void
}) => {
  const dispatch = useAppDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const [transactionType, setTransactionType] = useState<
    TransactionRuleCategoryType | undefined
  >()
  const [transactionCategory, setTransactionCategory] = useState<string>('')
  const [transactionNote, setTransactionNote] = useState<string | undefined>()
  const allTransactionCategories = useReselector(getAllTransactionCategories)
  const expenseCategories = useReselector(selectCategoriesByType, 'Expenses')
  const incomeCategories = useReselector(selectCategoriesByType, 'Income')
  const otherCategories = useReselector(selectNonExpenseAndIncomeCategories)
  const transactionAmount = centsToDollars(transaction.amountInCents)

  const categoriesToDisplay = useMemo(() => {
    if (transactionAmount) {
      if (transactionAmount < 0) {
        return { ...expenseCategories, ...otherCategories }
      }
      if (transactionAmount > 0) {
        return { ...incomeCategories, ...otherCategories }
      }
    }
    return allTransactionCategories
  }, [
    transactionAmount,
    expenseCategories,
    incomeCategories,
    otherCategories,
    allTransactionCategories,
  ])

  const orderedTransactionCategoriesToDisplay = Object.values(
    categoriesToDisplay
  ).sort((a, b) => a.name.localeCompare(b.name))

  const orderedCategoryValuesToDisplay = Object.values(
    orderedTransactionCategoriesToDisplay
  ).map((category) => ({
    text: category.name,
    value: category.name,
  }))

  useEffect(() => {
    if (
      transaction.type === TransactionRuleCategoryType.business ||
      transaction.type === TransactionRuleCategoryType.personal
    ) {
      setTransactionType(transaction.type)
    } else {
      setTransactionType(undefined)
    }

    setTransactionCategory(transaction.transactionCategory?.name || '')
    setTransactionNote(transaction.notes || '')
  }, [transaction])

  const updateTransactionCategory = (value: string) => {
    setTransactionCategory(value)
    const categoryToUpdate = Object.values(allTransactionCategories).find(
      (category) => category.name === value
    )

    if (displaySampleTransaction) {
      updateSampleTransaction(transaction.id, {
        transactionCategory: categoryToUpdate,
        transactionCategoryId: categoryToUpdate?.id,
        type: categoryToUpdate
          ? TransactionRuleCategoryType.business
          : undefined,
      })
    } else {
      updateUserTransaction(transaction.id, {
        transactionCategoryId: categoryToUpdate?.id || null,
        // Auto set the type as business if categorized
        type: categoryToUpdate
          ? TransactionRuleCategoryType.business
          : undefined,
      })(dispatch)
    }
  }

  const updateTransactionType = (value: TransactionRuleCategoryType) => {
    setTransactionType(value)

    if (displaySampleTransaction) {
      updateSampleTransaction(transaction.id, {
        type: value,
        // Clear category if transaction is personal
        transactionCategory:
          value === 'personal' ? null : transaction.transactionCategory,
        transactionCategoryId:
          value === 'personal' ? null : transaction.transactionCategoryId,
      })
    } else {
      updateUserTransaction(transaction.id, {
        type: value,
        // Clear category if transaction is personal
        transactionCategoryId: value === 'personal' ? null : undefined,
      })(dispatch)
    }
  }

  const saveNote = async () => {
    setModalOpen(false)

    if (displaySampleTransaction) {
      updateSampleTransaction(transaction.id, {
        notes: transactionNote,
        notesLastUpdatedAt: new Date().toISOString(),
      })
    } else {
      await updateUserTransaction(transaction.id, {
        notes: transactionNote,
        notesLastUpdatedAt: DateTime.now().toISO(),
      })(dispatch)
    }
  }

  return (
    <Card padding={24} backgroundColor="stone" type="subsection">
      <Grid>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={2}>
            <Text>
              {isoToUTCDateTime(transaction.date).toFormat(
                DATE_FORMATS_LUXON.DISPLAY_SHORT_YEAR
              )}
            </Text>
          </Grid.Column>
          <Grid.Column width={3}>
            <Text>{transaction.description}</Text>
          </Grid.Column>
          <Grid.Column width={2}>
            <Text>
              <CurrencyFormatLabel value={transactionAmount} />
            </Text>
          </Grid.Column>
          <Grid.Column width={3}>
            <Dropdown
              options={[
                {
                  text: 'Business',
                  value: TransactionRuleCategoryType.business,
                },
                {
                  text: 'Personal',
                  value: TransactionRuleCategoryType.personal,
                },
              ]}
              value={transactionType}
              onChange={updateTransactionType}
              variant="text"
              placeholder="Select Type"
            />
          </Grid.Column>
          <Grid.Column width={4}>
            {transaction.type === 'personal' ? (
              <Text>
                <b>N/A</b>
              </Text>
            ) : (
              <Dropdown
                options={orderedCategoryValuesToDisplay}
                value={transactionCategory}
                onChange={updateTransactionCategory}
                placeholder="Select Category"
                scrolling
                search
                icon="search"
                fluid
              />
            )}
          </Grid.Column>
          <Grid.Column width={2} textAlign="right">
            <Button onClick={() => setModalOpen(true)} variant="link">
              {transactionNote ? 'Edit Note' : 'Add Note'}
            </Button>
            <SampleTransactionNotesModal
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
              transaction={transaction}
              transactionNote={transactionNote}
              onNoteChange={setTransactionNote}
              onSave={saveNote}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

export default SampleTransactionCard
