import axios from 'axios'
import { fetchWrapper } from '../../reducers/fetch'

interface WaitlistUser {
  id: number
  email: string
  numberOfEmployees: number
  createdAt: string
  updatedAt: string
}

export const postWaitlistUser = (payload: {
  email: string
  numberOfEmployees: number
}) =>
  fetchWrapper({
    fetchFunction: async () => {
      const res = await axios.post<WaitlistUser>(
        '/finances/api/v1/waitlist_users',
        payload
      )
      return res.data
    },
  })
