import { useFormik, FormikProvider } from 'formik'
import { useParams } from 'react-router-dom'
import { Container, Divider, Grid } from 'semantic-ui-react'
import { Form1040DetailsProps } from '.'
import {
  GridRowColumn,
  FormikInput,
  getFieldName,
  makeNumberSchema,
  Text,
  FormikRadioToggleButton,
  FormikLabelError,
  makeReqBoolSchema,
} from '../../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { TaxListQuestionId } from '../../service'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../../taxChecklist.selectors'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import {
  DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  deleteTaxQuestionnaireResponses,
} from '../../taxChecklistQuestion.actions'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { selectPayerHasAboundTaxDocsForYear } from '../../../aboundAnnualTaxFilings.selector'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'

export const businessInfoQuestionIds = [
  TaxListQuestionId.paid_contractors,
  TaxListQuestionId.sprop_owned_other_businesses,
  TaxListQuestionId.sprop_number_other_businesses,
]

const BusinessInfoPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: Form1040DetailsProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()
  const dispatch = useAppDispatch()
  const didPayContractors = useReselector(
    selectPayerHasAboundTaxDocsForYear,
    taxYear
  )
  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
  ])

  const questionPaidContractors = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.paid_contractors,
    taxYear
  )
  const responsePaidContractors = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.paid_contractors,
    Number(formId)
  )
  const questionOwnedOtherBiz = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.sprop_owned_other_businesses,
    taxYear
  )
  const responseOwnedOtherBiz = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.sprop_owned_other_businesses,
    Number(formId)
  )
  const questionNumberOtherBiz = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.sprop_number_other_businesses,
    taxYear
  )
  const responseNumberOtherBiz = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.sprop_number_other_businesses,
    Number(formId)
  )

  const formik = useFormik({
    initialValues: {
      paidContractors:
        responsePaidContractors?.[0]?.value ??
        (didPayContractors ? true : undefined),
      otherBiz: responseOwnedOtherBiz?.[0]?.value,
      numberOtherBiz: responseNumberOtherBiz?.[0]?.value ?? '',
    },
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      if (values.paidContractors !== responsePaidContractors?.[0]?.value) {
        responseData.push({
          id: responsePaidContractors?.[0]?.id,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.paid_contractors,
          value: values.paidContractors,
        })
      }
      if (values.otherBiz !== responseOwnedOtherBiz?.[0]?.value) {
        responseData.push({
          id: responseOwnedOtherBiz?.[0]?.id,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.sprop_owned_other_businesses,
          value: values.otherBiz,
        })
      }
      if (
        Number(values.numberOtherBiz ?? 0) !==
        responseNumberOtherBiz?.[0]?.value
      ) {
        responseData.push({
          id: responseNumberOtherBiz?.[0]?.id,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.sprop_number_other_businesses,
          value: Number(values.numberOtherBiz ?? 0),
        })
      }
      let deleteSuccess = true
      if (!values.otherBiz && responseNumberOtherBiz?.[0]?.id) {
        deleteSuccess = Boolean(
          await dispatch(
            deleteTaxQuestionnaireResponses([responseNumberOtherBiz[0].id])
          )
        )
      }
      if (deleteSuccess) {
        await goToNextStep(responseData, nextScreen ?? null)
      }
    },
  })

  const { values, validateForm, isSubmitting, isValid, submitForm } = formik

  return (
    <FormikProvider value={formik}>
      <Container style={{ marginTop: 56 }} text textAlign="left">
        <Grid>
          <GridRowColumn>
            <Text as="display2" textAlign="center">
              Update Business Information
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="bodyLg">
              Please update the following information for {taxYear}. These
              answers will appear on your tax return.
            </Text>
          </GridRowColumn>
          <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
            <FormikLabelError
              name={getFieldName<typeof values>('paidContractors')}
              label={questionPaidContractors.question?.text}
              schema={makeReqBoolSchema()}
            />
          </GridRowColumn>
          <Grid.Row className="short" style={{ justifyContent: 'center' }}>
            <Grid.Column width={6} style={{ paddingRight: 0 }}>
              <FormikRadioToggleButton
                fullWidth
                name={getFieldName<typeof values>('paidContractors')}
                value
              >
                Yes
              </FormikRadioToggleButton>
            </Grid.Column>
            <Grid.Column width={6}>
              <FormikRadioToggleButton
                fullWidth
                name={getFieldName<typeof values>('paidContractors')}
                value={false}
              >
                No
              </FormikRadioToggleButton>
            </Grid.Column>
          </Grid.Row>
          <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
            <Divider />
          </GridRowColumn>
          <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
            <FormikLabelError
              name={getFieldName<typeof values>('otherBiz')}
              label={questionOwnedOtherBiz.question?.text}
              schema={makeReqBoolSchema()}
            />
          </GridRowColumn>
          <Grid.Row className="short" style={{ justifyContent: 'center' }}>
            <Grid.Column width={6} style={{ paddingRight: 0 }}>
              <FormikRadioToggleButton
                fullWidth
                name={getFieldName<typeof values>('otherBiz')}
                value
              >
                Yes
              </FormikRadioToggleButton>
            </Grid.Column>
            <Grid.Column width={6}>
              <FormikRadioToggleButton
                fullWidth
                name={getFieldName<typeof values>('otherBiz')}
                value={false}
                onClick={validateForm}
              >
                No
              </FormikRadioToggleButton>
            </Grid.Column>
          </Grid.Row>
          <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
            <Divider />
          </GridRowColumn>
          {values.otherBiz && (
            <>
              <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
                <FormikInput
                  name={getFieldName<typeof values>('numberOtherBiz')}
                  label={questionNumberOtherBiz.question?.text}
                  schema={makeNumberSchema({}).when('otherBiz', {
                    is: true,
                    then: (schema) => schema.required(),
                    otherwise: (schema) => schema.nullable().notRequired(),
                  })}
                  type="number"
                  fullWidth
                />
              </GridRowColumn>
              <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
                <Divider />
              </GridRowColumn>
            </>
          )}
        </Grid>
      </Container>
      <Grid>
        <FormFlowFooter
          loading={isUpdating || isSubmitting}
          continueDisabled={!isValid || isUpdating || isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
        />
      </Grid>
    </FormikProvider>
  )
}

export default BusinessInfoPanel
