import { useEffect, useState } from 'react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FinancialAccount } from '../../../../reducers/finances/financialAccountsReducer'
import Card from '../../../../components/BaseComponents/Card'
import Icon from '../../../../components/BaseComponents/Icon'
import Text from '../../../../components/BaseComponents/Text'
import { StepHeaders } from '../../../../components/StepHeader'
import { FinancialAccountCard } from '../../../../components/Finances/Accounts/FinancialAccountCard'
import './styles.scss'

interface IDisconnectedAccountsProps {
  financialAccounts?: FinancialAccount[]
  setIsStepComplete: (value: boolean) => void
}

export const DisconnectedAccounts = ({
  financialAccounts = [],
  setIsStepComplete,
}: IDisconnectedAccountsProps) => {
  const [skipAccountReconnects, setSkipAccountReconnects] = useState(0)

  useEffect(() => {
    const count = financialAccounts.filter((acct) => {
      return !acct.accounts?.needsReconnection
    }).length

    setSkipAccountReconnects(count)
  }, [financialAccounts])

  useEffect(() => {
    if (setIsStepComplete) {
      setIsStepComplete(false)
    }
  }, [setIsStepComplete])

  const handleSkipAccountReconnect = (isSkipped: boolean) => {
    const nextCount = isSkipped ? 1 : -1
    setSkipAccountReconnects((prev) => prev + nextCount)
  }

  useEffect(() => {
    if (setIsStepComplete === undefined) return

    if (skipAccountReconnects - financialAccounts.length === 0) {
      setIsStepComplete(true)
      return
    }

    setIsStepComplete(false)
  }, [skipAccountReconnects, financialAccounts, setIsStepComplete])

  return (
    <StepHeaders
      imageSrc="https://heard-images.s3.amazonaws.com/assets/bank-yellow.svg"
      imageAlt="bank"
      imageWidth={180}
      imageHeight={180}
      title="Reconnect your accounts"
      description="Don’t worry, disconnections can happen on occasion. Please reconnect any
      accounts you plan on using."
    >
      <Text style={{ marginTop: '16px' }}>
        If you’re having trouble connecting an account, check the box.
      </Text>

      {skipAccountReconnects - financialAccounts.length === 0 ? (
        <Card backgroundColor="lightGreen" fullWidth>
          <div className="all-reconnected">
            <Icon icon={solid('check')} color="accentGreen" size="2x" />

            <div className="reconnected-text">
              <Text as="h3">All accounts have been reconnected</Text>
              <Text>You may skip to the next step</Text>
            </div>
          </div>
        </Card>
      ) : (
        financialAccounts.map((account) => (
          <FinancialAccountCard
            key={account.id.toString()}
            account={account}
            handleSkipAccountReconnect={handleSkipAccountReconnect}
          />
        ))
      )}
    </StepHeaders>
  )
}
