import { createRef, useCallback, useEffect, useMemo, useState } from 'react'
import { Container, Loader, Ref } from 'semantic-ui-react'

import { getAllUserTaxCalculations } from '../../../selectors/userTaxCalculation.selectors'
import {
  adminFetchAllUserTaxCalculationsIfNeeded,
  FETCH_ALL_USER_TAX_CALCULATIONS_KEY,
  UserTaxCalculationTabNames,
} from './userTaxCalculation.slice'
import { createViewModel } from './service'
import CalculationDateToolbar from './components/CalculationDateToolbar'
import CalculationTabMenu from './components/CalculationTabMenu'
import {
  getFetchError,
  getIsFetchingOrNotStarted,
} from '../../../reducers/fetch'
import QTEDateDetails from './components/QTEDateDetails'
import CalculationTable from './components/CalculationTable'
import { fetchAllQuarterlyTaxEstimateDetailsIfNeeded } from '../QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import { selectAllQuarterlyTaxEstimateDetailsSorted } from '../QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { useReselector } from '../../../utils/sharedHooks'
import { Text } from '../../../components/BaseComponents'
import {
  FETCH_ALL_USERS_KEY,
  fetchAllUsersIfNeeded,
} from '../../../actions/admin/adminAllUsersActions'
import { useAppDispatch } from '../../../utils/typeHelpers'

const QuarterlyTaxCalculationsPanel = () => {
  const dispatch = useAppDispatch()
  const [calendarYear, setCalendarYear] = useState('')
  const [calendarQuarter, setCalendarQuarter] = useState('')
  const [activeTab, setActiveTab] = useState(UserTaxCalculationTabNames.all)

  const contextRef = createRef<HTMLElement>()

  useEffect(() => {
    dispatch(adminFetchAllUserTaxCalculationsIfNeeded())
    dispatch(fetchAllQuarterlyTaxEstimateDetailsIfNeeded())
    dispatch(fetchAllUsersIfNeeded())
  }, [dispatch])

  const allUserTaxCalculations = useReselector(getAllUserTaxCalculations)

  const allQuarterlyTaxEstimateDetails = useReselector(
    selectAllQuarterlyTaxEstimateDetailsSorted
  )

  const isFetching = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ALL_USER_TAX_CALCULATIONS_KEY
  )
  const fetchError = useReselector(
    getFetchError,
    FETCH_ALL_USER_TAX_CALCULATIONS_KEY
  )

  const fetchingUsers = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ALL_USERS_KEY
  )

  /*
    This represents all userTaxCalculations from the database. Calculations are accessed via
      obj[calendarYear][calendarQuarter][userTaxCalculationTabName] = UserTaxCalculation[]
  */
  const viewModel = useMemo(
    () =>
      createViewModel(
        Object.values(allUserTaxCalculations),
        allQuarterlyTaxEstimateDetails,
        []
      ),
    [allUserTaxCalculations, allQuarterlyTaxEstimateDetails]
  )

  const onDateChange = useCallback(
    ({
      selectedQuarter,
      selectedYear,
    }: {
      selectedQuarter: string
      selectedYear: string
    }) => {
      setCalendarYear(selectedYear)
      setCalendarQuarter(selectedQuarter)

      // Select "All" when year or quarter changes
      setActiveTab(UserTaxCalculationTabNames.all)
    },
    []
  )

  const onTabChange = useCallback(
    (tabName: UserTaxCalculationTabNames) => {
      setActiveTab(tabName)
    },
    [setActiveTab]
  )

  return (
    <Ref innerRef={contextRef}>
      <Container style={{ width: '100%' }} id="userTaxCalculations">
        <Container style={{ width: '100%' }}>
          {fetchError && (
            <p>
              An error occurred fetching tax calculations{' '}
              <i>{fetchError.message}</i>
            </p>
          )}
          {isFetching && <p>Loading tax calculations</p>}
          {!isFetching && !fetchError && (
            <>
              <CalculationDateToolbar
                onDateChange={onDateChange}
                viewModel={viewModel}
              />
              {calendarQuarter && calendarYear && activeTab && (
                <>
                  <QTEDateDetails
                    calendarQuarter={calendarQuarter}
                    calendarYear={calendarYear}
                    style={{ padding: '1em', maxWidth: '550px' }}
                  />

                  <CalculationTabMenu
                    activeTab={activeTab}
                    contextRef={contextRef}
                    calculations={
                      viewModel[calendarYear][calendarQuarter][
                        UserTaxCalculationTabNames.all
                      ]
                    }
                    onTabChange={onTabChange}
                    financialProfileHistoriesThisYear={[]}
                  />
                  {fetchingUsers ? (
                    <Text
                      as="h1"
                      textAlign="center"
                      style={{ verticalAlign: 'middle', padding: '1em' }}
                    >
                      Loading user data <Loader active inline />
                    </Text>
                  ) : (
                    <CalculationTable
                      calculations={
                        viewModel[calendarYear][calendarQuarter][activeTab]
                      }
                      calendarQuarter={calendarQuarter}
                      calendarYear={calendarYear}
                      activeTab={activeTab}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Container>
      </Container>
    </Ref>
  )
}

export default QuarterlyTaxCalculationsPanel
