import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button, Comment, Card, Header, Tab, Icon } from 'semantic-ui-react'
import { convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import moment from 'moment'
import parse from 'html-react-parser'

import {
  createUserRecordNotes,
  deleteUserRecordNote,
  fetchUserRecordNotes,
  sendSlackNotification,
  UserRecordNote,
} from '../../../actions/userRecordNotesActions'
import StyledDraftEditor from '../../../features/Conversations/ConversationThread/StyledDraftEditor'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { getResetEditorState } from '../../../features/Conversations/helpers'

const UserRecordNotes = ({ userId }: { userId: number }) => {
  const [notes, setNotes] = useState<Map<number, UserRecordNote>>(new Map())

  useEffect(() => {
    const fetchNotes = async () => {
      const response = await fetchUserRecordNotes(userId)

      if (response) {
        const newNotes = new Map(response.data.map((obj) => [obj.id, obj]))

        setNotes(newNotes)
      }
    }

    fetchNotes()
  }, [userId])

  return (
    <Card fluid className="documentsCard">
      <Card.Content>
        <Header as="h4">User Record Notes</Header>
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={[
            {
              menuItem: 'Onboarding',
              render: () => (
                <NotesSection
                  userId={userId}
                  view="Onboarding"
                  notes={notes}
                  setNotes={setNotes}
                />
              ),
            },
            {
              menuItem: 'Bookkeeping',
              render: () => (
                <NotesSection
                  userId={userId}
                  view="Bookkeeping"
                  notes={notes}
                  setNotes={setNotes}
                />
              ),
            },
            {
              menuItem: 'Check-in',
              render: () => (
                <NotesSection
                  userId={userId}
                  view="Check-in"
                  notes={notes}
                  setNotes={setNotes}
                />
              ),
            },
            {
              menuItem: 'Taxes',
              render: () => (
                <NotesSection
                  userId={userId}
                  view="Taxes"
                  notes={notes}
                  setNotes={setNotes}
                />
              ),
            },
          ]}
        />
      </Card.Content>
    </Card>
  )
}

export default UserRecordNotes

interface NotesContainerProps {
  userId: number
  view: string
  notes: Map<number, UserRecordNote>
  setNotes: Dispatch<SetStateAction<Map<number, UserRecordNote>>>
}
const NotesSection = ({
  userId,
  view,
  notes,
  setNotes,
}: NotesContainerProps) => {
  const deleteNote = async (noteId: number) => {
    await deleteUserRecordNote(noteId)
    setNotes((prev) => {
      const newState = new Map(prev)
      newState.delete(noteId)
      return newState
    })
  }
  return (
    <div style={{ marginTop: '20px' }}>
      <Comment.Group>
        {notes &&
          Array.from(notes.values())
            .filter((note: UserRecordNote) => note.category === view)
            .map((note: UserRecordNote) => (
              <Comment
                key={note.id}
                style={{ padding: '1em', border: '1px solid #E0E0E0' }}
              >
                <Comment.Content>
                  <Comment.Author as="a">
                    {note.notesAuthor
                      ? `${note.notesAuthor.firstName} ${note.notesAuthor.lastName}`
                      : 'deleted user'}
                  </Comment.Author>
                  <Comment.Metadata>
                    <div>
                      {moment(note.createdAt).format(
                        DATE_FORMATS.DISPLAY_SHORT
                      )}
                    </div>
                  </Comment.Metadata>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Comment.Text>{parse(note.text)}</Comment.Text>
                    <Comment.Action>
                      <Icon name="trash" onClick={() => deleteNote(note.id)} />
                    </Comment.Action>
                  </div>
                </Comment.Content>
              </Comment>
            ))}
        <div style={{ marginTop: '16px' }}>
          <DraftEditor
            userId={userId}
            view={view}
            notes={notes}
            setNotes={setNotes}
          />
        </div>
      </Comment.Group>
    </div>
  )
}

interface DraftEditorProps {
  userId: number
  view: string
  notes: Map<number, UserRecordNote>
  setNotes: Dispatch<SetStateAction<Map<number, UserRecordNote>>>
}
const DraftEditor = ({ userId, view, notes, setNotes }: DraftEditorProps) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )

  useEffect(() => {
    setEditorState((prevState) => getResetEditorState(prevState))
  }, [view, notes])

  const submitNotes = async (
    userId: number,
    text: string,
    category: string
  ) => {
    const createdNotes = await createUserRecordNotes(userId, text, category)

    if (!createdNotes) {
      return
    }

    setNotes((prev) => new Map([...prev, [createdNotes.id, createdNotes]]))

    await sendSlackNotification(userId, text, category)
  }

  return (
    <div>
      <StyledDraftEditor
        editorState={editorState}
        onEditorStateChange={setEditorState}
      />
      <Button
        primary
        floated="right"
        onClick={() =>
          submitNotes(
            userId,
            draftToHtml(convertToRaw(editorState.getCurrentContent())),
            view
          )
        }
      >
        Create Note
      </Button>
    </div>
  )
}
