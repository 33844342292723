import { RefObject, useCallback, createRef, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Grid } from 'semantic-ui-react'
import { times } from 'lodash'
import moment from 'moment'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import { selectPayrollByUuid, selectPaySchedule } from '../../payroll.selectors'
import { filterNulls, useAppDispatch } from '../../../../utils/typeHelpers'
import {
  fetchAllPaySchedules,
  PUT_PAYROLL_CALCULATE_KEY,
  PUT_PAYROLL_KEY,
  putPayrollByUuid,
} from '../../payrollActions'
import PayrollInputRow, {
  ConnectedPayrollInputRow,
} from '../PayrollInput/PayrollInputRow'
import PayrollHeader from './PayrollHeader'
import PayrollError from '../../PayrollError'
import {
  Alert,
  Button,
  GridRowColumn,
  Icon,
  Link,
  Table,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'

// Setup of this file is a bit confusing, but it's split so that each fixed/hourly cell contains its own state rather
// than the parent owning EVERYTHING
// Class components are used here so refs can be created
// If a cell changes it triggers an event that is picked up by the OverviewRow to recalculate gross pay
// Once the user submits payroll each row is iterated over to create the expected payload

const AutoSetupNotice = ({ deadlineDate }: { deadlineDate: string }) => {
  const dispatch = useAppDispatch()
  const paySchedule = useReselector(selectPaySchedule)

  useEffect(() => {
    dispatch(fetchAllPaySchedules())
  }, [dispatch])

  if (!paySchedule?.auto_pilot) {
    return null
  }

  return (
    <Container className="auto-setup">
      <Icon icon={light('info-circle')} />
      <Text>
        <b>Note:</b> You are enrolled in Automatic Payroll! No need to go
        through this flow, as payroll will automatically run for you on{' '}
        {deadlineDate}
      </Text>
    </Container>
  )
}

const PayrollOverview = ({
  goForward,
  uuid,
}: {
  goForward: () => void
  uuid: string
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const upcomingPayroll = useReselector(selectPayrollByUuid, uuid)
  const [rowRefs, setRowRefs] = useState<RefObject<PayrollInputRow>[]>([])
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (!upcomingPayroll?.employee_compensations?.length) {
      return
    }

    setRowRefs(() =>
      times(upcomingPayroll?.employee_compensations?.length || 0).map(() =>
        createRef<PayrollInputRow>()
      )
    )
  }, [upcomingPayroll?.employee_compensations?.length])

  const onSave = useCallback(async () => {
    if (!upcomingPayroll?.payroll_uuid) {
      return
    }

    setSubmitting(true)

    // Gather all the employee data via refs
    const employee_compensations = filterNulls(
      rowRefs.map((ref) => ref?.current?.calculate())
    )

    const res = await putPayrollByUuid(upcomingPayroll.payroll_uuid, {
      employee_compensations,
    })(dispatch)

    setSubmitting(false)
    if (res) {
      goForward()
    }
  }, [dispatch, goForward, rowRefs, upcomingPayroll?.payroll_uuid])

  if (!upcomingPayroll) {
    return null
  }

  const deadlineDate = moment(upcomingPayroll.payroll_deadline).format(
    DATE_FORMATS.DISPLAY_SHORT
  )

  const missingEmployees =
    !upcomingPayroll.employee_compensations ||
    upcomingPayroll.employee_compensations.length === 0

  return (
    <Grid>
      <GridRowColumn>
        <PayrollHeader upcomingPayroll={upcomingPayroll} currentTab={0} />
      </GridRowColumn>
      <GridRowColumn>
        <AutoSetupNotice deadlineDate={deadlineDate} />
      </GridRowColumn>
      <GridRowColumn>
        <Text>
          Update your employee’s hours, reimbursements, and additional earnings
          below.
        </Text>
      </GridRowColumn>
      <GridRowColumn>
        <Text>
          As a reminder, you will need to run payroll by{' '}
          <b>3:30PM PT {deadlineDate}</b> to ensure your employees get paid on
          time.{' '}
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/7257143896727"
            newPage
          >
            Learn why
          </Link>
        </Text>
      </GridRowColumn>
      <PayrollError fetchKey={PUT_PAYROLL_KEY} />
      <PayrollError fetchKey={PUT_PAYROLL_CALCULATE_KEY} />
      {missingEmployees && (
        <GridRowColumn>
          <Alert type="error">No eligible employees for this pay period.</Alert>
        </GridRowColumn>
      )}
      <GridRowColumn>
        <Table basic="very" style={{ marginTop: 40 }}>
          <Table.Header>
            <Table.Row style={{ height: 80 }}>
              <Table.HeaderCell width={3}>Employees</Table.HeaderCell>
              <Table.HeaderCell width={3}>Hours Worked</Table.HeaderCell>
              <Table.HeaderCell width={4}>Additional Earnings</Table.HeaderCell>
              <Table.HeaderCell width={4}>
                Gross Pay
                <br />
                <br />
                Reimbursement
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {upcomingPayroll.employee_compensations?.map((comp, index) => {
              if (!rowRefs[index]) {
                return null
              }
              return (
                <ConnectedPayrollInputRow
                  key={comp.employee_uuid}
                  comp={comp}
                  ref={rowRefs[index]}
                  payrollUuid={upcomingPayroll.payroll_uuid}
                />
              )
            })}
          </Table.Body>
        </Table>
      </GridRowColumn>
      <Grid.Row>
        <Grid.Column width={4}>
          <Button
            onClick={() => navigate('/payroll/run_payroll')}
            variant="secondary"
            fullWidth
          >
            Cancel
          </Button>
        </Grid.Column>
        <Grid.Column width={8} />
        <Grid.Column width={4}>
          <Button
            onClick={onSave}
            fullWidth
            loading={submitting}
            disabled={missingEmployees}
          >
            Save & Continue
            <Icon icon={light('arrow-right')} style={{ marginLeft: 8 }} />
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default PayrollOverview
