import { useContext, useMemo } from 'react'
import { Grid } from 'semantic-ui-react'
import {
  Alert,
  Button,
  Card,
  GridRowColumn,
  Modal,
  Text,
} from '../../components/BaseComponents'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'
import { useAnalyticsTrack } from '../Amplitude'
import { Colors } from '../../styles/theme'
import {
  DELETE_MANUAL_TRANSACTION_KEY,
  UPDATE_USER_TRANSACTION_KEY,
  deleteManualTransaction,
  updateUserTransaction,
} from './transactions.slice'
import { useAppDispatch } from '../../utils/typeHelpers'
import { useReselector } from '../../utils/sharedHooks'
import { getFetchError, getIsFetching } from '../../reducers/fetch'

import {
  UndoTransactionContext,
  UNDO_TRANSACTION_TYPES,
} from './UndoTransactionContext'

interface TransactionDeleteModalProps {
  open: boolean
  close: () => void
  transactionId: number
  isManualTransaction: boolean
}
export const TransactionDeleteModal = ({
  open,
  close,
  transactionId,
  isManualTransaction,
}: TransactionDeleteModalProps) => {
  const dispatch = useAppDispatch()
  const deleteIsFetching = useReselector(
    getIsFetching,
    DELETE_MANUAL_TRANSACTION_KEY
  )
  const deleteError = useReselector(
    getFetchError,
    DELETE_MANUAL_TRANSACTION_KEY
  )

  const dupeIsFetching = useReselector(
    getIsFetching,
    UPDATE_USER_TRANSACTION_KEY
  )
  const dupeError = useReselector(getFetchError, UPDATE_USER_TRANSACTION_KEY)

  const { setUndoTransactionId, setUndoTransactionType } = useContext(
    UndoTransactionContext
  )

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const buttonOrder = useMemo(() => {
    return isMobile ? 1 : 0
  }, [isMobile])

  const content = useMemo(() => {
    return isManualTransaction
      ? {
          header: 'Are you sure you want to delete?',
          body: 'Deleting this transaction will:',
          button: 'Delete Transaction',
        }
      : {
          header: 'Are you sure you want to mark as duplicate?',
          body: 'Marking this transaction as a duplicate will:',
          button: 'Mark as Duplicate',
        }
  }, [isManualTransaction])

  const track = useAnalyticsTrack()

  const deleteTransaction = async () => {
    if (isManualTransaction) {
      track('deleted manual transaction')
      const deleted = await deleteManualTransaction(transactionId)(dispatch)
      if (deleted) {
        setUndoTransactionId(transactionId)
        setUndoTransactionType(UNDO_TRANSACTION_TYPES.DELETE)
        close()
      }
    } else {
      track('marked transaction as duplicate')
      const updated = await updateUserTransaction(transactionId, {
        manuallyDedupedAt: new Date().toISOString(),
      })(dispatch)
      if (updated) {
        setUndoTransactionId(transactionId)
        setUndoTransactionType(UNDO_TRANSACTION_TYPES.DUPLICATE)
        close()
      }
    }
  }

  return (
    <Modal
      open={open}
      onClose={close}
      onOpen={() => track('viewed delete user manual transaction modal')}
      closeIcon
      size="tiny"
    >
      <Modal.Header>{content.header}</Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text>{content.body}</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Card
              type="subsection"
              style={{ backgroundColor: Colors.lightRed }}
            >
              <Text>
                <b>Permanently remove</b> it from your Transactions, Profit &
                Loss, and reports.
              </Text>
            </Card>
          </GridRowColumn>
          <GridRowColumn>
            <Text>This cannot be undone.</Text>
          </GridRowColumn>
          {dupeError && (
            <GridRowColumn>
              <Alert type="error">
                <Text>
                  Error marking transaction as duplicate. Please try again.
                </Text>
              </Alert>
            </GridRowColumn>
          )}
          {deleteError && (
            <GridRowColumn>
              <Alert type="error">
                <Text>Error deleting transaction. Please try again.</Text>
              </Alert>
            </GridRowColumn>
          )}
        </Grid>
      </Modal.Content>
      <Modal.Actions style={isMobile ? { flexDirection: 'column' } : {}}>
        <Button
          variant="secondaryLink"
          style={{ textDecorationLine: 'none', order: buttonOrder }}
          onClick={close}
          fullWidth={isMobile}
        >
          Cancel
        </Button>
        <Button
          variant="warning"
          onClick={deleteTransaction}
          fullWidth={isMobile}
          loading={dupeIsFetching || deleteIsFetching}
        >
          {content.button}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default TransactionDeleteModal
