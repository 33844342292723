import axios from 'axios'
import { normalize } from 'normalizr'
import { fetchWrapper } from '../../reducers/fetch'
import {
  JournalEntry,
  SubmitJournalEntryBody,
  JournalEntryTransaction,
  LedgerAccount,
} from '../../features/JournalEntries/types'
import {
  Transaction,
  TransactionResponseEntities,
  receiveAdminAllTransactions,
  addJournalEntryToTransaction,
  addJournalEntryToSplitTransaction,
  removeJournalEntryFromTransaction,
  removeJournalEntryFromSplitTransaction,
} from '../../reducers/admin/allTransactions.slice'
import { transactionListSchema } from '../../schema'
import {
  receiveJournalEntries,
  receiveJournalEntry,
} from '../../reducers/admin/journalEntryReducer'
import { keyBy } from 'lodash'

export type FetchParams = {
  startDate?: string
  endDate?: string
  limit?: number
  page?: number
  journalEntryType?: string
} & (
  | {
      transactionCategoryId?: number | string
      financialAccountId?: never
    }
  | {
      transactionCategoryId?: never
      financialAccountId?: number | string
    }
) &
  (
    | {
        transactionId: number | string
        userId?: never
      }
    | {
        transactionId?: never
        userId: number | string
      }
    | {
        transactionId?: number
        userId?: number | string
      }
  )

export const FETCH_JOURNAL_ENTRY_TRANSACTIONS_KEY =
  'FETCH_JOURNAL_ENTRY_TRANSACTIONS_KEY'
export const fetchJournalEntries = (params: FetchParams) =>
  fetchWrapper({
    fetchKey: FETCH_JOURNAL_ENTRY_TRANSACTIONS_KEY,
    defaultErrorMessage: 'Error in retrieving journal entry transactions',
    defaultValue: {
      count: 0,
      transactions: [],
    },
    fetchFunction: async (dispatch) => {
      const json = await axios.get<{
        count: number
        transactions: JournalEntryTransaction[]
      }>('/finances/api/v1/admin/transactions/journal_entries', { params })
      const normalizedData = normalize<
        Transaction,
        TransactionResponseEntities,
        string[]
      >(json.data.transactions, transactionListSchema)
      dispatch(receiveAdminAllTransactions(normalizedData))
      if (normalizedData.entities.journalEntries) {
        dispatch(receiveJournalEntries(normalizedData.entities.journalEntries))
      }
      return json.data
    },
  })

// todo: change this to actual route once that is complete and add redux dispatches
export const FETCH_ENTRIES_WITHOUT_TRANSACTIONS_KEY =
  'FETCH_ENTRIES_WITHOUT_TRANSACTIONS_KEY'
export const fetchEntriesWithoutTransactions = (params: FetchParams) =>
  fetchWrapper({
    fetchKey: FETCH_ENTRIES_WITHOUT_TRANSACTIONS_KEY,
    defaultErrorMessage: 'Error in retrieving manual journal entries',
    defaultValue: [],
    fetchFunction: async (dispatch) => {
      const json = await axios.get<JournalEntry[]>(
        '/finances/api/v1/admin/transactions/journal_entries/without_transactions',
        { params }
      )
      dispatch(receiveJournalEntries(keyBy(json.data, 'id')))
      return json.data
    },
  })

export const UPDATE_JOURNAL_ENTRY_KEY = 'UPDATE_JOURNAL_ENTRY_KEY'
export const updateJournalEntry = (id: number, data: SubmitJournalEntryBody) =>
  fetchWrapper({
    fetchKey: UPDATE_JOURNAL_ENTRY_KEY,
    defaultErrorMessage: 'Error in updating journal entry',
    defaultValue: false,
    fetchFunction: async (dispatch) => {
      const json = await axios.put<JournalEntry>(
        `/finances/api/v1/admin/transactions/journal_entries/${id}`,
        data
      )
      dispatch(receiveJournalEntry(json.data))
      return json.data
    },
  })

export const DELETE_JOURNAL_ENTRY_KEY = 'DELETE_JOURNAL_ENTRY_KEY'
export const deleteJournalEntry = (id: number) =>
  fetchWrapper({
    fetchKey: DELETE_JOURNAL_ENTRY_KEY,
    defaultErrorMessage: 'Error in deleting journal entry',
    defaultValue: false,
    fetchFunction: async (dispatch) => {
      const json = await axios.delete<JournalEntry>(
        `/finances/api/v1/admin/transactions/journal_entries/${id}`
      )
      dispatch(deleteJournalEntry(json.data.id))
      if (json.data.transaction) {
        if (json.data.transaction?.splitFrom) {
          dispatch(
            removeJournalEntryFromSplitTransaction({
              transactionId: json.data.transaction.id,
              journalEntry: json.data,
              parentId: json.data.transaction.splitFrom,
            })
          )
        } else {
          dispatch(
            removeJournalEntryFromTransaction({
              transactionId: json.data.transaction.id,
              journalEntry: json.data,
            })
          )
        }
      }
      return json.data
    },
  })

export const CREATE_JOURNAL_ENTRY_KEY = 'CREATE_JOURNAL_ENTRY_KEY'
export const createJournalEntry = (data: SubmitJournalEntryBody) =>
  fetchWrapper({
    fetchKey: CREATE_JOURNAL_ENTRY_KEY,
    defaultErrorMessage: 'Error in creating journal entry',
    fetchFunction: async (dispatch) => {
      const json = await axios.post<JournalEntry>(
        '/finances/api/v1/admin/transactions/journal_entries/',
        data
      )
      dispatch(receiveJournalEntry(json.data))
      if (json.data.transaction) {
        if (json.data.transaction?.splitFrom) {
          dispatch(
            addJournalEntryToSplitTransaction({
              transactionId: json.data.transaction.id,
              journalEntry: json.data,
              parentId: json.data.transaction.splitFrom,
            })
          )
        } else {
          dispatch(
            addJournalEntryToTransaction({
              transactionId: json.data.transaction.id,
              journalEntry: json.data,
            })
          )
        }
      }
      return json.data
    },
  })

export const FETCH_GENERAL_LEDGER_KEY = 'FETCH_GENERAL_LEDGER_KEY'
export const fetchGeneralLedger = (params: FetchParams) =>
  fetchWrapper({
    fetchKey: FETCH_GENERAL_LEDGER_KEY,
    defaultErrorMessage: 'Error in retrieving general ledger',
    defaultValue: [],
    fetchFunction: async () => {
      const json = await axios.get<LedgerAccount[]>(
        '/finances/api/v1/admin/transactions/general_ledger',
        { params }
      )
      return json.data
    },
  })
