import { sumBy } from 'lodash'

import { Table, Text } from '../BaseComponents'
import CurrencyFormatLabel from '../shared/CurrencyFormatLabel'
import { formatCurrency, centsToDollars } from '../../utils/currencyHelpers'
import { AllocationGuide } from '../../reducers/finances/allocationGuidesReducer'

interface Props {
  allocationGuide: AllocationGuide | undefined
  profits: number
}

const AllocationGuideAmountsTable = ({ allocationGuide, profits }: Props) => {
  const totalAmount = () => {
    if (allocationGuide) {
      return sumBy(Object.values(allocationGuide.amounts), (amt) =>
        centsToDollars(amt.amountInCents)
      )
    } else {
      return 0
    }
  }

  const calculatedTotal = totalAmount()
  return (
    <>
      <Table basic>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={8}>Allocation Name</Table.HeaderCell>
            <Table.HeaderCell width={8}>Amount Allocated</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {allocationGuide?.amounts &&
            Object.values(allocationGuide.amounts).map((amount) => (
              <Table.Row key={amount.id}>
                <Table.Cell width={8}>{amount.allocationRuleName}</Table.Cell>
                <Table.Cell width={8}>
                  <CurrencyFormatLabel
                    value={formatCurrency(centsToDollars(amount.amountInCents))}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>

      <br />
      <Table basic>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={8}>
              <Text as="h3">Total</Text>
            </Table.Cell>
            <Table.Cell width={8}>
              <Text as="h3">
                <CurrencyFormatLabel value={formatCurrency(calculatedTotal)} />
              </Text>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={8}>
              <i>Unallocated Profits</i>
            </Table.Cell>
            <Table.Cell width={8}>
              <i>
                <CurrencyFormatLabel
                  value={formatCurrency(profits - calculatedTotal)}
                />
              </i>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  )
}

export default AllocationGuideAmountsTable
