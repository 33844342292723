import { useWindowSize } from './sharedHooks'

export enum DeviceWidth {
  wideDesktop = 'wideDesktop',
  smallDesktop = 'smallDesktop',
  tablet = 'tablet',
  mobile = 'mobile',
}

export enum DeviceWidthCombo {
  desktop = 'desktop',
  mobileOrTablet = 'mobileOrTablet',
}

const DESKTOP_WIDTH = 1280
// These match semantic UI
const TABLET_WIDTH = 991
const MOBILE_WIDTH = 767

// Only returns real device widths
export const useDeviceWidth = () => {
  const windowSize = useWindowSize()

  if (!windowSize.width || windowSize.width > DESKTOP_WIDTH) {
    return DeviceWidth.wideDesktop
  } else if (windowSize.width > TABLET_WIDTH) {
    return DeviceWidth.smallDesktop
  } else if (windowSize.width > MOBILE_WIDTH) {
    return DeviceWidth.tablet
  }

  return DeviceWidth.mobile
}

export const useIsDeviceWidth = (width: DeviceWidth | DeviceWidthCombo) => {
  const deviceWidth = useDeviceWidth()

  if (width === DeviceWidthCombo.desktop) {
    return [DeviceWidth.smallDesktop, DeviceWidth.wideDesktop].includes(
      deviceWidth
    )
  } else if (width === DeviceWidthCombo.mobileOrTablet) {
    return [DeviceWidth.mobile, DeviceWidth.tablet].includes(deviceWidth)
  }

  return width === deviceWidth
}

type SemanticWidthNumber =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16

export const useColumnWidth = ({
  width,
  tabletMobileAdd,
  mobileAdd,
}: {
  // Base width of column
  width: SemanticWidthNumber
  // If the device is smaller than desktop this will be added to total
  tabletMobileAdd?: SemanticWidthNumber
  // If the device is mobile this number will be added
  mobileAdd?: SemanticWidthNumber
}) => {
  let newWidth = width
  const deviceWidth = useDeviceWidth()
  if (
    [DeviceWidth.tablet, DeviceWidth.mobile].includes(deviceWidth) &&
    tabletMobileAdd
  ) {
    newWidth += tabletMobileAdd
  }

  if (deviceWidth === DeviceWidth.mobile && mobileAdd) {
    newWidth += mobileAdd
  }

  // This helper assumes you are sending in valid numbers and number will be 16 or less
  return newWidth as SemanticWidthNumber
}
