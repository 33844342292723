import { CSSProperties, FC, ReactNode, SyntheticEvent } from 'react'
import { Grid, Icon } from 'semantic-ui-react'

import {
  GridRowColumn,
  Link,
  Text,
  Popup,
} from '../../../../components/BaseComponents'
import { PopupProps } from '../../../../components/BaseComponents/Popup'
import { insertBetween } from '../../../../utils/arrayHelpers'
import { Colors } from '../../../../styles/theme'

interface Props extends PopupProps {
  popup: {
    title?: string
    body: string | FC
    link?: string
    linkText?: string
  }
  labelComponent?: string | ReactNode
  style?: CSSProperties
}

// if the body of the tooltip includes a `<br/>`, parse that and insert an empty line where it is
const parseTooltipBody = (body: string) => {
  if (body.includes('<br/>')) {
    return insertBetween<ReactNode>(
      body.split('<br/>'),
      <>
        <br />
        <br />
      </>
    )
  }

  return body
}

const Tooltip = ({ popup, labelComponent, bordered, ...rest }: Props) => (
  <Popup
    hoverable
    bordered={bordered}
    {...rest}
    content={
      <Grid
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation()
        }}
        style={
          bordered
            ? { backgroundColor: Colors.white, borderRadius: 4 }
            : undefined
        }
      >
        {popup.title && (
          <GridRowColumn style={{ paddingBottom: '0' }}>
            <Text style={{ paddingTop: '10px' }} as="h3">
              {popup.title}
            </Text>
          </GridRowColumn>
        )}
        <GridRowColumn>
          {typeof popup.body === 'string' ? (
            <Text>{parseTooltipBody(popup.body)}</Text>
          ) : (
            <popup.body />
          )}
        </GridRowColumn>
        {popup.link && (
          <GridRowColumn style={{ padding: '0 0 20px 0' }}>
            <Link href={popup.link} newPage>
              {/* Wrapping in <span> so icon vertically aligns */}
              <span>
                {popup.linkText || 'Learn more'}{' '}
                <Icon name="long arrow alternate right" />
              </span>
            </Link>
          </GridRowColumn>
        )}
      </Grid>
    }
    trigger={
      labelComponent && (
        <span style={{ cursor: 'pointer' }}>{labelComponent}</span>
      )
    }
  />
)

export default Tooltip
