export const ABOUND_STATUS = Object.freeze({
  not_started: 'not_started',
  created: 'created',
  verifying: 'verifying',
  pending: 'pending',
  error: 'error',
  done: 'done',
})

export const ABOUND_ERROR_TYPES = Object.freeze({
  ssnVerificationError: 'ssnVerificationError',
  userError: 'userError',
  payerError: 'payerError',
  documentError: 'documentError',
})

export const zeroToTenIntToWord = Object.freeze({
  0: 'zero',
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
  10: 'ten',
})

export const TIN_VERIFICATION_STATUS = Object.freeze({
  unverified: 'unverified',
  verified: 'verified',
  mismatch: 'mismatch',
  pending: 'pending',
  error: 'error',
  lockedOut: 'lockedOut',
})
