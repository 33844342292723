import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { createSelector } from 'reselect'
import { filter, keyBy } from 'lodash'

import { fetchWrapper } from '../../reducers/fetch'
import { ActionItem } from '../Admin/ActionItems/allActionItems.slice'
import { ReduxState } from '../../utils/typeHelpers'

export interface FinancialProfileActionItem {
  id: number
  completedAt: string | null
  actionItem: ActionItem
  financialProfileId: number
  actionItemId: number
  createdAt: string
}

export interface FinancialProfileActionItemState {
  [key: string]: FinancialProfileActionItem
}

const financialProfileActionItemsSlice = createSlice({
  name: 'financialProfileActionItems',
  initialState: {} as FinancialProfileActionItemState,
  reducers: {
    receiveFinancialProfileActionItems: (
      state,
      action: PayloadAction<{ [key: string]: FinancialProfileActionItem }>
    ) => ({ ...state, ...action.payload }),
    receiveSingleFinancialProfileActionItem: (
      state,
      action: PayloadAction<FinancialProfileActionItem>
    ) => {
      state[action.payload.id] = action.payload
    },
    deleteFinancialProfileActionItem: (
      state,
      action: PayloadAction<number>
    ) => {
      delete state[action.payload]
    },
  },
})

export default financialProfileActionItemsSlice.reducer

// Actions
const {
  receiveFinancialProfileActionItems,
  receiveSingleFinancialProfileActionItem,
  deleteFinancialProfileActionItem,
} = financialProfileActionItemsSlice.actions

export const fetchFinancialProfileActionItems = () =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const json = await axios.get<FinancialProfileActionItem[]>(
        '/finances/api/v1/action_items'
      )

      dispatch(receiveFinancialProfileActionItems(keyBy(json.data, 'id')))
      return json.data
    },
  })

export const updateFinancialProfileActionItems = (
  id: number,
  data: {
    completedAt: number | null
  }
) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const json = await axios.post<FinancialProfileActionItem>(
        `/finances/api/v1/action_items/${id}`,
        data
      )
      dispatch(receiveSingleFinancialProfileActionItem(json.data))
      return json.data
    },
  })

// Admin endpoints
export const ADMIN_CREATE_FP_USER_ACTION_ITEM_KEY =
  'ADMIN_CREATE_USER_ACTION_ITEM_KEY'
export const adminCreateFPUserActionItems = (
  userId: number,
  data: {
    actionItemId: number | undefined
  }
) =>
  fetchWrapper({
    fetchKey: ADMIN_CREATE_FP_USER_ACTION_ITEM_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<FinancialProfileActionItem>(
        `/finances/api/v1/admin/financial_profile/action_item/${userId}`,
        data
      )
      dispatch(receiveSingleFinancialProfileActionItem(json.data))
      return json.data
    },
  })

export const adminDeleteFPUserActionItems = (userId: number, id: number) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const json = await axios.delete<{ deletedId: number }>(
        `/finances/api/v1/admin/financial_profile/action_item/${userId}/${id}`
      )
      dispatch(deleteFinancialProfileActionItem(json.data.deletedId))
      return json.data
    },
  })

export const selectFPActionItems = (state: ReduxState) =>
  state.financialProfileActionItems

export const selectIncompleteFPUserActionItems = createSelector(
  selectFPActionItems,
  (_: unknown, fpId: number | undefined) => fpId,
  (allActionItems, fpId) =>
    filter(
      allActionItems,
      (item) => item.financialProfileId === fpId && !item.completedAt
    )
)

export const selectCompleteFPUserActionItems = createSelector(
  [selectFPActionItems, (_: unknown, fpId: number | undefined) => fpId],
  (allActionItems, fpId) =>
    filter(
      allActionItems,
      (item) => item.financialProfileId === fpId && Boolean(item.completedAt)
    )
)
