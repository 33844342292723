import { ReactNode, useMemo } from 'react'

import {
  Accordion,
  GridRowColumn,
  Link,
  Text,
} from '../../../../components/BaseComponents'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectActiveQuarterlyTaxEstimateDetails } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'

const TaxProfileAccordions = ({
  faqs,
}: {
  faqs: Array<{ title: string; content: ReactNode }>
}) => (
  <GridRowColumn {...makeGridConfig([12, 16, 16], true)} short>
    {faqs.map((faq) => (
      <Accordion
        key={faq.title}
        {...faq}
        backgroundColor="white"
        variant="default"
      />
    ))}
  </GridRowColumn>
)

export default TaxProfileAccordions

export const useTaxProfileAccordionCopy = () => {
  const currentDetails = useReselector(selectActiveQuarterlyTaxEstimateDetails)

  const ytdScorpW2IncomeFaqCopy = useMemo(() => {
    switch (currentDetails?.taxQuarter) {
      case '1':
        return '"Year-to-date" means all of the money you\'ve made so far just this year. This includes income from January of this year through February. You\'ll find this on the first paycheck you paid yourself in March.'
      case '2':
        return '"Year-to-date" means all of the money you\'ve made so far just this year. This includes income from January of this year through April. You\'ll find this on the first paycheck you paid yourself in May.'
      case '3':
        return '"Year-to-date" means all of the money you\'ve made so far just this year. This includes income from January of this year through July. You\'ll find this on the first paycheck you paid yourself in August.'
      case '4':
        return '"Year-to-date" means all of the money you\'ve made so far just this year. This includes income from January of this year through November. You\'ll find this on the first paycheck you paid yourself in December.'
      // should never happen, but fall back to generic copy
      default:
        return 'This is all of the money you’ve made so far just this year. This includes income from January of this year up to the most recent pay period.'
    }
  }, [currentDetails?.taxQuarter])

  const ytdSpouseW2IncomeFaqCopy = useMemo(() => {
    switch (currentDetails?.taxQuarter) {
      case '1':
        return '"Year-to-date" means all of the money your spouse made so far just this year. This includes income from January of this year through February. You\'ll find this on the first paycheck they received in March.'
      case '2':
        return '"Year-to-date" means all of the money your spouse made so far just this year. This includes income from January of this year through April. You\'ll find this on the first paycheck they received in May.'
      case '3':
        return '"Year-to-date" means all of the money your spouse made so far just this year. This includes income from January of this year through July. You\'ll find this on the first paycheck they received in August.'
      case '4':
        return '"Year-to-date" means all of the money your spouse made so far just this year. This includes income from January of this year through November. You\'ll find this on the first paycheck they received in December.'
      // should never happen, but fall back to generic copy
      default:
        return 'This is all of the money your spouse has made so far just this year. This includes income from January of this year up to the most recent pay period.'
    }
  }, [currentDetails?.taxQuarter])

  return {
    canUseOldPaystub: {
      title: 'Can I use an old paystub?',
      content:
        'Since we need your year-to-date income, which is all of the money you’ve made so far this year, an old paystub won’t capture all of the income you’ve made. Plus, outdated numbers may result in an inaccurate quarterly tax estimate.',
    },
    whereFindOldPaystub: {
      title: 'Where do I find my paystub?',
      content:
        'If you’re an S corp, you’ll receive your paystub from your payroll provider( i.e. Gusto, ADP, OnPay). If you earn W-2 income outside of your practice, your employer should provide this to you every pay period.',
    },
    whatIfDoNotEarnW2: {
      title: 'What if I don’t earn W-2 income?',
      content:
        'If you (or your spouse) don’t earn W-2 income, then there’s no need to worry about a paystub since we already have your practice income information.',
    },
    whoPaysQtes: {
      title: 'Who should pay quarterly tax estimates?',
      content: (
        <Text>
          The following are responsible for paying quarterly taxes:
          <ul>
            <li>A sole proprietor</li>
            <li>A shareholder of an S Corporation</li>
            <li>
              A member of a partnership that conducts business, such as an LLC
            </li>
            <li>An independent contractor collecting 1099 income</li>
          </ul>
        </Text>
      ),
    },
    no1040TaxReturn: {
      title: `What if I don’t have a 1040 federal tax return for ${Number(currentDetails?.taxYear) - 1}?`,
      content: (
        <Text>
          Most Heard members will have a <b>personal tax return</b> from last
          year that they can use for this calculator. Safe harbor amounts are
          calculated off of personal tax returns. If you don&apos;t have a
          personal federal tax return from last year, you can{' '}
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/22050337435415-How-to-manually-calculate-quarterly-estimated-tax"
            newPage
            as="secondaryLink"
          >
            manually calculate your estimated taxes
          </Link>
          . Heard will help provide a calculated estimate in future quarters.
        </Text>
      ),
    },
    noPayQtesPriorQuarters: {
      title:
        "What if I didn't pay my quarterly estimates for prior quarters this year?",
      content: (
        <Text>
          You may owe underpayment penalties at the end of the year if you
          haven&apos;t made consistent, quarterly estimated tax payments.
          <br />
          <br />
          After this quarter, Heard will provide quarterly tax estimates and
          recommend payments that are inclusive of your past tax payments in
          order to help you “true up” to your annual tax liabilities and
          minimize underpayment penalties moving forward.
        </Text>
      ),
    },
    haveToPayQTEsScorp: {
      title: 'Do I still have to pay quarterly taxes as an S-corp?',
      content: (
        <Text>
          Yes, you are required to pay quarterly taxes. S Corps are pass-through
          entities, meaning that the business&apos; income and losses are passed
          through to the shareholders on their personal tax returns.
          <br />
          <br />
          Although you pay payroll taxes on your W-2 wages through your payroll
          provider, you must still pay income tax on the business earnings to
          the IRS and your state entity (if applicable). However, you will
          notice that your estimates are substantially lower than they would be
          as a Sole Proprietor.
        </Text>
      ),
    },
    whyNeedKnowAmountTaxes: {
      title: 'Why do you need to know how much I paid in taxes?',
      content:
        'Since you just joined, we won’t be able to provide a calculated quarterly tax estimate using your practice income this quarter. This quarter, you can use our safe harbor estimate calculator to determine an estimate that will help minimize underpayment penalties, which is based on last year’s income and tax liabilities.',
    },
    whatIsSafeHarbor: {
      title: 'What is a safe harbor estimate, and how is it calculated?',
      content: (
        <Text>
          The term “safe harbor” means you are protected from penalties by law
          as long as certain conditions are met.
          <br />
          <br />
          It is calculated by taking your prior year taxes paid and dividing by
          4. For example, if you paid $1,000 last year for federal taxes,
          you&apos;ll divide it by 4 and pay $250 for this quarter&apos;s tax
          payment. You&apos;ll do the same calculation for your state quarterly
          tax payment (if it applies). To reduce the risk of an underpayment
          penalty, make sure to pay at least 90% of what you owe for the current
          year or 100% of what you owe for the prior year.
        </Text>
      ),
    },
    willPaySafeHarborNoTaxes: {
      title: 'Will paying safe harbor mean I won’t owe taxes at end of year?',
      content: (
        <Text>
          Paying safe harbor amounts for your quarterly tax estimates helps you
          avoid underpayment penalties. However, it doesn&apos;t guarantee that
          you won&apos;t owe additional tax at the end of the year, since this
          amount is calculated based on last year’s income and liabilities.
          <br />
          <br />
          If you haven&apos;t paid quarterly taxes in previous quarters, you can
          minimize risk of underpayment penalties by paying the shown amount for
          each quarter.
        </Text>
      ),
    },
    whyEstimateHigh: {
      title: 'Why is my estimate so high?',
      content: (
        <Text>
          This safe harbor estimate calculated only looks at your prior year’s
          withholdings, and is based on last year’s adjusted gross income.
          <br />
          <br />
          It does not take into account any withholdings from other income
          sources. If you expect to make less than last year, or have taxes
          withheld from income (e.g. through you or a partner’s salaried job),
          you can lower the amount you plan to pay with this estimate. To be
          more certain about paying a lower amount, you can{' '}
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/22050337435415-How-to-manually-calculate-quarterly-estimated-tax"
            newPage
            as="secondaryLink"
          >
            manually calculate your estimated taxes
          </Link>
          . Heard will do this for you in future quarters once we are up-to-date
          with your practice bookkeeping and earnings.
        </Text>
      ),
    },
    taxesWithheldW2: {
      title:
        'I have taxes withheld from my W-2 job. How does this estimate include those?',
      content:
        'This estimate is the total personal taxes you should pay this quarter to meet your safe harbor goal. If you have federal taxes withheld from income (e.g. taxes on salaried jobs), you can subtract those taxes from the amount you see above, and use the remainder as the estimated amount to pay. In future quarters, as Heard knows more about your business, we’ll ask for and account for this information.',
    },
    onlyWantIncludeBusinessProfit: {
      title:
        'I only want to include my business profit. Why do I need to provide my W-2 income?',
      content: (
        <Text>
          You pay quarterly taxes on your <b>personal</b> income; quarterly
          taxes aren’t for your business profit. If you enter your business
          profit or business gross income, this inflates your income and leads
          to a wrong calculation of your estimate.
          <br />
          <br />
          By including the income you earned as an employee in the quarterly
          estimate calculation, you’ll end up paying more accurate quarterly
          taxes that ultimately reduces your year-end tax bill. You can learn
          more about how quarterly taxes work for S corporations{' '}
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/4416208116247"
            newPage
          >
            here
          </Link>
          .
        </Text>
      ),
    },
    whyNeedEnterPaystubIncome: {
      title: 'Why do I need to enter my income from my paystub?',
      content: (
        <Text>
          This is because we’re asking for your W-2 income that you’ve earned as
          an employee of your S corporation. You can find your year-to-date
          gross income at the top of your most recent paystub.
          <br />
          <br />
          If you feel your paystub isn’t reflective of your total year income,
          you can use our estimated income approach to enter how much you
          estimate you’ll make from your S corporation W-2 this year.
        </Text>
      ),
    },
    howDoIEstimate: {
      title: 'How do I estimate my annual income?',
      content: (
        <Text>
          If you choose to provide us with your estimated income, you tell us
          how much you think you’ll pay yourself as an employee of your S
          corporation throughout the year (before taxes). This allows you to
          account for additional factors like changes in your salary or unpaid
          leave.
          <br />
          <br />
          If you need help estimating your annual income, see this article{' '}
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/25443892699927/"
            newPage
          >
            How to Estimate your Annual Income
          </Link>
          .
        </Text>
      ),
    },
    whatIsYearToDate: {
      title: 'What does “year-to-date” mean, and how do I find this number?',
      content: <Text>{ytdScorpW2IncomeFaqCopy}</Text>,
    },
    whatIsGrossIncome: {
      title: 'What does “gross income” mean?',
      content:
        'This is the amount of income earned before taxes have been taken out.',
    },
    whySpouseIncomeOtherJobs: {
      title:
        'Why do I need to include my spouse’s income from their other jobs?',
      content:
        'Your spouse pays quarterly taxes on all of their earned income. When your quarterly payments reflect you and your spouse’s total earned income, it’s a more accurate amount and ultimately reduces your end-of-year tax bill.',
    },
    howFindSpouse1099: {
      title: 'How do I find my spouse’s year-to-date 1099 income?',
      content:
        'We recommend gathering all of your spouse’s 1099 invoices/paychecks/proof of payment from this year. You’ll add up the total of all of the income from those forms and enter the final number.',
    },
    whyShareSpouseIncome: {
      title: 'Why do I need to share my spouse’s income?',
      // Text should show as married even if user is a widow
      content:
        'This is because your filing status is married filing jointly, and quarterly estimates differ based on your filing status. If you don’t share your spouse’s income, you may have an estimate that’s too low and you may end up paying a much larger year-end tax bill.',
    },
    whatIsW2Job: {
      title: 'What’s a W-2 job?',
      content:
        'This is a form of employment where a person is considered an employee, versus a contractor or freelancer. They receive a W-2 tax form each year that reports their income and tax withholdings. W-2 employees are entitled to protections and benefits like health insurance, paid time off, unemployment and workers compensation.',
    },
    whatDoesYTDMeanSpouse: {
      title: 'What does “year-to-date” mean, and how do I find this number?',
      content: <Text>{ytdSpouseW2IncomeFaqCopy}</Text>,
    },
    whyShareSpouseTaxWithholdings: {
      title: 'Why do I need to share my spouse’s tax withholdings?',
      content: (
        // Text should show as married even if user is widow
        <Text>
          This is because your filing status is married filing jointly, and
          quarterly estimates differ based on your filing status. If you don’t
          share your spouse’s withholdings, this can lead to an inaccurate
          estimate and a larger year-end tax bill. Even if you’re confident that
          your spouse is withholding enough taxes, we still strongly encourage
          you to add them here.
          <br />
          <br />
          You can learn more about withholdings{' '}
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/4761711959063"
            newPage
          >
            here
          </Link>
          .
        </Text>
      ),
    },
    whatIfHomeStateNoTax: {
      title: 'What if my home state doesn’t collect income tax?',
      content:
        'If your state doesn’t collect income tax, you can put “0.00” for state withholdings (Alaska, Florida, Nevada, South Dakota, Tennessee, Texas, Washington, and Wyoming).',
    },
    whatAreSpouseYTDTaxWithholdings: {
      title: 'What are year-to-date tax withholdings?',
      content:
        'This is the amount of money that’s been taken from your spouse’s W-2 wages so far this year for federal and state taxes.',
    },
    needToInclude1099Job: {
      title:
        'I have a 1099 job on the side of my private practice. Do I need to include that income? ',
      content:
        'Yes. Since you pay quarterly taxes on all of your earned income, we need that additional income information for an accurate estimate calculation.',
    },
    howFind1099: {
      title: 'How do I find my year-to-date 1099 income?',
      content:
        'We recommend gathering all of your 1099 invoices/paychecks/proof of payment from this year. You’ll add up the income from those forms and enter that number.',
    },
    whyNeedToIncludeIncomeOtherJobs: {
      title: 'Why do I need to include income from my other jobs?',
      content: (
        <Text>
          You pay quarterly taxes on all of your earned personal income – not
          just what you make from your private practice. When your quarterly
          payments reflect your total earned income, it’s a more accurate amount
          and ultimately reduces your year-end tax bill.
          <br />
          <br />
          If you feel your paystubs aren’t reflective of your total year income,
          you can use our estimated income approach to enter how much you
          estimate you’ll make across your W-2 jobs throughout the year.
        </Text>
      ),
    },
    alreadyPaidW2Withholdings: {
      title:
        'I already pay taxes through my W-2 withholdings. Why do I need to provide them here?',
      content: (
        <Text>
          Withholdings from your W-2 wages aren’t always accurate. If you solely
          rely on those withholdings to cover the taxes you owe throughout the
          year, you risk paying a much larger year-end tax bill. You can learn
          more about tax withholdings{' '}
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/4761711959063"
            newPage
          >
            here
          </Link>
          .
        </Text>
      ),
    },
    whatAreYTDTaxWithholdings: {
      title: 'What are year-to-date tax withholdings?',
      content: (
        <Text>
          This is the amount of money that’s been taken from your W-2 wages so
          far this year for taxes. You can learn more about tax withholdings{' '}
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/4761711959063"
            newPage
          >
            here
          </Link>
          .
        </Text>
      ),
    },
    whatIfMultipleW2: {
      title: 'What if I have multiple W-2 jobs?',
      content:
        'Please enter the total of all tax withholdings from your W-2 jobs (including withholdings from the wages you earn from your S corporation and any additional W-2 jobs).',
    },
  }
}
