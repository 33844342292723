import { DateTime } from 'luxon'
import { BalanceSheetLineItem } from '../../../../actions/reportActions'
import { DATE_FORMATS_LUXON } from '../../../../utils/dateHelpers'

export const generateLineItemLink = (
  account: BalanceSheetLineItem,
  userId: string | undefined,
  balanceSheetDate: string
) => {
  if (!userId) return undefined
  const { financialAccountId, transactionCategoryId } = account
  let param = ''
  if (transactionCategoryId) {
    param = `transactionCategoryId=${transactionCategoryId}`
  } else if (financialAccountId) {
    param = `financialAccountId=${financialAccountId}`
  }

  const endDate = DateTime.fromFormat(
    balanceSheetDate,
    DATE_FORMATS_LUXON.DISPLAY_SHORT
  ).toFormat(DATE_FORMATS_LUXON.INPUT)

  return `/admin/finances/financial-reports/${userId}/general-ledger?${param}&endDate=${endDate}`
}

export const SUBCATEGORY_LABELS: {
  [label: string]: string
} = {
  'Current Asset': 'Current Assets',
  'PP&E, net': 'PP&E, net',
  'Current Liability': 'Current Liabilities',
  'Long Term Liability': 'Long-term Liabilities',
}

export const SUBCATEGORY_TOTAL_LABELS: {
  [label: string]: string
} = {
  'Current Asset': 'Total Current Assets',
  'PP&E, net': 'Total PP&E',
  'Current Liability': 'Total Current Liabilities',
  'Long Term Liability': 'Total Long-term Liabilities',
  Equity: 'Total Equity',
}

/**
 * 2023 Tax Season specific
 *  
 *  - Users who joined Heard in 2023 and elected to be an S Corp in 2023 or after**, will have an automatic balance sheet generated**
 * - Users who joined Heard before 2022 OR elected to be an S Corp in 2022 or after**, will have manual balance sheet compiled**
    - Why: Low confidence in 2022, 20221 accounting to carry into 2023 Taxes
 */
export const canAutoGenerateBS = (
  membershipStartDate?: string | null,
  effectiveDate?: string | null
) => {
  if (!membershipStartDate || !effectiveDate) return false
  return (
    DateTime.fromISO(membershipStartDate).toUTC().startOf('day') >=
      DateTime.fromISO('2023-01-01').toUTC().startOf('day') &&
    DateTime.fromISO(effectiveDate).toUTC().startOf('day') >=
      DateTime.fromISO('2023-01-01').toUTC().startOf('day')
  )
}
