import moment from 'moment'
import { sortBy } from 'lodash'

// BL
import { UserDocument } from './userDocuments.slice'
import {
  UserDocumentCategory,
  UserDocumentCategoryState,
} from '../Admin/UserDocumentCategories/userDocumentCategories.slice'
import {
  SERVICE_PLACEHOLDER_DOCUMENT_DATE_FORMAT,
  TabCategoryKeys,
  TABLE_MONTH_FORMAT,
} from './constants'
import {
  bankStatementsPane as copy,
  bankStatementsPane as bankCopy,
  serviceCopy,
} from './copyConstants'
import { GroupByType } from './sharedComponents/Table/UserDocumentsTable'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { TaxUserDocument } from '../Taxes/AnnualTaxes/taxUserDocuments.slice'

// Inserts any missing documents as placeholders in the user's document collection
export function insertPlaceholderDocuments({
  documents,
  categories,
  entityType,
}: {
  documents: UserDocument[]
  categories: UserDocumentCategory[]
  entityType?: string
}) {
  if (!entityType) {
    return documents
  }

  const result = [...documents]

  // For each category, if requiredFor includes user's entityType
  categories.forEach((cat) => {
    if (cat.requiredFor?.includes(entityType)) {
      // Search for the required doc within the user's collection
      const query = documents.filter(
        (doc) => doc.userDocumentCategoryId === cat.id
      )

      // Is required document missing?
      if (query.length === 0) {
        // Create a placeholder document in the user's collection
        result.push({
          id: -1,
          financialProfileId: -1,
          transactionId: null,
          bookkeepingReportId: null,
          awsFileName:
            cat.title || serviceCopy.static.defaultPlaceholderDocumentName,
          documentType: cat.type,
          fileDescription: cat.description,
          statementMonth: null,
          statementFinancialAccountId: null,
          updatedAt: '',
          createdAt: moment(new Date()).format(
            SERVICE_PLACEHOLDER_DOCUMENT_DATE_FORMAT
          ),
          uploadedAt: '',
          userDocumentCategoryId: cat.id,
          signedUrl: null,
          customerDescription: null,
          needsReview: null,
          textractDocumentType: null,
          textractUserDocumentCategoryId: null,
          FinancialProfileId: -1,
        })
      }
    }
  })

  return result
}

// Checks for populated file array and a date earlier than the current month/year
export function validateBankStatementsUpload({
  files,
  year,
  month,
}: {
  files: File[]
  year: number
  month: number
}) {
  const errors = []

  if (!files || files.length === 0) {
    errors.push(bankCopy.static.uploadErrorNoFilesSelected)
  }

  const now = new Date()
  if (year >= now.getFullYear() && month >= now.getMonth()) {
    errors.push(bankCopy.static.uploadErrorInvalidDate)
  }

  const message = errors.join('\n')

  return message
}

export function validateTaxDocumentsUpload({ files }: { files: File[] }) {
  const errors = []

  if (!files || files.length === 0) {
    errors.push(bankCopy.static.uploadErrorNoFilesSelected)
  }

  const message = errors.join('\n')

  return message
}

export function localDateString({
  date,
  format,
}: {
  date: string
  format: string
}) {
  return moment(date).format(format)
}

export function toLocalFullDate(date: string) {
  return localDateString({ date, format: DATE_FORMATS.DISPLAY_SHORT })
}

export function toLocalMonth(date: string) {
  return localDateString({ date, format: TABLE_MONTH_FORMAT })
}

// Document Sorting
export function sortDocumentsByFileNameAsc(a: UserDocument, b: UserDocument) {
  return a.awsFileName.localeCompare(b.awsFileName)
}

export function sortDocumentsByCreatedAtDesc(a: UserDocument, b: UserDocument) {
  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
}

export function sortDocumentsByStatementDateDesc(
  a: UserDocument,
  b: UserDocument
) {
  if (a.statementMonth && b.statementMonth) {
    return (
      new Date(b.statementMonth).getTime() -
      new Date(a.statementMonth).getTime()
    )
  }

  return sortDocumentsByCreatedAtDesc(a, b)
}

export function sortDocumentsByNameAscWithinCreatedAtDesc(
  a: UserDocument,
  b: UserDocument
) {
  return sortDocumentsByCreatedAtDesc(a, b) || sortDocumentsByFileNameAsc(a, b)
}

export function sortDocumentsByNameAscWithinStatementDateDesc(
  a: UserDocument,
  b: UserDocument
) {
  return (
    sortDocumentsByStatementDateDesc(a, b) || sortDocumentsByFileNameAsc(a, b)
  )
}

// Document Grouping (using a Map b/c an Object forces ASC order of its keys)
// Assuming documents is already sorted
export function groupDocumentsByYear(
  documents: UserDocument[],
  groupBy?: GroupByType,
  documentCategories?: UserDocumentCategoryState,
  taxUserDocuments?: TaxUserDocument[]
) {
  const groups = new Map<number, UserDocument[]>()

  documents.forEach((doc) => {
    let year

    if (groupBy === GroupByType.CategoryYear && documentCategories) {
      // Looks through document categories to find year in the internal name
      const category =
        doc.userDocumentCategoryId !== null &&
        documentCategories[doc.userDocumentCategoryId]

      const categoryYear =
        category && category.internalName.match(/20\d\d/)?.[0]

      const taxUserDocument = taxUserDocuments?.find(
        (t) => t.userDocumentId === doc.id
      )
      // Use the year from the document category name, otherwise default to created at year
      if (taxUserDocument?.taxYear) {
        year = Number(taxUserDocument.taxYear)
      } else if (categoryYear) {
        year = Number(categoryYear)
      } else {
        year = new Date(doc.createdAt).getFullYear()
      }
    } else if (groupBy === GroupByType.StatementYear) {
      year =
        (doc.statementMonth ?? doc.statement?.statementMonth)
          ? moment(
              doc.statementMonth ?? doc.statement?.statementMonth,
              'YYYY-MM'
            ).year()
          : new Date(doc.createdAt).getFullYear()
    } else {
      year = new Date(doc.createdAt).getFullYear()
    }

    if (groups.has(year)) {
      groups.get(year)?.push(doc)
    } else {
      groups.set(year, [doc])
    }
  })

  return groups
}

// Assuming documents is already sorted
export function groupDocumentsByMonthWithinYear(documents: UserDocument[]) {
  const groups = new Map<number, Map<number, UserDocument[]>>()

  const years = groupDocumentsByYear(documents)

  for (const year of years.keys()) {
    groups.set(year, new Map<number, UserDocument[]>())
    const docs = years.get(year)
    if (docs) {
      for (const doc of docs) {
        const key = new Date(doc.createdAt).getMonth()
        if (groups.get(year)?.get(key)) {
          groups.get(year)?.get(key)?.push(doc)
        } else {
          groups.get(year)?.set(key, [doc])
        }
      }
    }
  }

  return groups
}

export const getDocumentName = (
  document: UserDocument,
  category?: UserDocumentCategory
) => {
  if (document.signedUrl) {
    return document.fileDescription
  }

  if (!document.signedUrl) {
    /* Required, missing documents have their id set to -1 */
    if (document.id === -1) {
      return category?.title
    }
    return document.fileDescription
  }

  return ''
}

export const getDocumentTag = (
  document: UserDocument,
  category?: UserDocumentCategory
) => {
  let content

  if (document.documentType === TabCategoryKeys.BANK_STATEMENTS) {
    const statementMonth =
      document.statement?.statementMonth || document.statementMonth
    content = statementMonth
      ? toLocalMonth(statementMonth)
      : copy.static.statementMonthNoValue
  }

  if (document.documentType === TabCategoryKeys.TAX) {
    content = category?.title || null
  }

  if (document.documentType === TabCategoryKeys.INCORPORATION_DOCUMENTS) {
    content = category?.title || null
  }

  if (document.documentType === TabCategoryKeys.BOOKKEEPING) {
    content = category?.title || null
  }

  return content
}

// Documents with a -1 id are missing, required documents the user has not uploaded yet
export const getDocumentDescription = (
  document: UserDocument,
  category?: UserDocumentCategory
) =>
  document.id === -1
    ? category?.description
    : document.customerDescription || category?.description

export const getDocumentCategory = (
  doc: UserDocument,
  categories?: UserDocumentCategory[]
) => categories?.find((c) => c.id === doc.userDocumentCategoryId)

export const sortDocuments = ({
  docs,
  cats,
  sortByVal,
  sortAsc,
}: {
  docs: UserDocument[]
  cats?: UserDocumentCategory[]
  sortByVal: string | null
  sortAsc: boolean
}) => {
  let sortedDocs = docs

  switch (sortByVal) {
    case 'createdAt':
      sortedDocs = sortBy(docs, (doc) => doc.createdAt)
      break
    case 'fileName':
      sortedDocs = sortBy(docs, (doc) =>
        getDocumentName(doc, getDocumentCategory(doc, cats))
      )
      break
    case 'tag':
      sortedDocs = sortBy(docs, (doc) => {
        const tag = getDocumentTag(doc, getDocumentCategory(doc, cats))
        // If a tag is a month we want to sort them in order.  Look up the month index to see if it exists
        const index = tag ? moment.months().indexOf(tag) : -1

        // Return tag or month index
        return index === -1 ? tag : index
      })
      break
    case 'description':
      sortedDocs = sortBy(docs, (doc) =>
        getDocumentDescription(doc, getDocumentCategory(doc, cats))
      )
      break
    case 'transactionId':
      sortedDocs = sortBy(docs, (doc) => doc.transactionId)
      break
    case null:
    default:
      break
  }

  return sortAsc ? sortedDocs.reverse() : sortedDocs
}
