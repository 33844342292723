import { useEffect, useState } from 'react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FinancialAccount } from '../../../../reducers/finances/financialAccountsReducer'
import Card from '../../../BaseComponents/Card'
import Checkbox from '../../../BaseComponents/Checkbox'
import Icon, { StackedIcon } from '../../../BaseComponents/Icon'
import Text from '../../../BaseComponents/Text'
import PlaidLink from '../../../shared/plaid/PlaidLink'
import styles from './styles.module.scss'

export const FinancialAccountCard = ({
  account,
  handleSkipAccountReconnect,
}: {
  account: FinancialAccount
  handleSkipAccountReconnect: (isSkipped: boolean) => void
}) => {
  const [isChecked, setIsChecked] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const [isReconnectReconciled, setIsReconnectReconciled] = useState(false)

  useEffect(() => {
    setIsConnected(!account?.accounts?.needsReconnection)
  }, [account?.accounts?.needsReconnection])

  useEffect(() => {
    setIsReconnectReconciled(isConnected || isChecked)
  }, [isChecked, isConnected])

  const onChangeSkipAccountReconnect = () => {
    const newCheckedState = !isChecked
    setIsChecked(newCheckedState)
    handleSkipAccountReconnect(newCheckedState)
  }

  return (
    <Card
      key={account.id.toString()}
      backgroundColor={isReconnectReconciled ? 'lightGreen' : 'stone40'}
      fullWidth
    >
      <div className={styles.accountCard}>
        <div className={styles.topRow}>
          <StackedIcon
            icon={regular('bank')}
            color="white"
            backgroundIcon={solid('circle')}
            backgroundColor="forest"
            marginRight={16}
          />

          <div className={styles.accountName}>{account.name}</div>

          {isChecked ? (
            <div className={styles.isChecked}>
              <Checkbox
                id={`cant-reconnect-${account.id}`}
                label="I can't reconnect this account"
                checked={isChecked}
                onChange={onChangeSkipAccountReconnect}
              />
            </div>
          ) : isConnected ? (
            <div className={styles.reconnectedText}>
              <Text color="accentGreen" style={{ marginRight: 5 }}>
                Reconnected{' '}
              </Text>
              <Icon icon={regular('check')} color="accentGreen" />
            </div>
          ) : (
            <PlaidLink account={account} disconnected />
          )}
        </div>

        {!isChecked && !isConnected && (
          <div className={styles.cantReconnectAccount}>
            <Checkbox
              id={`cant-reconnect-${account.id}`}
              label="I can't reconnect this account"
              checked={isChecked}
              onChange={onChangeSkipAccountReconnect}
            />
          </div>
        )}
      </div>
    </Card>
  )
}
