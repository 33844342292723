import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Container, Grid } from 'semantic-ui-react'

import EmployeePayrollHistoryTab from './EmployeePayrollHistoryTab'
import ContractorPayrollHistoryTab from './ContractorPayrollHistoryTab'
import {
  Card,
  GridRowColumn,
  Tab,
  Text,
} from '../../../components/BaseComponents'

const PayrollHistory = () => {
  const [searchParams] = useSearchParams()

  const [activeIndex, setActiveIndex] = useState(
    searchParams.get('tab') === 'contractor' ? 1 : 0
  )

  const panes = useMemo(
    () => [
      {
        menuItem: 'Payroll History',
        render: () => (
          <Tab.Pane>
            <EmployeePayrollHistoryTab />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Contractor Payment History',
        render: () => (
          <Tab.Pane>
            <ContractorPayrollHistoryTab />
          </Tab.Pane>
        ),
      },
    ],
    []
  )

  return (
    <Container>
      <Card>
        <Grid>
          <GridRowColumn>
            <Text as="h1">Payroll History</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Tab
              panes={panes}
              activeIndex={activeIndex}
              defaultActiveIndex={activeIndex}
              onTabClick={(index) => setActiveIndex(index)}
            />
          </GridRowColumn>
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollHistory
