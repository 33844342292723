import axios from 'axios'
import { normalize } from 'normalizr'

import { transactionListSchema } from '../../schema'
import {
  receiveAdminAllTransactions,
  Transaction,
  TransactionResponseEntities,
} from '../../reducers/admin/allTransactions.slice'
import { fetchWrapper } from '../../reducers/fetch'

export const importTransactions = (
  userId: number,
  data: {
    userId: number
    importedOn: number
    accountId: number
    dataArray: string[][]
    manualAccountId?: number
  }
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error important transactions.',
    fetchFunction: async (dispatch) => {
      const json = await axios.post<Transaction[]>(
        `/finances/api/v1/admin/transactions/${userId}`,
        data
      )

      const normalizedData = normalize<
        Transaction,
        TransactionResponseEntities,
        string[]
      >(json.data, transactionListSchema)
      dispatch(receiveAdminAllTransactions(normalizedData))
      return json.data
    },
  })
