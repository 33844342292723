import { Grid, Icon } from 'semantic-ui-react'

import {
  selectFilingLocation,
  selectMailingLocation,
} from '../payroll.selectors'
import { GridRowColumn, Text } from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'

const LocationsPanel = ({ title }: { title: string }) => {
  const filingLocation = useReselector(selectFilingLocation)
  const mailingAddress = useReselector(selectMailingLocation)

  const areSameLocation =
    Boolean(filingLocation) && filingLocation?.uuid === mailingAddress?.uuid

  if (!filingLocation) {
    return null
  }

  const { street_1, street_2, city, state, zip } = filingLocation

  return (
    <>
      <GridRowColumn>
        <Text as="bodyLg">
          <b>
            <Icon name="map marker alternate" />
            {title}
          </b>
        </Text>
      </GridRowColumn>
      <GridRowColumn short>
        <Text>
          We need an accurate company address for tax filing purposes. This
          should be the address registered to your business.
        </Text>
      </GridRowColumn>
      <Grid.Row>
        <Grid.Column width={6}>
          <Grid>
            <GridRowColumn>
              <Text>
                <b>
                  {areSameLocation
                    ? 'Filling and mailing address:'
                    : 'Filing address'}
                </b>
              </Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Text>
                {street_1} {street_2 || ''}
              </Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Text>
                {city}, {state} {zip}
              </Text>
            </GridRowColumn>
          </Grid>
        </Grid.Column>
        {!areSameLocation && mailingAddress && (
          <Grid.Column width={6}>
            <Grid>
              <GridRowColumn short>
                <Text>
                  <b>Mailing Address</b>
                </Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text>
                  {mailingAddress.street_1} {mailingAddress.street_2 || ''}
                </Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text>
                  {mailingAddress.city}, {mailingAddress.state}{' '}
                  {mailingAddress.zip}
                </Text>
              </GridRowColumn>
            </Grid>
          </Grid.Column>
        )}
      </Grid.Row>
    </>
  )
}

export default LocationsPanel
