import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RequestSource, SCorpSetUpStatus } from './utils'

export interface SCorpElectionStatus {
  id: number
  userId: number
  status: SCorpSetUpStatus
  initialRequestSource: RequestSource
  initialRequestSubmittedAt: string | null
  lastUpdatedByUserId: number
  form2553FiledAt?: string | null
  cp261UploadedAt?: string | null
  createdAt: string
  updatedAt?: string
  deletedAt?: string | null
}

export interface SCorpStatusEntryState {
  [userId: number]: SCorpElectionStatus
}

const sCorpStatusSlice = createSlice({
  name: 'sCorpStatus',
  initialState: {} as SCorpStatusEntryState,
  reducers: {
    receiveUserSCorpElectionStatus: (
      state,
      action: PayloadAction<{
        userId: number
        data: SCorpElectionStatus
      }>
    ) => {
      state[action.payload.userId] = {
        ...action.payload.data,
      }
    },
  },
})

export const { receiveUserSCorpElectionStatus } = sCorpStatusSlice.actions

export default sCorpStatusSlice.reducer
