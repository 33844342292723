import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { receiveAdminAllTransactions } from './allTransactions.slice'

export interface AllTransactionEditState {
  [key: string]: TransactionEdit
}

export interface TransactionEdit {
  id: string
  type: 'flagged' | 'clarification'
  transactionId: number
  authorId: number | null
  transactionType: string | null
  transactionCategoryId: number | null
  notes: string | null
  requestedAt: string | null
  approvedOn: string | null
  notifiedUserAt: string | null
  createdAt: string
  updatedAt: string
}

const allTransactionsEditSlice = createSlice({
  name: 'allTransactionEdits',
  initialState: {} as AllTransactionEditState,
  reducers: {
    receiveTransactionEdit: (state, action: PayloadAction<TransactionEdit>) => {
      state[action.payload.id] = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(receiveAdminAllTransactions, (state, action) => ({
      ...state,
      ...action.payload.entities.transactionEdits,
    }))
  },
})

export const { receiveTransactionEdit } = allTransactionsEditSlice.actions

export default allTransactionsEditSlice.reducer
