import { Grid, Image } from 'semantic-ui-react'
import { Button, GridRowColumn, Text } from '../../components/BaseComponents'
import { makeGridConfig } from '../../components/BaseComponents/Grid'
import { useNavigate } from 'react-router-dom'

const CancellationPrevented = () => {
  const navigate = useNavigate()
  return (
    <Grid>
      <GridRowColumn />
      <GridRowColumn />
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/party-hat.svg"
          style={{ height: 180, width: 180 }}
        />
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([8, 16], true)}>
        <Text textAlign="center" as="h1">
          We&apos;re delighted that you&apos;re choosing to stay with us!
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([10, 16], true)}>
        <Text as="bodyLg">
          We&apos;re always working to improve Heard to address our therapist
          community&apos;s feedback and are glad that you&apos;ll be able to
          take advantage of all your benefits.
        </Text>
      </GridRowColumn>
      <GridRowColumn centerContent>
        <Button onClick={() => navigate('/')}>Go to Home</Button>
      </GridRowColumn>
    </Grid>
  )
}

export default CancellationPrevented
