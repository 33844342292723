import { Divider, Grid, Icon } from 'semantic-ui-react'

import { selectPayrollCompany } from '../payroll.selectors'
import LocationsPanel from './LocationsPanel'
import { useBankAccount } from '../helpers'
import { Button, Text, Link, Alert } from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'

const CompanySettingsTab = ({
  goToLocations,
}: {
  goToLocations: () => void
}) => {
  const company = useReselector(selectPayrollCompany)
  const bankAccount = useBankAccount()

  if (!company) {
    return null
  }

  const { primary_signatory } = company

  return (
    <Grid>
      <Grid.Row />
      <Grid.Row>
        <Grid.Column>
          <Alert>
            This page holds important information related to your company, which
            allows us to run payroll in a compliant manner. Please keep it up to
            date!
          </Alert>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row />
      <LocationsPanel title="Company Location" />
      <Grid.Row>
        <Grid.Column>
          <Button variant="link" onClick={goToLocations}>
            View all Company Locations
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Divider />

      <Grid.Row>
        <Grid.Column>
          <Text as="bodyLg">
            <b>
              <Icon name="university" />
              Business Bank Account For Payroll
            </b>
          </Text>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <Text>
            We will pull money from this bank account when running payroll.
          </Text>
        </Grid.Column>
      </Grid.Row>

      {bankAccount && (
        <>
          <Grid.Row>
            <Grid.Column>
              <Text>
                <b>{bankAccount.account_type}</b>
              </Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="short">
            <Grid.Column>
              <Text>Routing Number: {bankAccount.routing_number}</Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="short">
            <Grid.Column>
              <Text>Account Number: {bankAccount.hidden_account_number}</Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Text>
                Need to update this bank account?{' '}
                <Link to="/conversations">Contact the Heard Team</Link>
              </Text>
            </Grid.Column>
          </Grid.Row>
        </>
      )}
      <Divider />
      {primary_signatory && (
        <>
          <Grid.Row>
            <Grid.Column>
              <Text as="bodyLg">
                <b>
                  <Icon name="user outline" />
                  Company Signatory
                </b>
              </Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Text>
                This is the person that has authority to sign documents for your
                Company. We recommend this be you.
              </Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Text>
                <b>
                  {primary_signatory.first_name} {primary_signatory.last_name}
                </b>
              </Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="short">
            <Grid.Column>
              <Text>{primary_signatory.email}</Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Text>
                Need to update this signatory?{' '}
                <Link to="/conversations">Contact the Heard Team</Link>
              </Text>
            </Grid.Column>
          </Grid.Row>
        </>
      )}
    </Grid>
  )
}

export default CompanySettingsTab
