import { useState } from 'react'
import { Modal, Header, Button, Form } from 'semantic-ui-react'

import { useReselector } from '../../../utils/sharedHooks'
import { getFetchError } from '../../../reducers/fetch'
import { Alert } from '../../../components/BaseComponents'
import {
  CREATE_ACTION_ITEM_CATEGORY_KEY,
  createActionItemCategories,
} from './actionItemCategories.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'

const ActionItemCategoryCreationModal = ({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) => {
  const dispatch = useAppDispatch()
  const [title, setTitle] = useState('')
  const [internalName, setInternalName] = useState('')
  const error = useReselector(getFetchError, CREATE_ACTION_ITEM_CATEGORY_KEY)

  const createCategory = async () => {
    const res = await createActionItemCategories({
      title,
      internalName,
    })(dispatch)
    if (res) {
      close()
    }
  }

  return (
    <Modal
      size="tiny"
      className="actionItemCategoryCreationModal"
      open={open}
      onClose={close}
    >
      <Modal.Content>
        <Header as="h2">Create a new Action Item Category</Header>
        <p>
          Action Item Categories are used to group various Action Items into a
          collection.
        </p>
        {error && <Alert type="error">{error.message}</Alert>}
        <Form>
          <Form.Field required>
            <Form.Input
              placeholder="Internal Name"
              label="Enter internal name for Category"
              name="internalName"
              value={internalName}
              onChange={(e, target) => setInternalName(target.value)}
              fluid
              required
            />
          </Form.Field>
          <Form.Field required>
            <Form.Input
              placeholder="User Facing Title"
              label="Enter User Facing Title for this category"
              name="title"
              value={title}
              onChange={(e, target) => setTitle(target.value)}
              fluid
              required
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => close()}>
          Cancel
        </Button>
        <Button primary onClick={() => createCategory()}>
          Create
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ActionItemCategoryCreationModal
