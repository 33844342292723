import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { keyBy } from 'lodash'

import { fetchIfNeededWrapper, fetchWrapper } from '../../../reducers/fetch'

export interface ZendeskBulkMessage {
  id: number
  createdBy: number
  messageBody: string
  subject: string
  toDescription: string
  recipientIds: number[]
  zendeskJobs: unknown
  createdAt: string
}

export interface NewZendeskBulkMessage {
  createdBy: number
  messageBody: string
  subject: string
  recipientIds: number[]
  toDescription: string
}

export interface ZendeskBulkMessageState {
  [key: string]: ZendeskBulkMessage
}

const zendeskBulkMessageSlice = createSlice({
  name: 'zendeskBulkMessageSlice',
  initialState: {} as ZendeskBulkMessageState,
  reducers: {
    getAllZendeskBulkMessages: (
      state,
      action: PayloadAction<{ [key: string]: ZendeskBulkMessage }>
    ) => ({ ...state, ...action.payload }),
    getSingleZendeskBulkMessage: (
      state,
      action: PayloadAction<ZendeskBulkMessage>
    ) => {
      state[action.payload.id] = action.payload
    },
  },
})

export default zendeskBulkMessageSlice.reducer

// Actions
const { getAllZendeskBulkMessages, getSingleZendeskBulkMessage } =
  zendeskBulkMessageSlice.actions

export const createZendeskBulkMessage = (data: NewZendeskBulkMessage) =>
  fetchWrapper({
    defaultErrorMessage: 'Error creating ZendeskBulkMessage',
    shouldHandleError: false,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<ZendeskBulkMessage>(
        '/finances/api/v1/admin/zendesk-bulk-message',
        data
      )

      dispatch(getSingleZendeskBulkMessage(json.data))

      return json.data
    },
  })

const FETCH_ALL_ZENDESK_BULK_MESSAGES_KEY =
  'FETCH_ALL_ZENDESK_BULK_MESSAGES_KEY'
export const fetchAllZendeskBulkMessagesIfNeeded = () =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_ALL_ZENDESK_BULK_MESSAGES_KEY,
    defaultErrorMessage: 'Error fetching all ZendeskBulkMessages',
    shouldHandleError: false,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<ZendeskBulkMessage[]>(
        '/finances/api/v1/admin/zendesk-bulk-message'
      )

      dispatch(getAllZendeskBulkMessages(keyBy(json.data, 'id')))

      return json.data
    },
  })
