import { FC, useEffect, useState } from 'react'
import moment from 'moment'
import { Button, Container } from 'semantic-ui-react'

import { listLastRanOnDate } from './actions'
import SmartRuleCreationModal from './SmartRuleCreationModal'
import UserTransactionRulesTablex from './UserTransactionRulesTable'
import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import { DATE_FORMATS } from '../../../utils/dateHelpers'

interface props {
  user: UserWithAdminInfo
}

const SmartRuleCreation: FC<props> = ({ user }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [lastRanOn, setLastRanOn] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const response = await listLastRanOnDate(user.id)
      if (response) {
        setLastRanOn(response.smartRuleLastRan)
      }
    }
    fetchData()
  }, [user.id])
  return (
    <Container id="smartRuleCreation">
      <Button
        basic
        primary
        onClick={() => setModalOpen(true)}
        disabled={moment(lastRanOn) > moment().subtract(2, 'days')}
      >
        Run Smart Rule Creation
      </Button>
      <div style={{ marginTop: '10px' }}>
        <p>
          Last ran on: {moment(lastRanOn).format(DATE_FORMATS.DISPLAY_SHORT)}
        </p>
        <p>
          Note: You can only run Smart Rule creation every 3 days. This allows
          the new rules you have created to go into effect, so that we do not
          create duplicate rules.
        </p>
      </div>
      <hr />
      <UserTransactionRulesTablex user={user} />
      <SmartRuleCreationModal
        open={modalOpen}
        close={() => setModalOpen(false)}
        user={user}
      />
    </Container>
  )
}

export default SmartRuleCreation
