import { CSSProperties, ReactNode } from 'react'
import { StrictLabelProps } from 'semantic-ui-react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import styled from 'styled-components'
import { Colors, Fonts } from '../../styles/theme'
import Icon from './Icon'

export const LabelColorMap = {
  neutral: {
    backgroundColor: Colors.stone,
    color: Colors.oak,
  },
  dark: {
    backgroundColor: Colors.moss,
    color: Colors.forest,
  },
  blue: {
    backgroundColor: Colors.lightBlue,
    color: Colors.blue,
  },
  red: {
    backgroundColor: Colors.lightRed,
    color: Colors.red,
  },
  orange: {
    backgroundColor: Colors.lightOrange,
    color: Colors.orange,
  },
  yellow: {
    backgroundColor: Colors.lightYellow,
    color: Colors.yellow,
  },
  green: {
    backgroundColor: Colors.lightGreen,
    color: Colors.green,
  },
  darkGreen: {
    backgroundColor: Colors.green,
    color: Colors.white,
  },
  darkYellow: {
    backgroundColor: Colors.yellow,
    color: Colors.white,
  },
  gray: {
    backgroundColor: Colors.mediumGray,
    color: Colors.white,
  },
  white: {
    backgroundColor: Colors.white,
    color: Colors.oak,
  },
}

interface Props extends Omit<StrictLabelProps, 'color'> {
  children?: ReactNode
  $circular?: boolean
  color?: keyof typeof LabelColorMap
  icon?: IconDefinition
  $hoverable?: boolean
  style?: CSSProperties
}

const StyledLabel = styled.div<Props>(
  ({ $circular = false, color = 'neutral', $hoverable }) => ({
    alignItems: 'center',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    padding: 8,
    width: 'fit-content',

    ...($hoverable && {
      '&:hover': {
        cursor: 'pointer',
      },
    }),

    'i.icon': {
      fontSize: 14,
      height: 'initial',
      marginRight: 8,
    },

    // for circular labels, override some of the previously set styles and add others
    ...($circular && {
      borderRadius: 100,
      height: 24,
      padding: '4px 8px',
      width: 24,
    }),

    ...Fonts.buttonSm,
    ...LabelColorMap[color],
  })
)

const Label = ({ children, icon, ...rest }: Props) => (
  <StyledLabel {...rest}>
    {icon && <Icon icon={icon} style={{ marginRight: 8 }} />}
    {children ?? ''}
  </StyledLabel>
)

export default Label
