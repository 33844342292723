import { Divider, Grid } from 'semantic-ui-react'
import moment from 'moment'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import { selectPayrollByUuid } from '../../payroll.selectors'
import PaymentOverview from './PayrollOverview'
import PayrollDetails from './PayrollDetails'
import PayrollHeader from './PayrollHeader'
import {
  Button,
  GridRowColumn,
  Icon,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import PayrollError from '../../PayrollError'
import { PUT_PAYROLL_SUBMIT_KEY } from '../../payrollActions'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'

const PayrollReviewAndSubmitTab = ({
  goForward,
  goBack,
  uuid,
}: {
  goForward: () => void
  goBack: () => void
  uuid: string
}) => {
  const upcomingPayroll = useReselector(selectPayrollByUuid, uuid)

  if (!upcomingPayroll) {
    return null
  }

  return (
    <Grid>
      <GridRowColumn>
        <PayrollHeader upcomingPayroll={upcomingPayroll} currentTab={1} />
      </GridRowColumn>
      <GridRowColumn>
        <Text as="h1">Review and Submit Payroll</Text>
      </GridRowColumn>
      <GridRowColumn>
        <Text as="h3">Please take a moment to review the following:</Text>
      </GridRowColumn>
      <PaymentOverview payroll={upcomingPayroll} />
      <GridRowColumn>
        <Text as="bodyMd" style={{ paddingLeft: 20 }}>
          Funds will be withdrawn from your practice&apos;s bank account on{' '}
          <b>
            {moment(upcomingPayroll.payroll_deadline).format(
              DATE_FORMATS.DAY_OF_WEEK_DAY_MONTH
            )}
          </b>
          <br />
          If you submit this payroll by 3:30pm PT on{' '}
          <b>
            {moment(upcomingPayroll.payroll_deadline).format(
              DATE_FORMATS.DAY_OF_WEEK_DAY_MONTH
            )}
          </b>
          , your employees will be paid by{' '}
          <b>
            {moment(upcomingPayroll.check_date).format(
              DATE_FORMATS.DAY_OF_WEEK_DAY_MONTH
            )}
          </b>
        </Text>
      </GridRowColumn>
      <PayrollError fetchKey={PUT_PAYROLL_SUBMIT_KEY} />
      <Grid.Row />
      <Grid.Row>
        <Grid.Column width={4}>
          <Button onClick={goBack} variant="secondary" fullWidth>
            <Icon icon={light('arrow-left')} style={{ marginRight: 8 }} />
            Back
          </Button>
        </Grid.Column>
        <Grid.Column width={8} />
        <Grid.Column width={4}>
          <Button onClick={goForward} fullWidth>
            Run Payroll{' '}
            <Icon icon={light('arrow-right')} style={{ marginLeft: 8 }} />
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <PayrollDetails uuid={uuid} />
    </Grid>
  )
}

export default PayrollReviewAndSubmitTab
