import { Radio, Text } from '../../components/BaseComponents'
import { Form } from 'semantic-ui-react'

interface IBinaryRadioGroupProps {
  label: string
  text: string
  name: string
  onChange: (value: boolean) => void
  value: boolean | undefined
}

const BinaryRadioGroup: React.FC<IBinaryRadioGroupProps> = ({
  label,
  text,
  name,
  onChange,
  value,
}) => {
  return (
    <Form.Group grouped>
      <Text as="h3" style={{ paddingBottom: 5 }}>
        <b>{label}</b>
      </Text>
      <Text as="bodySm">{text}</Text>

      <Form.Field>
        <Radio
          label="Yes"
          name={name}
          checked={value === true}
          onChange={() => onChange(true)}
        />
      </Form.Field>

      <Form.Field>
        <Radio
          label="No"
          name={name}
          checked={value === false}
          onChange={() => onChange(false)}
        />
      </Form.Field>
    </Form.Group>
  )
}

export default BinaryRadioGroup
