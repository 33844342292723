import { useMemo } from 'react'

import { Link, Table, Text } from '../../BaseComponents'
import UserViewOnlyTransactionRow from '../../shared/UserViewOnlyTransactionRow'
import { getTransactionsByType } from '../../../features/Transactions/transactions.selectors'
import { useReselector } from '../../../utils/sharedHooks'

const ExpensesTransactionTable = () => {
  const transactions = useReselector(
    getTransactionsByType,
    'categorizedExpenses'
  )

  const transactionsToView = useMemo(
    () => transactions.slice(0, 10),
    [transactions]
  )

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={2}>Date</Table.HeaderCell>
          <Table.HeaderCell width={6}>Description</Table.HeaderCell>
          <Table.HeaderCell width={2} textAlign="right">
            Amount
          </Table.HeaderCell>

          <Table.HeaderCell width={6}>Category</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {transactionsToView.map((transaction) => (
          <UserViewOnlyTransactionRow
            key={transaction.id}
            transaction={transaction}
          />
        ))}
        <Table.Row>
          <Table.Cell colSpan="3">
            <Text as="bodyMd" color="green">
              Viewing {transactionsToView.length} out of {transactions.length}{' '}
              transactions
            </Text>
          </Table.Cell>
          <Table.Cell textAlign="right">
            <Link to="/transactions">View All</Link>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}

export default ExpensesTransactionTable
