import { createSelector } from 'reselect'
import { ReduxState } from '../../utils/typeHelpers'
import { selectCurrentAnnualTaxYear } from '../../features/Admin/AnnualTaxDetails/annualTaxDetails.selector'

export const getUser = (state: ReduxState) => state.auth.user

export const getUserId = createSelector(getUser, (user) => user.id)

export const getUserBooksLockedForUserYear = createSelector(
  getUser,
  (user) => user.booksLockedForUserYear
)

export const selectUserBooksLockedForCurrentTaxYear = createSelector(
  selectCurrentAnnualTaxYear,
  getUserBooksLockedForUserYear,
  (taxYear, booksLockedForUserYear) => booksLockedForUserYear === taxYear
)

export const selectUserBooksLockedForYear = createSelector(
  getUserBooksLockedForUserYear,
  (_: unknown, year: string) => year,
  (booksLockedForUserYear, year) => booksLockedForUserYear === year
)

export const getUserManager = createSelector(getUser, (user) => user.owner)

export const getUserManagerName = createSelector(
  getUserManager,
  (manager) => `${manager?.firstName || ''} ${manager?.lastName || ''}`
)

export const getUserManagerRole = createSelector(getUserManager, (manager) =>
  manager?.roles.find(({ name }) => name === 'Account Manager')
)

export const getUserManagerUserRole = createSelector(
  getUserManagerRole,
  (managerRole) => managerRole?.UserRole
)

export const getUserBookkeeper = createSelector(getUser, (user) => user.owner)

export const getUserBookkeeperRole = createSelector(
  getUserBookkeeper,
  (owner) => owner?.roles.find(({ name }) => name === 'Bookkeeper')
)

export const getUserBookkeeperUserRole = createSelector(
  getUserBookkeeperRole,
  (bookkeeperRole) => bookkeeperRole?.UserRole
)
