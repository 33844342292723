import { Grid } from 'semantic-ui-react'
import { Button, GridRowColumn, Modal, Text } from '../../BaseComponents'

const AccountDownloadModal = ({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) => {
  return (
    <Modal open={open} closeIcon>
      <Modal.Header>Account Download</Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text>
              Your account information is being prepared and should be available
              shortly. Once ready for download, you will receive an email
              notification with a link to download your information.
            </Text>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={close}>Close</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default AccountDownloadModal
