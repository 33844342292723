import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Divider, Grid, Image } from 'semantic-ui-react'

import './enroll.scss'

import {
  selectPayrollCompany,
  selectPayrollProfile,
} from '../payroll.selectors'
import {
  GEP_ENROLL_PATHS,
  GEP_PER_PERSON_PRICE,
  GEP_SUB_PRICE,
  getResumeLink,
  useOnboardingSteps,
} from '../helpers'
import PayrollSetupList from './PayrollSetupList'
import { putApproveDemoCompany } from '../payrollActions'
import {
  Card,
  Text,
  Button,
  GridRowColumn,
} from '../../../components/BaseComponents'
import { useAnalyticsView } from '../../Amplitude'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useReselector } from '../../../utils/sharedHooks'

export const GEPPriceCard = ({ footerText }: { footerText: string }) => (
  <Card backgroundColor="stone40" style={{ minHeight: 230 }}>
    <Grid>
      <GridRowColumn>
        <Text as="eyebrow" textAlign="center" color="darkGray">
          Pricing
        </Text>
      </GridRowColumn>
      <GridRowColumn short>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                }}
              >
                <Text as="display" color="green" style={{ paddingRight: 5 }}>
                  ${GEP_SUB_PRICE}{' '}
                </Text>
                <Text color="green" as="h3">
                  /mo.
                </Text>
              </div>
              <Text as="bodySm" textAlign="center">
                Base subscription cost
              </Text>
            </Grid.Column>

            <Grid.Column width={8}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                }}
              >
                <Text as="h1" color="green">
                  +{' '}
                </Text>
                <Text as="display" color="green" style={{ paddingRight: 5 }}>
                  ${GEP_PER_PERSON_PRICE}{' '}
                </Text>
                <Text color="green" as="h3">
                  /mo.
                </Text>
              </div>
              <Text as="bodySm" textAlign="center">
                Per employee, including yourself
              </Text>
            </Grid.Column>
          </Grid.Row>
          <GridRowColumn>
            <Text as="bodySm" textAlign="center">
              {footerText}
            </Text>
          </GridRowColumn>
        </Grid>
      </GridRowColumn>
    </Grid>
  </Card>
)

const PayrollEnroll = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const payrollProfile = useReselector(selectPayrollProfile)
  const payrollCompany = useReselector(selectPayrollCompany)
  const steps = useOnboardingSteps()
  const pageView = useAnalyticsView()

  const approveCompany = useCallback(
    () => putApproveDemoCompany()(dispatch),
    [dispatch]
  )

  useEffect(() => {
    // If company is approved auto navigate to run payroll page
    if (payrollCompany?.company_status === 'Approved') {
      navigate('/payroll/run_payroll')
    }
    pageView('payroll enroll')
  }, [navigate, payrollCompany?.company_status, pageView])

  if (payrollProfile?.onboardingCompleted) {
    return (
      <Container>
        <Card>
          <Grid>
            <GridRowColumn>
              <Text as="h1">Welcome to Heard Payroll!</Text>
            </GridRowColumn>
            <Divider />
            <Grid.Row>
              <Grid.Column width={6}>
                <PayrollSetupList />
              </Grid.Column>
              <Grid.Column width={1} />
              <Grid.Column width={8}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Text as="h2" textAlign="center">
                        You&apos;re all set up! We&apos;ll notify you when
                        payroll is ready to be run.
                      </Text>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={4} />
                    <Grid.Column width={8}>
                      <Image src="https://heard-images.s3.us-west-1.amazonaws.com/assets/payroll_rocket.png" />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            {process.env.VITE_IS_PROD === 'false' && (
              <GridRowColumn width={6}>
                <Button onClick={approveCompany} fullWidth>
                  Approve demo company
                </Button>
              </GridRowColumn>
            )}
            <Grid.Row />
            <Grid.Row />
            <Grid.Row />
          </Grid>
        </Card>
      </Container>
    )
  }

  const nextPageLink = getResumeLink(steps)
  return (
    <Container>
      <Card>
        <Grid>
          <Grid.Row />
          <Grid.Row />
          <Grid.Row />
          <GridRowColumn
            spacer={6}
            width={4}
            columnStyle={{ display: 'flex', justifyContent: 'center' }}
          >
            <Image
              src="https://heard-images.s3.us-west-1.amazonaws.com/assets/payroll_phone.png"
              style={{ width: 124 }}
            />
          </GridRowColumn>
          <Grid.Row />
          <GridRowColumn spacer={2} width={12}>
            <Text as="display" color="forest" textAlign="center">
              Simplify your finances with Heard Payroll
            </Text>
          </GridRowColumn>
          <GridRowColumn spacer={2} width={12}>
            <Text as="bodyLg" textAlign="center">
              Run payroll for your employees, contractors and even yourself— all
              within Heard’s platform.
            </Text>
          </GridRowColumn>
          <Grid.Row>
            <Grid.Column width={2} />
            <Grid.Column width={6}>
              <GEPPriceCard footerText="(This total will be added to your Heard subscription)." />
            </Grid.Column>
            <Grid.Column width={6}>
              <Card type="section" style={{ minHeight: 230 }}>
                <Text as="eyebrow" textAlign="center" color="darkGray">
                  What you&apos;ll need
                </Text>
                <ul style={{ paddingLeft: 10, paddingTop: 10 }}>
                  <li>
                    <Text as="bodyLg">Your business information</Text>
                  </li>
                  <li>
                    <Text as="bodyLg">
                      Access to your personal and business bank accounts
                    </Text>
                  </li>
                </ul>
              </Card>
            </Grid.Column>
          </Grid.Row>
          <GridRowColumn spacer={6} width={4}>
            <Button onClick={() => navigate(nextPageLink)} fullWidth>
              {nextPageLink === GEP_ENROLL_PATHS.overview
                ? 'Add Payroll'
                : 'Resume Enrollment'}
            </Button>
          </GridRowColumn>
          <Grid.Row />
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollEnroll
