import { createSelector } from 'reselect'

import { AnnualTaxFilingStepStatus } from '../constants'
import {
  selectDraftReviewStatusForForm,
  selectFileExtensionStatusForYear,
  selectFileNecStatusByFormId,
  selectFileNecStatusByYear,
  selectFormTQStatus,
  selectTaxSeasonKickoffStatusByFormId,
  selectTaxSeasonKickoffStatusByYear,
  selectShowFileExtensionByFormId,
  selectShowFileExtensionForYear,
  selectShowFileExtensionStatusByFormId,
  selectShowNeedContractorFilingByFormId,
  selectShowNeedContractorFilingByYear,
  selectTaxPreparationStatusForForm,
} from '../annualTaxFilings.selector'
import { Colors } from '../../../../styles/theme'
import {
  selectAllTQTasksExceptUploadDocumentsAreCompleteForYear,
  selectDocumentsUploadStepCompleteForYear,
  selectQuarterlyPaymentsStepComplete,
  selectReviewBooksInitialCompletedForYear,
} from '../TaxChecklist/Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.selector'
import {
  AnnualTaxFilingForm,
  AnnualTaxFilingFormQuestionnaireStatus,
  TaxfyleJobStatus,
  TaxfyleJobStep,
} from '../annualTaxFilingForms.slice'
import { selectFilingFormsForYear } from '../annualTaxFilingForms.selector'
import { FORM_1040_TYPE, FORM_1120_S_TYPE } from '../Questionnaires/constants'
import { selectUserBooksLockedForYear } from '../../../../reducers/auth/user.selectors'
import { selectK1IsUploadedOrNotNeeded } from '../TaxChecklist/taxChecklist.selectors'
import { selectTaxPaymentLoggedForYearAndFormId } from '../annualTaxOutcome.slice'

export enum FilingFormStep {
  tq,
  taxPrep,
  draftReview,
  returnFiling,
}

export enum FormFilingStatusDetailedStep {
  submittedAndUnclaimed = 'submittedAndUnclaimed',
  claimedForReview = 'claimedForReview',
  unreadMessages = 'unreadMessages',
  inProgress = 'inProgress',
  readyForReview = 'readyForReview',
  signatureRequested = 'signatureRequested',
  filingReturn = 'filingReturn',
  filedNeedsPayment = 'filedNeedsPayment', // only used for 1040
  filedUploadK1 = 'filedUploadK1', // only used for 1120s 2-form filer
  completed = 'completed',
}

export enum TaxChecklistSectionStatusStep {
  enter_qte_payments = 'enter_qte_payments',
  close_your_books = 'close_your_books',
  continue_tax_questionnaire = 'continue_tax_questionnaire',
  upload_documents = 'upload_documents',
  bookkeeper_review = 'bookkeeper_review',
  approve_and_submit = 'approve_and_submit',
}

export type TaxFormDetailedStatusStep =
  | FormFilingStatusDetailedStep
  | TaxChecklistSectionStatusStep
  | null

export const selectCurrentStepForForm = createSelector(
  selectFormTQStatus,
  selectTaxPreparationStatusForForm,
  selectDraftReviewStatusForForm,
  (tqStatus, taxPrepStatus, draftReviewStatus) => {
    if (tqStatus !== AnnualTaxFilingStepStatus.completeLocked) {
      return FilingFormStep.tq
    } else if (taxPrepStatus !== AnnualTaxFilingStepStatus.completeLocked) {
      return FilingFormStep.taxPrep
    } else if (draftReviewStatus !== AnnualTaxFilingStepStatus.completeLocked) {
      return FilingFormStep.draftReview
    }

    return FilingFormStep.returnFiling
  }
)

const taxfyleJobStatusToFilingFormDetailedStep = {
  [TaxfyleJobStatus.underConstruction]:
    FormFilingStatusDetailedStep.submittedAndUnclaimed,
  [TaxfyleJobStatus.infoGathering]:
    FormFilingStatusDetailedStep.submittedAndUnclaimed,
  [TaxfyleJobStatus.unclaimed]:
    FormFilingStatusDetailedStep.submittedAndUnclaimed,
  [TaxfyleJobStatus.claimed]: FormFilingStatusDetailedStep.claimedForReview,
  [TaxfyleJobStatus.idle]: FormFilingStatusDetailedStep.inProgress,
  [TaxfyleJobStatus.onHold]: FormFilingStatusDetailedStep.inProgress,
  [TaxfyleJobStatus.completed]: FormFilingStatusDetailedStep.completed,
  [TaxfyleJobStatus.canceled]: null,
  '': null,
}

const taxfyleJobStepToFilingFormDetailedStep = {
  [TaxfyleJobStep.jobStarted]: FormFilingStatusDetailedStep.inProgress,
  [TaxfyleJobStep.openItems]: FormFilingStatusDetailedStep.unreadMessages,
  [TaxfyleJobStep.draftRejected]: FormFilingStatusDetailedStep.inProgress,
  [TaxfyleJobStep.draftInReview]: FormFilingStatusDetailedStep.readyForReview,
  [TaxfyleJobStep.draftApproved]:
    FormFilingStatusDetailedStep.signatureRequested,
  [TaxfyleJobStep.authorizationRequested]:
    FormFilingStatusDetailedStep.signatureRequested,
  [TaxfyleJobStep.authorizationSigned]:
    FormFilingStatusDetailedStep.filingReturn,
  [TaxfyleJobStep.returnFiled]: FormFilingStatusDetailedStep.filedNeedsPayment,
  '': null,
}

const getTaxChecklistSectionStatusStepForForm = ({
  form,
  isTwoFormFiler,
  booksComplete,
  tqQuestionsComplete,
  documentUploadComplete,
  booksLockedForUser,
  qteLogged,
}: {
  form: AnnualTaxFilingForm
  isTwoFormFiler: boolean
  booksComplete: boolean
  tqQuestionsComplete: boolean
  documentUploadComplete: boolean
  booksLockedForUser: boolean
  qteLogged: boolean
}) => {
  if (form.formType.name === FORM_1040_TYPE) {
    if (!qteLogged) {
      return TaxChecklistSectionStatusStep.enter_qte_payments
    } else if (!isTwoFormFiler && !booksComplete) {
      return TaxChecklistSectionStatusStep.close_your_books
    } else if (!tqQuestionsComplete) {
      return TaxChecklistSectionStatusStep.continue_tax_questionnaire
    } else if (!documentUploadComplete) {
      return TaxChecklistSectionStatusStep.upload_documents
    } else if (!isTwoFormFiler && !booksLockedForUser) {
      return TaxChecklistSectionStatusStep.bookkeeper_review
    }
    return TaxChecklistSectionStatusStep.approve_and_submit
  }

  if (form.formType.name === FORM_1120_S_TYPE) {
    if (!booksComplete) {
      return TaxChecklistSectionStatusStep.close_your_books
    } else if (!tqQuestionsComplete) {
      return TaxChecklistSectionStatusStep.continue_tax_questionnaire
    } else if (!documentUploadComplete) {
      return TaxChecklistSectionStatusStep.upload_documents
    } else if (!booksLockedForUser) {
      return TaxChecklistSectionStatusStep.bookkeeper_review
    }
    return TaxChecklistSectionStatusStep.approve_and_submit
  }
  return null
}

export const selectCurrentDetailedStepForForm = createSelector(
  selectFilingFormsForYear,
  selectReviewBooksInitialCompletedForYear,
  selectAllTQTasksExceptUploadDocumentsAreCompleteForYear,
  selectDocumentsUploadStepCompleteForYear,
  selectUserBooksLockedForYear,
  selectQuarterlyPaymentsStepComplete,
  selectK1IsUploadedOrNotNeeded,
  selectTaxPaymentLoggedForYearAndFormId,
  (_: unknown, _year: string, formId: number | string) => formId,
  (
    filingForms,
    booksComplete,
    tqQuestionsComplete,
    documentUploadComplete,
    booksLockedForUser,
    qteLogged,
    k1IsUploaded,
    taxPaymentLogged,
    formId
  ) => {
    const isTwoFormFiler = filingForms.length > 1
    const form = filingForms.find((form) => form.id === Number(formId))
    const is1040 = form?.formType.name === FORM_1040_TYPE
    const submitted =
      form?.questionnaireResponseStatus ===
      AnnualTaxFilingFormQuestionnaireStatus.submitted
    if (!form) {
      return null
    }
    if (!submitted) {
      // No job step means the checklist needs to be completed
      return getTaxChecklistSectionStatusStepForForm({
        form,
        isTwoFormFiler,
        booksComplete,
        tqQuestionsComplete,
        documentUploadComplete,
        booksLockedForUser,
        qteLogged,
      })
    }
    // handle case where it's been submitted, but not created in taxfyle yet
    if (!form.taxfyleJob) {
      return FormFilingStatusDetailedStep.submittedAndUnclaimed
    }

    // Prioritize the returnFiled statuses over everything else
    if (
      form.taxfyleJob.jobStep === TaxfyleJobStep.returnFiled ||
      form.taxfyleJob.jobStatus === TaxfyleJobStatus.completed
    ) {
      // If the form has been filed, there are additional checks to determine the status
      if (!taxPaymentLogged && is1040) {
        return FormFilingStatusDetailedStep.filedNeedsPayment
      } else if (isTwoFormFiler && !k1IsUploaded) {
        return FormFilingStatusDetailedStep.filedUploadK1
      }
      return FormFilingStatusDetailedStep.completed
    }

    // Next priority is showing readyForReview if that's the current step
    const stepMatch =
      taxfyleJobStepToFilingFormDetailedStep[form.taxfyleJob.jobStep ?? '']
    if (stepMatch === FormFilingStatusDetailedStep.readyForReview) {
      return stepMatch
    } else if (form.taxfyleJob.hasUnreadMessages) {
      // final 'override' priority is showing unread messages status if there are unread messages
      return FormFilingStatusDetailedStep.unreadMessages
    }
    // Else return current status or step for (step takes precedence)
    const statusMatch =
      taxfyleJobStatusToFilingFormDetailedStep[form.taxfyleJob.jobStatus ?? '']
    return stepMatch ?? statusMatch
  }
)

export const selectIsTskComplete = createSelector(
  selectTaxSeasonKickoffStatusByFormId,
  (taxSeasonKickoffStatus) =>
    [
      AnnualTaxFilingStepStatus.completeLocked,
      AnnualTaxFilingStepStatus.completeUnlocked,
    ].includes(taxSeasonKickoffStatus)
)

export const selectIsCurveGreen = createSelector(
  selectTaxSeasonKickoffStatusByYear,
  selectShowNeedContractorFilingByYear,
  selectFileNecStatusByYear,
  selectShowFileExtensionForYear,
  selectFileExtensionStatusForYear,
  (
    tskStatus,
    showContractorFiling,
    fileNecStatus,
    showFileExtension,
    fileExtensionStatus
  ) =>
    [
      AnnualTaxFilingStepStatus.completeLocked,
      AnnualTaxFilingStepStatus.completeUnlocked,
    ].includes(tskStatus) &&
    (!showContractorFiling ||
      fileNecStatus === AnnualTaxFilingStepStatus.completeLocked) &&
    (!showFileExtension ||
      fileExtensionStatus === AnnualTaxFilingStepStatus.completeLocked)
)

export const selectCurvedLineStyles = createSelector(
  selectShowNeedContractorFilingByYear,
  selectShowFileExtensionForYear,
  (showContractorFiling, showFileExtension) => {
    if (showContractorFiling && showFileExtension) {
      return { left: '40%' }
    } else if (showContractorFiling || showFileExtension) {
      return { left: '30%' }
    }

    return { left: '16%' }
  }
)

const selectPercentageForm1 = createSelector(
  selectShowNeedContractorFilingByFormId,
  selectShowFileExtensionByFormId,
  selectIsTskComplete,
  selectFileNecStatusByFormId,
  selectShowFileExtensionStatusByFormId,
  selectCurrentStepForForm,
  (
    showContractorFiling,
    showFileExtension,
    isTskComplete,
    fileNecStatus,
    fileExtensionStatus,
    currentStepForm1
  ) => {
    // Progress is based on number of steps - 1 (gaps between steps)
    let gapsInSteps = 4
    let completeSteps = 0

    // Add to number of steps if additional steps needed
    if (showContractorFiling) {
      gapsInSteps++
    }
    if (showFileExtension) {
      gapsInSteps++
    }

    if (!isTskComplete) {
      return 0
    }

    completeSteps++

    if (showContractorFiling) {
      if (fileNecStatus !== AnnualTaxFilingStepStatus.completeLocked) {
        return (completeSteps / gapsInSteps) * 100
      } else {
        completeSteps++
      }
    }

    if (showFileExtension) {
      if (fileExtensionStatus !== AnnualTaxFilingStepStatus.completeLocked) {
        return (completeSteps / gapsInSteps) * 100
      } else {
        completeSteps++
      }
    }

    if (currentStepForm1 === FilingFormStep.tq) {
      return (completeSteps / gapsInSteps) * 100
    } else if (currentStepForm1 === FilingFormStep.taxPrep) {
      return ((completeSteps + 1) / gapsInSteps) * 100
    } else if (currentStepForm1 === FilingFormStep.draftReview) {
      return ((completeSteps + 2) / gapsInSteps) * 100
    }

    return 100
  }
)

const selectPercentageForm2 = createSelector(
  selectShowNeedContractorFilingByFormId,
  selectShowFileExtensionByFormId,
  selectIsTskComplete,
  selectFileNecStatusByFormId,
  selectShowFileExtensionStatusByFormId,
  selectCurrentStepForForm,
  (
    showContractorFiling,
    showFileExtension,
    isTskComplete,
    fileNecStatus,
    fileExtensionStatus,
    currentStepForm2
  ) => {
    if (
      !isTskComplete ||
      (showContractorFiling &&
        fileNecStatus !== AnnualTaxFilingStepStatus.completeLocked) ||
      (showFileExtension &&
        fileExtensionStatus !== AnnualTaxFilingStepStatus.completeLocked)
    ) {
      return 0
    } else if (currentStepForm2 === FilingFormStep.tq) {
      return 15
    } else if (currentStepForm2 === FilingFormStep.taxPrep) {
      return 40
    } else if (currentStepForm2 === FilingFormStep.draftReview) {
      return 70
    }

    return 100
  }
)

export const selectDividingLineStyles = createSelector(
  selectShowNeedContractorFilingByFormId,
  selectShowFileExtensionByFormId,
  selectPercentageForm1,
  (showContractorFiling, showFileExtension, percentageForm1) => {
    const background = `linear-gradient(to right, ${Colors.green} 0%,${Colors.green} ${percentageForm1}%,${Colors.lightGray} ${percentageForm1}%,${Colors.lightGray} 100%)`
    if (showContractorFiling && showFileExtension) {
      return { width: '86%', background, left: '7%' }
    } else if (showContractorFiling || showFileExtension) {
      return { width: '84%', background, left: '8%' }
    }

    return { width: '80%', background, left: '9.8%' }
  }
)

export const selectDividingLine2Styles = createSelector(
  selectShowNeedContractorFilingByFormId,
  selectShowFileExtensionByFormId,
  selectPercentageForm2,
  (showContractorFiling, showFileExtension, percentageForm2) => {
    const background = `linear-gradient(to right, ${Colors.green} 0%,${Colors.green} ${percentageForm2}%,${Colors.lightGray} ${percentageForm2}%,${Colors.lightGray} 100%)`
    if (showContractorFiling && showFileExtension) {
      return { left: '41%', width: '52%', background }
    } else if (showContractorFiling || showFileExtension) {
      return { left: '31%', width: '60.5%', background }
    }

    return { left: '17.3%', width: '72.5%', background }
  }
)
