import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { keyBy } from 'lodash'

import { fetchIfNeededWrapper, fetchWrapper } from '../../../reducers/fetch'

export enum SafeHarborType {
  calculated = 'calculated',
  ES = 'ES',
}

export interface UserTaxEstimate {
  id: number
  userId: number
  type: string
  // In YYYY-Q format
  taxQuarter: string
  quarterPaid: boolean | null
  dueDate: string | null
  status: string
  paidAt: string | null
  filingState: string | null
  notifiedAt: string | null
  noTaxPaymentNeeded: boolean | null
  estimateInCents: number
  amountPaidInCents: number | null
  minimumPaymentInCents?: number
  pytlInCents?: number
  userDocumentId?: number
  safeHarborType: SafeHarborType | null
  createdAt: string
  updatedAt: string
}

export interface UserTaxEstimateEntities {
  userTaxEstimates?: { [key: string]: UserTaxEstimate }
}

export interface UserTaxEstimateState {
  [key: string]: UserTaxEstimate
}

const userTaxEstimatesSlice = createSlice({
  name: 'userTaxEstimates',
  initialState: {} as UserTaxEstimateState,
  reducers: {
    getUserTaxEstimates: (
      state,
      action: PayloadAction<{
        [key: string]: UserTaxEstimate
      }>
    ) => ({ ...state, ...action.payload }),
    updateUserTaxEstimate: (state, action: PayloadAction<UserTaxEstimate>) => {
      state[action.payload.id] = action.payload
    },
  },
})

export default userTaxEstimatesSlice.reducer

// Actions
const { getUserTaxEstimates, updateUserTaxEstimate } =
  userTaxEstimatesSlice.actions

export const ALWAYS_FETCH_USER_TAX_ESTIMATES_KEY =
  'ALWAYS_FETCH_USER_TAX_ESTIMATES_KEY'
export const fetchUserTaxEstimates = () =>
  fetchWrapper({
    fetchKey: ALWAYS_FETCH_USER_TAX_ESTIMATES_KEY,
    defaultErrorMessage: 'Error fetching all user tax estimates',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<UserTaxEstimate[]>(
        '/finances/api/v1/tax_estimates'
      )

      dispatch(getUserTaxEstimates(keyBy(json.data, 'id')))

      return json.data
    },
  })

export const FETCH_TAX_ESTIMATES_KEY = 'FETCH_TAX_ESTIMATES_KEY'
export const fetchUserTaxEstimatesIfNeeded = () =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_TAX_ESTIMATES_KEY,
    defaultErrorMessage: 'Error fetching all user tax calculations',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<UserTaxEstimate[]>(
        '/finances/api/v1/tax_estimates'
      )

      dispatch(getUserTaxEstimates(keyBy(json.data, 'id')))

      return json.data
    },
  })

// UserId is required if admin user
// Paid at sent as number but received as string
type UpdateTaxEstimatePayload = Partial<
  Omit<UserTaxEstimate, 'paidAt'> & { paidAt: number | null }
>

export const UPDATE_USER_TAX_ESTIMATES_KEY = 'UPDATE_USER_TAX_ESTIMATES_KEY'
export const updateUserTaxEstimates = (
  id: number,
  data: UpdateTaxEstimatePayload
) =>
  fetchWrapper({
    fetchKey: UPDATE_USER_TAX_ESTIMATES_KEY,
    defaultErrorMessage: 'There was an error updating user tax estimates.',
    fetchFunction: async (dispatch) => {
      const json = await axios.post<UserTaxEstimate>(
        `/finances/api/v1/tax_estimates/${id}`,
        data
      )

      dispatch(updateUserTaxEstimate(json.data))
      return json.data
    },
  })

// Admin actions
export const FETCH_USER_TAX_ESTIMATES_KEY = (userId?: number) =>
  `FETCH_USER_TAX_ESTIMATES_KEY_${userId}`
export const fetchSingleUserTaxEstimates = (userId: number) =>
  fetchWrapper({
    fetchKey: FETCH_USER_TAX_ESTIMATES_KEY(userId),
    defaultErrorMessage: 'There was an error fetching tax estimates for user.',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<UserTaxEstimate[]>(
        `/finances/api/v1/admin/${userId}/user_tax_estimates`
      )

      dispatch(getUserTaxEstimates(keyBy(json.data, 'id')))

      return json.data
    },
  })

/*
  Fetch all user tax estimates
*/

export const fetchAllUserTaxEstimates = () =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error fetching tax estimates.',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<UserTaxEstimate[]>(
        '/finances/api/v1/admin/user_tax_estimates'
      )
      dispatch(getUserTaxEstimates(keyBy(json.data, 'id')))
      return json.data
    },
  })

export const adminUpdateUserTaxEstimate = (
  estimateId: number,
  data: Partial<UserTaxEstimate>
) =>
  fetchWrapper({
    defaultErrorMessage: 'Unable to update user tax estimate.',
    fetchFunction: (dispatch) =>
      axios
        .post<UserTaxEstimate>(
          `/finances/api/v1/admin/user_tax_estimates/${estimateId}`,
          data
        )
        .then((json) => {
          dispatch(updateUserTaxEstimate(json.data))
          return json.data
        }),
  })

/*
  Send Notify User
*/

export const notifyUserTaxEstimates = (userId: number, taxQuarter: string) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error notifying user of tax estimates.',
    fetchFunction: async (dispatch) => {
      const json = await axios.post<{
        status: string
        estimates: UserTaxEstimate[]
      }>('/finances/api/v1/admin/user_tax_estimates/notify', {
        userId,
        taxQuarter,
      })

      dispatch(getUserTaxEstimates(keyBy(json.data.estimates, 'id')))

      return json.data
    },
  })
