import { createContext, useState, ReactNode } from 'react'

export enum UNDO_TRANSACTION_TYPES {
  DELETE = 'delete',
  DUPLICATE = 'duplicate',
}

interface UndoTransactionContextType {
  undoTransactionId: number
  setUndoTransactionId: (undoTransactionId: number) => void
  undoTransactionType: string
  setUndoTransactionType: (undoTransactionType: string) => void
}

export const UndoTransactionContext = createContext<UndoTransactionContextType>(
  {
    undoTransactionId: NaN,
    setUndoTransactionId: () => {},
    undoTransactionType: '',
    setUndoTransactionType: () => {},
  }
)

export const UndoTransactionContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [undoTransactionId, setUndoTransactionId] = useState(NaN)
  const [undoTransactionType, setUndoTransactionType] = useState('')

  return (
    <UndoTransactionContext.Provider
      value={{
        undoTransactionId,
        setUndoTransactionId,
        undoTransactionType,
        setUndoTransactionType,
      }}
    >
      {children}
    </UndoTransactionContext.Provider>
  )
}
