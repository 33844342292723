import { useEffect, useMemo } from 'react'
import { Container, Grid } from 'semantic-ui-react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { capitalize } from 'lodash'

import {
  FETCH_CONTRACTORS_KEY,
  FETCH_EMPLOYEES_KEY,
  POST_ACCEPT_COMPANY_TOS_KEY,
  POST_MIGRATE_PAYROLL_KEY,
  postAcceptCompanyTOS,
  postMigratePayroll,
} from '../payrollActions'
import PayrollLoading from '../RunPayroll/PayrollLoading'
import {
  Alert,
  Button,
  Card,
  GridRowColumn,
} from '../../../components/BaseComponents'
import PayrollError from '../PayrollError'
import { useReselector } from '../../../utils/sharedHooks'
import { getFetchError, selectIsFetchingForKeys } from '../../../reducers/fetch'
import { useAppDispatch } from '../../../utils/typeHelpers'

const FETCH_KEY_LIST = [
  POST_MIGRATE_PAYROLL_KEY,
  FETCH_EMPLOYEES_KEY,
  FETCH_CONTRACTORS_KEY,
  POST_ACCEPT_COMPANY_TOS_KEY,
]

const ProcessMigration = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const isFetching = useReselector(selectIsFetchingForKeys, FETCH_KEY_LIST)
  const migrationError = useReselector(getFetchError, POST_MIGRATE_PAYROLL_KEY)

  const errorMessage = useMemo(() => {
    if (!migrationError) {
      return null
    } else if (migrationError.message.startsWith('tierError')) {
      const currentTier = migrationError.message.split(':')[1] || ''
      return (
        <Alert type="error">
          It looks like you are on the {capitalize(currentTier)} Gusto plan.
          Currently, these plans are not supported by Heard Payroll. Please
          reach out to <b>payroll@joinheard.com</b> for assistance.
        </Alert>
      )
      // We can't check payment speeds via api before migration so we depend on Gusto's internal errors.
      // We want to display a more helpful error message though so doing a string compare
    } else if (migrationError.message.toLowerCase().includes('1 day')) {
      return (
        <Alert type="error">
          Oops, something went wrong. It looks like you have next-day payments
          set up. Currently, this feature is not supported by Heard Payroll.
          Please reach out to <b>payroll@joinheard.com</b> for assistance.
        </Alert>
      )
    }

    return <PayrollError fetchKey={POST_MIGRATE_PAYROLL_KEY} />
  }, [migrationError])

  useEffect(() => {
    const migrate = async () => {
      // This page shouldn't be navigated to without the code param
      if (!code) {
        return
      }
      const res = await postMigratePayroll({ code })(dispatch)
      if (res) {
        // User accepted TOS before this page but company wasn't created so do it here once migration completed
        await postAcceptCompanyTOS()(dispatch)
        // Navigate to Dashboard
        navigate('/payroll/run_payroll?migrated=true')
      }
    }

    migrate()
  }, [code, dispatch, navigate])

  return (
    <Container>
      <Card>
        {isFetching && <PayrollLoading text="Fetching your data..." />}
        {migrationError && (
          <Grid>
            <GridRowColumn>
              <Button
                variant="link"
                onClick={() => navigate('/payroll/migrate')}
              >
                Go back
              </Button>
            </GridRowColumn>
            {errorMessage}
          </Grid>
        )}
      </Card>
    </Container>
  )
}

export default ProcessMigration
