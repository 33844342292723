import Moment from 'react-moment'
import CurrencyFormatLabel from './CurrencyFormatLabel'
import TransactionCategoryLabel from './TransactionCategoryLabel'

import { centsToDollars } from '../../utils/currencyHelpers'
import { Transaction } from '../../reducers/admin/allTransactions.slice'
import { Table } from '../BaseComponents'
import { DATE_FORMATS } from '../../utils/dateHelpers'

const UserViewOnlyTransactionRow = ({
  transaction,
}: {
  transaction: Transaction
}) => (
  <Table.Row>
    <Table.Cell>
      <Moment format={DATE_FORMATS.DISPLAY_SHORT} utc>
        {transaction.date}
      </Moment>
    </Table.Cell>
    <Table.Cell>{transaction.description}</Table.Cell>
    <Table.Cell textAlign="right">
      <CurrencyFormatLabel value={centsToDollars(transaction?.amountInCents)} />
    </Table.Cell>
    <Table.Cell>
      <TransactionCategoryLabel transaction={transaction} />
    </Table.Cell>
  </Table.Row>
)

export default UserViewOnlyTransactionRow
