import { useAppDispatch } from '../../utils/typeHelpers'
import { Divider, Grid, List, Image } from 'semantic-ui-react'
import {
  Alert,
  Card,
  GridRowColumn,
  Icon,
  Text,
} from '../../components/BaseComponents'
import { useReselector } from '../../utils/sharedHooks'
import {
  getCurrentUser,
  selectIsCurrentUserScorp,
} from '../../selectors/user.selectors'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  formatCurrency,
  formatCurrencyFromCents,
} from '../../utils/currencyHelpers'
import { fetchStripePayrollPrices } from '../../constants/pricingConstants'
import { makeGridConfig } from '../../components/BaseComponents/Grid'
import UserCancellationFlowFooter from './UserCancellationFlowFooter'
import {
  CANCELLATION_FLOW_STEP,
  useGetCostComparisonPageData,
  useSelfServiceCancellationSteps,
} from './helpers'
import { useCallback, useEffect } from 'react'
import {
  CancellationStepIdentifier,
  updateAutomaticCancellationAnalyticsRecord,
} from './userCancellation.slice'
import { isEligibleForDiscount } from './userCancellation.selectors'
import {
  submitCancelStripeSubscriptionAtPeriodEnd,
  SUMBIT_CANCEL_STRIPE_SUBSCRIPTION_AT_PERIOD_END_KEY,
} from '../../actions/settings/billingActions'
import { sendUserCancellationMessageZap } from '../../actions/zapierActions'
import {
  selectErrorsForKeys,
  selectIsFetchingForKeys,
} from '../../reducers/fetch'
import { useLocation } from 'react-router-dom'
import { useIsDeviceWidth, DeviceWidth } from '../../utils/deviceWidthHelpers'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../OpenFeature'

const HeardItem = ({ title }: { title: string }) => {
  return (
    <List.Item style={{ padding: '12px 0', marginBottom: 20 }}>
      <List.Icon verticalAlign="middle">
        <Icon
          style={{ display: 'inline' }}
          color="green"
          icon={solid('check')}
        />
      </List.Icon>
      <List.Content>
        <Text as="bodySm">{title}</Text>
      </List.Content>
    </List.Item>
  )
}

const AlternativeItem = ({
  title,
  price,
  cadence,
}: {
  title: string
  price: string
  cadence?: string
}) => {
  return (
    <List.Item style={{ marginTop: 4, marginBottom: 25 }}>
      <List.Content>
        <Grid verticalAlign="middle">
          <Grid.Column width={10} verticalAlign="top">
            <Text as="bodySm">{title}</Text>
          </Grid.Column>
          <Grid.Column width={6} textAlign="right">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'end',
                justifyContent: 'flex-end',
              }}
            >
              <Text style={{ fontSize: 22, fontWeight: 600 }}>{price}</Text>
              {cadence && (
                <Text as="bodyMd" style={{ marginBottom: 2 }}>
                  /{cadence}
                </Text>
              )}
            </div>
          </Grid.Column>
        </Grid>
      </List.Content>
    </List.Item>
  )
}

const CostComparison = ({ recordId }: { recordId?: number }) => {
  const dispatch = useAppDispatch()

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const location = useLocation()

  const isLoading = useReselector(selectIsFetchingForKeys, [
    SUMBIT_CANCEL_STRIPE_SUBSCRIPTION_AT_PERIOD_END_KEY,
  ])

  const error = useReselector(selectErrorsForKeys, [
    SUMBIT_CANCEL_STRIPE_SUBSCRIPTION_AT_PERIOD_END_KEY,
  ])

  const enableDiscounts = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableCancellationDiscounts,
    false
  )

  const eligibleForDiscount = useReselector(
    isEligibleForDiscount,
    enableDiscounts
  )
  const currentUser = useReselector(getCurrentUser)

  useEffect(() => {
    const updateRecord = async (recordId?: number) => {
      if (recordId) {
        await updateAutomaticCancellationAnalyticsRecord(recordId, {
          finalStepReached: CancellationStepIdentifier.COST_COMPARISON,
        })(dispatch)
      }
    }
    updateRecord(recordId)
  }, [dispatch, recordId])

  const isScorp = useReselector(selectIsCurrentUserScorp)

  const goToNextStep = useSelfServiceCancellationSteps(
    CANCELLATION_FLOW_STEP.costComparison
  )

  const payrollPrices = fetchStripePayrollPrices()

  const onContinue = useCallback(async () => {
    if (!eligibleForDiscount) {
      const res = await submitCancelStripeSubscriptionAtPeriodEnd(
        location?.state?.cancellationDetails ?? {}
      )(dispatch)
      if (res) {
        sendUserCancellationMessageZap(currentUser, 'submit_cancel')
        goToNextStep()
      }
    } else {
      goToNextStep()
    }
  }, [dispatch, eligibleForDiscount, location, currentUser, goToNextStep])

  const data = useGetCostComparisonPageData(isScorp)
  return (
    <Grid>
      {error.length > 0 && <Alert type="error">{error[0].message}</Alert>}
      <GridRowColumn centerContent>
        <Text as="display2">Compare and save with Heard</Text>
      </GridRowColumn>
      <GridRowColumn centerContent {...makeGridConfig([10, 16], true)}>
        <Text as="bodyLg" textAlign="center">
          Paying for services a la carte adds up fast. Heard saves money by
          giving you everything you need all in one place.
        </Text>
      </GridRowColumn>
      <GridRowColumn>
        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} tablet={8} mobile={16} stretched>
              <Card backgroundColor="natural">
                <Text as="display2" color="green">
                  Heard
                </Text>
                <Text as="bodyXs" color="darkGray" style={{ marginTop: '5px' }}>
                  {data.subText}
                </Text>
                <Divider style={{ borderWidth: '2px' }} />
                <List>
                  <HeardItem title="Online access to bookkeeping software" />
                  <HeardItem title="Expert done-for-you monthly bookkeeping" />
                  <HeardItem title="Monthly financial insights and reports" />
                  <HeardItem title="Personalized federal and state quarterly tax estimates" />
                  <HeardItem title="Annual personal federal and state income tax returns" />
                  {isScorp && (
                    <HeardItem title="S corporation set up and compliance" />
                  )}
                  {isScorp && (
                    <HeardItem
                      title={`Payroll (${formatCurrency(payrollPrices.payrollBase.amountInDollarsPerMonth, { hideDecimalsIfZero: true })}/mo + ${formatCurrency(payrollPrices.payrollPerSeat.amountInDollars, { hideDecimalsIfZero: true })}/person add on)`}
                    />
                  )}
                </List>
                <div style={{ flex: 1 }} />
                <Divider style={{ borderWidth: '2px' }} />
                <Grid verticalAlign="bottom">
                  <Grid.Row>
                    <Grid.Column
                      verticalAlign="middle"
                      computer={4}
                      tablet={4}
                      mobile={16}
                    >
                      <Image
                        src="https://heard-images.s3.amazonaws.com/assets/piggy-bank-green.svg"
                        style={{
                          height: 80,
                          width: 80,
                          backgroundColor: 'transparent',
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column
                      verticalAlign="middle"
                      computer={12}
                      tablet={12}
                      mobile={16}
                    >
                      <Text
                        textAlign={isMobile ? 'left' : 'right'}
                        as="display"
                        color="green"
                      >
                        {data.currentSubscriptionPrice}/
                        {data.isMonthly ? 'mo' : 'year'}
                      </Text>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card>
            </Grid.Column>
            <Grid.Column
              computer={8}
              tablet={8}
              mobile={16}
              stretched
              style={isMobile ? { marginTop: 15 } : undefined}
            >
              <Card backgroundColor="natural">
                <Text as="display2" color="green">
                  Alternatives
                </Text>
                <Text as="bodyXs" color="darkGray" style={{ marginTop: '5px' }}>
                  {data.subText}
                </Text>
                <Divider style={{ borderWidth: '2px' }} />
                <List>
                  <AlternativeItem
                    title="Bookkeeping software"
                    price={formatCurrencyFromCents(
                      data.bookkeeping_software_monthly_fee_in_cents,
                      { hideDecimalsIfZero: true }
                    )}
                    cadence="mo"
                  />
                  <AlternativeItem
                    title="Done for you monthly bookkeeping"
                    price={formatCurrencyFromCents(
                      data.done_for_you_monthly_bookkeeping_monthly_fee_in_cents,
                      { hideDecimalsIfZero: true }
                    )}
                    cadence="mo"
                  />
                  <AlternativeItem
                    title="Annual personal tax return filing"
                    price={formatCurrencyFromCents(
                      data.annual_personal_tax_return_filing_yearly_fee_in_cents,
                      { hideDecimalsIfZero: true }
                    )}
                    cadence="year"
                  />
                  {isScorp && (
                    <AlternativeItem
                      title="Annual business tax return filing"
                      price={formatCurrencyFromCents(
                        data.annual_business_tax_return_filing_yearly_fee_in_cents,
                        { hideDecimalsIfZero: true }
                      )}
                      cadence="year"
                    />
                  )}
                  <AlternativeItem
                    title="Tax advisory with a local CPA"
                    price={formatCurrencyFromCents(
                      data.tax_advisory_with_local_cpa_hourly_fee_in_cents,
                      { hideDecimalsIfZero: true }
                    )}
                    cadence="hour"
                  />
                  {isScorp && (
                    <AlternativeItem
                      title="S corporation set up and compliance"
                      price={formatCurrencyFromCents(
                        data.s_corp_setup_and_compliance_in_cents,
                        { hideDecimalsIfZero: true }
                      )}
                    />
                  )}
                  {isScorp && (
                    <AlternativeItem
                      title="Payroll"
                      price={formatCurrencyFromCents(
                        data.payroll_monthly_fee_in_cents,
                        { hideDecimalsIfZero: true }
                      )}
                      cadence="mo"
                    />
                  )}
                </List>
                <div style={{ flex: 1 }} />
                <Divider style={{ borderWidth: '2px' }} />
                <Grid verticalAlign="bottom">
                  <GridRowColumn>
                    <Image
                      src="https://heard-images.s3.amazonaws.com/assets/piggy-bank-orange.svg"
                      style={{
                        height: 80,
                        width: 80,
                        backgroundColor: 'transparent',
                      }}
                    />
                  </GridRowColumn>
                </Grid>
              </Card>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <GridRowColumn />
          <GridRowColumn centerContent>
            <Text as="h1">Did you know?</Text>
          </GridRowColumn>
          <GridRowColumn centerContent {...makeGridConfig([10, 16], true)}>
            <Text as="bodyLg">
              Right now, you have a locked-in rate of{' '}
              {data.currentSubscriptionPrice}/{data.isMonthly ? 'mo' : 'year'}.
              If you decide to re-join later, you will have to pay an additional
              catch up bookkeeping fee in addition to any increase in rate.
            </Text>
          </GridRowColumn>
          <Grid.Row>
            <Grid.Column computer={8} tablet={8} mobile={16}>
              {data.currentRateCard}
            </Grid.Column>
            <Grid.Column
              computer={8}
              tablet={8}
              mobile={16}
              style={isMobile ? { marginTop: 15 } : undefined}
            >
              {data.newMemberRateCard}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </GridRowColumn>
      <UserCancellationFlowFooter
        stayLabel="Keep My Plan"
        continueLabel={
          eligibleForDiscount ? undefined : 'Finalize cancellation'
        }
        goToNextStep={onContinue}
        isLoading={isLoading}
      />
    </Grid>
  )
}

export default CostComparison
