import { useContext, useEffect } from 'react'
import { Grid } from 'semantic-ui-react'
import { GridRowColumn, Modal, Text, Button, Link } from '../../BaseComponents'
import { PlaidLinkContext } from './PlaidLinkContext'

export const PlaidErrorModal = () => {
  const { plaidError, clearPlaidError } = useContext(PlaidLinkContext)

  useEffect(() => {
    return () => {
      // clear plaid error on unmount
      clearPlaidError()
    }
  }, [clearPlaidError])

  return (
    <Modal open={Boolean(plaidError)} closeIcon onClose={clearPlaidError}>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text as="h2"> Plaid Connection Error </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>{plaidError?.message}</Text>
            {plaidError?.errorCode === 'ID_NOT_FOUND' && (
              <Text>
                Check out{' '}
                <Link href="https://plaid.com/trouble-connecting/" newPage>
                  some possible reasons
                </Link>{' '}
                for why this is happening and try adding the connection again.
              </Text>
            )}
            {plaidError?.errorCode === 'INTERNAL_SERVER_ERROR' && (
              <Text>
                In the meantime, check out{' '}
                <Link href="https://plaid.com/trouble-connecting/" newPage>
                  some possible reasons
                </Link>{' '}
                for why this is happening.
              </Text>
            )}
            <Text> </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Text>
              If this error persists, reach out to us via{' '}
              <Link to="/conversations">Conversations</Link>.
            </Text>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="secondary"
          style={{ marginRight: 8 }}
          onClick={clearPlaidError}
        >
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
