import { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import currency from 'currency.js'
import { findLastIndex } from 'lodash'

import {
  fetchAllCompanyLocations,
  fetchBankAccounts,
  fetchCompanyOnboardingStatus,
  fetchEmployeeWorkAddresses,
  postCreateEmployeeWorkAddress,
  putUpdateEmployeeWorkAddress,
} from './payrollActions'
import { PayrollProfile } from './payrollProfile.slice'
import { FlsaStatus } from './employeeJob.slice'
import { TAX_ENTITY_TYPES_TYPE } from '../Taxes/taxConstants'
import { DATE_FORMATS, getMomentBD, getOrdinal } from '../../utils/dateHelpers'
import {
  selectFilingLocationOrParsedAddress,
  selectMailingLocation,
  selectOtherLocations,
  selectPayrollCompany,
  selectPayrollCompanyLocations,
  selectPayrollProfile,
} from './payroll.selectors'
import { addCurrencyArray } from '../../utils/currencyHelpers'
import { useReselector } from '../../utils/sharedHooks'
import { useAppDispatch } from '../../utils/typeHelpers'
import {
  Gusto_BankAccount,
  Gusto_Company,
  Gusto_CompanyAddress,
  Gusto_CompanyIndustry,
  Gusto_CompanyOnboardingStatus,
  Gusto_Contractor,
  Gusto_EmployeeHomeAddress,
  Gusto_EmployeeJob,
  Gusto_EmployeePayrollCompensation,
  Gusto_EmployeeWorkAddress,
  Gusto_EntityType,
  Gusto_OnboardingStep,
  Gusto_PaymentConfig,
  Gusto_PaymentUnitEnum,
  Gusto_Payroll,
  Gusto_PaySchedule,
  Gusto_PayScheduleFrequency,
} from './generated_gusto_types'
import { EmployeeWithoutJob } from './employee.slice'

export const GEP_SUB_PRICE = 39
export const GEP_PER_PERSON_PRICE = 6

export const getContractorName = (
  contractor?: {
    first_name: string | null
    last_name: string | null
    business_name: string | null
    type: 'Individual' | 'Business' | undefined
  } | null,
  lastNameFirst?: boolean
) => {
  if (!contractor?.type) {
    return ''
  }

  if (contractor.type === 'Business') {
    return contractor.business_name
  }
  return lastNameFirst
    ? `${contractor.last_name || ''}, ${contractor.first_name || ''} `
    : `${contractor.first_name || ''} ${contractor.last_name || ''}`
}

export const getFrequencyText = ({
  frequency,
  day_1,
  day_2,
}: Gusto_PaySchedule) => {
  if (frequency === 'Monthly' && day_1) {
    return `${getOrdinal(day_1)} of every month`
  } else if (frequency === 'Twice per month' && day_1 && day_2) {
    return `${getOrdinal(day_1)} and ${getOrdinal(day_2)} of every month`
  }
  return frequency
}

// Used for lists of employees/contractors.  Jobs array only exists for employees
export function isEmployee(
  mem: EmployeeWithoutJob | Gusto_Contractor
): mem is EmployeeWithoutJob {
  return 'jobs' in mem
}

export interface GeneratedOnboardingStep {
  key?: string
  text: string
  complete: boolean
  paths: string[]
}

const checkComplete = (
  payrollProfile: PayrollProfile | undefined | null,
  steps: Gusto_OnboardingStep[],
  key: string
) =>
  Boolean(payrollProfile) &&
  Boolean(steps.find((step) => step.id === key && step.completed))

export const GEP_ENROLL_PATHS = {
  intro: '/payroll/enroll/intro',
  overview: '/payroll/enroll/overview',
  company: '/payroll/enroll/company',
  federal: '/payroll/enroll/federal',
  locations: '/payroll/enroll/locations',
  employee: '/payroll/enroll/add_employee',
  employeeJob: '/payroll/enroll/employee_job',
  employeeFederal: '/payroll/enroll/employee_federal_tax',
  employeeState: '/payroll/enroll/employee_state_tax',
  employeeBank: '/payroll/enroll/employee_bank_account',
  schedule: '/payroll/enroll/schedule',
  flow: '/payroll/enroll/flow',
  complete: '/payroll/enroll/complete',
}

export const EMPLOYEE_ENROLL_PATHS = [
  GEP_ENROLL_PATHS.employee,
  GEP_ENROLL_PATHS.employeeJob,
  GEP_ENROLL_PATHS.employeeFederal,
  GEP_ENROLL_PATHS.employeeState,
  GEP_ENROLL_PATHS.employeeBank,
]

export enum PayrollComps {
  bonus = 'Bonus',
  cashTips = 'Cash Tips',
  commission = 'Commission',
  correctionPayment = 'Correction Payment',
  minimumWageAdjustment = 'Minimum Wage Adjustment',
  paycheckTips = 'Paycheck Tips',
  reimbursement = 'Reimbursement',
}

export enum PayrollTaskIdents {
  REVIEW_PAYROLL_COMPLIANCE = 'review-payroll-compliance',
}

export const compileSteps = (
  payrollProfile?: PayrollProfile | null,
  payrollCompany?: Gusto_Company | null,
  onboardingStatus?: Gusto_CompanyOnboardingStatus
): GeneratedOnboardingStep[] => {
  const onboardingSteps = onboardingStatus?.onboarding_steps || []

  return [
    {
      text: '1. Confirm company details',
      complete: Boolean(payrollProfile),
      paths: [GEP_ENROLL_PATHS.company],
    },
    {
      text: '2. Add tax information',
      complete:
        checkComplete(payrollProfile, onboardingSteps, 'federal_tax_setup') &&
        checkComplete(payrollProfile, onboardingSteps, 'select_industry'),
      paths: [GEP_ENROLL_PATHS.federal],
    },
    {
      text: '3. Add your company’s addresses',
      complete: checkComplete(payrollProfile, onboardingSteps, 'add_addresses'),
      paths: [GEP_ENROLL_PATHS.locations],
    },
    {
      key: 'employee',
      text: '4. Add yourself as an employee',
      complete: checkComplete(payrollProfile, onboardingSteps, 'add_employees'),
      paths: EMPLOYEE_ENROLL_PATHS,
    },
    {
      text: '5. Create a pay schedule',
      complete: checkComplete(
        payrollProfile,
        onboardingSteps,
        'payroll_schedule'
      ),
      paths: [GEP_ENROLL_PATHS.schedule],
    },
    {
      text: '6. Confirm and sign forms',
      complete:
        checkComplete(payrollProfile, onboardingSteps, 'add_bank_info') &&
        checkComplete(payrollProfile, onboardingSteps, 'state_setup') &&
        checkComplete(payrollProfile, onboardingSteps, 'sign_all_forms') &&
        checkComplete(payrollProfile, onboardingSteps, 'verify_bank_info') &&
        // TOS approval & payroll finish onboarding calls are on the confirmation page.
        Boolean(onboardingStatus?.onboarding_completed) &&
        Boolean(payrollProfile?.onboardingCompleted),
      paths: [GEP_ENROLL_PATHS.flow],
    },
    {
      text: '7. Await company approval',
      complete: payrollCompany?.company_status === 'Approved',
      paths: [GEP_ENROLL_PATHS.intro, GEP_ENROLL_PATHS.complete],
    },
  ]
}

export const getResumeLink = (steps: GeneratedOnboardingStep[]) => {
  // Find the first complete step.  Note steps can be empty but below will handle
  const completedIndex = findLastIndex(steps, (step) => step.complete)

  // Return the first path of the next screen or the subscription page if nothing is completed
  return completedIndex === -1
    ? GEP_ENROLL_PATHS.overview
    : steps[completedIndex + 1]?.paths[0]
}

// These are gathered from Gusto's prebuilt enrollment flow.  If more need to be added, inspect network calls when
// typing in values on the industry input
export const INDUSTRY_OPTIONS: {
  [key: string]: Omit<Gusto_CompanyIndustry, 'company_uuid'>
} = {
  'Offices of Physicians, Mental Health Specialists': {
    title: 'Offices of Physicians, Mental Health Specialists',
    naics_code: '621112',
    sic_codes: ['8011', '8031'],
  },
  'Offices of Mental Health Practitioners (except Physicians)': {
    title: 'Offices of Mental Health Practitioners (except Physicians)',
    naics_code: '621330',
    sic_codes: ['8049'],
  },
}

export const generatePayrollDetails = (
  payroll: Gusto_Payroll | null,
  employees: { [key: string]: EmployeeWithoutJob }
) => {
  if (!payroll?.totals) {
    return null
  }

  const taxesObject: {
    [name: string]: {
      name: string
      employeeAmount: number
      companyAmount: number
    }
  } = {}
  const employeeObject: {
    [key: string]: {
      uuid: string
      name: string
      paymentType: string
      grossPay: number
      deductions: number
      reimbursements: number
      employeeTaxes: number
      employeeBenefits: number
      employeeNetPay: number
      companyTaxes: number
      companyBenefits: number
      companyNetPay: number
    }
  } = {}

  const {
    employee_compensations,
    totals: {
      gross_pay,
      reimbursement_debit,
      child_support_debit,
      employee_taxes,
      employee_benefits_deductions,
      employer_taxes,
      benefits,
    } = {},
  } = payroll

  for (const comp of employee_compensations || []) {
    const employee = employees[comp.employee_uuid]
    if (!employee) {
      continue
    }

    const { uuid, first_name, last_name } = employee
    const {
      payment_method,
      deductions,
      gross_pay: compGrossPay,
      benefits: compBenefits,
      net_pay,
      employee_uuid,
      taxes,
    } = comp

    const reim =
      comp.fixed_compensations.find((fixed) => fixed.name === 'Reimbursement')
        ?.amount || 0

    employeeObject[employee_uuid] = {
      uuid,
      name: `${last_name}, ${first_name}`,
      paymentType: payment_method,
      grossPay: compGrossPay || 0,
      deductions: deductions
        ? addCurrencyArray(deductions.map((d) => d.amount))
        : 0,
      reimbursements: Number(reim),
      employeeBenefits: compBenefits
        ? addCurrencyArray(compBenefits.map((b) => b.employee_deduction))
        : 0,
      employeeNetPay: currency(net_pay || 0).add(currency(reim)).value,
      companyBenefits: compBenefits
        ? addCurrencyArray(compBenefits.map((b) => b.company_contribution))
        : 0,
      // handled below
      employeeTaxes: 0,
      companyTaxes: 0,
      companyNetPay: 0,
    }

    // Taxes
    for (const { employer, name, amount } of taxes || []) {
      if (employer) {
        if (taxesObject[name]) {
          taxesObject[name].companyAmount = currency(
            taxesObject[name].companyAmount
          ).add(amount).value
        } else {
          taxesObject[name] = { name, companyAmount: amount, employeeAmount: 0 }
        }

        employeeObject[employee_uuid].companyTaxes = currency(
          employeeObject[employee_uuid].companyTaxes
        ).add(amount).value
      } else {
        if (taxesObject[name]) {
          taxesObject[name].employeeAmount = currency(
            taxesObject[name].employeeAmount
          ).add(amount).value
        } else {
          taxesObject[name] = { name, employeeAmount: amount, companyAmount: 0 }
        }
        employeeObject[comp.employee_uuid].employeeTaxes = currency(amount).add(
          employeeObject[comp.employee_uuid].employeeTaxes
        ).value
      }
    }

    employeeObject[comp.employee_uuid].companyNetPay = addCurrencyArray([
      employeeObject[comp.employee_uuid].grossPay,
      employeeObject[comp.employee_uuid].reimbursements,
      employeeObject[comp.employee_uuid].companyTaxes,
      employeeObject[comp.employee_uuid].companyBenefits,
    ])
  }

  const employeeArray = Object.values(employeeObject)
  const totals = {
    grossPay: Number(gross_pay),
    deductions: addCurrencyArray(employeeArray.map((e) => e.deductions)),
    reimbursements: Number(reimbursement_debit),
    // The only garnishment that's displayed here is child support
    garnishments: Number(child_support_debit),
    employeeTaxes: Number(employee_taxes),
    employeeBenefits: Number(employee_benefits_deductions),
    employeeNetPay: addCurrencyArray(
      employeeArray.map((e) => e.employeeNetPay)
    ),
    companyTaxes: Number(employer_taxes),
    companyBenefits: Number(benefits),
    companyNetPay: addCurrencyArray(employeeArray.map((e) => e.companyNetPay)),
    allTaxes: currency(employer_taxes || 0).add(employee_taxes || 0).value,
  }

  return {
    employees: employeeObject,
    taxes: taxesObject,
    totals,
  }
}

export const getShortPayUnit = (unit: Gusto_PaymentUnitEnum) => {
  switch (unit) {
    case 'Week':
      return 'wk'
    case 'Hour':
      return 'hr'
    case 'Month':
      return 'mnth'
    case 'Year':
    case 'Paycheck':
      return 'yr'
    default:
      return unit satisfies never
  }
}

const COMP_ORDER = ['Regular Hours', 'Overtime', 'Double overtime']

export const sortHourComps = (
  comps: Gusto_EmployeePayrollCompensation['hourly_compensations']
) =>
  comps.slice().sort((a, b) => {
    if (COMP_ORDER.indexOf(a.name) === -1) return 1
    if (COMP_ORDER.indexOf(b.name) === -1) return -1
    return COMP_ORDER.indexOf(a.name) - COMP_ORDER.indexOf(b.name)
  })

export const getShortCompName = (name: string) => {
  switch (name) {
    case 'Regular Hours':
      return 'RH'
    case 'Overtime':
      return 'O'
    case 'Double overtime':
      return 'DO'
    default:
      return ''
  }
}

export const getContractorWageText = (contractor: Gusto_Contractor) => {
  if (contractor.wage_type === 'Hourly') {
    return `${contractor.wage_type} @ ${contractor.hourly_rate}/hr`
  }
  return `${contractor.wage_type} Wage`
}

const PAY_PERIODS_PER_YEAR = {
  'Every week': 40,
  'Every other week': 80,
  'Twice per month': 86.6666666667,
  Monthly: 173.333333,
  Quarterly: 4,
  Annually: 1,
}

// Note this does NOT use currency calculations to match Gusto's implementation
export const calculateHourlyRate = (
  job: Gusto_EmployeeJob | null,
  payFrequency: Gusto_PayScheduleFrequency
) => {
  const compensation = job?.compensations[0]

  if (!compensation || !job?.payment_unit) {
    return 0
  }

  const rate = Number(compensation.rate)

  switch (job.payment_unit) {
    case 'Year':
      return rate / 2080
    case 'Month':
      return rate / 173.333333
    case 'Week':
      return rate / 40
    case 'Paycheck':
      return rate / PAY_PERIODS_PER_YEAR[payFrequency]
    case 'Hour':
      return rate
    default:
      return job.payment_unit satisfies never
  }
}

export const PAYROLL_STEPS = [
  'Hours and Earnings',
  'Review and Submit',
  'Confirmation',
]

export const CONTRACTOR_PAYROLL_STEPS = [
  'Select Contractor',
  'Enter Payments',
  'Review',
  'Confirmation',
]

export const getContractorWages = ({
  contractor,
  bonus,
  reimbursement,
  hours,
  wage,
}: {
  contractor: Gusto_Contractor
  hours: string
  bonus: string
  reimbursement: string
  wage: string
}) => {
  const wages =
    contractor.wage_type === 'Hourly'
      ? currency(contractor.hourly_rate).multiply(hours)
      : currency(wage)

  return {
    wages: wages.value,
    total: wages.add(reimbursement).add(bonus).value,
  }
}

export const mapTaxType = (
  type: TAX_ENTITY_TYPES_TYPE | undefined | null
): Gusto_EntityType | '' => {
  switch (type) {
    case 'form_1065':
      return 'General partnership'
    case 'form_1120':
      return 'C-Corporation'
    case 'form_1120_s':
      return 'S-Corporation'
    case 'schedule_c':
    case 'form_1040':
      return 'Sole proprietor'
    case null:
    case undefined:
      return ''
    default:
      return type satisfies never
  }
}

export const gepAddressToText = ({
  street_1,
  street_2,
  city,
  state,
  zip,
}: Gusto_CompanyAddress | Gusto_EmployeeHomeAddress) =>
  `${street_1 || ''}${street_2 ? ` ${street_2}` : ''}, ${city || ''}, ${
    state || ''
  }, ${zip || ''}`

export const mapLocationOptions = (locations: Gusto_CompanyAddress[]) =>
  locations.map((location) => ({
    text: gepAddressToText(location),
    value: location.uuid,
  }))

export const getFutureBusinessDay = (days: number) =>
  getMomentBD()().businessAdd(days)

export const getMinPayDate = (paySpeed: number) => {
  // If today is a business day AND it's before 4PM then the current day can be used to process payments
  if (
    getMomentBD()().isBusinessDay() &&
    moment().isBefore(moment().hours(16))
  ) {
    return getFutureBusinessDay(paySpeed)
  }
  return getFutureBusinessDay(paySpeed + 1)
}

export const getMinPayDateFromConfig = (
  paySpeed?: Gusto_PaymentConfig['payment_speed']
) => {
  switch (paySpeed) {
    case '1-day':
      return getMinPayDate(1)
    case '2-day':
      return getMinPayDate(2)
    case '4-day':
    case undefined:
      return getMinPayDate(4)
    default:
      return paySpeed satisfies never
  }
}

export const flsaMapping = {
  Exempt: 'Salary/No Overtime',
  'Salaried Nonexempt': 'Salary with Overtime',
  Nonexempt: 'Hourly with Overtime',
  'Commission Only Exempt': 'Commission Only Exempt',
  'Commission Only Nonexempt': 'Commission Only Nonexempt',
  Owner: 'Owner',
  // Seems to be an edge case - we don't actually give this as an option
  Unknown: 'Unknown',
}

export const flsaDropdownOptions = Object.values(FlsaStatus)
  .filter((status) => status !== FlsaStatus.Owner)
  .map((status) => ({ text: flsaMapping[status], value: status }))

export const useBankAccount = () => {
  const dispatch = useAppDispatch()

  const [bankAccount, setBankAccount] = useState<Gusto_BankAccount>()

  // Initial Load
  useEffect(() => {
    const fetch = async () => {
      const res = await fetchBankAccounts()(dispatch)
      if (res?.length) {
        setBankAccount(res[0])
      }
    }
    fetch()
  }, [dispatch])

  return bankAccount
}

export const useOnboardingSteps = () => {
  const dispatch = useAppDispatch()
  const [steps, setSteps] = useState<GeneratedOnboardingStep[]>([])
  const payrollProfile = useReselector(selectPayrollProfile)
  const payrollCompany = useReselector(selectPayrollCompany)

  useEffect(() => {
    const fetchStatus = async () => {
      const res = await fetchCompanyOnboardingStatus()(dispatch)

      setSteps(compileSteps(payrollProfile, payrollCompany, res))
    }

    fetchStatus()
  }, [dispatch, payrollCompany, payrollProfile])

  return steps
}

// Dates based on day_1 and day_2 in PayrollEnrollSchedule
export const generateFilterFirstDate =
  (frequency: Gusto_PayScheduleFrequency) => (date: Date) => {
    switch (frequency) {
      case 'Every week':
        return true
      case 'Every other week':
        return true
      case 'Twice per month':
        return [15, moment(date).endOf('month').date()].includes(
          moment(date).date()
        )
      case 'Monthly':
        return moment(date).date() === moment(date).endOf('month').date()

      // These aren't valid options in our flow and cannot be selected
      case 'Quarterly':
      case 'Annually':
        return true
      default:
        return frequency satisfies never
    }
  }

// This logic isn't documented on Gusto's side.  Easiest way to debug is to make a Gusto flow and play with schedules https://flows.gusto-demo.com/demos
export const generateFilterAnchorDate =
  (firstPayDate: string, frequency: Gusto_PayScheduleFrequency) =>
  (date: Date) => {
    if (!firstPayDate) {
      return true
    }

    switch (frequency) {
      case 'Every week':
        return moment(date).isBetween(
          moment(firstPayDate, DATE_FORMATS.INPUT).subtract(10, 'days'),
          moment(firstPayDate, DATE_FORMATS.INPUT),
          undefined,
          '[]'
        )
      case 'Every other week':
        return moment(date).isBetween(
          moment(firstPayDate, DATE_FORMATS.INPUT).subtract(17, 'days'),
          moment(firstPayDate, DATE_FORMATS.INPUT),
          undefined,
          '[]'
        )
      case 'Twice per month':
        if (moment(firstPayDate).date() === 15) {
          return moment(date).isBetween(
            moment(firstPayDate, DATE_FORMATS.INPUT)
              .subtract(1, 'month')
              .endOf('month'),
            moment(firstPayDate, DATE_FORMATS.INPUT),
            undefined,
            '[]'
          )
        }
        return moment(date).isBetween(
          moment(firstPayDate, DATE_FORMATS.INPUT).date(15),
          moment(firstPayDate, DATE_FORMATS.INPUT),
          undefined,
          '[]'
        )
      case 'Monthly':
        return moment(date).isBetween(
          moment(firstPayDate, DATE_FORMATS.INPUT).subtract(1, 'month'),
          moment(firstPayDate, DATE_FORMATS.INPUT),
          undefined,
          '[]'
        )
      // These aren't valid options in our flow and cannot be selected
      case 'Quarterly':
      case 'Annually':
        return true
      default:
        return frequency satisfies never
    }
  }

export const useEmployeeWorkLocations = (employeeUuid?: string) => {
  const dispatch = useAppDispatch()

  const locations = useReselector(selectPayrollCompanyLocations)
  const filingAddress = useReselector(selectFilingLocationOrParsedAddress)
  const mailingAddress = useReselector(selectMailingLocation)
  const otherAddresses = useReselector(selectOtherLocations)

  const [workLocations, setWorkLocations] =
    useState<Gusto_EmployeeWorkAddress[]>()

  useEffect(() => {
    dispatch(fetchAllCompanyLocations())
  }, [dispatch])

  const fetchWorkLocations = useCallback(async () => {
    if (!employeeUuid) {
      return
    }

    const locationResponse = await dispatch(
      fetchEmployeeWorkAddresses(employeeUuid)
    )

    if (locationResponse) {
      setWorkLocations(locationResponse)
    }
  }, [dispatch, employeeUuid])

  useEffect(() => {
    fetchWorkLocations()
  }, [fetchWorkLocations])

  const locationOptions = useMemo(
    () => mapLocationOptions(Object.values(locations)),
    [locations]
  )

  const currentWorkLocation = useMemo(
    () => workLocations?.find((location) => location.active),
    [workLocations]
  )

  // Find the employee's work address
  const defaultAddress = useMemo(() => {
    // First see if there are any active work addresses for the employee
    if (currentWorkLocation) {
      return currentWorkLocation.location_uuid
    }

    // If none are set go through other addresses and use those
    // In the flow the address that wasn't set as filing or mailing is the work one
    if (otherAddresses.length) {
      return otherAddresses[0].uuid
    } else if (mailingAddress) {
      return mailingAddress.uuid
    } else if (filingAddress) {
      return filingAddress.uuid
    }

    return undefined
  }, [currentWorkLocation, filingAddress, mailingAddress, otherAddresses])

  const setWorkLocation = useCallback(
    async (location_uuid: string) => {
      if (!employeeUuid) {
        return false
      }

      let workAddressResponse
      // Set work address
      if (currentWorkLocation) {
        workAddressResponse = await dispatch(
          putUpdateEmployeeWorkAddress(currentWorkLocation.uuid, {
            version: currentWorkLocation.version,
            location_uuid,
          })
        )
      } else {
        workAddressResponse = await dispatch(
          postCreateEmployeeWorkAddress(employeeUuid, {
            location_uuid,
          })
        )
      }

      // Refetch work locations rather than update existing ones
      await fetchWorkLocations()

      return Boolean(workAddressResponse)
    },
    [currentWorkLocation, dispatch, employeeUuid, fetchWorkLocations]
  )

  return {
    // The current work location of the employee
    currentWorkLocation,
    // The work location response of the employees - all of their active and inactive locations
    workLocations,
    // Selected option for location dropdown
    defaultAddress,
    // Options for location dropdown
    locationOptions,
    // Updates or creates work location
    setWorkLocation,
  }
}

export const getMinDateOfBirth = () => moment().subtract(13, 'years')
