import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { keyBy } from 'lodash'

import { fetchIfNeededWrapper } from '../../../../../../reducers/fetch'
import { SubStepIdentifiers } from './stepProgress.helpers'

export interface AllEoyReviewStep {
  id: number
  identifier: SubStepIdentifiers
  requiredForTaxEntityTypes: string[]
  createdAt: string
  updatedAt: string
}

export interface AllEoyReviewStepState {
  [key: string]: AllEoyReviewStep
}

const allEoyReviewStepsSlice = createSlice({
  name: 'allEoyReviewSteps',
  initialState: {} as AllEoyReviewStepState,
  reducers: {
    setAllEoyReviewSteps: (
      state,
      action: PayloadAction<{
        [key: string]: AllEoyReviewStep
      }>
    ) => ({
      ...state,
      ...action.payload,
    }),
  },
})

const { setAllEoyReviewSteps } = allEoyReviewStepsSlice.actions
export default allEoyReviewStepsSlice.reducer

export const FETCH_ALL_EOY_REVIEW_STEPS_KEY = 'FETCH_ALL_EOY_REVIEW_STEPS_KEY'
export const fetchAllEoyReviewStepsIfNeeded = () =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_ALL_EOY_REVIEW_STEPS_KEY,
    defaultErrorMessage: 'Error fetching end of year review steps',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<AllEoyReviewStep[]>(
        '/finances/api/v1/end_of_year_review/steps'
      )

      dispatch(setAllEoyReviewSteps(keyBy(json.data, 'identifier')))
      return json.data
    },
  })
