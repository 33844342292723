import {
  Component,
  createRef,
  forwardRef,
  RefObject,
  useCallback,
  useImperativeHandle,
} from 'react'
import { FormikProvider, useFormik, FormikProps } from 'formik'
import * as yup from 'yup'
import { capitalize, noop } from 'lodash'

import {
  FormikInput,
  makeNumberSchema,
  NumberSchema,
} from '../../../../components/BaseComponents'
import { AddButton, EditButton } from './PayrollInputButtons'
import { Gusto_EmployeeFixedCompensation } from '../../generated_gusto_types'

interface FormValues {
  amount?: string
}

export const AmountInputForm = forwardRef(
  (
    {
      amount,
      employeeUuid,
      isMoney,
      numSchema,
    }: {
      amount: string
      employeeUuid: string
      isMoney?: boolean
      numSchema: NumberSchema
    },
    ref
  ) => {
    const formik = useFormik({
      initialValues: { amount },
      validationSchema: yup.object({
        amount: makeNumberSchema(numSchema),
      }),
      onSubmit: noop,
    })

    useImperativeHandle(ref, () => ({ ...formik }))

    const triggerChange = useCallback(() => {
      document.dispatchEvent(new CustomEvent(`inputChange${employeeUuid}`))
    }, [employeeUuid])

    return (
      <FormikProvider value={formik}>
        <FormikInput
          name="amount"
          componentType={isMoney ? 'currency' : undefined}
          onChangeCallback={triggerChange}
        />
      </FormikProvider>
    )
  }
)

interface Props {
  fixedComp: Gusto_EmployeeFixedCompensation
  employeeUuid: string
  name?: string
}

class FixedCell extends Component<
  Props,
  { inputShown: boolean; editShown: boolean }
> {
  formRef: RefObject<FormikProps<FormValues>>

  constructor(props: Props) {
    super(props)
    this.state = {
      inputShown: false,
      editShown: props.fixedComp.amount !== '0.00',
    }

    this.formRef = createRef<FormikProps<FormValues>>()
  }

  calculate() {
    const {
      fixedComp: { name, job_uuid, amount },
    } = this.props
    const { inputShown } = this.state

    return {
      name,
      job_uuid,
      amount:
        (inputShown ? this.formRef.current?.values.amount : amount) || '0',
    }
  }

  render() {
    const { fixedComp, employeeUuid, name } = this.props
    const { inputShown, editShown } = this.state
    const fieldName = name || fixedComp.name.toLowerCase()

    return (
      <div className="fixed-cell">
        {inputShown && (
          <AmountInputForm
            amount={fixedComp.amount}
            ref={this.formRef}
            employeeUuid={employeeUuid}
            isMoney
            numSchema={{
              field: `${fieldName} amount`,
              allowedDecimals: 2,
            }}
          />
        )}
        {!inputShown && editShown && (
          <EditButton
            onClick={() => this.setState({ inputShown: true })}
            text={`$${fixedComp.amount} ${capitalize(fieldName)}`}
          />
        )}
        {!inputShown && !editShown && (
          <AddButton
            onClick={() => this.setState({ inputShown: true })}
            text={capitalize(fieldName)}
          />
        )}
      </div>
    )
  }
}

export default FixedCell
