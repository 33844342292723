import { useEffect, useState } from 'react'
import { Container, Header, Loader, Dimmer, Button } from 'semantic-ui-react'

import UserTodoCreationModal from './UserTodoCreationModal'
import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import { UserActionItemsTable } from './UserTodoTable'
import { adminFetchUserActionItems } from '../../../features/Dashboard/UserActionItems/userActionItems.slice'
import { getUserIdNonDeletedActionItems } from '../../../features/Dashboard/UserActionItems/userActionItems.selectors'
import { UserImportantDatesTable } from './UserImportantDateTable'
import { getNonDeletedImportantDates } from '../../../features/Dashboard/UserImportantDates/userImportantDates.selectors'
import { adminFetchUserImportantDates } from '../../../features/Dashboard/UserImportantDates/userImportantDates.slice'
import UserImportantDateCreationModal from './UserImportantDateCreationModal'
import { useReselector } from '../../../utils/sharedHooks'
import {
  fetchAllImportantDates,
  fetchAllTodos,
} from '../../../features/Admin/ActionItems/allActionItems.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'

const UserActionItemsPanel = ({ user }: { user: UserWithAdminInfo }) => {
  const dispatch = useAppDispatch()
  const [fetching, setFetching] = useState(true)
  const [todoModalOpen, setTodoModalOpen] = useState(false)
  const [importantDateModalOpen, setImportantDateModalOpen] = useState(false)

  const userActionItems = useReselector(getUserIdNonDeletedActionItems, user.id)
  const userImportantDates = useReselector(getNonDeletedImportantDates)

  useEffect(() => {
    async function fetch() {
      await fetchAllTodos()(dispatch)
      await fetchAllImportantDates()(dispatch)
      await adminFetchUserActionItems(user.id)(dispatch)
      await adminFetchUserImportantDates(user.id)(dispatch)
      setFetching(false)
    }
    fetch()
  }, [dispatch, user.id])

  return (
    <Container id="userActionItemsPanel">
      {fetching && (
        <Dimmer active inverted>
          <Loader inverted>Fetching all data</Loader>
        </Dimmer>
      )}
      {!fetching && (
        <div>
          <div className="flexbox">
            <Header as="h4">User Todos</Header>
            <Button secondary onClick={() => setTodoModalOpen(true)}>
              Assign todo for user
            </Button>
            <UserTodoCreationModal
              open={todoModalOpen}
              close={() => setTodoModalOpen(false)}
              userId={user.id}
            />
          </div>
          <UserActionItemsTable actionItems={userActionItems} user={user} />

          <div className="flexbox">
            <Header as="h4">User Important Dates</Header>
            <Button secondary onClick={() => setImportantDateModalOpen(true)}>
              Assign important date for user
            </Button>
            <UserImportantDateCreationModal
              open={importantDateModalOpen}
              close={() => setImportantDateModalOpen(false)}
              userId={user.id}
            />
          </div>
          <UserImportantDatesTable
            importantDates={userImportantDates}
            user={user}
          />
        </div>
      )}
    </Container>
  )
}

export default UserActionItemsPanel
