import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Container, Grid } from 'semantic-ui-react'

import './settings.scss'

import PayrollDetailsTab from './PayrollDetailsTab'
import CompanySettingsTab from './CompanySettingsTab'
import CompanyLocationsTab from './CompanyLocationsTab'
import {
  fetchAllCompanyLocations,
  fetchPayrollCompany,
} from '../payrollActions'
import { Tab, Text, Card } from '../../../components/BaseComponents'
import WorkersCompensationTab from './WorkersCompensationTab'
import { useAppDispatch } from '../../../utils/typeHelpers'
const WC_FEATURE_FLAG = process.env.VITE_WORKERS_COMP_ENABLED

const PayrollSettings = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchPayrollCompany())
    dispatch(fetchAllCompanyLocations())
  }, [dispatch])

  const [searchParams] = useSearchParams()

  const [activeIndex, setActiveIndex] = useState(
    searchParams.get('tab') === 'locations' ? 2 : 0
  )
  const panes = useMemo(() => {
    if (WC_FEATURE_FLAG) {
      return [
        {
          menuItem: 'Payroll Details',
          pane: {
            key: 'tab1',
            content: <PayrollDetailsTab />,
            size: 'massive',
          },
        },
        {
          menuItem: 'Company Details',
          pane: {
            key: 'tab2',
            content: (
              <CompanySettingsTab goToLocations={() => setActiveIndex(2)} />
            ),
            size: 'massive',
          },
        },
        {
          menuItem: 'Company Locations',
          pane: {
            key: 'tab3',
            content: <CompanyLocationsTab />,
            size: 'massive',
          },
        },
        {
          menuItem: 'Workers Compensation',
          pane: {
            key: 'tab4',
            content: <WorkersCompensationTab />,
            size: 'massive',
          },
        },
      ]
    } else {
      return [
        {
          menuItem: 'Payroll Details',
          pane: {
            key: 'tab1',
            content: <PayrollDetailsTab />,
            size: 'massive',
          },
        },
        {
          menuItem: 'Company Details',
          pane: {
            key: 'tab2',
            content: (
              <CompanySettingsTab goToLocations={() => setActiveIndex(2)} />
            ),
            size: 'massive',
          },
        },
        {
          menuItem: 'Company Locations',
          pane: {
            key: 'tab3',
            content: <CompanyLocationsTab />,
            size: 'massive',
          },
        },
      ]
    }
  }, [])

  return (
    <Container id="payroll-settings">
      <Card>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Text as="h1">Payroll Settings</Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Tab
                panes={panes}
                renderActiveOnly={false}
                activeIndex={activeIndex}
                onTabClick={(index) => setActiveIndex(index)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollSettings
