import axios from 'axios'
import { fetchWrapper } from '../../../../../reducers/fetch'
import { StandardDeduction, TaxRateBrackets } from '../IncomeTaxDetailsHelpers'

//Client-side models
export interface StateIncomeTaxEstimateDetails {
  id: number
  effectiveFrom: string
  current: boolean
  state: StateIncomeTaxDetailsStates
  updatedBy?: number
  standardDeduction?: StandardDeduction | null
  taxRateType: TaxRateType
  taxRateFlat?: number
  taxRateBrackets?: TaxRateBrackets | null
  createdAt: string
  updatedAt: string
}

export type StateIncomeTaxDetailsPayload = Omit<
  StateIncomeTaxEstimateDetails,
  'id' | 'createdAt' | 'updatedAt' | 'effectiveFrom'
> & { effectiveFrom: Date }

// Keys used within our fetchWrapper. Sometimes used by UI components for ascertaining the request's state
export const FETCH_ALL_STATE_INCOME_TAX_DETAILS_KEY =
  'FETCH_ALL_STATE_INCOME_TAX_DETAILS_KEY'
export const CREATE_SINGLE_STATE_INCOME_TAX_DETAILS_KEY =
  'CREATE_SINGLE_STATE_INCOME_TAX_DETAILS_KEY'

/*
  This list only includes the 50 US states and DC
  Note this list does not include US territories and other locations that users
  can indicate are home states
*/
export enum StateIncomeTaxDetailsStates {
  AL = 'AL',
  AK = 'AK',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  DC = 'DC',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

export enum TaxRateType {
  flat = 'flat',
  bracket = 'bracket',
}

// Action functions
export const createSingleStateIncomeTaxDetails = (
  data: Partial<StateIncomeTaxDetailsPayload>
) =>
  fetchWrapper({
    fetchKey: CREATE_SINGLE_STATE_INCOME_TAX_DETAILS_KEY,
    defaultErrorMessage: 'Error creating the state income tax details',
    fetchFunction: async () => {
      const json = await axios.post<StateIncomeTaxEstimateDetails>(
        '/finances/api/v1/admin/state_income_tax',
        data
      )
      return json.data
    },
  })

export const getStateDetails = () =>
  fetchWrapper({
    fetchKey: FETCH_ALL_STATE_INCOME_TAX_DETAILS_KEY,
    fetchFunction: async () => {
      const json = await axios.get<StateIncomeTaxEstimateDetails[]>(
        '/finances/api/v1/admin/state_income_tax'
      )
      return json.data
    },
  })
