import { useState, useEffect } from 'react'

import { adminUpdateUserTransaction } from '../../../actions/admin/transactionActions'
import TransactionCategoryDropdownCOA from '../../shared/TransactionCategoryDropdown'
import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import { Text } from '../../BaseComponents'
import { useAppDispatch } from '../../../utils/typeHelpers'

const TransactionCategoryDropdown = ({
  transaction,
  onUpdate = (_: Transaction) => undefined,
}: {
  transaction: Transaction
  onUpdate?: (transaction: Transaction) => void
}) => {
  const dispatch = useAppDispatch()
  const [dropdownDisabled, setDropdownDisabled] = useState(false)

  useEffect(() => {
    if (transaction.splitAt || transaction.type === 'split') {
      setDropdownDisabled(true)
      return
    }

    if (!transaction.type) {
      setDropdownDisabled(true)
    }
    if (transaction.confirmedAt) {
      setDropdownDisabled(true)
    } else {
      setDropdownDisabled(false)
    }
  }, [transaction])

  if (transaction.type === 'personal') {
    return <Text style={{ padding: '12px 16px' }}>N/A</Text>
  }

  const categoryChange = async (categoryId: number | null) => {
    await adminUpdateUserTransaction(transaction.id, {
      transactionCategoryId: categoryId,
    })(dispatch)
    onUpdate({
      ...transaction,
      transactionCategoryId: categoryId,
    })
  }

  return (
    <TransactionCategoryDropdownCOA
      optionStyle="normal"
      value={transaction.transactionCategoryId || undefined}
      onChange={(value) => categoryChange(value || null)}
      disabled={dropdownDisabled}
    />
  )
}

export default TransactionCategoryDropdown
