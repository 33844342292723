import { useCallback, useEffect, useState } from 'react'

// BL
import { DocumentTabPane } from '.'
import { incorporationDocumentsPane as copy } from '../copyConstants'
import {
  closeUploadModal,
  openUploadModal,
  useFeatureDispatch,
  useFeatureState,
} from '../state'
import { TabCategoryKeys as ModalTypes } from '../constants'

// UI
import { DocumentSearchBarOnChangeData } from '../sharedComponents/DocumentsSearchBar'
import TabPaneToolbar from '../sharedComponents/TabPaneToolbar'
import UploadDocumentButton from '../sharedComponents/Buttons/UploadDocumentButton'
import UserDocumentsTable from '../sharedComponents/Table/UserDocumentsTable'
import { TaxOrIncorporationRow } from '../sharedComponents/Table/UserDocumentsTableRow'
import FileUploadModal from '../../../components/FileUpload/FileUploadModal'
import { UploadDocumentType } from '../../../constants/businessConstants'
import { useAnalyticsTrack } from '../../Amplitude'

const IncorporationDocuments: DocumentTabPane = ({
  documents,
  tableOptions,
}) => {
  const [filteredDocuments, setFilteredDocuments] = useState(documents)
  const { activeUploadModal, userDocumentCategoryId } = useFeatureState()
  const dispatch = useFeatureDispatch()
  const track = useAnalyticsTrack()

  useEffect(() => {
    setFilteredDocuments(documents)
  }, [documents])

  const onSearch = useCallback((data: DocumentSearchBarOnChangeData) => {
    setFilteredDocuments(data.filtered)
  }, [])

  const openModal = () => {
    track('clicked document upload', {
      document_category: ModalTypes.INCORPORATION_DOCUMENTS,
      document_subcategory: '',
    })
    dispatch?.(openUploadModal(ModalTypes.INCORPORATION_DOCUMENTS))
  }

  return (
    <>
      <TabPaneToolbar
        preamble="Share entity formation documents here, such as articles of incorporation, LLC approval, or Form 2553."
        documents={documents}
        onSearch={onSearch}
        rightElement={<UploadDocumentButton onClick={openModal} />}
      />
      <UserDocumentsTable
        columns={copy.static.tableColumns}
        documents={filteredDocuments}
        RowComponent={TaxOrIncorporationRow}
        tableOptions={tableOptions}
      />
      <FileUploadModal
        open={activeUploadModal === ModalTypes.INCORPORATION_DOCUMENTS}
        close={() => dispatch?.(closeUploadModal())}
        documentType={UploadDocumentType.INCORPORATION}
        userFacing
        categoryId={userDocumentCategoryId}
      />
    </>
  )
}

IncorporationDocuments.paneDescription = copy.static.paneDescription
IncorporationDocuments.paneTitle = copy.static.paneTitle

export default IncorporationDocuments
