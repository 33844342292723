import { useEffect, useMemo } from 'react'
import { sortBy } from 'lodash'

import {
  FETCH_CONTRACTORS_KEY,
  FETCH_EMPLOYEES_KEY,
  fetchContractors,
  fetchEmployees,
} from '../payrollActions'
import {
  selectContractorByUuid,
  selectContractors,
  selectEmployeeByUuid,
  selectEmployees,
} from '../payroll.selectors'
import { Link, Loader, Table } from '../../../components/BaseComponents'
import { getContractorName } from '../helpers'
import { useReselector } from '../../../utils/sharedHooks'
import { selectIsFetchingForKeys } from '../../../reducers/fetch'
import { useAppDispatch } from '../../../utils/typeHelpers'

const EmployeeRow = ({ uuid }: { uuid: string }) => {
  const employee = useReselector(selectEmployeeByUuid, uuid)

  if (!employee) {
    return null
  }

  return (
    <Table.Row>
      <Table.Cell>
        {employee.first_name} {employee.last_name}
      </Table.Cell>
      <Table.Cell>
        <Link to={`/payroll/employee/${uuid}?tab=documents`}>View Forms</Link>
      </Table.Cell>
    </Table.Row>
  )
}

const ContractorRow = ({ uuid }: { uuid: string }) => {
  const contractor = useReselector(selectContractorByUuid, uuid)

  if (!contractor) {
    return null
  }

  return (
    <Table.Row>
      <Table.Cell>{getContractorName(contractor)}</Table.Cell>
      <Table.Cell>
        <Link to={`/payroll/contractor/${uuid}?tab=documents`}>View Forms</Link>
      </Table.Cell>
    </Table.Row>
  )
}

const TeamMemberFormsTab = () => {
  const dispatch = useAppDispatch()
  const employees = useReselector(selectEmployees)
  const contractors = useReselector(selectContractors)
  const isLoading = useReselector(selectIsFetchingForKeys, [
    FETCH_EMPLOYEES_KEY,
    FETCH_CONTRACTORS_KEY,
  ])

  useEffect(() => {
    dispatch(fetchEmployees())
    dispatch(fetchContractors())
  }, [dispatch])

  const sortedEmployees = useMemo(
    () =>
      sortBy(Object.values(employees), (employee) =>
        `${employee.first_name} ${employee.last_name}`.toLowerCase()
      ),
    [employees]
  )

  const sortedContractors = useMemo(
    () =>
      sortBy(
        Object.values(contractors),
        (contractor) => getContractorName(contractor)?.toLowerCase() || ''
      ),
    [contractors]
  )

  return (
    <>
      <Loader
        loading={
          isLoading && !sortedEmployees.length && !sortedContractors.length
        }
      />
      <Table basic>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedEmployees.map((employee) => (
            <EmployeeRow key={employee.uuid} uuid={employee.uuid} />
          ))}
          {sortedContractors.map((contractor) => (
            <ContractorRow key={contractor.uuid} uuid={contractor.uuid} />
          ))}
        </Table.Body>
      </Table>
    </>
  )
}

export default TeamMemberFormsTab
