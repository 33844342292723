import { createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'

import { updateCurrentUser } from './userReducer'

export interface SecurityState {
  isAuthenticated: boolean
  isFinancesActive: boolean
  isAdmin: boolean
  loading: boolean
  resetPasswordEmailSent: boolean
  updatedPassword: boolean
  validatingTokenLoading: boolean
  validResetPasswordToken: boolean
}

export const initialStateSecurityState: SecurityState = {
  isAuthenticated: false,
  isFinancesActive: false,
  isAdmin: false,
  loading: false,
  resetPasswordEmailSent: false,
  updatedPassword: false,
  validatingTokenLoading: false,
  validResetPasswordToken: false,
}

const securitySlice = createSlice({
  name: 'security',
  initialState: initialStateSecurityState,
  reducers: {
    setResetPasswordEmailSent: (state) => {
      state.resetPasswordEmailSent = true
    },
    setValidResetPasswordToken: (state) => {
      state.validResetPasswordToken = true
      state.validatingTokenLoading = false
    },
    setInvalidResetPasswordToken: (state) => {
      state.validResetPasswordToken = false
      state.validatingTokenLoading = false
    },
    validatingTokenLoading: (state) => {
      state.validatingTokenLoading = true
    },
    setUpdatedPassword: (state) => {
      state.updatedPassword = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateCurrentUser, (state, action) => {
      state.isAuthenticated = !isEmpty(action.payload)
      state.isAdmin = Boolean(action.payload.admin)
      // isFinancesActive come back inconsistently so just check for false
      state.isFinancesActive = !(action.payload.isFinancesActive === false)
    })
  },
})

export const {
  setResetPasswordEmailSent,
  setUpdatedPassword,
  setValidResetPasswordToken,
  validatingTokenLoading,
  setInvalidResetPasswordToken,
} = securitySlice.actions

export default securitySlice.reducer
