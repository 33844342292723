import axios from 'axios'
import { fetchIfNeededWrapper, fetchWrapper } from '../../../reducers/fetch'
import {
  receiveUserSCorpElectionStatus,
  SCorpElectionStatus,
} from './sCorpStatus.slice'

export interface SCorpEligibility {
  show_card_to_user: boolean
  user_joined_prior_to_current_year?: boolean
  tax_savings_in_cents?: number
}

export type SCorpElectionStatusPostPayload = Pick<
  SCorpElectionStatus,
  'userId' | 'status' | 'initialRequestSource'
>

export const FETCH_SCORP_ELIGIBILITY_KEY = 'FETCH_SCORP_ELIGIBILITY_KEY'
export const fetchSCorpEligibility = () =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error fetching s corp eligibility.',
    fetchKey: FETCH_SCORP_ELIGIBILITY_KEY,
    fetchFunction: () =>
      axios
        .get<SCorpEligibility>('/finances/api/v1/scorp-upsell/eligibility')
        .then((json) => {
          return json.data
        }),
  })

export const FETCH_SCORP_ELECTION_STATUS_KEY = 'FETCH_SCORP_ELECTION_STATUS_KEY'
export const fetchSCorpElectionStatus = (userId: number, alwaysFetch = false) =>
  fetchIfNeededWrapper({
    defaultErrorMessage: 'There was an error fetching s corp election status.',
    fetchKey: FETCH_SCORP_ELECTION_STATUS_KEY,
    alwaysFetch,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<SCorpElectionStatus | null>(
        '/finances/api/v1/scorp-upsell/user_scorp_election_statuses',
        {
          params: {
            userId,
          },
        }
      )
      if (json?.data) {
        dispatch(receiveUserSCorpElectionStatus({ userId, data: json.data }))
      }
      return json.data
    },
  })

export const POST_USER_S_CORP_ELECTION_STATUS =
  'POST_USER_S_CORP_ELECTION_STATUS'
export const postSCorpElectionStatus = (
  payload: SCorpElectionStatusPostPayload
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error posting s corp election status.',
    fetchKey: POST_USER_S_CORP_ELECTION_STATUS,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<SCorpElectionStatus>(
        '/finances/api/v1/scorp-upsell/user_scorp_election_statuses',
        payload
      )

      dispatch(
        receiveUserSCorpElectionStatus({
          userId: json.data.userId,
          data: json.data,
        })
      )

      return json.data
    },
  })

export const PUT_USER_S_CORP_ELECTION_STATUS = 'PUT_USER_S_CORP_ELECTION_STATUS'
export const putSCorpElectionStatus = ({
  id,
  payload,
}: {
  id: number
  payload: Partial<SCorpElectionStatus>
}) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error fetching s corp election status.',
    fetchKey: PUT_USER_S_CORP_ELECTION_STATUS,
    fetchFunction: async (dispatch) => {
      const json = await axios.put<SCorpElectionStatus>(
        `/finances/api/v1/scorp-upsell/user_scorp_election_statuses/${id}`,
        payload
      )
      dispatch(
        receiveUserSCorpElectionStatus({
          userId: json.data.userId,
          data: json.data,
        })
      )
      return json.data
    },
  })
