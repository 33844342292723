import { createSelector } from 'reselect'
import {
  selectHeardProductSubscription,
  selectIsBasicPlan,
} from '../../reducers/subscription.slice'
import {
  getCurrentUser,
  membershipIsIn,
  selectPrimaryMembership,
} from '../../selectors/user.selectors'
import { MembershipScopes } from '../../reducers/auth/userReducer'
import { DateTime } from 'luxon'
/**
 * A user is eligble for the cancellation discount if they:
 * 1. are not on a basic plan
 * 2. have not received the discount before
 * 3. do not have any other current discounts
 * 4. are not currently on a trial
 * 5. were not on a trial within the last year
 */
export const isEligibleForDiscount = createSelector(
  getCurrentUser,
  selectIsBasicPlan,
  selectHeardProductSubscription,
  selectPrimaryMembership,
  (_: unknown, discountFlagOn: boolean) => discountFlagOn,
  (user, isBasicPlan, sub, membership, discountFlagOn) =>
    discountFlagOn &&
    !isBasicPlan &&
    user?.cancellationDiscountApplied !== true &&
    sub?.discounts.length === 0 &&
    !membershipIsIn(MembershipScopes.trial, membership) &&
    (!membership?.trialStartDate ||
      DateTime.fromISO(membership.trialStartDate) <
        DateTime.now().minus({ years: 1 }))
)
