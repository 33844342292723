import {
  FinancialAdvisoryProfile,
  FinancialAdvisoryProfilesStatus,
} from './financialAdvisory.slice'
import PlanCompletedPanel from './panels/PlanCompletedPanel'
import PostIntroCallPanel from './panels/PostIntroCallPanel'
import PreIntroCallPanel from './panels/PreIntroCallPanel'
import { UpriseIframe } from './UpriseIframe'

/**
 * Wrapper for the Advisory Homepage Post Intro Call
 *
 *  profile_generated -> User just has Profile
 *  intro_call_scheduled -> User scheduled intro call
 *  intro_call_completed -> User completed Intro call
 *  onboarding -> User is in the Uprise OB flow
 *  active -> User is onboarded, has active uprise accnt
 *  canceled -> canceled
 */
const AdvisoryHomepageWrapper = ({
  faProfile,
}: {
  faProfile: FinancialAdvisoryProfile
}) => {
  // State: Intro Call Scheduled, but not completed.
  if (
    faProfile?.status === FinancialAdvisoryProfilesStatus.intro_call_scheduled
  ) {
    return <PreIntroCallPanel faProfile={faProfile} />
  }
  // State: Intro Call Completed, but Uprise Onboarding still has not started
  else if (
    faProfile?.status === FinancialAdvisoryProfilesStatus.intro_call_completed
  ) {
    return <PostIntroCallPanel faProfile={faProfile} />
  }
  // State: Uprise Onboarding Started. We now can use Uprises' embedded Wrapper
  else if (faProfile?.status === FinancialAdvisoryProfilesStatus.onboarding) {
    return <UpriseIframe page="onboarding" />
  }
  // State: Plan Completed — we can show user the Plan Completed Pannel
  else if (
    faProfile?.status === FinancialAdvisoryProfilesStatus.plan_completed
  ) {
    return <PlanCompletedPanel faProfile={faProfile} />
  }
  // State: User has onboarded to Uprise, regular embedded flow
  else if (faProfile?.status === FinancialAdvisoryProfilesStatus.active) {
    return <UpriseIframe page="" />
  }
  // Default State — reaching here means something went wrong
  else {
    return null
  }
}

export default AdvisoryHomepageWrapper
