import {
  Accordion,
  Button,
  GridRowColumn,
  Modal,
  Text,
} from '../../BaseComponents'
import { Grid } from 'semantic-ui-react'
import {
  FinancialAccount,
  TransactionSyncState,
} from '../../../reducers/finances/financialAccountsReducer'
import { FontWeight } from '../../../styles/theme'
import { useEffect } from 'react'
import { AccountBanner } from './InstitutionList'
import { updateFinancialAccount } from '../../../actions/financialAccountActions'
import { useAnalyticsTrack } from '../../../features/Amplitude'
import { useAppDispatch } from '../../../utils/typeHelpers'

const InitialStateModal = ({
  open,
  account,
  onClose,
  setAccountBanner,
}: {
  open: boolean
  account: FinancialAccount
  onClose: () => void
  setAccountBanner: (accountBanner: AccountBanner | null) => void
}) => {
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()

  useEffect(() => {
    if (open) {
      track('viewed review bank account modal')
    }
  }, [open, track])

  return (
    <Modal size="small" open={open} closeIcon onClose={onClose}>
      <Modal.Header>Did you want to show this account?</Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text>
              Please confirm if you want to show or hide transactions from{' '}
              <span style={{ fontWeight: FontWeight.SEMIBOLD }}>
                {account.name} - {account.mask}
              </span>
              .
            </Text>
            <br />
            <Text as={'h3'} style={{ fontWeight: FontWeight.SEMIBOLD }}>
              &quot;Show Account&quot;
            </Text>
            <Text>
              Transactions from this account will start showing up in Heard.
            </Text>
            <br />
            <Text as={'h3'} style={{ fontWeight: FontWeight.SEMIBOLD }}>
              &quot;Hide Account&quot;
            </Text>
            <Text>Transactions from this account will be hidden in Heard.</Text>
            <br />
            <Text>
              To unlink the account from Heard, please visit{' '}
              {account.plaidInstitutionName}&apos;s security center.
            </Text>
            <br />
            <Accordion
              title={'Why do I need to confirm?'}
              variant={'text'}
              content={
                <>
                  <Text>
                    If you choose to “Show Account”, our bookkeepers will assume
                    transactions from this account are business related. If this
                    account has mostly personal transactions, we suggest you
                    hide it and add business transactions manually.
                  </Text>
                  <br />
                  <Text>
                    Some potential consequences of mixing business and personal
                    expenses include unorganized data, and miscalculation in
                    taxes.
                  </Text>
                </>
              }
            />
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="secondary"
          onClick={async () => {
            onClose()
            await updateFinancialAccount(account.id, {
              isHidden: true,
              transactionSyncState: TransactionSyncState.DISABLED,
            })(dispatch)
            setAccountBanner({ account, type: 'hide' })
            track('clicked review bank account modal button', {
              button: 'hide account',
            })
          }}
        >
          Hide account
        </Button>
        <Button
          variant={'secondary'}
          onClick={async () => {
            onClose()
            await updateFinancialAccount(account.id, {
              isHidden: false,
              transactionSyncState: TransactionSyncState.ENABLED,
            })(dispatch)
            setAccountBanner({ account, type: 'show' })
            track('clicked review bank account modal button', {
              button: 'show account',
            })
          }}
        >
          Show account
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default InitialStateModal
