import Label from '../components/BaseComponents/Label'

export const DefaultLabel = () => (
  <div style={{ backgroundColor: 'white', padding: 4 }}>
    <Label>Default Label</Label>
  </div>
)

export const IconLabel = () => (
  <div style={{ backgroundColor: 'white', padding: 4 }}>
    <Label>Icon Label</Label>
  </div>
)

export const DarkLabel = () => (
  <div style={{ backgroundColor: 'white', padding: 4 }}>
    <Label color="dark">Dark Label</Label>
  </div>
)

export const RedIconLabel = () => (
  <div style={{ backgroundColor: 'white', padding: 4 }}>
    <Label color="red">Red Label</Label>
  </div>
)

export const BlueIconLabel = () => (
  <div style={{ backgroundColor: 'white', padding: 4 }}>
    <Label color="blue">Blue Label</Label>
  </div>
)

export const CircularLabel = () => (
  <div style={{ backgroundColor: 'white', padding: 4 }}>
    <Label $circular color="green">
      0
    </Label>
  </div>
)
