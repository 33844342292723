import { Label, LabelGroup } from 'semantic-ui-react'
import { Transaction } from '../../../reducers/admin/allTransactions.slice'

const RowTags = ({ row }: { row: Transaction }) => (
  <LabelGroup>
    {row.manuallyCreated && <Label>Manual</Label>}
    {row.confirmedAt && <Label color="green">Confirmed</Label>}
    {row.requestedClarificationAt && (
      <Label color="red">Clarification Requested</Label>
    )}
    {row._count?.receipts && row._count.receipts > 0 && <Label>Receipts</Label>}
  </LabelGroup>
)

export default RowTags
