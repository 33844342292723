import { Grid, Divider } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  Card,
  GridRowColumn,
  Icon,
  IconButton,
  Link,
  Text,
} from '../../BaseComponents'
import { Colors } from '../../../styles/theme'
import {
  ARTICLE_TYPE,
  FinancialInsightsButtonType,
  INSIGHTS_TYPE,
  insightsCopyConstants,
} from './utils'
import { useTrackFinancialInsightsButtonClick } from '../../../utils/sharedHooks'

const getArticleCardIcon = (type: string) => {
  if (type === ARTICLE_TYPE.WEBINAR) {
    return regular('video')
  }
  return regular('newspaper')
}

export const ArticleCard = ({
  title,
  type,
  url,
  insightsType,
}: {
  title: string
  type: ARTICLE_TYPE
  url: string
  insightsType: INSIGHTS_TYPE
}) => {
  const trackInsights = useTrackFinancialInsightsButtonClick()

  const goToUrl = () => {
    window.open(url, '_blank', 'noreferrer')

    trackInsights({
      button: FinancialInsightsButtonType.LEARN_MORE_CARD,
      insightsType,
    })
  }

  return (
    <Card
      type="subsection"
      backgroundColor="stone40"
      style={{ padding: 20, height: '100%', minHeight: 140 }}
    >
      <Grid>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={1}>
            <Icon icon={getArticleCardIcon(type)} />
          </Grid.Column>
          <Grid.Column width={11}>
            <Text as="eyebrow" color="darkGray">
              {type}
            </Text>
          </Grid.Column>
          <Grid.Column width={2}>
            <IconButton
              icon={regular('arrow-up-right-from-square')}
              onClick={goToUrl}
            />
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn short>
          <Divider style={{ margin: 0, padding: 0 }} />
        </GridRowColumn>
        <GridRowColumn className="short">
          <Link
            href={url}
            newPage
            style={{ color: Colors['black'] }}
            trackMessage={insightsCopyConstants[insightsType].trackButton}
            trackAttributes={{
              button: FinancialInsightsButtonType.LEARN_MORE_CARD,
            }}
          >
            {title}
          </Link>
        </GridRowColumn>
      </Grid>
    </Card>
  )
}
