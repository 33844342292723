import { Alert, GridRowColumn } from '../../../components/BaseComponents'

const PrefilledNoticeContainer = () => (
  <GridRowColumn>
    <Alert>
      Please double-check the information we have on file, as it will be shared
      with the government in order to approve you for payroll.
    </Alert>
  </GridRowColumn>
)

export default PrefilledNoticeContainer
