import React from 'react'

// BL
import { Transaction } from '../../../../reducers/admin/allTransactions.slice'
import {
  formatCurrency,
  formatCurrencyFromCents,
} from '../../../../utils/currencyHelpers'

// UI
import { Divider, Header, Icon, Popup, Step } from 'semantic-ui-react'
const MAX_DESCRIPTION_LENGTH = 50

interface Props {
  allocationRemaining: string | number
  transaction: Transaction
}

const OriginalTransactionRow: React.FC<Props> = ({
  allocationRemaining,
  transaction,
}) => {
  const headerText =
    transaction.description.length > MAX_DESCRIPTION_LENGTH
      ? `${transaction.description.substring(0, MAX_DESCRIPTION_LENGTH)}...`
      : transaction.description

  const amountFormatted = formatCurrencyFromCents(transaction.amountInCents)

  return (
    <Step active>
      <Step.Content>
        <Popup
          content={transaction.description}
          trigger={
            <div>
              <Header as="h2">{headerText}</Header>
              <Header as="h3">{amountFormatted}</Header>
            </div>
          }
        />
        <Divider />
        <Step.Title>
          {isNaN(Number(allocationRemaining))
            ? allocationRemaining
            : formatCurrency(allocationRemaining)}
        </Step.Title>
        <Step.Description style={{ fontStyle: 'italic' }}>
          awaiting to be allocated
          <Popup
            content="Any unallocated amount will be saved as a single, lump-sum personal expense."
            trigger={
              <Icon
                name="question circle outline"
                style={{ paddingLeft: '5px' }}
              />
            }
          />
        </Step.Description>
      </Step.Content>
    </Step>
  )
}

export default OriginalTransactionRow
