import { useFormik, FormikProvider } from 'formik'
import 'react-datepicker/dist/react-datepicker.css'
import { Grid } from 'semantic-ui-react'
import * as yup from 'yup'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DateTime } from 'luxon'

import {
  Button,
  FormikDateInput,
  FormikDropdown,
  FormikInput,
  getFieldNames,
  Icon,
  Modal,
  Text,
} from '../../../components/BaseComponents'
import {
  RequestSource,
  formatDateForUpsellImportantDates,
  formatTimeForUpsellImportantDates,
} from '../../Dashboard/SCorpUpsell/utils'
import { SCorpElectionStatus } from '../../Dashboard/SCorpUpsell/sCorpStatus.slice'
import { putSCorpElectionStatus } from '../../Dashboard/SCorpUpsell/sCorpActions'
import { useAppDispatch } from '../../../utils/typeHelpers'

const MeridianOptions = [
  { text: 'AM', value: 'AM' },
  { text: 'PM', value: 'PM' },
]

export const getMeridianForDateTime = (date: string) => {
  return DateTime.fromISO(date).toFormat('a')
}

const validateTime = (value: string | undefined | null) => {
  if (!value || value === '') {
    return true
  }

  const [hoursStr, minutesStr] = value.split(':')
  const hours = parseInt(hoursStr, 10)
  const minutes = parseInt(minutesStr || '', 10)

  return !(
    isNaN(hours) ||
    isNaN(minutes) ||
    hours < 1 ||
    hours > 12 ||
    minutes < 0 ||
    minutes > 59
  )
}

const timeSchema = yup
  .string()
  .nullable()
  .test('is-valid-time', 'Invalid time format', (value) => {
    return validateTime(value)
  })

const formatToISOString = (date: string, time: string, meridian: string) => {
  if (!date || !time || !meridian) return null

  const dateString = `${date} ${time} ${meridian}`
  const luxonDate = DateTime.fromFormat(dateString, 'LL-dd-y hh:mm a', {
    zone: 'America/Los_Angeles',
  })
  return luxonDate.toUTC().toISO()
}

const timeZoneText = '(GMT-08:00) Pacific Time - Los Angeles'

const transformImportantDates = (input: {
  requestDate: string
  requestTime: string
  requestTimeMeridian: string
  form2553Date: string
  form2553Time: string
  form2553TimeMeridian: string
  cp261Date: string
  cp261Time: string
  cp261TimeMeridian: string
}) => {
  return {
    initialRequestSubmittedAt: formatToISOString(
      input.requestDate,
      input.requestTime,
      input.requestTimeMeridian
    ),
    form2553FiledAt: formatToISOString(
      input.form2553Date,
      input.form2553Time,
      input.form2553TimeMeridian
    ),
    cp261UploadedAt: formatToISOString(
      input.cp261Date,
      input.cp261Time,
      input.cp261TimeMeridian
    ),
  }
}

const EditImportantDatesModal = ({
  open,
  onClose,
  electionStatus,
}: {
  open: boolean
  onClose: () => void
  electionStatus: SCorpElectionStatus
}) => {
  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      requestDate: electionStatus.initialRequestSubmittedAt
        ? formatDateForUpsellImportantDates(
            electionStatus.initialRequestSubmittedAt,
            true
          )
        : '',
      requestTime: electionStatus.initialRequestSubmittedAt
        ? formatTimeForUpsellImportantDates(
            electionStatus.initialRequestSubmittedAt
          )
        : '',
      requestTimeMeridian: electionStatus.initialRequestSubmittedAt
        ? getMeridianForDateTime(electionStatus.initialRequestSubmittedAt)
        : '',
      form2553Date: electionStatus.form2553FiledAt
        ? formatDateForUpsellImportantDates(
            electionStatus.form2553FiledAt,
            true
          )
        : '',
      form2553Time: electionStatus.form2553FiledAt
        ? formatTimeForUpsellImportantDates(electionStatus.form2553FiledAt)
        : '',
      form2553TimeMeridian: electionStatus.form2553FiledAt
        ? getMeridianForDateTime(electionStatus.form2553FiledAt)
        : '',
      cp261Date: electionStatus.cp261UploadedAt
        ? formatDateForUpsellImportantDates(
            electionStatus.cp261UploadedAt,
            true
          )
        : '',
      cp261Time: electionStatus.cp261UploadedAt
        ? formatTimeForUpsellImportantDates(electionStatus.cp261UploadedAt)
        : '',
      cp261TimeMeridian: electionStatus.cp261UploadedAt
        ? getMeridianForDateTime(electionStatus.cp261UploadedAt)
        : '',
    },
    onSubmit: async (values) => {
      const payload = transformImportantDates(values)
      const res = await putSCorpElectionStatus({
        id: electionStatus.id,
        payload,
      })(dispatch)

      if (res) {
        onClose()
      }
    },
  })

  const { submitForm, setFieldValue } = formik
  const fieldNames = getFieldNames(formik)

  const handleClearDate = (properties: (keyof typeof fieldNames)[]) => {
    properties.forEach((property) => {
      setFieldValue(property, '')
    })
  }

  return (
    <FormikProvider value={formik}>
      <Modal open={open} onClose={onClose} closeIcon>
        <Modal.Header>Dates</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row className="short">
              <Grid.Column width={8}>Request Submitted</Grid.Column>
              <Grid.Column width={8}>
                <Text as="bodySm" color="gray">
                  {timeZoneText}
                </Text>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3} className="short">
              <Grid.Column width={8}>
                <FormikDateInput
                  name={fieldNames.requestDate}
                  fullWidth
                  maxDate={new Date()}
                  disabled={
                    electionStatus.initialRequestSource ===
                    RequestSource.upsell_card
                  }
                />
              </Grid.Column>
              <Grid.Column width={4} className="short">
                <FormikInput
                  name={fieldNames.requestTime}
                  fullWidth
                  disabled={
                    electionStatus.initialRequestSource ===
                    RequestSource.upsell_card
                  }
                  format="##:##"
                  componentType="pattern"
                  schema={timeSchema}
                />
              </Grid.Column>
              <Grid.Column width={3} className="short">
                <FormikDropdown
                  options={MeridianOptions}
                  name={fieldNames.requestTimeMeridian}
                  fullWidth
                  disabled={
                    electionStatus.initialRequestSource ===
                    RequestSource.upsell_card
                  }
                />
              </Grid.Column>
              <Grid.Column width={1}>
                <Icon icon={solid('clock')} color="gray" size="1x" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} style={{ paddingTop: 0, marginTop: 0 }}>
              <Grid.Column width={14} />
              <Grid.Column width={1}>
                {electionStatus.initialRequestSource !==
                  RequestSource.upsell_card && (
                  <Button
                    className="link"
                    style={{ float: 'right' }}
                    onClick={() => {
                      handleClearDate([
                        'requestDate',
                        'requestTime',
                        'requestTimeMeridian',
                      ])
                    }}
                  >
                    Clear
                  </Button>
                )}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="short">
              <Grid.Column width={8}>Form 2553 Filed</Grid.Column>
              <Grid.Column width={8}>
                <Text as="bodySm" color="gray">
                  {timeZoneText}
                </Text>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3} className="short">
              <Grid.Column width={8}>
                <FormikDateInput
                  name={fieldNames.form2553Date}
                  fullWidth
                  maxDate={new Date()}
                />
              </Grid.Column>
              <Grid.Column width={4} className="short">
                <FormikInput
                  name={fieldNames.form2553Time}
                  fullWidth
                  format="##:##"
                  componentType="pattern"
                  schema={timeSchema}
                  required={false}
                />
              </Grid.Column>
              <Grid.Column width={3} className="short">
                <FormikDropdown
                  name={fieldNames.form2553TimeMeridian}
                  options={MeridianOptions}
                  fullWidth
                />
              </Grid.Column>
              <Grid.Column width={1}>
                <Icon icon={solid('clock')} size="1x" color="gray" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} style={{ paddingTop: 0, marginTop: 0 }}>
              <Grid.Column width={14} />
              <Grid.Column width={1}>
                <Button
                  className="link"
                  style={{ float: 'right' }}
                  onClick={() => {
                    handleClearDate([
                      'form2553Date',
                      'form2553Time',
                      'form2553TimeMeridian',
                    ])
                  }}
                >
                  Clear
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="short">
              <Grid.Column width={8}>CP261 Received</Grid.Column>
              <Grid.Column width={8}>
                <Text as="bodySm" color="gray">
                  {timeZoneText}
                </Text>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3} className="short">
              <Grid.Column width={8}>
                <FormikDateInput
                  name={fieldNames.cp261Date}
                  fullWidth
                  maxDate={new Date()}
                />
              </Grid.Column>
              <Grid.Column width={4} className="short">
                <FormikInput
                  name={fieldNames.cp261Time}
                  fullWidth
                  format="##:##"
                  componentType="pattern"
                  schema={timeSchema}
                  required={false}
                />
              </Grid.Column>
              <Grid.Column width={3} className="short">
                <FormikDropdown
                  name={fieldNames.cp261TimeMeridian}
                  options={MeridianOptions}
                  fullWidth
                />
              </Grid.Column>
              <Grid.Column width={1}>
                <Icon icon={solid('clock')} size="1x" color="gray" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} className="short">
              <Grid.Column width={14} />
              <Grid.Column width={1}>
                <Button
                  className="link"
                  style={{ float: 'right' }}
                  onClick={() => {
                    handleClearDate([
                      'cp261Date',
                      'cp261Time',
                      'cp261TimeMeridian',
                    ])
                  }}
                >
                  Clear
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button variant="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button onClick={submitForm}>Save</Button>
        </Modal.Actions>
      </Modal>
    </FormikProvider>
  )
}

export default EditImportantDatesModal
