import React, { useState, Dispatch, SetStateAction, useEffect } from 'react'
import GridRowWrapper from './shared/GridRowWrapper'
import { Form, Button, Input, InputProps } from 'semantic-ui-react'

interface SubjectRowProps {
  editingIndex: number | null
  subject: string
  setEditingIndex: Dispatch<SetStateAction<number | null>>
  setSubject: Dispatch<SetStateAction<string>>
  disableEditing: boolean
}

const COMPONENT_INDEX = 2

const SubjectRow: React.FC<SubjectRowProps> = ({
  subject,
  editingIndex,
  setEditingIndex,
  setSubject,
  disableEditing,
}) => {
  subject = subject.trim()

  const [inputValue, setInputValue] = useState(subject)
  const buttonText = subject ? 'Edit Subject' : 'Add Subject'
  const subtitle = subject || "What's the subject line for this message?"

  useEffect(() => {
    setInputValue(subject)
  }, [subject])

  const resetForm = () => {
    setEditingIndex(null)
    setInputValue(subject)
  }

  const onInputChange = (_e: unknown, data: InputProps) => {
    setInputValue(data.value)
  }

  const onSaveClicked = () => {
    if (inputValue) {
      setSubject(inputValue)
      setEditingIndex(null)
    }
  }

  return (
    <GridRowWrapper
      buttonHidden={editingIndex === COMPONENT_INDEX}
      buttonDisabled={editingIndex !== null || disableEditing}
      buttonText={buttonText}
      subtitle={subtitle}
      title="Subject"
      checked={subject.trim().length > 0}
      buttonOnClick={() => setEditingIndex(COMPONENT_INDEX)}
    >
      {editingIndex === COMPONENT_INDEX && (
        <Form style={{ width: '300px' }}>
          <Form.Field>
            <label>Subject</label>
            <Input
              placeholder="Enter a subject"
              value={inputValue}
              onChange={onInputChange}
            />
          </Form.Field>
          <Button
            className="form-button"
            disabled={!inputValue || inputValue.trim() === ''}
            onClick={onSaveClicked}
            primary
          >
            Save
          </Button>
          <Button onClick={resetForm} basic>
            Cancel
          </Button>
        </Form>
      )}
    </GridRowWrapper>
  )
}

export default SubjectRow
