import { useMemo } from 'react'
import { Divider, Grid, Label } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import { GridRowColumn, Text, Loader } from '../../BaseComponents'
import { CurrentInfoCard } from './CurrentInfoCard'
import { CurrentSummaryCard } from './CurrentSummaryCard'
import {
  INSIGHTS_CHANGE_TYPE,
  getInsightsMonthYear,
  INSIGHTS_TYPE,
  formatSummary,
  insightsCopyConstants,
  generateSampleSummaryExpenseData,
} from './utils'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import {
  fetchInsightsSummary,
  FETCH_INSIGHTS_SUMMARY_KEY,
} from './financeActions'
import {
  useFetchResponse,
  useGetEndDateForFinancialInsights,
  useReselector,
} from '../../../utils/sharedHooks'
import { getIsFetching } from '../../../reducers/fetch'
import { DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import { isNil } from 'lodash'

export const CurrentPanel = ({
  inputDate,
  insightsType,
  shouldDisplaySampleData = false,
}: {
  inputDate?: string
  insightsType: INSIGHTS_TYPE
  shouldDisplaySampleData?: boolean
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const currentDate = inputDate
    ? DateTime.fromFormat(inputDate, DATE_FORMATS_LUXON.INPUT)
    : DateTime.now()

  const getEndDate = useGetEndDateForFinancialInsights(currentDate)

  const { month, year } = useMemo(() => {
    return getInsightsMonthYear(getEndDate())
  }, [getEndDate])

  const summaryDataResponse = useFetchResponse(
    fetchInsightsSummary,
    insightsType,
    month,
    year
  )

  const isResponseEmpty =
    isNil(summaryDataResponse) || summaryDataResponse?.length === 0

  const summaryData = shouldDisplaySampleData
    ? generateSampleSummaryExpenseData[0]
    : summaryDataResponse?.[0]

  const changeType = useMemo(() => {
    if (!summaryData) return INSIGHTS_CHANGE_TYPE.EMPTY
    const {
      percent_change_from_previous_month_decimal,
      previous_month_total_in_cents,
      target_month_total_in_cents,
    } = summaryData
    if (previous_month_total_in_cents === 0) {
      return INSIGHTS_CHANGE_TYPE.LAST_MONTH_EMPTY
    } else if (target_month_total_in_cents === 0) {
      return INSIGHTS_CHANGE_TYPE.EMPTY
    } else if (percent_change_from_previous_month_decimal > 0) {
      return INSIGHTS_CHANGE_TYPE.INCREASE
    } else if (percent_change_from_previous_month_decimal < 0) {
      return INSIGHTS_CHANGE_TYPE.DECREASE
    }
    return INSIGHTS_CHANGE_TYPE.SAME
  }, [summaryData])

  const formattedData = useMemo(() => {
    return formatSummary(summaryData, insightsType)
  }, [summaryData, insightsType])

  const queryLoading = useReselector(getIsFetching, FETCH_INSIGHTS_SUMMARY_KEY)

  return (
    <>
      <GridRowColumn>
        <Text as="h2" style={{ marginBottom: 12 }}>
          {insightsCopyConstants[insightsType].currentPanelTitle}{' '}
          {shouldDisplaySampleData && (
            <Label color="orange" style={{ borderRadius: 5 }}>
              Sample data
            </Label>
          )}
        </Text>
        <Text color="darkGray">
          {insightsCopyConstants[insightsType].trackPageDescription}
        </Text>
      </GridRowColumn>
      <GridRowColumn short>
        <Divider style={{ padding: 0, margin: 0 }} />
      </GridRowColumn>
      {queryLoading ? (
        <GridRowColumn>
          <Loader loading />
        </GridRowColumn>
      ) : (
        <Grid.Row className="short">
          <Grid.Column
            computer={4}
            tablet={4}
            mobile={16}
            style={isMobile ? { marginBottom: 12 } : undefined}
          >
            <CurrentInfoCard
              headerText={
                isResponseEmpty ? '$0.00' : formattedData?.month_total
              }
              subheader={formattedData?.month_name}
              bottomLeftText={
                formattedData?.monthly_average && 'Monthly average'
              }
              bottomRightText={
                isResponseEmpty ? '$0.00' : formattedData?.monthly_average
              }
            />
          </Grid.Column>
          <Grid.Column
            computer={4}
            tablet={4}
            mobile={16}
            style={isMobile ? { marginBottom: 12 } : undefined}
          >
            <CurrentInfoCard
              headerText={isResponseEmpty ? '$0.00' : formattedData?.year_total}
              subheader={year}
              bottomLeftText="Total"
            />
          </Grid.Column>
          <Grid.Column
            computer={8}
            tablet={8}
            mobile={16}
            style={isMobile ? { marginBottom: 24 } : undefined}
          >
            <CurrentSummaryCard
              headerText={insightsCopyConstants[insightsType].label}
              insightsType={insightsType}
              type={changeType}
              currentMonth={formattedData?.month_name}
              prevMonth={formattedData?.previous_month_name}
              amount={formattedData?.month_total}
              percentChange={formattedData?.percent_change}
              percentCounterpart={formattedData?.percent_counterpart_change}
              isResponseEmpty={isResponseEmpty}
              prevMonthTotal={formattedData?.previous_month_total}
            />
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  )
}
