import React from 'react'
import { orderBy } from 'lodash'
import moment from 'moment'
import { Message, Table } from 'semantic-ui-react'

import { ChartOfAccount } from '../../ChartOfAccounts/chartOfAccount.slice'
import { TransactionCategory } from '../../Reports/reports.slice'
import { TransactionCategoryRow } from '../../../components/Admin/TransactionCategoriesTable'
import { DATE_FORMATS } from '../../../utils/dateHelpers'

interface Props {
  coa?: ChartOfAccount
  categories?: { [key: number]: TransactionCategory }
}

const ChartOfAccountsTable: React.FC<Props> = ({ coa, categories }) => {
  if (!categories) {
    return null
  }

  let categoriesSorted: TransactionCategory[] = []

  coa?.chartOfAccountCategories.forEach((c) => {
    if (categories[c.transactionCategoryId]) {
      categoriesSorted.push(categories[c.transactionCategoryId])
    }
  })

  // Sort by non-archived first, then by calendar year
  categoriesSorted = orderBy(
    categoriesSorted,
    ['archivedAt', 'name'],
    ['desc', 'asc']
  )

  return (
    <>
      {coa?.archivedAt && (
        <Message
          warning
          header="This Chart of Accounts is no longer active"
          content={`It was archived on ${moment(coa?.archivedAt).format(
            DATE_FORMATS.DISPLAY_LONG
          )}`}
        />
      )}
      <Table compact basic>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Category Name</Table.HeaderCell>
            <Table.HeaderCell>Account Type</Table.HeaderCell>
            <Table.HeaderCell>Financial Statement</Table.HeaderCell>
            <Table.HeaderCell>Date Added</Table.HeaderCell>
            <Table.HeaderCell>Customer Account Label</Table.HeaderCell>
            <Table.HeaderCell>Archived Date</Table.HeaderCell>
            <Table.HeaderCell>Edit</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {categoriesSorted.map((category, index) => {
            let addedOn = ''
            const coaCategory = coa?.chartOfAccountCategories.find(
              (c) => c.transactionCategoryId === category.id
            )

            if (coaCategory) {
              addedOn = moment(coaCategory?.createdAt).format(
                DATE_FORMATS.DISPLAY_SHORT
              )
            }

            if (!category) {
              return null
            }

            return (
              <TransactionCategoryRow
                key={index}
                auxColumn={addedOn}
                category={category}
              />
            )
          })}
        </Table.Body>
      </Table>
    </>
  )
}

export default ChartOfAccountsTable
