import { useEffect } from 'react'
import { Grid } from 'semantic-ui-react'

import { GridRowColumn, Text } from '../../../../components/BaseComponents'
import {
  TaxSeasonKickoffStepProps,
  TAX_SURVEY_CONTENT_SCREENS,
} from './AnnualTaxSurvey'
import FormFlowFooter from '../../../../components/FormFlow/FormFlowFooter'
import ImportantDates from '../components/ImportantDates'
import { useAnalyticsView } from '../../../Amplitude'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'

const ImportantDatesPanel = ({
  year,
  goBack,
  goToNextStep,
}: TaxSeasonKickoffStepProps) => {
  const pageView = useAnalyticsView()
  useEffect(() => {
    pageView('TSK important dates')
  }, [pageView])

  return (
    <Grid>
      <GridRowColumn {...makeGridConfig([10, 14], true)}>
        <Text as="display2" textAlign="center">
          Important Tax Dates Timeline
        </Text>
      </GridRowColumn>

      <ImportantDates year={year} />

      <FormFlowFooter
        onBack={() => goBack(TAX_SURVEY_CONTENT_SCREENS.contractorNec)}
        onForward={() => goToNextStep(null, TAX_SURVEY_CONTENT_SCREENS.review)}
      />
    </Grid>
  )
}

export default ImportantDatesPanel
