import { Icon } from 'semantic-ui-react'

const CalculationTableLink = ({ url, text }: { url: string; text: string }) => (
  <a
    target={'_blank'}
    rel="noreferrer"
    style={{
      color: 'grey',
      paddingTop: '1em',
      display: 'block',
      width: '100%',
    }}
    href={url}
  >
    {text}
    <Icon style={{ float: 'right' }} name="external alternate" />
  </a>
)

export default CalculationTableLink
