import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { omit } from 'lodash'

import { PartialAdmin, updateCurrentUser, User } from './auth/userReducer'
import { RoleName } from './admin/allRolesReducer'
import { ReduxState } from '../utils/typeHelpers'
import { ConversationUser } from '../features/Conversations/actions'

interface TeamMember {
  id: number
  firstName: string
  lastName: string
  avatarUrl?: string | null
  roleId?: number
  roleName?: RoleName
}

interface TeamMemberState {
  [key: number]: TeamMember
}

const flattenChildUser = (admin: PartialAdmin, roleName: RoleName) => {
  const role = admin.roles.find(({ name }) => name === roleName)

  return {
    id: admin.id,
    firstName: admin.firstName,
    lastName: admin.lastName,
    avatarUrl: role?.UserRole.avatarUrl,
    roleId: role?.id,
    roleName: role?.name,
  }
}

// This is pulled out because it redux initialization (in session.ts) doesn't go through redux actions
export const transformUserToTeamMembers = (user: Partial<User>) => {
  const teamMemberState: TeamMemberState = {}

  if (user.owner) {
    teamMemberState[user.owner.id] = flattenChildUser(
      user.owner,
      RoleName.AccountManager
    )
  }

  if (user.bookkeeper) {
    teamMemberState[user.bookkeeper.id] = flattenChildUser(
      user.bookkeeper,
      RoleName.Bookkeeper
    )
  }

  return teamMemberState
}

const taxTeamSlice = createSlice({
  name: 'TeamMember',
  initialState: {} as TeamMemberState,
  reducers: {
    // Roles come back with a current user already.  This action is mostly for filling in the missing ones
    addConversationUsers: (
      state,
      action: PayloadAction<ConversationUser[]>
    ) => {
      for (const user of action.payload) {
        // When users are received we don't know what role to pick so don't overwrite this
        if (state[user.id]) {
          state[user.id] = {
            ...state[user.id],
            ...omit(user, 'roles'),
          }
        } else {
          // If the user is missing that means they aren't part of a user's team.  Just take their first role and display that
          state[user.id] = {
            ...state[user.id],
            ...omit(user, 'roles'),
            roleId: user.roles[0]?.id,
            roleName: user.roles[0]?.name,
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateCurrentUser, (state, action) => ({
      ...state,
      ...transformUserToTeamMembers(action.payload),
    }))
  },
})

export const { addConversationUsers } = taxTeamSlice.actions

export default taxTeamSlice.reducer

// For ALL tax team members.  Think of as smaller admin DB
export const selectTaxTeam = (state: ReduxState) => state.taxTeam

export const selectTaxTeamMemberById = createSelector(
  selectTaxTeam,
  (_: unknown, id?: number | string | null) => id,
  (taxTeam, id) => (id && taxTeam[Number(id)] ? taxTeam[Number(id)] : undefined)
)
