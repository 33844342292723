import { useEffect, useMemo, useState } from 'react'
import {
  fetchFinancialAccountsIfNeeded,
  getAccountUploadStatuses,
  StatementStatus,
  StatementStatusType,
} from '../../../actions/financialAccountActions'
import {
  selectEligibleForLBA,
  selectEligibleForPlaidStatements,
} from '../../../selectors/financeSelectors'
import { useReselector } from '../../../utils/sharedHooks'
import { Button, Card, GridRowColumn, Link, Text } from '../../BaseComponents'
import { Grid } from 'semantic-ui-react'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useNavigate } from 'react-router-dom'

export enum FaAvailableConnectionType {
  PLAID_STATEMENTS = 'plaid_statements',
  LBA = 'lba',
  CREATE_NEW_ACCOUNT = 'create_new_account',
}
export const useFinancialAccountsAutomaticStatementsCard = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  // opt-out of lower friction statements for this component
  // until after initial onboarding dashboard launch
  const enableLowerFrictionStatements = false
  const eligibleForPlaidStatements = useReselector(
    selectEligibleForPlaidStatements,
    enableLowerFrictionStatements
  )
  const [accountUploadStatuses, setAccountUploadStatuses] =
    useState<StatementStatus[]>()

  const eligibleForLBA = useReselector(
    selectEligibleForLBA,
    accountUploadStatuses
  )

  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchFinancialAccountsIfNeeded())
      const statusesResponse = await dispatch(getAccountUploadStatuses())
      if (statusesResponse) {
        setAccountUploadStatuses(statusesResponse)
      }
    }
    fetchData()
  }, [dispatch])

  const faAccountAlertType = useMemo(() => {
    const noSupport = accountUploadStatuses?.every(
      (status) => status.status === StatementStatusType.NO_SUPPORT
    )
    if (eligibleForPlaidStatements) {
      return FaAvailableConnectionType.PLAID_STATEMENTS
    } else if (eligibleForLBA) {
      return FaAvailableConnectionType.LBA
    } else if (noSupport) {
      return FaAvailableConnectionType.CREATE_NEW_ACCOUNT
    } else {
      return undefined
    }
  }, [accountUploadStatuses, eligibleForPlaidStatements, eligibleForLBA])

  const financialAccountAlert = useMemo(() => {
    if (!faAccountAlertType) {
      return null
    }
    let alertBody
    let alertButton

    switch (faAccountAlertType) {
      case FaAvailableConnectionType.PLAID_STATEMENTS: {
        alertBody =
          'Connect your bank through Plaid to automatically upload all your statements each month.'
        alertButton = (
          <Button
            variant="secondary"
            onClick={() => {
              localStorage.setItem('plaidStatementReloginModalShown', 'false')
              navigate('/accounts')
            }}
          >
            Connect bank
          </Button>
        )
        break
      }
      case FaAvailableConnectionType.LBA: {
        alertBody =
          'You can grant Heard limited bank access through your bank, and it will allow us to automatically upload your statements each month.'
        //update with proper link
        alertButton = (
          <Link
            newPage
            href="https://support.joinheard.com/hc/en-us/articles/18202072914071-What-is-limited-bank-access"
          >
            <Button variant="secondary" onClick={() => {}}>
              Learn More
            </Button>
          </Link>
        )
        break
      }
      case FaAvailableConnectionType.CREATE_NEW_ACCOUNT: {
        alertBody =
          'By opening up a separate business bank account that is supported by Plaid, we can save your time by automatically uploading your statements each month.'
        alertButton = (
          <Link
            newPage
            href={
              'https://support.joinheard.com/hc/en-us/articles/23569956149399-Bank-Statements-at-Heard'
            }
          >
            <Button onClick={() => {}} className="secondary">
              Learn more
            </Button>
          </Link>
        )
        break
      }
      default:
        break
    }

    return (
      <Card backgroundColor="natural" variant="noShadow">
        <Grid>
          <GridRowColumn>
            <Text as="h3">
              Save even more time with auto-upload of monthly statements
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="bodyMd">{alertBody}</Text>
          </GridRowColumn>
          <GridRowColumn>{alertButton}</GridRowColumn>
        </Grid>
      </Card>
    )
  }, [faAccountAlertType, navigate])

  useEffect(() => {
    const fetchData = async () => {
      const statusesResponse = await dispatch(getAccountUploadStatuses())
      if (statusesResponse) {
        setAccountUploadStatuses(statusesResponse)
      }
    }
    fetchData()
  }, [dispatch])
  return {
    financialAccountAlert,
  }
}
