import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { keyBy } from 'lodash'

import { fetchIfNeededWrapper } from '../../../reducers/fetch'

export interface AnnualTaxFormType {
  id: number
  name: string
  description: null | string
  displayName: string
  createdAt: string
  updatedAt: string
  displayOrder: number | null
}

export interface AnnualTaxFormTypeState {
  [key: string]: AnnualTaxFormType
}

const annualTaxFormTypesSlice = createSlice({
  name: 'annualTaxFormTypes',
  initialState: {} as AnnualTaxFormTypeState,
  reducers: {
    getAnnualTaxFormTypes: (
      state,
      action: PayloadAction<{ [key: string]: AnnualTaxFormType }>
    ) => ({ ...state, ...action.payload }),
  },
})

export default annualTaxFormTypesSlice.reducer

// Actions
const { getAnnualTaxFormTypes } = annualTaxFormTypesSlice.actions

export const FETCH_ANNUAL_TAX_FORM_TYPES_KEY = 'FETCH_ANNUAL_TAX_FORM_TYPES_KEY'
export const fetchAnnualTaxFormTypesIfNeeded = () =>
  fetchIfNeededWrapper({
    defaultErrorMessage: 'Error fetching all annual tax form types',
    fetchKey: FETCH_ANNUAL_TAX_FORM_TYPES_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<AnnualTaxFormType[]>(
        '/finances/api/v1/annual_tax_filing_forms/types'
      )

      dispatch(getAnnualTaxFormTypes(keyBy(json.data, 'id')))
      return json.data
    },
  })
