import axios from 'axios'

import { logSentryError } from '../utils/sentryHelpers'

export type UserRecordNote = {
  id: number
  userId: number
  authorId: number
  category: string
  text: string
  createdAt: string
  updatedAt: string
  notesAuthor?: {
    firstName: string
    lastName: string
  }
}

export const fetchUserRecordNotes = async (userId: number) => {
  try {
    return await axios.get<UserRecordNote[]>(
      `/finances/api/v1/admin/user_record_notes/${userId}`
    )
  } catch (err) {
    logSentryError(err)
    return false
  }
}

export const createUserRecordNotes = async (
  userId: number,
  text: string,
  category: string
) => {
  try {
    const json = await axios.post<UserRecordNote>(
      '/finances/api/v1/admin/user_record_notes',
      {
        userId,
        category,
        text,
      }
    )
    return json.data
  } catch (err) {
    logSentryError(err)
    return false
  }
}

export const deleteUserRecordNote = async (noteId: number) => {
  try {
    return await axios.delete<{ message: string }>(
      `/finances/api/v1/admin/user_record_notes/${noteId}`
    )
  } catch (err) {
    logSentryError(err)
    return false
  }
}

export const sendSlackNotification = async (
  userId: number,
  text: string,
  category: string
) => {
  category = category.replace(/<[^>]+>/g, '')
  text = text.replace(/<[^>]+>/g, '')

  try {
    const isProduction = process.env.VITE_IS_PROD === 'true'
    if (isProduction) {
      const body = `Category: ${category} \n Note: ${text} \n See record here: https://dashboard.joinheard.com/admin/finances/records/${userId}`
      return await fetch(
        'https://hooks.slack.com/services/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',
        {
          method: 'POST',
          body: JSON.stringify({ text: body }),
        }
      )
    }
    return false
  } catch (err) {
    logSentryError(err)
    return false
  }
}
