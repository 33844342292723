import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Divider, Grid, Image } from 'semantic-ui-react'

import {
  Card,
  GridRowColumn,
  Link,
  Text,
} from '../../../../../components/BaseComponents'
import { Transaction } from '../../../../../reducers/admin/allTransactions.slice'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../utils/sharedHooks'
import {
  distinctTransactionsByDateDESC,
  getUserNeedsClarificationTransactionsWithoutUserNoteForYear,
  selectUncategorizedTransactionsNotNeedClarification,
} from '../../../../Transactions/transactions.selectors'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import { UpdateYourBooksStepProps } from './UpdateYourBooks'
import { TransactionCard } from '../../../../Transactions/Lists/transaction-card'
import { selectAnnualTaxFilingFormById } from '../../annualTaxFilingForms.selector'
import { Colors, Fonts } from '../../../../../styles/theme'
import { TransactionHeader } from '../../../../Transactions/Lists/transaction-header'
import Tooltip from '../../../QuarterlyTaxEstimates/components/Tooltip'
import { SubStepIdentifiers } from '../Shared/ReviewStepsandProgresses/stepProgress.helpers'

const CategorizeTransactionsStep = ({
  goForward,
  goBack,
}: UpdateYourBooksStepProps) => {
  const { formId } = useParams()
  const filingForm = useReselector(selectAnnualTaxFilingFormById, formId)

  const uncatTransactions = useReselector(
    selectUncategorizedTransactionsNotNeedClarification,
    filingForm?.year
  )

  const needsNoteTransactions = useReselector(
    getUserNeedsClarificationTransactionsWithoutUserNoteForYear,
    filingForm?.year
  )

  const transactionIds = useMemo(
    () => [
      ...uncatTransactions.map((t) => t.id),
      ...needsNoteTransactions.map((t) => t.id),
    ],
    [needsNoteTransactions, uncatTransactions]
  )

  // Copy of transactions upon step start
  const [uncatTransAtStart, setUncatTransAtStart] = useState<Transaction[]>([])
  useEffect(() => {
    if (uncatTransactions.length && !uncatTransAtStart.length) {
      // Note - the above logic assumes uncategorized transactions and needs clarification transactions have been fetched
      // from the server by a higher component. Otherwise we could run into timing issues and would need a loading flag
      setUncatTransAtStart(distinctTransactionsByDateDESC(uncatTransactions))
    }
  }, [uncatTransAtStart.length, uncatTransactions])

  const [needsNoteTransAtStart, setNeedsNoteTransAtStart] = useState<
    Transaction[]
  >([])
  useEffect(() => {
    if (needsNoteTransactions.length && !needsNoteTransAtStart.length) {
      setNeedsNoteTransAtStart(
        distinctTransactionsByDateDESC(needsNoteTransactions)
      )
    }
  }, [needsNoteTransAtStart.length, needsNoteTransactions])

  const updatedTransactionsIds = useMemo(
    () =>
      [...uncatTransAtStart, ...needsNoteTransAtStart].flatMap((t) =>
        !transactionIds.includes(t.id) ? t.id : []
      ),
    [needsNoteTransAtStart, transactionIds, uncatTransAtStart]
  )

  const saveAndContinue = useAsyncCallback(() =>
    goForward({ completedSteps: [SubStepIdentifiers.clarifyTransactions] })
  )

  return (
    <Grid>
      <Grid.Row />
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/tq_1120_s.svg"
          style={{ width: 212, height: 162 }}
        />
      </GridRowColumn>
      <GridRowColumn spacer={3} width={10}>
        <Text as="h1" textAlign="center">
          How should we categorize these transactions?
        </Text>
      </GridRowColumn>
      <GridRowColumn spacer={3} width={10}>
        <Text>
          Your bookkeeper needs clarity on the transactions below. Please select
          a category or add a note if you’re unsure.
        </Text>
      </GridRowColumn>
      <GridRowColumn spacer={3} width={10}>
        <Tooltip
          popup={{
            title: 'Why do I need to do this?',
            body: 'Reviewing your transactions allows the Heard Team to finish your year-end bookkeeping, minimize how much you’ll owe in taxes, and ensure an accurate tax return.',
          }}
          labelComponent="Why do I need to do this?"
          style={{
            display: 'inline-block',
            borderBottom: `2px dashed ${Colors.green}`,
            ...Fonts.bodySm,
          }}
        />
      </GridRowColumn>
      <GridRowColumn spacer={3} width={10}>
        <Link
          newPage
          href="https://support.joinheard.com/hc/en-us/articles/10547782596759-How-do-I-know-which-category-to-use-"
        >
          How do I know which category to use?
        </Link>
      </GridRowColumn>
      <Grid.Row />
      {!uncatTransAtStart.length && !needsNoteTransAtStart.length && (
        <GridRowColumn>
          <Card backgroundColor="lightGreen">
            <Text>
              All transactions have been categorized. You may continue to the
              next step.
            </Text>
          </Card>
        </GridRowColumn>
      )}

      {Boolean(uncatTransAtStart.length) && (
        <>
          <GridRowColumn>
            <Text as="h1">Missing Category</Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Text color="darkGray">
              Select the correct category, or if you are unsure, add a note for
              your bookkeeper.
            </Text>
          </GridRowColumn>
          <TransactionHeader />
          <GridRowColumn>
            {uncatTransAtStart.map((t) => (
              <TransactionCard
                key={t.id}
                transactionId={t.id}
                backgroundColor={
                  updatedTransactionsIds.includes(t.id)
                    ? 'lightGreen'
                    : undefined
                }
              />
            ))}
          </GridRowColumn>
        </>
      )}

      {Boolean(uncatTransAtStart.length && needsNoteTransAtStart.length) && (
        <GridRowColumn>
          <Divider />
        </GridRowColumn>
      )}

      {Boolean(needsNoteTransAtStart.length) && (
        <>
          <GridRowColumn>
            <Text as="h1">Needs Review</Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Text color="darkGray">
              Your bookkeeper needs information around these transactions. Add a
              note to give your bookkeeper more context.
            </Text>
          </GridRowColumn>
          <TransactionHeader />
          <GridRowColumn>
            {needsNoteTransAtStart.map((t) => (
              <TransactionCard
                key={t.id}
                transactionId={t.id}
                backgroundColor={
                  updatedTransactionsIds.includes(t.id)
                    ? 'lightGreen'
                    : undefined
                }
              />
            ))}
          </GridRowColumn>
        </>
      )}

      <FormFlowFooter
        onBack={goBack}
        onForward={saveAndContinue.callback}
        loading={saveAndContinue.loading}
        continueDisabled={
          Boolean(uncatTransactions.length || needsNoteTransactions.length) ||
          saveAndContinue.loading
        }
      />
    </Grid>
  )
}

export default CategorizeTransactionsStep
