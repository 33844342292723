import { ReactNode, useState } from 'react'

/*
  Component structure  
    - SplitTransactionButton x 1
    - Modal x 1
      - SplitTransactionForm x 1
        - OriginalTransactionRow x 1
        - SplitTransactionRow x 2+
*/

// BL
import { Transaction } from '../../../../reducers/admin/allTransactions.slice'

// UI
import { Button, Icon, Modal, Popup } from 'semantic-ui-react'
import SplitTransactionForm from './SplitTransactionForm'

interface Props {
  loading: boolean
  toggleGroup: () => void
  transaction: Transaction
  onUpdate?: (transaction: Transaction) => void
  children: ReactNode
}

export const SplitTransactionIcon = ({
  transaction,
}: {
  transaction: Transaction
}) => {
  const splitIds = transaction.splitTransactions
    ? transaction.splitTransactions
        .map((t) => t.id)
        .reduce((prev, curr, _index, array) => {
          let separator = ', '
          if (array.length === 1 || !prev) {
            separator = ''
          }

          return prev + separator + curr
        }, '')
    : ''

  return transaction.splitAt ? (
    <Popup
      position="top right"
      content={`This transaction was split into ${
        transaction.splitTransactions?.length || 0
      } transactions [${splitIds}]`}
      hoverable
      trigger={<Icon name="fork" />}
    />
  ) : null
}

export const SplitTransactionButton = ({
  loading,
  toggleGroup,
  transaction,
  onUpdate = (_: Transaction) => undefined,
  children,
}: Props) => {
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  const onSplitClick = () => {
    if (transaction.splitFrom) {
      alert(
        'This transaction cannot be split. It originates from a split transaction.'
      )
      return
    }

    if (transaction.splitAt) {
      alert(
        'We cannot split this transaction because it has already been split.'
      )
      return
    }

    openModal()
  }

  const onCancel = () => {
    if (transaction.confirmedAt) {
      toggleGroup()
    }
    closeModal()
  }

  const onSaveSuccess = (updatedTransaction?: Transaction) => {
    if (updatedTransaction) {
      onUpdate(updatedTransaction)
    }
    closeModal()
  }

  return (
    <>
      <Button loading={loading} disabled={loading} onClick={onSplitClick}>
        {children}
      </Button>

      {/* conditionally rendering the modal so it's not included with each row by default */}
      {open && (
        <Modal
          centered={false}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          open={open}
          size="large"
        >
          <Modal.Header>Split a transaction</Modal.Header>
          <Modal.Content>
            <SplitTransactionForm
              onSaveSuccess={onSaveSuccess}
              onCancel={onCancel}
              transaction={transaction}
            />
          </Modal.Content>
        </Modal>
      )}
    </>
  )
}
