import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  ChildcareProvider,
  DELETE_CHILDCARE_PROVIDERS_KEY,
  UPSERT_CHILDCARE_PROVIDERS_KEY,
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  deleteChildcareProviders,
  deleteTaxQuestionnaireResponses,
  fetchChildcareProviders,
} from '../../taxChecklistQuestion.actions'
import { DeductionsAndCreditsStepProps } from '.'
import { TaxListQuestionId } from '../../service'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../../utils/sharedHooks'
import {
  selectTaxListQuestionResponseGroup,
  selectTaxListQuestionResponsesByQuestionIds,
} from '../../taxChecklist.selectors'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { ChildcareProviderForm } from './ChildcareProviderForm'
import {
  Button,
  GridRowColumn,
  Icon,
  Link,
  Text,
} from '../../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import { UPDATE_USER_EOY_REVIEW_PROGRESS_KEY } from '../../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'

export const dependentCareQuestionIds = [
  TaxListQuestionId.childcare_provider_name,
  TaxListQuestionId.childcare_provider_address,
  TaxListQuestionId.childcare_provider_ein,
  TaxListQuestionId.childcare_provider_expenses_amount_in_cents,
]

const DependentCareExpensesPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: DeductionsAndCreditsStepProps) => {
  const dispatch = useAppDispatch()
  const [childcareProviders, setChildcareProviders] = useState<
    Partial<ChildcareProvider>[]
  >([])
  const { formId } = useParams()
  const allDependentCareResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    dependentCareQuestionIds,
    Number(formId)
  )

  const groups = useReselector(
    selectTaxListQuestionResponseGroup,
    dependentCareQuestionIds,
    [Number(formId)],
    'childcareProviderId'
  )

  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    UPSERT_CHILDCARE_PROVIDERS_KEY,
    DELETE_CHILDCARE_PROVIDERS_KEY,
    UPDATE_USER_EOY_REVIEW_PROGRESS_KEY,
  ])

  const loadProviders = useCallback(async () => {
    const existing = await fetchChildcareProviders()(dispatch)
    if (existing?.length && existing.length > 0) {
      setChildcareProviders(existing)
    }
  }, [dispatch])

  useEffect(() => {
    loadProviders()
  }, [loadProviders])

  const removeProvider = useCallback(
    ({
      index,
      childcareProviderId,
    }: {
      index: number
      childcareProviderId?: number
    }) => {
      let success = true
      if (childcareProviderId) {
        const responsesToDelete = allDependentCareResponses.flatMap((r) =>
          r.childcareProviderId === childcareProviderId ? [r.id] : []
        )
        success = Boolean(
          dispatch(deleteTaxQuestionnaireResponses(responsesToDelete))
        )
        if (success) {
          success = Boolean(
            dispatch(deleteChildcareProviders(childcareProviderId))
          )
        }
      }
      if (success) {
        setChildcareProviders((old) => {
          const newList = [...old]
          newList.splice(index, 1)
          return newList
        })
      }
    },
    [dispatch, allDependentCareResponses]
  )

  //Check to see if there is a name, address, ein, and total amount for each childcare provider
  const everyFormIsValid = useMemo(() => {
    const groupArray = Object.values(groups)
    return (
      groupArray.length > 0 &&
      groupArray.every(
        (g) =>
          Boolean(
            Object.values(g).find(({ responses }) =>
              Boolean(
                responses.find(
                  (r) =>
                    r.questionId === TaxListQuestionId.childcare_provider_name
                )?.value
              )
            )
          ) &&
          Boolean(
            Object.values(g).find(({ responses }) =>
              Boolean(
                responses.find(
                  (r) =>
                    r.questionId ===
                    TaxListQuestionId.childcare_provider_address
                )?.value
              )
            )
          ) &&
          Boolean(
            Object.values(g).find(({ responses }) =>
              Boolean(
                responses.find(
                  (r) =>
                    r.questionId === TaxListQuestionId.childcare_provider_ein
                )?.value
              )
            )
          ) &&
          Boolean(
            Object.values(g).find(({ responses }) =>
              Boolean(
                responses.find(
                  (r) =>
                    r.questionId ===
                    TaxListQuestionId.childcare_provider_expenses_amount_in_cents
                )?.value
              )
            )
          )
      )
    )
  }, [groups])

  const goForward = useAsyncCallback(() =>
    goToNextStep(null, nextScreen ?? null, {
      completedSteps: [SubStepIdentifiers.dependentCare],
    })
  )

  return (
    // skipcq: JS-0415
    <>
      <Grid>
        <GridRowColumn>
          <Text as="display2" textAlign="center">
            Dependent Care Expenses
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <Text as="bodyLg">
            If you paid a daycare center, babysitter, summer camp, or other care
            provider to care for a qualifying child under age 13 or a disabled
            dependent of any age, you may qualify for a tax credit of up to 35%
            of:
            <ul>
              <li>
                Up to $3,000 of qualifying expenses (for a maximum credit of
                $1,050) for one child or dependent, or
              </li>
              <li>
                Up to $6,000 of qualifying (for a maximum credit of $2,100) for
                two or more children or dependents.
              </li>
            </ul>
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <Link
            newPage
            href="https://support.joinheard.com/hc/en-us/articles/11882774339607-What-s-the-Child-and-Dependent-Care-Credit-"
          >
            Learn more about this credit
            <Icon
              icon={light('square-arrow-up-right')}
              style={{ marginLeft: 6 }}
            />
          </Link>
        </GridRowColumn>
        {childcareProviders.map((c, index) => (
          <GridRowColumn key={c.id ?? index} {...makeGridConfig([8, 14], true)}>
            <ChildcareProviderForm
              index={index}
              childcareProvider={c}
              removeChildcareProvider={removeProvider}
              goToNextStep={goToNextStep}
              loadChildcareProviders={loadProviders}
            />
          </GridRowColumn>
        ))}
        <GridRowColumn
          columnStyle={{ display: 'flex', justifyContent: 'center' }}
        >
          <Button
            variant="secondary"
            onClick={() => setChildcareProviders((old) => [...old, {}])}
          >
            <Icon size="1x" icon={regular('plus')} style={{ marginRight: 8 }} />
            <Text>Add Provider</Text>
          </Button>
        </GridRowColumn>
        <Grid.Row />
      </Grid>
      <Grid>
        <FormFlowFooter
          continueDisabled={
            isUpdating || !everyFormIsValid || goForward.loading
          }
          onBack={() =>
            goBack(
              previousScreen ?? null,
              !everyFormIsValid ? SubStepIdentifiers.dependentCare : undefined
            )
          }
          onForward={goForward.callback}
          loading={isUpdating || goForward.loading}
        />
      </Grid>
    </>
  )
}

export default DependentCareExpensesPanel
