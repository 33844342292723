import { FC, useMemo, useEffect, useState } from 'react'
import { Card, Dimmer, Loader } from 'semantic-ui-react'
import { BOOKKEEPING_REPORT_STATUSES } from '../../../constants/businessConstants'
import { getBookkeeperStatistics } from './actions'
import {
  BookkeepingReportStatistics,
  ReportByMonth,
  getCountByStatus,
} from './helpers'
import { useAppDispatch } from '../../../utils/typeHelpers'

const MONTHS_ARR = [
  { month: '12', name: 'Dec' },
  { month: '11', name: 'Nov' },
  { month: '10', name: 'Oct' },
  { month: '9', name: 'Sept' },
  { month: '8', name: 'Aug' },
  { month: '7', name: 'Jul' },
  { month: '6', name: 'Jun' },
  { month: '5', name: 'May' },
  { month: '4', name: 'Apr' },
  { month: '3', name: 'Mar' },
  { month: '2', name: 'Feb' },
  { month: '1', name: 'Jan' },
]

interface Props {
  year: string
  bookkeeperId: string | number
}

const SummationCards: FC<Props> = ({ year, bookkeeperId }) => {
  const [stats, setStats] = useState<BookkeepingReportStatistics | undefined>()
  const [loading, setLoading] = useState(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    const fetchData = async () => {
      const statData = await getBookkeeperStatistics(
        year,
        bookkeeperId
      )(dispatch)
      if (statData) {
        setStats(statData)
      }
      setLoading(false)
    }
    setLoading(true)
    fetchData()
  }, [bookkeeperId, year, dispatch])

  const summationData = useMemo(() => {
    if (!stats?.totalReportsByStatus || !stats?.totalReportsByMonth) return []

    const { totalReportsByStatus, totalReportsByMonth } = stats

    const processedStats = MONTHS_ARR.map((monthObj) => {
      const totalBooks =
        totalReportsByMonth.find(
          (report: ReportByMonth) => report.month === monthObj.month
        )?.count ?? '0'

      const categorized = getCountByStatus(
        totalReportsByStatus,
        monthObj.month,
        BOOKKEEPING_REPORT_STATUSES.Categorized.name
      )

      const completed = getCountByStatus(
        totalReportsByStatus,
        monthObj.month,
        BOOKKEEPING_REPORT_STATUSES.Done.name
      )

      const inProgress = getCountByStatus(
        totalReportsByStatus,
        monthObj.month,
        BOOKKEEPING_REPORT_STATUSES.InProgress.name
      )

      const plaidError = getCountByStatus(
        totalReportsByStatus,
        monthObj.month,
        BOOKKEEPING_REPORT_STATUSES.PlaidError.name
      )

      const missingInfo = getCountByStatus(
        totalReportsByStatus,
        monthObj.month,
        BOOKKEEPING_REPORT_STATUSES.MissingInfo.name
      )

      const notStarted = getCountByStatus(
        totalReportsByStatus,
        monthObj.month,
        BOOKKEEPING_REPORT_STATUSES.NotStarted.name
      )

      return {
        month: monthObj.month,
        name: monthObj.name,
        totalBooks,
        categorized,
        completed,
        inProgress,
        plaidError,
        missingInfo,
        notStarted,
      }
    })
    return processedStats
  }, [stats])

  if (loading) {
    return (
      <Dimmer active inverted>
        <Loader inverted>Loading statistics...</Loader>
      </Dimmer>
    )
  }

  return (
    <Card.Group className="summationCards" itemsPerRow={4} stackable doubling>
      {summationData?.map((data) => {
        const calculatePercentage = (x: string, y: string) => {
          if (!y || y === '0') return 0
          return ((parseInt(x) / parseInt(y)) * 100).toFixed(2)
        }

        return (
          <Card key={data.month}>
            <Card.Header>
              {data.name}, {year}
            </Card.Header>
            <Card.Content className="crmCard">
              <p>Categorized ({data.categorized})</p>
              <p>{calculatePercentage(data.categorized, data.totalBooks)}%</p>
            </Card.Content>
            <Card.Content className="crmCard">
              <p>Completed ({data.completed})</p>
              <p>{calculatePercentage(data.completed, data.totalBooks)}%</p>
            </Card.Content>
            <Card.Content className="crmCard">
              <p>In Progress ({data.inProgress})</p>
              <p>{calculatePercentage(data.inProgress, data.totalBooks)}%</p>
            </Card.Content>
            <Card.Content className="crmCard">
              <p>Plaid Error ({data.plaidError})</p>
              <p>{calculatePercentage(data.plaidError, data.totalBooks)}%</p>
            </Card.Content>
            <Card.Content className="crmCard">
              <p>Missing Info ({data.missingInfo})</p>
              <p>{calculatePercentage(data.missingInfo, data.totalBooks)}%</p>
            </Card.Content>
            <Card.Content className="crmCard">
              <p>Not Started ({data.notStarted})</p>
              <p>{calculatePercentage(data.notStarted, data.totalBooks)}%</p>
            </Card.Content>
          </Card>
        )
      })}
    </Card.Group>
  )
}

export default SummationCards
