import { useMemo } from 'react'
import { Grid } from 'semantic-ui-react'
import moment from 'moment'
import { groupBy } from 'lodash'
import { DateTime } from 'luxon'

import {
  Accordion,
  Card,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectActiveQuarterlyTaxEstimateDetails } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { selectPreviousQuarterTaxEstimatesForYear } from '../../QuarterlyTaxEstimates/userTaxEstimates.selector'
import EstimateCard from '../../AnnualTaxes/TaxChecklist/Form1040/AddMissingQTEPayments/EstimateCard'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import { useCompleteQTEActionItemIfNeeded } from '../../QuarterlyTaxEstimates/service'

const QTE_WITHHOLDING_ENABLED =
  process.env.VITE_QTE_WITHHOLDING_ENABLED === 'true'

const PreviousTaxPayments = () => {
  const activeQuarterDetails = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )

  const currentQuarter = Number(activeQuarterDetails?.taxQuarter ?? 1)
  const year = Number(activeQuarterDetails?.taxYear ?? DateTime.now().year)

  const estimates = useReselector(
    selectPreviousQuarterTaxEstimatesForYear,
    year,
    currentQuarter
  )
  const groupedEstimates = useMemo(
    () => Object.entries(groupBy(estimates, (estimate) => estimate.taxQuarter)),
    [estimates]
  )

  useCompleteQTEActionItemIfNeeded()

  return (
    <Grid>
      <GridRowColumn {...makeGridConfig([12, 16, 16], true)}>
        <Text as="display2" textAlign="center">
          {QTE_WITHHOLDING_ENABLED
            ? 'Update your quarterly tax payments to receive your estimated tax payment'
            : `If you haven’t already, please update your ${year} quarterly tax payments`}
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([12, 16, 16], true)} centerContent>
        <Accordion
          title="Why does Heard need this?"
          content={
            QTE_WITHHOLDING_ENABLED
              ? 'Updating your quarterly tax payments helps Heard calculate a more accurate estimate for you: we predict how much you’ll owe in taxes for this year, and then subtract actual payments already made.'
              : 'Updating past quarterly payments helps Heard calculate a more accurate estimate for you: we predict how much you’ll owe in taxes for this year, and then subtract actual payments already made.'
          }
          variant="text"
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        />
      </GridRowColumn>

      {groupedEstimates.map(([key, estimates]) => (
        <GridRowColumn key={key}>
          <Card backgroundColor="stone40">
            <Text as="h2">{moment(key, 'YYYY-Q').format('[Q]Q YYYY')}</Text>
            {estimates.map((estimate) => (
              <EstimateCard key={estimate.id} estimate={estimate} />
            ))}
          </Card>
        </GridRowColumn>
      ))}
    </Grid>
  )
}

export default PreviousTaxPayments
