import CalendlyPopupModal from '../../../../components/shared/CalendlyPopupModal'
import { useAnalyticsTrack } from '../../../Amplitude'
import { useFinancialAccountsAutomaticStatementsCard } from '../../../../components/Finances/Accounts/automaticStatements.hooks'
import { Modal } from '../../../../components/BaseComponents'
import { Colors } from '../../../../styles/theme'
import { markUserActionItemCompleteIfExists } from '../../UserActionItems/service'
import { ScheduleOnboardingCallStatus } from '../../UserActionItems/userActionItemStatuses'
import { useCompleteOnboardingStepIfNeeded } from '../../../Onboarding/UserOnboardingSteps/onboarding.hooks'
import { GettingStartedOnboardingStep } from '../../../Onboarding/UserOnboardingSteps/onboarding.reducer'
import { OnboardingTaskIdents } from '../../../Onboarding/config'
import { useFetchResponse, useReselector } from '../../../../utils/sharedHooks'
import { getNeededBankStatementsForCatchupBookkeeping } from '../../../UserDocuments/userDocuments.slice'
import ViewMissingStatementsAccordion from '../../../../components/shared/ViewMissingStatementsAccordion'
import { selectOnboardingStepCompletedOrSkipped } from '../../../Onboarding/UserOnboardingSteps/onboarding.selectors'

export const GroupOnboardingModal = ({
  open = false,
  setOpen,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const groupOnboardingUrl = process.env.VITE_ONBOARDING_MEETING_LINK
  const track = useAnalyticsTrack()
  const onboardingStepCompletion = useCompleteOnboardingStepIfNeeded(
    GettingStartedOnboardingStep.joinGroupOnboardingSession
  )
  const stepCompleted = useReselector(
    selectOnboardingStepCompletedOrSkipped,
    GettingStartedOnboardingStep.joinGroupOnboardingSession
  )
  const shouldOpen = !stepCompleted && open

  return groupOnboardingUrl ? (
    <CalendlyPopupModal
      url={groupOnboardingUrl}
      open={shouldOpen}
      onClose={() => setOpen(false)}
      onScheduled={async () => {
        await markUserActionItemCompleteIfExists(
          OnboardingTaskIdents.SCHEDULE_ONBOARDING_MEETING,
          track,
          ScheduleOnboardingCallStatus.scheduled
        )
        await onboardingStepCompletion.markComplete()
        track('completed onboarding item schedule call', {
          status: 'done',
        })
      }}
    />
  ) : null
}

export const AutomaticStatementUploadModal = ({
  open = false,
  setOpen,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const automaticStatementUploadCard =
    useFinancialAccountsAutomaticStatementsCard()
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Modal.Content style={{ backgroundColor: Colors.natural }}>
        {automaticStatementUploadCard.financialAccountAlert}
      </Modal.Content>
    </Modal>
  )
}

export const ViewMissingStatementsAccordionForCatchupBookkeeping = () => {
  const missingStatements = useFetchResponse(
    getNeededBankStatementsForCatchupBookkeeping
  )
  if (!missingStatements || missingStatements.length === 0) {
    return null
  }
  return (
    <ViewMissingStatementsAccordion
      neededStatements={missingStatements}
      title={'View missing statements'}
    />
  )
}
