import { useParams } from 'react-router-dom'
import { Grid, Image } from 'semantic-ui-react'

import {
  GridRowColumn,
  Text,
} from '../../../../../../components/BaseComponents'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectTaxListQuestion } from '../../taxChecklist.selectors'
import { TaxListQuestionId } from '../../service'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { LIFE_CHANGES_SCREENS, LifeChangesStepProps } from '.'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import ReviewSubSection from '../../Shared/ReviewSubSection'
import { soldHomeQuestionIds } from './SoldHomePanel'
import { lifeChangesQuestionIds } from './LifeChangesPanel'
import { purchasedHomeQuestionIds } from './PurchasedHomePanel'
import { miscQuestionIds } from './MiscQuestionsPanel'
import { useNavigateWithLocation } from '../../../../../../utils/routeHelpers'
import { selectTqIsReadOnly } from '../../../annualTaxFilings.selector'

const LifeChangesReviewPanel = ({
  goBack,
  previousScreen,
}: LifeChangesStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const navigate = useNavigateWithLocation()
  const { formId } = useParams()
  const readOnly = useReselector(selectTqIsReadOnly, formId)
  const questionSoldHome = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.sold_home,
    taxYear
  )
  const questionPurchasedHome = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_purchase,
    taxYear
  )

  return (
    <Grid>
      <GridRowColumn
        columnStyle={{ display: 'flex', justifyContent: 'center' }}
      >
        <Image src="https://heard-images.s3.amazonaws.com/assets/magnifying-glass-checkmark.svg" />
      </GridRowColumn>
      <GridRowColumn>
        <Text as="display2" textAlign="center">
          Review
        </Text>
      </GridRowColumn>
      {!readOnly && (
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <Text as="bodyLg">
            Review your answers for accuracy. Click edit to go back to the
            previous section.
          </Text>
        </GridRowColumn>
      )}
      {readOnly && (
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <Text as="bodyLg">
            You can no longer edit your Personal Tax Questionnaire responses,
            but you can view them anytime.
          </Text>
        </GridRowColumn>
      )}
      <Grid.Row />
      <ReviewSubSection
        title="Tell us about your year"
        questionIds={lifeChangesQuestionIds}
        editScreen={LIFE_CHANGES_SCREENS.lifeChanges}
      />
      {questionSoldHome?.responses?.[0]?.value && (
        <ReviewSubSection
          title="Sold a home"
          questionIds={soldHomeQuestionIds}
          editScreen={LIFE_CHANGES_SCREENS.soldHome}
        />
      )}
      {questionPurchasedHome?.responses?.[0]?.value && (
        <ReviewSubSection
          title="Purchased a home"
          questionIds={purchasedHomeQuestionIds}
          editScreen={LIFE_CHANGES_SCREENS.purchasedHome}
        />
      )}
      <ReviewSubSection
        title="Miscellaneous Questions"
        questionIds={miscQuestionIds}
        editScreen={LIFE_CHANGES_SCREENS.miscQuestions}
      />
      {!readOnly && (
        <FormFlowFooter
          isSubmit
          onBack={() => goBack(previousScreen ?? null)}
          onForward={() => navigate(`/taxes/annual/tax_checklist/${formId}`)}
        />
      )}
    </Grid>
  )
}

export default LifeChangesReviewPanel
