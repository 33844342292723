import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import {
  Button,
  GridRowColumn,
  Modal,
  Text,
  TextArea,
} from '../../../../components/BaseComponents'
import { useSaveAnnualTaxFilingFormData } from '../helpers'
import { ConfirmAnnualTaxFiling } from '../annualTaxFilings.slice'

const SurveyTaxFormHelpModal = ({
  year,
  open,
  close,
  confirmedFormNeeds,
}: {
  year: string
  open: boolean
  close: () => void
  confirmedFormNeeds?: ConfirmAnnualTaxFiling | null
}) => {
  const navigate = useNavigate()
  const [textInput, setTextInput] = useState('')
  const saveTaxFiling = useSaveAnnualTaxFilingFormData(year)

  const saveUnsure = useCallback(() => {
    saveTaxFiling({
      confirmedFormNeeds,
      surveyLastUpdatedAt: new Date().toISOString(),
      message: textInput,
    })
    navigate('/taxes/annual')
  }, [confirmedFormNeeds, navigate, saveTaxFiling, textInput])

  return (
    <Modal closeIcon onClose={close} open={open} size="tiny">
      <Modal.Header>We’re here to help</Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text as="bodyLg">
              Once you submit your message, the Tax Season Kickoff will be
              paused. When your situation has been resolved, you can continue.
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <TextArea
              onChange={setTextInput}
              value={textInput}
              placeholder="How can we help?"
            />
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={close}>
          Cancel
        </Button>
        <Button disabled={!textInput} onClick={saveUnsure}>
          Send
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default SurveyTaxFormHelpModal
