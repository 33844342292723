import { useMemo, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import { createIsSomeEnum, useAppDispatch } from '../../../../utils/typeHelpers'
import { TAX_PROFILE_STEP, TaxesProfileFlowAnswers } from '../FormFlow/helpers'
import { useReselector, useScrollRef } from '../../../../utils/sharedHooks'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../../utils/deviceWidthHelpers'
import { fetchFinancialProfile } from '../../../../actions/financialProfileActions'
import {
  GridRowColumn,
  ProgressBarNoTitles,
  ProgressBar,
  Text,
} from '../../../../components/BaseComponents'
import { fetchAllQuarterlyTaxEstimateDetailsIfNeeded } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import { useAnalyticsTrack } from '../../../Amplitude'
import { fetchUserTaxEstimates } from '../../QuarterlyTaxEstimates/userTaxEstimates.slice'
import PersonalTaxIncomeIntro from '../FormFlow/PersonalTaxIncomeIntro'
import Spouse1099Income from '../FormFlow/Spouse1099Income'
import SpouseW2Form from '../FormFlow/SpouseW2Form'
import SpouseWithholdingForm from '../FormFlow/SpouseWithholdingForm'
import Ten99Income from '../FormFlow/Ten99Income'
import WithholdingsForm from '../FormFlow/WithholdingsForm'
import { selectActiveQuarterlyTaxEstimateDetails } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import FinishRecalculation from './FinishRecalculation'
import RecalculationAboutYouForm from './RecalculationAboutYouForm'
import { centsToDollars } from '../../../../utils/currencyHelpers'
import { getFinancialProfile } from '../../../../selectors/user.selectors'
import ScorpW2PrivatePracticeIncome from '../FormFlow/ScorpW2PrivatePracticeIncome'
import W2IncomeOutsideOfPractice from '../FormFlow/W2IncomeOutsideOfPractice'
import ScorpAdditionalW2Income from '../FormFlow/ScorpAdditionalW2Income'
import { defaultQTEFormFlowAnswers } from '../FormFlow'

const isStep = createIsSomeEnum(TAX_PROFILE_STEP)

const RecalculationProfileFlow = () => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const activeQuarterDetails = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )
  const { scrollRef, scrollToRef } = useScrollRef({ autoScroll: true })
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const track = useAnalyticsTrack()

  const fp = useReselector(getFinancialProfile)

  // This state is used to conditionally render the currency inputs in the form
  // If a user has not submitted their profile this quarter:
  //   1. the inputs will initially be empty
  //   2. when the user saves the data (continues to the next step),
  //      the inputs will be saved to this state so that they can be pre-filled if the user uses the back button
  // If a user has submitted their profile this quarter:
  //  1. the inputs will be pre-filled with the values from the financial profile.
  //  2. when the user saves the data (continues to the next step), the inputs will be saved to this state
  //     so that they can be pre-filled with the most up-to-date data if the user uses the back button
  const [formFlowAnswers, setFormFlowAnswers] =
    useState<TaxesProfileFlowAnswers>(defaultQTEFormFlowAnswers)

  useEffect(() => {
    if (fp) {
      setFormFlowAnswers({
        federalWithholding: fp.federalWithholdingInCents
          ? centsToDollars(fp.federalWithholdingInCents)
          : null,
        stateWithholding: fp.stateWithholdingInCents
          ? centsToDollars(fp.stateWithholdingInCents)
          : null,
        wIncome: fp.wIncomeInCents ? centsToDollars(fp.wIncomeInCents) : null,
        spouseWIncome: fp.spouseWIncomeInCents
          ? centsToDollars(fp.spouseWIncomeInCents)
          : null,
        spouseFederalWithholding: fp.spouseFederalWithholdingInCents
          ? centsToDollars(fp.spouseFederalWithholdingInCents)
          : null,
        spouseStateWithholding: fp.spouseStateWithholdingInCents
          ? centsToDollars(fp.spouseStateWithholdingInCents)
          : null,
        otherIndividualIncome: fp.otherIndividualIncomeInCents
          ? centsToDollars(fp.otherIndividualIncomeInCents)
          : null,
        spouseIndividualIncome: fp.spouseIndividualIncomeInCents
          ? centsToDollars(fp.spouseIndividualIncomeInCents)
          : null,
        scorpPracticeWIncome: fp.scorpPracticeWIncomeInCents
          ? centsToDollars(fp.scorpPracticeWIncomeInCents)
          : null,
        estimatedAnnualScorpPracticeW2Income:
          fp.estimatedAnnualScorpPracticeW2IncomeInCents
            ? centsToDollars(fp.estimatedAnnualScorpPracticeW2IncomeInCents)
            : null,
        estimatedAnnualW2Income: fp.estimatedAnnualW2IncomeInCents
          ? centsToDollars(fp.estimatedAnnualW2IncomeInCents)
          : null,
        estimatedAnnualSpouseW2Income: fp.estimatedAnnualSpouseW2IncomeInCents
          ? centsToDollars(fp.estimatedAnnualSpouseW2IncomeInCents)
          : null,
      })
    }
  }, [fp])

  const stepParam = searchParams.get('step')
  const step = isStep(stepParam) ? stepParam : TAX_PROFILE_STEP.aboutYou

  const stepNumber = useMemo(() => {
    switch (step) {
      case TAX_PROFILE_STEP.finish:
        return 2
      case TAX_PROFILE_STEP.personalTaxIncomeIntro:
      case TAX_PROFILE_STEP.scorpW2Earnings:
      case TAX_PROFILE_STEP.scorpAdditionalW2Income:
      case TAX_PROFILE_STEP.incomeOutsideOfPractice:
      case TAX_PROFILE_STEP.withholdings:
      case TAX_PROFILE_STEP.ten99Income:
      case TAX_PROFILE_STEP.spouseW2:
      case TAX_PROFILE_STEP.spouseWithholding:
      case TAX_PROFILE_STEP.spouse1099Income:
        return 1
      case TAX_PROFILE_STEP.aboutYou:
        return 0
      default:
        return step satisfies never
    }
  }, [step])

  useEffect(() => {
    dispatch(fetchFinancialProfile())
    dispatch(fetchUserTaxEstimates())
    dispatch(fetchAllQuarterlyTaxEstimateDetailsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    scrollToRef()
  }, [scrollToRef])

  useEffect(() => {
    track('qte recalculation view', { step })
    if (step === TAX_PROFILE_STEP.finish) {
      track('resubmitted tax info for recalculation', {
        qte_quarter: activeQuarterDetails?.taxQuarter ?? 'undefined',
        qte_states: activeQuarterDetails?.status ?? 'undefined',
      })
    }
  }, [
    activeQuarterDetails?.status,
    activeQuarterDetails?.taxQuarter,
    step,
    track,
  ])

  const content = useMemo(() => {
    const formFlowAnswerProps = {
      formFlowAnswers,
      setFormFlowAnswers,
    }
    //these components are shared with the Taxes Profile form flow. Updating them will affect both flows
    switch (step) {
      case TAX_PROFILE_STEP.aboutYou:
        return <RecalculationAboutYouForm />
      case TAX_PROFILE_STEP.personalTaxIncomeIntro:
        return <PersonalTaxIncomeIntro />
      case TAX_PROFILE_STEP.scorpW2Earnings:
        return <ScorpW2PrivatePracticeIncome {...formFlowAnswerProps} />
      case TAX_PROFILE_STEP.scorpAdditionalW2Income:
        return <ScorpAdditionalW2Income {...formFlowAnswerProps} />
      case TAX_PROFILE_STEP.incomeOutsideOfPractice:
        return <W2IncomeOutsideOfPractice {...formFlowAnswerProps} />
      case TAX_PROFILE_STEP.withholdings:
        return <WithholdingsForm {...formFlowAnswerProps} />
      case TAX_PROFILE_STEP.ten99Income:
        return <Ten99Income {...formFlowAnswerProps} />
      case TAX_PROFILE_STEP.spouseW2:
        return <SpouseW2Form {...formFlowAnswerProps} />
      case TAX_PROFILE_STEP.spouseWithholding:
        return <SpouseWithholdingForm {...formFlowAnswerProps} />
      case TAX_PROFILE_STEP.spouse1099Income:
        return <Spouse1099Income {...formFlowAnswerProps} />
      case TAX_PROFILE_STEP.finish:
        return <FinishRecalculation />
      default:
        return step satisfies never
    }
  }, [step, formFlowAnswers])

  return (
    <>
      <span ref={scrollRef} />
      <Grid>
        <GridRowColumn>
          <Text as="h3">
            Your New Q{activeQuarterDetails?.taxQuarter} Federal Tax Estimate
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          {isMobile ? (
            <ProgressBarNoTitles currentStep={stepNumber} totalSteps={3} />
          ) : (
            <ProgressBar
              currentStep={stepNumber}
              steps={['About You', 'Taxable Income', 'Submit']}
            />
          )}
        </GridRowColumn>
        <GridRowColumn>{content}</GridRowColumn>
      </Grid>
    </>
  )
}

export default RecalculationProfileFlow
