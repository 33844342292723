import { CSSProperties, ReactNode } from 'react'
import moment from 'moment'
import { Divider, List } from 'semantic-ui-react'

import CurrencyFormatLabel from '../../../../components/shared/CurrencyFormatLabel'
import { centsToDollars } from '../../../../utils/currencyHelpers'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectUsesYTDW2Input,
  selectUserTaxCalculationById,
} from '../../../../selectors/userTaxCalculation.selectors'
import { Text } from '../../../../components/BaseComponents'

const dashesOrDollars = (val: number | null) =>
  val === null ? (
    <Text>--</Text>
  ) : (
    <CurrencyFormatLabel value={centsToDollars(val)} />
  )

const ListRow = ({
  header,
  value,
  valueStyle,
}: {
  header: ReactNode
  value?: ReactNode
  valueStyle?: CSSProperties
}) => (
  <List.Item>
    <List.Content verticalAlign="middle" floated="right" style={valueStyle}>
      <Text>{value}</Text>
    </List.Content>
    <List.Header>
      <Text>
        <b>{header}</b>
      </Text>
    </List.Header>
  </List.Item>
)

const TaxCalculationInputFigures = ({ id }: { id: number }) => {
  const calc = useReselector(selectUserTaxCalculationById, id)
  const usesYTDW2Input = useReselector(selectUsesYTDW2Input, id)

  if (!calc) {
    return null
  }

  return (
    <List style={{ width: '400px', padding: '2em' }}>
      <ListRow header="Filing States" value={calc.filingStates.join(', ')} />
      <ListRow header="Home State" value={calc.homeState} />
      <ListRow header="Filing Status" value={calc.filingStatus} />
      <ListRow header="Tax Entity Type" value={calc.taxEntityType ?? 'N/A'} />
      <ListRow
        header="% Income/State"
        value={JSON.stringify(calc.percentageIncomePerState)}
        valueStyle={{ maxWidth: '170px' }}
      />

      <Divider />
      <ListRow header={<i>Income from P&L</i>} />
      <ListRow
        header="Annualized Net Income"
        value={
          <CurrencyFormatLabel
            value={centsToDollars(calc.annualizedNetIncomeInCents)}
          />
        }
      />
      <Divider fitted />

      <ListRow
        header="Annualized Income"
        value={
          <CurrencyFormatLabel
            value={centsToDollars(calc.annualizedIncomeInCents)}
          />
        }
      />
      <ListRow
        header="Annualized Expenses"
        value={
          <CurrencyFormatLabel
            value={centsToDollars(calc.annualizedExpensesInCents)}
          />
        }
      />
      <ListRow
        header="Period Income"
        value={
          <CurrencyFormatLabel
            value={centsToDollars(calc.periodIncomeInCents)}
          />
        }
      />
      <ListRow
        header="Period Expenses"
        value={
          <CurrencyFormatLabel
            value={centsToDollars(calc.periodExpensesInCents)}
          />
        }
      />
      <Divider />

      <ListRow
        header={
          <i>
            1099 Income outside of Heard <br /> (not included in Annualized Net
            Income)
          </i>
        }
      />
      <ListRow
        header="Other 1099 Income (Self)"
        value={
          <CurrencyFormatLabel
            value={centsToDollars(calc.otherIndividualIncomeInCents)}
          />
        }
      />
      <ListRow
        header="Other 1099 Income (Spouse)"
        value={
          <CurrencyFormatLabel
            value={centsToDollars(calc.spouseIndividualIncomeInCents)}
          />
        }
      />
      <Divider />

      <ListRow header={<i>W2 Income</i>} />
      <ListRow
        header={`W2 Income ${usesYTDW2Input ? '(YTD)' : '(Expected Annual)'}`}
        value={dashesOrDollars(calc.wIncomeInCents)}
      />
      <ListRow
        header="W2 Income (Estimated)"
        value={dashesOrDollars(calc.estimatedAnnualW2IncomeInCents)}
      />
      <Divider />
      <ListRow
        header="Annualized W2 Income"
        value={dashesOrDollars(calc.annualizedWIncomeInCents)}
      />
      <br />

      <ListRow
        header="Scorp Practice W2 Income (YTD)"
        value={dashesOrDollars(calc.scorpPracticeWIncomeInCents)}
      />
      <ListRow
        header="Scorp Practice W2 Income (Estimated)"
        value={dashesOrDollars(
          calc.estimatedAnnualScorpPracticeW2IncomeInCents
        )}
      />
      <Divider />
      <ListRow
        header="Annualized S Scorp Practice Income"
        value={dashesOrDollars(calc.annualizedScorpPracticeWIncomeInCents)}
      />
      <br />

      <ListRow
        header={`Spouse W2 Income ${usesYTDW2Input ? '(YTD)' : '(Expected Annual)'}`}
        value={dashesOrDollars(calc.spouseWIncomeInCents)}
      />
      <ListRow
        header="Spouse W2 Income (Estimated)"
        value={dashesOrDollars(calc.estimatedAnnualSpouseW2IncomeInCents)}
      />

      <Divider />
      <ListRow
        header="Annualized Spouse W2 Income"
        value={dashesOrDollars(calc.annualizedSpouseWIncomeInCents)}
      />
      <br />

      <ListRow
        header={`Total W2 Income ${usesYTDW2Input ? '(projected annual)' : '(Expected Annual)'}`}
        value={dashesOrDollars(
          usesYTDW2Input
            ? calc.annualizedTotalWIncomeInCents
            : calc.totalWIncomeInCents
        )}
      />
      <Divider />

      <ListRow header={<i>Withholding</i>} />
      <ListRow
        header="Federal Withholding"
        value={dashesOrDollars(calc.federalWithholdingInCents)}
      />
      <ListRow
        header="Spouse Federal Withholding YTD"
        value={
          <CurrencyFormatLabel
            value={centsToDollars(calc.spouseFederalWithholdingInCents)}
          />
        }
      />
      <ListRow
        header="Total Federal Withholding YTD"
        value={
          <CurrencyFormatLabel
            value={centsToDollars(calc.totalFederalWithholdingInCents)}
          />
        }
      />
      <Divider />

      <ListRow
        header="State Withholding YTD"
        value={
          <CurrencyFormatLabel
            value={centsToDollars(calc.stateWithholdingInCents)}
          />
        }
      />
      <ListRow
        header="Spouse State Withholding YTD"
        value={
          <CurrencyFormatLabel
            value={centsToDollars(calc.spouseStateWithholdingInCents)}
          />
        }
      />
      <ListRow
        header="Total State Withholding YTD"
        value={
          <CurrencyFormatLabel
            value={centsToDollars(calc.totalStateWithholdingInCents)}
          />
        }
      />
      <Divider />

      <ListRow
        header="Self Employment Tax"
        value={
          <CurrencyFormatLabel
            value={calc.taxCalculationOutput?.data.selfEmploymentTax}
          />
        }
      />

      <List.Item style={{ padding: '2em 0' }}>
        <List.Content>
          <Text as="eyebrow">
            As of{' '}
            {moment(calc.plInputsUpdatedAt).format(DATE_FORMATS.DISPLAY_FULL)}
          </Text>
        </List.Content>
      </List.Item>
    </List>
  )
}

export default TaxCalculationInputFigures
