import { Text } from '../../BaseComponents'
import {
  DeviceWidth,
  DeviceWidthCombo,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'

export const ResponsiveHeader = ({ content }: { content: string }) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  return <Text as={isMobile ? 'h3' : 'h2'}> {content} </Text>
}

export const ComputerOnlyHeader = ({ content }: { content: string }) => {
  const isMobileOrTablet = useIsDeviceWidth(DeviceWidthCombo.mobileOrTablet)
  if (isMobileOrTablet) return null
  return <Text as="h1"> {content} </Text>
}
