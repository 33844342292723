import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Divider, Icon } from 'semantic-ui-react'
import moment from 'moment'

import { useReselector } from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { fetchFinancialAccountsIfNeeded } from '../../../actions/financialAccountActions'
import { fetchPlaidItemsIfNeeded } from '../../../actions/plaidItemActions'
import { selectDisconnectedFinancialAccounts } from '../../../selectors/financeSelectors'
import { selectCurrentAnnualTaxYear } from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { selectMissingBankStatementMonthsForYear } from '../../UserDocuments/userDocuments.selector'
import { getSCorpSetUpStatuses } from '../../Dashboard/SCorpUpsell/sCorpActions.selector'
import {
  getUserNeedsClarificationTransactionsWithoutUserNoteForYear,
  selectUserTransactionsByCategoryIdentifierForYear,
} from '../../Transactions/transactions.selectors'
import { fetchFilteredUserTransactions } from '../../Transactions/transactions.slice'
import { fetchTransactionCategoriesIfNeeded } from '../../Reports/reports.slice'
import {
  TransactionCategoryIdentifier,
  Transaction,
} from '../../../reducers/admin/allTransactions.slice'

import { ProgressBarNoTitles } from '../../../components/BaseComponents/ProgressBar'
import Text from '../../../components/BaseComponents/Text'
import { NavButtons } from './nav-buttons'
import { IStep, STEPS } from './step-map'
import './styles.scss'

interface IMissingBankStatement {
  account_id: string
  months: string[]
}

interface IUnclarifiedTransactions {
  bookkeeperClarification: Transaction[]
  uncatagorized: Transaction[]
}

export const EndOfYearReviewContainer = () => {
  const [currentStep, setCurrentStep] = useState<IStep | null>(null)
  const [currentStepIdx, setCurrentStepIdx] = useState<number>(0)
  const [userSteps, setUserSteps] = useState<IStep[]>([])
  const [prevStep, setPrevStep] = useState<string>('')
  const [nextStep, setNextStep] = useState<string>('')
  const [isStepComplete, setIsStepComplete] = useState<boolean>(true)
  const year = useReselector(selectCurrentAnnualTaxYear)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchFinancialAccountsIfNeeded())
      await dispatch(fetchPlaidItemsIfNeeded())
    }

    fetch()
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchTransactionCategoriesIfNeeded())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      fetchFilteredUserTransactions({
        startDate: moment().year(parseInt(year)).startOf('year'),
        endDate: moment().year(parseInt(year)).endOf('year'),
      })
    )
  }, [dispatch, year])

  const disconnectedBankAccounts = useReselector(
    selectDisconnectedFinancialAccounts
  )

  const missingBankStatements = useReselector(
    selectMissingBankStatementMonthsForYear,
    year
  )

  const unclarifiedTransactions = useReselector(
    getUserNeedsClarificationTransactionsWithoutUserNoteForYear,
    year
  )

  // const uncatagorized = useReselector(
  //   selectUncategorizedTransactionsNotNeedClarification,
  //   year
  // )

  const otherExpenses = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.other_expenses,
    year
  )

  const otherIncome = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.other_income,
    year
  )

  const isSCorp = Object.keys(useReselector(getSCorpSetUpStatuses)).length !== 0

  const getMissingBankStatements = (
    statements: string[]
  ): IMissingBankStatement[] => {
    return statements.map((statement) => ({
      account_id: statement,
      months: [],
    }))
  }

  const getUnclarifiedTransactions = (
    transactions: Transaction[]
  ): IUnclarifiedTransactions => {
    return transactions.reduce(
      (acc, transaction) => {
        // @ts-expect-error mocking out step state for now
        acc.bookkeeperClarification.push(transaction)
        return acc
      },
      { bookkeeperClarification: [], uncatagorized: [] }
    )
  }

  const userBookkeepingStatus = useMemo(() => {
    return {
      disconnectedBankAccounts,
      missingBankStatements: getMissingBankStatements(missingBankStatements),
      unclarifiedTransactions: getUnclarifiedTransactions(
        unclarifiedTransactions
      ),
      otherExpenses,
      otherIncome,
      isSCorp,
      lastEoyBookkeepingScreen: '/base/year/step_slug',
    }
  }, [
    disconnectedBankAccounts,
    missingBankStatements,
    unclarifiedTransactions,
    otherExpenses,
    otherIncome,
    isSCorp,
  ])

  useMemo(() => {
    const steps = STEPS.filter((step) => {
      return step.isRequired(userBookkeepingStatus)
    })

    setUserSteps(steps)
  }, [userBookkeepingStatus])

  useEffect(() => {
    setCurrentStep(STEPS[currentStepIdx])
  }, [currentStepIdx])

  useEffect(() => {
    setIsStepComplete(userSteps[currentStepIdx]?.isAlwaysComplete || false)
    const prevStep =
      currentStepIdx === 0 ? 'foo' : userSteps[currentStepIdx - 1]?.route
    const nextStep =
      currentStepIdx === userSteps.length - 1
        ? 'foo'
        : userSteps[currentStepIdx + 1]?.route

    setPrevStep(prevStep)
    setNextStep(nextStep)
  }, [currentStepIdx, userSteps])

  const handlePrevStep = () => {
    setCurrentStepIdx((prev) => prev - 1)
    navigate(`/bookkeeping/end-of-year-review/${year}/${prevStep}`)
  }

  const handleNextStep = () => {
    setCurrentStepIdx((prev) => prev + 1)
    navigate(`/bookkeeping/end-of-year-review/${year}/${nextStep}`)
  }

  const handlSaveAndExit = () => {
    // PENDING desired nav path from Business
    navigate('/foo')
  }

  const getCurrentStepComponent = () => {
    if (currentStep === null) {
      return STEPS[0].component
    }

    return userSteps[currentStepIdx].component
  }

  const getCurrentProps = () => {
    if (userSteps?.[currentStepIdx] === null) {
      return {}
    }

    if (userSteps?.[currentStepIdx]?.props !== null) {
      return {
        ...(userSteps[currentStepIdx]?.props(userBookkeepingStatus) || {}),
      }
    }

    return {}
  }

  return (
    <div className="eoy-review_wrapper">
      <header className="header">
        <Text>{year} End of Year Review</Text>
        <div className="autosave-wrapper">
          <Icon name="check" style={{ color: '#457634' }} />
          <Text style={{ color: '#457634' }}>Autosaved</Text>
        </div>
      </header>

      <ProgressBarNoTitles
        currentStep={currentStepIdx}
        totalSteps={userSteps.length}
      />

      <div className="step-body">
        {/* @ts-expect-error temp type issue resoltion - ticket hea-5043 */}
        {React.createElement(getCurrentStepComponent(), {
          ...getCurrentProps(),
          ...(currentStep?.isAlwaysComplete ? {} : { setIsStepComplete }),
        })}
      </div>

      <Divider style={{ margin: 0 }} />

      <NavButtons
        isStepComplete={isStepComplete}
        stepBack={handlePrevStep}
        stepForward={handleNextStep}
        saveAndExit={handlSaveAndExit}
      />
    </div>
  )
}
