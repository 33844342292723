import { useParams } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'
import { Grid, Image } from 'semantic-ui-react'
import * as yup from 'yup'

import {
  FormikInput,
  getFieldName,
  GridRowColumn,
  Link,
  makeNumberSchema,
  Text,
} from '../../../../../../components/BaseComponents'
import {
  DEDUCTIONS_AND_CREDITS_SCREENS,
  DeductionsAndCreditsStepProps,
} from '.'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { TaxListQuestionId } from '../../service'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../../taxChecklist.selectors'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { UPSERT_USER_TAX_QUESTIONNAIRE_KEY } from '../../taxChecklistQuestion.actions'
import { useMemo } from 'react'
import { CalculationAlert } from '../../Shared/SpecialtyAlerts'

export const homeOfficeStandardStep1QuestionIds = [
  TaxListQuestionId.home_office_sq_ft_for_business,
  TaxListQuestionId.home_office_sq_ft_total,
]

const validationSchema = yup.object({
  squareFtUsedForBusiness: makeNumberSchema({ field: 'square footage' }),
  totalSquareFtOfHome: makeNumberSchema({ field: 'square footage' }).test(
    'testSizes',
    'Office square footage should be less than total square footage',
    function compareSizes(totalSquareFtOfHome) {
      const { squareFtUsedForBusiness } = this.parent
      return (
        totalSquareFtOfHome &&
        squareFtUsedForBusiness &&
        Number(totalSquareFtOfHome) >= Number(squareFtUsedForBusiness)
      )
    }
  ),
})

const HomeOfficeDeductionsStandardStep1Panel = ({
  goBack,
  goToNextStep,
}: DeductionsAndCreditsStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()

  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  ])

  const questionSquareFootageBusiness = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_office_sq_ft_for_business,
    taxYear
  )
  const responseSquareFootageBusiness = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_sq_ft_for_business,
    Number(formId)
  )

  const questionSquareFootageTotal = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_office_sq_ft_total,
    taxYear
  )
  const responseSquareFootageTotal = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_sq_ft_total,
    Number(formId)
  )

  const formik = useFormik({
    initialValues: {
      squareFtUsedForBusiness:
        responseSquareFootageBusiness?.[0]?.value ?? undefined,
      totalSquareFtOfHome: responseSquareFootageTotal?.[0]?.value ?? undefined,
    },
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      if (
        values.squareFtUsedForBusiness !==
        responseSquareFootageBusiness?.[0]?.value
      ) {
        responseData.push({
          id: responseSquareFootageBusiness?.[0]?.id,
          value: Number(values.squareFtUsedForBusiness ?? 0),
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.home_office_sq_ft_for_business,
        })
      }
      if (
        values.totalSquareFtOfHome !== responseSquareFootageTotal?.[0]?.value
      ) {
        responseData.push({
          id: responseSquareFootageTotal?.[0]?.id,
          value: Number(values.totalSquareFtOfHome ?? 0),
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.home_office_sq_ft_total,
        })
      }
      await goToNextStep(
        responseData,
        DEDUCTIONS_AND_CREDITS_SCREENS.homeOfficeStandardStep2
      )
    },
  })

  const { values, submitForm, isValid, errors, isSubmitting } = formik

  const percentage = useMemo(
    () =>
      !errors.squareFtUsedForBusiness &&
      !errors.totalSquareFtOfHome &&
      values.squareFtUsedForBusiness &&
      values.totalSquareFtOfHome
        ? (
            (100 * Number(values.squareFtUsedForBusiness ?? 0)) /
            Number(values.totalSquareFtOfHome ?? 0)
          ).toFixed(0)
        : '--',
    [values, errors]
  )

  return (
    <Grid>
      <Grid.Row />
      <Grid.Row />
      <GridRowColumn>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/big-house.svg"
          style={{ margin: 'auto', width: 120 }}
        />
      </GridRowColumn>
      <GridRowColumn>
        <Text as="display2" textAlign="center">
          Step 1: Standard Method
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([6, 14], true)}>
        <Text as="bodyLg">
          In this two-step process, we’ll ask you for the square footage of your
          home office, as well as total amounts spent across a number of
          categories.
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([6, 14], true)}>
        <Link
          href="https://support.joinheard.com/hc/en-us/articles/10270233571607-Home-Office-Deductions-FAQs"
          newPage
        >
          Home Office Deduction FAQs
        </Link>
      </GridRowColumn>
      <FormikProvider value={formik}>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikInput
            name={getFieldName<typeof values>('squareFtUsedForBusiness')}
            label={questionSquareFootageBusiness?.question?.text}
            placeholder="Enter business square footage"
            fullWidth
          />
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikInput
            name={getFieldName<typeof values>('totalSquareFtOfHome')}
            label={questionSquareFootageTotal?.question?.text}
            placeholder="Enter home square footage"
            fullWidth
          />
        </GridRowColumn>
      </FormikProvider>
      {values.squareFtUsedForBusiness && values.totalSquareFtOfHome && (
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <CalculationAlert>
            Your home office allocation is <b>{percentage}%</b>
          </CalculationAlert>
        </GridRowColumn>
      )}
      <FormFlowFooter
        onBack={() => goBack(DEDUCTIONS_AND_CREDITS_SCREENS.homeOffice)}
        onForward={submitForm}
        continueDisabled={!isValid || isSubmitting}
        loading={isUpdating || isSubmitting}
      />
    </Grid>
  )
}

export default HomeOfficeDeductionsStandardStep1Panel
